import { useQuery } from "@tanstack/react-query";

import api from "../api";
import urls from "../urls";

interface Origin {
  id: string;
  name: string;
}

interface OriginSitesReturnValue {
  origins: Array<Origin>;
  isLoading: boolean;
  error: unknown;
}

function useFileOrigins(): OriginSitesReturnValue {
  const {
    isLoading: isSitesListLoading,
    error: sitesListError,
    data: origins
  } = useQuery({
    queryKey: ["origins"],
    queryFn: () => fetchOrigins()
  });

  async function fetchOrigins() {
    const response = await api.get<Array<Origin>>(
      urls.api.fileOriginsRequests()
    );
    return response.data;
  }

  return {
    isLoading: isSitesListLoading,
    error: sitesListError,
    origins: origins || []
  };
}

export { useFileOrigins };
