import type { QueryClient } from "@tanstack/react-query";

import api from "../../../../../../api";
import type {
  KwkAnlageData,
  PutOptions
} from "../../../../../../utils/backend-types";
import { getFormFieldsAndValuesFromResponse } from "./getFormFieldsAndValuesFromResponse";

export async function loadKwkAnlageData(
  queryClient: QueryClient,
  kwkAnlageUrl: string,
  visibleKwkAnlageFieldNames: Array<string>
) {
  const kwkAnlageData = await queryClient.fetchQuery({
    queryKey: ["kwkAnlage-" + kwkAnlageUrl],
    queryFn: () => getKwkAnlageData(kwkAnlageUrl, visibleKwkAnlageFieldNames)
  });

  return kwkAnlageData;
}

export async function getKwkAnlageData(
  kwkAnlageUrl: string,
  visibleKwkAnlageFieldNames: Array<string>
) {
  const optionsResponse = await api.options<PutOptions>(kwkAnlageUrl);
  const getResponse = await api.get<KwkAnlageData>(kwkAnlageUrl);

  return getFormFieldsAndValuesFromResponse(
    [optionsResponse, getResponse],
    visibleKwkAnlageFieldNames,
    ["id"]
  );
}
