import React from "react";
import type { UncontrolledDropdownProps as ReactstrapUncontrolledDropdownProps } from "reactstrap";
// eslint-disable-next-line no-restricted-imports
import { UncontrolledDropdown as ReactstrapUncontrolledDropdown } from "reactstrap";

type UncontrolledDropdownProps = ReactstrapUncontrolledDropdownProps;

function UncontrolledDropdown(props: UncontrolledDropdownProps) {
  return <ReactstrapUncontrolledDropdown {...props} />;
}

export { UncontrolledDropdown, UncontrolledDropdownProps };
