import { Alert, Badge, Group, Tabs, Text, Title } from "@mantine/core";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import {
  generatePath,
  Route,
  Routes,
  useNavigate,
  useParams
} from "react-router-dom";
import { usePersons } from "../../hooks/usePersons";
import { useProject } from "../../hooks/useProject";
import { useSiteCategories } from "../../hooks/useSiteCategories";
import { ROUTES } from "../../routes";
import { OptiSupportEndpoints } from "../../urls";
import { type Todo, TodoCategory, TodoStatus } from "../../utils/backend-types";
import { showToast } from "../../utils/toast";
import { AlertColor } from "../Alert/Alert";
import { IconName } from "../BuildingBlocks/Icon/types";
import { Portlet } from "../BuildingBlocks/Layout/Portlet";
import { IconButton } from "../Buttons/IconButton/IconButton";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { OptiSupportHelpLink } from "../OptiSupportHelpLink/OptiSupportHelpLink";
import { useShouldShowStaffView } from "../StaffViewToggle/useShouldShowStaffView";
import { AutoFillTodoModal } from "../Todos/AutoFillTodoModal/AutoFillTodoModal";
import { Mode, type TodoUser } from "../Todos/common";
import { DeleteTodosButton } from "../Todos/DeleteTodosButton/DeleteTodosButton";
import { useTodos } from "../Todos/hooks/useTodos";
import { NewTodoModal } from "../Todos/NewTodoModal/NewTodoModal";
import { SingleTodo } from "../Todos/SingleTodo";
import { TodoCalendarIntegration } from "../Todos/TodoCalendarIntegration/TodoCalendarIntegration";
import { useSystemUsers } from "../Todos/utils/useSystemUsers";
import { TodoStats } from "./TodoStats/TodoStats";
import "./TodosV2.scss";
import { TodoTable } from "./TodoTable";
import { handleChangeDueDate } from "./utils/handleChangeDueDate";
import { handleChangeOverdue } from "./utils/handleChangeOverdue";
import { handleChangePriority } from "./utils/handleChangePriority";
import { handleChangeResponsible } from "./utils/handleChangeResponsible";
import { handleChangeStatus } from "./utils/handleChangeStatus";

enum TabValues {
  Once = "once",
  Recurring = "recurring"
}

interface TodoTabLabelProps {
  label: string;
  amount?: number;
}

function TodoTab({ label, amount }: TodoTabLabelProps) {
  return (
    <Group>
      <Text size="md">{label}</Text>
      {amount && amount > 0 && <Badge>{amount}</Badge>}
    </Group>
  );
}

interface TodosV2Props {
  variantId: number;
}

function TodosV2({ variantId }: TodosV2Props) {
  const [idSelection, setIdSelection] = useState<Array<number>>([]);
  const [isNewTodoModalOpen, setIsNewTodoModalOpen] = useState(false);
  const [isAutoFillTodoModalOpen, setIsAutoFillTodoModalOpen] = useState(false);

  const isStaff = useShouldShowStaffView();
  const { projectId } = useParams();
  const navigate = useNavigate();

  const {
    project,
    isLoading: projectLoading,
    error: projectError
  } = useProject(projectId);
  const users = project?.managers;

  const {
    persons,
    isLoading: personsLoading,
    error: personsError
  } = usePersons(variantId);

  const {
    siteCategories,
    isLoading: siteCategoriesLoading,
    error: siteCategoriesError
  } = useSiteCategories(projectId || null);
  const isPremium = siteCategories?.some((category) => category.is_premium);

  /* controlled tabs since the tab content should only be rendered if selected */
  const [tab, setTab] = useState(
    typeof isPremium === "boolean"
      ? isPremium
        ? TabValues.Once
        : TabValues.Recurring
      : TabValues.Recurring
  );

  useEffect(() => {
    if (isPremium) {
      setTab(isPremium ? TabValues.Once : TabValues.Recurring);
    }
  }, [isPremium]);

  const {
    todos,
    isLoading: todosLoading,
    error: todosError,
    updateTodoResponsible,
    updateTodoPriority,
    updateTodoStatus,
    updateTodoDueDate,
    updateTodoOverdue,
    updateTodoPerson,
    updateDocumentProvided,
    removeTodo,
    removeTodos
  } = useTodos({
    mode: Mode.Normal,
    variantId,
    onUpdateError: (error) => showToast("error", error)
  });

  const oneTimeTodos =
    todos &&
    todos.filter(
      (todo) =>
        todo.category !== TodoCategory.RegulatoryDuty ||
        !todo.regulatoryDuty?.recurring
    );
  const openOneTimeTodosAmount = oneTimeTodos?.filter(
    (todo) => todo.status === TodoStatus.Open
  ).length;
  const recurringTodos =
    todos &&
    todos.filter(
      (todo) =>
        todo.category === TodoCategory.RegulatoryDuty &&
        todo.regulatoryDuty?.recurring
    );
  const openRecurringTodosAmount = recurringTodos?.filter(
    (todo) => todo.status === TodoStatus.Open
  ).length;

  const {
    isLoading: systemUsersLoading,
    error: systemUsersError,
    data: systemUsers
  } = useSystemUsers();

  // It may happen that a system user is also a project manager
  const uniqueUsersWithSystemUsers = useMemo(() => {
    const markedSystemUsers =
      systemUsers &&
      systemUsers.map<TodoUser>((user) => {
        return { ...user, isSystemUser: true };
      });

    return _.uniqBy<TodoUser>(
      users && markedSystemUsers && users.concat(markedSystemUsers),
      "id"
    );
  }, [users, systemUsers]);

  function handleAutoFillTodoModalRefreshRequired() {
    if (projectId) {
      document.location.href = generatePath(ROUTES.todos, {
        projectId: projectId
      });
    }
  }

  function handleTodoSelectionChange(selectedIds: Array<number>) {
    setIdSelection(selectedIds);
  }

  function getSelectedTodos(): Array<Todo> {
    return todos?.filter((todo) => idSelection.includes(todo.id)) || [];
  }

  async function handleDeleteTodo(todo: Todo) {
    await removeTodo(todo).catch((error) => showToast("error", error));
  }

  async function handleDeleteMultipleTodos(todos: Array<Todo>) {
    await removeTodos(todos).catch((error) => showToast("error", error));
  }

  const toggleTodoModal = (todoId: number | false) => {
    if (todoId) {
      navigate(`./${todoId}`);
    } else {
      navigate(".");
    }
  };

  // this should never happen unless Todos is used incorrectly from a wrong route
  if (!projectId) {
    return <Alert color={AlertColor.Danger}>Diese URL ist nicht gültig.</Alert>;
  }

  return (
    <div className="TodosV2">
      <LoadOrError
        error={
          projectError ||
          personsError ||
          todosError ||
          siteCategoriesError ||
          systemUsersError
        }
        loading={
          projectLoading ||
          personsLoading ||
          todosLoading ||
          siteCategoriesLoading ||
          systemUsersLoading
        }
      >
        {project &&
          persons &&
          todos &&
          oneTimeTodos &&
          openOneTimeTodosAmount !== undefined &&
          recurringTodos &&
          openRecurringTodosAmount !== undefined &&
          isPremium !== undefined && (
            <>
              <Portlet
                className="TodosV2-portlet"
                title={
                  <Group justify="space-between" pb="lg" pt="xl">
                    <Title fw="500" order={3}>
                      {"Aufgaben"}
                    </Title>
                    {isStaff && (
                      <Group className="controls" gap="md">
                        <IconButton
                          color="brand"
                          iconName={IconName.Plus}
                          onClick={() => setIsNewTodoModalOpen(true)}
                        >
                          Neue Aufgabe
                        </IconButton>
                        <IconButton
                          iconName={IconName.List}
                          variant="outline"
                          onClick={() => setIsAutoFillTodoModalOpen(true)}
                        >
                          Liste aktualisieren
                        </IconButton>
                        <TodoCalendarIntegration variantId={variantId} />
                        <DeleteTodosButton
                          todos={getSelectedTodos()}
                          onDeleteMultipleTodos={handleDeleteMultipleTodos}
                          onDeleteTodo={handleDeleteTodo}
                        />
                      </Group>
                    )}
                  </Group>
                }
              >
                <TodoStats variantId={variantId} />
                <Tabs
                  className="tabs"
                  value={tab}
                  onChange={(newTab) => {
                    setTab(newTab as TabValues);
                    setIdSelection([]);
                  }}
                >
                  <Tabs.List className="tabs-list">
                    <Tabs.Tab value={TabValues.Once}>
                      <TodoTab
                        amount={openOneTimeTodosAmount}
                        label="Einmalig"
                      />
                    </Tabs.Tab>
                    <Tabs.Tab value={TabValues.Recurring}>
                      <TodoTab
                        amount={openRecurringTodosAmount}
                        label="Wiederkehrend"
                      />
                    </Tabs.Tab>
                    <OptiSupportHelpLink
                      iconName={IconName.QuestionCircle2}
                      optiSupportEndpoint={
                        OptiSupportEndpoints.RegulatorischeAufgaben
                      }
                      text="Was sind Aufgaben?"
                    />
                  </Tabs.List>

                  {tab === TabValues.Once && (
                    <Tabs.Panel pt="md" value={TabValues.Once}>
                      <TodoTable
                        isPremium={isPremium}
                        mode={TabValues.Once}
                        projectId={projectId}
                        variantId={variantId}
                        onRowSelectionChange={handleTodoSelectionChange}
                      />
                    </Tabs.Panel>
                  )}

                  {tab === TabValues.Recurring && (
                    <Tabs.Panel pt="md" value={TabValues.Recurring}>
                      <TodoTable
                        isPremium={isPremium}
                        mode={TabValues.Recurring}
                        projectId={projectId}
                        variantId={variantId}
                        onRowSelectionChange={handleTodoSelectionChange}
                      />
                    </Tabs.Panel>
                  )}
                </Tabs>
              </Portlet>

              <NewTodoModal
                basePath={ROUTES.todos}
                isOpen={isNewTodoModalOpen}
                mode={Mode.Normal}
                persons={persons}
                projectId={projectId}
                users={project.managers}
                variantId={variantId}
                onToggle={() => setIsNewTodoModalOpen(false)}
              />
              <AutoFillTodoModal
                isOpen={isAutoFillTodoModalOpen}
                users={project.managers}
                variantId={variantId}
                onRequireTodoRefresh={handleAutoFillTodoModalRefreshRequired}
                onToggle={() => setIsAutoFillTodoModalOpen(false)}
              />
              <Routes>
                <Route
                  element={
                    <SingleTodo
                      mode={Mode.Normal}
                      projectId={projectId}
                      showTodoStatus={true}
                      todos={todos}
                      users={uniqueUsersWithSystemUsers}
                      variantId={variantId}
                      onChangeDueDate={(todoId, dueDate) =>
                        handleChangeDueDate(
                          todos,
                          updateTodoDueDate,
                          todoId,
                          dueDate
                        )
                      }
                      onChangeOverdue={(todoId, overdue) =>
                        handleChangeOverdue(
                          todos,
                          updateTodoOverdue,
                          todoId,
                          overdue
                        )
                      }
                      onChangePerson={(todoId, personId) =>
                        updateTodoPerson(todoId, personId)
                      }
                      onChangePriority={(todoId, priority) =>
                        handleChangePriority(
                          todos,
                          updateTodoPriority,
                          todoId,
                          priority
                        )
                      }
                      onChangeResponsible={(todoId, responsibleId) =>
                        handleChangeResponsible(
                          todos,
                          updateTodoResponsible,
                          todoId,
                          responsibleId
                        )
                      }
                      onChangeStatus={(todoId, status) =>
                        handleChangeStatus(
                          todos,
                          updateTodoStatus,
                          todoId,
                          status
                        )
                      }
                      onChangeTodoStatus={updateDocumentProvided}
                      onToggleTodoModal={() => toggleTodoModal(false)}
                    />
                  }
                  path=":todoId/*"
                />
              </Routes>
            </>
          )}
      </LoadOrError>
    </div>
  );
}

export { TabValues, TodosV2, TodosV2Props };
