import PropTypes from "prop-types";
import React, { useState } from "react";
import type { ButtonProps } from "reactstrap";

import type { ProjectMinimal } from "../../../utils/backend-types";
import { Button } from "../../Buttons/Button/Button";
import { useShouldShowStaffView } from "../../StaffViewToggle/useShouldShowStaffView";
import { NotAccessableModal } from "../../StructureView/NotAccessableModal/NotAccessableModal";
import { CreateProjectModal } from "./CreateProjectModal/CreateProjectModal";

interface CreateProjectButtonProps extends ButtonProps {
  onCreateProject: (projectId: string) => void;
}

function CreateProjectButton({
  onCreateProject,
  ...otherProps
}: CreateProjectButtonProps) {
  const [openModal, setOpenModal] = useState<"noAccess" | "createModal" | null>(
    null
  );
  const shouldShowStaffView = useShouldShowStaffView();

  function toggleModal() {
    if (!shouldShowStaffView) {
      setOpenModal("noAccess");
    } else {
      if (openModal === "createModal") {
        setOpenModal(null);
      } else {
        setOpenModal("createModal");
      }
    }
  }

  function handleClickSave(project: ProjectMinimal) {
    onCreateProject(project.id);
    toggleModal();
  }

  return (
    <React.Fragment>
      <span id="create-project-button-wrapper">
        <Button
          {...otherProps}
          color={!shouldShowStaffView ? "secondary" : "brand"}
          id="create-project-button"
          onClick={toggleModal}
        >
          Neues Projekt erstellen
        </Button>
      </span>
      <CreateProjectModal
        isOpen={openModal === "createModal"}
        toggle={toggleModal}
        onClickSave={handleClickSave}
      />
      {openModal === "noAccess" && (
        <NotAccessableModal
          text={
            "Diese Option steht derzeit nicht zur Verfügung," +
            " da Sie nicht über die entsprechenden Berechtigungen verfügen." +
            " Bitte wenden Sie sich an das Support-Team: "
          }
          onClose={() => setOpenModal(null)}
        />
      )}
    </React.Fragment>
  );
}

CreateProjectButton.propTypes = {
  onCreateProject: PropTypes.func.isRequired
};

export { CreateProjectButton };
