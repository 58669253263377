import classnames from "classnames";
import React, { useMemo } from "react";
import type { NodeProps, ReactFlowState } from "reactflow";
import { Handle, Position, useStore } from "reactflow";
import type { MeteringDirection } from "../../../../utils/backend-types";
import { ObjectName } from "../../../../utils/enums";
import { StructureViewMode } from "../../../StructureView/StructureView.constants";
import type { EdgeData } from "../../../StructureViewDiagram/StructureViewDiagram";
import { ConnectionPointDirection } from "../../../StructureViewDiagram/StructureViewDiagram";
import { getStructureViewImageUrl } from "../../../StructureViewDiagram/utils/getStructureViewImageUrl";
import { getArrowTypeFromMeteringDirection } from "../../utils/getArrowTypeFromMeteringDirection";
import { getCorrectSizeFromComponentType } from "../../utils/getCorrectSizeFromComponentType";
import "./CustomFlowNode.scss";
import { ArrowPosition, MeteringArrows } from "./MeteringArrows/MeteringArrows";

const DISABLED_OPACITY = 0.7;

const connectionNodeIdSelector = (state: ReactFlowState) =>
  state.connectionNodeId;

export interface CustomFlowNodeData {
  active: boolean;
  componentId: number;
  image: string;
  imageColor: string;
  label: string;
  size?: number;
  tooltipData: {
    name: string;
    text: string;
    type: string;
    isConsumptionShareConfirmed?: boolean;
    isConsumptionShareEditable?: boolean;
  };
  type: ObjectName;
  meterBillingRelevantOrSubmeter?: string;
  meterPriorityDescription?: string;
}
interface CustomFlowNodeProps extends NodeProps<CustomFlowNodeData> {
  id: string;
  mode: Exclude<StructureViewMode, StructureViewMode.Old>;
  meteringDirection?: MeteringDirection | null;
  connectedSourceEdges: Array<EdgeData>;
}
function CustomFlowNode({
  id,
  data,
  mode,
  meteringDirection,
  connectedSourceEdges
}: CustomFlowNodeProps) {
  const connectionNodeId = useStore<string | null>(connectionNodeIdSelector);

  const isConnecting = !!connectionNodeId;
  const isTarget = connectionNodeId && connectionNodeId !== id;
  const imageUrl = useMemo(() => {
    const url = data.image
      ? getStructureViewImageUrl(
          data.image,
          data.imageColor,
          data.active === false ? DISABLED_OPACITY : undefined
        )
      : null;
    return url;
  }, [data.active, data.image, data.imageColor]);

  const size: number | undefined = getCorrectSizeFromComponentType(
    data.type,
    data.size,
    mode === StructureViewMode.MeteringConcept
  );
  const isGhostNode = data.type === ObjectName.GhostNode;
  const isMeter = data.type === ObjectName.Meter;
  const label =
    isMeter && mode === StructureViewMode.MeteringConcept
      ? data.tooltipData.name
      : data.label;
  const isMCMeter = isMeter && mode === StructureViewMode.MeteringConcept;
  const isMCMeterAndBillingRelevant =
    isMCMeter &&
    data.meterBillingRelevantOrSubmeter === "Bilanzierungsrelevanter Zähler";
  const arrowType =
    isMCMeter && meteringDirection
      ? getArrowTypeFromMeteringDirection(meteringDirection)
      : null;
  return (
    <div className="CustomFlowNode" data-testid="custom-flow-node">
      <div
        className={classnames("customNodeBody", {
          ghostNode: isGhostNode,
          mcMeterBillingRelevant: isMCMeterAndBillingRelevant
        })}
        style={{
          borderStyle: isTarget ? "dashed" : "solid",
          backgroundColor: isGhostNode ? "black" : "",
          backgroundImage: imageUrl ? `url(${imageUrl})` : "",
          width: size ?? 60,
          height: size ?? 60
        }}
      >
        {isMCMeter && arrowType && (
          <MeteringArrows
            meteringPriorityDescription={data.meterPriorityDescription}
            position={
              connectedSourceEdges[0] &&
              connectedSourceEdges[0].fromConnectionPoint ===
                ConnectionPointDirection.Right
                ? ArrowPosition.Top
                : ArrowPosition.Side
            }
            type={arrowType}
          />
        )}
        {!isConnecting && (
          <>
            <Handle
              className="customHandle"
              id={ConnectionPointDirection.Right}
              position={Position.Right}
              type="source"
            />
            <Handle
              className="customHandle"
              id={ConnectionPointDirection.Bottom}
              position={Position.Bottom}
              type="source"
            />
          </>
        )}

        <Handle
          className="customHandle"
          id={ConnectionPointDirection.Left}
          isConnectableStart={false}
          position={Position.Left}
          type="target"
        />
        <Handle
          className="customHandle"
          id={ConnectionPointDirection.Top}
          isConnectableStart={false}
          position={Position.Top}
          type="target"
        />
      </div>
      <div className="custom-flow-node-label">{label}</div>
    </div>
  );
}

export { CustomFlowNode };
