import React from "react";

import type { Product } from "../../../../utils/enums";
import { ComponentEditWizard } from "./ComponentEditWizard";

interface ComponentEditWizardTsProps {
  product: Product;
  siteId?: number;
  variantId?: number;
  doAfterSave?: () => void;
  isSubSection?: boolean;
  isCollapsible?: boolean;
  initialToggle?: boolean;
  componentId?: number;
  componentName?: string;
  currentUserCanAssessManagerSiteFinancially: boolean;
  objectName?: string;
  isOpen: boolean;
  onClose: () => void;
  helpText?: string;
}

/** Temporary TypeScript version of ComponentEditWizardTs to avoid conversion of ComponentEditWizard into TypeScript. */
function ComponentEditWizardTs({
  product,
  siteId,
  variantId,
  doAfterSave,
  isSubSection,
  isCollapsible,
  initialToggle,
  ...props
}: ComponentEditWizardTsProps) {
  return (
    <ComponentEditWizard
      doAfterSave={doAfterSave}
      initialToggle={initialToggle}
      isCollapsible={isCollapsible}
      isSubSection={isSubSection}
      product={product}
      siteId={siteId}
      variantId={variantId}
      {...props}
    />
  );
}

export { ComponentEditWizardTs };
