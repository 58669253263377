import type { Site } from "../utils/backend-types";
import { useVariantSites } from "./useVariantSites";

const NO_SITE_ERROR = {
  response: {
    status: 404
  }
};

interface VariantSiteResult {
  isLoading: boolean;
  error: unknown;
  site?: Site;
  siteId?: number;
}

/** Gets the first `Site` and `siteId` for a variant. **It only makes sense to use this in Analyzer, where there is only one site per variant.** */
export function useVariantSite(
  variantId: number | undefined,
  disabled = false
): VariantSiteResult {
  const { isLoading, error, sites } = useVariantSites(variantId, disabled);

  if (isLoading || error) {
    return { isLoading, error };
  }

  const site = sites.find((site) => site.variant === variantId);
  const errorOrNoSite = site ? error : NO_SITE_ERROR;

  return { isLoading, error: errorOrNoSite, site, siteId: site?.id };
}
