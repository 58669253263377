import React from "react";
import type { MeterPlausibility } from "../../../../../../../utils/backend-types";
import { Alert, AlertColor } from "../../../../../../Alert/Alert";
import { MeterChecks } from "../MeterChecks/MeterChecks";

interface MeterInfoProps {
  plausibility: MeterPlausibility | undefined;
}

function MeterInfo({ plausibility }: MeterInfoProps) {
  if (!!plausibility && plausibility.plausibility === false) {
    return (
      <Alert className="MeterInfo" color={AlertColor.Warning}>
        <MeterChecks
          label="Einspeisung"
          problems={plausibility.failed_generation_checks}
        />
        <MeterChecks
          label="Entnahme"
          problems={plausibility.failed_consumption_checks}
        />
      </Alert>
    );
  }

  return null;
}
export { MeterInfo };
