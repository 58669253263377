import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import api from "../api";
import urls from "../urls";
import type { Person } from "../utils/backend-types";

interface PersonsReturnValue {
  persons: Array<Person>;
  paragraph6Persons: Array<Person>;
  isLoading: boolean;
  error: unknown;
}

type UsePersonsOptions = { searchBy: SearchBy };
type SearchBy = "variantId" | "siteId";

function usePersons(
  siteOrVariantId?: number,
  options?: UsePersonsOptions
): PersonsReturnValue {
  const { isLoading, error, data } = useQuery({
    queryKey: ["persons", { siteOrVariantId }],
    queryFn: () => fetchPersons(siteOrVariantId, options?.searchBy),
    enabled: typeof siteOrVariantId !== "undefined",
    refetchInterval: false,
    refetchOnWindowFocus: false
  });

  async function fetchPersons(
    siteOrVariantId?: number,
    searchBy: SearchBy = "variantId"
  ) {
    if (typeof siteOrVariantId === "undefined") {
      return undefined;
    }

    const response = await api.get<Array<Person>>(
      searchBy === "siteId"
        ? urls.api.personsBySiteId(siteOrVariantId)
        : urls.api.personsByVariantId(siteOrVariantId)
    );
    return response.data;
  }

  const paragraph6Persons = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.filter((person) => person.isParagraph_6EegActive);
  }, [data]);

  return {
    isLoading: isLoading,
    error: error,
    persons: data || [],
    paragraph6Persons
  };
}

export { usePersons };
