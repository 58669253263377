import { useQuery } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import { useFeatureFlags } from "../../../hooks/useFeatureFlags";
import { Flag } from "../../../hooks/useFeatureFlags.types";
import urls from "../../../urls";
import type { ProjectOverviewSite } from "../MsmProjectView.types";

interface ProjectOverviewSitesReturnValue {
  sites: Array<ProjectOverviewSite>;
  isLoading: boolean;
  error: unknown;
}

function useProjectOverviewSites(
  variantId: number | undefined,
  disabled = false
): ProjectOverviewSitesReturnValue {
  const { featureFlags } = useFeatureFlags();
  const isSiteSetupAssistantActive = featureFlags[Flag.siteSetupAssistant];
  const {
    isLoading: isSitesListLoading,
    error: sitesListError,
    data: sites
  } = useQuery({
    queryKey: [
      "project-overview-sites",
      { variantId, isSiteSetupAssistantActive }
    ],
    queryFn: () => fetchSitesList(variantId, isSiteSetupAssistantActive),
    enabled: !disabled && typeof variantId !== "undefined",
    refetchInterval: false,
    refetchOnWindowFocus: false
  });

  return {
    isLoading: isSitesListLoading,
    error: sitesListError,
    sites: sites || []
  };
}

export async function fetchSitesList(
  variantId: number | undefined,
  isFeatureFlagActive: boolean
) {
  if (typeof variantId === "undefined") {
    return undefined;
  }
  if (isFeatureFlagActive) {
    const response = await apiWithoutCamelization.get<
      Array<ProjectOverviewSite>
    >(urls.apiWithoutCamelization.siteSetupAssistant.sites(variantId));
    return response.data;
  } else {
    const response = await apiWithoutCamelization.get<
      Array<ProjectOverviewSite>
    >(urls.apiWithoutCamelization.sitesList(variantId));
    return response.data;
  }
}

export { useProjectOverviewSites };
