import api from "../../../api";
import urls from "../../../urls";
import type { Meter, Estimation, Identity } from "../EdaTable/Mbk";
import type { DateRange } from "./CreateEdaVersionModal";
import type { EDA_FORM_FIELDS } from "./CreateEdaVersionModal.constants";

const MEDIUM_ELECTRICITY = "electricity";
const MEDIUM_GAS = "gas";
const MEDIUM_HEAT = "heat";

export const MEDIUM = {
  [MEDIUM_ELECTRICITY]: "Strom",
  [MEDIUM_GAS]: "Gas",
  [MEDIUM_HEAT]: "Wärme"
} as const;

export type Mediums = typeof MEDIUM;
export type Medium = keyof Mediums;

export const ORIGIN_COMPUTATION = "computation";
export const ORIGIN_METER = "meter";
export const ORIGIN_ESTIMATION = "estimation";

export const ORIGIN = {
  [ORIGIN_COMPUTATION]: "Berechnung",
  [ORIGIN_METER]: "Zähler",
  [ORIGIN_ESTIMATION]: "Schätzung"
} as const;

export type Origins = typeof ORIGIN;
export type Origin = keyof Origins;

export type CreateEdaVersionPayload = (
  | ComputationPayload
  | MeterPayload
  | EstimationPayload
) &
  GenericPayload;

export type GenericPayload = {
  components: number[];
  connections: number[];
  gasConnections: number[];
  tags: string[];
  firstDate: string;
  lastDate: string;
  label: string;
  siteId: number;
  identity: Identity;
};

export type MeterPayload = {
  meter: Meter;
  computation: null;
  estimation: null;
  origin: typeof ORIGIN_METER;
};

export type EstimationPayload = {
  meter: null;
  computation: null;
  estimation: Estimation;
  origin: typeof ORIGIN_ESTIMATION;
};

export type ComputationPayload = {
  meter: null;
  computation: Computation;
  estimation: null;
  origin: typeof ORIGIN_COMPUTATION;
};

export const createEdaVersion = (args: CreateEdaVersionPayload) => {
  const { siteId, ...payload } = args;
  return api.post(urls.api.mbk(siteId), payload);
};

type Computation = {
  formulaStr: string;
  frequency: string;
  medium: string;
};

export type IdentityDefinition = {
  displayName?: string;
  connection?: string;
  generator?: string;
  consumer?: string;
  company?: string;
  site?: string;
  rootMeter?: string;
  supplier?: string;
  supplied?: string;
};

export type IdentityDefinitionList = {
  [key: string]: IdentityDefinition;
};
export const ESTIMATION_PROCEDURES_CONSTANT_LOAD = "constantLoad";
export const ESTIMATION_PROCEDURES_TYPICAL_STANDARD_VALUES =
  "typicalStandardValues";
export const ESTIMATION_PROCEDURES_TYPICAL_STANDARD_VALUES_15_MIN =
  "typicalStandardValues_15Min";
export const ESTIMATION_PROCEDURES_EXAMPLE_MEASURING = "exampleMeasuring";
export const ESTIMATION_PROCEDURES_NON_CALIBRATED_MEASURING =
  "nonCalibratedMeasuring";
export const ESTIMATION_PROCEDURES_PARITAL_RATIO = "paritalRatio";
export const ESTIMATION_PROCEDURES_MISC = "misc";

export const ESTIMATION_PROCEDURES = {
  [ESTIMATION_PROCEDURES_CONSTANT_LOAD]:
    "Worst-Case-Schätzung (konstante Last)",
  [ESTIMATION_PROCEDURES_TYPICAL_STANDARD_VALUES]:
    "Typische Standardwerte (deprecated)",
  [ESTIMATION_PROCEDURES_TYPICAL_STANDARD_VALUES_15_MIN]:
    "Typische Standardwerte in 15 Min Frequenz",
  [ESTIMATION_PROCEDURES_EXAMPLE_MEASURING]: "Exemplarische Messung",
  [ESTIMATION_PROCEDURES_NON_CALIBRATED_MEASURING]:
    "Schätzung auf Basis nicht geeichter Messwerte",
  [ESTIMATION_PROCEDURES_PARITAL_RATIO]:
    "Schätzung auf Basis anteiliger Verhältnisse",
  [ESTIMATION_PROCEDURES_MISC]: "Sonstige Schätzung"
} as const;

type EstimationProcedures = typeof ESTIMATION_PROCEDURES;
export type EstimationProcedure = keyof EstimationProcedures;

export const ESTIMATION_PROCEDURE_PARAMETER_ANNUAL_CONSUMPTION =
  "annual_consumption";
export const ESTIMATION_PROCEDURE_PARAMETER_CONSTANT_LOAD_KW =
  "constant_load_kw";
export const ESTIMATION_PROCEDURE_PARAMETER_NUMBER_OF_CONSUMERS =
  "number_of_consumers";
export const ESTIMATION_PROCEDURE_PARAMETER_RATIO_OF_ENERGY_DATA =
  "ratio_of_energy_data";
export const ESTIMATION_PROCEDURE_PARAMETER_REFERENCED_EDA_IDENTITY =
  "referenced_eda_identity";
export const ESTIMATION_PROCEDURE_PARAMETER_SECURITY_BUFFER = "security_buffer";
export const ESTIMATION_PROCEDURE_PARAMETER_COMPUTATION = "computation";
export const ESTIMATION_PROCEDURE_PARAMETER_FREQUENCY = "frequency";

export type EdaVersionFormValues = EdaVersionFormPayloadValue &
  EdaVersionFormValidityValue;

export type EdaVersionFormPayloadValue = {
  [EDA_FORM_FIELDS.annualConsumption]?: number;
  [EDA_FORM_FIELDS.artDerEnergieMenge]?: string;
  [EDA_FORM_FIELDS.company]?: number;
  [EDA_FORM_FIELDS.connection]?: number;
  [EDA_FORM_FIELDS.constantLoadKw]?: number;
  [EDA_FORM_FIELDS.consumer]?: number;
  [EDA_FORM_FIELDS.description]?: string;
  [EDA_FORM_FIELDS.estimationProcedure]?: string;
  [EDA_FORM_FIELDS.formula]?: string;
  [EDA_FORM_FIELDS.frequency]?: string;
  [EDA_FORM_FIELDS.fromCompany]?: number;
  [EDA_FORM_FIELDS.generator]?: number;
  [EDA_FORM_FIELDS.label]?: string;
  [EDA_FORM_FIELDS.medium]?: string;
  [EDA_FORM_FIELDS.meter]?: number;
  [EDA_FORM_FIELDS.numberOfConsumers]?: number;
  [EDA_FORM_FIELDS.origin]?: string;
  [EDA_FORM_FIELDS.ratioOfEnergyData]?: number;
  [EDA_FORM_FIELDS.component]?: number;
  [EDA_FORM_FIELDS.referencedEdaIdentity]?: string;
  [EDA_FORM_FIELDS.rootMeter]?: number;
  [EDA_FORM_FIELDS.securityBuffer]?: number;
  [EDA_FORM_FIELDS.stromsteuerpflichtig]?: boolean;
  [EDA_FORM_FIELDS.supplied]?: number;
  [EDA_FORM_FIELDS.supplier]?: number;
  [EDA_FORM_FIELDS.tags]?: string[];
  [EDA_FORM_FIELDS.toCompany]?: number;
};

type EdaVersionFormValidityValue = {
  [EDA_FORM_FIELDS.validity]?: DateRange;
};
