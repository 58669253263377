import * as Sentry from "@sentry/browser";
import { t } from "i18next";
import type { ApiError } from "../api";

interface ApiErrorWithUserFacingErrorMessage extends ApiError {
  userFacingErrorMessage: string;
}

type DetailError = ApiError<{ detail: string }>;

type NonFieldError = ApiError<{ nonFieldErrors: string | Array<string> }>;
type Non_Field_Error = ApiError<{ non_field_errors: string | Array<string> }>;

type DataFileError = ApiError<{ dataFile: string }>;

type StatusAndMessageError = ApiError<{
  status: string;
  message: string;
}>;

/** Returns appropriate error text according to given error. Localization Warning: https://github.com/i18next/react-i18next/issues/1042 */
export function getErrorText(
  error: unknown,
  customErrors?: Record<number, string>
) {
  let errorText: string | Array<string> = t("errors.UnknownError");

  if (!error || !(error as ApiError).response) {
    return errorText;
  }

  const errorResponse = (error as ApiError).response;

  if (customErrors && errorResponse && customErrors[errorResponse.status]) {
    errorText = customErrors[errorResponse.status];
  } else {
    switch (errorResponse?.status) {
      case 400: {
        if ((error as DetailError).response?.data.detail) {
          errorText = (error as DetailError).response?.data.detail as string;
        } else if ((error as NonFieldError).response?.data.nonFieldErrors) {
          errorText = (error as NonFieldError).response?.data
            .nonFieldErrors as Array<string>;
        } else if ((error as Non_Field_Error).response?.data.non_field_errors) {
          errorText = (error as Non_Field_Error).response?.data
            .non_field_errors as Array<string>;
        } else if ((error as DataFileError).response?.data.dataFile) {
          // For file upload
          errorText = (error as DataFileError).response?.data
            .dataFile as string;
        } else if (typeof errorResponse.data === "string") {
          errorText = errorResponse.data;
        } else {
          errorText = t("errors.InvalidData");
        }
        break;
      }
      case 403: {
        const detail = (error as DetailError).response?.data.detail;
        errorText = detail ?? t("errors.NoAccess");
        break;
      }
      case 404:
        errorText = t("errors.NotFound");
        break;
      case 449:
        errorText = t("errors.RetryWithError");
        break;
      case 422:
        errorText = (error as ApiError).response?.data as
          | string
          | Array<string>;
        break;
      case 500:
        if (
          (error as ApiErrorWithUserFacingErrorMessage).userFacingErrorMessage
        ) {
          errorText = (error as ApiErrorWithUserFacingErrorMessage)
            .userFacingErrorMessage;
        } else {
          errorText = t("errors.ServerError");
          Sentry.captureException(error);
        }
        break;
      case 502:
        errorText = t("errors.ServerError");
        Sentry.captureException(error);
        break;
      case 200: {
        const isErrorStatus =
          (error as StatusAndMessageError).response?.data.status === "ERROR";
        const message = (error as StatusAndMessageError).response?.data.message;

        if (isErrorStatus && message) {
          errorText = message;
        } else {
          Sentry.captureException(error);
        }
        break;
      }
      case 413:
        errorText = t("errors.FileTooLarge");
        Sentry.captureException(error);
        break;
      default:
        Sentry.captureException(error);
        break; // keep default error text from above
    }
  }

  return errorText;
}
