import urls from "../../../../urls";
import type { CreateManagerProject } from "../../../../utils/backend-types";
import type { FormInputData } from "../../../BuildingBlocks/Forms/Controllers/FormFieldController";

export const CREATE_MANAGER_PROJECT_FORM_FIELD_DATA = {
  name: {
    name: "name",
    label: "Bezeichnung",
    helpText: "Frei wählbar, wird nur zur Identifikation benutzt",
    type: "text",
    required: true
  },
  demo: {
    name: "demo",
    label: "Ist ein Demo-Projekt",
    type: "boolean",
    required: true
  },
  customer: {
    name: "customer",
    label: "Kunde",
    type: "async-select",
    placeholder: "Name oder Hubspot-ID",
    dataUrl: urls.api.customersList(),
    required: true
  }
} as const satisfies {
  [key in keyof CreateManagerProject]?: FormInputData<
    CreateManagerProject,
    key
  >;
};
