import api from "../../../api";
import urls from "../../../urls";
import type { GeneratorChangesFromMastr } from "../../../utils/backend-types";

export async function getChangesFromMastrResult(taskId: string) {
  const { data } = await api.get<Array<GeneratorChangesFromMastr>>(
    urls.api.getChangesFromMastrResult(taskId)
  );

  return data;
}
