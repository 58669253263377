import { isValidElement } from "react";
import type { Column } from "react-table";

import type { ColumnToExport } from "../../components/CustomReactTable/CustomReactTableHooks";

/** Converts `Header` from ReactTable 6 `Column` to a string for array of columns. */
export function gatherExcelExportDataFromReactTableColumns(
  columns: Array<Column>
) {
  return columns.map<ColumnToExport>((column, index) => ({
    accessor: column.accessor ? column.accessor.toString() : `Spalte ${index}`,
    header:
      !column.Header || isValidElement(column.Header)
        ? `Spalte ${index}`
        : (column.Header as string)
  }));
}
