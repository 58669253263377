import { useCallback, useState } from "react";
import type { Column, DerivedDataObject } from "react-table";

import type { CustomReactTableWithSelectProps } from "./CustomReactTableWithSelect/CustomReactTableWithSelect";
import { getSelectionKeyFromRecord } from "./CustomReactTableWithSelect/CustomReactTableWithSelect";

/**
 * Creates necessary extra state for CustomReactSelectTable.
 * @param initialSelection is an optional `array` in the format of `select-${keyField}` (see `composeSelectionKey`)
 * @returns state variables which must be passed to CustomReactSelectTable.
 */
export function useCustomReactTableCheckboxes<T>(
  initialSelection?: Array<string>
) {
  const [selection, setSelection] = useState(initialSelection || []);
  const [selectAll, setSelectAll] = useState(false);
  // ts: react-table mess of types (better to use any)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [tableRef, setTableRef] = useState<any>(null);

  function getSelectedData() {
    const data: Array<T> = [];

    if (tableRef) {
      const wrappedInstance = tableRef.getWrappedInstance();
      const currentRecords: Array<DerivedDataObject> =
        wrappedInstance.getResolvedState().sortedData;

      currentRecords.forEach((item) => {
        if (selection.includes(getSelectionKeyFromRecord(item))) {
          data.push(item._original);
        }
      });
    }

    return data;
  }

  return {
    selection,
    setSelection,
    selectAll,
    setSelectAll,
    tableRef,
    getSelectedData,
    customReactTableProps: {
      selection,
      onSetSelection: setSelection,
      selectAll,
      onSetSelectAll: setSelectAll,
      tableRef,
      onSetTableRef: setTableRef
    } as CustomReactTableWithSelectProps<T>
  };
}

export interface ColumnToExport {
  accessor: string;
  header: string;
}

export type CellToStringConverters = Record<
  string,
  (value) => Promise<string> | string
>;

interface CustomReactTableDataExportExportOptions {
  columnsToExport?: Array<ColumnToExport>;
  cellToStringConverters?: CellToStringConverters;
}

/** Hook to support exporting of React Table 6 table data.
 *
 * Only supports string accessors and columns with defined accessors.
 *
 * Data is exported as blob of buffered excel workbook in .xlsx format.
 */
export function useCustomReactTableDataExport<T>(
  options: CustomReactTableDataExportExportOptions = {}
) {
  // ts: react-table mess of types (better to use any)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [tableRef, setTableRef] = useState<any>(null);

  const exportData = useCallback(async () => {
    const dataToDownload: Array<Array<string>> = [];

    if (!tableRef) {
      return dataToDownload;
    }

    const currentRecords: Array<DerivedDataObject> =
      tableRef.getResolvedState().sortedData;
    const tableColumns: Array<Column<T>> = tableRef.getResolvedState().columns;
    const flattenedTableColumns: Array<Column<T>> = tableColumns.reduce(
      (newColumnsList, column) => {
        if (column.columns) {
          return [...newColumnsList, ...column.columns];
        }

        return [...newColumnsList, column];
      },
      []
    );
    const filteredTableColumns = flattenedTableColumns.filter((column) => {
      if (!options.columnsToExport) {
        return true;
      } else if (!column.accessor || typeof column.accessor !== "string") {
        return false;
      }

      return options.columnsToExport.some(
        (columnToExport) => columnToExport.accessor === column.accessor
      );
    });
    const columnHeaders = filteredTableColumns.map((column, index) => {
      const columnToExport = options.columnsToExport?.find(
        (columnToExport) => columnToExport.accessor === column.accessor
      );

      if (!columnToExport) {
        return `Spalte ${index}`;
      }

      return columnToExport.header;
    });

    dataToDownload.push(columnHeaders);

    await Promise.all(
      currentRecords.map(async (record) => {
        const rowPromises = filteredTableColumns.map((column) => {
          if (typeof column.accessor === "string") {
            if (options.cellToStringConverters?.[column.accessor]) {
              const converter = options.cellToStringConverters[column.accessor];
              return converter(record._original[column.accessor]);
            }

            const columnDataDoesNotExistOrIsEmpty =
              !Object.prototype.hasOwnProperty.call(
                record._original,
                column.accessor
              ) || record._original[column.accessor] === null;
            if (columnDataDoesNotExistOrIsEmpty) {
              return Promise.resolve("");
            }

            return Promise.resolve(
              record._original[column.accessor].toString() as string
            );
          }

          return Promise.resolve("Daten Fehler");
        });

        const rowSettledResults = await Promise.allSettled(rowPromises);
        const row = rowSettledResults.map((resultStatus) => {
          if (resultStatus.status === "fulfilled") {
            return resultStatus.value;
          }

          return "Daten Fehler";
        });

        dataToDownload.push(row);
      })
    );

    return dataToDownload;
  }, [options.cellToStringConverters, options.columnsToExport, tableRef]);

  return {
    tableRef,
    setTableRef,
    exportData
  };
}

/** Hook to expose row count from React Table 6 table data. */
export function useCustomReactTableRowCount() {
  // ts: react-table mess of types (better to use any)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [tableRef, setTableRef] = useState<any>(null);

  const getNumRows = useCallback(() => {
    if (!tableRef) {
      return null;
    }

    const currentRecords: Array<DerivedDataObject> =
      tableRef.getResolvedState().sortedData;

    return currentRecords.length;
  }, [tableRef]);

  return {
    setTableRef,
    getNumRows
  };
}

export function useCustomReactTableSortedData() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [tableRef, setTableRef] = useState<any>(null);

  function getSortedData() {
    if (!tableRef) {
      return null;
    }
    const currentRecords: Array<DerivedDataObject> =
      tableRef.getResolvedState().sortedData;

    return currentRecords;
  }
  return {
    setTableRef,
    getSortedData
  };
}
