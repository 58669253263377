import { useQuery } from "@tanstack/react-query";

import api from "../../../../api";
import urls from "../../../../urls";
import type { MeteringServiceProvider } from "../../../../utils/backend-types";

export function useMeteringServiceProvider() {
  const { data, isLoading, error } = useQuery({
    queryKey: ["metering-service-provider"],
    queryFn: fetchMeteringServiceProviderData
  });

  async function fetchMeteringServiceProviderData(): Promise<
    MeteringServiceProvider[]
  > {
    const { data } = await api.get(urls.api.meteringServiceProviders());
    return data;
  }

  return {
    meteringServiceData: data,
    isMeteringServiceLoading: isLoading,
    meteringServiceError: error
  };
}
