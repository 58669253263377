import { Group } from "@mantine/core";
import * as React from "react";
import { type SiteSetupProcessDataStep } from "../../../../types/api.types";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import { Button } from "../../../Buttons/Button/Button";
import { IconButton } from "../../../Buttons/IconButton/IconButton";
import { SpinButton } from "../../../Buttons/SpinButton/SpinButton";
import { getOrderFromStep } from "../../utils/getOrderFromStep";
import { getStepFromOrder } from "../../utils/getStepFromOrder";

interface SiteSetupAssistantFormControlsProps {
  activeStep: SiteSetupProcessDataStep;
  isSubmitting: boolean;
  navigationBlocked?: boolean;
  onStep: (stepTo: SiteSetupProcessDataStep) => void;
  onCancel: () => void;
  onSaveProgress: () => Promise<void>;
}

function SiteSetupAssistantFormControls({
  activeStep,
  isSubmitting,
  navigationBlocked,
  onStep,
  onSaveProgress,
  onCancel
}: SiteSetupAssistantFormControlsProps) {
  function stepBack(): SiteSetupProcessDataStep | null {
    const currentStepNumber = getOrderFromStep(activeStep);
    if (currentStepNumber === null || currentStepNumber - 1 === -1) {
      return null;
    } else {
      return getStepFromOrder(currentStepNumber - 1);
    }
  }

  function stepForward(): SiteSetupProcessDataStep | null {
    const currentStepNumber = getOrderFromStep(activeStep);
    if (currentStepNumber === null || currentStepNumber + 1 === 6) {
      return null;
    } else {
      return getStepFromOrder(currentStepNumber + 1);
    }
  }

  const stepBackTo = stepBack();
  const stepForwardTo = stepForward();

  function handleStepBack() {
    if (stepBackTo) {
      onStep(stepBackTo);
    }
  }

  function handleStepForward() {
    if (stepForwardTo) {
      onStep(stepForwardTo);
    }
  }

  return (
    <Group className="SiteSetupAssistantFormControls" justify="space-between">
      <Button color="secondary" onClick={onCancel}>
        Abbrechen
      </Button>

      <Group>
        <IconButton
          color="secondary"
          disabled={stepBackTo === null || navigationBlocked}
          iconColor="brand"
          iconName={IconName.ArrowCircleLeft}
          onClick={handleStepBack}
        >
          Zurück
        </IconButton>
        <IconButton
          color="secondary"
          disabled={stepForwardTo === null || navigationBlocked}
          iconColor="brand"
          iconName={IconName.ArrowCircleRight}
          onClick={handleStepForward}
        >
          Weiter
        </IconButton>
      </Group>

      <SpinButton
        color="brand"
        disabled={navigationBlocked}
        spin={isSubmitting}
        onClick={onSaveProgress}
      >
        Speichern
      </SpinButton>
    </Group>
  );
}

export { SiteSetupAssistantFormControls, SiteSetupAssistantFormControlsProps };
