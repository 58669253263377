import { ObjectName } from "../../../utils/enums";

/**
 * Gets the correct size for a node based on its type.
 * For now, only meters and ghost nodes have a different size.
 */
export function getCorrectSizeFromComponentType(
  componentType: ObjectName,
  size?: number,
  isMeteringConcept?: boolean
): number | undefined {
  if (size) {
    switch (componentType) {
      case ObjectName.Meter:
        if (isMeteringConcept) {
          return size * 4;
        }
        return size * 2;
      case ObjectName.GhostNode:
        return size * 2;
      default:
        return size;
    }
  }
}
