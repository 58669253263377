import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

import urls from "../../../urls";
import type {
  ProjectMinimal,
  VariantMinimal
} from "../../../utils/backend-types";
import { showToast } from "../../../utils/toast";
import { ErrorAlert } from "../../BuildingBlocks/ErrorAlert/ErrorAlert";
import {
  Action,
  ActionConfirmationModal,
  ActionObject
} from "../../BuildingBlocks/Layout/Modals/ActionConfirmationModal/ActionConfirmationModal";
import { Portlet } from "../../BuildingBlocks/Layout/Portlet";
import { Button } from "../../Buttons/Button/Button";
import { ProjectRow } from "../ProjectRow/ProjectRow";
import { ProjectSettingsModal } from "../ProjectSettingsModal/ProjectSettingsModal";
import { filterAnalyzerProjects } from "../utils/filterAnalyzerProjects";
import { CreateProjectModal } from "./CreateProjectModal/CreateProjectModal";
import { ProjectShareModal } from "./ProjectShareModal/ProjectShareModal";

import "./AnalyzerProjectList.scss";

interface AnalyzerProjectListProps {
  projects: Array<ProjectMinimal>;
  onReloadProjects: (projectId?: string) => void;
}

function AnalyzerProjectList({
  projects: initialProjects,
  onReloadProjects
}: AnalyzerProjectListProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [projects, setProjects] = useState<Array<ProjectMinimal>>(
    filterAnalyzerProjects(initialProjects)
  );
  const [variantsExpanded, setVariantsExpanded] = useState<
    Record<string, boolean>
  >(setInitialVariantsExpanded());
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [projectToUpdate, setProjectToUpdate] = useState<ProjectMinimal | null>(
    null
  );
  const [projectForModal, setProjectForModal] = useState<ProjectMinimal | null>(
    null
  );
  const [variantForModal, setVariantForModal] = useState<VariantMinimal | null>(
    null
  );
  const [modalAction, setModalAction] = useState<Action | null>(null); // todo ts: create enums in ActionConfirmationModal
  const [modalObject, setModalObject] = useState<ActionObject | null>(null);
  const [error, setError] = useState<unknown>(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    const newProjects = filterAnalyzerProjects(initialProjects);
    setProjects(newProjects);
  }, [initialProjects]);

  function setInitialVariantsExpanded(): Record<string, boolean> {
    const variantIds = searchParams.getAll("erweiterteVarianten");
    return variantIds.reduce((obj, id) => {
      obj[id] = true;
      return obj;
    }, {});
  }

  function toggleVariantsExpanded(project: ProjectMinimal): void {
    const newVariantsExpanded: Record<string, boolean> = {
      ...variantsExpanded,
      [project.id]: !variantsExpanded[project.id]
    };
    const filteredVariants = Object.keys(newVariantsExpanded).filter(
      (key) => newVariantsExpanded[key]
    );

    setSearchParams({
      erweiterteVarianten: filteredVariants
    });
    setVariantsExpanded(newVariantsExpanded);
  }

  function handleSelectProjectToDelete(project: ProjectMinimal): void {
    setProjectForModal(project);
    setModalAction(Action.Delete);
    setModalObject(ActionObject.Project);
  }

  function handleSelectVariantToDelete(variant: VariantMinimal): void {
    setVariantForModal(variant);
    setModalAction(Action.Delete);
    setModalObject(ActionObject.Variant);
  }

  function handleSelectProjectToCopy(project: ProjectMinimal): void {
    setProjectForModal(project);
    setModalAction(Action.Copy);
    setModalObject(ActionObject.Project);
  }

  function handleSelectVariantToCopy(variant: VariantMinimal): void {
    setVariantForModal(variant);
    setModalAction(Action.Copy);
    setModalObject(ActionObject.Variant);
  }

  function openProjectSettingsModal(project: ProjectMinimal): void {
    setProjectToUpdate(project);
    toggleSettingsModal();
  }

  function toggleSettingsModal(): void {
    setIsSettingsModalOpen((isSettingsModalOpen) => !isSettingsModalOpen);
  }

  function openProjectShareModal(project: ProjectMinimal): void {
    setProjectToUpdate(project);
    toggleShareModal();
  }

  function toggleShareModal(): void {
    setIsShareModalOpen((isShareModalOpen) => !isShareModalOpen);
  }

  function onError(error: unknown): void {
    setError(error);
  }

  function handleCloseModal(): void {
    setProjectForModal(null);
    setVariantForModal(null);
    setModalAction(null);
    setModalObject(null);
  }

  function handleUpdateSettings(project: ProjectMinimal): void {
    onReloadProjects(project.id);
    toggleSettingsModal();
  }

  function handleShare(projectId: string): void {
    onReloadProjects(projectId);
    showToast("success", "Das Projekt wurde erfolgreich geteilt.");
  }

  function handleActionSuccess(): void {
    onReloadProjects();
    handleCloseModal();
  }

  function redirectToVariant(variantId: number, projectId: string): void {
    navigate(urls.analyzerVariantStructure(variantId, projectId));
  }

  const projectRows = projects.map((project) => (
    <ProjectRow
      isVariantsExpanded={variantsExpanded[project.id]}
      key={project.id}
      project={project}
      showVariants
      onClickProjectSettings={openProjectSettingsModal}
      onClickShareProject={openProjectShareModal}
      onSelectProjectToCopy={handleSelectProjectToCopy}
      onSelectProjectToDelete={handleSelectProjectToDelete}
      onSelectVariantToCopy={handleSelectVariantToCopy}
      onSelectVariantToDelete={handleSelectVariantToDelete}
      onToggleExpanded={() => toggleVariantsExpanded(project)}
    />
  ));

  const noProject = (
    <div className="col-lg-8 ml-lg-auto">
      <CreateProjectButton size="lg" onCreateProject={redirectToVariant} />
    </div>
  );

  const createProjectButton = (
    <CreateProjectButton onCreateProject={redirectToVariant} />
  );
  const portletNavItems =
    projects.length > 0 ? [createProjectButton] : undefined;

  return (
    <Container>
      <Row>
        <Col>
          <Portlet
            className="AnalyzerProjectList"
            navItems={portletNavItems}
            title="Projekte"
          >
            {projectToUpdate && (
              <ProjectSettingsModal
                isOpen={isSettingsModalOpen}
                projectId={projectToUpdate.id}
                projectName={projectToUpdate.name}
                toggle={toggleSettingsModal}
                onClickSave={handleUpdateSettings}
              />
            )}
            {projectToUpdate && (
              <ProjectShareModal
                isOpen={isShareModalOpen}
                projectId={projectToUpdate.id}
                projectName={projectToUpdate.name}
                toggle={toggleShareModal}
                onError={onError}
                onShare={handleShare}
              />
            )}
            {modalAction &&
              modalObject === ActionObject.Project &&
              !!projectForModal && (
                <ActionConfirmationModal
                  action={modalAction}
                  object={modalObject}
                  project={projectForModal}
                  onActionSuccess={handleActionSuccess}
                  onClose={handleCloseModal}
                  onError={onError}
                />
              )}
            {modalAction &&
              modalObject === ActionObject.Variant &&
              !!variantForModal && (
                <ActionConfirmationModal
                  action={modalAction}
                  object={modalObject}
                  variant={variantForModal}
                  onActionSuccess={handleActionSuccess}
                  onClose={handleCloseModal}
                  onError={onError}
                />
              )}
            {!!error && <ErrorAlert error={error} />}
            {projects.length > 0 ? projectRows : noProject}
          </Portlet>
        </Col>
      </Row>
    </Container>
  );
}

function CreateProjectButton({
  size,
  onCreateProject
}: {
  size?: string;
  onCreateProject: (variantId: number, projectId: string) => void;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  function toggleModal() {
    setIsModalOpen(!isModalOpen);
  }

  return (
    <React.Fragment>
      <span id="create-project-button-wrapper">
        <Button
          color="brand"
          id="create-project-button"
          size={size}
          onClick={toggleModal}
        >
          Neues Projekt erstellen
        </Button>
      </span>
      <CreateProjectModal
        isOpen={isModalOpen}
        toggle={toggleModal}
        onClickSave={onCreateProject}
      />
    </React.Fragment>
  );
}

AnalyzerProjectList.propTypes = {
  projects: PropTypes.array.isRequired,
  onReloadProjects: PropTypes.func.isRequired
};

export { AnalyzerProjectList };
