import type { components } from "../../types/api.types";
import type { RegulatoryDutyCategory } from "../../utils/backend-types";

export type StartPageTile = components["schemas"]["StartPageTile"];

export interface StartPageInvoices {
  created: number;
  sent: number;
}

export interface StartPageElectricityTax {
  amountOfTemplates: number;
}

export interface StartPageDeliveredElectricity {
  sumDirect: number;
  sumForwarded: number;
}

export interface StartPageProducedElectricity {
  producedElectricityKwh: number;
}

export interface StartPageEEG6 {
  sum: number;
  paid: number;
}

export interface StartPageCO2Savings {
  sum: number;
}

export interface StartPageSite {
  id: number;
  name: string;
  project_id: string;
  project_name: string;
}

export interface StartPageOnboardingProgress {
  projectId: string;
  projectName: string;
  siteId: number;
  siteName: string;
  phaseName: string;
}

export interface StartPageCompany {
  id: number;
  name: string;
}

export enum StartPageRegulatoryTodoTag {
  DUE = "due",
  NEW = "new"
}

export interface StartPageRegulatoryTodo {
  created: string;
  regulatoryDutyCategory: RegulatoryDutyCategory;
  dueDate: string;
  id: number;
  name: string;
  personId: number | null;
  personName: string | null;
  projectId: string;
  projectName: string;
  tag: StartPageRegulatoryTodoTag;
}
