type ComparisonResult = -1 | 0 | 1;

function compareByComparisonOperators(a: number, b: number): ComparisonResult;
function compareByComparisonOperators(a: string, b: string): ComparisonResult;
function compareByComparisonOperators(
  a: string | number,
  b: string | number
): ComparisonResult {
  if (a < b) {
    return -1;
  } else if (b < a) {
    return 1;
  }

  return 0;
}

export { compareByComparisonOperators };
