/** Check that format contains {JJJJ} or {JJ}, and {nummer}. */
export function isFormatValid(format: string | null) {
  if (!format || typeof format !== "string") {
    return false;
  }

  const hasYear = format.includes("{JJJJ}") || format.includes("{JJ}");
  const hasNumber = format.toLowerCase().includes("{nummer}");

  return hasYear && hasNumber;
}
