import type { DateTime } from "luxon";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { useEnergyDataAcquisitionFilters } from "../../../../hooks/useEnergyDataAcquisitionFilters";
import { useEnergyDataAcquisitions } from "../../../../hooks/useEnergyDataAcquisitions";
import { useEnergyDisplayData } from "../../../../hooks/useEnergyDisplayData";
import type { Site } from "../../../../utils/backend-types";
import { THEME_VARS } from "../../../../utils/constants";
import { Frequency, Unit } from "../../../../utils/enums";
import { AcquisitionPlot } from "../../../EnergyData/EnergyDataView/AcquisitionPlot/AcquisitionPlot";
import {
  DATE_2022_FILTER,
  buildFilterString
} from "../../../EnergyData/EnergyDataView/EnergyDataAcquisitionSelectionModal/Filters/filter-utils";
import type { AcquisitionFilter } from "../../../EnergyData/EnergyDataView/EnergyDataView";
import { openErrorAlertPopup } from "../../../ErrorAlertPopup/openErrorAlertPopup";
import { AnimatedLoadingIcon } from "../../../Icons/AnimatedLoadingIcon/AnimatedLoadingIcon";
import { LoadOrError } from "../../../LoadOrError/LoadOrError";
import { useParkabrechnungPoolContracts } from "../../hooks/useParkabrechnungPoolContracts";
import type { SimpleOrExpandedCompany } from "../../Parkabrechnung.types";
import { ParkabrechnungContext } from "../../ParkabrechnungContext";
import { ContractSelector } from "../ContractSelector/ContractSelector";
import { DisplayedDaysSelector } from "../DisplayedDaysSelector/DisplayedDaysSelector";
import { IntervalSelector } from "../IntervalSelector/IntervalSelector";
import type {
  ParkabrechnungDataResponse,
  ParkabrechnungDataResponseSerieses
} from "../ParkabrechnungTable/ParkabrechnungTable";
import { ParkabrechnungTable } from "../ParkabrechnungTable/ParkabrechnungTable";
import { SiteSelector } from "../SiteSelector/SiteSelector";
import { getAllBelieferterIds } from "../utils/getAllBelieferterIds";
import "./ParkabrechnungData.scss";
import { ParkabrechnungDataPriceInput } from "./ParkabrechnungDataPriceInput";

type ParkabrechnungDataProps = {
  selectedSite: Site | undefined;
  sites: Array<Site>;
  variantId: number;
  projectId: string;
  abrechnungType: ParkabrechnungTag;
  companies: Array<SimpleOrExpandedCompany>;
};

type MonthYearFrequency = Frequency.Month | Frequency.Year;

function isMonthYearFrequency(arg: Frequency): arg is MonthYearFrequency {
  return arg === Frequency.Month || arg === Frequency.Year;
}

export const PARKABRECHNUNG_TAGS = {
  drittlieferung: "Drittlieferung Parkabrechnung",
  netzbezug: "Netzbezug Parkabrechnung",
  vertrag: "Vertrag Parkabrechnung"
} as const;

export type ParkabrechnungTag =
  (typeof PARKABRECHNUNG_TAGS)[keyof typeof PARKABRECHNUNG_TAGS];

export function isParkabrechnungTag(arg: unknown): arg is ParkabrechnungTag {
  return Object.keys(PARKABRECHNUNG_TAGS).includes(arg as ParkabrechnungTag);
}
type GetFilterArgs =
  | {
      abrechnungType: "Netzbezug Parkabrechnung";
      selectedSiteId?: number;
      parkabrechnungTagId?: number;
    }
  | {
      abrechnungType: "Drittlieferung Parkabrechnung";
      parkabrechnungTagId?: number;
      selectedSiteId?: number;
      selectedCompanyId?: number;
    }
  | { abrechnungType: "Vertrag Parkabrechnung" };

function getFilters(arg: GetFilterArgs): AcquisitionFilter[] | undefined {
  switch (arg.abrechnungType) {
    case PARKABRECHNUNG_TAGS.netzbezug: {
      const { parkabrechnungTagId, selectedSiteId } = arg;
      if (
        typeof parkabrechnungTagId === "undefined" ||
        typeof selectedSiteId === "undefined"
      ) {
        return undefined;
      } else
        return [
          ...DATE_2022_FILTER,
          {
            options: [parkabrechnungTagId.toString()],
            type: "energyDataAcquisitionTags"
          },
          { options: [selectedSiteId.toString()], type: "sites" }
        ];
    }

    case PARKABRECHNUNG_TAGS.drittlieferung: {
      const { parkabrechnungTagId, selectedCompanyId, selectedSiteId } = arg;
      if (
        typeof parkabrechnungTagId === "undefined" ||
        typeof selectedSiteId === "undefined" ||
        typeof selectedCompanyId === "undefined"
      ) {
        return undefined;
      } else
        return [
          ...DATE_2022_FILTER,
          {
            options: [parkabrechnungTagId.toString()],
            type: "energyDataAcquisitionTags"
          },
          { options: [selectedSiteId.toString()], type: "sites" }

          // TODO: uncomment this when the backend is ready
          // { options: [selectedCompanyId.toString()], type: "persons" }
        ];
    }

    default:
      return undefined;
  }
}
export function ParkabrechnungData({
  companies,
  sites,
  variantId,
  projectId,
  abrechnungType
}: ParkabrechnungDataProps) {
  const [selectedFrequency, setSelectedFrequency] =
    useState<MonthYearFrequency>(Frequency.Month);
  const [filteredEnergyData, setFilteredEnergyData] =
    useState<ParkabrechnungDataResponse>();
  const {
    ctPerKwh,
    selectedEndDate,
    selectedStartDate,
    setSelectedEndDate,
    selectedSite,
    setSelectedStartDate,
    selectedCompany,
    setPoolContractId: setPoolContractid
  } = useContext(ParkabrechnungContext);
  const { data: edaFilters } = useEnergyDataAcquisitionFilters(projectId);

  const parkabrechnungTagId =
    edaFilters?.data?.energyDataAcquisitionTags.filter(
      (tag) => tag.label === abrechnungType
    )?.[0]?.value;

  const filters = getFilters({
    abrechnungType,
    parkabrechnungTagId,
    selectedCompanyId: selectedCompany,
    selectedSiteId: selectedSite?.id
  });
  const { data: edas } = useEnergyDataAcquisitions(
    variantId,
    buildFilterString(filters ?? []),
    undefined,
    {
      enabled: Boolean(filters)
    }
  );
  const payload = {
    energyDataAcquisitions: edas?.map((eda) => eda.id),
    firstDate: selectedStartDate?.toISO(),
    lastDate: selectedEndDate?.toISO(),
    frequency: selectedFrequency,
    unit: Unit.KilowattHour
  };

  const {
    data: energyDataDisplayDataResponse,
    error,
    isError,
    isLoading: energyDataDisplayDataLoading,
    fetchStatus
  } = useEnergyDisplayData({
    payload,
    enabled:
      Boolean(filters) &&
      Array.isArray(edas) &&
      edas.length > 0 &&
      Boolean(selectedStartDate?.toISO()) &&
      Boolean(selectedEndDate?.toISO())
  });
  const filterSerieses = useCallback(
    (neededIds: number[], serieses: ParkabrechnungDataResponseSerieses) => {
      const filteredHeaders = filterDataByIds(neededIds, serieses.header);
      const filteredIds = serieses.ids.filter((id) => neededIds.includes(id));
      const filteredLabels = filterDataByIds(neededIds, serieses.labels);
      const filterdValues = filterDataByIds(neededIds, serieses.values);
      return {
        ...serieses,
        header: filteredHeaders,
        ids: filteredIds,
        labels: filteredLabels,
        values: filterdValues
      };
    },
    []
  );

  useEffect(() => {
    if (
      energyDataDisplayDataResponse &&
      energyDataDisplayDataResponse.serieses
    ) {
      const summary = energyDataDisplayDataResponse.summary.filter((eda) => {
        return (
          eda.energyDataAcquisition.identity.type ===
            "ThirdPartyDeliveryFromCompanyToCompany" &&
          eda.energyDataAcquisition.identity.fromCompany === selectedCompany
        );
      });
      const neededIds = summary.map((eda) => {
        return eda.energyDataAcquisition.id;
      });
      const serieses = filterSerieses(
        neededIds,
        energyDataDisplayDataResponse.serieses
      );
      setFilteredEnergyData({
        serieses: serieses,
        summary: summary,
        acquisitionsWithLowerFrequency:
          energyDataDisplayDataResponse.acquisitionsWithLowerFrequency,
        missingData: energyDataDisplayDataResponse.missingData
      });
    }
  }, [
    selectedCompany,
    selectedSite,
    energyDataDisplayDataResponse,
    filterSerieses
  ]);

  useEffect(() => {
    if (isError) {
      openErrorAlertPopup(error);
    }
  }, [isError, error]);

  function handleChangeInterval(_, interval: Frequency) {
    if (isMonthYearFrequency(interval)) {
      setSelectedFrequency(interval);
    }
  }
  function filterDataByIds(ids: number[], data: Record<string, unknown>) {
    const filteredData = {};

    ids.forEach((id) => {
      if (data && data[id]) {
        filteredData[id] = data[id];
      }
    });

    return filteredData;
  }

  function handleChangeDateRange(dates: {
    startDate: DateTime;
    endDate: DateTime | null;
  }) {
    setSelectedStartDate(dates.startDate.startOf("month"));

    if (dates.endDate) {
      setSelectedEndDate(dates.endDate.endOf("month"));
    }
  }

  const belieferterCompanies = getAllBelieferterIds(
    energyDataDisplayDataResponse,
    selectedCompany
  ).map((id) => {
    return {
      value: id,
      displayName:
        companies.find((company) => company.id.toString() === id.toString())
          ?.name ?? ""
    };
  });

  const { contracts } = useParkabrechnungPoolContracts(variantId);

  // temporarily hide the graph and table for netzbezug as we don't have an endpoint yet
  // for contract-based netzbezug data to feed graph and table
  const showGraphAndTable = abrechnungType !== PARKABRECHNUNG_TAGS.netzbezug;

  return (
    <div className="netzbezug-data-container">
      <Row>
        <Col>
          <div className="netzbezug-data-site-input-container">
            {abrechnungType === PARKABRECHNUNG_TAGS.netzbezug && (
              <ContractSelector
                contractChoices={
                  contracts?.map((contract) => ({
                    value: contract.id,
                    displayName: contract.name
                  })) ?? []
                }
                onChange={(_, newValue) => {
                  setPoolContractid(newValue);
                }}
              />
            )}

            {abrechnungType === PARKABRECHNUNG_TAGS.drittlieferung &&
              selectedSite && (
                <SiteSelector
                  abrechnungType={abrechnungType}
                  companies={companies}
                  label={{
                    companyLabel: "von",
                    siteLabel: "Lieferung in"
                  }}
                  sites={sites}
                />
              )}
          </div>
        </Col>

        <Col className="netzbezug-upper-right-controls-container">
          <div className="netzbezug-data-interval-input-container">
            <IntervalSelector
              selectedFrequency={selectedFrequency}
              onIntervalChange={handleChangeInterval}
            />
          </div>
          <div className="netzbezug-data-displayed-days-selector-container">
            {selectedEndDate && selectedStartDate && (
              <DisplayedDaysSelector
                endDate={selectedEndDate}
                monthYearPickerMode={selectedFrequency}
                startDate={selectedStartDate}
                onChange={handleChangeDateRange}
              />
            )}
          </div>
        </Col>
      </Row>
      {showGraphAndTable && (
        <LoadOrError error={error} loading={energyDataDisplayDataLoading}>
          {filteredEnergyData && (
            <>
              <Row>
                {energyDataDisplayDataLoading && fetchStatus !== "idle" && (
                  <div className="energy-data-loading-spinner">
                    <AnimatedLoadingIcon color={THEME_VARS.primaryColor} />
                  </div>
                )}

                <AcquisitionPlot
                  acquisitionSerieses={filteredEnergyData.serieses}
                  selectedUnitValue={Unit.KilowattHour}
                />
              </Row>
              <Row>
                <Col>
                  <ParkabrechnungDataPriceInput
                    abrechnungType={abrechnungType}
                    belieferterCompanies={belieferterCompanies}
                    projectId={projectId}
                  />

                  <ParkabrechnungTable
                    abrechnungType={abrechnungType}
                    ctPerKwh={ctPerKwh}
                    energyDataDisplayDataResponse={filteredEnergyData}
                    variantId={variantId}
                  />
                </Col>
              </Row>
            </>
          )}
        </LoadOrError>
      )}
    </div>
  );
}
