import React from "react";
import type { Control, FieldErrors } from "react-hook-form";
import type { Delivery } from "../../../../../../../utils/backend-types";
import type {
  FormFieldData,
  FormInputData
} from "../../../../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { FormFieldController } from "../../../../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { isFieldError } from "../../../../../../BuildingBlocks/Forms/FormField/HelperText/utils/isFieldError";
import { SectionHeader } from "../../../../../../DynamicForm/FormItems/SectionHeader/SectionHeader";
import { ChangeablePricesField } from "../../../../SharedFields/ChangeablePricesField";
import { DELIVERY_FORM_HELP_TEXTS } from "../../DeliveryForm.constants";

interface DirectDeliveryFixedPriceFieldsProps {
  control: Control<Delivery>;
  errors: FieldErrors<Delivery>;
  formFieldData: FormFieldData<Delivery>;
}

function DirectDeliveryFixedPriceFields({
  control,
  errors,
  formFieldData
}: DirectDeliveryFixedPriceFieldsProps) {
  return (
    <>
      <SectionHeader>Konditionen Direktlieferung</SectionHeader>
      <ChangeablePricesField
        className="WorkingPrices"
        control={control}
        error={
          isFieldError(errors.workingPrices) ? errors.workingPrices : undefined
        }
        helpText={DELIVERY_FORM_HELP_TEXTS.workingPrices}
        label={"Arbeitspreis inkl. Abgaben/Umlagen, exkl. MWSt.*"}
        name="workingPrices"
        unit="ct/kWh"
      />
      <FormFieldController
        control={control}
        data={
          formFieldData.electricityTax as FormInputData<
            Delivery,
            "electricityTax"
          >
        }
        error={errors.electricityTax}
      />
    </>
  );
}

export { DirectDeliveryFixedPriceFields };
