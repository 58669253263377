import { Text } from "@mantine/core";
import { DateTime } from "luxon";
import React from "react";
import { HistoryLogAction } from "../../../types/api.types";
import type { HistoryLog } from "../../../utils/backend-types";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import type { FieldNameToLabelMap } from "../HistoryTab";
import { ChangeDisplay } from "./ChangeDisplay/ChangeDisplay";

interface HistoryItemProps {
  historyLog: HistoryLog;
  fieldNameToLabelMap: FieldNameToLabelMap;
}

function HistoryItem({ historyLog, fieldNameToLabelMap }: HistoryItemProps) {
  const userName = historyLog.user ?? (
    <Text fs="italic" span>
      Gelöschte Benutzer:in
    </Text>
  );
  const datetime = backendDateOrDateTimeToLuxonDateTime(historyLog.timestamp);
  const displayDate = datetime.toLocaleString(DateTime.DATE_MED);
  const displayTime = datetime.toLocaleString(DateTime.TIME_24_SIMPLE);

  switch (historyLog.action) {
    case HistoryLogAction.Created:
      return (
        <Text>
          <i>{historyLog.object_label}</i> wurde von {userName} am {displayDate}{" "}
          um {displayTime} Uhr erstellt.{" "}
          {historyLog.additional_data?.extra_information}
        </Text>
      );
    case HistoryLogAction.Updated:
      return (
        <div>
          <Text>
            {userName} hat am {displayDate} um {displayTime} Uhr folgende
            Änderungen vorgenommen:
          </Text>
          <ul>
            {Object.keys(historyLog.changes).map((fieldName) => {
              return (
                <li key={fieldName}>
                  <ChangeDisplay
                    change={historyLog.changes[fieldName]}
                    fieldName={fieldName}
                    fieldNameToLabelMap={fieldNameToLabelMap}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      );
    case HistoryLogAction.Deleted:
      return (
        <Text>
          <i>{historyLog.object_label}</i> wurde von {userName} am {displayDate}{" "}
          um {displayTime} Uhr gelöscht.{" "}
          {historyLog.additional_data?.extra_information}
        </Text>
      );
    default:
      return null;
  }
}

export { HistoryItem, HistoryItemProps };
