import { TodoStatus } from "../../../../utils/backend-types";
import type { OnboardingTodo } from "../../site-status-types";

/** Check how many onboarding todos are marked as `done` or `not relevant`. */
export function calculateNumDoneOnboardingTodos(todos: Array<OnboardingTodo>) {
  return todos.filter(
    (todo) =>
      todo.status === TodoStatus.Done || todo.status === TodoStatus.NotRelevant
  ).length;
}
