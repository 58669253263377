import React, { Component } from "react";

export default function WithHover(WrappedComponent) {
  class Hover extends Component {
    state = {
      isHovered: false
    };

    onMouseEnter = () => {
      this.setState({ isHovered: true });
    };

    onMouseLeave = () => {
      this.setState({ isHovered: false });
    };

    render() {
      return (
        <WrappedComponent
          isHovered={this.state.isHovered}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          {...this.props}
        />
      );
    }
  }

  return Hover;
}
