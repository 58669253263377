import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../api";
import urls from "../urls";
import type { MeteringConceptTodo } from "../utils/backend-types";

function useMeteringConceptTodos(
  siteId: number,
  options?: Partial<UseQueryOptions<Array<MeteringConceptTodo>>>
) {
  const { isLoading, error, data } = useQuery({
    queryKey: ["metering-concept-todos", { siteId }],
    queryFn: () => getMeteringConceptTodos(siteId),
    ...options
  });

  async function getMeteringConceptTodos(siteId) {
    const response = await apiWithoutCamelization.get<
      Array<MeteringConceptTodo>
    >(urls.apiWithoutCamelization.meteringConceptTodos(siteId));
    return response.data;
  }

  return {
    isLoading: isLoading,
    error: error,
    meteringConceptTodos: data || []
  };
}

export { useMeteringConceptTodos };
