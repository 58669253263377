import type { EdaWithDisplayableReferences } from "../EdaVisualization/hooks/useMbks";

export type Eda = {
  identity: Identity;
  versions: Array<Version>;
};
export type Identity = {
  type: string;
  displayName: string;
  connection?: number;
  generator?: number;
  consumer?: number;
  company?: number;
  site?: number;
  rootMeter?: number;
  supplier?: number;
  supplied?: number;
  toCompany?: number;
  fromCompany?: number;
  description?: string;
  component?: number;
};

export type Version = (ComputationVersion | MeterVersion | EstimationVersion) &
  GenericVersion;

type GenericVersion = {
  publicId: number;
  label: string;
  tags: Array<string>;
  firstDate: string;
  lastDate: string;
  connections: Array<number>;
  gasConnections: Array<number>;
};

type ComputationVersion = {
  meter: null;
  computation: Computation;
  estimation: null;
  origin: "computation";
};

type MeterVersion = {
  meter: Meter;
  computation: null;
  estimation: null;
  origin: "meter";
};

type EstimationVersion = {
  meter: null;
  computation: null;
  estimation: Estimation;
  origin: "estimation";
};

export type Meter = {
  id: number;
  medium: string;
};

export type Computation = {
  formulaStr: string;
  frequency: string;
  medium: string;
};
export type Estimation = {
  frequency: string;
  procedure: string;
  medium: string;
  computation?: Computation | null;
  constantLoadKw?: number | null;
  annualConsumption?: number | null;
  numberOfConsumers?: number | null;
  ratioOfEnergyData?: number | null;
  securityBuffer: number | null;
  referencedEdaIdentity?: Identity | null;
};

export type ArrayedMBKResponse = Array<MBKResponse>;

export type MBKResponse = Version & {
  type: string;
  referenzen: string;
  timespan: string;
  medium: string;
  details: string;
  originalVersion: Eda;
};

export const MBKAsResponseArray = (
  edas: EdaWithDisplayableReferences[]
): ArrayedMBKResponse => {
  const res: ArrayedMBKResponse = [];

  for (const { versions, identity, referencesDisplayname } of edas) {
    for (const version of versions) {
      res.push({
        ...version,
        type: identity.type,
        medium: `${
          version?.computation?.medium ||
          version?.meter?.medium ||
          version?.estimation?.medium ||
          ""
        }`,
        details: version?.computation?.formulaStr ?? "",
        timespan: `${version.firstDate} - ${version.lastDate}`,
        referenzen: referencesDisplayname,
        originalVersion: { identity, versions: [version] }
      });
    }
  }

  return res;
};
