import React from "react";

import { Icon } from "../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../BuildingBlocks/Icon/types";

function RightIcon() {
  return <Icon className="m-menu__ver-arrow" name={IconName.AngleRight} />;
}

export { RightIcon };
