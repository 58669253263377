import React from "react";
import AlvaLogo from "../../../alva/assets/img/alva-logo.svg";
import DefaultLogo from "../../../default/assets/img/logo-opti-node.png";
import SecondaryDefaultLogo from "../../../default/assets/img/logo.png";
import SecondarySmarendoLogo from "../../../roedl/assets/img/logo-white-small.png";
import SmarendoLogo from "../../../roedl/assets/img/logo.svg";
import { SiteName } from "../../utils/enums";

interface SiteLogoProps {
  siteName: SiteName;
  useSecondaryImage?: boolean;
}

function SiteLogo({ siteName, useSecondaryImage = false }: SiteLogoProps) {
  function getLogo() {
    if (siteName === SiteName.Smarendo) {
      if (useSecondaryImage) {
        return SecondarySmarendoLogo;
      }

      return SmarendoLogo;
    } else if (siteName === SiteName.Alva) {
      return AlvaLogo;
    }

    if (useSecondaryImage) {
      return SecondaryDefaultLogo;
    }

    return DefaultLogo;
  }
  return <img alt="logo" id="logo" src={getLogo()} />;
}

export { SiteLogo };
