import { TodoStatus } from "../../../utils/backend-types";
import type { Choice } from "../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { canTodoBeNotRelevant } from "./canTodoBeNotRelevant";

const CHOICES: Array<Choice<TodoStatus>> = [
  {
    displayName: "Offen",
    value: TodoStatus.Open
  },
  {
    displayName: "Erledigt",
    value: TodoStatus.Done
  }
];

const NOT_RELEVANT_CHOICES: Array<Choice<TodoStatus>> = [
  ...CHOICES,
  {
    displayName: "Nicht relevant",
    value: TodoStatus.NotRelevant
  }
];

/** Get `Choice` array depending on TodoStatus. */
export function getTodoStatusChoices(
  currentStatus: TodoStatus,
  todoLabel = "",
  isStaff = false
) {
  const isNotRelevant = currentStatus === TodoStatus.NotRelevant;
  const canBeNotRelevant = isStaff && canTodoBeNotRelevant(todoLabel);

  if (isNotRelevant || canBeNotRelevant) {
    return NOT_RELEVANT_CHOICES;
  }

  return CHOICES;
}
