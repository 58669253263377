import { Group, Text } from "@mantine/core";
import { type MRT_ColumnDef } from "mantine-react-table";
import React from "react";
import { type Todo } from "../../../utils/backend-types";
import { backendDateOrDateTimeToLuxonDateTimeOrNull } from "../../../utils/dates/backendDateOrDateTimeToLuxonDateTimeOrNull";
import { Icon } from "../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../BuildingBlocks/Icon/types";
import { TodosListColumnKeys } from "../TodoTable";

const TODO_DUE_DATE_EMPTY = "empty";
const TODO_OVERDUE = "overdue";

export function getDueDateColumn(
  todos: Array<Todo>,
  isPremium: boolean
): MRT_ColumnDef<Todo> {
  const now = new Date().valueOf();
  const allDueDateYears = todos.map(
    (todo) => backendDateOrDateTimeToLuxonDateTimeOrNull(todo.dueDate)?.year
  );
  const sortedNonNullYears = allDueDateYears
    .filter((dueDate) => typeof dueDate !== "undefined")
    .sort((dateA, dateB) => (dateB ?? 0) - (dateA ?? 0));
  const uniqueUsedDueDateYears = Array.from<number>(
    new Set(sortedNonNullYears)
  );
  const usedDueDateYears = Array.from(uniqueUsedDueDateYears);

  return {
    accessorKey: TodosListColumnKeys.DueDate,
    header: "Fälligkeitsdatum",
    enableGrouping: false,
    Cell: ({ renderedCellValue, row }) => {
      const dueDateOrNull = backendDateOrDateTimeToLuxonDateTimeOrNull(
        row.original.dueDate
      );
      const isOverdue =
        row.original.overdue ||
        ((dueDateOrNull && dueDateOrNull.valueOf() < now) ?? false);
      return (
        <div className="cell-due-date">
          <Group gap={5} wrap="nowrap">
            {isOverdue ? (
              <>
                <Icon className="overdue" name={IconName.Warning} />
                <Text ml="xs">
                  Überfällig{" "}
                  {row.original.dueDate && "(" + row.original.dueDate + ")"}
                </Text>
              </>
            ) : (
              renderedCellValue && (
                <>
                  <Icon name={IconName.Calendar} />
                  <Text ml="xs">{renderedCellValue}</Text>
                </>
              )
            )}
          </Group>
        </div>
      );
    },
    filterVariant: "select",
    filterFn: (row, _, filterValue) => {
      if (filterValue === TODO_OVERDUE) {
        const dueDateOrNull = backendDateOrDateTimeToLuxonDateTimeOrNull(
          row.original.dueDate
        );
        const isOverdue =
          row.original.overdue ||
          ((dueDateOrNull && dueDateOrNull.valueOf() < now) ?? false);
        return isOverdue;
      } else if (filterValue === TODO_DUE_DATE_EMPTY) {
        return row.original.dueDate === null;
      } else {
        return row.original.dueDate
          ? backendDateOrDateTimeToLuxonDateTimeOrNull(
              row.original.dueDate
            )?.year.toString() === filterValue
          : false;
      }
    },
    mantineFilterSelectProps: {
      data: (isPremium
        ? [
            { value: TODO_DUE_DATE_EMPTY, label: "(leer)" },
            { value: TODO_OVERDUE, label: "Überfällig" }
          ]
        : [{ value: TODO_DUE_DATE_EMPTY, label: "(leer)" }]
      ).concat(
        usedDueDateYears.map((dueDate) => ({
          value: dueDate.toString(),
          label: dueDate.toString()
        }))
      ),
      searchable: false,
      dropdownOpened: true
    }
  };
}
