/** Splits a file name with extension into a an array containing the name without extension and the extension. */
export function splitNameExtension(
  nameWithExtension: string
): [string, string] {
  // file.xlsx => [file, xlsx]
  // file => [file, ""]
  // dots.in.file.name.docx => [dots.in.file.name, docx]
  // .hidden-file => [.hidden-file, ""]
  const indexOfLastDot = (nameWithExtension.lastIndexOf(".") - 1) >>> 0;
  return [
    nameWithExtension.slice(0, indexOfLastDot + 1),
    nameWithExtension.slice(indexOfLastDot + 2)
  ];
}
