import api from "../../../api";
import urls from "../../../urls";
import type { GeneratorChangesFromMastr } from "../../../utils/backend-types";

export async function updateGeneratorWithChangesFromMastr(
  generatorId: number,
  generatorChangesFromMastr: GeneratorChangesFromMastr[]
) {
  return api.put(urls.api.updateGeneratorWithChangesFromMastr(generatorId), {
    changes: generatorChangesFromMastr
  });
}
