import { DateTime } from "luxon";
import React, { useCallback, useMemo, useState } from "react";
import { IconName } from "../BuildingBlocks/Icon/types";
import { showBasicConfirmationPopup } from "../BuildingBlocks/Layout/Modals/BasicConfirmationModal/BasicConfirmationModal";
import { Button } from "../Buttons/Button/Button";
import { IconButton } from "../Buttons/IconButton/IconButton";
import { SpinButton } from "../Buttons/SpinButton/SpinButton";
import type { FormErrors } from "../CustomForm/useCustomForm";
import { FreeTextField } from "../EdaVisualizationModal/EdaDeliveryModel/FreeTextField/FreeTextField";
import type {
  ChangeableFieldTableOptions,
  ChangeableFieldTableValueColumnsData
} from "./ChangeableFieldTable/ChangeableFieldTable";
import { ChangeableFieldTable } from "./ChangeableFieldTable/ChangeableFieldTable";
import "./ChangeableFieldWidget.scss";
import { addNewChangeableFieldValue } from "./utils/addNewChangeableFieldValue";
import { updateChangeableFieldValue } from "./utils/updateChangeableFieldValue";

export interface ChangeableFieldData<T = number> {
  startDate: DateTime | null;
  endDate: DateTime | null;
  value: T | null;
  id?: number;
}

export interface ChangeableFieldWidgetOptions
  extends ChangeableFieldTableOptions {
  addButtonText?: string;
  saveButtonText?: string;
  addNullStartDateWhenNoValues?: boolean;
  canAddFutureDates?: boolean;
  withFreeTextField?: boolean;
  hideAddButtonAfterFirstEntry?: boolean;
  hideCancelButton?: boolean;
}

export interface ChangeableFieldWidgetProps<T> {
  initialValues: Array<ChangeableFieldData<T>>;
  dataName: string;
  options?: ChangeableFieldWidgetOptions;
  confirmationOnSaveElement?: React.ReactNode;
  confirmationOnSaveCancellable?: boolean;
  valueColumns?: Array<ChangeableFieldTableValueColumnsData<T>>;
  initialFreeTextFieldValue?: string;
  onCancel: () => void;
  onDone: (
    newValues: Array<ChangeableFieldData<T | null>>,
    freeTextFieldValue?: string
  ) => Promise<void>;
  errors?: FormErrors;
}

/** Manages time-changeable fields of type T (default number)
 *
 * If T is specified, `valueColumns` must also be specified.
 */
function ChangeableFieldWidget<T>({
  initialValues,
  dataName,
  options = {},
  confirmationOnSaveElement,
  confirmationOnSaveCancellable = true,
  valueColumns,
  initialFreeTextFieldValue,
  onCancel,
  onDone,
  errors
}: ChangeableFieldWidgetProps<T>) {
  const [values, setValues] =
    useState<Array<ChangeableFieldData<T | null>>>(initialValues);
  const [isSaving, setIsSaving] = useState(false);

  const [freeTextFieldValue, setFreeTextFieldValue] = useState(
    initialFreeTextFieldValue
  );

  const isButtonHidden =
    values.length !== 0 && options.hideAddButtonAfterFirstEntry;

  function handleAddValue() {
    setValues((currentValues) =>
      addNewChangeableFieldValue(currentValues, options)
    );
  }

  const handleUpdateValue = useCallback(
    (index: number, updatedValue: ChangeableFieldData<T | null>) => {
      setValues((currentValues) =>
        updateChangeableFieldValue(updatedValue, index, currentValues)
      );
    },
    [setValues]
  );

  const handleDeleteValue = useCallback(
    (index: number) => {
      setValues((currentValues) => {
        const newValues = [...currentValues];
        const startDateOfDeleted = newValues[index].startDate;

        if (newValues[index + 1]) {
          newValues[index + 1] = {
            ...newValues[index + 1],
            startDate: startDateOfDeleted
          };
        } else if (index === newValues.length - 1 && index > 0) {
          newValues[index - 1] = {
            ...newValues[index - 1],
            endDate: null
          };
        }

        newValues.splice(index, 1);

        return newValues;
      });
    },
    [setValues]
  );

  function handleClickSave(alreadyConfirmed = false) {
    if (!!confirmationOnSaveElement && !alreadyConfirmed) {
      showBasicConfirmationPopup({
        text: confirmationOnSaveElement,
        onConfirm: () => handleClickSave(true),
        onCancel: confirmationOnSaveCancellable
          ? () => setIsSaving(false)
          : undefined
      });

      return;
    }

    setIsSaving(true);
    onDone(values, freeTextFieldValue).then(() => setIsSaving(false));
  }

  const yesterday = DateTime.now().plus({ days: -1 }).startOf("day");
  const lastStartDate =
    values.length > 0 ? values[values.length - 1].startDate : null;
  const canAddValue =
    !lastStartDate ||
    lastStartDate.startOf("day") < yesterday ||
    options.canAddFutureDates;
  const canSave = values.reduce((canSave, value, index) => {
    const isFirstValue = index === 0;
    const validStartDate =
      !!value.startDate || (isFirstValue && !value.startDate);
    const isLastValue = index === values.length - 1;
    const validEndDate = !!value.endDate || (isLastValue && !value.endDate);

    return canSave && validStartDate && validEndDate;
  }, true);

  const memoizedChangeableFieldTable = useMemo(
    () => (
      <ChangeableFieldTable
        dataName={dataName}
        errors={errors}
        tableOptions={options}
        valueColumns={valueColumns}
        values={values}
        onDeleteValue={handleDeleteValue}
        onUpdateValue={handleUpdateValue}
      />
    ),
    [
      dataName,
      errors,
      options,
      valueColumns,
      values,
      handleDeleteValue,
      handleUpdateValue
    ]
  );
  return (
    <div className="ChangeableFieldWidget">
      {memoizedChangeableFieldTable}
      {!isButtonHidden && (
        <IconButton
          color="brand"
          disabled={!canAddValue}
          iconName={IconName.PlusCircle}
          id="add-changeable-field-button"
          style={{ marginTop: "20px" }}
          onClick={handleAddValue}
        >
          {options.addButtonText ?? "Eintragen"}
        </IconButton>
      )}
      {options.withFreeTextField && (
        <FreeTextField
          value={freeTextFieldValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFreeTextFieldValue(e.target.value)
          }
        />
      )}
      <div className="save-cancel-buttons">
        <SpinButton
          color="primary"
          disabled={!canSave}
          spin={isSaving}
          onClick={() => handleClickSave()}
        >
          {!options.saveButtonText ? "Speichern" : options.saveButtonText}
        </SpinButton>
        {!options.hideCancelButton && (
          <Button
            color="secondary"
            onClick={() => {
              setValues(initialValues);
              onCancel();
            }}
          >
            Abbrechen
          </Button>
        )}
      </div>
    </div>
  );
}

export { ChangeableFieldWidget };
