import api from "../../../api";
import urls from "../../../urls";

export async function getChangesFromMastr(generatorId: number) {
  const { data } = await api.get<{
    taskStatusUrl: string;
    taskIds: Array<string>;
  }>(urls.api.getChangesFromMastr(generatorId));

  return data;
}
