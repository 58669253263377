import type { Todo } from "../../utils/backend-types";

export interface SiteOverviewSite {
  id: number;
  projectIsActive: boolean;
  meteringConceptRequirementsMet: boolean;
  name: string;
  customer: string | null;
  customers: Array<string>;
  onboardingTodos: Array<OnboardingTodo>;
  siteDataAssessment: DataAssessment;
  project: string;
  projectId: string;
  projectIsDemo: boolean;
  meteringConceptType: MeteringConceptType;
  siteCategories: Array<SiteCategory>;
}

export interface SiteStatusSite extends SiteOverviewSite {
  siteLabels: Array<SiteLabel>;
  logs: SiteStatusLogs;
  meteringConceptInfo: MeteringConceptInfo;
}

export interface OnboardingTodo extends Pick<Todo, "label" | "status"> {
  group: OnboardingTodosGroup | null;
}

export interface DataAssessment {
  gridMeterSufficient: boolean | null;
  generatorsSufficient: boolean | null;
  makoMeterDataIsSufficient: boolean | null;
  subMeterDataIsSufficient: boolean | null;
}
/** Duplicated from optinode/webserver/metering_concept_creation/enums.py */
export enum MeteringConceptType {
  Gold = "Gold-Standard",
  NoScada = "no-SCADA",
  Missing = "-"
}

export enum SiteCategory {
  Volleinspeisung = "Voll",
  PvDirekt = "PV-Direkt",
  EigenerzeugungPlus = "EE+",
  Premium = "Premium",
  PPAAAS = "PPA-as-a-Service"
}

export interface SiteLabel {
  name: string;
  description: string;
}

export interface SiteStatusLogs {
  infos: Array<string>;
  warnings: Array<string>;
  errors: Array<string>;
}

export enum OnboardingTodosGroup {
  MeteringConceptCreationRequirement = "METERING_CONCEPT_CREATION_REQUIREMENT",
  MeteringConceptCreationStatus = "METERING_CONCEPT_CREATION_STATUS"
}

export interface MeteringConceptInfo {
  executionState: string;
  errorMessages: Array<string>;
}
