import api from "../../../../../api";
import urls from "../../../../../urls";
import { MeasurementType, ObjectName } from "../../../../../utils/enums";
import { CounterDataView } from "../../../../CounterDataView/CounterDataView";
import { loadData, saveDataBasic } from "./common";
import type { Form, FormWithMeteringData } from "./data-types";

const HIDDEN_FIELDS_IF_NULL = [];

const VISIBLE_FIELDS = [
  "name",
  "pressureStage",
  "gasQuality",
  "connectingParty"
];

const GAS_CONNECTION_EDIT_TABS = {
  TAB_GENERAL: "general",
  TAB_METERING_DATA: "messwerte"
};

export function saveGasConnectionData(gasConnectionId, forms) {
  return saveDataBasic(ObjectName.GasConnection, gasConnectionId, [
    forms[0]
  ]).then((result) => {
    const finalResult = {
      ...result,
      forms: [...result.forms, ...forms.slice(1)]
    };

    const counterUpdateRequestData = forms[1].data;
    const promises = Object.keys(counterUpdateRequestData).map((counterId) => {
      return api.patch(
        urls.api.counterDataForGasConnection(parseInt(counterId, 10)),
        counterUpdateRequestData[counterId]
      );
    });

    return Promise.all(promises)
      .catch((error) => {
        finalResult.serverError = error;
        finalResult.didError = true;
      })
      .then(() => {
        return Promise.resolve(finalResult);
      });
  });
}

export function loadGasConnectionData(
  queryClient,
  gasConnectionId,
  siteId,
  variantId
) {
  return loadData({
    queryClient,
    objectName: ObjectName.GasConnection,
    objectId: gasConnectionId,
    siteId,
    variantId,
    visibleFields: VISIBLE_FIELDS,
    hiddenFieldsIfNull: HIDDEN_FIELDS_IF_NULL
  }).then((forms: Array<Form>) => {
    forms[0].formTitle = "Stammdaten";
    forms.push({
      name: GAS_CONNECTION_EDIT_TABS.TAB_METERING_DATA,
      formTitle: "Messwerte",
      Component: CounterDataView,
      extraProps: {
        isGasConnectionDataView: true,
        variantId: variantId,
        meterId: gasConnectionId,
        measurementType: MeasurementType.Single,
        siteId: siteId,
        objectName: ""
      },
      data: {},
      sections: []
    } as FormWithMeteringData);

    return Promise.resolve(forms);
  });
}
