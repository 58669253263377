import { useEffect, useState } from "react";

import api from "../api";
import urls from "../urls";
import type { Meter, Site } from "../utils/backend-types";
import { useVariantSites } from "./useVariantSites";

interface VariantMetersReturnValue {
  meters: Array<Meter>;
  sites: Array<Site>;
  isLoading: boolean;
  error: unknown;
}

function useVariantMeters(variantId: number): VariantMetersReturnValue {
  const {
    isLoading: isSitesListLoading,
    error: sitesListError,
    sites
  } = useVariantSites(variantId);
  const [meters, setMeters] = useState<Array<Meter>>([]);
  const [metersAreLoading, setMetersAreLoading] = useState(true);
  const [metersError, setMetersError] = useState<unknown | null>(null);

  useEffect(() => {
    if (!isSitesListLoading && sites.length === 0) {
      setMeters([]);
      setMetersAreLoading(false);
      setMetersError(null);
    } else if (sites.length > 0) {
      const promises = sites.map((site) => fetchMetersForSite(site.id));
      setMetersAreLoading(true);

      Promise.all(promises)
        .then((listOfMetersList) => {
          const uniqueMeters = listOfMetersList.reduce((meters, meterList) => {
            return meterList.concat(meters);
          }, []);

          setMeters(uniqueMeters);
          setMetersAreLoading(false);
          setMetersError(null);
        })
        .catch(setMetersError);
    }
  }, [sites, isSitesListLoading]);

  async function fetchMetersForSite(siteId: number) {
    const response = await api.get<Array<Meter>>(urls.api.meters(siteId));
    return response.data;
  }

  return {
    isLoading: isSitesListLoading || metersAreLoading,
    error: sitesListError || metersError,
    meters: meters,
    sites: sites
  };
}

export { useVariantMeters };
