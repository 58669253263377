/* eslint-disable @typescript-eslint/no-explicit-any */
// https://gist.github.com/mariocesar/e96f6cf6cb2db213173a9c08b9a9867a
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosRequestTransformer,
  AxiosResponse,
  AxiosResponseTransformer,
  Cancel,
  InternalAxiosRequestConfig,
  Method
} from "axios";
import axios, { isCancel as axiosIsCancel } from "axios";
import MockAdapter from "axios-mock-adapter";
import { jwtDecode } from "jwt-decode";
import { refresh } from "./components/Login/utils/refresh";
import { PUBLIC_ROUTES } from "./routes";
import { decamelizeWithoutBreakingFile } from "./utils/decamelizeWithoutBreakingFile";
import { camelizeKeys } from "./utils/SnakeCamelConversion";

export type ApiResponse<T = any, D = any> = AxiosResponse<T, D>;
export interface ApiError<T = unknown, D = any> extends AxiosError<T, D> {
  config?: InternalAxiosRequestConfig<D> & { _isRetry?: boolean };
}

interface MockOptions {
  delayResponse?: number;
}

enum CustomHeaders {
  X_Requested_With = "X-Requested-With",
  Authorization = "Authorization"
}

interface AxiosRequestHeadersExtended extends AxiosRequestHeaders {
  [CustomHeaders.X_Requested_With]?: string;
  [CustomHeaders.Authorization]?: string;
}

interface AccessTokenDecoded {
  exp: number;
  iat: number;
  user_id: number;
}

interface AccessToken {
  value: string;
  expiry: number;
  issuedAt: number;
  expiryInClientTime: number;
}

declare const BACKEND_BASE_URL: string | undefined;

const BASE_URL =
  BACKEND_BASE_URL || `${window.location.protocol}//${window.location.host}/`;
const WITH_CREDENTIALS = typeof BACKEND_BASE_URL === "string";
const MAX_REQUESTS_COUNT = 12;
const INTERVAL_MS = 10;

let accessToken: AccessToken | undefined;
let pendingRequests = 0;
let mockSession: MockAdapter;
let mockSessionWithoutCamelization: MockAdapter;

if (BACKEND_BASE_URL !== undefined) {
  console.info(`backend base URL: ${BACKEND_BASE_URL}`);
}

function throttleRequests<T>(config: T) {
  return new Promise<T>((resolve) => {
    const interval = setInterval(() => {
      if (pendingRequests < MAX_REQUESTS_COUNT) {
        pendingRequests++;
        clearInterval(interval);
        resolve(config);
      }
    }, INTERVAL_MS);
  });
}

function onResponseFulfilled<T>(response: T) {
  pendingRequests = Math.max(0, pendingRequests - 1);
  return Promise.resolve(response);
}

function onResponseRejected(error: ApiError) {
  pendingRequests = Math.max(0, pendingRequests - 1);
  return Promise.reject(error);
}

async function refreshOnResponseRejected(error: ApiError) {
  const initialRequest = error.config;

  // if unauthorized - send request to refresh token
  if (
    error.response?.status === 401 &&
    (error as ApiError<{ code: string }>).response?.data?.code ===
      "token_not_valid" &&
    initialRequest &&
    !error.config?._isRetry
  ) {
    try {
      const success = await refresh();

      if (success) {
        initialRequest.headers = {
          ...initialRequest.headers,
          [CustomHeaders.Authorization]: `Bearer ${getAccessTokenValue()}`
        } as AxiosRequestHeadersExtended;
      }

      return axios(initialRequest); // triggering initial request one more time
    } catch (_) {
      setAccessToken("");
    }
  }

  return Promise.reject(error);
}

const instance = axios.create({
  baseURL: BASE_URL,
  withCredentials: WITH_CREDENTIALS,
  transformResponse: [
    ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
    (data) => camelizeKeys(data)
  ],
  transformRequest: [
    (data) => decamelizeWithoutBreakingFile(data),
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[])
  ]
});

instance.interceptors.request.use(throttleRequests);
instance.interceptors.response.use(onResponseFulfilled, onResponseRejected);
instance.interceptors.response.use((config) => {
  return config;
}, refreshOnResponseRejected);

const withoutCamelizationInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: WITH_CREDENTIALS
});

withoutCamelizationInstance.interceptors.request.use(throttleRequests);
withoutCamelizationInstance.interceptors.response.use(
  onResponseFulfilled,
  onResponseRejected
);
withoutCamelizationInstance.interceptors.response.use((config) => {
  return config;
}, refreshOnResponseRejected);

function getAccessTokenValue() {
  if (accessToken) {
    return accessToken.value;
  } else {
    return "";
  }
}

function setAccessToken(token?: string) {
  if (token) {
    const { exp, iat } = jwtDecode<AccessTokenDecoded>(token);
    const now = Math.floor(Date.now() / 1000);
    /*
      The tokens have an expiry datetime set. The client can use this to determine whether a token is still valid or
      not. However, the client's time might not be in sync with the server's time. Therefore, we need to calculate
      the time drift between the client and the server and adjust the expiry time accordingly.

      The token also has the issued datetime set. We can compare this to the clients datetime and compute an offset
      between server and client (neglecting the network latency).

      Positive values between client times (now) and server times (iat) mean that the client is ahead of the server.
      Hence, for the expiry in the client's time, we need to add the time drift to the servers expiry time (exp),
      which is in the server's time.
      */

    const clientServerTimeDrift = now - iat;
    const expiryInClientTime = exp + clientServerTimeDrift;

    accessToken = {
      value: token,
      expiry: exp,
      issuedAt: iat,
      expiryInClientTime: expiryInClientTime
    };
    console.debug(
      "Got new token, time drift between server and client",
      clientServerTimeDrift
    );
  } else {
    accessToken = undefined;
  }
}

function isAccessTokenValid() {
  if (!accessToken) {
    return false;
  }

  const now: number = Math.floor(Date.now() / 1000);
  // assume that the token is already invalid if it expires in less than 10 seconds
  // this is to reduce the likelihood of performing requests with an expired token
  // the actual expiration might be sooner because of the network latency

  return now < accessToken.expiryInClientTime - 10;
}

async function checkAuthMiddleware() {
  if (mockSession || mockSessionWithoutCamelization) {
    return true;
  }

  if (
    Object.values(PUBLIC_ROUTES).some((route) =>
      new RegExp(route).test(location.pathname)
    )
  ) {
    return false;
  }

  if (isAccessTokenValid()) {
    return true;
  } else {
    return refresh();
  }
}

function getRequestFunction(camelization: boolean) {
  return async function request<T>(
    method: Method,
    url: string,
    data?,
    config?: AxiosRequestConfig
  ) {
    const isAuthenticated = await checkAuthMiddleware();

    if (!isAuthenticated) {
      throw new Error("isAuthenticated error");
    }

    return (camelization ? instance : withoutCamelizationInstance).request<T>({
      ...config,
      method,
      url,
      data,
      headers: {
        ...config?.headers,
        [CustomHeaders.X_Requested_With]: "XMLHttpRequest",
        [CustomHeaders.Authorization]: `Bearer ${getAccessTokenValue()}`
      }
    });
  };
}

const request = getRequestFunction(true);

function getPublicRequestFunction(camelization: boolean) {
  return function publicRequest<T>(
    method: Method,
    url: string,
    data?,
    config?: AxiosRequestConfig
  ) {
    return (camelization ? instance : withoutCamelizationInstance).request<T>({
      ...config,
      method,
      url,
      data,
      headers: {
        [CustomHeaders.X_Requested_With]: "XMLHttpRequest"
      }
    });
  };
}

function getGetFunction(camelization: boolean) {
  return function get<T = any>(url: string, config?: AxiosRequestConfig) {
    return getRequestFunction(camelization)<T>("GET", url, undefined, config);
  };
}

function getPostFunction(camelization: boolean) {
  return function post<T = any>(
    url: string,
    data?,
    config?: AxiosRequestConfig
  ) {
    return getRequestFunction(camelization)<T>("POST", url, data, config);
  };
}

function getPutFunction(camelization: boolean) {
  return function putRequest<T = any>(
    url: string,
    data?,
    config?: AxiosRequestConfig
  ) {
    return getRequestFunction(camelization)<T>("PUT", url, data, config);
  };
}

function getPatchFunction(camelization: boolean) {
  return function patch<T = any>(
    url: string,
    data?,
    config?: AxiosRequestConfig
  ) {
    return getRequestFunction(camelization)<T>("PATCH", url, data, config);
  };
}

function getOptionsFunction(camelization: boolean) {
  return function optionsRequest<T = any>(
    url: string,
    config?: AxiosRequestConfig
  ) {
    return getRequestFunction(camelization)<T>(
      "OPTIONS",
      url,
      undefined,
      config
    );
  };
}

function getDeleteFunction(camelization: boolean) {
  return function deleteRequest<T = any>(
    url: string,
    data?,
    config?: AxiosRequestConfig
  ) {
    return getRequestFunction(camelization)<T>("DELETE", url, data, config);
  };
}

function isCancel(value: Cancel) {
  return axiosIsCancel(value);
}

function getMockFunction(camelization: boolean) {
  return function mock(options: MockOptions = {}) {
    const { delayResponse } = options;

    if (camelization) {
      if (!mockSession) {
        mockSession = new MockAdapter(instance, {
          delayResponse: delayResponse ?? 500,
          onNoMatch: "throwException"
        });
      }

      return mockSession;
    } else {
      if (!mockSessionWithoutCamelization) {
        mockSessionWithoutCamelization = new MockAdapter(
          withoutCamelizationInstance,
          {
            delayResponse: delayResponse ?? 500,
            onNoMatch: "throwException"
          }
        );
      }

      return mockSessionWithoutCamelization;
    }
  };
}

const api = {
  delete: getDeleteFunction(true),
  get: getGetFunction(true),
  isAccessTokenValid,
  checkAuthMiddleware,
  isCancel,
  mock: getMockFunction(true),
  options: getOptionsFunction(true),
  patch: getPatchFunction(true),
  post: getPostFunction(true),
  publicRequest: getPublicRequestFunction(true),
  put: getPutFunction(true),
  request,
  setAccessToken
};

const apiWithoutCamelization = {
  delete: getDeleteFunction(false),
  get: getGetFunction(false),
  mock: getMockFunction(false),
  options: getOptionsFunction(false),
  patch: getPatchFunction(false),
  post: getPostFunction(false),
  publicRequest: getPublicRequestFunction(false),
  put: getPutFunction(false),
  request: getRequestFunction(false)
};

export default api;
export { apiWithoutCamelization };
