import React, { useState } from "react";
import ReactDOM from "react-dom";
import { type SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import api from "../../../../api";
import urls from "../../../../urls";
import type {
  CreateManagerProject,
  ProjectMinimal
} from "../../../../utils/backend-types";
import { FormFieldController } from "../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { setErrorsFromResponseData } from "../../../BuildingBlocks/Forms/utils/setErrorsFromResponseData";
import { Button } from "../../../Buttons/Button/Button";
import { SpinButton } from "../../../Buttons/SpinButton/SpinButton";
import { CREATE_MANAGER_PROJECT_FORM_FIELD_DATA } from "./CreateManagerProjectForm.constants";

const DEFAULT_VALUES = {
  demo: false
};

type CreateManagerProjectFormProps = {
  buttonContainer?: HTMLElement;
  onCancel: () => void;
  onSubmit: (project: ProjectMinimal) => void;
};

function CreateManagerProjectForm({
  buttonContainer,
  onCancel,
  onSubmit
}: CreateManagerProjectFormProps) {
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setError,
    watch
  } = useForm<CreateManagerProject>({
    defaultValues: DEFAULT_VALUES
  });
  const isDemo = watch("demo");
  const formFieldData = CREATE_MANAGER_PROJECT_FORM_FIELD_DATA;
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onValidSubmit: SubmitHandler<CreateManagerProject> = async (data) => {
    try {
      const url = urls.api.managerProjectsCreate();
      const response = await api.post(url, data);
      onSubmit(response.data);
      return response;
    } catch (error) {
      setErrorsFromResponseData<CreateManagerProject>(
        error,
        watch(),
        setError,
        t("errors.UnknownError")
      );
    }
  };

  const submitCallback = handleSubmit(onValidSubmit);

  async function handleClickSubmit() {
    setIsSubmitting(true);

    try {
      await submitCallback();
    } finally {
      setIsSubmitting(false);
    }
  }

  function handleClickCancel() {
    reset();
    onCancel();
  }

  const Buttons = (
    <>
      <Button onClick={handleClickCancel}>Abbrechen</Button>
      <SpinButton
        color="primary"
        spin={isSubmitting}
        onClick={handleClickSubmit}
      >
        Erstellen
      </SpinButton>
    </>
  );

  return (
    <form className="CreateManagerProjectForm" onSubmit={submitCallback}>
      <FormFieldController
        control={control}
        data={formFieldData.name}
        error={errors.name}
      />
      <FormFieldController
        control={control}
        data={formFieldData.demo}
        error={errors.demo}
      />
      <FormFieldController
        control={control}
        data={{
          ...formFieldData.customer,
          required: !isDemo
        }}
        error={errors.customer}
      />
      {buttonContainer
        ? ReactDOM.createPortal(Buttons, buttonContainer)
        : Buttons}
    </form>
  );
}

export { CreateManagerProjectForm, CreateManagerProjectFormProps };
