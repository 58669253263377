import { useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useActiveCompanies } from "../../../../hooks/useActiveCompanies";
import { useContainerRef } from "../../../../hooks/useContainerRef";
import { useShouldFieldBeHighlighted } from "../../../../hooks/useShouldFieldBeHighlighted";
import type { Contract, Payment, Site } from "../../../../utils/backend-types";
import { PaymentIntervalAndBillingMethod } from "../../../../utils/backend-types";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../../BuildingBlocks/Layout/Modal/Modal";
import { LoadOrError } from "../../../LoadOrError/LoadOrError";
import { ContractForm } from "./ContractForm/ContractForm";
import "./ContractModal.scss";
import { EditForm } from "./EditForm/EditForm";
import { useContract } from "./hooks/useContract";
import { useContractFormOptions } from "./hooks/useContractFormOptions";
import type { PaymentDate } from "./Tabs/PaymentsTab";

interface ContractModalProps {
  contractId?: string;
  variantId: number;
  withTemplate?: boolean;
  templateContracts?: Array<Contract>;
  sites?: Array<Site>;
  onSubmit: () => void;
  onClose: () => void;
}

function ContractModal({
  contractId,
  variantId,
  onSubmit,
  onClose,
  withTemplate,
  templateContracts,
  sites
}: ContractModalProps) {
  const mode: "create" | "edit" = contractId ? "edit" : "create";
  const queryClient = useQueryClient();
  const { containerNode, containerRef } = useContainerRef();
  const {
    data: contractFormOptions,
    isLoading: isContractFormOptionsLoading,
    error: contractFormOptionsError
  } = useContractFormOptions(variantId);
  const { data: activeCompanies } = useActiveCompanies(variantId, {
    enabled: withTemplate && mode === "create"
  });
  const {
    isContractDataLoading,
    contractLoadingError,
    contract,
    createContract,
    updateContract
  } = useContract(contractId);
  const [paymentsData, setPaymentsData] = useState<Array<Payment> | undefined>(
    contract?.payments
  );

  const isMonthlyBilled =
    contract?.billingMethodAndPeriod ===
    PaymentIntervalAndBillingMethod.MonthlyBasedOnMeasurements;

  useEffect(() => {
    if (contract?.payments) {
      setPaymentsData(contract?.payments);
    }
  }, [contract?.payments, setPaymentsData]);

  const updatePaymentsValue = useCallback(
    (value: number | string | null, date: PaymentDate, objKey: string) => {
      setPaymentsData((oldPaymentsData) => {
        if (oldPaymentsData) {
          const index = oldPaymentsData.findIndex(
            (pd) => pd.year === date.year && pd.month === date.month
          );

          if (index >= 0) {
            const newObj = { ...oldPaymentsData[index], [objKey]: value };
            const newPaymentsData = [...oldPaymentsData];

            newPaymentsData[index] = newObj;
            return newPaymentsData;
          }
        }
        return oldPaymentsData;
      });
    },
    []
  );

  function addPayment(hasRlmDeliveries: boolean) {
    if (paymentsData && contract) {
      const contractBegin = backendDateOrDateTimeToLuxonDateTime(
        contract.begin
      );
      const now = new Date();

      const previousYearOrContractBeginning = Math.max(
        contractBegin.year,
        now.getFullYear() - 1
      );

      const previousMonthOrContractBeginning =
        contractBegin.toJSDate() > now
          ? contractBegin.month
          : (now.getMonth() - 1) % 12;

      const latestYear: number =
        paymentsData.sort((a, b) => b.year - a.year)[0]?.year ||
        previousYearOrContractBeginning - 1;

      const latestMonth: number =
        paymentsData
          .filter((pd) => pd.year === latestYear)
          .sort((a, b) =>
            a.month !== null && b.month !== null ? b.month - a.month : 0
          )[0]?.month ||
        (paymentsData
          .filter((pd) => pd.year === latestYear)
          .find((pd) => pd.month === null) !== undefined
          ? 12
          : previousMonthOrContractBeginning) ||
        previousMonthOrContractBeginning;

      /* if yearly based: only rows for years (month null) */
      /* if monthly based with rlm delivery: rows for months + row for year (no month) */
      /* if monthly based without rlm delivery: only rows for months */
      const newPayment: Payment = {
        contract: contract.id,
        year: isMonthlyBilled
          ? latestMonth === 12
            ? latestYear + 1
            : latestYear
          : latestYear + 1,
        month: isMonthlyBilled
          ? latestMonth === 12
            ? 1
            : latestMonth + 1
          : null,
        estimatedAdvancePayments: null,
        madeAdvancePayments: null,
        pendingPayments: null,
        pendingPaymentsDescription: null,
        discountPayments: null,
        discountDescription: null,
        deliveryAmountPredictionThisYear: null,
        directDeliverySharePrediction: null
      };

      let newPaymentsData: Array<Payment>;
      if (isMonthlyBilled && hasRlmDeliveries && latestMonth + 1 === 12) {
        const newExtraPayment: Payment = {
          contract: contract.id,
          year: latestYear,
          month: null,
          estimatedAdvancePayments: null,
          madeAdvancePayments: null,
          pendingPayments: null,
          pendingPaymentsDescription: null,
          discountPayments: null,
          discountDescription: null,
          deliveryAmountPredictionThisYear: null,
          directDeliverySharePrediction: null
        };
        newPaymentsData = [newExtraPayment, newPayment, ...paymentsData];
      } else {
        newPaymentsData = [newPayment, ...paymentsData];
      }

      setPaymentsData(newPaymentsData);
      queryClient.setQueryData(
        ["payments-data", { contractId: contract.id }],
        newPaymentsData
      );
    }
  }

  async function handleSubmitCreateContract(
    contract: Contract,
    variantId: number
  ) {
    try {
      const response = await createContract({
        ...contract,
        payments: [],
        variant: variantId
      });

      onSubmit();

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function handleSubmitEditContract(contract: Contract) {
    const response = await updateContract({
      ...contract,
      payments: paymentsData || []
    });

    onSubmit();

    return response;
  }

  const editFormIsLoading =
    isContractDataLoading || isContractFormOptionsLoading;
  const editFormError = contractLoadingError || contractFormOptionsError;
  const { state: missingData } = useLocation();
  const missingFields =
    (missingData && missingData.missingFields.general) || [];
  const shouldFieldBeHighlighted = useShouldFieldBeHighlighted(missingFields);

  return (
    <Modal className="ContractModal" isOpen size="xl" toggle={onClose}>
      <ModalHeader toggle={onClose}>
        {contractId ? "Vertrag bearbeiten" : "Vertrag hinzufügen"}
      </ModalHeader>
      <ModalBody scrollable>
        {contractId ? (
          <LoadOrError error={editFormError} loading={editFormIsLoading}>
            {contract && contractFormOptions && (
              <EditForm
                addPayment={addPayment}
                buttonContainer={containerNode}
                contract={contract}
                missingFields={missingFields}
                options={contractFormOptions}
                paymentsData={paymentsData}
                shouldFieldBeHighlighted={shouldFieldBeHighlighted}
                updatePaymentsValue={updatePaymentsValue}
                variantId={variantId}
                onCancel={onClose}
                onSubmit={handleSubmitEditContract}
              />
            )}
          </LoadOrError>
        ) : (
          <LoadOrError
            error={contractFormOptionsError}
            loading={isContractFormOptionsLoading}
          >
            {contractFormOptions && (
              <ContractForm
                activeCompanies={activeCompanies}
                buttonContainer={containerNode}
                missingFields={missingFields}
                options={contractFormOptions}
                shouldFieldBeHighlighted={shouldFieldBeHighlighted}
                sites={sites}
                templateContracts={templateContracts}
                withTemplate={withTemplate}
                onCancel={onClose}
                onSubmit={(contract) =>
                  handleSubmitCreateContract(contract, variantId)
                }
              />
            )}
          </LoadOrError>
        )}
      </ModalBody>
      <ModalFooter>
        <div className="contract-modal-buttons" ref={containerRef} />
      </ModalFooter>
    </Modal>
  );
}

export { ContractModal, ContractModalProps };
