import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Icon } from "../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../BuildingBlocks/Icon/types";
import "./HeaderWithCustomSorting.scss";

interface HeaderWithCustomSortingProps {
  className?: string;
  children?: React.ReactNode;
  desc?: boolean;
  sortable: boolean;
}

function HeaderWithCustomSorting({
  className,
  children,
  desc,
  sortable
}: HeaderWithCustomSortingProps) {
  if (!children) {
    return null;
  }

  let sortingIcon = <Icon name={IconName.Unsorted} />;

  if (typeof desc !== "undefined") {
    if (desc) {
      sortingIcon = <Icon name={IconName.SortDesc} />;
    } else {
      sortingIcon = <Icon name={IconName.SortAsc} />;
    }
  }

  return (
    <div className={classNames("HeaderWithCustomSorting", className)}>
      <div className="header-wrapper">
        <div className="header-component">{children as string}</div>
        {sortable && <div className="sorting-icon">{sortingIcon}</div>}
      </div>
    </div>
  );
}

HeaderWithCustomSorting.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object
  ]),
  desc: PropTypes.bool,
  sortable: PropTypes.bool.isRequired
};

export { HeaderWithCustomSorting, HeaderWithCustomSortingProps };
