import { useQuery } from "@tanstack/react-query";

import type { DateTime } from "luxon";
import api from "../api";
import urls from "../urls";
import { backendDateOrDateTimeToLuxonDateTime } from "../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { luxonDateTimeToBackendDateOrDateTime } from "../utils/dates/luxonDateTimeToBackendDateOrDateTime";

function usePerformanceMonitoringCreationDates(projectId: string) {
  const {
    data: creationDates,
    isLoading,
    error
  } = useQuery({
    queryKey: ["performance-monitoring-creation-dates", { projectId }],
    queryFn: () => fetchPerformanceMonitoringCreationDates(projectId),
    refetchInterval: false,
    refetchOnWindowFocus: false
  });

  async function fetchPerformanceMonitoringCreationDates(
    projectId: string
  ): Promise<Array<DateTime>> {
    const url = urls.api.performanceMonitoringCreationDates(projectId);
    const response = await api.get<Array<string>>(url);
    return response.data.map(backendDateOrDateTimeToLuxonDateTime);
  }

  return {
    creationDates,
    isLoading,
    error
  };
}

function usePerformanceMonitoringOverview(projectId: string, date: DateTime) {
  const { data, isLoading, error } = useQuery({
    queryKey: ["performance-monitoring-overview", { projectId, date }],
    queryFn: () => fetchPerformanceMonitoringOverview(projectId, date),
    enabled: !!date,
    refetchInterval: false,
    refetchOnWindowFocus: false
  });

  async function fetchPerformanceMonitoringOverview(
    projectId: string,
    date: DateTime
  ): Promise<string> {
    const url = urls.api.performanceMonitoringOverview(
      projectId,
      luxonDateTimeToBackendDateOrDateTime(date)
    );
    const response = await api.get<string>(url);
    return response.data;
  }

  return {
    data,
    isLoading,
    error
  };
}

export {
  usePerformanceMonitoringCreationDates,
  usePerformanceMonitoringOverview
};
