import { buildExcelUri } from "./buildExcelUri";
import { convertToExcelWorkbook } from "./convertToExcelWorkbook";

export function downloadAsExcel(rows: Array<Array<string>>, filename: string) {
  const excelWorkbook = convertToExcelWorkbook(rows);
  const uri = buildExcelUri(excelWorkbook);
  const link = document.createElement("a");

  if (link.download !== undefined) {
    link.setAttribute("href", uri);
    link.setAttribute("download", `${filename}.xlsx`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
