import _ from "lodash";
import React from "react";

import { TodoStatus } from "../../../utils/backend-types";
import { makeArrayUnique } from "../../../utils/data-utils";
import { DropdownFilter } from "../../CustomReactTable/Filters/DropdownFilter/DropdownFilter";
import { DateRangePicker } from "../../DatePicker/DatePicker";
import {
  CATEGORIES,
  CLUSTERS,
  Mode,
  PRIORITIES,
  TODO_DOCUMENT_STATUS,
  UNKNOWN_USER
} from "../common";
import { getTodoStatusChoices } from "../utils/getTodoStatusChoices";

const CLUSTER_FILTER_CHOICES = Object.keys(CLUSTERS).reduce(
  (choices, categoryKey) => {
    const category = CLUSTERS[categoryKey];
    choices[category.value] = category.displayName;
    return choices;
  },
  {}
);

const PRIORITY_FILTER_CHOICES = Object.keys(PRIORITIES).reduce(
  (choices, priorityKey) => {
    const priority = PRIORITIES[priorityKey];
    choices[priority.value] = priority.displayName;
    return choices;
  },
  {}
);

const TODO_DOCUMENT_STATUS_FILTER_CHOICES = Object.keys(
  TODO_DOCUMENT_STATUS
).reduce((choices, todoStatusKey) => {
  choices[todoStatusKey] = TODO_DOCUMENT_STATUS[todoStatusKey].displayName;
  return choices;
}, {});

function buildTableFilters(
  todos,
  users,
  persons,
  responsiblePersons,
  firstDueDate,
  lastDueDate,
  disableFilters,
  mode
) {
  const labels = todos.map((todo) => todo.label);
  const fulfillFors = todos
    .map((todo) => todo.fulfillFor)
    .filter((fulfillFor) => !!fulfillFor);
  const areSomeTodosNotRelevant = todos.some(
    (todo) => todo.status === TodoStatus.NotRelevant
  );

  const labelFilter = (filterProps) => (
    <LabelFilter
      {...filterProps}
      disableFilters={disableFilters}
      labels={labels}
    />
  );

  const fulfillForFilter = (filterProps) => (
    <LabelFilter
      {...filterProps}
      disableFilters={disableFilters}
      labels={fulfillFors}
      showWithoutOption
    />
  );

  const projectFilter = (filterProps) => (
    <ProjectFilter
      {...filterProps}
      disableFilters={disableFilters}
      todos={todos}
    />
  );

  const clusterFilter = (filterProps) => (
    <ClusterFilter
      {...filterProps}
      disableFilters={disableFilters}
      mode={mode}
    />
  );

  const priorityFilter = (filterProps) => (
    <PriorityFilter
      {...filterProps}
      disableFilters={disableFilters}
      mode={mode}
    />
  );

  const personFilter = (filterProps) => (
    <PersonFilter
      {...filterProps}
      disableFilters={disableFilters}
      persons={persons}
    />
  );

  const dueDateFilter = (filterProps) => (
    <DueDateFilter
      {...filterProps}
      disableFilters={disableFilters}
      firstDueDate={firstDueDate}
      lastDueDate={lastDueDate}
    />
  );
  const responsibleFilter = (filterProps) => (
    <ResponsibleFilter
      {...filterProps}
      disableFilters={disableFilters}
      responsiblePersons={responsiblePersons}
      users={users}
    />
  );

  const statusFilter = (filterProps) => (
    <StatusFilter
      {...filterProps}
      areSomeTodosNotRelevant={areSomeTodosNotRelevant}
      disableFilters={disableFilters}
    />
  );

  const todoDocumentStatusFilter = (filterProps) => (
    <TodoDocumentStatusFilter
      {...filterProps}
      disableFilters={disableFilters}
    />
  );

  return {
    label: labelFilter,
    fulfillFor: fulfillForFilter,
    project: projectFilter,
    cluster: clusterFilter,
    priority: priorityFilter,
    person: personFilter,
    dueDate: dueDateFilter,
    responsible: responsibleFilter,
    status: statusFilter,
    documentProvided: todoDocumentStatusFilter
  };
}

function LabelFilter({
  filter,
  labels,
  showWithoutOption,
  disableFilters,
  onChange
}) {
  const uniqueLabels = makeArrayUnique(labels);
  uniqueLabels.sort((a, b) => a.localeCompare(b));

  const labelChoices = uniqueLabels.reduce((choices, label) => {
    choices[label] = label;
    return choices;
  }, {});

  return (
    <DropdownFilter
      choices={labelChoices}
      disabled={disableFilters}
      filter={filter}
      search
      showNoneOption
      showWithoutOption={showWithoutOption}
      onChange={onChange}
    />
  );
}

function ProjectFilter({ filter, todos, disableFilters, onChange }) {
  const projects = todos.map((todo) => todo.project);
  const uniqueProjects = makeArrayUnique(projects);
  uniqueProjects.sort((a, b) => a.localeCompare(b));

  const projectChoices = uniqueProjects.reduce((choices, project) => {
    choices[project] = project;
    return choices;
  }, {});

  return (
    <DropdownFilter
      choices={projectChoices}
      disabled={disableFilters}
      filter={filter}
      search
      showNoneOption
      onChange={onChange}
    />
  );
}

function ClusterFilter({ filter, disableFilters, mode, onChange }) {
  let choices = CLUSTER_FILTER_CHOICES;

  if (mode === Mode.Normal) {
    choices = Object.keys(CLUSTER_FILTER_CHOICES).reduce((choices, key) => {
      if (key !== CATEGORIES.ONBOARDING.value) {
        choices[key] = CLUSTER_FILTER_CHOICES[key];
      }

      return choices;
    }, {});
  } else if (mode === Mode.Onboarding) {
    choices = {
      [CATEGORIES.ONBOARDING.value]: CATEGORIES.ONBOARDING.displayName
    };
  }

  return (
    <DropdownFilter
      choices={choices}
      disabled={disableFilters}
      filter={filter}
      instantUpdates
      showNoneOption
      onChange={onChange}
    />
  );
}

function PriorityFilter({ filter, disableFilters, mode, onChange }) {
  let choices = PRIORITY_FILTER_CHOICES;

  if (mode === Mode.Normal) {
    choices = Object.keys(PRIORITY_FILTER_CHOICES).reduce((choices, key) => {
      choices[key] = PRIORITY_FILTER_CHOICES[key];
      return choices;
    }, {});
  } else if (mode === Mode.Onboarding) {
    choices = {
      [PRIORITIES.UNKNOWN.value]: PRIORITIES.UNKNOWN.displayName
    };
  }

  return (
    <DropdownFilter
      choices={choices}
      disabled={disableFilters}
      filter={filter}
      showNoneOption
      onChange={onChange}
    />
  );
}

function PersonFilter({ filter, persons, disableFilters, onChange }) {
  const personChoices = persons.reduce((choices, person) => {
    choices[person] = person;
    return choices;
  }, {});

  return (
    <DropdownFilter
      choices={personChoices}
      disabled={disableFilters}
      filter={filter}
      search
      showNoneOption
      showWithoutOption
      onChange={onChange}
    />
  );
}

function DueDateFilter({ firstDueDate, disableFilters, onChange }) {
  const onChangeDebounced = _.debounce(onChange, 500); // avoid calling filter on every key press

  return (
    <DateRangePicker
      disabled={disableFilters}
      endDatePlaceholderText="Bis"
      initialVisibleDate={firstDueDate}
      presets="all"
      showClearDates={false}
      startDatePlaceholderText="Von"
      onChange={onChangeDebounced}
    />
  );
}

function ResponsibleFilter({
  filter,
  responsiblePersons,
  users,
  disableFilters,
  onChange
}) {
  const responsibleChoices = responsiblePersons.reduce((choices, person) => {
    const personUser = users.find((user) => user.id === parseInt(person, 10));
    choices[person] = personUser ? personUser.name : UNKNOWN_USER;
    return choices;
  }, {});

  return (
    <DropdownFilter
      choices={responsibleChoices}
      disabled={disableFilters}
      filter={filter}
      search
      showNoneOption
      showWithoutOption
      onChange={onChange}
    />
  );
}

function StatusFilter({
  filter,
  areSomeTodosNotRelevant,
  disableFilters,
  onChange
}) {
  const choices = getTodoStatusChoices(
    areSomeTodosNotRelevant ? TodoStatus.NotRelevant : TodoStatus.Open
  );
  const choicesDict = choices.reduce((choicesDict, status) => {
    choicesDict[status.value] = status.displayName;
    return choicesDict;
  }, {});

  return (
    <DropdownFilter
      choices={choicesDict}
      disabled={disableFilters}
      filter={filter}
      instantUpdates
      onChange={onChange}
    />
  );
}

function TodoDocumentStatusFilter({ filter, disableFilters, onChange }) {
  return (
    <DropdownFilter
      choices={TODO_DOCUMENT_STATUS_FILTER_CHOICES}
      disabled={disableFilters}
      filter={filter}
      instantUpdates
      onChange={onChange}
    />
  );
}

export { buildTableFilters };
