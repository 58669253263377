import { Highlight } from "@mantine/core";
import React from "react";
import type { Column } from "react-table";
import { useDownload } from "../../../../../hooks/useDownload";
import urls from "../../../../../urls";
import { Icon } from "../../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../../BuildingBlocks/Icon/types";
import type { TypedCellInfo } from "../../../../CustomReactTable/CustomReactTable.types";
import type { Paragraph6FinancialDocument } from "../../../Paragraph6.types";
import { FinancialDocumentType } from "../../../Paragraph6.types";

function getDocumentColumn(highlightText = "") {
  return {
    Header: "Dokument",
    accessor: (row) => (
      <Highlight component="span" highlight={highlightText}>
        {row.document_name}
      </Highlight>
    ),
    id: "document",
    Cell: (cellInfo: TypedCellInfo<string, Paragraph6FinancialDocument>) => (
      <DocumentDownloadCell
        id={cellInfo.original.id}
        name={cellInfo.value}
        type={cellInfo.original.type}
      />
    )
  } satisfies Column<Paragraph6FinancialDocument>;
}

interface DocumentDownloadCellProps {
  name: string;
  id: string;
  type?: FinancialDocumentType;
}

function DocumentDownloadCell({ name, id, type }: DocumentDownloadCellProps) {
  const { download } = useDownload();

  let downloadUrl: string | undefined = undefined;

  switch (type) {
    case FinancialDocumentType.Credit:
    case FinancialDocumentType.PaymentInformation:
      downloadUrl = urls.apiWithoutCamelization.paragraph6CreditDocument(id);
      break;

    case FinancialDocumentType.Cancellation:
      downloadUrl =
        urls.apiWithoutCamelization.paragraph6CancellationDocument(id);
      break;

    default:
      downloadUrl = undefined;
  }

  if (!downloadUrl) return null;

  return (
    <span>
      <Icon name={IconName.FileText} />{" "}
      <a href="#" onClick={() => download({ downloadUrl })}>
        {name}
      </a>
    </span>
  );
}
export { getDocumentColumn };
