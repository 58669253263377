import type { Path } from "react-hook-form";

/** Takes an array of strings and returns an array of keys from T, for use with react-hook-form `watch`. */
export function validateWatchStrings<T extends object>(
  watchStrings: Array<string>,
  objectOfType: T
): Array<Path<T>> {
  return watchStrings.filter((watchString) =>
    (Object.keys(objectOfType) as Array<Path<T>>).includes(
      watchString as Path<T>
    )
  ) as Array<Path<T>>;
}
