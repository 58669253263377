import { type FieldError } from "react-hook-form";

export function getErrorKeyFromFieldError(fieldError?: FieldError) {
  if (!fieldError) {
    return undefined;
  }

  switch (fieldError.type) {
    case "required":
      return "errors.Required";
    default:
      if (fieldError.message) {
        return fieldError.message;
      }

      return "errors.UnknownError";
  }
}
