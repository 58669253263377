import { useState } from "react";
import api from "../api";
import { openErrorAlertPopup } from "../components/ErrorAlertPopup/openErrorAlertPopup";
import urls from "../urls";
import { pollTaskStatus } from "../utils/api-utils";
import { downloadPDF } from "../utils/downloadPdf";
import { showToast } from "../utils/toast";

function useSapExport() {
  const [exportLoading, setExportLoading] = useState(false);

  function getPDFfromBackend(variantId, taskId) {
    api
      .get(urls.api.getSapExport(variantId, taskId))
      .then((r) => {
        downloadPDF(r);
        showToast("success", "Export erfolgreich heruntergeladen.");
      })
      .catch((error) => {
        showToast("error", error);
      })
      .finally(() => {
        setExportLoading(false);
      });
  }

  function generateAndDownloadSapExport(
    variantId: number,
    month: number,
    year: number
  ) {
    if (!variantId || !month || !year) return;
    setExportLoading(true);
    api
      .get(urls.api.generateSapExport(variantId, month, year))
      .then((response) => {
        if (response.data) {
          pollTaskStatus(
            urls.api.jobStatus(response.data.taskIds[0]),
            () => getPDFfromBackend(variantId, response.data.taskIds[0]),
            (error) => {
              setExportLoading(false);
              openErrorAlertPopup(error);
            },
            () => {
              showToast(
                "error",
                "Ein Fehler ist aufgetreten. Wir sind bereits darüber informiert."
              );
            }
          );
        }
      });
  }

  return { generateAndDownloadSapExport, exportLoading };
}

export { useSapExport };
