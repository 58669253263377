import type { TFunction } from "i18next";
import { isNumberStringValid } from "../../../../../mantine/mrt/validation/isNumberStringValid";
import type { Paragraph6CreditNumberRangeFormValues } from "../../Paragraph6CreditNumberRangesPage.types";
import { isFormatValid } from "./isFormatValid";

/** Validates form values from a row in Paragraph6CreditNumberRangesTable. */
export function validateParagraph6CreditNumberRangeFormValues(
  range: Partial<Paragraph6CreditNumberRangeFormValues>,
  t: TFunction
) {
  return {
    supplier: !isRequiredNumberValid(range.supplier)
      ? t("errors.Required")
      : "",
    format: validateFormat(range.format, t),
    counter_start_value: validateCounterStartValue(
      range.counter_start_value,
      t
    ),
    valid_from: !isRequiredStringValid(range.valid_from)
      ? t("errors.Required")
      : ""
  };
}

function validateFormat(value: string | null | undefined, t: TFunction) {
  if (!isRequiredStringValid(value)) {
    return t("errors.Required");
  }

  if (!isFormatValid(value)) {
    return t("errors.Paragraph6.CreditNumberRanges.formatError");
  }

  return "";
}

function validateCounterStartValue(
  value: string | null | undefined,
  t: TFunction
) {
  if (!isRequiredStringValid(value)) {
    return t("errors.Required");
  }

  if (!isNumberStringValid(value)) {
    return t("errors.Paragraph6.CreditNumberRanges.counterStartValueError");
  }

  return "";
}

function isRequiredStringValid(
  value: string | null | undefined
): value is string {
  return !!value && value.length > 0;
}

function isRequiredNumberValid(
  value: number | null | undefined
): value is number {
  return typeof value !== "undefined" && value !== null;
}
