import { ShareType } from "../../Parkabrechnung.types";
import type { ParkabrechnungContractFormControlObject } from "./ParkabrechnungContractModal/ParkabrechnungContractModal.types";
import { PERIOD_CHOICES } from "./ParkabrechnungContractModal/ParkabrechnungContractModal.types";

export const GENERIC_CONTRACT_FIELDS = {
  name: {
    name: "name",
    required: true,
    label: "Vertragsname",
    type: "text"
  },
  site: {
    name: "site",
    required: true,
    label: "Liegenschaft",
    type: "dropdown"
  },
  gridConnectionSupplyHolder: {
    name: "gridConnectionSupplyHolder",
    label: "Netzanschlussnehmer",
    type: "text"
  },
  components: {
    name: "components",
    required: true,
    label: "Anlagen",
    type: "dropdown",
    multiselect: true
  }
} as const satisfies ParkabrechnungContractFormControlObject;

export const NETZBEZUG_CONTRACT_FIELDS = {
  period: {
    name: "period",
    label: "Abrechnungsart und -periode",
    type: "dropdown",
    choices: PERIOD_CHOICES
  },
  meloMaloId: {
    name: "meloMaloId",
    label: "am Zählpunkt",
    type: "dropdown",
    placeholder:
      "z.B. DE0071372933100000E00000000051762 (Zähler am Netzverknüpfungspunkt)"
  },
  powerFeedoutPrice: {
    name: "powerFeedoutPrice",
    label: "Strombezugspreis",
    type: "number",
    inputGroupText: "ct/kWh",
    placeholder: "z.B. 10,5"
  }
} as const satisfies ParkabrechnungContractFormControlObject;

export const EINSPEISUNG_CONTRACT_FIELDS = {
  feedinPeriod: {
    name: "feedinPeriod",
    label: "Abrechnungsart und -periode",
    type: "dropdown",
    choices: PERIOD_CHOICES
  },
  feedinMeloMaloId: {
    name: "feedinMeloMaloId",
    label: "am Zählpunkt",
    type: "dropdown",
    placeholder:
      "DE0071372933100000E00000000051762 (Zähler am Netzverknüpfungspunkt)"
  },
  feedinDistributionType: {
    name: "feedinDistributionType",
    label: "Aufteilungsart der Anlagen am Zählpunkt",
    type: "dropdown",
    choices: [
      {
        displayName: "Installierte Leistung",
        value: ShareType.InstalledCapacity
      },
      { displayName: "Scada-Daten", value: ShareType.ScadaData },
      {
        displayName: "Fester Aufteilungsfaktor",
        value: ShareType.FixedShareFactor
      }
    ]
  }
} as const satisfies ParkabrechnungContractFormControlObject;
