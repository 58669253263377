import { OAuthApplicationScope } from "../../../types/api.types";
import type { FormFieldData } from "../../BuildingBlocks/Forms/Controllers/FormFieldController";
import type { OAuthApplicationCreate } from "../ApiSettings.types";

export const OAUTH_APPLICATION_FORM_FIELD_DATA = {
  name: {
    name: "name",
    required: true,
    label: "Name der Applikation",
    type: "text"
  },
  customer: {
    name: "customer",
    required: true,
    label: "Kunde, für den die OAuth-Applikation registiert werden soll",
    placeholder: "Kunde suchen",
    type: "dropdown",
    choices: [] // will be filled dynamically during runtime
  },
  scope: {
    name: "scope",
    required: true,
    label: "Scopes der Applikation",
    type: "dropdown",
    multiselect: true,
    choices: [] // will be filled dynamically during runtime
  }
} as const satisfies FormFieldData<OAuthApplicationCreate>;

export const SCOPE_LABELS: Record<OAuthApplicationScope, string> = {
  [OAuthApplicationScope.EnergySeriesRead]: "Energieserie lesen",
  [OAuthApplicationScope.EnergySeriesWrite]: "Energieserie schreiben",
  [OAuthApplicationScope.EnergySeriesCreate]: "Energieserie erstellen",
  [OAuthApplicationScope.EnergySeriesDelete]: "Energieserie löschen",
  [OAuthApplicationScope.EnergySeriesEventsRead]: "Energieserie-Event lesen",
  [OAuthApplicationScope.LocationDataRead]: "Lokationsdaten lesen",
  [OAuthApplicationScope.LocationDataWrite]: "Lokationsdaten schreiben",
  [OAuthApplicationScope.MeterDataRead]: "Meterdaten lesen",
  [OAuthApplicationScope.MeterDataWrite]: "Meterdaten schreiben"
};
