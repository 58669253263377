import { Textarea, type TextareaProps } from "@mantine/core";
import classNames from "classnames";
import React from "react";
import { THEME_VARS } from "../../../../../utils/constants";
import "../styles/form-control.scss";

interface TextareaFieldProps extends Omit<TextareaProps, "error"> {
  errorText?: string;
  extraInputClasses?: string;
  inputGroupText?: string;
  invalid?: boolean;
  warned?: boolean;
}

function TextareaField(
  {
    className,
    errorText,
    extraInputClasses,
    invalid,
    value,
    warned,
    ...otherProps
  }: TextareaFieldProps,
  ref?: React.Ref<HTMLTextAreaElement>
) {
  const inputClassName = classNames(extraInputClasses, {
    "is-invalid": invalid,
    warning: !invalid && warned
  });
  const hasError = Boolean(errorText) || invalid;
  const borderColor = hasError
    ? THEME_VARS.dangerColor
    : warned
      ? THEME_VARS.warningColor
      : THEME_VARS.inputBorderColor;

  return (
    <Textarea
      {...otherProps}
      className={inputClassName}
      error={hasError}
      ref={ref}
      styles={{
        input: {
          color: "var(--node-energy-black)",
          borderColor: borderColor,
          fontSize: "var(--mantine-font-size-md)"
        }
      }}
      value={value}
    />
  );
}

const ForwardedRefTextareaField = React.forwardRef(TextareaField);

export { ForwardedRefTextareaField as TextareaField, TextareaFieldProps };
