import React from "react";

import {
  Modal,
  ModalFooter,
  ModalHeader
} from "../../BuildingBlocks/Layout/Modal/Modal";
import { ModalBody } from "../../BuildingBlocks/Layout/Modal/ModalBody/ModalBody";
import { Button } from "../../Buttons/Button/Button";

interface AuthDeactivationConfirmModalProps {
  isOpen: boolean;
  onToggle: () => void;
  onAuthDeactivationConfirm: () => void;
}

function AuthDeactivationConfirmModal({
  isOpen,
  onToggle,
  onAuthDeactivationConfirm
}: AuthDeactivationConfirmModalProps) {
  return (
    <Modal fade={false} isOpen={isOpen} toggle={onToggle}>
      <ModalHeader toggle={onToggle}>Bestätigen Sie Ihre Aktion</ModalHeader>
      <ModalBody scrollable>
        <div className="description">
          <p>
            Die 2-Faktor-Authentifizierung (2FA) erhöht die Sicherheit Ihres
            Nutzerkontos, indem sie eine zusätzliche Sicherheitsebene darstellt.
            Wir raten Ihnen daher davon ab die 2FA zu deaktivieren und den
            erweiterten Schutz beizubehalten.
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onToggle}>
          Schließen
        </Button>
        <Button
          color="primary"
          spin={false}
          onClick={onAuthDeactivationConfirm}
        >
          Weiter
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export { AuthDeactivationConfirmModal };
