import React, { useState } from "react";
import { Collapse } from "reactstrap";
import { Icon } from "../Icon/Icon";
import { IconName } from "../Icon/types";
import "./Accordion.scss";

type AccordionProps = {
  children: React.ReactNode;
  header: string | React.ReactNode;
};

function Accordion({ children, header }: AccordionProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="Accordion" onClick={() => setIsOpen(!isOpen)}>
      <header className="accordion-header">
        <Icon
          className="accordion-icon"
          name={isOpen ? IconName.AngleDown : IconName.AngleRight}
        />
        <HeaderComponent header={header} />
      </header>
      <Collapse isOpen={isOpen}>{children}</Collapse>
    </div>
  );
}

function HeaderComponent({ header }: { header: string | React.ReactNode }) {
  if (typeof header === "string") {
    return <span className="accordion-header-text">{header}</span>;
  }

  return <>{header}</>;
}

export { Accordion, AccordionProps };
