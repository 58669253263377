import * as Sentry from "@sentry/browser";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import api from "../../../../api";
import { useEnergyDataAcquisitions } from "../../../../hooks/useEnergyDataAcquisitions";
import urls from "../../../../urls";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../../../Buttons/Button/Button";
import { useCustomReactTableCheckboxes } from "../../../CustomReactTable/CustomReactTableHooks";
import { composeSelectionKey } from "../../../CustomReactTable/CustomReactTableWithSelect/CustomReactTableWithSelect";
import { openErrorAlertPopup } from "../../../ErrorAlertPopup/openErrorAlertPopup";
import loader from "../../../Loader";
import { LoadOrError } from "../../../LoadOrError/LoadOrError";
import type { AcquisitionWithSummaryData } from "../EnergyDataAcquisitionList/EnergyDataAcquisitionList";
import type { AcquisitionFilter } from "../EnergyDataView";
import { AcquisitionList } from "./AcquisitionList/AcquisitionList";
import { DefinedFilterDropdown } from "./DefinedFilterDropdown/DefinedFilterDropdown";
import {
  buildFilterString,
  DEFAULT_FILTERS,
  EMPTY_FILTERS
} from "./Filters/filter-utils";
import { Filters } from "./Filters/Filters";

import "./EnergyDataAcquisitionSelectionModal.scss";

export const DUMMY_DEFINED_FILTER_ID_FOR_NEW_FILTERS = -1;
const TEMP_DEFINED_FILTER_ID = -2;

export interface EdaFilter {
  appliedFilters: string;
  availableInAllProjects: boolean;
  filterName: string;
  id: number;
  project: string;
}

export interface EdaFilterOption {
  label: string;
  option: string;
  site: string;
  value: string | number | { min: number; max: number };
}

export type EdaFilterOptions = Array<EdaFilterOption>;

interface EnergyDataAcquisitionModalProps {
  isOpen: boolean;
  variantId: number;
  projectId: string;
  filterOptions: Record<string, EdaFilterOptions>; // string = FILTER_TYPES without installedCapacity and date
  onToggle: () => void;
  initialSelectedAcquisitions: Array<number>;
  initialAcquisitionFilters: Array<AcquisitionFilter>;
  initialDefinedFilterId: number | null;
  initialDefinedFilters: Array<EdaFilter>;
  onSelectedAcquisitionsChanged: (
    selectedAcquisitions: Array<number>,
    filters: Array<AcquisitionFilter>,
    definedFilterId: number | null
  ) => void;
}

function EnergyDataAcquisitionSelectionModal({
  isOpen,
  variantId,
  projectId,
  filterOptions,
  onToggle,
  initialSelectedAcquisitions,
  initialAcquisitionFilters,
  initialDefinedFilterId,
  initialDefinedFilters,
  onSelectedAcquisitionsChanged
}: EnergyDataAcquisitionModalProps) {
  const {
    selection: tableSelection,
    selectAll,
    setSelection: setTableSelection,
    setSelectAll,
    getSelectedData,
    customReactTableProps
  } = useCustomReactTableCheckboxes<AcquisitionWithSummaryData>(
    initialSelectedAcquisitions.map((acq) =>
      composeSelectionKey(acq.toString())
    )
  );
  const [firstLoadHappened, setFirstLoadHappened] = useState(false);
  const [filters, setFilters] = useState(
    initialAcquisitionFilters || DEFAULT_FILTERS
  );
  const [definedFilterId, setDefinedFilterId] = useState(
    initialDefinedFilterId
  );
  const [definedFilters, setDefinedFilters] = useState(
    initialDefinedFilters.map((definedFilter) => {
      let appliedFilters;

      try {
        appliedFilters = JSON.parse(definedFilter.appliedFilters);
      } catch (_) {
        Sentry.captureMessage(
          `Invalid filter sent to frontend: ${definedFilter.appliedFilters}`
        );
      }

      return {
        ...definedFilter,
        appliedFilters: appliedFilters ?? EMPTY_FILTERS
      };
    })
  );
  const [savingDefinedFilters, setSavingDefinedFilters] = useState(false);
  const filterString = buildFilterString(filters);
  const {
    data: acquisitions,
    isLoading: isLoadingAcquisitions,
    error: errorAcquisitions
  } = useEnergyDataAcquisitions(variantId, filterString);

  useEffect(() => {
    if (!acquisitions) {
      return;
    }

    setTableSelection((oldTableSelection) => {
      const newTableSelection = [
        ...acquisitions.map((acquisition) =>
          composeSelectionKey(acquisition.id.toString())
        )
      ];

      if (oldTableSelection.length > 0 || firstLoadHappened) {
        const filteredTableSelection = oldTableSelection.reduce(
          (filteredIds: Array<string>, selectedAcquisitionId) => {
            if (newTableSelection.includes(selectedAcquisitionId)) {
              filteredIds.push(selectedAcquisitionId);
            }

            return filteredIds;
          },
          []
        );

        return filteredTableSelection;
      } else {
        return newTableSelection;
      }
    });

    if (!firstLoadHappened) {
      setFirstLoadHappened(true);
    }
  }, [acquisitions, firstLoadHappened, setTableSelection]);

  useEffect(() => {
    if (
      isOpen &&
      acquisitions?.length === tableSelection.length &&
      !selectAll
    ) {
      setSelectAll(true);
    }
  }, [isOpen, acquisitions, tableSelection, selectAll, setSelectAll]);

  function handleChangeDefinedFilterId(filterId: number) {
    const filter = definedFilters.find(
      (definedFilter) => definedFilter.id === filterId
    );

    if (
      filterId === DUMMY_DEFINED_FILTER_ID_FOR_NEW_FILTERS ||
      (filter && filter.appliedFilters)
    ) {
      setDefinedFilterId(filterId);
    } else {
      setDefinedFilterId(null);
    }

    if (filter && filter.appliedFilters) {
      setFilters(filter.appliedFilters);
    }
  }

  function handleSaveDefinedFilter(definedFilterName: string) {
    const tempDefinedFilter = {
      appliedFilters: filters,
      availableInAllProjects: false,
      filterName: definedFilterName,
      id: TEMP_DEFINED_FILTER_ID,
      project: projectId
    };
    const oldDefinedFilters = [...definedFilters];
    const tempDefinedFilters = [...definedFilters];

    tempDefinedFilters.push(tempDefinedFilter);

    setDefinedFilters(tempDefinedFilters);
    setDefinedFilterId(TEMP_DEFINED_FILTER_ID);
    setSavingDefinedFilters(true);

    const newDefinedFilters = oldDefinedFilters;
    let newDefinedFilterId;

    api
      .post(urls.api.energyDataAcquisitionFilters(), {
        project: projectId,
        filterName: definedFilterName,
        appliedFilters: JSON.stringify(filters)
      })
      .then((response) => {
        newDefinedFilterId = response.data.id;
        const newDefinedFilter = {
          ...tempDefinedFilter,
          id: newDefinedFilterId
        };

        newDefinedFilters.push(newDefinedFilter);
      })
      .catch((error) => {
        newDefinedFilterId = DUMMY_DEFINED_FILTER_ID_FOR_NEW_FILTERS;
        openErrorAlertPopup(error);
      })
      .finally(() => {
        setDefinedFilters(newDefinedFilters);
        setDefinedFilterId(newDefinedFilterId);
        setSavingDefinedFilters(false);
      });
  }

  async function handleDeleteDefinedFilter(definedFilterId: number) {
    const oldDefinedFilters = [...definedFilters];
    const oldDefinedFilter = definedFilters.find(
      (definedFilter) => definedFilter.id === definedFilterId
    );
    const newDefinedFilters = definedFilters.filter(
      (definedFilter) => definedFilter.id !== definedFilterId
    );

    setFilters(EMPTY_FILTERS);
    setDefinedFilterId(DUMMY_DEFINED_FILTER_ID_FOR_NEW_FILTERS);
    setDefinedFilters(newDefinedFilters);

    try {
      await api.delete(
        urls.api.energyDataAcquisitionFiltersDetail(definedFilterId)
      );
    } catch (error) {
      openErrorAlertPopup(error);
      setDefinedFilters(oldDefinedFilters);
      setDefinedFilterId(definedFilterId);

      if (oldDefinedFilter) {
        setFilters(oldDefinedFilter.appliedFilters);
      }
    }
  }

  function handleChangeFilters(filters: AcquisitionFilter[]) {
    setFilters(filters);
    setDefinedFilterId(DUMMY_DEFINED_FILTER_ID_FOR_NEW_FILTERS);
  }

  function handleClickSave() {
    const selectedIds = getSelectedData().map((data) => data.id);

    onToggle();
    onSelectedAcquisitionsChanged(selectedIds, filters, definedFilterId);
  }

  // note: the backend does not guarantee that filters are unique for some reason
  // therefore, we filter out duplicated filters here
  const uniqueDefinedFilters = definedFilters.reduce<
    Array<EdaFilter & { appliedFilters: string }>
  >((filters, filter) => {
    if (!filters.find((f) => f.id === filter.id)) {
      filters.push(filter);
    }

    return filters;
  }, []);

  return (
    <Modal
      className="EnergyDataAcquisitionSelectionModal"
      isOpen={isOpen}
      size="xl"
      toggle={onToggle}
    >
      <ModalHeader toggle={onToggle}>Energiedaten auswählen</ModalHeader>
      <ModalBody scrollable>
        <h4>Filter</h4>
        <DefinedFilterDropdown
          definedFilterId={definedFilterId}
          definedFilters={uniqueDefinedFilters}
          savingDefinedFilters={savingDefinedFilters}
          onChange={handleChangeDefinedFilterId}
        />
        <Filters
          definedFilterId={definedFilterId}
          definedFilters={uniqueDefinedFilters}
          filterOptions={filterOptions}
          filters={filters}
          savingDefinedFilters={savingDefinedFilters}
          onChangeFilters={handleChangeFilters}
          onDeleteDefinedFilter={handleDeleteDefinedFilter}
          onSaveDefinedFilter={handleSaveDefinedFilter}
        />
        <h4>Energiedaten</h4>
        <LoadOrError error={errorAcquisitions} loading={isLoadingAcquisitions}>
          {acquisitions && (
            <AcquisitionList
              acquisitions={acquisitions}
              customReactSelectTableProps={customReactTableProps}
            />
          )}
        </LoadOrError>
      </ModalBody>
      <ModalFooter>
        <Button color="brand" onClick={onToggle}>
          Abbrechen
        </Button>
        <Button
          color="primary"
          disabled={tableSelection.length === 0}
          onClick={handleClickSave}
        >
          Übernehmen
        </Button>
      </ModalFooter>
    </Modal>
  );
}

function EnergyDataAcquisitionSelectionModalLoadedDataWrapper(props) {
  const definedFilters = props.data.definedFilters;
  let initialDefinedFilterId = props.initialDefinedFilterId;

  // don't pass on an invalid filter id
  if (
    !definedFilters.find(
      (definedFilter) => definedFilter.id === initialDefinedFilterId
    )
  ) {
    initialDefinedFilterId = null;
  }

  return (
    <EnergyDataAcquisitionSelectionModal
      filterOptions={props.data.filterOptions}
      initialAcquisitionFilters={props.initialAcquisitionFilters}
      initialDefinedFilterId={initialDefinedFilterId}
      initialDefinedFilters={definedFilters}
      initialSelectedAcquisitions={props.initialSelectedAcquisitions}
      isOpen={props.isOpen}
      projectId={props.projectId}
      variantId={props.variantId}
      onSelectedAcquisitionsChanged={props.onSelectedAcquisitionsChanged}
      onToggle={props.onToggle}
    />
  );
}

const EnergyDataAcquisitionSelectionModalLoadedDataWrapperWithLoader = loader(
  EnergyDataAcquisitionSelectionModalLoadedDataWrapper,
  false
);

EnergyDataAcquisitionSelectionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  variantId: PropTypes.number.isRequired,
  projectId: PropTypes.string.isRequired,
  filterOptions: PropTypes.object.isRequired,
  onToggle: PropTypes.func.isRequired,
  initialSelectedAcquisitions: PropTypes.arrayOf(PropTypes.number).isRequired,
  initialAcquisitionFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
  initialDefinedFilterId: PropTypes.number,
  initialDefinedFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelectedAcquisitionsChanged: PropTypes.func.isRequired
};

export { EnergyDataAcquisitionSelectionModalLoadedDataWrapperWithLoader as EnergyDataAcquisitionSelectionModal };
