import React, { useState } from "react";
import { NodeResizer, type NodeProps } from "reactflow";
import { useStructureViewFlowDiagramMutations } from "../../../../hooks/useStructureViewFlowDiagramMutations";
import type { ObjectName } from "../../../../utils/enums";
import { showToast } from "../../../../utils/toast";
import { type StructureViewMode } from "../../../StructureView/StructureView.constants";
import "./CustomContainerElementNode.scss";

export interface ContainerElementsNodeParameters {
  width: number;
  height: number;
  x: number;
  y: number;
}

interface CustomContainerElementNodeData {
  type: ObjectName;
  site: number;
  width: number;
  height: number;
  color: string;
}
interface CustomContainerElementNodeProps
  extends NodeProps<CustomContainerElementNodeData> {
  id: string;
  mode: Exclude<StructureViewMode, StructureViewMode.Old>;
}
function CustomContainerElementNode({
  data,
  id,
  mode
}: CustomContainerElementNodeProps) {
  const [width, setWidth] = useState(data.width);
  const [height, setHeight] = useState(data.height);
  const { updateResizedContainerElementNodeMutation } =
    useStructureViewFlowDiagramMutations(data.site, mode);

  async function handleResizeStop(
    _,
    parameters: ContainerElementsNodeParameters
  ) {
    await updateResizedContainerElementNodeMutation.mutateAsync(
      { nodeId: id, parameters },
      {
        onError: (error) => {
          showToast("error", error);
        }
      }
    );
  }

  function handleResize(_, parameters) {
    setWidth(parameters.width);
    setHeight(parameters.height);
  }

  return (
    <>
      <NodeResizer
        color="black"
        handleStyle={{
          width: 8,
          height: 8
        }}
        onResize={handleResize}
        onResizeEnd={handleResizeStop}
      />
      <div
        className="CustomContainerElementNode"
        data-testid="custom-flow-node"
        style={{
          width: width,
          height: height,
          backgroundColor: data.color,
          opacity: 0.6
        }}
      >
        <div className="custom-text-element-body"></div>
      </div>
    </>
  );
}

export { CustomContainerElementNode };
