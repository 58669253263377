import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { TabContent, TabPane } from "reactstrap";
import { useConfiguratorComponents } from "../../hooks/useConfiguratorComponents";
import { useFeatureFlags } from "../../hooks/useFeatureFlags";
import { Flag } from "../../hooks/useFeatureFlags.types";
import { useProject } from "../../hooks/useProject";
import { useSiteCategories } from "../../hooks/useSiteCategories";
import type {
  ExtendedUser,
  Person,
  SiteCategoriesResponse
} from "../../utils/backend-types";
import { ObjectName } from "../../utils/enums";
import { getPluralVariableNameFromObjectName } from "../../utils/getPluralVariableNameFromObjectName";
import { showToast } from "../../utils/toast";
import { PageContent } from "../BuildingBlocks/Layout/PageContent/PageContent";
import { PageHeader } from "../BuildingBlocks/Layout/PageHeader/PageHeader";
import { Portlet } from "../BuildingBlocks/Layout/Portlet";
import type { TabData } from "../BuildingBlocks/Layout/TabBar/TabBar";
import { TabBar } from "../BuildingBlocks/Layout/TabBar/TabBar";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { CompaniesPage } from "./Companies/CompaniesPage";
import { DeleteSiteModal } from "./DeleteSiteModal/DeleteSiteModal";
import { useProjectOverviewSites } from "./hooks/useProjectOverviewSites";
import "./MsmProjectView.scss";
import type { ProjectOverviewSite } from "./MsmProjectView.types";
import { RightsPage } from "./Rights/RightsPage";
import { SettingsPage } from "./Settings/SettingsPage";
import { SitesPage } from "./Sites/SitesPage";

enum ProjectViewTabs {
  Sites = "liegenschaften",
  Companies = "unternehmen",
  Rights = "rechte",
  Settings = "einstellungen"
}

const TABS: ReadonlyArray<TabData<ProjectViewTabs>> = [
  {
    id: ProjectViewTabs.Sites,
    label: "Liegenschaften"
  },
  {
    id: ProjectViewTabs.Companies,
    label: "Unternehmen"
  },
  {
    id: ProjectViewTabs.Rights,
    label: "Rechte"
  },
  {
    id: ProjectViewTabs.Settings,
    label: "Einstellungen"
  }
];

export const DEFAULT_SUBPAGE = ProjectViewTabs.Sites;

interface MsmProjectViewProps {
  initialProjectSites: ReadonlyArray<ProjectOverviewSite>;
  projectManagers: ReadonlyArray<ExtendedUser>;
  projectCompanies: Array<Person>;
  projectPage: string;
  projectId: string;
  projectName: string;
  variantId: number;
  siteCategories: Array<SiteCategoriesResponse>;
}

function MsmProjectView({
  initialProjectSites,
  projectManagers,
  projectCompanies,
  projectPage,
  projectId,
  projectName,
  variantId,
  siteCategories
}: MsmProjectViewProps) {
  const { featureFlags } = useFeatureFlags();
  const isSiteSetupAssistantActive = featureFlags[Flag.siteSetupAssistant];
  const queryClient = useQueryClient();
  const [siteToDelete, setSiteToDelete] = useState<ProjectOverviewSite | null>(
    null
  );

  function handleShareProject(newManager: ExtendedUser) {
    if (!projectManagers.find((manager) => manager.id === newManager.id)) {
      showToast("success", "Das Projekt wurde erfolgreich geteilt.");
    } else {
      showToast("info", "Das Projekt wurde schon geteilt.");
    }
  }

  function handleSiteDeleted() {
    invalidateSites();
    setSiteToDelete(null);
  }

  function invalidateSites() {
    queryClient.invalidateQueries({
      queryKey: [
        "project-overview-sites",
        { variantId, isSiteSetupAssistantActive }
      ]
    });
  }

  function handleDeleteCompany() {
    const objectCacheName = getPluralVariableNameFromObjectName(
      ObjectName.Person
    );

    queryClient.invalidateQueries({
      queryKey: [objectCacheName, { siteOrVariantId: variantId }]
    });
  }

  const activeTab = projectPage;

  return (
    <PageContent className="MsmProjectView">
      <PageHeader title={projectName} />
      <TabBar activeTab={activeTab} basePath="../" tabs={TABS} />
      <TabContent activeTab={activeTab} tag={Portlet}>
        <TabPane className="Sites" tabId={ProjectViewTabs.Sites}>
          {activeTab === ProjectViewTabs.Sites && (
            <SitesPage
              siteCategories={siteCategories}
              sites={initialProjectSites}
              variantId={variantId}
              onDeleteSite={setSiteToDelete}
              onSiteCreated={invalidateSites}
              onSiteEdited={invalidateSites}
            />
          )}
        </TabPane>
        <TabPane className="Companies" tabId={ProjectViewTabs.Companies}>
          {activeTab === ProjectViewTabs.Companies && (
            <CompaniesPage
              companies={projectCompanies}
              variantId={variantId}
              onDeleteCompany={handleDeleteCompany}
            />
          )}
        </TabPane>
        <TabPane className="Rights" tabId={ProjectViewTabs.Rights}>
          {activeTab === ProjectViewTabs.Rights && (
            <RightsPage
              projectId={projectId}
              projectManagers={projectManagers}
              projectName={projectName}
              onShare={handleShareProject}
            />
          )}
        </TabPane>
        <TabPane className="Settings" tabId={ProjectViewTabs.Settings}>
          {activeTab === ProjectViewTabs.Settings && (
            <SettingsPage
              projectId={projectId}
              projectSites={initialProjectSites}
              siteCategories={siteCategories}
              variantId={variantId}
            />
          )}
        </TabPane>
      </TabContent>
      {siteToDelete && (
        <DeleteSiteModal
          site={siteToDelete}
          toggleModal={() => setSiteToDelete(null)}
          onSuccess={handleSiteDeleted}
        />
      )}
    </PageContent>
  );
}

function MsmProjectViewDataWrapper() {
  const { projectId, projectPage } = useParams();
  const {
    isLoading: isProjectLoading,
    error: projectError,
    project
  } = useProject(projectId);
  const {
    siteCategories,
    isLoading: isSiteCategoriesLoading,
    error: siteCategoriesError
  } = useSiteCategories(projectId ?? null);
  const variant = project
    ? project.variants.find((variant) => variant.isManagerVariant)
    : undefined;
  const {
    sites,
    isLoading: isSitesLoading,
    error: sitesError
  } = useProjectOverviewSites(variant?.id);
  const {
    data: projectCompanies,
    isLoading: isProjectCompaniesLoading,
    error: projectCompaniesError
  } = useConfiguratorComponents<Person>(ObjectName.Person, variant?.id);

  if (!projectPage) {
    return <Navigate to={DEFAULT_SUBPAGE} />;
  }

  return (
    <LoadOrError
      error={
        projectError ||
        siteCategoriesError ||
        sitesError ||
        projectCompaniesError
      }
      loading={
        isProjectLoading ||
        isSiteCategoriesLoading ||
        isSitesLoading ||
        isProjectCompaniesLoading
      }
    >
      {project && variant && siteCategories && projectCompanies && (
        <MsmProjectView
          initialProjectSites={sites}
          projectCompanies={projectCompanies}
          projectId={project.id}
          projectManagers={project.managers}
          projectName={project.name}
          projectPage={projectPage}
          siteCategories={siteCategories}
          variantId={variant.id}
        />
      )}
    </LoadOrError>
  );
}

export { MsmProjectViewDataWrapper as MsmProjectView };
