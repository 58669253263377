import React, { PureComponent } from "react";

import { Button } from "../../../Buttons/Button/Button";

import "./DisplayAllToggleButton.scss";

export default class DisplayAllToggleButton extends PureComponent {
  render() {
    const { showAll, onChange } = this.props;
    const text = showAll
      ? "Profile des Projekts zeigen"
      : "Alle Profile zeigen";
    return (
      <Button color="secondary" onClick={onChange}>
        {text}
      </Button>
    );
  }
}
