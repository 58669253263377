import { useQuery } from "@tanstack/react-query";
import type { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { pollTaskStatus } from "../../../utils/api-utils";
import type { MetersSuggestions } from "../../../utils/backend-types";
import { showToast } from "../../../utils/toast";
import { findMetersSuggestions } from "../utils/findMetersSuggestions";
import { getMetersSuggestions } from "../utils/getMetersSuggestions";

export function useLoadSuggestedMeters(configurationId: number) {
  const { t } = useTranslation();

  return useQuery({
    queryKey: ["load-suggested-meters", configurationId],
    queryFn: () => getSuggestedMeters(configurationId, t),
    enabled: false
  });
}

async function getSuggestedMeters(
  configurationId: number,
  t: TFunction<"translation">
): Promise<MetersSuggestions[]> {
  try {
    const findMetersSuggestionsData =
      await findMetersSuggestions(configurationId);

    return new Promise((resolve, reject) => {
      pollTaskStatus(
        findMetersSuggestionsData.taskStatusUrl,
        () => {
          resolve(getMetersSuggestions(findMetersSuggestionsData.taskIds[0]));
        },
        () => {
          resolve(getMetersSuggestions(findMetersSuggestionsData.taskIds[0]));
        },
        () => {
          reject(showToast("error", t("errors.ServerError")));
        },
        1000
      );
    });
  } catch (error) {
    showToast("error", error);
    return [];
  }
}
