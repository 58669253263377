import React from "react";
import { type Control, Controller, type FieldErrors } from "react-hook-form";
import { FormFieldController } from "../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { type SiteSetupProcessForForm } from "../../SiteSetupAssistant.types";
import { PvPlantsFields } from "../CustomFields/PvPlantsFields";
import { SITE_SETUP_ASSISTANT_FORM } from "../SiteSetupAssistantForms.constants";

interface NameAndPvPlantsFormItemsProps {
  formControl: Control<SiteSetupProcessForForm>;
  formErrors: FieldErrors<SiteSetupProcessForForm>;
  onBlockNavigation: (blocked: boolean) => void;
}

function NameAndPvPlantsFormItems({
  formControl,
  formErrors,
  onBlockNavigation
}: NameAndPvPlantsFormItemsProps) {
  return (
    <div className="NameAndPvPlantsFormItems">
      <FormFieldController
        control={formControl}
        data={SITE_SETUP_ASSISTANT_FORM.name}
        error={formErrors.name}
      />
      <Controller
        control={formControl}
        name="pvPlants"
        render={({ field }) => {
          return (
            <PvPlantsFields
              pvPlants={field.value}
              onChange={field.onChange}
              onChangeNavigationBlock={(blocked: boolean) => {
                onBlockNavigation(blocked);
              }}
            />
          );
        }}
      />
      <FormFieldController
        control={formControl}
        data={SITE_SETUP_ASSISTANT_FORM.defaultPvPlantsMeasurementType}
        error={formErrors.defaultPvPlantsMeasurementType}
      />
    </div>
  );
}

export { NameAndPvPlantsFormItems };
