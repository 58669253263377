import React from "react";
import { Alert, AlertColor } from "../../Alert/Alert";
import {
  Modal,
  ModalFooter,
  ModalHeader
} from "../../BuildingBlocks/Layout/Modal/Modal";
import { ModalBody } from "../../BuildingBlocks/Layout/Modal/ModalBody/ModalBody";
import { Button } from "../../Buttons/Button/Button";

interface AuthActivationSuccessModalProps {
  isOpen: boolean;
  backupCodes: Array<string>;
  onToggle: () => void;
}

function AuthActivationSuccessModal({
  isOpen,
  backupCodes,
  onToggle
}: AuthActivationSuccessModalProps) {
  return (
    <div className="AuthActivationSuccessModal">
      <Modal
        className="AuthActivationSuccessModal"
        fade={false}
        isOpen={isOpen}
        toggle={onToggle}
      >
        <ModalHeader toggle={onToggle}>
          Zwei-Faktor-Authentifizierung wurde aktiviert
        </ModalHeader>
        <ModalBody scrollable>
          <p>
            Der von Ihnen eingegebene Code ist korrekt. Die
            2-Faktor-Authentifizierung ist nun aktiv und Ihr Konto ist besser
            geschützt.
          </p>

          <p>
            Falls Sie den Zugang zu Ihrer primären Authentifizierungsmethode
            verlieren, müssen Sie die folgenden Backup-Codes verwenden um sich
            einzuloggen. Bitte bewahren Sie die Backup-Codes an einem sicheren
            Ort auf. Jeder Backup-Code kann nur ein Mal verwendet werden.
          </p>

          <p>
            <strong>Wichtig:</strong> Wenn Sie den Zugriff zur
            Authentifizierungsmethode und Backup-Codes verlieren, können Sie
            sich nicht mehr in Ihren Account einloggen!
          </p>

          <Alert color={AlertColor.Dark}>
            {backupCodes.map((code) => (
              <span key={code}>
                {code}
                <br />
              </span>
            ))}
          </Alert>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onToggle}>
            Bestätigen
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export { AuthActivationSuccessModal };
