import type { DateTime } from "luxon";

/** Backend Date and DateTime formats. **Warning:** German format will be removed in the future!! */
export type BackendDateFormat =
  | "ISO 8601"
  | "ISO 8601 RFC822"
  | "German"
  | "GermanWithTime";

/**
 * Converts a Luxon DateTime to a backend date or datetime format, using the "German format" by default.
 * Returns null if the provided DateTime is invalid.
 */
export function luxonDateTimeToBackendDateOrDateTime(
  luxonDateTime: DateTime,
  format: BackendDateFormat = "German"
) {
  const backendIsoDate = luxonDateTime.toISODate();

  if (backendIsoDate === null) {
    throw Error("Invalid date format: " + luxonDateTime.toString());
  }

  if (format === "ISO 8601") {
    return backendIsoDate;
  }

  if (format === "ISO 8601 RFC822") {
    return luxonDateTime.toFormat("yyyy-MM-dd'T'HH:mm:ssZZZ");
  }

  if (format === "GermanWithTime") {
    console.warn("German date format is deprecated. Please use ISO 8601.");
    return luxonDateTime.toFormat("dd.MM.yyyy HH:mm:ss");
  }

  console.warn("German date format is deprecated. Please use ISO 8601.");
  return luxonDateTime.toFormat("dd.MM.yyyy");
}
