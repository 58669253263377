import React from "react";

import { usePreviewInvoices } from "../../../hooks/usePreviewInvoices";
import type { Contract } from "../../../utils/backend-types";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../../Buttons/Button/Button";
import { SpinButton } from "../../Buttons/SpinButton/SpinButton";

import "./InvoicePreviewModal.scss";

type InvoicePreviewModalProps = {
  isModalOpen: boolean;
  closeModal: () => void;
  contracts: Array<Contract>;
};

function InvoicePreviewModal({
  isModalOpen,
  closeModal,
  contracts
}: InvoicePreviewModalProps) {
  const { generateAndDownloadPreview, contractLoading } = usePreviewInvoices();

  return (
    <Modal contentClassName="preview-invoices-modal" isOpen={isModalOpen}>
      <ModalHeader>Rechnungsvorschau</ModalHeader>
      <ModalBody>
        <h4 className="preview-invoices-description-text">
          Bitte wählen Sie einen Vertrag aus, für den Sie eine Vorschau sehen
          wollen:
        </h4>

        <div className="preview-invoices-links">
          {contracts.map((contract) => (
            <div className="preview-invoices-link-container" key={contract.id}>
              <span className="preview-invoices-link-name">
                {contract.name}
              </span>
              <SpinButton
                className="preview-invoices-link-button"
                color="brand"
                spin={contractLoading === contract.id}
                onClick={() => generateAndDownloadPreview(contract.id)}
              >
                Vorschau
              </SpinButton>
            </div>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => closeModal()}>Schließen</Button>
      </ModalFooter>
    </Modal>
  );
}

export { InvoicePreviewModal };
