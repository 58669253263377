import { type MRT_ColumnDef } from "mantine-react-table";
import { type Todo } from "../../../utils/backend-types";
import { TodosListColumnKeys } from "../TodoTable";

export function getLabelColumn(): MRT_ColumnDef<Todo> {
  return {
    accessorKey: TodosListColumnKeys.Label,
    header: "Aufgabe",
    enableColumnFilter: false,
    enableGrouping: false
  };
}
