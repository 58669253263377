import { useQuery } from "@tanstack/react-query";
import api from "../../api";
import type { StartPageEEG6 } from "../../components/StartPage/StartPage.types";
import urls from "../../urls";

function useStartPageEEG6(bookable: boolean, year: number, projectId?: string) {
  const { data, error, isLoading } = useQuery({
    queryKey: urls.startPage.eeg6.key(year, projectId),
    queryFn: () => fetchStartPageEEG6(year, projectId),
    enabled: !bookable
  });

  async function fetchStartPageEEG6(year: number, projectId?: string) {
    const response = await api.get<StartPageEEG6>(
      urls.startPage.eeg6.url(year, projectId)
    );
    return response.data;
  }

  return { data, error, isLoading };
}

export { useStartPageEEG6 };
