import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import urls from "../../../urls";
import type { DirektvermarktungUnassignedGeneratorsResponse } from "../Direktvermarktung.types";

export interface UseDirektvermarktungUnassignedGeneratorsProps {
  contractUuid: string;
}

export function useDirektvermarktungUnassignedGenerators(
  args: UseDirektvermarktungUnassignedGeneratorsProps,
  options?: Partial<
    UseQueryOptions<Array<DirektvermarktungUnassignedGeneratorsResponse>>
  >
) {
  const { data, isLoading, error } = useQuery({
    queryKey: ["direktvermarktungUnassignedGenerators", args],
    queryFn: () => fetchDirektvermarktungUnassignedGenerators(args),
    ...options
  });

  async function fetchDirektvermarktungUnassignedGenerators({
    contractUuid
  }: UseDirektvermarktungUnassignedGeneratorsProps) {
    const direktvermarktungUnassignedGeneratorsUrl =
      urls.apiWithoutCamelization.direktvermarktungUnassignedGenerators(
        contractUuid
      );
    const response = await apiWithoutCamelization.get<
      Array<DirektvermarktungUnassignedGeneratorsResponse>
    >(direktvermarktungUnassignedGeneratorsUrl);
    return response.data;
  }

  return {
    data,
    isLoading,
    error
  };
}
