import * as Sentry from "@sentry/browser";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import type { MissingObjectDataProps } from "../../../hooks/useShouldFieldBeHighlighted";
import { useShouldFieldBeHighlighted } from "../../../hooks/useShouldFieldBeHighlighted";
import { useSiteWizard } from "../../../hooks/useSiteWizard";
import urls from "../../../urls";
import type {
  SiteWizard,
  SiteWizardPhases,
  WizardDetail,
  WizardMissingData
} from "../../../utils/backend-types";
import { Product } from "../../../utils/enums";
import type { OperatorChange } from "../../SiteTasks/OperatorChanges/OperatorChanges.types";
import type { TenantChange } from "../../SiteTasks/TenantChanges/TenantChanges.types";
import { useChangeProcesses } from "../../SiteTasks/useChangeProcesses";

export interface MissingObjectDataLocationState {
  highlightMissingFieldsFromPhase: keyof SiteWizardPhases;
}

export interface PhaseData {
  missingData: Array<WizardMissingData>;
  exclusiveOrFields: Array<Array<string>>;
}

export function useMissingObjectData(
  objectId: number | undefined,
  siteId: number | undefined,
  product: Product
): {
  isLoading: boolean;
  error: unknown;
} & MissingObjectDataProps {
  const { t } = useTranslation();
  const { siteWizard, isLoading, error } = useSiteWizard(siteId, {
    enabled:
      product === Product.Manager &&
      typeof siteId !== "undefined" &&
      typeof objectId !== "undefined",
    refetchOnWindowFocus: true
  });
  const {
    data: operatorChanges,
    isLoading: operatorChangesLoading,
    error: operatorChangesError
  } = useChangeProcesses<OperatorChange>(
    "operatorChanges",
    urls.api.mieterstromProcesses.listOperatorChanges(siteId),
    siteId,
    {
      enabled:
        product === Product.Manager &&
        typeof siteId !== "undefined" &&
        typeof objectId !== "undefined"
    }
  );
  const {
    data: tenantChanges,
    isLoading: tenanChangesLoading,
    error: tenanChangesError
  } = useChangeProcesses<TenantChange>(
    "tenantChanges",
    urls.api.mieterstromProcesses.listTenantChanges(siteId),
    siteId,
    {
      enabled:
        product === Product.Manager &&
        typeof siteId !== "undefined" &&
        typeof objectId !== "undefined"
    }
  );

  const { state: locationState } = useLocation();
  const {
    missingData: missingDataFromSiteWizard,
    exclusiveOrFields: groupedExclusiveOrFields,
    missingDataError
  } = getMissingDataAndExclusiveOrFieldsFromSiteWizardData(
    siteWizard,
    locationState
  );

  const { missingData: operatorChangeMissingData } =
    getMissingDataFromOperatorChangeProcesses(operatorChanges);

  const { missingData: tenantChangeMissingData } =
    getMissingDataFromTenantChangeProcesses(tenantChanges);

  const missingData = [
    ...missingDataFromSiteWizard,
    ...operatorChangeMissingData,
    ...tenantChangeMissingData
  ];

  const filteredMissingData: Array<WizardMissingData> =
    missingData.filter((data) => data.id === objectId) ?? [];

  const allFields = Object.values(filteredMissingData).reduce(
    (acc: Array<string>, data) => {
      acc = acc.concat(data.missingFields);
      return acc;
    },
    []
  );

  const shouldFieldBeHighlighted = useShouldFieldBeHighlighted(
    allFields,
    groupedExclusiveOrFields
  );

  return {
    error:
      error ||
      operatorChangesError ||
      tenanChangesError ||
      (missingDataError ? t("errors.UnknownError") : undefined),
    isLoading: isLoading || operatorChangesLoading || tenanChangesLoading,
    fieldsThatCouldBeMissing: allFields,
    shouldFieldBeHighlighted
  };
}

function getMissingDataAndExclusiveOrFieldsFromSiteWizardData(
  siteWizard: SiteWizard | undefined,
  locationState: unknown
): Pick<WizardDetail, "missingData" | "exclusiveOrFields"> & {
  missingDataError?: boolean;
} {
  let missingDataError = false;

  if (
    siteWizard &&
    locationState &&
    isMissingObjectDataLocationState(locationState)
  ) {
    const phaseData =
      siteWizard.phases[locationState.highlightMissingFieldsFromPhase];

    if (isPhaseData(phaseData)) {
      return {
        missingData: phaseData.missingData,
        exclusiveOrFields: phaseData.exclusiveOrFields
      };
    } else {
      const errorMessage = `The phase data is not in the correct format. It should have a missingData and exclusiveOrFields property. Or locationState.highlightMissingFieldsFromPhase (${locationState.highlightMissingFieldsFromPhase}) is not a valid phase.`;
      missingDataError = true;

      console.error(errorMessage);
      Sentry.captureMessage(errorMessage);
    }
  }

  return { missingData: [], exclusiveOrFields: [], missingDataError };
}

function getMissingDataFromOperatorChangeProcesses(
  operatorChanges: Array<OperatorChange> | undefined
) {
  const missingData: Array<WizardMissingData> = [];
  if (!operatorChanges) {
    return { missingData };
  }

  operatorChanges.forEach((operatorChange) => {
    const steps = operatorChange.steps;
    missingData.push(...(steps.stepNewData.missingData || []));
    missingData.push(...(steps.stepNewContract.missingData || []));
    missingData.push(...(steps.stepOldContract.missingData || []));
  });

  return { missingData };
}

function getMissingDataFromTenantChangeProcesses(
  tenantChanges: Array<TenantChange> | undefined
) {
  const missingData: Array<WizardMissingData> = [];
  if (!tenantChanges) {
    return { missingData };
  }

  tenantChanges.forEach((tenantChange) => {
    const steps = tenantChange.steps;
    missingData.push(...(steps.stepParticipation.missingData || []));
    missingData.push(...(steps.stepNewData.missingData || []));
    missingData.push(...(steps.stepNewContract.missingData || []));
    missingData.push(...(steps.stepOldContract.missingData || []));
    missingData.push(...(steps.stepMeteringConcept.missingData || []));
  });

  return { missingData };
}

function isMissingObjectDataLocationState(
  data: unknown
): data is MissingObjectDataLocationState {
  return (
    typeof data === "object" &&
    data !== null &&
    "highlightMissingFieldsFromPhase" in data
  );
}

function isPhaseData(data: unknown): data is PhaseData {
  return (
    typeof data === "object" &&
    data !== null &&
    "missingData" in data &&
    "exclusiveOrFields" in data
  );
}
