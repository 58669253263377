import { Group, SegmentedControl, Space } from "@mantine/core";
import classNames from "classnames";
import React, { useContext, useEffect, useMemo, useState } from "react";
import type { Meter } from "../../../utils/backend-types";
import { Product } from "../../../utils/enums";
import { Portlet } from "../../BuildingBlocks/Layout/Portlet";
import { PremiumBadge } from "../../PremiumBadge/PremiumBadge";
import { StructureViewDiagram } from "../../StructureViewDiagram/StructureViewDiagram";
import { StructureViewFlowDiagram } from "../../StructureViewFlowDiagram/StructureViewFlowDiagram";
import { VariantRename } from "../../VariantRename/VariantRename";
import { CreateComponentDropdown } from "../CreateComponentDropdown/CreateComponentDropdown";
import { PersonsWidget } from "../PersonsWidget/PersonsWidget";
import { StructureViewMode } from "../StructureView.constants";
import { StructureViewModeContext } from "../StructureViewModeContext";
import { DiagramInfoModal } from "./DiagramInfoModal/DiagramInfoModal";
import "./StructureViewDiagramContainer.scss";

const MODE_CHOICES = [
  { label: "Alte Ansicht", value: StructureViewMode.Old },
  { label: "Neue Ansicht", value: StructureViewMode.New }
];
const MODE_CHOICES_WITH_METERING_CONCEPT = [
  { label: "Alte Ansicht", value: StructureViewMode.Old },
  { label: "Neue Ansicht", value: StructureViewMode.New },
  {
    label: "Messkonzept Ansicht",
    value: StructureViewMode.MeteringConcept
  }
];

interface StructureViewDiagramContainerProps {
  enableStructureViewDiagramLoad: boolean;
  graphCoordinates: { x: number; y: number };
  hasGasConnection: boolean;
  isPremium: boolean;
  isPartialFeedin: boolean;
  isPPA: boolean;
  product: Product;
  projectId: string;
  meters?: Array<Meter>;
  siteId: number;
  variantId: number;
  handleShowNotAccessibleModal: (show: boolean) => void;
  invalidateMeterData: () => void;
  setGraphCoordinates: ({ x, y }: { x: number; y: number }) => void;
}

function StructureViewDiagramContainer({
  enableStructureViewDiagramLoad,
  graphCoordinates,
  hasGasConnection,
  isPremium,
  isPPA,
  isPartialFeedin,
  product,
  projectId,
  siteId,
  variantId,
  meters,
  handleShowNotAccessibleModal,
  invalidateMeterData,
  setGraphCoordinates
}: StructureViewDiagramContainerProps) {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const { structureViewMode, updateStructureViewMode } = useContext(
    StructureViewModeContext
  );
  const showMeteringOption = (isPartialFeedin && isPremium) || isPPA;
  const createComponentDropdownMemoized = useMemo(
    () => (
      <CreateComponentDropdown
        graphCoordinates={graphCoordinates}
        hasGasConnection={hasGasConnection}
        product={product}
        setShowNotAccessibleModal={handleShowNotAccessibleModal}
        siteId={siteId}
        variantId={variantId}
      />
    ),
    [
      graphCoordinates,
      product,
      siteId,
      variantId,
      hasGasConnection,
      handleShowNotAccessibleModal
    ]
  );

  useEffect(() => {
    if (isPPA) {
      updateStructureViewMode(StructureViewMode.MeteringConcept);
    } else {
      updateStructureViewMode(StructureViewMode.New);
    }
  }, [isPPA, isPartialFeedin, updateStructureViewMode]);

  const VariantRenameComponent = (
    <div className="VariantRename">
      <VariantRename variantId={variantId} />
    </div>
  );

  function switchToMeteringConceptMode() {
    setShowInfoModal(true);
  }

  function switchMode(mode: StructureViewMode) {
    if (mode === StructureViewMode.MeteringConcept) {
      switchToMeteringConceptMode();
    } else {
      updateStructureViewMode(mode);
    }
  }

  return (
    <Portlet
      bodyClassName="portlet-body"
      className={classNames("StructureViewDiagramContainer", {
        analyzer: product === Product.Analyzer,
        manager: product === Product.Manager
      })}
      title={product === Product.Analyzer ? VariantRenameComponent : null}
    >
      <div
        className={classNames("structure-view-diagram-top-row", {
          "with-premium-badge": isPremium
        })}
      >
        {isPremium && <PremiumBadge text="Premium Liegenschaft" />}
        <Group justify="flex-end">
          {showInfoModal && (
            <DiagramInfoModal
              onCancel={() => setShowInfoModal(false)}
              onConfirm={() => {
                setShowInfoModal(false);
                updateStructureViewMode(StructureViewMode.MeteringConcept);
              }}
            />
          )}

          <SegmentedControl
            className="structure-view-diagram-controls"
            data={
              showMeteringOption
                ? MODE_CHOICES_WITH_METERING_CONCEPT
                : MODE_CHOICES
            }
            size="md"
            value={structureViewMode}
            onChange={switchMode}
          />

          <Space w="lg" />
          {createComponentDropdownMemoized}
        </Group>
      </div>
      <div id="graph">
        {structureViewMode !== StructureViewMode.Old ? (
          <StructureViewFlowDiagram
            centerCoordinates={graphCoordinates}
            enabled={enableStructureViewDiagramLoad}
            invalidateMeterData={invalidateMeterData}
            meters={meters}
            mode={structureViewMode}
            projectId={projectId}
            report={false}
            setCoordinates={setGraphCoordinates}
            setShowNotAccessibleModal={handleShowNotAccessibleModal}
            siteId={siteId}
          />
        ) : (
          <StructureViewDiagram
            enabled={enableStructureViewDiagramLoad}
            invalidateMeterData={invalidateMeterData}
            projectId={projectId}
            report={false}
            setCoordinates={setGraphCoordinates}
            setShowNotAccessibleModal={handleShowNotAccessibleModal}
            siteId={siteId}
          />
        )}
      </div>
      <PersonsWidget siteId={siteId} variantId={variantId} />
    </Portlet>
  );
}

export { StructureViewDiagramContainer, StructureViewDiagramContainerProps };
