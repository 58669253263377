import classNames from "classnames";
import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../../BuildingBlocks/Layout/Modal/Modal";
import { Button, buttonColors } from "../../../Buttons/Button/Button";
import "./ColorSelectionModal.scss";

interface ColorSelectionModalProps {
  onColorChange: (color: string) => void;
  onExit: () => void;
  currentlySelectedColor?: string;
}
const COLORS = [
  "#88DDD9",
  "#96D6B9",
  "#FECA99",
  "#FDE498",
  "#EDDEFF",
  "#F29E91",
  "#C3E5ED",
  "#E1EAED"
];
function ColorSelectionModal({
  onColorChange,
  onExit,
  currentlySelectedColor: initialColor
}: ColorSelectionModalProps) {
  const [currentlySelectedColor, setCurrentlySelectedColor] =
    useState(initialColor);
  return (
    <div className="ColorSelectionModal">
      <Modal isOpen>
        <ModalHeader>Farbe wählen</ModalHeader>
        <ModalBody>
          <div className="ColorSelectionModal__colors">
            {COLORS.map((color) => (
              <div
                className={classNames("ColorSelectionModal__color", {
                  highlighted: color === currentlySelectedColor
                })}
                key={color}
                style={{ backgroundColor: color }}
                onClick={() => {
                  setCurrentlySelectedColor(color);
                  onColorChange(color);
                }}
              />
            ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color={buttonColors.secondary} onClick={onExit}>
            Fertig
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export { ColorSelectionModal };
