import React, { useCallback, useEffect, useState } from "react";
import type { FormFieldValue } from "../../../../../DynamicForm/FormItems/FormField/FormField";
import type { FormFieldData } from "../../../../../DynamicForm/FormItems/FormItems";
import type { GeneralDataField } from "../../Data/common";
import type { FormSection } from "../../Data/data-types";
import { SectionMemoized } from "../EditForms";

interface FormWithSectionsProps {
  exclusiveOrFields: Array<Array<string>> | null;
  extraProps?: Record<string, unknown>;
  missingFields: Array<string> | null;
  sections: Array<FormSection>;
  fieldSatisfiesDependencies: (field: GeneralDataField) => boolean;
  isHighlighted: (
    missingFields: Array<string> | undefined,
    exclusiveOrFields: Array<Array<string>> | undefined,
    formItem: FormFieldData
  ) => boolean;
  onUpdateValues: (
    sectionIndex: number,
    valueKeyValuePair: Record<string, FormFieldValue>
  ) => void;
}

function FormWithSections({
  exclusiveOrFields,
  extraProps,
  missingFields,
  sections,
  fieldSatisfiesDependencies,
  isHighlighted,
  onUpdateValues
}: FormWithSectionsProps) {
  // Warning: while `sections` should never be null or undefined, it's possible bad data
  // can be passed in from a javascript file. This check can be safely removed after
  // ComponentEditWizard and its associated files are converted to typescript.
  if (!sections) {
    return null;
  }

  return (
    <div className="FormSections">
      {sections.map((section, index) => {
        return section.Component ? (
          <section.Component key={index} {...section} />
        ) : (
          <SectionWrapper
            exclusiveOrFields={exclusiveOrFields}
            extraProps={extraProps}
            fieldSatisfiesDependencies={fieldSatisfiesDependencies}
            index={index}
            isHighlighted={isHighlighted}
            key={index}
            missingFields={missingFields}
            section={section}
            sectionFormName={`form${index + 1}`}
            onUpdateValues={onUpdateValues}
          />
        );
      })}
    </div>
  );
}
interface SectionWrapperProps {
  exclusiveOrFields: Array<Array<string>> | null;
  extraProps?: Record<string, unknown>;
  missingFields: Array<string> | null;
  section: FormSection;
  index: number;
  sectionFormName: string;
  fieldSatisfiesDependencies: (field: GeneralDataField) => boolean;
  isHighlighted: (
    missingFields: Array<string> | undefined,
    exclusiveOrFields: Array<Array<string>> | undefined,
    formItem: FormFieldData
  ) => boolean;
  onUpdateValues: (
    sectionIndex: number,
    valueKeyValuePair: Record<string, FormFieldValue>
  ) => void;
}
function SectionWrapper({
  section,
  index,
  sectionFormName,
  extraProps,
  onUpdateValues,
  fieldSatisfiesDependencies,
  isHighlighted,
  missingFields,
  exclusiveOrFields
}: SectionWrapperProps) {
  const [fields, setFields] = useState(section.fields);

  const onInputMemoized = useCallback(
    (name, value) => {
      // either name must be the name of the form field and value the value it's set to
      // or name can be `null` and value an object name, value pairs. This is to allow updates of multiple fields
      // in one `handleInput` call
      if (name === null) {
        const safeValues = Object.keys(value).reduce((multipleValues, key) => {
          multipleValues[key] =
            typeof value[key] === "undefined" ? null : value[key];
          return multipleValues;
        }, {});

        onUpdateValues(index, safeValues);
      } else {
        onUpdateValues(index, {
          [name]: typeof value === "undefined" ? null : value
        });
      }
    },
    [index, onUpdateValues]
  );

  useEffect(() => {
    const fieldsThatSatisfyDependencies = section.fields.filter(
      fieldSatisfiesDependencies
    );

    if (fieldsThatSatisfyDependencies.length !== fields.length) {
      setFields(fieldsThatSatisfyDependencies);
    }
  }, [fieldSatisfiesDependencies, fields.length, section]);

  if (!fields) {
    return null;
  }

  return (
    <SectionMemoized
      errors={section.errors}
      exclusiveOrFields={exclusiveOrFields ?? undefined}
      extraProps={extraProps}
      fields={fields}
      header={section.header ? section.header : undefined}
      helpText={section.helpText ? section.helpText : undefined}
      initialToggle={section.initialToggle}
      isCollapsible={section.isCollapsible}
      isHighlighted={isHighlighted}
      isSubSection={section.isSubSection}
      missingFields={missingFields ?? undefined}
      name={sectionFormName}
      values={section.values}
      onInput={onInputMemoized}
    />
  );
}

export { FormWithSections, FormWithSectionsProps };
