import React, { useMemo } from "react";
import type { Column, RowInfo } from "react-table";

import type { Meter } from "../../../../../utils/backend-types";
import { compareByComparisonOperators } from "../../../../../utils/compareByComparisonOperators";
import { sortBackendDates } from "../../../../../utils/dates/sortBackendDates";
import type { CustomReactTableWithSelectProps } from "../../../../CustomReactTable/CustomReactTableWithSelect/CustomReactTableWithSelect";
import { PagedCustomReactTableWithSelect } from "../../../../CustomReactTable/CustomReactTableWithSelect/PagedCustomReactTableWithSelect";
import type { EnergyDataImportRequest } from "../../../EnergyDataUploadFlow/EnergyDataUpload";
import {
  EnergyDataFileImportRequestModalType,
  EnergyDataFileImportRequestStatus,
  getTypeOfDataLabel
} from "../../../EnergyDataUploadFlow/EnergyDataUpload";
import { IconCell } from "./IconCell";
import { NameCell } from "./NameCell";
import { StatusCell } from "./StatusCell";
import { SupportCell } from "./SupportCell";

const getCustomRowClassName = (_, rowInfo: RowInfo) =>
  rowInfo.row.status === EnergyDataFileImportRequestStatus.Stopped
    ? { className: "highlighted" }
    : {};

enum TableColumn {
  Name = "energyDataFile__name",
  Meter = "selectedMeter",
  Status = "status",
  Type = "typeOfData",
  Created = "created",
  Support = "supportRequested"
}

interface Props
  extends CustomReactTableWithSelectProps<EnergyDataImportRequest> {
  meters: Meter[];
  count: number;
  setModal: (
    type: EnergyDataFileImportRequestModalType,
    isOpen: boolean,
    selectedImportRequest?: EnergyDataImportRequest
  ) => void;
  fetchData: (page: number, pageSize: number, ordering?: string) => void;
}

function EnergyDataImportRequestsTable({
  meters,
  setModal,
  fetchData,
  data,
  ...reactTableNativeProps
}: Props) {
  const tableColumns: Array<Column<EnergyDataImportRequest>> = useMemo(
    () => [
      {
        Header: "",
        Cell: (cellData) => <IconCell file={cellData.original} />,
        width: 30,
        sortable: false,
        resizable: false
      },
      {
        Header: "Name",
        accessor: TableColumn.Name,
        Cell: (cellData) => (
          <NameCell file={cellData.original} setModal={setModal} />
        ),
        sortMethod: (a: { name: string }, b: { name: string }) =>
          compareByComparisonOperators(a.name, b.name)
      },
      {
        Header: "Typ",
        accessor: TableColumn.Type,
        width: 150,
        Cell: (cellData) =>
          getTypeOfDataLabel(cellData.original?.[TableColumn.Type])
      },
      {
        Header: "Status",
        accessor: TableColumn.Status,
        Cell: (cellData) => <StatusCell file={cellData.original} />
      },
      {
        Header: "Hochgeladen am",
        accessor: TableColumn.Created,
        width: 150,
        sortMethod: sortBackendDates
      },
      {
        Header: "Supportanfrage",
        accessor: TableColumn.Support,
        width: 125,
        sortable: false,
        resizable: false,
        Cell: (cellData) => (
          <SupportCell
            file={cellData.original}
            onRequestSupport={() =>
              setModal(
                EnergyDataFileImportRequestModalType.Support,
                true,
                cellData.original
              )
            }
          />
        )
      }
    ],
    [setModal]
  );

  const preparedData = useMemo(
    () =>
      data?.map((row) => ({
        ...row,
        disabledCheckbox:
          row.status === EnergyDataFileImportRequestStatus.InProgress
      })),
    [data]
  );

  return (
    <PagedCustomReactTableWithSelect<EnergyDataImportRequest>
      columns={tableColumns}
      data={preparedData}
      defaultSorted={[
        {
          id: TableColumn.Created,
          desc: true
        }
      ]}
      fetchData={fetchData}
      getTrProps={getCustomRowClassName}
      name="Energiedaten"
      {...reactTableNativeProps}
    />
  );
}

export { EnergyDataImportRequestsTable };
