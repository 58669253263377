import React, { useState, type ChangeEvent } from "react";

import type { TodoNote } from "../../../../utils/backend-types";
import { showToast } from "../../../../utils/toast";
import { Checkbox } from "../../../BuildingBlocks/Forms/Checkbox/Checkbox";
import { Button } from "../../../Buttons/Button/Button";
import { SpinButton } from "../../../Buttons/SpinButton/SpinButton";
import Multiline from "../../../DynamicForm/FormItems/FormField/Multiline/Multiline";
import { WhichUserModal } from "../../common";
import type { NoteMutation } from "./Notes.types";

interface NotesEntryProps {
  note?: TodoNote;
  isStaffUser: boolean;
  submitButtonText: string;
  onSaveNote: NoteMutation;
  onSaveNoteDone?: (note: TodoNote) => void;
  onCancel?: () => void;
}

function NotesEntry({
  note,
  isStaffUser,
  submitButtonText,
  onSaveNote,
  onSaveNoteDone,
  onCancel
}: NotesEntryProps) {
  const [text, setText] = useState(note?.note ?? "");
  const [internalChecked, setInternalChecked] = useState(
    note?.internal ?? false
  );
  const [submitting, setSubmitting] = useState(false);
  const [showSystemUserQuestionModal, setShowSystemUserQuestionModal] =
    useState(false);

  function handleChangeText(e: ChangeEvent<HTMLTextAreaElement>) {
    setText(e.target.value);
  }

  function handleClickSave() {
    const isEditing = !!note?.internal;

    setSubmitting(true);

    if (isStaffUser && !isEditing) {
      setShowSystemUserQuestionModal(true);
    } else {
      saveNote();
    }
  }

  function handleChooseWhichUser(asSystemUser: boolean) {
    setShowSystemUserQuestionModal(false);
    saveNote(asSystemUser);
  }

  function saveNote(asSystemUser?: boolean) {
    onSaveNote({
      noteId: note?.id,
      note: text,
      internal: internalChecked,
      asSystemUser
    })
      .then((newNote) => {
        if (onSaveNoteDone) onSaveNoteDone(newNote.data);
        setText("");
        setInternalChecked(false);
      })
      .catch((error) => {
        showToast("error", error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  return (
    <div className="Notes-entry">
      <Multiline
        id="todo-notes-entry"
        name="todo-notes-entry"
        placeholder="Notiz hinzufügen"
        value={text}
        onChange={handleChangeText}
      />
      {isStaffUser && (
        <Checkbox checked={internalChecked} onChange={setInternalChecked}>
          Interne Notiz
        </Checkbox>
      )}
      <div>
        <SpinButton
          color="brand"
          disabled={text.length === 0}
          spin={submitting}
          onClick={handleClickSave}
        >
          {submitButtonText}
        </SpinButton>
        {onCancel && (
          <Button
            color="secondary"
            style={{ marginLeft: "3px" }}
            onClick={onCancel}
          >
            Abbrechen
          </Button>
        )}
      </div>
      <WhichUserModal
        isOpen={showSystemUserQuestionModal}
        questionText="Wie soll die Notiz erstellt werden?"
        toggle={() =>
          setShowSystemUserQuestionModal(!showSystemUserQuestionModal)
        }
        onClickSelf={() => handleChooseWhichUser(false)}
        onClickSystemUser={() => handleChooseWhichUser(true)}
      />
    </div>
  );
}

export { NotesEntry };
