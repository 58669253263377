import { Title } from "@mantine/core";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../routes";
import { SiteSetupProcessDataStep } from "../../types/api.types";
import { showToast } from "../../utils/toast";
import { setErrorsFromResponseData } from "../BuildingBlocks/Forms/utils/setErrorsFromResponseData";
import { Portlet } from "../BuildingBlocks/Layout/Portlet";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { SiteSetupAssistantFormControls } from "./Forms/SiteSetupAssistantFormControls/SiteSetupAssistantFormControls";
import { useSiteSetupAssistant } from "./hooks/useSiteSetupAssistant";
import { useSiteSetupAssistantMutations } from "./hooks/useSiteSetupAssistantMutations";
import "./SiteSetupAssistant.scss";
import { type SiteSetupProcessForForm } from "./SiteSetupAssistant.types";
import { SiteSetupAssistantNavigation } from "./SiteSetupAssistantNavigation/SiteSetupAssistantNavigation";
import { formatBackendDataToFrontend } from "./utils/formatBackendDataToFrontend";
import { formatFrontedDataToBackend } from "./utils/formatFrontendDataToBackend";
import { getTitleFromStep } from "./utils/getTitleFromStep";

function SiteSetupAssistant() {
  const navigate = useNavigate();
  const { projectId, siteId, siteSetupProcessId } = useParams();
  const {
    data: siteSetupAssistantData,
    isLoading,
    error
  } = useSiteSetupAssistant(siteSetupProcessId);
  const initialData = formatBackendDataToFrontend(siteSetupAssistantData);

  const [activeStep, setActiveStep] = useState<
    SiteSetupProcessDataStep | undefined
  >(undefined);
  const defaultStep = SiteSetupProcessDataStep.name_and_pv_plants;
  const [navigationBlocked, setNavigationBlocked] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { control, formState, watch, setError, getValues, setValue } =
    useForm<SiteSetupProcessForForm>({
      defaultValues: {
        ...initialData,
        pvPlants:
          initialData &&
          Array.isArray(initialData.pvPlants) &&
          initialData.pvPlants.length > 0
            ? initialData.pvPlants
            : [{ see_number: "" }]
      }
    });

  useEffect(() => {
    const frontendData = formatBackendDataToFrontend(siteSetupAssistantData);
    if (frontendData) {
      Object.keys(frontendData).forEach((key) => {
        setValue(
          key as keyof SiteSetupProcessForForm,
          key === "pvPlants" &&
            (!Array.isArray(frontendData[key]) ||
              frontendData[key].length === 0)
            ? [{ see_number: "" }]
            : frontendData[key]
        );
      });
      if (activeStep === undefined && frontendData.step) {
        setActiveStep(frontendData.step);
      }
    }
  }, [activeStep, siteSetupAssistantData, setValue]);

  const { updateMutation: updateSiteSetupAssistant } =
    useSiteSetupAssistantMutations(siteSetupProcessId);

  async function handleStep(stepTo: SiteSetupProcessDataStep) {
    await saveProgress();
    setActiveStep(stepTo);
  }

  async function handleSaveProgress() {
    await saveProgress();
    showToast("success", "Ihre Liegenschaftskonfiguration wurde gespeichert.");
    navigate(ROUTES.managerProjectList + projectId);
  }

  async function saveProgress() {
    setIsSubmitting(true);
    try {
      await updateSiteSetupAssistant.mutateAsync(
        formatFrontedDataToBackend({
          ...getValues(),
          step: activeStep || defaultStep
        })
      );
    } catch (error) {
      setErrorsFromResponseData<SiteSetupProcessForForm>(
        error,
        watch(),
        setError,
        t("errors.UnknownError")
      );
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <LoadOrError error={error} loading={isLoading}>
      {siteId && initialData && (
        <Portlet
          bodyClassName="site-setup-assistant-portlet-body"
          className="SiteSetupAssistant"
          footer={
            <SiteSetupAssistantFormControls
              activeStep={activeStep || defaultStep}
              isSubmitting={isSubmitting}
              navigationBlocked={navigationBlocked}
              onCancel={() => navigate(ROUTES.managerProjectList + projectId)}
              onSaveProgress={handleSaveProgress}
              onStep={handleStep}
            />
          }
        >
          <Title
            className="SiteSetupAssistantTitle"
            fw="500"
            mb="xl"
            mt="lg"
            order={2}
            ta="center"
          >
            {getTitleFromStep(activeStep || defaultStep)}
          </Title>
          <form className="site-setup-form">
            <SiteSetupAssistantNavigation
              formControl={control}
              formErrors={formState.errors}
              step={activeStep || defaultStep}
              onBlockNavigation={setNavigationBlocked}
              onChangeStep={(step) => handleStep(step)}
            />
          </form>
        </Portlet>
      )}
    </LoadOrError>
  );
}

export { SiteSetupAssistant };
