import type { TFunction } from "i18next";
import { isRequiredStringValid } from "../../../../../mantine/mrt/validation/isRequiredStringValid";
import type {
  DirektvermarktungGeneratorShare,
  DirektvermarktungGeneratorShareResponse
} from "../../../Direktvermarktung.types";

interface EditRowFields extends DirektvermarktungGeneratorShare {
  generator_display_name: DirektvermarktungGeneratorShareResponse["generator_display_name"];
  metering_or_market_location_display_name: DirektvermarktungGeneratorShareResponse["metering_or_market_location_display_name"];
}

/** Validates form values from a row in DirektvermarktungGeneratorShareTable. */
export function validateDirektvermarktungGeneratorShareFormValues(
  generatorShare: Partial<EditRowFields>,
  t: TFunction
) {
  return {
    generatorId: !isRequiredStringValid(generatorShare.generator_display_name)
      ? t("errors.Required")
      : "",
    meteringOrMarketLocationId: !isRequiredStringValid(
      generatorShare.metering_or_market_location_display_name
    )
      ? t("errors.Required")
      : "",
    share: validateShare(generatorShare.weighting, t)
  };
}

function validateShare(value: string | undefined | null, t: TFunction) {
  if (!isRequiredStringValid(value)) {
    return t("errors.Required");
  }

  const parsedValue = parseFloat(value);
  if (parsedValue < 0 || parsedValue > 1) {
    return t("errors.Direktvermarktung.GeneratorShares.ShareRangeError");
  }

  return "";
}
