import { Select } from "@mantine/core";
import { type MRT_ColumnDef } from "mantine-react-table";
import React from "react";
import { Priority, type Todo } from "../../../utils/backend-types";
import { TodosListColumnKeys } from "../TodoTable";

const priorityLabelMapping = {
  [Priority.High]: "Hoch",
  [Priority.Medium]: "Mittel",
  [Priority.Low]: "Niedrig",
  [Priority.Unknown]: "Keine"
};

const priorityFilterData = Object.keys(priorityLabelMapping).map((key) => ({
  value: key,
  label: priorityLabelMapping[key]
}));

export function getPriorityColumn(
  handleChangePriority: (priorityValue: Priority, todoId: number) => void
): MRT_ColumnDef<Todo> {
  return {
    accessorKey: TodosListColumnKeys.Priority,
    header: "Priorität",
    Header: () => (
      <>
        Priorität{" "}
        {/* add when Bora and Paulina have written the text: <IconHelpText helpText="" /> */}
      </>
    ),
    enableGrouping: false,
    Cell: ({ row }) => (
      <Select
        allowDeselect={false}
        className={"priority-" + row.original.priority}
        data={Object.keys(priorityLabelMapping).map((key) => ({
          value: key,
          label: priorityLabelMapping[key]
        }))}
        value={row.original.priority}
        onChange={(value) =>
          handleChangePriority(value as Priority, row.original.id)
        }
      />
    ),
    filterVariant: "select",
    mantineFilterSelectProps: {
      data: priorityFilterData,
      searchable: false,
      dropdownOpened: true
    }
  };
}
