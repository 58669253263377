import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import urls from "../../../urls";
import type {
  DirektvermarktungGeneratorShareFormValues,
  DirektvermarktungGeneratorShareResponse
} from "../Direktvermarktung.types";
import type { UseDirektvermarktungUnassignedGeneratorsProps } from "./useDirektvermarktungUnassignedGenerators";

interface CreateMutationData {
  formValues: DirektvermarktungGeneratorShareFormValues;
  contractId: string;
}

interface MutationData extends CreateMutationData {
  localGeneratorShare: DirektvermarktungGeneratorShareResponse;
}

export function useDirektvermarktungGeneratorShareMutations(
  contractInfo: UseDirektvermarktungUnassignedGeneratorsProps
) {
  const queryClient = useQueryClient();

  const createMutation = useMutation({
    mutationFn: (data: CreateMutationData) =>
      createDirektvermarktungGeneratorShare(data.formValues, data.contractId),
    onMutate: handleCreateOrEditMutate,
    onSettled: handleSettled
  });

  const editMutation = useMutation({
    mutationFn: (data: MutationData) =>
      editDirektvermarktungGeneratorShare(
        data.localGeneratorShare.generator_id,
        data.formValues,
        data.contractId
      ),
    onMutate: handleCreateOrEditMutate,
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ["direktvermarktungContracts"]
      })
  });

  const deleteMutation = useMutation({
    mutationFn: (generatorId: number) =>
      deleteDirektvermarktungGeneratorShare(
        contractInfo.contractUuid,
        generatorId
      ),
    onSettled: handleSettled
  });

  async function handleCreateOrEditMutate() {
    await queryClient.cancelQueries({
      queryKey: ["direktvermarktungContracts"]
    });
  }

  async function handleSettled() {
    await queryClient.invalidateQueries({
      queryKey: ["direktvermarktungUnassignedGenerators", contractInfo]
    });

    return queryClient.invalidateQueries({
      queryKey: ["direktvermarktungContracts"]
    });
  }

  return {
    createMutation,
    editMutation,
    deleteMutation
  };
}

function createDirektvermarktungGeneratorShare(
  formValues: DirektvermarktungGeneratorShareFormValues,
  contractId: string
) {
  const url =
    urls.apiWithoutCamelization.direktvermarktungGeneratorShares(contractId);
  return apiWithoutCamelization.post<DirektvermarktungGeneratorShareResponse>(
    url,
    {
      ...formValues,
      contract_id: contractId
    }
  );
}

function editDirektvermarktungGeneratorShare(
  generatorId: number,
  formValues: DirektvermarktungGeneratorShareFormValues,
  contractId: string
) {
  const url = urls.apiWithoutCamelization.direktvermarktungGeneratorShare(
    contractId,
    generatorId
  );
  return apiWithoutCamelization.patch<DirektvermarktungGeneratorShareResponse>(
    url,
    {
      ...formValues,
      contract_id: contractId
    }
  );
}

function deleteDirektvermarktungGeneratorShare(
  contractId: string,
  generatorId: number
) {
  const url = urls.apiWithoutCamelization.direktvermarktungGeneratorShare(
    contractId,
    generatorId
  );
  return apiWithoutCamelization.delete(url);
}
