import React from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes } from "react-router";
import { withRouteProjectId } from "../../hocs/withRouteProjectId";
import { useRouteVariantId } from "../../hooks/useRouteVariantId";
import { useVariantSite } from "../../hooks/useVariantSite";
import { ROUTES } from "../../routes";
import {
  AnalyzeView,
  DEFAULT_SUBPAGE as DEFAULT_ANALYSE_VIEW_SUBPAGE
} from "../AnalyzeView/AnalyzeView";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { Report } from "../Report/Report";
import {
  DEFAULT_SUBPAGE as DEFAULT_VARIANT_COMPARISON_VIEW_SUBPAGE,
  VariantComparisonView
} from "../VariantComparisonView/VariantComparisonView";
import { AnalyzerStructureView } from "./AnalyzerStructureView";
import { MissingDataStructureView } from "./MissingDataStructureView";

const AnalyzerStructureViewWithRouteProjectId = withRouteProjectId(
  AnalyzerStructureView,
  true
);

const MissingDataStructureViewWithRouteProjectId = withRouteProjectId(
  MissingDataStructureView,
  true
);

const AnalyzeViewWithRouteProjectId = withRouteProjectId(AnalyzeView, true);

const VariantComparisonViewWithRouteProjectId = withRouteProjectId(
  VariantComparisonView,
  true
);

const ReportWithRouteProjectId = withRouteProjectId(Report, true);

function VariantRoutes() {
  const variantId = useRouteVariantId();
  const { siteId, isLoading, error } = useVariantSite(variantId);
  const { t } = useTranslation();

  const redirectPath = ROUTES.analyzerProjectList;

  const customErrors = {
    404: t("errors.VariantNotFound")
  };

  return (
    <LoadOrError
      customErrors={customErrors}
      error={error}
      errorRedirectTo={redirectPath}
      loading={isLoading}
    >
      {variantId && (
        <Routes>
          <Route
            element={
              <AnalyzerStructureViewWithRouteProjectId
                siteId={siteId}
                variantId={variantId}
              />
            }
            path="analyzer/*"
          />
          <Route
            element={
              <MissingDataStructureViewWithRouteProjectId
                siteId={siteId}
                variantId={variantId}
              />
            }
            path="bewertung/:evaluationId"
          />
          <Route path="analyse">
            <Route
              element={<Navigate replace to={DEFAULT_ANALYSE_VIEW_SUBPAGE} />}
              index
            />
            <Route
              element={<AnalyzeViewWithRouteProjectId variantId={variantId} />}
              path=":analysisPage"
            />
          </Route>
          <Route path="variantenvergleich">
            <Route
              element={
                <Navigate
                  replace
                  to={DEFAULT_VARIANT_COMPARISON_VIEW_SUBPAGE}
                />
              }
              index
            />
            <Route
              element={
                <VariantComparisonViewWithRouteProjectId
                  variantId={variantId}
                />
              }
              path=":comparisonPage"
            />
          </Route>
          <Route
            element={<ReportWithRouteProjectId variantId={variantId} />}
            path="ergebnisreport/*"
          />
        </Routes>
      )}
    </LoadOrError>
  );
}

export { VariantRoutes };
