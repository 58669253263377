import urls from "../../urls";
import type { System } from "./ThirdPartySystems.types";

export const subMeteringConfigurationImportLogsWildcardUrl = new RegExp(
  urls.api
    .subMeteringConfigurationImportLogs(123, { offset: 456, limit: 789 })
    .replace("123", "\\d+")
    .replace("456", "\\d+")
    .replace("789", "\\d+")
    .replace(/\//g, "/")
    .replace(/\?/g, "\\?")
);

export const SYSTEMS: Array<System> = [
  {
    value: "breeze_greenbyte",
    displayName: "Breeze (GreenByte)"
  },
  {
    value: "econ_3_econ_solutions",
    displayName: "econ 3 (Econ Solutions)"
  },
  {
    value: "enerboard_tengelmann",
    displayName: "EnerBoard (Tengelmann Energie)"
  },
  {
    value: "energy_platform_bosch",
    displayName: "Energy Platform (Bosch)"
  },
  {
    value: "evisor_limon",
    displayName: "é.VISOR LIMÓN"
  },
  {
    value: "e3m_emation",
    displayName: "e3m (Emation)"
  },
  {
    value: "gridvis_janitza",
    displayName: "GridVis (Janitza)"
  },
  {
    value: "navigator_siemens",
    displayName: "Navigator (Siemens)"
  },
  {
    value: "optimax_abb",
    displayName: "Optimax (ABB)"
  },
  {
    value: "power_system_enertrag",
    displayName: "PowerSystem (Enertrag)"
  },
  {
    value: "replant_its",
    displayName: "RePLANT (ITS)"
  },
  {
    value: "rotorsoft_drehpunkt",
    displayName: "Rotorsoft (Drehpunkt)"
  },
  {
    value: "siemens_gamesa_scada_solutions",
    displayName: "SCADA Solutions (Siemens Gamesa)"
  },
  {
    value: "sip_enercon",
    displayName: "SIP (Enercon)"
  },
  {
    value: "misc",
    displayName: "Sonstiges"
  },
  {
    value: "vob_vestas",
    displayName: "Vestas Online Business (Vestas)"
  },
  {
    value: "weo_bytemee",
    displayName: "Windenergie Online (Byte Mee)"
  },
  {
    value: "wis_softenergy",
    displayName: "WIS (softenergy)"
  },
  {
    value: "wonder_windguard",
    displayName: "Wonder (Windguard)"
  },
  {
    value: "qivalo",
    displayName: "Qivalo"
  },
  {
    value: "wattline",
    displayName: "Wattline"
  }
];
