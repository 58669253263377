import type { ParkabrechnungDataResponse } from "../ParkabrechnungTable/ParkabrechnungTable";

export function getAllBelieferterIds(
  data?: ParkabrechnungDataResponse,
  lieferantId?: number
) {
  if (typeof data === "undefined") {
    return [];
  }

  const allBelieferter: number[] = [];

  for (const item of data.summary) {
    if (
      item.energyDataAcquisition.identity.type ===
        "ThirdPartyDeliveryFromCompanyToCompany" &&
      item.energyDataAcquisition.identity.fromCompany === lieferantId
    ) {
      allBelieferter.push(item.energyDataAcquisition.identity.toCompany);
    }
  }

  return [...new Set(allBelieferter)];
}
