import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";

import {
  Modal,
  ModalFooter,
  ModalHeader
} from "../../../BuildingBlocks/Layout/Modal/Modal";
import { ModalBody } from "../../../BuildingBlocks/Layout/Modal/ModalBody/ModalBody";
import { Button } from "../../../Buttons/Button/Button";
import { SpinButton } from "../../../Buttons/SpinButton/SpinButton";
import { openErrorAlertPopup } from "../../../ErrorAlertPopup/openErrorAlertPopup";
import { useParagraph6CancellationMutations } from "../../hooks/useParagraph6CancellationMutations";

export interface Paragraph6ConfirmCancellationModalProps {
  creditId?: string;
  variantId: number;
  onClose: () => void;
}

function Paragraph6ConfirmCancellationModal({
  creditId,
  variantId,
  onClose
}: Paragraph6ConfirmCancellationModalProps) {
  const queryClient = useQueryClient();
  const [submitting, setSubmitting] = useState(false);
  const { paragraph6CancellationCreateMutation } =
    useParagraph6CancellationMutations(variantId);

  function onConfirm() {
    if (!creditId) return;

    setSubmitting(true);

    paragraph6CancellationCreateMutation.mutate(creditId, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["paragraph6Credits", { variantId }]
        });
      },
      onError: (error) => {
        openErrorAlertPopup(error);
      },
      onSettled: () => {
        setSubmitting(false);
        onClose();
      }
    });
  }
  return (
    <Modal isOpen={!!creditId} toggle={onClose}>
      <ModalHeader toggle={onClose}>
        Wollen Sie diese Gutschrift stornieren?
      </ModalHeader>
      <ModalBody scrollable>
        <p>
          Wenn Sie diese Gutschrift stornieren, erstellt opti.node automatisch
          einen Stornobeleg für Sie.
          <br />
          Der Status der Gutschrift kann anschließend nicht mehr verändert
          werden.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Abbrechen
        </Button>
        <SpinButton color="danger" spin={submitting} onClick={onConfirm}>
          Ja, stornieren
        </SpinButton>
      </ModalFooter>
    </Modal>
  );
}

export { Paragraph6ConfirmCancellationModal };
