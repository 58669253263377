import React from "react";

import {
  Modal,
  ModalBody,
  ModalFooter
} from "../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../Buttons/Button/Button";

function SiteStatusModal({ isOpen, onClose, header, children, ...other }) {
  return (
    <Modal isOpen={isOpen} toggle={onClose} {...other}>
      <ModalBody
        scrollable
        style={{
          display: "flex",
          flexFlow: "column",
          maxHeight: "calc(100vh - 210px)",
          overflowY: "auto"
        }}
      >
        <h5>{header}</h5>
        <div style={{ margin: "20px 0" }}>{children}</div>
      </ModalBody>

      <ModalFooter>
        <Button color="brand" style={{ alignSelf: "center" }} onClick={onClose}>
          Schließen
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export { SiteStatusModal };
