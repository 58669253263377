import React from "react";

import type { FormValues } from "../../../../CustomForm/useCustomForm";
import { OptionsForm } from "../../../../OptionsForm/OptionsForm";
import type { CreatableObject } from "./types";

interface CreateFormProps {
  postUrl: string;
  includedFields: Array<string>;
  nonFieldData: FormValues;
  formName: string;
  onSubmit: (newObject: CreatableObject) => void;
  onCancel: () => void;
}

function CreateForm({
  postUrl,
  includedFields,
  nonFieldData,
  formName,
  onSubmit,
  onCancel
}: CreateFormProps) {
  return (
    <OptionsForm
      filteredFields={includedFields}
      formName={formName}
      nonFieldData={nonFieldData}
      optionsUrl={postUrl}
      postUrl={postUrl}
      submitButtonText="Erstellen"
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  );
}

export { CreateForm };
