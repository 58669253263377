import type { QueryClient } from "@tanstack/react-query";
import { ObjectName, Product } from "../../../../../utils/enums";
import { loadData, saveDataBasic } from "./common";
import type { Form } from "./data-types";

const HIDDEN_FIELDS_IF_NULL = [
  "approvalDate",
  "ownConsumptionBefore_2014_08_01",
  "ownConsumptionBefore_2015_01_01",
  "modernized",
  "initialInstalledCapacity",
  "buildingInstalled",
  "residentialBuildingInstalled",
  "directMarketingFee",
  "eligibleForNonBuildingInstalledFeedinTariff",
  "registeredForMieterstrom",
  "operationalPurpose",
  "eligibleForTaxReliefForParticularProcesses"
];

const VISIBLE_FIELDS = [
  "name",
  "connection",
  "person",
  "commissioning",
  "decommissioning",
  "electricityPrice",
  "usageStrategy",
  "storageSize",
  "installedCapacity",
  "efficiency",
  "standbyConsumption"
];

const PRICE_DATA_FIELDS = ["electricityPrice", "usageStrategy"];

export function loadStorageData(
  queryClient: QueryClient,
  storageId: number,
  siteId: number,
  variantId: number,
  product: Product,
  showPriceDataTab: boolean
): Promise<Array<Form>> {
  return loadData({
    queryClient,
    objectName: ObjectName.Storage,
    objectId: storageId,
    siteId,
    variantId,
    visibleFields: VISIBLE_FIELDS,
    hiddenFieldsIfNull: HIDDEN_FIELDS_IF_NULL
  }).then((data: Array<Form>) => {
    const forms = [...data];

    if (product === Product.Manager) {
      const generalDataFields = forms[0].sections[0].fields.filter(
        (field) => !PRICE_DATA_FIELDS.includes(field.name)
      );
      const priceDataFields = forms[0].sections[0].fields.filter((field) =>
        PRICE_DATA_FIELDS.includes(field.name)
      );
      const allValues = forms[0].sections[0].values;
      const values = Object.keys(allValues).reduce(
        (groupedValues, key) => {
          if (PRICE_DATA_FIELDS.includes(key)) {
            groupedValues.priceData[key] = allValues[key];
          } else {
            groupedValues.general[key] = allValues[key];
          }

          return groupedValues;
        },
        {
          general: {},
          priceData: {}
        }
      );

      forms[0] = {
        ...forms[0],
        sections: [
          {
            ...forms[0].sections[0],
            fields: generalDataFields,
            values: values.general
          }
        ]
      };

      if (showPriceDataTab) {
        forms.push({
          name: "preisdaten",
          formTitle: "Preisdaten",
          sections: [
            {
              fields: priceDataFields,
              values: values.priceData,
              errors: {}
            }
          ]
        });
      }
    }

    return Promise.resolve(forms);
  });
}

export function saveStorageData(storageId: number, forms: Array<Form>) {
  return saveDataBasic(ObjectName.Storage, storageId, forms);
}
