import React from "react";
import { createRoot } from "react-dom/client";

import { getErrorText } from "../../utils/get-error-text";
import { ErrorAlertPopup } from "./ErrorAlertPopup";

const openErrorAlertPopups: Array<string | Array<string>> = [];

/** @deprecated Use showToast instead. */
export function openErrorAlertPopup(
  error: unknown,
  customErrors?: Record<number, string>,
  onClose?: () => void
) {
  const errorText = getErrorText(error, customErrors);
  const samePopupIsAlreadyOpen = !!openErrorAlertPopups.includes(errorText);

  if (!samePopupIsAlreadyOpen) {
    openErrorAlertPopups.push(errorText);

    let errorDiv: HTMLDivElement | null = document.createElement("div");
    const root = createRoot(errorDiv);
    const removeSelf = () => {
      const popupPosition = openErrorAlertPopups.findIndex(
        (popupText) => popupText === errorText
      );

      if (popupPosition > -1) {
        openErrorAlertPopups.splice(popupPosition, 1);
      }

      if (errorDiv) {
        root.unmount();
        errorDiv.remove();
        errorDiv = null;
      }

      if (onClose) {
        onClose();
      }
    };

    errorDiv.className = "error-alert-popup-error-div";
    document.body.appendChild(errorDiv);

    root.render(<ErrorAlertPopup errorText={errorText} onClose={removeSelf} />);
  }
}
