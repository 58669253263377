import classnames from "classnames";
import { DateTime } from "luxon";
import React, { useState } from "react";
import {
  Link,
  useLocation,
  useMatch,
  useParams,
  useResolvedPath,
  useSearchParams
} from "react-router-dom";
import {
  Col,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import urls from "../../../urls";
import {
  FREQUENCY_CHOICES,
  FREQUENCY_OPTIONS,
  THEME_VARS,
  UNITS
} from "../../../utils/constants";
import type { Unit } from "../../../utils/enums";
import { Frequency } from "../../../utils/enums";
import { showToast } from "../../../utils/toast";
import { Badge } from "../../BuildingBlocks/Badge/Badge";
import { Icon } from "../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../BuildingBlocks/Icon/types";
import { PageContent } from "../../BuildingBlocks/Layout/PageContent/PageContent";
import { PageHeader } from "../../BuildingBlocks/Layout/PageHeader/PageHeader";
import { Portlet } from "../../BuildingBlocks/Layout/Portlet";
import { Section } from "../../BuildingBlocks/Layout/Section";
import type { TabData } from "../../BuildingBlocks/Layout/TabBar/TabBar";
import { TabBar } from "../../BuildingBlocks/Layout/TabBar/TabBar";
import { SpinButton } from "../../Buttons/SpinButton/SpinButton";
import { CounterDataViewMode } from "../../CounterDataView/counter-data-view-types";
import type { CounterData } from "../../DataSheet/DataSheet";
import { DataSheet } from "../../DataSheet/DataSheet";
import { DatePresetType, DateRangePicker } from "../../DatePicker/DatePicker";
import { TsDropdown } from "../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import type { Identity } from "../../EdaVisualizationModal/EdaTable/Mbk";
import { AnimatedLoadingIcon } from "../../Icons/AnimatedLoadingIcon/AnimatedLoadingIcon";
import { LightAlert } from "../../LightAlert/LightAlert";
import { MonthYearRangePicker } from "../../MonthYearRangePicker/MonthYearRangePicker";
import { getActiveAcquisitionTab } from "../common";
import { AcquisitionPlot } from "./AcquisitionPlot/AcquisitionPlot";
import { DownloadButton } from "./DownloadButton/DownloadButton";
import { EnergyDataAcquisitionList } from "./EnergyDataAcquisitionList/EnergyDataAcquisitionList";
import { EnergyDataAcquisitionSelectionModal } from "./EnergyDataAcquisitionSelectionModal/EnergyDataAcquisitionSelectionModal";
import { EnergyDataAvailabilityPage } from "./EnergyDataAvailabilityPage/EnergyDataAvailabilityPage";
import { EnergyDataUploadPage } from "./EnergyDataUploadPage/EnergyDataUploadPage";
import "./EnergyDataView.scss";
import { MachineAccountPage } from "./MachineAccountPage/MachineAccountPage";
import { RawEnergyDataPage } from "./RawEnergyDataPage/RawEnergyDataPage";
import { ThirdPartySystemsTabContent } from "./ThirdPartySystemsTabContent/ThirdPartySystemsTabContent";

const MAX_DATE_SPAN = 5 * 365;
const MIN_DATE_RANGE = DateTime.fromISO("2016-01-01");
const MAX_DATE_RANGE = DateTime.now();

export const TABS = {
  TAB_ENERGY_DATA: "berechnete-energiedaten",
  TAB_RAW_ENERGY_DATA: "zaehlerdaten",
  TAB_CONSUMPTION_SHARE: "verbrauchsanteile",
  TAB_ENERGY_DATA_UPLOAD: "bereitstellen",
  TAB_THIRD_PARTY_SYSTEMS: "untermesssysteme",
  TAB_ENERGY_DATA_AVAILABILITY: "verfuegbarkeit",
  TAB_MACHINE_ACCOUNT: "machine-account"
};

const ACQUISITIONS_TABS = {
  TAB_PLOT: TABS.TAB_ENERGY_DATA + "/plot/",
  TAB_TABLE: TABS.TAB_ENERGY_DATA + "/table/",
  TAB_MISSING_DATA: TABS.TAB_ENERGY_DATA + "/missing_data/"
} as const;

// todo ts: this probably should be in EnergyData.ts when that file is converted to typescript
export interface Acquisition {
  components: Array<string>;
  connections: Array<string>;
  detailLabel: string;
  frequency: Frequency;
  id: number;
  label: string;
  medium: string;
  origin: string;
  publicId: number | null;
  site: string;
  statement: string | null;
  tags: Array<Tag>;
  type: string;
}

export interface Tag {
  color: string;
  fontColor: string;
  longName: string;
  name: string;
}

export interface EnergyDataAcquisitionInfo {
  id: number;
  identity: Omit<Identity, "displayName">;
}

export interface AcquisitionSummaryDataPoint {
  energy: number;
  energyDataAcquisition: EnergyDataAcquisitionInfo;
  maxPower: number;
}

export interface AcquisitionFilter {
  options: Array<string | { min: number; max: number }>;
  type: string;
}

export interface EnergyDataViewProps {
  acquisitions: Array<Acquisition>;
  selectedAcquisitions: Array<number>;
  acquisitionFilters: Array<AcquisitionFilter>;
  definedFilterId: number | null;
  acquisitionSummaryData: Array<AcquisitionSummaryDataPoint>;
  acquisitionSerieses?: CounterData;
  acquisitionsWithLowerFrequency: Array<Acquisition>;
  selectedFrequency: Frequency;
  selectedUnitValue: Unit;
  onSelectedAcquisitionsChanged: (
    selectedAcquisitions: Array<number>,
    acquisitionFilters: Array<AcquisitionFilter>,
    definedFilterId: number | null
  ) => void;
  onFrequencyChanged: (domName: string, newFrequency: Frequency) => void;
  onUnitChanged: (domName: string, unit: Unit) => void;
  onDatesChanged: (startDate: DateTime, endDate: DateTime) => void;
  firstDate: DateTime;
  lastDate: DateTime;
  loading: boolean;
  variantId: number;
}

function EnergyDataView({
  acquisitions,
  selectedAcquisitions,
  acquisitionFilters,
  definedFilterId,
  acquisitionSummaryData,
  acquisitionSerieses,
  acquisitionsWithLowerFrequency,
  selectedFrequency,
  selectedUnitValue,
  onSelectedAcquisitionsChanged,
  onFrequencyChanged,
  onUnitChanged,
  onDatesChanged,
  firstDate,
  lastDate,
  loading,
  variantId
}: EnergyDataViewProps) {
  const [canShowEnergyDataWarning, setCanShowEnergyDataWarning] =
    useState(true);
  const resolved = useResolvedPath(":activeTab/*");
  const match = useMatch({ path: resolved.pathname, end: true });
  const [searchParams] = useSearchParams();

  function handleDatePickerChange({
    startDate,
    endDate
  }: {
    startDate: DateTime;
    endDate: DateTime;
  }) {
    // don't allow empty range
    if (startDate && endDate) {
      onDatesChanged(startDate, endDate);
    }
  }

  const activeTab = match
    ? match.params.activeTab
    : TABS.TAB_ENERGY_DATA_AVAILABILITY;
  const showTabContentAsPortlet =
    activeTab === TABS.TAB_THIRD_PARTY_SYSTEMS ||
    activeTab === TABS.TAB_MACHINE_ACCOUNT;

  return (
    <PageContent className="EnergyDataView">
      <PageHeader title="Energiedaten" />
      <Nav className="tabs" pills>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === TABS.TAB_ENERGY_DATA_AVAILABILITY
            })}
            tag={Link}
            to={`./${
              TABS.TAB_ENERGY_DATA_AVAILABILITY
            }/?${searchParams.toString()}`}
          >
            Verfügbarkeit
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === TABS.TAB_ENERGY_DATA_UPLOAD
            })}
            tag={Link}
            to={`./${TABS.TAB_ENERGY_DATA_UPLOAD}/?${searchParams.toString()}`}
          >
            Bereitstellen
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: match?.pathname?.includes(TABS.TAB_THIRD_PARTY_SYSTEMS)
            })}
            tag={Link}
            to={`./${TABS.TAB_THIRD_PARTY_SYSTEMS}/?${searchParams.toString()}`}
          >
            Untermesssysteme
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === TABS.TAB_RAW_ENERGY_DATA
            })}
            tag={Link}
            to={`./${TABS.TAB_RAW_ENERGY_DATA}/?${searchParams.toString()}`}
          >
            Zählerdaten
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab?.includes(TABS.TAB_ENERGY_DATA)
            })}
            tag={Link}
            to={`./${
              TABS.TAB_ENERGY_DATA + "/plot"
            }/?${searchParams.toString()}`}
          >
            Berechnete Energiedaten
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === TABS.TAB_MACHINE_ACCOUNT
            })}
            tag={Link}
            to={`./${TABS.TAB_MACHINE_ACCOUNT}/?${searchParams.toString()}`}
          >
            Machine Account
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent
        activeTab={activeTab}
        tag={showTabContentAsPortlet ? Portlet : undefined}
      >
        <TabPane tabId={TABS.TAB_ENERGY_DATA_AVAILABILITY}>
          {activeTab === TABS.TAB_ENERGY_DATA_AVAILABILITY && (
            <EnergyDataAvailabilityPage variantId={variantId} />
          )}
        </TabPane>
        <TabPane tabId={TABS.TAB_RAW_ENERGY_DATA}>
          {activeTab === TABS.TAB_RAW_ENERGY_DATA && (
            <RawEnergyDataPage showPlausibility variantId={variantId} />
          )}
        </TabPane>
        <TabPane
          className="ThirdPartySystems"
          tabId={TABS.TAB_THIRD_PARTY_SYSTEMS}
        >
          {match?.pathname?.includes(TABS.TAB_THIRD_PARTY_SYSTEMS) && (
            <ThirdPartySystemsTabContent variantId={variantId} />
          )}
        </TabPane>
        <TabPane tabId={TABS.TAB_ENERGY_DATA}>
          {activeTab === TABS.TAB_ENERGY_DATA && (
            <>
              <Row>
                <Col className="controls-col">
                  <Portlet>
                    <Section>
                      <Controls
                        acquisitionFilters={acquisitionFilters}
                        acquisitions={acquisitions}
                        definedFilterId={definedFilterId}
                        firstDate={firstDate}
                        lastDate={lastDate}
                        selectedAcquisitions={selectedAcquisitions}
                        selectedFrequency={selectedFrequency}
                        selectedUnitValue={selectedUnitValue}
                        variantId={variantId}
                        onDatePickerChange={handleDatePickerChange}
                        onFrequencyChanged={onFrequencyChanged}
                        onSelectedAcquisitionsChanged={
                          onSelectedAcquisitionsChanged
                        }
                        onUnitChanged={onUnitChanged}
                      />
                    </Section>
                    <Section>
                      {canShowEnergyDataWarning &&
                        acquisitionsWithLowerFrequency.length > 0 && (
                          <EnergyDataViewWarning
                            acquisitionsWithLowerFrequency={
                              acquisitionsWithLowerFrequency
                            }
                            selectedFrequency={selectedFrequency}
                            onHide={() => setCanShowEnergyDataWarning(false)}
                          />
                        )}
                    </Section>
                    <Section className="acquisition-tabs-section">
                      {selectedAcquisitions.length > 0 && (
                        <AcquisitionTabs
                          acquisitionSerieses={acquisitionSerieses}
                          loading={loading}
                          selectedUnitValue={selectedUnitValue}
                        />
                      )}
                    </Section>
                  </Portlet>
                </Col>
              </Row>
              {selectedAcquisitions.length > 0 && (
                <Row>
                  <Col>
                    <Portlet>
                      <Section>
                        <EnergyDataAcquisitionList
                          acquisitions={acquisitions}
                          acquisitionSummaryData={acquisitionSummaryData}
                          loading={loading}
                        />
                      </Section>
                    </Portlet>
                  </Col>
                </Row>
              )}
            </>
          )}
        </TabPane>
        <TabPane tabId={TABS.TAB_ENERGY_DATA_UPLOAD}>
          {activeTab === TABS.TAB_ENERGY_DATA_UPLOAD && (
            <EnergyDataUploadPage variantId={variantId} />
          )}
        </TabPane>
        <TabPane tabId={TABS.TAB_MACHINE_ACCOUNT}>
          {activeTab === TABS.TAB_MACHINE_ACCOUNT && (
            <MachineAccountPage variantId={variantId} />
          )}
        </TabPane>
      </TabContent>
    </PageContent>
  );
}

interface ControlsProps {
  firstDate: DateTime;
  lastDate: DateTime;
  selectedFrequency: Frequency;
  selectedUnitValue: string;
  acquisitions: Array<Acquisition>;
  selectedAcquisitions: Array<number>;
  acquisitionFilters: Array<AcquisitionFilter>;
  definedFilterId: number | null;
  variantId: number;
  onSelectedAcquisitionsChanged: (
    selectedAcquisitions: Array<number>,
    acquisitionFilters: Array<AcquisitionFilter>,
    definedFilterId: number | null
  ) => void;
  onDatePickerChange: (dates: {
    startDate: DateTime;
    endDate: DateTime | null;
  }) => void;
  onFrequencyChanged: (domName: string, newFrequency: Frequency) => void;
  onUnitChanged: (domName: string, unit: Unit) => void;
}

function Controls({
  firstDate,
  lastDate,
  selectedFrequency,
  selectedUnitValue,
  acquisitions,
  selectedAcquisitions,
  acquisitionFilters,
  definedFilterId,
  variantId,
  onSelectedAcquisitionsChanged,
  onDatePickerChange,
  onFrequencyChanged,
  onUnitChanged
}: ControlsProps) {
  function handleFrequencyChange(domName: string, newFrequency: Frequency) {
    if (selectedFrequency !== newFrequency) {
      if (newFrequency === FREQUENCY_CHOICES.MONTH.value) {
        showToast(
          "info",
          "Es können nur komplette Monate ausgewählt werden. Der ausgewählte Zeitraum wurde entsprechend angepasst."
        );
        onDatePickerChange({
          startDate: firstDate.startOf("month"),
          endDate: lastDate.endOf("month")
        });
      } else if (newFrequency === FREQUENCY_CHOICES.YEAR.value) {
        onDatePickerChange({
          startDate: firstDate.startOf("year"),
          endDate: lastDate.endOf("year")
        });
        showToast(
          "info",
          "Es können nur komplette Jahre ausgewählt werden. Der ausgewählte Zeitraum wurde entsprechend angepasst."
        );
      }
    }

    onFrequencyChanged(domName, newFrequency);
  }

  const isFrequencyMonth = selectedFrequency === Frequency.Month;
  const isFrequencyYear = selectedFrequency === Frequency.Year;
  const monthYearPickerMode = isFrequencyMonth
    ? Frequency.Month
    : isFrequencyYear
      ? Frequency.Year
      : undefined;
  const unitChoices = Object.values(UNITS);
  const showOptions = selectedAcquisitions.length > 0;

  return (
    <div className="controls">
      <Acquisitions
        acquisitionFilters={acquisitionFilters}
        acquisitions={acquisitions}
        definedFilterId={definedFilterId}
        selectedAcquisitions={selectedAcquisitions}
        variantId={variantId}
        onSelectedAcquisitionsChanged={onSelectedAcquisitionsChanged}
      />
      {showOptions && (
        <div className="options">
          <div className="range-date-picker-wrapper">
            <span className="label">Dargestellte Tage</span>
            {(isFrequencyMonth || isFrequencyYear) && (
              <MonthYearRangePicker
                end={lastDate}
                mode={monthYearPickerMode}
                start={firstDate}
                onChange={onDatePickerChange}
              />
            )}
            {!isFrequencyMonth && !isFrequencyYear && (
              <DateRangePicker
                id="energy-data-range-date-picker"
                initialEndDate={lastDate}
                initialStartDate={firstDate}
                maxDate={MAX_DATE_RANGE}
                maxSpan={MAX_DATE_SPAN}
                minDate={MIN_DATE_RANGE}
                presets={DatePresetType.MonthYear}
                onChange={onDatePickerChange}
              />
            )}
          </div>
          <div className="time-interval-dropdown">
            <span className="label">Zeitintervall</span>
            <TsDropdown
              choices={Object.values(FREQUENCY_CHOICES)}
              defaultValue={selectedFrequency}
              id="energy-data-time-intervals"
              name="time-intervals"
              required={true}
              onChange={handleFrequencyChange}
            />
          </div>
          <div className="unit-dropdown">
            <span className="label">Einheit</span>
            <TsDropdown
              choices={unitChoices}
              defaultValue={selectedUnitValue}
              id="energy-data-unit"
              name="unit"
              required={true}
              onChange={onUnitChanged}
            />
          </div>
          <div className="download">
            <DownloadButton
              disabled={selectedAcquisitions.length === 0}
              downloadRequestOptions={{
                firstDate: firstDate.toISO() as string, // todo auto convert sent dates in axios hooks
                lastDate: lastDate.toISO() as string, // todo auto convert sent dates in axios hooks
                frequency: selectedFrequency,
                unit: selectedUnitValue,
                energyDataAcquisitions: selectedAcquisitions
              }}
              downloadRequestUrl={urls.api.energyDataRequestDownload()}
              downloadUrlFunc={urls.api.energyDataDownload}
            />
          </div>
        </div>
      )}
    </div>
  );
}

interface AcquisitionsProps {
  acquisitions: Array<Acquisition>;
  selectedAcquisitions: Array<number>;
  acquisitionFilters: Array<AcquisitionFilter>;
  definedFilterId: number | null;
  variantId: number;
  onSelectedAcquisitionsChanged: (
    selectedAcquisitions: Array<number>,
    acquisitionFilters: Array<AcquisitionFilter>,
    definedFilterId: number | null
  ) => void;
}

function Acquisitions({
  acquisitions,
  selectedAcquisitions,
  acquisitionFilters,
  definedFilterId,
  variantId,
  onSelectedAcquisitionsChanged
}: AcquisitionsProps) {
  const { projectId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(true);

  function removeAcquisition(acquisitionId: number): void {
    onSelectedAcquisitionsChanged(
      selectedAcquisitions.filter(
        (selectedAcquisitionId) => selectedAcquisitionId !== acquisitionId
      ),
      acquisitionFilters,
      definedFilterId
    );
  }

  const selectedAcquisitionObjects = acquisitions.filter((acquisition) =>
    selectedAcquisitions.includes(acquisition.id)
  );
  const dataUrls = {
    filterOptions: urls.api.energyDataAcquisitionFilterOptions(projectId),
    definedFilters: urls.api.energyDataAcquisitionFilters(projectId)
  };

  return (
    <>
      <div
        className={classnames("acquisitions", {
          "no-selected": selectedAcquisitions.length === 0
        })}
      >
        <div className="buttons-and-explanation-text">
          <div
            className={classnames("no-selected-text", {
              "no-selected": selectedAcquisitions.length === 0
            })}
          >
            <p>
              Es wurden noch keine Daten für die Anzeige ausgewählt. Wählen Sie
              hier einen Betrachtungszeitraum sowie die gewünschten Energiedaten
              aus.
            </p>
          </div>
          <div className="buttons">
            <SpinButton
              color="brand"
              size={selectedAcquisitions.length === 0 ? "lg" : undefined}
              spin={isModalLoading}
              onClick={() => setIsModalOpen(true)}
            >
              Energiedaten auswählen
            </SpinButton>
          </div>
        </div>
        <div className="acquisition-tags">
          {selectedAcquisitionObjects.map((acquisition) => (
            <AcquisitionTag
              key={acquisition.id}
              label={acquisition.label}
              onRemoveTag={() => removeAcquisition(acquisition.id)}
            />
          ))}
        </div>
      </div>
      <EnergyDataAcquisitionSelectionModal
        dataUrls={dataUrls}
        initialAcquisitionFilters={acquisitionFilters}
        initialDefinedFilterId={definedFilterId}
        initialSelectedAcquisitions={selectedAcquisitions}
        isOpen={isModalOpen}
        projectId={projectId}
        variantId={variantId}
        onLoadingDone={() => setIsModalLoading(false)}
        onSelectedAcquisitionsChanged={onSelectedAcquisitionsChanged}
        onToggle={() => setIsModalOpen(!isModalOpen)}
      />
    </>
  );
}

function AcquisitionTag({
  label,
  onRemoveTag
}: {
  label: string;
  onRemoveTag: () => void;
}) {
  return (
    <Badge className="acquisition-tag" color="light" darkText>
      {label}{" "}
      <Icon
        className="remove-tag-icon"
        name={IconName.Times}
        onClick={onRemoveTag}
      />
    </Badge>
  );
}

interface EnergyDataViewWarningProps {
  acquisitionsWithLowerFrequency: Array<Acquisition>;
  selectedFrequency: Frequency;
  onHide: () => void;
}

function EnergyDataViewWarning({
  acquisitionsWithLowerFrequency,
  selectedFrequency,
  onHide
}: EnergyDataViewWarningProps) {
  const [moreInfo, setMoreInfo] = useState(false);

  const toggleCollapse = () => setMoreInfo(!moreInfo);
  const linkText = moreInfo ? "Weniger anzeigen" : "Mehr anzeigen";
  const formattedAcquisitions = acquisitionsWithLowerFrequency.map((a) => (
    <li key={a.id}>
      {`${a.label}`} <b>[{`${a.frequency}`}]</b>
    </li>
  ));
  const formattedSelectedFrequency = FREQUENCY_OPTIONS[selectedFrequency];

  return (
    <LightAlert toggle={onHide}>
      Einige Energiedaten sind für das Zeitintervall{" "}
      <b>{formattedSelectedFrequency}</b> nicht verfügbar.
      <Collapse isOpen={moreInfo}>
        <p>
          Folgende Energiedaten liegen nicht im Zeitintervall{" "}
          {formattedSelectedFrequency} vor bzw. können nicht aggregiert werden.
        </p>
        <ul>{formattedAcquisitions}</ul>
      </Collapse>
      <div>
        <a href="#" onClick={toggleCollapse}>
          {linkText}
        </a>
      </div>
    </LightAlert>
  );
}

interface AcquisitionTabsProps {
  acquisitionSerieses?: CounterData;
  selectedUnitValue: Unit;
  loading: boolean;
}

function AcquisitionTabs({
  acquisitionSerieses,
  selectedUnitValue,
  loading
}: AcquisitionTabsProps) {
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState(getActiveAcquisitionTab(pathname));
  const dataIds =
    acquisitionSerieses && Object.keys(acquisitionSerieses.header);

  const tabs: Array<TabData> = [
    {
      id: ACQUISITIONS_TABS.TAB_PLOT,
      label: "Diagramm"
    },
    {
      id: ACQUISITIONS_TABS.TAB_TABLE,
      label: "Tabelle"
    }
  ];

  return (
    <React.Fragment>
      <TabBar activeTab={activeTab} tabs={tabs} onActivateTab={setActiveTab} />
      <TabContent activeTab={activeTab}>
        <TabPane tabId={ACQUISITIONS_TABS.TAB_PLOT}>
          <div className="plot-with-loader">
            {acquisitionSerieses && (
              <AcquisitionPlot
                acquisitionSerieses={acquisitionSerieses}
                selectedUnitValue={selectedUnitValue}
              />
            )}
            {loading && <AnimatedLoadingIcon color={THEME_VARS.primaryColor} />}
          </div>
        </TabPane>
        <TabPane tabId={ACQUISITIONS_TABS.TAB_TABLE}>
          <div className="plot-with-loader">
            <React.Fragment>
              {acquisitionSerieses && dataIds && (
                <DataSheet
                  initialData={acquisitionSerieses}
                  mode={CounterDataViewMode.DoubleIndex}
                  pageSize={96}
                  readOnly={true}
                />
              )}
            </React.Fragment>
            {loading && <AnimatedLoadingIcon color={THEME_VARS.primaryColor} />}
          </div>
        </TabPane>
      </TabContent>
    </React.Fragment>
  );
}

export { EnergyDataView };
