import type {
  Contract,
  Delivery,
  Site
} from "../../../../../utils/backend-types";

export function getSiteNameFromContractAndDeliveries(
  contract: Contract,
  deliveries: Array<Delivery>,
  sites: Array<Site>
): string {
  let output = "";
  const deliveryForContract = deliveries.find(
    (delivery) => delivery.contract === contract.id
  );
  if (deliveryForContract) {
    const foundSite = sites.find(
      (site) => site.id === deliveryForContract.site
    );
    if (foundSite) {
      output = foundSite.name;
    }
  }
  return output;
}
