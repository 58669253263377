import api from "../../../../api";
import urls from "../../../../urls";

interface Activate2FaMethodRes {
  details: string;
}

export async function activate2FaMethod(method: string) {
  const { data } = await api.post<Activate2FaMethodRes>(
    urls.auth.activate2FaMethod(method)
  );
  return data;
}
