import { generatePath } from "react-router-dom";
import type { PathMatch } from "react-router-dom";
import { ROUTES } from "../routes";
import { OBJECT_NAMES_TO_OBJECT_URL_NAMES } from "./enums";

export function getLinkToComponentEditModal(
  objectName: string,
  componentId: number,
  managerMatch?: PathMatch<"projectId" | "siteId">,
  analyzerMatch?: PathMatch<"projectId" | "variantId">,
  evaluationMatch?: PathMatch<"projectId" | "variantId">
) {
  let path: string;
  if (managerMatch) {
    path = generatePath(ROUTES.managerVariantStructureComponent, {
      projectId: managerMatch.params.projectId ?? null,
      siteId: managerMatch.params.siteId ?? null,
      objectUrlName: OBJECT_NAMES_TO_OBJECT_URL_NAMES[objectName],
      componentId: componentId.toString()
    });
  } else if (analyzerMatch) {
    path = generatePath(ROUTES.analyzerVariantStructureComponent, {
      projectId: analyzerMatch.params.projectId ?? null,
      variantId: analyzerMatch.params.variantId ?? null,
      objectUrlName: OBJECT_NAMES_TO_OBJECT_URL_NAMES[objectName],
      componentId: componentId.toString()
    });
  } else if (evaluationMatch) {
    path = generatePath(ROUTES.analyzerVariantStructureComponent, {
      projectId: evaluationMatch.params.projectId ?? null,
      variantId: evaluationMatch.params.variantId ?? null,
      objectUrlName: OBJECT_NAMES_TO_OBJECT_URL_NAMES[objectName],
      componentId: componentId.toString()
    });
  } else {
    console.error("Invalid use of ComponentOpenEditModalLink.");
    return null;
  }
  return path;
}
