import type { Method } from "axios";

import api from "../../api";

type FormDataObject = Record<string, null>;

/** Delete one or more files at a given endpoint with optional additional data. */
export function deleteFile<T>(
  endpointUrl: string,
  formDataFileKey: string,
  requestMethod: Method = "POST"
) {
  const formData = new FormData();
  // workaround for edge < 18 because formData has no way to traverse the actual data.
  const formDataObject: FormDataObject = {
    [formDataFileKey]: null
  };

  formData["formDataObject"] = formDataObject;

  return api.request<T>(requestMethod, endpointUrl, formData, {
    headers: {
      "content-type": "multipart/form-data"
    }
  });
}
