import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Label, Row, Col, Input } from "reactstrap";

export default class VariantSelectionPopoverBodyContent extends PureComponent {
  render() {
    const {
      variants,
      selectedVariantIds,
      onVariantSelect,
      onVariantDeselect,
      maxSelectable
    } = this.props;
    const selectionDisabled = selectedVariantIds.length >= maxSelectable;
    const filteredVariants = variants.filter(
      (variant) => variant.isManagerVariant === false
    );

    return (
      <div className="VariantSelectionPopoverBodyContent">
        {filteredVariants.map((variant) => (
          <VariantSelection
            key={variant.id}
            selectedVariantIds={selectedVariantIds}
            selectionDisabled={selectionDisabled}
            variant={variant}
            onVariantDeselect={onVariantDeselect}
            onVariantSelect={onVariantSelect}
          />
        ))}
      </div>
    );
  }
}

VariantSelectionPopoverBodyContent.propTypes = {
  variants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string
    })
  ).isRequired,
  selectedVariantIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onVariantSelect: PropTypes.func.isRequired,
  onVariantDeselect: PropTypes.func.isRequired,
  maxSelectable: PropTypes.number.isRequired
};

const VariantSelection = ({
  variant,
  selectionDisabled,
  selectedVariantIds,
  onVariantSelect,
  onVariantDeselect
}) => {
  return (
    <Row className="variant">
      <Col>
        <div className="m-checkbox-list">
          <VariantCheckbox
            key={variant.id}
            selectedVariantIds={selectedVariantIds}
            selectionDisabled={selectionDisabled}
            variant={variant}
            onVariantDeselect={onVariantDeselect}
            onVariantSelect={onVariantSelect}
          />
        </div>
      </Col>
    </Row>
  );
};

const VariantCheckbox = ({
  variant,
  selectionDisabled,
  selectedVariantIds,
  onVariantSelect,
  onVariantDeselect
}) => {
  const checked = selectedVariantIds.includes(variant.id);
  return (
    <Label
      className={classNames(
        "m-checkbox",
        selectionDisabled && !checked ? "m-checkbox--disabled" : null
      )}
    >
      <Input
        checked={checked}
        className="m-checkbox"
        disabled={selectionDisabled && !checked}
        type="checkbox"
        onChange={() =>
          checked ? onVariantDeselect(variant.id) : onVariantSelect(variant.id)
        }
      />
      <span />
      {variant.name}
    </Label>
  );
};
