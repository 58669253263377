import React from "react";
import type { To } from "react-router-dom";

import { IconName } from "../BuildingBlocks/Icon/types";
import { ActionIcon } from "./ActionIcon";

const ICON_NAME = IconName.Save;
const CLASS_NAME = "save-icon";

interface SaveIconProps {
  to?: To;
  tooltipText: string;
  onClick?: () => void;
  disabled?: boolean;
}

function SaveIcon({ ...props }: SaveIconProps) {
  return (
    <ActionIcon
      className={CLASS_NAME}
      iconName={ICON_NAME}
      {...props}
      data-testid="icon-button-save"
    />
  );
}

export { SaveIcon };
