import { useQuery } from "@tanstack/react-query";
import React, { useCallback, useState } from "react";
import api from "../../../../api";
import urls from "../../../../urls";
import { ErrorAlert } from "../../../BuildingBlocks/ErrorAlert/ErrorAlert";
import type { ImportLogsResponse } from "../../ThirdPartySystems.types";
import "./ImportLogs.scss";
import { ImportLogsTable } from "./ImportLogsTable/ImportLogsTable";

interface QueryOptions {
  limit: number;
  offset: number;
}

interface ImportLogsProps {
  configurationId: number;
  meterNames: Record<number, string>;
}

function ImportLogs({ configurationId, meterNames }: ImportLogsProps) {
  const [queryOptions, setQueryOptions] = useState<QueryOptions>({
    limit: 10,
    offset: 0
  });
  const { data, isError, error, isPending } = useQuery({
    queryKey: ["import-logs", { configurationId, queryOptions }],
    queryFn: () => fetchImportLogs(queryOptions)
  });

  async function fetchImportLogs(queryOptions: QueryOptions) {
    const response = await api.get<ImportLogsResponse>(
      urls.api.subMeteringConfigurationImportLogs(configurationId, queryOptions)
    );

    return response.data;
  }

  const handleFetchData = useCallback((state) => {
    const { page, pageSize } = state;
    const safePage = Math.floor(page);

    setQueryOptions(() => ({
      limit: pageSize,
      offset: safePage * pageSize
    }));
  }, []);

  const logs = data?.results ?? [];
  const safeCount = data?.count ?? 0;
  const numPages = Math.ceil(safeCount / queryOptions.limit);

  return (
    <div className="ImportLogs">
      {isError ? (
        <ErrorAlert error={error} />
      ) : (
        <ImportLogsTable
          isLoading={isPending}
          logs={logs}
          meterNames={meterNames}
          numPages={numPages}
          pageSize={queryOptions.limit}
          onFetchData={handleFetchData}
        />
      )}
    </div>
  );
}

export { ImportLogs, ImportLogsProps };
