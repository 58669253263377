import type { ProjectMinimal, VariantMinimal } from "../utils/backend-types";
import { useProjects } from "./useProjects";
import { useSiteVariantId } from "./useSiteVariantId";

export function useSiteProject(
  siteId?: number,
  disabled?: boolean,
  variantId?: number
) {
  const disableUseSiteVariant = disabled || !siteId || !!variantId;
  const {
    isLoading: isSiteLoading,
    error: siteError,
    variantId: siteVariantId
  } = useSiteVariantId(siteId, disableUseSiteVariant);

  const {
    isLoading: isProjectsLoading,
    error: projectsError,
    data: projects
  } = useProjects({
    enabled: !disabled && !!siteId,
    refetchInterval: false,
    refetchOnWindowFocus: false
  });

  const siteVariantIdOrVariantId = siteVariantId || variantId;
  let project: ProjectMinimal | undefined;
  let variant: VariantMinimal | undefined;

  if (siteVariantIdOrVariantId && projects) {
    for (let i = 0; i < projects.length; i++) {
      variant = projects[i].variants.find(
        (variant) => variant.id === siteVariantIdOrVariantId
      );

      if (variant) {
        project = projects[i];
        break;
      }
    }
  }

  const projectId = project ? project.id : null;
  const isLoading = isSiteLoading || isProjectsLoading;
  const error = siteError || projectsError;

  return { isLoading, error, project, projectId, variant };
}
