import type { CellContext, ColumnDef } from "@tanstack/react-table";
import React from "react";
import type { Payment } from "../../../../../../utils/backend-types";
import { IconHelpText } from "../../../../../IconHelpText/IconHelpText";
import { NumberCell } from "../Cells/NumberCell";
import type { UpdatePaymentsValue, Value } from "../PaymentsTab";

function getEstimatedAdvancePayments(
  updatePaymentsValue: UpdatePaymentsValue
): ColumnDef<Payment> {
  return {
    header: () => (
      <span>
        Geschätzter Abschlag (Brutto){" "}
        <IconHelpText
          helpText="Hier kann ein €-Betrag/Monat für den Abschlagsplan-Zeitraum hinterlegt werden.
                    Der Betrag wird auf den Abschlagsplan übernommen und als bereits geleistete
                    Zahlung für die Rechnung hinterlegt."
        />
      </span>
    ),
    enableSorting: false,
    accessorKey: "estimatedAdvancePayments",
    cell: (cellInfo: CellContext<Payment, number>) => (
      <div className="estimatedAdvancePaymentsCell">
        <NumberCell
          disabled={
            cellInfo.row.original.deliveryAmountPredictionThisYear !== null ||
            cellInfo.row.original.directDeliverySharePrediction !== null
          }
          key={cellInfo.row.original.year}
          unit="€"
          updateValue={(value: Value) =>
            updatePaymentsValue(
              value,
              {
                year: cellInfo.row.original.year,
                month: cellInfo.row.original.month
              },
              "estimatedAdvancePayments"
            )
          }
          value={cellInfo.getValue()}
        />
      </div>
    )
  };
}

export { getEstimatedAdvancePayments };
