import React from "react";
import type { NumericFormatProps } from "react-number-format";

import { RoundedNumberFormat } from "../../../../utils/RoundedNumberFormat";

interface Props {
  shareValue: number;
}

const baseProps: Partial<NumericFormatProps> = {
  decimalScale: 1,
  displayType: "text"
};

const LOW_LIMIT = 0.1;

const MissingValueCell: React.FC<Props> = ({ shareValue }: Props) => {
  if (0 < shareValue && shareValue < LOW_LIMIT) {
    return (
      <>
        {"< "}
        <RoundedNumberFormat {...baseProps} value={LOW_LIMIT} />
      </>
    );
  } else {
    return <RoundedNumberFormat {...baseProps} value={shareValue} />;
  }
};

export { MissingValueCell };
