import { useQueryClient } from "@tanstack/react-query";
import type { Dispatch, SetStateAction } from "react";
import React, { useCallback, useMemo } from "react";
import type { Column, Filter } from "react-table";

import type {
  Meter,
  MeterConfigurationData,
  Site
} from "../../../../utils/backend-types";
import { sortBackendDates } from "../../../../utils/dates/sortBackendDates";
import { SubMeteringSystems } from "../../../../utils/enums";
import { showToast } from "../../../../utils/toast";
import { CustomReactTable } from "../../../CustomReactTable/CustomReactTable";
import type { SelectedMeter } from "../../EditConfigurationModal/EditConfigurationModal";
import { updateMeterParametersCache } from "../../hooks/useMeterParameters";
import { getParameterName } from "../../utils/getParameterName";
import { matchFullTextCaseInsensitiveFilter } from "../../utils/matchFullTextCaseInsensitiveFilter";
import { updateEntitiesMeterData } from "../../utils/updateEntitiesMeterData";
import { MeterSelectionCell } from "../MeterSelectionCell/MeterSelectionCell";

declare const SITE_NAME: string;

interface MeterConfigurationTableProps {
  configurationId?: number;
  systemName: SubMeteringSystems;
  sites: Site[];
  meterParams?: MeterConfigurationData[];
  isMeterParamsLoading: boolean;
  isSuggestionsLoading: boolean;
  metersBySiteId?: Meter[];
  isMetersQueryLoading: boolean;
  selectedMeters: Array<SelectedMeter>;
  onMetersSave: (singleEntityId?: number) => Promise<{ success: boolean }>;
  setSelectedMeters: Dispatch<SetStateAction<SelectedMeter[]>>;
}

function MeterConfigurationTable({
  systemName,
  configurationId,
  sites,
  meterParams,
  isMeterParamsLoading,
  isSuggestionsLoading,
  metersBySiteId,
  isMetersQueryLoading,
  selectedMeters,
  onMetersSave,
  setSelectedMeters
}: MeterConfigurationTableProps) {
  const queryClient = useQueryClient();

  const handleRemoveMeter = useCallback(
    async (entityId: number) => {
      try {
        await updateEntitiesMeterData(configurationId as number, [
          { entityId, meterId: null }
        ]);

        updateMeterParametersCache(
          entityId,
          { meter: null },
          queryClient,
          configurationId
        );
      } catch (error) {
        showToast("error", error);
      }
    },
    [configurationId, queryClient]
  );

  const handleSelectMeter = useCallback(
    (entityId: number, meterId: number) => {
      const isMeterSelected = !!selectedMeters.find(
        (meter) => meter.subMeteringSystemEntityId === entityId
      );

      // when meter is deselected
      if (meterId === 0) {
        setSelectedMeters((prevState) =>
          prevState.filter(
            (meter) => meter.subMeteringSystemEntityId !== entityId
          )
        );
        return;
      }

      const meterName =
        metersBySiteId?.find((meter) => meter.id === meterId)?.name || "";

      const parameter = meterParams?.find((param) => param.id === entityId);

      const parameterName = getParameterName(systemName, parameter);

      if (isMeterSelected) {
        setSelectedMeters((prevState) =>
          prevState.map((selectedMeter) =>
            selectedMeter.subMeteringSystemEntityId === entityId
              ? { ...selectedMeter, meterId, meterName }
              : selectedMeter
          )
        );
        return;
      }

      setSelectedMeters((prevState) => [
        ...prevState,
        {
          subMeteringSystemEntityId: entityId,
          meterId,
          meterName,
          parameterName
        }
      ]);
    },
    [metersBySiteId, meterParams, selectedMeters, systemName, setSelectedMeters]
  );

  const tableColumns = useMemo(() => {
    const columns: Column<MeterConfigurationData>[] = [
      {
        Header: `Zähler (${SITE_NAME})`,
        accessor: "meter",
        width: 250,
        Cell: (cellData) => (
          <MeterSelectionCell
            meterParameter={cellData.original}
            metersBySiteId={metersBySiteId}
            selectedMeters={selectedMeters}
            sites={sites}
            onChange={(value) =>
              handleSelectMeter(cellData.original.id as number, Number(value))
            }
            onMeterRemove={handleRemoveMeter}
            onMeterSave={onMetersSave}
          />
        )
      },
      {
        Header: "",
        accessor: "suggestedMeter",
        show: false
      }
    ];

    switch (systemName) {
      case SubMeteringSystems.WIS:
        columns.unshift(
          {
            Header: "Anlagen ID",
            accessor: "parameters.plantId",
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "plantId")
          },
          {
            Header: "Windpark Name",
            accessor: "parameters.parkName",
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "parkName")
          },
          {
            Header: "Anlagenname",
            accessor: "parameters.plantName",
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "plantName")
          },
          {
            Header: "Seriennummer",
            accessor: "parameters.serialNumber",
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "serialNumber")
          }
        );
        break;

      case SubMeteringSystems.QIVALO:
        columns.unshift(
          {
            Header: "Windpark",
            accessor: "parameters.propertyName",
            minWidth: 150,
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "propertyName")
          },
          {
            Header: "Seriennummer des Zählers",
            accessor: "parameters.serialNumber",
            minWidth: 160,
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "serialNumber")
          },
          {
            Header: "Medium",
            accessor: "parameters.medium",
            width: 100,
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "medium")
          },
          {
            Header: "Datum der Installation es Zählers",
            accessor: "parameters.installationDate",
            width: 150,
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(
                filter,
                row,
                "installationDate"
              ),
            sortMethod: sortBackendDates,
            Cell: (cellData) => {
              return (
                <span>{cellData.value ? cellData.value : "Unbekannt"}</span>
              );
            }
          },
          {
            Header: "Einheit",
            accessor: "parameters.units",
            width: 100,
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "units")
          },
          {
            Header: "Messstellen-ID",
            accessor: "parameters.nodeId",
            minWidth: 170,
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "nodeId")
          }
        );
        break;

      case SubMeteringSystems.ROTORSOFT:
        columns.unshift(
          {
            Header: "Windparks",
            accessor: "parameters.windparkName",
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "windparkName")
          },
          {
            Header: "Anlagenname",
            accessor: "parameters.plantName",
            minWidth: 140,
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "plantName")
          },
          {
            Header: "Hersteller",
            accessor: "parameters.manufacturer",
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "manufacturer"),
            Cell: (cellData) => {
              return (
                <span>{cellData.value ? cellData.value : "Unbekannt"}</span>
              );
            }
          },
          {
            Header: "Anlagenkennung",
            accessor: "parameters.plantId",
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "plantId")
          },
          {
            Header: "Seriennummer",
            accessor: "parameters.serialNumber",
            minWidth: 150,
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "serialNumber")
          },
          {
            Header: "Name des Modells",
            accessor: "parameters.modelName",
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "modelName"),
            Cell: (cellData) => {
              return (
                <span>{cellData.value ? cellData.value : "Unbekannt"}</span>
              );
            }
          },
          {
            Header: "Installierte Leistung",
            accessor: "parameters.modelNominalPower",
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(
                filter,
                row,
                "modelNominalPower"
              ),
            Cell: (cellData) => {
              return (
                <span>
                  {cellData.value?.includes("None") ? "0.0 kW" : cellData.value}
                </span>
              );
            }
          }
        );
        break;

      case SubMeteringSystems.BYTEMEE:
        columns.unshift(
          {
            Header: "Windpark ID",
            accessor: "parameters.windparkId",
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "windparkId")
          },
          {
            Header: "Anlagenname",
            accessor: "parameters.windparkName",
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "windparkName")
          },
          {
            Header: "Seriennummer",
            accessor: "parameters.serialNumber",
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "serialNumber")
          },
          {
            Header: "Inbetriebnahme",
            accessor: "parameters.installationDate",
            width: 160,
            sortMethod: sortBackendDates,
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(
                filter,
                row,
                "installationDate"
              ),
            Cell: (cellData) => {
              return (
                <span>{cellData.value ? cellData.value : "Unbekannt"}</span>
              );
            }
          }
        );
        break;

      case SubMeteringSystems.GREENBYTE:
        columns.unshift(
          {
            Header: "ID",
            accessor: "parameters.greenbyteId",
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "greenbyteId")
          },
          {
            Header: "Name der Seite",
            accessor: "parameters.siteName",
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "siteName")
          },
          {
            Header: "Titel",
            accessor: "parameters.title",
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "title")
          },
          {
            Header: "Alternativer Titel",
            accessor: "parameters.altTitle",
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "altTitle")
          },
          {
            Header: "Datum der Installation",
            accessor: "parameters.installationDate",
            width: 150,
            sortMethod: sortBackendDates,
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(
                filter,
                row,
                "installationDate"
              ),
            Cell: (cellData) => {
              return (
                <span>{cellData.value ? cellData.value : "Unbekannt"}</span>
              );
            }
          }
        );
        break;

      case SubMeteringSystems.SIEMENS:
        columns.unshift(
          {
            Header: "Name",
            accessor: "parameters.name",
            minWidth: 180,
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "name")
          },
          {
            Header: "Name des Modells",
            accessor: "parameters.meterModel",
            minWidth: 160,
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "meterModel"),
            Cell: (cellData) => (
              <span>{cellData.value ? cellData.value : "Unbekannt"}</span>
            )
          },
          {
            Header: "Typ der Messung",
            accessor: "parameters.measurementType",
            minWidth: 160,
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "measurementType")
          },
          {
            Header: "Seriennummer",
            accessor: "parameters.serialNumber",
            width: 150,
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "serialNumber")
          },
          {
            Header: "Einheit",
            accessor: "parameters.unit",
            width: 100,
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "unit")
          },
          {
            Header: "Lesehäufigkeit in Sekunden",
            accessor: "parameters.readingFrequencyInSeconds",
            width: 130,
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(
                filter,
                row,
                "readingFrequencyInSeconds"
              )
          }
        );
        break;

      case SubMeteringSystems.WONDER:
        columns.unshift(
          {
            Header: "Windpark ID",
            accessor: "parameters.wonderId",
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "wonderId")
          },
          {
            Header: "Name",
            accessor: "parameters.name",
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "name")
          },
          {
            Header: "Hersteller",
            accessor: "parameters.manufacturer",
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "manufacturer")
          },
          {
            Header: "Seriennummer",
            accessor: "parameters.serialNumber",
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "serialNumber")
          },
          {
            Header: "Typ",
            accessor: "parameters.type",
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "type")
          },
          {
            Header: "Nennleistung",
            accessor: "parameters.nominalPower",
            width: 140,
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "nominalPower")
          }
        );
        break;

      case SubMeteringSystems.WATTLINE:
        columns.unshift(
          {
            Header: "Seriennummer",
            accessor: "parameters.serialNumber",
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(filter, row, "serialNumber")
          },
          {
            Header: "Richtung der Messung",
            accessor: "parameters.meteringDirection",
            filterMethod: (filter: Filter, row) =>
              matchFullTextCaseInsensitiveFilter(
                filter,
                row,
                "meteringDirection"
              )
          }
        );
        break;
      default:
        return;
    }

    return columns;
  }, [
    selectedMeters,
    metersBySiteId,
    sites,
    systemName,
    handleRemoveMeter,
    handleSelectMeter,
    onMetersSave
  ]);

  return (
    <CustomReactTable
      columns={tableColumns}
      data={meterParams}
      filterable
      loading={
        isMeterParamsLoading || isMetersQueryLoading || isSuggestionsLoading
      }
      minRows={0}
      pageSize={10}
      showPageJump
      showPagination
    />
  );
}

export { MeterConfigurationTable };
