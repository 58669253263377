import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { SpinButton } from "../../../Buttons/SpinButton/SpinButton";
import { LoadprofileTemplateDownload } from "./LoadprofileTemplateDownload";

import "./LoadprofileUploadButtons.scss";

export default class LoadprofileUploadButtons extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false
    };
  }

  toggleDropdown = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  render() {
    return (
      <div className="Upload">
        <LoadprofileTemplateDownload />
        <SpinButton
          color="primary"
          disabled={this.props.uploading}
          spin={this.props.uploading}
          onClick={this.props.onUploadFile}
        >
          Lastprofil hochladen
        </SpinButton>
      </div>
    );
  }
}

LoadprofileUploadButtons.propTypes = {
  onUploadFile: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired
};
