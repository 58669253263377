import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

import { Icon } from "../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import { Button } from "../../../Buttons/Button/Button";

import "./UploadDropzone.scss";

interface UploadDropzoneProps {
  onChooseFiles: (files: Array<File>) => void;
}

/** @deprecated: Use frontend/app/components/BuildingBlocks/Forms/FormFields/FileUpload/FileUpload.tsx instead **/
function UploadDropzone({ onChooseFiles }: UploadDropzoneProps) {
  const onDrop = useCallback(
    (acceptedFiles: Array<File>) => {
      onChooseFiles(acceptedFiles);
    },
    [onChooseFiles]
  );
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      "text/csv": [],
      "application/vnd.ms-excel": [],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
      "text/plain": []
    },
    onDrop
  });

  function handleClickOpenFileInput(e: React.MouseEvent) {
    e.stopPropagation();
    open();
  }

  return (
    <div className="UploadDropzone" {...getRootProps()}>
      <input {...getInputProps()} />
      <p>
        Sie können gemessene Energiedaten hier als Excel-Datei per Drag&amp;Drop
        ablegen.
      </p>
      <div className="upload-icon-container">
        <Icon className="upload-icon" name={IconName.Upload} />
      </div>
      <Button
        className="upload-button"
        color="brand"
        onClick={handleClickOpenFileInput}
      >
        Datei(en) auswählen
      </Button>
    </div>
  );
}

export { UploadDropzone };
