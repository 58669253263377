import _ from "lodash";
import type { Data } from "plotly.js";
import React from "react";
import Plot from "react-plotly.js";

import { UNITS } from "../../../../utils/constants";
import type { Unit } from "../../../../utils/enums";
import type { CounterData } from "../../../DataSheet/DataSheet";

interface AcquisitionPlotProps {
  acquisitionSerieses: CounterData;
  selectedUnitValue: Unit;
}

function AcquisitionPlot({
  acquisitionSerieses,
  selectedUnitValue
}: AcquisitionPlotProps) {
  const dataIds = Object.keys(acquisitionSerieses.header);
  const selectedUnit = UNITS[selectedUnitValue];
  const yAxisDescription = selectedUnit.physicalSize;
  const yAxisTitle = `${yAxisDescription} [${selectedUnitValue}]`;
  const valueCount = acquisitionSerieses.index
    ? acquisitionSerieses.index.length
    : 0;

  const layout = {
    showLegend: true,
    margin: { t: 18 },
    yaxis: { title: { text: yAxisTitle } }
  };

  let traces: Array<Data> = [];

  if (valueCount === 1) {
    // build bar plot
    const xs = dataIds.map((id) =>
      acquisitionSerieses.labels ? acquisitionSerieses.labels[id] : "Unbekannt"
    );
    const ys = dataIds.map((id) => acquisitionSerieses.values[id][0]);
    traces = [
      {
        type: "bar",
        x: xs,
        y: ys
      }
    ];
  } else if (valueCount > 1) {
    // build line plot
    layout["xAxis"] = { title: { text: "Zeitraum" } };
    const xs = _.unzip(acquisitionSerieses.index)[0];
    traces = dataIds.map((id) => {
      return {
        name: acquisitionSerieses.labels
          ? acquisitionSerieses.labels[id]
          : "Unbekannt",
        x: xs,
        y: acquisitionSerieses.values[id],
        type: "scatter",
        mode: "lines",
        connectgaps: false,
        line: {
          shape: "hv"
        }
      };
    });
  }

  return (
    <Plot
      data={traces}
      layout={layout}
      style={{ width: "100%" }}
      useResizeHandler={true}
    />
  );
}

export { AcquisitionPlot };
