import type { ChangeablePrice } from "../../../../DynamicForm/FormItems/FormField/ChangeablePriceField/ChangeablePriceField";
import { getCurrentDateRangeObject } from "./getCurrentDateRangeObject";

/**
 * Takes an array of prices
 * and returns the price that
 * is valid for the current date
 */
export function getCurrentPrice(prices: Array<ChangeablePrice>) {
  const price = getCurrentDateRangeObject(prices);
  if (price) {
    return price.value;
  }
  return null;
}
