const mapping = {
  positive: "Verbrauch/Entnahme",
  negative: "Erzeugung/Einspeisung",
  reactive_capacitive: "Kapazitive Blindleistung",
  reactive_inductive: "Induktive Blindleistung",
  loss_of_work: "Ausfallarbeit"
};

function mapLabelToMeasurand(label: string) {
  return Object.keys(mapping).find((key) => label.includes(mapping[key]));
}

export { mapLabelToMeasurand };
