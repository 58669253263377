import { useQuery, useQueryClient } from "@tanstack/react-query";
import PropTypes from "prop-types";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Row } from "reactstrap";

import type { ApiResponse } from "../../api";
import api from "../../api";
import urls from "../../urls";
import type { GasConnection } from "../../utils/backend-types";
import { CREATE_STEPS } from "../../utils/constants";
import { MeasurementType, ObjectName } from "../../utils/enums";
import { getPluralVariableNameFromObjectName } from "../../utils/getPluralVariableNameFromObjectName";
import { Button } from "../Buttons/Button/Button";
import { SpinButton } from "../Buttons/SpinButton/SpinButton";
import { CounterDataView } from "../CounterDataView/CounterDataView";
import {
  CustomForm,
  getFormFieldsFromResponse
} from "../CustomForm/CustomForm";
import FormItems from "../DynamicForm/FormItems/FormItems";
import { withCreatableDropdown } from "../DynamicForm/FormItems/withCreatableDropdown";
import { openErrorAlertPopup } from "../ErrorAlertPopup/openErrorAlertPopup";
import { AnimatedLoadingIcon } from "../Icons/AnimatedLoadingIcon/AnimatedLoadingIcon";
import type { FieldsArray, FieldsDict } from "../OptionsForm/OptionsForm";
import { CREATE_FORM_FIELD_NAMES_REDUCED as PERSON_FORM_FIELD_NAMES } from "../PersonWizard/PersonWizard";
import { WizardSuccessWidget } from "../VariantObjectWizard/WizardSuccessWidget/WizardSuccessWidget";

export const CREATE_FORM_FIELD_NAMES = [
  "name",
  "pressureStage",
  "gasQuality",
  "connectingParty"
];

interface GasConnectionWizard {
  siteId: number;
  variantId: number;
  buttonContainer?: HTMLElement;
  onClose: () => void;
  graphCoordinates: { x: number; y: number };
}

function GasConnectionWizard({
  siteId,
  variantId,
  buttonContainer,
  onClose,
  graphCoordinates
}: GasConnectionWizard) {
  const [step, setStep] = useState(CREATE_STEPS.CREATE_FORM);
  const [gasConnectionData, setGasConnectionData] = useState<
    GasConnection | undefined
  >(undefined);
  const [counterUpdateRequestData, setCounterUpdateRequestData] = useState({});
  const [isSavingGasConnectionData, setIsSavingGasConnectionData] =
    useState(false);
  const queryClient = useQueryClient();

  const { data: allFormFields } = useQuery({
    queryKey: ["gas-connections", { siteId }],
    queryFn: () => fetchGasConnectionOptions(siteId),
    gcTime: 0,
    refetchInterval: false,
    refetchOnWindowFocus: false
  });

  async function fetchGasConnectionOptions(siteId: number) {
    const optionsUrl: string = urls.api.gasConnections(siteId);
    let response: ApiResponse;

    try {
      response = await api.options(optionsUrl);
    } catch (error) {
      openErrorAlertPopup(error);
      return;
    }

    const postData: FieldsDict = response.data.actions.pOST;
    return postData;
  }

  function handleClickCreate(responseData: GasConnection): void {
    const objectCacheName = getPluralVariableNameFromObjectName(
      ObjectName.GasConnection
    );

    queryClient.invalidateQueries({
      queryKey: [objectCacheName, { siteOrVariantId: siteId }]
    });
    setGasConnectionData(responseData);
    showGasConnectionDataStep();
  }

  function handleSaveGasConnectionData(): void {
    setIsSavingGasConnectionData(true);
    const promises = Object.keys(counterUpdateRequestData).map((counterId) => {
      const url = urls.api.counterDataForGasConnection(parseInt(counterId, 10));
      return api.patch(url, counterUpdateRequestData[counterId]);
    });
    Promise.all(promises)
      .then(() => {
        handleGasConnectionDataStepDone();
      })
      .catch((error) => {
        openErrorAlertPopup(error);
      })
      .finally(() => setIsSavingGasConnectionData(false));
  }

  function showGasConnectionDataStep(): void {
    setStep(CREATE_STEPS.GAS_CONNECTION_DATA);
  }

  function showSuccessStep(): void {
    setStep(CREATE_STEPS.CONFIRMATION_PAGE);
  }

  function handleGasConnectionDataStepDone(): void {
    showSuccessStep();
  }

  function renderStep(): JSX.Element {
    switch (step) {
      case CREATE_STEPS.CREATE_FORM: {
        const postUrl: string = urls.api.gasConnections(siteId);

        const nonFieldData = {
          site: siteId,
          sketchElement: {
            xPosition: graphCoordinates.x,
            yPosition: graphCoordinates.y
          }
        };

        const personPostUrl = urls.api.personsByVariantId(variantId);
        const personPutUrlFunc = urls.api.person;
        const personNonFieldData = {
          variant: variantId
        };
        const FormItemsWithCreatablePerson = withCreatableDropdown(
          FormItems,
          "connectingParty",
          personPostUrl,
          personPutUrlFunc,
          PERSON_FORM_FIELD_NAMES,
          personNonFieldData
        );

        const CustomFormItemsComponent = (props) => (
          <FormItemsWithCreatablePerson {...props} />
        );

        let selectedFormFields: FieldsArray | undefined;
        if (allFormFields) {
          selectedFormFields = getFormFieldsFromResponse(
            CREATE_FORM_FIELD_NAMES,
            allFormFields
          );
        }
        return (
          <CustomForm
            buttonContainer={buttonContainer}
            CustomFormItemsComponent={CustomFormItemsComponent}
            formFields={selectedFormFields}
            nonFieldData={nonFieldData}
            postUrl={postUrl}
            submitButtonText="Erstellen"
            onCancel={onClose}
            onSubmit={handleClickCreate}
          />
        );
      }
      case CREATE_STEPS.GAS_CONNECTION_DATA:
        if (!gasConnectionData) {
          return <AnimatedLoadingIcon />;
        }

        return (
          <div className="gas-connection-data-step">
            <CounterDataView
              isGasConnectionDataView={true}
              measurementType={MeasurementType.Single}
              meterId={gasConnectionData.id}
              variantId={variantId}
              onSetRequestData={setCounterUpdateRequestData}
            />
            <div>
              {buttonContainer &&
                ReactDOM.createPortal(
                  <Row>
                    <Button
                      className="mr-2"
                      color="secondary"
                      disabled={isSavingGasConnectionData}
                      onClick={handleGasConnectionDataStepDone}
                    >
                      Überspringen
                    </Button>
                    <SpinButton
                      className="continue-button"
                      color="primary"
                      disabled={isSavingGasConnectionData}
                      spin={isSavingGasConnectionData}
                      onClick={handleSaveGasConnectionData}
                    >
                      Daten speichern
                    </SpinButton>
                  </Row>,
                  buttonContainer
                )}
            </div>
          </div>
        );
      case CREATE_STEPS.CONFIRMATION_PAGE: {
        const successMessage = "Der Gas-Netzanschlusspunkt wurde erstellt.";
        return (
          <WizardSuccessWidget
            buttonContainer={buttonContainer}
            message={successMessage}
            onClose={onClose}
          />
        );
      }
      default:
        return <div></div>;
    }
  }

  return <div className="GasConnectionWizard">{renderStep()}</div>;
}

GasConnectionWizard.propTypes = {
  siteId: PropTypes.number.isRequired,
  variantId: PropTypes.number.isRequired,
  buttonContainer: PropTypes.object,
  onClose: PropTypes.func.isRequired
};

export { GasConnectionWizard };
