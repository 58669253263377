import React from "react";
import { useShouldShowStaffView } from "../../../../StaffViewToggle/useShouldShowStaffView";
import { useParkabrechnungContractFormOptions } from "../../../hooks/useParkabrechnungContractFormOptions";
import { useParkabrechnungPoolContractMutations } from "../../../hooks/useParkabrechnungPoolContractMutations";
import type {
  ParkabrechnungContractFormOptionsSite,
  ParkabrechnungPoolContract
} from "../../../Parkabrechnung.types";
import type { ParkabrechnungContractFormState } from "./ParkabrechnungContractModal.types";
import type { ParkabrechnungContractModalUIProps } from "./ParkabrechnungContractModalUI/ParkabrechnungContractModalUI";
import { ParkabrechnungContractModalUI } from "./ParkabrechnungContractModalUI/ParkabrechnungContractModalUI";
import { parseContractToFormState } from "./utils/parseContractToFormState";

export interface ParkabrechnungContractModalProps
  extends Pick<
    ParkabrechnungContractModalUIProps,
    "sites" | "isOpen" | "onClose"
  > {
  contract?: ParkabrechnungPoolContract;
  variantId: number;
}

export function ParkabrechnungContractModal({
  contract,
  sites,
  isOpen,
  variantId,
  onClose
}: ParkabrechnungContractModalProps) {
  const showStaffView = useShouldShowStaffView();
  const {
    parkabrechnungPoolContractCreateMutation,
    parkabrechnungPoolContractEditMutation
  } = useParkabrechnungPoolContractMutations(variantId);

  const { data: dataContractFormOptions } =
    useParkabrechnungContractFormOptions({
      variantId
    });

  async function handleSubmit(
    data: ParkabrechnungContractFormState,
    selectedSite?: ParkabrechnungContractFormOptionsSite
  ) {
    if (contract) {
      await parkabrechnungPoolContractEditMutation.mutateAsync({
        contractId: contract.id,
        formState: data,
        meteringLocations: selectedSite?.meteringLocations ?? []
      });
    } else {
      await parkabrechnungPoolContractCreateMutation.mutateAsync({
        formState: data,
        variantId,
        meteringLocations: selectedSite?.meteringLocations ?? []
      });
    }

    onClose();
  }

  return (
    <ParkabrechnungContractModalUI
      defaultValues={contract ? parseContractToFormState(contract) : undefined}
      isOpen={isOpen}
      optionsSites={dataContractFormOptions?.sites}
      showStaffView={showStaffView}
      sites={sites}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
}
