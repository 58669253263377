import type { MRT_RowData, MRT_TableInstance } from "mantine-react-table";

/** Submits or cancels editable MRT row. */
export function submitOrCancelOnKeyPress<T extends MRT_RowData>(
  event: React.KeyboardEvent<HTMLInputElement>,
  table: MRT_TableInstance<T>
) {
  if (event.key === "Enter") {
    submit(event);
  } else if (event.key === "Escape") {
    cancel(table);
  }
}

function submit(event: React.KeyboardEvent<HTMLInputElement>) {
  const targetElement = event.currentTarget;
  const tableRowElement = targetElement.closest(".mantine-Table-tr");
  const saveButtonElement =
    tableRowElement?.querySelector<HTMLSpanElement>(".la-save");

  if (saveButtonElement) {
    targetElement.blur();
    saveButtonElement.click();
  }
}

function cancel<T extends MRT_RowData>(table: MRT_TableInstance<T>) {
  if (table.getState().creatingRow !== null) {
    table.setCreatingRow(null);
  } else if (table.getState().editingRow !== null) {
    table.setEditingRow(null);
  }
}
