import { FoerderRegime } from "../../../../../../types/api.types";

import type { FormFieldData } from "../../../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import type { Choice } from "../../../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import type { paragraph6ErzeugerAnteilPayload } from "../../../../Paragraph6.types";
import { getFoerderregimeLabel } from "../../../../utils/getFoerderregimeLabel";

export const PARAGRAPH_6_FOERDERREGIME_CHOICES = [
  {
    value: FoerderRegime.keine_foerderung_in_anspruch_genommen,
    displayName: getFoerderregimeLabel(
      FoerderRegime.keine_foerderung_in_anspruch_genommen
    )
  },
  {
    value: FoerderRegime.foerderung_ausgelaufen,
    displayName: getFoerderregimeLabel(FoerderRegime.foerderung_ausgelaufen)
  },
  {
    value: FoerderRegime.eeg_fixe_einspeiseverguetung,
    displayName: getFoerderregimeLabel(
      FoerderRegime.eeg_fixe_einspeiseverguetung
    )
  },
  {
    value: FoerderRegime.eeg_ausschreibung,
    displayName: getFoerderregimeLabel(FoerderRegime.eeg_ausschreibung)
  },
  {
    value: FoerderRegime.eeg_ohne_ausschreibung,
    displayName: getFoerderregimeLabel(FoerderRegime.eeg_ohne_ausschreibung)
  },
  {
    value: FoerderRegime.eeg_innovationsausschreibung,
    displayName: getFoerderregimeLabel(
      FoerderRegime.eeg_innovationsausschreibung
    )
  },
  {
    value: FoerderRegime.kwkg_zuschlag_2016_2020,
    displayName: getFoerderregimeLabel(FoerderRegime.kwkg_zuschlag_2016_2020)
  },
  {
    value: FoerderRegime.kwkg_zuschlag_2012,
    displayName: getFoerderregimeLabel(FoerderRegime.kwkg_zuschlag_2012)
  },
  {
    value: FoerderRegime.kwkg_ausschreibung,
    displayName: getFoerderregimeLabel(FoerderRegime.kwkg_ausschreibung)
  },
  {
    value: FoerderRegime.kwkg_zuschlag_2000_2002_2009,
    displayName: getFoerderregimeLabel(
      FoerderRegime.kwkg_zuschlag_2000_2002_2009
    )
  }
] as const satisfies Array<Choice>;

export const PARAGRAPH_6_GENERATOR_FORM_INPUT_DATA = {
  generator: {
    name: "generator",
    required: true,
    label: "Erzeuger",
    type: "dropdown",
    choices: [] // will be filled dynamically during runtime
  },
  eeg_inbetriebnahmedatum: {
    name: "eeg_inbetriebnahmedatum",
    label: "EEG-Inbetriebnahmedatum",
    type: "text",
    disabled: true,
    helpText:
      "Bei einer EEG-Inbetriebnahme ab dem 01.01.2021 gelten mitunter gesonderte Regelungen für die Rückerstattung der finanziellen Beteiligung nach § 6 EEG."
  },
  metering_or_market_location: {
    name: "metering_or_market_location",
    required: true,
    label: "Zählpunkt",
    type: "dropdown",
    choices: [] // will be filled dynamically during runtime
  },
  weighting: {
    name: "weighting",
    required: true,
    label: "Anteil am Zählpunkt",
    type: "number",
    placeholder: "z.B. 1,0"
  },
  community_share: {
    name: "community_share",
    required: true,
    label: "Anteil Gemeinde",
    type: "number",
    placeholder: "z.B. 1,0"
  },
  anzulegender_wert: {
    name: "anzulegender_wert",
    required: true,
    label: "Anzulegender Wert in ct/kWh",
    type: "number",
    inputGroupText: "ct/kWh",
    placeholder: "z.B. 7,98"
  },
  foerderregime: {
    name: "foerderregime",
    required: true,
    label: "Förderregime",
    type: "dropdown",
    choices: PARAGRAPH_6_FOERDERREGIME_CHOICES
  },
  anlagenschluessel_eeg: {
    name: "anlagenschluessel_eeg",
    required: true,
    label: "Anlagenschlüssel EEG",
    type: "text"
  }
} as const satisfies FormFieldData<paragraph6ErzeugerAnteilPayload>;
