import React, { useState } from "react";
import type { Column } from "react-table";
import { Paragraph6EEGCreditResponseState } from "../../../../../types/api.types";
import { IconName } from "../../../../BuildingBlocks/Icon/types";
import { buttonColors } from "../../../../Buttons/Button/Button";
import { IconButton } from "../../../../Buttons/IconButton/IconButton";
import type { TypedCellInfo } from "../../../../CustomReactTable/CustomReactTable.types";
import type { Paragraph6FinancialDocument } from "../../../Paragraph6.types";
import { FinancialDocumentType } from "../../../Paragraph6.types";
import { Paragraph6ConfirmCancellationModal } from "../../Paragraph6ConfirmCancellationModal/Paragraph6ConfirmCancellationModal";

function getCancelColumn(variantId: number) {
  return {
    Cell: (cellInfo: TypedCellInfo<never, Paragraph6FinancialDocument>) => {
      const [cancellationCreditId, setCancellationCreditId] = useState<
        string | undefined
      >(undefined);

      if (cellInfo.original.type !== FinancialDocumentType.Credit) return null;

      const disabled =
        cellInfo.original.state == Paragraph6EEGCreditResponseState.cancelled;

      return (
        <>
          <Paragraph6ConfirmCancellationModal
            creditId={cancellationCreditId}
            variantId={variantId}
            onClose={() => setCancellationCreditId(undefined)}
          />
          <IconButton
            className="credit-cancel-button"
            color={buttonColors.link}
            disabled={disabled}
            iconColor={buttonColors.danger}
            iconName={IconName.Times}
            noBackground
            type="button"
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              setCancellationCreditId(cellInfo.original.id);
            }}
          >
            Stornieren
          </IconButton>
        </>
      );
    },
    width: 120,
    sortable: false,
    resizable: false
  } satisfies Column<Paragraph6FinancialDocument>;
}

export { getCancelColumn };
