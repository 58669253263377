import type {
  Meter,
  MeterConfigurationData,
  MetersSuggestions
} from "../../../utils/backend-types";
import type { SubMeteringSystems } from "../../../utils/enums";
import type { SelectedMeter } from "../EditConfigurationModal/EditConfigurationModal";
import { getParameterName } from "./getParameterName";
import { getSuggestedMeterDisplayName } from "./getSuggestedMeterDisplayName";

export function getSuggestedMetersSelectionData(
  systemName: SubMeteringSystems,
  suggestions: Array<MetersSuggestions>,
  parameters: Array<MeterConfigurationData>,
  metersBySiteId: Array<Meter>
): Array<SelectedMeter> {
  return suggestions.map((suggestion) => {
    const meterData = metersBySiteId.find(
      (meter) => meter.id === suggestion.suggestedMeterId
    );

    const parameter = parameters.find(
      (param) => param.id === suggestion.subMeteringEntityId
    );

    return {
      meterId: suggestion.suggestedMeterId,
      subMeteringSystemEntityId: suggestion.subMeteringEntityId,
      meterName: getSuggestedMeterDisplayName(meterData),
      parameterName: getParameterName(systemName, parameter),
      isSuggested: true
    };
  });
}
