import {
  useMutation,
  useQuery,
  useQueryClient,
  type UseQueryOptions
} from "@tanstack/react-query";
import api from "../../api";
import { camelize } from "../../utils/SnakeCamelConversion";

export interface Response<T> {
  id: number;
  processes: Array<T>;
}

function useChangeProcesses<T extends { id: number }>(
  queryKey: string,
  queryUrl: string,
  siteId: number | undefined,
  options?: Partial<UseQueryOptions<Array<T> | undefined>>
) {
  const { data, isLoading, error } = useQuery({
    queryKey: [queryKey, { siteId }],
    queryFn: () => fetchChanges(siteId),
    ...options,
    enabled: !!siteId && (!options || options.enabled)
  });

  async function fetchChanges(siteId: number | undefined) {
    if (typeof siteId === "undefined") {
      return undefined;
    }

    const response = await api.get<Response<T>>(queryUrl);

    const processes = convertMissingDataFields(response.data.processes);
    return processes.sort((a, b) => a.id - b.id);
  }

  function convertMissingDataFields(data) {
    const formattedData = data;
    formattedData.forEach((process) => {
      Object.keys(process.steps).forEach((step) => {
        process.steps[step]?.missingData?.forEach((missingData, dataIndex) => {
          missingData.missingFields.forEach((missingField, fieldIndex) => {
            process.steps[step].missingData[dataIndex].missingFields[
              fieldIndex
            ] = camelize(missingField);
          });
        });
        process.steps[step]?.exclusiveOrFields?.forEach((group, groupIndex) => {
          group.forEach((field, index) => {
            process.steps[step].exclusiveOrFields[groupIndex][index] =
              camelize(field);
          });
        });
      });
    });
    return formattedData;
  }

  return { data, isLoading, error };
}

function useChangeMutation<T>(
  siteId: number,
  mutationUrl: string,
  queryKey: string
) {
  const queryClient = useQueryClient();

  function mutateOperatorChange<T>(change: T, siteId: number) {
    return api.post(mutationUrl, {
      id: siteId,
      processes: [change]
    });
  }

  return useMutation({
    mutationFn: (change: T) => mutateOperatorChange(change, siteId),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [queryKey, { siteId }]
      });
    }
  });
}

export { useChangeMutation, useChangeProcesses };
