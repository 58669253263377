import React, { useCallback } from "react";
import ReactTable from "react-table";
import type { TableProps } from "react-table";
import type { SelectTableAdditionalProps } from "react-table/lib/hoc/selectTable";
import selectTableHOC from "react-table/lib/hoc/selectTable";
import { Input, Label } from "reactstrap";

const SelectTable = selectTableHOC(ReactTable);

type SelectTableProps<T> = Partial<TableProps<T>> & SelectTableAdditionalProps;
type CustomTableRow = { disabledCheckbox?: boolean };

interface CustomReactTableWithSelectProps<T> extends SelectTableProps<T> {
  selection: Array<string>;
  tableRef;
  onSetSelection: (newSelection: Array<string>) => void;
  onSetSelectAll: (selectAll: boolean) => void;
  onSetTableRef: (tableRef) => void;
}

function CustomReactTableWithSelect<T>({
  keyField = "id",
  selection,
  selectAll,
  tableRef,
  onSetSelection,
  onSetSelectAll,
  onSetTableRef,
  ...otherProps
}: CustomReactTableWithSelectProps<T>) {
  const toggleSelection = useCallback(
    (key: string) => {
      // start off with the existing state
      let newSelection = [...selection];
      const keyIndex = newSelection.indexOf(key);

      // check to see if the key exists
      if (keyIndex >= 0) {
        // it does exist so we will remove it using destructing
        newSelection = [
          ...newSelection.slice(0, keyIndex),
          ...newSelection.slice(keyIndex + 1)
        ];
      } else {
        // it does not exist so add it
        newSelection.push(key);
      }

      // update the state
      onSetSelection(newSelection);
      onSetSelectAll(
        newSelection.length < selection.length ? false : !!selectAll
      );
    },
    [selection, onSetSelection, selectAll, onSetSelectAll]
  );

  const toggleAllVisibleTodos = useCallback(() => {
    const newSelection: Array<string> = [];

    if (!selectAll) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = tableRef.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      currentRecords.forEach((item) => {
        newSelection.push(getSelectionKeyFromRecord(item));
      });
    }

    onSetSelection(newSelection);
    onSetSelectAll(!selectAll);
  }, [onSetSelection, selectAll, onSetSelectAll, tableRef]);

  const isSelected = useCallback(
    (key: string) => {
      return selection.includes(composeSelectionKey(key));
    },
    [selection]
  );

  const memoisedSelectInputComponent = useCallback(
    (props) => (
      <SelectInputComponent toggleSelection={toggleSelection} {...props} />
    ),
    [toggleSelection]
  );

  const memoisedSelectAllInputComponent = useCallback(
    (props) => {
      const enabled = (otherProps.data || []).some(
        (row) => !(row as CustomTableRow)?.disabledCheckbox
      );

      return (
        <SelectAllInputComponent
          disabled={!enabled}
          toggleAll={toggleAllVisibleTodos}
          {...props}
        />
      );
    },
    [otherProps.data, toggleAllVisibleTodos]
  );

  const memoisedOnSetTableRef = useCallback(
    (r) => onSetTableRef(r),
    [onSetTableRef]
  );

  return (
    <SelectTable
      isSelected={isSelected}
      keyField={keyField}
      ref={memoisedOnSetTableRef}
      selectAll={selectAll}
      SelectAllInputComponent={memoisedSelectAllInputComponent}
      SelectInputComponent={memoisedSelectInputComponent}
      selectType="checkbox"
      toggleAll={toggleAllVisibleTodos}
      toggleSelection={toggleSelection}
      {...otherProps}
    />
  );
}

interface SelectInputComponentProps {
  checked: boolean;
  id: number;
  toggleSelection: (id: number) => void;
  row?: CustomTableRow;
}

function SelectInputComponent({
  checked,
  id,
  toggleSelection,
  row
}: SelectInputComponentProps) {
  const isDisabled = row?.disabledCheckbox as boolean | undefined;

  if (isDisabled) {
    checked = !isDisabled;
  }

  return (
    <TodoCheckbox
      checked={checked}
      inputClassName="todo-checkbox"
      isDisabled={isDisabled}
      onChange={() => toggleSelection(id)}
    />
  );
}

interface SelectAllInputComponentProps {
  checked: boolean;
  disabled: boolean;
  toggleAll: () => void;
}

function SelectAllInputComponent({
  checked,
  toggleAll,
  disabled
}: SelectAllInputComponentProps) {
  return (
    <TodoCheckbox
      checked={checked}
      inputClassName="select-all-checkbox"
      isDisabled={disabled}
      onChange={toggleAll}
    />
  );
}

interface TodoCheckboxProps {
  checked: boolean;
  inputClassName: string;
  onChange: () => void;
  isDisabled?: boolean;
}

export function TodoCheckbox({
  checked,
  inputClassName,
  onChange,
  isDisabled
}: TodoCheckboxProps) {
  return (
    <Label className="m-checkbox">
      <Input
        checked={checked}
        className={`m-checkbox ${inputClassName}`}
        disabled={isDisabled}
        type="checkbox"
        onChange={onChange}
      />
      <span />
    </Label>
  );
}

export function getSelectionKeyFromRecord(item) {
  return composeSelectionKey(item._original["id"]);
}

export function composeSelectionKey(key: string) {
  return `select-${key}`;
}

export { CustomReactTableWithSelect, CustomReactTableWithSelectProps };
