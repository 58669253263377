import { MeteringDirection } from "../../../utils/backend-types";
import { ArrowTypes } from "../CustomGraphComponents/CustomNode/MeteringArrows/MeteringArrows";

export function getArrowTypeFromMeteringDirection(
  meteringDirection: MeteringDirection
): ArrowTypes | null {
  switch (meteringDirection) {
    case MeteringDirection.Zweirichtungszähler:
    case MeteringDirection.VierQuadrantenZähler:
      return ArrowTypes.DoubleArrow;
    case MeteringDirection.ErzeugungEinspeisung:
      return ArrowTypes.GreenArrow;
    case MeteringDirection.VerbrauchEntnahme:
      return ArrowTypes.BlueArrow;
    default:
      console.error("Unknown metering direction");
      return null;
  }
}
