import React from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../../Buttons/Button/Button";
import { SpinButton } from "../../Buttons/SpinButton/SpinButton";

interface DownloadConfirmationModalProps {
  onCancel: () => void;
  onDownload: () => void;
  onDownloadWithUpload: () => void;
  downloadLoading: boolean;
  downloadWithUploadLoading: boolean;
}

function DownloadConfirmationModal({
  onCancel,
  onDownload,
  onDownloadWithUpload,
  downloadLoading,
  downloadWithUploadLoading
}: DownloadConfirmationModalProps) {
  return (
    <Modal isOpen>
      <ModalHeader>Messkonzeptdokument herunterladen</ModalHeader>
      <ModalBody>
        Ihr Messkonzept kann jetzt heruntergeladen werden. Wir empfehlen, das
        Dokument direkt auf der Aufgabe 3.1. „Messkonzept vorbereiten“ der
        zugehörigen Liegenschaft zu hinterlegen. Klicken Sie dafür auf{" "}
        <i>Herunterladen und Ablegen</i>.
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => onCancel()}>Abbrechen</Button>

        <SpinButton
          color="brand"
          disabled={downloadLoading || downloadWithUploadLoading}
          spin={downloadLoading}
          onClick={() => onDownload()}
        >
          Herunterladen
        </SpinButton>

        <SpinButton
          color="primary"
          disabled={downloadLoading || downloadWithUploadLoading}
          spin={downloadWithUploadLoading}
          onClick={() => onDownloadWithUpload()}
        >
          Herunterladen und Ablegen
        </SpinButton>
      </ModalFooter>
    </Modal>
  );
}

export { DownloadConfirmationModal };
