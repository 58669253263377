import React from "react";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import { Button } from "../../../Buttons/Button/Button";
import { IconButton } from "../../../Buttons/IconButton/IconButton";
import { Select } from "../../../Select/Select";

interface TableLightPaginationProps {
  count: number;
  currentPage: number;
  pageCount: number;
  pageSize: number;
  onCurrentPageChange: (value: number) => void;
  onPageSizeChange: (value: number) => void;
}

function TableLightPagination({
  count,
  currentPage,
  pageCount,
  pageSize,
  onCurrentPageChange,
  onPageSizeChange
}: TableLightPaginationProps) {
  return (
    <div className="Pagination">
      <div className="total">Insgesamt {count} Einträge</div>
      <div className="pagination-buttons">
        <IconButton
          disabled={currentPage - 1 <= 0}
          iconName={IconName.CaretLeft}
          noBackground
          noBorder
          onClick={() => onCurrentPageChange(currentPage - 1)}
        />
        {currentPage - 2 > 0 && (
          <Button noBorder onClick={() => onCurrentPageChange(currentPage - 2)}>
            {currentPage - 2}
          </Button>
        )}
        {currentPage - 1 > 0 && (
          <Button noBorder onClick={() => onCurrentPageChange(currentPage - 1)}>
            {currentPage - 1}
          </Button>
        )}
        <Button color="brand" noBorder>
          {currentPage}
        </Button>
        {currentPage + 1 <= pageCount && (
          <Button noBorder onClick={() => onCurrentPageChange(currentPage + 1)}>
            {currentPage + 1}
          </Button>
        )}
        {currentPage + 2 <= pageCount && (
          <Button noBorder onClick={() => onCurrentPageChange(currentPage + 2)}>
            {currentPage + 2}
          </Button>
        )}
        <IconButton
          disabled={currentPage + 1 > pageCount}
          iconName={IconName.CaretRight}
          noBackground
          noBorder
          onClick={() => onCurrentPageChange(currentPage + 1)}
        />
      </div>
      <div className="page-size-select">
        <Select
          defaultValue={pageSize}
          items={[
            { key: 5, text: "5 / Seite", value: 5 },
            { key: 10, text: "10 / Seite", value: 10 },
            { key: 20, text: "20 / Seite", value: 20 }
          ]}
          onChange={onPageSizeChange}
        />
      </div>
    </div>
  );
}

export { TableLightPagination, TableLightPaginationProps };
