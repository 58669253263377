import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import urls from "../../../urls";
import type { paragraph6ErzeugerAnteilPayload } from "../Paragraph6.types";

export function useParagraph6ErzeugerAnteilMutations(
  variantId: number,
  contractId: string
) {
  const queryClient = useQueryClient();

  function invalidateRelatedContracts(contractId: string) {
    queryClient.invalidateQueries({
      queryKey: ["paragraph6Contract", { contractId }]
    });
    queryClient.invalidateQueries({
      queryKey: ["paragraph6Contracts", { variantId }]
    });
  }

  function invalidateCreditsAndErrors() {
    queryClient.invalidateQueries({
      queryKey: ["paragraph6CreditErrors", { variantId }]
    });
    queryClient.invalidateQueries({
      queryKey: ["paragraph6CreatableCredits", { variantId }]
    });
  }

  const paragraph6ErzeugerAnteilCreateMutation = useMutation({
    mutationFn: (generator: paragraph6ErzeugerAnteilPayload) =>
      createparagraph6ErzeugerAnteil(generator, contractId),
    onSuccess: (_, generator) => {
      queryClient.invalidateQueries({
        queryKey: [
          "paragraph6UnassignedGenerators",
          { contractId: generator.contract }
        ]
      });
      if (generator.contract) invalidateRelatedContracts(generator.contract);
      invalidateCreditsAndErrors();
    }
  });

  const paragraph6ErzeugerAnteilEditMutation = useMutation({
    mutationFn: (generator: paragraph6ErzeugerAnteilPayload) =>
      editparagraph6ErzeugerAnteil(generator, contractId),
    onSuccess: (_, generator) => {
      queryClient.invalidateQueries({
        queryKey: [
          "paragraph6ErzeugerAnteil",
          { generatorShareId: generator.generator }
        ]
      });
      if (generator.contract) invalidateRelatedContracts(generator.contract);
    }
  });

  const paragraph6ErzeugerAnteilDeleteMutation = useMutation({
    mutationFn: (generatorId: number) =>
      deleteparagraph6ErzeugerAnteil(generatorId, contractId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["paragraph6UnassignedGenerators", { contractId }]
      });
      invalidateRelatedContracts(contractId ?? "");
      invalidateCreditsAndErrors();
    }
  });

  return {
    paragraph6ErzeugerAnteilCreateMutation,
    paragraph6ErzeugerAnteilEditMutation,
    paragraph6ErzeugerAnteilDeleteMutation
  };
}

function createparagraph6ErzeugerAnteil(
  generator: paragraph6ErzeugerAnteilPayload,
  contractId: string
) {
  const paragraph6ErzeugerAnteileUrl =
    urls.apiWithoutCamelization.paragraph6ErzeugerAnteile(contractId);
  return apiWithoutCamelization.post<paragraph6ErzeugerAnteilPayload>(
    paragraph6ErzeugerAnteileUrl,
    generator
  );
}

function editparagraph6ErzeugerAnteil(
  generator: paragraph6ErzeugerAnteilPayload,
  contractId: string
) {
  if (!generator.generator) {
    throw new Error("Cannot edit generator share without an id");
  }
  const paragraph6ErzeugerAnteilUrl =
    urls.apiWithoutCamelization.paragraph6ErzeugerAnteil(
      contractId,
      generator.generator
    );
  return apiWithoutCamelization.patch<paragraph6ErzeugerAnteilPayload>(
    paragraph6ErzeugerAnteilUrl,
    generator
  );
}

function deleteparagraph6ErzeugerAnteil(
  generatorId: number,
  contractId: string
) {
  const paragraph6ErzeugerAnteilUrl =
    urls.apiWithoutCamelization.paragraph6ErzeugerAnteil(
      contractId,
      generatorId
    );
  return apiWithoutCamelization.delete(paragraph6ErzeugerAnteilUrl);
}
