import { findIndexForIdInPrefixedFormValuesArray } from "../../../../utils/findIndexForIdInPrefixedFormValuesArray";
import type { FictionalEnergyValueResponse } from "../EnergyDataUploadPage/FictionalEnergyValueManager/FictionalEnergyValueManager";
import { convertFictionalEnergyValuesToReactHookFormValues } from "./convertFictionalEnergyValuesToReactHookFormValues";
import { isFictionalEnergyValueResponseUpdatedFormValues } from "./isFictionalEnergyValueResponseUpdatedFormValues";

/** Merges the current form values with newly created FictionalEnergyValueResponse. */
export function mergeCurrentFormValuesWithFictionalEnergyValueResponse(
  formValues: Record<string, unknown>,
  fictionalEnergyValues: Array<FictionalEnergyValueResponse>
) {
  const updatedValues = getUpdatedValuesFromWatchValues(formValues);
  const updatedEntries = pairUpdatedValuesWithFictionalEnergyValues(
    updatedValues,
    formValues,
    fictionalEnergyValues
  );
  const mergedFormValues =
    convertFictionalEnergyValuesToReactHookFormValues(updatedEntries);

  return mergedFormValues;
}

function getUpdatedValuesFromWatchValues(
  watchValues: Record<string, unknown>
): Record<string, FictionalEnergyValueResponse> {
  const allKeys = Object.keys(watchValues);
  const keysThatAreIndices = allKeys.filter((key) => !isNaN(Number(key)));

  const safeValues = keysThatAreIndices.reduce<
    Record<string, FictionalEnergyValueResponse>
  >((safeValues, index) => {
    const potentialFictionalEnergyValue = watchValues[index];

    if (
      isFictionalEnergyValueResponseUpdatedFormValues(
        potentialFictionalEnergyValue
      )
    ) {
      safeValues[index] = potentialFictionalEnergyValue;
    }

    return safeValues;
  }, {});

  return safeValues;
}

function pairUpdatedValuesWithFictionalEnergyValues(
  updatedValues: Record<string, FictionalEnergyValueResponse>,
  allFormValues: Record<string, unknown>,
  fictionalEnergyValues: Array<FictionalEnergyValueResponse>
) {
  const updatedEntries = fictionalEnergyValues.reduce<
    Array<FictionalEnergyValueResponse>
  >((items, item) => {
    const indexForItem = findIndexForIdInPrefixedFormValuesArray(
      item.id,
      allFormValues
    );
    const updatedValuesForItem = indexForItem
      ? updatedValues[indexForItem]
      : undefined;

    return [
      ...items,
      {
        ...item,
        generator: updatedValuesForItem?.generator ?? item.generator,
        firstDate: updatedValuesForItem?.firstDate ?? item.firstDate,
        lastDate: updatedValuesForItem?.lastDate ?? item.lastDate,
        technischeNichtverfuegbarkeitKwh:
          updatedValuesForItem?.technischeNichtverfuegbarkeitKwh ??
          item.technischeNichtverfuegbarkeitKwh,
        redispatchAbregelungKwh:
          updatedValuesForItem?.redispatchAbregelungKwh ??
          item.redispatchAbregelungKwh,
        sonstigeAbschaltungOderDrosselungKwh:
          updatedValuesForItem?.sonstigeAbschaltungOderDrosselungKwh ??
          item.sonstigeAbschaltungOderDrosselungKwh
      }
    ];
  }, []);

  return updatedEntries;
}
