import { type DateTime } from "luxon";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useMeters } from "../../../../../hooks/useMeters";
import type { Meter } from "../../../../../utils/backend-types";
import { IconName } from "../../../../BuildingBlocks/Icon/types";
import { Portlet } from "../../../../BuildingBlocks/Layout/Portlet";
import { LoadOrError } from "../../../../LoadOrError/LoadOrError";
import { useSitePlausibility } from "../../hooks/useSitePlausibility";
import "./RawEnergyDataSite.scss";
import { RawEnergyDataSiteMeters } from "./RawEnergyDataSiteMeters/RawEnergyDataSiteMeters";
import { SiteInfo } from "./SiteInfo/SiteInfo";
import { SiteTitle } from "./SiteTitle/SiteTitle";

interface RawEnergyDataSiteProps {
  selectedTimeRange: [DateTime, DateTime];
  showPlausibility: boolean;
  siteId: number;
  siteName: string;
}

function RawEnergyDataSite({
  selectedTimeRange,
  showPlausibility,
  siteId,
  siteName
}: RawEnergyDataSiteProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const meterIdSearchParam = searchParams.get("meterId");
  const siteIdSearchParam = searchParams.get("siteId");
  const {
    data: sitePlausibility,
    isLoading: isSitePlausibilityLoading,
    error: sitePlausibilityError
  } = useSitePlausibility(siteId, selectedTimeRange, showPlausibility);

  const {
    data: meters,
    isLoading: isMetersLoading,
    error: metersError
  } = useMeters(siteId);
  const [selectedMeterId, setSelectedMeterId] = useState<number | undefined>(
    meterIdSearchParam ? parseInt(meterIdSearchParam) : undefined
  );
  const siteIdIsInSearchParams = siteIdSearchParam === siteId.toString();
  const scrollToRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (meters?.length === 1) {
      setSelectedMeterId(meters[0].id);
    }
  }, [meters]);

  useEffect(() => {
    if (siteIdIsInSearchParams && scrollToRef.current) {
      // avoid conflict of searchParams being set initially in EnergyData
      setTimeout(() => {
        if (scrollToRef.current) {
          scrollToRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center"
          });

          setSearchParams(
            (searchParams) => {
              searchParams.delete("meterId");
              searchParams.delete("siteId");

              return searchParams;
            },
            { replace: true }
          );
        }
      }, 100);
    }
  }, [setSearchParams, siteIdIsInSearchParams]);

  const { unplausibleMeters, unknownMeters } = useMemo(() => {
    const defaultMeters = {
      unplausibleMeters: [] as Array<Meter>,
      unknownMeters: [] as Array<Meter>
    };

    if (!sitePlausibility || !meters) {
      return defaultMeters;
    }

    const unplausibleAndUnknownMeters = sitePlausibility.reduce(
      (unplausibleAndUnknownMeters, meterPlausibility) => {
        if (meterPlausibility.plausibility === false) {
          const meter = meters.find(
            (meter) => meter.id === meterPlausibility.meter_id
          );

          if (meter) {
            unplausibleAndUnknownMeters.unplausibleMeters.push(meter);
          }
        } else if (meterPlausibility.plausibility === null) {
          const meter = meters.find(
            (meter) => meter.id === meterPlausibility.meter_id
          );

          if (meter) {
            unplausibleAndUnknownMeters.unknownMeters.push(meter);
          }
        }

        return unplausibleAndUnknownMeters;
      },
      defaultMeters
    );

    return unplausibleAndUnknownMeters;
  }, [sitePlausibility, meters]);

  function handleClickMeter(id: number) {
    setSelectedMeterId(id);
  }

  const notAllMetersAreUnplausible =
    !meters || meters.length === 0 || unknownMeters.length !== meters.length;
  const showSiteInfo = showPlausibility && notAllMetersAreUnplausible;

  return (
    <Portlet
      className="RawEnergyDataSite"
      ref={scrollToRef}
      title={
        <SiteTitle
          loading={showPlausibility && isSitePlausibilityLoading}
          loadingFailed={!!sitePlausibilityError}
          showPlausibility={showPlausibility}
          siteName={siteName}
          sitePlausibility={sitePlausibility}
        />
      }
      toggle
      toggleIconClosed={IconName.AngleRight}
      toggleIconOpen={IconName.AngleDown}
      toggleInitial={siteIdIsInSearchParams}
    >
      {showSiteInfo && (
        <SiteInfo
          loading={isSitePlausibilityLoading || isMetersLoading}
          loadingFailed={!!sitePlausibilityError}
          noMeters={!!meters && meters.length === 0}
          unplausibleMeters={unplausibleMeters}
          onClickMeter={handleClickMeter}
        />
      )}
      <LoadOrError error={metersError} loading={isMetersLoading}>
        {meters && meters.length > 0 && (
          <RawEnergyDataSiteMeters
            meterPlausibilities={
              showPlausibility ? sitePlausibility : undefined
            }
            meters={meters}
            selectedMeterId={selectedMeterId}
            selectedTimeRange={selectedTimeRange}
            siteId={siteId}
            onSetSelectedMeterId={setSelectedMeterId}
          />
        )}
      </LoadOrError>
    </Portlet>
  );
}

export { RawEnergyDataSite };
