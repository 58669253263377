export enum ErrorName {
  InvalidBatchImportTemplate = "InvalidBatchImportTemplate",
  MaStRApiConnectionError = "MaStRApiConnectionError",
  ComponentTypeMissing = "ComponentTypeMissing",
  ConsumerIdentifierMissing = "ConsumerIdentifierMissing",
  OperatorIdentifierMissing = "OperatorIdentifierMissing",
  MaStRNumberInvalid = "MaStRNumberInvalid",
  MaStRObjectNotFound = "MaStRObjectNotFound",
  NoAccessToMaStRObject = "NoAccessToMaStRObject",
  VoltageLevelNotFound = "VoltageLevelNotFound",
  MaStRNumberMissing = "MaStRNumberMissing",
  ConsumerTypeInvalid = "ConsumerTypeInvalid",
  MeasurementTypeInvalid = "MeasurementTypeInvalid",
  GeneratorMissing = "GeneratorMissing"
}

export enum ComponentType {
  Connection = "connection",
  Generator = "generator",
  Consumer = "consumer",
  BatteryStorage = "battery_storage"
}

export interface BatchImportError {
  name: ErrorName;
  cell?: number;
  row?: number;
  componentType?: ComponentType;
}
