import PropTypes from "prop-types";
import React, { useState } from "react";

import { MultiConfirmationModal as DeleteDataImportRequestsModal } from "../../../../BuildingBlocks/Layout/Modals/MultiConfirmationModal/MultiConfirmationModal";
import { Button } from "../../../../Buttons/Button/Button";
import type { EnergyDataImportRequest } from "../../../EnergyDataUploadFlow/EnergyDataUpload";

interface DeleteDataImportRequestsButtonProps {
  dataImportRequests: Array<EnergyDataImportRequest>;
  onDeleteMultipleImportRequests: (
    dataImportRequests: Array<EnergyDataImportRequest>
  ) => Promise<void>;
}

function DeleteDataImportRequestsButton({
  dataImportRequests,
  onDeleteMultipleImportRequests
}: DeleteDataImportRequestsButtonProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  function handleClickDelete() {
    return onDeleteMultipleImportRequests(dataImportRequests);
  }

  const dataImportRequestsToDelete = dataImportRequests.map(
    (dataImportRequest) => dataImportRequest.energyDataFile.name
  );

  return (
    <React.Fragment>
      <Button
        color="brand"
        disabled={dataImportRequests.length === 0}
        onClick={() => setIsModalOpen(true)}
      >
        {`Datei${dataImportRequestsToDelete.length > 1 ? "en" : ""} löschen`}
      </Button>
      <DeleteDataImportRequestsModal
        actionName="löschen"
        actionObjects={dataImportRequestsToDelete}
        confirmationText="Möchten Sie die folgenden Dateien löschen?"
        isModalOpen={isModalOpen}
        objectName="Dateien"
        toggleModal={() => setIsModalOpen(false)}
        onAction={handleClickDelete}
      />
    </React.Fragment>
  );
}

DeleteDataImportRequestsButton.propTypes = {
  dataImportRequests: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDeleteMultipleImportRequests: PropTypes.func.isRequired
};

export { DeleteDataImportRequestsButton };
