import api from "../../../api";
import urls from "../../../urls";
import type { MetersSuggestions } from "../../../utils/backend-types";

export async function getMetersSuggestions(taskId: string) {
  const { data } = await api.get<Array<MetersSuggestions>>(
    urls.api.getMeterSuggestions(taskId)
  );

  return data;
}
