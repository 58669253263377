import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import ReactMarkdown from "react-markdown";
import { Col, Row } from "reactstrap";

import { Icon } from "../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import { RegulatoryDutyDetailRow } from "./RegulatoryDutyDetailRow/RegulatoryDutyDetailRow";

import "./RegulatoryDutyItem.scss";

export default class RegulatoryDutyItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false
    };
  }

  toggleDetails = () => {
    this.setState({
      showDetails: !this.state.showDetails
    });
  };

  render() {
    const { duty, applicable = true, collapsible = false } = this.props;

    const applicableClass = applicable ? null : "grey-out";
    const showDetails = collapsible ? this.state.showDetails : true;

    return (
      <React.Fragment>
        <RegulatoryDutyHeader
          className={applicableClass}
          clickable={collapsible}
          name={duty.name}
          recurring={duty.recurring}
          onClick={this.toggleDetails}
        />
        {showDetails && (
          <RegulatoryDutyDetail className={applicableClass} duty={duty} />
        )}
      </React.Fragment>
    );
  }
}

RegulatoryDutyItem.propTypes = {
  duty: PropTypes.object,
  collapsible: PropTypes.bool,
  applicable: PropTypes.bool
};

function RegulatoryDutyHeader({
  name,
  recurring,
  className,
  clickable,
  onClick
}) {
  return (
    <div
      className={classNames(className, "p-2", { clickable: clickable })}
      onClick={clickable ? onClick : null}
    >
      <div className="label-container">
        <div>
          <RegulatoryDutyIcon recurring={recurring} />
        </div>
        <div className="ml-3 font-weight-normal">{name}</div>
      </div>
    </div>
  );
}

function RegulatoryDutyIcon({ recurring }) {
  if (recurring) {
    return <Icon name={IconName.Refresh2} />;
  } else {
    return <div className="regulatory-duty-icon">1</div>;
  }
}

const RegulatoryDutyDetail = ({ duty, className }) => {
  return (
    <Row className={classNames(className, "pl-5", "mb-3")}>
      <Col>
        <RegulatoryDutyDetailRow title="Beschreibung der regulatorischen Pflicht">
          {duty.description && (
            <ReactMarkdown linkTarget="_blank">
              {duty.description}
            </ReactMarkdown>
          )}
        </RegulatoryDutyDetailRow>
        <RegulatoryDutyDetailRow title="Verpflichteter">
          {duty.obligor}
        </RegulatoryDutyDetailRow>
        <RegulatoryDutyDetailRow title="Empfänger">
          {duty.obligee}
        </RegulatoryDutyDetailRow>
        <RegulatoryDutyDetailRow title="Erfüllungsprozess">
          {duty.fulfillmentProcess && (
            <ReactMarkdown linkTarget="_blank">
              {duty.fulfillmentProcess}
            </ReactMarkdown>
          )}
        </RegulatoryDutyDetailRow>
        <RegulatoryDutyDetailRow title="Gesetzlich vorgegebene Frist">
          {duty.deadline}
        </RegulatoryDutyDetailRow>
        <RegulatoryDutyDetailRow title="Gesetzliche Grundlage">
          {duty.legalBasis}
        </RegulatoryDutyDetailRow>
        <RegulatoryDutyDetailRow title="Weitere Informationen">
          {duty.additionalInformation && (
            <ReactMarkdown linkTarget="_blank">
              {duty.additionalInformation}
            </ReactMarkdown>
          )}
        </RegulatoryDutyDetailRow>
      </Col>
    </Row>
  );
};
