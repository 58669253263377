import React from "react";

import { Icon } from "../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../BuildingBlocks/Icon/types";

function DownIcon() {
  return <Icon className="m-menu__hor-arrow" name={IconName.AngleDown} />;
}

export { DownIcon };
