import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, Collapse } from "reactstrap";

import { Button } from "../../Buttons/Button/Button";
import type { Choice } from "./FormField/Dropdown/TsDropdown";
import type { FormFieldValue } from "./FormField/FormField";
import { FormField } from "./FormField/FormField";
import type { FormFieldData } from "./FormItems";

interface CreatableDropdownProps {
  formItemData: FormFieldData;
  formName?: string;
  value: FormFieldValue;
  choices: Array<Choice>;
  errors?: Array<string>;
  allowInput: boolean;
  isCreating: boolean;
  isEditing: boolean;
  onInput: (name: string, value: FormFieldValue) => void;
  onCreateFormNodeChange: (node: HTMLElement) => void;
  onEditFormNodeChange: (node: HTMLElement) => void;
  onStartCreating: () => void;
  onStartEditing: () => void;
}

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function CreatableDropdown({
  formItemData,
  formName,
  value,
  choices,
  errors,
  allowInput,
  isCreating,
  isEditing,
  onInput,
  onCreateFormNodeChange,
  onEditFormNodeChange,
  onStartCreating,
  onStartEditing
}: CreatableDropdownProps) {
  const [choicesKey, setChoicesKey] = useState(0);
  useEffect(() => {
    setChoicesKey((oldChoicesKey) => oldChoicesKey + 1);
  }, [choices]);

  const createFormRef = useCallback(
    (node: HTMLElement | null) => {
      if (node !== null) {
        onCreateFormNodeChange(node);
      }
    },
    [onCreateFormNodeChange]
  );

  const editFormRef = useCallback(
    (node: HTMLElement | null) => {
      if (node !== null) {
        onEditFormNodeChange(node);
      }
    },
    [onEditFormNodeChange]
  );

  function handleClickCreate() {
    onStartCreating();
  }

  function handleClickEdit() {
    onStartEditing();
  }

  const formItemName = formItemData.name as string;
  const formItemId = `id_${formName ? formName : "form"}_${formItemName.replace(
    /\./g,
    "__"
  )}`;

  return (
    <div className="CreatableDropdown">
      <div className="controls">
        <FormField
          allowInput={!formItemData.readOnly && allowInput}
          errorTexts={errors}
          id={formItemId}
          key={choicesKey}
          value={value}
          onInput={onInput}
          {...formItemData}
          choices={choices}
        />
        <Button
          className="create-button"
          color="brand"
          disabled={isCreating || isEditing}
          onClick={handleClickCreate}
        >
          Hinzufügen
        </Button>
        <Button
          className="edit-button"
          color="brand"
          disabled={isCreating || isEditing || !value}
          onClick={handleClickEdit}
        >
          Bearbeiten
        </Button>
      </div>
      <Collapse isOpen={isCreating}>
        <Card>
          <CardBody>
            <div ref={createFormRef} />
          </CardBody>
        </Card>
      </Collapse>
      <Collapse isOpen={isEditing}>
        <Card>
          <CardBody>
            <div ref={editFormRef} />
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
}

CreatableDropdown.propTypes = {
  formItemData: PropTypes.object.isRequired,
  formName: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
    PropTypes.number,
    PropTypes.bool,
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string
    })
  ]),
  choices: PropTypes.arrayOf(PropTypes.object).isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  allowInput: PropTypes.bool.isRequired,
  isCreating: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  onInput: PropTypes.func.isRequired,
  onCreateFormNodeChange: PropTypes.func.isRequired,
  onEditFormNodeChange: PropTypes.func.isRequired,
  onStartCreating: PropTypes.func.isRequired,
  onStartEditing: PropTypes.func.isRequired
};

export { CreatableDropdown };
