import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Input, Label } from "reactstrap";
import { HelperText } from "../../BuildingBlocks/Forms/FormField/HelperText/HelperText";
import { DateInput } from "../../BuildingBlocks/Forms/FormFields/DateInput/DateInput";

const DUE_DATE_FIELD_NAME = "dueDate";
const OVERDUE_FIELD_NAME = "overdue";

const getInitialFormItemsWithPortals = (formItemsWithPortals) => [
  ...(formItemsWithPortals || []),
  DUE_DATE_FIELD_NAME
];

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function withOverdueDateFields(FormItems) {
  const WrappedFormItemsWithOverdueDateFields = ({
    formItems,
    formName,
    formValues,
    formErrors,
    formItemsWithPortals,
    allowInput,
    onFormItemNodeChange,
    onInput,
    ...otherProps
  }) => {
    const [localFormItemsWithPortals, setLocalFormItemsWithPortals] = useState(
      getInitialFormItemsWithPortals()
    );

    const [formItemNode, setFormItemNode] = useState(null);

    function handleFormItemNodeChange(formItemName, node) {
      if (formItemName === DUE_DATE_FIELD_NAME) {
        setFormItemNode(node);
      } else if (onFormItemNodeChange) {
        onFormItemNodeChange(formItemName, node);
      }
    }

    const dueDateFormItemData = formItems.find(
      (formItem) => formItem.name === DUE_DATE_FIELD_NAME
    );
    const overdueFormItemData = formItems.find(
      (formItem) => formItem.name === OVERDUE_FIELD_NAME
    );

    const dueDateFormItemId = `id_${
      formName ? formName : "form"
    }_${dueDateFormItemData.name.replace(/\./g, "__")}`;
    const overdueFormItemId = `id_${
      formName ? formName : "form"
    }_${overdueFormItemData.name.replace(/\./g, "__")}`;

    const formItemsWithoutOverdueField = formItems.filter(
      (formItem) => formItem.name !== OVERDUE_FIELD_NAME
    );

    useEffect(() => {
      if (!localFormItemsWithPortals.includes(DUE_DATE_FIELD_NAME)) {
        setLocalFormItemsWithPortals(getInitialFormItemsWithPortals());
      }
    }, [formItemsWithPortals, localFormItemsWithPortals]);

    return (
      <React.Fragment>
        <FormItems
          {...otherProps}
          allowInput={allowInput}
          formErrors={formErrors}
          formItems={formItemsWithoutOverdueField}
          formItemsWithPortals={localFormItemsWithPortals}
          formValues={formValues}
          onFormItemNodeChange={handleFormItemNodeChange}
          onInput={onInput}
        />
        {formItemNode &&
          ReactDOM.createPortal(
            <DatepickerWithOverdueDates
              allowInput={allowInput}
              dueDateErrorTexts={formErrors[DUE_DATE_FIELD_NAME]}
              dueDateFormItemData={dueDateFormItemData}
              dueDateId={dueDateFormItemId}
              dueDateValue={formValues[DUE_DATE_FIELD_NAME]}
              overdueErrorTexts={formErrors[OVERDUE_FIELD_NAME]}
              overdueId={overdueFormItemId}
              overdueValue={formValues[OVERDUE_FIELD_NAME]}
              onInput={onInput}
            />,
            formItemNode
          )}
      </React.Fragment>
    );
  };

  function DatepickerWithOverdueDates({
    dueDateId,
    overdueId,
    dueDateValue,
    overdueValue,
    dueDateErrorTexts,
    overdueErrorTexts,
    onInput,
    allowInput,
    dueDateFormItemData
  }) {
    function handleDateInput(value) {
      onInput(DUE_DATE_FIELD_NAME, value);
    }

    function handleChangeOverdue() {
      if (overdueValue) {
        onInput(OVERDUE_FIELD_NAME, false);
      } else {
        onInput(null, {
          [DUE_DATE_FIELD_NAME]: null,
          [OVERDUE_FIELD_NAME]: true
        });
      }
    }

    function handleClickCheckbox() {
      // do nothing - it's handled by handleChangeOverdue
    }

    const errorTexts = [
      ...(dueDateErrorTexts || []),
      ...(overdueErrorTexts || [])
    ];

    return (
      <div className="DatepickerWithOverdueDates">
        <DateInput
          disabled={!allowInput || overdueValue}
          errorTexts={errorTexts}
          id={dueDateId}
          invalid={errorTexts.length > 0}
          value={dueDateValue}
          onChange={handleDateInput}
          {...dueDateFormItemData}
        />
        <HelperText error={errorTexts.length > 0 ? errorTexts[0] : ""} />
        <div
          className="FormField due-date-form-field"
          onClick={handleChangeOverdue}
        >
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <Label
                  className="m-checkbox m-checkbox--brand form-check-label form-check-label m-checkbox--single mr-1"
                  htmlFor={overdueId}
                  style={{ height: "18px" }}
                >
                  <Input
                    checked={overdueValue}
                    className="form-check-input"
                    id={overdueId}
                    type="checkbox"
                    onChange={(e) => handleClickCheckbox(e)}
                  />
                  <span />
                </Label>
                Überfällig
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  WrappedFormItemsWithOverdueDateFields.propTypes = {
    formItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    formName: PropTypes.string,
    formValues: PropTypes.object.isRequired,
    formErrors: PropTypes.object,
    formItemsWithPortals: PropTypes.array,
    allowInput: PropTypes.bool,
    onInput: PropTypes.func.isRequired,
    onFormItemNodeChange: PropTypes.func
  };

  return WrappedFormItemsWithOverdueDateFields;
}

export { withOverdueDateFields };
