import React from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useAnlageStromstg } from "../../../hooks/useAnlageStromstg";
import { useContainerRef } from "../../../hooks/useContainerRef";
import type { Generator } from "../../../utils/backend-types";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../BuildingBlocks/Layout/Modal/Modal";
import { GENERATOR_EDIT_TABS } from "../../ComponentListContainer/ComponentList/ComponentEditWizard/Data/Generator";
import { LoadOrError } from "../../LoadOrError/LoadOrError";
import { AnlageStromstgFlow } from "./AnlageStromstgFlow";
import "./UpdateAnlageStromstgModal.scss";

const HEADER_TEXT = "Zuordnung der Einheit ändern";

interface UpdateAnlageStromstgModalProps {
  generators?: Array<Generator>;
  isOpen: boolean;
  siteId: number;
  variantId: number;
}

function UpdateAnlageStromstgModal({
  generators,
  isOpen,
  siteId,
  variantId
}: UpdateAnlageStromstgModalProps) {
  const { containerNode, containerRef } = useContainerRef();
  const { componentId } = useParams();
  const navigate = useNavigate();
  const currentGeneratorId = componentId
    ? parseInt(componentId, 10)
    : undefined;
  const currentGenerator = generators?.find((g) => g.id === currentGeneratorId);
  const {
    data: anlageStromstgData,
    isLoading: isAnlageStromstgDataLoading,
    error: anlageStromstgDataError
  } = useAnlageStromstg(currentGenerator?.anlageStromstg);
  const multiSite = Boolean(
    anlageStromstgData?.hasStandortuebergreifendeVerklammerung
  );

  function handleClose() {
    navigate(`../${GENERATOR_EDIT_TABS.TAB_STROMSTEUER_DATA}`);
  }

  if (!currentGenerator) {
    console.error(
      "UpdateAnlageStromstgModal should only be used with a componentId in the path!"
    );
    return <Navigate to={`../${GENERATOR_EDIT_TABS.TAB_STROMSTEUER_DATA}`} />;
  }

  return (
    <Modal
      className="UpdateAnlageStromstgModal"
      isOpen={isOpen}
      size="lg"
      toggle={handleClose}
    >
      <ModalHeader toggle={handleClose}>
        {currentGenerator
          ? `${currentGenerator?.name} - ${HEADER_TEXT}`
          : HEADER_TEXT}
      </ModalHeader>
      <ModalBody scrollable>
        <LoadOrError
          error={anlageStromstgDataError}
          loading={!currentGenerator || isAnlageStromstgDataLoading}
        >
          {currentGenerator && (
            <AnlageStromstgFlow
              buttonContainer={containerNode}
              currentGenerator={currentGenerator}
              multiSite={multiSite}
              siteId={siteId}
              variantId={variantId}
              onSubmit={handleClose}
            />
          )}
        </LoadOrError>
      </ModalBody>
      <ModalFooter>
        <div
          className="anlage-stromstg-edit-wizard-buttons"
          ref={containerRef}
        />
      </ModalFooter>
    </Modal>
  );
}

export { UpdateAnlageStromstgModal, UpdateAnlageStromstgModalProps };
