import type { MarketLocation } from "./backend-types";

/** Returns Accounted Feed-out label according to MarketLocation consumer or connection data. */
export function getAccountedFeedOutLabelFromMarketLocation(
  marketLocation: MarketLocation
) {
  let name: string;

  if (marketLocation.consumer) {
    name = marketLocation.consumer.name;
  } else {
    // in case of bundled accounted feedout
    name = `Netzentgelte gebündelte Verbräuche (${marketLocation.connection?.name ?? "Unbekannter Netzverknüpfungspunkt"})`;
  }

  return name;
}
