import * as Sentry from "@sentry/browser";
import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import api from "../api";
import urls from "../urls";
import type { Generator } from "../utils/backend-types";

export function useSupplierGenerators(
  contractId: string | undefined,
  siteId: number | undefined,
  options?: Partial<UseQueryOptions<Array<Generator>>>
) {
  const { data, isLoading, error } = useQuery({
    queryKey: ["supplier-generators", { siteId, contractId }],
    queryFn: () => fetchSupplierGenerators(contractId, siteId),
    enabled: typeof contractId !== "undefined" && typeof siteId !== "undefined",
    ...options
  });
  return { data, isLoading, error };
}

async function fetchSupplierGenerators(
  contractId: string | undefined,
  siteId: number | undefined
) {
  let generators: Array<Generator> = [];

  if (!contractId || !siteId) return generators;

  try {
    const response = await api.get<Array<Generator>>(
      urls.api.contractSupplierGenerators(contractId, siteId)
    );

    generators = response.data;
  } catch (_) {
    // ignore error but log it in Sentry (default choices will be used as fallback)
    Sentry.captureMessage(
      `Something went wrong in fetchSupplierGenerators, using contractId ${contractId} and siteId ${siteId}`
    );
  }

  return generators;
}
