import { SiteSetupProcessDataStep } from "../../../types/api.types";

export function getStepFromOrder(
  order: number
): SiteSetupProcessDataStep | null {
  switch (order) {
    case 0:
      return SiteSetupProcessDataStep.name_and_pv_plants;
    case 1:
      return SiteSetupProcessDataStep.address_and_supply_type;
    case 2:
      return SiteSetupProcessDataStep.connection;
    case 3:
      return SiteSetupProcessDataStep.consumers;
    case 4:
      return SiteSetupProcessDataStep.tenants;
    case 5:
      return SiteSetupProcessDataStep.meters;
    default:
      return null;
  }
}
