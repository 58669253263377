import { Card, Group, SimpleGrid, Text, Title, Tooltip } from "@mantine/core";
import { DateTime } from "luxon";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { TodoStatus, type Todo } from "../../../utils/backend-types";
import { backendDateOrDateTimeToLuxonDateTimeOrNull } from "../../../utils/dates/backendDateOrDateTimeToLuxonDateTimeOrNull";
import { Icon } from "../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../BuildingBlocks/Icon/types";
import "./TodosDueSoon.scss";

const now = DateTime.now();

interface TodosDueSoonProps {
  todos: Array<Todo>;
}

function TodosDueSoon({ todos }: TodosDueSoonProps) {
  const navigate = useNavigate();

  const dueSoonTodos = todos
    .filter((todo) => todo.status === TodoStatus.Open)
    .filter((todo) => {
      if (todo.dueDate) {
        // Mieterstrom defined "soon" as "within 2 months"
        const dueDate = backendDateOrDateTimeToLuxonDateTimeOrNull(
          todo.dueDate
        );
        return dueDate && now < dueDate && dueDate < now.plus({ months: 2 });
      }
      return false;
    })
    .sort((todoA, todoB) => {
      // due dates are never null, but Typescript doesn't know that
      const dueDateA = todoA.dueDate ? new Date(todoA.dueDate).valueOf() : 0;
      const dueDateB = todoB.dueDate ? new Date(todoB.dueDate).valueOf() : 0;
      return dueDateA - dueDateB;
    });

  return (
    <Card className="TodosDueSoon" p="lg">
      <Group className="header" justify="space-between" mb="xl">
        <Title order={4}>Bald fällig</Title>
        <Tooltip label="Diese Aufgaben sind in den nächsten zwei Monaten fällig.">
          <Icon name={IconName.InfoCircle} />
        </Tooltip>
      </Group>
      {dueSoonTodos.length > 0 ? (
        <SimpleGrid cols={2} spacing="xl">
          {dueSoonTodos.map((todo) => (
            <Group className="due-soon-element" key={todo.id} wrap="nowrap">
              <Icon name={IconName.Calendar} />
              <Text>{todo.dueDate}</Text>
              <Text
                className="label-with-link"
                onClick={() => navigate("./" + todo.id)}
              >
                {todo.label}
              </Text>
            </Group>
          ))}
        </SimpleGrid>
      ) : (
        <Group className="no-due-soon" gap="xs" justify="center" mt="xl">
          <Icon name={IconName.CheckCircle} />
          <Text>Keine dringenden Aufgaben</Text>
        </Group>
      )}
    </Card>
  );
}

export { TodosDueSoon };
