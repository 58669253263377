import api from "../../../../../api";
import { fetchSitesList } from "../../../../../hooks/useVariantSites";
import urls from "../../../../../urls";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import {
  LoadprofileType,
  ObjectName,
  Product
} from "../../../../../utils/enums";
import {
  getOrderedFormFieldsFromResponseAndValues,
  setLoadprofileType
} from "../common";
import { AnlageStromstgList } from "../Components/AnlageStromstgList/AnlageStromstgList";
import {
  buildHistoryData,
  filterValues,
  LOADPROFILE_TYPES,
  mapDependenciesToSelectedFields,
  saveGeneralDatafields
} from "./common";
import { buildFieldNameToLabelMapOldForms } from "./utils/buildFieldNameToLabelMapOldForms";
import { loadAnlageStromStg } from "./utils/loadAnlageStromStg";
import { loadEegAnlageData } from "./utils/loadEegAnlageData";
import { loadGeneralDataFields } from "./utils/loadGeneralDataFields";
import { loadKwkAnlageData } from "./utils/loadKwkAnlageData";
import { loadMastrData } from "./utils/loadMastrData";

export const API_CALLS = {
  GENERAL: "general",
  MASTR_EINHEIT: "mastr-einheit",
  ANLAGE_EEG: "anlage-eeg",
  ANLAGE_KWK: "anlage-kwk",
  ANLAGE_STROMSTG: "anlage-stromstg"
};

const EEG_ANLAGE_SECTIONS = {
  GENERAL: "General",
  REGULATORY_DATA: "Regulatorische Daten",
  EEG_EIGENVERBRAUCH: "EEG-Umlage auf Eigenverbrauch"
};

export const GENERATOR_EDIT_TABS = {
  TAB_GENERAL: "general",
  TAB_UNIT_DATA: "einheit",
  TAB_EEG_ANLAGE_DATA: "eeg-anlage",
  TAB_KWK_ANLAGE_DATA: "kwk-anlage",
  TAB_STROMSTEUER_DATA: "stromsteuerliche-anlage",
  TAB_PRICE_DATA: "preisdaten",
  TAB_HISTORY: "historie"
};

export const STROMSTEUER_FIELDS_ABOVE_TABLE = {
  HAS_STANDORTUEBERGREIFENDE_VERKLAMMERUNG:
    "hasStandortuebergreifendeVerklammerung"
};

export const GENERATOR_STROMSTEUER_FIELDS = {
  STROMSTG_PARAGRAPH_9_1_3_A: "stromstgParagraph_9_1_3a",
  ZEITRAUM_STROMSTG_PARAGRAPH_9_1_3_A: "zeitraumStromstgParagraph_9_1_3a",
  STROMSTG_PARAGRAPH_9_1_3_B: "stromstgParagraph_9_1_3b",
  ZEITRAUM_STROMSTG_PARAGRAPH_9_1_3_B: "zeitraumStromstgParagraph_9_1_3b"
};

const PV_PROJECTOR_FIELDS = [
  {
    name: "pvProjector",
    form: GENERATOR_EDIT_TABS.TAB_GENERAL,
    apiCall: API_CALLS.MASTR_EINHEIT
  },
  {
    name: "pvProjectorContact",
    form: GENERATOR_EDIT_TABS.TAB_GENERAL,
    apiCall: API_CALLS.MASTR_EINHEIT
  },
  {
    name: "pvProjectorContactMail",
    form: GENERATOR_EDIT_TABS.TAB_GENERAL,
    apiCall: API_CALLS.MASTR_EINHEIT
  },
  {
    name: "pvProjectorContactPhone",
    form: GENERATOR_EDIT_TABS.TAB_GENERAL,
    apiCall: API_CALLS.MASTR_EINHEIT
  }
];

const PV_PROJECTOR_FIELD_NAMES = PV_PROJECTOR_FIELDS.map((field) => field.name);

export const FIELDS = [
  {
    name: "name",
    form: GENERATOR_EDIT_TABS.TAB_GENERAL,
    apiCall: API_CALLS.GENERAL
  },
  {
    name: "type",
    form: GENERATOR_EDIT_TABS.TAB_GENERAL,
    apiCall: API_CALLS.GENERAL
  },
  {
    name: "connection",
    form: GENERATOR_EDIT_TABS.TAB_GENERAL,
    apiCall: API_CALLS.GENERAL
  },
  {
    name: "person",
    form: GENERATOR_EDIT_TABS.TAB_GENERAL,
    apiCall: API_CALLS.GENERAL
  },
  {
    name: "bruttoleistung",
    form: GENERATOR_EDIT_TABS.TAB_GENERAL,
    apiCall: API_CALLS.MASTR_EINHEIT
  },
  {
    name: "installedCapacity",
    form: GENERATOR_EDIT_TABS.TAB_GENERAL,
    apiCall: API_CALLS.GENERAL
  },
  {
    name: "zugeordneteWirkleistungWechselrichter",
    form: GENERATOR_EDIT_TABS.TAB_GENERAL,
    apiCall: API_CALLS.MASTR_EINHEIT
  },
  {
    name: "loadprofile",
    form: GENERATOR_EDIT_TABS.TAB_GENERAL,
    apiCall: API_CALLS.GENERAL,
    product: Product.Analyzer
  },
  {
    name: "yearlyEnergy",
    form: GENERATOR_EDIT_TABS.TAB_GENERAL,
    apiCall: API_CALLS.GENERAL,
    product: Product.Analyzer
  },
  {
    name: "commissioning",
    form: GENERATOR_EDIT_TABS.TAB_GENERAL,
    apiCall: API_CALLS.GENERAL
  },
  {
    name: "decommissioning",
    form: GENERATOR_EDIT_TABS.TAB_GENERAL,
    apiCall: API_CALLS.GENERAL
  },
  {
    name: "marketPremiumViaTendering",
    form: GENERATOR_EDIT_TABS.TAB_GENERAL,
    apiCall: API_CALLS.GENERAL,
    product: Product.Analyzer,
    hiddenIfNull: true
  },
  {
    name: "feedinTariffDeprecated",
    form: {
      [Product.Analyzer]: GENERATOR_EDIT_TABS.TAB_GENERAL,
      [Product.Manager]: GENERATOR_EDIT_TABS.TAB_PRICE_DATA
    },
    hiddenIfNull: true,
    apiCall: API_CALLS.GENERAL
  },
  {
    name: "feedinTariffNonPv",
    form: {
      [Product.Analyzer]: GENERATOR_EDIT_TABS.TAB_GENERAL,
      [Product.Manager]: GENERATOR_EDIT_TABS.TAB_PRICE_DATA
    },
    apiCall: API_CALLS.GENERAL
  },
  {
    name: "applicableValuePv",
    form: {
      [Product.Analyzer]: GENERATOR_EDIT_TABS.TAB_GENERAL,
      [Product.Manager]: GENERATOR_EDIT_TABS.TAB_PRICE_DATA
    },
    apiCall: API_CALLS.GENERAL
  },
  {
    name: "marketValueEeg",
    form: {
      [Product.Analyzer]: GENERATOR_EDIT_TABS.TAB_GENERAL,
      [Product.Manager]: GENERATOR_EDIT_TABS.TAB_PRICE_DATA
    },
    apiCall: API_CALLS.GENERAL
  },
  {
    name: "directMarketingFee",
    form: {
      [Product.Analyzer]: GENERATOR_EDIT_TABS.TAB_GENERAL,
      [Product.Manager]: GENERATOR_EDIT_TABS.TAB_PRICE_DATA
    },
    apiCall: API_CALLS.GENERAL
  },
  {
    name: "ownConsumptionSubsidy",
    form: {
      [Product.Analyzer]: GENERATOR_EDIT_TABS.TAB_GENERAL,
      [Product.Manager]: GENERATOR_EDIT_TABS.TAB_PRICE_DATA
    },
    apiCall: API_CALLS.GENERAL
  },
  {
    name: "kwkgDirectDeliverySubsidy",
    form: {
      [Product.Analyzer]: GENERATOR_EDIT_TABS.TAB_GENERAL,
      [Product.Manager]: GENERATOR_EDIT_TABS.TAB_PRICE_DATA
    },
    apiCall: API_CALLS.GENERAL
  },
  {
    name: "operationalPurpose",
    form: GENERATOR_EDIT_TABS.TAB_GENERAL,
    apiCall: API_CALLS.GENERAL,
    hiddenIfNull: true
  },
  {
    name: "imMastrRegistriert",
    form: GENERATOR_EDIT_TABS.TAB_GENERAL,
    apiCall: API_CALLS.MASTR_EINHEIT
  },
  {
    name: "einheitMastrNummer",
    form: GENERATOR_EDIT_TABS.TAB_GENERAL,
    apiCall: API_CALLS.MASTR_EINHEIT
  },
  {
    name: "nameStromerzeugungseinheit",
    form: GENERATOR_EDIT_TABS.TAB_GENERAL,
    apiCall: API_CALLS.MASTR_EINHEIT
  },
  {
    name: "datumErstmaligeInbetriebnahme",
    form: GENERATOR_EDIT_TABS.TAB_GENERAL,
    apiCall: API_CALLS.MASTR_EINHEIT
  },
  {
    name: "einspeisungsart",
    form: GENERATOR_EDIT_TABS.TAB_GENERAL,
    apiCall: API_CALLS.MASTR_EINHEIT
  },
  {
    name: "technologie",
    form: GENERATOR_EDIT_TABS.TAB_GENERAL,
    apiCall: API_CALLS.MASTR_EINHEIT
  },
  {
    name: "energietraeger",
    form: GENERATOR_EDIT_TABS.TAB_GENERAL,
    apiCall: API_CALLS.MASTR_EINHEIT
  },
  {
    name: "hauptbrennstoff",
    form: GENERATOR_EDIT_TABS.TAB_GENERAL,
    apiCall: API_CALLS.MASTR_EINHEIT
  },
  {
    name: "typenbezeichnung",
    form: GENERATOR_EDIT_TABS.TAB_GENERAL,
    apiCall: API_CALLS.MASTR_EINHEIT
  },
  {
    name: "seriennummer",
    form: GENERATOR_EDIT_TABS.TAB_GENERAL,
    apiCall: API_CALLS.MASTR_EINHEIT,
    dependencies: [
      {
        field: "type",
        value: "EEGAnlageWind"
      }
    ]
  },
  {
    name: "beschreibung",
    form: GENERATOR_EDIT_TABS.TAB_GENERAL,
    apiCall: API_CALLS.MASTR_EINHEIT
  },
  {
    name: "imMastrRegistriert",
    form: GENERATOR_EDIT_TABS.TAB_EEG_ANLAGE_DATA,
    section: EEG_ANLAGE_SECTIONS.GENERAL,
    apiCall: API_CALLS.ANLAGE_EEG
  },
  {
    name: "mastrNummer",
    form: GENERATOR_EDIT_TABS.TAB_EEG_ANLAGE_DATA,
    section: EEG_ANLAGE_SECTIONS.GENERAL,
    apiCall: API_CALLS.ANLAGE_EEG
  },
  {
    name: "bezeichnung",
    form: GENERATOR_EDIT_TABS.TAB_EEG_ANLAGE_DATA,
    section: EEG_ANLAGE_SECTIONS.GENERAL,
    apiCall: API_CALLS.ANLAGE_EEG
  },
  {
    name: "foerderregime",
    form: GENERATOR_EDIT_TABS.TAB_EEG_ANLAGE_DATA,
    section: EEG_ANLAGE_SECTIONS.GENERAL,
    apiCall: API_CALLS.ANLAGE_EEG
  },
  {
    name: "anlagenschluesselEeg",
    form: GENERATOR_EDIT_TABS.TAB_EEG_ANLAGE_DATA,
    section: EEG_ANLAGE_SECTIONS.GENERAL,
    apiCall: API_CALLS.ANLAGE_EEG
  },
  {
    name: "eegInbetriebnahmedatum",
    form: GENERATOR_EDIT_TABS.TAB_EEG_ANLAGE_DATA,
    section: EEG_ANLAGE_SECTIONS.GENERAL,
    apiCall: API_CALLS.ANLAGE_EEG
  },
  {
    name: "jahrDesGebotstermins",
    form: GENERATOR_EDIT_TABS.TAB_EEG_ANLAGE_DATA,
    section: EEG_ANLAGE_SECTIONS.GENERAL,
    apiCall: API_CALLS.ANLAGE_EEG
  },
  {
    name: "installierteLeistung",
    form: GENERATOR_EDIT_TABS.TAB_EEG_ANLAGE_DATA,
    section: EEG_ANLAGE_SECTIONS.GENERAL,
    apiCall: API_CALLS.ANLAGE_EEG
  },
  {
    name: "anzulegenderWert",
    form: GENERATOR_EDIT_TABS.TAB_EEG_ANLAGE_DATA,
    section: EEG_ANLAGE_SECTIONS.GENERAL,
    apiCall: API_CALLS.ANLAGE_EEG
  },
  {
    name: "ownConsumptionBefore_2014_08_01",
    form: GENERATOR_EDIT_TABS.TAB_EEG_ANLAGE_DATA,
    section: EEG_ANLAGE_SECTIONS.EEG_EIGENVERBRAUCH,
    apiCall: API_CALLS.GENERAL
  },
  {
    name: "ownConsumptionBefore_2015_01_01",
    form: GENERATOR_EDIT_TABS.TAB_EEG_ANLAGE_DATA,
    section: EEG_ANLAGE_SECTIONS.EEG_EIGENVERBRAUCH,
    apiCall: API_CALLS.GENERAL
  },
  {
    name: "modernized",
    form: GENERATOR_EDIT_TABS.TAB_EEG_ANLAGE_DATA,
    section: EEG_ANLAGE_SECTIONS.EEG_EIGENVERBRAUCH,
    apiCall: API_CALLS.GENERAL
  },
  {
    name: "initialInstalledCapacity",
    form: GENERATOR_EDIT_TABS.TAB_EEG_ANLAGE_DATA,
    section: EEG_ANLAGE_SECTIONS.REGULATORY_DATA,
    apiCall: API_CALLS.GENERAL,
    hiddenIfNull: true
  },
  {
    name: "approvalDate",
    form: GENERATOR_EDIT_TABS.TAB_EEG_ANLAGE_DATA,
    section: EEG_ANLAGE_SECTIONS.EEG_EIGENVERBRAUCH,
    apiCall: API_CALLS.GENERAL
  },
  {
    name: "operatorHasChangedSince_01_01_2017",
    form: GENERATOR_EDIT_TABS.TAB_EEG_ANLAGE_DATA,
    section: EEG_ANLAGE_SECTIONS.EEG_EIGENVERBRAUCH,
    apiCall: API_CALLS.GENERAL
  },
  {
    name: "buildingInstalled",
    form: GENERATOR_EDIT_TABS.TAB_EEG_ANLAGE_DATA,
    section: EEG_ANLAGE_SECTIONS.REGULATORY_DATA,
    apiCall: API_CALLS.GENERAL,
    hiddenIfNull: true
  },
  {
    name: "residentialBuildingInstalled",
    form: GENERATOR_EDIT_TABS.TAB_EEG_ANLAGE_DATA,
    section: EEG_ANLAGE_SECTIONS.REGULATORY_DATA,
    apiCall: API_CALLS.GENERAL,
    hiddenIfNull: true
  },
  {
    name: "eligibleForNonBuildingInstalledFeedinTariff",
    form: GENERATOR_EDIT_TABS.TAB_EEG_ANLAGE_DATA,
    section: EEG_ANLAGE_SECTIONS.REGULATORY_DATA,
    apiCall: API_CALLS.GENERAL,
    hiddenIfNull: true
  },
  {
    name: "registeredForMieterstrom",
    form: GENERATOR_EDIT_TABS.TAB_EEG_ANLAGE_DATA,
    section: EEG_ANLAGE_SECTIONS.REGULATORY_DATA,
    apiCall: API_CALLS.GENERAL,
    hiddenIfNull: true
  },
  {
    name: "imMastrRegistriert",
    form: GENERATOR_EDIT_TABS.TAB_KWK_ANLAGE_DATA,
    apiCall: API_CALLS.ANLAGE_KWK
  },
  {
    name: "mastrNummer",
    form: GENERATOR_EDIT_TABS.TAB_KWK_ANLAGE_DATA,
    apiCall: API_CALLS.ANLAGE_KWK
  },
  {
    name: "bezeichnung",
    form: GENERATOR_EDIT_TABS.TAB_KWK_ANLAGE_DATA,
    apiCall: API_CALLS.ANLAGE_KWK
  },
  {
    name: "foerderregime",
    form: GENERATOR_EDIT_TABS.TAB_KWK_ANLAGE_DATA,
    apiCall: API_CALLS.ANLAGE_KWK
  },
  {
    name: "vorrichtungenAbwaermeabfuhr",
    form: GENERATOR_EDIT_TABS.TAB_KWK_ANLAGE_DATA,
    apiCall: API_CALLS.ANLAGE_KWK
  },
  {
    name: "elektrischeKwkLeistung",
    form: GENERATOR_EDIT_TABS.TAB_KWK_ANLAGE_DATA,
    apiCall: API_CALLS.ANLAGE_KWK
  },
  {
    name: "inbetriebnahmedatum",
    form: GENERATOR_EDIT_TABS.TAB_KWK_ANLAGE_DATA,
    apiCall: API_CALLS.ANLAGE_KWK
  },
  {
    name: "mechEnergieStromerzeugungParagraph_3Abs_1Nr_1Energiestg",
    form: GENERATOR_EDIT_TABS.TAB_KWK_ANLAGE_DATA,
    apiCall: API_CALLS.ANLAGE_KWK
  },
  {
    name: "hocheffizient",
    form: GENERATOR_EDIT_TABS.TAB_KWK_ANLAGE_DATA,
    apiCall: API_CALLS.ANLAGE_KWK
  },
  {
    name: "energiesteuerentlastungParagraph_53aEnergiestg",
    form: GENERATOR_EDIT_TABS.TAB_KWK_ANLAGE_DATA,
    apiCall: API_CALLS.ANLAGE_KWK
  },
  {
    name: "energiesteuerentlastungParagraph_53Energiestg",
    form: GENERATOR_EDIT_TABS.TAB_KWK_ANLAGE_DATA,
    apiCall: API_CALLS.ANLAGE_KWK
  },
  {
    name: "steuerbefreiungStromZurStromerzParagraph_9Abs_2Stromstg",
    form: GENERATOR_EDIT_TABS.TAB_KWK_ANLAGE_DATA,
    apiCall: API_CALLS.ANLAGE_KWK
  },
  {
    name: "bafaAnlagennummer",
    form: GENERATOR_EDIT_TABS.TAB_KWK_ANLAGE_DATA,
    apiCall: API_CALLS.ANLAGE_KWK
  },
  {
    name: "gesamtwirkungsgrad",
    form: GENERATOR_EDIT_TABS.TAB_KWK_ANLAGE_DATA,
    apiCall: API_CALLS.ANLAGE_KWK
  },
  {
    name: "nutzungsgrad",
    form: GENERATOR_EDIT_TABS.TAB_KWK_ANLAGE_DATA,
    apiCall: API_CALLS.ANLAGE_KWK
  },
  {
    name: "vollbenutzungsstunden",
    form: GENERATOR_EDIT_TABS.TAB_KWK_ANLAGE_DATA,
    apiCall: API_CALLS.ANLAGE_KWK
  },

  {
    name: "beschreibung",
    form: GENERATOR_EDIT_TABS.TAB_KWK_ANLAGE_DATA,
    apiCall: API_CALLS.ANLAGE_KWK
  },
  {
    name: STROMSTEUER_FIELDS_ABOVE_TABLE.HAS_STANDORTUEBERGREIFENDE_VERKLAMMERUNG,
    form: GENERATOR_EDIT_TABS.TAB_STROMSTEUER_DATA,
    apiCall: API_CALLS.ANLAGE_STROMSTG
  },
  {
    name: "stfreiParagraph_9Abs_1Nr_1Stromstg",
    form: GENERATOR_EDIT_TABS.TAB_STROMSTEUER_DATA,
    apiCall: API_CALLS.ANLAGE_STROMSTG
  },
  {
    name: "datumErlaubnisStfreiParagraph_9Abs_1Nr_1Stromstg",
    form: GENERATOR_EDIT_TABS.TAB_STROMSTEUER_DATA,
    apiCall: API_CALLS.ANLAGE_STROMSTG,
    dependencies: [
      {
        field: "stfreiParagraph_9Abs_1Nr_1Stromstg",
        value: "yes"
      }
    ]
  },
  {
    name: "einzelerlaubnisSteuerfreieEntnahmeZurStromerzeugung",
    form: GENERATOR_EDIT_TABS.TAB_STROMSTEUER_DATA,
    apiCall: API_CALLS.ANLAGE_STROMSTG
  },
  {
    name: "datumEinzelerlaubnisSteuerfreieEntnahmeZurStromerzeugung",
    form: GENERATOR_EDIT_TABS.TAB_STROMSTEUER_DATA,
    apiCall: API_CALLS.ANLAGE_STROMSTG,
    dependencies: [
      {
        field: "einzelerlaubnisSteuerfreieEntnahmeZurStromerzeugung",
        value: "yes"
      }
    ]
  },
  {
    name: GENERATOR_STROMSTEUER_FIELDS.STROMSTG_PARAGRAPH_9_1_3_A,
    form: GENERATOR_EDIT_TABS.TAB_STROMSTEUER_DATA,
    apiCall: API_CALLS.ANLAGE_STROMSTG
  },
  {
    name: GENERATOR_STROMSTEUER_FIELDS.ZEITRAUM_STROMSTG_PARAGRAPH_9_1_3_A,
    form: GENERATOR_EDIT_TABS.TAB_STROMSTEUER_DATA,
    apiCall: API_CALLS.ANLAGE_STROMSTG,
    dependencies: [
      {
        field: GENERATOR_STROMSTEUER_FIELDS.STROMSTG_PARAGRAPH_9_1_3_A,
        value: "yes"
      }
    ]
  },
  {
    name: GENERATOR_STROMSTEUER_FIELDS.STROMSTG_PARAGRAPH_9_1_3_B,
    form: GENERATOR_EDIT_TABS.TAB_STROMSTEUER_DATA,
    apiCall: API_CALLS.ANLAGE_STROMSTG
  },
  {
    name: GENERATOR_STROMSTEUER_FIELDS.ZEITRAUM_STROMSTG_PARAGRAPH_9_1_3_B,
    form: GENERATOR_EDIT_TABS.TAB_STROMSTEUER_DATA,
    apiCall: API_CALLS.ANLAGE_STROMSTG,
    dependencies: [
      {
        field: GENERATOR_STROMSTEUER_FIELDS.STROMSTG_PARAGRAPH_9_1_3_B,
        value: "yes"
      }
    ]
  },
  {
    name: "hauptzollamtAnlagennummer",
    form: GENERATOR_EDIT_TABS.TAB_STROMSTEUER_DATA,
    apiCall: API_CALLS.ANLAGE_STROMSTG
  }
];

const HIDDEN_MASTR_FIELDS = ["eeganlage", "kwkanlage", "id"];

export function saveGeneratorData(generatorId, forms, product) {
  const newForms = [...forms];
  const generalFormIndex = forms.findIndex(
    (form) => form.name === GENERATOR_EDIT_TABS.TAB_GENERAL
  );
  const eegAnlageDataFormIndex = forms.findIndex(
    (form) => form.name === GENERATOR_EDIT_TABS.TAB_EEG_ANLAGE_DATA
  );
  const kwkAnlageDataFormIndex = forms.findIndex(
    (form) => form.name === GENERATOR_EDIT_TABS.TAB_KWK_ANLAGE_DATA
  );
  const stromstgDataFormIndex = forms.findIndex(
    (form) => form.name === GENERATOR_EDIT_TABS.TAB_STROMSTEUER_DATA
  );
  const priceDataFormIndex = forms.findIndex(
    (form) => form.name === GENERATOR_EDIT_TABS.TAB_PRICE_DATA
  );
  let didError = false;
  let serverError = null;

  newForms.forEach((form, index) => {
    newForms[index] = {
      ...form,
      sections: form.sections.map((section) => {
        return {
          ...section,
          errors: {}
        };
      })
    };
  });

  function handleSaveGeneralDatafieldsError(error) {
    if (error.response && error.response.status === 400) {
      const generalFormFieldNames = FIELDS.filter(
        (field) =>
          field.apiCall === API_CALLS.GENERAL &&
          checkForm(field.form, GENERATOR_EDIT_TABS.TAB_GENERAL, product)
      ).map((field) => field.name);

      const generalErrors = Object.keys(error.response.data).reduce(
        (errors, key) => {
          if (generalFormFieldNames.includes(key)) {
            errors[key] = error.response.data[key];
          }

          return errors;
        },
        {}
      );

      newForms[generalFormIndex] = {
        ...newForms[generalFormIndex],
        sections: [
          {
            ...newForms[generalFormIndex].sections[0],
            errors: {
              ...newForms[generalFormIndex].sections[0].errors,
              ...generalErrors
            }
          }
        ]
      };

      if (newForms[eegAnlageDataFormIndex]) {
        const regulatoryDataFieldNames = FIELDS.filter(
          (field) =>
            field.apiCall === API_CALLS.GENERAL &&
            checkForm(
              field.form,
              GENERATOR_EDIT_TABS.TAB_EEG_ANLAGE_DATA,
              product
            )
        ).map((field) => field.name);

        const regulatoryDataErrors = Object.keys(error.response.data).reduce(
          (errors, key) => {
            if (regulatoryDataFieldNames.includes(key)) {
              errors[key] = error.response.data[key];
            }

            return errors;
          },
          {}
        );

        const regulatoryDataSectionIndex = Object.values(
          EEG_ANLAGE_SECTIONS
        ).findIndex(
          (section) => section === EEG_ANLAGE_SECTIONS.REGULATORY_DATA
        );
        const newSections = [...newForms[eegAnlageDataFormIndex].sections];

        newSections[regulatoryDataSectionIndex] = {
          ...newSections[regulatoryDataSectionIndex],
          errors: { ...regulatoryDataErrors }
        };

        newForms[eegAnlageDataFormIndex] = {
          ...newForms[eegAnlageDataFormIndex],
          sections: newSections
        };
      }

      if (newForms[priceDataFormIndex]) {
        const priceDataFieldNames = FIELDS.filter(
          (field) =>
            field.apiCall === API_CALLS.GENERAL &&
            checkForm(field.form, GENERATOR_EDIT_TABS.TAB_PRICE_DATA, product)
        ).map((field) => field.name);

        const priceDataErrors = Object.keys(error.response.data).reduce(
          (errors, key) => {
            if (priceDataFieldNames.includes(key)) {
              errors[key] = error.response.data[key];
            }

            return errors;
          },
          {}
        );

        newForms[priceDataFormIndex] = {
          ...newForms[priceDataFormIndex],
          sections: [
            {
              ...newForms[priceDataFormIndex].sections[0],
              errors: {
                ...newForms[priceDataFormIndex].sections[0].errors,
                ...priceDataErrors
              }
            }
          ]
        };
      }

      if (
        Object.prototype.hasOwnProperty.call(
          error.response.data,
          "nonFieldErrors"
        )
      ) {
        serverError = error;
      }
    } else {
      serverError = error;
    }

    didError = true;
  }

  function handleSaveMastrEinheitDataError(error) {
    if (error.formErrors) {
      newForms[generalFormIndex] = {
        ...newForms[generalFormIndex],
        sections: [
          {
            ...newForms[generalFormIndex].sections[0],
            errors: {
              ...newForms[generalFormIndex].sections[0].errors,
              ...error.formErrors
            }
          }
        ]
      };
    } else {
      serverError = error;
    }

    didError = true;
  }

  function handleSaveMastrAnlageError(error) {
    if (error.formErrors) {
      let eegAnlageErrors, kwkAnlageErrors;

      if (error.formErrors.length === 2) {
        eegAnlageErrors = error.formErrors[0];
        kwkAnlageErrors = error.formErrors[1];
      } else if (eegAnlageDataFormIndex) {
        eegAnlageErrors = error.formErrors[0];
      } else if (kwkAnlageDataFormIndex) {
        kwkAnlageErrors = error.formErrors[0];
      }

      if (newForms[eegAnlageDataFormIndex]) {
        newForms[eegAnlageDataFormIndex] = {
          ...newForms[eegAnlageDataFormIndex],
          sections: newForms[eegAnlageDataFormIndex].sections.map((section) => {
            return {
              ...section,
              errors: eegAnlageErrors ? eegAnlageErrors : {}
            };
          })
        };
      }

      if (newForms[kwkAnlageErrors]) {
        newForms[kwkAnlageErrors] = {
          ...newForms[kwkAnlageErrors],
          sections: newForms[kwkAnlageErrors].sections.map((section) => {
            return {
              ...section,
              errors: kwkAnlageErrors ? kwkAnlageErrors : {}
            };
          })
        };
      }
    } else {
      serverError = error;
    }

    didError = true;
  }

  function handleSaveStromstgAnlageDataError(error) {
    if (error.formErrors) {
      newForms[stromstgDataFormIndex] = {
        ...newForms[stromstgDataFormIndex],
        sections: newForms[stromstgDataFormIndex].sections.map((section) => {
          if (section.Component) {
            return section;
          } else {
            return {
              ...section,
              errors: error.formErrors
            };
          }
        })
      };
    } else {
      serverError = error;
    }

    didError = true;
  }

  const generalDataValues = getValuesFromFormsForApiCall(
    forms,
    [
      GENERATOR_EDIT_TABS.TAB_GENERAL,
      GENERATOR_EDIT_TABS.TAB_EEG_ANLAGE_DATA,
      GENERATOR_EDIT_TABS.TAB_KWK_ANLAGE_DATA,
      GENERATOR_EDIT_TABS.TAB_PRICE_DATA
    ],
    API_CALLS.GENERAL,
    product
  );

  return saveGeneralDatafields(
    ObjectName.Generator,
    generatorId,
    generalDataValues
  )
    .catch(handleSaveGeneralDatafieldsError)
    .then((response) => {
      const additionalDataPromises = [];
      const generalForm = forms.find(
        (form) => form.name === GENERATOR_EDIT_TABS.TAB_GENERAL
      );
      const mastrEinheitUrl = generalForm.mastrEinheitUrl;

      if (mastrEinheitUrl) {
        const mastrEinheitDataValues = getValuesFromFormsForApiCall(
          forms,
          [GENERATOR_EDIT_TABS.TAB_GENERAL],
          API_CALLS.MASTR_EINHEIT,
          product
        );
        const mastrEinheitPromise = putMastrEinheitData(
          mastrEinheitUrl,
          mastrEinheitDataValues
        ).catch(handleSaveMastrEinheitDataError);

        additionalDataPromises.push(mastrEinheitPromise);

        if (generalForm.eegAnlageUrl || generalForm.kwkAnlageUrl) {
          const eegAnlageDataValues = getValuesFromFormsForApiCall(
            forms,
            [GENERATOR_EDIT_TABS.TAB_EEG_ANLAGE_DATA],
            API_CALLS.ANLAGE_EEG,
            product
          );
          const kwkAnlageDataValues = getValuesFromFormsForApiCall(
            forms,
            [GENERATOR_EDIT_TABS.TAB_KWK_ANLAGE_DATA],
            API_CALLS.ANLAGE_KWK,
            product
          );
          const cleanedKwkAnlageDataValues =
            cleanKwkAnlageDataValues(kwkAnlageDataValues);

          const mastrAnlagePromise = putMastrAnlageData(
            generalForm.eegAnlageUrl,
            generalForm.kwkAnlageUrl,
            eegAnlageDataValues,
            cleanedKwkAnlageDataValues
          ).catch(handleSaveMastrAnlageError);

          additionalDataPromises.push(mastrAnlagePromise);
        }
      }

      const stromStgAnlageValues = getValuesFromFormsForApiCall(
        forms,
        [GENERATOR_EDIT_TABS.TAB_STROMSTEUER_DATA],
        API_CALLS.ANLAGE_STROMSTG,
        product
      );

      if (Object.keys(stromStgAnlageValues).length > 0) {
        const stromStgAnlageForm = forms.find(
          (form) => form.name === GENERATOR_EDIT_TABS.TAB_STROMSTEUER_DATA
        );
        const anlageStromstgPromise = putAnlageStromStgData(
          stromStgAnlageForm.anlageStromstgUrl,
          stromStgAnlageValues,
          stromStgAnlageForm.extraProps.variantId
        ).catch(handleSaveStromstgAnlageDataError);

        additionalDataPromises.push(anlageStromstgPromise);
      }

      return Promise.all(additionalDataPromises).then(() => {
        return Promise.resolve({
          responseData: response ? response.data : null,
          forms: newForms,
          didError: didError,
          serverError: serverError
        });
      });
    });
}

export async function loadAnlageData(
  queryClient,
  product,
  eegAnlageUrl,
  kwkAnlageUrl
) {
  if (!eegAnlageUrl && !kwkAnlageUrl) {
    return null;
  }

  const data = {};

  if (eegAnlageUrl) {
    const visibleEegAnlageFields = FIELDS.filter(
      (field) =>
        field.apiCall === API_CALLS.ANLAGE_EEG &&
        checkForm(
          field.form,
          GENERATOR_EDIT_TABS.TAB_EEG_ANLAGE_DATA,
          product
        ) &&
        !field.hiddenIfNull
    );
    const visibleEegAnlageFieldNames = visibleEegAnlageFields.map(
      (field) => field.name
    );

    const eegAnlageData = await loadEegAnlageData(
      queryClient,
      eegAnlageUrl,
      visibleEegAnlageFieldNames
    );

    data.eegAnlage = eegAnlageData;
  }

  if (kwkAnlageUrl) {
    const visibleKwkAnlageFields = FIELDS.filter(
      (field) =>
        field.apiCall === API_CALLS.ANLAGE_KWK &&
        checkForm(
          field.form,
          GENERATOR_EDIT_TABS.TAB_KWK_ANLAGE_DATA,
          product
        ) &&
        !field.hiddenIfNull
    );
    const visibleKwkAnlageFieldNames = visibleKwkAnlageFields.map(
      (field) => field.name
    );

    const kwkAnlageData = await loadKwkAnlageData(
      queryClient,
      kwkAnlageUrl,
      visibleKwkAnlageFieldNames
    );
    data.kwkAnlage = kwkAnlageData;
  }

  return data;
}

export async function loadGeneratorData(
  queryClient,
  generatorId,
  siteId,
  variantId,
  product,
  showPriceDataTab,
  isPremiumSite
) {
  const sitesList = await queryClient.fetchQuery({
    queryKey: ["sites-list", { variantId }],
    queryFn: () => fetchSitesList(variantId)
  });
  const showHasStandortuebergreifendeVerklammerungField = sitesList.length > 1;

  return loadGeneralDataFields(
    queryClient,
    ObjectName.Generator,
    generatorId,
    siteId
  )
    .catch((error) => Promise.reject(error))
    .then((response) => {
      const generalDataFieldsPostResponse = response.option.data.actions.pOST;
      const generalDataInitialValues = response.detail.data;
      const mastrEinheitUrl = generalDataInitialValues.einheit;
      const anlageStromstgId = generalDataInitialValues.anlageStromstg;

      return loadMastrData(queryClient, mastrEinheitUrl)
        .then(([mastrEinheitFieldsPostResponse, mastrEinheitInitialValues]) => {
          const generalData = sortDataIntoGeneralDataForm(
            generalDataFieldsPostResponse,
            generalDataInitialValues,
            mastrEinheitFieldsPostResponse,
            mastrEinheitInitialValues,
            product,
            generatorId,
            siteId,
            variantId,
            isPremiumSite
          );

          const forms = [generalData];
          const eegAnlageUrl = mastrEinheitInitialValues["eeganlage"];
          const kwkAnlageUrl = mastrEinheitInitialValues["kwkanlage"];

          let otherFormsPromise;

          if (eegAnlageUrl || kwkAnlageUrl || anlageStromstgId) {
            otherFormsPromise = Promise.all([
              loadAnlageData(queryClient, product, eegAnlageUrl, kwkAnlageUrl),
              loadAnlageStromStg(queryClient, anlageStromstgId)
            ]).then(([anlageData, anlageStromstgIdData]) => {
              if (anlageData) {
                const anlageForms = sortAnlageDataIntoAnlageForms(
                  anlageData,
                  generalDataFieldsPostResponse,
                  generalDataInitialValues,
                  product,
                  generatorId,
                  siteId
                );

                forms.push(...anlageForms);
              }

              if (anlageStromstgIdData) {
                const fieldsResponse = {
                  ...generalDataFieldsPostResponse,
                  ...anlageStromstgIdData.fieldsResponse
                };
                const initialValues = {
                  ...generalDataInitialValues,
                  ...anlageStromstgIdData.initialValues
                };

                const anlageStromstgForm =
                  sortStromsteuerDataIntoStromsteuerDataForm(
                    fieldsResponse,
                    initialValues,
                    anlageStromstgId,
                    product,
                    showHasStandortuebergreifendeVerklammerungField
                  );

                if (anlageStromstgForm !== null) {
                  anlageStromstgForm.anlageStromstgUrl =
                    anlageStromstgIdData.anlageStromstgUrl;
                  anlageStromstgForm.extraProps = {
                    variantId: variantId
                  };

                  forms.push(anlageStromstgForm);
                }
              }

              return Promise.resolve();
            });
          } else {
            otherFormsPromise = Promise.resolve();
          }

          return otherFormsPromise.then(() => Promise.resolve(forms));
        })
        .then((forms) => {
          if (showPriceDataTab && product === Product.Manager) {
            const priceDataForm = sortPriceDataIntoPriceDataForm(
              generalDataFieldsPostResponse,
              generalDataInitialValues,
              product
            );
            forms.push(priceDataForm);
          }

          const generatorHistory = buildHistoryData(
            GENERATOR_EDIT_TABS.TAB_HISTORY,
            ObjectName.Generator,
            generatorId,
            {
              ...buildFieldNameToLabelMapOldForms(forms),
              operation_periods: "Betreiber"
            }
          );
          forms.push(generatorHistory);

          return Promise.resolve(forms);
        });
    });
}

export function sortDataIntoGeneralDataForm(
  generalDataFieldsPostResponse,
  generalDataInitialValues,
  mastrEinheitFieldsPostResponse,
  mastrEinheitInitialValues,
  product,
  generatorId,
  siteId,
  variantId,
  isPremiumSite
) {
  const pvProjectorFieldOptions = getPvProjectorFields(
    generalDataFieldsPostResponse
  );
  const pvProjectorFieldValues = getPvProjectorFields(generalDataInitialValues);
  const generalSectionFieldOptions = getGeneralSectionFields(
    generalDataFieldsPostResponse
  );
  const generalSectionFieldValues = getGeneralSectionFields(
    generalDataInitialValues
  );

  const formFields = FIELDS.filter(
    (field) =>
      checkForm(field.form, GENERATOR_EDIT_TABS.TAB_GENERAL, product) &&
      (!field.product || field.product === product)
  );
  const formFieldNames = formFields.map((field) => field.name);
  const hiddenIfNullFormFields = formFields.filter(
    (field) => field.hiddenIfNull === true
  );
  const hiddenIfNullFormFieldNames = hiddenIfNullFormFields.map(
    (field) => field.name
  );
  const combinedPostResponse = {
    ...generalSectionFieldOptions,
    ...mastrEinheitFieldsPostResponse
  };
  const combinedInitialValues = {
    ...generalSectionFieldValues,
    ...mastrEinheitInitialValues
  };

  let selectedFormFields = getOrderedFormFieldsFromResponseAndValues(
    formFieldNames,
    hiddenIfNullFormFieldNames,
    combinedPostResponse,
    combinedInitialValues
  );

  const selectedPVProjectorFields = getOrderedFormFieldsFromResponseAndValues(
    PV_PROJECTOR_FIELD_NAMES,
    [],
    pvProjectorFieldOptions,
    pvProjectorFieldValues
  );

  selectedFormFields = setLoadprofileType(
    selectedFormFields,
    LoadprofileType.Generation
  );

  const selectedFormFieldsWithDependencies = mapDependenciesToSelectedFields(
    selectedFormFields,
    formFields
  );

  const generalSections = [
    {
      fields: selectedFormFieldsWithDependencies,
      values: combinedInitialValues,
      errors: {}
    }
  ];
  if (generalDataInitialValues.typeLabel === "Photovoltaik" && isPremiumSite) {
    generalSections.push({
      header: "Details zum Projektierer",
      fields: selectedPVProjectorFields,
      values: pvProjectorFieldValues,
      errors: {},
      isSubSection: true,
      isCollapsible: false
    });
  }
  return {
    name: GENERATOR_EDIT_TABS.TAB_GENERAL,
    formTitle: "Allgemeine Daten",
    extraProps: {
      objectName: ObjectName.Generator,
      componentId: generatorId,
      siteId: siteId,
      variantId: variantId,
      loadprofileType: LOADPROFILE_TYPES[ObjectName.Generator],
      initialLoadprofileListOpen: false,
      isCombined: combinedInitialValues["isCombined"],
      confirmationCheckUrl:
        urls.api.operationPeriodsMeteringReadingsRequired(generatorId)
    },
    sections: generalSections,
    mastrEinheitUrl: generalDataInitialValues.einheit,
    eegAnlageUrl: mastrEinheitInitialValues.eeganlage,
    kwkAnlageUrl: mastrEinheitInitialValues.kwkanlage
  };
}

function getPvProjectorFields(fields) {
  return {
    pvProjector: fields.pvProjector,
    pvProjectorContact: fields.pvProjectorContact,
    pvProjectorContactMail: fields.pvProjectorContactMail,
    pvProjectorContactPhone: fields.pvProjectorContactPhone
  };
}
function getGeneralSectionFields(fields) {
  const objWithoutPvProjector = { ...fields };

  delete objWithoutPvProjector.pvProjector;
  delete objWithoutPvProjector.pvProjectorContact;
  delete objWithoutPvProjector.pvProjectorContactMail;
  delete objWithoutPvProjector.pvProjectorContactPhone;
  return objWithoutPvProjector;
}

export function sortAnlageDataIntoAnlageForms(
  anlageData,
  generalDataFieldsPostResponse,
  generalDataInitialValues,
  product,
  generatorId,
  siteId
) {
  const forms = [];

  if (anlageData.eegAnlage) {
    const regulatoryDataEegFields = FIELDS.filter(
      (field) =>
        checkForm(
          field.form,
          GENERATOR_EDIT_TABS.TAB_EEG_ANLAGE_DATA,
          product
        ) && field.section === EEG_ANLAGE_SECTIONS.REGULATORY_DATA
    );

    const hiddenIfNullRegulatoryDataEegFields = regulatoryDataEegFields.filter(
      (field) => field.hiddenIfNull
    );
    const regulatoryDataEegFieldNames = regulatoryDataEegFields.map(
      (field) => field.name
    );
    const hiddenIfNullRegulatoryDataEegFieldNames =
      hiddenIfNullRegulatoryDataEegFields.map((field) => field.name);
    const selectedRegulatoryDataEegFormFields =
      getOrderedFormFieldsFromResponseAndValues(
        regulatoryDataEegFieldNames,
        hiddenIfNullRegulatoryDataEegFieldNames,
        generalDataFieldsPostResponse,
        generalDataInitialValues
      );
    const regulatoryDataEegValues = filterValues(
      generalDataInitialValues,
      selectedRegulatoryDataEegFormFields
    );

    const [generalSection] = sortAnlageDataIntoSections(anlageData.eegAnlage, [
      EEG_ANLAGE_SECTIONS.GENERAL
    ]);

    const sections = [generalSection];

    if (selectedRegulatoryDataEegFormFields.length > 0) {
      sections.push({
        fields: selectedRegulatoryDataEegFormFields,
        values: regulatoryDataEegValues,
        errors: {}
      });
    }

    const { eegInbetriebnahmedatum } = anlageData.eegAnlage.values;

    const eegDateInFullYear = eegInbetriebnahmedatum
      ? backendDateOrDateTimeToLuxonDateTime(eegInbetriebnahmedatum).year
      : 1970;
    const isAfter2015 = eegDateInFullYear && eegDateInFullYear >= 2015;

    if (!isAfter2015) {
      sections.push(
        getEegEigenverbrauchSection(
          product,
          generalDataFieldsPostResponse,
          generalDataInitialValues
        )
      );
    }

    const form = {
      name: GENERATOR_EDIT_TABS.TAB_EEG_ANLAGE_DATA,
      formTitle: "Daten zur EEG-Anlage",
      sections: sections,
      extraProps: { siteId }
    };

    forms.push(form);
  }

  if (anlageData.kwkAnlage) {
    const sections = [
      {
        ...anlageData.kwkAnlage,
        errors: {}
      }
    ];

    const form = {
      name: GENERATOR_EDIT_TABS.TAB_KWK_ANLAGE_DATA,
      formTitle: "Daten zur KWK-Anlage",
      sections: sections,
      extraProps: { componentId: generatorId, siteId }
    };

    forms.push(form);
  }

  forms.forEach((form) => {
    form.sections.forEach((section, index) => {
      form.sections[index].fields = section.fields.filter(
        (field) => !HIDDEN_MASTR_FIELDS.includes(field.name)
      );
    });
  });

  return forms;
}

function getEegEigenverbrauchSection(
  product,
  generalDataFieldsPostResponse,
  generalDataInitialValues
) {
  const eegEigenverbrauchFields = FIELDS.filter(
    (field) =>
      checkForm(field.form, GENERATOR_EDIT_TABS.TAB_EEG_ANLAGE_DATA, product) &&
      field.section === EEG_ANLAGE_SECTIONS.EEG_EIGENVERBRAUCH
  );

  const eegEigenverbrauchFieldNames = eegEigenverbrauchFields.map(
    (field) => field.name
  );

  const selectedEegEigenverbrauchFormFields =
    getOrderedFormFieldsFromResponseAndValues(
      eegEigenverbrauchFieldNames,
      [],
      generalDataFieldsPostResponse,
      generalDataInitialValues
    );

  const eegEigenverbrauchValues = filterValues(
    generalDataInitialValues,
    selectedEegEigenverbrauchFormFields
  );

  return {
    header: "EEG-Umlage auf Eigenverbrauch",
    fields: selectedEegEigenverbrauchFormFields,
    values: eegEigenverbrauchValues,
    errors: {}
  };
}

export function sortStromsteuerDataIntoStromsteuerDataForm(
  fieldsResponse,
  initialValues,
  anlageStromstgId,
  product,
  showHasStandortuebergreifendeVerklammerungField
) {
  const filteredFields = FIELDS.filter((field) =>
    checkForm(field.form, GENERATOR_EDIT_TABS.TAB_STROMSTEUER_DATA, product)
  );
  const hiddenIfNullFilteredFields = filteredFields.filter(
    (field) => field.hiddenIfNull
  );
  const filteredFieldNames = filteredFields.map((field) => field.name);
  const hiddenIfNullfilteredFieldNames = hiddenIfNullFilteredFields.map(
    (field) => field.name
  );

  const selectedFormFields = getOrderedFormFieldsFromResponseAndValues(
    filteredFieldNames,
    hiddenIfNullfilteredFieldNames,
    fieldsResponse,
    initialValues
  );
  const selectedFormFieldsBeforeTable = selectedFormFields.filter((field) => {
    if (Object.values(STROMSTEUER_FIELDS_ABOVE_TABLE).includes(field.name)) {
      if (
        field.name ===
          STROMSTEUER_FIELDS_ABOVE_TABLE.HAS_STANDORTUEBERGREIFENDE_VERKLAMMERUNG &&
        !showHasStandortuebergreifendeVerklammerungField
      ) {
        return false;
      }

      return true;
    }

    return false;
  });
  const selectedFormFieldsAfterTable = selectedFormFields.filter(
    (field) =>
      !Object.values(STROMSTEUER_FIELDS_ABOVE_TABLE).includes(field.name)
  );

  const filteredValuesBeforeTable = filterValues(
    initialValues,
    selectedFormFieldsBeforeTable
  );
  const filteredValuesAfterTable = filterValues(
    initialValues,
    selectedFormFieldsAfterTable
  );

  const selectedFormFieldsBeforeTableWithDependencies =
    mapDependenciesToSelectedFields(
      selectedFormFieldsBeforeTable,
      filteredFields
    );
  const selectedFormFieldsAfterTableWithDependencies =
    mapDependenciesToSelectedFields(
      selectedFormFieldsAfterTable,
      filteredFields
    );

  let form = null;

  if (
    selectedFormFieldsBeforeTableWithDependencies.length > 0 ||
    selectedFormFieldsAfterTableWithDependencies.length > 0
  ) {
    const sections = [];

    if (selectedFormFieldsBeforeTableWithDependencies.length > 0) {
      sections.push({
        fields: selectedFormFieldsBeforeTableWithDependencies,
        values: filteredValuesBeforeTable,
        errors: {}
      });
    }

    if (product === Product.Manager) {
      sections.push(createAnlageStromstgListSection(anlageStromstgId));
    }

    if (selectedFormFieldsAfterTableWithDependencies.length > 0) {
      sections.push({
        fields: selectedFormFieldsAfterTableWithDependencies,
        values: filteredValuesAfterTable,
        errors: {}
      });
    }

    form = {
      name: GENERATOR_EDIT_TABS.TAB_STROMSTEUER_DATA,
      formTitle: "Stromsteuerliche Anlage",
      sections: sections
    };
  }

  return form;
}

export function sortPriceDataIntoPriceDataForm(
  generalDataFieldsPostResponse,
  generalDataInitialValues,
  product
) {
  const priceDataFields = FIELDS.filter((field) =>
    checkForm(field.form, GENERATOR_EDIT_TABS.TAB_PRICE_DATA, product)
  );
  const hiddenIfNullPriceDataFields = priceDataFields.filter(
    (field) => field.hiddenIfNull
  );
  const priceDataFieldNames = priceDataFields.map((field) => field.name);
  const hiddenIfNullPriceDataFieldNames = hiddenIfNullPriceDataFields.map(
    (field) => field.name
  );
  const selectedPriceDataFormFields = getOrderedFormFieldsFromResponseAndValues(
    priceDataFieldNames,
    hiddenIfNullPriceDataFieldNames,
    generalDataFieldsPostResponse,
    generalDataInitialValues
  );
  const priceDataValues = filterValues(
    generalDataInitialValues,
    selectedPriceDataFormFields
  );

  return {
    name: GENERATOR_EDIT_TABS.TAB_PRICE_DATA,
    formTitle: "Preisdaten",
    sections: [
      {
        fields: selectedPriceDataFormFields,
        values: priceDataValues,
        errors: {}
      }
    ]
  };
}

function getValuesFromFormsForApiCall(forms, tabs, apiCall, product) {
  const formsWithValues = forms.filter((form) => tabs.includes(form.name));
  const filteredFieldNames = FIELDS.filter(
    (field) =>
      field.apiCall === apiCall &&
      tabs.includes(getFormFromDefinition(field.form, product))
  )
    .map((field) => field.name)
    .concat(PV_PROJECTOR_FIELD_NAMES);
  const values = formsWithValues.reduce((valuesFromForms, form) => {
    const formSections = form.sections.filter((section) => !section.Component);

    formSections.forEach((section) => {
      const sectionValues = Object.keys(section.values).reduce(
        (sectionValues, valueKey) => {
          if (filteredFieldNames.includes(valueKey)) {
            sectionValues[valueKey] = section.values[valueKey];
          }

          return sectionValues;
        },
        {}
      );

      valuesFromForms = {
        ...valuesFromForms,
        ...sectionValues
      };
    });

    return valuesFromForms;
  }, {});

  return values;
}

function putMastrEinheitData(mastrEinheitDataUrl, values) {
  return api.put(mastrEinheitDataUrl, values).catch((error) => {
    let errors = {};

    if (
      error.response.request.responseURL.indexOf(mastrEinheitDataUrl) > -1 &&
      error.response.status === 400
    ) {
      errors = error.response.data;
    }

    return Promise.reject({
      formErrors: errors
    });
  });
}

function putMastrAnlageData(eegAnlageUrl, kwkAnlageUrl, eegValues, kwkValues) {
  let promises = [];

  if (eegAnlageUrl) {
    const eegPromise = api.put(eegAnlageUrl, eegValues);
    promises.push(eegPromise);
  }

  if (kwkAnlageUrl) {
    const kwkPromise = api.put(kwkAnlageUrl, kwkValues);
    promises.push(kwkPromise);
  }

  return Promise.all(promises).catch((error) => {
    const errors = Array(promises.length);

    if (error.response.status === 400) {
      if (error.response.request.responseURL.indexOf(eegAnlageUrl) > -1) {
        errors[0] = error.response.data;
      } else {
        errors[promises.length - 1] = error.response.data;
      }
    }

    return Promise.reject({
      formErrors: errors
    });
  });
}

function putAnlageStromStgData(anlageUrl, dataValues, variantId) {
  if (dataValues.length === 0 || !anlageUrl) return Promise.resolve();
  dataValues.variant = variantId;
  return api.put(anlageUrl, dataValues).catch((error) => {
    let errors = {};

    if (
      error.response.request.responseURL.indexOf(anlageUrl) > -1 &&
      error.response.status === 400
    ) {
      errors = error.response.data;
    }

    return Promise.reject({
      formErrors: errors
    });
  });
}

function sortAnlageDataIntoSections(eegAnlage, sections) {
  return sections.map((section) => {
    const sectionFieldNames = FIELDS.filter(
      (field) => field.section === section
    ).map((field) => field.name);
    const sectionFields = eegAnlage.fields.filter((field) =>
      sectionFieldNames.includes(field.name)
    );
    const sectionValues = filterValues(eegAnlage.values, sectionFields);

    return {
      fields: sectionFields,
      values: sectionValues,
      errors: {}
    };
  });
}

function createAnlageStromstgListSection(id) {
  return {
    Component: AnlageStromstgList,
    id: id
  };
}

export function checkForm(formDefinition, formToCheck, product) {
  const form = getFormFromDefinition(formDefinition, product);
  return form === formToCheck;
}

function getFormFromDefinition(formDefinition, product) {
  if (typeof formDefinition === "string") {
    return formDefinition;
  }

  return formDefinition[product];
}

function cleanKwkAnlageDataValues(kwkAnlageDataValues) {
  if (!kwkAnlageDataValues || !kwkAnlageDataValues.vollbenutzungsstunden) {
    return kwkAnlageDataValues;
  }

  const cleanedValues = {
    ...kwkAnlageDataValues,
    vollbenutzungsstunden: removeUnusedYearsFromVbhArr(
      kwkAnlageDataValues.vollbenutzungsstunden
    )
  };

  return cleanedValues;
}

function removeUnusedYearsFromVbhArr(vbhArr) {
  if (vbhArr.length === 0) {
    return vbhArr;
  }

  const newVbhArr = [...vbhArr];
  let latestYearWithValues = 0;

  for (let i = 0; i < newVbhArr.length; i++) {
    if (
      newVbhArr[i].year > latestYearWithValues &&
      newVbhArr[i].hours !== null
    ) {
      latestYearWithValues = newVbhArr[i].year;
    }
  }
  if (latestYearWithValues !== 0) {
    for (let i = newVbhArr.length - 1; i >= 0; i--) {
      if (newVbhArr[i].year > latestYearWithValues) {
        newVbhArr.splice(i, 1);
      }
    }
  }

  return newVbhArr;
}

export const GENERATOR_TYPES = {
  EEG_ANLAGE_BIOMASSE: "EEGAnlageBiomasse",
  EEG_ANLAGE_GSGK: "EEGAnlageGSGK",
  EEG_ANLAGE_SOLAR: "EEGAnlageSolar",
  EEG_ANLAGE_WASSER: "EEGAnlageWasser",
  EEG_ANLAGE_WIND: "EEGAnlageWind",
  KWK_ANLAGE_VERBRENNUNG: "KWKAnlageVerbrennung",
  SONSTIGE: "Sonstige"
};
