import { AxiosError } from "axios";
import classnames from "classnames";
import type { ReactNode } from "react";
import React, { useEffect, useState } from "react";
import type { To } from "react-router";
import { generatePath } from "react-router";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../routes";
import type { GeneratorDataStatus } from "../../../utils/backend-types";
import { UNNAMED_OBJECT_NAME } from "../../../utils/constants";
import { ObjectName } from "../../../utils/enums";
import { getErrorText } from "../../../utils/get-error-text";
import { Icon } from "../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../BuildingBlocks/Icon/types";
import { ComponentDeleteModal } from "../../ComponentListContainer/ComponentList/ComponentDeleteModal/ComponentDeleteModal";
import { ComponentOpenEditModalLink } from "../../ComponentListContainer/ComponentOpenEditModalLink/ComponentOpenEditModalLink";
import { DummyLink } from "../../DummyLink/DummyLink";
import { TABS as ENERGY_DATA_TABS } from "../../EnergyData/EnergyDataView/EnergyDataView";
import { useShouldShowStaffView } from "../../StaffViewToggle/useShouldShowStaffView";
import { GeneratorOverwriteConfirmationModal } from "../GeneratorOverwriteConfirmationModal/GeneratorOverwriteConfirmationModal";
import { GeneratorStatusModal } from "../GeneratorStatusModal/GeneratorStatusModal";
import { useGetPossibleGeneratorChanges } from "../hooks/useGetPossibleGeneratorChanges";
import { useOverwriteGeneratorMutation } from "../hooks/useOverwriteGeneratorMutation";
import type { IconData } from "../StructureViewDiagram";
import { getStructureViewImageUrl } from "../utils/getStructureViewImageUrl";
import "./NodePopup.scss";

export interface NodePopupData {
  name?: string;
  icon?: string;
  iconColor?: string;
  active?: boolean;
  type?: string;
  componentId: number;
  componentType: ObjectName;
  fontWeight?: number;
  fontSize?: number;
  detail: NodePopupDataDetail | null;
}

interface NodePopupDataDetail {
  type: string;
  text: string;
  isConsumptionShareEditable?: boolean;
}

interface NodePopupProps {
  className: string;
  x: number;
  y: number;
  nodeId: number;
  icons: Array<IconData>;
  data: NodePopupData;
  projectId: string;
  siteId: number;
  userHasNoAccess?: boolean;
  setShowNotAccessibleModal?: (show: boolean) => void;
  onChangeIcon: (iconName: string) => void;
  onClickAddEdge: (nodeId: number) => void;
  onNodeDeleted: () => void;
}

function NodePopup({
  className,
  x,
  y,
  nodeId,
  icons,
  data,
  projectId,
  siteId,
  onChangeIcon,
  onClickAddEdge,
  onNodeDeleted,
  userHasNoAccess,
  setShowNotAccessibleModal
}: NodePopupProps) {
  const [showChangeIcons, setShowChangeIcons] = useState(false);
  const [deleteNode, setDeleteNode] = useState(false);
  const [
    isGeneratorOverwriteConfirmationModalOpen,
    setIsGeneratorOverwriteConfirmationModalOpen
  ] = useState(false);
  const [isGeneratorStatusModalOpen, setIsGeneratorStatusModalOpen] =
    useState(false);
  const [generatorDataStatusText, setGeneratorDataStatusText] = useState<
    string | Array<string> | ReactNode | null
  >(null);
  const [generatorDataStatus, setGeneratorDataStatus] =
    useState<GeneratorDataStatus | null>(null);

  const {
    data: possibleChanges,
    isLoading: isGettingPossibleChanges,
    refetch: refetchPossibleChanges,
    error: getPossibleChangesError
  } = useGetPossibleGeneratorChanges(data.componentId);

  const { mutate: overwriteGeneratorData, isPending: isOverwritingData } =
    useOverwriteGeneratorMutation({
      generatorId: data.componentId,
      onSuccess: () => {
        setIsGeneratorOverwriteConfirmationModalOpen(false);
        setGeneratorDataStatus("overwriteSuccess");
        setIsGeneratorStatusModalOpen(true);

        setGeneratorDataStatusText(
          <>
            <p>
              Überschreiben der Erzeugerdaten in opti.node durch die Daten des
              Erzeugers im Marktstammdatenregister beendet.
            </p>

            {data?.name && (
              <>
                <p>Die folgenden Erzeuger wurden erfolgreich importiert:</p>

                <ul>
                  <li>{data.name}</li>
                </ul>
              </>
            )}
          </>
        );
      },
      onError: (error) => {
        setIsGeneratorOverwriteConfirmationModalOpen(false);
        setGeneratorDataStatus("overwriteFail");
        setIsGeneratorStatusModalOpen(true);
        setGeneratorDataStatusText(getErrorText(error));
      }
    });

  useEffect(() => {
    if (getPossibleChangesError) {
      setIsGeneratorOverwriteConfirmationModalOpen(false);
      setGeneratorDataStatus("getPossibleChangesFail");
      setIsGeneratorStatusModalOpen(true);
      setGeneratorDataStatusText(
        getPossibleChangesError instanceof AxiosError
          ? getErrorText(getPossibleChangesError)
          : (getPossibleChangesError as unknown as string)
      );
    }
  }, [getPossibleChangesError]);

  function handleDelete() {
    setDeleteNode(true);
  }

  function handleCloseComponentDelete() {
    setDeleteNode(false);
    onNodeDeleted();
  }

  const componentToDelete = {
    id: data.componentId,
    name: data.name ?? "",
    site: siteId
  };

  function handleShowGeneratorOverwriteConfirmationModal() {
    setIsGeneratorOverwriteConfirmationModalOpen(true);
  }

  function handleCloseGeneratorOverwriteConfirmationModal() {
    setIsGeneratorOverwriteConfirmationModalOpen(false);
  }

  function handleCloseGeneratorStatusModal() {
    setIsGeneratorStatusModalOpen(false);
  }

  function handleOverwriteGeneratorData() {
    if (possibleChanges) {
      overwriteGeneratorData(possibleChanges);
    }
  }

  return (
    <div
      className={classnames("NodePopup", className)}
      style={{ top: y, left: x }}
    >
      {showChangeIcons ? (
        <ChangeIcons
          iconColor={data.iconColor}
          icons={icons}
          selectedIcon={data.icon}
          onClick={onChangeIcon}
        />
      ) : (
        <LinksAndDetails
          data={data}
          nodeId={nodeId}
          projectId={projectId}
          setShowNotAccessibleModal={setShowNotAccessibleModal}
          showGeneratorOverwriteConfirmationModal={
            handleShowGeneratorOverwriteConfirmationModal
          }
          siteId={siteId}
          userHasNoAccess={userHasNoAccess}
          onClickAddEdge={onClickAddEdge}
          onClickChangeIcon={() => setShowChangeIcons(true)}
          onClickDelete={handleDelete}
        />
      )}
      {deleteNode && componentToDelete && (
        <ComponentDeleteModal
          component={componentToDelete}
          objectName={data.componentType}
          onDeleteSuccess={handleCloseComponentDelete}
          onToggle={handleCloseComponentDelete}
        />
      )}

      {isGeneratorOverwriteConfirmationModalOpen && (
        <GeneratorOverwriteConfirmationModal
          isGeneratorOverwriteConfirmationModalOpen={
            isGeneratorOverwriteConfirmationModalOpen
          }
          isGettingPossibleChanges={isGettingPossibleChanges}
          isOverwritingData={isOverwritingData}
          possibleChanges={possibleChanges}
          refetchPossibleChanges={refetchPossibleChanges}
          onCloseGeneratorOverwriteConfirmationModal={
            handleCloseGeneratorOverwriteConfirmationModal
          }
          onOverwriteGeneratorData={handleOverwriteGeneratorData}
        />
      )}
      {isGeneratorStatusModalOpen && (
        <GeneratorStatusModal
          description={generatorDataStatusText}
          isGeneratorStatusModalOpen={isGeneratorStatusModalOpen}
          status={generatorDataStatus}
          onCloseGeneratorStatusModal={handleCloseGeneratorStatusModal}
        />
      )}
    </div>
  );
}

interface ChangeIconsProps {
  icons: Array<IconData>;
  iconColor?: string;
  selectedIcon?: string;
  onClick: (iconName: string) => void;
}

function ChangeIcons({
  icons,
  iconColor,
  selectedIcon,
  onClick
}: ChangeIconsProps) {
  return (
    <div className="ChangeIcons">
      {icons.map((icon) => (
        <NodeIcon
          key={icon.imageName}
          name={icon.imageName}
          selectedIcon={selectedIcon}
          url={getStructureViewImageUrl(icon.imageUrl, iconColor) ?? ""}
          onClick={onClick}
        />
      ))}
    </div>
  );
}

interface LinksAndDetailsProps {
  userHasNoAccess?: boolean;
  setShowNotAccessibleModal?: (show: boolean) => void;
  data: NodePopupData;
  nodeId: number;
  projectId: string;
  siteId: number;
  onClickChangeIcon: () => void;
  onClickDelete: () => void;
  onClickAddEdge: (nodeId: number) => void;
  showGeneratorOverwriteConfirmationModal: () => void;
}

function LinksAndDetails({
  data,
  nodeId,
  projectId,
  siteId,
  onClickChangeIcon,
  onClickDelete,
  onClickAddEdge,
  userHasNoAccess,
  setShowNotAccessibleModal,
  showGeneratorOverwriteConfirmationModal
}: LinksAndDetailsProps) {
  const isStaff = useShouldShowStaffView();

  const showChangeIconLink = data && data.detail;
  const name = data && data.name ? data.name : UNNAMED_OBJECT_NAME;

  return (
    <div className="LinksAndDetails">
      <div className="name-and-info">
        <p className="small">
          <span className="name">{name}</span>
          {data && (
            <React.Fragment>
              <br />
              <span className="type">{data.componentType}</span>
            </React.Fragment>
          )}
          {data && data.detail && (
            <React.Fragment>
              <br />
              <Detail detail={data.detail} />
            </React.Fragment>
          )}
          {data && data.active === false && (
            <React.Fragment>
              <br />
              <span className="inactive">
                Dieser Erzeuger ist aktuell nicht in Betrieb.
              </span>
            </React.Fragment>
          )}
        </p>
      </div>
      <div className={classnames("links", { vertical: !showChangeIconLink })}>
        {data && (
          <React.Fragment>
            <ComponentOpenEditModalLink
              componentId={data.componentId}
              linkContent={
                <span className="small">
                  <Icon name={IconName.Pencil} /> Bearbeiten
                </span>
              }
              objectName={data.componentType}
            />
            {data && data.componentType === ObjectName.Meter && (
              <>
                <PopupLink
                  icon={IconName.AreaChart}
                  text="Zählerdaten"
                  to={{
                    pathname: generatePath(
                      `${ROUTES.energyData}/${ENERGY_DATA_TABS.TAB_RAW_ENERGY_DATA}`,
                      { projectId }
                    ),
                    search: `?meterId=${data.componentId}&siteId=${siteId}`
                  }}
                />
                {data.detail?.isConsumptionShareEditable && (
                  <PopupLink
                    icon={IconName.PieChart}
                    text="Verbrauchsanteil bearbeiten"
                    to={{
                      pathname: generatePath(
                        `./${ENERGY_DATA_TABS.TAB_CONSUMPTION_SHARE}/${data.componentId}`
                      )
                    }}
                  />
                )}
              </>
            )}
            {isStaff && data.componentType === ObjectName.Generator && (
              <PopupLink
                icon={IconName.Edit}
                text="Überschreiben aus Marktstammdatenregister"
                onClick={showGeneratorOverwriteConfirmationModal}
              />
            )}
            {showChangeIconLink && (
              <PopupLink
                icon={IconName.Circle}
                text="Icon ändern"
                onClick={onClickChangeIcon}
              />
            )}
          </React.Fragment>
        )}
        <PopupLink
          icon={IconName.Chain}
          setShowNotAccessibleModal={setShowNotAccessibleModal}
          text="Verbindung hinzufügen"
          userHasNoAccess={userHasNoAccess}
          onClick={() => onClickAddEdge(nodeId)}
        />
        {data && (
          <PopupLink
            className="m--font-danger"
            icon={IconName.Trash}
            setShowNotAccessibleModal={setShowNotAccessibleModal}
            text="Löschen"
            userHasNoAccess={userHasNoAccess}
            onClick={onClickDelete}
          />
        )}
      </div>
    </div>
  );
}

interface NodeIconProps {
  url: string;
  selectedIcon?: string;
  name: string;
  onClick: (name: string) => void;
}

function NodeIcon({ url, selectedIcon, name, onClick }: NodeIconProps) {
  return (
    <img
      className={"custom-icon" + (url === selectedIcon ? " selected-icon" : "")}
      src={url}
      onClick={() => {
        onClick(name);
      }}
    />
  );
}

interface LinkProps {
  text: string;
  icon: IconName;
  className?: string;
  to?: To;
  onClick?: () => void;
  userHasNoAccess?: boolean;
  setShowNotAccessibleModal?: (show: boolean) => void;
}

function PopupLink({
  text,
  icon,
  className,
  to,
  onClick,
  userHasNoAccess,
  setShowNotAccessibleModal
}: LinkProps) {
  const Text = (
    <>
      <Icon className={className} name={icon} /> {text}
    </>
  );

  return (
    <div className="icon-link">
      {!userHasNoAccess ? (
        <span className="small">
          {onClick && <DummyLink onClick={onClick}>{Text}</DummyLink>}
          {to && <Link to={to}>{Text}</Link>}
        </span>
      ) : (
        <span className="small">
          <DummyLink
            onClick={() => {
              if (setShowNotAccessibleModal) {
                setShowNotAccessibleModal(true);
              }
            }}
          >
            <span className="link-disabled">{Text}</span>
          </DummyLink>
        </span>
      )}
    </div>
  );
}

interface DetailProps {
  detail: NodePopupDataDetail | null;
}

function Detail({ detail }: DetailProps) {
  if (detail === null) {
    return null;
  }

  return (
    <span>
      {detail.type}: {detail.text}
    </span>
  );
}

// todo: disabled for Storybook compatibility. Need solution to check against backend.
//
// NodePopup.propTypes = {
//   className: PropTypes.string.isRequired,
//   x: PropTypes.number.isRequired,
//   y: PropTypes.number.isRequired,
//   nodeId: PropTypes.number.isRequired,
//   icons: PropTypes.arrayOf(
//     PropTypes.shape({
//       image_name: PropTypes.string,
//       image_url: PropTypes.string
//     })
//   ).isRequired,
//   data: PropTypes.objectOf(
//     PropTypes.oneOfType([
//       PropTypes.number,
//       PropTypes.string,
//       PropTypes.bool,
//       PropTypes.objectOf(PropTypes.string)
//     ])
//   ).isRequired,
//   projectId: PropTypes.string.isRequired,
//   siteId: PropTypes.number.isRequired,
//   onChangeIcon: PropTypes.func.isRequired,
//   onClickAddEdge: PropTypes.func.isRequired,
//   onNodeDeleted: PropTypes.func.isRequired
// };

export { NodePopup, NodePopupProps };
