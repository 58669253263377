import React from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Navigate } from "react-router";
import { useRouteSiteId } from "../../hooks/useRouteSiteId";
import { useSiteProject } from "../../hooks/useSiteProject";
import { ROUTES } from "../../routes";
import { LoadOrError } from "../LoadOrError/LoadOrError";

function OldManagerStructureViewRoute() {
  const siteId = useRouteSiteId();
  const { isLoading, error, projectId } = useSiteProject(siteId);
  const redirectOnErrorPath = generatePath(ROUTES.managerProjectList);
  const { t } = useTranslation();

  const redirectPath =
    projectId && siteId
      ? generatePath(ROUTES.managerVariantStructure, {
          projectId: projectId,
          siteId: siteId.toString()
        })
      : null;

  const customErrors = {
    404: t("errors.SiteNotFound")
  };

  return (
    <LoadOrError
      customErrors={customErrors}
      error={error}
      errorRedirectTo={redirectOnErrorPath}
      loading={isLoading}
    >
      {redirectPath && <Navigate to={redirectPath} />}
    </LoadOrError>
  );
}

export { OldManagerStructureViewRoute };
