import React from "react";

import { THEME_VARS } from "../../../../utils/constants";
import { StatusSymbol, StatusSymbolColour } from "../../../Icons/StatusSymbol";
import { Icon } from "../../Icon/Icon";
import { IconName } from "../../Icon/types";

import "./PortletTitleWithStatusSymbol.scss";

interface PortletTitleWithStatusSymbolProps {
  title: string;
  isLoading: boolean;
  isGreen: boolean;
  isRed: boolean;
}

function PortletTitleWithStatusSymbol({
  title,
  isLoading,
  isGreen,
  isRed
}: PortletTitleWithStatusSymbolProps) {
  let statusSymbolColour = "#FFF";

  if (isGreen) {
    statusSymbolColour = StatusSymbolColour.Green;
  } else if (isRed) {
    statusSymbolColour = StatusSymbolColour.Red;
  }

  return (
    <div className="PortletTitleWithStatusSymbol">
      {isLoading ? (
        <Icon
          className="status-symbol-loader"
          name={IconName.SpinnerSpinning}
          style={{ color: THEME_VARS.brandColor }}
        />
      ) : (
        <StatusSymbol colour={statusSymbolColour} />
      )}
      {title}
    </div>
  );
}

export { PortletTitleWithStatusSymbol, PortletTitleWithStatusSymbolProps };
