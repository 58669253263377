import classNames from "classnames";
import type { ReactNode } from "react";
import React from "react";
import { Link } from "react-router-dom";
import DefaultLogoWhite from "../../../default/assets/img/logo_white.png";
import { SiteName } from "../../utils/enums";
import { FooterMenu } from "./FooterMenu";
import "./PublicPageLayout.scss";
import { SiteLogo } from "./SiteLogo";

interface PublicPageLayoutProps {
  siteName: SiteName;
  children: ReactNode;
  wide?: boolean;
}

function PublicPageLayout({
  children,
  siteName,
  wide = false
}: PublicPageLayoutProps) {
  return (
    <div
      className={classNames(
        "m-grid__item",
        "m-grid__item--fluid",
        "m-grid",
        "m-grid--ver-desktop",
        "m-grid--desktop",
        "m-grid--tablet-and-mobile",
        "m-grid--hor-tablet-and-mobile",
        "m-login",
        "m-login--1",
        "m-login--singin"
      )}
      id="m_login"
    >
      <div
        className={classNames(
          "m-grid__item",
          "m-grid__item--order-tablet-and-mobile-1",
          "m-login__aside",
          { ["wide-panel"]: wide }
        )}
      >
        <div
          className={classNames("m-stack", "m-stack--hor", "m-stack--desktop")}
        >
          <div className={classNames("m-stack__item", "m-stack__item--fluid")}>
            <div className="m-login__wrapper">
              <div className="m-login__logo">
                <Link to="/login">
                  <SiteLogo siteName={siteName} />
                </Link>
              </div>
              {siteName === SiteName.Smarendo && (
                <div className="m-login__logo">
                  <h1>SMARENDO</h1>
                </div>
              )}
              {children}
            </div>
          </div>
          <div
            className={classNames(
              "m-stack__item",
              "m-stack__item--center",
              "m-stack__item--middle",
              "m-stack__item--first"
            )}
          >
            <FooterMenu siteName={siteName} />
          </div>
        </div>
      </div>
      <div
        className={classNames(
          "m-grid__item",
          "m-grid__item--fluid",
          "m-grid",
          "m-grid--center",
          "m-grid__item--order-tablet-and-mobile-1",
          "m-login__content",
          {
            ["default-logo"]: siteName === SiteName.Optinode,
            ["smarendo-logo"]: siteName === SiteName.Smarendo
          }
        )}
      >
        <div
          className={classNames(
            "m-grid__item",
            "m-grid__item--middle",
            "logo-text"
          )}
        >
          <div>
            {siteName === SiteName.Optinode && (
              <img
                className={classNames("mx-auto", "d-block")}
                src={DefaultLogoWhite}
              />
            )}
          </div>
          {siteName === SiteName.Optinode && (
            <>
              <p>&nbsp;</p>
              <p className="m-login__msg">
                Mit opti.node lassen sich lokale Energiekonzepte ganz einfach
                planen, umsetzen und managen.
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export { PublicPageLayout };
