import { type Todo, type TodoStatus } from "../../../utils/backend-types";

export function handleChangeStatus(
  todos: Array<Todo>,
  updateTodoStatus: (editedTodo: Todo) => void,
  status: TodoStatus,
  todoId: number
) {
  const todo = todos.find((todo) => todo.id === todoId);

  if (todo) {
    updateTodoStatus({
      ...todo,
      status: status
    });
  }
}
