import classNames from "classnames";
import React from "react";
import {
  RoundedNumberFormat,
  type RoundedNumberFormatProps
} from "../../../utils/RoundedNumberFormat";

interface DecimalLabelProps extends RoundedNumberFormatProps {
  alignRight?: boolean;
  fontDanger?: boolean;
  fontSuccess?: boolean;
  fontDangerIfNegative?: boolean;
  fontSuccessIfPositive?: boolean;
  convert?: (
    value: RoundedNumberFormatProps["value"]
  ) => RoundedNumberFormatProps["value"];
}

function DecimalLabel({
  value,
  alignRight,
  className,
  convert,
  fontDanger,
  fontSuccess,
  fontDangerIfNegative,
  fontSuccessIfPositive,
  ...other
}: DecimalLabelProps) {
  const convertedValue = convert ? convert(value) : value;

  const newClassName = classNames(className, {
    "float-right": alignRight,
    "m--font-danger":
      fontDanger || (fontDangerIfNegative && isNegative(convertedValue)),
    "m--font-success":
      fontSuccess || (fontSuccessIfPositive && isPositive(convertedValue))
  });

  return (
    <RoundedNumberFormat
      className={newClassName}
      decimalScale={0}
      displayType="text"
      value={convertedValue}
      {...other}
    />
  );
}

function isNegative(value: RoundedNumberFormatProps["value"]) {
  if (value === null || typeof value === "undefined") {
    return false;
  }

  return Number(value) < 0;
}

function isPositive(value: RoundedNumberFormatProps["value"]) {
  if (value === null || typeof value === "undefined") {
    return false;
  }

  return Number(value) > 0;
}

export { DecimalLabel };
