import { useQuery } from "@tanstack/react-query";
import api from "../../../api";
import urls from "../../../urls";
import type { LoadProfileCategory } from "../LoadProfileManager.types";

export function useLoadProfileCategories() {
  const { data, isLoading, error } = useQuery({
    queryKey: ["loadProfileCategories"],
    queryFn: () => fetchLoadProfileCategories()
  });

  return { categories: data, isLoading, error };
}

async function fetchLoadProfileCategories() {
  const url = urls.api.loadprofileCategories();
  const response = await api.get<Array<LoadProfileCategory>>(url);

  return response.data;
}
