import _ from "lodash";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import axios from "../../api";
import { pollTaskStatus } from "../../utils/api-utils";
import { Alert, AlertColor } from "../Alert/Alert";
import { ErrorAlert } from "../BuildingBlocks/ErrorAlert/ErrorAlert";
import { Button } from "../Buttons/Button/Button";
import { AnimatedLoadingIcon } from "../Icons/AnimatedLoadingIcon/AnimatedLoadingIcon";

import "./Loader.scss";

/**
 * @deprecated Please use `LoadOrError` or `AnimatedLoadingIcon` instead.
 */
const loader = (WrappedComponent, showLoadingIcon = true) => {
  class Loader extends PureComponent {
    constructor(props) {
      super(props);

      if (!this.isFunctionComponent()) {
        this.ref = React.createRef();
      }

      this.abortControllersByIndex = {};
      this.state = this.getInitialState(props);
    }

    getInitialState = (props) => {
      return {
        data: _.isArray(props.dataUrls)
          ? []
          : _.isObject(props.dataUrls)
            ? {}
            : null,
        loading: true,
        error: undefined,
        redirectUrl: null,
        evaluationId: null
      };
    };

    isFunctionComponent() {
      return (
        typeof WrappedComponent !== "string" &&
        !WrappedComponent.prototype.render
      );
    }

    loadData = () => {
      this.setState({
        loading: true,
        error: undefined
      });
      _.each(this.props.dataUrls, (url, key) => {
        this.processDataUrl(url, key);
      });
      if (this.isLoadingFinished()) {
        this.setState(
          {
            loading: false
          },
          this.props.onLoadingDone
        );
      } else {
        // since we have no idea when all data will be loaded, we need to check periodically to see if it's all done
        const interval = setInterval(() => {
          if (this.isLoadingFinished()) {
            this.setState(
              {
                loading: false
              },
              this.props.onLoadingDone
            );

            clearInterval(interval);
          }
        }, 500);
      }
    };

    isLoadingFinished() {
      const dataSize = _.size(this.state.data);
      const dataUrlSize = _.size(this.props.dataUrls);
      const loadingFinished = dataSize === dataUrlSize;

      return loadingFinished || this.state.error || this.state.redirectUrl;
    }

    processDataUrl = async (dataUrl, index) => {
      if (this.abortControllersByIndex[index]) {
        this.abortControllersByIndex[index].forEach((controller) => {
          controller.abort("Repeat request");
        });

        this.abortControllersByIndex[index] = null;
      }

      const newAbortController = new AbortController();
      if (this.abortControllersByIndex[index]) {
        this.abortControllersByIndex[index].push(newAbortController);
      } else {
        this.abortControllersByIndex[index] = [newAbortController];
      }

      const data = await this.getData(dataUrl, newAbortController);

      if (data) {
        if (data.taskStatusUrl) {
          pollTaskStatus(
            data.taskStatusUrl,
            () => this.handleTaskSuccess(dataUrl, index),
            this.setErrorDuringTask,
            this.handleTaskMissing,
            500
          );
        } else {
          const processedData = _.clone(this.state.data);
          processedData[index] = data;

          this.setState({
            data: processedData
          });
        }
      }
    };

    async getData(url, abortController) {
      try {
        const response = await axios.get(url, {
          signal: abortController.signal
        });
        return response.data;
      } catch (error) {
        if (!axios.isCancel(error)) {
          this.setState({
            error: error
          });
        }

        return null;
      }
    }

    handleTaskSuccess = (dataUrl, index) => {
      const { doWhenTaskSuccessfulThenAbortLoading } = this.props;

      // if the Loader is loading an endpoint that always returns a taskStatusUrl, processDataUrl will loop infinitely
      // we need to give components a way out in this case
      if (doWhenTaskSuccessfulThenAbortLoading) {
        doWhenTaskSuccessfulThenAbortLoading();
      } else {
        this.processDataUrl(dataUrl, index);
      }
    };

    handleTaskMissing = (url, evaluationId) => {
      this.setState({
        redirectUrl: url,
        evaluationId: evaluationId,
        loading: false
      });
    };

    setErrorDuringTask = (error) => {
      this.setState({
        loading: false,
        error: error
      });
    };

    componentDidMount() {
      if (this.dataUrlsPropIsPresent()) {
        this.loadData();
      }
    }

    componentDidUpdate(prevProps) {
      if (
        // this code checks if the dataUrls differ and retrieves the new data from it
        (this.dataUrlsPropIsPresent() &&
          _.isEqual(this.props.dataUrls, prevProps.dataUrls) === false) ||
        // or get new data if the data has been marked as stale for some reason
        (!prevProps.isDataStale && this.props.isDataStale)
      ) {
        this.reload();

        if (this.props.onDataWasReloaded) {
          this.props.onDataWasReloaded();
        }
      }
    }

    dataUrlsPropIsPresent() {
      const { dataUrls } = this.props;
      return !(
        _.isNull(dataUrls) ||
        _.isUndefined(dataUrls) ||
        _.isEmpty(dataUrls)
      );
    }

    reload = () => {
      this.setState(this.getInitialState(this.props), () => {
        this.loadData();
      });
    };

    render() {
      const {
        loadingText,
        redirectHeaderText,
        redirectText,
        ...passThroughProps
      } = this.props;
      const { data, loading, error, redirectUrl, evaluationId } = this.state;

      const loaderComponent = (
        <div className="Loader">
          {showLoadingIcon ? <AnimatedLoadingIcon /> : null}
          {loadingText ? (
            <div className="loading-text">
              <p>{loadingText}</p>
            </div>
          ) : null}
        </div>
      );
      if (loading) {
        return loaderComponent;
      } else if (error) {
        const requestAborted =
          error &&
          error.request &&
          !error.response &&
          error.message === "Request aborted";
        if (requestAborted) {
          // Show loading icon if a request was aborted due to a redirect
          return loaderComponent;
        } else {
          return <ErrorAlert error={error} />;
        }
      } else if (redirectUrl) {
        return (
          <div className="Loader">
            <Alert className="redirect" color={AlertColor.Brand}>
              <h1>{redirectHeaderText}</h1>
              <p>{redirectText}</p>
              <Button
                onClick={() => {
                  window.location.replace(redirectUrl);
                }}
              >
                Eingabe
              </Button>
            </Alert>
          </div>
        );
      }

      if (this.isFunctionComponent()) {
        return (
          <WrappedComponent
            {...passThroughProps}
            data={data}
            evaluationId={evaluationId}
            onReload={this.reload}
          />
        );
      } else {
        return (
          <WrappedComponent
            {...passThroughProps}
            data={data}
            evaluationId={evaluationId}
            ref={this.ref}
            onReload={this.reload}
          />
        );
      }
    }
  }

  Loader.propTypes = {
    dataUrls: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.objectOf(PropTypes.string)
    ]),
    loadingText: PropTypes.string,
    redirectHeaderText: PropTypes.string,
    redirectText: PropTypes.string,
    onLoadingDone: PropTypes.func,
    doWhenTaskSuccessfulThenAbortLoading: PropTypes.func
  };

  Loader.defaultProps = {
    redirectHeaderText: "Weitere Daten erforderlich",
    redirectText:
      "Es werden weitere Informationen benötigt damit die Berechnung möglichst genau ausgeführt werden kann."
  };

  Loader.displayName = `(${getDisplayName(WrappedComponent)})WithLoader`;

  return Loader;
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

export default loader;
