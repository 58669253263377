import classnames from "classnames";
import React, { useMemo, useCallback } from "react";

import type { Site, Meter } from "../../../../../utils/backend-types";
import { getMeterDisplayName } from "../../../../../utils/meter";
import { TsDropdown } from "../../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";

const getChoiceGroupName = (siteId: number, sites: Array<Site>) => {
  const site = sites.find((site) => site.id === siteId);

  return !site ? "Unbekannte Liegenschaft" : site.name;
};

interface EnergyMeterSelectProps {
  sites: Array<Site>;
  meters: Array<Meter>;
  onChangeMeter: (meterId: number | null) => void;
}

function EnergyMeterSelect({
  sites,
  meters,
  onChangeMeter
}: EnergyMeterSelectProps) {
  const choices = useMemo(
    () => [
      ...meters.map((meter) => ({
        displayName: getMeterDisplayName(meter),
        value: meter.id,
        group: getChoiceGroupName(meter.site, sites)
      }))
    ],
    [meters, sites]
  );

  const handleMeterDropdownChange = useCallback(
    (_, value: string) => {
      if (value === "" || value === null) {
        onChangeMeter(null);
      } else {
        onChangeMeter(parseInt(value, 10));
      }
    },
    [onChangeMeter]
  );

  return (
    <div className={classnames("meter-dropdown")}>
      <TsDropdown
        choices={choices}
        id="meter-number-select-id"
        name="meter-number-select-class"
        placeholder="Zähler auswählen"
        required
        onChange={handleMeterDropdownChange}
      />
    </div>
  );
}

export { EnergyMeterSelect };
