import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import api from "../api";
import urls from "../urls";
import type { SuggestedMeteringOrMarketLocationResponse } from "../utils/backend-types";

export function useSuggestedMeteringOrMarketLocation(
  generatorId?: number,
  options?: Partial<
    UseQueryOptions<SuggestedMeteringOrMarketLocationResponse | undefined>
  >
) {
  const {
    data: suggestedMeteringOrMarketLocation,
    isLoading,
    error
  } = useQuery({
    queryKey: ["suggestedMeteringOrMarketLocation", { generatorId }],
    queryFn: () => fetchsuggestedMeteringOrMarketLocation(generatorId),
    enabled: !!generatorId,
    ...options
  });

  async function fetchsuggestedMeteringOrMarketLocation(generatorId?: number) {
    if (!generatorId) return undefined;

    const suggestedMeteringOrMarketLocationUrl =
      urls.api.suggestedMeteringOrMarketLocation(generatorId);
    const response = await api.get<SuggestedMeteringOrMarketLocationResponse>(
      suggestedMeteringOrMarketLocationUrl
    );
    return response.data;
  }

  return { suggestedMeteringOrMarketLocation, isLoading, error };
}
