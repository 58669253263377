import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

import { THEME_VARS } from "../../../utils/constants";
import WithHover from "../../../utils/WithHover";
import { IconWithLink } from "../../BuildingBlocks/Icon/IconWithLink/IconWithLink";
import { IconName } from "../../BuildingBlocks/Icon/types";
import { VariantRow } from "../AnalyzerProjectList/VariantRow/VariantRow";

import "./ProjectRow.scss";

function ProjectRow({
  project,
  showVariants,
  isVariantsExpanded,
  isHovered,
  onToggleExpanded,
  onSelectProjectToDelete,
  onSelectVariantToDelete,
  onSelectProjectToCopy,
  onSelectVariantToCopy,
  onClickProjectSettings,
  onClickShareProject,
  ...hoverProps
}) {
  return (
    <React.Fragment>
      <Row
        className="project-row"
        onClick={showVariants ? onToggleExpanded : null}
        {...hoverProps}
      >
        <Col md={10} sm={9}>
          <ProjectName
            id={project.id}
            name={project.name}
            showVariants={showVariants}
          />
        </Col>
        <Col className="icons" md={2} sm={3}>
          {isHovered && (
            <ProjectIcons
              project={project}
              onClickProjectSettings={onClickProjectSettings}
              onClickShareProject={onClickShareProject}
              onSelectProjectToCopy={onSelectProjectToCopy}
              onSelectProjectToDelete={onSelectProjectToDelete}
            />
          )}
        </Col>
      </Row>
      {showVariants && project.variants && (
        <VariantRows
          expanded={isVariantsExpanded}
          projectId={project.id}
          variants={project.variants}
          onSelectVariantToCopy={onSelectVariantToCopy}
          onSelectVariantToDelete={onSelectVariantToDelete}
        />
      )}
    </React.Fragment>
  );
}

function ProjectName({ name, id, showVariants }) {
  if (showVariants) {
    return <span>{name}</span>;
  } else {
    return (
      <Link className="project-name-link" to={id}>
        {name}
      </Link>
    );
  }
}

function ProjectIcons({
  project,
  onSelectProjectToDelete,
  onSelectProjectToCopy,
  onClickProjectSettings,
  onClickShareProject
}) {
  return (
    <React.Fragment>
      <ProjectIcon
        iconName={IconName.Cogs}
        linkText="Einstellungen"
        onClick={() => onClickProjectSettings(project)}
      />
      {onClickShareProject && (
        <ProjectIcon
          iconName={IconName.Share}
          linkText="Teilen"
          onClick={() => onClickShareProject(project)}
        />
      )}
      {onSelectProjectToCopy && (
        <ProjectIcon
          iconName={IconName.Copy}
          linkText="Projekt duplizieren"
          onClick={() => onSelectProjectToCopy(project)}
        />
      )}
      <ProjectIcon
        color={THEME_VARS.dangerColor}
        iconName={IconName.Trash}
        linkText="Projekt löschen"
        onClick={() => onSelectProjectToDelete(project)}
      />
    </React.Fragment>
  );
}

function VariantRows({
  variants,
  projectId,
  expanded,
  onSelectVariantToCopy,
  onSelectVariantToDelete
}) {
  if (!expanded) {
    return null;
  }

  return (
    <React.Fragment>
      {variants.map((variant) => (
        <VariantRow
          key={variant.id}
          projectId={projectId}
          variant={variant}
          onClickCopyVariant={() => onSelectVariantToCopy(variant)}
          onClickDeleteVariant={() => onSelectVariantToDelete(variant)}
        />
      ))}
    </React.Fragment>
  );
}

function ProjectIcon({
  iconName,
  linkText,
  onClick,
  color = THEME_VARS.primaryColor
}) {
  return (
    <IconWithLink
      name={iconName}
      style={{ color: color }}
      tooltipText={linkText}
      onClick={(e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        onClick();
      }}
    />
  );
}

const ProjectRowWithHover = WithHover(ProjectRow);

ProjectRow.propTypes = {
  project: PropTypes.object.isRequired,
  showVariants: PropTypes.bool,
  isVariantsExpanded: PropTypes.bool,
  isHovered: PropTypes.bool.isRequired,
  onToggleExpanded: PropTypes.func,
  onSelectProjectToCopy: PropTypes.func,
  onSelectProjectToDelete: PropTypes.func.isRequired,
  onSelectVariantToCopy: PropTypes.func,
  onSelectVariantToDelete: PropTypes.func,
  onClickProjectSettings: PropTypes.func.isRequired,
  onClickShareProject: PropTypes.func
};

export { ProjectRowWithHover as ProjectRow };
