import React from "react";
import { useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import { TabContent, TabPane } from "reactstrap";
import { useDeliveries } from "../../../../../hooks/useDeliveries";
import type { Contract, Payment } from "../../../../../utils/backend-types";
import { isApiError } from "../../../../../utils/isApiError";
import { buildFieldNameToLabelMap } from "../../../../../utils/react-hook-form/buildFieldNameToLabelMap";
import type { TabData } from "../../../../BuildingBlocks/Layout/TabBar/TabBar";
import { TabBar } from "../../../../BuildingBlocks/Layout/TabBar/TabBar";
import { HistoryTab } from "../../../../HistoryTab/HistoryTab";
import type { ContractFormProps } from "../ContractForm/ContractForm";
import { ContractForm } from "../ContractForm/ContractForm";
import { CONTRACT_FORM_FIELD_DATA } from "../ContractForm/ContractForm.constants";
import type { PaymentsTabProps } from "../Tabs/PaymentsTab";
import { PaymentsTab } from "../Tabs/PaymentsTab";

export const CONTRACT_TABS = {
  TAB_GENERAL: "allgemein",
  TAB_PAYMENT: "abschlagszahlungen",
  TAB_HISTORY: "historie"
};

const TABS: Array<TabData> = [
  {
    id: CONTRACT_TABS.TAB_GENERAL,
    label: "Allgemein"
  },
  {
    id: CONTRACT_TABS.TAB_PAYMENT,
    label: "Abschläge & sonst. Zahlungen"
  },
  {
    id: CONTRACT_TABS.TAB_HISTORY,
    label: "Historie"
  }
];

interface EditFormProps
  extends ContractFormProps,
    Pick<PaymentsTabProps, "updatePaymentsValue" | "addPayment"> {
  contract: Contract;
  paymentsData?: Array<Payment>;
  variantId: number;
}

function EditForm({
  buttonContainer,
  contract,
  options,
  paymentsData,
  variantId,
  addPayment,
  updatePaymentsValue,
  onCancel,
  onSubmit,
  missingFields,
  shouldFieldBeHighlighted
}: EditFormProps): JSX.Element {
  const navigate = useNavigate();
  const resolved = useResolvedPath(":activeTab");
  const match = useMatch({ path: resolved.pathname, end: true });
  const activeTab = match?.params.activeTab ?? CONTRACT_TABS.TAB_GENERAL;
  const fieldNameToLabelMap = buildFieldNameToLabelMap<Contract>(
    CONTRACT_FORM_FIELD_DATA
  );

  const { deliveries } = useDeliveries(variantId);

  async function handleSubmit(contract: Contract) {
    try {
      const response = await onSubmit(contract);
      return response;
    } catch (error) {
      if (
        isApiError<Record<keyof Contract, Array<string>>>(error) &&
        error.response?.data &&
        error.response.data.payments
      ) {
        navigate(`./${CONTRACT_TABS.TAB_PAYMENT}`);
      } else {
        navigate(`./${CONTRACT_TABS.TAB_GENERAL}`);
      }

      return Promise.reject(error);
    }
  }

  return (
    <div className="EditForm">
      <TabBar activeTab={activeTab} firstTabIsIndex tabs={TABS} />
      <TabContent activeTab={activeTab}>
        <TabPane className="general-tab" tabId={CONTRACT_TABS.TAB_GENERAL}>
          <ContractForm
            buttonContainer={buttonContainer}
            contract={contract}
            missingFields={missingFields}
            options={options}
            shouldFieldBeHighlighted={shouldFieldBeHighlighted}
            onCancel={onCancel}
            onSubmit={handleSubmit}
          />
        </TabPane>
        <TabPane className="payment-tab" tabId={CONTRACT_TABS.TAB_PAYMENT}>
          <PaymentsTab
            addPayment={addPayment}
            billingMethodAndPeriod={contract.billingMethodAndPeriod}
            contractId={contract.id}
            deliveries={deliveries.filter(
              (delivery) => delivery.contract === contract.id
            )}
            paymentsData={paymentsData}
            updatePaymentsValue={updatePaymentsValue}
          />
        </TabPane>
        <TabPane className="history-tab" tabId={CONTRACT_TABS.TAB_HISTORY}>
          <HistoryTab
            entityId={contract.id}
            entityType={"contract"}
            fieldNameToLabelMap={fieldNameToLabelMap}
          />
        </TabPane>
      </TabContent>
    </div>
  );
}

export { EditForm, EditFormProps };
