import React, { useEffect, useState } from "react";
import {
  Link,
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams
} from "react-router-dom";

import api from "../../../api";
import { THIRD_PARTY_SYSTEMS_URL } from "../../../routes";
import urls from "../../../urls";
import { IconWithRouterLink } from "../../BuildingBlocks/Icon/IconWithRouterLink/IconWithRouterLink";
import { IconName } from "../../BuildingBlocks/Icon/types";
import { MultiConfirmationModal } from "../../BuildingBlocks/Layout/Modals/MultiConfirmationModal/MultiConfirmationModal";
import { Button } from "../../Buttons/Button/Button";
import { DeleteIcon } from "../../Buttons/DeleteIcon";
import { CustomReactSelectTable } from "../../CustomReactTable/CustomReactTable";
import { useCustomReactTableCheckboxes } from "../../CustomReactTable/CustomReactTableHooks";
import { openErrorAlertPopup } from "../../ErrorAlertPopup/openErrorAlertPopup";
import {
  DEFAULT_SUBPAGE as EDIT_CONFIGURATION_MODAL_DEFAULT_SUBPAGE,
  EditConfigurationModal
} from "../EditConfigurationModal/EditConfigurationModal";
import { ImportLogsModal } from "../ImportLogsModal/ImportLogsModal";
import { NewConfigurationModal } from "../NewConfigurationModal/NewConfigurationModal";
import { getSystemDisplayNameFromValue } from "../utils/getSystemDisplayNameFromValue";

import "./ThirdPartySystemsList.scss";

function ThirdPartySystemsList({
  subMeteringConfigurations,
  sites,
  variantId,
  onConfigurationCreated,
  onConfigurationUpdated,
  onConfigurationsDeleted
}) {
  const [configurationsToDelete, setConfigurationsToDelete] = useState([]);
  const {
    selection,
    setSelection,
    setSelectAll,
    getSelectedData,
    customReactTableProps
  } = useCustomReactTableCheckboxes();

  const toggleDeleteConfigurationsModal = () => setConfigurationsToDelete([]);

  function handleClickDeleteSelection() {
    const selectedConfigurations = getSelectedData();
    setConfigurationsToDelete(selectedConfigurations);
  }

  function handleClickDelete(systemId) {
    const systemToDelete = subMeteringConfigurations.find(
      (system) => system.id === systemId
    );
    setConfigurationsToDelete([systemToDelete]);
  }

  function handleDeleteSelectedConfigurations(ids) {
    const deletePromises = ids.map((id) =>
      api.delete(urls.api.subMeteringConfiguration(id))
    );

    return Promise.all(deletePromises)
      .then(() => {
        onConfigurationsDeleted(ids);
        setSelection([]);
        setSelectAll(false);
      })
      .catch((error) => openErrorAlertPopup(error))
      .finally(() => {
        setConfigurationsToDelete([]);
      });
  }

  const tableColumns = [
    {
      Header: "Name",
      accessor: "name",
      minHeight: 45,
      Cell: (data) => (
        <NameCell
          id={data.original.id}
          name={data.original.name}
          system={data.original.subMeteringSystem}
        />
      )
    },
    {
      Header: "System",
      accessor: "subMeteringSystem",
      minHeight: 45,
      Cell: (data) => <SystemCell system={data.original.subMeteringSystem} />
    },
    {
      Cell: (data) => {
        return (
          <div className="icons">
            <IconWithRouterLink
              name={IconName.Pencil}
              style={{ color: "#00008b" }}
              to={`${THIRD_PARTY_SYSTEMS_URL}/${data.original.id}/${EDIT_CONFIGURATION_MODAL_DEFAULT_SUBPAGE}`}
              tooltipText="System bearbeiten"
            />
          </div>
        );
      },
      width: 30,
      sortable: false,
      resizable: false
    },
    {
      Cell: (data) => {
        return (
          <div className="icons">
            <DeleteIcon
              tooltipText="System löschen"
              onClick={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                handleClickDelete(data.original.id);
              }}
            />
          </div>
        );
      },
      width: 30,
      sortable: false,
      resizable: false
    },
    {
      Cell: (data) => {
        return (
          <div className="icons">
            <Button
              color="brand"
              size="sm"
              tag={Link}
              to={`${THIRD_PARTY_SYSTEMS_URL}/${data.original.id}/historie/`}
            >
              Import-Historie anzeigen
            </Button>
          </div>
        );
      },
      width: 175,
      sortable: false,
      resizable: false
    }
  ];

  const selectionButtonsDisabled = selection.length === 0;
  const configurationsToDeleteModalIsOpen = configurationsToDelete.length > 0;
  const configurationsToDeleteNames = configurationsToDelete.map((system) =>
    getConfigurationDisplayName(system.name, system.subMeteringSystem)
  );
  const configurationsToDeleteIds = configurationsToDelete.map(
    (system) => system.id
  );
  const dataText = selection.length <= 1 ? "Konfiguration" : "Konfigurationen";
  const newConfigurationPath = `${THIRD_PARTY_SYSTEMS_URL}/neu/`;
  const logsModalPath = `${THIRD_PARTY_SYSTEMS_URL}/:configurationId/historie/`;

  return (
    <div className="ThirdPartySystemsList">
      <div className="third-party-systems-list-controls">
        <Button color="brand" tag={Link} to={newConfigurationPath}>
          Neue Konfiguration einrichten
        </Button>
        <Button
          color="danger"
          disabled={selectionButtonsDisabled}
          onClick={handleClickDeleteSelection}
        >
          {dataText} löschen
        </Button>
      </div>
      <CustomReactSelectTable
        keyField="id"
        {...customReactTableProps}
        columns={tableColumns}
        data={subMeteringConfigurations}
        minRows={0}
        NoDataComponent={NoDataComponent}
        pageSize={subMeteringConfigurations.length}
      />
      <MultiConfirmationModal
        actionName="löschen"
        actionObjects={configurationsToDeleteNames}
        confirmationText="Möchten Sie die folgenden Konfigurationen löschen? Dieser Schritt kann nicht rückgängig gemacht werden."
        isModalOpen={configurationsToDeleteModalIsOpen}
        objectName="Konfigurationen"
        toggleModal={toggleDeleteConfigurationsModal}
        onAction={() =>
          handleDeleteSelectedConfigurations(configurationsToDeleteIds)
        }
      />
      <Routes>
        <Route
          element={
            <NewConfigurationModalWrapper
              sites={sites}
              variantId={variantId}
              onConfigurationCreated={onConfigurationCreated}
            />
          }
          path={newConfigurationPath}
        />
        <Route
          element={<LogsModalWrapper sites={sites} />}
          path={logsModalPath}
        />
        <Route path={`${THIRD_PARTY_SYSTEMS_URL}/:id`}>
          <Route
            element={
              <Navigate replace to={EDIT_CONFIGURATION_MODAL_DEFAULT_SUBPAGE} />
            }
            index
          />
          <Route
            element={
              <EditConfigurationsModalWrapper
                sites={sites}
                subMeteringConfigurations={subMeteringConfigurations}
                variantId={variantId}
                onConfigurationUpdated={onConfigurationUpdated}
              />
            }
            path=":subPage/*"
          />
        </Route>
      </Routes>
    </div>
  );
}

function NameCell({ id, name, system }) {
  const path = `${THIRD_PARTY_SYSTEMS_URL}/${id}/${EDIT_CONFIGURATION_MODAL_DEFAULT_SUBPAGE}`;
  const displayName = getConfigurationDisplayName(name, system);

  return <Link to={path}>{displayName}</Link>;
}

function SystemCell({ system }) {
  return <span>{getSystemDisplayNameFromValue(system)}</span>;
}

function NoDataComponent() {
  return (
    <div className="no-data-component">
      <p>Es gibt noch keine Konfigurationen.</p>
    </div>
  );
}

function NewConfigurationModalWrapper({
  sites,
  variantId,
  onConfigurationCreated
}) {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsOpen(true);
  }, []);

  function handleToggle() {
    setIsOpen(!isOpen);
    navigate(`../${THIRD_PARTY_SYSTEMS_URL}`, { replace: true });
  }

  return (
    <NewConfigurationModal
      isOpen={isOpen}
      sites={sites}
      variantId={variantId}
      onConfigurationCreated={onConfigurationCreated}
      onToggle={handleToggle}
    />
  );
}

function EditConfigurationsModalWrapper({
  subMeteringConfigurations,
  sites,
  variantId,
  onConfigurationUpdated
}) {
  const params = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (params.id) {
      setIsOpen(true);
    }
  }, [params.id]);

  function handleToggle() {
    setIsOpen(!isOpen);
    navigate(`../../${THIRD_PARTY_SYSTEMS_URL}`);
  }

  const id = parseInt(params.id, 10);
  const configuration = subMeteringConfigurations.find(
    (configuration) => configuration.id === id
  );

  return (
    <EditConfigurationModal
      configuration={configuration}
      isOpen={isOpen}
      sites={sites}
      variantId={variantId}
      onConfigurationUpdated={onConfigurationUpdated}
      onToggle={handleToggle}
    />
  );
}

function LogsModalWrapper({ sites }) {
  const { configurationId } = useParams();
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();

  function handleToggle() {
    setIsOpen(!isOpen);
    navigate(`../${THIRD_PARTY_SYSTEMS_URL}`);
  }

  return (
    <ImportLogsModal
      configurationId={configurationId}
      isOpen={isOpen}
      sites={sites}
      onToggle={handleToggle}
    />
  );
}

function getConfigurationDisplayName(configurationName, system) {
  let displayName = configurationName;

  if (!displayName) {
    displayName = getSystemDisplayNameFromValue(system);
  }

  return displayName;
}

export { ThirdPartySystemsList, getConfigurationDisplayName };
