import React, { useEffect, useState } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { withRouteProjectId } from "../../hocs/withRouteProjectId";
import { useProjects } from "../../hooks/useProjects";
import { ROUTES } from "../../routes";
import type { ProjectMinimal } from "../../utils/backend-types";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { useLoadProfile } from "./hooks/useLoadProfile";
import { useLoadProfileCategories } from "./hooks/useLoadProfileCategories";
import ListView from "./ListView/ListView";
import { LoadProfile } from "./LoadProfile/LoadProfile";
import type { LoadProfileCategory } from "./LoadProfileManager.types";

interface LoadProfileManagerProps {
  projectId: string;
  projects: Array<ProjectMinimal>;
  categories: Array<LoadProfileCategory>;
}

function LoadProfileManager({
  projectId,
  projects,
  categories: initialCategories
}: LoadProfileManagerProps) {
  const [categories, setCategories] = useState([...initialCategories]);

  useEffect(() => {
    setCategories([...initialCategories]);
  }, [initialCategories]);

  function handleAddCategory(newCategory: string) {
    const newCategories = [...categories];

    newCategories.push({
      id: 0,
      name: newCategory
    });
    setCategories(newCategories);
  }

  return (
    <div className="LoadProfileManager">
      <Routes>
        <Route
          element={
            <LoadProfileDetailRoute
              categories={categories}
              projects={projects}
              onAddCategory={handleAddCategory}
            />
          }
          path=":loadProfileId"
        />
        <Route
          element={<ListView categories={categories} projectId={projectId} />}
          index
        />
      </Routes>
    </div>
  );
}

interface LoadProfileDetailRouteProps {
  categories: Array<LoadProfileCategory>;
  projects: Array<ProjectMinimal>;
  onAddCategory: (category: string) => void;
}

function LoadProfileDetailRoute({
  categories,
  projects,
  onAddCategory
}: LoadProfileDetailRouteProps) {
  const { loadProfileId } = useParams();
  const { loadProfile, isLoading, error } = useLoadProfile(
    Number(loadProfileId)
  );

  return (
    <LoadOrError error={error} loading={isLoading}>
      {loadProfile && (
        <LoadProfile
          categories={categories}
          id={Number(loadProfileId)}
          loadProfile={loadProfile}
          projects={projects}
          onAddCategory={onAddCategory}
        />
      )}
    </LoadOrError>
  );
}

const LoadProfileManagerWithValidProjectId = withRouteProjectId(
  LoadProfileManager,
  true
);

function LoadProfileManagerDataWrapper() {
  const redirectPathOnMissingProject = ROUTES.analyzerProjectList;
  const {
    data: projects,
    isLoading: isLoadingProjects,
    error: errorProjects
  } = useProjects();
  const {
    categories,
    isLoading: isLoadingCategories,
    error: errorCategories
  } = useLoadProfileCategories();

  return (
    <LoadOrError
      error={errorProjects || errorCategories}
      loading={isLoadingProjects || isLoadingCategories}
    >
      {projects && categories && (
        <LoadProfileManagerWithValidProjectId
          categories={categories}
          projects={projects}
          redirectPathOnMissingProject={redirectPathOnMissingProject}
        />
      )}
    </LoadOrError>
  );
}

export { LoadProfileManagerDataWrapper as LoadProfileManager };
