import { useQuery } from "@tanstack/react-query";

import api from "../../../api";
import urls from "../../../urls";
import type { TodoCanGenerateDocumentsResponse } from "../TodoModal/DocumentsWidget/GenerateDocuments/GenerateDocuments";

function useTodoCanGenerateDocuments(
  todoId: number,
  handleErrorMessage: (message: string) => void
) {
  const { data, isLoading, error } = useQuery({
    queryKey: ["todo-can-generate-documents", { todoId }],
    queryFn: fetchTodoCanGenerateDocuments
  });

  async function fetchTodoCanGenerateDocuments() {
    const response = await api.get<TodoCanGenerateDocumentsResponse>(
      urls.api.todoCanGenerateDocuments(todoId)
    );
    if (response.data.documentGenerationBlocker) {
      handleErrorMessage(response.data.documentGenerationBlocker.message);
    }
    return response.data;
  }

  return {
    todoCanGenerateDocuments: data,
    isTodoCanGenerateDocumentsLoading: isLoading,
    todoCanGenerateDocumentsError: error
  };
}

export { useTodoCanGenerateDocuments };
