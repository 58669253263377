import React, { useState } from "react";
import type { Column } from "react-table";
import type { CancellationState } from "../../../../../types/api.types";
import { Paragraph6EEGCreditResponseState } from "../../../../../types/api.types";
import {
  CANCELLATION_STATE_CHOICES,
  INVOICE_STATE_CHOICES
} from "../../../../../utils/constants";
import { makeSafeForCSS } from "../../../../../utils/css-utils";
import type { TypedCellInfo } from "../../../../CustomReactTable/CustomReactTable.types";
import { TsDropdown } from "../../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { useParagraph6CancellationMutations } from "../../../hooks/useParagraph6CancellationMutations";
import { useParagraph6CreditMutations } from "../../../hooks/useParagraph6CreditMutations";
import { useShowParagraph6CreditsAlerts } from "../../../hooks/useShowParagraph6CreditsAlerts";
import type { Paragraph6FinancialDocument } from "../../../Paragraph6.types";
import { FinancialDocumentType } from "../../../Paragraph6.types";
import { Paragraph6PaymentDateModal } from "../../Paragraph6PaymentDateModal/Paragraph6PaymentDateModal";

function getChoicesWithDateOfPayment(dateOfPayment: string) {
  const stateChoices = [...INVOICE_STATE_CHOICES];
  stateChoices.splice(
    stateChoices.findIndex(
      (choice) => choice.value === Paragraph6EEGCreditResponseState.paid
    ),
    1,
    {
      value: Paragraph6EEGCreditResponseState.paid,
      displayName: `Bezahlt am ${dateOfPayment}`
    }
  );
  return stateChoices;
}

function getStateColumn(variantId: number) {
  return {
    Header: "Status",
    accessor: (row) => row.state,
    id: "state",
    Cell: (
      cellInfo: TypedCellInfo<
        Paragraph6EEGCreditResponseState,
        Paragraph6FinancialDocument
      >
    ) => {
      const [showConfirmationModal, setShowConfirmationModal] = useState(false);

      const { paragraph6CreditUpdateStatusMutation } =
        useParagraph6CreditMutations(variantId);

      const { showParagraph6CreditsAlerts } = useShowParagraph6CreditsAlerts();

      const { paragraph6CancellationUpdateMutation } =
        useParagraph6CancellationMutations(variantId);

      function handleCreditStateChange(
        newValue: Paragraph6EEGCreditResponseState
      ) {
        if (newValue === Paragraph6EEGCreditResponseState.paid) {
          setShowConfirmationModal(true);
        } else {
          paragraph6CreditUpdateStatusMutation.mutate(
            {
              creditId: cellInfo.original.id,
              payload: { state: newValue }
            },
            {
              onSuccess: (res) => {
                showParagraph6CreditsAlerts({
                  todoAlertState: res.data.todo_alert_state,
                  todoUrl: res.data.todo_url,
                  todoPageUrl: "../../aufgaben"
                });
              }
            }
          );
        }
      }

      function handleModalClose() {
        setShowConfirmationModal(false);
      }

      const initialPaymentDate = cellInfo.original.date_of_payment ?? undefined;

      const invoiceStateChoices =
        cellInfo.value === Paragraph6EEGCreditResponseState.paid &&
        cellInfo.original.date_of_payment
          ? getChoicesWithDateOfPayment(cellInfo.original.date_of_payment)
          : INVOICE_STATE_CHOICES;

      return (
        <>
          <Paragraph6PaymentDateModal
            creditId={cellInfo.original.id}
            initialPaymentDate={initialPaymentDate}
            isOpen={showConfirmationModal}
            variantId={variantId}
            onClose={handleModalClose}
          />
          {cellInfo.original.type == FinancialDocumentType.Cancellation ? (
            <TsDropdown
              choices={CANCELLATION_STATE_CHOICES}
              className={`invoice-state-${makeSafeForCSS(cellInfo.value)}`}
              id={`state-${cellInfo.original.id}`}
              name={`state-${cellInfo.original.id}`}
              required
              value={cellInfo.value}
              onChange={(_, newValue) =>
                paragraph6CancellationUpdateMutation.mutate({
                  cancellation: cellInfo.original,
                  payload: { state: newValue as CancellationState }
                })
              }
            />
          ) : cellInfo.original.state ===
            Paragraph6EEGCreditResponseState.cancelled ? (
            <span className={`invoice-state-${makeSafeForCSS(cellInfo.value)}`}>
              Storniert
            </span>
          ) : (
            <TsDropdown
              choices={invoiceStateChoices}
              className={`invoice-state-${makeSafeForCSS(cellInfo.value)}`}
              id={`state-${cellInfo.original.id}`}
              name={`state-${cellInfo.original.id}`}
              required
              value={cellInfo.value}
              onChange={(_, newValue) =>
                handleCreditStateChange(
                  newValue as Paragraph6EEGCreditResponseState
                )
              }
            />
          )}
        </>
      );
    },
    width: 200
  } satisfies Column<Paragraph6FinancialDocument>;
}

export { getStateColumn };
