import saveAs from "file-saver";
import { b64toBlob } from "./files/b64toBlob";
import { extractFilenameFromContentDispositionHeader } from "./files/extractFilenameFromContentDispositionHeader";

export function downloadPDF(response) {
  const contentType = response.headers["content-type"];
  const filename = extractFilenameFromContentDispositionHeader(response);
  const blob = b64toBlob(response.data, contentType);

  saveAs(blob, filename);
}
