import type { ReactElement } from "react";
import React from "react";
import { AlertColor } from "../../../Alert/Alert";
import { Icon } from "../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import { IconAlert } from "../../../BuildingBlocks/IconAlert/IconAlert";
import "./GuaranteeOfOriginCheckResult.scss";
import type { GuaranteeOfOriginCheckResponse } from "../../GuaranteeOfOrigin.types";

export interface GuaranteeOfOriginCheckResultProps {
  result: Array<GuaranteeOfOriginCheckResponse>;
}
export function GuaranteeOfOriginCheckResult({
  result
}: GuaranteeOfOriginCheckResultProps) {
  const alerts: Array<ReactElement> = [];

  if (result.some((value) => !value.isOnboardingComplete)) {
    alerts.push(
      <IconAlert color={AlertColor.Warning} key="onboarding">
        Bitte schließen Sie den Onboarding-Prozess ab. Erst dann können wir die
        Dokumentation für Ihre Herkunftsnachweise generieren.
      </IconAlert>
    );
  } else {
    if (result.some((value) => !value.isEnergyDataComplete)) {
      alerts.push(
        <IconAlert color={AlertColor.Warning} key="energy-data">
          Leider liegen die Energiedaten für den ausgewählten Zeitraum nicht
          vollständig vor. Sie können eine Dokumentation nur für die
          vorliegenden Daten erstellen oder die fehlenden Energiedaten ergänzen.
        </IconAlert>
      );
    }
    if (result.some((value) => !value.isMbkConfigurationCorrect)) {
      alerts.push(
        <IconAlert color={AlertColor.Warning} key="mbk-configuration">
          Die Berechnungsformeln der Liegenschaft sind veraltet und unterstützen
          keine zeitliche Anpassungen. Bitte stellen Sie sicher, dass alle
          Mengenangaben in Ihrer Dokumentation größer als 0 sind. Aktualisieren
          Sie die Formeln, um eine korrekte Mengenermittlung zu gewährleisten.
        </IconAlert>
      );
    }
    if (result.some((value) => !value.isConnectionDataSufficient)) {
      alerts.push(
        <IconAlert color={AlertColor.Warning} key="connection-data">
          Es fehlen Informationen am Netzverknüpfungspunkt. Bitte stellen Sie
          sicher, dass die Art der Versorgung und das Messkonzept hinterlegt
          sind.
        </IconAlert>
      );
    }
  }

  if (alerts.length > 0) {
    return <React.Fragment>{alerts}</React.Fragment>;
  }

  return (
    <div className="GuaranteeOfOriginCheckResult">
      <div>
        <Icon className="check-result-icon" name={IconName.CheckCircle} />
      </div>
      <div>
        Ihre Daten liegen vollständig vor! Die Dokumentation für Ihre
        Herkunftsnachweise kann jetzt erstellt werden. Klicken Sie dazu auf den
        Button <i>Dokument erstellen</i>.
      </div>
    </div>
  );
}
