import PropTypes from "prop-types";
import React from "react";
import { Input } from "reactstrap";

import { ToggleSwitch } from "../../BuildingBlocks/Forms/ToggleSwitch/ToggleSwitch";
import type { FormFieldValue } from "../../DynamicForm/FormItems/FormField/FormField";
import type { SourceOrTarget } from "../useDeliveryConstraints";

import "./DeliveryConstraint.scss";

interface DeliveryConstraintProps {
  id: number;
  source: SourceOrTarget;
  target: SourceOrTarget;
  possible: boolean;
  priceCtPerKwh: number | null;
  onChange: (id: number, fieldName: string, value: FormFieldValue) => void;
}

export default function DeliveryConstraint({
  id,
  source,
  target,
  possible,
  priceCtPerKwh,
  onChange
}: DeliveryConstraintProps) {
  function handleChangePriceCtPerKwh(newValue: string) {
    if (newValue === "") {
      return null;
    }

    return newValue;
  }

  return (
    <tr className="DeliveryConstraint">
      <td>{source.name}</td>
      <td>{target.name}</td>
      <td align="center">
        <ToggleSwitch
          checked={possible}
          onChange={() => {
            onChange(id, "possible", !possible);
          }}
        />
      </td>
      <td align="center">
        <span className="def-number-input number-input">
          <label style={{ marginBottom: 0 }}>
            <Input
              className="form-control"
              min="0"
              step="0.01"
              type="number"
              value={priceCtPerKwh ?? ""}
              onChange={(e) => {
                onChange(
                  id,
                  "priceCtPerKwh",
                  handleChangePriceCtPerKwh(e.target.value)
                );
              }}
            />
            <span />
          </label>
        </span>
      </td>
    </tr>
  );
}

DeliveryConstraint.propTypes = {
  id: PropTypes.number.isRequired,
  source: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  }).isRequired,
  target: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  }).isRequired,
  possible: PropTypes.bool.isRequired,
  priceCtPerKwh: PropTypes.number,
  onChange: PropTypes.func.isRequired
};
