import React from "react";
import { numericFormatter } from "react-number-format";
import { useStartPageCO2Savings } from "../../../hooks/startPage/useStartPageCO2Savings";
import { IconName } from "../../BuildingBlocks/Icon/types";
import type { MiniTileItem } from "./MiniTile";
import { MiniTile } from "./MiniTile";

type CO2SavingsMiniTileProps = {
  projectId?: string;
  year: number;
};

function CO2SavingsMiniTile({ projectId, year }: CO2SavingsMiniTileProps) {
  const { data, isLoading } = useStartPageCO2Savings(year, projectId);

  function formatCO2Savings(sum: number, unit: string) {
    return numericFormatter(sum.toString(), {
      decimalSeparator: ",",
      thousandSeparator: ".",
      decimalScale: 1,
      suffix: " " + unit
    });
  }

  const co2Savings = data
    ? data.sum > 1000000
      ? formatCO2Savings(data.sum / 1000000, "t")
      : data.sum > 1000
        ? formatCO2Savings(data.sum / 1000, "kg")
        : formatCO2Savings(data.sum, "g")
    : "";

  const items: Array<MiniTileItem> = [
    {
      label: "Summe",
      value: co2Savings
    }
  ];

  return (
    <MiniTile
      iconName={IconName.Leaf}
      isLoading={isLoading}
      items={items}
      title={
        <span>
          CO<sub>2</sub>-Einsparung
        </span>
      }
    />
  );
}

export { CO2SavingsMiniTile };
