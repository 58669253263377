import saveAs from "file-saver";
import React, { useCallback, useState } from "react";

import api from "../../../../../api";
import urls from "../../../../../urls";
import { Button } from "../../../../Buttons/Button/Button";
import { openErrorAlertPopup } from "../../../../ErrorAlertPopup/openErrorAlertPopup";
import type { EnergyDataImportRequest } from "../../../EnergyDataUploadFlow/EnergyDataUpload";

const ZIP_FILENAME = "opti Node Dateien.zip";

interface EnergyDataUploadFilesToDownload {
  ids: string[];
}

interface Props {
  dataImportRequests: EnergyDataImportRequest[];
  setSelection: (selection: string[]) => void;
  setLoading: (loading: boolean) => void;
}

async function download(dataImportRequests: EnergyDataImportRequest[]) {
  const data: EnergyDataUploadFilesToDownload = {
    ids: dataImportRequests.map(({ id }) => id)
  };

  try {
    const response = await api.post(urls.api.energyDataUploadFiles(), data, {
      responseType: "blob",
      transformResponse: []
    });

    const blob = new Blob([response.data]);

    const filename =
      dataImportRequests.length === 1
        ? dataImportRequests[0].energyDataFile.name
        : ZIP_FILENAME;

    saveAs(blob, filename);
  } catch (e) {
    openErrorAlertPopup(e);
  }
}

function DownloadButton({
  dataImportRequests,
  setSelection,
  setLoading
}: Props) {
  const [isInProgress, setIsInProgress] = useState(false);

  const handleClick = useCallback(async () => {
    setIsInProgress(true);
    setLoading(true);

    await download(dataImportRequests);
    setSelection([]);
    setIsInProgress(false);
    setLoading(false);
  }, [dataImportRequests, setLoading, setSelection]);

  return (
    <Button
      color="brand"
      disabled={dataImportRequests.length === 0 || isInProgress}
      onClick={handleClick}
    >
      Download
    </Button>
  );
}

export { DownloadButton };
