import api from "../../../api";
import urls from "../../../urls";

export interface RefreshSuccess {
  access: string;
}

export const loginWithCode = async (code: string, ephemeralToken: string) => {
  const {
    data,
    data: { access }
  } = await api.publicRequest<RefreshSuccess>(
    "POST",
    urls.auth.loginWithCode(),
    {
      code,
      ephemeral_token: ephemeralToken
    }
  );

  api.setAccessToken(access);

  return data;
};
