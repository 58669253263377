import { useProject } from "./useProject";

const NO_MANAGER_VARIANT_ERROR = {
  response: {
    status: 404
  }
};

export function useManagerVariant(projectId?: string, disabled = false) {
  const { isLoading, error, project } = useProject(projectId, {
    enabled: !disabled && typeof projectId !== "undefined",
    refetchInterval: false,
    refetchOnWindowFocus: false
  });

  if (!project) {
    return { isLoading, error };
  }

  const variants = project.variants;
  const managerVariant = variants.find(
    (variant) => variant.isManagerVariant === true
  );
  const errorOrNoManagerVariant = managerVariant
    ? error
    : NO_MANAGER_VARIANT_ERROR;

  return {
    isLoading,
    error: errorOrNoManagerVariant,
    variant: managerVariant,
    variantId: managerVariant ? managerVariant.id : null
  };
}
