import { Stack } from "@mantine/core";
import React from "react";
import { generatePath, useParams } from "react-router-dom";
import { ROUTES } from "../../../../../../routes";
import type { MeteringConceptTodo } from "../../../../../../utils/backend-types";
import { AnchorLink } from "../../../../../AnchorLink/AnchorLink";
import { Icon } from "../../../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../../../BuildingBlocks/Icon/types";
import "./MeteringConceptDocumentLinks.scss";

interface MeteringConceptDocumentLinksProps {
  todos?: Array<MeteringConceptTodo>;
}

function MeteringConceptDocumentLinks({
  todos
}: MeteringConceptDocumentLinksProps) {
  const { projectId } = useParams<{ projectId: string }>();
  if (!todos || todos.length === 0) {
    return null;
  }

  const linkWithProjectId = generatePath(ROUTES.todos, {
    projectId: projectId?.toString() ?? null
  });

  return (
    <Stack className="MeteringConceptDocumentLinks" mt="sm">
      {todos.map((todo) => (
        <div className="mcd-link" key={todo.id}>
          <Icon name={IconName.FileText} />
          <AnchorLink href={linkWithProjectId + "/" + todo.id}>
            Zu den Unterlagen
          </AnchorLink>
        </div>
      ))}
    </Stack>
  );
}

export { MeteringConceptDocumentLinks };
