import React from "react";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import { buttonColors } from "../../../Buttons/Button/Button";
import { IconButton } from "../../../Buttons/IconButton/IconButton";
import "./SourceTargetButton.scss";

interface SourceTargetButtonProps {
  isSource?: boolean;
  onSave: () => void;
}

function SourceTargetButton({ isSource, onSave }: SourceTargetButtonProps) {
  return (
    <IconButton
      className="SourceTargetButton"
      color={buttonColors.info}
      iconName={isSource ? IconName.Minus : IconName.Plus}
      onClick={() => onSave()}
    >
      {isSource ? "Quelle" : "Ziel"} ändern
    </IconButton>
  );
}

export { SourceTargetButton };
