import type { QueryClient } from "@tanstack/react-query";

import api from "../../../../../../api";
import type { PostOptions } from "../../../../../../utils/backend-types";
import { ObjectName } from "../../../../../../utils/enums";
import { getObjectDetailUrl } from "../../../../../../utils/getObjectDetailUrl";
import { getObjectListUrl } from "../../../../../../utils/getObjectListUrl";
import type { FormValues } from "../../../../../CustomForm/useCustomForm";

export async function loadGeneralDataFields(
  queryClient: QueryClient,
  objectName: ObjectName,
  componentId: number,
  siteOrVariantId: number,
  isPremium = false
) {
  const optionsUrl =
    objectName === ObjectName.Meter
      ? getObjectListUrl(objectName, siteOrVariantId, componentId, isPremium)
      : getObjectListUrl(objectName, siteOrVariantId);
  const detailUrl = getObjectDetailUrl(objectName, componentId, isPremium);

  const data = await queryClient.fetchQuery({
    queryKey: [`generalDataFields-${objectName}-${componentId}`],
    queryFn: () => getFieldsOptionsAndData(optionsUrl, detailUrl)
  });

  return data;
}

export async function getFieldsOptionsAndData(
  optionsUrl: string,
  detailUrl: string
) {
  const generalDataFieldsOptionsResponse =
    await api.options<PostOptions>(optionsUrl);
  const generalDataFieldsGetResponse = await api.get<FormValues>(detailUrl);

  return {
    option: generalDataFieldsOptionsResponse,
    detail: generalDataFieldsGetResponse
  };
}
