import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import api from "../api";
import urls from "../urls";
import type { MeteringOrMarketLocation } from "../utils/backend-types";

export function useMeteringOrMarketLocations(
  siteId?: number,
  options?: Partial<UseQueryOptions<Array<MeteringOrMarketLocation>>>
) {
  const { data, isLoading, error } = useQuery({
    queryKey: ["metering-or-market-locations", { siteId }],
    queryFn: () => fetchMeteringOrMarketLocations(siteId),
    ...options
  });

  async function fetchMeteringOrMarketLocations(siteId?: number) {
    if (!siteId) {
      return [];
    }
    const response = await api.get<Array<MeteringOrMarketLocation>>(
      urls.api.meteringOrMarketLocations(siteId)
    );
    return response.data;
  }

  return {
    meteringOrMarketLocations: data,
    isLoading,
    error
  };
}
