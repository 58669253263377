import { DateTime } from "luxon";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import type { FictionalEnergyValueResponse } from "../EnergyDataUploadPage/FictionalEnergyValueManager/FictionalEnergyValueManager";

/** Creates a new FictionalEnergyValueResponse object with null values and a random Id for submission to Backend (without id). */
export function createNewLocalFictionalEnergyValue() {
  const now = DateTime.now();
  const backendCreationDateTime = luxonDateTimeToBackendDateOrDateTime(now);
  const newFictionalEnergyValue: FictionalEnergyValueResponse = {
    id: `local-${now.toISO()}`,
    created: backendCreationDateTime,
    generator: null,
    generatorDisplayName: null,
    firstDate: null,
    lastDate: null,
    technischeNichtverfuegbarkeitKwh: null,
    redispatchAbregelungKwh: null,
    sonstigeAbschaltungOderDrosselungKwh: null
  };

  return newFictionalEnergyValue;
}
