import { colorsTuple, createTheme } from "@mantine/core";
import { THEME_VARS } from "../utils/constants";

const mantineTheme = createTheme({
  colors: {
    brand: colorsTuple(THEME_VARS.brandColor),
    secondary: colorsTuple(THEME_VARS.primaryColor),
    danger: colorsTuple(THEME_VARS.dangerColor),
    customGrey: colorsTuple(THEME_VARS.customGrey)
  },
  components: {
    ActionIcon: {
      classNames: {
        root: "mantine-focus-never"
      }
    },
    Alert: {
      styles: {
        message: {
          fontSize: "var(--mantine-font-size-md)"
        }
      }
    },
    Button: {
      defaultProps: {
        size: "md"
      }
    },
    Popover: {
      defaultProps: {
        shadow: "tooltip"
      }
    },
    RadioGroup: {
      defaultProps: {
        size: "md"
      }
    },
    Table: {
      styles: {
        tr: { fontSize: "var(--mantine-font-size-md)" }
      }
    },
    Tabs: {
      defaultProps: {
        color: THEME_VARS.primaryColor
      }
    },
    Tooltip: {
      defaultProps: {
        color: "white"
      },
      styles: {
        tooltip: {
          boxShadow: "var(--mantine-shadow-tooltip)"
        }
      }
    },
    NumberInput: {
      defaultProps: {
        allowedDecimalSeparators: [".", ","],
        decimalSeparator: ",",
        hideControls: true,
        thousandSeparator: ".",
        withKeyboardEvents: false
      }
    }
  },
  fontFamily: "Montserrat, sans-serif",
  primaryColor: "brand",
  shadows: {
    md: "1px 1px 3px rgba(0, 0, 0, .25)",
    xl: "5px 5px 3px rgba(0, 0, 0, .25)",
    // should be kept in sync with old Tooltip.scss until that file is deleted
    // rgba(98, 95, 107, 0.1) is equivalent to rgba(color.scale(#c5c2cc, $lightness: -50%), 0.1)
    tooltip: "0 1px 15px 1px rgba(98, 95, 107, 0.1)"
  }
});

export { mantineTheme };
