import { useContext } from "react";

import { useCurrentUserQuery } from "../../hooks/useCurrentUserQuery";
import { ShowStaffViewContext } from "../App/ShowStaffViewContext";

function useShouldShowStaffView() {
  const showStaffView = useContext(ShowStaffViewContext);
  const { currentUser } = useCurrentUserQuery();

  return !!showStaffView && !!currentUser?.is_staff;
}

export { useShouldShowStaffView };
