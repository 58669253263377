import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import api from "../api";
import urls from "../urls";
import type { Direktvermarkter } from "../utils/backend-types";

export function useDirektvermarkters(
  options?: Partial<UseQueryOptions<Array<Direktvermarkter> | undefined>>
) {
  const { data, isLoading, error } = useQuery({
    queryKey: ["direktvermarkters"],
    queryFn: () => fetchDirektvermarkters(),
    ...options
  });

  async function fetchDirektvermarkters() {
    const response = await api.get<Array<Direktvermarkter>>(
      urls.api.direktvermarkter()
    );
    return response.data;
  }

  return {
    data,
    isLoading,
    error
  };
}
