import { Select } from "@mantine/core";
import { type MRT_ColumnDef } from "mantine-react-table";
import React from "react";
import { type Todo, TodoStatus } from "../../../utils/backend-types";
import { TodosListColumnKeys } from "../TodoTable";

const statusLabelMapping = {
  [TodoStatus.Open]: "Offen",
  [TodoStatus.Done]: "Erledigt",
  [TodoStatus.NotRelevant]: "Nicht relevant"
};

const statusFilterData = Object.keys(statusLabelMapping).map((key) => ({
  value: key,
  label: statusLabelMapping[key]
}));

export function getStatusColumn(
  handleChangeStatus: (statusValue: TodoStatus, todoId: number) => void
): MRT_ColumnDef<Todo> {
  return {
    accessorKey: TodosListColumnKeys.Status,
    header: "Status",
    Header: () => (
      <>
        Status{" "}
        {/* add when Bora and Paulina have written the text: <IconHelpText helpText="" /> */}
      </>
    ),
    enableGrouping: false,
    Cell: ({ row }) => (
      <Select
        allowDeselect={false}
        data={Object.keys(statusLabelMapping).map((key) => ({
          value: key,
          label: statusLabelMapping[key]
        }))}
        value={row.original.status}
        onChange={(value) =>
          handleChangeStatus(value as TodoStatus, row.original.id)
        }
      />
    ),
    filterVariant: "select",
    mantineFilterSelectProps: {
      data: statusFilterData,
      searchable: false,
      dropdownOpened: true
    }
  };
}
