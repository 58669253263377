import React from "react";
import { Col, Row } from "reactstrap";
import urls from "../../urls";
import { getDataUrlsForSelectedVariantIds } from "../../utils/getDataUrlsForSelectedVariantIds";
import RegulatoryDuties from "../RegulatoryDuties/RegulatoryDuties";

import "./RegulatoryDutiesPage.scss";

interface RegulatoryDutiesPageProps {
  displayNames: Record<number, string>;
  selectedVariantIds: Array<number>;
}

function RegulatoryDutiesPage({
  displayNames,
  selectedVariantIds
}: RegulatoryDutiesPageProps) {
  const selectedDataUrls = getDataUrlsForSelectedVariantIds(
    selectedVariantIds,
    urls.api.regulatoryDutiesListByVariant
  );

  return (
    <div className="RegulatoryDutiesPage">
      <Row>
        <Col>
          <RegulatoryDuties
            columnHeaders={displayNames}
            dataUrls={selectedDataUrls}
            selectedVariantIds={selectedVariantIds}
          />
        </Col>
      </Row>
    </div>
  );
}

export { RegulatoryDutiesPage };
