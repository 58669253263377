import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useStartPageCompanies } from "../../../hooks/startPage/useStartPageCompanies";
import { useStartPageRegulatoryTodos } from "../../../hooks/startPage/useStartPageRegulatoryTodos";
import { useSessionStorage } from "../../../hooks/useSessionStorage";
import { Badge } from "../../BuildingBlocks/Badge/Badge";
import { IconName } from "../../BuildingBlocks/Icon/types";
import type { StartPageRegulatoryTodo } from "../StartPage.types";
import { StartPageRegulatoryTodoTag } from "../StartPage.types";
import type { TableLightColumn } from "./TableLight/TableLight";
import { TableLight } from "./TableLight/TableLight";
import { Tile } from "./Tile";

interface RegulatoryTodosTileProps {
  projectId?: string;
}

function RegulatoryTodosTile({ projectId }: RegulatoryTodosTileProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [storedRegulatoryCompanyId, setStoredRegulatoryCompanyId] =
    useSessionStorage<number | undefined>(
      "startPageRegulatoryCompanyId",
      undefined
    );

  const { companies } = useStartPageCompanies(projectId);
  const {
    regulatoryTodos,
    error: regulatoryTodosError,
    isLoading: regulatoryTodosLoading
  } = useStartPageRegulatoryTodos(
    currentPage,
    pageSize,
    projectId,
    storedRegulatoryCompanyId
  );

  const columns: Array<TableLightColumn<StartPageRegulatoryTodo>> = [
    { accessor: "id", hidden: true },
    { accessor: "projectId", hidden: true },
    { accessor: "personName" },
    {
      accessor: "name",
      render: (todoName, regulatoryTodo) => (
        <Link
          to={`../projekte/${regulatoryTodo.projectId}/aufgaben/${regulatoryTodo.id}`}
        >
          {todoName}
        </Link>
      )
    },
    {
      accessor: "tag",
      render: (_, regulatoryTodo) => (
        <RegulatoryTodoBadge regulatoryTodo={regulatoryTodo} />
      )
    }
  ];

  useEffect(() => {
    setCurrentPage(1);
  }, [projectId, storedRegulatoryCompanyId]);

  function handleCompanyChange(newSiteId: number | undefined) {
    setCurrentPage(1);
    setStoredRegulatoryCompanyId(newSiteId);
  }

  function handleSwitchPage(newCurrentPage: number, newPageSize: number) {
    setCurrentPage(newCurrentPage);
    setPageSize(newPageSize);
  }

  const allCompaniesChoice = { value: undefined, label: "Alle Unternehmen" };
  const companiesOptions = companies
    ? [
        allCompaniesChoice,
        ...companies.map((company) => ({
          label: company.name,
          value: company.id
        }))
      ]
    : [];

  return (
    <Tile
      error={regulatoryTodosError}
      filterOptions={companiesOptions}
      filterValue={companiesOptions.find(
        (site) => site.value === storedRegulatoryCompanyId
      )}
      iconName={IconName.List}
      isLoading={regulatoryTodosLoading}
      title="Anstehende Aufgaben"
      onFilterChange={(newFilter) => handleCompanyChange(newFilter.value)}
    >
      {regulatoryTodos && (
        <TableLight<StartPageRegulatoryTodo>
          columns={columns}
          count={regulatoryTodos.count}
          currentPage={currentPage}
          data={regulatoryTodos.results}
          NoDataComponent={NoDataComponent}
          pageSize={pageSize}
          onCurrentPageChange={(newCurrentPage) =>
            handleSwitchPage(newCurrentPage, pageSize)
          }
          onPageSizeChange={(newPageSize) => handleSwitchPage(1, newPageSize)}
        />
      )}
    </Tile>
  );
}

interface RegulatoryTodoTagProps {
  regulatoryTodo: StartPageRegulatoryTodo;
}

function RegulatoryTodoBadge({ regulatoryTodo }: RegulatoryTodoTagProps) {
  if (regulatoryTodo.tag === StartPageRegulatoryTodoTag.DUE) {
    return <Badge color="danger">Überfällig</Badge>;
  } else if (regulatoryTodo.tag === StartPageRegulatoryTodoTag.NEW) {
    return <Badge color="info">Neu</Badge>;
  } else if (regulatoryTodo.dueDate) {
    return <Badge color="info">Fällig am {regulatoryTodo.dueDate}</Badge>;
  } else {
    return null;
  }
}

function NoDataComponent() {
  return (
    <tr>
      <td className="NoDataComponent">Super, alles erledigt!</td>
    </tr>
  );
}

export { RegulatoryTodosTile, RegulatoryTodosTileProps };
