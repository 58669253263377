import PropTypes from "prop-types";
import React from "react";

import { Button } from "../../../../../Buttons/Button/Button";
import { SpinButton } from "../../../../../Buttons/SpinButton/SpinButton";

import "./EditFormControls.scss";

interface EditFormControlsProps {
  submitting: boolean;
  onClickSubmit: () => void;
  onClickCancel: () => void;
}

function EditFormControls({
  submitting,
  onClickSubmit,
  onClickCancel
}: EditFormControlsProps) {
  return (
    <div className="controls">
      <Button className="button-spacing" onClick={onClickCancel}>
        Abbrechen
      </Button>
      <SpinButton
        className="submit-button"
        color="primary"
        disabled={submitting}
        spin={submitting}
        onClick={onClickSubmit}
      >
        Speichern
      </SpinButton>
    </div>
  );
}

EditFormControls.propTypes = {
  submitting: PropTypes.bool.isRequired,
  onClickSubmit: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired
};

export { EditFormControls };
