import React from "react";
import { useStartPageInvoices } from "../../../hooks/startPage/useStartPageInvoices";
import { IconName } from "../../BuildingBlocks/Icon/types";
import type { MiniTileItem } from "./MiniTile";
import { MiniTile } from "./MiniTile";

type InvoicesMiniTileProps = {
  projectId?: string;
  year: number;
};

function InvoicesMiniTile({ projectId, year }: InvoicesMiniTileProps) {
  const { data, isLoading } = useStartPageInvoices(year, projectId);

  const items: Array<MiniTileItem> = [
    {
      label: "Erstellt",
      value: data?.created
    },
    {
      label: "Versendet",
      value: data?.sent
    }
  ];

  return (
    <MiniTile
      iconName={IconName.Copy}
      isLoading={isLoading}
      items={items}
      title="Rechnungen"
    />
  );
}

export { InvoicesMiniTile };
