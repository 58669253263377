import React, { useCallback } from "react";

import { DataImportRequestType } from "../../../../../utils/enums";
import type { EnergyDataImportRequest } from "../../../EnergyDataUploadFlow/EnergyDataUpload";
import {
  EnergyDataFileImportRequestModalType,
  EnergyDataFileImportRequestStatus
} from "../../../EnergyDataUploadFlow/EnergyDataUpload";

import "./NameCell.scss";

const getModalType = (
  typeOfData: DataImportRequestType,
  errorMessage?: string | null
) => {
  const errorMessageModalTypeMapping = [
    {
      errorMessage: "Die Herkunft der Datei konnte nicht identifiziert werden",
      typeOfData: DataImportRequestType.METERING,
      modalType: EnergyDataFileImportRequestModalType.Origin
    },
    {
      errorMessage: "Dateiformat wurde nicht eindeutig erkannt",
      modalType: EnergyDataFileImportRequestModalType.ParameterSet
    },
    {
      errorMessage: "Bezeichner in der Datei wurde nicht identifiziert",
      typeOfData: DataImportRequestType.METERING,
      modalType: EnergyDataFileImportRequestModalType.Meter
    }
  ];

  for (const modalDescription of errorMessageModalTypeMapping) {
    if (
      !!errorMessage &&
      errorMessage?.indexOf(modalDescription.errorMessage) !== -1 &&
      (!modalDescription.typeOfData ||
        modalDescription.typeOfData === typeOfData)
    ) {
      return modalDescription.modalType;
    }
  }

  return EnergyDataFileImportRequestModalType.Details;
};

interface NameCellProps {
  file: EnergyDataImportRequest;
  setModal: (
    type: EnergyDataFileImportRequestModalType,
    isOpen: boolean,
    selectedImportRequest?: EnergyDataImportRequest
  ) => void;
}

function NameCell({ file, setModal }: NameCellProps) {
  const toggleModal = useCallback(
    (typeOfData: DataImportRequestType, errorMessage?: string | null) =>
      setModal(getModalType(typeOfData, errorMessage), true, file),
    [file, setModal]
  );

  return (
    <span
      className={`name-cell ${
        file.status === EnergyDataFileImportRequestStatus.InProgress
          ? "disallow-modal"
          : ""
      }`}
      onClick={() => toggleModal(file.typeOfData, file?.errorMessage)}
    >
      {file?.energyDataFile?.name}
    </span>
  );
}

export { NameCell };
