import React from "react";

interface MeterChecksProps {
  label: string;
  problems: Array<string>;
}

function MeterChecks({ label, problems }: MeterChecksProps) {
  if (problems.length === 0) {
    return null;
  }

  return (
    <>
      <h6>{label}</h6>
      <ul>
        {problems.map((problem) => (
          <li key={problem}>{problem}</li>
        ))}
      </ul>
    </>
  );
}

export { MeterChecks };
