import type {
  ProjectMinimal,
  ProjectMinimalWithManagerVariant
} from "../../../utils/backend-types";

export function filterManagerProjects(projects: Array<ProjectMinimal>) {
  return projects.reduce<Array<ProjectMinimalWithManagerVariant>>(
    (projects, project) => {
      const managerVariant = project.variants.find(
        (variant) => variant.isManagerVariant === true
      );

      if (managerVariant) {
        projects.push({ ...project, managerVariant: managerVariant });
      }

      return projects;
    },
    []
  );
}
