import React, { useEffect, useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useDirektvermarkters } from "../../../hooks/useDirektvermarkters";
import { useOperators } from "../../../hooks/useOperators";
import { OperatorFeatures } from "../../../urls";
import {
  Modal,
  ModalBody,
  ModalHeader
} from "../../BuildingBlocks/Layout/Modal/Modal";
import { LoadOrError } from "../../LoadOrError/LoadOrError";
import type { DirektvermarktungContract } from "../Direktvermarktung.types";
import { useDirektvermarktungContract } from "../hooks/useDirektvermarktungContract";
import { useDirektvermarktungContractMutations } from "../hooks/useDirektvermarktungContractMutations";
import { useDirektvermarktungFormulaEntgeltVariables } from "../hooks/useDirektvermarktungFormulaEntgeltVariables";
import { DirektvermarktungContractForm } from "./DirektvermarktungContractForm/DirektvermarktungContractForm";

interface DirektvermarktungContractModalProps {
  contractId?: string;
  isOpen?: boolean;
  onClose: () => void;
}

function DirektvermarktungContractModal({
  contractId,
  isOpen,
  onClose
}: DirektvermarktungContractModalProps) {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const [responseError, setResponseError] = useState<Error>();

  const editMode = !!contractId;

  const {
    data: direktvermarktungContract,
    isLoading: direktvermarktungContractLoading,
    error: direktvermarktungContractError
  } = useDirektvermarktungContract(contractId);
  const {
    direktvermarktungContractCreateMutation,
    direktvermarktungContractEditMutation
  } = useDirektvermarktungContractMutations();
  const isMutating =
    direktvermarktungContractCreateMutation.isPending ||
    direktvermarktungContractEditMutation.isPending;

  const {
    data: operators,
    isLoading: operatorsLoading,
    error: operatorsError
  } = useOperators({
    activeFeatures: [
      OperatorFeatures.Abschoepfung,
      OperatorFeatures.Paragraph6EEG,
      OperatorFeatures.Parkabrechnung
    ]
  });

  const {
    data: direktvermarkter,
    isLoading: direktvermarkterLoading,
    error: direktvermarkterError
  } = useDirektvermarkters();

  const {
    data: formulaEntgeltVariables,
    isLoading: formulaEntgeltVariablesLoading,
    error: formulaEntgeltVariablesError
  } = useDirektvermarktungFormulaEntgeltVariables();

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  const onSubmit: SubmitHandler<DirektvermarktungContract> = (data) => {
    const mutation = editMode
      ? direktvermarktungContractEditMutation
      : direktvermarktungContractCreateMutation;

    mutation.mutate(data, {
      onSuccess: () => {
        onClose();
      },
      onError: (error) => {
        setResponseError(error);
      }
    });
  };

  return (
    <Modal
      className="DirektvermarktungContractModal"
      isOpen={isModalOpen}
      size="xl"
    >
      <ModalHeader toggle={onClose}>
        Direktvermarktungsvertrag {editMode ? "bearbeiten" : "hinzufügen"}
      </ModalHeader>
      <ModalBody>
        <LoadOrError
          error={
            (editMode && direktvermarktungContractError) ||
            operatorsError ||
            direktvermarkterError ||
            formulaEntgeltVariablesError
          }
          loading={
            (editMode &&
              (direktvermarktungContractLoading || operatorsLoading)) ||
            operatorsLoading ||
            direktvermarkterLoading ||
            formulaEntgeltVariablesLoading
          }
          loadingMessage="Vertrag wird geladen ..."
        >
          <DirektvermarktungContractForm
            direktvermarkter={direktvermarkter ?? []}
            direktvermarktungContract={direktvermarktungContract || {}}
            formulaEntgeltVariables={formulaEntgeltVariables || []}
            isSubmitting={isMutating}
            operators={operators ?? []}
            submissionError={responseError}
            onCancel={onClose}
            onSubmit={onSubmit}
          />
        </LoadOrError>
      </ModalBody>
    </Modal>
  );
}

export { DirektvermarktungContractModal, DirektvermarktungContractModalProps };
