// eslint-disable-next-line @typescript-eslint/no-require-imports
require("expose-loader?exposes=ReactComponents!./components");

import * as Sentry from "@sentry/browser";
import { use as i18nextUse } from "i18next";
import { Settings } from "luxon";
import React from "react";
import { createRoot } from "react-dom/client";
import { initReactI18next } from "react-i18next";
import "../scss/index.scss";
import { BrowserRouter } from "react-router-dom";
import { App } from "./components/App/App";
import de from "./i18n/translations/de.json";

declare const SENTRY_DSN: string;

// Send uncaught exceptions to Sentry (https://docs.sentry.io/platforms/javascript/react/)
// The SENTRY_DSN constant is defined using Webpack's DefinePlugin
Sentry.init({ dsn: SENTRY_DSN });

// Warning when switching languages: https://github.com/i18next/react-i18next/issues/1042
i18nextUse(initReactI18next).init({
  resources: {
    de: {
      translation: de
    }
  },
  lng: "de",
  fallbackLng: ["de"],
  interpolation: {
    escapeValue: false
  }
});

Settings.defaultLocale = "de";

const METRONIC_BODY_CLASSES = [
  "m-page--fluid",
  "m--skin-",
  "m-content--skin-light2",
  "m-header--fixed",
  "m-header--fixed-mobile",
  "m-aside-left--enabled",
  "m-aside-left--skin-dark",
  "m-aside-left--fixed",
  "m-aside-left--offcanvas",
  "m-footer--push",
  "m-aside--offcanvas-default"
];

const METRONIC_ROOT_CLASSES = [
  "m-grid",
  "m-grid--hor",
  "m-grid--root",
  "m-page"
];

document.body.className = METRONIC_BODY_CLASSES.join(" ");
const container = document.getElementById("root");

if (container) {
  container.className = METRONIC_ROOT_CLASSES.join(" ");
  const root = createRoot(container);
  root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}
