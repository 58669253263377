import React from "react";
import type { Column } from "react-table";
import type { TypedCellInfo } from "../../../../CustomReactTable/CustomReactTable.types";
import type {
  MarketOrMeteringLocationOverview,
  LocationOverviewData
} from "../../LocationOverview.types";

function getMeteringDataAvailabilityColumn(
  header: string,
  id: keyof NonNullable<MarketOrMeteringLocationOverview["assessment"]>
) {
  return {
    Header: header,
    id: "currentValues",
    accessor: (row) => row.singleOverviews,
    Cell: (
      cellInfo: TypedCellInfo<
        Array<MarketOrMeteringLocationOverview>,
        LocationOverviewData
      >
    ) => (
      <div className="current-values">
        {cellInfo.value.map((overview) => (
          <div className="sub-row" key={overview.location.id}>
            Aktuelles Jahr:{" "}
            {getCurrentValueText(
              overview.assessment === null
                ? null
                : overview.assessment[id].currentYear
            )}
            <br />
            Aktueller Monat:{" "}
            {getCurrentValueText(
              overview.assessment === null
                ? null
                : overview.assessment[id].currentMonth
            )}
          </div>
        ))}
      </div>
    ),
    sortable: false,
    width: 205
  } satisfies Column<LocationOverviewData>;
}

function getCurrentValueText(value: number | null) {
  if (value === null) {
    return "-";
  }

  return `${value}%`;
}

export { getMeteringDataAvailabilityColumn };
