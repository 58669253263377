import React, { PureComponent } from "react";

import urls from "../../urls";
import { getAccountedFeedOutLabelFromMarketLocation } from "../../utils/getAccountedFeedOutLabelFromMarketLocation";
import { Portlet } from "../BuildingBlocks/Layout/Portlet";
import { Section } from "../BuildingBlocks/Layout/Section";
import { Button } from "../Buttons/Button/Button";
import { DetailedAnalysisModal } from "../DetailedAnalysis/DetailedAnalysis";
import loader from "../Loader";

import "./IndividualGridFeesView.scss";

class IndividualGridFeesView extends PureComponent {
  render() {
    const { analyses } = this.props;

    return (
      <Portlet className="IndividualGridFeesView">
        <Section>
          {analyses.length > 0 ? (
            <IndividualGridFeesTable analyses={analyses} />
          ) : (
            <div>Keine Analyse möglich.</div>
          )}
        </Section>
      </Portlet>
    );
  }
}

const IndividualGridFeesTable = ({ analyses }) => {
  return (
    <div className="table-responsive">
      <table className="table m-table m-table--head-bg-brand">
        <thead>
          <tr>
            <th>Verbraucher</th>
            <th>Netzverknüpfungspunkt</th>
            <th className="column-analyze">Analyse</th>
          </tr>
        </thead>
        <tbody>
          {analyses.map((analysis) => (
            <AnalysisRow
              connectionName={analysis.metadata.connection.name}
              key={analysis.url}
              name={getAccountedFeedOutLabelFromMarketLocation(
                analysis.metadata.location
              )}
              url={analysis.url}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

class AnalysisRow extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isDetailedAnalysisOpen: false
    };
  }

  handleClickOpen = () => {
    this.setState({
      isDetailedAnalysisOpen: true
    });
  };

  handleClickClose = () => {
    this.setState({
      isDetailedAnalysisOpen: false
    });
  };

  render() {
    const { name, connectionName, url } = this.props;

    const { isDetailedAnalysisOpen } = this.state;

    return (
      <tr>
        <td>{name}</td>
        <td>{connectionName}</td>
        <td className="column-analyze">
          <Button color="primary" onClick={this.handleClickOpen}>
            Starten
          </Button>
          {isDetailedAnalysisOpen && (
            <DetailedAnalysisModal
              loadingText="Einen Moment bitte, die Analyse kann einige Minuten dauern"
              url={url}
              onClose={this.handleClickClose}
            />
          )}
        </td>
      </tr>
    );
  }
}

class IndividualGridFeesViewWrapper extends PureComponent {
  render() {
    const { data } = this.props;

    return <IndividualGridFeesView analyses={data[0]} />;
  }
}

const IndividualGridFeesViewWrapperWithLoader = loader(
  IndividualGridFeesViewWrapper
);
const IndividualGridFeesViewWrapperWithLoaderWithData = (props) => (
  <IndividualGridFeesViewWrapperWithLoader
    dataUrls={[urls.api.individualGridFeesAnalyses(props.variantId)]}
  />
);

export { IndividualGridFeesViewWrapperWithLoaderWithData as IndividualGridFeesView };
