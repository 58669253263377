import type { MeterDataGaps } from "../../../../../../../../utils/backend-types";
import type { DataGapsTableObject } from "../DataGaps.types";
import { getTableObjectsFromDataGapEntry } from "./getTableObjectFromDataGapEntry";

export function getTableDataFromDataGaps(
  dataGaps: MeterDataGaps
): Array<DataGapsTableObject> {
  const tableData: Array<DataGapsTableObject> = [];
  Object.keys(dataGaps).forEach((measurand) => {
    if (measurand !== "expectedOrigin") {
      const dataObjects = getTableObjectsFromDataGapEntry(dataGaps[measurand]);
      if (dataObjects) {
        tableData.push(...dataObjects);
      }
    }
  });
  return tableData;
}
