import type { DrittlieferungPrice } from "../ParkabrechnungContext";
import type { DrittlieferungExportPayload } from "./ParkabrechnungDownloadButton.types";

export function mapDrittlieferungPriceToExportPayload(
  prices: DrittlieferungPrice[]
): DrittlieferungExportPayload {
  const payload: DrittlieferungExportPayload = [];

  for (const price of prices) {
    if (price.recipientIds) {
      for (const recipientId of price.recipientIds) {
        payload.push({
          siteId: price.siteId,
          supplierId: price.supplierId || 0,
          recipientId,
          priceCtPerKwh: price.priceCtPerKwh || 0
        });
      }
    }
  }

  return payload;
}
