import React from "react";
import ReactDOM from "react-dom";

import type { Component } from "../../../../utils/backend-types";
import type { ObjectName } from "../../../../utils/enums";
import { Alert, AlertColor } from "../../../Alert/Alert";
import { Button } from "../../../Buttons/Button/Button";

interface MaStRImportSuccessWidgetProps<T> {
  createdObjects: Array<T>;
  warnings: Array<string>;
  objectName: ObjectName;
  buttonContainer?: HTMLElement;
  lastStep?: boolean;
  onObjectCreated: (createdObject: T) => void;
  onDoneStep: (createdObjects: Array<T>) => void;
}

function MaStRImportSuccessWidget<T extends Component>({
  createdObjects,
  warnings,
  objectName,
  buttonContainer,
  lastStep,
  onObjectCreated,
  onDoneStep
}: MaStRImportSuccessWidgetProps<T>) {
  function handleDone() {
    createdObjects.forEach((createdObject) => {
      onObjectCreated(createdObject);
    });

    onDoneStep(createdObjects);
  }

  const closeButtonText = lastStep ? "Schließen" : "Weiter";

  return (
    <div className="MaStRImportSuccessWidget">
      {createdObjects.length > 0 ? (
        <p>Die folgenden {objectName} wurden erfolgreich importiert:</p>
      ) : (
        <p>Keine {objectName} wurden importiert.</p>
      )}
      <ul>
        {createdObjects.map((createdObject) => (
          <li key={createdObject.name}>{createdObject.name}</li>
        ))}
      </ul>
      {warnings &&
        warnings.map((warning) => (
          <Alert color={AlertColor.Info} key={warning}>
            {warning}
          </Alert>
        ))}
      {buttonContainer &&
        ReactDOM.createPortal(
          <Button className="close-button" color="primary" onClick={handleDone}>
            {closeButtonText}
          </Button>,
          buttonContainer
        )}
    </div>
  );
}

export { MaStRImportSuccessWidget, MaStRImportSuccessWidgetProps };
