import classNames from "classnames";
import React from "react";
import { IconHelpText } from "../../../IconHelpText/IconHelpText";
import { IconName } from "../../Icon/types";
import "./InputWrapper.scss";

interface InputWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  inputGroupText?: string;
  errorText?: string;
}

function InputWrapper({
  children,
  className,
  errorText,
  inputGroupText,
  ...otherProps
}: InputWrapperProps) {
  const showErrorIcon = !!errorText;
  const showInputGroupText = !!inputGroupText;

  return (
    <div
      className={classNames(
        "InputWrapper",
        { "input-group": showInputGroupText || showErrorIcon },
        className
      )}
      {...otherProps}
    >
      {children}
      {showErrorIcon ? (
        <div className="input-group-append">
          <span className="input-group-text input-group-error-icon">
            <IconHelpText
              className="error-icon"
              disableMarkdown
              helpText={errorText}
              icon={IconName.Warning}
            />
          </span>
        </div>
      ) : (
        showInputGroupText && (
          <div className="input-group-append">
            <span className="input-group-text">{inputGroupText}</span>
          </div>
        )
      )}
    </div>
  );
}

export { InputWrapper };
