import React, { useState } from "react";
import type { Todo } from "../../../utils/backend-types";
import { MultiConfirmationModal as DeleteTodosModal } from "../../BuildingBlocks/Layout/Modals/MultiConfirmationModal/MultiConfirmationModal";
import { Button } from "../../Buttons/Button/Button";

interface DeleteTodosButtonProps {
  todos: Array<Todo>;
  onDeleteTodo: (todo: Todo) => Promise<void>;
  onDeleteMultipleTodos: (todos: Array<Todo>) => Promise<void>;
}

function DeleteTodosButton({
  todos,
  onDeleteTodo,
  onDeleteMultipleTodos
}: DeleteTodosButtonProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  function handleClickDelete() {
    if (todos.length > 1) {
      return onDeleteMultipleTodos(todos);
    }

    return onDeleteTodo(todos[0]);
  }

  const todosToDelete = todos.map((todo) => todo.label);

  return (
    <React.Fragment>
      <Button
        color="danger"
        disabled={todos.length === 0}
        onClick={() => setIsModalOpen(true)}
      >
        {`Aufgabe${todosToDelete.length > 1 ? "n" : ""} löschen`}
      </Button>
      <DeleteTodosModal
        actionName="löschen"
        actionObjects={todosToDelete}
        confirmationText="Möchten Sie die folgenden Aufgaben löschen? Bitte beachten Sie, dass auch zugeordnete Dokumente, etc. damit gelöscht werden."
        isModalOpen={isModalOpen}
        objectName="Aufgaben"
        toggleModal={() => setIsModalOpen(false)}
        onAction={handleClickDelete}
      />
    </React.Fragment>
  );
}

export { DeleteTodosButton };
