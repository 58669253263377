import { useQuery } from "@tanstack/react-query";

import { apiWithoutCamelization } from "../api";
import urls from "../urls";
import type { Site } from "../utils/backend-types";

interface VariantSitesReturnValue {
  sites: Array<Site>;
  isLoading: boolean;
  error: unknown;
}

function useVariantSites(
  variantId: number | undefined,
  disabled = false
): VariantSitesReturnValue {
  const {
    isLoading: isSitesListLoading,
    error: sitesListError,
    data: sites
  } = useQuery({
    queryKey: ["sites-list", { variantId }],
    queryFn: () => fetchSitesList(variantId),
    enabled: !disabled && typeof variantId !== "undefined",
    refetchInterval: false,
    refetchOnWindowFocus: false
  });

  return {
    isLoading: isSitesListLoading,
    error: sitesListError,
    sites: sites || []
  };
}

export async function fetchSitesList(variantId: number | undefined) {
  if (typeof variantId === "undefined") {
    return undefined;
  }

  const response = await apiWithoutCamelization.get<Array<Site>>(
    urls.apiWithoutCamelization.sitesList(variantId)
  );
  return response.data;
}

export { useVariantSites };
