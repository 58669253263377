import { useQuery } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import urls from "../../../urls";
import type { Paragraph6EEGCreditNumberRangeResponse } from "../Paragraph6.types";

export function useParagraph6CreditNumberRanges(variantId: number) {
  const queryKey = ["paragraph6CreditNumberRanges", { variantId }];
  const { data, isLoading, error } = useQuery({
    queryKey,
    queryFn: () => fetchParagraph6CreditNumberRanges(variantId)
  });

  async function fetchParagraph6CreditNumberRanges(variantId: number) {
    const url =
      urls.apiWithoutCamelization.paragraph6CreditNumberRanges(variantId);
    const response =
      await apiWithoutCamelization.get<
        Array<Paragraph6EEGCreditNumberRangeResponse>
      >(url);

    return response.data;
  }

  return { creditNumberRanges: data, isLoading, error };
}
