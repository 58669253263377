import React from "react";

import { Icon } from "../BuildingBlocks/Icon/Icon";
import { IconName } from "../BuildingBlocks/Icon/types";
import type { CustomPasswordErrors } from "./PasswordResetConfirm";

interface PasswordRequirementsListProps {
  isChecked: boolean;
  validationErrors: CustomPasswordErrors;
}

function PasswordRequirementsList({
  isChecked,
  validationErrors
}: PasswordRequirementsListProps) {
  return (
    <div className="PasswordRulesList">
      <p>Das Passwort muss mindestens:</p>
      <ul>
        <li
          className="PasswordRulesListItem"
          style={{ listStyle: isChecked ? "none" : "circle" }}
        >
          {isChecked && (
            <span className="PasswordRulesCheckIcon">
              {validationErrors.isTooShort ? (
                <Icon className="FailedCheck" name={IconName.Times} />
              ) : (
                <Icon className="SuccessCheck" name={IconName.Check} />
              )}
            </span>
          )}
          12 Zeichen enthalten
        </li>
        <li
          className="PasswordRulesListItem"
          style={{ listStyle: isChecked ? "none" : "circle" }}
        >
          {isChecked && (
            <span className="PasswordRulesCheckIcon">
              {validationErrors.doesNotContainLowerAndUppercaseLetter ? (
                <Icon className="FailedCheck" name={IconName.Times} />
              ) : (
                <Icon className="SuccessCheck" name={IconName.Check} />
              )}
            </span>
          )}
          einen Klein- und Großbuchstaben enthalten
        </li>
        <li
          className="PasswordRulesListItem"
          style={{ listStyle: isChecked ? "none" : "circle" }}
        >
          {isChecked && (
            <span className="PasswordRulesCheckIcon">
              {validationErrors.doesNotContainNumberOrSpecialCharacter ? (
                <Icon className="FailedCheck" name={IconName.Times} />
              ) : (
                <Icon className="SuccessCheck" name={IconName.Check} />
              )}
            </span>
          )}
          eine Zahl oder ein Sonderzeichen enthalten (z. B. ! @ # ?)
        </li>
        <li
          className="PasswordRulesListItem"
          style={{ listStyle: isChecked ? "none" : "circle" }}
        >
          {isChecked && (
            <span className="PasswordRulesCheckIcon">
              {validationErrors.containsUserEmail ? (
                <Icon className="FailedCheck" name={IconName.Times} />
              ) : (
                <Icon className="SuccessCheck" name={IconName.Check} />
              )}
            </span>
          )}
          darf nicht Ihre E-Mail-Adresse enthalten
        </li>
      </ul>
    </div>
  );
}

export { PasswordRequirementsList };
