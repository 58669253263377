import React, { useCallback, useState } from "react";
import type { Filter, ReactTableFunction } from "react-table";
import { ButtonDropdown, ButtonGroup } from "reactstrap";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "../../../BuildingBlocks/Dropdown/Dropdown";
import { Button } from "../../../Buttons/Button/Button";
import { StatusSymbol, StatusSymbolColour } from "../../../Icons/StatusSymbol";
import "./ThreeWayFilter.scss";

const DEFAULT_SHOW_ALL_TEXT = "Alle anzeigen";
const FILTER_APPLIED_TEXT = "Filter ausgewählt";
const RESET_FILTER_TEXT = "Filter zurücksetzen";

interface ThreeWayFilterProps {
  filter: Filter;
  options: Array<string>;
  allTrueSelectedText: string;
  showAllText?: string;
  onChange: ReactTableFunction;
}

function ThreeWayFilter({
  filter,
  options,
  allTrueSelectedText,
  showAllText = DEFAULT_SHOW_ALL_TEXT,
  onChange
}: ThreeWayFilterProps) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () =>
    setIsDropdownOpen((oldIsDropdownOpen) => !oldIsDropdownOpen);

  const getDisplayText = useCallback(() => {
    if (!filter) {
      return showAllText;
    }

    const selections = Object.values(filter.value);
    const { numNull, numTrue } = selections.reduce<{
      numNull: number;
      numTrue: number;
    }>(
      (counts, selection) => {
        if (selection === null) {
          counts.numNull++;
        } else if (selection === true) {
          counts.numTrue++;
        }

        return counts;
      },
      {
        numNull: 0,
        numTrue: 0
      }
    );

    if (numNull === selections.length) {
      return showAllText;
    } else if (numTrue === selections.length && allTrueSelectedText) {
      return allTrueSelectedText;
    }

    return FILTER_APPLIED_TEXT;
  }, [filter, showAllText, allTrueSelectedText]);

  function handleClickAll() {
    const newSelections = getSelectionsWithValue(true);

    onChange(newSelections);
  }

  function handleClickNone() {
    const newSelections = getSelectionsWithValue(null);

    onChange(newSelections);
  }

  function handleClickLabel(label: string) {
    const newSelections = {
      ...getSelections()
    };

    if (newSelections[label] === null) {
      newSelections[label] = true;
    } else if (newSelections[label] === true) {
      newSelections[label] = false;
    } else if (newSelections[label] === false) {
      newSelections[label] = null;
    }

    onChange(newSelections);
  }

  function handleClickSelectionButton(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    label: string,
    value: boolean | null
  ) {
    e.stopPropagation();

    const newSelections = {
      ...getSelections()
    };

    newSelections[label] = value;

    onChange(newSelections);
  }

  const getSelectionsWithValue = useCallback(
    (value: boolean | null) => {
      return options.reduce<Record<string, boolean | null>>(
        (selections, label) => {
          selections[label] = value;
          return selections;
        },
        {}
      );
    },
    [options]
  );

  const getSelections = useCallback(() => {
    return filter ? filter.value : getSelectionsWithValue(null);
  }, [filter, getSelectionsWithValue]);

  const selections = getSelections();

  return (
    <ButtonDropdown
      className="ThreeWayFilter"
      isOpen={isDropdownOpen}
      toggle={toggleDropdown}
    >
      <DropdownToggle caret>
        <span className="filter-dropdown-text">{getDisplayText()}</span>
      </DropdownToggle>
      <DropdownMenu strategy="fixed">
        <div className="scrollable-menu-items">
          {allTrueSelectedText && (
            <DropdownItem
              className="special-option"
              toggle={false}
              onClick={handleClickAll}
            >
              {allTrueSelectedText}
            </DropdownItem>
          )}
          <DropdownItem
            className="special-option"
            toggle={false}
            onClick={handleClickNone}
          >
            {RESET_FILTER_TEXT}
          </DropdownItem>
          <DropdownItem divider />
          {options.map((label, id) => (
            <DropdownItem
              key={label + id}
              tag="div"
              toggle={false}
              onClick={() => handleClickLabel(label)}
            >
              <ButtonGroup>
                <Button
                  active={selections[label] === true}
                  onClick={(e) => handleClickSelectionButton(e, label, true)}
                >
                  <StatusSymbol colour={StatusSymbolColour.Green} />
                </Button>
                <Button
                  active={selections[label] === false}
                  onClick={(e) => handleClickSelectionButton(e, label, false)}
                >
                  <StatusSymbol colour={StatusSymbolColour.Red} />
                </Button>
                <Button
                  active={selections[label] === null}
                  onClick={(e) => handleClickSelectionButton(e, label, null)}
                >
                  Egal
                </Button>
              </ButtonGroup>
              {label}
            </DropdownItem>
          ))}
        </div>
      </DropdownMenu>
    </ButtonDropdown>
  );
}

export { ThreeWayFilter, ThreeWayFilterProps };
