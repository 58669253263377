import classNames from "classnames";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { RegulatoryDutyCategory } from "../../../utils/backend-types";
import { Portlet } from "../../BuildingBlocks/Layout/Portlet";
import { Section } from "../../BuildingBlocks/Layout/Section";
import { Button } from "../../Buttons/Button/Button";
import { mergeByAccessors } from "../../MeteringConceptComparison/merging";
import RegulatoryDutyItem from "./RegulatoryDutyItem/RegulatoryDutyItem";
import "./RegulatoryDutyList.scss";

export default class RegulatoryDutyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideNotApplicable: true
    };
  }

  shouldComponentUpdate(nextProps) {
    /**
     * See docstring on `VariantComparison`
     */
    return !(
      !_.isEqual(nextProps.columnHeaders, this.props.columnHeaders) &&
      _.isEqual(nextProps.duties, this.props.duties)
    );
  }

  render() {
    const { hideNotApplicable } = this.state;
    const { columnHeaders, duties, selectedVariantIds } = this.props;

    const firstDutyVariantId = selectedVariantIds[0];
    const categories = _.uniq(
      duties[firstDutyVariantId].map((duty) => duty.category)
    );
    const categoryOrder = [
      RegulatoryDutyCategory.OwnConsumption,
      RegulatoryDutyCategory.DirectDelivery,
      RegulatoryDutyCategory.PlantOperation
    ];
    const sortedCategories = _.uniq(categoryOrder.concat(categories));

    let mergedDuties = mergeByAccessors(duties, ["name"]);
    const groupedByCategory = _.groupBy(mergedDuties.merged, (entry) => {
      const firstDutyObject = Object.values(entry).filter(
        (obj) => !_.isNull(obj)
      )[0];
      return firstDutyObject.category;
    });

    const minWidth = `${320 * Object.values(columnHeaders).length}px`;

    return (
      <Portlet style={{ minWidth }}>
        <Section>
          <Row>
            <Col className="m--align-center" style={{ maxWidth: "100vw" }}>
              <Button
                active={hideNotApplicable}
                color="secondary"
                primary="true"
                onClick={this.hideNotApplicableDuties}
              >
                Relevante Pflichten
              </Button>
              <Button
                active={!hideNotApplicable}
                color="secondary ml-2"
                onClick={this.showAllRegulatoryDuties}
              >
                Alle Pflichten
              </Button>
            </Col>
          </Row>
        </Section>
        {sortedCategories.map((category) => (
          <RegulatoryDutyCategoryTable
            columnHeaders={columnHeaders}
            duties={groupedByCategory[category]}
            hideNotApplicable={hideNotApplicable}
            key={category}
            selectedVariantIds={selectedVariantIds}
            title={category}
          />
        ))}
      </Portlet>
    );
  }

  showAllRegulatoryDuties = () => {
    this.setState({
      hideNotApplicable: false
    });
  };

  hideNotApplicableDuties = () => {
    this.setState({
      hideNotApplicable: true
    });
  };
}

RegulatoryDutyList.propTypes = {
  duties: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.object)).isRequired
};

function orderMergedDutiesBy(duties, orderingKeys, orders) {
  /**
   * Sorts an array of regulatory duties which have been merged across different metering concepts.
   * Example:
   *  duties = [
   *    {
   *      "variant-1": {...}
   *      "variant-2": {...}
   *    },
   *    ...
   *  ]
   *  The method call orderByDuties(duties, ["recurring", "applicable"], ["asc", "desc"]) will call =>
   *  _.orderBy(
   *    duties,
   *    ["variant-1.recurring","variant-2.recurring","variant-1.applicable","variant-2.applicable"],
   *    ["asc", "asc", "desc", "desc"]
   *  )
   *  @param {Array} [orderingKeys] Defines the properties according to which they will be sorted
   *  @param {Array} [orders] The sort order (ascending, descending) for each property
   */
  const keys = Object.keys(duties);
  const expandedOrderingKeys = orderingKeys.reduce((arr, value) => {
    arr = arr.concat(keys.map((key) => `${key}.${value}`));
    return arr;
  }, []);
  const expandedOrders = orders.reduce((arr, order) => {
    arr = arr.concat(keys.map(() => order));
    return arr;
  }, []);
  return _.orderBy(duties, expandedOrderingKeys, expandedOrders);
}

const RegulatoryDutyCategoryTable = ({
  title,
  duties,
  hideNotApplicable,
  columnHeaders,
  selectedVariantIds
}) => {
  if (!duties) {
    return null;
  }
  const orderedDuties = orderMergedDutiesBy(
    duties,
    ["applicable", "recurring", "title"],
    ["desc", "asc", "asc"]
  );
  const firstVariantId = selectedVariantIds[0];
  return (
    <Section>
      <Row>
        <Col>
          <Row className="section-title-row m--font-light m--align-center">
            <Col
              className="m--bg-brand col-header"
              md={2}
              style={{ maxWidth: "235px" }}
            >
              {title}
            </Col>
            {selectedVariantIds.map((variantId, index) => {
              const colorClassName =
                index % 2 === 0 ? "grey-column" : "m--bg-brand";
              return (
                <Col
                  className={classNames(
                    "col-header",
                    "justify-content-center",
                    colorClassName
                  )}
                  key={variantId}
                >
                  <span>{columnHeaders[variantId]}</span>
                </Col>
              );
            })}
          </Row>
          {orderedDuties.map((duty) => (
            <RegulatoryDutyRow
              dutiesByVariant={duty}
              hideNotApplicable={hideNotApplicable}
              key={duty[firstVariantId].id}
              variantIds={selectedVariantIds}
            />
          ))}
        </Col>
      </Row>
    </Section>
  );
};

const RegulatoryDutyRow = ({
  dutiesByVariant,
  variantIds,
  hideNotApplicable
}) => {
  return (
    <Row>
      <Col md={2} style={{ maxWidth: "235px" }} />
      {variantIds.map((variantId) => {
        const duty = dutiesByVariant[variantId];
        const showItem = duty.applicable || !hideNotApplicable;
        return (
          <Col key={variantId}>
            {showItem && (
              <RegulatoryDutyItem
                applicable={duty.applicable}
                collapsible
                duty={duty}
              />
            )}
          </Col>
        );
      })}
    </Row>
  );
};
