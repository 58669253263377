import _ from "lodash";

/**
 * Camelize a string, cutting the string by multiple separators like
 * hyphens, underscores and spaces.
 *
 * @param text String Text to camelize
 * @return string Camelized text
 */
function camelize(text: string | number) {
  if (!_.isString(text)) {
    return text.toString();
  }

  return text.replace(
    /^([A-Z])|[\s-_]+([A-Za-z])/g,
    function (match, startStringMatch, restStringMatch) {
      if (restStringMatch) {
        return restStringMatch.toUpperCase();
      }
      return startStringMatch.toLowerCase();
    }
  );
}

/**
 * Camelizes keys of an object in a recursive fashion.
 *
 * @param object Object with keys in camelCase
 */
function camelizeKeys(object) {
  if (!_.isObject(object) && !_.isArray(object)) {
    return object;
  }

  if (_.isArray(object)) {
    return _.map(object, (value) => camelizeKeys(value));
  }

  const camelizedValues = _.mapValues(object, (value) => camelizeKeys(value));

  return _.mapKeys(camelizedValues, (_, key) => camelize(key));
}

/**
 * Decamelizes a string to snake_case.
 *
 * @param text String in camelCase
 */
function decamelize(text: string | number) {
  if (_.isNumber(text)) {
    return text.toString();
  }

  const separator = "_";
  return text
    .replace(/([a-z\d])([A-Z])/g, "$1" + separator + "$2")
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, "$1" + separator + "$2")
    .toLowerCase();
}

export { camelize, decamelize, camelizeKeys };
