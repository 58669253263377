import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "reactstrap";

interface DescriptionHeader {
  description: string;
}

function DescriptionHeader({ description }) {
  return (
    <Row style={{ marginTop: "15px" }}>
      <Col>
        <span style={{ fontSize: "1.3em" }}>
          <i>{description}</i>
        </span>
      </Col>
    </Row>
  );
}

DescriptionHeader.propTypes = {
  description: PropTypes.string.isRequired
};

export { DescriptionHeader };
