import classNames from "classnames";
import React from "react";
// eslint-disable-next-line no-restricted-imports
import { Button as ReactstrapButton } from "reactstrap";
import type { ButtonProps as ReactstrapButtonProps } from "reactstrap";

import "./Button.scss";

export const buttonColors = {
  brand: "brand",
  danger: "danger",
  info: "info",
  primary: "primary",
  secondary: "secondary",
  warning: "warning",
  link: "link",
  dark: "dark"
} as const;

export type ButtonColor = keyof typeof buttonColors;

type ButtonProps = Omit<ReactstrapButtonProps, "color"> & {
  color?: ButtonColor;
  noBorder?: boolean;
  noBackground?: boolean;
};

function Button(
  { className, noBackground, noBorder, ...props }: ButtonProps,
  ref: React.Ref<HTMLButtonElement>
) {
  const showOutline = noBackground || props.outline;

  return (
    <ReactstrapButton
      className={classNames(className, {
        ButtonFilledDisabled:
          props.disabled && !props.outline && props.color !== buttonColors.link,
        ButtonOutlineDisabled: props.disabled && props.outline,
        ButtonLinkDisabled: props.disabled && props.color === buttonColors.link,
        ButtonNoBorder: noBorder,
        ButtonNoBackground: noBackground
      })}
      {...props}
      innerRef={ref}
      outline={showOutline}
    />
  );
}

// these forwarded refs are needed by Mantine Popover
const ForwardedRefButton = React.forwardRef(Button);

export { ForwardedRefButton as Button, ButtonProps };
