import type { Column } from "react-table";
import type { InlineEditTableColumnData } from "../../../../../../types/inlineEditTable.types";
import type { TypedCellInfo } from "../../../../../CustomReactTable/CustomReactTable.types";
import type { FictionalEnergyValueResponse } from "../../FictionalEnergyValueManager/FictionalEnergyValueManager";

export function getSumColumn({
  rowStates
}: Pick<
  InlineEditTableColumnData<FictionalEnergyValueResponse>,
  "rowStates"
>): Column<FictionalEnergyValueResponse> {
  return {
    Header: "Summe (kWh)",
    accessor: (row) => {
      const calculatedSum =
        (row.technischeNichtverfuegbarkeitKwh || 0) +
        (row.redispatchAbregelungKwh || 0) +
        (row.sonstigeAbschaltungOderDrosselungKwh || 0);

      return Math.round(calculatedSum * 100) / 100;
    },
    id: "sumKwh",
    sortable: false,
    Cell: (cellInfo: TypedCellInfo<number, FictionalEnergyValueResponse>) => {
      const showFormField =
        rowStates[cellInfo.original.id] === "edit" ||
        rowStates[cellInfo.original.id] === "loading";

      if (showFormField) {
        // TODO: dynamic values are not easily obtainable, since watch() somehow doesn't update
        // and also when trying to couple watch() with this method here, focus is always pulled after typing one char
        return "...";
      }

      return cellInfo.value || "-";
    }
  };
}
