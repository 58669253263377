import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import api from "../api";
import type { Acquisition } from "../components/EnergyData/EnergyDataView/EnergyDataView";
import urls from "../urls";

type UseEnergyDataAcquisitionsOptions = {
  enabled?: boolean;
};
function useEnergyDataAcquisitions(
  variantId: number,
  filterString?: string,
  onError?: (error: unknown) => void,
  options?: UseEnergyDataAcquisitionsOptions
) {
  const { data, error, isLoading, isError } = useQuery({
    queryKey: ["energy-data-acquisitions", { variantId, filterString }],
    queryFn: () => fetchEnergyDataAcquisitions(variantId, filterString),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: typeof options?.enabled === "undefined" ? true : options.enabled
  });

  async function fetchEnergyDataAcquisitions(
    variantId: number,
    filterString?: string
  ) {
    const url = filterString
      ? urls.api.energyDataAcquisitionsWithFilters(variantId, filterString)
      : urls.api.energyDataAcquisitions(variantId);
    const response = await api.get<Array<Acquisition>>(url);
    return response.data;
  }

  useEffect(() => {
    if (onError && isError) {
      onError(error);
    }
  }, [isError, error, onError]);

  return {
    data,
    isLoading,
    error
  };
}

export { useEnergyDataAcquisitions };
