import type { CellInfo, SortingRule } from "react-table";

/** Returns the current `desc` value of a column. If the column is not sorted, it returns undefined. */
export function getCurrentSortedDesc(
  sortedColumns: Array<SortingRule> | undefined,
  cellInfo: CellInfo
) {
  const sortedColumnIndex = sortedColumns?.findIndex(
    (column) => column.id === cellInfo.column.id
  );
  const thisColumnIsSorted =
    typeof sortedColumnIndex !== "undefined" && sortedColumnIndex !== -1;
  const currentSortedDesc = thisColumnIsSorted
    ? sortedColumns?.[sortedColumnIndex].desc
    : undefined;

  return currentSortedDesc;
}
