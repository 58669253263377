import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import api from "../../../../api";
import urls from "../../../../urls";
import { queryWithPollResponse } from "../../../../utils/api-utils";
import type { CreatedGenerator } from "../../../../utils/backend-types";

export function useImportGeneratorFromMastr(
  taskId: string,
  options?: Partial<UseQueryOptions<CreatedGenerator | undefined>>
) {
  async function fetchGeneratorFromMastr(taskId: string) {
    const url = urls.api.getCreatedGeneratorsFromMastr(taskId);

    const response = await queryWithPollResponse<CreatedGenerator>(() =>
      api.get(url)
    );

    return response.data;
  }

  return useQuery({
    queryKey: ["generator-from-mastr", { taskId }],
    queryFn: () => fetchGeneratorFromMastr(taskId),
    ...options
  });
}
