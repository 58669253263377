import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../api";
import urls from "../urls";
import type { ConsumptionShareModel } from "../utils/backend-types";

export function useConsumptionShare(
  consumptionShareId?: number | null,
  options?: Partial<UseQueryOptions<ConsumptionShareModel>>
) {
  const { data, isLoading, error } = useQuery({
    queryKey: ["consumptionShareData", { consumptionShareId }],
    queryFn: () => fetchConsumptionShare(consumptionShareId),
    ...options
  });

  const queryClient = useQueryClient();

  function invalidateConsumptionShareData() {
    return queryClient.invalidateQueries({
      queryKey: ["consumptionShareData", { consumptionShareId }]
    });
  }

  function saveConsumptionShareData(consumptionShareModelData) {
    return api.post(urls.api.consumptionShare(), consumptionShareModelData);
  }

  return {
    data,
    isLoading,
    error,
    invalidateConsumptionShareData,
    saveConsumptionShareData
  };
}

async function fetchConsumptionShare(consumptionShareId?: number | null) {
  if (!consumptionShareId) {
    return null;
  }
  const consumptionShareUrl = urls.api.consumptionShare(consumptionShareId);
  const response = await api.get(consumptionShareUrl);
  return response.data;
}
