import type { ChangeableSpotMarketFormula } from "../../../../DynamicForm/FormItems/FormField/ChangeableSpotMarketFormulaFieldStatic/ChangeableSpotMarketFormulaFieldStatic";
import { getCurrentDateRangeObject } from "./getCurrentDateRangeObject";

/**
 * Takes an array of formulas
 * and returns the formula that
 * is valid for the current date
 */
export function getCurrentFormula(
  formulas: Array<ChangeableSpotMarketFormula>
) {
  const formula = getCurrentDateRangeObject(formulas);
  if (formula) {
    return formula.formula;
  }
  return null;
}
