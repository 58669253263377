import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import { direktvermarktungApiWithoutCamelization } from "../../../api/subApis/direktvermarktungApi";
import type { DirektvermarktungFormulaEntgeltVariablesResponse } from "../Direktvermarktung.types";

export function useDirektvermarktungFormulaEntgeltVariables(
  options?: Partial<
    UseQueryOptions<
      DirektvermarktungFormulaEntgeltVariablesResponse | undefined
    >
  >
) {
  const { data, isLoading, error } = useQuery({
    queryKey:
      direktvermarktungApiWithoutCamelization.formulaEntgeltVariables.key(),
    queryFn: () => fetchDirektvermarktungFormulaEntgeltVariables(),
    ...options
  });

  async function fetchDirektvermarktungFormulaEntgeltVariables() {
    const direktvermarktungFormulaEntgeltVariablesUrl =
      direktvermarktungApiWithoutCamelization.formulaEntgeltVariables.url();
    const response =
      await apiWithoutCamelization.get<DirektvermarktungFormulaEntgeltVariablesResponse>(
        direktvermarktungFormulaEntgeltVariablesUrl
      );
    return response.data;
  }

  return {
    data,
    isLoading,
    error
  };
}
