import { useCallback } from "react";
import { useLocation } from "react-router-dom";

export interface MissingObjectDataProps {
  fieldsThatCouldBeMissing: Array<string>;
  shouldFieldBeHighlighted: (
    fieldName: string,
    relevantFormValues: Record<string, unknown>
  ) => boolean;
}

type UseShouldFieldBeHighlightedProps =
  MissingObjectDataProps["shouldFieldBeHighlighted"];

export function useShouldFieldBeHighlighted(
  fields: Array<string>,
  groupedExclusiveOrFields?: Array<Array<string>>
): UseShouldFieldBeHighlightedProps {
  const { state: locationState } = useLocation();

  return useCallback(
    (fieldName: string, relevantFormValues: Record<string, unknown>) => {
      const highlightingEnabled = !!locationState?.missingFields;
      const formFieldValue = relevantFormValues[fieldName];
      const formFieldValueIsEmpty =
        formFieldValue === "" ||
        !formFieldValue ||
        (Array.isArray(formFieldValue) && formFieldValue.length === 0);

      if (
        highlightingEnabled &&
        fields.includes(fieldName) &&
        formFieldValueIsEmpty
      ) {
        if (groupedExclusiveOrFields && groupedExclusiveOrFields.length > 0) {
          const exclusiveOrFields = groupedExclusiveOrFields.find((group) =>
            group.includes(fieldName)
          );

          if (exclusiveOrFields) {
            return !atLeastOneFieldHasAValue(
              exclusiveOrFields,
              relevantFormValues
            );
          }
        }

        return true;
      }

      return false;
    },
    [fields, groupedExclusiveOrFields, locationState]
  );
}

/** Checks all form values belonging to a group of fields to see if at least one has a value. */
function atLeastOneFieldHasAValue(
  exclusiveOrFields: Array<string>,
  formValues: Record<string, unknown>
) {
  return exclusiveOrFields.some(
    (field) => formValues[field] && formValues[field] !== ""
  );
}
