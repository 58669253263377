import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "reactstrap";
import {
  type LocationSection,
  type MeteringConceptByPersons
} from "../../utils/backend-types";
import { LOCATION_SECTIONS, THEME_VARS } from "../../utils/constants";
import { Portlet } from "../BuildingBlocks/Layout/Portlet";
import { Section } from "../BuildingBlocks/Layout/Section";
import loader from "../Loader/Loader.js";
import MarketLocationComparison from "../MeteringConceptComparison/MarketLocationComparison";
import { mergeByAccessors } from "../MeteringConceptComparison/merging";
import {
  SectionHeaderRow,
  SectionTitleRow
} from "../MeteringConceptComparison/MeteringConceptComparison";

import "./MeteringConcept.scss";

interface MeteringConceptProps {
  data: { meteringConcept: [MeteringConceptByPersons] };
  variantId: number;
  formatForReport: boolean;
}

function MeteringConcept({
  data,
  variantId,
  formatForReport
}: MeteringConceptProps) {
  const marketLocations = data.meteringConcept[0].persons.reduce(
    (marketLocations, person) => {
      return [...marketLocations, ...person.marketLocations];
    },
    []
  );
  const marketLocationsByVariantId = {
    [variantId]: marketLocations
  };

  function renderLocationSections(
    locationTypes,
    mergedMarketLocations,
    meteringConceptKeys,
    meteringConceptConfiguration
  ) {
    const keysWithLocationTypeToRender = mergedMarketLocations.keys.filter(
      (key) =>
        locationTypes.includes(mergedMarketLocations.common[key].locationType)
    );

    return keysWithLocationTypeToRender.map((key) => (
      <MarketLocationComparison
        displayFormulas
        key={key}
        marketLocationKey={key}
        mergedMarketLocations={mergedMarketLocations}
        meteringConceptConfiguration={meteringConceptConfiguration}
        meteringConceptKeys={meteringConceptKeys}
      />
    ));
  }

  const meteringConceptKeys = Object.keys(marketLocationsByVariantId);
  const meteringConceptConfiguration = {
    [meteringConceptKeys[0]]: {
      header: "",
      headerBackgroundColor: THEME_VARS.brandColor,
      cellBackgroundColor: null
    }
  };
  const mergedMarketLocations = mergeByAccessors(
    marketLocationsByVariantId,
    ["locationType", "generator.name", "consumer.name", "connection.name"],
    ["generator.name", "consumer.name"]
  );
  const locationSectionsWithRenderedResults = LOCATION_SECTIONS.map<
    LocationSection & { rendered: Array<React.ReactNode> }
  >((section) => {
    return {
      ...section,
      rendered: renderLocationSections(
        section.locationTypes,
        mergedMarketLocations,
        meteringConceptKeys,
        meteringConceptConfiguration
      )
    };
  });
  const minWidth = `${320 * meteringConceptKeys.length}px`;

  return (
    <div id="metering-concept-table">
      <Row style={{ minWidth }}>
        <Col>
          <Portlet noPadding={formatForReport} title={""}>
            {locationSectionsWithRenderedResults.map((section) => {
              return (
                <Section key={section.key}>
                  <SectionTitleRow
                    meteringConceptConfiguration={meteringConceptConfiguration}
                    meteringConceptKeys={meteringConceptKeys}
                    title={section.title}
                  />
                  <SectionHeaderRow
                    {...section}
                    displayFormulas={true}
                    meteringConceptConfiguration={meteringConceptConfiguration}
                    meteringConceptKeys={meteringConceptKeys}
                  />
                  {section.rendered}
                </Section>
              );
            })}
          </Portlet>
        </Col>
      </Row>
    </div>
  );
}

MeteringConcept.propTypes = {
  data: PropTypes.object.isRequired,
  variantId: PropTypes.number.isRequired,
  formatForReport: PropTypes.bool
};

export default loader(MeteringConcept);
