import type { DateTime } from "luxon";
import type { ChangeableFieldData } from "../ChangeableFieldWidget";

export function updateChangeableFieldValue<T>(
  updatedValue: ChangeableFieldData<T | null>,
  index: number,
  currentValues: Array<ChangeableFieldData<T | null>>
) {
  const updatedValues: Array<ChangeableFieldData<T | null>> = [
    ...currentValues
  ];
  updatedValues[index] = updatedValue;

  for (let i = index; i < updatedValues.length; i++) {
    const value = updatedValues[i];
    const nextValue = updatedValues[i + 1];

    if (nextValue && value.endDate) {
      const newStartDate = calculateNewStartDate(value.endDate);

      if (isStartDateInvalid(value.endDate, nextValue.startDate)) {
        const needsNewEndDate =
          nextValue.endDate && newStartDate > nextValue.endDate;
        const newEndDate = needsNewEndDate ? newStartDate : nextValue.endDate;

        updatedValues[i + 1] = {
          ...nextValue,
          startDate: newStartDate,
          endDate: newEndDate
        };
      } else {
        updatedValues[i + 1] = {
          ...nextValue,
          startDate: newStartDate
        };
      }
    }
  }

  const lastValueEndDate = updatedValues[updatedValues.length - 1].endDate;
  if (lastValueEndDate !== null) {
    const newLastValueStartDate = lastValueEndDate.set({
      day: lastValueEndDate.day + 1
    });
    const newLastValue: ChangeableFieldData<T | null> = {
      value: null,
      startDate: newLastValueStartDate,
      endDate: null
    };

    updatedValues.push(newLastValue);
  }

  return updatedValues;
}

function calculateNewStartDate(currentEndDate: DateTime) {
  return currentEndDate.set({
    day: currentEndDate.day + 1
  });
}

function isStartDateInvalid(
  currentEndDate: DateTime,
  nextStartDate: DateTime | null
) {
  return !nextStartDate || currentEndDate >= nextStartDate;
}
