import classnames from "classnames";
import React, { useState } from "react";

import { Col, Collapse } from "reactstrap";
import type { SiteEnergyWizard, SiteWizard } from "../../utils/backend-types";
import { Icon } from "../BuildingBlocks/Icon/Icon";
import { IconName } from "../BuildingBlocks/Icon/types";
import { EnergyWizard } from "./EnergyWizard/EnergyWizard";
import { OnboardingWizard } from "./OnboardingWizard/OnboardingWizard";

import "./SiteTasks.scss";
import type { OperatorChange } from "./OperatorChanges/OperatorChanges.types";
import { OperatorChangeTaskList } from "./OperatorChanges/OperatorChangeTaskList/OperatorChangeTaskList";
import type { TenantChange } from "./TenantChanges/TenantChanges.types";
import { TenantChangeTaskList } from "./TenantChanges/TenantChangeTaskList/TenantChangeTaskList";

type SiteTasksProps = {
  siteWizard: SiteWizard;
  energyWizard: SiteEnergyWizard;
  operatorChanges: Array<OperatorChange>;
  tenantChanges: Array<TenantChange>;
  onSiteWizardUpdated: () => Promise<void>;
  onEnergyWizardUpdated: () => void;
  onUpdateOperatorChangeProcess: (
    operatorChange: OperatorChange
  ) => Promise<void>;
  onUpdateTenantChangeProcess: (tenantChange: TenantChange) => Promise<void>;
  projectId: string;
  siteIsFullFeedin?: boolean;
};

function SiteTasks({
  siteWizard,
  energyWizard,
  operatorChanges,
  tenantChanges,
  onSiteWizardUpdated,
  onEnergyWizardUpdated,
  onUpdateOperatorChangeProcess,
  onUpdateTenantChangeProcess,
  projectId,
  siteIsFullFeedin
}: SiteTasksProps) {
  const siteWizardFinished = siteIsFullFeedin
    ? siteWizard.phases.msbauthority.done
    : siteWizard.onboardingDone.onboardingDone;
  const siteTasksNotFinished =
    !siteWizardFinished ||
    (energyWizard.enabled && !energyWizard.phases.meters.done);
  const [isOpen, setIsOpen] = useState(siteTasksNotFinished);
  const [fadeOut, setFadeOut] = useState(false);
  const [fadeIn, setFadeIn] = useState(siteTasksNotFinished);

  function getCurrentView() {
    if (!isOpen) {
      return (
        <div
          onClick={() => {
            setFadeIn(true);
            setIsOpen(!isOpen);
          }}
        >
          <div className="circle-and-icon-container">
            <Icon className="circle-arrow" name={IconName.ArrowCircleLeft} />
            {siteTasksNotFinished && <span className="red-circle" />}
          </div>
          <h4 className="site-tasks-header-collapsed">Meine Aufgaben</h4>
        </div>
      );
    } else {
      return (
        <Col>
          <Collapse className="site-task-collapse" isOpen={isOpen}>
            <div
              className={classnames(
                "site-tasks-container",
                { "site-task-container-fade-out": fadeOut },
                { "site-task-container-fade-in": fadeIn }
              )}
              onAnimationEnd={() => {
                if (fadeIn) {
                  setFadeIn(false);
                } else {
                  setFadeOut(false);
                  setIsOpen(!isOpen);
                }
              }}
            >
              <div
                className="site-tasks-header-container"
                onClick={() => {
                  if (!fadeIn) {
                    setFadeOut(true);
                  }
                }}
              >
                <div className="circle-and-icon-container">
                  <Icon
                    className="circle-arrow"
                    name={
                      fadeOut
                        ? IconName.ArrowCircleLeft
                        : IconName.ArrowCircleRight
                    }
                  />
                  {siteTasksNotFinished && <span className="red-circle" />}
                </div>

                <h3 className="site-tasks-header-text">Meine Aufgaben</h3>
              </div>

              <OnboardingWizard
                projectId={projectId}
                siteIsFullFeedin={siteIsFullFeedin}
                siteWizard={siteWizard}
                onSiteWizardUpdated={onSiteWizardUpdated}
              />
              {energyWizard.enabled && (
                <EnergyWizard
                  energyWizard={energyWizard}
                  onEnergyWizardUpdated={onEnergyWizardUpdated}
                />
              )}
              <OperatorChangeTaskList
                operatorChanges={operatorChanges}
                projectId={projectId}
                onUpdateProcess={onUpdateOperatorChangeProcess}
              />
              <TenantChangeTaskList
                projectId={projectId}
                tenantChanges={tenantChanges}
                onUpdateProcess={onUpdateTenantChangeProcess}
              />
            </div>
          </Collapse>
        </Col>
      );
    }
  }
  return <>{getCurrentView()}</>;
}

export { SiteTasks };
