import _ from "lodash";
import React, { useState } from "react";

import { aggregateMeteringConceptComparisonData } from "../../../utils/aggregate-metering-concept-comparison-data";
import type {
  MarketLocation,
  MeteringConceptByPersons,
  MeteringConceptForPerson,
  PriceComponent,
  ResultSummary,
  Variant
} from "../../../utils/backend-types";
import { LOCATION_SECTIONS, THEME_VARS } from "../../../utils/constants";
import loader from "../../Loader";
import { MeteringConceptComparison } from "../../MeteringConceptComparison/MeteringConceptComparison";
import SimulationComparisonHeader from "../SimulationComparisonHeader";

interface SimulationComparisonProps {
  data: Record<number, [MeteringConceptByPersons]>;
  variants: Array<Variant>;
  additionalInstalledPower: Record<number, number>;
  scenarios;
}

function SimulationComparison({
  data,
  variants,
  additionalInstalledPower,
  scenarios
}: SimulationComparisonProps) {
  const [meteringConcepts, setMeteringConcepts] = useState(
    getInitialMeteringConceptsByVariantId(data)
  );

  function getInitialMeteringConceptsByVariantId(
    data: Record<number, [MeteringConceptByPersons]>
  ) {
    return Object.keys(data).reduce<Record<number, [MeteringConceptForPerson]>>(
      (meteringConceptsByVariantId, key) => {
        const variantId = parseInt(key, 10);

        return {
          ...meteringConceptsByVariantId,
          [variantId]: [getInitialMeteringConcepts(data[variantId][0])]
        };
      },
      {}
    );
  }

  function getInitialMeteringConcepts(
    meteringConceptByPersons: MeteringConceptByPersons
  ) {
    function aggregateSummaries(a: ResultSummary, b: ResultSummary) {
      return {
        totalYearlyCost: (
          parseInt(a.totalYearlyCost) + parseInt(b.totalYearlyCost)
        ).toString(),
        internalDelivery: {
          kwh: a.internalDelivery.kwh + b.internalDelivery.kwh,
          share: a.internalDelivery.share + b.internalDelivery.share
        }
      };
    }

    return meteringConceptByPersons.persons.reduce<MeteringConceptForPerson>(
      (meteringConcepts, person) => {
        return {
          marketLocations: [
            ...meteringConcepts.marketLocations,
            ...person.marketLocations
          ],
          name: person.name,
          priceComponents: [
            ...meteringConcepts.priceComponents,
            ...person.priceComponents
          ],
          summary: aggregateSummaries(meteringConcepts.summary, person.summary)
        };
      },
      {
        marketLocations: [],
        name: "",
        priceComponents: [],
        summary: {
          totalYearlyCost: "0",
          internalDelivery: { kwh: 0, share: 0 }
        }
      }
    );
  }

  function removeScenario(variantId: number) {
    setMeteringConcepts((oldMeteringConcepts) => {
      const filteredKeys = Object.keys(oldMeteringConcepts).filter(
        (key) => key !== variantId.toString()
      );

      const filteredMeteringConcepts = filteredKeys.reduce<
        Record<number, [MeteringConceptForPerson]>
      >((meteringConcepts, key) => {
        const variantId = parseInt(key, 10);
        meteringConcepts[variantId] = oldMeteringConcepts[variantId];
        return meteringConcepts;
      }, {});

      return filteredMeteringConcepts;
    });
  }

  function setComparisonState(
    data: Record<number, [MeteringConceptForPerson]>
  ) {
    let state: {
      marketLocations: Record<number, Array<MarketLocation>>;
      priceComponents: Record<number, Array<PriceComponent>>;
      summary: Record<number, ResultSummary>;
    } = { marketLocations: {}, priceComponents: {}, summary: {} };

    _.each(data, (variant, variantId) => {
      const meteringConcept = variant[0];
      state = {
        ...state,
        marketLocations: {
          ...state.marketLocations,
          [variantId]: meteringConcept.marketLocations
        },
        priceComponents: {
          ...state.priceComponents,
          [variantId]: meteringConcept.priceComponents
        },
        summary: {
          ...state.summary,
          [variantId]: meteringConcept.summary
        }
      };
    });

    return state;
  }

  function createMeteringConceptConfiguration(
    variants: Array<Variant>,
    variantIds: Array<number>
  ) {
    return variantIds.reduce((obj, variantId) => {
      const variant = variants.find((v) => v.id === variantId);
      const headerText = `${variant?.name}`;

      // set every odd-indexed column in variantIds to grey
      const index = variantIds.findIndex((vId) => vId === variantId);
      const shouldUseGrey = index % 2 === 0;

      obj[variantId] = {
        header: headerText,
        headerBackgroundColor: shouldUseGrey
          ? THEME_VARS.customGrey
          : THEME_VARS.brandColor,
        cellBackgroundColor: shouldUseGrey ? "light-grey" : null,
        bundleConsumers: variants.find((variant) => variant.id === variantId)
          ?.bundleConsumers
      };

      return obj;
    }, {});
  }

  const { marketLocations, priceComponents, summary } =
    setComparisonState(meteringConcepts);

  let variantIds = Object.keys(meteringConcepts).map((variantId) =>
    parseInt(variantId)
  );
  variantIds = _.sortBy(variantIds, function (variantId) {
    // sort scenarios (using variantIds mapping) by installed power
    return additionalInstalledPower[variantId];
  });
  const meteringConceptConfiguration = createMeteringConceptConfiguration(
    variants,
    variantIds
  );
  const { mergedMarketLocations, mergedPriceComponents, yearlyAggregates } =
    aggregateMeteringConceptComparisonData(
      marketLocations,
      priceComponents,
      summary
    );
  const header = (
    <SimulationComparisonHeader
      additionalInstalledPower={additionalInstalledPower}
      meteringConceptConfiguration={meteringConceptConfiguration}
      removeScenario={removeScenario}
      scenarios={scenarios}
      variantIds={variantIds}
      yearlyAggregates={yearlyAggregates}
    />
  );

  return (
    <MeteringConceptComparison
      header={header}
      locationSections={LOCATION_SECTIONS}
      mergedMarketLocations={mergedMarketLocations}
      mergedPriceComponents={mergedPriceComponents}
      meteringConceptConfiguration={meteringConceptConfiguration}
      meteringConceptKeys={variantIds}
      title="Ergebnisübersicht Simulation neue PV"
      yearlyAggregates={yearlyAggregates}
    />
  );
}

export default loader(SimulationComparison);
