import type { Node } from "reactflow";
import type { NodeData } from "../../StructureViewDiagram/StructureViewDiagram";

export function getFlowNodesFromNodes(nodes: Array<NodeData>): Array<Node> {
  return nodes.map((node) => {
    return {
      id: node.id.toString(),
      position: { x: node.xRelative ?? node.x, y: node.yRelative ?? node.y },
      positionAbsolute: { x: node.x, y: node.y },
      parentId: node.parent,
      data: {
        active: node.data?.active,
        label: node.label,
        image: node.imageUrl,
        imageColor: node.data?.color,
        size: node.size,
        type: node.data?.type,
        componentId: node.data?.componentId,
        tooltipData: node.tooltipData,
        meterBillingRelevantOrSubmeter:
          node.data?.meterBillingRelevantOrSubmeter,
        meterPriorityDescription: node.data?.meterPriorityDescription
      },
      type: "custom"
    };
  });
}
