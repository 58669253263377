import classnames from "classnames";
import React from "react";
import api from "../../../../api";
import urls from "../../../../urls";
import type { SiteEnergyWizard } from "../../../../utils/backend-types";
import { THEME_VARS } from "../../../../utils/constants";
import { Icon } from "../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import { AssignmentType } from "../EnergyWizard";
import { MeterAssignment } from "./MeterAssignment/MeterAssignment";

import "./energyAssignment.scss";

type EnergyAssignmentProps = {
  energyWizard: SiteEnergyWizard;
  type: AssignmentType;
  isNext: boolean;
  isDone: boolean;
  onEnergyWizardUpdated: () => void;
};

function EnergyAssignment({
  energyWizard,
  type,
  isNext,
  isDone,
  onEnergyWizardUpdated
}: EnergyAssignmentProps) {
  async function updateEnergyWizard(type: AssignmentType) {
    let measurementDone = energyWizard.phases.meters.done;

    if (type === AssignmentType.MeasurementData) {
      measurementDone = true;
    }

    await api
      .post(urls.api.energyWizardUpdate(energyWizard.id), {
        id: energyWizard.id,
        phases: {
          meters: {
            done: measurementDone
          }
        }
      })
      .then(() => {
        onEnergyWizardUpdated();
      });
  }

  return (
    <div
      className={classnames("energy-assignment-item", {
        "energy-assignment-item--next": isNext
      })}
    >
      <div
        className={classnames("next-assignment-title", {
          "next-assignment-title-inactive": !isNext
        })}
      >
        <span>{type}</span>

        {isDone && (
          <Icon
            className="check-circle-icon"
            name={IconName.CheckCircle}
            style={{ fontSize: "1.5rem", color: THEME_VARS.brandColor }}
          />
        )}
      </div>

      {type === AssignmentType.MeasurementData && (
        <MeterAssignment
          done={isDone}
          isBlocked={energyWizard.phases.meters.blocked}
          isNext={isNext}
          missingData={energyWizard.phases.meters.missingData}
          updateSiteWizard={updateEnergyWizard}
        />
      )}
    </div>
  );
}

export { EnergyAssignment };
