import PropTypes from "prop-types";
import React from "react";
import type { Column } from "react-table";
import { FREQUENCY_OPTIONS } from "../../../../utils/constants";
import { DecimalLabel } from "../../../BuildingBlocks/DecimalLabel/DecimalLabel";
import {
  CustomReactSelectTable,
  CustomReactTable
} from "../../../CustomReactTable/CustomReactTable";
import type { CustomReactTableWithSelectProps } from "../../../CustomReactTable/CustomReactTableWithSelect/CustomReactTableWithSelect";
import {
  MEDIUM_LABEL_MAP,
  ORIGIN_LABEL_MAP,
  TYPE_LABEL_MAP
} from "../../common";
import type {
  Acquisition,
  AcquisitionSummaryDataPoint
} from "../EnergyDataView";

import "./EnergyDataAcquisitionList.scss";

const DEFAULT_SORTED = [
  {
    id: "site",
    desc: false
  },
  {
    id: "label",
    desc: false
  }
];

export type AcquisitionWithSummaryData = Exclude<
  Acquisition & Partial<AcquisitionSummaryDataPoint>,
  "energyDataAcquisition"
>;

interface EnergyDataAcquisitionListProps {
  acquisitions: Array<Acquisition>;
  acquisitionSummaryData?: Array<AcquisitionSummaryDataPoint>;
  showAll?: boolean;
  customReactSelectTableProps?: CustomReactTableWithSelectProps<AcquisitionWithSummaryData>;
  loading?: boolean;
}

function EnergyDataAcquisitionList({
  acquisitions,
  showAll,
  acquisitionSummaryData,
  customReactSelectTableProps,
  loading
}: EnergyDataAcquisitionListProps) {
  const showSummaryColumns = !!acquisitionSummaryData;
  const acqisitionsWithSummaryData: Array<AcquisitionWithSummaryData> = showAll
    ? acquisitions
    : acquisitions.reduce<Array<AcquisitionWithSummaryData>>(
        (acquisitions, acquisition) => {
          const summaryData = acquisitionSummaryData?.find(
            (data) => data.energyDataAcquisition.id === acquisition.id
          );

          if (showSummaryColumns && summaryData) {
            acquisitions.push({
              ...acquisition,
              energy: summaryData.energy,
              maxPower: summaryData.maxPower
            });
          }

          return acquisitions;
        },
        []
      );

  const totalEnergy = acqisitionsWithSummaryData.reduce(
    (total, acquisition) => {
      if (typeof acquisition.energy !== "undefined") {
        return total + acquisition.energy;
      }

      return total;
    },
    0
  );

  const tableColumns: Array<Column<AcquisitionWithSummaryData>> = [
    {
      Header: "Liegenschaft",
      accessor: "site",
      minWidth: 195
    },
    {
      Header: "Bezeichnung",
      accessor: "label",
      minWidth: 460
    },
    {
      Header: "Typ",
      accessor: "type",
      Cell: (cellData) => TYPE_LABEL_MAP[cellData.value]
    },
    {
      Header: "Medium",
      accessor: "medium",
      Cell: (cellData) => MEDIUM_LABEL_MAP[cellData.value]
    },
    {
      Header: "Herkunft",
      accessor: "origin",
      Cell: (cellData) => ORIGIN_LABEL_MAP[cellData.value]
    },
    {
      Header: "Zeitintervall",
      accessor: "frequency",
      Cell: (cellData) => FREQUENCY_OPTIONS[cellData.value]
    }
  ];

  if (showSummaryColumns) {
    tableColumns.push(
      {
        Header: "Energiemenge [kWh]",
        accessor: "energy",
        minWidth: 170,
        Cell: (cellData) =>
          cellData.original.maxPower === null ? (
            "-"
          ) : (
            <span className="align-right">
              <DecimalLabel decimalScale={2} value={cellData.value} />
            </span>
          ),
        Footer: () => (
          <span className="total align-right">
            Summe: <DecimalLabel decimalScale={2} value={totalEnergy} />
          </span>
        )
      },
      {
        Header: "Höchstleistung [kW]",
        accessor: "maxPower",
        minWidth: 170,
        Cell: (cellData) =>
          cellData.value === null ? (
            "-"
          ) : (
            <span className="align-right">
              <DecimalLabel decimalScale={2} value={cellData.value} />
            </span>
          )
      }
    );
  }

  return (
    <div className="EnergyDataAcquisitionList">
      {customReactSelectTableProps ? (
        <CustomReactSelectTable
          {...customReactSelectTableProps}
          columns={tableColumns}
          data={acqisitionsWithSummaryData}
          defaultSorted={DEFAULT_SORTED}
          keyField="id"
          minRows={0}
          NoDataComponent={() => <div />}
          pageSize={acqisitionsWithSummaryData.length}
        />
      ) : (
        <CustomReactTable
          columns={tableColumns}
          data={acqisitionsWithSummaryData}
          defaultSorted={DEFAULT_SORTED}
          loading={loading}
          minRows={0}
          NoDataComponent={() => <div />}
          pageSize={acqisitionsWithSummaryData.length}
        />
      )}
    </div>
  );
}

EnergyDataAcquisitionList.propTypes = {
  acquisitions: PropTypes.array.isRequired,
  acquisitionSummaryData: PropTypes.array,
  showAll: PropTypes.bool,
  customReactSelectTableProps: PropTypes.object
};

export { EnergyDataAcquisitionList };
