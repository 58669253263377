import React, { useEffect, useState } from "react";
import { useConfiguratorComponents } from "../../hooks/useConfiguratorComponents";
import { useContainerRef } from "../../hooks/useContainerRef";
import type { MissingObjectDataProps } from "../../hooks/useShouldFieldBeHighlighted";
import type {
  GhostNode,
  MeteringOrMarketLocation
} from "../../utils/backend-types";
import type { Product } from "../../utils/enums";
import { ObjectName } from "../../utils/enums";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../BuildingBlocks/Layout/Modal/Modal";
import { ConnectionWizard } from "../ConnectionWizard/ConnectionWizard";
import { ConsumerWizard } from "../ConsumerWizard/ConsumerWizard";
import { GasConnectionWizard } from "../GasConnectionWizard/GasConnectionWizard";
import { GeneratorWizard } from "../GeneratorWizard/GeneratorWizard";
import { GhostNodeWizard } from "../GhostNodeWizard/GhostNodeWizard";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { MarketLocationWizard } from "../MarketLocationWizard/MarketLocationWizard";
import { MeteringLocationWizard } from "../MeteringLocationWizard/MeteringLocationWizard";
import { MeterWizard } from "../MeterWizard/MeterWizard";
import { PersonWizard } from "../PersonWizard/PersonWizard";
import { StorageWizard } from "../StorageWizard/StorageWizard";
import "./VariantObjectWizard.scss";
import { useMissingObjectData } from "./hooks/useMissingObjectData";

export type WizardModeProps<T> =
  | { mode: "create" }
  | ({
      mode: "edit";
      wizardObject: T;
    } & MissingObjectDataProps);

type VariantObjectWizardProps = {
  graphCoordinates?: { x: number; y: number };
  isOpen: boolean;
  /** Currently only used in MeteringLocationWizard, MarketLocationWizard and GhostNodeWizard. */
  objectName: ObjectName;
  objectDisplayName: string;
  product: Product;
  siteId?: number;
  variantId: number;
  onToggle: () => void;
} & ({ mode: "create" } | { mode: "edit"; objectId: number });

/** Renders wizards according to `ObjectName`. */
function VariantObjectWizard({
  graphCoordinates = { x: 0, y: 0 },
  isOpen,
  objectDisplayName,
  objectName,
  product,
  siteId,
  variantId,
  onToggle,
  ...modeProps
}: VariantObjectWizardProps) {
  const { containerNode: footerNode, containerRef: footerRef } =
    useContainerRef();
  const action = modeProps.mode === "create" ? "erstellen" : "bearbeiten";
  const [title, setTitle] = useState<string | React.ReactNode>(
    `${objectDisplayName} ${action}`
  );
  const {
    data,
    isLoading: isDataLoading,
    error: dataError
  } = useConfiguratorComponents(objectName, siteId, modeProps.mode === "edit");
  const objectData =
    modeProps.mode === "edit"
      ? data?.find((obj) => obj.id === modeProps.objectId)
      : undefined;
  const {
    error: missingObjectDataError,
    fieldsThatCouldBeMissing,
    isLoading: missingObjectDataIsLoading,
    shouldFieldBeHighlighted
  } = useMissingObjectData(
    modeProps.mode === "edit" ? modeProps.objectId : undefined,
    siteId,
    product
  );

  useEffect(() => {
    if (objectData) {
      setTitle(`${objectData.name} ${action}`);
    }
  }, [objectData, action]);

  function onWizardClose() {
    setTitle(`${objectDisplayName} ${action}`);
    onToggle();
  }

  function renderWizard(siteId: number) {
    switch (objectName) {
      case ObjectName.Generator:
        return (
          <GeneratorWizard
            buttonContainer={footerNode}
            graphCoordinates={graphCoordinates}
            product={product}
            siteId={siteId}
            variantId={variantId}
            onClose={onWizardClose}
            onUpdateModalTitle={(newTitle) => setTitle(newTitle)}
          />
        );
      case ObjectName.Consumer:
        return (
          <ConsumerWizard
            buttonContainer={footerNode}
            graphCoordinates={graphCoordinates}
            product={product}
            siteId={siteId}
            variantId={variantId}
            onClose={onWizardClose}
            onUpdateModalTitle={(newTitle) => setTitle(newTitle)}
          />
        );
      case ObjectName.Storage:
        return (
          <StorageWizard
            buttonContainer={footerNode}
            graphCoordinates={graphCoordinates}
            product={product}
            siteId={siteId}
            variantId={variantId}
            onClose={onWizardClose}
            onUpdateModalTitle={(newTitle) => setTitle(newTitle)}
          />
        );
      case ObjectName.Connection:
        return (
          <ConnectionWizard
            buttonContainer={footerNode}
            graphCoordinates={graphCoordinates}
            siteId={siteId}
            variantId={variantId}
            onClose={onWizardClose}
          />
        );
      case ObjectName.GasConnection:
        return (
          <GasConnectionWizard
            buttonContainer={footerNode}
            graphCoordinates={graphCoordinates}
            siteId={siteId}
            variantId={variantId}
            onClose={onToggle}
          />
        );
      case ObjectName.Meter:
        return (
          <MeterWizard
            buttonContainer={footerNode}
            graphCoordinates={graphCoordinates}
            siteId={siteId}
            variantId={variantId}
            onClose={onWizardClose}
          />
        );
      case ObjectName.GhostNode:
        return (
          <GhostNodeWizard
            buttonContainer={footerNode}
            graphCoordinates={graphCoordinates}
            siteId={siteId}
            onClose={onWizardClose}
            {...(modeProps.mode === "create"
              ? { mode: "create" }
              : {
                  mode: "edit",
                  wizardObject: objectData as GhostNode,
                  fieldsThatCouldBeMissing: fieldsThatCouldBeMissing,
                  shouldFieldBeHighlighted: shouldFieldBeHighlighted
                })}
          />
        );
      case ObjectName.MarketLocation:
        return (
          <MarketLocationWizard
            buttonContainer={footerNode}
            siteId={siteId}
            onClose={onWizardClose}
            {...(modeProps.mode === "create"
              ? { mode: "create" }
              : {
                  mode: "edit",
                  wizardObject: objectData as MeteringOrMarketLocation,
                  fieldsThatCouldBeMissing: fieldsThatCouldBeMissing,
                  shouldFieldBeHighlighted: shouldFieldBeHighlighted
                })}
          />
        );
      case ObjectName.MeteringLocation:
        return (
          <MeteringLocationWizard
            buttonContainer={footerNode}
            siteId={siteId}
            onClose={onWizardClose}
            {...(modeProps.mode === "create"
              ? { mode: "create" }
              : {
                  mode: "edit",
                  wizardObject: objectData as MeteringOrMarketLocation,
                  fieldsThatCouldBeMissing: fieldsThatCouldBeMissing,
                  shouldFieldBeHighlighted: shouldFieldBeHighlighted
                })}
          />
        );
      default:
        console.error("Unexpected error: no Wizard defined for this type.");
        return <div></div>;
    }
  }

  function renderWizardNoSiteId() {
    if (objectName !== ObjectName.Person) {
      console.error(`siteId is required for ${objectName} wizard.`);
      return <div></div>;
    }

    return (
      <PersonWizard
        buttonContainer={footerNode}
        graphCoordinates={graphCoordinates}
        product={product}
        variantId={variantId}
        onClose={onWizardClose}
      />
    );
  }

  const isLoading =
    modeProps.mode === "edit" && (isDataLoading || missingObjectDataIsLoading);
  const error =
    modeProps.mode === "edit" ? dataError || missingObjectDataError : null;

  return (
    <div className="VariantObjectWizard">
      <Modal
        className="wizard-modal"
        isOpen={isOpen}
        size="lg"
        toggle={onToggle}
      >
        <ModalHeader toggle={onToggle}>{title}</ModalHeader>
        <ModalBody scrollable>
          <LoadOrError error={error} loading={isLoading}>
            {objectName !== ObjectName.Person && !!siteId
              ? renderWizard(siteId)
              : renderWizardNoSiteId()}
          </LoadOrError>
        </ModalBody>
        <ModalFooter>
          <div className="variant-object-wizard-buttons" ref={footerRef} />
        </ModalFooter>
      </Modal>
    </div>
  );
}

export { VariantObjectWizard, VariantObjectWizardProps };
