import type { Column } from "react-table";
import type { LocationOverviewData } from "../../LocationOverview.types";
import { getMeteringDataAvailabilityColumn } from "./MeteringDataAvailabilityColumn";

function getMeteringDataColumn() {
  return {
    Header: "Messwerte",
    className: "metering-data",
    columns: [
      getMeteringDataAvailabilityColumn("Verbrauch/Entnahme", "positive"),
      getMeteringDataAvailabilityColumn("Erzeugung/Einspeisung", "negative")
    ]
  } satisfies Column<LocationOverviewData>;
}

export { getMeteringDataColumn };
