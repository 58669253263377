import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../api";
import urls from "../urls";
import type { GhostNode } from "../utils/backend-types";
import { ObjectName } from "../utils/enums";
import { getPluralVariableNameFromObjectName } from "../utils/getPluralVariableNameFromObjectName";

export function useGhostNodeMutation(
  mode: "create" | "edit",
  siteId: number,
  graphCoordinates: { x: number; y: number }
) {
  const queryClient = useQueryClient();
  const cacheName = getPluralVariableNameFromObjectName(ObjectName.GhostNode);

  return useMutation({
    mutationFn: (newData: GhostNode) =>
      mutateGhostNode(newData, mode, siteId, graphCoordinates),
    mutationKey: [cacheName, { siteOrVariantId: siteId }],
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [cacheName, { siteOrVariantId: siteId }]
      });
    }
  });
}

function mutateGhostNode(
  newData: GhostNode,
  mode: "create" | "edit",
  siteId: number,
  graphCoordinates: { x: number; y: number }
) {
  if (mode === "create") {
    return createGhostNode(newData, siteId, graphCoordinates);
  }

  return editGhostNode(newData);
}

function createGhostNode(
  newNode: GhostNode,
  siteId: number,
  graphCoordinates: { x: number; y: number }
) {
  const url = urls.api.ghostNodes(siteId);

  return api.post(url, {
    ...newNode,
    site: siteId,
    sketchElement: {
      xPosition: graphCoordinates.x,
      yPosition: graphCoordinates.y
    }
  });
}

function editGhostNode(editedNode: GhostNode) {
  const url = urls.api.ghostNode(editedNode.id);

  return api.patch(url, editedNode);
}
