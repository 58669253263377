import { t } from "i18next";
import React from "react";
import type { ApiError } from "../../../api";
import { showToast } from "../../../utils/toast";
import { SaveMeterError } from "../MeterConfiguration/SaveMeterError/SaveMeterError";

export type DifferentParamsInfo = Array<Record<string, Array<string>>>;

interface SaveMeterErrorData {
  extraInfo?: {
    meterName: string;
    differingParameters: DifferentParamsInfo;
  };
  error?: string;
  detail?: string;
}

export function showSaveMeterErrorToast(error: ApiError<SaveMeterErrorData>) {
  if (error?.response?.status === 500) {
    showToast("error", t("errors.ServerError"));
  } else {
    const errorMessage =
      error.response?.data.error || error.response?.data.detail;
    const errorExtraInfo =
      !!error.response?.data?.extraInfo?.differingParameters?.length;
    const meterName = error.response?.data?.extraInfo?.meterName;
    const meterDifferingParameters =
      error.response?.data?.extraInfo?.differingParameters;

    showToast(
      "error",
      <SaveMeterError
        errorExtraInfo={errorExtraInfo}
        errorMessage={errorMessage}
        meterDifferingParameters={meterDifferingParameters}
        meterName={meterName}
      />,
      {
        autoClose: false
      }
    );
  }
}
