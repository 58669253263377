import React, { useEffect, useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PaymentIntervallAndBillingMethod } from "../../../../../types/api.types";
import { showBannerToast } from "../../../../../utils/toast";
import { FormFieldInfoText } from "../../../../BuildingBlocks/Forms/FormField/FormFieldInfoText/FormFieldInfoText";
import { FormFieldLabel } from "../../../../BuildingBlocks/Forms/FormField/FormFieldLabel/FormFieldLabel";
import {
  Modal,
  ModalBody,
  ModalHeader
} from "../../../../BuildingBlocks/Layout/Modal/Modal";
import {
  TemplateDecisionCards,
  TemplateModalState
} from "../../../../BuildingBlocks/TemplateDecisionCards/TemplateDecisionCards";
import { LoadOrError } from "../../../../LoadOrError/LoadOrError";
import type { SelectItem } from "../../../../Select/Select";
import { Select } from "../../../../Select/Select";
import { useShouldShowStaffView } from "../../../../StaffViewToggle/useShouldShowStaffView";
import { useParagraph6Companies } from "../../../hooks/useParagraph6Companies";
import { useParagraph6Contract } from "../../../hooks/useParagraph6Contract";
import { useParagraph6ContractMutations } from "../../../hooks/useParagraph6ContractMutations";
import type {
  Paragraph6ContractFormFields,
  Paragraph6ContractPayload,
  Paragraph6ContractResponse
} from "../../../Paragraph6.types";
import { Paragraph6ContractForm } from "./Paragraph6ContractForm/Paragraph6ContractForm";

interface Paragraph6ContractModalProps {
  variantId: number;
  contractId?: string;
  isOpen?: boolean;
  onClose: () => void;
  templates?: Array<Paragraph6ContractResponse>;
}

function Paragraph6ContractModal({
  variantId,
  contractId,
  isOpen,
  onClose,
  templates
}: Paragraph6ContractModalProps) {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const [responseError, setResponseError] = useState<Error>();
  const shouldShowStaffView = useShouldShowStaffView();

  const navigate = useNavigate();

  const editMode = !!contractId;

  const defaultParagraph6Contract = {
    billing_method_and_period:
      PaymentIntervallAndBillingMethod.annual_based_on_measurements,
    variant: variantId,
    erzeuger_anteile: []
  } as const satisfies Partial<Paragraph6ContractPayload>;

  const { paragraph6Contract, isLoading: paragraph6ContractLoading } =
    useParagraph6Contract(contractId);
  const { paragraph6ContractCreateMutation, paragraph6ContractEditMutation } =
    useParagraph6ContractMutations(variantId);
  const isMutating =
    paragraph6ContractCreateMutation.isPending ||
    paragraph6ContractEditMutation.isPending;

  const { paragraph6Companies, isLoading: paragraph6CompaniesLoading } =
    useParagraph6Companies(variantId);

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  const onSubmit: SubmitHandler<Paragraph6ContractFormFields> = (data) => {
    const fiktiveStrommengeActive = data.has_fiktive_strommenge;

    if (!data.has_fiktive_strommenge) {
      data.beteiligung_fiktive_strommengen = null;
    }
    delete data.has_fiktive_strommenge;

    if (!data.has_split_beteiligung) {
      data.beteiligung_nicht_erstattungsfaehig = null;
    }
    delete data.has_split_beteiligung;
    if (data.is_template === undefined) {
      data.is_template = false;
    }

    data.community_iban = (data?.community_iban || "").replace(
      /[^a-zA-Z0-9]/g,
      ""
    );

    const mutation = editMode
      ? paragraph6ContractEditMutation
      : paragraph6ContractCreateMutation;

    mutation.mutate(data, {
      onSuccess: () => {
        onClose();
        if (fiktiveStrommengeActive) {
          const fiktiveMengenToast = showBannerToast("warning", {
            text: "Sie haben in Ihrem Vertrag angegeben, dass auch fiktive Strommengen berücksichtigt werden. Bitte fügen Sie nun die betroffenen Erzeuger hinzu und ergänzen Sie anschließend im Bereich Energiedaten die fiktiven Mengen für den im Vertrag angegebenen Zeitraum. Wenn Sie bereits alle Erzeuger hinzugefügt haben, können Sie auch direkt auf den untenstehenden Button klicken.",
            ctaText: "Fiktive Strommengen bereitstellen",
            ctaAction: () => {
              toast.dismiss(fiktiveMengenToast);
              navigate("../../../energiedaten/bereitstellen");
            }
          });
        }
      },
      onError: (error) => {
        setResponseError(error);
      }
    });
  };
  const [modalState, setModalState] = useState<TemplateModalState>(
    TemplateModalState.None
  );
  const [selectedTemplate, setSelectedTemplate] =
    useState<Partial<Paragraph6ContractPayload> | null>(null);

  function setAsTemplateAndRemoveNonCopyableFields(
    template: Paragraph6ContractPayload
  ) {
    setSelectedTemplate({
      ...template,
      is_template: false,
      name: "",
      id: undefined,
      erzeuger_anteile: []
    });
  }

  function modalInner() {
    const showContractForm =
      modalState === TemplateModalState.NewContract ||
      editMode ||
      selectedTemplate ||
      !templates ||
      templates.length === 0;
    if (showContractForm) {
      const isLoading =
        paragraph6CompaniesLoading || (editMode && paragraph6ContractLoading);

      return (
        <LoadOrError
          loading={isLoading}
          loadingMessage="Vertrag wird geladen ..."
        >
          <Paragraph6ContractForm
            isStaff={shouldShowStaffView}
            isSubmitting={isMutating}
            paragraph6Companies={paragraph6Companies ?? []}
            paragraph6Contract={
              paragraph6Contract ||
              selectedTemplate ||
              defaultParagraph6Contract
            }
            submissionError={responseError}
            onCancel={onClose}
            onSubmit={onSubmit}
          />
        </LoadOrError>
      );
    }

    if (modalState === TemplateModalState.None) {
      return <TemplateDecisionCards setModalState={setModalState} />;
    }

    function getItems() {
      if (!templates) return [];
      return templates.map(
        (template): SelectItem<Paragraph6ContractPayload> => {
          return { value: template, text: template.name, key: template.id };
        }
      );
    }

    function onChange(template: Paragraph6ContractPayload) {
      setAsTemplateAndRemoveNonCopyableFields(template);
    }

    return (
      <div className="contract-templates-list">
        <FormFieldLabel
          formFieldId="Paragraph6ContractModal_template"
          label="Referenzvertrag auswählen:"
        />
        <Select
          id="Paragraph6ContractModal_template"
          items={getItems()}
          onChange={onChange}
        />
        <FormFieldInfoText infoText="Wählen Sie einen Referenzvertrag aus, um das Formular im nächsten Schritt vorauszufüllen." />
      </div>
    );
  }

  function onClosed() {
    setModalState(TemplateModalState.None);
    setSelectedTemplate(null);
  }

  return (
    <Modal
      className="Paragraph6ContractModal"
      isOpen={isModalOpen}
      size="xl"
      onClosed={onClosed}
    >
      <ModalHeader toggle={onClose}>
        Vertrag {editMode ? "bearbeiten" : "hinzufügen"}
      </ModalHeader>
      <ModalBody>{modalInner()}</ModalBody>
    </Modal>
  );
}

export { Paragraph6ContractModal, Paragraph6ContractModalProps };
