import type { QueryClient } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

import api from "../../../api";
import urls from "../../../urls";
import type { MeterConfigurationData } from "../../../utils/backend-types";

export function useMeterParameters(configurationId?: number) {
  const { data, isLoading, isFetching, error, refetch } = useQuery({
    queryKey: ["meter-parameters", { configurationId }],
    queryFn: fetchMeterParameters,
    enabled: !!configurationId,
    refetchOnWindowFocus: false
  });

  async function fetchMeterParameters() {
    if (!configurationId) {
      return [];
    }

    const response = await api.get<MeterConfigurationData[]>(
      urls.api.meterParameters(configurationId)
    );
    return response.data;
  }

  return {
    meterParams: data,
    isMeterParamsLoading: isLoading,
    isMeterParamsFetching: isFetching,
    meterParamsError: error,
    refetchMeterParams: refetch
  };
}

export function updateMeterParametersCache(
  parameterId: number,
  newData: Partial<MeterConfigurationData>,
  queryClient: QueryClient,
  configurationId?: number
) {
  queryClient.setQueryData(
    ["meter-parameters", { configurationId }],
    (oldData: MeterConfigurationData[]) => {
      return oldData.map((data) =>
        data.id === parameterId ? { ...data, ...newData } : data
      );
    }
  );
}

export function invalidateMeterParametersCache(
  configurationId: number,
  queryClient: QueryClient
) {
  queryClient.invalidateQueries({
    queryKey: ["meter-parameters", { configurationId }]
  });
}
