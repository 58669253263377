import React from "react";
import { generatePath } from "react-router-dom";
import type { Column } from "react-table";
import { ROUTES } from "../../../../../routes";
import type { TypedCellInfo } from "../../../../CustomReactTable/CustomReactTable.types";
import type {
  LocationOverviewData,
  MarketOrMeteringLocationOverview
} from "../../LocationOverview.types";

function getSiteColumn() {
  return {
    Header: "Liegenschaften",
    id: "site",
    accessor: (row) => row.singleOverviews,
    Cell: (
      cellInfo: TypedCellInfo<
        Array<MarketOrMeteringLocationOverview>,
        LocationOverviewData
      >
    ) => (
      <div className="sites">
        {cellInfo.value.map((overview) => (
          <div className="sub-row" key={overview.location.id}>
            <Site
              id={overview.location.site.id}
              name={overview.location.site.name}
              projectId={overview.location.project}
            />
          </div>
        ))}
      </div>
    ),
    sortable: false,
    width: 220
  } satisfies Column<LocationOverviewData>;
}

interface SiteProps {
  projectId: string;
  id: number;
  name: string;
}

function Site({ projectId, id, name }: SiteProps) {
  const path = generatePath(ROUTES.managerVariantStructure, {
    projectId,
    siteId: id.toString()
  });

  return <a href={path}>{name}</a>;
}

export { getSiteColumn };
