import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useProjects } from "../../hooks/useProjects";
import { ErrorAlert } from "../BuildingBlocks/ErrorAlert/ErrorAlert";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { AnalyzerProjectList } from "./AnalyzerProjectList/AnalyzerProjectList";
import "./ProjectManager.scss";

const PERMISSION_ERROR = { response: { status: 403, data: "" } };

interface ProjectManagerProps {
  hasPermission: boolean;
}

function ProjectManager({ hasPermission }: ProjectManagerProps) {
  const queryClient = useQueryClient();
  const {
    isLoading,
    error,
    data: projects
  } = useProjects({
    refetchInterval: false,
    refetchOnWindowFocus: false
  });

  function handleReloadProjectsData(changedProjectId: string) {
    queryClient.invalidateQueries({
      queryKey: ["projects-list"]
    });

    if (changedProjectId) {
      queryClient.invalidateQueries({
        queryKey: [
          "project",
          {
            projectId: changedProjectId
          }
        ]
      });
    }
  }

  if (!hasPermission) {
    return <ErrorAlert error={PERMISSION_ERROR} />;
  }

  return (
    <LoadOrError error={error} loading={isLoading}>
      {projects && (
        <AnalyzerProjectList
          projects={projects}
          onReloadProjects={handleReloadProjectsData}
        />
      )}
    </LoadOrError>
  );
}

export { ProjectManager };
