/** Gets the number of days for a given `month`. **Warning**:
 * Leap years exception case has been excluded on purpose. */
export function getDaysOfMonth(month: number) {
  switch (month) {
    case 2:
      return 28;
    case 4:
    case 6:
    case 9:
    case 11:
      return 30;
    default:
      return 31;
  }
}
