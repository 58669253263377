import classNames from "classnames";
import type { ReactNode } from "react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getErrorText } from "../../utils/get-error-text";
import { Alert, AlertColor } from "../Alert/Alert";
import { openErrorAlertPopup } from "../ErrorAlertPopup/openErrorAlertPopup";
import { AnimatedLoadingIcon } from "../Icons/AnimatedLoadingIcon/AnimatedLoadingIcon";
import "./LoadOrError.scss";

interface LoadOrErrorProps {
  loading?: boolean;
  loadingMessage?: string;
  error?: unknown;
  errorRedirectTo?: string;
  customErrors?: Record<number, string>;
  className?: string;
  children?: ReactNode;
}

function LoadOrError({
  loading,
  loadingMessage,
  error,
  errorRedirectTo,
  customErrors,
  className,
  children
}: LoadOrErrorProps) {
  const navigate = useNavigate();

  useEffect(() => {
    if (error && errorRedirectTo) {
      openErrorAlertPopup(error, customErrors, () =>
        navigate(errorRedirectTo, { replace: true })
      );
    }
  }, [error, errorRedirectTo, customErrors, navigate]);

  if (loading) {
    return (
      <div className={classNames("LoadOrError", className)}>
        <AnimatedLoadingIcon />
        {loadingMessage && (
          <div className="loading-message">{loadingMessage}</div>
        )}
      </div>
    );
  } else if (error) {
    return (
      <Alert
        className={classNames("LoadOrError", className)}
        color={AlertColor.Danger}
      >
        {getErrorText(error)}
      </Alert>
    );
  }

  return <>{children}</>;
}

export { LoadOrError };
