import type { CurrentUser } from "../../../utils/backend-types";
import { loadScript } from "../../../utils/loadScript";

const MAX_RETRIES = 10;
let retries = 0;

/** Loads HubSpot tracking code, and identifies user. */
export async function loadHubSpotTracker(currentUser: CurrentUser) {
  await loadScript("//js-eu1.hs-scripts.com/25500712.js", "hs-script-loader");

  if (typeof window._hsq === "undefined") {
    if (retries < MAX_RETRIES) {
      setTimeout(() => loadHubSpotTracker(currentUser), 1000);
      retries++;
    } else {
      console.error("HubSpot tracking failed to load");
    }

    return;
  }

  window._hsq.push([
    "identify",
    {
      id: currentUser.id,
      user_type: currentUser.is_staff ? "internal" : "external"
    }
  ]);
}
