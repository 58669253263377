import { Center, Stack, Flex } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { type SiteCategoriesResponse } from "../../../utils/backend-types";
import { Section } from "../../BuildingBlocks/Layout/Section";
import { Button } from "../../Buttons/Button/Button";
import { SearchBar } from "../../SearchBar/SearchBar";
import { useShouldShowStaffView } from "../../StaffViewToggle/useShouldShowStaffView";
import { NotAccessableModal } from "../../StructureView/NotAccessableModal/NotAccessableModal";
import type { ProjectOverviewSite } from "../MsmProjectView.types";
import { SiteActionRow } from "./SiteActionRow/SiteActionRow";
import { SiteModal } from "./SiteModal";

interface SitesPageProps {
  sites: ReadonlyArray<ProjectOverviewSite>;
  onSiteCreated: (site: ProjectOverviewSite) => void;
  onSiteEdited: (site: ProjectOverviewSite) => void;
  onDeleteSite: (site: ProjectOverviewSite) => void;
  variantId: number;
  siteCategories: Array<SiteCategoriesResponse> | undefined;
}

function SitesPage({
  sites,
  onSiteCreated,
  onSiteEdited,
  onDeleteSite,
  variantId,
  siteCategories
}: SitesPageProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalCreateMode, setIsModalCreateMode] = useState(true);
  const [siteToEdit, setSiteToEdit] = useState<ProjectOverviewSite | null>(
    null
  );
  const [autoModalOpen, setAutoModalOpen] = useState(false);
  const [noAccessModalOpen, setNoAccessModalOpen] = useState(false);
  const shouldShowStaffView = useShouldShowStaffView();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirectedSiteId = searchParams.get("site");
  const [searchValue, setSearchValue] = useState("");

  function toggleModal() {
    setIsModalOpen((prev) => !prev);
  }

  function openCreateModal() {
    if (!shouldShowStaffView) {
      setNoAccessModalOpen(true);
    } else {
      setIsModalCreateMode(true);
      toggleModal();
    }
  }

  function openEditModal(site: ProjectOverviewSite) {
    setIsModalCreateMode(false);
    setSiteToEdit(site);
    toggleModal();
  }

  useEffect(() => {
    if (autoModalOpen) {
      return;
    }

    if (redirectedSiteId) {
      const site = sites?.find(
        (site) => site.id === parseInt(redirectedSiteId)
      );
      if (site) {
        setIsModalOpen(true);
        setIsModalCreateMode(false);
        setSiteToEdit(site);
        setAutoModalOpen(true);
      }
    }
  }, [autoModalOpen, redirectedSiteId, sites]);

  const filteredSites = searchValue
    ? sites.filter((site) =>
        site.name.toLowerCase().includes(searchValue.toLowerCase())
      )
    : sites;

  return (
    <div className="SitesPage">
      <Section>
        {sites.length === 0 && (
          <Center>
            <CreateSiteButton
              noAccess={!shouldShowStaffView}
              size="lg"
              onClickCreate={openCreateModal}
            />
          </Center>
        )}
        {sites.length > 0 && (
          <Stack>
            <Flex justify="space-between">
              <SearchBar
                btnHidden
                controlledValue={searchValue}
                placeholder="Suche nach Name"
                onChange={(event) => setSearchValue(event.currentTarget.value)}
                onClear={() => setSearchValue("")}
              />
              <CreateSiteButton
                noAccess={!shouldShowStaffView}
                onClickCreate={openCreateModal}
              />
            </Flex>
            <SitesList
              highlight={searchValue}
              siteCategories={siteCategories}
              sites={filteredSites}
              onClickEdit={openEditModal}
              onDelete={onDeleteSite}
            />
          </Stack>
        )}
        <SiteModal
          headerText={
            isModalCreateMode ? "Neue Liegenschaft" : "Liegenschaft bearbeiten"
          }
          isOpen={isModalOpen}
          site={!isModalCreateMode ? siteToEdit : null}
          variantId={variantId}
          onClickSave={isModalCreateMode ? onSiteCreated : onSiteEdited}
          onToggle={toggleModal}
        />
        {noAccessModalOpen && (
          <NotAccessableModal
            text={
              "Diese Option steht derzeit nicht zur Verfügung," +
              " da Sie nicht über die entsprechenden Berechtigungen verfügen." +
              " Bitte wenden Sie sich an das Support-Team: "
            }
            onClose={() => setNoAccessModalOpen(false)}
          />
        )}
      </Section>
    </div>
  );

  function CreateSiteButton({
    size,
    onClickCreate,
    noAccess,
    className
  }: {
    size?: string;
    onClickCreate: () => void;
    noAccess: boolean;
    className?: string;
  }) {
    return (
      <Button
        className={className}
        color={noAccess ? "secondary" : "brand"}
        id="create-site-button"
        size={size}
        onClick={onClickCreate}
      >
        Neue Liegenschaft
      </Button>
    );
  }
}

interface SitesListProps {
  sites: ReadonlyArray<ProjectOverviewSite>;
  siteCategories: ReadonlyArray<SiteCategoriesResponse> | undefined;
  onClickEdit: (site: ProjectOverviewSite) => void;
  onDelete: (site: ProjectOverviewSite) => void;
  highlight: string;
}

function SitesList({
  sites,
  siteCategories,
  onClickEdit,
  onDelete,
  highlight
}: SitesListProps) {
  return (
    <Stack align="stretch" gap="xs">
      {sites.map((site) => (
        <SiteActionRow
          highlight={highlight}
          isPremium={
            siteCategories?.find((category) => category.id === site.id)
              ?.is_premium ?? false
          }
          key={site.id}
          site={site}
          onClickEdit={onClickEdit}
          onDelete={onDelete}
        />
      ))}
    </Stack>
  );
}

export { SitesPage, SitesPageProps };
