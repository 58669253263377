import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Col, Row } from "reactstrap";

import { Portlet } from "../BuildingBlocks/Layout/Portlet";
import { Section } from "../BuildingBlocks/Layout/Section";
import MarketLocationComparison, {
  ValueCostsRow
} from "./MarketLocationComparison";
import { mergingResultPropType } from "./merging";

import "./MeteringConceptComparison.scss";

export class MeteringConceptComparison extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      displayDetails: {}
    };
  }

  renderMarketLocationsComparisonForTypes = (locationTypes) => {
    const {
      mergedMarketLocations,
      meteringConceptKeys,
      meteringConceptConfiguration
    } = this.props;

    const keysWithLocationTypeToRender = mergedMarketLocations.keys.filter(
      (key) =>
        locationTypes.includes(mergedMarketLocations.common[key].locationType)
    );

    return keysWithLocationTypeToRender.map((key) => (
      <MarketLocationComparison
        displayDetails={this.state.displayDetails[key]}
        key={key}
        marketLocationKey={key}
        mergedMarketLocations={mergedMarketLocations}
        meteringConceptConfiguration={meteringConceptConfiguration}
        meteringConceptKeys={meteringConceptKeys}
        onToggleDetails={() => this.handleToggleDetails(key)}
      />
    ));
  };

  handleToggleDetails = (marketLocationID) => {
    this.setState((state) => ({
      displayDetails: {
        ...state.displayDetails,
        [marketLocationID]: !state.displayDetails[marketLocationID]
      }
    }));
  };

  render() {
    const {
      locationSections,
      meteringConceptKeys,
      meteringConceptConfiguration,
      formatForReport,
      title,
      mergedPriceComponents,
      header
    } = this.props;

    const locationSectionsWithRenderedResultsComparison = locationSections.map(
      (section) => {
        section["rendered"] = this.renderMarketLocationsComparisonForTypes(
          section.locationTypes
        );
        return section;
      }
    );
    const minWidth = `${320 * meteringConceptKeys.length}px`;
    return (
      <div className="scrollable-main-content" id="concept-comparison-table">
        {header}
        <Row style={{ minWidth }}>
          <Col>
            <Portlet noPadding={formatForReport} title={title}>
              {locationSectionsWithRenderedResultsComparison.map((section) => {
                return (
                  <Section key={section.key}>
                    <SectionTitleRow
                      meteringConceptConfiguration={
                        meteringConceptConfiguration
                      }
                      meteringConceptKeys={meteringConceptKeys}
                      title={section.title}
                    />
                    <SectionHeaderRow
                      {...section}
                      meteringConceptConfiguration={
                        meteringConceptConfiguration
                      }
                      meteringConceptKeys={meteringConceptKeys}
                    />
                    {section.rendered}
                  </Section>
                );
              })}
              {mergedPriceComponents.keys.length > 0
                ? this.renderPriceComponentSection()
                : null}
            </Portlet>
          </Col>
        </Row>
      </div>
    );
  }

  renderPriceComponentSection() {
    const {
      meteringConceptKeys,
      meteringConceptConfiguration,
      mergedPriceComponents
    } = this.props;
    return (
      <Section>
        <SectionTitleRow
          meteringConceptConfiguration={meteringConceptConfiguration}
          meteringConceptKeys={meteringConceptKeys}
          title="Sonstiges"
        />
        <SectionHeaderRow
          meteringConceptConfiguration={meteringConceptConfiguration}
          meteringConceptKeys={meteringConceptKeys}
          sourceLabel="Bezeichnung"
        />
        {mergedPriceComponents.keys.map((priceComponentKey) => {
          return (
            <Row className="metering-concept-row" key={priceComponentKey}>
              <Col>{mergedPriceComponents.common[priceComponentKey].label}</Col>
              {meteringConceptKeys.map((meteringConceptKey) => {
                const priceComponent =
                  mergedPriceComponents.merged[priceComponentKey][
                    meteringConceptKey
                  ];
                return (
                  <Col key={meteringConceptKey}>
                    {priceComponent ? (
                      <ValueCostsRow
                        costs={priceComponent.yearlyCost}
                        quantity={priceComponent.quantity}
                      />
                    ) : null}
                  </Col>
                );
              })}
            </Row>
          );
        })}
      </Section>
    );
  }
}

MeteringConceptComparison.propTypes = {
  meteringConceptKeys: PropTypes.arrayOf(PropTypes.number).isRequired,
  meteringConceptConfiguration: PropTypes.objectOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      headerBackgroundColor: PropTypes.string,
      cellBackgroundColor: PropTypes.string
    })
  ).isRequired,
  mergedMarketLocations: mergingResultPropType,
  mergedPriceComponents: mergingResultPropType,
  yearlyAggregates: PropTypes.objectOf(
    PropTypes.shape({
      localDelivery: PropTypes.number,
      totalDemand: PropTypes.number
    })
  )
};

function ValueCostsColHeaders({ backgroundColor }) {
  return (
    <Col className="col-header" style={{ backgroundColor }}>
      <Row>
        <Col>
          <span className="float-right text-right">Menge [kWh]</span>
        </Col>
        <Col>
          <span className="float-right text-right">
            + Erlöse / - Kosten [€]
          </span>
        </Col>
      </Row>
    </Col>
  );
}

function FormulaColHeader({ backgroundColor }) {
  return (
    <Col className="col-header" style={{ backgroundColor }}>
      <Row>
        <Col>
          <span>Formel</span>
        </Col>
      </Row>
    </Col>
  );
}

function ConceptHeaderCol({ backgroundColor, label, ...other }) {
  return (
    <Col
      className="col-header m--font-light justify-content-center"
      style={{ textAlign: "center", backgroundColor }}
      {...other}
    >
      <span>{label}</span>
    </Col>
  );
}

function SectionTitle({ title }) {
  return (
    <Col
      className="col-header m--bg-brand m--font-light m--font-bolder"
      style={{
        textAlign: "center"
      }}
    >
      {title}
    </Col>
  );
}

export function SectionTitleRow({
  title,
  meteringConceptKeys,
  meteringConceptConfiguration
}) {
  return (
    <Row className="market-location-row-header">
      <SectionTitle title={title} />
      {meteringConceptKeys.map((meteringConceptKey) => (
        <ConceptHeaderCol
          backgroundColor={
            meteringConceptConfiguration[meteringConceptKey]
              .headerBackgroundColor
          }
          key={meteringConceptKey}
          label={meteringConceptConfiguration[meteringConceptKey].header}
        />
      ))}
    </Row>
  );
}

export function SectionHeaderRow({
  sourceLabel,
  targetLabel = "",
  meteringConceptKeys,
  meteringConceptConfiguration,
  displayFormulas
}) {
  return (
    <Row className="m--font-light">
      <Col className="col-header m--bg-brand">
        <Row>
          <Col>{sourceLabel}</Col>
          <Col>{targetLabel}</Col>
        </Row>
      </Col>
      {meteringConceptKeys.map((meteringConceptKey) =>
        displayFormulas ? (
          <FormulaColHeader
            backgroundColor={
              meteringConceptConfiguration[meteringConceptKey]
                .headerBackgroundColor
            }
            key={meteringConceptKey}
          />
        ) : (
          <ValueCostsColHeaders
            backgroundColor={
              meteringConceptConfiguration[meteringConceptKey]
                .headerBackgroundColor
            }
            key={meteringConceptKey}
          />
        )
      )}
    </Row>
  );
}
