import { useQuery } from "@tanstack/react-query";

import api from "../../../../api";
import urls from "../../../../urls";
import { queryWithPollResponse } from "../../../../utils/api-utils";
import type {
  WindDataSufficiencyResponse,
  MeterBasedDataSufficiencyResponse,
  NoDataSufficiencyResponse
} from "../EnergyDataAvailabilityPage/AvailabilityWidget/AvailabilityWidget";

export function useFetchDataSufficiencyAssessment(
  siteId: number,
  year: number
) {
  async function fetchDataSufficiencyAssessment(siteId: number, year: number) {
    const response = await queryWithPollResponse<
      | WindDataSufficiencyResponse
      | MeterBasedDataSufficiencyResponse
      | NoDataSufficiencyResponse
    >(
      (taskId?: string) =>
        api.get(urls.api.energyDataSufficiencyAssessment(siteId, year, taskId)),
      {
        queryAgainOnPollSuccess: true,
        queryAgainOnPollSuccessWithTaskId: true
      }
    );

    return response.data;
  }

  return useQuery({
    queryKey: ["data-sufficiency-assessment", { year, siteId }],
    queryFn: () => fetchDataSufficiencyAssessment(siteId, year),
    refetchInterval: false,
    refetchOnWindowFocus: false
  });
}
