import type { FREQUENCY_OPTIONS } from "../../../utils/constants";

export const DYNAMIC_FIELD_PARAMETERS = {
  company: "company",
  connection: "connection",
  consumer: "consumer",
  component: "component",
  description: "description",
  fromCompany: "fromCompany",
  generator: "generator",
  meter: "meter",
  rootMeter: "rootMeter",
  site: "site",
  supplied: "supplied",
  supplier: "supplier",
  toCompany: "toCompany"
} as const;

export type DynamicFieldParameters = typeof DYNAMIC_FIELD_PARAMETERS;
export type DynamicFieldParameter = keyof DynamicFieldParameters;
export type FrequencyOptions = keyof typeof FREQUENCY_OPTIONS;
