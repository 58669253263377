import type { WizardMissingData } from "../../utils/backend-types";

export interface Step {
  done: StepStatus;
  missingData: Array<WizardMissingData> | null;
  objectsAreMissing: boolean | null;
}

export enum StepStatus {
  open = "open",
  done = "done",
  notRelevant = "not_relevant"
}
