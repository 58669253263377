import React from "react";
import { SiteWithSetupInformationStatus } from "../../../../../types/api.types";
import { Button } from "../../../../Buttons/Button/Button";

export function ContinueEditingButton({
  status
}: {
  status?: SiteWithSetupInformationStatus | null;
}) {
  switch (status) {
    case SiteWithSetupInformationStatus.NotConfigured:
      return (
        <Button color="brand" outline>
          Daten erfassen
        </Button>
      );
    case SiteWithSetupInformationStatus.InProgress:
      return (
        <Button color="brand" outline>
          Fortschritt bearbeiten
        </Button>
      );
    default:
      return;
  }
}
