import { useQuery } from "@tanstack/react-query";
import api from "../api";
import { StructureViewMode } from "../components/StructureView/StructureView.constants";
import type { SketchData } from "../components/StructureViewDiagram/StructureViewDiagram";
import urls from "../urls";
import { DIAGRAM_QUERY_KEY } from "./useStructureViewFlowDiagramMutations";

function useStructureViewFlowDiagram(
  siteId: number,
  mode: Exclude<StructureViewMode, StructureViewMode.Old>
) {
  const { isLoading, error, data } = useQuery({
    queryKey: DIAGRAM_QUERY_KEY(mode, siteId),
    queryFn: () => fetchPremiumSketchData(siteId, mode)
  });

  async function fetchPremiumSketchData(
    siteId: number,
    mode: Exclude<StructureViewMode, StructureViewMode.Old>
  ) {
    const url =
      mode === StructureViewMode.MeteringConcept
        ? urls.api.meteringConceptSketchData(siteId)
        : urls.api.extendedSketchData(siteId);
    const response = await api.get<SketchData>(url);

    return response.data;
  }

  return {
    isLoading,
    error,
    data
  };
}

export { useStructureViewFlowDiagram };
