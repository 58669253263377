import React from "react";

import type { Generator } from "../../../../utils/backend-types";
import { ObjectName } from "../../../../utils/enums";
import { LoadOrError } from "../../../LoadOrError/LoadOrError";
import type { MaStRImportSuccessWidgetProps } from "../MaStRImportSuccessWidget/MaStRImportSuccessWidget";
import { MaStRImportSuccessWidget } from "../MaStRImportSuccessWidget/MaStRImportSuccessWidget";
import { useGetCreatedGeneratorsFromBatchImport } from "./hooks/useGetCreatedGeneratorsFromBatchImport";

interface GeneratorBatchImportSuccessWidgetProps
  extends Omit<
    MaStRImportSuccessWidgetProps<Generator>,
    "createdObjects" | "warnings" | "objectName"
  > {
  taskId: string;
}

function GeneratorBatchImportSuccessWidget({
  taskId,
  ...otherProps
}: GeneratorBatchImportSuccessWidgetProps) {
  const { data, isLoading, error } =
    useGetCreatedGeneratorsFromBatchImport(taskId);
  return (
    <LoadOrError error={error} loading={isLoading}>
      {data && (
        <MaStRImportSuccessWidget
          createdObjects={data.generators}
          lastStep
          objectName={ObjectName.Generator}
          warnings={data.warnings}
          {...otherProps}
        />
      )}
    </LoadOrError>
  );
}

export { GeneratorBatchImportSuccessWidget };
