import React, { useCallback, useMemo } from "react";
import type { Control, FieldErrors } from "react-hook-form";
import type {
  ActiveCompanies,
  Contract
} from "../../../../../../utils/backend-types";
import { FormFieldController } from "../../../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import type { Choice } from "../../../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { CONTRACT_FORM_FIELD_DATA } from "../ContractForm.constants";

interface SupplierAndSuppliedProps {
  activeCompanies?: ActiveCompanies;
  control: Control<Contract>;
  errors: FieldErrors<Contract>;
  selectedSite?: number;
  supplier: number | null;
  supplierChocies: Array<Choice<number>>;
  supplied: number | null;
  suppliedChoices: Array<Choice<number>>;
}

function SupplierAndSupplied({
  activeCompanies,
  control,
  errors,
  selectedSite,
  supplier,
  supplierChocies,
  supplied,
  suppliedChoices
}: SupplierAndSuppliedProps) {
  const filterChoices = useCallback(
    (choices: Array<Choice<number>>, companyIdToRemove: number | null) => {
      let newChoices: Array<Choice<number>> = [];

      if (activeCompanies && selectedSite) {
        const siteCompanies = activeCompanies.sites.find(
          (site) => site.id === selectedSite
        )?.activeCompanies;

        if (siteCompanies) {
          const siteCompanyChoices: Array<Choice<number>> = siteCompanies.map(
            (company) => ({
              displayName: company.name,
              value: company.id
            })
          );

          if (!companyIdToRemove) {
            return siteCompanyChoices;
          } else {
            newChoices = siteCompanyChoices.filter(
              (choice) => choice.value !== companyIdToRemove
            );
          }
        }
      } else {
        if (!companyIdToRemove) {
          return choices;
        }

        newChoices =
          choices.filter((choice) => choice.value !== companyIdToRemove) || [];
      }

      return newChoices;
    },
    [activeCompanies, selectedSite]
  );

  const filteredSupplierChoices = useMemo(() => {
    return filterChoices(supplierChocies, supplied);
  }, [filterChoices, supplierChocies, supplied]);

  const filteredSuppliedChoices = useMemo(() => {
    return filterChoices(suppliedChoices, supplier);
  }, [filterChoices, suppliedChoices, supplier]);

  return (
    <div className="SupplierAndSupplied">
      <FormFieldController
        control={control}
        data={{
          ...CONTRACT_FORM_FIELD_DATA.supplier,
          choices: filteredSupplierChoices
        }}
        error={errors.supplier}
      />
      <FormFieldController
        control={control}
        data={{
          ...CONTRACT_FORM_FIELD_DATA.supplied,
          choices: filteredSuppliedChoices
        }}
        error={errors.supplied}
      />
    </div>
  );
}

export { SupplierAndSupplied };
