import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import urls from "../../../urls";
import type {
  OAuthApplicationCreate,
  OAuthApplicationCreateResponse,
  OAuthApplicationResponse
} from "../ApiSettings.types";

export function useOAuthApplicationsMutations() {
  const queryClient = useQueryClient();

  const createMutation = useMutation({
    mutationFn: (newApplication: OAuthApplicationCreate) =>
      createOAuthApplication(newApplication),
    onMutate: handleCreateMutate,
    onSettled: handleSettled
  });

  const deleteMutation = useMutation({
    mutationFn: (applicationId: OAuthApplicationResponse["id"]) =>
      deleteOAuthApplication(applicationId),
    onSettled: handleSettled
  });

  async function handleCreateMutate() {
    await queryClient.cancelQueries({
      queryKey: ["oauthApplications"]
    });
  }

  function handleSettled() {
    return queryClient.invalidateQueries({
      queryKey: ["oauthApplications"]
    });
  }

  return {
    createMutation,
    deleteMutation
  };
}

function createOAuthApplication(newApplication: OAuthApplicationCreate) {
  const url = urls.apiWithoutCamelization.oauthApplications();
  return apiWithoutCamelization.post<OAuthApplicationCreateResponse>(
    url,
    newApplication
  );
}

function deleteOAuthApplication(applicationId: string) {
  const url = urls.apiWithoutCamelization.oauthApplication(applicationId);
  return apiWithoutCamelization.delete(url);
}
