import React from "react";

import type { FormattedEda } from "../../../EnergyData/common";
import { ORIGIN_LABEL_MAP, ORIGINS } from "../../../EnergyData/common";
import { EdaProperty } from "./EdaProperty/EdaProperty";
import { EdaTagsProperty } from "./EdaTagsProperty/EdaTagsProperty";

interface EdaOverviewProps {
  acquisition: FormattedEda;
}

function EdaOverview({ acquisition }: EdaOverviewProps) {
  return (
    <div className="overview">
      <EdaProperty header="ID" text={acquisition.publicId} />
      <EdaProperty header="Bezeichnung" text={acquisition.label} />
      <EdaProperty header="Medium" text={acquisition.medium} />
      <EdaProperty header="Typ" text={acquisition.type} />
      <EdaProperty
        header="Referenzen"
        text={acquisition.references.join(", ")}
      />
      <EdaTagsProperty header="Klassifizierung" tags={acquisition.tags} />
      <EdaProperty header="Herkunft" text={acquisition.origin} />
      {acquisition.origin === ORIGIN_LABEL_MAP[ORIGINS.ESTIMATION] && (
        <EdaProperty
          header="Erläuterung zum angewendeten Schätzverfahren"
          text={acquisition.statement}
        />
      )}
      <EdaProperty header="Details" text={acquisition.detailLabel} />
    </div>
  );
}

export { EdaOverview };
