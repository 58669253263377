/*global module, process*/
import _ from "lodash";
import PropTypes from "prop-types";

export function mergeByAccessors(obj, mergeBy, sortKeysBy) {
  const keyed = _.mapValues(obj, (m) => keyByAccessors(m, mergeBy));
  let allKeys = _.union(..._.values(keyed).map(_.keys));
  const mergedAndPossiblyOverwritten = Object.assign({}, ..._.values(keyed));

  const commonEntries = allKeys.reduce((obj, key) => {
    const obtainedFromAccessors = mergeBy.map((accessor) =>
      _.pick(mergedAndPossiblyOverwritten[key], accessor)
    );
    obj[key] = _.merge({}, ...obtainedFromAccessors);
    return obj;
  }, {});

  if (sortKeysBy) {
    allKeys = _.sortBy(allKeys, (key) => {
      return sortKeysBy.map((s) => _.get(commonEntries[key], s));
    });
  }

  const merged = transposeNestedObject(keyed);
  return {
    keys: allKeys,
    common: commonEntries,
    merged: merged
  };
}

export const mergingResultPropType = PropTypes.shape({
  keys: PropTypes.arrayOf(PropTypes.string).isRequired,
  common: PropTypes.objectOf(PropTypes.object).isRequired,
  merged: PropTypes.objectOf(PropTypes.object).isRequired
});

function keyByAccessors(arr, accessors) {
  return _.keyBy(arr, (entry) => {
    const valuesForComposedKey = accessors.map((accessor) =>
      _.get(entry, accessor, "\0")
    );
    return valuesForComposedKey.join("__");
  });
}

function transposeNestedObject(keyed) {
  // Transposes a two layer nested object.
  // The second level keys will become the first level keys and vice versa.
  // See tests for examples
  return _.keys(keyed).reduce((transposed, firstLevelKey) => {
    _.keys(keyed[firstLevelKey]).reduce((transposed, secondLevelKey) => {
      const assignTo = transposed[secondLevelKey]
        ? transposed[secondLevelKey]
        : {};
      transposed[secondLevelKey] = Object.assign(assignTo, {
        [firstLevelKey]: keyed[firstLevelKey][secondLevelKey]
      });
      return transposed;
    }, transposed);
    return transposed;
  }, {});
}

if (process.env.NODE_ENV === "test") {
  module.exports.keyByAccessors = keyByAccessors;
  module.exports.transposeNestedObject = transposeNestedObject;
}
