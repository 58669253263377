import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../../../api";
import urls from "../../../urls";
import type { Person } from "../../../utils/backend-types";
import { ObjectName } from "../../../utils/enums";
import { getPluralVariableNameFromObjectName } from "../../../utils/getPluralVariableNameFromObjectName";

export function usePersonMutation(
  mode: "create" | "edit",
  graphCoordinates: { x: number; y: number },
  variantId: number
) {
  const queryClient = useQueryClient();
  const cacheName = getPluralVariableNameFromObjectName(ObjectName.Person);

  return useMutation({
    mutationFn: (newData: Person) =>
      mutatePerson(newData, mode, graphCoordinates, variantId),
    mutationKey: [cacheName, { siteOrVariantId: variantId }],
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [cacheName, { siteOrVariantId: variantId }]
      });
    }
  });
}

function mutatePerson(
  newData: Person,
  mode: "create" | "edit",
  graphCoordinates: { x: number; y: number },
  variantId: number
) {
  if (mode === "create") {
    return createPerson(newData, graphCoordinates, variantId);
  }

  return editPerson(newData);
}

function createPerson(
  newPerson: Person,
  graphCoordinates: { x: number; y: number },
  variantId: number
) {
  const url = urls.api.personsByVariantId(variantId);

  return api.post(url, {
    ...newPerson,
    sketchElement: {
      xPosition: graphCoordinates.x,
      yPosition: graphCoordinates.y
    },
    variant: variantId
  });
}

function editPerson(editedPerson: Person) {
  const url = urls.api.person(editedPerson.id);
  return api.patch(url, editedPerson);
}
