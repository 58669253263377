import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

import api from "../api";
import urls from "../urls";
import type { ProjectMinimal } from "../utils/backend-types";

export function useProjects(
  options?: Partial<UseQueryOptions<Array<ProjectMinimal>>>
) {
  async function fetchProjects() {
    const response = await api.get<Array<ProjectMinimal>>(
      urls.api.projectsList()
    );
    return response.data;
  }

  const { data, error, isLoading } = useQuery({
    queryKey: ["projects-list"],
    queryFn: () => fetchProjects(),
    ...options
  });

  return { data, error, isLoading };
}
