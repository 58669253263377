import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import Plot from "react-plotly.js";
import { Col, Row } from "reactstrap";

import { Portlet } from "../../../BuildingBlocks/Layout/Portlet";
import { Section } from "../../../BuildingBlocks/Layout/Section";
import loader from "../../../Loader/Loader";

class EnergyFlowDisplay extends PureComponent {
  render() {
    const { formatForReport, producerTableHeader, consumerTableHeader, data } =
      this.props;

    const key = Object.keys(data)[0];
    const consumerPlots = data[key].consumers;
    const generatorPlots = data[key].generators;

    const portletStyle = { background: formatForReport ? "transparent" : null };
    const plotStyle = {
      width: formatForReport ? "525px" : "100%",
      height: formatForReport ? "355px" : null
    };
    const plotlyConfig = formatForReport ? { displayModeBar: false } : {};
    return (
      <Row>
        <Col md="6" style={{ paddingRight: formatForReport ? "0" : null }}>
          <Portlet
            noPadding={formatForReport}
            style={portletStyle}
            title={producerTableHeader}
          >
            {generatorPlots.map((graph, index) => {
              return (
                <Section key={`${graph.layout.title}-${index}`}>
                  <Plot
                    config={plotlyConfig}
                    data={graph.data}
                    layout={graph.layout}
                    style={plotStyle}
                    useResizeHandler={true}
                  />
                </Section>
              );
            })}
          </Portlet>
        </Col>
        <Col md="6" style={{ paddingLeft: formatForReport ? "0" : null }}>
          <Portlet
            noPadding={formatForReport}
            style={portletStyle}
            title={consumerTableHeader}
          >
            {consumerPlots.map((graph, index) => {
              return (
                <Section key={`${graph.layout.title}-${index}`}>
                  <Plot
                    config={plotlyConfig}
                    data={graph.data}
                    layout={graph.layout}
                    style={plotStyle}
                    useResizeHandler={true}
                  />
                </Section>
              );
            })}
          </Portlet>
        </Col>
      </Row>
    );
  }
}

EnergyFlowDisplay.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  formatForReport: PropTypes.bool.isRequired
};

export default loader(EnergyFlowDisplay);
