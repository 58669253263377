import React from "react";
import { getErrorText } from "../../../utils/get-error-text";
import { isApiError } from "../../../utils/isApiError";
import { Alert, AlertColor } from "../../Alert/Alert";
import { Button } from "../../Buttons/Button/Button";

interface ErrorAlertProps {
  error: unknown;
  onTryAgain?: () => void;
}

/** @deprecated Use IconAlert instead of ErrorAlert. If try again functionality is needed, implement it there. */
function ErrorAlert({ error, onTryAgain }: ErrorAlertProps) {
  const errorText = getErrorText(error);
  const showTryAgainButton =
    onTryAgain && (!isApiError(error) || error.response?.status === 404);

  return (
    <div>
      <Alert color={AlertColor.Danger}>{errorText}</Alert>
      {showTryAgainButton && (
        <Button onClick={onTryAgain}>Erneut versuchen</Button>
      )}
    </div>
  );
}

export { ErrorAlert };
