import React from "react";
import { AnimatedLoadingIcon } from "../../../Icons/AnimatedLoadingIcon/AnimatedLoadingIcon";

import "./MeterDataLoading.scss";

function MeterDataLoading() {
  return (
    <div className="MeterDataLoadingWrapper">
      <div className="MeterDataLoader">
        <AnimatedLoadingIcon />
      </div>

      <div className="MeterDataLoadingText">
        <h3 className="MeterDataLoadingHeader">Stammdaten werden abgerufen!</h3>
        <p>
          Die Stammdatensynchronisation kann mehrere Minuten dauern. Sie können
          das Fenster schließen und der Prozess läuft im Hintergrund.
        </p>
      </div>
    </div>
  );
}

export { MeterDataLoading };
