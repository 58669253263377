import { useQuery } from "@tanstack/react-query";
import api from "../../api";
import type { StartPageSite } from "../../components/StartPage/StartPage.types";
import urls from "../../urls";

function useStartPageSites(projectId?: string) {
  const { data, error, isLoading } = useQuery({
    queryKey: urls.startPage.sites.key(projectId),
    queryFn: () => fetchStartPageSites(projectId)
  });

  async function fetchStartPageSites(projectId?: string) {
    const response = await api.get<Array<StartPageSite>>(
      urls.startPage.sites.url(projectId)
    );
    return response.data;
  }

  return {
    sites: data,
    isLoading,
    error
  };
}

export { useStartPageSites };
