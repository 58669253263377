import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useId } from "react";
import { components } from "react-select";
import { Select } from "../../../../BuildingBlocks/Forms/Select/Select";
import { anchorTooltip, Tooltip } from "../../../../Tooltip/Tooltip";
import { ErrorText } from "../utils/ErrorText";
import "./Dropdown.scss";
import { MultiSelectDropdown } from "./MultiSelectDropdown/MultiSelectDropdown";
import { getGroupedOptionsFromChoices } from "./utils/getGroupedOptionsFromChoices";
import { getLabelFromChoice } from "./utils/getLabelFromChoice";
import { getOptionFromChoice } from "./utils/getOptionFromChoice";
import { getOptionsWithOptionalOption } from "./utils/getOptionsWithOptionalOption";
import { getSimpleOptionsFromChoices } from "./utils/getSimpleOptionsFromChoices";
import { groupDropdownOptions } from "./utils/groupDropdownOptions";

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
export default function Dropdown({
  id,
  className,
  name,
  value,
  defaultValue,
  choices = [],
  placeholder,
  multiselect,
  required,
  disabled,
  searchable,
  invalid,
  errorTexts,
  menuWidth,
  onChange
}) {
  const backupId = useId();
  const isGrouped =
    choices &&
    choices.length > 0 &&
    Object.prototype.hasOwnProperty.call(choices[0], "group");
  const commonProps = {
    id: id || backupId,
    name,
    value,
    defaultValue,
    choices,
    placeholder,
    required,
    isSearchable: searchable,
    isDisabled: disabled,
    invalid: invalid || (errorTexts && errorTexts?.length > 0),
    menuWidth,
    onChange
  };

  if (name === "tags") {
    return (
      <div className={classNames("Dropdown FormField", className)}>
        <TagsDropdown key={id} {...commonProps} />
        {errorTexts && errorTexts.map(ErrorText)}
      </div>
    );
  }
  if (isGrouped && !multiselect) {
    return (
      <div className={classNames("Dropdown FormField", className)}>
        <GroupedDropdown key={id} {...commonProps} />
        {errorTexts && errorTexts.map(ErrorText)}
      </div>
    );
  } else if (multiselect) {
    return (
      <div className={classNames("Dropdown FormField", className)}>
        <MultiSelectDropdown key={id} {...commonProps} isGrouped={isGrouped} />
        {errorTexts && errorTexts.map(ErrorText)}
      </div>
    );
  } else {
    return (
      <div className={classNames("Dropdown FormField", className)}>
        <SimpleDropdown key={id} {...commonProps} />
        {errorTexts && errorTexts.map(ErrorText)}
      </div>
    );
  }
}

Dropdown.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.bool)
  ]),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.bool)
  ]),
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
      ]),
      displayName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      isFixed: PropTypes.bool
    })
  ),
  multiselect: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  searchable: PropTypes.bool,
  invalid: PropTypes.bool,
  menuWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired
};

function SimpleDropdown({
  name,
  value,
  defaultValue,
  choices,
  required,
  onChange,
  ...otherProps
}) {
  function handleChangeDropdown(selected) {
    onChange(name, selected.value);
  }

  const hasTooltips =
    choices &&
    choices.length > 0 &&
    Object.prototype.hasOwnProperty.call(choices[0], "tooltipText");

  const options = hasTooltips
    ? getToolTipOptionsFromChoices(choices, !required)
    : getSimpleOptionsFromChoices(choices, !required);

  let formattedValue = undefined;
  if (value !== undefined) {
    const optionWithValue = options.find((option) => option.value === value);
    if (optionWithValue) {
      formattedValue = {
        value: value,
        label: optionWithValue.label
      };
    }
  }

  let formattedDefaultValue = null;
  if (defaultValue !== undefined) {
    const defaultOption = options.find(
      (option) => option.value === defaultValue
    );
    if (defaultOption) {
      formattedDefaultValue = {
        value: defaultValue,
        label: defaultOption.label
      };
    }
  }
  return (
    <Select
      {...otherProps}
      components={hasTooltips ? { Option: ToolTipOption } : {}}
      defaultValue={formattedDefaultValue}
      options={options}
      value={formattedValue}
      onChange={handleChangeDropdown}
    />
  );
}

function ToolTipOption(props) {
  const tooltipId = useId();
  return (
    <components.Option {...props}>
      <span
        {...anchorTooltip({
          content: props.data.tooltipText,
          place: "top",
          id: tooltipId
        })}
      >
        {props.data.label}
      </span>
      <Tooltip id={tooltipId}></Tooltip>
    </components.Option>
  );
}

function GroupedDropdown({
  name,
  defaultValue,
  value,
  choices,
  required,
  onChange,
  ...otherProps
}) {
  function handleChangeDropdown(selected) {
    onChange(name, selected.value);
  }
  let options = getGroupedOptionsFromChoices(choices, false);
  options = groupDropdownOptions(options);

  if (!required) {
    options = [
      {
        value: "",
        label: ""
      },
      ...options
    ];
  }

  let formattedDefaultValue = undefined;
  let formattedValue = undefined;

  if (typeof defaultValue !== "undefined") {
    const defaultChoiceWithGroup = choices.find(
      (choice) => choice.value === defaultValue
    );
    if (defaultChoiceWithGroup) {
      formattedDefaultValue = {
        value: defaultValue,
        label: getLabelFromChoice(defaultChoiceWithGroup),
        group: defaultChoiceWithGroup.group
      };
    }
  }

  if (typeof value !== "undefined") {
    const choiceWithGroup = choices.find((choice) => choice.value === value);
    if (choiceWithGroup) {
      formattedValue = {
        value: value,
        label: getLabelFromChoice(choiceWithGroup),
        group: choiceWithGroup.group
      };
    }
  }

  return (
    <Select
      {...otherProps}
      defaultValue={formattedDefaultValue}
      options={options}
      value={formattedValue}
      onChange={handleChangeDropdown}
    />
  );
}

function TagsDropdown({
  name,
  defaultValue,
  choices,
  required,
  onChange,
  ...otherProps
}) {
  function handleChangeDropdown(selected) {
    let value = [];
    if (selected !== null) {
      value = selected.map((option) => option.value);
    }
    onChange(name, value);
  }

  let options = choices.map((choice) => {
    return {
      value: choice.value,
      label: getLabelFromChoice(choice),
      shortName: choice.shortName,
      color: choice.color || "#e5e5e5",
      fontColor: choice.fontColor || "#000",
      group: choice.group
    };
  });

  let groupedOptions = groupDropdownOptions(options);

  const overridingStyles = {
    option: (styles, { data }) => ({
      ...styles,
      alignItems: "center",
      display: "flex",
      ":before": {
        backgroundColor: data.color,
        borderRadius: 10,
        content: '" "',
        display: "block",
        marginRight: 8,
        height: 10,
        width: 10,
        minWidth: 10
      }
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: data.color
      };
    },
    multiValueLabel: (styles, { data }) => {
      return {
        ...styles,
        color: data.fontColor
      };
    },
    multiValueRemove: (styles) => ({
      ...styles,
      ":hover": {
        color: "#ffffff"
      }
    })
  };

  let formattedValue = null;

  if (Array.isArray(defaultValue)) {
    formattedValue = defaultValue.reduce((values, item) => {
      const optionForItem = options.find((option) => option.value === item);
      if (optionForItem) {
        values.push({
          value: item,
          label: optionForItem.label,
          shortName: optionForItem.shortName,
          color: optionForItem.color,
          fontColor: optionForItem.fontColor,
          group: optionForItem.group
        });
      }

      return values;
    }, []);
  }

  const ShortNameValueLabel = (props) => {
    return (
      <React.Fragment>
        <components.MultiValueLabel {...props}>
          <span {...anchorTooltip({ content: props.data.label })}>
            {props.data.shortName}
          </span>
        </components.MultiValueLabel>
      </React.Fragment>
    );
  };

  return (
    <Select
      {...otherProps}
      closeMenuOnSelect={false}
      components={{ MultiValueLabel: ShortNameValueLabel }}
      defaultValue={formattedValue}
      isMulti
      options={groupedOptions}
      overridingStyles={overridingStyles}
      onChange={handleChangeDropdown}
    />
  );
}

function getToolTipOptionsFromChoices(choices, includeOptional) {
  let options = choices.map((choice) => {
    return {
      ...getOptionFromChoice(choice),
      tooltipText: choice.tooltipText
    };
  });

  return getOptionsWithOptionalOption(options, includeOptional);
}
