import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { useVariantSites } from "../../../../hooks/useVariantSites";
import { Portlet } from "../../../BuildingBlocks/Layout/Portlet";
import { TsDropdown } from "../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { LoadOrError } from "../../../LoadOrError/LoadOrError";
import { INITIAL_YEAR, YEAR_CHOICES_UNTIL_THIS_YEAR } from "../../common";
import { AvailabilityWidget } from "./AvailabilityWidget/AvailabilityWidget";

import "./EnergyDataAvailabilityPage.scss";

interface EnergyDataAvailabilityPageProps {
  variantId: number;
}

function EnergyDataAvailabilityPage({
  variantId
}: EnergyDataAvailabilityPageProps) {
  const { projectId } = useParams();

  const [year, setYear] = useState(INITIAL_YEAR);
  const { error, isLoading, sites } = useVariantSites(variantId);

  return (
    <div className="EnergyDataAvailabilityPage">
      <Portlet>
        <YearControls onChangeYear={setYear} />
      </Portlet>
      <LoadOrError error={error} loading={isLoading}>
        {sites.map((site) => (
          <AvailabilityWidget
            key={site.id}
            projectId={projectId}
            siteId={site.id}
            siteName={site.name}
            toggleInitial={sites.length === 1}
            year={year}
          />
        ))}
      </LoadOrError>
    </div>
  );
}

interface YearControlsProps {
  onChangeYear: (year: number) => void;
}

function YearControls({ onChangeYear }: YearControlsProps) {
  function handleDropdownChange(_, value: number): void {
    onChangeYear(value);
  }

  return (
    <div className="year-controls">
      <span className="label">Meldejahr</span>
      <TsDropdown
        choices={YEAR_CHOICES_UNTIL_THIS_YEAR}
        defaultValue={INITIAL_YEAR}
        id="energy-data-availability-year"
        name="energy-data-availability-year"
        required
        onChange={handleDropdownChange}
      />
    </div>
  );
}

export { EnergyDataAvailabilityPage, EnergyDataAvailabilityPageProps };
