import { Stepper } from "@mantine/core";
import React from "react";
import { type Control, type FieldErrors } from "react-hook-form";
import { type SiteSetupProcessDataStep } from "../../../types/api.types";
import { AddressFormItems } from "../Forms/FormItems/AddressFormItems";
import { ConnectionFormItems } from "../Forms/FormItems/ConnectionFormItems";
import { ConsumerFormItems } from "../Forms/FormItems/ConsumerFormItems";
import { MeterFormItems } from "../Forms/FormItems/MeterFormItems";
import { NameAndPvPlantsFormItems } from "../Forms/FormItems/NameAndPvPlantsFormItems";
import { TenantFormItems } from "../Forms/FormItems/TenantFormItems";
import { type SiteSetupProcessForForm } from "../SiteSetupAssistant.types";
import { getOrderFromStep } from "../utils/getOrderFromStep";
import { getStepFromOrder } from "../utils/getStepFromOrder";
import "./SiteSetupAssistantNavigation.scss";

interface SiteSetupAssistantNavigationProps {
  formControl: Control<SiteSetupProcessForForm>;
  formErrors: FieldErrors<SiteSetupProcessForForm>;
  step: SiteSetupProcessDataStep;
  onBlockNavigation: (blocked: boolean) => void;
  onChangeStep: (step: SiteSetupProcessDataStep) => void;
}

function SiteSetupAssistantNavigation({
  formControl,
  formErrors,
  step,
  onBlockNavigation,
  onChangeStep
}: SiteSetupAssistantNavigationProps) {
  const activeStep = getOrderFromStep(step);

  return (
    <Stepper
      active={activeStep}
      className="SiteSetupAssistantNavigation"
      contentPadding="xl"
      mt="xl"
      onStepClick={(newStepOrder) => {
        const newStep = getStepFromOrder(newStepOrder);
        if (newStep) {
          onChangeStep(newStep);
        }
      }}
    >
      <Stepper.Step label="Bezeichnung & PV-Anlage(n)">
        <NameAndPvPlantsFormItems
          formControl={formControl}
          formErrors={formErrors}
          onBlockNavigation={onBlockNavigation}
        />
      </Stepper.Step>
      <Stepper.Step label="Adresse und Art der Versorgung">
        <AddressFormItems formControl={formControl} formErrors={formErrors} />
      </Stepper.Step>
      <Stepper.Step label="Netzverknüpfungspunkt">
        <ConnectionFormItems />
      </Stepper.Step>
      <Stepper.Step label="Verbraucherdaten">
        <ConsumerFormItems />
      </Stepper.Step>
      <Stepper.Step label="Belieferte">
        <TenantFormItems />
      </Stepper.Step>
      <Stepper.Step label="Zählerdaten">
        <MeterFormItems />
      </Stepper.Step>
    </Stepper>
  );
}

export { SiteSetupAssistantNavigation, SiteSetupAssistantNavigationProps };
