import { toBlob } from "html-to-image";
import { DateTime } from "luxon";
import {
  getRectOfNodes,
  getTransformForBounds,
  type Instance
} from "reactflow";
import urls from "../../../urls";
import { type Site } from "../../../utils/backend-types";
import { uploadFileOrFiles } from "../../../utils/files/uploadFileOrFiles";
import { showToast } from "../../../utils/toast";
import { type NodeData } from "../../StructureViewDiagram/StructureViewDiagram";
import {
  STRUCTURE_VIEW_IMAGE_HEIGHT,
  STRUCTURE_VIEW_IMAGE_WIDTH
} from "../../StructureViewFlowDiagram/StructureViewFlowDiagram";
import { StructureViewMode } from "../StructureView.constants";

/* This function triggers the creation of the metering concept document for todo 3.1 */
function createMeteringConceptPdf(
  siteDetails: Site | undefined,
  siteWizardId: number,
  structureViewMode: StructureViewMode,
  updateStructureViewMode: (newValue: StructureViewMode) => void,
  getNodes: Instance.GetNodes<NodeData>
) {
  const modeSave = structureViewMode;
  if (modeSave !== StructureViewMode.MeteringConcept) {
    updateStructureViewMode(StructureViewMode.MeteringConcept);
  }
  // the timeout is needed to wait for the structure view to update
  setTimeout(() => {
    generateBlobImage(getNodes)
      .then((blob) => {
        if (!blob) {
          showToast("error", "Fehler bei der Ablage des Messkonzept-Reports");
        } else {
          const currentDate = DateTime.now().toFormat("yyyyLLdd_HHmmss");
          const image = new File(
            [blob],
            getFileName(siteDetails, currentDate, "pdf"),
            { type: blob.type }
          );

          uploadFileOrFiles(image, urls.api.mkExport(), "image", {
            uploadToTodo: true,
            site: siteWizardId
          });
        }
      })
      .catch(() => {
        showToast("error", "Fehler bei der Ablage des Messkonzept-Reports");
      })
      .finally(() => {
        if (modeSave !== StructureViewMode.MeteringConcept) {
          updateStructureViewMode(modeSave);
        }
      });
  }, 1000);
}

export function generateBlobImage(
  getNodes: Instance.GetNodes<NodeData>
): Promise<null | Blob> {
  const nodesBounds = getRectOfNodes(getNodes());
  //Sometimes the bounds are too small and names are cut off, so we increase the width by 15%
  nodesBounds.width += nodesBounds.width * 0.15;
  const transform = getTransformForBounds(
    nodesBounds,
    STRUCTURE_VIEW_IMAGE_WIDTH,
    STRUCTURE_VIEW_IMAGE_HEIGHT,
    0.5,
    2
  );
  const reactFlowViewport = document.querySelector<HTMLElement>(
    ".react-flow__viewport"
  );

  if (reactFlowViewport) {
    return toBlob(reactFlowViewport, {
      backgroundColor: "white",
      width: STRUCTURE_VIEW_IMAGE_WIDTH,
      height: STRUCTURE_VIEW_IMAGE_HEIGHT,
      quality: 1,
      style: {
        width: STRUCTURE_VIEW_IMAGE_WIDTH.toString(),
        height: STRUCTURE_VIEW_IMAGE_HEIGHT.toString(),
        transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`
      }
    });
  }

  return Promise.resolve(null);
}

export function getFileName(
  siteDetails: Site | undefined,
  currentDate: string,
  extension: "pdf" | "png"
) {
  return `${siteDetails?.name ?? "Unbekannt"}_${currentDate}.${extension}`;
}

export { createMeteringConceptPdf };
