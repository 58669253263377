import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import {
  usePerformanceMonitoringCreationDates,
  usePerformanceMonitoringOverview
} from "../../hooks/PerformanceMonitoringHooks";
import { luxonDateTimeToBackendDateOrDateTime } from "../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import { PageHeader } from "../BuildingBlocks/Layout/PageHeader/PageHeader";
import { Portlet } from "../BuildingBlocks/Layout/Portlet";
import type { Choice } from "../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { TsDropdown } from "../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import "./PerformanceMonitoring.scss";

interface PerformanceMonitoringProps {
  projectId: string;
}

function PerformanceMonitoring({ projectId }: PerformanceMonitoringProps) {
  const { creationDates, isLoading, error } =
    usePerformanceMonitoringCreationDates(projectId);
  const [selectedYear, setSelectedYear] = useState<number | null>(null);
  const [selectedDate, setSelectedDate] = useState<DateTime | null>(null);

  function getUniqueSortedYears(dates: Array<DateTime>) {
    const years = dates.map((date) => date.year);
    const uniqueYears = years.filter(
      (item, index, arr) => arr.indexOf(item) === index
    );
    return uniqueYears.sort().reverse();
  }

  function getSortedCreationDatesByYear(
    dates: Array<DateTime>,
    year: number
  ): Array<DateTime> {
    return dates
      .filter((date) => date.year === year)
      .sort()
      .reverse();
  }

  useEffect(() => {
    if (!!creationDates && creationDates?.length > 0) {
      if (selectedYear === null) {
        const initialYear = getUniqueSortedYears(creationDates)[0];
        setSelectedYear(initialYear);
      } else {
        const filteredDates = getSortedCreationDatesByYear(
          creationDates,
          selectedYear
        );
        setSelectedDate(filteredDates[0]);
      }
    }
  }, [creationDates, selectedYear]);

  const years = creationDates ? getUniqueSortedYears(creationDates) : undefined;
  const filteredDates =
    creationDates && selectedYear !== null
      ? getSortedCreationDatesByYear(creationDates, selectedYear)
      : undefined;

  return (
    <div className="PerformanceMonitoring">
      <PageHeader title="Performance Monitoring" />
      <LoadOrError error={error} loading={isLoading}>
        <div>
          {years && filteredDates && (
            <PerformanceMonitoringHeader
              dates={filteredDates}
              years={years}
              onDateSelected={setSelectedDate}
              onYearSelected={setSelectedYear}
            />
          )}
          {selectedDate && (
            <PerformanceMonitoringOverviewDisplay
              date={selectedDate}
              projectId={projectId}
            />
          )}
          {creationDates?.length === 0 && <NoDataComponent />}
        </div>
      </LoadOrError>
    </div>
  );
}

interface PerformanceMonitoringHeaderProps {
  years: Array<number>;
  dates: Array<DateTime>;
  onYearSelected: (year: number) => void;
  onDateSelected: (date: DateTime) => void;
}

function PerformanceMonitoringHeader({
  years,
  dates,
  onYearSelected,
  onDateSelected
}: PerformanceMonitoringHeaderProps) {
  const yearChoices: Array<Choice> = years.map((year) => {
    return {
      value: year,
      displayName: year
    };
  });

  const dateChoices: Array<Choice> = dates.map((date) => {
    return {
      value: date.toMillis(),
      displayName: luxonDateTimeToBackendDateOrDateTime(date)
    };
  });

  return (
    <Portlet>
      <div className="PerformanceMonitoringHeader">
        <strong>Jahr:</strong>
        <TsDropdown
          choices={yearChoices}
          defaultValue={yearChoices[0].value}
          disabled={yearChoices.length === 0}
          id="monitoring-year-selection"
          name="monitoring-year-selection"
          required
          onChange={(domName, newValue) => onYearSelected(newValue as number)}
        />
        <div className="PerformanceMonitoringDateSelection">
          <strong>Datum:</strong>
          <TsDropdown
            choices={dateChoices}
            defaultValue={dateChoices[0].value}
            disabled={dateChoices.length === 0}
            id="monitoring-year-selection"
            key={dateChoices[0].value as number}
            name="monitoring-year-selection"
            required
            onChange={(domName, newValue) =>
              onDateSelected(DateTime.fromMillis(newValue as number))
            }
          />
        </div>
      </div>
    </Portlet>
  );
}

interface PerformanceMonitoringOverviewDisplayProps {
  date: DateTime;
  projectId: string;
}

function PerformanceMonitoringOverviewDisplay({
  date,
  projectId
}: PerformanceMonitoringOverviewDisplayProps) {
  const {
    data: htmlData,
    isLoading,
    error
  } = usePerformanceMonitoringOverview(projectId, date);

  return (
    <LoadOrError error={error} loading={isLoading}>
      {htmlData && (
        <div
          className="PerformanceMonitoringOverviewDisplay"
          dangerouslySetInnerHTML={{ __html: htmlData }}
        />
      )}
    </LoadOrError>
  );
}

function NoDataComponent() {
  return (
    <div className="NoDataComponent">
      <p>Keine Performance Monitoring-Berichte vorhanden.</p>
    </div>
  );
}

export { PerformanceMonitoring };
