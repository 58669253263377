let canvas: HTMLCanvasElement | null = null;

/**
 * Returns the width of a text in pixels with given font if it would be rendered on a canvas.
 * Uses cached canvas element if possible, else create a new one.
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript
 */
export function getTextWidth(text: string, font: string) {
  if (!canvas) {
    canvas = document.createElement("canvas");
  }

  const context = canvas.getContext("2d");

  if (!context) {
    return 0;
  }

  context.font = font;
  const metrics = context.measureText(text);

  return metrics.width;
}
