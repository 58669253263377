import type { TFunction } from "i18next";
import type { FieldErrors, FieldPath } from "react-hook-form";
import { getErrorKeyFromFieldError } from "../../../BuildingBlocks/Forms/FormField/HelperText/utils/getErrorKeyFromFieldError";
import type { TypedCellInfo } from "../../../CustomReactTable/CustomReactTable.types";
import type { FictionalEnergyValueResponse } from "../EnergyDataUploadPage/FictionalEnergyValueManager/FictionalEnergyValueManager";

export function getErrorTextFromCellInfo<CellValue>(
  errors: FieldErrors<Array<FictionalEnergyValueResponse>>,
  cellInfo: TypedCellInfo<CellValue, FictionalEnergyValueResponse>,
  fieldName: FieldPath<FictionalEnergyValueResponse>,
  t: TFunction
) {
  const error = errors[cellInfo.index]?.[fieldName];
  const errorKey = error ? getErrorKeyFromFieldError(error) : undefined;
  const errorText = errorKey ? t(errorKey) : undefined;

  return errorText;
}
