import dayjs from "dayjs";

/** Parses a given string to a Date, using dayjs. */
export function parseManualDateInput(input: string) {
  return dayjs(input, [
    "D.M.YYYY",
    "D.MM.YYYY",
    "DD.M.YYYY",
    "DD.MM.YYYY"
  ]).toDate();
}
