import React, { useContext } from "react";

import { useCurrentUserQuery } from "../../hooks/useCurrentUserQuery";
import { ShowStaffViewContext } from "../App/ShowStaffViewContext";
import { ToggleSwitch } from "../BuildingBlocks/Forms/ToggleSwitch/ToggleSwitch";

import "./StaffViewToggle.scss";

interface StaffViewToggleProps {
  toggleShowStaffView: () => void;
}

function StaffViewToggle({ toggleShowStaffView }: StaffViewToggleProps) {
  const { currentUser } = useCurrentUserQuery();
  const isStaff = currentUser?.is_staff ?? false;
  const showStaffView = useContext<boolean | undefined>(ShowStaffViewContext);

  function handleClickStaffViewToggle(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
  }

  if (!isStaff || typeof showStaffView === "undefined") {
    return null;
  }

  const text = showStaffView ? "node.energy-Ansicht" : "Kundenansicht";

  return (
    <ToggleSwitch
      checked={showStaffView}
      className="StaffViewToggle"
      text={text}
      onChange={toggleShowStaffView}
      onClick={handleClickStaffViewToggle}
    />
  );
}

export { StaffViewToggle, StaffViewToggleProps };
