import { backendDateOrDateTimeToLuxonDateTime } from "../../../../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import type { ParkabrechnungPoolContract } from "../../../../Parkabrechnung.types";
import type { ParkabrechnungContractFormState } from "../ParkabrechnungContractModal.types";

/** Parses a ParkabrechnungPoolContract from Backend to a (partial) form state for use in Frontend. */
export function parseContractToFormState(
  contract: ParkabrechnungPoolContract
): Partial<ParkabrechnungContractFormState> {
  return {
    components:
      contract.gridFeedoutContract?.componentFeedoutShares.map(
        (share) => share.component.id
      ) ??
      contract.gridFeedinContract?.generatorFeedinShares?.map(
        (share) => share.generator.id
      ),
    endDate: backendDateOrDateTimeToLuxonDateTime(contract.end),
    feedinDistributionType: contract.gridFeedinContract?.shareType,
    feedinMeloMaloId:
      contract.gridFeedinContract?.generatorFeedinShares[0]
        .meteringOrMarketLocation.id,
    feedinPeriod: contract.gridFeedinContract?.billingPeriod,
    meloMaloId:
      contract.gridFeedoutContract?.componentFeedoutShares[0]
        .meteringOrMarketLocation.id,
    name: contract.name,
    period: contract.gridFeedoutContract?.billingPeriod,
    powerFeedoutPrice: contract.powerFeedoutContracts?.[0]?.price,
    site: contract.site.id,
    startDate: backendDateOrDateTimeToLuxonDateTime(contract.begin),
    ...contract.gridFeedoutContract?.componentFeedoutShares.reduce<
      Record<string, number>
    >((acc, share) => {
      acc[`share-${share.component.id}`] = share.share;
      return acc;
    }, {})
  };
}
