import { useQuery } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import urls from "../../../urls";
import type { SiteSetupProcessRaw } from "../SiteSetupAssistant.types";

function useSiteSetupAssistant(processId?: string) {
  const {
    isLoading: siteSetupAssistantLoading,
    error: siteSetupAssistantError,
    data: formData
  } = useQuery({
    queryKey: ["siteSetupAssistant", { processId }],
    queryFn: () => fetchSiteSetupAssistant(processId)
  });

  async function fetchSiteSetupAssistant(processId?: string) {
    if (typeof processId === "undefined") {
      return undefined;
    }
    const response = await apiWithoutCamelization.get<SiteSetupProcessRaw>(
      urls.apiWithoutCamelization.siteSetupAssistant.mieterStromData(processId)
    );
    return response.data;
  }

  return {
    isLoading: siteSetupAssistantLoading,
    error: siteSetupAssistantError,
    data: formData
  };
}

export { useSiteSetupAssistant };
