import React from "react";
import type { Todo } from "../../../../../utils/backend-types";
import { getErrorText } from "../../../../../utils/get-error-text";
import { AlertColor } from "../../../../Alert/Alert";
import { IconAlert } from "../../../../BuildingBlocks/IconAlert/IconAlert";
import { useTodoCanGenerateDocuments } from "../../../utils/useTodoCanGenerateDocuments";
import "./GenerateDocuments.scss";
import { GenerateDocumentsButton } from "./GenerateDocumentsButton/GenerateDocumentsButton";
import { GenerateProformaDocumentsButton } from "./GenerateDocumentsButton/GenerateProformaButton";

export interface TodoCanGenerateDocumentsResponse {
  canGenerateDocumentsAutomatically: boolean;
  canGenerateProformaDocuments: boolean;
  warnings: Array<string>;
  confirmationQuestion?: string;
  todoIdentifier?: string;
  documentGenerationBlocker?: DocumentGenerationBlocker;
}

export type DocumentGenerationBlocker = {
  missingData: MissingData;
  message: string;
};

type MissingData = {
  objectName: string;
  objectId: number;
  siteId: number;
  parameter: string;
};

interface GenerateDocumentsProps {
  todoId: number;
  todoLabel: string;
  storeTodo: (todo: Todo) => void;
  reloadTodo: () => void;
  handleErrorMessage: (message: string) => void;
}

function GenerateDocuments({
  todoId,
  todoLabel,
  storeTodo,
  reloadTodo,
  handleErrorMessage
}: GenerateDocumentsProps) {
  const { todoCanGenerateDocuments, todoCanGenerateDocumentsError } =
    useTodoCanGenerateDocuments(todoId, handleErrorMessage);

  const { canGenerateDocumentsAutomatically, canGenerateProformaDocuments } =
    todoCanGenerateDocuments ?? {};

  return (
    <div className="generate-documents">
      {todoCanGenerateDocumentsError && (
        <IconAlert color={AlertColor.Danger}>
          {getErrorText(todoCanGenerateDocumentsError)}
        </IconAlert>
      )}
      <div className="generate-documents-button-wrapper">
        <div className="generate-button">
          <GenerateDocumentsButton
            confirmationQuestion={
              todoCanGenerateDocuments?.confirmationQuestion
            }
            disabled={!canGenerateDocumentsAutomatically}
            todoId={todoId}
            todoLabel={todoLabel}
            onUpdateTodo={storeTodo}
          />
        </div>
        {!!canGenerateProformaDocuments && (
          <div className="generate-button">
            <GenerateProformaDocumentsButton
              disabled={!canGenerateProformaDocuments}
              reloadTodo={reloadTodo}
              todoId={todoId}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export { GenerateDocuments, GenerateDocumentsProps };
