import { FoerderRegime } from "../../../types/api.types";

/** Returns string label according to foederregime enum. Returns enum value in case of missing string. */
export function getFoerderregimeLabel(foederregime: FoerderRegime) {
  switch (foederregime) {
    case FoerderRegime.keine_foerderung_in_anspruch_genommen:
      return "Keine gesetzliche Förderung / Zuschläge in Anspruch genommen";
    case FoerderRegime.foerderung_ausgelaufen:
      return "Förderung ausgelaufen";
    case FoerderRegime.eeg_fixe_einspeiseverguetung:
      return "EEG fixe Einspeisevergütung";
    case FoerderRegime.eeg_ausschreibung:
      return "EEG Marktprämie - über Ausschreibung ermittelt";
    case FoerderRegime.eeg_ohne_ausschreibung:
      return "EEG Marktprämie - gesetzlich festgelegt (ohne Ausschreibung)";
    case FoerderRegime.eeg_innovationsausschreibung:
      return "EEG (fixe) Marktprämie - über Innovationsausschreibung ermittelt";
    case FoerderRegime.kwkg_zuschlag_2016_2020:
      return "Zuschlag gemäß KWKG 2016 oder KWKG 2020 (ohne Ausschreibung)";
    case FoerderRegime.kwkg_zuschlag_2012:
      return "Zuschlag gemäß KWKG 2012";
    case FoerderRegime.kwkg_ausschreibung:
      return "Zuschlag KWKG über Ausschreibung";
    case FoerderRegime.kwkg_zuschlag_2000_2002_2009:
      return "Zuschlag gemäß KWKG 2000, 2002 oder 2009";
    default:
      return foederregime;
  }
}
