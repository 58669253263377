import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import api from "../api";
import type { OperatorFeatures } from "../urls";
import urls from "../urls";
import type { Person } from "../utils/backend-types";

export function useOperators(
  args: {
    projectId?: string;
    variantId?: number;
    activeFeatures?: Array<OperatorFeatures>;
    withDetails?: boolean;
  },
  options?: Partial<UseQueryOptions<Array<Person>>>
) {
  const { data, isLoading, error } = useQuery({
    queryKey: ["operators", args],
    queryFn: () => fetchOperators(),
    ...options
  });

  async function fetchOperators() {
    const operatorsUrl = urls.api.operators(args);
    const response = await api.get<Array<Person>>(operatorsUrl);
    return response.data;
  }

  return {
    data,
    isLoading,
    error
  };
}
