import type { FictionalEnergyValueResponse } from "../EnergyDataUploadPage/FictionalEnergyValueManager/FictionalEnergyValueManager";

export function convertKwhNumberStringsToFloats(
  responseData: Array<FictionalEnergyValueResponse>
) {
  responseData.forEach((entry) => {
    entry.technischeNichtverfuegbarkeitKwh = parseFloat(
      entry.technischeNichtverfuegbarkeitKwh as unknown as string
    );
    entry.redispatchAbregelungKwh = parseFloat(
      entry.redispatchAbregelungKwh as unknown as string
    );
    entry.sonstigeAbschaltungOderDrosselungKwh = parseFloat(
      entry.sonstigeAbschaltungOderDrosselungKwh as unknown as string
    );
  });

  return responseData;
}
