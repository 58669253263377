/**
 * Converts a number to a german localized string
 * @param number The number to convert
 * @param currency [OPTIONAL] The currency to use
 * @returns The number as string, localized for Germany
 * @example
 * numberToGermanLocalizedString(1234.536, "EUR") // => 1.234,54 €
 *
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#options]
 */

const ROUNDING_OPTIONS = { maximumFractionDigits: 2, minimumFractionDigits: 2 };

export function numberToGermanLocalizedString(
  number: number,
  currency?: string
) {
  return new Intl.NumberFormat(
    "de-DE",
    currency
      ? {
          ...ROUNDING_OPTIONS,
          style: "currency",
          currency
        }
      : ROUNDING_OPTIONS
  ).format(number);
}
