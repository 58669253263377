import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, FormGroup } from "reactstrap";

import { SpinButton } from "../Buttons/SpinButton/SpinButton";
import { FormItem } from "../DynamicForm/FormItem/FormItem";
import { FormFieldType } from "../DynamicForm/FormItems/FormField/FormField";
import { PasswordResetLayout } from "../Layout/PasswordResetLayout";
import { PublicLayout } from "../Layout/PublicLayout";
import { useSetPass } from "./hooks/useSetPass";
import { PasswordRequirementsList } from "./PasswordRequirementsList";
import { setPasswordConfirm } from "./utils/setPasswordConfirm";

function SetPasswordConfirm() {
  const [newPass, setNewPass] = useState<string>("");
  const [newPassConfirm, setNewPassConfirm] = useState<string>("");

  const {
    handleSetPass,
    isSettingPass,
    isPasswordSet,
    errors,
    isChecked,
    validationErrors,
    isInvalidPass
  } = useSetPass({
    newPass,
    newPassConfirm,
    onPasswordSet: setPasswordConfirm
  });

  return (
    <PublicLayout>
      <PasswordResetLayout
        description={
          isPasswordSet ? (
            <>
              Ihr Passwort wurde gesetzt. Sie können sich jetzt mit dem Passwort{" "}
              <Link to="/login">einloggen</Link>.
            </>
          ) : (
            "Bitte wählen Sie das Passwort, mit dem Sie sich anmelden möchten:"
          )
        }
        title={isPasswordSet ? "Passwort gesetzt" : "Passwort setzen"}
      >
        {!isPasswordSet && (
          <Form className="PasswordResetForm" onSubmit={handleSetPass}>
            <FormGroup className="form-group">
              <FormItem
                initialValue=""
                invalid={isInvalidPass}
                label="Neues Passwort"
                name="newPass"
                required
                type={FormFieldType.Obfuscated}
                value={newPass}
                onInput={(_, value: string) => setNewPass(value)}
              />

              <PasswordRequirementsList
                isChecked={isChecked}
                validationErrors={validationErrors}
              />

              <FormItem
                errors={
                  validationErrors.passwordsDoNotMatch
                    ? ["Die eingegebenen Passwörter stimmen nicht überein."]
                    : []
                }
                initialValue=""
                invalid={isChecked && validationErrors.passwordsDoNotMatch}
                label="Neues Passwort bestätigen"
                name="newPassConfirm"
                required
                type={FormFieldType.Obfuscated}
                value={newPassConfirm}
                onInput={(_, value: string) => setNewPassConfirm(value)}
              />

              {errors}

              <div className="SubmitBtnWrapper">
                <SpinButton
                  className="PasswordSubmitBtn"
                  color="brand"
                  disabled={!newPass && !newPassConfirm}
                  spin={isSettingPass}
                >
                  Passwort setzen
                </SpinButton>
              </div>
            </FormGroup>
          </Form>
        )}
      </PasswordResetLayout>
    </PublicLayout>
  );
}

export { SetPasswordConfirm };
