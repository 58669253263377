import React from "react";
import { useFeatureFlags } from "../../../../hooks/useFeatureFlags";
import { Flag } from "../../../../hooks/useFeatureFlags.types";
import type { ProjectOverviewSite } from "../../MsmProjectView.types";
import { SiteWithHover } from "./SiteWithHover";
import { SiteWithSetupProcess } from "./SiteWithSetupProcess/SiteWithSetupProcess";

interface SiteActionRowProps {
  site: ProjectOverviewSite;
  isPremium: boolean;
  onDelete: (site: ProjectOverviewSite) => void;
  onClickEdit: (site: ProjectOverviewSite) => void;
  highlight: string;
}

function SiteActionRow({
  site,
  isPremium,
  onDelete,
  onClickEdit,
  highlight
}: SiteActionRowProps) {
  const { featureFlags } = useFeatureFlags();
  if (featureFlags[Flag.siteSetupAssistant]) {
    return (
      <SiteWithSetupProcess
        highlight={highlight}
        isPremium={isPremium}
        key={site.id}
        site={site}
        onClickEdit={onClickEdit}
        onDelete={onDelete}
      />
    );
  } else {
    // Old display with a hover effect. This will be replaced completely by the new display once
    // the feature flag is removed and the new feature is approved.
    return (
      <SiteWithHover
        highlight={highlight}
        isPremium={isPremium}
        key={site.id}
        name={site.name}
        siteId={site.id}
        onClickDelete={() => onDelete(site)}
        onClickEdit={() => onClickEdit(site)}
      />
    );
  }
}

export { SiteActionRow };
