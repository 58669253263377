import { DateTime } from "luxon";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import type { ChangeablePrice } from "../../../../DynamicForm/FormItems/FormField/ChangeablePriceFieldStatic/ChangeablePriceFieldStatic";
import type { ChangeableSpotMarketFormula } from "../../../../DynamicForm/FormItems/FormField/ChangeableSpotMarketFormulaFieldStatic/ChangeableSpotMarketFormulaFieldStatic";

/**
 * Takes an array of date range objects
 * and returns the object with the daterange that
 * is valid for the current date
 */
export function getCurrentDateRangeObject<
  T extends ChangeablePrice | ChangeableSpotMarketFormula
>(objects: Array<T>) {
  const now = DateTime.now();

  const object = objects.find((object) => {
    const firstDate = object.firstDate
      ? backendDateOrDateTimeToLuxonDateTime(object.firstDate)
      : null;
    const lastDate = object.lastDate
      ? backendDateOrDateTimeToLuxonDateTime(object.lastDate)
      : null;

    const firstDateInRange = !firstDate || firstDate <= now;
    const lastDateInRange = !lastDate || now <= lastDate;

    return firstDateInRange && lastDateInRange;
  });
  return object;
}
