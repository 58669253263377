import React from "react";
import { RoundedNumberFormat } from "../../../../utils/RoundedNumberFormat";

interface MantineRoundedNumberCellProps {
  decimalScale?: number;
  unit?: string;
  value: number;
}

function MantineRoundedNumberCell({
  decimalScale = 2,
  unit,
  value
}: MantineRoundedNumberCellProps) {
  return (
    <RoundedNumberFormat
      decimalScale={decimalScale}
      decimalSeparator=","
      displayType="text"
      suffix={unit}
      thousandSeparator="."
      value={value}
    />
  );
}

export { MantineRoundedNumberCell, MantineRoundedNumberCellProps };
