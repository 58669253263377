import { Pagination, Select } from "@mantine/core";
import React from "react";
import "./MantinePagination.scss";

const PAGE_SIZE_OPTIONS = [
  "5",
  "10",
  "20",
  "25",
  "50",
  "100"
] as const satisfies Array<string>;

interface MantinePaginationProps {
  page: number;
  pageSize: number;
  totalPages: number;
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number | null) => void;
}

function MantinePagination({
  page,
  pageSize,
  totalPages,
  onChangePage,
  onChangePageSize
}: MantinePaginationProps) {
  return (
    <div className="MantinePagination">
      <Select
        allowDeselect={false}
        data={PAGE_SIZE_OPTIONS}
        label="Zeilen pro Seite"
        value={pageSize.toString()}
        onChange={(option) =>
          onChangePageSize(option ? parseInt(option) : null)
        }
      />
      <Pagination
        total={totalPages}
        value={page}
        withEdges={true}
        onChange={onChangePage}
      />
    </div>
  );
}

export { MantinePagination, MantinePaginationProps };
