import React from "react";
import { AlertColor } from "../../Alert/Alert";
import { Accordion } from "../../BuildingBlocks/Accordion/Accordion";
import { IconAlert } from "../../BuildingBlocks/IconAlert/IconAlert";
import "./DataWarningAlert.scss";

interface DataWarningAlertProps {
  objectNames: Array<string>;
}

function DataWarningAlert({ objectNames }: DataWarningAlertProps) {
  return (
    <IconAlert className="DataWarningAlert" color={AlertColor.Warning}>
      <div>
        Überprüfen Sie die Verbrauchsanteile für folgende Zähler:
        {objectNames.length === 1 && <span> {objectNames[0]}</span>}
      </div>
      {objectNames.length > 1 && (
        <Accordion header={<span>Zähler-Liste</span>}>
          <ul className="object-list">
            {objectNames?.map((objectName) => (
              <li key={objectName}>{objectName}</li>
            ))}
          </ul>
        </Accordion>
      )}
    </IconAlert>
  );
}

export { DataWarningAlert, DataWarningAlertProps };
