import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import api from "../../../../api";
import type { Component, Person } from "../../../../utils/backend-types";
import { ObjectName } from "../../../../utils/enums";
import { getObjectDetailUrl } from "../../../../utils/getObjectDetailUrl";
import { getPluralVariableNameFromObjectName } from "../../../../utils/getPluralVariableNameFromObjectName";
import { DeleteConfirmationModal } from "../../../BuildingBlocks/Layout/Modals/DeleteConfirmationModal/DeleteConfirmationModal";
import "./ComponentDeleteModal.scss";

interface ComponentWithSiteId extends Component {
  site: number;
}

interface ComponentDeleteModalProps {
  component: Component;
  objectName: ObjectName;
  onToggle: () => void;
  onDeleteSuccess: () => void;
}

function ComponentDeleteModal({
  component,
  objectName,
  onToggle,
  onDeleteSuccess
}: ComponentDeleteModalProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const queryClient = useQueryClient();

  async function confirmDelete() {
    setLoading(true);
    setError(null);

    const url = getObjectDetailUrl(objectName, component.id);

    try {
      await api.delete(url);
    } catch (error) {
      setError(error);
      return;
    } finally {
      setLoading(false);
    }

    const objectCacheName = getPluralVariableNameFromObjectName(objectName);

    if (objectName === ObjectName.Person) {
      queryClient.invalidateQueries({
        queryKey: [
          objectCacheName,
          {
            siteOrVariantId: (component as Person).variant
          }
        ]
      });
    } else {
      queryClient.invalidateQueries({
        queryKey: [
          objectCacheName,
          {
            siteOrVariantId: (component as ComponentWithSiteId).site
          }
        ]
      });
    }

    onDeleteSuccess();
  }

  return (
    <DeleteConfirmationModal
      error={error}
      headerName={objectName}
      isLoading={loading}
      isModalOpen={true}
      name={component.name}
      toggleModal={onToggle}
      onClickConfirm={confirmDelete}
    />
  );
}

export { ComponentDeleteModal };
