import api from "../../../api";
import { PUBLIC_ROUTES } from "../../../routes";
import urls from "../../../urls";
import type { RefreshSuccess } from "./login";

export const refresh = async () => {
  try {
    const {
      data: { access }
    } = await api.publicRequest<RefreshSuccess>("POST", urls.auth.refresh());

    api.setAccessToken(access);

    return true;
  } catch (e) {
    if (e.code && e.code !== "ERR_NETWORK") {
      location.replace(`${PUBLIC_ROUTES.login}?redirect=${location.pathname}`);
    }

    return false;
  }
};
