import {
  ActionIcon,
  type ComboboxItem,
  Flex,
  Group,
  rem,
  Select,
  Space,
  Title
} from "@mantine/core";
import { MonthPickerInput } from "@mantine/dates";
import { DateTime } from "luxon";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePersonGenerators } from "../../../hooks/usePersonGenerators";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { AlertColor } from "../../Alert/Alert";
import { Icon } from "../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../BuildingBlocks/Icon/types";
import { IconAlert } from "../../BuildingBlocks/IconAlert/IconAlert";
import { LoadOrError } from "../../LoadOrError/LoadOrError";
import { usePersonEnergyRevenuesData } from "../hooks/usePersonEnergyRevenuesData";
import type { RevenueMonitoringCompanies } from "../RevenueMonitoring.types";
import { EnergyRevenuesCharts } from "./EnergyRevenuesCharts/EnergyRevenuesCharts";
import "./EnergyRevenuesPage.scss";
import { EnergyRevenuesTable } from "./EnergyRevenuesTable/EnergyRevenuesTable";
import { getMinOrMaxDateFromEnergyRevenues } from "./utils/getMinOrMaxDateFromEnergyRevenues";

interface EnergyRevenuesPageProps {
  companies: Array<RevenueMonitoringCompanies>;
}

function EnergyRevenuesPage({ companies }: EnergyRevenuesPageProps) {
  const { t } = useTranslation();
  const companyOptions = companies.map<ComboboxItem>((company) => ({
    label: company.name,
    value: company.id.toString()
  }));
  const [view, setView] = useState<"chart" | "table">("chart");
  const [selectedCompany, setSelectedCompany] = useState<
    ComboboxItem | undefined
  >(companyOptions[0]);
  const [selectedMonthAndYearRange, setSelectedMonthAndYearRange] = useState<
    [Date | null, Date | null]
  >([
    DateTime.now().startOf("year").toJSDate(),
    DateTime.now().startOf("month").toJSDate()
  ]);

  const {
    energyRevenuesData,
    isLoading: isEnergyRevenuesDataLoading,
    error: energyRevenuesDataError
  } = usePersonEnergyRevenuesData(
    selectedCompany ? Number(selectedCompany.value) : undefined
  );

  const {
    data: personGenerators,
    error: personGeneratorsError,
    isLoading: isLoadingPersonGenerators
  } = usePersonGenerators(
    selectedCompany ? Number(selectedCompany.value) : undefined
  );

  const selectedEnergyRevenuesData = useMemo(() => {
    return energyRevenuesData?.filter((energyRevenues) => {
      const revenuesDate = backendDateOrDateTimeToLuxonDateTime(
        energyRevenues.period_start
      ).toJSDate();

      return (
        (selectedMonthAndYearRange[0] === null ||
          revenuesDate >= selectedMonthAndYearRange[0]) &&
        (selectedMonthAndYearRange[1] === null ||
          revenuesDate <= selectedMonthAndYearRange[1])
      );
    });
  }, [energyRevenuesData, selectedMonthAndYearRange]);

  return (
    <div className="EnergyRevenuesPage">
      <Flex justify="flex-end">
        <ActionIcon.Group>
          <ActionIcon
            aria-label="Charts"
            size="lg"
            variant={view === "chart" ? "filled" : "default"}
            onClick={() => setView("chart")}
          >
            <Icon name={IconName.AreaChart} style={{ fontSize: rem(20) }} />
          </ActionIcon>
          <ActionIcon
            aria-label="Tabelle"
            size="lg"
            variant={view === "table" ? "filled" : "default"}
            onClick={() => setView("table")}
          >
            <Icon name={IconName.Table} style={{ fontSize: rem(20) }} />
          </ActionIcon>
        </ActionIcon.Group>
      </Flex>
      <Title order={4}>Unternehmensübersicht</Title>
      <Space h="md" />
      <Group>
        <Select
          allowDeselect={false}
          className="person-select"
          data={companyOptions}
          disabled={companies.length === 0}
          label="Unternehmen"
          placeholder="Unternehmen auswählen"
          searchable
          value={selectedCompany?.value}
          onChange={(_, option) => setSelectedCompany(option)}
        />
        <Space w="md" />
        <MonthPickerInput
          allowSingleDateInRange
          clearable
          disabled={energyRevenuesData?.length === 0}
          label="Berechneter Zeitraum"
          maxDate={getMinOrMaxDateFromEnergyRevenues(
            energyRevenuesData ?? [],
            "max"
          )?.toJSDate()}
          minDate={getMinOrMaxDateFromEnergyRevenues(
            energyRevenuesData ?? [],
            "min"
          )?.toJSDate()}
          placeholder="Monate auswählen"
          type="range"
          value={selectedMonthAndYearRange}
          onChange={setSelectedMonthAndYearRange}
        />
      </Group>

      <Space h="lg" />

      <LoadOrError
        error={energyRevenuesDataError}
        loading={isEnergyRevenuesDataLoading}
      >
        {selectedEnergyRevenuesData && companies.length > 0 ? (
          <>
            {view === "chart" && (
              <EnergyRevenuesCharts
                energyRevenuesData={selectedEnergyRevenuesData}
                generators={personGenerators}
                generatorsAreLoading={isLoadingPersonGenerators}
                generatorsError={personGeneratorsError}
              />
            )}
            {view === "table" && (
              <EnergyRevenuesTable
                energyRevenuesData={selectedEnergyRevenuesData}
              />
            )}
          </>
        ) : (
          <IconAlert color={AlertColor.Danger} icon="error">
            {t(
              companies.length === 0
                ? "errors.RevenueMonitoring.NoCompaniesError"
                : "errors.UnknownError"
            )}
          </IconAlert>
        )}
      </LoadOrError>
    </div>
  );
}

export { EnergyRevenuesPage, EnergyRevenuesPageProps };
