import { BarChart, ChartTooltip } from "@mantine/charts";
import { Card, Group, Space, Text, Title } from "@mantine/core";
import React from "react";
import { numericFormatter } from "react-number-format";
import { NoChartDataWrapper } from "../../../../../mantine/charts/components/NoChartDataWrapper/NoChartDataWrapper";
import { RoundedNumberFormat } from "../../../../../utils/RoundedNumberFormat";
import type { EnergyRevenuesListItem } from "../../../RevenueMonitoring.types";

const SERIES = [
  {
    label: "Direktvermarktung in kWh",
    name: "direktvermarktung_volume",
    color: "#118EB0"
  },
  {
    label: "Marktprämie in kWh",
    name: "market_premium_volume",
    color: "#42B8B2"
  }
];

const UNIT = " kWh";

interface EnergyVolumeOverviewChartProps {
  formattedData: Array<
    Pick<
      EnergyRevenuesListItem,
      "direktvermarktung_volume" | "market_premium_volume" | "period_start"
    >
  >;
  totals: {
    direktvermarktung_volume: number;
    market_premium_volume: number;
  };
}

function EnergyVolumeOverviewChart({
  formattedData,
  totals
}: EnergyVolumeOverviewChartProps) {
  return (
    <Card withBorder>
      <Title order={5}>Strommengenübersicht</Title>
      <Text>
        Hier sehen Sie die erzeugten Strommengen sowie die Mengen, für die Sie
        Anrecht auf Marktprämie haben.
      </Text>
      <Space h="xl" />
      <Group align="center" wrap="nowrap">
        <NoChartDataWrapper noData={formattedData.length === 0}>
          <BarChart
            data={formattedData}
            dataKey="period_start"
            h={600}
            series={SERIES}
            tooltipProps={{
              content: ({ label, payload }) => (
                <ChartTooltip
                  label={label}
                  payload={payload}
                  series={SERIES}
                  unit={UNIT}
                  valueFormatter={(value) =>
                    numericFormatter(value.toString(), {
                      decimalScale: 2,
                      decimalSeparator: ",",
                      thousandSeparator: "."
                    })
                  }
                />
              )
            }}
            unit={UNIT}
            valueFormatter={(value) =>
              numericFormatter((value / 1000).toString(), {
                decimalScale: 0,
                decimalSeparator: ",",
                suffix: "k",
                thousandSeparator: "."
              })
            }
            withLegend
          />
        </NoChartDataWrapper>
        <Card className="sidebar" withBorder>
          <Text c="dimmed" size="sm">
            Direktvermarktung
          </Text>
          <Text>
            <RoundedNumberFormat
              decimalScale={0}
              displayType="text"
              suffix={UNIT}
              value={totals.direktvermarktung_volume}
            />
          </Text>
          <Space h="md" />
          <Text c="dimmed" size="sm">
            Marktprämie
          </Text>
          <Text>
            <RoundedNumberFormat
              decimalScale={0}
              displayType="text"
              suffix={UNIT}
              value={totals.market_premium_volume}
            />
          </Text>
        </Card>
      </Group>
    </Card>
  );
}

export { EnergyVolumeOverviewChart, EnergyVolumeOverviewChartProps };
