import api from "../../../api";
import urls from "../../../urls";

interface UpdateOrCreateMeterParametersRes {
  taskStatusUrl: string;
  taskIds: Array<string>;
}

export async function updateOrCreateMeterParameters(configurationId: number) {
  const response = await api.post<UpdateOrCreateMeterParametersRes>(
    urls.api.updateOrCreateMeterParameters(configurationId)
  );
  return response.data;
}
