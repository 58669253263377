import { useQuery } from "@tanstack/react-query";
import type { UseQueryOptions } from "@tanstack/react-query";

import api from "../api";
import urls from "../urls";
import type { ActiveCompanies } from "../utils/backend-types";

export function useActiveCompanies(
  variantId: number,
  options?: Partial<UseQueryOptions<ActiveCompanies>>
) {
  return useQuery({
    queryKey: ["activeCompanies", { variantId }],
    queryFn: () => fetchActiveCompanies(variantId),
    ...options,
    staleTime: Infinity
  });
}

async function fetchActiveCompanies(variantId: number) {
  const activeCompaniesUrl = urls.api.activeCompanies(variantId);
  const response = await api.get(activeCompaniesUrl);
  return response.data;
}
