import classNames from "classnames";
import React from "react";

const METRONIC_BREADCRUMB_CLASSES = [
  "m-header-menu",
  "m-aside-header-menu-mobile",
  "m-aside-header-menu-mobile--offcanvas",
  "m-header-menu--skin-light",
  "m-header-menu--submenu-skin-light",
  "m-aside-header-menu-mobile--skin-dark",
  "m-aside-header-menu-mobile--submenu-skin-dark"
];

interface MetronicBreadcrumbsWrapperProps {
  className: string;
  children: React.ReactNode;
}

function MetronicBreadcrumbWrapper({
  className,
  children
}: MetronicBreadcrumbsWrapperProps) {
  return (
    <div className={classNames(className, METRONIC_BREADCRUMB_CLASSES)}>
      <ul className="m-menu__nav m-menu__nav--submenu-arrow ">{children}</ul>
    </div>
  );
}

export { MetronicBreadcrumbWrapper, MetronicBreadcrumbsWrapperProps };
