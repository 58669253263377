import type { MRT_ColumnDef } from "mantine-react-table";
import { submitOrCancelOnKeyPress } from "../../../../../mantine/mrt/utils/submitOrCancelOnKeyPress";
import { isNumberStringValid } from "../../../../../mantine/mrt/validation/isNumberStringValid";
import type { InlineEditTableColumnData2 } from "../../../../../types/inlineEditTable.types";
import type { Paragraph6EEGCreditNumberRangeResponse } from "../../../Paragraph6.types";

export function getCounterStartValueColumn({
  validationErrors,
  setValidationErrors,
  t
}: InlineEditTableColumnData2): MRT_ColumnDef<Paragraph6EEGCreditNumberRangeResponse> {
  return {
    accessorKey: "counter_start_value",
    header: "Startwert des Nummernkreises",
    enableSorting: false,
    mantineEditTextInputProps: ({ table }) => ({
      "aria-label": "Startwert des Nummernkreises",
      error: validationErrors.counter_start_value,
      required: true,
      type: "text",
      onChange: (event) => {
        const value = event.target.value;

        if (!isNumberStringValid(value)) {
          setValidationErrors({
            ...validationErrors,
            counter_start_value: t(
              "errors.Paragraph6.CreditNumberRanges.counterStartValueError"
            )
          });
        } else {
          delete validationErrors.counter_start_value;
          setValidationErrors({ ...validationErrors });
        }
      },
      onKeyDown: (event) => {
        submitOrCancelOnKeyPress(event, table);
      }
    }),
    minSize: 150
  };
}
