import type { MRT_ColumnDef } from "mantine-react-table";
import type { InlineEditTableColumnData2 } from "../../../../../types/inlineEditTable.types";
import type {
  Paragraph6Company,
  Paragraph6EEGCreditNumberRangeResponse
} from "../../../Paragraph6.types";

export function getSupplierColumn(
  { validationErrors }: InlineEditTableColumnData2,
  companies: Array<Paragraph6Company> | undefined
): MRT_ColumnDef<Paragraph6EEGCreditNumberRangeResponse> {
  const companyChoices = companies?.map((company) => company.name).sort() ?? [];

  return {
    accessorFn: (row) => {
      const supplierName = companies?.find(
        (company) => company.id === row.supplier
      )?.name;

      return supplierName ?? null;
    },
    header: "Rechnungssteller",
    editVariant: "select",
    enableSorting: false,
    id: "supplier",
    mantineEditSelectProps: {
      "aria-label": "Rechnungssteller",
      data: companyChoices,
      error: validationErrors.supplier,
      required: true
    }
  };
}
