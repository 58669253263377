import * as Sentry from "@sentry/browser";
import React from "react";
import HubspotForm from "react-hubspot-form";

import urls from "../../urls";
import { SiteName } from "../../utils/enums";
import { AnchorLink } from "../AnchorLink/AnchorLink";
import { AnimatedLoadingIcon } from "../Icons/AnimatedLoadingIcon/AnimatedLoadingIcon";

declare const SITE_NAME: SiteName;

function Support() {
  const phone = (
    <PhoneLink phoneNumber="+4969588098859">069 5880988 59</PhoneLink>
  );
  let content: React.ReactNode = null;
  let email: React.ReactNode;

  switch (SITE_NAME) {
    case SiteName.Optinode:
      email = <EmailLink emailAddress="support@node.energy" />;
      content = (
        <React.Fragment>
          <div>
            <h2>Schnelle Hilfe</h2>
            <p style={{ marginBottom: "20px" }}>
              Die Antworten auf die häufigsten Fragen finden Sie in unserer{" "}
              <AnchorLink href={urls.optiSupport()}>opti.node-Hilfe</AnchorLink>
              .
            </p>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <h2>Kontakt-Formular</h2>
            <p>
              Sie haben in der opti.node-Hilfe keine Antwort auf Ihre Frage
              bekommen? Dann können Sie uns über dieses Kontakt-Formular direkt
              erreichen. Wir helfen Ihnen gerne weiter:
            </p>
            <HubspotForm
              formId="be23f3ff-ad6d-45dd-a8f9-fc383a3a618b"
              loading={
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <AnimatedLoadingIcon />
                </div>
              }
              portalId="25500712"
            />
          </div>
          <h2>Weitere Kontaktmöglichkeiten</h2>
          <p>
            Alternativ können Sie uns auch telefonisch oder per Mail erreichen:
          </p>
          <p>
            Telefon: {phone}
            <br />
            E-Mail: {email}
          </p>
        </React.Fragment>
      );
      break;
    case SiteName.Smarendo:
      email = <EmailLink emailAddress="smarendo@node.energy" />;
      content = (
        <React.Fragment>
          <span>
            Bei Fragen zur Nutzung von SMARENDO (Software-Support) wenden Sie
            sich bitte an:
          </span>
          <br />
          <br />
          <p>
            Telefon: {phone}
            <br />
            E-Mail: {email}
          </p>
        </React.Fragment>
      );
      break;
    default:
      Sentry.captureMessage(
        `Unsupported SITE_NAME ${SITE_NAME} used on Support page! This needs to be implemented to show the right message and contact details.`
      );
      break;
  }

  return <div className="Support">{content}</div>;
}

function EmailLink({ emailAddress }: { emailAddress: string }) {
  return <a href={`mailto:${emailAddress}`}>{emailAddress}</a>;
}

function PhoneLink({
  phoneNumber,
  children
}: {
  phoneNumber: string;
  children: React.ReactNode;
}) {
  return <a href={`tel:${phoneNumber}`}>{children}</a>;
}

export { Support };
