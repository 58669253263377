import { Center, Stack, Text } from "@mantine/core";
import React from "react";
import type { HistoryLogs } from "../../../utils/backend-types";
import type { FieldNameToLabelMap } from "../HistoryTab";
import { PAGE_SIZE } from "../HistoryTab.constants";
import { HistoryItem } from "./HistoryItem";
import "./HistoryDisplay.scss";

interface HistoryDisplayProps {
  historyLogs?: HistoryLogs;
  page: number;
  fieldNameToLabelMap: FieldNameToLabelMap;
}

function HistoryDisplay({
  historyLogs,
  page,
  fieldNameToLabelMap
}: HistoryDisplayProps) {
  if (!historyLogs || historyLogs.length === 0)
    return (
      <Center>
        <Text>Aktuell ist noch keine Historie vorhanden.</Text>
      </Center>
    );

  return (
    <Stack className="history-display" gap="xs">
      {historyLogs
        ?.slice(0, PAGE_SIZE * page)
        .map((historyLog) => (
          <HistoryItem
            fieldNameToLabelMap={fieldNameToLabelMap}
            historyLog={historyLog}
            key={historyLog.id}
          />
        ))}
    </Stack>
  );
}

export { HistoryDisplay, HistoryDisplayProps };
