import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import AlvaLogo from "../../../alva/assets/img/alva-logo.svg";
import LogoWhite from "../../../default/assets/img/logo_white.png";
import NodeEnergyLogo from "../../../roedl/assets/img/node.energy lowercase.png";
import { SiteName } from "../../utils/enums";
import { FooterMenu } from "./FooterMenu";

import "./PublicLayout.scss";
import { SiteLogo } from "./SiteLogo";

declare const SITE_NAME: SiteName;

export function PublicLayout({ children }) {
  return (
    <div className="LayoutContainer">
      <div className="LayoutGradient">
        <div className="LogoWhite">
          <img
            alt="Logo white"
            src={SITE_NAME === SiteName.Alva ? AlvaLogo : LogoWhite}
            width={SITE_NAME === SiteName.Alva ? 80 : 30}
          />
        </div>

        <h1 className="LayoutHeadingText">
          So einfach <br /> ist Energie.
        </h1>
      </div>

      <div className="LayoutContent">
        <div className="OptiNodeLogo">
          <Link to="/login">
            <SiteLogo siteName={SITE_NAME} />
          </Link>
        </div>

        {children}

        <div>
          <div className="LayoutFooterProductLogo">
            <span className="ProductByText">Product by</span>
            <img alt="node.energy logo" src={NodeEnergyLogo} width={100} />
          </div>

          <div
            className={classNames(
              "m-stack__item",
              "m-stack__item--center",
              "m-stack__item--middle",
              "m-stack__item--first"
            )}
          >
            <FooterMenu siteName={SITE_NAME} strongLinks />
          </div>
        </div>
      </div>
    </div>
  );
}
