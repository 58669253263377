import React from "react";
import ReactMarkdown from "react-markdown";
import { InfoIcon, InfoType } from "../../Icons/InfoIcon/InfoIcon";

interface FormItemInfoTextProps {
  infoText: string;
}

function FormItemInfoText({ infoText }: FormItemInfoTextProps) {
  return (
    <div className="form-item-info-text-container">
      <InfoIcon infoType={InfoType.Instruction}>
        <span className="form-item-info-text">
          <ReactMarkdown linkTarget="_blank">{infoText}</ReactMarkdown>
        </span>
      </InfoIcon>
    </div>
  );
}

export { FormItemInfoText };
