import type { UseQueryOptions } from "@tanstack/react-query";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../api";
import urls from "../urls";
import type { MSBFiles, TodoFile } from "../utils/backend-types";
import { uploadFileOrFiles } from "../utils/files/uploadFileOrFiles";

export function useMSBFiles(
  siteId: number,
  options?: Partial<UseQueryOptions<MSBFiles>>
) {
  const {
    data: msbFiles,
    isLoading,
    error
  } = useQuery({
    queryKey: ["MSBFiles", { siteId }],
    queryFn: () => fetchMSBFiles(siteId),
    ...options
  });

  const queryClient = useQueryClient();

  async function fetchMSBFiles(siteId: number) {
    const msbFilesUrl = urls.api.siteDocumentsFiles(siteId);
    const response = await api.get<MSBFiles>(msbFilesUrl);
    return response.data;
  }

  const addMSBFileMutations = useMutation({
    mutationFn: (documentsInfo: {
      siteId: number;
      newFiles: Array<File>;
      asSystemUser: boolean;
    }) => {
      const uploadPromises = documentsInfo.newFiles.map((file) =>
        uploadFileOrFiles<TodoFile>(
          file,
          urls.api.uploadSiteFile(siteId),
          "data_file",
          {
            site: documentsInfo.siteId,
            as_system_user: documentsInfo.asSystemUser
          }
        )
      );

      return Promise.allSettled(uploadPromises);
    },
    onError(err) {
      return err;
    },
    onSettled() {
      handleSettled();
    }
  });

  function handleSettled() {
    queryClient.invalidateQueries({
      queryKey: ["MSBFiles", { siteId }]
    });
  }

  return {
    msbFiles,
    isLoading,
    error,
    addMSBFile: addMSBFileMutations.mutateAsync
  };
}
