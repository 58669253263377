import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import urls from "../../../urls";
import type { DirektvermarktungContractResponse } from "../Direktvermarktung.types";

export function useDirektvermarktungContracts(
  options?: Partial<UseQueryOptions<Array<DirektvermarktungContractResponse>>>
) {
  const { data, isLoading, error } = useQuery({
    queryKey: ["direktvermarktungContracts"],
    queryFn: () => fetchDirektvermarktungContracts(),
    ...options
  });

  async function fetchDirektvermarktungContracts() {
    const direktvermarktungContractsUrl =
      urls.apiWithoutCamelization.direktvermarktungContracts();
    const response = await apiWithoutCamelization.get<
      Array<DirektvermarktungContractResponse>
    >(direktvermarktungContractsUrl);
    return response.data;
  }

  return {
    data,
    isLoading,
    error
  };
}
