import React from "react";

import { EMPTY_FIELD } from "../../../../../utils/constants";
import type { Tag } from "../../../../EnergyData/EnergyDataView/EnergyDataView";
import { Tags } from "../../../EdaTable/EdaTable";

interface EdaTagsPropertyProps {
  header: string;
  tags: Array<Tag>;
}

function EdaTagsProperty({ header, tags }: EdaTagsPropertyProps) {
  const noTags = tags.length === 0;

  return (
    <div className="eda-property">
      <h6>{header}</h6>
      <span className="eda-property-value">
        {noTags ? EMPTY_FIELD : <Tags tags={tags} />}
      </span>
    </div>
  );
}

export { EdaTagsProperty };
