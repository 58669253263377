import React, { useState } from "react";
import { showToast } from "../../../utils/toast";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../../Buttons/Button/Button";
import { LoadOrError } from "../../LoadOrError/LoadOrError";
import type {
  OAuthApplicationCreate,
  OAuthApplicationCreateResponse
} from "../ApiSettings.types";
import { useOAuthApplicationOptionsForCreate } from "../hooks/useOAuthApplicationOptionsForCreate";
import { useOAuthApplicationsMutations } from "../hooks/useOAuthApplicationsMutations";
import { OAuthApplicationForm } from "../OAuthApplicationForm/OAuthApplicationForm";
import { OAuthApplicationSuccess } from "./OAuthApplicationSuccess/OAuthApplicationSuccess";

interface CreateOAuthApplicationModalProps {
  isOpen?: boolean;
  onClose: () => void;
}

function CreateOAuthApplicationModal({
  isOpen,
  onClose
}: CreateOAuthApplicationModalProps) {
  const [createdApplication, setCreatedApplication] =
    useState<OAuthApplicationCreateResponse | null>(null);
  const { optionsForCreate, isLoading, error } =
    useOAuthApplicationOptionsForCreate();
  const { createMutation } = useOAuthApplicationsMutations();
  const customers = optionsForCreate?.customers ?? {};
  const scopes = optionsForCreate?.scope ?? [];

  async function handleSubmit(newApplication: OAuthApplicationCreate) {
    try {
      const response = await createMutation.mutateAsync(newApplication);
      setCreatedApplication(response.data);
    } catch (error) {
      if (error.response?.status === 400) {
        return Promise.reject(error);
      }

      showToast("error", error);
      return Promise.resolve();
    }

    return Promise.resolve();
  }

  function handleCloseModal() {
    setCreatedApplication(null);
    onClose();
  }

  return (
    <Modal className="CreateOAuthApplicationModal" isOpen={isOpen}>
      <ModalHeader toggle={handleCloseModal}>
        Neue OAuth-Applikation registrieren
      </ModalHeader>
      <ModalBody>
        {!createdApplication ? (
          <LoadOrError error={error} loading={isLoading}>
            <OAuthApplicationForm
              customers={customers}
              scopes={scopes}
              onCancel={handleCloseModal}
              onSubmit={handleSubmit}
            />
          </LoadOrError>
        ) : (
          <OAuthApplicationSuccess
            clientId={createdApplication.client_id}
            clientSecret={createdApplication.client_secret}
          />
        )}
      </ModalBody>
      {createdApplication && (
        <ModalFooter>
          <Button onClick={handleCloseModal}>Schließen</Button>
        </ModalFooter>
      )}
    </Modal>
  );
}

export { CreateOAuthApplicationModal, CreateOAuthApplicationModalProps };
