import React, { useEffect, useState } from "react";

import urls from "../../../urls";
import { OptionsForm } from "../../OptionsForm/OptionsForm";
import type { ConfigurationData } from "../ThirdPartySystems.types";

export enum SubmeteringConfigurationFormMode {
  New = "new",
  Edit = "edit"
}

enum Field {
  SystemField = "subMeteringSystem",
  NameOfConfiguration = "name",
  Url = "url",
  Username = "username",
  Password = "password",
  Token = "token",
  ClientId = "clientId"
}

const INITIAL_FIELDS = [Field.SystemField, Field.NameOfConfiguration];

const ON_PREMISE_FIELDS = [Field.Url, Field.Username, Field.Password];
const SAAS_PASSWORD_FIELDS = [Field.Username, Field.Password];
const SAAS_PASSWORD_AND_CLIENT_ID_FIELDS = [
  Field.ClientId,
  Field.Username,
  Field.Password
];
const SAAS_TOKEN_FIELDS = [Field.Username, Field.Token];

const READ_ONLY_FIELDS = {
  [SubmeteringConfigurationFormMode.New]: [],
  [SubmeteringConfigurationFormMode.Edit]: [Field.SystemField]
};

const FIELDS_FOR_SYSTEM = {
  rotorsoft_drehpunkt: ON_PREMISE_FIELDS,
  wis_softenergy: ON_PREMISE_FIELDS,
  navigator_siemens: SAAS_PASSWORD_AND_CLIENT_ID_FIELDS,
  qivalo: SAAS_PASSWORD_FIELDS,
  wonder_windguard: SAAS_PASSWORD_FIELDS,
  breeze_greenbyte: SAAS_TOKEN_FIELDS,
  weo_bytemee: INITIAL_FIELDS,
  wattline: SAAS_TOKEN_FIELDS
};
const getFields = (initialConfigurationData, systemName) => {
  const fields = [...INITIAL_FIELDS];

  const currentOrInitialSystemName = initialConfigurationData
    ? initialConfigurationData.subMeteringSystem
    : systemName;
  const fieldsForSystem = FIELDS_FOR_SYSTEM[currentOrInitialSystemName];

  if (fieldsForSystem) {
    fields.push(...fieldsForSystem);
  }

  return fields;
};

interface SubMeteringConfigurationFormProps {
  initialConfigurationData?: ConfigurationData;
  variantId: number;
  mode?: SubmeteringConfigurationFormMode;
  buttonContainer?: HTMLElement;
  isConnectionLoading?: boolean;
  onSubmit:
    | ((configurationData: ConfigurationData) => void)
    | ((
        configurationData: ConfigurationData,
        stopSpinButton: () => void
      ) => Promise<void>);
  onCheckConnection?:
    | (({
        withSuccessToast
      }: {
        withSuccessToast?: boolean;
      }) => Promise<true | undefined>)
    | null;
}

function SubMeteringConfigurationForm({
  mode = SubmeteringConfigurationFormMode.New,
  initialConfigurationData,
  buttonContainer,
  variantId,
  onSubmit,
  onCheckConnection,
  isConnectionLoading
}: SubMeteringConfigurationFormProps) {
  const [systemName, setSystemName] = useState(
    initialConfigurationData ? initialConfigurationData.subMeteringSystem : null
  );

  const [filteredFields, setFilteredFields] = useState(
    getFields(initialConfigurationData, systemName)
  );
  const [isCheckConnectionBtnDisabled, setIsCheckConnectionBtnDisabled] =
    useState(false);

  const nonFieldData: { variant: number; id?: number } = { variant: variantId };

  const postUrl =
    mode === SubmeteringConfigurationFormMode.New
      ? urls.api.subMeteringConfigurations(variantId)
      : null;

  const putUrl =
    mode === SubmeteringConfigurationFormMode.Edit && initialConfigurationData
      ? urls.api.subMeteringConfiguration(initialConfigurationData.id)
      : null;
  const optionsUrl =
    mode === SubmeteringConfigurationFormMode.New ? postUrl : putUrl;

  if (
    mode === SubmeteringConfigurationFormMode.Edit &&
    initialConfigurationData
  ) {
    nonFieldData.id = initialConfigurationData.id;
  }

  function handleInput(fieldName, value) {
    if (fieldName === Field.SystemField) {
      setSystemName(value);
      return;
    }

    if (fieldName === Field.NameOfConfiguration) {
      return;
    }

    const isCredentialFieldDirty =
      (fieldName === Field.Username &&
        initialConfigurationData?.username !== value) ||
      (fieldName === Field.Url && initialConfigurationData?.url !== value) ||
      (fieldName === Field.Password &&
        initialConfigurationData?.password !== value) ||
      (fieldName === Field.Token &&
        initialConfigurationData?.token !== value) ||
      (fieldName === Field.ClientId &&
        initialConfigurationData?.clientId !== value);

    setIsCheckConnectionBtnDisabled(isCredentialFieldDirty);
  }

  const readOnlyFields = READ_ONLY_FIELDS[mode];
  const submitButtonText =
    mode === SubmeteringConfigurationFormMode.New
      ? "Weiter zu Zähler"
      : "Speichern";

  useEffect(() => {
    const newFilteredFields = getFields(initialConfigurationData, systemName);
    setFilteredFields(newFilteredFields);
  }, [initialConfigurationData, systemName]);

  return (
    <div className="SubMeteringConfigurationForm">
      <OptionsForm
        actionButtonText="Verbindung testen"
        allowInput={true}
        buttonContainer={buttonContainer}
        filteredFields={filteredFields}
        initialValues={initialConfigurationData}
        isActionBtnDisabled={isCheckConnectionBtnDisabled}
        isActionLoading={isConnectionLoading}
        nonFieldData={nonFieldData}
        optionsUrl={optionsUrl ?? ""}
        postUrl={postUrl ?? ""}
        putUrl={putUrl ?? ""}
        readOnlyFields={readOnlyFields}
        submitButtonText={submitButtonText}
        onActionBtnClick={onCheckConnection}
        onInput={handleInput}
        onSubmit={onSubmit}
      />
    </div>
  );
}

export { SubMeteringConfigurationForm };
