import React from "react";
import { Portlet } from "../BuildingBlocks/Layout/Portlet";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { GuaranteeOfOriginGenerationView } from "./GuaranteeOfOriginGenerationView/GuaranteeOfOriginGenerationView";
import { useGOSites } from "./hooks/useGOSites";

export interface GuaranteeOfOriginPageProps {
  variantId: number;
}
function GuaranteeOfOriginPage({ variantId }: GuaranteeOfOriginPageProps) {
  const { sites, loading, error } = useGOSites(variantId);
  return (
    <Portlet className="Paragraph6View" title="Herkunftsnachweis">
      <LoadOrError error={error} loading={loading}>
        <GuaranteeOfOriginGenerationView sites={sites} />
      </LoadOrError>
    </Portlet>
  );
}

export { GuaranteeOfOriginPage };
