import { useQuery } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../api";
import urls from "../urls";
import type { Site } from "../utils/backend-types";

export function useSitesDetail(siteId: number) {
  const { data, isLoading, error } = useQuery({
    queryKey: ["site", { siteId }],
    queryFn: () => fetchSitesDetail(siteId)
  });

  async function fetchSitesDetail(siteId: number) {
    const response = await apiWithoutCamelization.get<Site>(
      urls.apiWithoutCamelization.sitesDetail(siteId)
    );
    return response.data;
  }

  return {
    data,
    isLoading,
    error
  };
}
