import type { Choice } from "../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { FieldName } from "./DeliveryForm/CustomFields/FieldNames";

export interface DeliveryFormOptions {
  sites: Array<Choice<number>>;
}

export interface DeliveryFormOptionsResponse {
  sites: Array<DeliveryFormOptionsChoice>;
}

interface DeliveryFormOptionsChoice {
  id: number;
  name: string;
}

export const INDIVIDUAL_MIX_FIELDS = [
  FieldName.NuclearPercentage,
  FieldName.CoalPercentage,
  FieldName.GasPercentage,
  FieldName.OtherFossilPercentage,
  FieldName.TenantPercentage,
  FieldName.RenewableNotPromotedPercentage,
  FieldName.RenewablePromotedPercentage,
  FieldName.YearMix,
  FieldName.EmissionCo2,
  FieldName.RadioactiveWaste
];
