import { useQuery } from "@tanstack/react-query";
import type { UseQueryOptions } from "@tanstack/react-query";

import api from "../api";
import urls from "../urls";
import type { SiteEnergyWizard } from "../utils/backend-types";

export function useEnergyWizard(
  siteId: number,
  options?: Partial<UseQueryOptions<SiteEnergyWizard>>
) {
  const {
    data: energyWizard,
    isLoading,
    error
  } = useQuery({
    queryKey: ["energyWizard", { siteId }],
    queryFn: () => fetchEnergyWizard(siteId),
    ...options
  });

  async function fetchEnergyWizard(siteId: number) {
    const energyWizardUrl = urls.api.energyWizard(siteId);
    const response = await api.get(energyWizardUrl);
    return response.data;
  }

  return { energyWizard, isLoading, error };
}
