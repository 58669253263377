import { useParams } from "react-router-dom";
import { useManagerVariant } from "./useManagerVariant";
import { useSiteProject } from "./useSiteProject";
import { useSiteVariantId } from "./useSiteVariantId";
import { useVariantSite } from "./useVariantSite";

export function useIds() {
  const params = useParams();
  const projectId = params.projectId;
  const siteId =
    typeof params.siteId !== "undefined"
      ? parseInt(params.siteId, 10)
      : undefined;
  const variantId =
    typeof params.variantId !== "undefined"
      ? parseInt(params.variantId, 10)
      : undefined;

  const { variantId: siteVariantId, error: variantError } = useSiteVariantId(
    siteId,
    !!variantId
  );
  const { site: variantSite, error: siteError } = useVariantSite(
    variantId,
    !!siteId
  );

  const siteIdOrVariantSiteId = siteId || variantSite?.id;

  const {
    project: siteProject,
    variant: siteAndProjectVariant,
    error: projectError
  } = useSiteProject(siteIdOrVariantSiteId, !!projectId, variantId);

  const { error: managerVariantError, variantId: managerVariantId } =
    useManagerVariant(projectId, !projectId || !!variantId || !!siteId);

  if (projectId && siteId && variantId) {
    return {
      projectId: projectId,
      siteId: siteId,
      variantId: variantId
    };
  } else if (projectId && siteId) {
    return {
      projectId: projectId,
      siteId: siteId,
      variantId: siteVariantId,
      variantIdError: variantError
    };
  } else if (projectId && variantId) {
    return {
      projectId: projectId,
      siteId: variantSite ? variantSite.id : null,
      siteIdError: siteError,
      variantId: variantId
    };
  } else if (siteId && variantId) {
    return {
      projectId: siteProject ? siteProject.id : null,
      projectIdError: projectError,
      siteId: siteId,
      variantId: variantId
    };
  } else if (projectId) {
    return {
      projectId: projectId,
      variantId: managerVariantId,
      variantIdError: managerVariantError
    };
  } else if (siteId) {
    return {
      projectId: siteProject ? siteProject.id : null,
      projectIdError: projectError,
      siteId: siteId,
      variantId: siteAndProjectVariant ? siteAndProjectVariant.id : null
    };
  } else if (variantId) {
    return {
      projectId: siteProject ? siteProject.id : null,
      projectIdError: projectError,
      siteId: variantSite ? variantSite.id : null,
      siteIdError: siteError,
      variantId: variantId
    };
  } else {
    return {};
  }
}
