import { useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useState } from "react";
import { generatePath } from "react-router-dom";
import { ROUTES } from "../../../routes";
import urls from "../../../urls";
import { showToast } from "../../../utils/toast";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../BuildingBlocks/Layout/Modal/Modal";
import { TodoForm } from "../TodoForm/TodoForm";
import "./NewTotalModal.scss";

function NewTodoModal({
  isOpen,
  onToggle,
  persons,
  users,
  variantId,
  projectId,
  mode,
  basePath
}) {
  const queryClient = useQueryClient();
  // footerNode is used in TodoForm (and then CustomForm) with React.Portal to allow the
  //  CustomForm buttons to be displayed outside of the CustomForm and in this component
  // footerRef and useCallback are needed because the node does not exist in the Dom
  //  at the time of the first render of this component
  const [footerNode, setFooterNode] = useState(null);
  const footerRef = useCallback((node) => {
    if (node !== null) {
      setFooterNode(node);
    }
  }, []);

  async function handleSubmit(data) {
    if (basePath !== ROUTES.todos) {
      document.location.href = `${generatePath(basePath, {
        projectId: projectId
      })}/${data.id}/`;
    } else {
      await queryClient.invalidateQueries({
        queryKey: ["todos"]
      });
      onToggle();
      showToast("success", "Die Aufgabe wurde erfolgreich erstellt.");
    }
  }

  const postUrl = urls.api.todosCreate();

  return (
    <Modal className="NewTodoModal" isOpen={isOpen} size="lg" toggle={onToggle}>
      <ModalHeader toggle={onToggle}>Aufgabe hinzufügen</ModalHeader>
      <ModalBody scrollable>
        <TodoForm
          buttonContainer={footerNode}
          mode={mode}
          persons={persons}
          postUrl={postUrl}
          users={users}
          variantId={variantId}
          onCancel={onToggle}
          onSubmit={handleSubmit}
        />
      </ModalBody>
      <ModalFooter>
        <div className="buttons" ref={footerRef} />
      </ModalFooter>
    </Modal>
  );
}

export { NewTodoModal };
