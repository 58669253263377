import { getFormFieldsFromResponse } from "../../../CustomForm/CustomForm";

/** @deprecated - techdebt: switch to getOrderedFormFieldsFromResponseAndValues */
export function getSelectedFormFields(
  allFormFields,
  initialValues,
  visibleFields,
  hiddenFieldsWhenNull
) {
  const visibleHiddenIfNullFields = hiddenFieldsWhenNull.filter(
    (fieldName) => initialValues[fieldName] !== null
  );
  const visibleFieldNames = visibleFields.concat(visibleHiddenIfNullFields);
  let formFieldArray = getFormFieldsFromResponse(
    visibleFieldNames,
    allFormFields,
    initialValues
  );

  return formFieldArray;
}

export function getOrderedFormFieldsFromResponseAndValues(
  orderedFormFields,
  hiddenIfNullFormFields,
  formFieldsResponse,
  initialValues
) {
  const fieldNames = orderedFormFields.filter(
    (name) =>
      Object.prototype.hasOwnProperty.call(initialValues, name) &&
      (initialValues[name] !== null || !hiddenIfNullFormFields.includes(name))
  );

  return getFormFieldsFromResponse(
    fieldNames,
    formFieldsResponse,
    initialValues
  );
}

export function setLoadprofileType(formFieldArray, type) {
  const index = formFieldArray.findIndex((f) => f.name === "loadprofile");
  if (index >= 0) {
    formFieldArray[index]["loadprofileType"] = type;
  }

  return formFieldArray;
}
