import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";

import api from "../../../../api";
import urls from "../../../../urls";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import type { Eda } from "../../EdaTable/Mbk";

const QUERY_KEY = "mbks";

export type EdaWithReferences = Eda & {
  references: Record<string, unknown>;
  publicId: number | null;
};
export type EdaWithDisplayableReferences = EdaWithReferences & {
  referencesDisplayname: string;
};

export function useMbksQuery(siteId: number) {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => {
      return addReferencesField((await api.get(urls.api.mbk(siteId))).data);
    }
  });
}
export function useMbksMutation(siteId: number, method: "DELETE") {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: Eda[]) => {
      switch (method) {
        case "DELETE":
        default:
          return await api.delete(urls.api.mbk(siteId), payload);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY]
      });
    }
  });
}

export function useMieterstromMBKCreation(siteId: number) {
  return useMutation({
    mutationFn: async ({
      firstDate,
      lastDate
    }: {
      firstDate: DateTime;
      lastDate?: DateTime;
    }) => {
      return await api.post(urls.api.mieterstromMbk(siteId), {
        firstDate: luxonDateTimeToBackendDateOrDateTime(firstDate),
        lastDate: luxonDateTimeToBackendDateOrDateTime(
          lastDate ?? DateTime.fromISO("2100-01-01")
        )
      });
    }
  });
}

export const addReferencesField = (edas: Eda[]): EdaWithReferences[] => {
  return edas.map((eda) => ({
    ...eda,
    publicId: eda.versions.length > 0 ? eda.versions[0].publicId : null,
    references: Object.entries(eda.identity).reduce((acc, [key, value]) => {
      if (key !== "displayName" && key !== "type") {
        return { ...acc, [key]: value };
      } else {
        return acc;
      }
    }, {})
  }));
};

export const mapEdasWithReferenceValues = (
  edasWithReferences: EdaWithReferences[],
  referenceDictionary
): EdaWithDisplayableReferences[] => {
  return edasWithReferences.map((eda) => {
    const references = eda.references;
    const referenceKeys = Object.keys(references);
    let description = "";
    if (
      referenceKeys.includes("description") &&
      typeof references.description === "string"
    ) {
      description = references.description;
    }

    const referenceValues = Object.entries(references).reduce(
      (acc, [key, value]) => {
        const reference = referenceDictionary[key]?.find(
          (reference) => reference?.id === value
        );
        return { ...acc, [key]: reference?.name };
      },
      {}
    );

    return {
      ...eda,
      referencesDisplayname:
        Object.values(referenceValues)
          .filter((v) => v)
          .join(", ") + description
    };
  });
};
