import type { Filter } from "react-table";

import { matchFullTextCaseInsensitive } from "../../../utils/text-utils";

export function matchFullTextCaseInsensitiveFilter(
  filter: Filter,
  row,
  accessor: string
) {
  return !!matchFullTextCaseInsensitive(
    row._original?.parameters?.[accessor] ?? "",
    filter.value
  );
}
