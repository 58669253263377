import React from "react";
import { useTranslation } from "react-i18next";
import { OptiSupportEndpoints } from "../../../urls";
import { showToast } from "../../../utils/toast";
import { AlertColor } from "../../Alert/Alert";
import { IconAlert } from "../../BuildingBlocks/IconAlert/IconAlert";
import { LoadOrError } from "../../LoadOrError/LoadOrError";
import { OptiSupportHelpLink } from "../../OptiSupportHelpLink/OptiSupportHelpLink";
import { useParagraph6CreditNumberRangeMutations } from "../hooks/useParagraph6CreditNumberRangeMutations";
import { useParagraph6CreditNumberRanges } from "../hooks/useParagraph6CreditNumberRanges";
import { CreditNumberRangesTable } from "./CreditNumberRangesTable/CreditNumberRangesTable";
import "./Paragraph6CreditNumberRangesPage.scss";
import type { Paragraph6CreditNumberRangeFormValues } from "./Paragraph6CreditNumberRangesPage.types";

interface Paragraph6CreditNumberRangesPageProps {
  variantId: number;
}

function Paragraph6CreditNumberRangesPage({
  variantId
}: Paragraph6CreditNumberRangesPageProps) {
  const { t } = useTranslation();
  const { creditNumberRanges, error, isLoading } =
    useParagraph6CreditNumberRanges(variantId);
  const paragraph6CreditNumberRangeMutations =
    useParagraph6CreditNumberRangeMutations(variantId);

  async function handleSaveCreditRange(
    creditRangeFormValues: Paragraph6CreditNumberRangeFormValues,
    creditRangeId?: string
  ) {
    const isLocal = typeof creditRangeId === "undefined";

    if (isLocal) {
      const response =
        await paragraph6CreditNumberRangeMutations.createMutation.mutateAsync({
          formValues: creditRangeFormValues
        });

      if (response.status === 201) {
        showToast(
          "success",
          "Ihr Gutschriftennummernkreis wurde erfolgreich gespeichert."
        );

        return Promise.resolve(response);
      }

      return Promise.reject(response);
    }

    const range = creditNumberRanges?.find(
      (range) => range.id === creditRangeId
    );

    if (!range) {
      console.error("Could not find local range with id", creditRangeId);
      // todo: do we need to send to sentry or do console.errors finally get auto-logged?
      // check with Johannes
      return Promise.reject(t("errors.ServerError"));
    }

    return paragraph6CreditNumberRangeMutations.editMutation.mutateAsync({
      formValues: creditRangeFormValues,
      id: range.id
    });
  }

  async function handleDeleteCreditRange(creditRangeId: string) {
    try {
      await paragraph6CreditNumberRangeMutations.deleteMutation.mutateAsync(
        creditRangeId
      );
    } catch (error) {
      showToast("error", error);
      return Promise.resolve();
    }

    showToast(
      "success",
      "Ihr Gutschriftennummernkreis wurde erfolgreich gelöscht."
    );

    return Promise.resolve();
  }

  return (
    <div className="Paragraph6CreditNumberRangesPage">
      <OptiSupportHelpLink
        optiSupportEndpoint={
          OptiSupportEndpoints.GutschriftenNummernKreiseParagraph6
        }
        text="Wie können Gutschriftennummernkreise angelegt werden?"
      />

      {creditNumberRanges?.length === 0 && (
        <IconAlert color={AlertColor.Info}>
          Dieser Vertrag enthält bisher keine personalisierten
          Gutschriftennummern. opti.node verwendet bisher die standardisierten
          Gutschriftennummernkreise für Ihre Dokumente
          [G&#123;JJJJ&#125;-(fortlaufende Nummer des Nummernkreises)]. Bitte
          fügen Sie neue hinzu, indem Sie auf den Button{" "}
          <em>Gutschriftennummernkreis hinzufügen</em> klicken.
        </IconAlert>
      )}
      <LoadOrError
        error={error}
        loading={isLoading}
        loadingMessage="Nummernkreise werden geladen ..."
      >
        <CreditNumberRangesTable
          creditNumberRanges={creditNumberRanges ?? []}
          variantId={variantId}
          onDelete={handleDeleteCreditRange}
          onSubmit={handleSaveCreditRange}
        />
      </LoadOrError>
    </div>
  );
}

export {
  Paragraph6CreditNumberRangesPage,
  Paragraph6CreditNumberRangesPageProps
};
