import { type components } from "../../../../../../types/api.types";

enum CompanyFeatureKeys {
  isErloesmonitoringActive = "isErloesmonitoringActive",
  isParagraph6EegActive = "isParagraph_6EegActive",
  shouldRegulatoryDutiesBeChecked = "shouldRegulatoryDutiesBeChecked"
}

type CompanyFeature = {
  label: string;
  key: CompanyFeatureKeys;
};

export const COMPANY_FEATURES: Array<CompanyFeature> = [
  {
    label: "§ 6 EEG",
    key: CompanyFeatureKeys.isParagraph6EegActive
  },
  {
    label: "Erlösmonitoring",
    key: CompanyFeatureKeys.isErloesmonitoringActive
  },
  {
    label: "Stromsteuer",
    key: CompanyFeatureKeys.shouldRegulatoryDutiesBeChecked
  }
];

export function getCompanyFeatures(Company: components["schemas"]["Company"]) {
  return COMPANY_FEATURES.filter((feature) => Company[feature.key]);
}
