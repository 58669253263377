import React from "react";
import { Link } from "react-router-dom";

import { ROUTES } from "../../routes";
import type { SiteName } from "../../utils/enums";
import { Icon } from "../BuildingBlocks/Icon/Icon";
import { IconName } from "../BuildingBlocks/Icon/types";
import { SiteLogo } from "./SiteLogo";

interface Props {
  siteName: SiteName;
  url?: string;
  isAdminDashboardPage?: boolean;
}

function Brand({
  siteName,
  url = ROUTES.productSelectPage,
  isAdminDashboardPage
}: Props) {
  return (
    <div className="m-stack__item m-brand  m-brand--skin-dark ">
      <div className="m-stack m-stack--ver m-stack--general">
        <div className="m-stack__item m-stack__item--middle m-stack__item--center m-brand__logo">
          {isAdminDashboardPage ? (
            <a className="m-brand__logo-wrapper" href={url}>
              <SiteLogo siteName={siteName} useSecondaryImage />
            </a>
          ) : (
            <Link className="m-brand__logo-wrapper" to={url}>
              <SiteLogo siteName={siteName} useSecondaryImage />
            </Link>
          )}
        </div>
        <div className="m-stack__item m-stack__item--middle m-brand__tools">
          <a
            className="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-tablet-and-mobile-inline-block"
            href="#"
            id="m_aside_left_offcanvas_toggle"
          >
            <span></span>
          </a>

          <a
            className="m-brand__icon m-brand__toggler m--visible-tablet-and-mobile-inline-block"
            href="#"
            id="m_aside_header_menu_mobile_toggle"
          >
            <span></span>
          </a>
          <a
            className="m-brand__icon m--visible-tablet-and-mobile-inline-block"
            href="#"
            id="m_aside_header_topbar_mobile_toggle"
          >
            <Icon name={IconName.More} />
          </a>
        </div>
      </div>
    </div>
  );
}

export { Brand };
