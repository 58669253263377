import { DateTime } from "luxon";
import React from "react";
import type { Column, Filter, SortingRule } from "react-table";
import type { Person } from "../../../utils/backend-types";
import {
  Priority,
  RegulatoryDutyCategory,
  TodoStatus
} from "../../../utils/backend-types";
import { makeSafeForCSS } from "../../../utils/css-utils";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { Icon } from "../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../BuildingBlocks/Icon/types";
import { WITHOUT_KEY } from "../../CustomReactTable/Filters/DropdownFilter/DropdownFilter";
import { useShouldShowStaffView } from "../../StaffViewToggle/useShouldShowStaffView";
import { anchorTooltip } from "../../Tooltip/Tooltip";
import type { TodoUser } from "../common";
import { CLUSTERS, Mode, PRIORITIES, TODO_DOCUMENT_STATUS } from "../common";
import { getTodoStatusChoices } from "../utils/getTodoStatusChoices";
import { TodoDueDateCell } from "./Cells/TodoDueDateCell";
import TodoPersonCell from "./Cells/TodoPersonCell";
import { TodoResponsibleCell } from "./Cells/TodoResponsibleCell";
import type { TodoTableTodo } from "./TodoTable";
import { OVERDUE_DATE } from "./TodoTable";

const LABEL_COLUMN: Column<TodoTableTodo> = {
  Header: "Aufgabe / Pflicht",
  accessor: "label",
  Cell: (data) => <LabelCell {...data} />,
  filterMethod: (filter: Filter, row: TodoTableTodo) => {
    return !!filter.value[row.label];
  }
};

const PROJECT_COLUMN: Column<TodoTableTodo> = {
  Header: "Projekt",
  accessor: "project",
  Cell: (data) => <LabelCell {...data} />,
  filterMethod: (filter: Filter, row: TodoTableTodo) => {
    return !!filter.value[row.project];
  }
};

const CATEGORY_COLUMN: Column<TodoTableTodo> = {
  Header: "Kategorie",
  accessor: "cluster",
  Cell: (data) => <CategoryCell {...data} />,
  filterMethod: (filter: Filter, row: TodoTableTodo) => {
    return !!filter.value[row.cluster];
  }
};

const FULFILL_FOR_COLUMN: Column<TodoTableTodo> = {
  Header: "Zu erfüllen für",
  accessor: "fulfillFor",
  filterMethod: (filter: Filter, row: TodoTableTodo) => {
    if (!row.fulfillFor && filter.value[WITHOUT_KEY]) {
      return true;
    }

    return !!row.fulfillFor && !!filter.value[row.fulfillFor];
  }
};

function buildTableColumns(
  users: Array<TodoUser>,
  persons: Array<Person>,
  firstDueDate: DateTime | null,
  lastDueDate: DateTime | null,
  sortedColumns: Array<SortingRule>,
  mode: Mode,
  showPersonColumn: boolean,
  showPriorityColumn: boolean | undefined,
  showTodoStatusColumn: boolean | undefined,
  onChangeStatus: TodoStatusCellProps["onChangeStatus"],
  onChangePriority: (priorityValue: Priority, todoId: number) => void,
  onChangeResponsible: (responsibleId: number | null, todoId: number) => void,
  onChangeTodoStatus: (todoId: number) => void,
  onChangeTodoPerson: (todoId: number, person: number) => void,
  onChangeDueDate: (todoId: number, dueDate: string | null) => void,
  onChangeOverdue: (todoId: number, overdue: boolean) => void,
  isStaff: boolean
) {
  const dueDateColumn: Column<TodoTableTodo> = {
    Header: "Fälligkeit",
    accessor: "dueDate",
    width: 220,
    className: "todo-due-date-cell",
    Cell: (row) => {
      return (
        <TodoDueDateCell
          dueDate={
            row.value && row.value !== "-1"
              ? backendDateOrDateTimeToLuxonDateTime(row.value)
              : undefined
          }
          notChangeable={isNotChangeable(row.original)}
          overdue={row.original.overdue}
          todoId={row.original.id}
          onChangeDueDate={onChangeDueDate}
          onChangeOverdue={onChangeOverdue}
        />
      );
    },
    sortMethod: (a: string | null, b: string | null) => {
      if (a === null && b === null) {
        return 0;
      } else if (a === null) {
        return 1;
      } else if (b === null) {
        return -1;
      }

      const dateA =
        a === OVERDUE_DATE
          ? OVERDUE_DATE
          : backendDateOrDateTimeToLuxonDateTime(a);
      const dateB =
        b === OVERDUE_DATE
          ? OVERDUE_DATE
          : backendDateOrDateTimeToLuxonDateTime(b);

      if (dateA === OVERDUE_DATE) {
        return -1;
      } else if (dateB === OVERDUE_DATE) {
        return 1;
      } else if (!dateA.isValid) {
        return 1;
      } else if (!dateB.isValid) {
        return -1;
      }

      return dateA < dateB ? -1 : 1;
    },
    filterable: !!firstDueDate && !!lastDueDate,
    filterMethod: (filter: Filter, row: TodoTableTodo) => {
      const noStartDateFilter = filter.value.startDate === null;
      const noEndDateFilter = filter.value.endDate === null;

      if (noStartDateFilter && noEndDateFilter) {
        return true;
      } else if (row.dueDate === OVERDUE_DATE && noStartDateFilter) {
        return true;
      } else if (!row.dueDate || row.dueDate === OVERDUE_DATE) {
        return false;
      }

      const dueDate = DateTime.fromFormat(row.dueDate, "dd.MM.yyyy");

      if (dueDate.isValid) {
        if (noStartDateFilter && dueDate <= filter.value.endDate) {
          return true;
        } else if (noEndDateFilter && dueDate >= filter.value.startDate) {
          return true;
        } else if (
          dueDate >= filter.value.startDate &&
          dueDate <= filter.value.endDate
        ) {
          return true;
        }
      }

      return false;
    }
  };

  const responsibleColumn: Column<TodoTableTodo> = {
    Header: "Verantwortlich",
    accessor: "responsible",
    width: 160,
    Cell: (row) => {
      return (
        <TodoResponsibleCell
          responsibleId={row.value}
          todoId={row.original.id}
          universal={mode === Mode.Universal}
          users={users}
          onChangeResponsible={onChangeResponsible}
        />
      );
    },
    filterMethod: (filter: Filter, row: TodoTableTodo) => {
      if (!row.responsible && filter.value[WITHOUT_KEY]) {
        return true;
      }

      return !!row.responsible && !!filter.value[row.responsible];
    }
  };

  const statusColumn: Column<TodoTableTodo> = {
    Header: "Status",
    accessor: "status",
    width: 160,
    Cell: (row) => (
      <TodoStatusCell
        id={row.original.id}
        label={row.original.label}
        status={row.value}
        onChangeStatus={onChangeStatus}
      />
    ),
    filterMethod: (filter: Filter, row: TodoTableTodo) => {
      if (filter.value[TodoStatus.Open] && row.status === TodoStatus.Open) {
        return true;
      } else if (
        filter.value[TodoStatus.Done] &&
        row.status === TodoStatus.Done
      ) {
        return true;
      } else if (
        filter.value[TodoStatus.NotRelevant] &&
        row.status === TodoStatus.NotRelevant
      ) {
        return true;
      }

      return false;
    }
  };

  const todoDocumentStatusColumn: Column<TodoTableTodo> = {
    Header: "Aufgaben Status",
    accessor: "documentProvided",
    width: 160,
    Cell: (row) => (
      <TodoDocumentStatusCell
        id={row.original.id}
        isStaff={isStaff}
        label={row.original.label}
        status={row.value}
        onChangeTodoStatus={onChangeTodoStatus}
      />
    ),
    filterMethod: (filter: Filter, row: TodoTableTodo) => {
      if (filter.value["OPEN"] && !row.documentProvided) {
        return true;
      } else if (filter.value["DOCUMENTS_PROVIDED"] && row.documentProvided) {
        return true;
      }
      return false;
    }
  };

  const priorityColumn: Column<TodoTableTodo> = {
    Header: "Priorität",
    accessor: "priority",
    width: 160,
    Cell: (row) => (
      <PriorityCell
        id={row.original.id}
        label={row.original.label}
        priorityValue={row.value}
        onChangePriority={onChangePriority}
      />
    ),
    sortMethod: (a: Priority, b: Priority) => {
      const priorityValues = {
        [Priority.High]: 3,
        [Priority.Medium]: 2,
        [Priority.Low]: 1,
        [Priority.Unknown]: 0
      };

      return priorityValues[a] - priorityValues[b];
    },
    filterMethod: (filter: Filter, row: TodoTableTodo) => {
      return !!row.priority && !!filter.value[row.priority];
    }
  };
  const personColumn: Column<TodoTableTodo> = {
    Header: "Verpflichtetes Unternehmen",
    accessor: "person",
    width: 160,
    Cell: (row) => {
      return (
        <TodoPersonCell
          notChangeable={isNotChangeable(row.original)}
          person={row.value}
          persons={persons}
          todoId={row.original.id}
          onChangePerson={onChangeTodoPerson}
        />
      );
    },
    filterMethod: (filter: Filter, row: TodoTableTodo) => {
      if (!row.person && filter.value[WITHOUT_KEY]) {
        return true;
      }

      return !!row.person && !!filter.value[row.person];
    }
  };
  const tableColumns = [LABEL_COLUMN];

  if (mode === Mode.Universal) {
    tableColumns.push(PROJECT_COLUMN);
  }

  if (mode !== Mode.Onboarding) {
    tableColumns.push(CATEGORY_COLUMN);
  }

  tableColumns.push(FULFILL_FOR_COLUMN);

  if (showPersonColumn) {
    tableColumns.push(personColumn);
  }

  if (mode !== Mode.Onboarding) {
    tableColumns.push(dueDateColumn);
  }
  if (showPriorityColumn) {
    tableColumns.push(priorityColumn);
  }

  tableColumns.push(responsibleColumn, statusColumn);

  if (showTodoStatusColumn) {
    tableColumns.push(todoDocumentStatusColumn);
  }

  // replace header text with custom component with sorting icons
  const tableColumnsWithUpdatedHeaders = tableColumns.map<
    Column<TodoTableTodo>
  >((column) => {
    const text = column.Header as string;

    return {
      ...column,
      Header: (data) => (
        <TodosHeader
          active={sortedColumns && sortedColumns[0].id === data.column.id}
          desc={sortedColumns && sortedColumns[0].desc}
          text={text}
        />
      )
    };
  });

  return tableColumnsWithUpdatedHeaders;
}

interface TodosHeaderProps {
  text: string;
  active: boolean;
  desc: boolean;
}

function TodosHeader({ text, active, desc }: TodosHeaderProps) {
  let sortingIcon = <Icon name={IconName.Unsorted} />;
  if (active) {
    if (desc) {
      sortingIcon = <Icon name={IconName.SortDesc} />;
    } else {
      sortingIcon = <Icon name={IconName.SortAsc} />;
    }
  }

  return (
    <div className="header-wrapper">
      <span className="header-text">{text}</span>
      <div className="sorting-icon">{sortingIcon}</div>
    </div>
  );
}

interface LabelCellProps {
  value: string;
}

function LabelCell({ value }: LabelCellProps) {
  if (!value) {
    return null;
  }

  return (
    <React.Fragment>
      <span
        className="label-cell-text"
        {...anchorTooltip({ content: value, delayShow: 500 })}
      >
        {value}
      </span>
    </React.Fragment>
  );
}

interface CategoryCellProps {
  value: string;
}

function CategoryCell({ value }: CategoryCellProps) {
  const categoryKey = Object.keys(CLUSTERS).find(
    (categoryKey) => CLUSTERS[categoryKey].value === value
  );
  return (
    <LabelCell value={categoryKey ? CLUSTERS[categoryKey].displayName : ""} />
  );
}

interface PriorityCellProps {
  priorityValue: Priority;
  id: number;
  label: string;
  onChangePriority: (priorityValue: Priority, id: number) => void;
}

export function PriorityCell({
  priorityValue,
  id,
  label,
  onChangePriority
}: PriorityCellProps) {
  const cssSafeLabel = makeSafeForCSS(label);
  const labelClassName = `priority-cell-${cssSafeLabel}`;
  const uniqueLabelClassName = `priority-cell-${cssSafeLabel}-${id}`;

  return (
    <div className={`priority-cell ${labelClassName} ${uniqueLabelClassName}`}>
      <select
        className="cell-dropdown form-control m-input"
        value={priorityValue}
        onChange={(event) =>
          onChangePriority(event.target.value as Priority, id)
        }
      >
        <option value={PRIORITIES.HIGH.value.toString()}>
          {PRIORITIES.HIGH.displayName}
        </option>
        <option value={PRIORITIES.MEDIUM.value.toString()}>
          {PRIORITIES.MEDIUM.displayName}
        </option>
        <option value={PRIORITIES.LOW.value.toString()}>
          {PRIORITIES.LOW.displayName}
        </option>
        <option value={PRIORITIES.UNKNOWN.value.toString()}>
          {PRIORITIES.UNKNOWN.displayName}
        </option>
      </select>
    </div>
  );
}

export interface TodoStatusCellProps {
  status: TodoStatus;
  id: number;
  label: string;
  onChangeStatus: (status: TodoStatus, todoId: number) => void;
}

export function TodoStatusCell({
  status,
  id,
  label,
  onChangeStatus
}: TodoStatusCellProps) {
  const isStaff = useShouldShowStaffView();
  const cssSafeLabel = makeSafeForCSS(label);
  const labelClassName = `status-cell-${cssSafeLabel}`;
  const uniqueLabelClassName = `status-cell-${cssSafeLabel}-${id}`;
  const choices = getTodoStatusChoices(status, label, isStaff);
  const disabled = !isStaff && status === TodoStatus.NotRelevant;

  return (
    <div className={`status-cell ${labelClassName} ${uniqueLabelClassName}`}>
      <select
        className="cell-dropdown form-control m-input"
        disabled={disabled}
        value={status.toString()}
        onChange={(e) => onChangeStatus(e.target.value as TodoStatus, id)}
      >
        {choices.map((choice) => (
          <option key={choice.value} value={choice.value}>
            {choice.displayName}
          </option>
        ))}
      </select>
    </div>
  );
}

interface TodoDocumentStatusCellProps {
  status: boolean;
  id: number;
  label: string;
  onChangeTodoStatus: (todoId: number) => void;
  isStaff: boolean;
}

export function TodoDocumentStatusCell({
  status,
  id,
  label,
  onChangeTodoStatus,
  isStaff
}: TodoDocumentStatusCellProps) {
  const cssSafeLabel = makeSafeForCSS(label);
  const labelClassName = `status-cell-${cssSafeLabel}`;
  const uniqueLabelClassName = `status-cell-${cssSafeLabel}-${id}`;

  return (
    <div className={`status-cell ${labelClassName} ${uniqueLabelClassName}`}>
      {isStaff ? (
        <select
          className="cell-dropdown form-control m-input"
          value={status.toString()}
          onChange={() => onChangeTodoStatus(id)}
        >
          <option value={TODO_DOCUMENT_STATUS.OPEN.value.toString()}>
            {TODO_DOCUMENT_STATUS.OPEN.displayName}
          </option>
          <option
            value={TODO_DOCUMENT_STATUS.DOCUMENTS_PROVIDED.value.toString()}
          >
            {TODO_DOCUMENT_STATUS.DOCUMENTS_PROVIDED.displayName}
          </option>
        </select>
      ) : (
        <span>
          {status
            ? TODO_DOCUMENT_STATUS.DOCUMENTS_PROVIDED.displayName
            : TODO_DOCUMENT_STATUS.OPEN.displayName}
        </span>
      )}
    </div>
  );
}

function isNotChangeable(todo: TodoTableTodo) {
  if (!todo.regulatoryDuty) {
    return false;
  }
  return todo.regulatoryDuty.category !== RegulatoryDutyCategory.Premium;
}

export { buildTableColumns };
