import { backendDateOrDateTimeToLuxonDateTime } from "./backendDateOrDateTimeToLuxonDateTime";
import { sortDateTime } from "./sortDateTime";

/** Sorting function for backend Dates or DateTimes. Accounts for undefined dates. */
export function sortBackendDates(a: string | undefined, b: string | undefined) {
  if (!a) {
    return 1;
  } else if (!b) {
    return -1;
  }

  const aDateTime = backendDateOrDateTimeToLuxonDateTime(a);
  const bDateTime = backendDateOrDateTimeToLuxonDateTime(b);

  return sortDateTime(aDateTime, bDateTime);
}
