import _ from "lodash";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import ReactDataSheet from "react-datasheet";
import "react-datasheet/lib/react-datasheet.css";
import { zip } from "../../utils/data-utils";
import { luxonDateTimeToBackendDateOrDateTime } from "../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import type { Frequency } from "../../utils/enums";
import { showToast } from "../../utils/toast";
import { Button } from "../Buttons/Button/Button";
import { CounterDataViewMode } from "../CounterDataView/counter-data-view-types";
import "./DataSheet.scss";
import { toStr } from "./data-sheet-utils";

export type DataValue = number | null;
export type Index = Array<[string]> | Array<[string, string]>;

//We use the mode here
//we could also use the length of the first element
//but that does not work with empty arrays
//we postulate that the mode defines the IndexType
export function isDoubleIndex(
  index: Index,
  mode: CounterDataViewMode
): index is Array<[string, string]> {
  return mode === CounterDataViewMode.DoubleIndex;
}

export function isSingleIndex(
  index: Index,
  mode: CounterDataViewMode
): index is Array<[string]> {
  return mode !== CounterDataViewMode.DoubleIndex;
}

// for ChangedValue and CounterData: ids are number for gas connections and string for meters
export type ChangedValue = {
  index: Array<string>;
  columnId: number | string;
  value: DataValue;
};

export type CounterData = {
  frequency?: Frequency;
  header: Record<number | string, Array<string>>;
  index: Index; // ISO
  indexHeader: Index; // e.g. [["Von"], ["Bis"]]
  labels?: Record<number | string, string>;
  values: Record<number | string, Array<DataValue>>;
};

export type GridElement = {
  value: string | DataValue;
  changed?: boolean;
  readOnly?: boolean;
};

export interface DataSheetProps {
  mode: CounterDataViewMode;
  initialData: CounterData;
  changedValues?: Array<ChangedValue>;
  readOnly?: boolean;
  pageSize: number;
  initialPage?: number;
  onUpdateChangedValues?: (changedValues: Array<ChangedValue>) => void;
  onUpdateChangedIndex?: (
    oldIndexPos: number,
    newIndex: Array<string>,
    changedValues: Array<ChangedValue>
  ) => Array<ChangedValue>;
  customValueRenderer?: (
    cell: GridElement,
    index: number,
    secondIndex: number
  ) => string;
}

const DATE_FORMATS = [
  "dd.MM.yyyy",
  "d.M.yyyy",
  "dd.MM.yyyy HH:mm:ss",
  "d.M.yyyy H:m:s",
  "dd.MM.yyyy HH:mm",
  "d.M.yyyy H:m"
];

export function DataSheet(props: DataSheetProps) {
  const {
    mode,
    initialData,
    changedValues = [],
    readOnly = false,
    pageSize,
    initialPage = 0,
    onUpdateChangedValues,
    onUpdateChangedIndex,
    customValueRenderer
  } = props;
  const dataIds = Object.keys(initialData.header);

  const numberValues = initialData.index.length;

  const [page, setPage] = useState<number>(
    getInitialPageNumber(numberValues, pageSize, initialPage)
  );

  useEffect(() => {
    setPage(getInitialPageNumber(numberValues, pageSize, initialPage));
  }, [initialData, numberValues, pageSize, initialPage]);

  if (numberValues === 0) {
    return <div>Keine Daten vorhanden</div>;
  }

  const numberPages = getNumberPages(numberValues, pageSize);
  const hasPagination = numberPages > 0;

  const rowIndexOffset = initialData.index[0].length;
  const rowHeaderOffset = initialData.header[dataIds[0]].length;
  const rowPaginationOffset = page * pageSize;

  const headerValuesOrdered = dataIds.map((id) => initialData.header[id]);
  const headerGrid: GridElement[][] = zip(
    ...initialData.indexHeader,
    ...headerValuesOrdered
  ).map((row) =>
    row.map((value) => {
      return {
        value: value,
        readOnly: true
      };
    })
  );

  const indexOnPage = initialData.index.slice(
    page * pageSize,
    (page + 1) * pageSize
  );

  let targetFormat = "dd.MM.yyyy HH:mm:ss";

  if (mode === CounterDataViewMode.SparseIndex) {
    const anyHourSet = indexOnPage.some((indexEntry) =>
      indexEntry.some(
        (indexColumn) =>
          DateTime.fromISO(indexColumn).hour !== 0 ||
          DateTime.fromISO(indexColumn).minute !== 0
      )
    );

    if (!anyHourSet) {
      targetFormat = "dd.MM.yyyy";
    }
  }

  const indexSeries = _.unzip(indexOnPage).map((indexColumn) =>
    indexColumn.map((value) => DateTime.fromISO(value).toFormat(targetFormat))
  );

  const indexGrid: GridElement[][] = indexSeries.map((column) =>
    column.map((value) => {
      return {
        value: value,
        readOnly: mode !== CounterDataViewMode.SparseIndex
      };
    })
  );
  const valueGrid: GridElement[][] = dataIds.map((id) => {
    return initialData.values[id]
      .slice(page * pageSize, (page + 1) * pageSize)
      .map((value) => {
        return {
          value: value
        };
      });
  });
  changedValues.forEach(({ index, columnId, value }) => {
    const columnPos = dataIds.indexOf(columnId.toString());
    const indexPosInPage = indexOnPage.findIndex((v) => _.isEqual(v, index));
    const isInPage = indexPosInPage >= 0;
    if (isInPage && columnPos !== -1) {
      const indexPos = indexPosInPage + rowPaginationOffset;
      const cell = valueGrid[columnPos][indexPos];
      valueGrid[columnPos][indexPosInPage] = {
        ...cell,
        value: value,
        changed: true
      };
    }
  });
  const grid: GridElement[][] = [
    ...headerGrid,
    ...zip(...indexGrid, ...valueGrid)
  ];

  function valueRenderer(cell: GridElement) {
    return toStr(cell.value);
  }

  function parseSparseDate(value: string): DateTime | null {
    for (const format of DATE_FORMATS) {
      const parsedDate = DateTime.fromFormat(value, format);

      if (parsedDate.isValid) {
        if (
          !(
            parsedDate.minute === 0 ||
            parsedDate.minute === 15 ||
            parsedDate.minute === 30 ||
            parsedDate.minute === 45
          ) ||
          parsedDate.second !== 0
        ) {
          return parsedDate.set({
            minute: Math.floor(parsedDate.minute / 15) * 15,
            second: 0
          });
        } else {
          return parsedDate;
        }
      }
    }

    return null;
  }

  function handleChanges(
    changes: Array<{ cell: never; row: number; col: number; value: string }>
  ): void {
    if (readOnly) {
      return;
    }

    let newChangedValues = changedValues;
    changes.forEach(({ row, col, value }) => {
      const indexPos = row - rowHeaderOffset + rowPaginationOffset;
      const index = initialData.index[indexPos];

      if (col < rowIndexOffset) {
        if (onUpdateChangedIndex) {
          const parsedValue = parseSparseDate(value);

          if (parsedValue === null) {
            showToast(
              "error",
              `Bitte geben Sie das Datum in einem der folgenden Formate ein ${DATE_FORMATS.join(
                ", "
              )}.`
            );
            return;
          }

          const newIndex = [...index];
          newIndex[col] = luxonDateTimeToBackendDateOrDateTime(
            parsedValue,
            "ISO 8601 RFC822"
          );
          newChangedValues = onUpdateChangedIndex(
            indexPos,
            newIndex,
            newChangedValues
          );
        }
      } else {
        const columnId = dataIds[col - rowIndexOffset];

        const nonStringValue = parseLocaleNumber(value);

        if (nonStringValue === null && value !== "") {
          showToast(
            "error",
            "Bitte geben Sie einen korrekt formatierten Zahlenwert ein."
          );
          return;
        }

        // remove the value from the list of changes
        // if it's still changed, it will be added again below
        newChangedValues = newChangedValues.filter(
          (change) =>
            !(_.isEqual(change.index, index) && change.columnId === columnId)
        );

        const changed =
          nonStringValue !== initialData.values[columnId][indexPos];
        if (changed) {
          newChangedValues = [
            ...newChangedValues,
            { index, columnId, value: nonStringValue }
          ];
        }
      }
    });

    if (onUpdateChangedValues) {
      onUpdateChangedValues(newChangedValues);
    }
  }

  return (
    <div className="DataSheet">
      {hasPagination && (
        <Pagination numberPages={numberPages} page={page} onSetPage={setPage} />
      )}
      <ReactDataSheet
        attributesRenderer={(cell: GridElement) => {
          // setting a custom attribute to indicate cell has changed
          // classes are set dynamically by the underlying library
          // overriding this caused some unwanted interferences
          const mark = cell.changed ? "changed" : "";
          return { mark };
        }}
        data={grid}
        dataRenderer={valueRenderer}
        valueRenderer={customValueRenderer || valueRenderer}
        onCellsChanged={handleChanges}
      />
      {hasPagination && (
        <Pagination numberPages={numberPages} page={page} onSetPage={setPage} />
      )}
    </div>
  );
}

function getNumberPages(numberValues: number, pageSize: number): number {
  const numberPagesFloat = numberValues / pageSize;
  let numberPages = Math.floor(numberPagesFloat);
  if (numberPagesFloat === Math.floor(numberPagesFloat)) {
    numberPages--;
  }
  return numberPages;
}

function getInitialPageNumber(
  numberValues: number,
  pageSize: number,
  initialPage: number
): number {
  return initialPage === -1
    ? getNumberPages(numberValues, pageSize)
    : initialPage;
}

function parseLocaleNumber(stringNumber: string) {
  const thousandSeparator = (1111).toLocaleString().replace(/1/g, "");
  const decimalSeparator = (1.1).toLocaleString().replace(/1/g, "");

  const parsedValue = parseFloat(
    stringNumber
      .replace(new RegExp("\\" + thousandSeparator, "g"), "")
      .replace(new RegExp("\\" + decimalSeparator), ".")
  );
  return isNaN(parsedValue) || parsedValue < 0 ? null : parsedValue;
}

function Pagination({
  page,
  numberPages,
  onSetPage
}: {
  page: number;
  numberPages: number;
  onSetPage: (x: number) => void;
}) {
  return (
    <div className="counter-data-pagination">
      <Button disabled={page === 0} onClick={() => onSetPage(page - 1)}>
        Zurück
      </Button>
      Seite {page + 1} / {numberPages + 1}
      <Button
        disabled={page === numberPages}
        onClick={() => onSetPage(page + 1)}
      >
        Vor
      </Button>
    </div>
  );
}
