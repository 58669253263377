import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { THEME_VARS } from "../../../../../../../utils/constants";
import { Alert, AlertColor } from "../../../../../../Alert/Alert";
import { Icon } from "../../../../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../../../../BuildingBlocks/Icon/types";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../../../../../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../../../../../../Buttons/Button/Button";
import { DummyLink } from "../../../../../../DummyLink/DummyLink";
import "./BatchImportErrorDisplay.scss";
import type { BatchImportError } from "../types";
import { ComponentType } from "../types";
import { groupByComponent } from "./utils";

export interface BatchImportErrorDisplayProps {
  errors: Array<BatchImportError>;
}
function BatchImportErrorDisplay({ errors }: BatchImportErrorDisplayProps) {
  const { t } = useTranslation();

  const groupedErrors = groupByComponent(errors);

  return (
    <Alert className="BatchImportErrorDisplay" color={AlertColor.Danger}>
      <div className="error-display-header">
        <div>
          <Icon
            name={IconName.Warning}
            style={{ color: THEME_VARS.dangerColor }}
          />
        </div>
        <strong className="header-text">
          {t("errors.MieterstromBatchImport.ValidationFailed")}
        </strong>
      </div>
      <ul>
        {Object.entries(groupedErrors).map(([componentType, errors]) => {
          if (componentType === "ungrouped") {
            return errors.map((error, index) => (
              <li key={index}>
                {t(`errors.MieterstromBatchImport.${error.name}`)}
              </li>
            ));
          } else {
            return (
              <li key={componentType}>
                <GroupedErrorDisplay
                  componentType={componentType as ComponentType}
                  errors={errors}
                />
              </li>
            );
          }
        })}
      </ul>
    </Alert>
  );
}

function GroupedErrorDisplay({
  componentType,
  errors
}: {
  componentType: ComponentType;
  errors: Array<BatchImportError>;
}) {
  const { t } = useTranslation();
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);

  function toggle() {
    setDetailsModalOpen(!detailsModalOpen);
  }

  function getGroupErrorMessage() {
    switch (componentType) {
      case ComponentType.Connection:
        return t("errors.MieterstromBatchImport.ConnectionCreationError");
      case ComponentType.Consumer:
        return t("errors.MieterstromBatchImport.ConsumerCreationError", {
          amount: errors.length
        });
      case ComponentType.Generator:
        return t("errors.MieterstromBatchImport.GeneratorCreationError", {
          amount: errors.length
        });
      case ComponentType.BatteryStorage:
        return t("errors.MieterstromBatchImport.BatteryStorageCreationError");
      default:
        return "";
    }
  }

  function getDetailsModalTitle() {
    switch (componentType) {
      case ComponentType.Connection:
        return "Fehlerhafte Netzverknüpfungspunkt-Daten";
      case ComponentType.Consumer:
        return "Fehlerhafte Verbraucher-Daten";
      case ComponentType.Generator:
        return "Fehlerhafte Erzeuger-Daten";
      case ComponentType.BatteryStorage:
        return "Fehlerhafte Batteriespeicher-Daten";
      default:
        return "Fehlerhafte Daten";
    }
  }

  return (
    <div>
      {getGroupErrorMessage()}
      {"   "}
      <DummyLink onClick={() => setDetailsModalOpen(true)}>Details</DummyLink>
      <ErrorDetailsModal
        errors={errors}
        isOpen={detailsModalOpen}
        title={getDetailsModalTitle()}
        toggle={toggle}
      />
    </div>
  );
}
function ErrorDetailsModal({
  title,
  errors,
  toggle,
  isOpen
}: {
  errors: Array<BatchImportError>;
  title?: string;
  toggle: () => void;
  isOpen: boolean;
}) {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} size="lg" toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <ul>
          {errors.map((error, index) => (
            <li key={index}>
              {t(`errors.MieterstromBatchImport.${error.name}`)}
            </li>
          ))}
        </ul>
      </ModalBody>
      <ModalFooter>
        <Button color="brand" onClick={toggle}>
          OK
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export { BatchImportErrorDisplay };
