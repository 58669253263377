import type { FieldValues, Path, UseFormSetError } from "react-hook-form";
import { isApiError } from "../../../../utils/isApiError";
import { showToast } from "../../../../utils/toast";

/** Using `setError` from `useForm`, assigns incoming errors from an API response to their respective fields. */
export function setErrorsFromResponseData<T extends FieldValues>(
  error: unknown,
  fieldList: FieldValues,
  setError: UseFormSetError<T>,
  unknownErrorText: string,
  fieldNamePrefix?: string
) {
  if (
    isApiError<Record<keyof T, Array<string>>>(error) &&
    error.response?.data &&
    error.response?.status === 400
  ) {
    const formKeys: ReadonlyArray<string> = Object.keys(fieldList);

    Object.keys(error.response.data).forEach((key) => {
      const keyWithPrefix = fieldNamePrefix ? `${fieldNamePrefix}${key}` : key;

      if (formKeys.includes(keyWithPrefix)) {
        setError(
          keyWithPrefix as Path<T>,
          {
            type: "custom",
            message:
              typeof error.response?.data[key][0] === "string"
                ? error.response?.data[key][0]
                : unknownErrorText
          },
          { shouldFocus: true }
        );
      }
    });

    if (error.response.data["nonFieldErrors"]) {
      showToast("error", error.response.data["nonFieldErrors"]);
    }
    if (error.response.data["non_field_errors"]) {
      showToast("error", error.response.data["non_field_errors"]);
    }
  } else {
    showToast("error", unknownErrorText);
  }
}
