import { useQuery } from "@tanstack/react-query";
import api from "../api";
import urls from "../urls";
import type { Delivery } from "../utils/backend-types";

function useDeliveries(variantId: number) {
  const { isLoading, error, data } = useQuery({
    queryKey: ["deliveries", { variantId }],
    queryFn: () => getDeliveries(variantId)
  });

  async function getDeliveries(variantId) {
    const response = await api.get<Array<Delivery>>(
      urls.api.deliveries(variantId)
    );
    return response.data;
  }

  return {
    isLoading: isLoading,
    error: error,
    deliveries: data || []
  };
}

export { useDeliveries };
