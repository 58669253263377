import { luxonDateTimeToBackendDateOrDateTime } from "../../../../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import type {
  ParkabrechnungContractFormState,
  ParkabrechnungMeteringLocation,
  PoolContractsPayload
} from "../ParkabrechnungContractModal.types";

export function parseFormStateToPayload({
  formState,
  meteringLocations
}: {
  formState: ParkabrechnungContractFormState;
  meteringLocations: Array<ParkabrechnungMeteringLocation>;
}): PoolContractsPayload {
  const meteringLocation = meteringLocations.find(
    (meteringLocation) => meteringLocation.id === formState.meloMaloId
  );
  const feedinMeteringLocation = meteringLocations.find(
    (meteringLocation) => meteringLocation.id === formState.feedinMeloMaloId
  );

  if (formState.startDate === null) {
    const errorMsg = `No start date provided`;
    console.error(errorMsg);
    throw new Error(errorMsg);
  }

  if (formState.endDate === null) {
    const errorMsg = `No end date provided`;
    console.error(errorMsg);
    throw new Error(errorMsg);
  }

  return {
    begin: luxonDateTimeToBackendDateOrDateTime(formState.startDate),
    end: luxonDateTimeToBackendDateOrDateTime(formState.endDate),
    gridFeedoutContract: meteringLocation
      ? {
          billingPeriod: formState.period,
          componentFeedoutShares: formState.components.map((componentId) => ({
            component: {
              id: componentId
            },
            meteringOrMarketLocation: {
              id: meteringLocation.id,
              number: meteringLocation.number
            },
            share: formState[`share-${componentId}`]
          }))
        }
      : undefined,
    gridFeedinContract: feedinMeteringLocation
      ? {
          billingPeriod: formState.feedinPeriod,
          generatorFeedinShares: formState.components.map((componentId) => ({
            generator: {
              id: componentId
            },
            meteringOrMarketLocation: {
              id: feedinMeteringLocation.id
            },
            share: formState[`share-${componentId}`]
          })),
          shareType: formState.feedinDistributionType
        }
      : undefined,
    name: formState.name,
    powerFeedoutContracts:
      typeof formState.powerFeedoutPrice !== "undefined"
        ? [
            {
              price: formState.powerFeedoutPrice,
              begin: luxonDateTimeToBackendDateOrDateTime(formState.startDate),
              end: luxonDateTimeToBackendDateOrDateTime(formState.endDate),
              site: {
                id: formState.site
              }
            }
          ]
        : undefined,
    site: {
      id: formState.site
    }
  };
}
