import { useQuery } from "@tanstack/react-query";
import type { UseQueryOptions } from "@tanstack/react-query";

import api from "../api";
import urls from "../urls";
import type { SiteWizard } from "../utils/backend-types";
import { camelize } from "../utils/SnakeCamelConversion";

export function useSiteWizard(
  siteId: number | undefined,
  options?: Partial<UseQueryOptions<SiteWizard>>
) {
  const {
    data: siteWizard,
    isLoading,
    error
  } = useQuery({
    queryKey: ["siteWizard", { siteId }],
    queryFn: () => fetchSiteWizard(siteId),
    ...options,
    enabled: !!siteId && (!options || options.enabled)
  });

  async function fetchSiteWizard(siteId: number | undefined) {
    if (typeof siteId === "undefined") {
      return undefined;
    }

    const siteWizardUrl = urls.api.siteWizard(siteId);
    const response = await api.get(siteWizardUrl);
    let formattedData;

    if (response.data) {
      formattedData = response.data;
      if (formattedData.phases) {
        Object.keys(formattedData.phases).forEach((phase) => {
          formattedData.phases[phase]?.missingData?.forEach(
            (missingData, dataIndex) => {
              missingData.missingFields.forEach((missingField, fieldIndex) => {
                formattedData.phases[phase].missingData[
                  dataIndex
                ].missingFields[fieldIndex] = camelize(missingField);
              });
            }
          );
          formattedData.phases[phase]?.exclusiveOrFields?.forEach(
            (group, groupIndex) => {
              group.forEach((field, index) => {
                formattedData.phases[phase].exclusiveOrFields[groupIndex][
                  index
                ] = camelize(field);
              });
            }
          );
        });
      }
    }

    return formattedData;
  }

  async function updateOnboardingPhaseDoneStatus(
    onboardingPhase: string,
    isDone: boolean
  ) {
    return api.post(urls.api.onboardingPhaseUpdateDone(), {
      site: siteId,
      done: isDone,
      phase: onboardingPhase
    });
  }

  async function updateOnboardingProcessDoneStatus(isDone: boolean) {
    return api.post(urls.api.onboardingProcessUpdateDone(), {
      site: siteId,
      done: isDone
    });
  }

  return {
    siteWizard,
    updateOnboardingPhaseDoneStatus,
    updateOnboardingProcessDoneStatus,
    isLoading,
    error
  };
}
