import { Radio } from "@mantine/core";
import classNames from "classnames";
import React from "react";
import { RadioGroup, type RadioGroupProps } from "../../RadioGroup/RadioGroup";

interface RadioFieldProps
  extends Pick<RadioGroupProps, "aria-label" | "id" | "name" | "value"> {
  className?: string;
  disabled?: boolean;
  errorText?: string;
  invalid?: boolean;
  layout?: "horizontal" | "vertical";
  useCompactErrorMessage?: boolean;
  choices: Array<{ value: string; displayName: string }>;
  warned?: boolean;
  onChange: (newValue: string | null) => void;
}

function RadioField(
  {
    "aria-label": ariaLabel,
    className,
    disabled,
    errorText,
    id,
    invalid,
    layout = "horizontal",
    name,
    useCompactErrorMessage,
    choices,
    warned,
    value,
    onChange
  }: RadioFieldProps,
  ref?: React.Ref<HTMLInputElement>
) {
  const backupId = React.useId();
  const radioGroupId = id || backupId;

  return (
    <div
      className={classNames("RadioField", className, {
        "is-invalid": invalid,
        warning: !invalid && warned
      })}
    >
      <RadioGroup
        aria-label={ariaLabel}
        errorText={useCompactErrorMessage ? undefined : errorText}
        id={radioGroupId}
        layout={layout}
        name={name}
        ref={ref}
        value={value}
        onChange={onChange}
      >
        {choices.map((choice) => (
          <Radio
            disabled={disabled}
            key={choice.value}
            label={choice.displayName}
            value={choice.value}
          />
        ))}
      </RadioGroup>
    </div>
  );
}

const ForwardedRefRadioField = React.forwardRef(RadioField);

export { ForwardedRefRadioField as RadioField, RadioFieldProps };
