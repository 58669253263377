import React from "react";

import urls from "../../urls";
import { Icon } from "../BuildingBlocks/Icon/Icon";
import { IconName } from "../BuildingBlocks/Icon/types";

import "./OptiNodeHelpIcon.scss";

const HELP_LINK = {
  text: "opti.node-Hilfe",
  href: urls.optiSupport()
};
function OptiNodeHelpIcon() {
  return (
    <a
      aria-label={HELP_LINK.text}
      className="OptiNodeHelpIcon"
      href={HELP_LINK.href}
      rel="noreferrer"
      target="_blank"
      title={HELP_LINK.text}
    >
      <Icon name={IconName.QuestionCircle} />
    </a>
  );
}

export { OptiNodeHelpIcon };
