import React from "react";
import { AnimatedLoadingIcon } from "../../../../Icons/AnimatedLoadingIcon/AnimatedLoadingIcon";

function MeterSuggestionsLoading() {
  return (
    <div className="MeterDataLoadingWrapper">
      <div className="MeterDataLoader">
        <AnimatedLoadingIcon />
      </div>
    </div>
  );
}

export { MeterSuggestionsLoading };
