import { useQueryClient } from "@tanstack/react-query";
import classnames from "classnames";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "../../../../../components/BuildingBlocks/Dropdown/Dropdown";
import { useDownloadGuaranteeOfAttorney } from "../../../../../hooks/useDownloadGuaranteeOfAttorney";
import { useMSBFiles } from "../../../../../hooks/useMSBFiles";
import type {
  TodoFile,
  WizardMissingData
} from "../../../../../utils/backend-types";
import { Icon } from "../../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../../BuildingBlocks/Icon/types";
import { Button } from "../../../../Buttons/Button/Button";
import { SpinButton } from "../../../../Buttons/SpinButton/SpinButton";
import { openErrorAlertPopup } from "../../../../ErrorAlertPopup/openErrorAlertPopup";
import { AnimatedLoadingIcon } from "../../../../Icons/AnimatedLoadingIcon/AnimatedLoadingIcon";
import { SYSTEM_USER_NAME } from "../../../../Todos/common";
import { AssignmentType } from "../../OnboardingWizard";
import { UserDocuments } from "./UserDocuments";
import { getLatestDocuments } from "./utils/getLatestDocuments";
import "./vollmachtAssignment.scss";

type VollmachtAssignmentProps = {
  missingData: Array<WizardMissingData>;
  done: boolean;
  isNext: boolean;
  siteId: number;
  isDisabled: boolean;
  updateSiteWizard: (type: AssignmentType) => Promise<void>;
  onSiteWizardUpdated: () => Promise<void>;
  hasMissingDocuments: boolean;
};

function VollmachtAssignment({
  missingData,
  done,
  isNext,
  siteId,
  isDisabled,
  hasMissingDocuments,
  onSiteWizardUpdated,
  updateSiteWizard
}: VollmachtAssignmentProps) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const { msbFiles, addMSBFile } = useMSBFiles(siteId);
  const { generateAndDownloadGuaranteeOfAttorney } =
    useDownloadGuaranteeOfAttorney(siteId);
  const [isSpinning, setIsSpinning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  const hasMissingData = missingData && missingData.length > 0;

  function onClickSubmit() {
    setIsSpinning(true);
    updateSiteWizard(AssignmentType.Vollmacht).then(() => {
      setIsSpinning(false);
    });
  }

  function handleDownload() {
    setIsLoading(true);
    generateAndDownloadGuaranteeOfAttorney().finally(() => {
      setIsLoading(false);
    });
  }

  function currentUserDocuments(): Array<TodoFile> | undefined {
    if (!msbFiles) {
      return;
    }

    const userGeneratedContent = msbFiles.todoFiles.filter((file) => {
      return file.createdBy?.name !== SYSTEM_USER_NAME;
    });

    const currentDocuments = getLatestDocuments(userGeneratedContent, 5);
    return currentDocuments;
  }

  function handleClickUpload() {
    const input = document.getElementById("uploadable-file-field-upload-input");

    if (input) {
      input.click();
    }
  }

  function handleUpload(event) {
    setIsLoading(true);
    addMSBFile({
      siteId: siteId,
      newFiles: Array.from(event.target.files),
      asSystemUser: false
    })
      .then((results) => {
        let noErrors = true;
        results.forEach((result) => {
          if (result.status !== "fulfilled") {
            noErrors = false;
            openErrorAlertPopup(result.reason);
          }
        });

        if (noErrors) {
          queryClient.refetchQueries({
            queryKey: ["MSBFiles", { siteId }]
          });
        }
      })
      .finally(() => {
        onSiteWizardUpdated().finally(() => {
          setIsLoading(false);
        });
      });
  }

  return (
    <div className="vollmacht-assignment-container">
      <UserDocuments documents={currentUserDocuments()} />
      {!done && hasMissingData ? (
        <div className="missing-data-container">
          <Button
            className="missing-data-button"
            color={isNext ? "brand" : "secondary"}
            disabled={isDisabled}
            tag={Link}
            to={"vollmacht"}
          >
            Fehlende Daten ergänzen
          </Button>
        </div>
      ) : isLoading ? (
        <AnimatedLoadingIcon />
      ) : (
        !done && (
          <div className="missing-data-container">
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle
                caret={true}
                className={classnames(
                  "missing-data-button",
                  "msb-dropdown-button",
                  {
                    "missing-data-button--secondary-with-border":
                      !hasMissingDocuments
                  }
                )}
                color={isNext && hasMissingDocuments ? "brand" : "secondary"}
                disabled={isDisabled}
              >
                Vollmacht zur Verfügung stellen
              </DropdownToggle>
              <DropdownMenu className="msb-dropdown-menu">
                <DropdownItem onClick={() => handleDownload()}>
                  <Icon name={IconName.File} />
                  Vorlage herunterladen
                </DropdownItem>
                <DropdownItem onClick={() => handleClickUpload()}>
                  <input
                    hidden
                    id="uploadable-file-field-upload-input"
                    type="file"
                    onChange={handleUpload}
                  />
                  <Icon name={IconName.FileText} />
                  Vollmacht hochladen
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            {!hasMissingDocuments && (
              <SpinButton
                className="missing-data-button"
                color={isNext ? "brand" : "secondary"}
                disabled={isDisabled}
                spin={isSpinning}
                onClick={() => onClickSubmit()}
              >
                Als erledigt markieren
              </SpinButton>
            )}
          </div>
        )
      )}
    </div>
  );
}

export { VollmachtAssignment };
