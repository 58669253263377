import { Group, Stack, Title } from "@mantine/core";
import { RichTextEditor } from "@mantine/tiptap";
import { Document } from "@tiptap/extension-document";
import { HardBreak } from "@tiptap/extension-hard-break";
import { Mention } from "@tiptap/extension-mention";
import { Paragraph } from "@tiptap/extension-paragraph";
import { Placeholder } from "@tiptap/extension-placeholder";
import { Text } from "@tiptap/extension-text";
import { useEditor } from "@tiptap/react";
import classNames from "classnames";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, buttonColors } from "../../../../Buttons/Button/Button";
import { IconButton } from "../../../../Buttons/IconButton/IconButton";
import { IconName } from "../../../Icon/types";
import { InputWrapper } from "../../utils/InputWrapper";
import { CharacterConstraint } from "./extensions/CharacterConstraint";
import { PasteTransform } from "./extensions/PasteTransform";
import { getSuggestionConfig } from "./utils/getSuggestionConfig";
import "./ExpressionEditor.scss";

function parseVariablesIntoMentions(
  value: string | null | undefined,
  variables: Array<string>
) {
  if (!value) {
    return "";
  }

  let enrichedValue = value;
  for (const variable of variables) {
    enrichedValue = enrichedValue.replace(
      new RegExp(`\\b${variable}\\b`, "g"),
      // note that the class will be overriden inside the actual editor by the `renderHTML` call below
      // but we also want to use the same class to show the field value even before the editor is rendered
      `<span class="expression-variable" data-type="mention" data-id="${variable}">${variable}</span>`
    );
  }

  return enrichedValue;
}

interface ExpressionEditorProps {
  id?: string;
  name?: string;
  value?: string | null;
  errorText?: string;
  invalid?: boolean;
  warned?: boolean;
  variables: Array<string>;
  operators: Array<string>;
  otherElements: Array<string>;
  onChange: (value: string | undefined) => void;
}

function ExpressionEditor({
  id,
  value,
  errorText,
  invalid,
  warned,
  variables,
  operators,
  otherElements,
  onChange
}: ExpressionEditorProps) {
  const { t } = useTranslation();

  const [isEditing, setIsEditing] = useState(false);

  const htmlEnrichedValue = parseVariablesIntoMentions(
    value,
    variables
  ).replace(/(\r\n|\r|\n)/g, "<br/>");

  const editor = useEditor({
    extensions: [
      CharacterConstraint.configure({
        allowedOperatorsAndFunctions: operators.concat(otherElements),
        errorMessages: {
          invalidVariablesOrFunctions: t(
            "errors.ExpressionEditor.InvalidVariablesOrFunctions"
          )
        }
      }),
      Document.extend({
        // this prevents additional paragraphs, so we don't need the overhead of serializing them
        content: "block"
      }),
      HardBreak.extend({
        addKeyboardShortcuts() {
          return {
            // reroute the shortcut since we only create one single paragraph -> line breaks = <br/>
            Enter: () => this.editor.commands.setHardBreak()
          };
        }
      }),
      Mention.configure({
        renderHTML({ node }) {
          const varLabel = node.attrs.label ?? node.attrs.id;
          return [
            "span",
            {
              class: "expression-variable",
              "data-type": "mention",
              "data-id": varLabel
            },
            `${varLabel}`
          ];
        },
        renderText({ node }) {
          return `${node.attrs.label ?? node.attrs.id}`;
        },
        deleteTriggerWithBackspace: true,
        suggestion: getSuggestionConfig(variables)
      }),
      Paragraph,
      PasteTransform.configure({
        allowedVariables: variables
      }),
      Placeholder.configure({
        placeholder: "Geben Sie hier die gewünschte Formel ein ..."
      }),
      Text
    ],
    content: htmlEnrichedValue,
    onUpdate({ editor }) {
      onChange(editor.getText());
    }
  });

  function insertVariable(variable: string) {
    editor
      ?.chain()
      .focus()
      .insertContent([
        { type: "mention", attrs: { id: variable } },
        { type: "text", text: " " }
      ])
      .run();
  }

  function insertText(text: string) {
    editor
      ?.chain()
      .focus()
      .insertContent([{ type: "text", text }])
      .run();
  }

  function clearExpression() {
    editor?.chain().focus().clearContent().run();
    onChange(undefined);
  }

  return (
    <Group className="ExpressionEditor" grow>
      <InputWrapper className="expression-inputwrapper" errorText={errorText}>
        {isEditing ? (
          <>
            <RichTextEditor
              className={classNames("rte-root", {
                "is-invalid": invalid,
                warning: !invalid && warned
              })}
              data-testid="expression-rte"
              editor={editor}
              id={id}
            >
              <RichTextEditor.Content />
            </RichTextEditor>
            <Button
              className="expression-clear"
              color={buttonColors.danger}
              noBackground
              noBorder
              onClick={clearExpression}
            >
              Formel löschen
            </Button>
          </>
        ) : value && value !== "" ? (
          <div
            className="expression-value-display form-control"
            dangerouslySetInnerHTML={{ __html: htmlEnrichedValue }}
            onClick={() => setIsEditing(true)}
          />
        ) : (
          <IconButton
            color={buttonColors.brand}
            iconName={IconName.Plus}
            noBackground
            noBorder
            onClick={() => setIsEditing(true)}
          >
            Formel hinzufügen
          </IconButton>
        )}
      </InputWrapper>
      <Stack justify={!isEditing ? "space-around" : undefined}>
        {isEditing ? (
          <>
            <Title fw="400" order={5}>
              Variablen
            </Title>
            <Group>
              {variables.map((variable, i) => (
                <Button key={i} onClick={() => insertVariable(variable)}>
                  {variable}
                </Button>
              ))}
            </Group>
            <Title fw="400" order={5}>
              Operatoren
            </Title>
            <Group>
              {operators.map((operator, i) => (
                <Button key={i} onClick={() => insertText(operator)}>
                  {operator.replace(/\(.*\)/, "")}
                </Button>
              ))}
            </Group>
            <Title fw="400" order={5}>
              Andere
            </Title>
            <Group>
              {otherElements.map((otherElement, i) => (
                <Button key={i} onClick={() => insertText(otherElement)}>
                  {otherElement.replace(/\(.*\)/, "")}
                </Button>
              ))}
            </Group>
          </>
        ) : value && value !== "" ? (
          <IconButton
            color={buttonColors.brand}
            iconName={IconName.Pencil}
            noBackground
            noBorder
            onClick={() => setIsEditing(true)}
          >
            Formel editieren
          </IconButton>
        ) : null}
      </Stack>
    </Group>
  );
}

export { ExpressionEditor, ExpressionEditorProps };
