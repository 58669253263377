export enum IconName {
  Admin = "admin",
  AngleDown = "angle-down",
  AngleLeft = "angle-left",
  AngleRight = "angle-right",
  AngleUp = "angle-up",
  Analytics = "analytics",
  AreaChart = "area-chart",
  ArrowCircleLeft = "arrow-circle-left",
  ArrowCircleRight = "arrow-circle-right",
  ArrowDown = "arrow-down",
  ArrowUp = "arrow-up",
  Billing = "billing",
  Building = "building",
  Calendar = "calendar",
  CaretDown = "caret-down",
  CaretLeft = "caret-left",
  CaretRight = "caret-right",
  CaretUp = "caret-up",
  Chain = "chain",
  Check = "check",
  CheckCircle = "check-circle",
  Circle = "circle",
  Clipboard = "clipboard",
  Close = "close",
  CloudDownload = "cloud-download",
  Cogs = "cogs",
  Cogwheel = "cogwheel",
  Cogwheel1 = "cogwheel-1",
  Cogwheel2 = "cogwheel-2",
  Contract = "contract",
  Copy = "copy",
  Crosshairs = "crosshairs",
  Download = "download",
  Download2 = "download2",
  Edit = "edit",
  Envelope = "envelope",
  ExclamationCircle = "exclamation-circle",
  ExclamationOrange = "exclamation-orange",
  ExclamationRed = "exclamation-red",
  ExternalLink = "external-link",
  Eye = "eye",
  Fax = "fax",
  File = "file",
  FileCode = "file-code",
  FileExcel = "file-excel",
  FileImage = "file-image",
  FileJSON = "file-json",
  FilePdf = "file-pdf",
  FileText = "file-text",
  FileWord = "file-word",
  Filter = "filter",
  FlowArrow = "flow-arrow",
  Graph = "graph",
  GraphMeter = "graph-meter",
  GuaranteeOfOrigin = "guarantee-of-origin",
  Home = "home",
  Info = "info",
  InfoCircle = "info-circle",
  Leaf = "leaf",
  Lightbulb = "lightbulb",
  List = "list",
  Magic = "magic",
  Map = "map",
  MapPin = "map-pin",
  Minus = "minus",
  More = "more",
  NoSearchResults = "no-search-results",
  OptiCharge = "opti-charge",
  Paragraph6 = "paragraph-6",
  FileMinus = "file-minus",
  Paste = "paste",
  Pause = "pause",
  Pencil = "pencil",
  Phone = "phone",
  PieChart = "pie-chart",
  Pins = "pins",
  Plus = "plus",
  PlusCircle = "plus-circle",
  Projects = "projects",
  Question = "question",
  QuestionCircle = "question-circle",
  QuestionCircle2 = "question-circle2",
  Refresh = "refresh",
  Refresh2 = "refresh2",
  Report = "report",
  RevenueMonitoring = "revenue-monitoring",
  Rocket = "rocket",
  RotateRight = "rotate-right",
  RoundedCheckGreen = "rounded-check-green",
  Save = "save",
  Search = "search",
  Share = "share",
  Sliders = "sliders",
  SolarPowerGenerator = "solar-power-generator",
  SortAsc = "sort-asc",
  SortDesc = "sort-desc",
  Spinner = "spinner",
  SpinnerSpinning = "spinner-spinning",
  Star = "star",
  Table = "table",
  TextElement = "text-element",
  ThumbTack = "thumb-tack",
  Times = "times",
  Todos = "todos",
  Trash = "trash",
  Unsorted = "unsorted",
  Upload = "upload",
  Warning = "warning",
  WindGenerator = "wind-generator"
}

export enum IconComponentType {
  LaIcon = "LaIcon",
  FlatIcon = "FlatIcon",
  ImgSrc = "ImgSrc",
  OptiIcon = "OptiIcon"
}
