import * as Sentry from "@sentry/browser";
import type { Identity } from "../../EdaTable/Mbk";
import { EDA_FORM_FIELDS } from "../CreateEdaVersionModal.constants";
import type {
  EdaVersionFormValues,
  IdentityDefinitionList
} from "../CreateEdaVersionModal.types";

export function createIdentity(
  formValues: EdaVersionFormValues,
  identityList: IdentityDefinitionList,
  siteId: number
): Identity {
  if (!formValues[EDA_FORM_FIELDS.artDerEnergieMenge]) {
    throw new Error("Type needed for createIdentity but not provided");
  }

  return createIdentityFromFormValues({
    company: formValues[EDA_FORM_FIELDS.company]
      ? [formValues[EDA_FORM_FIELDS.company]]
      : undefined,
    component: formValues[EDA_FORM_FIELDS.component],
    connection: formValues[EDA_FORM_FIELDS.connection],
    consumer: formValues[EDA_FORM_FIELDS.consumer]
      ? [formValues[EDA_FORM_FIELDS.consumer]]
      : undefined,
    description: formValues[EDA_FORM_FIELDS.description],
    fromCompany: formValues[EDA_FORM_FIELDS.fromCompany],
    generator: formValues[EDA_FORM_FIELDS.generator]
      ? [formValues[EDA_FORM_FIELDS.generator]]
      : undefined,
    identityList,
    rootMeter: formValues[EDA_FORM_FIELDS.rootMeter],
    site: siteId,
    supplied: formValues[EDA_FORM_FIELDS.supplied],
    supplier: formValues[EDA_FORM_FIELDS.supplier],
    toCompany: formValues[EDA_FORM_FIELDS.toCompany],
    type: formValues[EDA_FORM_FIELDS.artDerEnergieMenge]
  });
}

type CreateIdentityParams = {
  identityList: IdentityDefinitionList;
  type: string;
  connection?: number;
  generator?: number[];
  consumer?: number[];
  company?: number[];
  site?: number;
  rootMeter?: number;
  supplier?: number;
  supplied?: number;
  toCompany?: number;
  fromCompany?: number;
  component?: number;
  description?: string;
};

function createIdentityFromFormValues({
  company,
  connection,
  consumer,
  component,
  description,
  fromCompany,
  generator,
  identityList,
  rootMeter,
  site,
  supplied,
  supplier,
  toCompany,
  type
}: CreateIdentityParams): Identity {
  if (!(type in identityList)) {
    throw new Error(`Identity type ${type} not found in identityList`);
  }

  const identity: Identity = { type, displayName: "" };

  const identityType = identityList[type];
  const keys = Object.keys(identityType).filter((key) => key !== "displayName");

  for (const key of keys) {
    switch (identityType[key]) {
      case "optinode.webserver.configurator.models.Connection":
        identity[key] = connection;
        break;
      case "optinode.webserver.configurator.models.Generator":
        if (!generator || generator.length == 0)
          throw new Error("Generator needed for identity but not provided");
        identity[key] = generator[0];
        break;
      case "optinode.webserver.configurator.models.Consumer":
        if (!consumer || consumer.length == 0)
          throw new Error("Consumer needed for identity but not provided");
        identity[key] = consumer[0];
        break;
      case "optinode.webserver.configurator.models.Site":
        identity[key] = site;
        break;
      case "optinode.webserver.configurator.models.Meter":
        identity[key] = rootMeter;
        break;
      case "optinode.webserver.configurator.models.Person":
        if (key === "toCompany") {
          identity[key] = toCompany;
        }
        if (key === "fromCompany") {
          identity[key] = fromCompany;
        }
        if (key === "company") {
          if (!company || company.length == 0)
            throw new Error("Company needed for identity but not provided");
          identity[key] = company[0];
        }
        if (key === "supplier") {
          identity[key] = supplier;
        }
        if (key === "supplied") {
          identity[key] = supplied;
        }
        break;
      case "builtins.str":
        identity[key] = description;
        break;
      case "optinode.webserver.configurator.models.ComponentBase":
        identity[key] = component;
        break;
      default:
        Sentry.captureMessage(
          `IdentityDefinition ${identityType[key]} not supported`
        );
    }
  }

  return identity;
}
