import type { Site } from "../../../../utils/backend-types";
import type { FormInputData } from "../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { AcceptedFileType } from "../../../BuildingBlocks/Forms/FormFields/FileUpload/FileUpload";

export type SiteForForm = Site & {
  is_mieterstrom_site?: boolean;
  template?: Array<File>;
};
export const SITE_FORM_FIELD_DATA = {
  global_id: {
    name: "global_id",
    type: "text",
    label: "Globale ID"
  },
  name: {
    name: "name",
    type: "text",
    label: "Bezeichnung",
    required: true
  },
  address: {
    name: "address",
    type: "text",
    label: "Anschrift"
  },
  zip: {
    name: "zip",
    type: "text",
    label: "Postleitzahl"
  },
  city: {
    name: "city",
    type: "text",
    label: "Ort"
  },
  is_mieterstrom_site: {
    name: "is_mieterstrom_site",
    type: "boolean",
    label: "Handelt es sich um eine Mieterstrom-Liegenschaft?"
  },
  template: {
    name: "template",
    type: "file-upload",
    label: "Excel-Datei hochladen",
    options: {
      dropzoneInfoText:
        "Ziehen Sie Ihre Datei einfach in dieses Feld oder klicken Sie, um Ihre Datei auszuwählen.",
      buttonText: "Erfassungsbogen auswählen",
      buttonColor: "secondary",
      maxFiles: 1,
      accept: [AcceptedFileType.EXCEL]
    }
  }
} as const satisfies {
  [key in Extract<keyof SiteForForm, string>]?: FormInputData<SiteForForm, key>;
};
