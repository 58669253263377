export function nthIndex(str: string, pattern: string, n: number) {
  let i = -1;

  while (n-- && i++ < str.length) {
    i = str.indexOf(pattern, i);

    if (i < 0) {
      break;
    }
  }

  return i;
}
