export const MISSING_DATA_DESCRIPTIONS = {
  configuration: {
    companies: "Allgemeine Angaben zu den nachfolgenden Unternehmen:",
    connections: "Daten zu nachfolgenden Netzverknüpfungspunkten:"
  },
  meteringConcept: {
    meterMeteringConcept:
      "Messkonzept relevante Daten für nachfolgende Zähler: "
  },
  regulatory: {
    generalGenerators:
      "Allgemeine Angaben zu den nachfolgenden Erzeugungsanlagen:",
    regulatoryGenerators:
      "Regulatorische Angaben zu den nachfolgenden Erzeugungsanlagen:",
    regulatoryData: "Regulatorische Angaben für die nachfolgenden Unternehmen:",
    regulatoryConsumers:
      "Regulatorische Angaben für die nachfolgenden Verbraucher:",
    taxData: "Steuerrechtliche Daten zu den nachfolgenden Unternehmen:",
    invoiceData:
      "Angaben zur Stromlieferung und Abrechnung zu den nachfolgenden Unternehmen:",
    eeg: "Angaben zu den nachfolgenden EEG-Anlagen:",
    kwk: "Angaben zu den nachfolgenden KWK-Anlagen:"
  },
  vollmacht: {
    meters:
      "Für die/den nachfolgend aufgeführten Zähler benötigen wir noch eine eindeutige Kennung, um die Messdaten richtig zuordnen zu können. \
        Zähler, für welche wir die Messdaten von einem Messstellenbetreiber erhalten sollen, benötigen in jedem Fall einen Messlokations- (Melo – 33-stellig, beginnend mit DE…) oder eine Marktlokationsnummer (Malo – 11-stellig). \
        Andernfalls kann die Zuordnung über die Zählernummer vom Hersteller erfolgen.",
    marketLocations: "Daten für nachfolgende Marktlokationen: ",
    meteringLocations: "Daten für nachfolgende Messlokationen: "
  },
  invoice: {
    delivery: "Stromkennzeichnung für folgende Lieferungen: ",
    site: "Daten für nachfolgende Liegenschaften: "
  },
  historischeMessdaten: {
    meters: "Daten für das Meldejahr nachfolgender Zähler: "
  }
};
