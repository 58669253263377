import { useQuery } from "@tanstack/react-query";

import api from "../api";
import urls from "../urls";

interface LoadProfileYearsReturnValue {
  years: Array<number>;
  isLoading: boolean;
  error: unknown;
}
function useLoadProfileTemplateYears(): LoadProfileYearsReturnValue {
  const {
    isLoading,
    error,
    data: years
  } = useQuery({
    queryKey: ["loadprofile-template-years"],
    queryFn: () => fetchYears()
  });

  async function fetchYears() {
    const response = await api.get<Array<number>>(
      urls.api.loadprofilesTemplateYears()
    );
    return response.data;
  }

  return {
    isLoading,
    error,
    years: years || []
  };
}

export { useLoadProfileTemplateYears };
