import type { QueryClient } from "@tanstack/react-query";
import api from "../../../../../../api";
import { fetchAnlageStromStg } from "../../../../../../hooks/useAnlageStromstg";
import urls from "../../../../../../urls";
import type { PutOptions } from "../../../../../../utils/backend-types";

export async function loadAnlageStromStg(
  queryClient: QueryClient,
  anlageStromstgId?: number
) {
  if (!anlageStromstgId) {
    return;
  }

  const anlageUrl = urls.api.anlagenStromstgDetailUrl(anlageStromstgId);

  const values = await queryClient.fetchQuery({
    queryKey: ["anlageStromStg-" + anlageStromstgId],
    queryFn: () => fetchAnlageStromStg(anlageStromstgId)
  });

  const options = await queryClient.fetchQuery({
    queryKey: ["anlageStromStgOptions-" + anlageStromstgId],
    queryFn: () => fetchAnlageStromStgOptions(anlageUrl)
  });

  return {
    anlageStromstgUrl: anlageUrl,
    fieldsResponse: options,
    initialValues: values
  };
}

export async function fetchAnlageStromStgOptions(anlageUrl: string) {
  const optionsResponse = await api.options<PutOptions>(anlageUrl);

  return optionsResponse.data.actions.pUT;
}
