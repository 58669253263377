import PropTypes from "prop-types";
import React from "react";
import type { CustomReactTableWithSelectProps } from "../../../../CustomReactTable/CustomReactTableWithSelect/CustomReactTableWithSelect";

import type { AcquisitionWithSummaryData } from "../../EnergyDataAcquisitionList/EnergyDataAcquisitionList";
import { EnergyDataAcquisitionList } from "../../EnergyDataAcquisitionList/EnergyDataAcquisitionList";
import type { Acquisition } from "../../EnergyDataView";

interface AcquisitionListProps {
  acquisitions: Array<Acquisition>;
  customReactSelectTableProps: CustomReactTableWithSelectProps<AcquisitionWithSummaryData>;
}

function AcquisitionList({
  acquisitions,
  customReactSelectTableProps
}: AcquisitionListProps) {
  const totalAcquisitions = acquisitions.length;

  return (
    <div className="acquisition-list">
      <EnergyDataAcquisitionList
        acquisitions={acquisitions}
        customReactSelectTableProps={customReactSelectTableProps}
        showAll
      />
      {totalAcquisitions === 0 && (
        <div className="no-acquisitions">
          <span>
            Für die ausgewählten Filter stehen keine Energiedaten zur Verfügung.
          </span>
        </div>
      )}
    </div>
  );
}

AcquisitionList.propTypes = {
  acquisitions: PropTypes.arrayOf(PropTypes.object).isRequired,
  customReactSelectTableProps: PropTypes.object
};

export { AcquisitionList };
