import { useQuery } from "@tanstack/react-query";

import api from "../api";
import urls from "../urls";
import type { ParameterSet } from "../utils/backend-types";

function useParameterSetCollection() {
  const { isLoading, error, data } = useQuery({
    queryKey: ["parameter-collection"],
    queryFn: () => fetchParameterSetCollection()
  });

  async function fetchParameterSetCollection() {
    const response = await api.get<ParameterSet[]>(
      urls.api.parameterSetCollection()
    );

    return response.data;
  }

  return {
    isLoading,
    error,
    data
  };
}

export { useParameterSetCollection };
