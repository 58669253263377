import { Group, Stack, Text } from "@mantine/core";
import React from "react";

import { IconButton } from "../../Buttons/IconButton/IconButton";
import { IconName } from "../Icon/types";

enum TemplateModalState {
  None,
  NewContract,
  NewTemplateContract
}

interface TemplateDecisionCardsProps {
  setModalState: React.Dispatch<React.SetStateAction<TemplateModalState>>;
}

function TemplateDecisionCards({ setModalState }: TemplateDecisionCardsProps) {
  return (
    <Stack gap="xl" justify="center">
      <div>
        <Text fw={500} size="lg">
          Bitte wählen Sie, ob Sie Ihren Vertrag ohne oder mit einem
          Referenzvertrag erstellen möchten.
        </Text>
        <Text>
          Wenn Sie einen Referenzvertrag nutzen, werden bestehende Inhalte
          übernommen. Stellen Sie sicher, diese Inhalte zu überprüfen und bei
          Bedarf anzupassen.
        </Text>
      </div>
      <Group gap="md" justify="center">
        <IconButton
          color="brand"
          iconName={IconName.Plus}
          outline
          size="lg"
          onClick={() => setModalState(TemplateModalState.NewContract)}
        >
          Ohne Referenzvertrag
        </IconButton>
        <IconButton
          color="brand"
          iconName={IconName.File}
          outline
          size="lg"
          onClick={() => setModalState(TemplateModalState.NewTemplateContract)}
        >
          Mit Referenzvertrag
        </IconButton>
      </Group>
    </Stack>
  );
}
export { TemplateDecisionCards, TemplateModalState };
