import type {
  ConsumptionShareModel,
  DateRangeConsumptionShare
} from "../../../utils/backend-types";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";

export function getNewConsumptionSharesOnAdd(
  currentShares: ConsumptionShareModel | undefined,
  newShare
) {
  const newShares = { ...currentShares };
  if (!currentShares || !newShare || !newShares.dateRangeConsumptionShares) {
    return currentShares;
  }

  const newLastDateWithDayBefore = luxonDateTimeToBackendDateOrDateTime(
    backendDateOrDateTimeToLuxonDateTime(newShare.firstDate).minus({ days: 1 })
  );

  let newDateRangeConsumptionShares:
    | Array<DateRangeConsumptionShare>
    | undefined = [
    ...getSortedDateArrayFromDateRangeConsumptionShares(
      newShares.dateRangeConsumptionShares
    )
  ];

  putNewShareIntoDateRangeConsumptionShares(
    newDateRangeConsumptionShares,
    newShare
  );
  newDateRangeConsumptionShares = newShares.dateRangeConsumptionShares?.map(
    (share) => {
      if (!share.lastDate) {
        return {
          ...share,
          lastDate: newLastDateWithDayBefore
        };
      }
      return share;
    }
  );
  if (!newDateRangeConsumptionShares) {
    return currentShares;
  }

  newDateRangeConsumptionShares.push(newShare);
  newShares.dateRangeConsumptionShares = newDateRangeConsumptionShares;
  return newShares;
}

function getSortedDateArrayFromDateRangeConsumptionShares(
  dateRangeConsumptionShares: Array<DateRangeConsumptionShare>
) {
  const sortedDateRangeConsumptionShares = dateRangeConsumptionShares.sort(
    (a, b) => {
      if (a.firstDate && b.firstDate) {
        return (
          backendDateOrDateTimeToLuxonDateTime(a.firstDate).toUnixInteger() -
          backendDateOrDateTimeToLuxonDateTime(b.firstDate).toUnixInteger()
        );
      }
      return 0;
    }
  );
  return sortedDateRangeConsumptionShares;
}

function putNewShareIntoDateRangeConsumptionShares(
  currentShares: Array<DateRangeConsumptionShare>,
  newShare
) {
  if (!currentShares || !newShare) {
    return currentShares;
  }
}
