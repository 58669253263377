import { OPTIONAL_OPTION } from "../Dropdown.constants";
import type {
  DropdownOption,
  DropdownOptionWithGroup
} from "../Dropdown.types";

/** Adds the "optional" (blank) option to a list of Options or returns the options unaltered. */
export function getOptionsWithOptionalOption<
  Option extends DropdownOption | DropdownOptionWithGroup
>(options: Array<Option>, includeOptional: boolean) {
  if (includeOptional) {
    return [OPTIONAL_OPTION, ...options];
  } else {
    return options;
  }
}
