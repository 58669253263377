import { useQuery, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import type { Accessor, CellInfo, Column } from "react-table";
import api from "../../../api";
import { useConfiguratorComponents } from "../../../hooks/useConfiguratorComponents";
import urls from "../../../urls";
import type {
  Component,
  MeteringServiceProvider,
  Person
} from "../../../utils/backend-types";
import { UNNAMED_OBJECT_NAME } from "../../../utils/constants";
import { sortBackendDates } from "../../../utils/dates/sortBackendDates";
import { ObjectName } from "../../../utils/enums";
import { downloadAsExcel } from "../../../utils/files/downloadAsExcel";
import { gatherExcelExportDataFromReactTableColumns } from "../../../utils/files/gatherExcelExportDataFromReactTableColumns";
import { RoundedNumberFormat } from "../../../utils/RoundedNumberFormat";
import { Badge } from "../../BuildingBlocks/Badge/Badge";
import { Icon } from "../../BuildingBlocks/Icon/Icon";
import { IconWithLink } from "../../BuildingBlocks/Icon/IconWithLink/IconWithLink";
import { IconName } from "../../BuildingBlocks/Icon/types";
import { IconButton } from "../../Buttons/IconButton/IconButton";
import { CustomReactTable } from "../../CustomReactTable/CustomReactTable";
import type { CellToStringConverters } from "../../CustomReactTable/CustomReactTableHooks";
import { useCustomReactTableDataExport } from "../../CustomReactTable/CustomReactTableHooks";
import { IconHelpText } from "../../IconHelpText/IconHelpText";
import { AnimatedLoadingIcon } from "../../Icons/AnimatedLoadingIcon/AnimatedLoadingIcon";
import { useShouldShowStaffView } from "../../StaffViewToggle/useShouldShowStaffView";
import { ComponentOpenEditModalLink } from "../ComponentOpenEditModalLink/ComponentOpenEditModalLink";
import { ComponentDeleteModal } from "./ComponentDeleteModal/ComponentDeleteModal";
import "./ComponentTable.scss";

const NAME_COLUMN_HEADER = "Name";

const TABLE_IDS: Readonly<Record<ObjectName, string>> = {
  [ObjectName.Generator]: "generator-table",
  [ObjectName.Consumer]: "consumer-table",
  [ObjectName.Person]: "person-table",
  [ObjectName.Connection]: "connection-table",
  [ObjectName.GasConnection]: "gas-connection-table",
  [ObjectName.Storage]: "storage-table",
  [ObjectName.Meter]: "meter-table",
  [ObjectName.GhostNode]: "ghost-node-table",
  [ObjectName.MarketLocation]: "market-location-table",
  [ObjectName.MeteringLocation]: "metering-location-table",
  [ObjectName.Weighting]: "weighting-table"
};

const locationTablesDefaultConsumerColumns: Array<Column<string>> = [
  {
    Header: "Messstellenbetreiber",
    accessor: "meteringServiceProvider",
    Cell: (cellInfo) => <MeteringServiceProviderCell id={cellInfo.value} />
  },
  {
    Header: "Messrichtungen",
    accessor: "measurands",
    Cell: (cellInfo) => <span>{cellInfo.value.join(", ")}</span>
  },
  {
    Header: (
      <span>
        Datenempfang Marktkommunikation{" "}
        <IconHelpText
          helpText="Es kann sein, dass der Messstellenbetreiber node.energy zurückmeldet,
          dass ein automatisierter kontinuierlicher Datenempfang für diese
          Lokationsnummer bei diesem Messstellenbetreiber nicht betrieben werden
          kann. Dies kann mehrere Gründe haben, über die wir Sie üblicherweise
          bereits z.B. per E-Mail informiert haben. Sollten Sie Rückfragen zu
          den Gründen haben, kontaktieren Sie bitte den für Sie zuständigen
          Customer Success Mitarbeiter."
        />
      </span>
    ),
    accessor: "locationDataReception",
    Cell: (cellInfo) => {
      const locationDataReception = cellInfo.value;
      return (
        <Badge color={locationDataReception === "Möglich" ? "brand" : "danger"}>
          {locationDataReception}
        </Badge>
      );
    },
    className: "text-center"
  }
];

const locationTablesDefaultStaffColumns: Array<Column<string>> = [
  {
    Header: "Messstellenbetreiber",
    accessor: "meteringServiceProvider",
    Cell: (cellInfo) => <MeteringServiceProviderCell id={cellInfo.value} />
  },
  {
    Header: "Messrichtungen",
    accessor: "measurands",
    Cell: (cellInfo) => <span>{cellInfo.value.join(", ")}</span>
  },
  {
    Header: "Status",
    accessor: "status",
    width: 100
  },
  {
    Header: "Kommentar",
    accessor: "comment"
  },
  {
    Header: "Änderungsdatum",
    accessor: "updated"
  }
];

// const ART_DER_EINSPEISUNG = {
//   None: "---",
//   Volleinspeisung: "Volleinspeisung",
//   Teileinspeisung: "Teileinspeisung"
// };

const TRANSMISSION_SYSTEM_OPERATOR = {
  "50HzT": "50Hertz Transmission GmbH",
  AMPRION: "Amprion GmbH",
  TENNET: "TenneT TSO GmbH",
  TRANSNET: "TransnetBW GmbH"
};

function componentSortingHelper(
  a: number,
  b: number,
  components: Array<Component>
) {
  const locationA = components.find((component) => component.id === a);
  const locationB = components.find((component) => component.id === b);

  if (!locationA && !!locationB) {
    return -1;
  } else if (!locationB && !!locationA) {
    return 1;
  } else if (!locationA || !locationB) {
    return 0;
  }

  const nameA = locationA.name.toLowerCase();
  const nameB = locationB.name.toLowerCase();

  if (nameA < nameB) {
    return -1;
  } else if (nameB < nameA) {
    return 1;
  }

  return 0;
}

export interface ComponentTableProps {
  objectName: ObjectName;
  siteId: number;
  variantId: number;
  userHasAccess: boolean;
  setShowNotAccessibleModal: (show: boolean) => void;
}

function ComponentTable({
  objectName,
  siteId,
  variantId,
  userHasAccess,
  setShowNotAccessibleModal
}: ComponentTableProps) {
  const isStaff = useShouldShowStaffView();
  const [componentToDelete, setComponentToDelete] = useState<Component | null>(
    null
  );
  const siteOrVariantId = objectName === ObjectName.Person ? variantId : siteId;
  const { data: configuratorComponents } = useConfiguratorComponents(
    objectName,
    siteOrVariantId
  );
  const { data: persons } = useConfiguratorComponents<Person>(
    ObjectName.Person,
    variantId
  );
  const { data: marketLocations } = useConfiguratorComponents(
    ObjectName.MarketLocation,
    siteId
  );
  const { data: meteringLocations } = useConfiguratorComponents(
    ObjectName.MeteringLocation,
    siteId
  );

  const customTableColumns = useMemo<Array<Column>>(() => {
    switch (objectName) {
      case ObjectName.Generator:
        return [
          // todo: comment this back in when backend sends einspeisungsart
          // {
          //   Header: "Art der Einspeisung",
          //   accessor: "einspeisungsart",
          //   Cell: ArtDerEinspeisungCell
          // },
          {
            Header: "MaStR-Nummer",
            accessor: "einheit",
            Cell: (data) => <MaStRNumberCell endpoint={data.value} />
          },
          {
            Header: "Betreiber",
            accessor: "person"
          },
          {
            Header: "Typ",
            accessor: "typeLabel"
          },
          {
            Header: "Nettonennleistung",
            accessor: "installedCapacity",
            Cell: InstalledCapacityCell
          },
          {
            Header: "Inbetriebnahme",
            accessor: "commissioning",
            sortMethod: sortBackendDates
          },
          {
            Header: "Außerbetriebnahme",
            accessor: "decommissioning",
            sortMethod: sortBackendDates
          }
        ];

      case ObjectName.Consumer:
        return [
          {
            Header: "Typ",
            accessor: "typeLabel"
          }
        ];

      case ObjectName.GasConnection:
        return [
          {
            Header: "Netzanschlussnehmer",
            accessor: "connectingParty"
          }
        ];

      case ObjectName.Storage:
        return [
          {
            Header: "Speicherleistung",
            accessor: "installedCapacity",
            Cell: InstalledCapacityCell
          }
        ];

      case ObjectName.Connection:
        return [
          {
            Header: "Netzanschlussnehmer",
            accessor: "connectingParty"
          },
          {
            Header: "Strombezugsvertrag gehalten durch",
            accessor: "supplyContractHeldBy"
          },
          {
            Header: "Anschlussnetzbetreiber",
            accessor: "gridOperatorName"
          },
          {
            Header: "Übertragungsnetzbetreiber",
            accessor: "transmissionSystemOperator",
            Cell: TransmissionSystemOperatorCell
          }
        ];

      case ObjectName.Meter:
        return [
          {
            Header: "Zählernummer",
            accessor: "number"
          },
          {
            Header: "Messlokation",
            accessor: "meteringLocation"
          },
          {
            Header: "Marktlokation Einspeisung",
            accessor: "marketLocationFeedin"
          },
          {
            Header: "Marktlokation Entnahme",
            accessor: "marketLocationFeedout"
          },
          {
            Header: "Messrichtung",
            accessor: "meteringDirectionLabel"
          }
        ];

      case ObjectName.MarketLocation:
        return isStaff
          ? [
              {
                Header: "Marktlokationsnummer",
                accessor: "number"
              },
              ...locationTablesDefaultStaffColumns
            ]
          : [
              {
                Header: "Marktlokationsnummer",
                accessor: "number"
              },
              ...locationTablesDefaultConsumerColumns
            ];

      case ObjectName.MeteringLocation:
        return isStaff
          ? [
              {
                Header: "Messlokationsnummer",
                accessor: "number"
              },
              ...locationTablesDefaultStaffColumns
            ]
          : [
              {
                Header: "Messlokationsnummer",
                accessor: "number"
              },
              ...locationTablesDefaultConsumerColumns
            ];

      case ObjectName.Person:
      case ObjectName.GhostNode:
      default:
        return [];
    }
  }, [isStaff, objectName]);

  const columnsToExport = [
    { accessor: "name", header: NAME_COLUMN_HEADER },
    ...gatherExcelExportDataFromReactTableColumns(customTableColumns).filter(
      (column) => !column.header.includes("Spalte ")
    )
  ];
  const cellToStringConverters = getCellToStringConverters();

  const { setTableRef, exportData } = useCustomReactTableDataExport({
    columnsToExport,
    cellToStringConverters
  });
  const [exportIsLoading, setExportIsLoading] = useState(false);
  const queryClient = useQueryClient();

  function getCellToStringConverters(): CellToStringConverters {
    const personFunc = (personId: number | null) => {
      if (personId === null || !persons) {
        return "";
      }

      const person = persons.find((person) => person.id === personId);

      return person ? person.name : "";
    };

    switch (objectName) {
      case ObjectName.Generator: {
        return {
          person: personFunc,
          einheit: async (endpoint?: string | null) => {
            if (!endpoint || endpoint === null) {
              return "";
            }

            const mastrNumber = await queryClient.fetchQuery({
              queryKey: ["mastr-number", { endpoint }],
              queryFn: () => fetchMaStRNumber(endpoint)
            });

            return mastrNumber?.einheitMastrNummer
              ? mastrNumber.einheitMastrNummer
              : "";
          }
        };
      }
      case ObjectName.Connection:
        return {
          connectingParty: personFunc,
          supplyContractHeldBy: personFunc
        };
      case ObjectName.GasConnection:
        return {
          connectingParty: personFunc
        };
      case ObjectName.Meter: {
        const marketLocationFunc = (marketLocationId: number | null) => {
          if (marketLocationId === null || !marketLocations) {
            return "";
          }

          const marketLocation = marketLocations.find(
            (melo) => melo.id === marketLocationId
          );

          return marketLocation ? marketLocation.name : "";
        };

        return {
          meteringLocation: (meteringLocationId: number | null) => {
            if (meteringLocationId === null || !meteringLocations) {
              return "";
            }

            const meteringLocation = meteringLocations.find(
              (melo) => melo.id === meteringLocationId
            );

            return meteringLocation ? meteringLocation.name : "";
          },
          marketLocationFeedin: marketLocationFunc,
          marketLocationFeedout: marketLocationFunc
        };
      }
      case ObjectName.GhostNode:
        return {
          name: (name: string | null) =>
            name === null ? UNNAMED_OBJECT_NAME : name
        };
      case ObjectName.MarketLocation:
      case ObjectName.MeteringLocation:
        return {
          meteringServiceProvider: async (mspId: number | null) => {
            if (mspId === null) {
              return "";
            }

            const msp = await queryClient.fetchQuery({
              queryKey: ["metering-service-providers", { id: mspId }],
              queryFn: () => fetchMeteringServiceProvider(mspId)
            });

            if (!msp) {
              return "";
            }

            return msp.name;
          },
          statusProvider: (status: string) => {
            if (status === "active") return "Möglich";
            else return "Nicht möglich";
          }
        };
      default:
        return {};
    }
  }

  const tableColumns: Array<Column> = useMemo(() => {
    return [
      {
        Header: NAME_COLUMN_HEADER,
        accessor: "name",
        minWidth: 200,
        Cell: (data) => (
          <NameCell
            componentId={data.original.id}
            incomplete={
              Object.prototype.hasOwnProperty.call(data.original, "complete") &&
              !data.original.complete
            }
            name={data.original.name}
            objectName={objectName}
          />
        )
      },
      ...customTableColumns,
      {
        Header: "",
        accessor: "id",
        width: 50,
        Cell: (data) => (
          <OptionsCell
            componentId={data.original.id}
            objectName={objectName}
            setShowNotAccessibleModal={setShowNotAccessibleModal}
            userHasAccess={userHasAccess}
            onClickDelete={() => handleDelete(data.original)}
          />
        )
      }
    ];
  }, [
    customTableColumns,
    objectName,
    userHasAccess,
    setShowNotAccessibleModal
  ]);

  function isDataLoading() {
    let isExtraDataLoading = false;

    if (
      objectName === ObjectName.Connection ||
      objectName === ObjectName.GasConnection
    ) {
      isExtraDataLoading = !persons;
    } else if (objectName === ObjectName.Meter) {
      isExtraDataLoading = !marketLocations || !meteringLocations;
    }

    return !configuratorComponents || isExtraDataLoading;
  }

  function handleDelete(component: Component) {
    setComponentToDelete(component);
  }

  function closeComponentDelete() {
    setComponentToDelete(null);
  }

  function setComponentCells(
    componentObjectName: ObjectName,
    cellConfiguratorComponents: Array<Component>,
    accessors: Array<Accessor<string>>
  ) {
    if (cellConfiguratorComponents) {
      const componentColumns =
        tableColumns.filter(
          (column) => column.accessor && accessors.includes(column.accessor)
        ) || [];
      componentColumns.forEach((column) => {
        const ComponentCellWithData = (data: CellInfo) => (
          <ComponentCell
            components={cellConfiguratorComponents}
            id={data.value}
            objectName={componentObjectName}
          />
        );
        column.Cell = ComponentCellWithData;
        column.sortMethod = (a: number, b: number) =>
          componentSortingHelper(a, b, cellConfiguratorComponents);
      });
    }
  }

  async function handleClickExport() {
    setExportIsLoading(true);

    const rows = await exportData();
    downloadAsExcel(rows, `${objectName}`);
    setExportIsLoading(false);
  }

  if (isDataLoading()) {
    return (
      <div className="component-list-loader">
        <AnimatedLoadingIcon />
      </div>
    );
  }

  if (objectName === ObjectName.Generator && !!persons) {
    setComponentCells(ObjectName.Person, persons, ["person"]);
  } else if (objectName === ObjectName.Connection && !!persons) {
    setComponentCells(ObjectName.Person, persons, [
      "connectingParty",
      "supplyContractHeldBy"
    ]);
  } else if (objectName === ObjectName.GasConnection && !!persons) {
    setComponentCells(ObjectName.Person, persons, ["connectingParty"]);
  } else if (
    objectName === ObjectName.Meter &&
    !!marketLocations &&
    !!meteringLocations
  ) {
    setComponentCells(ObjectName.MarketLocation, marketLocations, [
      "marketLocationFeedin",
      "marketLocationFeedout"
    ]);
    setComponentCells(ObjectName.MeteringLocation, meteringLocations, [
      "meteringLocation"
    ]);
  }

  return (
    <div className="ComponentTable" id={TABLE_IDS[objectName]}>
      <IconButton
        className="export-button"
        color="brand"
        disabled={exportIsLoading}
        iconName={
          exportIsLoading ? IconName.SpinnerSpinning : IconName.Download
        }
        size="sm"
        onClick={handleClickExport}
      >
        Als Excel-Datei Herunterladen
      </IconButton>
      <CustomReactTable
        columns={tableColumns}
        data={configuratorComponents}
        minRows={0}
        NoDataComponent={() => (
          <NoDataComponent objectDisplayName={objectName} />
        )}
        pageSize={configuratorComponents?.length}
        setTableRef={setTableRef}
      />
      {componentToDelete && (
        <ComponentDeleteModal
          component={componentToDelete}
          objectName={objectName}
          onDeleteSuccess={closeComponentDelete}
          onToggle={closeComponentDelete}
        />
      )}
    </div>
  );
}

interface NameCellProps {
  componentId: number;
  name: string;
  objectName: ObjectName;
  incomplete?: boolean;
}

function NameCell({
  componentId,
  name,
  objectName,
  incomplete
}: NameCellProps) {
  const linkContent = name || UNNAMED_OBJECT_NAME;

  return (
    <span>
      <ComponentOpenEditModalLink
        componentId={componentId}
        linkContent={linkContent}
        objectName={objectName}
      />
      {incomplete && (
        <Icon
          className="m--font-danger"
          name={IconName.ExclamationCircle}
          tooltipText="Diese Komponente benötigt weitere Daten zur Vervollständigung."
        />
      )}
    </span>
  );
}

// function ArtDerEinspeisungCell({ value }: { value: string }) {
//   return <span>{ART_DER_EINSPEISUNG[value]}</span>;
// }

function InstalledCapacityCell({ value }: { value: string }) {
  if (!value) {
    return null;
  }

  return (
    <RoundedNumberFormat
      decimalScale={4}
      decimalSeparator=","
      displayType="text"
      suffix=" kW"
      thousandSeparator="."
      value={value}
    />
  );
}

function TransmissionSystemOperatorCell({ value }: { value: string }) {
  return <span>{TRANSMISSION_SYSTEM_OPERATOR[value]}</span>;
}

interface ComponentCellProps {
  id: number;
  components: Array<Component>;
  objectName: ObjectName;
}

function ComponentCell({ id, components, objectName }: ComponentCellProps) {
  const object = components.find((object) => object.id === id);

  if (!object) {
    return null;
  }

  return (
    <NameCell componentId={id} name={object.name} objectName={objectName} />
  );
}

interface OptionsCellProps {
  componentId: number;
  objectName: ObjectName;
  onClickDelete: () => void;
  userHasAccess: boolean;
  setShowNotAccessibleModal: (show: boolean) => void;
}

function OptionsCell({
  componentId,
  objectName,
  onClickDelete,
  userHasAccess,
  setShowNotAccessibleModal
}: OptionsCellProps) {
  return (
    <React.Fragment>
      <ComponentOpenEditModalLink
        componentId={componentId}
        linkContent={<Icon name={IconName.Edit} />}
        objectName={objectName}
      />
      <IconWithLink
        className={classNames("ml-1", { "m--font-danger": userHasAccess })}
        name={IconName.Trash}
        style={{ cursor: "pointer" }}
        onClick={() => {
          if (!userHasAccess) {
            setShowNotAccessibleModal(true);
          } else {
            onClickDelete();
          }
        }}
      />
    </React.Fragment>
  );
}

function MeteringServiceProviderCell({ id }: { id: number }) {
  const { data } = useQuery({
    queryKey: ["metering-service-providers", { id }],
    queryFn: () => fetchMeteringServiceProvider(id),
    enabled: !!id
  });

  if (!id) {
    return null;
  }

  return <span>{data?.name ?? "Wird geladen..."}</span>;
}

function MaStRNumberCell({ endpoint }: { endpoint: string }) {
  const { data } = useQuery({
    queryKey: ["mastr-number", { endpoint }],
    queryFn: () => fetchMaStRNumber(endpoint),
    enabled: !!endpoint
  });

  if (!endpoint) {
    return null;
  }

  return <span>{data?.einheitMastrNummer ?? "Wird geladen..."}</span>;
}

function NoDataComponent({ objectDisplayName }: { objectDisplayName: string }) {
  return (
    <div className="no-data-component">
      <p>Keine {objectDisplayName} vorhanden.</p>
    </div>
  );
}

async function fetchMaStRNumber(endpoint: string) {
  const { data } = await api.get(endpoint);
  return data;
}

async function fetchMeteringServiceProvider(id: number) {
  const { data } = await api.get<MeteringServiceProvider>(
    urls.api.meteringServiceProvider(id)
  );
  return data;
}

ComponentTable.propTypes = {
  objectName: PropTypes.oneOf(Object.values(ObjectName)).isRequired,
  siteId: PropTypes.number.isRequired,
  variantId: PropTypes.number.isRequired
};

export { ComponentTable };
