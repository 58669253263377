export function matchFullTextCaseInsensitive(
  text: string,
  searchValue: string
) {
  return String(text).match(new RegExp(searchValue, "i"));
}

export function capitalizeWord(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}
