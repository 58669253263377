import React, { useMemo } from "react";
import type { Column } from "react-table";

import { sortBackendDates } from "../../../../utils/dates/sortBackendDates";
import { DataImportRequestType } from "../../../../utils/enums";
import { RoundedNumberFormat } from "../../../../utils/RoundedNumberFormat";
import { CustomReactTable } from "../../../CustomReactTable/CustomReactTable";
import type { EnergyDataImportRequestDetails } from "../../../EnergyData/EnergyDataUploadFlow/EnergyDataUpload";
import { LoadOrError } from "../../../LoadOrError/LoadOrError";
import { MissingValueCell } from "./MissingValueCell";

import "./EnergyDataImportDetailsTable.scss";

interface Props {
  dataImportRequestDetails: EnergyDataImportRequestDetails[];
  dataType: DataImportRequestType;
  isLoading: boolean;
  error: unknown;
  customEmptyDataMessage?: string;
}

enum TableColumn {
  Meter = "meterNumber",
  Location = "locationNumber",
  Start = "startDate",
  End = "endDate",
  Metric = "measurand",
  MissingValuesShare = "missingValuesShare",
  MaxPower = "maxPower",
  SumPower = "sumPower"
}

function EnergyDataImportDetailsTable({
  dataImportRequestDetails,
  isLoading,
  dataType,
  error,
  customEmptyDataMessage
}: Props) {
  const tableColumns: Array<Column<EnergyDataImportRequestDetails>> = useMemo(
    () => [
      {
        Header: "Bezeichner",
        accessor: TableColumn.Meter,
        maxWidth: 140
      },
      ...(dataType === DataImportRequestType.REDISPATCH
        ? []
        : [
            {
              Header: "Messlokationsnummer",
              accessor: TableColumn.Location,
              maxWidth: 300,
              width: 200
            }
          ]),
      {
        Header: "Zeitpunkt des ersten Wertes",
        accessor: TableColumn.Start,
        sortMethod: sortBackendDates,
        resizable: false
      },
      {
        Header: "Zeitpunkt des letzten Wertes",
        accessor: TableColumn.End,
        sortMethod: sortBackendDates,
        resizable: false
      },
      {
        Header: "Messgröße",
        accessor: TableColumn.Metric
      },
      {
        Header: "Anteil fehlender Werte, %",
        accessor: TableColumn.MissingValuesShare,
        Cell: (cellData) => <MissingValueCell shareValue={cellData.value} />
      },
      {
        Header: "Maximale Leistung, kW",
        accessor: TableColumn.MaxPower,
        Cell: (cellData) => (
          <RoundedNumberFormat
            decimalScale={0}
            displayType="text"
            value={cellData.value}
          />
        )
      },
      {
        Header: "Summe Energie, kWh",
        accessor: TableColumn.SumPower,
        Cell: (cellData) => (
          <RoundedNumberFormat
            decimalScale={0}
            displayType="text"
            value={cellData.value}
          />
        )
      }
    ],
    [dataType]
  );

  return (
    <LoadOrError error={error} loading={isLoading}>
      {!isLoading && (
        <div className="metersDetails">
          <CustomReactTable
            columns={tableColumns}
            data={dataImportRequestDetails}
            defaultSorted={[
              {
                id: TableColumn.Meter,
                desc: false
              }
            ]}
            minRows={0}
            NoDataComponent={() => (
              <p className="lead text-center my-3">
                {customEmptyDataMessage ||
                  "Für diesen Upload sind keine Daten vorhanden"}
              </p>
            )}
            pageSize={dataImportRequestDetails.length}
          />
        </div>
      )}
    </LoadOrError>
  );
}

export { EnergyDataImportDetailsTable };
