import type { Edge, Node } from "reactflow";
import { StructureViewMode } from "../../StructureView/StructureView.constants";
import { ConnectionPointDirection } from "../../StructureViewDiagram/StructureViewDiagram";

function findConnectedEdge(
  connectedEdges: Edge[],
  directions: ConnectionPointDirection[]
) {
  return connectedEdges.find(
    (edge) =>
      (edge.sourceHandle === directions[0] &&
        edge.targetHandle === directions[1]) ||
      (edge.sourceHandle === directions[1] &&
        edge.targetHandle === directions[0])
  );
}

function getConnectedNodeId(edge: Edge | undefined, nodeId: string) {
  return edge
    ? edge.source === nodeId
      ? edge.target
      : edge.source
    : undefined;
}

function findNodeById(nodes: Node[], nodeId: string | undefined) {
  return nodes.find((node) => node.id === nodeId);
}

function isWithinSnapRange(position: number, targetPosition: number) {
  return targetPosition - 10 < position && position < targetPosition + 10;
}

export function getSnapPoints(
  nodeId: string,
  newNodes: Node[],
  connectedEdges: Edge[],
  x: number,
  y: number,
  mode: StructureViewMode
) {
  if (mode !== StructureViewMode.MeteringConcept) {
    return {
      snapRelativeX: undefined,
      snapRelativeY: undefined,
      snapAbsoluteX: undefined,
      snapAbsoluteY: undefined
    };
  }

  const verticalEdge = findConnectedEdge(connectedEdges, [
    ConnectionPointDirection.Bottom,
    ConnectionPointDirection.Top
  ]);
  const horizontalEdge = findConnectedEdge(connectedEdges, [
    ConnectionPointDirection.Left,
    ConnectionPointDirection.Right
  ]);

  const verticalNodeId = getConnectedNodeId(verticalEdge, nodeId);
  const horizontalNodeId = getConnectedNodeId(horizontalEdge, nodeId);

  const verticalNode = findNodeById(newNodes, verticalNodeId);
  const horizontalNode = findNodeById(newNodes, horizontalNodeId);

  const snapPoints = {
    snapRelativeX: undefined as number | undefined,
    snapRelativeY: undefined as number | undefined,
    snapAbsoluteX: undefined as number | undefined,
    snapAbsoluteY: undefined as number | undefined
  };

  if (verticalNode && isWithinSnapRange(x, verticalNode.position.x)) {
    snapPoints.snapRelativeX = verticalNode.position.x;
    snapPoints.snapAbsoluteX = verticalNode.positionAbsolute?.x;
  }

  if (horizontalNode && isWithinSnapRange(y, horizontalNode.position.y)) {
    snapPoints.snapRelativeY = horizontalNode.position.y;
    snapPoints.snapAbsoluteY = horizontalNode.positionAbsolute?.y;
  }

  return snapPoints;
}
