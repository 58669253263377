import type { MRT_ColumnDef } from "mantine-react-table";
import React from "react";
import { MantineEditDateInput } from "../../../../../mantine/mrt/components/edit/MantineEditDateInput";
import { submitOrCancelOnKeyPress } from "../../../../../mantine/mrt/utils/submitOrCancelOnKeyPress";
import type { InlineEditTableColumnData2 } from "../../../../../types/inlineEditTable.types";
import type { Paragraph6EEGCreditNumberRangeResponse } from "../../../Paragraph6.types";

export function getValidFromColumn({
  validationErrors
}: InlineEditTableColumnData2): MRT_ColumnDef<Paragraph6EEGCreditNumberRangeResponse> {
  return {
    accessorKey: "valid_from",
    header: "Startdatum",
    enableSorting: false,
    Edit: ({ cell, column, row, table }) => (
      <MantineEditDateInput
        cell={cell}
        columnId={column.id}
        errorText={validationErrors.valid_from}
        label="Startdatum"
        row={row}
        table={table}
        onKeyDown={(event) => submitOrCancelOnKeyPress(event, table)}
      />
    )
  };
}
