import { type Todo } from "../../../utils/backend-types";

export function handleChangeResponsible(
  todos: Array<Todo>,
  updateTodoResponsible: (editedTodo: Todo) => void,
  responsibleId: number | null,
  todoId: number
) {
  const todo = todos.find((todo) => todo.id === todoId);

  if (todo) {
    updateTodoResponsible({
      ...todo,
      responsible: responsibleId
    });
  }
}
