import { IconName } from "../types";

const ICON_EXCEL = IconName.FileExcel;
const ICON_WORD = IconName.FileWord;
const ICON_POWERPOINT = IconName.FilePdf;
const ICON_IMAGE = IconName.FileImage;
const ICON_PDF = IconName.FilePdf;
const ICON_CODE = IconName.FileCode;
const ICON_OTHER = IconName.File;

const FILE_TYPES_TO_ICONS: Record<string, IconName> = {
  xls: ICON_EXCEL,
  xlsx: ICON_EXCEL,
  csv: ICON_EXCEL,
  doc: ICON_WORD,
  docx: ICON_WORD,
  pdf: ICON_PDF,
  ppt: ICON_POWERPOINT,
  pptx: ICON_POWERPOINT,
  pptm: ICON_POWERPOINT,
  jpg: ICON_IMAGE,
  jpeg: ICON_IMAGE,
  gif: ICON_IMAGE,
  tiff: ICON_IMAGE,
  png: ICON_IMAGE,
  bmp: ICON_IMAGE,
  svg: ICON_IMAGE,
  xml: ICON_CODE
} as const;

/** Matches a file extension to an appropriate Icon name. */
export function getIconNameByFileExtension(fileExtension: string) {
  const lowerCaseFileExtension = fileExtension.toLowerCase();
  return FILE_TYPES_TO_ICONS[lowerCaseFileExtension] ?? ICON_OTHER;
}
