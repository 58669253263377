import { useMutation } from "@tanstack/react-query";
import api from "../../../../api";
import urls from "../../../../urls";
import type { CreateEdaVersionPayload } from "../CreateEdaVersionModal.types";

export function useCreateEdaVersion() {
  return useMutation({
    mutationFn: (args: CreateEdaVersionPayload) => createEdaVersion(args)
  });
}

function createEdaVersion(args: CreateEdaVersionPayload) {
  const { siteId, ...payload } = args;
  return api.post(urls.api.mbk(siteId), payload);
}
