import React from "react";

// eslint-disable-next-line no-restricted-imports
import { ModalHeader as ReactstrapModalHeader } from "reactstrap";
import type { ModalHeaderProps as ReactstrapModalHeaderProps } from "reactstrap";

type ModalHeaderProps = ReactstrapModalHeaderProps;

function ModalHeader(props: ReactstrapModalHeaderProps) {
  return <ReactstrapModalHeader {...props} />;
}

export { ModalHeader, ModalHeaderProps };
