import classnames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import { Nav, NavItem, NavLink } from "reactstrap";
import { OrangeCircle } from "../../../OrangeCircle/OrangeCircle";
import "./TabBar.scss";

export interface TabData<T extends string = string> {
  id: T;
  label: string;
  hidden?: boolean;
}

type TabBarProps<T extends string = string> = {
  activeTab: T;
  basePath?: string;
  firstTabIsIndex?: boolean;
  highlightedTabs?: Array<string>;
  routeless?: boolean;
  tabs: ReadonlyArray<TabData<T>>;
  tabComponents?: Record<T, React.ReactNode>;
  onActivateTab?: (tabID: T) => void;
};

function TabBar<T extends string>({
  activeTab,
  basePath = "./",
  firstTabIsIndex = false,
  highlightedTabs = [],
  routeless,
  tabs,
  tabComponents,
  onActivateTab
}: TabBarProps<T>) {
  return (
    <Nav className="TabBar" pills role="tablist">
      {tabs.map((tab, tabIndex) => (
        <Tab
          active={activeTab === tab.id}
          hidden={tab.hidden}
          highlighted={
            tab.id
              ? highlightedTabs.includes(tab.id)
              : highlightedTabs.includes("default")
          }
          id={tab.id}
          key={tab.id}
          label={tab.label}
          tabComponent={tabComponents?.[tab.id]}
          to={
            routeless
              ? undefined
              : tabIndex === 0 && firstTabIsIndex
                ? "."
                : `${basePath}${tab.id}`
          }
          onActivateTab={onActivateTab}
        />
      ))}
    </Nav>
  );
}

interface TabProps<T extends string> extends TabData<T> {
  active: boolean;
  highlighted: boolean;
  tabComponent?: React.ReactNode;
  to?: string;
  onActivateTab?: (tabId: string) => void;
}

function Tab<T extends string>({
  hidden,
  id,
  label,
  tabComponent,
  to,
  active,
  highlighted,
  onActivateTab
}: TabProps<T>) {
  if (hidden) {
    return null;
  }

  return (
    <NavItem>
      <NavLink
        aria-controls={id}
        aria-selected={active}
        className={classnames({ active }, { highlighted }, "tab-link")}
        role="tab"
        tag={to ? Link : undefined}
        to={to}
        onClick={() => {
          if (onActivateTab) {
            onActivateTab(id);
          }
        }}
      >
        {tabComponent ?? label}
        {highlighted && <OrangeCircle />}
      </NavLink>
    </NavItem>
  );
}

export { TabBar, TabBarProps };
