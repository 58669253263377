import api from "../../../api";
import urls from "../../../urls";

export async function resetPasswordConfirm(
  newPass: string,
  confirmPass: string,
  uid: string,
  token: string
) {
  await api.publicRequest("POST", urls.auth.resetPasswordConfirm(), {
    new_password1: newPass,
    new_password2: confirmPass,
    uid,
    token
  });
}
