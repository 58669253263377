import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Paragraph6EEGCreditResponseState } from "../../../../types/api.types";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import type { FormInputData } from "../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { FormFieldController } from "../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { setErrorsFromResponseData } from "../../../BuildingBlocks/Forms/utils/setErrorsFromResponseData";
import { BasicConfirmationModal } from "../../../BuildingBlocks/Layout/Modals/BasicConfirmationModal/BasicConfirmationModal";
import { useParagraph6CreditMutations } from "../../hooks/useParagraph6CreditMutations";
import { useShowParagraph6CreditsAlerts } from "../../hooks/useShowParagraph6CreditsAlerts";

interface PaymentDateFormFields {
  date_of_payment: string;
}

const PAYMENT_DATE_FORM_FIELD_DATA = {
  name: "date_of_payment",
  type: "date",
  lastPossibleDate: luxonDateTimeToBackendDateOrDateTime(DateTime.now())
} as const satisfies FormInputData<PaymentDateFormFields, "date_of_payment">;

interface Paragraph6PaymentDateModalProps {
  isOpen?: boolean;
  variantId: number;
  creditId: string;
  initialPaymentDate?: string;
  onClose: () => void;
}

function Paragraph6PaymentDateModal({
  isOpen,
  variantId,
  creditId,
  initialPaymentDate,
  onClose
}: Paragraph6PaymentDateModalProps) {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    watch
  } = useForm<PaymentDateFormFields>({
    defaultValues: { date_of_payment: initialPaymentDate }
  });

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  const { paragraph6CreditUpdateStatusMutation } =
    useParagraph6CreditMutations(variantId);

  const { showParagraph6CreditsAlerts } = useShowParagraph6CreditsAlerts();

  const onValid: SubmitHandler<PaymentDateFormFields> = (data) => {
    setIsLoading(true);

    paragraph6CreditUpdateStatusMutation.mutate(
      {
        creditId,
        payload: {
          state: Paragraph6EEGCreditResponseState.paid,
          date_of_payment: data.date_of_payment
        }
      },
      {
        onSuccess: (res) => {
          showParagraph6CreditsAlerts({
            todoAlertState: res.data.todo_alert_state,
            todoUrl: res.data.todo_url,
            todoPageUrl: "../../aufgaben"
          });
          onClose();
        },
        onError: (error) => {
          setErrorsFromResponseData<PaymentDateFormFields>(
            error,
            watch(),
            setError,
            t("errors.UnknownError")
          );
        },
        onSettled: () => {
          setIsLoading(false);
        }
      }
    );
  };

  const submitCallback = handleSubmit(onValid);

  return (
    <BasicConfirmationModal
      confirmationButtonText="Speichern"
      headerText="Zahlungsdatum eingeben"
      isLoading={isLoading}
      isModalOpen={isModalOpen ?? false}
      toggleModal={onClose}
      onCancel={onClose}
      onConfirm={submitCallback}
    >
      <p>Bitte geben Sie das Zahlungsdatum für die Gutschrift ein.</p>
      <form className="payment-date-form" onSubmit={submitCallback}>
        <FormFieldController
          control={control}
          data={PAYMENT_DATE_FORM_FIELD_DATA}
          error={errors.date_of_payment}
          rules={{
            required: {
              value: true,
              message: t("errors.Paragraph6.InvalidPaymentDate")
            }
          }}
        />
      </form>
    </BasicConfirmationModal>
  );
}

export { Paragraph6PaymentDateModal, Paragraph6PaymentDateModalProps };
