import type { MspCategory } from "../../../../utils/enums";
import type { FilterDropdownValues } from "../../../FilterDropdown/FilterDropdown.types";
import type { LocationOverviewStatus } from "../LocationOverview.types";

interface GetLocationOverviewQueryParams {
  locationOverViewFilters: FilterDropdownValues;
  categories: Array<MspCategory>;
  searchValue: string;
}

export function getLocationOverviewQueryParams({
  locationOverViewFilters,
  categories,
  searchValue
}: GetLocationOverviewQueryParams) {
  return {
    status:
      locationOverViewFilters.status === "egal"
        ? undefined
        : (locationOverViewFilters.status as LocationOverviewStatus),
    metering_service_provider_categories:
      categories.length > 0 ? categories : undefined,
    min_missing_consumption_year_percentage:
      typeof locationOverViewFilters.minMissingConsumptionYearPercentage !==
      "undefined"
        ? Number(locationOverViewFilters.minMissingConsumptionYearPercentage)
        : undefined,
    min_missing_consumption_month_percentage:
      typeof locationOverViewFilters.minMissingConsumptionMonthPercentage !==
      "undefined"
        ? Number(locationOverViewFilters.minMissingConsumptionMonthPercentage)
        : undefined,
    min_missing_generation_year_percentage:
      typeof locationOverViewFilters.minMissingGenerationYearPercentage !==
      "undefined"
        ? Number(locationOverViewFilters.minMissingGenerationYearPercentage)
        : undefined,
    min_missing_generation_month_percentage:
      typeof locationOverViewFilters.minMissingGenerationMonthPercentage !==
      "undefined"
        ? Number(locationOverViewFilters.minMissingGenerationMonthPercentage)
        : undefined,
    search_segment: searchValue
  };
}
