import React, { useState } from "react";
import { useShouldShowStaffView } from "../../../StaffViewToggle/useShouldShowStaffView";
import type { StepStatus } from "../../ChangeProcess.types";
import { TenantChangeProcess } from "../TenantChangeProcess/TenantChangeProcess";
import type { TenantChange, TenantChangeSteps } from "../TenantChanges.types";
import "./TenantChangeTaskList.scss";

interface TenantChangeTaskListProps {
  tenantChanges: Array<TenantChange>;
  projectId: string | undefined;
  onUpdateProcess: (operatorChange: TenantChange) => Promise<void>;
}

function TenantChangeTaskList({
  tenantChanges,
  projectId,
  onUpdateProcess
}: TenantChangeTaskListProps) {
  const [isUpdating, setIsUpdating] = useState(false);
  const shouldShowStaffView = useShouldShowStaffView();

  function handleUpdateStepStatus(
    operatorChange: TenantChange,
    step: keyof TenantChangeSteps,
    status: StepStatus
  ) {
    setIsUpdating(true);
    onUpdateProcess({
      ...operatorChange,
      steps: {
        ...operatorChange.steps,
        [step]: { ...operatorChange.steps[step], done: status }
      }
    }).finally(() => setIsUpdating(false));
  }

  return tenantChanges.map((tenantChange, index) => (
    <TenantChangeProcess
      isUpdating={isUpdating}
      key={tenantChange.id}
      projectId={projectId}
      shoudShowStaffView={shouldShowStaffView}
      tenantChange={tenantChange}
      toggleInitial={index === 0}
      onUpdateStepStatus={handleUpdateStepStatus}
    />
  ));
}

export { TenantChangeTaskList, TenantChangeTaskListProps };
