import saveAs from "file-saver";
import React, { useState } from "react";
import api from "../../../../api";
import { postDataAndPollResponse } from "../../../../utils/api-utils";
import { b64toBlob } from "../../../../utils/files/b64toBlob";
import { showToast } from "../../../../utils/toast";
import { SpinButton } from "../../../Buttons/SpinButton/SpinButton";
import { openErrorAlertPopup } from "../../../ErrorAlertPopup/openErrorAlertPopup";

import "./DownloadButton.scss";

interface DownloadButtonProps {
  downloadRequestUrl: string;
  downloadRequestOptions: Record<string, string | number | Array<number>>;
  downloadUrlFunc: (taskId: string) => string;
  disabled?: boolean;
}

function DownloadButton({
  downloadRequestUrl,
  downloadRequestOptions,
  downloadUrlFunc,
  disabled
}: DownloadButtonProps) {
  const [isDownloading, setIsDownloading] = useState(false);

  function handleClickDownload() {
    setIsDownloading(true);

    postDataAndPollResponse(downloadRequestUrl, downloadRequestOptions)
      .then(({ taskIds }) => {
        if (taskIds) {
          downloadFile(taskIds[0]);
        } else {
          openErrorAlertPopup({
            response: {
              status: 500
            }
          });

          setIsDownloading(false);
        }
      })
      .catch((errorResponse) => {
        openErrorAlertPopup({
          response: errorResponse
        });

        setIsDownloading(false);
      });
  }

  async function downloadFile(taskId: string) {
    const response = await api.get(downloadUrlFunc(taskId));

    if (response.data.warnings.length > 0) {
      showToast("warning", response.data.warnings.join("<br/><br/>"), {
        autoClose: 10000
      });
    }
    const contentType = response.headers["content-type"];
    const blob = b64toBlob(response.data.encodedFileData, contentType);

    saveAs(blob, response.data.fileName);
    setIsDownloading(false);
  }

  return (
    <SpinButton
      className="DownloadButton"
      color="brand"
      disabled={disabled}
      spin={isDownloading}
      onClick={handleClickDownload}
    >
      Herunterladen
    </SpinButton>
  );
}

export { DownloadButton };
