import classNames from "classnames";
import React from "react";
import { Link, Navigate } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

import { ROUTES } from "../../routes";
import { UserLicense } from "../../utils/enums";
import { Portlet } from "../BuildingBlocks/Layout/Portlet";

import "./ProductSelectPage.scss";

interface ProductSelectPageProps {
  userLicenses: Array<UserLicense>;
}

function ProductSelectPage({ userLicenses }: ProductSelectPageProps) {
  function checkExclusiveLicense(license: UserLicense) {
    return userLicenses.length === 1 && userLicenses.includes(license);
  }

  if (checkExclusiveLicense(UserLicense.Analyzer)) {
    return <Navigate replace to={ROUTES.analyzerProjectList} />;
  } else if (checkExclusiveLicense(UserLicense.Manager)) {
    return <Navigate replace to={ROUTES.managerStartPage} />;
  } else {
    return (
      <Container>
        <Row>
          <Col>
            <ProductRouterLink
              className="analyzer"
              description="Planung & Optimierung"
              header="Analyzer"
              to={ROUTES.analyzerProjectList}
            />
          </Col>
          <Col>
            <ProductRouterLink
              className="manager"
              description="Administratives Management"
              header="Manager"
              to={ROUTES.managerStartPage}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

interface ProductRouterLinkProps {
  to: string;
  header: string;
  description: string;
  className?: string;
}

function ProductRouterLink({
  to,
  header,
  description,
  className
}: ProductRouterLinkProps) {
  return (
    <Link className="product-link" to={to}>
      <Portlet className={classNames(className)}>
        <Container fluid>
          <h1 className="text-center">{header}</h1>
          <p className="text-center">{description}</p>
        </Container>
      </Portlet>
    </Link>
  );
}

export { ProductSelectPage };
