import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { TabContent, TabPane } from "reactstrap";
import { useOperators } from "../../hooks/useOperators";
import { OperatorFeatures } from "../../urls";
import { AlertColor } from "../Alert/Alert";
import { IconAlert } from "../BuildingBlocks/IconAlert/IconAlert";
import { PageContent } from "../BuildingBlocks/Layout/PageContent/PageContent";
import { PageHeader } from "../BuildingBlocks/Layout/PageHeader/PageHeader";
import { Portlet } from "../BuildingBlocks/Layout/Portlet";
import type { TabData } from "../BuildingBlocks/Layout/TabBar/TabBar";
import { TabBar } from "../BuildingBlocks/Layout/TabBar/TabBar";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { EnergyRevenuesPage } from "./EnergyRevenuesPage/EnergyRevenuesPage";
import "./RevenueMonitoringView.scss";

enum RevenueMonitoringTabs {
  EnergyRevenues = "stromerloese"
}

const TABS: Array<TabData> = [
  {
    id: RevenueMonitoringTabs.EnergyRevenues,
    label: "Stromerlöse"
  }
];

function RevenueMonitoringView() {
  const { t } = useTranslation();
  const { tab: activeTab } = useParams();
  const {
    data: companies,
    isLoading: isLoadingCompanies,
    error: companiesError
  } = useOperators({ activeFeatures: [OperatorFeatures.RevenueMonitoring] });

  return (
    <PageContent className="RevenueMonitoringView">
      <PageHeader title="Erlösmonitoring" />
      <TabBar
        activeTab={activeTab || RevenueMonitoringTabs.EnergyRevenues}
        tabs={TABS}
      />
      <Portlet>
        <TabContent
          activeTab={activeTab ?? RevenueMonitoringTabs.EnergyRevenues}
        >
          <TabPane tabId={RevenueMonitoringTabs.EnergyRevenues}>
            <LoadOrError error={companiesError} loading={isLoadingCompanies}>
              {companies ? (
                <EnergyRevenuesPage companies={companies} />
              ) : (
                <IconAlert color={AlertColor.Danger} icon="error">
                  {t("errors.RevenueMonitoring.NoCompaniesError")}
                </IconAlert>
              )}
            </LoadOrError>
          </TabPane>
        </TabContent>
      </Portlet>
    </PageContent>
  );
}

export { RevenueMonitoringView };
