import { useQuery } from "@tanstack/react-query";
import api from "../../api";
import type { StartPageInvoices } from "../../components/StartPage/StartPage.types";
import urls from "../../urls";

function useStartPageInvoices(year: number, projectId?: string) {
  const { data, error, isLoading } = useQuery({
    queryKey: urls.startPage.invoices.key(year, projectId),
    queryFn: () => fetchStartPageInvoices(year, projectId)
  });

  async function fetchStartPageInvoices(year: number, projectId?: string) {
    const response = await api.get<StartPageInvoices>(
      urls.startPage.invoices.url(year, projectId)
    );
    return response.data;
  }

  return { data, error, isLoading };
}

export { useStartPageInvoices };
