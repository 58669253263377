import type { DateTime } from "luxon";
import React from "react";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";

function ObservationPeriodCell({ period }) {
  const [start, end]: [DateTime, DateTime] = period;

  const isStartOfYear = start.equals(start.startOf("year"));
  const isEndOfYear = end.equals(end.endOf("year"));

  if (isStartOfYear && isEndOfYear) {
    return <span>Gesamtes Jahr</span>;
  } else if (isStartOfYear && !isEndOfYear) {
    return <span>Bis zum {luxonDateTimeToBackendDateOrDateTime(end)}</span>;
  } else if (!isStartOfYear && isEndOfYear) {
    return <span>Ab dem {luxonDateTimeToBackendDateOrDateTime(start)}</span>;
  } else {
    return (
      <span>
        Von {luxonDateTimeToBackendDateOrDateTime(start)} bis{" "}
        {luxonDateTimeToBackendDateOrDateTime(end)}
      </span>
    );
  }
}
export { ObservationPeriodCell };
