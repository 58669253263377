import { useQuery } from "@tanstack/react-query";

import api from "../../../../api";
import urls from "../../../../urls";
import type { EnergyDataImportRequestWithDetails } from "../../../EnergyData/EnergyDataUploadFlow/EnergyDataUpload";

interface DataImportRequestDetailsReturnValue {
  dataImportRequestWithDetails: EnergyDataImportRequestWithDetails;
  isLoading: boolean;
  isError: boolean;
  error: unknown;
}

function useDataImportRequestDetails(
  requestId: string
): DataImportRequestDetailsReturnValue {
  const {
    data: dataImportRequestWithDetails,
    isLoading,
    isError,
    error
  } = useQuery({
    queryKey: ["import-request-details", { requestId }],
    queryFn: () => fetchDataImportRequest(requestId),
    enabled: !!requestId,
    refetchOnWindowFocus: false
  });

  async function fetchDataImportRequest(requestId: string) {
    const url = urls.api.energyDataUploadImportRequestDetails(requestId);
    const response = await api.get(url);
    return response.data;
  }

  return { dataImportRequestWithDetails, isLoading, isError, error };
}

export { useDataImportRequestDetails };
