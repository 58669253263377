import { useQuery } from "@tanstack/react-query";
import api from "../../api";
import type { StartPageDeliveredElectricity } from "../../components/StartPage/StartPage.types";
import urls from "../../urls";

function useStartPageDeliveredElectricity(year: number, projectId?: string) {
  const { data, error, isLoading } = useQuery({
    queryKey: urls.startPage.deliveredElectricity.key(year, projectId),
    queryFn: () => fetchStartPageDeliveredElectricity(year, projectId)
  });

  async function fetchStartPageDeliveredElectricity(
    year: number,
    projectId?: string
  ) {
    const response = await api.get<StartPageDeliveredElectricity>(
      urls.startPage.deliveredElectricity.url(year, projectId)
    );
    return response.data;
  }

  return { data, error, isLoading };
}

export { useStartPageDeliveredElectricity };
