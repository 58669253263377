import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import urls from "../../../urls";
import type { DirektvermarktungContractResponse } from "../Direktvermarktung.types";

export function useDirektvermarktungContract(
  contractId?: string,
  options?: Partial<
    UseQueryOptions<DirektvermarktungContractResponse | undefined>
  >
) {
  const { data, isLoading, error } = useQuery({
    queryKey: ["direktvermarktungContract", { contractId }],
    queryFn: () => fetchDirektvermarktungContract(contractId),
    enabled: !!contractId,
    ...options
  });

  async function fetchDirektvermarktungContract(contractId?: string) {
    if (!contractId) return undefined;

    const direktvermarktungContractsUrl =
      urls.apiWithoutCamelization.direktvermarktungContract(contractId);
    const response =
      await apiWithoutCamelization.get<DirektvermarktungContractResponse>(
        direktvermarktungContractsUrl
      );
    return response.data;
  }

  return {
    data,
    isLoading,
    error
  };
}
