import { useQuery } from "@tanstack/react-query";
import type { UseQueryOptions } from "@tanstack/react-query";

import api from "../api";
import { openErrorAlertPopup } from "../components/ErrorAlertPopup/openErrorAlertPopup";
import urls from "../urls";
import { pollTaskStatus } from "../utils/api-utils";
import type { Invoice } from "../utils/backend-types";

export function useInvoices(
  variantId?: number | null,
  options?: Partial<UseQueryOptions<Array<Invoice> | undefined>>
) {
  const {
    data: invoices,
    isLoading,
    error
  } = useQuery({
    queryKey: ["invoices", { variantId }],
    queryFn: () => fetchInvoices(variantId),
    ...options
  });

  async function reverseInvoice(invoiceId: string): Promise<void> {
    if (!invoiceId) return;
    try {
      const response = await api.post(urls.api.archiveInvoice(invoiceId));
      if (response.data) {
        return new Promise<void>((resolve, reject) => {
          pollTaskStatus(
            urls.api.jobStatus(response.data.taskIds[0]),
            () => {
              resolve();
            },
            (error) => {
              openErrorAlertPopup(error);
              reject(error);
            },
            () => {
              openErrorAlertPopup(
                "Ein Fehler ist aufgetreten. Wir sind bereits darüber informiert."
              );
              reject();
            }
          );
        });
      }
    } catch (error) {
      openErrorAlertPopup(error);
      throw error;
    }
  }

  async function fetchInvoices(variantId?: number | null) {
    if (!variantId) {
      return undefined;
    }

    const invoicesUrl = urls.api.invoices(variantId, false);
    const response = await api.get<Array<Invoice>>(invoicesUrl);
    return response.data;
  }

  return { invoices, reverseInvoice, isLoading, error };
}
