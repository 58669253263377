import type { MissingData } from "../../../../../../hooks/useMissingData";
import type { FormValues } from "../../../../../CustomForm/useCustomForm";

/**
 *  Determine if a tab has missing data, based on the missing data object, the current form values, and the fields that are exclusive or.
 * @param groupedExclusiveOrFields - An array of arrays (groups) of fields that are exclusive or. If one field in the group array has a value, then the other fields in the array are not required. A field can only appear in one group.
 */
export function tabHasMissingData(
  tabName: string,
  missingData: MissingData,
  allFormValues: FormValues,
  groupedExclusiveOrFields: Array<Array<string>> | null
): boolean {
  if (!missingData.missingFields[tabName]) {
    return false;
  }
  const fieldsToCheck = missingData.missingFields[tabName];

  for (const field of fieldsToCheck) {
    if (isFieldMissing(field, allFormValues, groupedExclusiveOrFields)) {
      return true;
    }
  }
  return false;
}

/** Checks if all other fields in a field's group also have no value. */
function isFieldMissing(
  field: string,
  allFormValues: FormValues,
  groupedExclusiveOrFields?: Array<Array<string>> | null
): boolean {
  const formFieldValue = allFormValues[field];
  const formFieldValueIsEmpty =
    formFieldValue === "" ||
    !formFieldValue ||
    (Array.isArray(formFieldValue) && formFieldValue.length === 0);

  if (formFieldValueIsEmpty) {
    if (groupedExclusiveOrFields && groupedExclusiveOrFields.length > 0) {
      const exclusiveOrFields = groupedExclusiveOrFields.find((group) =>
        group.includes(field)
      );

      if (exclusiveOrFields) {
        return !atLeastOneFieldHasAValue(exclusiveOrFields, allFormValues);
      }
    }

    return true;
  }

  return false;
}

/** Checks all form values belonging to a group of fields to see if at least one has a value. */
function atLeastOneFieldHasAValue(
  exclusiveOrFields: Array<string>,
  allFormValues: FormValues
) {
  return exclusiveOrFields.some(
    (field) => allFormValues[field] && allFormValues[field] !== ""
  );
}
