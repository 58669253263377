import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { showToast } from "../../../../../../utils/toast";

// Unicode-safe check for letters (see also https://dev.to/tillsanders/let-s-stop-using-a-za-z-4a0m#comment-1c8jo)
const variableRegex = "@[\\p{L}\\p{M}0-9]*";

interface CharacterConstraintOptions {
  variablesAllowed: boolean;
  allowedOperatorsAndFunctions: Array<string>;
  errorMessages: {
    invalidVariablesOrFunctions: string;
  };
}

interface CharacterConstraintStorage {
  allowedCharactersRegex?: RegExp;
}

const CharacterConstraint = Extension.create<
  CharacterConstraintOptions,
  CharacterConstraintStorage
>({
  name: "characterConstraint",
  addOptions() {
    return {
      variablesAllowed: true,
      allowedOperatorsAndFunctions: [],
      errorMessages: {
        invalidVariablesOrFunctions:
          "The pasted formula contains variables or functions that are not allowed here."
      }
    };
  },
  addStorage() {
    return {};
  },
  onCreate() {
    // construct the RegExp to be used when checking for allowed chars
    const allowedSimpleChars = ["0-9", ",", "\\s"];
    const allowedFunctions: Array<string> = [];

    this.options.allowedOperatorsAndFunctions.forEach((elem) => {
      if (/^[a-zA-Z]+\(.*\)$/.test(elem)) {
        // functions need braces, so add them to simple chars
        allowedSimpleChars.push("(");
        allowedSimpleChars.push(")");
        // for char constraint, only allow exact match of function name to be typed
        // example: "Func" becomes "Fu?n?c?"
        allowedFunctions.push(
          elem
            .replace(/\(.*\)/, "")
            .replace(/^(.)(.*)$/, (_, p1: string, p2: string) => {
              return (
                p1 +
                p2
                  .split("")
                  .map((char) => char + "?")
                  .join("")
              );
            })
        );
      } else {
        // non-functions get added as separate characters to the simple chars list
        allowedSimpleChars.push(...elem.split(""));
      }
    });

    const allowedSimpleCharsUnique = [...new Set(allowedSimpleChars)].map(
      (char) => (char === "-" ? "\\-" : char) // need actual minus, not the character range token
    );

    let combinedRegex = "[" + allowedSimpleCharsUnique.join("") + "]";
    if (this.options.variablesAllowed) {
      combinedRegex += "|" + variableRegex;
    }
    if (allowedFunctions.length > 0) {
      combinedRegex += "|" + allowedFunctions.join("|");
    }

    this.storage.allowedCharactersRegex = new RegExp(
      `^(${combinedRegex})*$`,
      "u"
    );
  },
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("characterConstraint"),
        filterTransaction: (transaction) => {
          if (!transaction.docChanged) {
            return true;
          }

          if (!this.storage.allowedCharactersRegex) {
            return false;
          }

          const changedTextNode = transaction.doc;
          const text = changedTextNode.textBetween(
            0,
            changedTextNode.content.size
          );

          if (!this.storage.allowedCharactersRegex.test(text)) {
            const isPaste = transaction.getMeta("paste");
            if (isPaste) {
              showToast(
                "error",
                this.options.errorMessages.invalidVariablesOrFunctions
              );
            }
            return false;
          }

          return true;
        }
      })
    ];
  }
});

export { CharacterConstraint };
