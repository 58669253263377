import React from "react";
import ReactMarkdown from "react-markdown";

import { Icon } from "../BuildingBlocks/Icon/Icon";
import type { IconName } from "../BuildingBlocks/Icon/types";
import type { TooltipPlaces } from "../Tooltip/Tooltip";

import "./IconHelpText.scss";

interface IconHelpTextProps {
  className?: string;
  disableMarkdown?: boolean; // mk: this only exists to avoid tooltip being rendered locally in Icon
  helpText: string;
  icon: IconName;
  place?: TooltipPlaces;
}

function IconHelpTextToolTip({
  className,
  helpText,
  icon,
  disableMarkdown,
  place = "left"
}: IconHelpTextProps) {
  return (
    <Icon
      className={className}
      name={icon}
      tooltipClassName="icon-help-text-tooltip"
      tooltipPlace={place}
      tooltipText={
        disableMarkdown ? (
          helpText
        ) : (
          <ReactMarkdown linkTarget="_blank">{helpText}</ReactMarkdown>
        )
      }
    />
  );
}

export { IconHelpTextToolTip };
