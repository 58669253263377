import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export interface MissingData {
  exclusiveOrFields: Array<Array<string>>;
  missingFields: { [tabName: string]: Array<string> };
}

export function useMissingData(activeTab?: string) {
  const [missingData, setMissingData] = useState<MissingData | null>(null);
  const [missingFields, setMissingFields] = useState<Array<string> | null>(
    null
  );
  const [exclusiveOrFields, setExclusiveOrFields] = useState<Array<
    Array<string>
  > | null>(null);
  const { state: initialMissingData } = useLocation();

  useEffect(() => {
    if (initialMissingData) {
      setMissingData(initialMissingData);
    }
  }, [initialMissingData]);

  useEffect(() => {
    if (missingData) {
      if (missingData.exclusiveOrFields) {
        setExclusiveOrFields(missingData.exclusiveOrFields);
      }
      if (!activeTab) {
        setMissingFields(missingData.missingFields["general"]);
      } else {
        setMissingFields(missingData.missingFields[activeTab]);
      }
    }
  }, [activeTab, missingData, missingFields]);

  return { missingData, missingFields, exclusiveOrFields };
}
