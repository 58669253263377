import type { MeterConfigurationData } from "../../../utils/backend-types";
import { SubMeteringSystems } from "../../../utils/enums";

export function getParameterName(
  systemName: SubMeteringSystems,
  parameter?: MeterConfigurationData
): string {
  let parameterName = "Unbekannt";

  if (parameter) {
    switch (systemName) {
      case SubMeteringSystems.QIVALO:
        if ("propertyName" in parameter.parameters) {
          parameterName = parameter.parameters.propertyName;
        }
        return parameterName;
      case SubMeteringSystems.WIS:
        if ("parkName" in parameter.parameters) {
          parameterName = parameter.parameters.parkName;
        }
        return parameterName;
      case SubMeteringSystems.ROTORSOFT:
      case SubMeteringSystems.BYTEMEE:
        if ("windparkName" in parameter.parameters) {
          parameterName = parameter.parameters.windparkName;
        }
        return parameterName;
      case SubMeteringSystems.GREENBYTE:
        if ("siteName" in parameter.parameters) {
          parameterName = parameter.parameters.siteName;
        }
        return parameterName;
      case SubMeteringSystems.SIEMENS:
      case SubMeteringSystems.WONDER:
        if ("name" in parameter.parameters) {
          parameterName = parameter.parameters.name;
        }
        return parameterName;
      case SubMeteringSystems.WATTLINE:
        if ("serialNumber" in parameter.parameters) {
          parameterName = parameter.parameters.serialNumber;
        }
        return parameterName;
      default:
        return parameterName;
    }
  }

  return parameterName;
}
