import { TextInput } from "@mantine/core";
import classNames from "classnames";
import React, { useState } from "react";
import { Icon } from "../BuildingBlocks/Icon/Icon";
import { IconName } from "../BuildingBlocks/Icon/types";
import "./SearchBar.scss";
import { SearchBarButton } from "./SearchBarButton";

interface SearchBarProps {
  className?: string;
  isLoading?: boolean;
  placeholder?: string;
  btnText?: string;
  btnDisabled?: boolean;
  btnHidden?: boolean;
  inputClassName?: string;
  controlledValue?: string;
  onClear?: () => void;
  onSearch?: (searchValue: string) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function SearchBar({
  className,
  placeholder = "Suche...",
  btnText = "Suche",
  isLoading = false,
  btnDisabled = false,
  btnHidden = false,
  inputClassName,
  controlledValue,
  onClear,
  onSearch,
  onChange
}: SearchBarProps) {
  const [searchValue, setSearchValue] = useState("");
  const value = controlledValue ?? searchValue;

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      e.preventDefault();
      onSearch?.(value);
    } else if (e.key === "Escape") {
      clearSearch();
    }
  }

  function clearSearch() {
    setSearchValue("");
    onClear?.();
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    onChange?.(e);
    setSearchValue(e.target.value);
  }

  return (
    <div className={classNames("SearchBarWrapper", className)}>
      <div className="search-bar-input">
        <TextInput
          className={classNames("search-bar-input-field", inputClassName)}
          leftSection={<Icon name={IconName.Search} />}
          leftSectionPointerEvents="none"
          placeholder={placeholder}
          role="searchbox"
          type="text"
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />

        {value?.length > 0 && (
          <button
            aria-label="Löschen"
            className="search-bar-clear-btn"
            type="button"
            onClick={clearSearch}
          >
            <Icon name={IconName.Close} />
          </button>
        )}
      </div>

      {!btnHidden && (
        <SearchBarButton
          btnText={btnText}
          disabled={btnDisabled}
          isLoading={isLoading}
          onSearch={() => {
            onSearch?.(value);
          }}
        />
      )}
    </div>
  );
}

export { SearchBar, SearchBarProps };
