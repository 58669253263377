import { SiteSetupProcessDataStep } from "../../../types/api.types";

export function getOrderFromStep(step: SiteSetupProcessDataStep): number {
  switch (step) {
    case SiteSetupProcessDataStep.name_and_pv_plants:
      return 0;
    case SiteSetupProcessDataStep.address_and_supply_type:
      return 1;
    case SiteSetupProcessDataStep.connection:
      return 2;
    case SiteSetupProcessDataStep.consumers:
      return 3;
    case SiteSetupProcessDataStep.tenants:
      return 4;
    case SiteSetupProcessDataStep.meters:
      return 5;
    default:
      return 0;
  }
}
