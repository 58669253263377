import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Col } from "reactstrap";

import { IconName } from "../../BuildingBlocks/Icon/types";
import { Portlet } from "../../BuildingBlocks/Layout/Portlet";
import { Section } from "../../BuildingBlocks/Layout/Section";
import { Button } from "../../Buttons/Button/Button";
import { DateRangePicker } from "../../DatePicker/DatePicker";

const MAX_DATE_SPAN = 6;
const MIN_DATE_RANGE = DateTime.fromISO("2019-01-01");
const MAX_DATE_RANGE = DateTime.fromISO("2019-12-31");

export default class EnergyFlowDatepicker extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      startDate: props.startDate,
      endDate: props.endDate
    };
  }

  onDatesChanged = ({ startDate, endDate }) => {
    this.setState({
      startDate: startDate,
      endDate: endDate
    });
  };

  handleDateSelection = () => {
    this.props.onSelectDateRange(this.state.startDate, this.state.endDate);
  };

  handleYearlyAverageSelection = () => {
    this.props.onSelectYearlyAverage();
  };

  render() {
    const { startDate, endDate } = this.state;

    return (
      <Col md="6">
        <Portlet
          className="m-portlet--head-sm"
          helpText={
            "Die unten dargestellten Plots stellen Mittelwerte über ein gesamtes Jahr dar. Die Berechnung " +
            "wurde jedoch mit viertelstundenscharfen Erzeugungs- und Lastprofilen durchgeführt. Um einen detaillierteren " +
            "Einblick zu erhalten, können Sie darzustellende Tage explizit auswählen."
          }
          icon={IconName.QuestionCircle2}
          id="date-selection-portlet"
          title="Tagesauswahl"
          toggle
          toggleIconClosed={IconName.AngleRight}
          toggleIconOpen={IconName.AngleDown}
        >
          <Section>
            <label>Dargestellte Tage</label>
            <DateRangePicker
              id="range-date-picker"
              initialEndDate={endDate}
              initialStartDate={startDate}
              maxDate={MAX_DATE_RANGE}
              maxSpan={MAX_DATE_SPAN}
              minDate={MIN_DATE_RANGE}
              onChange={this.onDatesChanged}
            />
            <p className="info-text">
              Im Analyzer wird der aktuelle Rechtsrahmen auf historische
              Lastprofile angewendet. Standardmäßig werden die Profile aus dem
              Jahr 2019 verwendet. Wenn für einen Erzeuger/Verbraucher lediglich
              Profile aus anderen Jahren vorliegen, werden die Daten auf das
              Jahr 2019 projiziert.
            </p>
            <Button
              color="primary"
              disabled={!startDate || !endDate}
              id="energy-flows-apply-dates"
              onClick={this.handleDateSelection}
            >
              Auswählen
            </Button>
            <Button
              className="ml-2"
              onClick={this.handleYearlyAverageSelection}
            >
              Mittelwerte anzeigen
            </Button>
          </Section>
        </Portlet>
      </Col>
    );
  }
}

EnergyFlowDatepicker.propTypes = {
  onSelectDateRange: PropTypes.func.isRequired,
  onSelectYearlyAverage: PropTypes.func.isRequired,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired
};
