import { FINAL_TODO_YEAR, INITIAL_TODO_YEAR } from "../../../utils/dates";
import { OnboardingTodo, getOnboardingTodoLabel } from "../onboarding-todos";

const NOT_RELEVANT_ONBOARDING_TODOS = [
  OnboardingTodo.VOLLMACHT_FUER_MSB_BEREITSTELLEN,
  OnboardingTodo.HISTORISCHE_MESSDATEN_AUS_UNTERMESSSYSTEMEN_BEREITSTELLEN
];

const NOT_RELEVANT_TODO_LABELS = NOT_RELEVANT_ONBOARDING_TODOS.reduce<
  Array<string>
>((labels, todo) => {
  for (let year = INITIAL_TODO_YEAR; year <= FINAL_TODO_YEAR; year++) {
    labels.push(getOnboardingTodoLabel(todo, year));
  }

  return labels;
}, []);

/** Determines whether a Todo can get the status NotRelevant. */
export function canTodoBeNotRelevant(label: string) {
  return NOT_RELEVANT_TODO_LABELS.includes(label);
}
