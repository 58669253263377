import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useContainerRef } from "../../../../hooks/useContainerRef";
import { useShouldFieldBeHighlighted } from "../../../../hooks/useShouldFieldBeHighlighted";
import type { Delivery } from "../../../../utils/backend-types";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../../BuildingBlocks/Layout/Modal/Modal";
import { LoadOrError } from "../../../LoadOrError/LoadOrError";
import { DeliveryForm } from "./DeliveryForm/DeliveryForm";
import "./DeliveryModal.scss";
import { EditForm } from "./EditForm/EditForm";
import { useDelivery } from "./hooks/useDelivery";
import { useDeliveryFormOptions } from "./hooks/useDeliveryFormOptions";
import { useDeliveryMutations } from "./hooks/useDeliveryMutations";

export interface DeliveryModalProps {
  deliveryId?: string;
  contractId?: string;
  variantId: number;
  onSubmit: (delivery?: Delivery) => void;
  onClose: () => void;
}

function DeliveryModal({
  deliveryId,
  variantId,
  onSubmit,
  onClose
}: DeliveryModalProps) {
  const [searchParams] = useSearchParams();
  const { containerNode, containerRef } = useContainerRef();
  const { delivery, isDeliveryDataLoading, deliveryLoadingError } =
    useDelivery(deliveryId);
  const { deliveryCreateMutation, deliveryUpdateMutation } =
    useDeliveryMutations(variantId, deliveryId);
  const contractId = searchParams.get("contract");

  const {
    data: deliveryFormOptions,
    isLoading: isDeliveryFormOptionsLoading,
    error: deliveryFormOptionsError
  } = useDeliveryFormOptions(variantId);

  const { state: missingData } = useLocation();
  const missingFields =
    (missingData && missingData.missingFields.general) || [];
  const shouldFieldBeHighlighted = useShouldFieldBeHighlighted(missingFields);

  async function handleSubmitCreateDelivery(delivery: Delivery) {
    try {
      const response = await deliveryCreateMutation.mutateAsync(delivery);
      onSubmit();

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  const title = deliveryId ? "Übersicht Lieferung" : "Lieferung hinzufügen";

  async function handleSubmitEditDelivery(delivery: Delivery) {
    const response = await deliveryUpdateMutation.mutateAsync({
      ...delivery
    });

    onSubmit(delivery);

    return response;
  }

  const editMode = typeof deliveryId !== "undefined";

  return (
    <Modal className="DeliveryModal" isOpen size="lg" toggle={onClose}>
      <ModalHeader toggle={onClose}>{title}</ModalHeader>
      <ModalBody scrollable>
        <LoadOrError
          error={deliveryFormOptionsError || deliveryLoadingError}
          loading={isDeliveryFormOptionsLoading || isDeliveryDataLoading}
        >
          {deliveryFormOptions &&
            (editMode ? (
              delivery && (
                <EditForm
                  buttonContainer={containerNode}
                  delivery={delivery}
                  missingFields={missingFields}
                  options={deliveryFormOptions}
                  shouldFieldBeHighlighted={shouldFieldBeHighlighted}
                  onCancel={onClose}
                  onSubmit={handleSubmitEditDelivery}
                />
              )
            ) : (
              <DeliveryForm
                buttonContainer={containerNode}
                delivery={delivery}
                initialContract={contractId}
                missingFields={[]}
                options={deliveryFormOptions}
                shouldFieldBeHighlighted={shouldFieldBeHighlighted}
                onCancel={onClose}
                onSubmit={handleSubmitCreateDelivery}
              />
            ))}
        </LoadOrError>
      </ModalBody>
      <ModalFooter>
        <div className="delivery-modal-buttons" ref={containerRef} />
      </ModalFooter>
    </Modal>
  );
}

export { DeliveryModal };
