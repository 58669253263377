import PropTypes from "prop-types";
import React from "react";

import type { TodoUser } from "../../common";
import { UNKNOWN_USER } from "../../common";

interface TodoResponsibleCellProps {
  responsibleId: number | null;
  users: Array<TodoUser>;
  todoId: number;
  universal: boolean;
  onChangeResponsible: (responsibleId: number | null, todoId: number) => void;
}

function TodoResponsibleCell({
  responsibleId,
  users,
  todoId,
  universal,
  onChangeResponsible
}: TodoResponsibleCellProps) {
  if (universal) {
    return (
      <UniversalResponsibleCell responsibleId={responsibleId} users={users} />
    );
  }

  const selectableUsers = users.filter(
    (user) => !user.isSystemUser || responsibleId === user.id
  );
  const responsibleUserIsUnknown = !selectableUsers.find(
    (user) => user.id === responsibleId
  );

  return (
    <div className="responsible-cell">
      <select
        className="cell-dropdown form-control m-input"
        value={responsibleId ? responsibleId : ""}
        onChange={(event) =>
          onChangeResponsible(
            event.target.value !== "" ? parseInt(event.target.value) : null,
            todoId
          )
        }
      >
        <option value="" />
        {selectableUsers.map((user) => (
          <option key={user.id} value={user.id}>
            {user.name}
          </option>
        ))}
        {responsibleUserIsUnknown && responsibleId !== null && (
          <option key={responsibleId} value={responsibleId}>
            {UNKNOWN_USER}
          </option>
        )}
      </select>
    </div>
  );
}

function UniversalResponsibleCell({
  responsibleId,
  users
}: Pick<TodoResponsibleCellProps, "responsibleId" | "users">) {
  const responsibleUser = responsibleId
    ? users.find((user) => user.id === responsibleId)
    : null;
  const responsibleName = responsibleUser ? responsibleUser.name : UNKNOWN_USER;

  return (
    <span className="label-cell-text">
      {responsibleId ? responsibleName : ""}
    </span>
  );
}

TodoResponsibleCell.propTypes = {
  responsibleId: PropTypes.number,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  todoId: PropTypes.number.isRequired,
  universal: PropTypes.bool.isRequired,
  onChangeResponsible: PropTypes.func.isRequired
};

export { TodoResponsibleCell };
