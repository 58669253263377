import PropTypes from "prop-types";
import React from "react";

import { Checkbox } from "../../BuildingBlocks/Forms/Checkbox/Checkbox";

export interface CheckboxCellProps {
  defaultValue: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
}

function CheckboxCell({ defaultValue, onChange, disabled }: CheckboxCellProps) {
  return (
    <Checkbox checked={defaultValue} disabled={disabled} onChange={onChange} />
  );
}

CheckboxCell.propTypes = {
  defaultValue: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export { CheckboxCell };
