export function makeSafeForCSS(name: string) {
  const replacedName = name
    .toLowerCase()
    .replace(/[^a-z\s-]/g, "") // remove invalid characters
    .replace(/\s+/g, "-") // collapse whitespaces and replace with dash
    .replace(/-+/g, "-"); // collapse dashes

  if (replacedName === "") {
    return "default-safe-class";
  }

  return replacedName;
}
