import { useMutation } from "@tanstack/react-query";

import React, { useState } from "react";

import type { ApiError } from "../../../../../../api";
import api from "../../../../../../api";
import urls from "../../../../../../urls";
import { showToast } from "../../../../../../utils/toast";
import {
  BasicConfirmationModal,
  BasicConfirmationModalIcon
} from "../../../../../BuildingBlocks/Layout/Modals/BasicConfirmationModal/BasicConfirmationModal";
import { Button } from "../../../../../Buttons/Button/Button";

interface GenerateProformaDocumentsButtonProps {
  todoId: number;
  disabled?: boolean;
  reloadTodo: (arg: unknown) => void;
}

function GenerateProformaDocumentsButton({
  todoId,
  disabled,
  reloadTodo
}: GenerateProformaDocumentsButtonProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const { mutateAsync: generateProformaDocuments } = useMutation({
    mutationFn: (id: number) => {
      return api.post(urls.api.todoGenerateProformaDocuments(id));
    }
  });

  function handleGenerateProformaSuccess() {
    showToast("success", "Pro-Forma-Meldung wurde erstellt");
    setModalIsOpen(false);

    /**
     * this workaround is needed because the backend places the task in a queue and we do not have a way to check if the task is finished.
     * So we just wait 3 seconds and then query the todos again to show the user the new document without refreshing the page.
     * TODO: remove this workaround: https://node-energy.atlassian.net/browse/UIUX-81
     */
    setTimeout(() => {
      api.get(urls.api.todosDetail(todoId)).then((response) => {
        reloadTodo(response.data);
      });
    }, 3000);
  }

  function handleGenerateProformaError(error: ApiError) {
    showToast("error", error);
  }

  return (
    <>
      <Button color="brand" disabled={disabled} outline onClick={toggleModal}>
        Pro-Forma-Meldung erstellen
      </Button>
      <BasicConfirmationModal
        confirmationButtonColor="brand"
        confirmationButtonText="Bestätigen"
        headerText="Proforma-Meldeunterlagen erstellen"
        icon={BasicConfirmationModalIcon.Success}
        isModalOpen={modalIsOpen}
        toggleModal={toggleModal}
        onCancel={toggleModal}
        onConfirm={() => {
          generateProformaDocuments(todoId)
            .then(handleGenerateProformaSuccess)
            .catch(handleGenerateProformaError);
        }}
      >
        <p>
          Es liegen noch nicht für alle Liegenschaften Ihres Unternehmens
          ausreichend Daten vor. Damit Sie trotzdem bereits Meldungen für alle
          Ihre Liegenschaften einreichen können, erstellt opti.node für Sie
          folgende Meldeunterlagen:
        </p>
        <ul>
          <li>
            Für Liegenschaften mit vollständigen und plausiblen Daten werden die
            vorhandenen Werte in der Berechnung berücksichtigt und gültige
            Dokumentationen erzeugt.
          </li>
          <li>
            Für Liegenschaften mit unvollständigen oder unplausiblen Daten
            werden Proforma-Werte verwendet und Sie erhalten
            Proforma-Unterlagen.
          </li>
        </ul>
        <p>
          Sobald vollständige und plausible Messdaten vorliegen, können Sie die
          finalen Meldeunterlagen erzeugen und nachreichen.
        </p>
      </BasicConfirmationModal>
    </>
  );
}

export { GenerateProformaDocumentsButton };
