export function validateMarketLocationNumber(number: string | number | null) {
  if (number === null) {
    return false;
  }

  if (typeof number === "number") {
    return validateString(number.toString());
  }

  return validateString(number);
}

function validateString(value: string) {
  return /^\d{11}$/.test(value);
}
