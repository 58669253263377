import classNames from "classnames";
import type { DateTime } from "luxon";
import React, { useId } from "react";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import {
  DateInput as DateInputComponent,
  type DateInputProps as DateInputComponentProps
} from "../../../Dates/DateInput/DateInput";
import { InputWrapper } from "../../utils/InputWrapper";
import "./DateInput.scss";

interface DateInputProps
  extends Pick<DateInputComponentProps, "disabled" | "placeholder"> {
  className?: string;
  errorText?: string;
  firstPossibleDate?: string;
  lastPossibleDate?: string;
  name?: string;
  id?: string;
  invalid?: boolean;
  value?: string | null;
  warned?: boolean;
  showCalendarIcon?: boolean;
  onChange: (date: string | null) => void;
}

function DateInput(
  {
    className,
    disabled,
    errorText,
    firstPossibleDate,
    lastPossibleDate,
    id,
    invalid,
    placeholder,
    value,
    warned,
    showCalendarIcon,
    onChange
  }: DateInputProps,
  ref?: React.Ref<HTMLInputElement>
) {
  const backupId = useId();
  const datePickerId = id || backupId;

  function parseDateInput(input?: string | null) {
    if (input === "" || !input) {
      return undefined;
    }

    return backendDateOrDateTimeToLuxonDateTime(input);
  }

  function handleDatePickerChange(date: DateTime | null) {
    const text = date ? luxonDateTimeToBackendDateOrDateTime(date) : null;
    onChange(text);
  }

  return (
    <div
      className={classNames("DateInput2", {
        "is-invalid": invalid,
        warning: !invalid && warned
      })}
    >
      <InputWrapper className="date-picker-input-wrapper" errorText={errorText}>
        <DateInputComponent
          className={className}
          date={parseDateInput(value)}
          disabled={disabled}
          firstPossibleDate={parseDateInput(firstPossibleDate)}
          id={datePickerId}
          lastPossibleDate={parseDateInput(lastPossibleDate)}
          placeholder={placeholder}
          ref={ref}
          showCalendarIcon={showCalendarIcon}
          showClearDate
          onChange={handleDatePickerChange}
        />
      </InputWrapper>
    </div>
  );
}

const ForwardedRefDateInput = React.forwardRef(DateInput);

export { ForwardedRefDateInput as DateInput, DateInputProps };
