import classNames from "classnames";
import React from "react";

import { Alert, AlertColor } from "../Alert/Alert";

import "./LightAlert.scss";

export function LightAlert({ className = undefined, ...otherProps }) {
  return (
    <Alert
      className={classNames("custom-alert", className)}
      closeClassName={"light-alert-close"}
      color={AlertColor.Light}
      {...otherProps}
    />
  );
}
