import React, { useState } from "react";

import { useParameterSetCollection } from "../../../../../hooks/useParameterSetCollection";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../../../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../../../../Buttons/Button/Button";
import type { Choice } from "../../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { TsDropdown } from "../../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { LoadOrError } from "../../../../LoadOrError/LoadOrError";

import "./ReimportDataImportRequestsModal.scss";

interface Props {
  dataImportRequestsNames: string[];
  onToggle: () => void;
  onAction: (parameterSetId: string) => void;
}

function ReimportDataImportRequestsModal({
  dataImportRequestsNames,
  onAction,
  onToggle
}: Props) {
  const [selectedParameterSetId, setSelectedParameterSetId] = useState<
    string | null
  >(null);

  const { data, isLoading, error } = useParameterSetCollection();

  const parameterSets: Choice[] = (data || []).map(({ id, name }) => ({
    displayName: name,
    value: id
  }));

  const onParameterSetChange = (_, value: string) =>
    setSelectedParameterSetId(value);

  const handleSendClick = () => {
    if (selectedParameterSetId !== null) {
      onAction(selectedParameterSetId);
    }
  };

  return (
    <Modal className="reimport-modal" isOpen toggle={onToggle}>
      <ModalHeader toggle={onToggle}>Reimport</ModalHeader>
      <ModalBody className="list">
        <p>Möchten Sie die folgenden Dateien reimport?</p>
        <ul>
          {dataImportRequestsNames.map((name, index) => (
            <li key={`object${index}`}>{name}</li>
          ))}
        </ul>
      </ModalBody>
      <ModalBody>
        <p>Dateiformat auswählen</p>
        <LoadOrError error={error} loading={isLoading}>
          <div className="parameter-set-control">
            <TsDropdown
              choices={parameterSets}
              defaultValue=""
              id="raw-file-origins-data"
              name="raw-file-origins-data"
              required={true}
              onChange={onParameterSetChange}
            />
          </div>
        </LoadOrError>
      </ModalBody>
      <ModalFooter>
        <div className="footer-buttons">
          <Button color="secondary" onClick={onToggle}>
            Abbrechen
          </Button>
          <Button
            color="primary"
            disabled={selectedParameterSetId === null}
            onClick={handleSendClick}
          >
            Einsenden
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export { ReimportDataImportRequestsModal };
