import type { QueryClient } from "@tanstack/react-query";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { noop } from "lodash";

import api from "../api";
import urls from "../urls";
import { pollTaskStatus } from "../utils/api-utils";
import type { MachineAccountConfiguration } from "../utils/backend-types";
import { MachineAccountReimportStatus } from "../utils/backend-types";
import { showToast } from "../utils/toast";

const QUERY_KEY = "machineAccountConfiguration";
const INTERVAL = 3000;

const pollReimportStatus = (queryClient: QueryClient, celeryTaskId: string) =>
  pollTaskStatus(
    urls.api.jobStatus(celeryTaskId),
    () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    },
    ({ userFacingErrorMessage }) => {
      showToast("error", userFacingErrorMessage);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    },
    noop,
    INTERVAL
  );

function useMachineAccounts(variantId: number) {
  const queryClient = useQueryClient();

  const { data, isLoading, error } = useQuery({
    queryKey: [QUERY_KEY],
    queryFn: () => fetchMachineAccountConfiguration()
  });

  const fetchMachineAccountConfiguration = async () => {
    const response = await api.get<MachineAccountConfiguration[]>(
      urls.api.machineAccountConfigurations(variantId)
    );

    return response.data?.map((item) => {
      if (item.status === MachineAccountReimportStatus.InProgress) {
        pollReimportStatus(queryClient, item.celeryTaskId);
      }

      return {
        ...item,
        email: `${item.receiverUuid}@opti.node.energy`
      };
    });
  };

  const createMachineAccountConfiguration = async (
    parameterSetId: string | null
  ) => {
    if (!parameterSetId || !variantId) {
      return;
    }

    await api.post(urls.api.machineAccountConfigurations(), {
      parameterSet: parameterSetId,
      variant: variantId
    });

    queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
  };

  const updateMachineAccountConfiguration = async (
    machineAccountId: number | undefined,
    parameterSetId: string | null
  ) => {
    if (!parameterSetId || !machineAccountId) {
      return;
    }

    await api.patch(urls.api.machineAccountConfiguration(machineAccountId), {
      parameterSet: parameterSetId
    });

    queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
  };

  const deleteMachineAccountConfiguration = async (
    machineAccountId: number | undefined
  ) => {
    if (!machineAccountId) {
      return;
    }

    await api.delete(urls.api.machineAccountConfiguration(machineAccountId));

    queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
  };

  const reimportForMachineAccountConfiguration = async (
    machineAccountId: number,
    variantId: number
  ) => {
    const response = await api.post<MachineAccountConfiguration>(
      urls.api.reimportFormachineAccountConfiguration(
        machineAccountId,
        variantId
      )
    );

    if (response.data.status === MachineAccountReimportStatus.InProgress) {
      return new Promise<boolean>((resolve) => {
        setTimeout(() => {
          pollReimportStatus(queryClient, response.data.celeryTaskId);
          resolve(true);
        }, INTERVAL);
      });
    } else {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      return true;
    }
  };

  return {
    machineAccountConfiguration: data,
    isLoading,
    error,
    createMachineAccountConfiguration,
    updateMachineAccountConfiguration,
    deleteMachineAccountConfiguration,
    reimportForMachineAccountConfiguration
  };
}

export { useMachineAccounts };
