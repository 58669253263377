import { useState } from "react";
import api from "../api";
import { openErrorAlertPopup } from "../components/ErrorAlertPopup/openErrorAlertPopup";
import urls from "../urls";
import { pollTaskStatus } from "../utils/api-utils";
import { downloadPDF } from "../utils/downloadPdf";

function usePreviewInvoices() {
  const [contractLoading, setContractLoading] = useState(null);

  function getPDFfromBackend(contractId, taskId) {
    api
      .get(urls.api.getPreviewInvoice(contractId, taskId))
      .then((r) => {
        downloadPDF(r);
      })
      .finally(() => {
        setContractLoading(null);
      });
  }

  function generateAndDownloadPreview(contractId) {
    if (!contractId) return;
    setContractLoading(contractId);
    api.get(urls.api.generatePreviewInvoices(contractId)).then((response) => {
      if (response.data) {
        pollTaskStatus(
          urls.api.jobStatus(response.data.taskIds[0]),
          () => getPDFfromBackend(contractId, response.data.taskIds[0]),
          (error) => {
            setContractLoading(null);
            openErrorAlertPopup(error);
          },
          () => {
            openErrorAlertPopup(
              "Ein Fehler ist aufgetreten. Wir sind bereits darüber informiert."
            );
          }
        );
      }
    });
  }

  return { generateAndDownloadPreview, contractLoading };
}

export { usePreviewInvoices };
