import PropTypes from "prop-types";
import React from "react";
import { Link, useMatch } from "react-router-dom";
import { ROUTES } from "../../../routes";
import { ObjectName } from "../../../utils/enums";
import { getLinkToComponentEditModal } from "../../../utils/getLinkToComponentEditModal";

function ComponentOpenEditModalLink({ componentId, objectName, linkContent }) {
  const managerMatch = useMatch(ROUTES.managerVariantStructure + "/*");
  const analyzerMatch = useMatch(ROUTES.analyzerVariantStructure + "/*");
  const evaluationMatch = useMatch(ROUTES.evaluation + "/*");
  const path = getLinkToComponentEditModal(
    objectName,
    componentId,
    managerMatch,
    analyzerMatch,
    evaluationMatch
  );

  if (evaluationMatch) {
    return <a href={path}>{linkContent}</a>;
  }

  return <Link to={path}>{linkContent}</Link>;
}

ComponentOpenEditModalLink.propTypes = {
  objectName: PropTypes.oneOf(Object.values(ObjectName)).isRequired,
  linkContent: PropTypes.any.isRequired,
  componentId: PropTypes.number.isRequired
};

export { ComponentOpenEditModalLink };
