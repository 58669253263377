import urls from "../../../../urls";
import type { Project } from "../../../../utils/backend-types";
import type { FormInputData } from "../../../BuildingBlocks/Forms/Controllers/FormFieldController";

export const PROJECT_SETTINGS_FORM_FIELD_DATA = {
  name: {
    name: "name",
    label: "Bezeichnung",
    helpText: "Frei wählbar, wird nur zur Identifikation benutzt",
    type: "text",
    required: true
  },
  customer: {
    name: "customer",
    label: "Kunde",
    type: "async-select",
    placeholder: "Name oder Hubspot-ID",
    dataUrl: urls.api.customersList(),
    required: false
  },
  reminderMailsActive: {
    name: "reminderMailsActive",
    label: "Erinnerungsmails aktiviert",
    type: "boolean",
    required: true
  },
  active: {
    name: "active",
    label: "Projekt ist aktiv",
    type: "boolean",
    required: true
  },
  parkabrechnungActive: {
    name: "parkabrechnungActive",
    label: "Parkabrechnung ist aktiv",
    type: "boolean",
    required: true
  },
  optichargeActive: {
    name: "optichargeActive",
    label: "OptiCharge ist aktiv",
    type: "boolean",
    required: true
  },
  sapExportActive: {
    name: "sapExportActive",
    label: "SAP-Export ist aktiv",
    type: "boolean",
    required: true
  },
  documentsInOnboardingAssistantActive: {
    name: "documentsInOnboardingAssistantActive",
    label: "Anzeige von Dokumenten im Onboarding-Assistenten ist aktiv",
    type: "boolean",
    required: true
  },
  customerMbkWriteAccessActive: {
    name: "customerMbkWriteAccessActive",
    label: "Kunde hat Schreibzugriff auf MBK",
    type: "boolean",
    required: true
  }
} as const satisfies {
  [key in keyof Project]?: FormInputData<Project, key>;
};
