import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

import { apiWithoutCamelization } from "../../../api";
import urls from "../../../urls";
import type { Paragraph6Company } from "../Paragraph6.types";

export function useParagraph6Companies(
  variantId: number,
  options?: Partial<UseQueryOptions<Array<Paragraph6Company>>>
) {
  const {
    data: paragraph6Companies,
    isLoading,
    error
  } = useQuery({
    queryKey: ["paragraph6Companies", { variantId }],
    queryFn: () => fetchParagraph6Companies(variantId),
    ...options
  });

  async function fetchParagraph6Companies(variantId: number) {
    const paragraph6CompaniesUrl =
      urls.apiWithoutCamelization.paragraph6Companies(variantId);
    const response = await apiWithoutCamelization.get<Array<Paragraph6Company>>(
      paragraph6CompaniesUrl
    );
    return response.data;
  }

  return { paragraph6Companies, isLoading, error };
}
