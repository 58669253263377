import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";

import DeliveryConstraintRow from "./DeliveryConstraint/DeliveryConstraint";
import type { DeliveryConstraint } from "./useDeliveryConstraints";

import "./DeliveryConstraints.scss";

interface DeliveryConstraintsProps {
  componentId?: number;
  deliveryConstraints: Array<DeliveryConstraint>;
  onChange: (
    id: number,
    parameter: string,
    value: boolean | number | null
  ) => void;
}

function DeliveryConstraints({
  componentId,
  deliveryConstraints,
  onChange
}: DeliveryConstraintsProps) {
  function filterDeliveryConstraints(
    deliveryConstraints: Array<DeliveryConstraint>,
    componentId?: number
  ) {
    if (!componentId) {
      return deliveryConstraints;
    }

    return deliveryConstraints.filter(
      (constraint) =>
        constraint.source.id === componentId ||
        constraint.target.id === componentId
    );
  }

  function renderDeliveryConstraint(deliveryConstraint: DeliveryConstraint) {
    return (
      <DeliveryConstraintRow
        id={deliveryConstraint.id}
        key={deliveryConstraint.id}
        possible={deliveryConstraint.possible}
        priceCtPerKwh={deliveryConstraint.priceCtPerKwh}
        source={deliveryConstraint.source}
        target={deliveryConstraint.target}
        onChange={onChange}
      />
    );
  }

  const sortedDeliveryConstraints = _.orderBy(deliveryConstraints, [
    "source.name",
    "target.name"
  ]) as DeliveryConstraint[];
  const filteredDeliveryConstraints = filterDeliveryConstraints(
    sortedDeliveryConstraints,
    componentId
  );

  return (
    <div className="DeliveryConstraints">
      <div className="table-responsive">
        <table
          className="table m-table m-table--head-bg-brand"
          id="select-delivery-table"
        >
          <thead>
            <tr>
              <th style={{ width: "25%" }}>Erzeuger</th>
              <th style={{ width: "25%" }}>Verbraucher</th>
              <th className="text-center" style={{ width: "25%" }}>
                Belieferung in Optimierung berücksichtigen
              </th>
              <th className="text-center" style={{ width: "25%" }}>
                Lieferpreis (ct/kWh)
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredDeliveryConstraints.length === 0 && (
              <tr>
                <td colSpan={4}>Keine Lieferbeziehungen vorhanden</td>
              </tr>
            )}
            {filteredDeliveryConstraints.map((deliveryConstraint) =>
              renderDeliveryConstraint(deliveryConstraint)
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

DeliveryConstraints.propTypes = {
  deliveryConstraints: PropTypes.arrayOf(PropTypes.object).isRequired,
  componentId: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

export { DeliveryConstraints };
