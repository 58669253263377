import { useQuery } from "@tanstack/react-query";

import api from "../api";
import urls from "../urls";

function useCanAssessManagerSiteFinancially() {
  const {
    isLoading,
    error,
    data: currentUserCanAssessManagerSiteFinancially
  } = useQuery({
    queryKey: ["can-assess-manager-site-financially"],
    queryFn: () => fetchData(),
    refetchInterval: false,
    refetchOnWindowFocus: false
  });

  async function fetchData() {
    const response = await api.get<boolean>(
      urls.api.currentUserCanAssessManagerSiteFinancially()
    );
    return response.data;
  }

  return {
    isLoading,
    error,
    currentUserCanAssessManagerSiteFinancially
  };
}

export { useCanAssessManagerSiteFinancially };
