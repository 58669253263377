export enum FontWeight {
  VeryThin = 300,
  Thin = 400,
  Normal = 500,
  Bold = 600,
  VeryBold = 700
}

export function getDescriptionForFontWeight(fontWeight: FontWeight): string {
  switch (fontWeight) {
    case FontWeight.VeryThin:
      return "Sehr dünn";
    case FontWeight.Thin:
      return "Dünn";
    case FontWeight.Normal:
      return "Normal";
    case FontWeight.Bold:
      return "Fett";
    case FontWeight.VeryBold:
      return "Sehr fett";
    default:
      console.error("Unknown font weight: ", fontWeight);
      return "Unbekannt";
  }
}
