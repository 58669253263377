import { useQuery } from "@tanstack/react-query";
import api from "../api";
import urls from "../urls";
import type { Generator } from "../utils/backend-types";

export function usePersonGenerators(personId?: number) {
  const { data, isLoading, error } = useQuery({
    queryKey: ["generatorsByPerson", { personId }],
    queryFn: () => fetchPersonGenerators(personId),
    enabled: typeof personId !== "undefined"
  });

  async function fetchPersonGenerators(personId?: number) {
    if (typeof personId === "undefined") {
      return undefined;
    }

    const response = await api.get<Array<Generator>>(
      urls.api.generatorsByPerson(personId)
    );

    return response.data;
  }

  return { data, isLoading, error };
}
