import classNames from "classnames";
import React from "react";
import type { PlacesType, ITooltip as ReactTooltipProps } from "react-tooltip";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "./Tooltip.scss";

type TooltipPlaces = PlacesType;

export const TOOLTIP_ID = "optinode-tooltip";

function Tooltip({
  className,
  id = TOOLTIP_ID,
  ...reactTooltipProps
}: ReactTooltipProps) {
  return (
    <ReactTooltip
      className={classNames(className, "optinode-tooltip")}
      id={id}
      opacity={1}
      variant="light"
      {...reactTooltipProps}
    />
  );
}

interface TooltipAnchorOptions {
  content?: string;
  delayShow?: number;
  id?: string;
  place?: PlacesType;
}

/** Connects an element with a Tooltip (if no `id` is specified, it connects to the App's main Tooltip) */
function getTooltipAnchorProps({
  id = TOOLTIP_ID,
  content,
  delayShow,
  place
}: TooltipAnchorOptions) {
  return {
    "data-tooltip-id": id,
    "data-tooltip-content": content,
    "data-tooltip-place": place,
    "data-delay-show": delayShow
  };
}

export {
  Tooltip,
  TooltipPlaces,
  TooltipAnchorOptions,
  getTooltipAnchorProps as anchorTooltip
};
