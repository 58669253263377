import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../api";
import urls from "../urls";
import type { VariantDetail } from "./useVariantDetail";

export function useVariantDetailMutations(variantId: number) {
  const queryClient = useQueryClient();

  const editMutation = useMutation({
    mutationFn: (variantDetail: VariantDetail) =>
      editVariantDetail(variantDetail, variantId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["variantDetail", { variantId }]
      });
    }
  });

  return {
    editMutation
  };
}

function editVariantDetail(variantDetail: VariantDetail, variantId: number) {
  const url = urls.apiWithoutCamelization.variantDetail(variantId);
  return apiWithoutCamelization.patch(url, variantDetail);
}
