import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { ObjectName, Product } from "../../../utils/enums";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "../../BuildingBlocks/Dropdown/Dropdown";
import { VariantObjectWizard } from "../../VariantObjectWizard/VariantObjectWizard";
import { UserAccessContext } from "../UserAccessContext";
import "./CreateComponentDropdown.scss";

interface CreateComponentDropdownProps {
  product: Product;
  siteId: number;
  variantId: number;
  hasGasConnection: boolean;
  graphCoordinates?: { x: number; y: number };
  setShowNotAccessibleModal?: (show: boolean) => void;
}

function CreateComponentDropdown({
  product,
  siteId,
  variantId,
  hasGasConnection,
  graphCoordinates,
  setShowNotAccessibleModal
}: CreateComponentDropdownProps) {
  const [modalObjectName, setModalObjectName] = useState<ObjectName | null>(
    null
  );
  const [modalObjectDisplayName, setModalObjectDisplayname] = useState<
    string | null
  >(null);

  const userCanCreateAndDelete = useContext(UserAccessContext);
  function handleCloseModal() {
    setModalObjectName(null);
    setModalObjectDisplayname(null);
  }

  function handleOpenModal(objectName: ObjectName) {
    if (
      userCanCreateAndDelete &&
      setShowNotAccessibleModal &&
      !userCanCreateAndDelete[objectName]
    ) {
      setShowNotAccessibleModal(true);
    } else {
      setModalObjectName(objectName);
      setModalObjectDisplayname(objectName);
    }
  }

  function getDropdownClassName(objectName: ObjectName): string {
    if (userCanCreateAndDelete && !userCanCreateAndDelete[objectName]) {
      return "CreateComponentDropdown--disabled";
    }

    return "";
  }

  return (
    <div className="CreateComponentDropdown">
      <UncontrolledDropdown>
        <DropdownToggle caret color="brand">
          Neu erstellen
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            className={getDropdownClassName(ObjectName.Generator)}
            onClick={() => handleOpenModal(ObjectName.Generator)}
          >
            {ObjectName.Generator}
          </DropdownItem>
          <DropdownItem
            className={getDropdownClassName(ObjectName.Consumer)}
            onClick={() => handleOpenModal(ObjectName.Consumer)}
          >
            {ObjectName.Consumer}
          </DropdownItem>
          <DropdownItem
            className={getDropdownClassName(ObjectName.Storage)}
            onClick={() => handleOpenModal(ObjectName.Storage)}
          >
            {ObjectName.Storage}
          </DropdownItem>
          {product === Product.Analyzer && (
            <DropdownItem
              className={getDropdownClassName(ObjectName.Person)}
              onClick={() => handleOpenModal(ObjectName.Person)}
            >
              {ObjectName.Person}
            </DropdownItem>
          )}
          <DropdownItem
            className={getDropdownClassName(ObjectName.Connection)}
            onClick={() => handleOpenModal(ObjectName.Connection)}
          >
            {ObjectName.Connection}
          </DropdownItem>
          {product === Product.Manager && !hasGasConnection && (
            <DropdownItem
              className={getDropdownClassName(ObjectName.GasConnection)}
              onClick={() => handleOpenModal(ObjectName.GasConnection)}
            >
              {ObjectName.GasConnection}
            </DropdownItem>
          )}
          {product === Product.Manager && (
            <DropdownItem
              className={getDropdownClassName(ObjectName.Meter)}
              onClick={() => handleOpenModal(ObjectName.Meter)}
            >
              {ObjectName.Meter}
            </DropdownItem>
          )}
          <DropdownItem
            className={getDropdownClassName(ObjectName.GhostNode)}
            onClick={() => handleOpenModal(ObjectName.GhostNode)}
          >
            {ObjectName.GhostNode}
          </DropdownItem>
          {product !== Product.Analyzer && (
            <React.Fragment>
              <DropdownItem
                className={getDropdownClassName(ObjectName.MarketLocation)}
                onClick={() => handleOpenModal(ObjectName.MarketLocation)}
              >
                {ObjectName.MarketLocation}
              </DropdownItem>
              <DropdownItem
                className={getDropdownClassName(ObjectName.MeteringLocation)}
                onClick={() => handleOpenModal(ObjectName.MeteringLocation)}
              >
                {ObjectName.MeteringLocation}
              </DropdownItem>
            </React.Fragment>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>

      {!!modalObjectName && !!modalObjectDisplayName && (
        <VariantObjectWizard
          graphCoordinates={graphCoordinates}
          isOpen={!!modalObjectName && !!modalObjectDisplayName}
          mode="create"
          objectDisplayName={modalObjectDisplayName}
          objectName={modalObjectName}
          product={product}
          siteId={siteId}
          variantId={variantId}
          onToggle={handleCloseModal}
        />
      )}
    </div>
  );
}

CreateComponentDropdown.propTypes = {
  product: PropTypes.string.isRequired,
  siteId: PropTypes.number.isRequired,
  variantId: PropTypes.number.isRequired,
  userHasNoAccess: PropTypes.bool,
  setShowNotAccessibleModal: PropTypes.func
};

export { CreateComponentDropdown };
