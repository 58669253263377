import classNames from "classnames";
import React from "react";
import "./NonSelectableDropdownItem.scss";

type NonSelectableDropdownItemProps = React.HTMLAttributes<HTMLDivElement>;

function NonSelectableDropdownItem({
  className,
  ...otherProps
}: NonSelectableDropdownItemProps) {
  return (
    <div
      className={classNames(className, "NonSelectableDropdownItem")}
      {...otherProps}
    />
  );
}

export { NonSelectableDropdownItem };
