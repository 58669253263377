import { useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../../../../../api";
import urls from "../../../../../urls";
import type { Contract } from "../../../../../utils/backend-types";

export function useContract(contractId: string | undefined) {
  const queryClient = useQueryClient();
  const {
    isLoading: isContractDataLoading,
    error: contractLoadingError,
    data: contract
  } = useQuery({
    queryKey: ["contract", { contractId }],
    queryFn: () => fetchContract(contractId),
    enabled: typeof contractId !== "undefined",
    gcTime: 0,
    refetchInterval: false,
    refetchOnWindowFocus: false
  });

  async function createContract(contract: Contract) {
    const endpointUrl = urls.api.contracts(contract.variant);

    try {
      const response = await api.post<Contract>(endpointUrl, contract);
      queryClient.invalidateQueries({
        queryKey: ["contracts", { variantId: contract.variant }]
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function updateContract(contract: Contract) {
    const endpointUrl = urls.api.contract(contract.id);

    try {
      const response = await api.put<Contract>(endpointUrl, contract);
      queryClient.invalidateQueries({
        queryKey: ["contract", { contractId: contract.id }]
      });
      queryClient.invalidateQueries({
        queryKey: ["contracts", { variantId: contract.variant }]
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return {
    isContractDataLoading,
    contractLoadingError,
    contract,
    createContract,
    updateContract
  };
}

async function fetchContract(contractId: string | undefined) {
  if (!contractId) {
    return undefined;
  }

  const endpointUrl = urls.api.contract(contractId);
  const response = await api.get<Contract>(endpointUrl);

  return response.data;
}
