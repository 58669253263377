import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";

import urls from "../../../urls";
import type { Product } from "../../../utils/enums";
import { Portlet } from "../../BuildingBlocks/Layout/Portlet";
import { DefineSolarSimulationForm } from "../../DefineSolarSimulationForm/DefineSolarSimulationForm";
import DynamicForm from "../../DynamicForm";
import { IconHelpText } from "../../IconHelpText/IconHelpText";
import { ComponentTabs } from "../ComponentTabs/ComponentTabs";
import { ViewMode } from "../StructureView";

interface ComponentsPortletProps {
  initialViewMode?: ViewMode;
  projectId: string;
  siteId: number;
  variantId: number;
  title?: string;
  childProps?; // todo ts: set when DynamicForm is converted to typescript
  graphCoordinates: { x: number; y: number };
  product: Product;
  setShowNotAccessibleModal: (show: boolean) => void;
}

function ComponentsPortlet({
  initialViewMode,
  projectId,
  siteId,
  variantId,
  title,
  childProps,
  product,
  graphCoordinates,
  setShowNotAccessibleModal
}: ComponentsPortletProps) {
  const [viewMode, setViewMode] = useState(initialViewMode);
  const portletRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (viewMode === ViewMode.MissingDataForm && !!portletRef.current) {
      portletRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [viewMode]);

  const handleChangeViewMode = useCallback(
    (newViewMode) => setViewMode(newViewMode),
    []
  );

  const navItems: Array<React.ReactNode> = [];

  switch (viewMode) {
    case ViewMode.PvSimulationForm:
      navItems.push(
        <IconHelpText helpText="Sie haben die Möglichkeit basierend auf der bestehenden Variante die Installation einer neuen Photovoltaikanlage zu bewerten. In diesem Formular können Sie die Rahmenbedingungen der neuen Anlage festlegen. Zwischen den Grenzen für minimale und maximale Leistung werden automatisch fünf Varianten berechnet und miteinander verglichen." />
      );

      return (
        <Portlet navItems={navItems} title="Rahmenbedingungen neue PV-Anlage">
          <DefineSolarSimulationForm
            dataUrls={[urls.api.computationAvailable(variantId)]}
            loadingText={
              "Die aktuelle Variante wird geprüft und ggf. neu bewertet."
            }
            projectId={projectId}
            siteId={siteId}
          />
        </Portlet>
      );
    case ViewMode.MissingDataForm:
      navItems.push(
        <IconHelpText helpText="Es werden weitere Informationen benötigt damit die Optimierung möglichst genau berechnet werden kann." />
      );

      return (
        <div ref={portletRef}>
          <Portlet navItems={navItems} title={title}>
            <DynamicForm {...childProps} />
          </Portlet>
        </div>
      );
    default:
      return (
        <Portlet>
          <ComponentTabs
            graphCoordinates={graphCoordinates}
            product={product}
            setShowNotAccessibleModal={setShowNotAccessibleModal}
            siteId={siteId}
            variantId={variantId}
            onChangeViewMode={handleChangeViewMode}
          />
        </Portlet>
      );
  }
}

ComponentsPortlet.propTypes = {
  initialViewMode: PropTypes.string,
  projectId: PropTypes.string.isRequired,
  siteId: PropTypes.number.isRequired,
  variantId: PropTypes.number.isRequired,
  title: PropTypes.string,
  childProps: PropTypes.array, // todo ts: set when DynamicForm is converted to typescript
  product: PropTypes.string.isRequired
};

export { ComponentsPortlet };
