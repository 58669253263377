import * as XLSX from "xlsx";

export function buildExcelUri(workbook: XLSX.WorkBook) {
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
  });
  const url = URL.createObjectURL(data);
  return url;
}
