import { useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { showToast } from "../../../utils/toast";
import { Button } from "../../Buttons/Button/Button";
import loader from "../../Loader/Loader";
import { AutoImportNotificationMsg } from "../AutoImportNotificationMsg/AutoImportNotificationMsg";
import { updateMeterParametersCache } from "../hooks/useMeterParameters";
import { MeterConfiguration } from "../MeterConfiguration/MeterConfiguration";
import { MetersSaveWarningModal } from "../MeterConfiguration/MetersSaveWarningModal/MetersSaveWarningModal";
import { checkSubMeteringConnection } from "../utils/checkSubMeteringConnection";
import { showSaveMeterErrorToast } from "../utils/showSaveMeterErrorToast";
import { updateEntitiesMeterData } from "../utils/updateEntitiesMeterData";

function MeterSelection({
  sites,
  systemName,
  configurationId,
  buttonContainer,
  selectedMeters,
  isSelectedMetersWarningModalOpen,
  setIsSelectedMetersWarningModalOpen,
  setSelectedMeters,
  onNextStep,
  onToggle
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [connectionLoading, setConnectionLoading] = useState(false);

  async function handleSubmitSelection() {
    if (selectedMeters.length > 0) {
      const { success } = await handleSaveMeters();

      if (success) {
        return onNextStep();
      }

      return;
    }

    return onNextStep();
  }

  async function handleCheckSubMeteringConnection() {
    try {
      setConnectionLoading(true);
      const checkConnectionRes =
        await checkSubMeteringConnection(configurationId);

      if (checkConnectionRes.data?.error) {
        throw new Error(
          checkConnectionRes.data?.error ||
            t("errors.ThirdPartySystems.AuthenticationFailed")
        );
      }

      return true;
    } catch (error) {
      showToast(
        "error",
        error?.message || t("errors.ThirdPartySystems.AuthenticationFailed")
      );
    } finally {
      setConnectionLoading(false);
    }
  }

  const handleSaveMeters = useCallback(
    async (singleEntityId) => {
      try {
        if (singleEntityId) {
          const singleSelectedMeter = selectedMeters.find(
            (selectedMeter) =>
              selectedMeter.subMeteringSystemEntityId === singleEntityId
          );

          const meterId = singleSelectedMeter?.meterId ?? null;

          await updateEntitiesMeterData(configurationId, [
            {
              entityId: singleEntityId,
              meterId: meterId
            }
          ]);

          setSelectedMeters((prevSelectedMeters) => {
            return prevSelectedMeters.filter(
              (meter) => meter.subMeteringSystemEntityId !== singleEntityId
            );
          });

          updateMeterParametersCache(
            singleEntityId,
            { meter: meterId },
            queryClient,
            configurationId
          );

          return { success: true };
        }

        await updateEntitiesMeterData(
          configurationId,
          selectedMeters.map((selectedMeter) => ({
            entityId: selectedMeter.subMeteringSystemEntityId,
            meterId: selectedMeter.meterId
          }))
        );

        showToast(
          "success",
          <AutoImportNotificationMsg selectedMeters={selectedMeters} />,
          {
            autoClose: 10_000
          }
        );

        setSelectedMeters([]);

        return { success: true };
      } catch (err) {
        showSaveMeterErrorToast(err);
        return { success: false };
      }
    },
    [configurationId, queryClient, selectedMeters, setSelectedMeters]
  );

  function handleCloseSelectedMetersWarningModal() {
    setIsSelectedMetersWarningModalOpen(false);
  }

  return (
    <div className="MeterSelection">
      <MeterConfiguration
        configurationId={configurationId}
        connectionLoading={connectionLoading}
        selectedMeters={selectedMeters}
        setSelectedMeters={setSelectedMeters}
        sites={sites}
        systemName={systemName}
        onMetersSave={handleSaveMeters}
        onSubMeteringConnectionCheck={handleCheckSubMeteringConnection}
      />
      <MetersSaveWarningModal
        isOpen={isSelectedMetersWarningModalOpen}
        onCloseEditModal={onToggle}
        onCloseSelectedMetersWarningModal={
          handleCloseSelectedMetersWarningModal
        }
      />
      {buttonContainer && (
        <React.Fragment>
          {ReactDOM.createPortal(
            <Button color="primary" onClick={handleSubmitSelection}>
              Weiter
            </Button>,
            buttonContainer
          )}
        </React.Fragment>
      )}
    </div>
  );
}

const MeterSelectionWithLoader = loader(MeterSelection);

export { MeterSelectionWithLoader as MeterSelection };
