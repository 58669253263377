import type { Component, Site } from "../../../utils/backend-types";
import { MspCategory } from "../../../utils/enums";
import type {
  FilterDropdownFilter,
  FilterDropdownFilters
} from "../../FilterDropdown/FilterDropdown.types";
import type { RadioChoice } from "../../FilterDropdown/FilterItems/RadioFilterItem";
import { getMspCategoryLabel } from "../utils/getMspCategoryLabel";

export type LocationOverviewStatus = "active" | "inactive" | undefined;

export interface LocationOverviewDataQueryParams {
  limit: number;
  offset: number;
  status: LocationOverviewStatus;
  metering_service_provider_categories?: Array<MspCategory>;
  min_missing_consumption_year_percentage?: number;
  min_missing_consumption_month_percentage?: number;
  min_missing_generation_year_percentage?: number;
  min_missing_generation_month_percentage?: number;
  search_segment?: string;
}

export interface LocationOverviewDataResponse {
  results: Array<LocationOverviewData>;
  count: number; // page size
  totalNumber: number;
}

export interface LocationOverviewData {
  number: string | number;
  singleOverviews: Array<MarketOrMeteringLocationOverview>;
}

export interface MarketOrMeteringLocationOverview {
  assessment: {
    positive: MeteringDataAvailability;
    negative: MeteringDataAvailability;
  } | null;
  todo: number | null;
  location: MarketOrMeteringLocation;
}

interface MarketOrMeteringLocation extends Component {
  meteringServiceProvider: number;
  meteringServiceProviderCategory: MspCategory;
  meteringServiceProviderName: string;
  number: string;
  project: string;
  site: Pick<Site, "id" | "name">;
  status: "aktiv" | "inaktiv";
  type: LocationType;
  updated: string;
}

export interface LocationType {
  isMarketLocation: boolean;
  isMeteringLocation: boolean;
}

interface MeteringDataAvailability {
  currentYear: number | null;
  currentMonth: number | null;
}

export enum LocationOverviewFilterType {
  Status = "status",
  MinMissingConsumptionYearPercentage = "minMissingConsumptionYearPercentage",
  MinMissingConsumptionMonthPercentage = "minMissingConsumptionMonthPercentage",
  MinMissingGenerationYearPercentage = "minMissingGenerationYearPercentage",
  MinMissingGenerationMonthPercentage = "minMissingGenerationMonthPercentage"
}

const LOCATION_OVERVIEW_STATUS_CHOICES: Array<RadioChoice> = [
  { label: "Egal", value: "egal" },
  { label: "Aktiv", value: "active" },
  { label: "Inaktiv", value: "inactive" }
];

const numberOptions = {
  min: 0,
  max: 100,
  inputGroupText: "%"
};
const LOCATION_OVERVIEW_DATA_FILTERS: Array<FilterDropdownFilter> = [
  {
    key: LocationOverviewFilterType.MinMissingConsumptionYearPercentage,
    label: "Mehr als X% Datenlücken aktuelles Jahr (Verbrauch)",
    type: "number",
    ...numberOptions
  },
  {
    key: LocationOverviewFilterType.MinMissingConsumptionMonthPercentage,
    label: "Mehr als X% Datenlücken aktueller Monat (Verbrauch)",
    type: "number",
    ...numberOptions
  },
  {
    key: LocationOverviewFilterType.MinMissingGenerationYearPercentage,
    label: "Mehr als X% Datenlücken aktuelles Jahr (Erzeugung)",
    type: "number",
    ...numberOptions
  },
  {
    key: LocationOverviewFilterType.MinMissingGenerationMonthPercentage,
    label: "Mehr als X% Datenlücken aktueller Monat (Erzeugung)",
    type: "number",
    ...numberOptions
  }
];

export const LOCATION_OVERVIEW_FILTERS: FilterDropdownFilters = [
  {
    key: "status",
    header: "Status",
    filters: [
      {
        choices: LOCATION_OVERVIEW_STATUS_CHOICES,
        key: LocationOverviewFilterType.Status,
        radioName: "location-overview-status-filter",
        type: "radio"
      }
    ]
  },
  {
    key: "msb",
    header: "MSB Kategorie",
    filters: Object.values(MspCategory).map(buildMspCategoryFilter)
  },
  {
    key: "data",
    header: "Daten",
    filters: LOCATION_OVERVIEW_DATA_FILTERS
  }
];

function buildMspCategoryFilter(category: MspCategory): FilterDropdownFilter {
  return {
    label: getMspCategoryLabel(category),
    key: category,
    type: "boolean"
  };
}
