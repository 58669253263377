import type { FieldPath, FieldValues, UseFormWatch } from "react-hook-form";
import type { FormInputData } from "../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { validateWatchStrings } from "../../BuildingBlocks/Forms/utils/validateWatchStrings";
import type { WizardModeProps } from "../VariantObjectWizard";

type WizardEditProps<T> = Omit<WizardModeProps<T> & { mode: "edit" }, "mode">;

export function applyHighlightingToObjectFormFieldData<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>(
  initialFormFieldData: {
    [key in TName]: FormInputData<TFieldValues, TName>;
  },
  watch: UseFormWatch<TFieldValues>,
  wizardEditProps?: WizardEditProps<TFieldValues>
) {
  function collectValuesForFieldsThatCouldBeMissing() {
    if (
      !wizardEditProps?.wizardObject ||
      !wizardEditProps?.fieldsThatCouldBeMissing
    ) {
      return {};
    }

    return watch(
      validateWatchStrings(
        wizardEditProps.fieldsThatCouldBeMissing,
        wizardEditProps.wizardObject
      )
    ).reduce<Record<string, unknown>>((values, value, currentIndex) => {
      const key = wizardEditProps.fieldsThatCouldBeMissing[currentIndex];

      if (!key) {
        return values;
      }

      return {
        ...values,
        [key]: value
      };
    }, {});
  }

  function getFormFieldDataWithHighlighting(
    valuesForFieldsThatCouldBeMissing: Record<string, unknown> = {}
  ) {
    return (Object.keys(initialFormFieldData) as Array<TName>).reduce<{
      [key in TName]: FormInputData<TFieldValues, TName>;
    }>((formFieldData, key) => {
      const field = initialFormFieldData[key];
      const shouldHighlight =
        typeof wizardEditProps?.shouldFieldBeHighlighted === "function"
          ? wizardEditProps.shouldFieldBeHighlighted(
              key,
              valuesForFieldsThatCouldBeMissing
            )
          : false;

      return {
        ...formFieldData,
        [key]: {
          ...field,
          highlight: shouldHighlight
        }
      };
    }, initialFormFieldData);
  }

  const valuesForFieldsThatCouldBeMissing =
    collectValuesForFieldsThatCouldBeMissing();
  const formFieldData = getFormFieldDataWithHighlighting(
    valuesForFieldsThatCouldBeMissing
  );
  const numHighlightedFields = Object.values(formFieldData).filter(
    (field: FormInputData<TFieldValues, TName>) => field?.highlight
  ).length;

  return { formFieldData, numHighlightedFields };
}
