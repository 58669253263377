import type { Generator } from "../../../../../../utils/backend-types";

export function areGeneratorsValid(
  generators: Array<Generator> | undefined,
  generatorsValue: Array<number> | undefined
) {
  const generatorIds = generators?.map((generator) => generator.id) ?? [];
  let hasInvalidValues = false;
  if (generatorsValue && generatorsValue.length > 0) {
    generatorsValue.forEach((generatorId) => {
      if (!generatorIds.includes(generatorId)) {
        hasInvalidValues = true;
      }
    });
  }
  return hasInvalidValues;
}
