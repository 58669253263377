import { Flex, Stack } from "@mantine/core";
import React from "react";
import { Link } from "react-router-dom";
import urls, { OptiSupportEndpoints } from "../../../../urls";
import { THEME_VARS } from "../../../../utils/constants";
import { Alert, AlertColor } from "../../../Alert/Alert";
import { AnchorLink } from "../../../AnchorLink/AnchorLink";
import { Icon } from "../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import { Button } from "../../../Buttons/Button/Button";
import type { Paragraph6CreditError } from "../../Paragraph6.types";
import "./Paragraph6CreditErrorMessage.scss";

interface Paragraph6CreditErrorMessageProps {
  paragraph6CreditErrors: Array<Paragraph6CreditError>;
  contractNames?: Array<{ id: string; name: string }>;
}

function Paragraph6CreditErrorMessage({
  paragraph6CreditErrors,
  contractNames
}: Paragraph6CreditErrorMessageProps) {
  const masterErrors = paragraph6CreditErrors.filter(
    (error) => error.master_data_check_failed
  );
  const energyErrors = paragraph6CreditErrors.filter(
    (error) =>
      error.energy_data_check_failed ||
      error.billing_period.fiktive_strommengen_are_imprecise
  );
  const exactlyOneOperatorErrors = paragraph6CreditErrors.filter(
    (error) => error.exactly_one_grid_operator_check_failed
  );

  const masterErrorContracts = [
    ...new Set(masterErrors.map((error) => error.billing_period.contract))
  ];

  const masterErrorContractNames = masterErrorContracts.map(
    (masterErrorContract) => {
      return contractNames?.find(
        (contract) => contract.id === masterErrorContract
      )?.name;
    }
  );
  const energyErrorContractNamesAndDates = energyErrors.map((energyError) => {
    const contract = contractNames?.find(
      (contract) => contract.id === energyError.billing_period.contract
    );
    return {
      name: contract?.name,
      id: contract?.id,
      startDate: energyError.billing_period.start_date,
      endDate: energyError.billing_period.end_date
    };
  });
  const energyDataCheckFailed =
    energyErrors.filter((e) => e.energy_data_check_failed).length > 0;
  const fiktiveStrommengenAreImprecise =
    energyErrors.filter(
      (e: Paragraph6CreditError): boolean =>
        e.billing_period.fiktive_strommengen_are_imprecise
    ).length > 0;

  const exactlyOneOperatorContracts = [
    ...new Set(
      exactlyOneOperatorErrors.map((error) => error.billing_period.contract)
    )
  ];
  const exactlyOneOperatorContractNames = exactlyOneOperatorContracts.map(
    (exactlyOneOperatorContract) => {
      return contractNames?.find(
        (contract) => contract.id === exactlyOneOperatorContract
      )?.name;
    }
  );

  return (
    <div className="Paragraph6CreditErrorMessage">
      <Alert color={AlertColor.Warning}>
        <div className="credit-error-header-container">
          <Icon
            className="credit-error-icon"
            name={IconName.Warning}
            style={{ color: THEME_VARS.warningColor }}
          />
          <h5 className="credit-error-header">
            Mindestens eine Gutschrift kann nicht erstellt werden
          </h5>
        </div>
        {masterErrorContractNames && masterErrorContractNames.length > 0 && (
          <div className="credit-error-info-container">
            <p>
              Für{" "}
              {masterErrorContractNames.length === 1
                ? "den Vertrag"
                : "die Verträge"}
            </p>
            <ul>
              {masterErrorContractNames?.map((contractName) => (
                <li key={contractName}>{contractName}</li>
              ))}
            </ul>
            <p className="credit-error-description-line">
              können keine Gutschriften erstellt werden. Bitte überprüfen Sie
              die{" "}
              <Link to="../../unternehmen">notwendigen Unternehmensdaten</Link>{" "}
              auf Vollständigkeit und Plausibilität.
            </p>
            <p className="credit-error-description-line">
              Weitere Informationen finden Sie in der{" "}
              <AnchorLink
                href={urls.optiSupport(
                  OptiSupportEndpoints.GutschriftErstellenParagraph6
                )}
              >
                opti.node-Hilfe
              </AnchorLink>
            </p>
          </div>
        )}
        {energyErrorContractNamesAndDates &&
          energyErrorContractNamesAndDates.length > 0 && (
            <div className="credit-error-info-container">
              <p>
                Für{" "}
                {energyErrorContractNamesAndDates.length === 1
                  ? "den Vertrag"
                  : "die Verträge"}
              </p>
              <ul>
                {energyErrorContractNamesAndDates?.map((energyContract) => (
                  <li
                    key={
                      energyContract.name +
                      energyContract.startDate +
                      energyContract.endDate
                    }
                  >
                    <Link to={"../vertraege/" + energyContract.id}>
                      {energyContract.name}
                    </Link>{" "}
                    im Zeitraum vom <strong>{energyContract.startDate}</strong>{" "}
                    bis <strong>{energyContract.endDate}</strong>
                  </li>
                ))}
              </ul>
              {energyDataCheckFailed && (
                <Stack>
                  <p className="credit-error-description-line">
                    können keine Gutschriften erstellt werden. Bitte überprüfen
                    Sie die <strong>Energiedaten</strong> für die genannten
                    Zeiträume.
                    <br />
                    Bei Fragen kontaktieren Sie bitte den{" "}
                    <AnchorLink href={urls.optiSupport()}>
                      Support
                    </AnchorLink>, wir kümmern uns darum.
                  </p>
                  <Flex justify="flex-end">
                    <Button
                      className="pull-right"
                      color="warning"
                      tag={Link}
                      to="../../energiedaten/bereitstellen"
                    >
                      Energiedaten bereitstellen
                    </Button>
                  </Flex>
                </Stack>
              )}
              {!energyDataCheckFailed && fiktiveStrommengenAreImprecise && (
                <p className="credit-error-description-line">
                  Sie haben Ihre <b>fiktiven Strommengen</b> in einem Zeitraum
                  hinterlegt, der über den Abrechnungszeitraum dieser Gutschrift
                  hinaus geht. Wir können daher nur eine Gleichverteilung
                  vornehmen.
                </p>
              )}
            </div>
          )}
        {exactlyOneOperatorErrors &&
          exactlyOneOperatorContractNames.length > 0 && (
            <div className="credit-error-info-container">
              <p>
                Für{" "}
                {exactlyOneOperatorContractNames.length === 1
                  ? "den Vertrag"
                  : "die Verträge"}
              </p>
              <ul>
                {exactlyOneOperatorContractNames?.map((contractName) => (
                  <li key={contractName}>{contractName}</li>
                ))}
              </ul>
              <p className="credit-error-description-line">
                können keine Gutschriften erstellt werden. Bitte stellen Sie
                sicher, dass Ihr Vertrag mindestens einen Erzeuger enthält und
                alle Erzeuger denselben Anschlussnetzbetreiber haben.
              </p>
            </div>
          )}
      </Alert>
    </div>
  );
}

export { Paragraph6CreditErrorMessage };
