import {
  DirektvermarktungContractContract_remuneration_type,
  DirektvermarktungContractFunding_marketing_form
} from "../../../../types/api.types";
import type { FormFieldData } from "../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import type { Choice } from "../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import type { DirektvermarktungContract } from "../../Direktvermarktung.types";

export const DIREKTVERMARKTUNG_MARKETING_FORM_CHOICES = [
  {
    value:
      DirektvermarktungContractFunding_marketing_form.subsidized_direct_marketing,
    displayName: "Geförderte Direktvermarktung"
  },
  {
    value:
      DirektvermarktungContractFunding_marketing_form.other_direct_marketing,
    displayName: "Sonstige Direktvermarktung"
  }
] as const satisfies Array<Choice>;

export const DIREKTVERMARKTUNG_CONTRACT_CHOICES = [
  {
    value:
      DirektvermarktungContractContract_remuneration_type.monthly_market_value_contract,
    displayName: "Monatsmarktwert-Vertrag"
  },
  {
    value:
      DirektvermarktungContractContract_remuneration_type.yearly_market_value_contract,
    displayName: "Jahresmarktwert-Vertrag"
  },
  {
    value:
      DirektvermarktungContractContract_remuneration_type.spot_price_contract,
    displayName: "Spotpreis-Vertrag"
  },
  {
    value: DirektvermarktungContractContract_remuneration_type.ppa_contract,
    displayName: "PPA (mit Fixpreiskomponente)"
  }
] as const satisfies Array<Choice>;

export const DIREKTVERMARKTUNG_FORM_INPUT_DATA = {
  name: {
    name: "name",
    required: true,
    label: "Vertragsname",
    type: "text"
  },
  operator_id: {
    name: "operator_id",
    required: true,
    label: "Betreibergesellschaft",
    type: "dropdown",
    choices: [] // will be filled dynamically during runtime
  },
  direktvermarkter_id: {
    name: "direktvermarkter_id",
    required: true,
    label: "Direktvermarkter",
    type: "dropdown",
    choices: [] // will be filled dynamically during runtime
  },
  funding_marketing_form: {
    name: "funding_marketing_form",
    required: true,
    label: "Vermarktungsform",
    type: "dropdown",
    choices: DIREKTVERMARKTUNG_MARKETING_FORM_CHOICES
  },
  contract_remuneration_type: {
    name: "contract_remuneration_type",
    required: true,
    label: "Vertragsart",
    type: "dropdown",
    choices: DIREKTVERMARKTUNG_CONTRACT_CHOICES
  },
  first_date: {
    name: "first_date",
    required: true,
    type: "text"
  },
  last_date: {
    name: "last_date",
    required: true,
    type: "text"
  },
  formula_entgelt: {
    name: "formula_entgelt",
    label: "Direktvermarktungsentgelt in Euro",
    type: "expression",
    variables: [], // will be filled dynamically during runtime
    operators: [
      "+",
      "-",
      "*",
      "/",
      "Gt( , )",
      "Lt( , )",
      "Ge( , )",
      "Le( , )",
      "Eq( , )",
      "Ne( , )"
    ],
    otherElements: ["(", ")", "Wenn( , , )", "Min( , )", "Max( , )"]
  }
} as const satisfies FormFieldData<DirektvermarktungContract>;
