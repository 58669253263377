import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import type { Column } from "react-table";
import { Card, CardBody, Collapse } from "reactstrap";

import api from "../../../../../api";
import { usePersons } from "../../../../../hooks/usePersons";
import urls from "../../../../../urls";
import type { Person } from "../../../../../utils/backend-types";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import { sortBackendDates } from "../../../../../utils/dates/sortBackendDates";
import { showToast } from "../../../../../utils/toast";
import { Icon } from "../../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../../BuildingBlocks/Icon/types";
import { Button } from "../../../../Buttons/Button/Button";
import type { ChangeableFieldData } from "../../../../ChangeableFieldWidget/ChangeableFieldWidget";
import { ChangeableFieldWidget } from "../../../../ChangeableFieldWidget/ChangeableFieldWidget";
import { PersonCell } from "../../../../CustomReactTable/Cells/PersonCell";
import { CustomReactTable } from "../../../../CustomReactTable/CustomReactTable";
import { openErrorAlertPopup } from "../../../../ErrorAlertPopup/openErrorAlertPopup";
import { LoadOrError } from "../../../../LoadOrError/LoadOrError";
import { FormItemLabel } from "../../../FormItem/FormItem";
import type { FormFieldData, FormItemsProps } from "../../FormItems";
import { CompanyCell } from "./CompanyCell";

import "./ChangeableOperatorField.scss";

const CHANGEABLE_FIELD_WIDGET_OPTIONS = {
  addButtonText: "neuen Betreiber eintragen",
  startDateHelpText:
    "Bitte erfassen Sie melderelevante Betreiberwechsel nach dem 01.01.2020 hier.",
  endDatePlaceholder: "Aktuell",
  deleteTooltipText: "Betreiberwechsel löschen"
};

export interface ChangeableOperator {
  firstDate: string | null;
  lastDate: string | null;
  company: number;
}

interface ExtraProps {
  variantId: number;
  componentId: number;
  isCombined?: boolean;
  confirmationCheckUrl?: string;
}

interface ChangeableOperatorProps
  extends Omit<FormItemsProps, "formItems">,
    ExtraProps {
  fieldNames: Array<string>;
  formItems: Record<string, FormFieldData>;
}

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function ChangeableOperatorField({
  fieldNames,
  formItems,
  formValues,
  formName,
  allowInput, // onInput
  variantId,
  componentId,
  isCombined: isCombinedGenerator,
  confirmationCheckUrl,
  onInput
}: ChangeableOperatorProps) {
  const [isHistoryCollapseOpen, setIsHistoryCollapseOpen] = useState(false);
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const queryClient = useQueryClient();
  const {
    isLoading: isPersonsQueryLoading,
    error: personsQueryError,
    persons
  } = usePersons(variantId);
  const {
    isLoading: isOperatorsQueryLoading,
    error: operatorsQueryError,
    data: operators
  } = useQuery({
    queryKey: ["operators", { componentId }],
    queryFn: () => fetchOperators(componentId),
    refetchInterval: false,
    refetchOnWindowFocus: false
  });
  const { data: confirmationCheckText } = useQuery({
    queryKey: [
      "changeable-operator-confirmation-check",
      { componentId, confirmationCheckUrl }
    ],
    queryFn: () => fetchConfirmationCheckText(confirmationCheckUrl),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: !!confirmationCheckUrl
  });

  const OPERATOR_FIELD_NAME = fieldNames[0];
  const formItem = formItems[OPERATOR_FIELD_NAME];
  const formValue = formValues[OPERATOR_FIELD_NAME];
  const formItemId = `id_${
    formName ? formName : "form"
  }_${formItem.name.replace(/\./g, "__")}`;
  const company = persons?.find((person) => person.id === formValue);
  const companyName = company?.name ?? "";

  async function fetchOperators(componentId: number) {
    const response = await api.get<Array<ChangeableOperator>>(
      urls.api.operationPeriods(componentId)
    );
    return response.data;
  }

  async function fetchConfirmationCheckText(confirmationCheckUrl?: string) {
    if (!confirmationCheckUrl) {
      return "";
    }

    const response = await api.get<{ message: string } | undefined>(
      confirmationCheckUrl
    );

    return response.data?.message || "";
  }

  function handleClickHistoryButton() {
    setIsCollapseOpen(false);
    setIsHistoryCollapseOpen((open) => !open);
  }

  function handleClickEditButton() {
    setIsHistoryCollapseOpen(false);
    setIsCollapseOpen((open) => !open);
  }

  function handleSaveOperators(
    changeableFieldData: Array<ChangeableFieldData>
  ) {
    const operators =
      convertChangeableFieldDataToOperators(changeableFieldData);
    if (confirmationCheckText) {
      for (const text of confirmationCheckText) {
        showToast("info", text);
      }
    }
    return api
      .post(urls.api.operationPeriods(componentId), operators)
      .then(() => {
        const lastOperator = operators[operators.length - 1];

        onInput(OPERATOR_FIELD_NAME, lastOperator.company);
        setIsCollapseOpen(false);
        queryClient.invalidateQueries({
          queryKey: [
            "operators",
            {
              componentId
            }
          ]
        });

        return Promise.resolve();
      })
      .catch((error) => {
        openErrorAlertPopup(error);
        return Promise.resolve();
      });
  }

  function getConfirmationOnSaveElement() {
    if (isCombinedGenerator) {
      return (
        <>
          <p>
            Dieser Erzeuger ist Teil einer stromsteuerlichen Anlage, die weitere
            Erzeuger umfasst. Wenn Sie den Betreiberwechsel bestätigen, wird
            dieser für alle Erzeuger, die Teil der stromsteuerlichen Anlage
            sind, durchgeführt. Soll der Betreiberwechsel nicht für alle
            Erzeuger der stromsteuerlichen Anlage durchgeführt werden, brechen
            Sie den Vorgang bitte zunächst ab und lösen Sie die/den Erzeuger,
            für den/die der Wechsel durchgeführt werden soll, aus der
            stromsteuerlichen Anlage heraus und führen Sie den Betreiberwechsel
            danach für die betreffenden Erzeuger durch.
          </p>
          <p>
            Soll der Betreiberwechsel trotzdem für alle Erzeuger der
            stromsteuerlichen Anlage durchgeführt werden?
          </p>
        </>
      );
    }

    return null;
  }

  function convertOperatorsToChangeableFieldData(
    operators?: Array<ChangeableOperator>
  ) {
    if (!operators) {
      return undefined;
    }

    return operators
      .map<ChangeableFieldData>((operator) => ({
        startDate: operator.firstDate
          ? backendDateOrDateTimeToLuxonDateTime(operator.firstDate)
          : null,
        endDate: operator.lastDate
          ? backendDateOrDateTimeToLuxonDateTime(operator.lastDate)
          : null,
        value: operator.company
      }))
      .sort((a, b) => {
        if (a.startDate === null && b.startDate === null) {
          return 0;
        } else if (a.startDate === null) {
          return -1;
        } else if (b.startDate === null) {
          return 1;
        } else if (a.startDate < b.startDate) {
          return -1;
        } else if (b.startDate < a.startDate) {
          return 1;
        }

        return 0;
      });
  }

  function convertChangeableFieldDataToOperators(
    values: Array<ChangeableFieldData>
  ) {
    return values.map<ChangeableOperator>((value) => ({
      company: value.value as number,
      firstDate: value.startDate
        ? luxonDateTimeToBackendDateOrDateTime(value.startDate)
        : null,
      lastDate: value.endDate
        ? luxonDateTimeToBackendDateOrDateTime(value.endDate)
        : null
    }));
  }

  const confirmationOnSaveElement = getConfirmationOnSaveElement();
  const suggestedChangeableOperators =
    convertOperatorsToChangeableFieldData(operators);

  return (
    <div className="ChangeableOperatorField FormField">
      <div>
        <div className="toggler-label">
          <FormItemLabel
            helpText={formItem.helpText}
            id={formItemId}
            instance={formItem.instance}
            label={formItem.label}
            required={formItem.required}
          />
          <div className="label-and-button">
            <span
              className="label-container"
              onClick={handleClickHistoryButton}
            >
              <Icon
                className="toggle-icon"
                name={
                  isHistoryCollapseOpen
                    ? IconName.AngleDown
                    : IconName.AngleRight
                }
              />{" "}
              {companyName}
            </span>
            <Button
              className="label-button"
              color="brand"
              disabled={!allowInput || isCollapseOpen}
              onClick={handleClickEditButton}
            >
              Betreiberwechsel bearbeiten
            </Button>
          </div>
        </div>
      </div>
      <Collapse isOpen={isHistoryCollapseOpen}>
        {operators && persons ? (
          <OperatorHistory companies={persons} operators={operators} />
        ) : (
          <Card className="loading-card">
            <CardBody>
              <LoadOrError
                error={personsQueryError || operatorsQueryError}
                loading={isPersonsQueryLoading || isOperatorsQueryLoading}
              />
            </CardBody>
          </Card>
        )}
      </Collapse>
      <Collapse isOpen={isCollapseOpen}>
        <Card className="operators-edit">
          <CardBody>
            <LoadOrError
              error={personsQueryError || operatorsQueryError}
              loading={isPersonsQueryLoading || isOperatorsQueryLoading}
            >
              {suggestedChangeableOperators && persons && (
                <ChangeableFieldWidget
                  confirmationOnSaveCancellable={!confirmationCheckText}
                  confirmationOnSaveElement={confirmationOnSaveElement}
                  dataName="Betreiberwechseln"
                  initialValues={suggestedChangeableOperators}
                  options={CHANGEABLE_FIELD_WIDGET_OPTIONS}
                  valueColumns={[
                    {
                      header: "Unternehmen",
                      accessor: "value",
                      Cell: (value, onChange) => (
                        <CompanyCell
                          companies={persons}
                          companyId={value}
                          value={value}
                          variantId={variantId}
                          onChange={onChange}
                        />
                      )
                    }
                  ]}
                  onCancel={() => setIsCollapseOpen(false)}
                  onDone={handleSaveOperators}
                />
              )}
            </LoadOrError>
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
}

interface OperatorHistoryProps {
  operators: Array<ChangeableOperator>;
  companies: Array<Person>;
}

function OperatorHistory({ operators, companies }: OperatorHistoryProps) {
  const tableColumns: Array<Column<ChangeableOperator>> = [
    {
      Header: "Von",
      accessor: "firstDate",
      width: 85,
      sortMethod: sortBackendDates
    },
    {
      Header: "Bis",
      accessor: "lastDate",
      width: 85,
      Cell: (cellData) => <span>{cellData.value ?? "Aktuell"}</span>,
      sortMethod: sortBackendDates
    },
    {
      Header: "Unternehmen",
      accessor: "company",
      Cell: (cellData) => (
        <PersonCell personId={cellData.original.company} persons={companies} />
      )
    }
  ];

  const defaultSorted = [
    {
      id: "firstDate",
      desc: false
    }
  ];

  return (
    <div className="operator-history" style={{ marginTop: "0.5rem" }}>
      <CustomReactTable
        columns={tableColumns}
        data={operators}
        defaultSorted={defaultSorted}
        minRows={0}
        pageSize={operators.length}
      />
    </div>
  );
}

export { ChangeableOperatorField };
