import PropTypes from "prop-types";
import React from "react";
import type { Filter } from "react-table";
import { DropdownFilter } from "../../../CustomReactTable/Filters/DropdownFilter/DropdownFilter";

import { StatusSymbolColour } from "../../../Icons/StatusSymbol";
import type { SiteStatusLogs, SiteStatusSite } from "../../site-status-types";

enum LogFilterLabel {
  Info = "Infos",
  Warnings = "Warnungen",
  Errors = "Blocker",
  None = "Keine"
}

interface LogsCellProps {
  logs: SiteStatusLogs;
  onClick: (logs: SiteStatusLogs) => void;
}

function LogsCell({ logs, onClick }: LogsCellProps) {
  const numInfo = logs.infos.length;
  const numWarnings = logs.warnings.length;
  const numErrors = logs.errors.length;
  const warningStyle =
    numWarnings > 0
      ? { color: StatusSymbolColour.Warning, fontWeight: "bold" }
      : {};
  const errorStyle =
    numErrors > 0 ? { color: StatusSymbolColour.Red, fontWeight: "bold" } : {};
  const clickable = numInfo > 0 || numWarnings > 0 || numErrors > 0;
  const handleClick = () => {
    if (clickable) {
      onClick(logs);
    }
  };

  return (
    <div style={clickable ? { cursor: "pointer" } : {}} onClick={handleClick}>
      <span>{numInfo} Infos</span>
      <br />
      <span style={warningStyle}>{numWarnings} Warnungen</span>
      <br />
      <span style={errorStyle}>{numErrors} Blocker</span>
      <br />
    </div>
  );
}

function sortLogs(a: SiteStatusLogs, b: SiteStatusLogs) {
  const aNumInfo = a.infos.length;
  const aNumWarnings = a.warnings.length;
  const aNumErrors = a.errors.length;
  const bNumInfo = b.infos.length;
  const bNumWarnings = b.warnings.length;
  const bNumErrors = b.errors.length;

  if (aNumErrors < bNumErrors) {
    return -1;
  } else if (aNumErrors > bNumErrors) {
    return 1;
  } else if (aNumWarnings < bNumWarnings) {
    return -1;
  } else if (aNumWarnings > bNumWarnings) {
    return 1;
  } else if (aNumInfo < bNumInfo) {
    return -1;
  } else if (aNumInfo > bNumInfo) {
    return 1;
  }

  return 0;
}

interface LogsFilterProps {
  filter: Filter;
  onChange: (selectedKeys: Record<string, boolean>) => void;
}

function LogsFilter({ filter, onChange }: LogsFilterProps) {
  const choices = Object.keys(LogFilterLabel).reduce<
    Record<string, LogFilterLabel>
  >((choices, key) => {
    const value = LogFilterLabel[key];
    choices[value] = value;
    return choices;
  }, {});

  return (
    <DropdownFilter
      choices={choices}
      filter={filter}
      showNoneOption
      onChange={onChange}
    />
  );
}

function onFilterLogs(filter: Filter, row: SiteStatusSite) {
  const options = filter.value;
  const logs = row.logs;
  const numInfo = logs.infos.length;
  const numWarnings = logs.warnings.length;
  const numErrors = logs.errors.length;

  if (numInfo > 0 && options[LogFilterLabel.Info] === true) {
    return true;
  } else if (numWarnings > 0 && options[LogFilterLabel.Warnings] === true) {
    return true;
  } else if (numErrors > 0 && options[LogFilterLabel.Errors] === true) {
    return true;
  } else if (
    numInfo === 0 &&
    numWarnings === 0 &&
    numErrors === 0 &&
    options[LogFilterLabel.None]
  ) {
    return true;
  }

  return false;
}

LogsCell.propTypes = {
  logs: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

export { LogsCell, sortLogs, LogsFilter, onFilterLogs };
