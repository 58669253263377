import { useQuery } from "@tanstack/react-query";
import api from "../api";
import urls from "../urls";
import type { FeatureFlags } from "./useFeatureFlags.types";
import { Flag } from "./useFeatureFlags.types";

/** Retrieves feature flags from Django Waffle API. Plain text flag keys are automatically camelized by api.ts. */
export function useFeatureFlags() {
  const { data, isLoading, error } = useQuery({
    queryKey: ["featureFlags"],
    queryFn: () => fetchFeatureFlags(),
    initialData: getInitialFalseFlags(),
    refetchOnWindowFocus: true
  });

  return { featureFlags: data, isLoading, error };
}

async function fetchFeatureFlags() {
  const response = await api.get<FeatureFlags>(urls.api.featureFlags());
  return response.data;
}

function getInitialFalseFlags() {
  return Object.fromEntries(
    Object.values(Flag).map((flagValue) => [flagValue, false])
  ) as FeatureFlags;
}
