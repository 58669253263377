import { useQuery } from "@tanstack/react-query";
import api from "../../../../../../../api";
import urls from "../../../../../../../urls";
import { queryWithPollResponse } from "../../../../../../../utils/api-utils";
import type { BatchImportError } from "../types";

function useValidateBatchTemplate(template: File | undefined) {
  const { data, isLoading, error } = useQuery({
    queryKey: [
      "mieterstrom-batch-import-validation",
      { template_name: template?.name, template_size: template?.size }
    ],
    queryFn: () => fetchValidationResult(template),
    enabled: !!template,
    refetchInterval: false
  });

  async function fetchValidationResult(template: File | undefined) {
    if (!template) {
      return [];
    }

    const formData = new FormData();
    formData["formDataObject"] = { excel: template };

    const response = await queryWithPollResponse<Array<BatchImportError>>(
      () => api.post(urls.api.mieterstromBatchImport.dispatchCheck(), formData),
      {
        queryAgainOnPollSuccess: true,
        queryAgainOnPollSuccessWithTaskId: true,
        queryAgainFn: (taskId: string) =>
          api.get(urls.api.mieterstromBatchImport.fetchCheck(taskId))
      }
    );

    return response.data;
  }

  return { data, isLoading, error };
}

export { useValidateBatchTemplate };
