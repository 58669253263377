import type { DateTime } from "luxon";

import type { Todo } from "../../../utils/backend-types";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";

export function parseTodos(todos: Array<Todo>) {
  const persons: Array<string> = [];
  const responsiblePersons: Array<number> = [];
  let firstDueDate: DateTime | null = null;
  let lastDueDate: DateTime | null = null;

  for (let i = 0; i < todos.length; i++) {
    const todo = todos[i];
    const dueDateString = todos[i].dueDate;
    const dueDate =
      dueDateString !== null
        ? backendDateOrDateTimeToLuxonDateTime(dueDateString)
        : null;

    if (!!todo.person && !persons.includes(todo.person)) {
      persons.push(todo.person);
    }

    if (!!todo.responsible && !responsiblePersons.includes(todo.responsible)) {
      responsiblePersons.push(todo.responsible);
    }

    // check if this todo's due date is before the earliest-found due date
    if (dueDate?.isValid && (!firstDueDate || dueDate < firstDueDate)) {
      firstDueDate = dueDate;
    }

    // check if this todo's due date is before the last-found due date
    if (dueDate?.isValid && (!lastDueDate || dueDate > lastDueDate)) {
      lastDueDate = dueDate;
    }
  }

  // sort them
  persons.sort();
  responsiblePersons.sort();

  return {
    persons,
    responsiblePersons,
    firstDueDate,
    lastDueDate
  };
}
