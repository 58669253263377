import classNames from "classnames";
import React from "react";
import "./StartPageSection.scss";

interface SectionProps {
  title?: string;
  filter?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
}

function StartPageSection({
  title,
  filter,
  children,
  className
}: SectionProps) {
  return (
    <div className={classNames("section", className)}>
      <div className="section-header">
        {title && <h5>{title}</h5>}
        {filter}
      </div>
      {children}
    </div>
  );
}

export { StartPageSection };
