import { saveAs } from "file-saver";

import { useState } from "react";
import api from "../api";

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods
export const HTTP_METHODS = {
  GET: "get",
  HEAD: "head",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
  CONNECT: "connect",
  OPTIONS: "options",
  TRACE: "trace",
  PATCH: "patch"
} as const;

type HttpMethod = (typeof HTTP_METHODS)[keyof typeof HTTP_METHODS];

export type DownloadArgs = {
  downloadUrl: string;
  options?: DownloadOptions;
};

type DownloadOptions = {
  method?: HttpMethod;
  payload?: unknown;
};

/**
 * Reusable hook to fetch a file from a protected route.
 * This will use the api client to fetch the file with the JWT headers.
 *
 */
export const useDownload = () => {
  const [errorResponse, setErrorResponse] = useState<unknown>();
  const [isLoading, setIsLoading] = useState(false);

  const getFilenameFromContentDispositionHeader = (arg: string) =>
    arg?.substring(arg.indexOf('"') + 1, arg.lastIndexOf('"')) ?? "download";

  const download = async ({ downloadUrl, options }: DownloadArgs) => {
    const { method = HTTP_METHODS.GET, payload } = options ?? {};
    try {
      setIsLoading(true);

      let response;

      // this is a bit convoluted but until we refactor the arguments of the api client, it is what it is
      switch (method) {
        case HTTP_METHODS.GET:
          response = await api[method](downloadUrl, {
            responseType: "blob",
            transformResponse: []
          });
          break;

        case HTTP_METHODS.POST:
          response = await api[method](downloadUrl, payload, {
            responseType: "blob",
            transformResponse: []
          });
          break;

        case HTTP_METHODS.OPTIONS:
        case HTTP_METHODS.PUT:
        case HTTP_METHODS.PATCH:
        case HTTP_METHODS.DELETE:
        default:
          throw new Error(`Unsupported download: ${method}`);
      }

      const blob = new Blob([response.data]);
      const filename = getFilenameFromContentDispositionHeader(
        response.headers["content-disposition"]
      );

      saveAs(blob, filename);
    } catch (error) {
      setErrorResponse(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { download, error: errorResponse, isLoading };
};
