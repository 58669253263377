import type { ChangeEvent } from "react";
import React, { useState } from "react";
import ReactDOM from "react-dom";

import { Checkbox } from "../../../BuildingBlocks/Forms/Checkbox/Checkbox";
import type { ButtonProps } from "../../../Buttons/Button/Button";
import { Button } from "../../../Buttons/Button/Button";
import { SpinButton } from "../../../Buttons/SpinButton/SpinButton";
import { openErrorAlertPopup } from "../../../ErrorAlertPopup/openErrorAlertPopup";
import { uploadGeneratorBatchImportFile } from "./utils/uploadGeneratorBatchImportFile";

import "./GeneratorBatchImport.scss";

interface GeneratorBatchImportProps {
  buttonContainer?: HTMLElement;
  siteId: number;
  onSuccess: (taskId: string) => void;
}

function GeneratorBatchImport({
  buttonContainer,
  siteId,
  onSuccess
}: GeneratorBatchImportProps) {
  const [file, setFile] = useState<File | null>(null);
  const [cleanChecked, setCleanChecked] = useState(false);
  const [ignoreSameOtherSitesChecked, setIgnoreSameOtherSitesChecked] =
    useState(false);

  async function doUpload() {
    if (!file) {
      return Promise.resolve();
    }

    let taskId: string | undefined = undefined;

    try {
      taskId = await uploadGeneratorBatchImportFile(
        file,
        siteId,
        cleanChecked,
        ignoreSameOtherSitesChecked
      );
    } catch (error) {
      openErrorAlertPopup(error);
      setFile(null);
    }

    if (taskId) {
      onSuccess(taskId);
    }
  }

  return (
    <div className="GeneratorBatchImport">
      <div className="select-button-with-text">
        <SelectFileButton
          key={file?.name ?? "no-file"}
          onSelectFile={setFile}
        />
        {file?.name}
      </div>
      <ImportOptions
        cleanChecked={cleanChecked}
        disabled={!file}
        ignoreSameOtherSitesChecked={ignoreSameOtherSitesChecked}
        onChangeClean={() => setCleanChecked(!cleanChecked)}
        onChangeIgnoreSameOtherSites={() =>
          setIgnoreSameOtherSitesChecked(!ignoreSameOtherSitesChecked)
        }
      />
      <ImportButton
        buttonContainer={buttonContainer}
        disabled={!file}
        upload={doUpload}
      />
    </div>
  );
}

interface ImportOptionsProps {
  cleanChecked: boolean;
  disabled: boolean;
  ignoreSameOtherSitesChecked: boolean;
  onChangeClean: () => void;
  onChangeIgnoreSameOtherSites: () => void;
}

function ImportOptions({
  cleanChecked,
  disabled,
  ignoreSameOtherSitesChecked,
  onChangeClean,
  onChangeIgnoreSameOtherSites
}: ImportOptionsProps) {
  return (
    <div className="ImportOptions">
      <Checkbox
        checked={cleanChecked}
        disabled={disabled}
        onChange={onChangeClean}
      >
        Bestehende Anlagen löschen
      </Checkbox>
      <Checkbox
        checked={ignoreSameOtherSitesChecked}
        disabled={disabled}
        onChange={onChangeIgnoreSameOtherSites}
      >
        Prüfung auf Duplikate ignorieren
      </Checkbox>
    </div>
  );
}

interface SelectFileButtonProps {
  onSelectFile: (file: File) => void;
}

function SelectFileButton({ onSelectFile }: SelectFileButtonProps) {
  function handleClickUpload() {
    const input = document.getElementById("generator-batch-select-file-input");

    if (input) {
      input.click();
    }
  }

  function handleSelectFile(e: ChangeEvent<HTMLInputElement>) {
    const selectedFile = e.target.files?.[0];

    if (selectedFile) {
      onSelectFile(selectedFile);
    }
  }

  return (
    <Button
      className="SelectFileButton"
      color="brand"
      onClick={handleClickUpload}
    >
      Datei auswählen
      <input
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        hidden
        id="generator-batch-select-file-input"
        type="file"
        onChange={handleSelectFile}
      />
    </Button>
  );
}

interface ImportButtonProps extends Pick<ButtonProps, "disabled"> {
  buttonContainer?: HTMLElement;
  upload: () => Promise<void>;
}

function ImportButton({
  buttonContainer,
  disabled,
  upload
}: ImportButtonProps) {
  const [uploading, setUploading] = useState(false);

  async function handleClick() {
    setUploading(true);

    await upload();

    setUploading(false);
  }

  return (
    <>
      {buttonContainer &&
        ReactDOM.createPortal(
          <SpinButton
            color="primary"
            disabled={disabled}
            spin={uploading}
            onClick={handleClick}
          >
            Datei importieren
          </SpinButton>,
          buttonContainer
        )}
    </>
  );
}

export { GeneratorBatchImport };
