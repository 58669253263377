import { useQuery } from "@tanstack/react-query";
import api from "../../../api";
import urls from "../../../urls";
import type { LoadProfileGraphData } from "../LoadProfileManager.types";

export function useLoadProfilesGraph(loadProfileId: number) {
  const { data, isLoading, error } = useQuery({
    queryKey: ["loadProfileGraph", { loadProfileId }],
    queryFn: () => fetchLoadProfilesGraph(loadProfileId)
  });

  return { data, isLoading, error };
}

async function fetchLoadProfilesGraph(loadProfileId: number) {
  const url = urls.api.loadprofilesGraph(loadProfileId);
  const response = await api.get<Array<LoadProfileGraphData>>(url);

  return response.data;
}
