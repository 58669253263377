import React from "react";
import { Button } from "../Buttons/Button/Button";
import "./BannerToast.scss";

type BannerToastProps = {
  headline?: string;
  text?: string | React.JSX.Element;
} & (
  | {
      ctaAction: () => void;
      ctaText: string;
    }
  | {
      ctaAction?: never;
      ctaText?: never;
    }
);

function BannerToast({ headline, text, ctaAction, ctaText }: BannerToastProps) {
  return (
    <div className="BannerToast">
      {headline && <h5>{headline}</h5>}
      {text && <div className="toast-bodytext">{text}</div>}
      {ctaAction && ctaText && (
        <div className="toast-button-container">
          <Button
            color="dark"
            outline
            role="link"
            type="button"
            onClick={ctaAction}
          >
            {ctaText}
          </Button>
        </div>
      )}
    </div>
  );
}

export { BannerToast, BannerToastProps };
