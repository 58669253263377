import type { DateTime } from "luxon";
import type { Optional } from "utility-types";
import type { FormInputData } from "../../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import type { Choice } from "../../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import type {
  GridFeedinContract,
  ParkabrechnungContractFormOptionsMeteringLocation,
  PowerFeedoutContract,
  ShareType
} from "../../../Parkabrechnung.types";
import { ParkabrechnungBillingPeriod } from "../../../Parkabrechnung.types";

export type ParkabrechnungContractFormControlObject = {
  [key in keyof ParkabrechnungContractFormState]?: Partial<
    FormInputData<ParkabrechnungContractFormState, key>
  >;
};

export type ParkabrechnungContractFormState = {
  components: Array<number>;
  endDate: DateTime | null;
  feedinDistributionType: ShareType;
  feedinMeloMaloId: number;
  feedinPeriod: ParkabrechnungBillingPeriod;
  gridConnectionSupplyHolder: string;
  meloMaloId: number;
  name: string;
  period: ParkabrechnungBillingPeriod;
  powerFeedoutPrice: number;
  site: number;
  startDate: DateTime | null;
  [key: `share-${number}`]: number;
};

export type PoolContractsPayload = {
  begin: string;
  end: string;
  gridFeedoutContract?: GridFeedoutContract;
  gridFeedinContract?: GridFeedinContract;
  name: string;
  powerFeedoutContracts?: Array<PowerFeedoutContract>;
  site: {
    id: number;
  };
};

interface GridFeedoutContract {
  billingPeriod: ParkabrechnungBillingPeriod;
  componentFeedoutShares: Array<ComponentFeedoutShare>;
}

interface ComponentFeedoutShare {
  component: {
    id: number;
  };
  meteringOrMarketLocation: { id: number; number: string };
  share: number;
}

export type ParkabrechnungMeteringLocation = Optional<
  ParkabrechnungContractFormOptionsMeteringLocation,
  "default"
>;

export const PERIOD_CHOICES: Array<Choice<ParkabrechnungBillingPeriod>> = [
  { displayName: "Monatlich", value: ParkabrechnungBillingPeriod.Monthly },
  { displayName: "Jährlich", value: ParkabrechnungBillingPeriod.Yearly }
];
