import React, { useState } from "react";
import type { CellInfo } from "react-table";
import { RoundedNumberFormat } from "../../../../../../../utils/RoundedNumberFormat";
import { NumberInput } from "../../../../../../BuildingBlocks/Forms/FormFields/NumberInput/NumberInput";
import { CustomReactTable } from "../../../../../../CustomReactTable/CustomReactTable";
import type { Vollbenutzungsstunden } from "../VollbenutzungsstundenList";
import { computeCumulativeVBH } from "../VollbenutzungsstundenList";

const DEFAULT_SORTED = [
  {
    id: "year",
    desc: false
  }
];

export interface VollbenutzungsstundenTableProps {
  vollbenutzungsstunden: Array<Vollbenutzungsstunden>;
  onUpdateValues: (
    currentVBH: Vollbenutzungsstunden,
    newHours: number | null
  ) => void;
}

function VollbenutzungsstundenTable({
  vollbenutzungsstunden,
  onUpdateValues
}: VollbenutzungsstundenTableProps) {
  const tableColumns = [
    {
      Header: "Jahr",
      accessor: "year",
      sortable: false,
      Cell: (cellInfo: CellInfo) => <p>{cellInfo.value}</p>
    },
    {
      Header: "zuschlagsfähige Vollbenutzungsstunden",
      accessor: "hours",
      sortable: false,
      Cell: (cellInfo: CellInfo) => (
        <VBSCell
          original={cellInfo.original}
          updateValues={onUpdateValues}
          value={cellInfo.value || cellInfo.value === 0 ? cellInfo.value : null}
        />
      )
    },
    {
      Header: "kumulierte Vollbenutzungsstunden zum 1.1. des Folgejahres",
      sortable: false,
      Cell: (cellInfo: CellInfo) => (
        <VBSCumulatedCell
          allValues={vollbenutzungsstunden}
          currentYear={cellInfo.original.year}
        />
      )
    }
  ];

  return (
    <CustomReactTable
      columns={tableColumns}
      data={vollbenutzungsstunden}
      defaultSorted={DEFAULT_SORTED}
    />
  );
}

interface VBSCumulatedCellProps {
  allValues: Array<Vollbenutzungsstunden>;
  currentYear: number;
}

function VBSCumulatedCell({ allValues, currentYear }: VBSCumulatedCellProps) {
  const hours = computeCumulativeVBH(allValues).find(
    (value) => value.year === currentYear
  )?.hours;
  return <RoundedNumberFormat displayType="text" value={hours ?? ""} />;
}

interface VBSCellProps {
  value: null | number;
  original: Vollbenutzungsstunden;
  updateValues: (
    currentVBH: Vollbenutzungsstunden,
    newHours: number | null
  ) => void;
}

function VBSCell({
  value: initialValue,
  original,
  updateValues
}: VBSCellProps) {
  const [value, setValue] = useState(initialValue);
  return (
    <NumberInput
      value={value ? value : ""}
      onBlur={() => updateValues(original, value)}
      onChange={(targetValue) => {
        if (targetValue || targetValue === 0) {
          setValue(targetValue);
        } else {
          setValue(null);
        }
      }}
    />
  );
}

export { VollbenutzungsstundenTable };
