import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import api from "../api";
import urls from "../urls";

export type Tag = {
  name: string;
  color: string;
  backgroundColor: string;
  longName: string;
  slug: string;
};

function useEnergyDataAcquisitionTags(onError?: (error: unknown) => void) {
  const { data, error, isError, isLoading } = useQuery({
    queryKey: ["energy-data-acquisition-tags"],
    queryFn: () => fetchEnergyDataAcquisitionTags(),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity
  });

  async function fetchEnergyDataAcquisitionTags() {
    const url = urls.api.energyDataAcquisitionTags();
    const response = await api.get(url);
    return response.data;
  }

  useEffect(() => {
    if (onError && isError) {
      onError(error);
    }
  }, [isError, error, onError]);

  return {
    data,
    isLoading,
    error
  };
}

export { useEnergyDataAcquisitionTags };
