import { DateTime } from "luxon";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import type { EnergyRevenuesListResponse } from "../../RevenueMonitoring.types";

/** Find the earliest or latest date in a whole EnergyRevenuesListResponse */
export function getMinOrMaxDateFromEnergyRevenues(
  energyRevenues: EnergyRevenuesListResponse,
  minOrMax: "min" | "max"
) {
  if (energyRevenues.length === 0) {
    return undefined;
  }

  const dates = energyRevenues.map((revenue) =>
    backendDateOrDateTimeToLuxonDateTime(revenue.period_start)
  );

  if (minOrMax === "min") {
    return DateTime.min(...dates);
  }

  return DateTime.max(...dates);
}
