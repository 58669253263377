import { useQuery } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import urls from "../../../urls";
import type { OAuthApplicationOptionsForCreate } from "../ApiSettings.types";

export function useOAuthApplicationOptionsForCreate() {
  const { data, isLoading, error } = useQuery({
    queryKey: ["oauthApplicationsOptionsForCreate"],
    queryFn: () => fetchApplications()
  });

  async function fetchApplications() {
    const url = urls.apiWithoutCamelization.oauthApplicationsOptionsForCreate();
    const response =
      await apiWithoutCamelization.get<OAuthApplicationOptionsForCreate>(url);

    return response.data;
  }

  return {
    optionsForCreate: data,
    isLoading,
    error
  };
}
