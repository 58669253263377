import type { Meter, Site } from "./backend-types";

export const MANY_METERS_TEXT = "Mehrere Zähler";
export const METER_OPTIONAL_TEXT = "Zählerauswahl (optional)";

function getMeterDisplayName(meter: Meter): string {
  if (!meter.number) {
    return meter.name;
  }

  return `${meter.name} (${meter.number})`;
}

function getMeterGroupName(siteId: number, sites: Array<Site>): string {
  const site = sites.find((site) => site.id === siteId);

  if (!site) {
    return "Unbekannte Liegenschaft";
  }

  return site.name;
}

export { getMeterDisplayName, getMeterGroupName };
