import { getSelectedFormFields } from "../../common";

export function getFormFieldsAndValuesFromResponse<T>(
  response: T,
  visibleFields: Array<string> | null = null,
  hiddenFieldsIfNull: Array<string> = []
) {
  const selectedFormFields = getSelectedFormFields(
    response[0].data.actions.pUT,
    response[1].data,
    visibleFields || Object.keys(response[0].data.actions.pUT),
    hiddenFieldsIfNull
  );

  const responseData = response[1].data;
  const selectedData = selectedFormFields.reduce((values, field) => {
    values[field.name] = responseData[field.name];
    return values;
  }, {});

  return {
    fields: selectedFormFields,
    values: selectedData
  };
}
