import React, { useMemo } from "react";
import { Button } from "../../../../Buttons/Button/Button";
import { anchorTooltip } from "../../../../Tooltip/Tooltip";
import type { EnergyDataImportRequest } from "../../../EnergyDataUploadFlow/EnergyDataUpload";

const SUPPORTED_ERRORS = [
  "Unbekannter Fehler",
  "Die Datei konnte nicht mit der ausgewählten Herkunft verarbeitet werden.",
  "Dieses Format wird aktuell nicht unterstützt. Wir werden es uns anschauen und eventuell implementieren. Um die Daten direkt zu importieren, konvertieren Sie es bitte in unser Template"
];

interface Props {
  file: EnergyDataImportRequest;
  onRequestSupport: (request: EnergyDataImportRequest) => void;
}

function SupportCell({ file, onRequestSupport }: Props) {
  const text = useMemo(() => {
    if (file.supportRequested) {
      return "Eingesendet";
    } else if (
      file.errorMessage &&
      SUPPORTED_ERRORS.includes(file.errorMessage) &&
      !file.supportRequested
    ) {
      return "Anfrage";
    }
  }, [file.errorMessage, file.supportRequested]);

  if (text) {
    return (
      <div>
        <div
          className="tooltip-wrapper"
          {...anchorTooltip({
            content: file.supportRequested
              ? `Ihr Support-Anfrage für ${file.energyDataFile.name} wurde gestellt`
              : undefined,
            delayShow: 500
          })}
        >
          <Button
            color="brand"
            disabled={file.supportRequested}
            style={{ minWidth: "100%" }}
            onClick={() => onRequestSupport(file)}
          >
            {text}
          </Button>
        </div>
      </div>
    );
  }

  return <div />;
}

export { SupportCell };
