import { useQuery } from "@tanstack/react-query";
import api from "../../../api";
import urls from "../../../urls";

type CurrentUserIsInvoiceAdminResponse = boolean | null;

export function useCurrentUserIsInvoiceAdmin() {
  const { isLoading, error, data } = useQuery({
    queryKey: ["currentUserIsInvoiceAdmin"],
    queryFn: () => fetchCurrentUserIsInvoiceAdmin(),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity
  });

  async function fetchCurrentUserIsInvoiceAdmin() {
    const response = await api.get<CurrentUserIsInvoiceAdminResponse>(
      urls.api.currentUserIsInvoiceAdmin()
    );
    return response.data;
  }

  return { currentUserIsInvoiceAdmin: data, isLoading, error };
}
