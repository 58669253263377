import { decamelize } from "../../../../../../utils/SnakeCamelConversion";
import type { FieldNameToLabelMap } from "../../../../../HistoryTab/HistoryTab";
import type { Form } from "../data-types";

export function buildFieldNameToLabelMapOldForms(forms: Array<Form>) {
  /**
   * Takes an array of old style forms with sections, iterates over all field definitions to build mapping from backend
   * field names to frontend field labels, which are displayed to the user. This method serves as a workaround and
   * should be replaced by `frontend/app/utils/react-hook-form/buildFieldNameToLabelMap.ts` when migrating to
   * react-hook-form.
   * Example:
   * {
   *     name: "Bezeichnung",
   *     type: "Typ"
   * }
   */
  return forms.reduce<FieldNameToLabelMap>((mapping, form) => {
    form.sections.forEach((section) => {
      section.fields?.forEach((fieldData) => {
        mapping[decamelize(fieldData.name)] = fieldData.label;
      });
    });
    return mapping;
  }, {});
}
