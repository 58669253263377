import classNames from "classnames";
import React from "react";

import { Icon } from "../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../BuildingBlocks/Icon/types";

import "./InfoIcon.scss";

export enum InfoType {
  Instruction = "instruction",
  Information = "information",
  Warning = "warning"
}

const ICON_NAME_BY_TYPE: Record<InfoType, IconName> = {
  [InfoType.Instruction]: IconName.Lightbulb,
  [InfoType.Information]: IconName.InfoCircle,
  [InfoType.Warning]: IconName.Warning
};

interface InfoIconProps {
  infoType?: InfoType;
  children: React.ReactNode;
}

function InfoIcon({
  infoType = InfoType.Information,
  children
}: InfoIconProps) {
  const icon = ICON_NAME_BY_TYPE[infoType];

  return (
    <div className="InfoIcon">
      <Icon
        className={classNames("info-icon", {
          "instruction-icon": infoType === InfoType.Instruction,
          "information-icon": infoType === InfoType.Information,
          "warning-icon": infoType === InfoType.Warning
        })}
        name={icon}
      />
      {children}
    </div>
  );
}

export { InfoIcon, InfoIconProps };
