import React from "react";
import { numericFormatter } from "react-number-format";
import { useStartPageDeliveredElectricity } from "../../../hooks/startPage/useStartPageDeliveredElectricity";
import { IconName } from "../../BuildingBlocks/Icon/types";
import type { MiniTileItem } from "./MiniTile";
import { MiniTile } from "./MiniTile";

type DeliveredElectricityMiniTileProps = {
  projectId?: string;
  year: number;
};

function DeliveredElectricityMiniTile({
  projectId,
  year
}: DeliveredElectricityMiniTileProps) {
  const { data, isLoading } = useStartPageDeliveredElectricity(year, projectId);

  const items: Array<MiniTileItem> = [
    {
      label: "Direktlieferung",
      value: data
        ? numericFormatter(data.sumDirect.toString(), {
            decimalSeparator: ",",
            thousandSeparator: ".",
            suffix: " kWh"
          })
        : ""
    },
    {
      label: "Weiterleitung",
      value: data
        ? numericFormatter(data.sumForwarded.toString(), {
            decimalSeparator: ",",
            thousandSeparator: ".",
            suffix: " kWh"
          })
        : ""
    }
  ];

  return (
    <MiniTile
      iconName={IconName.SolarPowerGenerator}
      isLoading={isLoading}
      items={items}
      title="Gelieferte Strommengen"
    />
  );
}

export { DeliveredElectricityMiniTile };
