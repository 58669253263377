import api from "../../../../api";
import urls from "../../../../urls";
import type { MspCategory } from "../../../../utils/enums";
import { showToast } from "../../../../utils/toast";

export async function meteringServiceProviderFieldUpdate(
  field: string,
  newValue: MspCategory | string,
  providerId: number
): Promise<void> {
  try {
    await api.patch(urls.api.meteringServiceProvider(providerId), {
      [field]: newValue
    });
  } catch (error) {
    showToast("error", error);
  }
}
