import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { UncontrolledTooltip } from "reactstrap";
import api from "../../../../api";
import urls from "../../../../urls";
import { pollTaskStatus } from "../../../../utils/api-utils";
import { downloadCsvOrExcelFileForResponseWithContentDisposition } from "../../../../utils/files/downloadCsvOrExcelFileForResponseWithContentDisposition";
import { showToast } from "../../../../utils/toast";

import { SpinButton } from "../../../Buttons/SpinButton/SpinButton";

class PVInvestmentCalculationButton extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: null
    };
  }

  dispatchPVInvestmentCalculation = () => {
    const { scenarioId } = this.props;

    this.setState({ loading: true });

    const url = urls.api.dispatchPVInvestmentCalculation(scenarioId);
    api
      .post(url, {})
      .then((response) => {
        const {
          taskStatusUrl,
          taskIds: [taskId]
        } = response.data;
        pollTaskStatus(
          taskStatusUrl,
          () => this.downloadPVInvestmentCalculation(scenarioId, taskId),
          this.genericError,
          this.genericError
        );
      })
      .catch((error) => {
        showToast("error", error);
      })
      .finally(() => this.setState({ loading: false }));
  };

  downloadPVInvestmentCalculation = (scenarioId, taskId) => {
    api
      .get(urls.api.downloadPVInvestmentCalculation(scenarioId, taskId))
      .then((response) => {
        downloadCsvOrExcelFileForResponseWithContentDisposition(response, true);
        showToast("success", "Excel Datei steht zum herunterladen bereit");
      })
      .catch((error) => showToast("error", error));
  };

  genericError = () => {
    showToast("error", { response: { status: 500 } });
  };

  render() {
    const { scenarioId } = this.props;
    const { loading } = this.state;

    return (
      <SpinButton
        block
        disabled={loading}
        id={`investment-calculation-${scenarioId}`}
        spin={loading}
        onClick={this.dispatchPVInvestmentCalculation}
      >
        <UncontrolledTooltip
          placement="bottom"
          target={`investment-calculation-${scenarioId}`}
        >
          Der Investitionsrechner erlaubt eine Wirtschaftlichkeitsbewertung der
          simulierten PV-Anlage. Weiterführende Informationen entnehmen Sie
          bitte direkt der Berechnung
        </UncontrolledTooltip>
        Investitionsrechner
      </SpinButton>
    );
  }
}

PVInvestmentCalculationButton.propTypes = {
  scenarioId: PropTypes.string.isRequired
};

export { PVInvestmentCalculationButton };
