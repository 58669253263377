export const CHARACTER_LIMIT = 140;
export const LINE_BREAK_LIMIT = 1;

/**
 * Checks if a string is too long or has too many line breaks (paragraphs)
 * @param text - the string to check
 * @param maxLength - the maximum length of the string, defaults to 140
 * @returns boolean
 */
export function isTooLongText(text: string, maxLength = CHARACTER_LIMIT) {
  return (
    text.length > maxLength ||
    (text.match(/(\r\n\n|\r\r|\n\n)/g) || []).length > LINE_BREAK_LIMIT
  );
}
