import { FINANCIAL_DOCUMENT_TYPES } from "../../../../utils/constants";
import type { Paragraph6FinancialDocument } from "../../Paragraph6.types";

/** Filters credits by Gutschriftdokument, Gesellschaft, or Gemeinde / Kommune,
 *  using a provided search string */
export function creditsBySearchString(
  searchString: string,
  credit: Paragraph6FinancialDocument
) {
  if (!searchString) {
    return true;
  }

  const lowerCaseSearchString = searchString.toLowerCase();

  return (
    (credit.type &&
      FINANCIAL_DOCUMENT_TYPES[credit.type]
        .toLowerCase()
        .includes(lowerCaseSearchString)) ||
    credit.document_name.toLowerCase().includes(lowerCaseSearchString) ||
    credit.supplier.toLowerCase().includes(lowerCaseSearchString) ||
    credit.community_name.toLowerCase().includes(lowerCaseSearchString) ||
    credit.credit_number?.toLowerCase().includes(lowerCaseSearchString)
  );
}

export function filterCreditsBySearchString(
  searchString: string,
  credits: Array<Paragraph6FinancialDocument>
) {
  return credits.filter((credit) =>
    creditsBySearchString(searchString, credit)
  );
}
