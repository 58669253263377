import classnames from "classnames";
import React from "react";
import { generatePath, Link } from "react-router-dom";
import { ROUTES } from "../../../../routes";
import type { WizardMissingData } from "../../../../utils/backend-types";
import { Icon } from "../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import { Portlet } from "../../../BuildingBlocks/Layout/Portlet";
import { Button } from "../../../Buttons/Button/Button";
import { TABS } from "../../../DeliveryOverview/DeliveryOverview";
import { OrangeCircle } from "../../../OrangeCircle/OrangeCircle";
import { StepStatus } from "../../ChangeProcess.types";
import type {
  OperatorChange,
  OperatorChangeSteps
} from "../OperatorChanges.types";

interface OperatorChangeProcessProps {
  operatorChange: OperatorChange;
  toggleInitial: boolean;
  shouldShowStaffView: boolean;
  isUpdating: boolean;
  onUpdateStepStatus: (
    operatorChange: OperatorChange,
    step: keyof OperatorChangeSteps,
    status: StepStatus
  ) => void;
  projectId: string | undefined;
}

function OperatorChangeProcess({
  operatorChange,
  toggleInitial,
  isUpdating,
  onUpdateStepStatus,
  shouldShowStaffView,
  projectId
}: OperatorChangeProcessProps) {
  const stepNewData = operatorChange.steps.stepNewData;
  const stepNewContract = operatorChange.steps.stepNewContract;
  const stepOldContract = operatorChange.steps.stepOldContract;
  const stepTaskList = operatorChange.steps.stepTaskList;

  return (
    <Portlet
      className="OperatorChangeProcess"
      key={operatorChange.id}
      title={
        <span className="site-tasks-title">
          {"Betreiberwechsel - " + operatorChange.componentName}
        </span>
      }
      toggle
      toggleIconClosed={IconName.AngleRight}
      toggleIconOpen={IconName.AngleDown}
      toggleInitial={toggleInitial}
    >
      <StepNewData
        isUpdating={isUpdating}
        missingData={stepNewData.missingData}
        modalLink={`betreiberwechsel/unternehmensdaten/${operatorChange.id}/`}
        status={stepNewData.done}
        onUpdateStepStatus={(status) =>
          onUpdateStepStatus(operatorChange, "stepNewData", status)
        }
      />
      <StepNewContract
        isUpdating={isUpdating}
        objectsAreMissing={stepNewContract.objectsAreMissing}
        projectId={projectId}
        status={stepNewContract.done}
        onUpdateStepStatus={(status) =>
          onUpdateStepStatus(operatorChange, "stepNewContract", status)
        }
      />
      <StepOldContract
        isUpdating={isUpdating}
        missingData={stepOldContract.missingData}
        modalLink={`betreiberwechsel/vertragsdaten/${operatorChange.id}/`}
        status={stepOldContract.done}
        onUpdateStepStatus={(status) =>
          onUpdateStepStatus(operatorChange, "stepOldContract", status)
        }
      />
      <StepTaskList
        isUpdating={isUpdating}
        shouldShowStaffView={shouldShowStaffView}
        status={stepTaskList.done}
        onUpdateStepStatus={(status) =>
          onUpdateStepStatus(operatorChange, "stepTaskList", status)
        }
      />
    </Portlet>
  );
}

interface StepNewDataProps {
  status: StepStatus;
  missingData: Array<WizardMissingData> | null;
  isUpdating: boolean;
  onUpdateStepStatus: (status: StepStatus) => void;
  modalLink: string;
}

function StepNewData({
  status,
  missingData,
  isUpdating,
  onUpdateStepStatus,
  modalLink
}: StepNewDataProps) {
  return (
    <div className="operator-change-step">
      <div
        className={classnames("step-title", {
          "step-title-inactive":
            status === StepStatus.done || status === StepStatus.notRelevant
        })}
      >
        <span>Stammdaten des neuen Betreibers eintragen</span>
        {status === StepStatus.done && (
          <Icon className="step-done-icon" name={IconName.CheckCircle} />
        )}
        {status === StepStatus.notRelevant && (
          <Icon className="step-done-icon" name={IconName.Minus} />
        )}
      </div>
      <div className="step-action-container">
        {status === StepStatus.open &&
          (missingData === null || missingData.length === 0) && (
            <Button
              color="secondary"
              disabled={isUpdating}
              onClick={() => onUpdateStepStatus(StepStatus.done)}
            >
              Als erledigt markieren
            </Button>
          )}
        {status === StepStatus.open &&
          missingData &&
          missingData.length > 0 && (
            <>
              <Button
                color="brand"
                disabled={isUpdating}
                tag={Link}
                to={modalLink}
              >
                Fehlende Daten ergänzen
              </Button>
              <Button
                color="secondary"
                disabled={isUpdating}
                onClick={() => onUpdateStepStatus(StepStatus.notRelevant)}
              >
                Überspringen
              </Button>
            </>
          )}
      </div>
    </div>
  );
}

interface StepNewContractProps {
  status: StepStatus;
  isUpdating: boolean;
  objectsAreMissing: boolean | null;
  onUpdateStepStatus: (status: StepStatus) => void;
  projectId: string | undefined;
}

function StepNewContract({
  status,
  isUpdating,
  objectsAreMissing,
  onUpdateStepStatus,
  projectId
}: StepNewContractProps) {
  const linkToContracts = `${generatePath(ROUTES.deliveryOverview, {
    projectId: projectId ?? null
  })}/${TABS.TAB_CONTRACTS}/`;

  return (
    <div className="operator-change-step">
      <div
        className={classnames("step-title", {
          "step-title-inactive":
            status === StepStatus.done || status === StepStatus.notRelevant
        })}
      >
        <span>Neue Verträge & Lieferungen anlegen</span>
        {status === StepStatus.done && (
          <Icon className="step-done-icon" name={IconName.CheckCircle} />
        )}
        {status === StepStatus.notRelevant && (
          <Icon className="step-done-icon" name={IconName.Minus} />
        )}
      </div>
      <div className="step-action-container">
        {status === StepStatus.open && !objectsAreMissing && (
          <Button
            color="secondary"
            disabled={isUpdating}
            onClick={() => onUpdateStepStatus(StepStatus.done)}
          >
            Als erledigt markieren
          </Button>
        )}
        {status === StepStatus.open && objectsAreMissing && (
          <>
            <Button
              color="brand"
              disabled={isUpdating}
              tag={Link}
              to={linkToContracts}
            >
              Neue Verträge und Lieferungen anlegen
            </Button>
            <Button
              color="secondary"
              disabled={isUpdating}
              onClick={() => onUpdateStepStatus(StepStatus.notRelevant)}
            >
              Überspringen
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

interface StepOldContractProps {
  status: StepStatus;
  isUpdating: boolean;
  missingData: Array<WizardMissingData> | null;
  onUpdateStepStatus: (status: StepStatus) => void;
  modalLink: string;
}

function StepOldContract({
  status,
  isUpdating,
  missingData,
  onUpdateStepStatus,
  modalLink
}: StepOldContractProps) {
  return (
    <div className="operator-change-step">
      <div
        className={classnames("step-title", {
          "step-title-inactive":
            status === StepStatus.done || status === StepStatus.notRelevant
        })}
      >
        <span>Verträge des alten Betreibers anpassen</span>
        {status === StepStatus.done && (
          <Icon className="step-done-icon" name={IconName.CheckCircle} />
        )}
        {status === StepStatus.notRelevant && (
          <Icon className="step-done-icon" name={IconName.Minus} />
        )}
      </div>
      <div className="step-action-container">
        {status === StepStatus.open &&
          (missingData === null || missingData.length === 0) && (
            <Button
              color="secondary"
              disabled={isUpdating}
              onClick={() => onUpdateStepStatus(StepStatus.done)}
            >
              Als erledigt markieren
            </Button>
          )}
        {status === StepStatus.open &&
          missingData !== null &&
          missingData.length > 0 && (
            <>
              <Button
                color="brand"
                disabled={isUpdating}
                tag={Link}
                to={modalLink}
              >
                Fehlende Daten ergänzen
              </Button>
              <Button
                color="secondary"
                disabled={isUpdating}
                onClick={() => onUpdateStepStatus(StepStatus.notRelevant)}
              >
                Überspringen
              </Button>
            </>
          )}
      </div>
    </div>
  );
}

interface StepTaskListProps {
  status: StepStatus;
  isUpdating: boolean;
  shouldShowStaffView: boolean;
  onUpdateStepStatus: (status: StepStatus) => void;
}

function StepTaskList({
  status,
  isUpdating,
  shouldShowStaffView,
  onUpdateStepStatus
}: StepTaskListProps) {
  return (
    <div className="operator-change-step">
      <div
        className={classnames("step-title", {
          "step-title-inactive":
            status === StepStatus.done || status === StepStatus.notRelevant
        })}
      >
        <span>Aufgabenliste aktualisieren</span>
        {status === StepStatus.open && !shouldShowStaffView && (
          <div className="todo-status">
            <OrangeCircle />
          </div>
        )}
        {status === StepStatus.done && (
          <Icon className="step-done-icon" name={IconName.CheckCircle} />
        )}
        {status === StepStatus.notRelevant && (
          <Icon className="step-done-icon" name={IconName.Minus} />
        )}
      </div>
      <div className="step-action-container">
        {status === StepStatus.open && shouldShowStaffView && (
          <Button
            color="secondary"
            disabled={isUpdating}
            onClick={() => onUpdateStepStatus(StepStatus.done)}
          >
            Als erledigt markieren
          </Button>
        )}
        {status === StepStatus.open && !shouldShowStaffView && (
          <span>Prüfung durch Mitarbeiter:innen</span>
        )}
      </div>
    </div>
  );
}

export { OperatorChangeProcessProps, OperatorChangeProcess };
