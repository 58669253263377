import type { Meter } from "../../../utils/backend-types";

export function getSuggestedMeterDisplayName(meter?: Meter): string {
  if (meter) {
    return `${meter.number} | ${
      meter?.name ? meter.name : ""
    } (autom. Vorschlag)`;
  }

  return "Unbekannt";
}
