import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../api";
import urls from "../urls";

export function useDeleteFullMbk() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (siteId: number) => deleteFullMbk(siteId),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["mbks"] });
    }
  });
}

async function deleteFullMbk(siteId: number) {
  return await api.delete(urls.api.deleteFullMbk(siteId));
}
