import type { TodoFile } from "../../../../../../utils/backend-types";

export function getLatestDocuments(
  documents: Array<TodoFile>,
  numberOfDocuments: number
): TodoFile[] {
  if (documents.length === 0) {
    return [];
  }

  return documents.sort((a, b) => b.id - a.id).slice(0, numberOfDocuments);
}
