import React from "react";
import { FormItem } from "../../../FormItem/FormItem";
import { FormItemInfoText } from "../../../FormItem/FormItemInfoText";
import type { FormFieldData, FormItemsProps } from "../../FormItems";

interface FieldWithInfoTextAbove extends Omit<FormItemsProps, "formItems"> {
  fieldNames: [string];
  formItems: Record<string, FormFieldData>;
  infoText: string;
}

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function FieldWithInfoTextAbove({
  fieldNames,
  formName,
  formItems,
  formValues,
  formErrors,
  allowInput,
  infoText,
  onInput,
  missingFields,
  exclusiveOrFields,
  isHighlighted
}: FieldWithInfoTextAbove) {
  const fieldName = fieldNames[0];
  const { [fieldName]: formItem } = formItems;
  const { [fieldName]: value } = formValues;
  const { [fieldName]: errorTexts } = formErrors;
  return (
    <div className="FieldWithInfoTextAbove">
      <FormItemInfoText infoText={infoText} />
      <FormItem
        {...formItem}
        allowInput={allowInput}
        errors={errorTexts}
        formName={formName}
        highlight={
          isHighlighted
            ? isHighlighted(missingFields, exclusiveOrFields, formItem)
            : !!missingFields && missingFields.includes(formItem.name)
        }
        value={value}
        onInput={onInput}
      />
    </div>
  );
}

export { FieldWithInfoTextAbove };
