import type {
  SiteSetupProcessForForm,
  SiteSetupProcessRaw
} from "../SiteSetupAssistant.types";

export function formatFrontedDataToBackend(
  frontendData: SiteSetupProcessForForm
): SiteSetupProcessRaw {
  return {
    step: frontendData.step,
    site: {
      name: frontendData.name ?? undefined,
      pv_plants: frontendData.pvPlants
        ?.filter((pvPlant) => pvPlant && pvPlant.see_number)
        .map((pvPlant) => ({
          see_number: pvPlant.see_number,
          meter: pvPlant.meter
        })),
      address: {
        address: frontendData.siteAddress || null,
        zip: frontendData.siteZip || null,
        city: frontendData.siteCity || null
      },
      supply_type: frontendData.siteSupplyType || null,
      battery_storage: frontendData.battery_storage || null,
      connection_meter: frontendData.connection_meter || null,
      connection_voltage_level: frontendData.connection_voltage_level || null,
      consumers: frontendData.consumers || []
    },
    default_pv_plants_measurement_type:
      frontendData.defaultPvPlantsMeasurementType || null,
    connection_meter_energy_data_origin:
      frontendData.connectionMeterEnergyDataOrigin || null,
    voltage_level_determined_automatically:
      frontendData.voltageLevelDeterminedAutomatically || null,
    consumer_amount: frontendData.consumerAmount || null,
    default_consumer_type: frontendData.defaultConsumerType || null,
    default_consumer_measurement_type:
      frontendData.defaultConsumerMeasurementType || null,
    all_tenant_addresses_are_same_as_site_address:
      frontendData.allTenantAddressesAreSameAsSiteAddress || null,
    all_tenants_participating_in_mieterstrom:
      frontendData.allTenantsParticipatingInMieterstrom || null,
    site_has_battery_storage: frontendData.siteHasBatteryStorage || null,
    is_battery_storage_measured_separately:
      frontendData.isBatteryStorageMeasuredSeparately || null
  };
}
