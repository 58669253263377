import { SiteSetupProcessDataDefault_pv_plants_measurement_type } from "../../../types/api.types";
import type { FormFieldData } from "../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { type SiteSetupProcessForForm } from "../SiteSetupAssistant.types";

export const SITE_SETUP_ASSISTANT_FORM = {
  name: {
    name: "name",
    type: "text",
    required: true,
    label: "Bezeichnung der Liegenschaft"
  },
  defaultPvPlantsMeasurementType: {
    name: "defaultPvPlantsMeasurementType",
    type: "dropdown",
    required: true,
    label: "Auf welche Art wird die Erzeugung der PV-Anlage(n) gemessen?",
    choices: [
      {
        value: SiteSetupProcessDataDefault_pv_plants_measurement_type.single,
        displayName: "Lastgang"
      },
      {
        value:
          SiteSetupProcessDataDefault_pv_plants_measurement_type.arbitrary_cumulative,
        displayName: "Zählerstände (selbst abgelesen)"
      },
      {
        value: SiteSetupProcessDataDefault_pv_plants_measurement_type.no_meter,
        displayName: "Kein Zähler vorhanden"
      }
    ]
  },
  siteAddress: {
    name: "siteAddress",
    type: "text",
    required: true,
    label: "Straße und Hausnummer"
  },
  siteZip: {
    name: "siteZip",
    type: "text",
    required: true,
    label: "Postleitzahl"
  },
  siteCity: {
    name: "siteCity",
    type: "text",
    required: true,
    label: "Ort"
  }
} as const satisfies FormFieldData<SiteSetupProcessForForm>;
