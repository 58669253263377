import * as Sentry from "@sentry/browser";
import type { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import { Frequency } from "../../../utils/enums";
import { decamelize } from "../../../utils/SnakeCamelConversion";
import { showToast } from "../../../utils/toast";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../../Buttons/Button/Button";
import { SpinButton } from "../../Buttons/SpinButton/SpinButton";
import type { FormFieldValue } from "../../DynamicForm/FormItems/FormField/FormField";
import type { Identity } from "../EdaTable/Mbk";
import type { CreateEdaVersionFormProps } from "./CreateEdaVersionForm/CreateEdaVersionForm";
import { CreateEdaVersionForm } from "./CreateEdaVersionForm/CreateEdaVersionForm";
import {
  EDA_FORM_FIELDS,
  REQUIRED_FIELDS
} from "./CreateEdaVersionModal.constants";
import type {
  ComputationPayload,
  EdaVersionFormValues,
  EstimationPayload,
  GenericPayload,
  MeterPayload
} from "./CreateEdaVersionModal.types";
import {
  ESTIMATION_PROCEDURES_CONSTANT_LOAD,
  ESTIMATION_PROCEDURES_EXAMPLE_MEASURING,
  ESTIMATION_PROCEDURES_MISC,
  ESTIMATION_PROCEDURES_NON_CALIBRATED_MEASURING,
  ESTIMATION_PROCEDURES_PARITAL_RATIO,
  ESTIMATION_PROCEDURES_TYPICAL_STANDARD_VALUES,
  ESTIMATION_PROCEDURES_TYPICAL_STANDARD_VALUES_15_MIN,
  ORIGIN_COMPUTATION,
  ORIGIN_ESTIMATION,
  ORIGIN_METER
} from "./CreateEdaVersionModal.types";
import { useCreateEdaVersion } from "./hooks/useCreateEdaVersion";
import { useIdentityList } from "./hooks/useIdentityList";
import { computeInitialFormStateFromPrefillData } from "./utils/computeInitialFormStateFromPrefillData";
import { createIdentity } from "./utils/createIdentity";

export type DateRange = {
  startDate: DateTime | null;
  endDate: DateTime | null;
};

export interface CreateEdaVersionModalProps
  extends Pick<
    CreateEdaVersionFormProps,
    | "isNewVersion"
    | "prefillData"
    | "referencedIdentityOptions"
    | "siteId"
    | "tagOptions"
  > {
  isOpen: boolean;
  onToggle: () => void;
  onVersionCreated: () => void;
}

export function CreateEdaVersionModal({
  isNewVersion,
  isOpen,
  prefillData,
  referencedIdentityOptions,
  siteId,
  tagOptions,
  onVersionCreated,
  onToggle
}: CreateEdaVersionModalProps) {
  const formInitialState = computeInitialFormStateFromPrefillData(
    prefillData,
    referencedIdentityOptions
  );
  const [formValues, setFormValues] =
    useState<EdaVersionFormValues>(formInitialState);
  const [requiredFieldErrors, setRequiredFieldErrors] = useState<Array<string>>(
    []
  );

  function handleUpdateFormValues(
    key: string,
    value:
      | FormFieldValue
      | {
          startDate: DateTime | null;
          endDate: DateTime | null;
        }
  ) {
    setFormValues((prev) => ({ ...prev, [key]: value }));
  }

  const { data: identityList } = useIdentityList({
    shouldReturnDictionary: true
  });

  const {
    mutate: mutateCreateEdaVersion,
    data,
    error,
    isPending
  } = useCreateEdaVersion();

  useEffect(() => {
    if (data && !isPending) {
      onVersionCreated();
      onToggle();
    }
  }, [data, isPending, onToggle, onVersionCreated]);

  let errorMessage: string | null = null;

  if (isDataStringResponse(error)) {
    errorMessage = error.response.data;
  }

  useEffect(() => {
    if (errorMessage) {
      showToast("error", errorMessage);
    }
  }, [errorMessage]);

  function checkForEmptyRequiredFields() {
    const emptyRequiredFields: string[] = [];
    for (const field of REQUIRED_FIELDS) {
      if (field === EDA_FORM_FIELDS["validity"]) {
        if (
          !formValues[EDA_FORM_FIELDS.validity]?.startDate ||
          !formValues[EDA_FORM_FIELDS.validity]?.endDate
        ) {
          emptyRequiredFields.push(field);
        }
      } else if (
        typeof formValues[field] === "undefined" ||
        formValues[field] === null
      ) {
        emptyRequiredFields.push(field);
      }
    }
    setRequiredFieldErrors(emptyRequiredFields);
  }

  function setPayloadAndMutate() {
    checkForEmptyRequiredFields();

    const components = [
      formValues[EDA_FORM_FIELDS.generator] ??
        prefillData?.originalVersion.identity.generator,

      formValues[EDA_FORM_FIELDS.consumer] ??
        prefillData?.originalVersion.identity.consumer
    ].filter((c): c is number => typeof c === "number");

    if (!formValues[EDA_FORM_FIELDS.artDerEnergieMenge]) {
      return;
    }

    const firstDate = formValues[EDA_FORM_FIELDS.validity]?.startDate;
    const lastDate = formValues[EDA_FORM_FIELDS.validity]?.endDate;

    if (!isDefined(firstDate) || !isDefined(lastDate)) {
      console.log("firstDate or lastDate is not defined");
      return;
    }

    const identity: Identity = createIdentity(formValues, identityList, siteId);

    const genericPayload: GenericPayload = {
      ...prefillData?.originalVersion.versions[0],
      identity,
      tags:
        formValues[EDA_FORM_FIELDS.stromsteuerpflichtig] &&
        formValues[EDA_FORM_FIELDS.tags]
          ? formValues[EDA_FORM_FIELDS.tags]
          : [],
      label: formValues[EDA_FORM_FIELDS.label] ?? "",
      components,
      siteId,
      firstDate: luxonDateTimeToBackendDateOrDateTime(
        formValues[EDA_FORM_FIELDS.validity]?.startDate as DateTime,
        "ISO 8601"
      ),
      lastDate: luxonDateTimeToBackendDateOrDateTime(
        formValues[EDA_FORM_FIELDS.validity]?.endDate as DateTime,
        "ISO 8601"
      ),
      connections: formValues[EDA_FORM_FIELDS.connection]
        ? [formValues[EDA_FORM_FIELDS.connection]]
        : [],
      gasConnections: []
    };

    switch (formValues[EDA_FORM_FIELDS.origin]) {
      case ORIGIN_COMPUTATION: {
        if (!formValues[EDA_FORM_FIELDS.formula]) {
          console.log("formulaStringValue is not defined");
          return;
        }
        if (!formValues[EDA_FORM_FIELDS.medium]) {
          console.log("mediumValue is not defined");
          return;
        }
        if (!formValues[EDA_FORM_FIELDS.frequency]) {
          console.log("frequencyValue is not defined");
          return;
        }

        const computationPayload: ComputationPayload = {
          origin: formValues[EDA_FORM_FIELDS.origin],
          meter: null,
          estimation: null,
          computation: {
            formulaStr: formValues[EDA_FORM_FIELDS.formula],
            medium: formValues[EDA_FORM_FIELDS.medium],
            frequency: formValues[EDA_FORM_FIELDS.frequency]
          }
        };
        mutateCreateEdaVersion({ ...genericPayload, ...computationPayload });
        break;
      }
      case ORIGIN_ESTIMATION: {
        if (!formValues[EDA_FORM_FIELDS.estimationProcedure]) {
          console.log("estimationProcedureValue is not defined");
          return;
        }

        let _frequencyValue: Frequency;
        switch (formValues[EDA_FORM_FIELDS.estimationProcedure]) {
          case ESTIMATION_PROCEDURES_CONSTANT_LOAD:
          case ESTIMATION_PROCEDURES_TYPICAL_STANDARD_VALUES_15_MIN:
            _frequencyValue = Frequency.QuarterHour;
            break;
          case ESTIMATION_PROCEDURES_PARITAL_RATIO:
          case ESTIMATION_PROCEDURES_EXAMPLE_MEASURING:
          case ESTIMATION_PROCEDURES_NON_CALIBRATED_MEASURING:
            _frequencyValue = Frequency.Year;
            break;
          case ESTIMATION_PROCEDURES_TYPICAL_STANDARD_VALUES:
            _frequencyValue = Frequency.Year;
            break;
          case ESTIMATION_PROCEDURES_MISC:
          default:
            _frequencyValue = formValues[EDA_FORM_FIELDS.frequency]
              ? (formValues[EDA_FORM_FIELDS.frequency] as Frequency)
              : Frequency.QuarterHour;
            break;
        }

        if (!formValues[EDA_FORM_FIELDS.medium]) {
          console.log("mediumValue is not defined");
          return;
        }

        const estimationPayload: EstimationPayload = {
          origin: "estimation",
          meter: null,
          computation: null,
          estimation: {
            frequency: _frequencyValue ? _frequencyValue : "",
            procedure: decamelize(
              formValues[EDA_FORM_FIELDS.estimationProcedure]
            ),
            ratioOfEnergyData: formValues[EDA_FORM_FIELDS.ratioOfEnergyData],
            securityBuffer: formValues[
              EDA_FORM_FIELDS.securityBuffer
            ] as number,

            medium: formValues[EDA_FORM_FIELDS.medium],
            annualConsumption: formValues[EDA_FORM_FIELDS.annualConsumption],
            computation:
              formValues[EDA_FORM_FIELDS.formula] &&
              formValues[EDA_FORM_FIELDS.medium] &&
              _frequencyValue
                ? {
                    formulaStr: formValues[EDA_FORM_FIELDS.formula],
                    medium: formValues[EDA_FORM_FIELDS.medium],
                    frequency: _frequencyValue
                  }
                : undefined,
            constantLoadKw: formValues[EDA_FORM_FIELDS.constantLoadKw],
            numberOfConsumers: formValues[EDA_FORM_FIELDS.numberOfConsumers],
            referencedEdaIdentity: formValues[
              EDA_FORM_FIELDS.referencedEdaIdentity
            ]
              ? referencedIdentityOptions[
                  formValues[EDA_FORM_FIELDS.referencedEdaIdentity]
                ]
              : null
          }
        };
        mutateCreateEdaVersion({ ...genericPayload, ...estimationPayload });
        break;
      }
      case ORIGIN_METER: {
        if (!formValues[EDA_FORM_FIELDS.meter]) {
          console.log("formulaStringValue is not defined");
          return;
        }
        if (!formValues[EDA_FORM_FIELDS.medium]) {
          console.log("mediumValue is not defined");
          return;
        }

        const meterPayload: MeterPayload = {
          meter: {
            id: formValues[EDA_FORM_FIELDS.meter],
            medium: formValues[EDA_FORM_FIELDS.medium]
          },
          computation: null,
          estimation: null,
          origin: ORIGIN_METER
        };
        mutateCreateEdaVersion({ ...genericPayload, ...meterPayload });
        break;
      }
      default:
        Sentry.captureMessage(
          `unknown origin: ${formValues[EDA_FORM_FIELDS.origin]}`
        );
        break;
    }
  }

  return (
    <Modal fade={false} isOpen={isOpen} size="lg" toggle={onToggle}>
      <ModalHeader toggle={onToggle}>
        Energiedatenerfassung hinzufügen
      </ModalHeader>
      <ModalBody scrollable>
        {identityList && (
          <CreateEdaVersionForm
            formValues={formValues}
            identityList={identityList}
            isNewVersion={isNewVersion}
            prefillData={prefillData}
            referencedIdentityOptions={referencedIdentityOptions}
            requiredFieldErrors={requiredFieldErrors}
            siteId={siteId}
            tagOptions={tagOptions}
            onUpdateFormValues={handleUpdateFormValues}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={onToggle}>Abbrechen</Button>
        <SpinButton
          color="primary"
          spin={isPending}
          onClick={() => {
            setPayloadAndMutate();
          }}
        >
          Speichern
        </SpinButton>
      </ModalFooter>
    </Modal>
  );
}

export const isDataStringResponse = (
  arg: unknown
): arg is { response: { data: string } } => {
  return (
    typeof arg === "object" &&
    arg !== null &&
    "response" in arg &&
    typeof arg.response === "object" &&
    arg.response !== null &&
    "data" in arg.response &&
    typeof arg.response.data === "string"
  );
};

export function isDefined<T>(arg: T | null | undefined): arg is T {
  return typeof arg !== "undefined" && arg !== null;
}
