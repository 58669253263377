import { Textarea } from "@mantine/core";
import React, { useState } from "react";
import { Icon } from "../../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../../BuildingBlocks/Icon/types";
import "./CustomInputComponent.scss";

interface CustomInputComponentProps {
  onSubmitNewTextValue: (value: string) => void;
  value: string;
  onCancel: () => void;
}

function CustomInputComponent({
  onSubmitNewTextValue,
  onCancel,
  value
}: CustomInputComponentProps) {
  const [textValue, setTextValue] = useState(value);

  return (
    <div className="CustomInputComponent">
      <Textarea
        autoFocus
        className="custom-text-element-node-text-input"
        value={textValue}
        onChange={(e) => setTextValue(e.target.value)}
        onClick={(e) => e.stopPropagation()}
      />
      <div className="cic-btn-footer">
        <Icon
          className="cancel-btn"
          name={IconName.Close}
          onClick={(e) => {
            e.stopPropagation();
            onCancel();
          }}
        />
        <Icon
          className="success-btn"
          name={IconName.Check}
          onClick={(e) => {
            e.stopPropagation();
            onSubmitNewTextValue(textValue);
          }}
        />
      </div>
    </div>
  );
}

export { CustomInputComponent };
