import type { FictionalEnergyValueResponse } from "../EnergyDataUploadPage/FictionalEnergyValueManager/FictionalEnergyValueManager";

/** Filters FictionalEnergyValueResponse by all form values,
 *  using a provided search string */
export function filterFictionalEnergyValueResponseBySearchString(
  searchString: string,
  fictionalEnergyValues: Array<FictionalEnergyValueResponse>
) {
  if (!searchString) {
    return fictionalEnergyValues;
  }

  const lowerCaseSearchString = searchString.toLowerCase();

  return fictionalEnergyValues.filter((item) => {
    return (
      item.generatorDisplayName
        ?.toLowerCase()
        .includes(lowerCaseSearchString) ||
      item.firstDate?.toLowerCase().includes(lowerCaseSearchString) ||
      item.lastDate?.toLowerCase().includes(lowerCaseSearchString) ||
      item.technischeNichtverfuegbarkeitKwh
        ?.toString()
        .toLowerCase()
        .includes(lowerCaseSearchString) ||
      item.redispatchAbregelungKwh
        ?.toString()
        .toLowerCase()
        .includes(lowerCaseSearchString) ||
      item.sonstigeAbschaltungOderDrosselungKwh
        ?.toString()
        .toLowerCase()
        .includes(lowerCaseSearchString)
    );
  });
}
