import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, FormGroup } from "reactstrap";
import api from "../../api";
import urls from "../../urls";
import { showToast } from "../../utils/toast";
import { Alert, AlertColor } from "../Alert/Alert";
import { Portlet } from "../BuildingBlocks/Layout/Portlet";
import { SpinButton } from "../Buttons/SpinButton/SpinButton";
import { FormItem } from "../DynamicForm/FormItem/FormItem";
import { FormFieldType } from "../DynamicForm/FormItems/FormField/FormField";

interface ChangePasswordFormState {
  currentPass: string;
  newPass: string;
  newPassConfirm: string;
}

function ChangePasswordForm() {
  const { t } = useTranslation();
  const [error, setError] = useState<string | Array<string> | null>(null);
  const [isChangingPass, setIsChangingPass] = useState(false);
  const [formState, setFormState] = useState<ChangePasswordFormState>({
    currentPass: "",
    newPass: "",
    newPassConfirm: ""
  });

  async function handleSubmitResetPass(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (formState.newPass !== formState.newPassConfirm) {
      setError("Passwörter stimmen nicht überein.");
      return;
    }

    if (
      !formState.currentPass ||
      !formState.newPass ||
      !formState.newPassConfirm
    ) {
      setError("Bitte füllen Sie alle Eingabefelder aus.");
      return;
    }

    setError(null);
    setIsChangingPass(true);

    try {
      await api.post(urls.api.changePassword(), {
        old_password: formState.currentPass,
        new_password1: formState.newPass,
        new_password2: formState.newPassConfirm
      });

      showToast("success", "Passwort wurde erfolgreich geändert");
      setFormState({
        currentPass: "",
        newPass: "",
        newPassConfirm: ""
      });
    } catch (error) {
      setError(
        error.response?.data?.[Object.keys(error.response?.data)[0]] ||
          t("errors.Login.ChangePasswordError")
      );
    } finally {
      setIsChangingPass(false);
    }
  }

  return (
    <Portlet title="Passwort zurücksetzen">
      <Form className="m-form ays-enable" onSubmit={handleSubmitResetPass}>
        <FormGroup id="div_id_image">
          <FormItem
            initialValue=""
            label="Aktuelles Passwort"
            name="currentPassword"
            required
            type={FormFieldType.Obfuscated}
            value={formState.currentPass}
            onInput={(_, value: string) =>
              setFormState((prevState) => ({
                ...prevState,
                currentPass: value
              }))
            }
          />
          <FormItem
            initialValue=""
            label="Neues Passwort"
            name="newPass"
            required
            type={FormFieldType.Obfuscated}
            value={formState.newPass}
            onInput={(_, value: string) =>
              setFormState((prevState) => ({
                ...prevState,
                newPass: value
              }))
            }
          />
          <FormItem
            initialValue=""
            label="Neues Passwort (Wiederholung)"
            name="newPassConfirm"
            required
            type={FormFieldType.Obfuscated}
            value={formState.newPassConfirm}
            onInput={(_, value: string) =>
              setFormState((prevState) => ({
                ...prevState,
                newPassConfirm: value
              }))
            }
          />
        </FormGroup>

        {error && (
          <Alert color={AlertColor.Danger}>
            {Array.isArray(error) ? (
              error.map((err) => <p key={err}>{err}</p>)
            ) : (
              <p>{error}</p>
            )}
          </Alert>
        )}

        <SpinButton color="primary" spin={isChangingPass} type="submit">
          Speichern
        </SpinButton>
      </Form>
    </Portlet>
  );
}

export { ChangePasswordForm };
