import { Group } from "@mantine/core";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import type { Column, SortingRule } from "react-table";
import { Form, FormGroup, Input } from "reactstrap";
import type { ApiResponse } from "../../../../../api";
import api from "../../../../../api";
import { useSiteCategories } from "../../../../../hooks/useSiteCategories";
import urls from "../../../../../urls";
import type {
  ExtendedUser,
  TodoFile
} from "../../../../../utils/backend-types";
import { sortBackendDates } from "../../../../../utils/dates/sortBackendDates";
import { downloadCsvOrExcelFileForResponseWithContentDisposition } from "../../../../../utils/files/downloadCsvOrExcelFileForResponseWithContentDisposition";
import { splitNameExtension } from "../../../../../utils/files/splitNameExtension";
import { showToast } from "../../../../../utils/toast";
import { Checkbox } from "../../../../BuildingBlocks/Forms/Checkbox/Checkbox";
import { Icon } from "../../../../BuildingBlocks/Icon/Icon";
import { IconWithLink } from "../../../../BuildingBlocks/Icon/IconWithLink/IconWithLink";
import { IconName } from "../../../../BuildingBlocks/Icon/types";
import { getIconNameByFileExtension } from "../../../../BuildingBlocks/Icon/utils/getIconNameByFileExtension";
import { MultiConfirmationModal } from "../../../../BuildingBlocks/Layout/Modals/MultiConfirmationModal/MultiConfirmationModal";
import { Button } from "../../../../Buttons/Button/Button";
import { SpinButton } from "../../../../Buttons/SpinButton/SpinButton";
import { CustomReactSelectTable } from "../../../../CustomReactTable/CustomReactTable";
import type { TypedCellInfo } from "../../../../CustomReactTable/CustomReactTable.types";
import { useCustomReactTableCheckboxes } from "../../../../CustomReactTable/CustomReactTableHooks";
import { DownloadLink } from "../../../../DownloadLink/DownloadLink";
import { openErrorAlertPopup } from "../../../../ErrorAlertPopup/openErrorAlertPopup";
import { useShouldShowStaffView } from "../../../../StaffViewToggle/useShouldShowStaffView";
import { Mode, SYSTEM_USER_NAME } from "../../../common";
import "./DocumentsList.scss";

declare const SITE_NAME: string;

const DEFAULT_SORTED: Array<SortingRule> = [
  { id: "creationDatetime", desc: true }
];

export interface DocumentsListProps {
  documents: Array<TodoFile>;
  todoId: number;
  mode: Mode;
  documentProvided?: boolean;
  showTodoStatus?: boolean;
  onClickRename: (document: TodoFile, newName: string) => void;
  onClickDelete: (document: TodoFile) => void;
  deleteDocuments: (ids: Array<number>) => Promise<ApiResponse>;
  updateDocumentProvided: (todoId: number) => void;
  onClickNotify: (todoId: number) => Promise<ApiResponse>;
}

function DocumentsList({
  documents,
  todoId,
  mode,
  documentProvided,
  showTodoStatus,
  onClickRename,
  onClickDelete,
  deleteDocuments,
  updateDocumentProvided,
  onClickNotify
}: DocumentsListProps) {
  const { projectId } = useParams();
  const { siteCategories } = useSiteCategories(projectId || null);
  const isPartialFeedin =
    siteCategories?.some((site) => !site.is_full_feedin) || false;

  const [showRenameControls, setShowRenameControls] = useState<
    Record<number, boolean>
  >({});
  const [documentsToDelete, setDocumentsToDelete] = useState<Array<TodoFile>>(
    []
  );

  const [isNotifying, setIsNotifying] = useState<boolean>(false);

  const {
    selection,
    setSelection,
    setSelectAll,
    getSelectedData,
    customReactTableProps
  } = useCustomReactTableCheckboxes<TodoFile>();
  const shouldShowStaffView = useShouldShowStaffView();
  const canAlwaysRenameAndDelete = mode !== Mode.Normal || shouldShowStaffView;

  const toggleDeleteDocumentsModal = () => setDocumentsToDelete([]);

  function getUserNameFromValue(user: ExtendedUser) {
    let name = "";

    if (user) {
      if (user.name === SYSTEM_USER_NAME) {
        name = SITE_NAME;
      } else {
        name = user.name;
      }
    }

    return name;
  }

  function handleClickDownloadSelection() {
    const selectedDocuments = getSelectedData();
    const selectedDocumentIds = selectedDocuments.map(
      (document) => document.id
    );

    api
      .post(urls.api.todoDownloadSelectedDocuments(todoId), {
        todoFileIds: selectedDocumentIds
      })
      .then((response) =>
        downloadCsvOrExcelFileForResponseWithContentDisposition(response, true)
      )
      .catch((error) => openErrorAlertPopup(error));
  }

  function handleClickNotify() {
    setIsNotifying(true);
    onClickNotify(todoId)
      .then(() => {
        showToast("success", "Die Benachrichtigung wurde versandt!");
      })
      .finally(() => setIsNotifying(false));
  }
  function handleClickDeleteSelection() {
    const selectedDocuments = getSelectedData();

    setDocumentsToDelete(selectedDocuments);
  }

  function handleDeleteDocuments(ids: Array<number>) {
    return deleteDocuments(ids)
      .then(() => {
        setSelection([]);
        setSelectAll(false);
      })
      .catch(openErrorAlertPopup)
      .finally(() => {
        setDocumentsToDelete([]);
      });
  }

  const tableColumns: Array<Column<TodoFile>> = [
    {
      Header: "Dateiname",
      accessor: "name",
      minWidth: 405,
      Cell: (data) => (
        <DocumentNameCell
          documentId={data.original.id}
          documentName={data.original.name}
          setShowRenameControls={(show: boolean) =>
            setShowRenameControls({
              ...showRenameControls,
              [data.original.id]: show
            })
          }
          showRenameControls={!!showRenameControls[data.original.id]}
          onRename={(newName: string) => onClickRename(data.original, newName)}
        />
      )
    },
    {
      Header: "Erstellt von",
      accessor: "createdBy",
      Cell: (data: TypedCellInfo<ExtendedUser, TodoFile>) => (
        <span className="created-by">{getUserNameFromValue(data.value)}</span>
      ),
      sortMethod: function (a, b) {
        const userA = getUserNameFromValue(a);
        const userB = getUserNameFromValue(b);

        return userA < userB ? -1 : 1;
      }
    },
    {
      Header: "Erstellt am",
      accessor: "creationDatetime",
      minWidth: 126,
      sortMethod: sortBackendDates
    },
    {
      Cell: (data) => (
        <RenameDocumentCell
          canAlwaysRename={canAlwaysRenameAndDelete}
          createdBy={data.original.createdBy}
          onClickRename={() =>
            setShowRenameControls({
              ...showRenameControls,
              [data.original.id]: true
            })
          }
        />
      ),
      width: 30,
      sortable: false,
      resizable: false
    },
    {
      Cell: (data) => (
        <DeleteDocumentCell
          canAlwaysDelete={canAlwaysRenameAndDelete}
          createdBy={data.original.createdBy}
          onClickDelete={() => onClickDelete(data.original)}
        />
      ),
      width: 30,
      sortable: false,
      resizable: false
    }
  ];

  const selectionButtonsDisabled = selection.length === 0;
  const notifyButtonDisabled = documentProvided === false;
  const documentsToDeleteModalIsOpen = documentsToDelete.length > 0;
  const documentsToDeleteNames = documentsToDelete.map(
    (document) => document.name
  );
  const documentsToDeleteIds = documentsToDelete.map((document) => document.id);
  const dataText = selection.length <= 1 ? "Datei" : "Dateien";

  return (
    <div className="documents-list">
      <div
        className="controls"
        style={{
          justifyContent:
            showTodoStatus && isPartialFeedin ? "space-between" : "flex-end"
        }}
      >
        {isPartialFeedin && (
          <Group className="documents-provided">
            {showTodoStatus && (
              <div className="left-hand-controls">
                {shouldShowStaffView && (
                  <div className="document-provided-checkbox">
                    <Checkbox
                      checked={documentProvided}
                      onChange={() => updateDocumentProvided(todoId)}
                    ></Checkbox>
                  </div>
                )}
                {(shouldShowStaffView || documentProvided) && (
                  <p className="document-provided">
                    {!documentProvided && shouldShowStaffView
                      ? "Als bereitgestellt markieren"
                      : "Dokument wurde bereitgestellt"}
                  </p>
                )}
                {shouldShowStaffView && (
                  <SpinButton
                    color="brand"
                    disabled={notifyButtonDisabled}
                    spin={isNotifying}
                    onClick={() => handleClickNotify()}
                  >
                    Benachrichtigen
                  </SpinButton>
                )}
              </div>
            )}
          </Group>
        )}
        <div className="right-hand-controls">
          {canAlwaysRenameAndDelete && (
            <Button
              color="brand"
              disabled={selectionButtonsDisabled}
              onClick={handleClickDeleteSelection}
            >
              {dataText} löschen
            </Button>
          )}
          <Button
            color="brand"
            disabled={selectionButtonsDisabled}
            onClick={handleClickDownloadSelection}
          >
            {dataText} herunterladen
          </Button>
        </div>
      </div>
      <CustomReactSelectTable
        keyField="id"
        {...customReactTableProps}
        columns={tableColumns}
        data={documents}
        defaultSorted={DEFAULT_SORTED}
        minRows={0}
        NoDataComponent={NoDataComponent}
        pageSize={documents.length}
      />
      <MultiConfirmationModal
        actionName="löschen"
        actionObjects={documentsToDeleteNames}
        confirmationText="Möchten Sie die folgenden Dokumente löschen? Dieser Schritt kann nicht rückgängig gemacht werden."
        isModalOpen={documentsToDeleteModalIsOpen}
        objectName="Dokumente"
        toggleModal={toggleDeleteDocumentsModal}
        onAction={() => handleDeleteDocuments(documentsToDeleteIds)}
      />
    </div>
  );
}

function NoDataComponent() {
  return (
    <div className="no-data-component">
      <p>Keine Dokumente vorhanden.</p>
    </div>
  );
}

interface DocumentNameCellProps {
  documentId: number;
  documentName: string;
  showRenameControls: boolean;
  setShowRenameControls: (show: boolean) => void;
  onRename: (newName: string) => void;
}

function DocumentNameCell({
  documentId,
  documentName,
  showRenameControls,
  setShowRenameControls,
  onRename
}: DocumentNameCellProps) {
  const [fileName, fileExtension] = splitNameExtension(documentName);
  const fileIconName = getIconNameByFileExtension(fileExtension);

  return (
    <React.Fragment>
      {showRenameControls ? (
        <RenameControls
          nameWithExtension={documentName}
          onCancel={() => setShowRenameControls(false)}
          onRename={(newName) => {
            setShowRenameControls(false);
            onRename(newName);
          }}
        />
      ) : (
        <React.Fragment>
          <DownloadLink
            className="file-type"
            filename={`${fileName}.${fileExtension}`}
            url={urls.api.todoDocumentDownload(documentId)}
          >
            <Icon name={fileIconName} />
          </DownloadLink>
          <DownloadLink
            className="name"
            filename={`${fileName}.${fileExtension}`}
            url={urls.api.todoDocumentDownload(documentId)}
          >
            {getDocumentLabel(fileName, fileExtension)}
          </DownloadLink>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

interface RenameDocumentCellProps {
  canAlwaysRename: boolean;
  createdBy: ExtendedUser | null;
  onClickRename: () => void;
}

function RenameDocumentCell({
  canAlwaysRename,
  createdBy,
  onClickRename
}: RenameDocumentCellProps) {
  if (!canAlwaysRename && createdBy?.name === SYSTEM_USER_NAME) {
    return null;
  }

  return (
    <div className="icons">
      <IconWithLink
        name={IconName.Pencil}
        tooltipText="Dokument umbenennen"
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          onClickRename();
        }}
      />
    </div>
  );
}

interface DeleteDocumentCellProps {
  canAlwaysDelete: boolean;
  createdBy: ExtendedUser | null;
  onClickDelete: () => void;
}

function DeleteDocumentCell({
  canAlwaysDelete,
  createdBy,
  onClickDelete
}: DeleteDocumentCellProps) {
  if (!canAlwaysDelete && createdBy?.name === SYSTEM_USER_NAME) {
    return null;
  }

  return (
    <div className="icons">
      <IconWithLink
        name={IconName.Trash}
        tooltipText="Dokument löschen"
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          onClickDelete();
        }}
      />
    </div>
  );
}

interface RenameControlsProps {
  nameWithExtension: string;
  onRename: (newName: string) => void;
  onCancel: () => void;
}

function RenameControls({
  nameWithExtension,
  onRename,
  onCancel
}: RenameControlsProps) {
  const [name, extension] = splitNameExtension(nameWithExtension);
  const [inputValue, setInputValue] = useState(name);

  function handleSubmit() {
    const newName = extension ? `${inputValue}.${extension}` : inputValue;
    onRename(newName);
  }

  return (
    <Form inline onSubmit={handleSubmit}>
      <FormGroup>
        <Input
          bsSize="sm"
          className="mr-sm-2"
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <Button color="secondary" role="button" size="sm" onClick={onCancel}>
          Abbrechen
        </Button>
        <Button
          className="mr-sm-2 rename-submit-button"
          color="primary"
          size="sm"
        >
          Speichern
        </Button>
      </FormGroup>
    </Form>
  );
}

function getDocumentLabel(name: string, extension: string) {
  let extensionText = "";
  if (extension && extension.length > 0) {
    extensionText = `.${extension}`;
  }

  return `${name}${extensionText}`;
}

export { DocumentsList };
