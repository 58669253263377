import { useQuery, useQueryClient } from "@tanstack/react-query";
import classnames from "classnames";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import api from "../../api";
import { usePersons } from "../../hooks/usePersons";
import { useVariantSites } from "../../hooks/useVariantSites";
import urls from "../../urls";
import type { Contract, Delivery } from "../../utils/backend-types";
import { PageContent } from "../BuildingBlocks/Layout/PageContent/PageContent";
import { Portlet } from "../BuildingBlocks/Layout/Portlet";
import { AdvancePaymentsPlans } from "../DeliveryInvoices/AdvancePayments/AdvancePayments";
import { DeliveryInvoices } from "../DeliveryInvoices/DeliveryInvoices";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { ContractDeliveries } from "./ContractDeliveries/ContractDeliveries";

export const TABS = {
  TAB_CONTRACTS: "vertraege",
  TAB_INVOICES: "rechnungen",
  TAB_ADVANCE_PAYMENTS: "abschlaege"
} as const;

export const DEFAULT_SUBPAGE = TABS.TAB_INVOICES;

interface DeliveryOverviewProps {
  variantId: number | undefined;
  projectId: string | undefined;
}

function DeliveryOverview({ variantId, projectId }: DeliveryOverviewProps) {
  const { tab: activeTab } = useParams();
  const queryClient = useQueryClient();
  const {
    isLoading: isContractsQueryLoading,
    error: contractsQueryError,
    data: contracts
  } = useQuery({
    queryKey: ["contracts", { variantId }],
    queryFn: () => fetchContracts(variantId),
    enabled: typeof variantId !== "undefined"
  });
  const {
    isLoading: isDeliveriesQueryLoading,
    error: deliveriesQueryError,
    data: deliveries
  } = useQuery({
    queryKey: ["deliveries", { variantId }],
    queryFn: () => fetchDeliveries(variantId),
    enabled: typeof variantId !== "undefined"
  });
  const {
    isLoading: isPersonsQueryLoading,
    error: personsQueryError,
    persons
  } = usePersons(variantId);
  const {
    isLoading: isSitesQueryLoading,
    error: sitesQueryError,
    sites
  } = useVariantSites(variantId);

  async function fetchContracts(variantId: number | undefined) {
    if (typeof variantId === "undefined") {
      return undefined;
    }

    const response = await api.get<Array<Contract>>(
      urls.api.contracts(variantId)
    );
    return response.data;
  }

  async function fetchDeliveries(variantId: number | undefined) {
    if (typeof variantId === "undefined") {
      return undefined;
    }

    const response = await api.get<Array<Delivery>>(
      urls.api.deliveries(variantId)
    );
    return response.data;
  }

  function onContractsUpdated() {
    queryClient.invalidateQueries({
      queryKey: ["contracts"]
    });
  }

  function onDeliveriesUpdated() {
    queryClient.invalidateQueries({
      queryKey: ["deliveries"]
    });
  }

  return (
    <PageContent className="DeliveryOverview">
      <Portlet>
        <Nav className="tabs" pills>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === TABS.TAB_CONTRACTS
              })}
              tag={Link}
              to={`../${TABS.TAB_CONTRACTS}`}
            >
              Verträge
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === TABS.TAB_INVOICES
              })}
              tag={Link}
              to={`../${TABS.TAB_INVOICES}`}
            >
              Rechnungen
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === TABS.TAB_ADVANCE_PAYMENTS
              })}
              tag={Link}
              to={`../${TABS.TAB_ADVANCE_PAYMENTS}`}
            >
              Abschlagspläne
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={TABS.TAB_CONTRACTS}>
            <LoadOrError
              error={
                deliveriesQueryError ||
                contractsQueryError ||
                personsQueryError ||
                sitesQueryError
              }
              loading={
                isContractsQueryLoading ||
                isPersonsQueryLoading ||
                isDeliveriesQueryLoading ||
                isSitesQueryLoading
              }
            >
              {activeTab === TABS.TAB_CONTRACTS &&
                variantId &&
                contracts &&
                deliveries &&
                persons && (
                  <ContractDeliveries
                    contracts={contracts}
                    deliveries={deliveries}
                    persons={persons}
                    sites={sites}
                    variantId={variantId}
                    onContractsUpdated={onContractsUpdated}
                    onDeliveriesUpdated={onDeliveriesUpdated}
                  />
                )}
            </LoadOrError>
          </TabPane>
          <TabPane tabId={TABS.TAB_INVOICES}>
            <LoadOrError
              error={personsQueryError}
              loading={isPersonsQueryLoading}
            >
              {activeTab === TABS.TAB_INVOICES &&
                variantId &&
                contracts &&
                persons && (
                  <DeliveryInvoices
                    contracts={contracts}
                    persons={persons}
                    projectId={projectId}
                    variantId={variantId}
                  />
                )}
            </LoadOrError>
          </TabPane>
          <TabPane tabId={TABS.TAB_ADVANCE_PAYMENTS}>
            <LoadOrError
              error={personsQueryError}
              loading={isPersonsQueryLoading}
            >
              {activeTab === TABS.TAB_ADVANCE_PAYMENTS &&
                variantId &&
                contracts &&
                persons && (
                  <AdvancePaymentsPlans
                    contracts={contracts}
                    persons={persons}
                    variantId={variantId}
                  />
                )}
            </LoadOrError>
          </TabPane>
        </TabContent>
      </Portlet>
    </PageContent>
  );
}

export { DeliveryOverview };
