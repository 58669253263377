import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import urls from "../../../urls";
import type { SiteSetupProcessRaw } from "../SiteSetupAssistant.types";

export function useSiteSetupAssistantMutations(processId: string | undefined) {
  const queryClient = useQueryClient();

  const updateMutation = useMutation({
    mutationFn: (newData: SiteSetupProcessRaw) =>
      updateSite(newData, processId),
    onSuccess: (data) =>
      queryClient.setQueryData(["siteSetupAssistant", { processId }], data)
  });

  return { updateMutation };
}

async function updateSite(
  newSiteData: SiteSetupProcessRaw,
  processId: string | undefined
) {
  if (!processId) {
    return Promise.reject();
  }

  const response = await apiWithoutCamelization.post<SiteSetupProcessRaw>(
    urls.apiWithoutCamelization.siteSetupAssistant.mieterStromData(processId),
    newSiteData
  );

  return response.data;
}
