import React from "react";

import { FormFieldType } from "../FormField";
import type { InputProps } from "../Input/Input";
import { Input } from "../Input/Input";

type EmailProps = Omit<InputProps, "type">;

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function Email(props: EmailProps, ref: React.Ref<HTMLInputElement>) {
  return <Input {...props} ref={ref} type={FormFieldType.Email} />;
}

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
const ForwardedRefEmail = React.forwardRef(Email);

export { ForwardedRefEmail as Email, EmailProps };
