import { useQuery, useQueryClient } from "@tanstack/react-query";
import api, { apiWithoutCamelization } from "../../../api";
import urls from "../../../urls";
import { queryWithPollResponse } from "../../../utils/api-utils";
import type { Paragraph6CreatableCredit } from "../Paragraph6.types";

export function useParagraph6CreatableCredits(variantId: number) {
  const queryClient = useQueryClient();
  const {
    data: creatableCredits,
    isLoading,
    error
  } = useQuery({
    queryKey: ["paragraph6CreatableCredits", { variantId }],
    queryFn: () => fetchParagraph6CreatableCredits(variantId)
  });

  async function fetchParagraph6CreatableCredits(variantId: number) {
    const url =
      urls.apiWithoutCamelization.paragraph6CreatableCredits(variantId);
    const response =
      await apiWithoutCamelization.get<Array<Paragraph6CreatableCredit>>(url);

    return response.data;
  }

  async function createParagraph6Credits(
    credits: Array<Paragraph6CreatableCredit>,
    preview?: boolean
  ) {
    const creditsToCreate = preview
      ? credits.map((credit) => {
          credit.draft = true;
          return credit;
        })
      : credits;
    return queryWithPollResponse(() =>
      api.post<{
        taskStatusUrl: string;
      }>(urls.api.paragraph6CreateCredit(), creditsToCreate)
    ).then(() =>
      queryClient.invalidateQueries({
        queryKey: ["paragraph6CreatableCredits", { variantId }]
      })
    );
  }

  async function fetchParagraph6DraftCreditStatus(
    credit: Paragraph6CreatableCredit
  ) {
    const url = urls.api.paragraph6DraftCreditStatus();
    const { data } = await api.post<{ creditId: string }>(url, credit);
    return data.creditId;
  }

  return {
    creatableCredits,
    isLoading,
    error,
    createParagraph6Credits,
    fetchParagraph6DraftCreditStatus
  };
}
