import { type Todo } from "../../../utils/backend-types";

export function handleChangeOverdue(
  todos: Array<Todo>,
  updateTodoOverdue: (editedTodo: Todo) => void,
  todoId: number,
  overdue: boolean
) {
  const todo = todos.find((todo) => todo.id === todoId);

  if (todo) {
    updateTodoOverdue({
      ...todo,
      overdue: overdue
    });
  }
}
