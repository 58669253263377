import React from "react";

import { SiteName } from "../../utils/enums";

export interface FooterProps {
  siteName: SiteName;
}

const getCopyright = (siteName: SiteName) => {
  switch (siteName) {
    case SiteName.Optinode:
      return "node.energy GmbH";
    case SiteName.Smarendo:
      return "Rödl & Partner GmbH";
    case SiteName.Alva:
      return "Alva Energie GmbH";
    default:
      return "";
  }
};

function Footer({ siteName }: FooterProps) {
  return (
    <footer className="m-grid__item m-footer ">
      <div className="m-container m-container--fluid m-container--full-height m-page__container">
        <div className="m-stack m-stack--flex-tablet-and-mobile m-stack--ver m-stack--desktop">
          <div className="m-stack__item m-stack__item--left m-stack__item--middle m-stack__item--last">
            <span className="m-footer__copyright">
              {new Date().getFullYear()} &copy; {getCopyright(siteName)}
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}

export { Footer };
