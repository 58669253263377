import { Text, Flex } from "@mantine/core";
import React from "react";
import type {
  HistoryChange,
  HistoryDateBoundedValue
} from "../../../../utils/backend-types";
import type { FieldNameToLabelMap } from "../../HistoryTab";
import { DateBoundedValuesTable } from "./DateBoundedValuesTable";

interface ChangeDisplayProps {
  fieldName: string;
  change: HistoryChange;
  fieldNameToLabelMap: FieldNameToLabelMap;
}

function ChangeDisplay({
  fieldName,
  change,
  fieldNameToLabelMap
}: ChangeDisplayProps) {
  const fieldLabel = fieldNameToLabelMap[fieldName] ?? fieldName;
  if (isStringArray(change)) {
    return (
      <SimpleValueChangeDisplay
        fieldLabel={fieldLabel}
        newValue={change[1]}
        oldValue={change[0]}
      />
    );
  } else {
    return (
      <DateBoundedValueChangeDisplay
        fieldLabel={fieldLabel}
        values={change[1]}
      />
    );
  }
}

interface SimpleValueChangeDisplayProps {
  fieldLabel: string;
  oldValue: string;
  newValue: string;
}

function SimpleValueChangeDisplay({
  fieldLabel,
  oldValue,
  newValue
}: SimpleValueChangeDisplayProps) {
  function valueAsDisplayValue(value: string | Array<HistoryDateBoundedValue>) {
    /**
     * The backend stores simple changes as string representation values for simple changes
     * even though the original values have different data types. That's why we need some special logic
     */
    if (value === "" || value === "None") {
      return (
        <Text fs="italic" span>
          Leer
        </Text>
      );
    } else if (value === "True") {
      return "Ja";
    } else if (value === "False") {
      return "Nein";
    }
    return value.toString();
  }

  const displayOldValue = valueAsDisplayValue(oldValue);
  const displayNewValue = valueAsDisplayValue(newValue);

  return (
    <Text>
      {fieldLabel}: {displayOldValue} → {displayNewValue}
    </Text>
  );
}
interface DateBoundedValuesChangeDisplayProps {
  fieldLabel: string;
  values: Array<HistoryDateBoundedValue>;
}

function DateBoundedValueChangeDisplay({
  fieldLabel,
  values
}: DateBoundedValuesChangeDisplayProps) {
  return (
    <Flex align="flex-start" direction="column">
      <Text span>{fieldLabel}</Text>
      <DateBoundedValuesTable values={values} />
    </Flex>
  );
}

function isStringArray(change: HistoryChange): change is Array<string> {
  return (
    Array.isArray(change) && change.every((item) => typeof item === "string")
  );
}

export { ChangeDisplay, ChangeDisplayProps };
