import { useQuery } from "@tanstack/react-query";
import api from "../../api";
import urls from "../../urls";
import type { Todo } from "../../utils/backend-types";

function useSystemCreatedTodos(variantId: number, enabled: boolean = true) {
  const { data, error, isLoading } = useQuery({
    queryKey: ["todos", { variantId, system_created: true }],
    queryFn: () => fetchSystemCreatedTodos(variantId),
    enabled: enabled
  });

  async function fetchSystemCreatedTodos(variantId: number) {
    const response = await api.get<Array<Todo>>(
      urls.api.systemCreatedTodos(variantId)
    );
    return response.data;
  }

  return { data, error, isLoading };
}

export { useSystemCreatedTodos };
