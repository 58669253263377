import React from "react";
import type { Column, Filter } from "react-table";
import { matchFullTextCaseInsensitive } from "../../../utils/text-utils";
import { Button } from "../../Buttons/Button/Button";
import { CustomReactTable } from "../../CustomReactTable/CustomReactTable";
import type { TypedCellInfo } from "../../CustomReactTable/CustomReactTable.types";
import type { PrefillData } from "../CreateEdaVersionModal/CreateEdaVersionModal.constants";
import {
  MEDIUM,
  ORIGIN
} from "../CreateEdaVersionModal/CreateEdaVersionModal.types";
import { ClassificationTag } from "./ClassificationTag";
import "./EdaTable-v2.scss";
import { NoDataComponent } from "./EdaTableNoDataPlaceholder";
import type {
  ArrayedMBKResponse,
  Eda,
  Identity,
  MBKResponse,
  Version
} from "./Mbk";

type OnOpenViewEdaModal = (mbk: Eda) => void;

type TableColumnArgs = {
  onClickNewEdaVersion: (
    prefillData: PrefillData,
    isNewVersion?: boolean
  ) => void;
  onOpenViewEdaModal: OnOpenViewEdaModal;
};

export const tableColumns = ({
  onClickNewEdaVersion,
  onOpenViewEdaModal
}: TableColumnArgs): Column<Version & Omit<Identity, "displayName">>[] => [
  {
    Header: "Id",
    accessor: "publicId"
  },
  {
    Header: "Art der Energiemenge",
    accessor: "type",
    Aggregated: (values) => {
      return (
        <span className="tableSubTitle">
          {values.subRows[0]._original.originalVersion.identity.displayName}
        </span>
      );
    },
    Cell: (cellInfo: TypedCellInfo<string, MBKResponse>) => {
      return (
        <Button
          color="link"
          onClick={() => onOpenViewEdaModal(cellInfo.original.originalVersion)}
        >
          {cellInfo.original.originalVersion.identity.displayName}
        </Button>
      );
    }
  },
  {
    Header: "Referenzen",
    Aggregated: (values) => {
      return (
        <span className="tableSubTitle">{values.subRows[0].referenzen}</span>
      );
    },
    accessor: "referenzen",
    Cell: () => <></>
  },

  {
    Header: "Herkunft",
    accessor: "origin",
    Aggregated: () => {
      return <span />;
    },
    Cell: (cellInfo: TypedCellInfo<string, MBKResponse>) =>
      cellInfo.original?.origin ? ORIGIN[cellInfo.original?.origin] : ""
  },

  {
    Header: "Gültigkeit",
    Aggregated: () => {
      return <span />;
    },
    accessor: "timespan"
  },

  {
    Header: "Medium",
    Aggregated: () => {
      return <span />;
    },
    accessor: "medium",
    Cell: (cellInfo: TypedCellInfo<string, MBKResponse>) =>
      cellInfo.original?.medium ? MEDIUM[cellInfo.original?.medium] : ""
  },
  {
    Header: "Details",
    accessor: "details",
    Aggregated: (cellInfo) => {
      const currentFormula = getCurrentEdaVersionFormula(cellInfo.subRows);
      return <span>{currentFormula}</span>;
    }
  },

  {
    Header: "Klassifizierung",
    accessor: "tags",
    Cell: (cellInfo: TypedCellInfo<string, MBKResponse>) => {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {cellInfo.original.tags.map((tag: string) => (
            <ClassificationTag key={tag} slug={tag} />
          ))}
        </div>
      );
    },
    Aggregated: () => {
      return <span />;
    }
  },
  {
    Header: "",
    filterable: false,
    Aggregated: (row) => {
      return (
        <Button
          color="link"
          onClick={() => {
            onClickNewEdaVersion(
              {
                artDerEnergieMenge: row?.subRows[0]?.type,
                origin: row?.subRows[0]?.origin,
                frequency: row?.subRows[0]?._original?.computation?.frequency,
                medium: row?.subRows[0]?.medium,
                estimation: row?.subRows[0]?._original?.estimation,
                originalVersion: row.subRows[0]._original.originalVersion,
                components: [],
                connections: []
              },
              true
            );
          }}
        >
          + Neue Version
        </Button>
      );
    }
  }
];

function defaultFilterMethod(filter: Filter, row) {
  const shouldShowRow = !!matchFullTextCaseInsensitive(
    row?._original?.[filter.id],
    filter.value
  );
  const shouldShowSubRow =
    row?._subRows?.filter(
      (subrow) =>
        !!matchFullTextCaseInsensitive(subrow?.[filter.id], filter.value)
    ).length > 0;

  return shouldShowRow || shouldShowSubRow;
}

type EdaTableProps = {
  tableData: ArrayedMBKResponse;
  siteId: number;
} & TableColumnArgs;

function EdaTableV2({
  onClickNewEdaVersion,
  tableData,

  onOpenViewEdaModal
}: EdaTableProps) {
  return (
    <CustomReactTable
      className="eda-table"
      columns={tableColumns({
        onClickNewEdaVersion,
        onOpenViewEdaModal
      })}
      data={tableData}
      defaultFilterMethod={defaultFilterMethod}
      filterable
      NoDataComponent={NoDataComponent}
      pivotBy={["publicId"]}
      showPagination
    />
  );
}

function getCurrentEdaVersionFormula(subRows: Array<MBKResponse>) {
  const today = new Date();

  const currentFormula = subRows.reduce((formula, edaVersion) => {
    const startDate = new Date(edaVersion.timespan.split(" - ")[0]);
    const endDate = new Date(edaVersion.timespan.split(" - ")[1]);
    const versionIsCurrentVersion = startDate <= today && today <= endDate;

    return versionIsCurrentVersion ? edaVersion.details : formula;
  }, "");

  return currentFormula;
}

export { EdaTableV2 as EdaTable };
