import React, { useState } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import api, { apiWithoutCamelization } from "../../../../api";
import { useFeatureFlags } from "../../../../hooks/useFeatureFlags";
import { Flag } from "../../../../hooks/useFeatureFlags.types";
import { useSiteMutations } from "../../../../hooks/useSiteMutations";
import urls from "../../../../urls";
import { queryWithPollResponse } from "../../../../utils/api-utils";
import type { Site } from "../../../../utils/backend-types";
import { FormFieldController } from "../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { setErrorsFromResponseData } from "../../../BuildingBlocks/Forms/utils/setErrorsFromResponseData";
import { useShouldShowStaffView } from "../../../StaffViewToggle/useShouldShowStaffView";
import { applyHighlightingToObjectFormFieldData } from "../../../VariantObjectWizard/utils/applyHighlightingToObjectFormFieldData";
import { BatchImportUpload } from "./Fields/BatchImportUpload/BatchImportUpload";
import type { SiteForForm } from "./SiteForm.constants";
import { SITE_FORM_FIELD_DATA } from "./SiteForm.constants";
import { SiteFormControls } from "./SiteFormControls";

interface SiteFormProps {
  variant: number;
  site: SiteForForm | null;
  onSubmit: (site: Site) => void;
  onCancel: () => void;
  buttonContainer?: HTMLElement;
  missingFields: Array<string>;
  shouldFieldBeHighlighted: (
    fieldName: string,
    relevantFormValues: Record<string, unknown>
  ) => boolean;
}

function SiteForm({
  variant,
  site,
  onCancel,
  onSubmit,
  buttonContainer,
  missingFields,
  shouldFieldBeHighlighted
}: SiteFormProps) {
  const {
    control,
    formState: { errors },
    reset,
    resetField,
    setError,
    handleSubmit,
    watch
  } = useForm<SiteForForm>({
    defaultValues: site ?? { is_mieterstrom_site: false }
  });
  const showStaffView = useShouldShowStaffView();
  const { featureFlags } = useFeatureFlags();
  const isSiteSetupAssistantEnabled = featureFlags[Flag.siteSetupAssistant];
  const { formFieldData } = applyHighlightingToObjectFormFieldData(
    SITE_FORM_FIELD_DATA,
    watch,
    site
      ? {
          wizardObject: site,
          fieldsThatCouldBeMissing: missingFields,
          shouldFieldBeHighlighted: shouldFieldBeHighlighted
        }
      : undefined
  );
  const { updateMutation } = useSiteMutations(site?.id);
  const { t } = useTranslation();
  const [templateHasErrors, setTemplateHasErrors] = useState(false);

  const isCreateMode = site === null;
  const isMieterstromSite = watch("is_mieterstrom_site");
  const watchTemplate = watch("template");
  const template = watchTemplate ? watchTemplate[0] : undefined;
  const showAddressFields = !(isCreateMode && isSiteSetupAssistantEnabled);

  const onValidSubmit: SubmitHandler<SiteForForm> = async (data) => {
    try {
      if (site) {
        const response = await updateMutation.mutateAsync(data);
        return onSubmit(response.data);
      } else if (site === null && !data.is_mieterstrom_site) {
        const response = await apiWithoutCamelization.post<SiteForForm>(
          urls.apiWithoutCamelization.sitesList(variant),
          {
            ...data,
            variant: variant
          }
        );
        return onSubmit(response.data);
      } else {
        const response = await submitTemplateCreation(data);

        if (isSite(response.data)) {
          return onSubmit(response.data);
        } else {
          setErrorsFromResponseData<Site>(
            { response },
            watch(),
            setError,
            t("errors.UnknownError")
          );
        }
      }
    } catch (error) {
      setErrorsFromResponseData<Site>(
        error,
        watch(),
        setError,
        t("errors.UnknownError")
      );
    }
  };

  async function submitTemplateCreation(data: SiteForForm) {
    const formData = new FormData();
    const cleanedData = { variant: variant };
    for (const key in data) {
      if (data[key] !== undefined) {
        cleanedData[key] = data[key];
      }
    }
    formData["formDataObject"] = cleanedData;

    return queryWithPollResponse<Site>(
      () =>
        api.post<SiteForForm>(
          urls.api.mieterstromBatchImport.dipatchSiteCreation(),
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        ),
      {
        queryAgainOnPollSuccess: true,
        queryAgainOnPollSuccessWithTaskId: true,
        queryAgainFn: (taskId) =>
          api.get(urls.api.mieterstromBatchImport.fetchSite(taskId))
      }
    );
  }

  function isSite(data: Site | undefined): data is Site {
    return (data as Site).id !== undefined;
  }

  const submitCallback = handleSubmit(onValidSubmit);

  function handleClickCancel() {
    reset();
    onCancel();
  }

  return (
    <form>
      {showStaffView && !isCreateMode && (
        <FormFieldController
          control={control}
          data={formFieldData.global_id}
          error={errors.global_id}
        />
      )}
      <FormFieldController
        control={control}
        data={formFieldData.name}
        error={errors.name}
      />
      {showAddressFields && (
        <>
          <FormFieldController
            control={control}
            data={formFieldData.address}
            error={errors.address}
          />
          <FormFieldController
            control={control}
            data={formFieldData.zip}
            error={errors.zip}
          />
          <FormFieldController
            control={control}
            data={formFieldData.city}
            error={errors.city}
          />
        </>
      )}
      {showStaffView && isCreateMode && (
        <FormFieldController
          control={control}
          data={formFieldData.is_mieterstrom_site}
          error={errors.is_mieterstrom_site}
        />
      )}
      {showStaffView && isCreateMode && isMieterstromSite && (
        <BatchImportUpload
          control={control}
          data={formFieldData.template}
          error={errors.template ? errors.template[0] : undefined}
          template={template}
          onReset={() => resetField("template")}
          onTemplateHasErrors={setTemplateHasErrors}
        />
      )}
      <SiteFormControls
        askForConfirmation={templateHasErrors}
        buttonContainer={buttonContainer}
        onCancel={handleClickCancel}
        onSubmit={submitCallback}
      />
    </form>
  );
}

export { SiteForm, SiteFormProps };
