import type { CurrentUser, SiteCategories } from "../../../utils/backend-types";
import { loadScript } from "../../../utils/loadScript";

/** Loads Userlane script, initializes Userlane, and identifies user. */
export async function loadUserlaneTracker(
  currentUser: CurrentUser,
  siteCategories: SiteCategories
) {
  await loadScript("https://cdn.userlane.com/userlane.js");

  window.Userlane("identify", currentUser.id, {
    user_type: currentUser.is_staff ? "internal" : "external",
    ee_plus: siteCategories.is_eigenerzeugung_plus ? "yes" : "no",
    premium: siteCategories.is_premium ? "yes" : "no",
    partial_feedin_site: siteCategories.is_partial_feedin_site ? "yes" : "no"
  });
  window.Userlane("init", currentUser.userlane_id);
}
