import React, { useState } from "react";
import ReactMarkdown from "react-markdown";

import {
  Modal,
  ModalBody,
  ModalHeader
} from "../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../Buttons/Button/Button";

export interface ErrorAlertModalProps {
  errorText: string | Array<string>;
  onClose: () => void;
}

/**
 * The `ErrorAlertModal` is used to display error messages to the user. It's not intended to be rendered directly.
 * Rather, it will be created and attached to the DOM using the function `openErrorAlertPopup`, typically in a `catch` block.
 *
 * For example when handling failed API calls, you can just do:
 *
 * ```
 * api
 *  .get("api/some/url")
 *  .then(() => {
 *    // do something
 *   })
 *  .catch(openErrorAlertPopup);
 * ```
 *
 * This will try it's best to display a meaningful error message to the user based on the response from the API.
 * See `getErrorMessage` for more details.
 *
 * You can also specify the error message based on the status code of the response or pass a `onClose` callback.
 * See this example:
 *
 * ```
 * api
 *  .get("api/some/url")
 *  .then(() => {
 *    // do something
 *   })
 *  .catch(
 *    (error) => openErrorAlertPopup(
 *      error,
 *      {
 *        404: "The resource you are looking for does not exist",
 *        500: "Something went wrong on our end. Please try again later",
 *      },
 *      () => navigate("some-url", { replace: true })
 *    )
 *  );
 * ```
 */
export function ErrorAlertPopup({ errorText, onClose }: ErrorAlertModalProps) {
  const [isOpen, setIsOpen] = useState(true);

  function handleClose() {
    setIsOpen(false);
    onClose();
  }

  const errorDisplayText = Array.isArray(errorText) ? (
    errorText.map((error) => <ReactMarkdown key={error}>{error}</ReactMarkdown>)
  ) : (
    <ReactMarkdown>{errorText}</ReactMarkdown>
  );

  return (
    <Modal isOpen={isOpen} toggle={handleClose}>
      <ModalHeader toggle={handleClose}>
        Es ist ein Fehler aufgetreten.
      </ModalHeader>
      <ModalBody scrollable>
        <div className="error-display-text">{errorDisplayText}</div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            className="error-alert-modal-close-button"
            color="primary"
            style={{ marginTop: "15px" }}
            onClick={handleClose}
          >
            Schließen
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}
