import api from "../../../../api";
import urls from "../../../../urls";
import type { EnergyDataImportRequest } from "../../EnergyDataUploadFlow/EnergyDataUpload";

function useDataImportRequests() {
  const removeEnergyDataImportRequests = (
    removedDataImportRequests: Array<EnergyDataImportRequest>
  ) => {
    return Promise.all(
      removedDataImportRequests.map((removedDataImportRequest) =>
        api.delete(
          urls.api.energyDataUploadImportRequest(removedDataImportRequest.id)
        )
      )
    );
  };

  return {
    removeEnergyDataImportRequests
  };
}

export { useDataImportRequests };
