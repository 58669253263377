import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { Column, SortingRule } from "react-table";
import type { ApiResponse } from "../../../../../api";
import { usePersons } from "../../../../../hooks/usePersons";
import { useVariantGenerators } from "../../../../../hooks/useVariantGenerators";
import { sortBackendDates } from "../../../../../utils/dates/sortBackendDates";
import { findIndexForIdInPrefixedFormValuesArray } from "../../../../../utils/findIndexForIdInPrefixedFormValuesArray";
import { isIdLocal } from "../../../../../utils/isIdLocal";
import { interactionSleep } from "../../../../../utils/storybook/interactionSleep";
import { setErrorsFromResponseData } from "../../../../BuildingBlocks/Forms/utils/setErrorsFromResponseData";
import { LoadOrError } from "../../../../LoadOrError/LoadOrError";
import type { RowState } from "../../../../SwissArmyTable/SwissArmyTable";
import { SwissArmyTable } from "../../../../SwissArmyTable/SwissArmyTable";
import {
  convertFictionalEnergyValuesToReactHookFormValues,
  convertFictionalEnergyValueToReactHookFormValues
} from "../../utils/convertFictionalEnergyValuesToReactHookFormValues";
import { filterFictionalEnergyValueResponseBySearchString } from "../../utils/filterFictionalEnergyValueResponseBySearchString";
import { isFictionalEnergyValueResponseUpdatedFormValues } from "../../utils/isFictionalEnergyValueResponseUpdatedFormValues";
import { mergeCurrentFormValuesWithFictionalEnergyValueResponse } from "../../utils/mergeCurrentFormValuesWithFictionalEnergyValueResponse";
import type { FictionalEnergyValueResponse } from "../FictionalEnergyValueManager/FictionalEnergyValueManager";
import { getFirstDateColumn } from "./Columns/getFirstDateColumn";
import { getGeneratorColumn } from "./Columns/getGeneratorColumn";
import { getLastDateColumn } from "./Columns/getLastDateColumn";
import { getRedispatchAbregelungColumn } from "./Columns/getRedispatchAbregelungColumn";
import { getSonstigeAbschaltungOderDrosselungColumn } from "./Columns/getSonstigeAbschaltungOderDrosselungColumn";
import { getSumColumn } from "./Columns/getSumColumn";
import { getTechnischeNichtverfuegbarkeitColumn } from "./Columns/getTechnischeNichtverfuegbarkeitColumn";
import "./FictionalEnergyValuesTable.scss";

const DEFAULT_SORTED: Array<SortingRule> = [
  {
    id: "created",
    desc: true
  }
];

interface FictionalEnergyValuesTableProps {
  fictionalEnergyValues: Array<FictionalEnergyValueResponse>;
  variantId: number;
  createNewFictionalEnergyValue: () => FictionalEnergyValueResponse;
  onDelete: (rangeId: string) => Promise<void>;
  onSubmit: (
    rangeId: string,
    updatedRange: FictionalEnergyValueResponse
  ) => Promise<ApiResponse>;
}

function FictionalEnergyValuesTable({
  fictionalEnergyValues,
  variantId,
  createNewFictionalEnergyValue,
  onDelete,
  onSubmit
}: FictionalEnergyValuesTableProps) {
  const { t } = useTranslation();

  const {
    data: generators,
    isLoading: generatorsLoading,
    error
  } = useVariantGenerators(variantId);
  const { paragraph6Persons, isLoading: personsLoading } =
    usePersons(variantId);

  const {
    control,
    formState: { errors },
    reset,
    setError,
    setValue,
    trigger,
    watch
  } = useForm<Array<FictionalEnergyValueResponse>>({
    defaultValues: convertFictionalEnergyValuesToReactHookFormValues(
      fictionalEnergyValues
    )
  });
  const [rowStates, setRowStates] = useState<Record<string, RowState>>({});

  useEffect(
    function updateFormValues() {
      // note: react-hook-form is completely wrong on the type of `watch` here,
      // and thinks it's Array<FictionalEnergyValueResponse>
      // it's actually a dictionary of values with the format ${number}.fieldName
      // and also combined with indexes corresponding to the original array
      // with FictionalEnergyValueResponse which represent all changes to the form
      const currentFormValues = watch() as unknown as Record<string, unknown>;

      reset(
        mergeCurrentFormValuesWithFictionalEnergyValueResponse(
          currentFormValues,
          fictionalEnergyValues
        )
      );
    },
    [fictionalEnergyValues, reset, watch]
  );

  const tableColumns = useMemo<
    Array<Column<FictionalEnergyValueResponse>>
  >(() => {
    const paragraph6Generators =
      generators?.filter(
        (generator) =>
          paragraph6Persons.filter((person) => person.id === generator.person)
            .length > 0
      ) ?? [];

    return [
      {
        // in order for React Table v6 to apply sorting of a value, a column with that value must exist
        // we hide this via CSS because we don't want to actually show the created date though
        // more info at: https://github.com/TanStack/table/issues/1104
        accessor: (row) => row.created,
        id: "created",
        Cell: (cellInfo) => cellInfo.value
      },
      getGeneratorColumn(
        { rowStates, control, errors, t },
        paragraph6Generators
      ),
      getFirstDateColumn({ rowStates, control, errors, t }),
      getLastDateColumn({ rowStates, control, errors, t }),
      getTechnischeNichtverfuegbarkeitColumn({ rowStates, control, errors, t }),
      getRedispatchAbregelungColumn({ rowStates, control, errors, t }),
      getSonstigeAbschaltungOderDrosselungColumn({
        rowStates,
        control,
        errors,
        t
      }),
      getSumColumn({ rowStates })
    ];
  }, [control, errors, generators, paragraph6Persons, rowStates, t]);

  function setRowState(id: string, newState: RowState) {
    setRowStates((oldRows) => ({
      ...oldRows,
      [id]: newState
    }));
  }

  function handleClickCreateNewFictionalEnergyValue() {
    const newFictionalEnergyValue = createNewFictionalEnergyValue();
    setRowState(newFictionalEnergyValue.id, "edit");
  }

  function handleFormKeyDown(e: React.KeyboardEvent<HTMLFormElement>) {
    if ((e.target as HTMLInputElement).name) {
      const fieldName = (e.target as HTMLInputElement).name;
      const rowNumber = fieldName.split(".")[0];
      const fictionalEnergyValue =
        fictionalEnergyValues[parseInt(rowNumber, 10)];
      const rowId = fictionalEnergyValue.id;

      if (e.key === "Enter") {
        submitRow(rowId);
      } else if (e.key === "Escape") {
        revertRow(fictionalEnergyValue.id);
      }
    }
  }

  async function submitRow(rowId: string) {
    const formValues = watch() as unknown as Record<string, unknown>;
    const rowIndexString = findIndexForIdInPrefixedFormValuesArray(
      rowId,
      formValues
    );

    if (!rowIndexString) {
      console.error("Could not find row index for rowId", rowId);
      return;
    }

    const rowIndex = parseInt(rowIndexString);
    const success = await trigger([
      `${rowIndex}.generator`,
      `${rowIndex}.firstDate`,
      `${rowIndex}.lastDate`,
      `${rowIndex}.technischeNichtverfuegbarkeitKwh`,
      `${rowIndex}.redispatchAbregelungKwh`,
      `${rowIndex}.sonstigeAbschaltungOderDrosselungKwh`
    ]);

    if (!success) {
      return;
    }

    const rowValues = formValues[rowIndex];

    if (!isFictionalEnergyValueResponseUpdatedFormValues(rowValues)) {
      console.error(
        `Unknown error while submitting FictionalEnergyValuesTable for rowIndex ${rowIndex}`,
        rowValues
      );

      return;
    }

    try {
      setRowState(rowId, "loading");
      await onSubmit(rowId, rowValues);
    } catch (error) {
      // TODO: for some reason, there is a rerender of the form/row after a very slight delay,
      // this causes the `setErrorsFromResponseData` to be overwritten again, making the error info unreadable
      // the following explicit delay temporarily fixes this,
      // but obviously we should get to the root of this problem and fix it there
      await interactionSleep(200);

      setErrorsFromResponseData<Array<FictionalEnergyValueResponse>>(
        error,
        watch(),
        setError,
        t("errors.UnknownError"),
        `${rowIndex}.`
      );

      setRowState(rowId, "edit");

      return;
    }

    if (isIdLocal(rowId)) {
      onDelete(rowId);
    } else {
      setRowState(rowId, "view");
    }
  }

  function revertRow(fictionalEnergyValueId: string) {
    const shouldBeDeletedInstead = isIdLocal(fictionalEnergyValueId);

    if (shouldBeDeletedInstead) {
      onDelete(fictionalEnergyValueId);
      return;
    }

    const rowId = findIndexForIdInPrefixedFormValuesArray(
      fictionalEnergyValueId,
      watch() as unknown as Record<string, unknown>
    );
    const fictionalEnergyValue = fictionalEnergyValues.find(
      (range) => range.id === fictionalEnergyValueId
    );

    if (rowId && fictionalEnergyValue) {
      const formValues = convertFictionalEnergyValueToReactHookFormValues(
        fictionalEnergyValue,
        rowId
      );

      Object.keys(formValues).forEach((key) => {
        // mk: HACK for complex react-hook-form values (this code is destined to be deleted soon)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setValue(key as any, formValues[key] as any);
      });
    }

    setRowState(fictionalEnergyValueId, "view");
  }

  return (
    <LoadOrError
      error={error}
      loading={generatorsLoading || personsLoading}
      loadingMessage="Erzeugerdaten werden geladen ..."
    >
      <form
        className="FictionalEnergyValuesTableForm"
        onKeyDown={handleFormKeyDown}
      >
        <SwissArmyTable
          className="FictionalEnergyValuesTable"
          data={fictionalEnergyValues}
          defaultSorted={DEFAULT_SORTED}
          defaultSortMethod={sortBackendDates}
          options={{
            buttons: {
              create: {
                iconButton: true,
                isPrimary: false,
                isTertiary: false,
                onClick: handleClickCreateNewFictionalEnergyValue
              },
              delete: {
                iconButton: true
              },
              edit: {
                rowStates: rowStates,
                onClickInline: (rowData) => setRowState(rowData.id, "edit"),
                onClickCancel: (rowData) => revertRow(rowData.id),
                onClickSave: (rowData) => submitRow(rowData.id)
              }
            },
            CustomNoDataComponent: NoDataComponent,
            deletion: {
              multiDelete: true,
              namesToDeleteCollectionFunction: (items) => {
                return items.map((item) => {
                  return item.firstDate
                    ? `${item.generatorDisplayName}: ${item.firstDate} – ${item.lastDate}`
                    : "Neue fiktive Strommengen";
                });
              },
              onClick: onDelete
            },
            search: {
              placeholder: "Suche nach fiktiven Strommengen",
              show: true,
              filterFunction: (searchString, ranges) => () =>
                filterFictionalEnergyValueResponseBySearchString(
                  searchString,
                  ranges
                )
            },
            singleWord: "Fiktive Strommenge",
            pluralWord: "Fiktive Strommengen"
          }}
          tableColumns={tableColumns}
        />
      </form>
    </LoadOrError>
  );
}

function NoDataComponent() {
  return (
    <div className="no-data-component">
      <p>Es gibt noch keine fiktiven Strommengen.</p>
    </div>
  );
}

export { FictionalEnergyValuesTable, FictionalEnergyValuesTableProps };
