import React from "react";
import type { Column } from "react-table";

import type { MeteringServiceProvider } from "../../../utils/backend-types";
import { MspCategory, MspImportFrequency } from "../../../utils/enums";
import {
  EditSaveCell,
  CellType
} from "../../CustomReactTable/Cells/EditSaveCell";
import { CustomReactTable } from "../../CustomReactTable/CustomReactTable";
import { LoadOrError } from "../../LoadOrError/LoadOrError";

import { getMspCategoryLabel } from "../utils/getMspCategoryLabel";
import { getMspImportFrequencyLabel } from "../utils/getMspImportFrequencyLabel";
import { HistoryCell } from "./HistoryCell/HistoryCell";
import { useMeteringServiceProvider } from "./hooks/useMeteringServiceProvider";
import { meteringServiceProviderFieldUpdate } from "./utils/meteringServiceProviderFieldUpdate";
import "./MeteringServiceProviderTable.scss";

const DEFAULT_SORTED = [
  {
    id: "name",
    desc: false
  }
];

const categoryChoices = Object.values(MspCategory).map((value) => ({
  displayName: getMspCategoryLabel(value),
  value: value
}));

const importFrequencyChoices = Object.values(MspImportFrequency).map(
  (value) => ({
    displayName: getMspImportFrequencyLabel(value),
    value: value
  })
);

const METERING_SERVICE_PROVIDER_FIELD_LABELS = {
  id: "ID",
  name: "Name",
  marktpartneridentifikationsnummer: "MP-ID",
  category: "Kategorie",
  medium: "Messstellenmedium",
  importFrequency: "Versandfrequenz",
  email: "E-Mail",
  notes: "Notizen"
} as const satisfies { [key in keyof MeteringServiceProvider]: string };

function MeteringServiceProviderTable() {
  const {
    meteringServiceData,
    isMeteringServiceLoading,
    meteringServiceError
  } = useMeteringServiceProvider();

  const columns: Array<Column<MeteringServiceProvider>> = [
    {
      Header: METERING_SERVICE_PROVIDER_FIELD_LABELS["name"],
      accessor: "name"
    },
    {
      Header:
        METERING_SERVICE_PROVIDER_FIELD_LABELS[
          "marktpartneridentifikationsnummer"
        ],
      accessor: "marktpartneridentifikationsnummer",
      width: 160
    },
    {
      Header: METERING_SERVICE_PROVIDER_FIELD_LABELS["category"],
      accessor: "category",
      width: 240,
      Cell: (cellData) => {
        return (
          <EditSaveCell
            cellType={CellType.Dropdown}
            choices={categoryChoices}
            getLabel={getMspCategoryLabel}
            value={cellData.value}
            onChange={(newValue) =>
              meteringServiceProviderFieldUpdate(
                "category",
                newValue as MeteringServiceProvider["category"],
                cellData.original.id
              )
            }
          />
        );
      }
    },
    {
      Header: METERING_SERVICE_PROVIDER_FIELD_LABELS["medium"],
      accessor: "medium",
      width: 120,
      Cell: (cellData) => (
        <span>{cellData.value ? cellData.value : "Unbekannt"}</span>
      )
    },
    {
      Header: METERING_SERVICE_PROVIDER_FIELD_LABELS["importFrequency"],
      accessor: "importFrequency",
      width: 140,
      Cell: (cellData) => {
        return (
          <EditSaveCell
            cellType={CellType.Dropdown}
            choices={importFrequencyChoices}
            getLabel={getMspImportFrequencyLabel}
            value={cellData.value}
            onChange={(newValue) =>
              meteringServiceProviderFieldUpdate(
                "importFrequency",
                newValue as MeteringServiceProvider["importFrequency"],
                cellData.original.id
              )
            }
          />
        );
      }
    },
    {
      Header: METERING_SERVICE_PROVIDER_FIELD_LABELS["email"],
      accessor: "email",
      Cell: (cellData) => {
        return (
          <EditSaveCell
            cellType={CellType.Input}
            placeholder={!cellData.value ? "z.B: example@email.com" : undefined}
            value={cellData.value || ""}
            onChange={(newValue) =>
              meteringServiceProviderFieldUpdate(
                "email",
                newValue.toString(),
                cellData.original.id
              )
            }
          />
        );
      }
    },
    {
      Header: METERING_SERVICE_PROVIDER_FIELD_LABELS["notes"],
      accessor: "notes",
      Cell: (cellData) => (
        <EditSaveCell
          cellType={CellType.Input}
          placeholder={!cellData.value ? "Notiz eingeben" : undefined}
          value={cellData.value || ""}
          onChange={(newValue) =>
            meteringServiceProviderFieldUpdate(
              "notes",
              newValue.toString(),
              cellData.original.id
            )
          }
        />
      )
    },
    {
      Header: "Historie",
      accessor: "id",
      width: 100,
      Cell: (cellData) => <HistoryCell id={cellData.value} />
    }
  ];

  return (
    <LoadOrError
      error={meteringServiceError}
      loading={isMeteringServiceLoading}
    >
      <div className="MeteringServiceProviderTableWrapper">
        <CustomReactTable
          columns={columns}
          data={meteringServiceData}
          defaultSorted={DEFAULT_SORTED}
          minRows={0}
          pageSize={10}
          showPageJump
          showPagination
        />
      </div>
    </LoadOrError>
  );
}

export { MeteringServiceProviderTable, METERING_SERVICE_PROVIDER_FIELD_LABELS };
