import type { GeneratorDataStatus } from "../../../utils/backend-types";

export function getGeneratorStatusHeaderText(
  status: GeneratorDataStatus | null
) {
  switch (status) {
    case "overwriteSuccess":
      return "Daten wurden erfolgreich überschrieben";
    case "overwriteFail":
      return "Automatisches Überschreiben ist nicht möglich";
    case "getPossibleChangesFail":
      return "Abrufen möglicher Änderungsdaten fehlgeschlagen";
    default:
      return "";
  }
}
