import { useQuery } from "@tanstack/react-query";

import api from "../../api";
import urls from "../../urls";

interface LoginConfiguration {
  microsoft: boolean;
  roedl: boolean;
}

function useSsoConfiguration() {
  const {
    isLoading,
    error,
    data: ssoConfig
  } = useQuery({
    queryKey: ["login-configuration"],
    queryFn: () => fetchLoginConfiguration(),
    staleTime: 10 * 60 * 1000 // 10 min
  });

  const fetchLoginConfiguration = async () => {
    const response = await api.publicRequest<LoginConfiguration>(
      "GET",
      urls.api.ssoConfig()
    );

    return response.data;
  };

  return { isLoading, error, ssoConfig };
}

export { useSsoConfiguration };
