import PropTypes from "prop-types";
import React, { PureComponent, useState } from "react";
import { Collapse, ListGroup, ListGroupItem } from "reactstrap";
import api from "../../../api";
import urls from "../../../urls";
import { copyToClipboard } from "../../../utils/copyToClipboard";
import { Alert, AlertColor } from "../../Alert/Alert";
import { ErrorAlert } from "../../BuildingBlocks/ErrorAlert/ErrorAlert";
import { Icon } from "../../BuildingBlocks/Icon/Icon";
import { IconWithLink } from "../../BuildingBlocks/Icon/IconWithLink/IconWithLink";
import { IconName } from "../../BuildingBlocks/Icon/types";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../../Buttons/Button/Button";
import loader from "../../Loader";
import "./TodoCalendarIntegration.scss";

function TodoCalendarIntegration({ variantId }) {
  const [isModalOpen, setModelOpen] = useState(false);
  const toggleModal = () => setModelOpen(!isModalOpen);

  return (
    <Button variant="outline" onClick={toggleModal}>
      Kalenderintegration
      <TodoCalendarIntegrationModal
        isModalOpen={isModalOpen}
        variantId={variantId}
        onToggleModal={toggleModal}
      />
    </Button>
  );
}

function TodoCalendarIntegrationModal({
  onToggleModal,
  isModalOpen,
  variantId
}) {
  return (
    <Modal
      className="TodoCalendarIntegrationModal"
      isOpen={isModalOpen}
      size="lg"
      toggle={onToggleModal}
    >
      <ModalHeader toggle={onToggleModal}>Kalenderintegration</ModalHeader>
      <ModalBody scrollable>
        <p>
          Sie haben die Möglichkeit durch einen Link die Aufgaben direkt mit
          Ihrem Kalender (Outlook, Google Calendar, etc.) zu synchronisieren.
          Dabei werden nur Aufgaben mit Fälligkeitsdatum berücksichtigt.
        </p>
        <Alert className="custom-alert" color={AlertColor.Warning}>
          Wir empfehlen Ihnen den Link genauso vertraulich zu behandeln wie Ihr
          Passwort, denn jede Person mit Kenntnis des Links hat auch Zugriff auf
          die Kalendersynchronisation.
        </Alert>
        <TodoCalendarIntegrationControllerWithLoader
          dataUrls={{
            calendarIntegrations: urls.api.todoCalendarIntegrations(variantId)
          }}
          variantId={variantId}
        />
        <div className="mt-3">
          <TodoCalendarIntegrationTutorial />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" variant="outline" onClick={onToggleModal}>
          Schließen
        </Button>
      </ModalFooter>
    </Modal>
  );
}

TodoCalendarIntegration.propTypes = {
  variantId: PropTypes.number.isRequired
};

class TodoCalendarIntegrationController extends PureComponent {
  createEntry = async () => {
    await api.post(urls.api.todoCalendarIntegrations(), {
      variant: this.props.variantId
    });
    this.props.onReload();
  };

  deleteEntry = async (calendarIntegrationId) => {
    await api.delete(
      urls.api.todoCalendarIntegrationDetail(calendarIntegrationId)
    );
    this.props.onReload();
  };

  refreshEntry = async (calendarIntegrationId) => {
    await api.post(
      urls.api.todoCalendarIntegrationRefresh(calendarIntegrationId)
    );
    this.props.onReload();
  };

  render() {
    const calendarIntegrations = this.props.data.calendarIntegrations;

    if (!calendarIntegrations) {
      return <ErrorAlert error="Generic error" />;
    }

    if (calendarIntegrations.length === 0) {
      return (
        <ConfirmTodoCalendarIntegrationCreate onCreate={this.createEntry} />
      );
    } else {
      return (
        <TodoCalendarIntegrationEntry
          calendarIntegration={calendarIntegrations[0]}
          onDelete={this.deleteEntry}
          onRefresh={this.refreshEntry}
        />
      );
    }
  }
}

const TodoCalendarIntegrationControllerWithLoader = loader(
  TodoCalendarIntegrationController
);

function TodoCalendarIntegrationTutorial() {
  const [isTutorialOpen, setTutorialOpen] = useState(false);
  const toggleTutorial = () => setTutorialOpen(!isTutorialOpen);
  const caret = (
    <Icon name={isTutorialOpen ? IconName.CaretUp : IconName.CaretDown} />
  );
  return (
    <div className="TodoCalendarIntegrationTutorial">
      <a href="#" onClick={toggleTutorial}>
        {caret} Anleitung zur Einrichtung im Kalender
      </a>
      <Collapse isOpen={isTutorialOpen}>
        <ul className="mt-2">
          <li>
            <a
              href="https://support.office.com/de-de/article/video-importieren-von-kalendern-8e8364e1-400e-4c0f-a573-fe76b5a2d379"
              rel="noreferrer"
              target="_blank"
            >
              Office 365
            </a>
            : siehe &quot;Hinzufügen von Internetkalendern&quot;
          </li>
          <li>
            <a
              href="https://support.office.com/de-de/article/importieren-oder-abonnieren-eines-kalenders-in-outlook-com-cff1429c-5af6-41ec-a5b4-74f2c278e98c"
              rel="noreferrer"
              target="_blank"
            >
              Outlook.com
            </a>
            : siehe &quot;Abonnieren eines Kalenders&quot;
          </li>
          <li>
            Outlook: In der Offline-Applikation von Outlook ist es nicht möglich
            den Kalender zu importieren. Wenn Sie den Import in Office 365 bzw.
            Outlook.com einrichten, wird es aber dennoch mit der
            Offline-Applikation synchronisiert.
          </li>
          <li>
            <a
              href="https://support.google.com/calendar/answer/37648?hl=de"
              rel="noreferrer"
              target="_blank"
            >
              Google Calendar
            </a>
            : siehe &quot;Kalender abrufen&quot;
          </li>
          <li>
            <a
              href="https://support.apple.com/de-de/HT202361"
              rel="noreferrer"
              target="_blank"
            >
              iCal
            </a>
            : siehe &quot;Ein neues iCloud-Kalenderabonnement auf dem Mac
            einrichten&quot;
          </li>
          <li>
            Für weitere Kalenderapplikationen wenden Sie sich bitte an Ihren
            IT-Support
          </li>
        </ul>
      </Collapse>
    </div>
  );
}

function ConfirmTodoCalendarIntegrationCreate({ onCreate }) {
  return (
    <div>
      <p>
        Mit dem Klick auf „Link erstellen“ bestätigen Sie, dass Sie über die
        Sicherheitsimplikationen informiert wurden. Nach dem Erstellen können
        Sie den Link auch aktualisieren oder wieder löschen.
      </p>
      <Button onClick={onCreate}>Link erstellen</Button>
    </div>
  );
}

function TodoCalendarIntegrationEntry({
  calendarIntegration,
  onDelete,
  onRefresh
}) {
  const [linkCopied, setLinkCopied] = useState(false);
  return (
    <div className="TodoCalendarIntegrationEntry my-3">
      <ListGroup>
        <ListGroupItem>
          <div className="todo-calendar-container">
            <div className="todo-calendar-url">
              {calendarIntegration.calendarUrl}
            </div>
            <div className="todo-calendar-icons">
              <IconWithLink
                className="copy-icon"
                name={IconName.Clipboard}
                tooltipText={
                  linkCopied ? "Link wurde kopiert" : "Link kopieren"
                }
                onClick={() => {
                  copyToClipboard(calendarIntegration.calendarUrl);
                  setLinkCopied(true);
                }}
                onMouseOut={() => setLinkCopied(false)}
              />
              <IconWithLink
                className="refresh-icon"
                name={IconName.Refresh}
                tooltipText="Link erneuern"
                onClick={() => onRefresh(calendarIntegration.id)}
              />
              <IconWithLink
                className="delete-icon"
                name={IconName.Trash}
                tooltipText="Link löschen"
                onClick={() => onDelete(calendarIntegration.id)}
              />
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    </div>
  );
}

export { TodoCalendarIntegration };
