import classNames from "classnames";
import React from "react";

import { Button } from "../Button/Button";
import type { ButtonProps } from "../Button/Button";

type SquareButtonProps = ButtonProps;

function SquareButton({ className, ...other }: SquareButtonProps) {
  return (
    <Button className={classNames("m-btn--square", className)} {...other} />
  );
}

export { SquareButton, SquareButtonProps };
