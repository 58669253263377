import { Extension } from "@tiptap/core";
import { Fragment, type Node, Slice } from "@tiptap/pm/model";
import { Plugin, PluginKey } from "@tiptap/pm/state";

// change \u2028 line separators into RTE-conform line breaks (edge case)
function splitUnicodeLineBreaks(node: Node) {
  const lineNodes: Array<Node> = [];

  const textLines = node.text?.split("\u2028");
  textLines?.forEach((textLine, lineIndex) => {
    if (textLine) {
      lineNodes.push(node.type.schema.text(textLine));
    }
    if (lineIndex < textLines.length - 1) {
      lineNodes.push(node.type.schema.node("hardBreak"));
    }
  });

  return lineNodes;
}

function addVariablesToLine(line: Node, allowedVariables: Array<string>) {
  const nodes: Array<Node> = [];

  if (allowedVariables.length === 0 || !line.text) {
    return [line];
  }

  const variablesRegex = new RegExp(
    "\\b" + allowedVariables.join("\\b|\\b") + "\\b",
    "g"
  );
  let pos = 0;
  let match = variablesRegex.exec(line.text);
  while (match) {
    const start = match.index;
    const end = start + match[0].length;

    if (start > 0) {
      nodes.push(line.cut(pos, start));
    }

    const variableName = line.text.slice(start, end);
    line.cut(start, end);
    nodes.push(
      line.type.schema.node("mention", { id: variableName, label: null })
    );
    pos = end;

    match = variablesRegex.exec(line.text);
  }

  if (pos < line.text.length) {
    nodes.push(line.cut(pos));
  }

  return nodes;
}

function paragraphCollect(fragment: Fragment, allowedVariables: Array<string>) {
  const collectedNodes: Array<Node> = [];

  fragment.forEach((node, _, nodeIndex) => {
    if (node.type.name === "paragraph") {
      // join paragraph nodes together & add line breaks instead
      const childFragments = paragraphCollect(node.content, allowedVariables);
      childFragments.forEach((childFragment) =>
        collectedNodes.push(childFragment)
      );
      if (nodeIndex < fragment.childCount - 1) {
        collectedNodes.push(node.type.schema.node("hardBreak"));
      }
    } else if (node.type.name === "text") {
      const lines = splitUnicodeLineBreaks(node);
      // non-unicode line separators are automatically converted into paragraphs on paste, so don't need to be handled here

      lines.forEach((line) => {
        // split line if variable is found to create separate nodes for allowed variables
        const lineParts = addVariablesToLine(line, allowedVariables);
        lineParts.forEach((linePart) => collectedNodes.push(linePart));
      });
    } else {
      // copy all other nodes as-is
      collectedNodes.push(
        node.copy(paragraphCollect(node.content, allowedVariables))
      );
    }
  });

  return Fragment.fromArray(collectedNodes);
}

interface PasteTransformOptions {
  allowedVariables: Array<string>;
}

const PasteTransform = Extension.create<PasteTransformOptions>({
  name: "pasteTransform",
  addOptions() {
    return {
      allowedVariables: []
    };
  },
  addProseMirrorPlugins() {
    const allowedVariables = this.options.allowedVariables;

    return [
      new Plugin({
        key: new PluginKey("pasteTransform"),
        props: {
          transformPasted(slice) {
            const collected = paragraphCollect(slice.content, allowedVariables);
            return new Slice(collected, slice.openStart, slice.openEnd);
          }
        }
      })
    ];
  }
});

export { PasteTransform };
