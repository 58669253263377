import { Buffer } from "buffer";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { parse } from "query-string";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import api from "../../api";
import { useEnergyDataAcquisitions } from "../../hooks/useEnergyDataAcquisitions";
import urls from "../../urls";
import { FREQUENCY_CHOICES, UNITS } from "../../utils/constants";
import {
  LUXON_END_OF_LAST_MONTH,
  LUXON_START_OF_LAST_MONTH,
  LUXON_START_OF_LAST_YEAR
} from "../../utils/dates";
import { luxonDateTimeToBackendDateOrDateTime } from "../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import { Frequency, Unit } from "../../utils/enums";
import { openErrorAlertPopup } from "../ErrorAlertPopup/openErrorAlertPopup";
import "./EnergyData.scss";
import {
  DEFAULT_FILTERS,
  buildFilterString
} from "./EnergyDataView/EnergyDataAcquisitionSelectionModal/Filters/filter-utils";
import type { AcquisitionFilter } from "./EnergyDataView/EnergyDataView";
import { EnergyDataView } from "./EnergyDataView/EnergyDataView";

let initialAcquisitions: Array<number> = [];
let initialAcquisitionFilters = DEFAULT_FILTERS;
let initialDefinedFilterId: number | null = null;
let initialFirstDate = LUXON_START_OF_LAST_MONTH;
let initialLastDate = LUXON_END_OF_LAST_MONTH;
let initialFrequency = Frequency.QuarterHour;
let initialUnit = Unit.KilowattHour;

if (location.search !== "") {
  const queryParams = parse(location.search);

  if (queryParams.firstDate) {
    const potentialFirstDate = DateTime.fromISO(
      queryParams.firstDate as string
    );
    if (potentialFirstDate.isValid) {
      initialFirstDate = potentialFirstDate;
    }
  }

  if (queryParams.lastDate) {
    const potentialLastDate = DateTime.fromISO(queryParams.lastDate as string);
    if (potentialLastDate.isValid && potentialLastDate > initialFirstDate) {
      initialLastDate = potentialLastDate;
    } else if (initialLastDate < initialFirstDate) {
      initialFirstDate = LUXON_START_OF_LAST_YEAR;
    }
  }

  if (queryParams.frequency) {
    const potentialFrequency = (queryParams.frequency as Frequency).replace(
      "-",
      " "
    ) as Frequency;
    if (
      Object.values(FREQUENCY_CHOICES)
        .map((frequency) => frequency.value)
        .includes(potentialFrequency)
    ) {
      initialFrequency = potentialFrequency as Frequency;
    }
  }

  if (queryParams.unit) {
    const potentialUnit = queryParams.unit;
    if (
      Object.values(UNITS)
        .map((unit) => unit.value)
        .includes(potentialUnit as Unit)
    ) {
      initialUnit = potentialUnit as Unit;
    }
  }

  if (queryParams.selectedAcquisitions) {
    initialAcquisitions = (queryParams.selectedAcquisitions as string)
      .split(",")
      .map((acquisition) => parseInt(acquisition, 10));
    initialAcquisitions = initialAcquisitions.filter(
      (acquisition) => !isNaN(acquisition)
    );
  }

  if (queryParams.acquisitionFilters) {
    let decodedFilters: string | null = null;

    try {
      decodedFilters = Buffer.from(
        queryParams.acquisitionFilters as string,
        "base64"
      ).toString("utf8");
    } catch (_) {
      // swallow this error
      // the user must have screwed up the URL or they are somehow using an old, incompatible URL
      // the best course of action is to just clear the filters
    }

    try {
      if (decodedFilters) {
        initialAcquisitionFilters = JSON.parse(decodedFilters);
      }
    } catch (_) {
      // swallow this error
      // the user must have screwed up the URL or they are somehow using an old, incompatible URL
    }
  }

  if (queryParams.filter) {
    const potentialDefinedFilterId = parseInt(queryParams.filter as string, 10);
    if (!isNaN(potentialDefinedFilterId)) {
      initialDefinedFilterId = potentialDefinedFilterId;
    }
  }
}

interface EnergyDataProps {
  variantId: number;
}

function EnergyData({ variantId }: EnergyDataProps) {
  const [selectedAcquisitions, setSelectedAcquisitions] =
    useState(initialAcquisitions);
  const [acquisitionFilters, setAcquisitionFilters] = useState(
    initialAcquisitionFilters
  );
  const [filterString, setFilterString] = useState<string | undefined>(
    buildFilterString(initialAcquisitionFilters)
  );
  const [definedFilterId, setDefinedFilterId] = useState<number | null>(
    initialDefinedFilterId
  );
  const [acquisitionsWithLowerFrequency, setAcquisitionsWithLowerFrequency] =
    useState([]);
  const [firstDate, setFirstDate] = useState(initialFirstDate);
  const [lastDate, setLastDate] = useState(initialLastDate);
  const [frequency, setFrequency] = useState<Frequency>(initialFrequency);
  const [unit, setUnit] = useState(initialUnit);
  const [, setSearchParams] = useSearchParams();

  const payload = {
    energyDataAcquisitions: selectedAcquisitions,
    firstDate: firstDate.toISO(), // todo auto convert sent dates in axios hooks
    lastDate: lastDate.toISO(), // todo
    frequency: frequency,
    unit: unit
  };

  const { data: acquisitions, isLoading: isLoadingAcquisitions } =
    useEnergyDataAcquisitions(variantId, filterString, openErrorAlertPopup);

  const {
    data: energyDataDisplayDataResponse,
    error,
    isError,
    isLoading: isLoadingEnergyData
  } = useQuery({
    queryKey: ["energy-data-display-data", payload],
    queryFn: () => fetchEnergyDataDisplayData(payload),
    refetchInterval: false,
    refetchOnWindowFocus: false
  });

  async function fetchEnergyDataDisplayData(payload) {
    const response = await api.post(urls.api.energyDataDisplayData(), payload);

    return {
      serieses: response.data.serieses,
      summaryData: response.data.summary,
      acquisitionsWithLowerFrequency:
        response.data.acquisitionsWithLowerFrequency
    };
  }

  useEffect(() => {
    setAcquisitionsWithLowerFrequency(
      energyDataDisplayDataResponse?.acquisitionsWithLowerFrequency || []
    );
  }, [energyDataDisplayDataResponse]);

  useEffect(() => {
    if (isError) {
      openErrorAlertPopup(error);
    }
  }, [error, isError]);

  // when the original data (metering location list) or the current options (stored in the state) change,
  // we need to re-fetch the meter register data and update the query parameters
  useEffect(() => {
    setSearchParams(
      (searchParams) => {
        searchParams.set(
          "selectedAcquisitions",
          selectedAcquisitions.join(",")
        );
        searchParams.set(
          "acquisitionFilters",
          Buffer.from(JSON.stringify(acquisitionFilters), "utf8").toString(
            "base64"
          )
        );
        searchParams.set("filter", definedFilterId?.toString() ?? "");
        searchParams.set(
          "firstDate",
          luxonDateTimeToBackendDateOrDateTime(firstDate, "ISO 8601")
        );
        searchParams.set(
          "lastDate",
          luxonDateTimeToBackendDateOrDateTime(lastDate, "ISO 8601")
        );
        searchParams.set("frequency", frequency.replace(" ", "-"));
        searchParams.set("unit", unit);

        return searchParams;
      },
      { replace: true }
    );
  }, [
    selectedAcquisitions,
    acquisitionFilters,
    firstDate,
    lastDate,
    frequency,
    unit,
    definedFilterId,
    setSearchParams
  ]);

  function handleSelectedAcquisitionsChanged(
    selectedAcquisitions: Array<number>,
    acquisitionFilters: Array<AcquisitionFilter>,
    definedFilterId: number | null
  ) {
    setSelectedAcquisitions(selectedAcquisitions);

    if (acquisitionFilters) {
      setAcquisitionFilters(acquisitionFilters);
      setFilterString(buildFilterString(acquisitionFilters));
    } else {
      setFilterString(undefined);
    }

    setDefinedFilterId(definedFilterId);
    setAcquisitionsWithLowerFrequency([]);
  }

  function handleDatesChanged(startDate: DateTime, endDate: DateTime) {
    setFirstDate(startDate);
    setLastDate(endDate);
  }

  function handleFrequencyChanged(domName: string, frequency: Frequency) {
    setFrequency(frequency);
    setAcquisitionsWithLowerFrequency([]);
  }

  function handleUnitChanged(domName: string, unit: Unit) {
    setUnit(unit);
  }

  return (
    <EnergyDataView
      acquisitionFilters={acquisitionFilters}
      acquisitions={acquisitions || []}
      acquisitionSerieses={energyDataDisplayDataResponse?.serieses}
      acquisitionSummaryData={energyDataDisplayDataResponse?.summaryData ?? []}
      acquisitionsWithLowerFrequency={acquisitionsWithLowerFrequency}
      definedFilterId={definedFilterId}
      firstDate={firstDate}
      lastDate={lastDate}
      loading={isLoadingAcquisitions || isLoadingEnergyData}
      selectedAcquisitions={selectedAcquisitions}
      selectedFrequency={frequency}
      selectedUnitValue={unit}
      variantId={variantId}
      onDatesChanged={handleDatesChanged}
      onFrequencyChanged={handleFrequencyChanged}
      onSelectedAcquisitionsChanged={handleSelectedAcquisitionsChanged}
      onUnitChanged={handleUnitChanged}
    />
  );
}

export { EnergyData };
