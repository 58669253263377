import { useEffect, useState } from "react";

import { PAGE_SIZE_OPTIONS } from "./constants";
import { calculateBestPageSize } from "./utils/calculateBestPageSize";

function usePageSize<T>(data?: Array<T>, maxPageSize?: number) {
  const initialPageSize =
    typeof maxPageSize !== "undefined" ? maxPageSize : PAGE_SIZE_OPTIONS[0];

  const [pageSize, setPageSize] = useState(initialPageSize);
  const dataLength = data ? data.length : 0;

  useEffect(() => {
    setPageSize(() => calculateBestPageSize(dataLength, maxPageSize));
  }, [dataLength, maxPageSize]);

  return {
    pageSize,
    setPageSize
  };
}

export { usePageSize };
