import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import api from "../api";
import { LoadOrError } from "../components/LoadOrError/LoadOrError";
import { ROUTES } from "../routes";
import urls from "../urls";

export function withRouteProjectId(
  WrappedComponent,
  checkIfProjectExistsAndRedirectOnError = false
) {
  const WrappedComponentWithRouteProjectId = ({
    redirectPathOnMissingProject = ROUTES.analyzerProjectList,
    ...otherProps
  }) => {
    const { projectId } = useParams();
    const { isLoading: checkingIfProjectExists, error } = useQuery({
      queryKey: ["project", { projectId }],
      queryFn: () => fetchProject(projectId),
      enabled: checkIfProjectExistsAndRedirectOnError,
      refetchInterval: false,
      refetchOnWindowFocus: false
    });
    const { t } = useTranslation();

    const customErrors = {
      404: t("errors.ProjectNotFound")
    };

    async function fetchProject(projectId) {
      const response = await api.get(urls.api.projectsDetail(projectId));
      return response.data;
    }

    return (
      <LoadOrError
        customErrors={customErrors}
        error={error}
        errorRedirectTo={redirectPathOnMissingProject}
        loading={checkingIfProjectExists}
      >
        <WrappedComponent {...otherProps} projectId={projectId} />
      </LoadOrError>
    );
  };

  return WrappedComponentWithRouteProjectId;
}
