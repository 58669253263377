export function interactionSleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const SLEEP_DURATION = {
  formValidation: 100,
  toastAnimation: 100,
  bannerAnimation: 200,
  modalAnimation: 400,
  mockFunction: 550
} as const;
