import _ from "lodash";

import { mergeByAccessors } from "../components/MeteringConceptComparison/merging";
import type {
  MarketLocation,
  PriceComponent,
  ResultSummary
} from "./backend-types";

export function aggregateMeteringConceptComparisonData(
  marketLocations: Record<number, Array<MarketLocation>>,
  priceComponents: Record<number, Array<PriceComponent>>,
  resultSummaries: Record<number, ResultSummary>
) {
  return {
    mergedMarketLocations: mergeByAccessors(
      marketLocations,
      ["locationType", "generator.name", "consumer.name", "connection.name"],
      ["generator.name", "consumer.name"]
    ),
    mergedPriceComponents: mergeByAccessors(priceComponents, ["label"]),
    yearlyAggregates: applyForMeteringConcepts(
      resultSummaries,
      calculateYearlyAggregatesForMeteringConcept
    )
  };
}

type ApplyForMeteringConceptsFunc = (resultSummary: ResultSummary) => {
  totalCosts: string;
  localDelivery: number;
  localShare: number;
};

function applyForMeteringConcepts(
  resultSummaries: Record<number, ResultSummary>,
  func: ApplyForMeteringConceptsFunc
) {
  const meteringConceptKeys = _.keys(resultSummaries);
  return meteringConceptKeys.reduce((obj, key) => {
    const intKey = parseInt(key, 10);
    obj[key] = func(resultSummaries[intKey]);
    return obj;
  }, {});
}

function calculateYearlyAggregatesForMeteringConcept(
  resultSummary: ResultSummary
) {
  return {
    totalCosts: resultSummary.totalYearlyCost,
    localDelivery: resultSummary.internalDelivery.kwh,
    localShare: resultSummary.internalDelivery.share
  };
}
