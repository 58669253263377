import * as Sentry from "@sentry/browser";
import { t } from "i18next";
import { DateTime } from "luxon";
import type { ApiError } from "../../../api";
import api from "../../../api";
import urls from "../../../urls";
import { pollTaskStatus } from "../../../utils/api-utils";
import type { Paged, ParameterSet } from "../../../utils/backend-types";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import { DataImportRequestType } from "../../../utils/enums";
import { uploadFileOrFiles } from "../../../utils/files/uploadFileOrFiles";
import { DEFAULT_PAGE_SIZE } from "../../CustomReactTable/constants";
import type { Choice } from "../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";

export interface EnergyDataFile {
  id: string;
  name: string;
  data: File;
  selectedMeter: number | null;
  manySelected: boolean;
  typeOfData: DataImportRequestType | null;
}

export type EnergyDataUploadResponse = {
  id: string;
};

export interface EnergyDataUploadRequestPayload {
  variant: number;
  selectedMeter: string;
  typeOfData: DataImportRequestType;
}

export interface EnergyDataImportRequest {
  id: string;
  variant: number;
  created: string;
  updated: string;
  energyDataFile: {
    id: string;
    name: string;
    dataFile: string;
  };
  status: EnergyDataFileImportRequestStatus;
  errorMessage: string | null;
  celeryTaskId: string;
  selectedMeter: number | null;
  supportRequested: boolean;
  typeOfData: DataImportRequestType;
  disabledCheckbox: boolean;
  user?: string;
  selectedOrigin?: string | null;
  newOriginName?: string | null;
  adminUrl: string | null;
}

export type PagedEnergyDataImportRequest = Paged<EnergyDataImportRequest>;

export interface EnergyDataImportRequestDetails {
  locationNumber?: string;
  meterNumber?: string;
  meterName?: string;
  startDate?: string;
  endDate?: string;
  measurand?: string;
  missingValuesShare?: number;
  maxPower?: number;
  sumPower?: number;
  parameterSet?: ParameterSet;
}

export interface EnergyDataImportRequestWithDetails
  extends EnergyDataImportRequest {
  details: EnergyDataImportRequestDetails[];
}

export enum EnergyDataFileImportRequestStatus {
  Open = "OPEN",
  InProgress = "IN_PROGRESS",
  Success = "SUCCESSFUL",
  Error = "FAILED",
  Stopped = "STOPPED"
}

export enum EnergyDataFileImportRequestStatusToRepresentation {
  Open = "offen",
  InProgress = "in Bearbeitung",
  Success = "erfolgreich",
  Error = "fehlgeschlagen",
  Stopped = "gestoppt"
}

export enum EnergyDataFileImportRequestModalType {
  Meter = "Meter",
  Origin = "Origin",
  ParameterSet = "ParameterSet",
  Support = "Support",
  Details = "Details"
}

export const dataTypeChoices: Choice[] = [
  {
    displayName: "Messwerte",
    value: DataImportRequestType.METERING
  },
  {
    displayName: "Redispatch-Daten",
    value: DataImportRequestType.REDISPATCH
  }
];

function uploadEnergyDataFiles(
  energyDataFiles: EnergyDataFile[],
  variantId: number
) {
  return energyDataFiles.map((energyDataFile) => {
    if (!energyDataFile.data || energyDataFile.typeOfData === null) {
      Sentry.captureMessage("Missing file in uploadEnergyDataFiles");

      return Promise.reject(new Error(t("errors.ServerError")));
    }

    return uploadEnergyDataFile(
      energyDataFile.data,
      variantId,
      energyDataFile.selectedMeter?.toString() ?? "",
      energyDataFile.typeOfData
    );
  });
}

function uploadEnergyDataFile(
  file: File,
  variant: number,
  selectedMeter: string,
  typeOfData: DataImportRequestType
) {
  const data: EnergyDataUploadRequestPayload = {
    variant,
    selectedMeter,
    typeOfData
  };

  return uploadFileOrFiles<
    EnergyDataUploadResponse,
    EnergyDataUploadRequestPayload
  >(file, urls.api.energyDataUpload(), "dataFile", data);
}

function getEnergyDataImportRequests(
  variantId: number,
  pageIndex = 1,
  pageSize = DEFAULT_PAGE_SIZE,
  ordering?: string
) {
  const url = urls.api.energyDataUploadImportRequests({
    variant: variantId,
    page: pageIndex,
    page_size: pageSize,
    ordering
  });

  return api
    .get<PagedEnergyDataImportRequest>(url)
    .then((response) => response.data)
    .catch((error: ApiError) => Promise.reject(error));
}

function pollDataImportRequests(
  energyDataImportRequests: Array<EnergyDataImportRequest>
): Array<Promise<EnergyDataImportRequest>> {
  return energyDataImportRequests.map((energyDataImportRequest) => {
    if (
      energyDataImportRequest.status !==
      EnergyDataFileImportRequestStatus.InProgress
    ) {
      return Promise.resolve(energyDataImportRequest);
    }

    return new Promise<EnergyDataImportRequest>((resolve, reject) => {
      const url = urls.api.jobStatus(energyDataImportRequest.celeryTaskId);

      pollTaskStatus(
        url,
        () => {
          getDataImportRequest(energyDataImportRequest.id).then(
            (dataImportRequest) => {
              resolve(dataImportRequest);
            }
          );
        },
        () => {
          getDataImportRequest(energyDataImportRequest.id).then(
            (dataImportRequest) => {
              resolve(dataImportRequest);
            }
          );
        },
        () => {
          reject(new Error(t("errors.ServerError")));
        },
        1000
      );
    });
  });
}

function getDataImportRequest(importRequestId: string) {
  const url = urls.api.energyDataUploadImportRequest(importRequestId);
  return api
    .get<EnergyDataImportRequest>(url)
    .then((response) => response.data);
}

function updateEnergyDataImportRequest(
  dataImportRequestFromUploadId: string,
  data: Partial<EnergyDataImportRequest>
) {
  return api.patch<void>(
    urls.api.energyDataUploadImportRequest(dataImportRequestFromUploadId),
    data
  );
}

function createEnergyDataImportRequestFromEnergyDataFile(
  energyDataFile: EnergyDataFile,
  variantId: number
): EnergyDataImportRequest {
  return {
    created: luxonDateTimeToBackendDateOrDateTime(DateTime.now()),
    id: energyDataFile.id,
    errorMessage: null,
    status: EnergyDataFileImportRequestStatus.InProgress,
    energyDataFile: {
      id: "temp",
      name: energyDataFile.name,
      dataFile: "temp"
    },
    selectedMeter: energyDataFile.selectedMeter,
    supportRequested: false,
    updated: luxonDateTimeToBackendDateOrDateTime(DateTime.now()),
    variant: variantId,
    celeryTaskId: "temp",
    disabledCheckbox: true,
    typeOfData: DataImportRequestType.METERING,
    adminUrl: null
  };
}

function retryUploadEnergyDataFile(
  importRequestId: string,
  meterNumber: number
) {
  const data = {
    selectedMeter: meterNumber
  };

  return api.post(urls.api.fileUploadWithMeterRetry(importRequestId), data);
}

function retryUploadWithOriginRequest(
  importRequestId: string,
  fileOriginId: string | null,
  newOriginName: string | null
) {
  const data = {
    fileOriginId: fileOriginId,
    originName: newOriginName
  };

  return api.post(urls.api.fileUploadWithOriginRetry(importRequestId), data);
}

function retryUploadWithParameterSetRequest(
  requestId: string,
  parameterSetId: string | null
) {
  return api.post(urls.api.fileUploadWithParameterSetRetry(requestId), {
    parameterSetId
  });
}

function requestSupportForDataUploadRequest(
  importRequestId: string,
  comment: string | null
) {
  const data = {
    supportComment: comment
  };

  return api.post(urls.api.requestSupportForDataUpload(importRequestId), data);
}

function getTypeOfDataLabel(type: DataImportRequestType) {
  return dataTypeChoices.find(
    ({ value }) => value === (type || DataImportRequestType.METERING)
  )?.displayName;
}

export {
  createEnergyDataImportRequestFromEnergyDataFile,
  getEnergyDataImportRequests,
  getTypeOfDataLabel,
  pollDataImportRequests,
  requestSupportForDataUploadRequest,
  retryUploadEnergyDataFile,
  retryUploadWithOriginRequest,
  retryUploadWithParameterSetRequest,
  updateEnergyDataImportRequest,
  uploadEnergyDataFiles
};
