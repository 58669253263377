import { DateTime } from "luxon";
import {
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnDef,
  type MRT_SortingState
} from "mantine-react-table";
import React, { useMemo } from "react";
import { getDefaultMRTOptions } from "../../../../../../../../mantine/getDefaultMRTOptions";
import type { Measurand } from "../../../../../../../../utils/backend-types";
import type { DataGapsTableObject } from "../DataGaps.types";
import { getDateRangeString } from "../utils/getDateRangeString";
import { getHourRangeString } from "../utils/getHourRangeString";
import { mapMeasurandToLabel } from "../utils/mapMeasurandToLabel";

export interface DataGapsTableProps {
  data: Array<DataGapsTableObject>;
}

const DEFAULT_SORTED: MRT_SortingState = [
  {
    id: "date",
    desc: true
  }
];

function DataGapsTable({ data }: DataGapsTableProps) {
  const columns = useMemo<Array<MRT_ColumnDef<DataGapsTableObject>>>(
    () => [
      {
        accessorKey: "date",
        header: "Datum",
        Cell: ({ cell }) => {
          const date = cell.getValue<{
            startDate: DateTime;
            lastDate: DateTime;
          }>();
          return <>{getDateRangeString(date.startDate, date.lastDate)}</>;
        }
      },
      {
        accessorKey: "measurand",
        header: "Messrichtung",
        Cell: ({ row }) => (
          <>{mapMeasurandToLabel(row.original.measurand as Measurand)}</>
        )
      },
      {
        accessorKey: "dataGaps",
        header: "Zeitraum",
        Cell: ({ row }) => (
          <div className="data-gaps-table-timeseries-container">
            {row.original.dataGaps.map((gap, index) => {
              return (
                <React.Fragment key={"hourGap" + index}>
                  {getHourRangeString(
                    DateTime.fromISO(gap[0], { zone: "Europe/Berlin" }),
                    DateTime.fromISO(gap[1], { zone: "Europe/Berlin" })
                  )}
                  <br />
                </React.Fragment>
              );
            })}
          </div>
        )
      }
    ],
    []
  );

  const table = useMantineReactTable({
    ...getDefaultMRTOptions<DataGapsTableObject>({
      emptyRowsFallbackText: "Keine Datenlücken vorhanden!"
    }),
    columns,
    data: data,
    enableColumnActions: true,
    enableFilters: false,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 15
      },
      sorting: DEFAULT_SORTED
    },
    mantineTopToolbarProps: {
      hidden: true
    }
  });

  return (
    <div className="DataGapsTable">
      <MantineReactTable table={table} />
    </div>
  );
}

export { DataGapsTable };
