import classNames from "classnames";
import React from "react";
// eslint-disable-next-line no-restricted-imports
import { ModalBody as ReactstrapModalBody } from "reactstrap";
import type { ModalBodyProps as ReactstrapModalBodyProps } from "reactstrap";

import "./ModalBody.scss";

interface ModalBodyProps extends ReactstrapModalBodyProps {
  scrollable?: boolean;
}

/** ModalBody from reactstrap but `scrollable` optionally adds a vertical scrollbar when necessary. */
function ModalBody({
  children,
  className,
  scrollable,
  ...otherProps
}: ModalBodyProps) {
  return (
    <ReactstrapModalBody
      className={classNames({ ScrollableModalBody: scrollable }, className)}
      {...otherProps}
    >
      {children}
    </ReactstrapModalBody>
  );
}

export { ModalBody, ModalBodyProps };
