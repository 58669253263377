import { type MRT_ColumnDef } from "mantine-react-table";
import React from "react";
import { type Todo } from "../../../utils/backend-types";
import { TodosListColumnKeys } from "../TodoTable";

const statusFilterData = [
  { value: "true", label: "Bereit" },
  { value: "false", label: "Keine" }
];

export function getDocumentProvidedColumn(): MRT_ColumnDef<Todo> {
  return {
    accessorKey: TodosListColumnKeys.DocumentProvided,
    header: "Dokumentenstatus",
    Header: () => (
      <>
        Dokumentenstatus
        {/* add when Bora and Paulina have written the text: <IconHelpText helpText="" /> */}
      </>
    ),
    enableGrouping: false,
    Cell: ({ row }) => (row.original.documentProvided ? "Bereit" : "Keine"),
    filterVariant: "select",
    filterFn: (row, _, filterValue) =>
      filterValue === "true"
        ? row.original.documentProvided
        : !row.original.documentProvided,
    mantineFilterSelectProps: {
      data: statusFilterData
    }
  };
}
