import React, { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import { useShouldFieldBeHighlighted } from "../../../hooks/useShouldFieldBeHighlighted";
import type { Site } from "../../../utils/backend-types";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../BuildingBlocks/Layout/Modal/Modal";
import { SiteForm } from "./SiteForm/SiteForm";

interface SiteModalProps {
  headerText: string;
  site: Site | null;
  variantId: number;
  isOpen: boolean;
  onToggle: () => void;
  onClickSave?: (site: Site) => void;
}
function SiteModal({
  headerText,
  site,
  variantId,
  isOpen,
  onToggle,
  onClickSave
}: SiteModalProps) {
  // footerNode is used in CustomForm with React.Portal to allow the
  //  CustomForm buttons to be displayed outside of the CustomForm and in this component
  // footerRef and useCallback are needed because the node does not exist in the Dom
  //  at the time of the first render of this component
  const [footerNode, setFooterNode] = useState(null);
  const { state: missingData } = useLocation();

  const missingFields =
    (missingData && missingData.missingFields.general) || [];

  const shouldFieldBeHighlighted = useShouldFieldBeHighlighted(missingFields);

  const footerRef = useCallback((node) => {
    if (node !== null) {
      setFooterNode(node);
    }
  }, []);

  function handleSubmit(site) {
    onClickSave?.(site);
    onToggle();
  }

  return (
    <Modal className="SiteModal" isOpen={isOpen} toggle={onToggle}>
      <ModalHeader role="heading" toggle={onToggle}>
        {headerText}
      </ModalHeader>
      <ModalBody scrollable>
        {footerNode && (
          <SiteForm
            buttonContainer={footerNode}
            missingFields={missingFields}
            shouldFieldBeHighlighted={shouldFieldBeHighlighted}
            site={site}
            variant={variantId}
            onCancel={onToggle}
            onSubmit={handleSubmit}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <div className="buttons" ref={footerRef}></div>
      </ModalFooter>
    </Modal>
  );
}

export { SiteModal, SiteModalProps };
