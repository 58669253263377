import { useQuery } from "@tanstack/react-query";

import api from "../../../api";
import urls from "../../../urls";
import type { Meter, Site } from "../../../utils/backend-types";

export function useMetersBySiteIds(sites: Array<Site>) {
  const { isLoading, error, data } = useQuery<Array<Meter>>({
    queryKey: ["meters-by-site-ids"],
    queryFn: fetchMetersBySiteIds,
    refetchOnWindowFocus: false
  });

  async function fetchMetersBySiteIds() {
    const metersPromises = sites.map((site) =>
      api.get<Array<Meter>>(urls.api.meters(site.id))
    );
    const responses = await Promise.all(metersPromises);

    const allMeters: Array<Meter> = responses.reduce((allMeters, response) => {
      const metersForSite = response.data;
      allMeters.push(...metersForSite);
      return allMeters;
    }, [] as Array<Meter>);

    return allMeters;
  }

  return {
    metersBySiteId: data,
    isMetersQueryLoading: isLoading,
    metersQueryError: error
  };
}
