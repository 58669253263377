import React, { useEffect } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { Direktvermarkter } from "../../../../utils/backend-types";
import { FormFieldController } from "../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { DateRangeInput } from "../../../BuildingBlocks/Forms/FormFields/GroupedFields/DateRangeInput/DateRangeInput";
import { FormBody } from "../../../BuildingBlocks/Forms/utils/FormBody";
import { FormContainer } from "../../../BuildingBlocks/Forms/utils/FormContainer";
import { FormFooter } from "../../../BuildingBlocks/Forms/utils/FormFooter";
import { setErrorsFromResponseData } from "../../../BuildingBlocks/Forms/utils/setErrorsFromResponseData";
import { Button, buttonColors } from "../../../Buttons/Button/Button";
import { SpinButton } from "../../../Buttons/SpinButton/SpinButton";
import type { Choice } from "../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { useShouldShowStaffView } from "../../../StaffViewToggle/useShouldShowStaffView";
import type {
  DirektvermarktungContract,
  DirektvermarktungContractResponse,
  DirektvermarktungOperator
} from "../../Direktvermarktung.types";
import { DIREKTVERMARKTUNG_FORM_INPUT_DATA } from "./DirektvermarktungContractForm.constants";

interface DirektvermarktungContractFormProps {
  direktvermarktungContract?: Partial<DirektvermarktungContractResponse>;
  operators: Array<DirektvermarktungOperator>;
  direktvermarkter: Array<Direktvermarkter>;
  formulaEntgeltVariables: Array<string>;
  isSubmitting?: boolean;
  submissionError?: Error;
  onSubmit: SubmitHandler<DirektvermarktungContract>;
  onCancel: () => void;
}

function DirektvermarktungContractForm({
  direktvermarktungContract,
  operators,
  direktvermarkter,
  formulaEntgeltVariables,
  isSubmitting = false,
  submissionError,
  onSubmit,
  onCancel
}: DirektvermarktungContractFormProps) {
  const { t } = useTranslation();

  const shouldShowStaffView = useShouldShowStaffView();

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    setError,
    unregister,
    watch
  } = useForm<DirektvermarktungContract>({
    defaultValues: direktvermarktungContract ?? {}
  });

  const operatorChoices: Array<Choice> =
    operators
      ?.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
      ?.map((operator) => ({
        value: operator.id,
        displayName: operator.name
      })) || [];
  const operatorFieldInfo = {
    ...DIREKTVERMARKTUNG_FORM_INPUT_DATA.operator_id,
    choices: operatorChoices,
    disabled:
      direktvermarktungContract?.generation_shares &&
      direktvermarktungContract?.generation_shares.length > 0
  };

  const direktvermarkterChoices: Array<Choice> =
    direktvermarkter
      ?.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
      .map((direktvermarkter) => ({
        value: direktvermarkter.id,
        displayName: direktvermarkter.name
      })) || [];
  const direktvermarkterFieldInfo = {
    ...DIREKTVERMARKTUNG_FORM_INPUT_DATA.direktvermarkter_id,
    choices: direktvermarkterChoices
  };

  const formulaEntgeltFieldInfo = {
    ...DIREKTVERMARKTUNG_FORM_INPUT_DATA.formula_entgelt,
    variables: formulaEntgeltVariables
  };

  useEffect(() => {
    if (submissionError) {
      setErrorsFromResponseData<DirektvermarktungContract>(
        submissionError,
        watch(),
        setError,
        t("errors.UnknownError")
      );
    }
  }, [setError, submissionError, t, watch]);

  return (
    <FormContainer>
      <form
        className="DirektvermarktungContractForm"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormBody>
          <section>
            <FormFieldController
              control={control}
              data={DIREKTVERMARKTUNG_FORM_INPUT_DATA.name}
              error={errors.name}
            />
            <FormFieldController
              control={control}
              data={operatorFieldInfo}
              error={errors.operator_id}
            />
            <FormFieldController
              control={control}
              data={direktvermarkterFieldInfo}
              error={errors.direktvermarkter_id}
            />
            <FormFieldController
              control={control}
              data={DIREKTVERMARKTUNG_FORM_INPUT_DATA.funding_marketing_form}
              error={errors.funding_marketing_form}
            />
            <FormFieldController
              control={control}
              data={
                DIREKTVERMARKTUNG_FORM_INPUT_DATA.contract_remuneration_type
              }
              error={errors.contract_remuneration_type}
            />
            <DateRangeInput
              endDateFieldName="last_date"
              errors={errors}
              label="Gültigkeit der Vermarktungsinformationen"
              register={register}
              registerOptions={DIREKTVERMARKTUNG_FORM_INPUT_DATA}
              setValue={setValue}
              startDateFieldName="first_date"
              unregister={unregister}
              watch={watch}
            />
            {/* 
            // TODO in WUP-7838: remove when expression editor for DV contracts is publicly released
            */}
            {shouldShowStaffView && (
              <FormFieldController
                control={control}
                data={formulaEntgeltFieldInfo}
                error={errors.formula_entgelt}
              />
            )}
          </section>
        </FormBody>
        <FormFooter>
          <Button
            color={buttonColors.secondary}
            disabled={isSubmitting}
            type="button"
            onClick={onCancel}
          >
            Abbrechen
          </Button>
          <SpinButton
            color={buttonColors.brand}
            spin={isSubmitting}
            type="submit"
          >
            Speichern
          </SpinButton>
        </FormFooter>
      </form>
    </FormContainer>
  );
}

export { DirektvermarktungContractForm, DirektvermarktungContractFormProps };
