/** Takes a URL function and returns an dictionary of URLs that matches the given array of variant ids. */
export function getDataUrlsForSelectedVariantIds(
  selectedVariantIds: Array<number>,
  dataUrlForConcept: (variantId: number) => string
) {
  return selectedVariantIds.reduce<{ [variantId: number]: string }>(
    (obj, variantId) => {
      obj[variantId] = dataUrlForConcept(variantId);
      return obj;
    },
    {}
  );
}
