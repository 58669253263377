import { useQuery } from "@tanstack/react-query";
import api from "../../../../api";
import urls from "../../../../urls";
import type { FictionalEnergyValueResponse } from "../EnergyDataUploadPage/FictionalEnergyValueManager/FictionalEnergyValueManager";
import { convertKwhNumberStringsToFloats } from "../utils/convertKwhNumberStringsToFloats";

export function useFictionalEnergyValues(variantId: number) {
  const queryKey = ["fictionalEnergyValues", { variantId }];
  const { data, isLoading, error } = useQuery({
    queryKey,
    queryFn: () => fetchFictionalEnergyValues(variantId)
  });

  async function fetchFictionalEnergyValues(variantId: number) {
    const url = urls.api.fictionalEnergyValues(variantId);
    const response = await api.get<Array<FictionalEnergyValueResponse>>(url);

    const convertedResponseData = convertKwhNumberStringsToFloats(
      response.data
    );

    return convertedResponseData;
  }

  return { fictionalEnergyValues: data, isLoading, error };
}
