import React from "react";
import { Link } from "react-router-dom";
import type { Meter } from "../../../../../../utils/backend-types";
import { Alert, AlertColor } from "../../../../../Alert/Alert";

interface SiteInfoProps {
  loading: boolean;
  loadingFailed: boolean;
  noMeters: boolean;
  unplausibleMeters: Array<Meter>;
  onClickMeter: (id: number) => void;
}

function SiteInfo({
  loading,
  loadingFailed,
  noMeters,
  unplausibleMeters,
  onClickMeter
}: SiteInfoProps) {
  let alertColour = AlertColor.Success;
  let alertMessage: string | React.ReactNode = (
    <span>
      Zum aktuellen Zeitpunkt haben wir keine Hinweise auf unplausible Werte in
      den hochgeladenen Zeitreihen gefunden. Zeiträume ohne Energiedaten werden
      nicht bewertet.
    </span>
  );

  if (loading) {
    alertColour = AlertColor.Info;
    alertMessage = "Die Energiedaten werden auf Plausbilität geprüft...";
  } else if (loadingFailed) {
    alertColour = AlertColor.Danger;
    alertMessage =
      "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt nochmal.";
  } else if (unplausibleMeters.length > 0) {
    alertColour = AlertColor.Warning;
    alertMessage = (
      <div>
        <h5>Hinweis auf unplausible Daten:</h5>
        <p>
          Bei der Überprüfung der Energiedaten haben wir Auffälligkeiten
          gefunden. Bitte prüfen Sie, ob es sich beispielsweise um fehlerhafte
          Daten handelt, welche durch Sie korrigiert werden können. Folgende
          Zähler sind betroffen:
        </p>
        <ul>
          {unplausibleMeters.map((meter) => (
            <li key={meter.id}>
              <Link
                to="#RawEnergyDataSiteMetersMeterData"
                onClick={() => onClickMeter(meter.id)}
              >
                {meter.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  } else if (noMeters) {
    alertColour = AlertColor.Info;
    alertMessage = "Keine Zähler vorhanden.";
  }

  return (
    <Alert className="SiteInfo" color={alertColour}>
      {alertMessage}
    </Alert>
  );
}

export { SiteInfo };
