import type { CSSProperties } from "react";
import React from "react";
import { getStraightPath } from "reactflow";

interface CustomConnectionLineProps {
  fromX: number;
  fromY: number;
  toX: number;
  toY: number;
  connectionLineStyle?: CSSProperties;
}
function CustomConnectionLine({
  fromX,
  fromY,
  toX,
  toY,
  connectionLineStyle
}: CustomConnectionLineProps) {
  const [edgePath] = getStraightPath({
    sourceX: fromX,
    sourceY: fromY,
    targetX: toX,
    targetY: toY
  });

  return (
    <g>
      <path d={edgePath} fill="none" style={connectionLineStyle} />
      <circle
        cx={toX}
        cy={toY}
        fill="black"
        r={3}
        stroke="black"
        strokeWidth={1.5}
      />
    </g>
  );
}

export { CustomConnectionLine };
