import React, { useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { BasicConfirmationModal } from "../BasicConfirmationModal/BasicConfirmationModal";

interface MultiConfirmationModalProps {
  actionName: string;
  actionObjects: Array<string>;
  confirmationText: string;
  confirmAction?: boolean;
  headerText?: string;
  isModalOpen: boolean;
  matchTextToConfirmActionText?: React.ReactNode;
  objectName: string;
  onAction: () => Promise<void>;
  toggleModal: () => void;
}

/** A confirmation modal that automatically handles the confirmation and action
 * (e.g. deletion) of multiple objects.
 */
function MultiConfirmationModal({
  actionName,
  actionObjects,
  confirmationText,
  confirmAction,
  headerText,
  isModalOpen,
  matchTextToConfirmActionText,
  objectName,
  onAction,
  toggleModal
}: MultiConfirmationModalProps) {
  const [loading, setLoading] = useState(false);
  const [confirmationValue, setConfirmationValue] = useState("");

  function getCustomHeaderText() {
    if (headerText && headerText.length > 0) {
      return headerText;
    }

    if (actionObjects.length > 1) {
      return `${actionObjects.length} ${objectName} ${actionName}?`;
    }

    return `${objectName} ${actionName}?`;
  }

  function handleClickConfirm() {
    setLoading(true);
    toggleModal();
    onAction().then(() => {
      setLoading(false);
    });
  }

  const customHeaderText = getCustomHeaderText();
  const capitalizedActionName =
    actionName.charAt(0).toUpperCase() + actionName.slice(1);
  const canClickConfirm =
    !confirmAction || confirmationValue === actionObjects[0];

  return (
    <BasicConfirmationModal
      confirmationButtonColor="danger"
      confirmationButtonText={capitalizedActionName}
      headerText={customHeaderText}
      isConfirmationBtnDisabled={!canClickConfirm}
      isLoading={loading}
      isModalOpen={isModalOpen}
      toggleModal={toggleModal}
      onCancel={toggleModal}
      onConfirm={handleClickConfirm}
    >
      <p>{confirmationText}</p>
      <ul>
        {actionObjects.map((object, index) => (
          <li key={`object${index}`}>{object}</li>
        ))}
      </ul>
      {confirmAction && (
        <FormGroup>
          <Label for="multi-confirm-action">
            {matchTextToConfirmActionText}
          </Label>
          <Input
            id="multi-confirm-action"
            type="text"
            value={confirmationValue}
            onChange={(e) => setConfirmationValue(e.target.value)}
          />
        </FormGroup>
      )}
    </BasicConfirmationModal>
  );
}

export { MultiConfirmationModal, MultiConfirmationModalProps };
