import React, { useMemo } from "react";
import type { Filter, ReactTableFunction } from "react-table";
import { TodoStatus } from "../../../../utils/backend-types";
import { ThreeWayFilter } from "../../../CustomReactTable/Filters/ThreeWayFilter/ThreeWayFilter";
import { StatusSymbol, StatusSymbolColour } from "../../../Icons/StatusSymbol";
import { getAllPossibleOnboardingTodoLabels } from "../../../Todos/onboarding-todos";
import type { OnboardingTodo } from "../../site-status-types";
import { calculateNumDoneOnboardingTodos } from "../utils/calculateNumDoneOnboardingTodos";
import "./OnboardingTodos.scss";

const NO_TODOS_TEXT = "Keine Aufgaben";
const ALL_DONE_TEXT = "Alles erledigt";

interface OnboardingTodosCellProps {
  todos: Array<OnboardingTodo>;
  onClick: (todos: Array<OnboardingTodo>) => void;
}

function OnboardingTodosCell({ todos, onClick }: OnboardingTodosCellProps) {
  const numTodos = todos.length;
  const textAndSymbolStyle = { display: "flex", alignItems: "center" };

  if (numTodos === 0) {
    return (
      <div style={textAndSymbolStyle}>
        <StatusSymbol noStatus />
        <span>{NO_TODOS_TEXT}</span>
      </div>
    );
  }

  const numDone = calculateNumDoneOnboardingTodos(todos);

  let statusSymbolColour = StatusSymbolColour.Red;

  if (numTodos === numDone) {
    statusSymbolColour = StatusSymbolColour.Green;
  } else if (numDone > 0) {
    statusSymbolColour = StatusSymbolColour.Yellow;
  }

  return (
    <div
      style={{ ...textAndSymbolStyle, cursor: "pointer" }}
      onClick={() => onClick(todos)}
    >
      <StatusSymbol colour={statusSymbolColour} />
      <span>
        {numDone}/{numTodos} erledigt
      </span>
    </div>
  );
}

function sortOnboardingTodos(
  a: Array<OnboardingTodo>,
  b: Array<OnboardingTodo>
) {
  const aTotalDone = calculateNumDoneOnboardingTodos(a);
  const aTotalTodos = a.length;
  const aPercent = aTotalTodos > 0 ? aTotalDone / aTotalTodos : -1;
  const bTotalDone = calculateNumDoneOnboardingTodos(b);
  const bTotalTodos = b.length;
  const bPercent = bTotalTodos > 0 ? bTotalDone / bTotalTodos : -1;

  if (aPercent < bPercent) {
    return -1;
  } else if (aPercent > bPercent) {
    return 1;
  }

  return 0;
}

interface OnboardingTodosFilterProps {
  filter: Filter;
  onChange: (value: ReactTableFunction) => void;
}

function OnboardingTodosFilter({
  filter,
  onChange
}: OnboardingTodosFilterProps) {
  const allPossibleOnboardingTodoLabels = useMemo(
    () => getAllPossibleOnboardingTodoLabels(),
    []
  );

  return (
    <ThreeWayFilter
      allTrueSelectedText={ALL_DONE_TEXT}
      filter={filter}
      options={allPossibleOnboardingTodoLabels}
      onChange={onChange}
    />
  );
}

function onFilterOnboardingTodos(
  filterValue: Record<string, boolean>,
  onboardingTodos: Array<OnboardingTodo>
) {
  if (!filterValue) {
    return true;
  }

  const options = filterValue;
  const optionLabels = Object.keys(filterValue);
  const todos = onboardingTodos;
  let match = true;

  optionLabels.forEach((label) => {
    const todo = todos.find((todo) => todo.label === label);

    if (!todo) {
      if (options[label] !== null) {
        match = false;
      }

      return;
    }

    if (options[label] !== null) {
      const todoIsDoneOrNotRelevant =
        todo.status === TodoStatus.Done ||
        todo.status === TodoStatus.NotRelevant;

      if (options[label] !== todoIsDoneOrNotRelevant) {
        match = false;
      }
    }
  });

  return match;
}

export {
  OnboardingTodosCell,
  OnboardingTodosFilter,
  onFilterOnboardingTodos,
  sortOnboardingTodos
};
