import classnames from "classnames";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";

import type { ApiResponse } from "../../../../../api";
import type { TodoFile } from "../../../../../utils/backend-types";
import { showToast } from "../../../../../utils/toast";
import { Icon } from "../../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../../BuildingBlocks/Icon/types";
import { Button } from "../../../../Buttons/Button/Button";
import { openErrorAlertPopup } from "../../../../ErrorAlertPopup/openErrorAlertPopup";
import { useShouldShowStaffView } from "../../../../StaffViewToggle/useShouldShowStaffView";
import { WhichUserModal } from "../../../common";

import "./DocumentsDropzone.scss";

let filesToUpload: Array<File>;

interface DocumentsDropzoneProps {
  todoId: number;
  addTodoDocuments: (documentsInfo: {
    todoId: number;
    newFiles: Array<File>;
    asSystemUser: boolean;
  }) => Promise<Array<PromiseSettledResult<ApiResponse>>>;
}

function DocumentsDropzone({
  todoId,
  addTodoDocuments
}: DocumentsDropzoneProps) {
  const [isUploading, setIsUploading] = useState(false);
  const [showSystemUserQuestionModal, setShowSystemUserQuestionModal] =
    useState(false);
  const shouldShowStaffView = useShouldShowStaffView();
  const { getRootProps, getInputProps, open } = useDropzone({
    disabled: isUploading,
    multiple: true,
    onDrop: handleDrop
  });

  function handleDrop(files: Array<File>) {
    if (shouldShowStaffView) {
      filesToUpload = files;
      setShowSystemUserQuestionModal(true);
    } else {
      handleUploadFiles(files, false);
    }
  }

  function handleUploadFiles(files: Array<File>, asSystemUser: boolean) {
    if (files.length === 0) {
      return;
    }

    setIsUploading(true);
    addTodoDocuments({ todoId, newFiles: files, asSystemUser }).then(
      (results) => {
        const uploadedFileResponses: Array<TodoFile> = [];

        results.forEach((result) => {
          if (result.status === "fulfilled") {
            uploadedFileResponses.push(result.value.data);
          } else {
            openErrorAlertPopup(result.reason);
          }
        });

        const allSucceeded = results.length === uploadedFileResponses.length;
        const someSucceeded =
          results.filter((result) => result.status === "fulfilled").length > 0;

        if (allSucceeded) {
          showToast("success", "Dateien wurden erfolgreich hochgeladen.");
        } else if (someSucceeded) {
          showToast(
            "success",
            "Einige Dateien konnten nicht hochgeladen. Bitte prüfen Sie welche Dateien nicht vorhanden sind und versuchen Sie es erneut."
          );
        }

        setIsUploading(false);
      }
    );
  }

  function handleClickOpenFileInput(e: React.MouseEvent) {
    e.stopPropagation();
    open();
  }

  function handleChooseWhichUser(asSystemUser: boolean) {
    setShowSystemUserQuestionModal(false);
    handleUploadFiles(filesToUpload, asSystemUser);
  }

  return (
    <>
      <div className="documents-dropzone" {...getRootProps()}>
        <input {...getInputProps()} />
        <DocumentsDropzoneText />
        <div className="upload-icon-container">
          <Icon className="upload-icon" name={IconName.Upload} />
        </div>
        <Button
          className={classnames("upload-button", {
            "m-loader m-loader--light m-loader--left": isUploading
          })}
          color="brand"
          onClick={handleClickOpenFileInput}
        >
          Datei(en) auswählen
        </Button>
      </div>
      <WhichUserModal
        isOpen={showSystemUserQuestionModal}
        questionText="Wie sollen die Dateien hochgeladen werden?"
        toggle={() =>
          setShowSystemUserQuestionModal(!showSystemUserQuestionModal)
        }
        onClickSelf={() => handleChooseWhichUser(false)}
        onClickSystemUser={() => handleChooseWhichUser(true)}
      />
    </>
  );
}

function DocumentsDropzoneText() {
  return (
    <div className="upload-text">
      <p>
        Um neue Dokumente hochzuladen, können Sie diese hier per Drag&Drop
        ablegen.
      </p>
    </div>
  );
}

export { DocumentsDropzone };
