import { useQuery } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../api";
import type { components } from "../types/api.types";
import urls from "../urls";

export type VariantDetail = components["schemas"]["VariantDetail"];

export function useVariantDetail(variantId: number) {
  const {
    data: variantDetail,
    isLoading,
    error
  } = useQuery({
    queryKey: ["variantDetail", { variantId }],
    queryFn: () => fetchVariantDetail(variantId)
  });

  async function fetchVariantDetail(variantId: number) {
    const url = urls.api.variantDetail(variantId);
    const response = await apiWithoutCamelization.get<VariantDetail>(url);
    return response.data;
  }

  return {
    variantDetail,
    isLoading,
    error
  };
}
