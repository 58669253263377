import { useQuery } from "@tanstack/react-query";
import api from "../../../api";
import urls from "../../../urls";
import type { Log } from "../LoggingView.types";

export function useMeteringConceptLogMessages(variantId: number) {
  const { data, error, isLoading } = useQuery({
    queryKey: ["metering-concept-log-messages", variantId],
    queryFn: () => fetchMeteringConceptLogMessages(variantId)
  });

  async function fetchMeteringConceptLogMessages(variantId: number) {
    const response = await api.get<Array<Log>>(
      urls.api.meteringConceptLogMessages(variantId)
    );

    return response.data;
  }

  return { logs: data, error, isLoading };
}
