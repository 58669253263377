import saveAs from "file-saver";
import api from "../../api";

/** Takes a URL to a file and downloads it as a file with the given name. */
export async function downloadFileAsName(url: string, name: string) {
  const response = await api.get(url, {
    responseType: "blob",
    transformResponse: []
  });

  const blob = new Blob([response.data]);

  saveAs(blob, name);
}
