import React from "react";
import { LoadprofileType } from "../../../utils/enums";
import {
  CustomForm,
  getFormFieldsFromResponse
} from "../../CustomForm/CustomForm";
import FormItems from "../../DynamicForm/FormItems";
import { withLoadprofileFields } from "../../DynamicForm/FormItems/withLoadprofileFields";

const FormItemsWithLoadprofileFields = withLoadprofileFields(FormItems);

function LoadprofileSelectionStep({
  responseData,
  allFormFields,
  onStepDone,
  buttonContainer,
  patchUrl,
  variantId
}) {
  const formFieldsArray = getFormFieldsFromResponse(
    ["loadprofile", "yearlyEnergy"],
    allFormFields,
    responseData
  );
  formFieldsArray[0]["loadprofileType"] = LoadprofileType.Generation;
  formFieldsArray[0]["initialLoadprofileListOpen"] = true;

  const CustomFormItemsComponent = (props) => (
    <FormItemsWithLoadprofileFields
      initialLoadprofileListOpen={true}
      loadprofileType="generation"
      variantId={variantId}
      {...props}
    />
  );

  return (
    <CustomForm
      buttonContainer={buttonContainer}
      CustomFormItemsComponent={CustomFormItemsComponent}
      formFields={formFieldsArray}
      key="loadprofile"
      patchUrl={patchUrl}
      submitButtonText="Weiter"
      onSubmit={onStepDone}
    />
  );
}

export { LoadprofileSelectionStep };
