import React from "react";
import { useTranslation } from "react-i18next";
import { FormItem } from "../../../DynamicForm/FormItem/FormItem";
import type {
  Choice,
  ChoiceValue
} from "../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import type {
  FormFieldType,
  FormFieldValue
} from "../../../DynamicForm/FormItems/FormField/FormField";
import type { EDA_FORM_FIELDS } from "../CreateEdaVersionModal.constants";
import {
  INPUT_LABELS,
  isRequiredField
} from "../CreateEdaVersionModal.constants";

type InputProps = {
  formField: EDA_FORM_FIELDS;
  requiredFieldErrors: Array<string>;
  updateFormValues: (formField: EDA_FORM_FIELDS, value: FormFieldValue) => void;
  type: FormFieldType;
  allowInput?: boolean;
  value?: FormFieldValue;
} & (
  | {
      choices: Choice<ChoiceValue>[] | undefined;
      type: FormFieldType.Field | FormFieldType.Dropdown;
    }
  | {
      type:
        | FormFieldType.Float
        | FormFieldType.Integer
        | FormFieldType.String
        | FormFieldType.Boolean;
    }
);

function Input({
  formField,
  type,
  requiredFieldErrors,
  updateFormValues,
  allowInput,
  value,
  ...rest
}: InputProps) {
  const { t } = useTranslation();
  const requiredFieldErrorText = t("errors.Required");

  if ("choices" in rest) {
    return (
      <FormItem
        {...formFieldProps({
          formField,
          requiredFieldErrors,
          updateFormValues,
          requiredFieldErrorText: requiredFieldErrorText
        })}
        allowInput={allowInput}
        choices={rest.choices}
        type={type}
        value={value}
      />
    );
  } else
    return (
      <FormItem
        {...formFieldProps({
          formField,
          requiredFieldErrors,
          updateFormValues,
          requiredFieldErrorText: requiredFieldErrorText
        })}
        allowInput={allowInput}
        type={type}
        value={value}
      />
    );
}

function formFieldProps({
  formField,
  requiredFieldErrors,
  updateFormValues,
  requiredFieldErrorText
}: {
  formField: EDA_FORM_FIELDS;
  requiredFieldErrorText: string;
  requiredFieldErrors: Array<string>;
  updateFormValues: (formField: EDA_FORM_FIELDS, value: FormFieldValue) => void;
}) {
  return {
    errors: isRequiredFieldMissing({
      formField,
      missingFieldsList: requiredFieldErrors
    })
      ? [requiredFieldErrorText]
      : undefined,
    required: isRequiredField(formField),
    label: INPUT_LABELS[formField].label,
    name: formField,
    onInput: (_, value) => updateFormValues(formField, value)
  };
}

function isRequiredFieldMissing({
  missingFieldsList,
  formField
}: {
  missingFieldsList: Array<string>;
  formField?: string;
}) {
  return (
    typeof formField !== "undefined" && missingFieldsList.includes(formField)
  );
}

export { Input, InputProps };
