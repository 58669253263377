import React, { useState } from "react";

import api from "../../../api";
import urls from "../../../urls";
import type { Todo } from "../../../utils/backend-types";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../../Buttons/Button/Button";
import { SpinButton } from "../../Buttons/SpinButton/SpinButton";
import { CustomForm } from "../../CustomForm/CustomForm";
import { AnimatedLoadingIcon } from "../../Icons/AnimatedLoadingIcon/AnimatedLoadingIcon";
import { TodoSwitch } from "../AutoFillTodoModal/TodoDecisionWidget";
import type { TodoUserDecision } from "../common";
import { TodoSystemDecision } from "../common";
import { Description } from "../TodoDescription/TodoDescription";

import "./ClarifyTodoModal.scss";

declare const SITE_NAME: string;

interface ClarifyTodoModalProps {
  isOpen: boolean;
  todo: Todo;
  currentUserDecision: TodoUserDecision;
  todoIsFinished: boolean;
  onToggle: () => void;
  onTodoClarified: (
    assessmentCompleted: boolean,
    localUserDecision: TodoUserDecision,
    localFinished: boolean
  ) => void;
}

function ClarifyTodoModal({
  isOpen,
  todo,
  currentUserDecision,
  todoIsFinished,
  onToggle,
  onTodoClarified
}: ClarifyTodoModalProps) {
  const [localUserDecision, setLocalUserDecision] =
    useState(currentUserDecision);
  const [localFinished, setLocalFinished] = useState(todoIsFinished);
  const [assessmentCompleted, setAssessmentCompleted] = useState(false);

  function handleAssessmentCompleted() {
    setAssessmentCompleted(true);
  }

  function handleClickSave() {
    onTodoClarified(assessmentCompleted, localUserDecision, localFinished);
    onToggle();
  }

  return (
    <Modal
      className="ClarifyTodoModal"
      isOpen={isOpen}
      size="lg"
      toggle={onToggle}
    >
      <ModalHeader toggle={onToggle}>{todo.label}</ModalHeader>
      <ModalBody scrollable>
        <div className="intro">
          <p>
            Um zu bewerten, ob Sie diese Aufgabe erfüllen müssen, sind weitere
            Informationen erforderlich. Beantworten Sie hierzu bitte die noch
            offenen Fragen.
          </p>
        </div>
        <div className="description">
          <h5>Beschreibung</h5>
          <Description
            regulatoryDuty={todo.regulatoryDuty}
            userDefinedDescription={todo.description}
          />
          <h5>Offene Fragen</h5>
          <RuleAssessment
            todoId={todo.id}
            onAssessmentCompleted={handleAssessmentCompleted}
          />
          <h5>Status</h5>
          <TodoSwitch
            finished={localFinished}
            userDecision={localUserDecision}
            onChangeUserDecision={(decision, finished: boolean) => {
              setLocalUserDecision(decision);
              setLocalFinished(finished);
            }}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onToggle}>
          Schließen
        </Button>
        {
          <SpinButton color="primary" spin={false} onClick={handleClickSave}>
            Speichern
          </SpinButton>
        }
      </ModalFooter>
    </Modal>
  );
}

interface RuleAssessmentProps {
  todoId: number;
  onAssessmentCompleted: () => void;
}

function RuleAssessment({
  todoId,
  onAssessmentCompleted
}: RuleAssessmentProps) {
  const [loadingUpdatedTodo, setLoadingUpdatedTodo] = useState(false);
  const [updatedTodo, setUpdatedTodo] = useState<Todo | null>(null);

  function handleDone() {
    setLoadingUpdatedTodo(true);
    onAssessmentCompleted();

    api.get<Todo>(urls.api.todosDetail(todoId)).then((response) => {
      setUpdatedTodo(response.data);
      setLoadingUpdatedTodo(false);
    });
  }

  function getInfoText() {
    const defaultText = (
      <span>
        Um zu bewerten, ob Sie diese Aufgabe erfüllen müssen, sind weitere
        Informationen erforderlich. Beantworten Sie hierzu bitte die noch
        offenen Fragen. Sollten Sie die Fragen aktuell nicht beantworten können,
        können Sie die Aufgabe zunächst mit dem Status &quot;zu klären&quot; in
        Ihre Aufgabenliste übernehmen und fehlende Informationen später
        eintragen. Bei Rückfragen steht Ihnen Ihr Ansprechpartner bei{" "}
        {SITE_NAME} gerne zur Verfügung.
      </span>
    );

    if (!updatedTodo) {
      return defaultText;
    }

    switch (updatedTodo.systemDecision) {
      case TodoSystemDecision.Applicable:
        return (
          <span>
            Auf Basis Ihrer Angaben gehen wir davon aus, dass die betreffende
            Aufgabe <strong>erfüllt werden muss</strong>. Sofern dies noch nicht
            erfolgt ist, empfehlen wir den Status <i>Als offen übernehmen</i>{" "}
            auszuwählen. Die Aufgabe wird dann als offene Aufgaben in Ihre Liste
            übernommen. Sofern die Aufgabe bereits erfüllt wurde, können Sie{" "}
            <i>Als erledigt übernehmen</i> auswählen. Die Aufgabe wird dann als
            bereits erledigt in Ihre Liste übernommen.
          </span>
        );
      case TodoSystemDecision.NotApplicable:
        return (
          <span>
            Auf Basis Ihrer Angaben wurde die Aufgabe als{" "}
            <strong>nicht zu erfüllen</strong> erkannt. Sie können{" "}
            <i>Nicht übernehmen</i> auswählen und speichern. Die Aufgabe wird
            nicht in Ihre Aufgabenliste übernommen.
          </span>
        );
      case TodoSystemDecision.CantTell:
        return (
          <span>
            Auf Basis Ihrer Angabe können wir keine eindeutige Entscheidung
            vorschlagen. Bitte treffen Sie eigenständig eine Wahl oder
            kontaktieren Ihren Ansprechpartner bei {SITE_NAME}.
          </span>
        );
      default:
        return defaultText;
    }
  }

  if (!loadingUpdatedTodo && !updatedTodo) {
    const url = urls.api.todoRuleAssessment(todoId);

    return (
      <CustomForm
        autoSubmitEmptyFirstForm
        postUrl={url}
        submitButtonText="Weiter"
        onPollSuccess={handleDone}
        onSubmit={handleDone}
      />
    );
  } else if (loadingUpdatedTodo) {
    return <AnimatedLoadingIcon />;
  } else {
    return <div>{getInfoText()}</div>;
  }
}

export { ClarifyTodoModal, ClarifyTodoModalProps };
