import { MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import { DatesProvider } from "@mantine/dates";
import "@mantine/dates/styles.css";
import "@mantine/tiptap/styles.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "mantine-react-table/styles.css";
import React from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes
} from "react-router-dom";
import { mantineTheme } from "../../mantine/mantineTheme";
import { ShowStaffViewContext } from "../App/ShowStaffViewContext";
import { SiteStatus } from "../SiteStatus/SiteStatus";
import { Mode } from "../Todos/common";
import { TodosWithoutProjectIdInRoute as Todos } from "../Todos/Todos";
import { AdminDashboardLayout } from "./AdminDashboardLayout/AdminDashboardLayout";
import { LocationOverview } from "./LocationOverview/LocationOverview";
import { MeteringServiceProviderTable } from "./MeteringServiceProviderTable/MeteringServiceProviderTable";

const queryClient = new QueryClient();

enum AdminDashboardTab {
  UniversalTodos = "aufgaben",
  SiteStatus = "liegenschaftsuebersicht",
  LocationOverview = "lokationsuebersicht",
  MeteringServiceProvider = "messstellenbetreiber"
}

export const UNIVERSAL_TODOS_VARIANT_ID = 321;
export const UNIVERSAL_TODOS_PROJECT_ID =
  "c34bf701-657b-42ba-a4f5-87d34265b94a";

function AdminDashboard() {
  return (
    <MantineProvider theme={mantineTheme}>
      <DatesProvider settings={{ locale: "de" }}>
        <AdminDashboardLayout>
          <div className="AdminDashboard">
            <QueryClientProvider client={queryClient}>
              <ShowStaffViewContext.Provider value={true}>
                <Routes>
                  <Route
                    element={
                      <Navigate replace to={AdminDashboardTab.UniversalTodos} />
                    }
                    index
                  />
                  <Route
                    element={
                      <SiteStatus sites={[]} title="Liegenschaftsübersicht" />
                    }
                    path={`${AdminDashboardTab.SiteStatus}/*`}
                  />
                  <Route
                    element={
                      <Todos
                        mode={Mode.Universal}
                        projectId={UNIVERSAL_TODOS_PROJECT_ID}
                        variantId={UNIVERSAL_TODOS_VARIANT_ID}
                      />
                    }
                    path={`${AdminDashboardTab.UniversalTodos}/*`}
                  />
                  <Route path="msb-prozess">
                    <Route
                      element={
                        <Navigate
                          replace
                          to={AdminDashboardTab.MeteringServiceProvider}
                        />
                      }
                      index
                    />
                    <Route
                      element={<MeteringServiceProviderTable />}
                      path={`${AdminDashboardTab.MeteringServiceProvider}/*`}
                    />
                    <Route
                      element={<LocationOverview />}
                      path={`${AdminDashboardTab.LocationOverview}/*`}
                    />
                  </Route>
                </Routes>
              </ShowStaffViewContext.Provider>
            </QueryClientProvider>
          </div>
        </AdminDashboardLayout>
      </DatesProvider>
    </MantineProvider>
  );
}

function RouterWrapper(props) {
  return (
    <Router>
      <Routes>
        <Route
          element={<AdminDashboard {...props} />}
          path="/admin-dashboard/*"
        />
      </Routes>
    </Router>
  );
}

export {
  RouterWrapper as AdminDashboard,
  AdminDashboard as AdminDashboardNoRouter
};
