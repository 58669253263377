import {
  Avatar,
  Card,
  Center,
  Group,
  List,
  Popover,
  Stack,
  Text
} from "@mantine/core";
import React from "react";
import { Link } from "react-router-dom";
import DefaultUserpic from "../../../default/assets/img/anonymous_user.png";
import { PUBLIC_ROUTES, ROUTES } from "../../routes";
import type { CurrentUser } from "../../utils/backend-types";
import { SiteName } from "../../utils/enums";
import { Icon } from "../BuildingBlocks/Icon/Icon";
import { IconName } from "../BuildingBlocks/Icon/types";
import { Button } from "../Buttons/Button/Button";
import { logout } from "../Login/utils/logout";
import "./UserMenu.scss";

declare const BACKEND_BASE_URL: string | undefined;

const BASE_URL = BACKEND_BASE_URL || "";

interface UserMenuProps {
  currentUser: CurrentUser | undefined;
  siteName: SiteName;
}

function onLogout(e: React.MouseEvent) {
  e.preventDefault();
  logout();
}

const secondaryLinks: {
  url: string;
  label: string;
  siteNames: ReadonlyArray<SiteName>;
}[] = [
  {
    url: `${BASE_URL}/seiten/datenschutz`,
    label: "Datenschutz",
    siteNames: [SiteName.Smarendo]
  },
  {
    url: "https://www.alva-energie.de/datenschutz",
    label: "Datenschutz",
    siteNames: [SiteName.Alva]
  },
  {
    url: "https://www.node.energy/legal/avv",
    label: "Datenschutz",
    siteNames: [SiteName.Optinode]
  },
  {
    url: `${BASE_URL}/seiten/SBOM`,
    label: "SBOM",
    siteNames: [SiteName.Smarendo, SiteName.Optinode, SiteName.Alva]
  }
];

function UserMenu({ currentUser, siteName }: UserMenuProps) {
  return (
    <Center className="UserMenu" h="100%" p="9px 12px">
      <Popover
        arrowSize={20}
        position="bottom"
        styles={{
          arrow: {
            backgroundColor: "#7948e1"
          }
        }}
        withArrow
      >
        <Popover.Target>
          <div className="user-menu-target">
            <UserAvatar currentUser={currentUser} />
          </div>
        </Popover.Target>
        <Popover.Dropdown className="user-menu-dropdown" p={0}>
          <Card
            className="user-menu-dropdown-card"
            padding={0}
            shadow="sm"
            withBorder
          >
            <Card.Section className="user-menu-header">
              <Group wrap="nowrap">
                <UserAvatar currentUser={currentUser} />
                <Stack gap={0}>
                  <Text
                    c="#d9dae3"
                    component={Link}
                    fw={400}
                    size="xl"
                    to={ROUTES.settings}
                  >
                    {currentUser?.name}
                  </Text>
                  <Text c="#aeafc2" fw={300}>
                    {currentUser?.email}
                  </Text>
                </Stack>
              </Group>
            </Card.Section>

            <Stack p="xl">
              <List center mb="md" size="lg" spacing="lg">
                <UserMenuItem
                  iconName={IconName.Cogwheel1}
                  internal
                  label="Einstellungen"
                  url={ROUTES.settings}
                />
                {siteName !== SiteName.Alva && (
                  <UserMenuItem
                    iconName={IconName.QuestionCircle2}
                    internal
                    label="Support"
                    url={PUBLIC_ROUTES.support}
                  />
                )}
              </List>
              <Button
                className="logout-button"
                color="brand"
                outline
                onClick={onLogout}
              >
                Logout
              </Button>
              <Group
                className="user-menu-secondary-links"
                gap="md"
                justify="flex-end"
              >
                {secondaryLinks
                  .filter(({ siteNames }) => siteNames.includes(siteName))
                  .map(({ url, label }) => (
                    <div key={label}>
                      <a href={url}>{label}</a>
                    </div>
                  ))}
              </Group>
            </Stack>
          </Card>
        </Popover.Dropdown>
      </Popover>
    </Center>
  );
}

function UserAvatar({ currentUser }: { currentUser: CurrentUser | undefined }) {
  return (
    <Avatar
      alt="user"
      className="user-menu-avatar"
      name={currentUser?.name}
      size="4rem"
      src={currentUser?.image}
    >
      <img alt="user" className="fallback-profile-pic" src={DefaultUserpic} />
    </Avatar>
  );
}

interface UserMenuItemProps {
  url: string;
  internal?: boolean;
  label: string;
  iconName: IconName;
}

function UserMenuItem({
  url,
  internal = false,
  label,
  iconName
}: UserMenuItemProps) {
  return (
    <List.Item icon={<Icon className="user-menu-icon" name={iconName} />}>
      {internal ? (
        <Text c="#6f727d" component={Link} td="none" to={url}>
          {label}
        </Text>
      ) : (
        <Text c="#6f727d" component="a" href={url} td="none">
          {label}
        </Text>
      )}
    </List.Item>
  );
}

export { UserMenu, UserMenuProps };
