import PropTypes from "prop-types";
import React from "react";
import { generatePath, Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

import { ROUTES } from "../../../../routes";
import { THEME_VARS } from "../../../../utils/constants";
import WithHover from "../../../../utils/WithHover";
import { IconWithLink } from "../../../BuildingBlocks/Icon/IconWithLink/IconWithLink";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import { DeleteIcon } from "../../../Buttons/DeleteIcon";

import "./VariantRow.scss";

function VariantRow({
  variant,
  projectId,
  isHovered,
  onClickDeleteVariant,
  onClickCopyVariant,
  ...other
}) {
  const variantPath = generatePath(ROUTES.analyzerVariantStructure, {
    variantId: variant.id,
    projectId: projectId
  });

  return (
    <Row className="variant-row" {...other}>
      <Col md={10} sm={9}>
        <div className="variant-name">
          <div className="ml-3">
            <Link to={variantPath}>{variant.name}</Link>
          </div>
        </div>
      </Col>
      <Col className="icons" md={2} sm={3}>
        {isHovered && (
          <VariantIcons
            onClickCopyVariant={onClickCopyVariant}
            onClickDeleteVariant={onClickDeleteVariant}
          />
        )}
      </Col>
    </Row>
  );
}

function VariantIcons({ onClickDeleteVariant, onClickCopyVariant }) {
  return (
    <React.Fragment>
      <IconWithLink
        name={IconName.Copy}
        style={{ color: THEME_VARS.primaryColor, cursor: "pointer" }}
        tooltipText="Variante duplizieren"
        onClick={onClickCopyVariant}
      />
      <DeleteIcon
        tooltipText="Variante löschen"
        onClick={onClickDeleteVariant}
      />
    </React.Fragment>
  );
}

const VariantRowWithHover = WithHover(VariantRow);

VariantRow.propTypes = {
  variant: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
  isHovered: PropTypes.bool.isRequired,
  onClickDeleteVariant: PropTypes.func.isRequired,
  onClickCopyVariant: PropTypes.func.isRequired
};

export { VariantRowWithHover as VariantRow };
