import type { CellContext, ColumnDef } from "@tanstack/react-table";
import React from "react";
import type { Payment } from "../../../../../../utils/backend-types";
import { IconHelpText } from "../../../../../IconHelpText/IconHelpText";
import { NumberCell } from "../Cells/NumberCell";
import type { UpdatePaymentsValue, Value } from "../PaymentsTab";

function getDeliverySharePredictionColumn(
  updatePaymentsValue: UpdatePaymentsValue
): ColumnDef<Payment> {
  return {
    header: () => (
      <span>
        Anteil Direktlieferung{" "}
        <IconHelpText
          helpText="Insofern die Abschlagszahlungen nicht auf Grundlage der
              historischen Messwerte ermittelt werden sollen oder
              können und sich die voraussichtliche Stromlieferung aus
              zwei Quellen zusammensetzt (Netzstromweiterleitung und
              PV-Direktlieferung), können Sie hier festlegen, welchen
              Anteil die Direktlieferungen (PV) voraussichtlich
              einnehmen werden. Dies ermöglicht eine präzisere
              Schätzung des voraussichtlichen Rechnungsbetrags.
              Wenn Sie keinen Anteil hinterlegen, wird ein
              PV-Stromanteil von 50% angenommen."
        />
      </span>
    ),
    enableSorting: false,
    accessorKey: "directDeliverySharePrediction",
    cell: (cellInfo: CellContext<Payment, number>) => (
      <div className="directDeliverySharePredictionCell">
        <NumberCell
          disabled={cellInfo.row.original.estimatedAdvancePayments !== null}
          key={cellInfo.row.original.year}
          max={100}
          min={0}
          unit="%"
          updateValue={(value: Value) =>
            updatePaymentsValue(
              value,
              {
                year: cellInfo.row.original.year,
                month: cellInfo.row.original.month
              },
              "directDeliverySharePrediction"
            )
          }
          value={cellInfo.getValue()}
        />
      </div>
    )
  };
}

export { getDeliverySharePredictionColumn };
