import type { DateTime } from "luxon";
import { backendDateOrDateTimeToLuxonDateTime } from "./backendDateOrDateTimeToLuxonDateTime";

/** Calls `backendDateOrDateTimeToLuxonDateTime` but returns `null` if `backendDate` is null. */
export function backendDateOrDateTimeToLuxonDateTimeOrNull(
  backendDate: string | null
): DateTime | null {
  if (!backendDate) {
    return null;
  }

  return backendDateOrDateTimeToLuxonDateTime(backendDate);
}
