import React from "react";
import { usePersons } from "../../../../../hooks/usePersons";
import { FormItem, FormItemLabel } from "../../../FormItem/FormItem";
import { FormItemInfoText } from "../../../FormItem/FormItemInfoText";
import type { FormFieldData, FormItemsProps } from "../../FormItems";
import type { ChoiceValue } from "../Dropdown/TsDropdown";
import { TsDropdown } from "../Dropdown/TsDropdown";

interface RepresentationFieldsProps extends Omit<FormItemsProps, "formItems"> {
  fieldNames: [string];
  formItems: Record<string, FormFieldData>;
  variantId: number;
  personId: number;
}
const REPRESENTED_BY_FIELD = "taxFormsCompanyRepresentedBy";
const REPRESENTATION_TYPE_FIELD = "taxFormsRepresentationType";
const SUBMISSION_OF_FORMS_IN_OWN_NAME_FIELD = "taxFormsSubmissionInOwnName";

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide
 * designed to be used for the representation fields in the tax forms for a person
 */
function RepresentationFields({
  onInput,
  formItems,
  formValues,
  formName,
  variantId,
  allowInput,
  formErrors,
  personId
}: RepresentationFieldsProps) {
  const { persons } = usePersons(variantId);

  const filteredPersons = persons.filter(
    (person) => person.shouldRegulatoryDutiesBeChecked && person.id !== personId
  );
  const personOptions = filteredPersons.map((person) => {
    return {
      displayName: person.name,
      value: person.id
    };
  });
  return (
    <div className="RepresentationFields">
      <FormItem
        {...formItems[SUBMISSION_OF_FORMS_IN_OWN_NAME_FIELD]}
        allowInput={allowInput}
        errors={formErrors[SUBMISSION_OF_FORMS_IN_OWN_NAME_FIELD]}
        formName={formName}
        value={formValues[SUBMISSION_OF_FORMS_IN_OWN_NAME_FIELD]}
        onInput={onInput}
      />
      {formValues[SUBMISSION_OF_FORMS_IN_OWN_NAME_FIELD] === false && (
        <>
          <FormItemLabel
            id="represented-by-field-dropdown-label"
            label="Name des Unternehmens, das Sie vertritt"
          />
          <TsDropdown
            choices={personOptions}
            defaultValue={
              formItems[REPRESENTED_BY_FIELD].initialValue
                ? (formItems[REPRESENTED_BY_FIELD].initialValue as ChoiceValue)
                : ""
            }
            errorTexts={formErrors[REPRESENTED_BY_FIELD]}
            name={formItems[REPRESENTED_BY_FIELD].name}
            onChange={onInput}
          />
          <FormItemInfoText
            infoText={formItems[REPRESENTED_BY_FIELD].infoText ?? ""}
          />
          <FormItem
            {...formItems[REPRESENTATION_TYPE_FIELD]}
            allowInput={allowInput}
            errors={formErrors[REPRESENTATION_TYPE_FIELD]}
            formName={formName}
            value={formValues[REPRESENTATION_TYPE_FIELD]}
            onInput={onInput}
          />
        </>
      )}
    </div>
  );
}

export { RepresentationFields };
