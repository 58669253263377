import { useQueryClient } from "@tanstack/react-query";
import PropTypes from "prop-types";
import React, { useState } from "react";

import urls from "../../../urls";
import { showToast } from "../../../utils/toast";
import { Alert, AlertColor } from "../../Alert/Alert";
import { OptionsForm } from "../../OptionsForm/OptionsForm";

interface ReportFormProps {
  projectId: string;
  variantId: number;
}

function ReportForm(props: ReportFormProps) {
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [formIteration, setFormIteration] = useState<number>(0);
  const queryClient = useQueryClient();
  const { projectId, variantId } = props;
  const apiUrl: string = urls.api.reportsOptions(projectId);
  const hiddenFields = ["variant"];
  const initialValues = { variants: [variantId] };
  const nonFieldData = {
    variant: variantId
  };

  function handleClickSubmit() {
    setShowInfo(true);
  }

  function handleSuccess() {
    queryClient.invalidateQueries({
      queryKey: ["reports", { variantId }]
    });

    showToast(
      "success",
      "Ein neuer Report wurde erstellt und steht zum herunterladen bereit."
    );

    setFormIteration(formIteration + 1);
  }

  return (
    <div className="ReportForm">
      <OptionsForm
        hiddenFields={hiddenFields}
        initialValues={initialValues}
        key={formIteration}
        nonFieldData={nonFieldData}
        optionsUrl={apiUrl}
        postUrl={apiUrl}
        submitButtonText="Generieren"
        onFirstSubmit={handleClickSubmit}
        onPollSuccess={handleSuccess}
        onSubmit={() => {
          /*do nothing - wait for onPollSuccess*/
        }}
      />
      {showInfo && (
        <Alert
          color={AlertColor.Info}
          style={{ marginTop: "20px" }}
          withCloseButton
          onClose={() => setShowInfo(!showInfo)}
        >
          <span>
            Hinweis: Teilweise kommt es zu Problemen beim Erstellen eines
            Reports. Oftmals hilft es maximal drei Varianten in die
            Ergebnisübersicht des Reports aufzunehmen. Sollten Sie dennoch
            Probleme haben, kontaktieren Sie uns bitte.
          </span>
        </Alert>
      )}
    </div>
  );
}

ReportForm.propTypes = {
  projectId: PropTypes.string.isRequired,
  variantId: PropTypes.number.isRequired
};

export { ReportForm };
