import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import type { Column, Filter, RowInfo } from "react-table";
import { UncontrolledButtonDropdown } from "reactstrap";
import { downloadAsExcel } from "../../../utils/files/downloadAsExcel";
import { gatherExcelExportDataFromReactTableColumns } from "../../../utils/files/gatherExcelExportDataFromReactTableColumns";
import { matchFullTextCaseInsensitive } from "../../../utils/text-utils";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "../../BuildingBlocks/Dropdown/Dropdown";
import { IconName } from "../../BuildingBlocks/Icon/types";
import { Button } from "../../Buttons/Button/Button";
import { IconButton } from "../../Buttons/IconButton/IconButton";
import { CustomReactTable } from "../../CustomReactTable/CustomReactTable";
import type { CellToStringConverters } from "../../CustomReactTable/CustomReactTableHooks";
import { useCustomReactTableDataExport } from "../../CustomReactTable/CustomReactTableHooks";
import type { FormattedEda } from "../../EnergyData/common";
import {
  MEDIUM_LABEL_MAP,
  ORIGIN_LABEL_MAP,
  TYPE_LABEL_MAP,
  formatEda
} from "../../EnergyData/common";
import type {
  Acquisition,
  Tag as AcquisitionTag
} from "../../EnergyData/EnergyDataView/EnergyDataView";
import { anchorTooltip } from "../../Tooltip/Tooltip";
import type { AcquisitionWithSuggestion } from "../EdaVisualization/EdaVisualization";
import "./EdaTable.scss";

interface EdaTableProps {
  acquisitions: Array<AcquisitionWithSuggestion>;
  loading: boolean;
  className: string;
  exportButtonContainerNode?: HTMLElement;
  readOnly: boolean;
  onClickLabelRow: (id: number) => void;
  onSaveSuggestion: (acquisition: Acquisition) => void;
}

function EdaTable({
  acquisitions,
  onClickLabelRow,
  onSaveSuggestion,
  loading,
  className,
  exportButtonContainerNode
}: EdaTableProps) {
  const [exportIsLoading, setExportIsLoading] = useState(false);

  function defaultFilterMethod(filter: Filter, row) {
    return !!matchFullTextCaseInsensitive(row[filter.id], filter.value);
  }

  function selectedCellProps(
    _,
    rowInfo: RowInfo,
    columnInfo: Column<AcquisitionWithSuggestion>
  ) {
    const clickable = columnInfo.id === "label" && !!rowInfo.original.id;

    return {
      onClick: clickable
        ? function () {
            onClickLabelRow(rowInfo.original.id);
          }
        : null,
      className: clickable ? "clickable-cell" : ""
    };
  }

  const hasSuggestions = acquisitions.some((a) => a.suggestion === true);
  const formattedAcquisitions = acquisitions.map(formatEda);

  const tableColumns: Array<Column<FormattedEda>> = [
    {
      Header: "ID",
      accessor: "publicId",
      minWidth: hasSuggestions ? 110 : 45,
      Cell: (data) => (
        <React.Fragment>
          {data.original.suggestion ? (
            <Button
              color="primary"
              disabled={loading}
              size="sm"
              onClick={() => onSaveSuggestion(data.original.write)}
            >
              Übernehmen
            </Button>
          ) : (
            data.value
          )}
        </React.Fragment>
      )
    },
    {
      Header: "Bezeichnung",
      accessor: "label",
      minWidth: 350,
      Cell: (data) => (
        <span>
          {data.original.suggestion ? <i>Neu: </i> : null} {data.value}
        </span>
      )
    },
    {
      Header: "Medium",
      accessor: "medium",
      Filter: ({ filter, onChange }) => (
        <div style={{ textAlign: "left" }}>
          <UncontrolledButtonDropdown>
            <DropdownToggle caret>
              {filter ? filter.value : "Alle anzeigen"}
            </DropdownToggle>
            <DropdownMenu strategy="fixed">
              <DropdownItem onClick={() => onChange("Alle anzeigen")}>
                Alle anzeigen
              </DropdownItem>
              <DropdownItem divider />
              {Object.values(MEDIUM_LABEL_MAP).map((mediumLabel) => (
                <DropdownItem
                  key={mediumLabel}
                  onClick={() => onChange(mediumLabel)}
                >
                  {mediumLabel}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      ),
      filterMethod: (filter: Filter, row) => {
        return (
          filter.value === "Alle anzeigen" || filter.value === row[filter.id]
        );
      },
      minWidth: 140
    },
    {
      Header: "Typ",
      accessor: "type",
      Filter: ({ filter, onChange }) => (
        <div style={{ textAlign: "left" }}>
          <UncontrolledButtonDropdown>
            <DropdownToggle caret>
              {filter ? filter.value : "Alle anzeigen"}
            </DropdownToggle>
            <DropdownMenu strategy="fixed">
              <DropdownItem onClick={() => onChange("Alle anzeigen")}>
                Alle anzeigen
              </DropdownItem>
              <DropdownItem divider />
              {Object.values(TYPE_LABEL_MAP).map((typeLabel) => (
                <DropdownItem
                  key={typeLabel}
                  onClick={() => onChange(typeLabel)}
                >
                  {typeLabel}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      ),
      filterMethod: (filter: Filter, row) => {
        return (
          filter.value === "Alle anzeigen" || filter.value === row[filter.id]
        );
      },
      minWidth: 140
    },
    {
      Header: "Details",
      accessor: "detailLabel",
      minWidth: 200
    },
    {
      Header: "Referenzen",
      id: "references",
      accessor: "references",
      Cell: (data) => <span>{data.value.join(", ")}</span>,
      minWidth: 200
    },
    {
      Header: "Klassifizierung",
      accessor: "tags",
      Cell: (data) => <TagsCell {...data} />,
      filterMethod: (filter: Filter, row) => {
        return !!matchFullTextCaseInsensitive(
          row[filter.id].map((value: AcquisitionTag) => value.name).join(),
          filter.value
        );
      },
      minWidth: 220,
      sortMethod: function (a, b) {
        const aFormatted = a.map((value) => value.name).join();
        const bFormatted = b.map((value) => value.name).join();

        return aFormatted < bFormatted ? -1 : 1;
      }
    },
    {
      Header: "Herkunft",
      accessor: "origin",
      Filter: ({ filter, onChange }) => (
        <div style={{ textAlign: "left" }}>
          <UncontrolledButtonDropdown>
            <DropdownToggle caret>
              {filter ? filter.value : "Alle anzeigen"}
            </DropdownToggle>
            <DropdownMenu strategy="fixed">
              <DropdownItem onClick={() => onChange("Alle anzeigen")}>
                Alle anzeigen
              </DropdownItem>
              <DropdownItem divider />
              {Object.values(ORIGIN_LABEL_MAP).map((originLabel) => (
                <DropdownItem
                  key={originLabel}
                  onClick={() => onChange(originLabel)}
                >
                  {originLabel}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      ),
      filterMethod: (filter: Filter, row) => {
        return (
          filter.value === "Alle anzeigen" || filter.value === row[filter.id]
        );
      },
      minWidth: 140
    }
  ];
  const columnsToExport =
    gatherExcelExportDataFromReactTableColumns(tableColumns);
  const cellToStringConverters: CellToStringConverters = {
    tags: (tags: Array<AcquisitionTag>) =>
      tags.map((tag) => tag.name).join("; ")
  };

  const { setTableRef, exportData } = useCustomReactTableDataExport({
    columnsToExport,
    cellToStringConverters
  });

  async function handleClickExport() {
    setExportIsLoading(true);

    const rows = await exportData();
    downloadAsExcel(rows, "Mess-und-Bilanzierungskonzept");
    setExportIsLoading(false);
  }

  return (
    <div className={classNames(className, "EdaTable")}>
      <CustomReactTable
        columns={tableColumns}
        data={formattedAcquisitions}
        defaultFilterMethod={defaultFilterMethod}
        filterable
        getTdProps={selectedCellProps}
        minRows={0}
        NoDataComponent={NoDataComponent}
        pageSize={formattedAcquisitions.length}
        setTableRef={setTableRef}
      />
      {exportButtonContainerNode && (
        <>
          {ReactDOM.createPortal(
            <IconButton
              className="export-button"
              color="secondary"
              disabled={exportIsLoading}
              iconName={
                exportIsLoading ? IconName.SpinnerSpinning : IconName.Download
              }
              onClick={handleClickExport}
            >
              Als Excel-Datei Herunterladen
            </IconButton>,
            exportButtonContainerNode
          )}
        </>
      )}
    </div>
  );
}

const MemoizedEdaTable = React.memo(EdaTable);

function TagsCell({ value }: { value: Array<AcquisitionTag> }) {
  if (!value) {
    return null;
  }

  return <Tags tags={value} />;
}

function Tags({ tags }: { tags: Array<AcquisitionTag> }) {
  return (
    <span>
      {tags.map((tag) => (
        <Tag
          color={tag.color}
          fontColor={tag.fontColor}
          key={tag.name}
          label={tag.name}
          longName={tag.longName}
        />
      ))}
    </span>
  );
}

interface TagProps {
  label: string;
  longName: string;
  color: string;
  fontColor: string;
}

function Tag({ label, longName, color, fontColor }: TagProps) {
  return (
    <React.Fragment>
      <span
        className="tag"
        style={{ backgroundColor: color, color: fontColor }}
        {...anchorTooltip({ content: longName, delayShow: 250 })}
      >
        {label}
      </span>
    </React.Fragment>
  );
}

function NoDataComponent() {
  return (
    <div className="no-data-component">
      <p>Keine Energiedatenerfassungen entsprechen dem gewählten Filter.</p>
    </div>
  );
}

EdaTable.propTypes = {
  acquisitions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClickLabelRow: PropTypes.func.isRequired,
  onSaveSuggestion: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  className: PropTypes.string
};

export { MemoizedEdaTable as EdaTable, Tags };
