import { useQuery } from "@tanstack/react-query";
import { type DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import api from "../../../../../../api";
import { useFeatureFlags } from "../../../../../../hooks/useFeatureFlags";
import urls from "../../../../../../urls";
import type {
  Meter,
  MeterPlausibility
} from "../../../../../../utils/backend-types";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import { Medium, Unit } from "../../../../../../utils/enums";
import type { CounterData } from "../../../../../DataSheet/DataSheet";
import { TsDropdown } from "../../../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { LoadOrError } from "../../../../../LoadOrError/LoadOrError";
import { AcquisitionPlot } from "../../../AcquisitionPlot/AcquisitionPlot";
import { DownloadButton } from "../../../DownloadButton/DownloadButton";
import { DataGaps } from "./DataGaps/DataGaps";
import { MeterInfo } from "./MeterInfo/MeterInfo";
import "./RawEnergyDataSiteMeters.scss";
import { UnitDropdown } from "./UnitDropdown/UnitDropdown";

interface RawEnergyDataSiteMetersProps {
  meters: Array<Meter>;
  meterPlausibilities: Array<MeterPlausibility> | undefined;
  selectedMeterId: number | undefined;
  selectedTimeRange: [DateTime, DateTime];
  siteId: number;
  onSetSelectedMeterId: (meterId: number) => void;
}

const DEFAULT_UNIT = Unit.KilowattHour;

function RawEnergyDataSiteMeters({
  meters,
  meterPlausibilities,
  selectedMeterId,
  selectedTimeRange,
  siteId,
  onSetSelectedMeterId
}: RawEnergyDataSiteMetersProps) {
  const [selectedUnit, setSelectedUnit] = useState<Unit>(DEFAULT_UNIT);
  const { featureFlags } = useFeatureFlags();
  useEffect(() => {
    const selectedMeter = meters.find((meter) => meter.id === selectedMeterId);
    if (selectedUnit === Unit.CubicMeter && !isGasMeter(selectedMeter)) {
      setSelectedUnit(DEFAULT_UNIT);
    }
  }, [meters, selectedMeterId, selectedUnit, setSelectedUnit]);

  const isGasMeter = (meter?: Meter): boolean => meter?.medium === Medium.Gas;

  const {
    data: acquisitionSerieses,
    isLoading: acquisitionSeriesesLoading,
    error: acquisitionSeriesesError
  } = useQuery({
    queryKey: [
      "energy-data-raw-data",
      {
        timeRange: selectedTimeRange,
        site: siteId,
        meter: selectedMeterId,
        unit: selectedUnit
      }
    ],
    queryFn: () =>
      fetchRawData(selectedTimeRange, selectedMeterId, selectedUnit),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: !!selectedMeterId
  });

  async function fetchRawData(
    selectedTimeRange: [DateTime, DateTime],
    meter = -1,
    unit: Unit
  ) {
    const firstDateStr = luxonDateTimeToBackendDateOrDateTime(
      selectedTimeRange[0],
      "ISO 8601"
    );
    const lastDateStr = luxonDateTimeToBackendDateOrDateTime(
      selectedTimeRange[1],
      "ISO 8601"
    );
    const response = await api.get<CounterData>(
      urls.api.energyDataRawData(meter, true, firstDateStr, lastDateStr, unit)
    );

    return response.data;
  }

  const meterChoices = meters.map((meter) => ({
    value: meter.id,
    displayName: meter.name
  }));
  const meterPlausibility = meterPlausibilities?.find(
    (mp) => mp.meter_id === selectedMeterId
  );
  const selectedMeter = meters.find((meter) => meter.id === selectedMeterId);

  return (
    <div className="RawEnergyDataSiteMeters">
      <h4 id="RawEnergyDataSiteMetersMeterData">Zählerdaten</h4>
      <div className="meters-dropdown">
        <TsDropdown
          choices={meterChoices}
          defaultValue={selectedMeterId}
          disabled={meterChoices.length === 0}
          id={`raw-energy-data-meters-${siteId}`}
          key={selectedMeterId}
          name={`raw-energy-data-meters-${siteId}`}
          required={true}
          onChange={(_, value) => onSetSelectedMeterId(value as number)}
        />
        {selectedMeter && isGasMeter(selectedMeter) && (
          <UnitDropdown
            meterId={selectedMeter.id}
            selectedUnit={selectedUnit}
            onUnitSelected={setSelectedUnit}
          />
        )}
        {selectedMeter && (
          <DownloadButton
            downloadRequestOptions={{
              meter: selectedMeter?.id,
              firstDate: luxonDateTimeToBackendDateOrDateTime(
                selectedTimeRange[0]
              ),
              lastDate: luxonDateTimeToBackendDateOrDateTime(
                selectedTimeRange[1]
              )
            }}
            downloadRequestUrl={urls.api.energyDataRawDataRequestDownload()}
            downloadUrlFunc={urls.api.energyDataRawDataDownload}
          />
        )}
      </div>
      <MeterInfo plausibility={meterPlausibility} />
      {typeof selectedMeterId !== "undefined" && (
        <LoadOrError
          error={acquisitionSeriesesError}
          loading={acquisitionSeriesesLoading}
        >
          {acquisitionSerieses && (
            <AcquisitionPlot
              acquisitionSerieses={acquisitionSerieses}
              selectedUnitValue={selectedUnit}
            />
          )}
          {featureFlags.showDataGaps && (
            <DataGaps
              meter={selectedMeterId}
              meterName={selectedMeter?.name}
              selectedTimeRange={selectedTimeRange}
              unit={selectedUnit}
            />
          )}
        </LoadOrError>
      )}
    </div>
  );
}

export { RawEnergyDataSiteMeters };
