import React from "react";
import type {
  ActionMeta,
  GroupBase,
  OnChangeValue,
  StylesConfig
} from "react-select";
import type { SelectProps } from "../../../../../BuildingBlocks/Forms/Select/Select";
import { Select } from "../../../../../BuildingBlocks/Forms/Select/Select";
import type {
  DropdownOption,
  DropdownOptionWithGroup
} from "../Dropdown.types";
import type { Choice } from "../TsDropdown";
import { getGroupedOptionsFromChoices } from "../utils/getGroupedOptionsFromChoices";
import { getLabelFromChoice } from "../utils/getLabelFromChoice";
import { getSimpleOptionsFromChoices } from "../utils/getSimpleOptionsFromChoices";
import { groupDropdownOptions } from "../utils/groupDropdownOptions";
import { isDropdownOptionGroupBase } from "../utils/isDropdownOptionGroupBase";

interface MultiSelectDropdownProps
  extends Omit<
    SelectProps<DropdownOption, true, GroupBase<DropdownOption>>,
    "defaultValue" | "value" | "onChange"
  > {
  choices: Array<Choice>;
  defaultValue?: Array<unknown>;
  name: string;
  isGrouped?: boolean;
  value?: Array<unknown>;
  onChange: (name: string, value: Array<unknown>) => void;
}

function MultiSelectDropdown({
  choices,
  defaultValue,
  isGrouped,
  name,
  required,
  overridingStyles,
  value,
  onChange,
  ...otherProps
}: MultiSelectDropdownProps) {
  let options: Array<DropdownOption> | Array<GroupBase<DropdownOption>>;
  // never include optional choice in case of multiselect because `[]` represents the empty value
  if (isGrouped) {
    const optionsWithGroups = getGroupedOptionsFromChoices(choices, false);
    options = groupDropdownOptions(optionsWithGroups);
  } else {
    options = getSimpleOptionsFromChoices(choices, false);
  }

  function handleChangeDropdown(
    newValue: OnChangeValue<DropdownOption, true>,
    actionMeta: ActionMeta<DropdownOption>
  ) {
    let value: Array<unknown> = [];

    switch (actionMeta.action) {
      case "remove-value":
      case "pop-value":
        if (actionMeta.removedValue.isFixed) {
          return;
        }

        if (newValue !== null) {
          value = newValue.map((option) => option.value);
        }
        break;
      case "clear":
        value = options.reduce<Array<unknown>>((values, option) => {
          if (isDropdownOptionGroupBase(option)) {
            const groupValues = option.options
              .filter((groupedOption) => groupedOption.isFixed)
              .map((groupedOption) => groupedOption.value);

            values.push(groupValues);
          } else if (option.isFixed) {
            values.push(option.value);
          }

          return values;
        }, []);

        value = options
          .filter((option) =>
            isDropdownOptionGroupBase(option)
              ? option.options.filter((groupedOption) => groupedOption.isFixed)
                  .length > 0
              : option.isFixed
          )
          .map((option) =>
            isDropdownOptionGroupBase(option) ? undefined : option.value
          );
        break;
      default:
        if (newValue !== null) {
          value = newValue.map((option) => option.value);
        }
        break;
    }

    onChange(name, value);
  }

  function convertChoicesToOptions(values: Array<unknown>) {
    return values.reduce<Array<DropdownOptionWithGroup>>((options, value) => {
      const choiceForValue = choices.find((choice) => choice.value === value);

      if (!choiceForValue) {
        return options;
      }

      return [
        ...options,
        {
          value: value,
          label: getLabelFromChoice(choiceForValue),
          group: isGrouped ? choiceForValue.group : undefined,
          isFixed: choiceForValue.isFixed
        }
      ];
    }, []);
  }

  const multiSelectStyles: StylesConfig<DropdownOption, true> = {
    ...overridingStyles,
    multiValueLabel: (base, state) => {
      return state.data.isFixed ? { ...base, paddingRight: 6 } : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: "none" } : base;
    }
  };

  const defaultSelectedOptions = Array.isArray(defaultValue)
    ? convertChoicesToOptions(defaultValue)
    : [];
  const selectedOptions = Array.isArray(value)
    ? convertChoicesToOptions(value)
    : undefined;

  return (
    <Select
      {...otherProps}
      closeMenuOnSelect={false}
      defaultValue={defaultSelectedOptions}
      isMulti
      options={options}
      overridingStyles={multiSelectStyles}
      value={selectedOptions}
      onChange={handleChangeDropdown}
    />
  );
}

export { MultiSelectDropdown, MultiSelectDropdownProps };
