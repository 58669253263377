import { useQuery } from "@tanstack/react-query";

import api from "../../../../api";
import urls from "../../../../urls";

const QUERY_KEY = "identityList";

export function useIdentityList(arg?: { shouldReturnDictionary: boolean }) {
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => {
      const { data } = await api.get(urls.api.identityList());

      if (arg?.shouldReturnDictionary) {
        return data.reduce(
          (acc, { type, parameters, displayName }) => ({
            ...acc,
            [type]: { ...parameters, displayName }
          }),
          {}
        );
      } else {
        return data;
      }
    },
    staleTime: Infinity
  });
}
