import classNames from "classnames";
import type { Key } from "react";
import React, { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "../BuildingBlocks/Dropdown/Dropdown";

type SelectItem<T> = {
  key: Key | undefined | null;
  value: T;
  text: string;
};

interface SelectProps<T> {
  className?: string;
  title?: string;
  items: SelectItem<T>[];
  onChange: (value: T) => void;
  defaultValue?: T;
  id?: string;
}

function Select<T>({
  className,
  title,
  items,
  id,
  onChange,
  defaultValue
}: SelectProps<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen = () => setIsOpen(!isOpen);

  const [selected, setSelected] = useState<SelectItem<T> | undefined>(
    items.find((item) => item.value === defaultValue)
  );

  function handleChange(item: SelectItem<T>) {
    setSelected(item);
    onChange(item.value);
    setIsOpen(false);
  }

  return (
    <Dropdown
      className={classNames("select", className)}
      isOpen={isOpen}
      role="select"
      toggle={toggleIsOpen}
    >
      <DropdownToggle caret id={id} role="selectToggle">
        {selected?.text || title || "Auswahl"}
      </DropdownToggle>
      <DropdownMenu>
        {items.map((item) => (
          <DropdownItem key={item.key} onClick={() => handleChange(item)}>
            {item.text}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}

export { Select, SelectItem, SelectProps };
