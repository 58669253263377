import { useQuery } from "@tanstack/react-query";
import api from "../../../../../api";
import urls from "../../../../../urls";
import type {
  DeliveryFormOptions,
  DeliveryFormOptionsResponse
} from "../DeliveryModal.types";

export function useDeliveryFormOptions(variantId: number) {
  const { isLoading, error, data } = useQuery({
    queryKey: ["delivery-form-options", { variantId }],
    queryFn: () => fetchDeliveryFormOptions(variantId)
  });

  return {
    isLoading,
    error,
    data
  };
}

async function fetchDeliveryFormOptions(variantId: number) {
  if (!variantId) {
    return undefined;
  }

  const endpointUrl = urls.api.deliveryFormOptions(variantId);
  const response = await api.get<DeliveryFormOptionsResponse>(endpointUrl);
  const options: DeliveryFormOptions = {
    sites: response.data.sites.map((site) => ({
      value: site.id,
      displayName: site.name
    }))
  };
  return options;
}
