import React from "react";
import type { Column } from "react-table";
import type { SiteCategoriesWithSiteFeatures } from "../../../../utils/backend-types";
import { CheckboxCell } from "../../../CustomReactTable/Cells/CheckboxCell";
import { CustomReactTable } from "../../../CustomReactTable/CustomReactTable";
import type { TypedCellInfo } from "../../../CustomReactTable/CustomReactTable.types";

import "./SiteCategoriesTable.scss";

export type SiteCategoriesTableProps = {
  data: Array<SiteCategoriesWithSiteFeatures>;
  onChange: (data: Array<SiteCategoriesWithSiteFeatures>) => void;
  onChangeInvoiceStatus: (id: number, active: boolean) => void;
  onChangeMeteringConceptStatus: (id: number, active: boolean) => void;
  onChangeGoStatus: (id: number, active: boolean) => void;
  onChangePremiumStatus: (id: number, active: boolean) => void;
  onChangePremiumTodosStatus: (id: number, active: boolean) => void;
};

const DEFAULT_SORTED = [{ id: "name", desc: true }];

function SiteCategoriesTable({
  data,
  onChange,
  onChangeInvoiceStatus,
  onChangeMeteringConceptStatus,
  onChangeGoStatus,
  onChangePremiumStatus,
  onChangePremiumTodosStatus
}: SiteCategoriesTableProps) {
  function onCheckedChange(value: boolean, index: number, key: string) {
    const newData = data.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          [key]: value
        };
      }
      return item;
    });

    if (key === "is_partial_feedin_site" && !value) {
      newData[index] = {
        ...newData[index],
        is_partial_feedin_site: false
      };
    }

    onChange(newData);
  }

  const NAME_COLUMN: Column<SiteCategoriesWithSiteFeatures> = {
    Header: "Liegenschaft",
    accessor: "name"
  };

  const PARTIAL_FEEDIN_COLUMN: Column<SiteCategoriesWithSiteFeatures> = {
    Header: "Teileinspeisung",
    accessor: (row) => row.is_partial_feedin_site,
    id: "is_partial_feedin_site",
    className: "centered-checkbox-in-table",
    Cell: (
      cellInfo: TypedCellInfo<boolean, SiteCategoriesWithSiteFeatures>
    ) => (
      <CheckboxCell
        defaultValue={cellInfo.value}
        onChange={(value) =>
          onCheckedChange(value, cellInfo.index, "is_partial_feedin_site")
        }
      />
    )
  };

  const EEPLUS_COLUMN: Column<SiteCategoriesWithSiteFeatures> = {
    Header: "EEplus",
    accessor: (row) => row.is_eigenerzeugung_plus,
    id: "is_eigenerzeugung_plus",
    className: "centered-checkbox-in-table",
    Cell: (
      cellInfo: TypedCellInfo<boolean, SiteCategoriesWithSiteFeatures>
    ) => (
      <CheckboxCell
        defaultValue={cellInfo.value}
        disabled={!cellInfo.original.is_partial_feedin_site}
        onChange={(value) =>
          onCheckedChange(value, cellInfo.index, "is_eigenerzeugung_plus")
        }
      />
    )
  };

  const PREMIUM_COLUMN: Column<SiteCategoriesWithSiteFeatures> = {
    Header: "Premium",
    accessor: (row) => row.is_premium,
    id: "is_premium",
    className: "centered-checkbox-in-table",
    Cell: (
      cellInfo: TypedCellInfo<boolean, SiteCategoriesWithSiteFeatures>
    ) => (
      <CheckboxCell
        defaultValue={cellInfo.value}
        onChange={(value) => onChangePremiumStatus(cellInfo.original.id, value)}
      />
    )
  };

  const INVOICING_ACTIVE_COLUMN: Column<SiteCategoriesWithSiteFeatures> = {
    Header: "Rechnungsstellung aktiv",
    accessor: (row) => row.invoicing_active,
    id: "invoicing_active",
    className: "centered-checkbox-in-table",
    Cell: (
      cellInfo: TypedCellInfo<boolean, SiteCategoriesWithSiteFeatures>
    ) => (
      <CheckboxCell
        defaultValue={cellInfo.value}
        onChange={(value) => {
          onChangeInvoiceStatus(cellInfo.original.id, value);
        }}
      />
    )
  };

  const METERING_CONCEPT_V2_ACTIVE: Column<SiteCategoriesWithSiteFeatures> = {
    Header: "MBK V2 für Rechnungserstellung",
    accessor: (row) => row.metering_concept_v2_active,
    id: "metering_concept_v2_active",
    className: "centered-checkbox-in-table",
    Cell: (
      cellInfo: TypedCellInfo<boolean, SiteCategoriesWithSiteFeatures>
    ) => (
      <CheckboxCell
        defaultValue={cellInfo.value}
        disabled={!cellInfo.original.invoicing_active}
        onChange={(value) => {
          onChangeMeteringConceptStatus(cellInfo.original.id, value);
        }}
      />
    )
  };

  const GUARANTEE_OF_ORIGIN_COLUMN: Column<SiteCategoriesWithSiteFeatures> = {
    Header: "Dokumentation HKN",
    accessor: (row) => row.go_active,
    id: "go_active",
    className: "centered-checkbox-in-table",
    Cell: (
      cellInfo: TypedCellInfo<boolean, SiteCategoriesWithSiteFeatures>
    ) => (
      <CheckboxCell
        defaultValue={cellInfo.value}
        disabled={
          !cellInfo.original.is_partial_feedin_site ||
          cellInfo.original.is_eigenerzeugung_plus ||
          cellInfo.original.is_premium
        }
        onChange={(value) => {
          onChangeGoStatus(cellInfo.original.id, value);
        }}
      />
    )
  };

  const PREMIUM_TODOS_COLUMN: Column<SiteCategoriesWithSiteFeatures> = {
    Header: "Premium Aufgaben",
    accessor: (row) => row.premium_todos_active,
    id: "premium_todos_active",
    className: "centered-checkbox-in-table",
    Cell: (
      cellInfo: TypedCellInfo<boolean, SiteCategoriesWithSiteFeatures>
    ) => (
      <CheckboxCell
        defaultValue={cellInfo.value}
        disabled={!cellInfo.original.is_premium}
        onChange={(value) => {
          onChangePremiumTodosStatus(cellInfo.original.id, value);
        }}
      />
    )
  };

  const PPAAAS_COLUMN: Column<SiteCategoriesWithSiteFeatures> = {
    Header: "PPA-as-a-Service",
    accessor: (row) => row.is_ppaaas,
    id: "is_ppaaas",
    className: "centered-checkbox-in-table",
    Cell: (
      cellInfo: TypedCellInfo<boolean, SiteCategoriesWithSiteFeatures>
    ) => (
      <CheckboxCell
        defaultValue={cellInfo.value}
        onChange={(value) =>
          onCheckedChange(value, cellInfo.index, "is_ppaaas")
        }
      />
    )
  };

  const columns = [
    NAME_COLUMN,
    PARTIAL_FEEDIN_COLUMN,
    EEPLUS_COLUMN,
    PREMIUM_COLUMN,
    INVOICING_ACTIVE_COLUMN,
    METERING_CONCEPT_V2_ACTIVE,
    GUARANTEE_OF_ORIGIN_COLUMN,
    PPAAAS_COLUMN,
    PREMIUM_TODOS_COLUMN
  ];

  return (
    <>
      <CustomReactTable
        className="SiteCategoriesTable"
        columns={columns}
        data={data}
        defaultSorted={DEFAULT_SORTED}
        NoDataComponent={NoDataComponent}
        pageSize={10}
        showPagination={true}
        sortable={false}
      />
    </>
  );
}

function NoDataComponent() {
  return (
    <div className="no-data-component">
      <p>Keine Liegenschaftsinformationen verfügbar</p>
    </div>
  );
}

export { SiteCategoriesTable };
