import React, { useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import api from "../../api";
import { ROUTES } from "../../routes";
import urls from "../../urls";
import type { Priority, Todo, ExtendedUser } from "../../utils/backend-types";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../Buttons/Button/Button";
import { AnimatedLoadingIcon } from "../Icons/AnimatedLoadingIcon/AnimatedLoadingIcon";
import { CATEGORIES, getBasePathByMode, Mode } from "./common";
import { EditTodoModal } from "./EditTodoModal/EditTodoModal";
import { TodoModal } from "./TodoModal/TodoModal";
import type { TodoStatusCellProps } from "./TodoTable/TableColumns";

interface SingleTodoProps extends Pick<TodoStatusCellProps, "onChangeStatus"> {
  todos: Array<Todo>;
  mode: Mode;
  users: Array<ExtendedUser>;
  variantId: number;
  projectId: string;
  showTodoStatus?: boolean;
  onToggleTodoModal: () => void;
  onChangeResponsible: (responsibleId: number, todoId: number) => void;
  onChangePriority: (priorityValue: Priority, todoId: number) => void;
  onChangeTodoStatus: (todoId: number) => void;
  onChangeDueDate: (todoId: number, dueDate: string | null) => void;
  onChangeOverdue: (todoId: number, overdue: boolean) => void;
  onChangePerson: (todoId: number, person: number) => void;
}

function SingleTodo({
  todos,
  mode,
  users,
  variantId,
  projectId,
  showTodoStatus,
  onToggleTodoModal,
  onChangeStatus,
  onChangeResponsible,
  onChangePriority,
  onChangeTodoStatus,
  onChangeDueDate,
  onChangeOverdue,
  onChangePerson
}: SingleTodoProps) {
  const [isEditTodoModalOpen, setIsEditTodoModalOpen] = useState(false);
  const { todoId } = useParams();

  function toggleEditTodoModal() {
    setIsEditTodoModalOpen(!isEditTodoModalOpen);
  }

  if (!todoId) {
    return null;
  }

  const basePath = getBasePathByMode(mode);
  const modalTodo = todos.find((todo) => todo.id === parseInt(todoId, 10));

  if (modalTodo) {
    // we are at /todos/todoId and the selectedTodo has been set (by clicking a Todo row)
    // or selectedTodo has never been set, meaning we arrived directly at this Todo URL
    if (isEditTodoModalOpen) {
      return (
        <EditTodoModal
          basePath={basePath}
          isOpen
          mode={mode}
          projectId={projectId}
          todo={modalTodo}
          users={users}
          variantId={variantId}
          onToggle={toggleEditTodoModal}
        />
      );
    }

    return (
      <TodoModal
        isOpen
        mode={mode}
        showTodoStatus={showTodoStatus}
        todo={modalTodo}
        users={users}
        variantId={variantId}
        onChangeDueDate={onChangeDueDate}
        onChangeOverdue={onChangeOverdue}
        onChangePerson={onChangePerson}
        onChangePriority={onChangePriority}
        onChangeResponsible={onChangeResponsible}
        onChangeStatus={onChangeStatus}
        onChangeTodoStatus={onChangeTodoStatus}
        onOpenEditTodoModal={toggleEditTodoModal}
        onToggle={onToggleTodoModal}
      />
    );
  }

  // we are at /todos/todoId but the todoId was invalid
  return <InvalidTodo mode={mode} />;
}

interface InvalidTodoProps {
  mode: Mode;
}

function InvalidTodo({ mode }: InvalidTodoProps) {
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (mode === Mode.Normal && !!params.todoId) {
      const todoId = parseInt(params.todoId, 10);

      api
        .get<Todo>(urls.api.todosDetail(todoId))
        .then((response) => {
          if (response.data.category === CATEGORIES.ONBOARDING.value) {
            document.location.href = generatePath(ROUTES.onboardingTodo, {
              projectId: params.projectId ?? null,
              todoId: todoId.toString()
            });
          } else {
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [mode, params.projectId, params.todoId]);

  function toggle() {
    navigate("../", { replace: true });
  }

  if (loading) {
    return <AnimatedLoadingIcon />;
  } else {
    return (
      <Modal className="TodoModal" isOpen toggle={toggle}>
        <ModalHeader toggle={toggle}>Die Aufgabe existiert nicht.</ModalHeader>
        <ModalBody scrollable>
          <p>Die Aufgabe existiert nicht. Möglicherweise wurde sie gelöscht.</p>
        </ModalBody>
        <ModalFooter>
          <Button color="brand" onClick={toggle}>
            Schließen
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export { SingleTodo };
