import React from "react";
import { Card } from "reactstrap";
import type { Meter, Site } from "../../../../utils/backend-types";
import { splitNameExtension } from "../../../../utils/files/splitNameExtension";
import {
  METER_OPTIONAL_TEXT,
  getMeterDisplayName,
  getMeterGroupName
} from "../../../../utils/meter";
import { Icon } from "../../../BuildingBlocks/Icon/Icon";
import { getIconNameByFileExtension } from "../../../BuildingBlocks/Icon/utils/getIconNameByFileExtension";
import { Button } from "../../../Buttons/Button/Button";
import type { Choice } from "../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { TsDropdown } from "../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import type { EnergyDataFile } from "../EnergyDataUpload";
import "./FileList.scss";

const MANY_METERS_VALUE = "many";

interface FileListProps {
  energyDataFiles: Array<EnergyDataFile>;
  meters: Array<Meter>;
  sites: Array<Site>;
  onChangeFileMeter: (
    index: number,
    meterId: number | null,
    manySelected: boolean
  ) => void;
  onRemoveFile: (index: number) => void;
}

function FileList({
  energyDataFiles,
  meters,
  sites,
  onChangeFileMeter,
  onRemoveFile
}: FileListProps) {
  return (
    <div className="FileList">
      {energyDataFiles.length > 0 && <h6>Ausgewählte Dateien</h6>}
      <ul>
        {energyDataFiles.map((file, index) => (
          <EnergyDataFileRow
            key={file.id}
            meters={meters}
            name={file.name}
            sites={sites}
            onChangeMeter={(meterId, manySelected) =>
              onChangeFileMeter(index, meterId, manySelected)
            }
            onRemoveFile={() => onRemoveFile(index)}
          />
        ))}
      </ul>
    </div>
  );
}
interface EnergyDataFileRowProps {
  name: string;
  meters: Array<Meter>;
  sites: Array<Site>;
  onChangeMeter: (meterId: number | null, manySelected: boolean) => void;
  onRemoveFile: () => void;
}
function EnergyDataFileRow({
  name,
  meters,
  sites,
  onChangeMeter,
  onRemoveFile
}: EnergyDataFileRowProps) {
  const manyMetersOption = {
    displayName: METER_OPTIONAL_TEXT,
    value: MANY_METERS_VALUE,
    group: ""
  };

  const meterChoices: Choice[] = [
    manyMetersOption,
    ...meters.map((meter) => ({
      displayName: getMeterDisplayName(meter),
      value: meter.id,
      group: getMeterGroupName(meter.site, sites)
    }))
  ];

  const [, fileExtension] = splitNameExtension(name);
  const iconName = getIconNameByFileExtension(fileExtension);

  function handleMeterDropdownChange(_, value: string): void {
    if (value === "" || value === null) {
      onChangeMeter(null, false);
    } else if (value === MANY_METERS_VALUE) {
      onChangeMeter(null, true);
    } else {
      onChangeMeter(parseInt(value, 10), false);
    }
  }

  return (
    <li className="file">
      <Card>
        <div className="file-body">
          <Icon name={iconName} />
          <span className="name">{name}</span>
          <div className="file-dropdowns">
            <div className="file-dropdown">
              <TsDropdown
                choices={meterChoices}
                defaultValue={manyMetersOption.value}
                id="meter"
                name="meter"
                placeholder={manyMetersOption.displayName}
                required
                onChange={handleMeterDropdownChange}
              />
            </div>
          </div>
          <div className="file-body-button">
            <Button close onClick={onRemoveFile} />
          </div>
        </div>
      </Card>
    </li>
  );
}

export { FileList, FileListProps };
