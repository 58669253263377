import classNames from "classnames";
import React from "react";
import { IconName } from "../BuildingBlocks/Icon/types";
import "./DeleteIcon.scss";
import { ActionIcon } from "./ActionIcon";

interface DeleteIconProps {
  tooltipText?: string;
  disabled?: boolean;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

function DeleteIcon({
  tooltipText,
  disabled,
  className,
  onClick
}: DeleteIconProps) {
  return (
    <ActionIcon
      className={classNames(
        "DeleteIcon",
        "delete-icon",
        { disabled },
        className
      )}
      data-testid="icon-button-delete"
      iconName={IconName.Trash}
      tooltipText={tooltipText}
      onClick={onClick}
    />
  );
}

export { DeleteIcon, DeleteIconProps };
