import React, { PureComponent } from "react";

import urls from "../../../urls";
import {
  Modal,
  ModalBody,
  ModalHeader
} from "../../BuildingBlocks/Layout/Modal/Modal";
import { OptionsForm } from "../../OptionsForm/OptionsForm";

class ProjectSettingsModal extends PureComponent {
  render() {
    const { isOpen, toggle, projectName, projectId, onClickSave } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader>{projectName} bearbeiten</ModalHeader>
        <ModalBody scrollable>
          <OptionsForm
            filteredFields={["name"]}
            initialValues={{ name: projectName }}
            optionsUrl={urls.api.projectsDetail(projectId)}
            patchUrl={urls.api.projectsDetail(projectId)}
            submitButtonText="Speichern"
            onCancel={toggle}
            onSubmit={onClickSave}
          />
        </ModalBody>
      </Modal>
    );
  }
}

export { ProjectSettingsModal };
