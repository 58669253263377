import type {
  MRT_Cell,
  MRT_Row,
  MRT_RowData,
  MRT_TableInstance
} from "mantine-react-table";
import React, { useState } from "react";
import type { DateInputFieldProps } from "../../../../components/BuildingBlocks/Forms/FormFields/DateInputField/DateInputField";
import { DateInputField } from "../../../../components/BuildingBlocks/Forms/FormFields/DateInputField/DateInputField";

interface MantineEditDateInputProps<T extends MRT_RowData>
  extends Pick<DateInputFieldProps, "onKeyDown"> {
  cell: MRT_Cell<T, string | null>;
  columnId: string;
  errorText: string | undefined;
  label?: string;
  row: MRT_Row<T>;
  table: MRT_TableInstance<T>;
}

/** Custom DatePicker Edit component for mantine-react-table.
 * Inspired by this workaround: https://github.com/KevinVandy/mantine-react-table/discussions/8#discussioncomment-8759454
 */
function MantineEditDateInput<T extends MRT_RowData>({
  cell,
  columnId,
  errorText,
  label,
  row,
  table,
  onKeyDown
}: MantineEditDateInputProps<T>) {
  const [value, setValue] = useState<string | null>(cell.getValue());

  function saveInputValueToRowCache(newValue: string | null) {
    const { editingRow, creatingRow } = table.getState();
    const isCreating = creatingRow?.id === row.id;
    const isEditing = editingRow?.id === row.id;

    //@ts-expect-error when using a generic type, the valuesCache is readonly
    row._valuesCache[columnId] = newValue;
    if (isCreating) {
      table.setCreatingRow(row);
    } else if (isEditing) {
      table.setEditingRow(row);
    }
  }

  function handleChange(newValue: string | null) {
    setValue(newValue);
    saveInputValueToRowCache(newValue);
  }

  return (
    <DateInputField
      aria-label={label}
      errorText={errorText}
      placeholder="TT.MM.JJJJ"
      value={value}
      onChange={handleChange}
      onKeyDown={onKeyDown}
    />
  );
}

export { MantineEditDateInput };
