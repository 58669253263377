import {
  type ComboboxItem,
  Divider,
  Group,
  MultiSelect,
  Space,
  Title
} from "@mantine/core";
import { MonthPickerInput } from "@mantine/dates";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useState } from "react";
import type { Generator } from "../../../../utils/backend-types";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { sortBackendDates } from "../../../../utils/dates/sortBackendDates";
import { LoadOrError } from "../../../LoadOrError/LoadOrError";
import { useGeneratorEnergyRevenuesData } from "../../hooks/useGeneratorEnergyRevenuesData";
import type { EnergyRevenuesListResponse } from "../../RevenueMonitoring.types";
import { getMinOrMaxDateFromEnergyRevenues } from "../utils/getMinOrMaxDateFromEnergyRevenues";
import { EnergyVolumeOverviewChart } from "./Charts/EnergyVolumeOverviewChart";
import { RevenueOverviewChart } from "./Charts/RevenueOverviewChart";
import "./EnergyRevenuesCharts.scss";

interface EnergyRevenuesChartsProps {
  energyRevenuesData: EnergyRevenuesListResponse;
  generators?: Array<Generator>;
  generatorsAreLoading: boolean;
  generatorsError: Error | null;
}

function EnergyRevenuesCharts({
  energyRevenuesData,
  generators,
  generatorsAreLoading,
  generatorsError
}: EnergyRevenuesChartsProps) {
  const [selectedGenerators, setSelectedGenerators] = useState<
    Array<string> | undefined
  >();
  const [selectedMonthAndYearRange, setSelectedMonthAndYearRange] = useState<
    [Date | null, Date | null]
  >([
    DateTime.now().startOf("year").toJSDate(),
    DateTime.now().startOf("month").toJSDate()
  ]);
  const {
    energyRevenuesGeneratorData,
    isLoading: isEnergyRevenuesGeneratorDataLoading,
    error: energyRevenuesGeneratorDataError
  } = useGeneratorEnergyRevenuesData(
    selectedGenerators?.map((generatorId) => Number(generatorId)) ?? []
  );

  const formattedData = useMemo(() => {
    return [...energyRevenuesData]
      .sort((revenuesA, revenuesB) =>
        sortBackendDates(revenuesA.period_start, revenuesB.period_start)
      )
      .map((data) => {
        return {
          direktvermarktung_volume: data.direktvermarktung_volume,
          direktvermarktung_net_revenue: data.direktvermarktung_net_revenue,
          market_premium_volume: data.market_premium_volume,
          market_premium_revenue: data.market_premium_revenue,
          period_start: backendDateOrDateTimeToLuxonDateTime(
            data.period_start
          ).toFormat("MMM yyyy")
        };
      });
  }, [energyRevenuesData]);

  const formattedGeneratorData = useMemo(() => {
    return [...(energyRevenuesGeneratorData ?? [])]
      .filter((energyRevenues) => {
        const revenuesDate = backendDateOrDateTimeToLuxonDateTime(
          energyRevenues.period_start
        ).toJSDate();

        return (
          (selectedMonthAndYearRange[0] === null ||
            revenuesDate >= selectedMonthAndYearRange[0]) &&
          (selectedMonthAndYearRange[1] === null ||
            revenuesDate <= selectedMonthAndYearRange[1])
        );
      })
      .sort((revenuesA, revenuesB) =>
        sortBackendDates(revenuesA.period_start, revenuesB.period_start)
      )
      .map((data) => {
        return {
          direktvermarktung_volume: data.direktvermarktung_volume,
          direktvermarktung_net_revenue: data.direktvermarktung_net_revenue,
          market_premium_volume: data.market_premium_volume,
          market_premium_revenue: data.market_premium_revenue,
          period_start: backendDateOrDateTimeToLuxonDateTime(
            data.period_start
          ).toFormat("MMM yyyy")
        };
      });
  }, [energyRevenuesGeneratorData, selectedMonthAndYearRange]);

  const totals = useMemo(() => {
    return energyRevenuesData.reduce(
      (acc, curr) => {
        acc.direktvermarktung_volume += curr.direktvermarktung_volume;
        acc.direktvermarktung_net_revenue += curr.direktvermarktung_net_revenue;
        acc.market_premium_volume += curr.market_premium_volume;
        acc.market_premium_revenue += curr.market_premium_revenue;
        return acc;
      },
      {
        direktvermarktung_volume: 0,
        direktvermarktung_net_revenue: 0,
        market_premium_volume: 0,
        market_premium_revenue: 0
      }
    );
  }, [energyRevenuesData]);

  const generatorTotals = useMemo(() => {
    return (energyRevenuesGeneratorData ?? [])
      .filter((energyRevenues) => {
        const revenuesDate = backendDateOrDateTimeToLuxonDateTime(
          energyRevenues.period_start
        ).toJSDate();

        return (
          (selectedMonthAndYearRange[0] === null ||
            revenuesDate >= selectedMonthAndYearRange[0]) &&
          (selectedMonthAndYearRange[1] === null ||
            revenuesDate <= selectedMonthAndYearRange[1])
        );
      })
      .reduce(
        (acc, curr) => {
          acc.direktvermarktung_volume += curr.direktvermarktung_volume;
          acc.direktvermarktung_net_revenue +=
            curr.direktvermarktung_net_revenue;
          acc.market_premium_volume += curr.market_premium_volume;
          acc.market_premium_revenue += curr.market_premium_revenue;
          return acc;
        },
        {
          direktvermarktung_volume: 0,
          direktvermarktung_net_revenue: 0,
          market_premium_volume: 0,
          market_premium_revenue: 0
        }
      );
  }, [energyRevenuesGeneratorData, selectedMonthAndYearRange]);

  const generatorOptions = useMemo(
    () =>
      generators?.map<ComboboxItem>((generator) => ({
        label: `${generator.name} (${generator.siteName})`,
        value: generator.id.toString()
      })),
    [generators]
  );

  useEffect(() => {
    if (generatorOptions && generatorOptions.length > 0) {
      setSelectedGenerators([generatorOptions[0].value]);
    } else {
      setSelectedGenerators(undefined);
    }
  }, [generatorOptions]);

  return (
    <div className="EnergyRevenuesCharts">
      <RevenueOverviewChart formattedData={formattedData} totals={totals} />
      <Space h="xl" />
      <EnergyVolumeOverviewChart
        formattedData={formattedData}
        totals={totals}
      />

      <Space h="xl" />
      <Divider />
      <Space h="xl" />

      {generators && (
        <>
          <Title order={5}>Anlagenübersicht</Title>
          <Space h="md" />
          <Group>
            <MultiSelect
              className="generator-select"
              clearable
              data={generatorOptions}
              disabled={generatorsAreLoading || generators?.length === 0}
              label="Anlagen"
              placeholder="Anlagen auswählen"
              searchable
              value={selectedGenerators}
              onChange={setSelectedGenerators}
            />
            <Space w="md" />
            <MonthPickerInput
              allowSingleDateInRange
              clearable
              disabled={
                generatorsAreLoading ||
                energyRevenuesGeneratorData?.length === 0
              }
              label="Berechneter Zeitraum"
              maxDate={getMinOrMaxDateFromEnergyRevenues(
                energyRevenuesGeneratorData ?? [],
                "max"
              )?.toJSDate()}
              minDate={getMinOrMaxDateFromEnergyRevenues(
                energyRevenuesGeneratorData ?? [],
                "min"
              )?.toJSDate()}
              placeholder="Monate auswählen"
              type="range"
              value={selectedMonthAndYearRange}
              onChange={setSelectedMonthAndYearRange}
            />
          </Group>
          <Space h="md" />

          <LoadOrError
            error={generatorsError || energyRevenuesGeneratorDataError}
            loading={
              generatorsAreLoading || isEnergyRevenuesGeneratorDataLoading
            }
          >
            <RevenueOverviewChart
              formattedData={formattedGeneratorData}
              totals={generatorTotals}
            />
            <Space h="xl" />
            <EnergyVolumeOverviewChart
              formattedData={formattedGeneratorData}
              totals={generatorTotals}
            />
          </LoadOrError>
        </>
      )}
    </div>
  );
}

export { EnergyRevenuesCharts, EnergyRevenuesChartsProps };
