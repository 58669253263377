import React from "react";
import type { Site } from "../../../utils/backend-types";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../../Buttons/Button/Button";
import { LoadOrError } from "../../LoadOrError/LoadOrError";
import { useMetersBySiteIds } from "../hooks/useMetersBySiteIds";
import { ImportLogs } from "./ImportLogs/ImportLogs";

interface ImportLogsModalProps {
  isOpen: boolean;
  configurationId: number;
  sites: Array<Site>;
  onToggle: () => void;
}

function ImportLogsModal({
  isOpen,
  configurationId,
  sites,
  onToggle
}: ImportLogsModalProps) {
  const { isMetersQueryLoading, metersBySiteId, metersQueryError } =
    useMetersBySiteIds(sites);

  const meterNamesByMeterId = metersBySiteId?.reduce<Record<number, string>>(
    (names, meter) => {
      names[meter.id] = meter.name;
      return names;
    },
    {}
  );

  return (
    <Modal
      className="ImportLogsModal"
      isOpen={isOpen}
      size="xl"
      toggle={onToggle}
    >
      <ModalHeader toggle={onToggle}>Historie</ModalHeader>
      <ModalBody scrollable>
        <LoadOrError error={metersQueryError} loading={isMetersQueryLoading}>
          {meterNamesByMeterId && (
            <ImportLogs
              configurationId={configurationId}
              meterNames={meterNamesByMeterId}
            />
          )}
        </LoadOrError>
      </ModalBody>
      <ModalFooter>
        <Button color="brand" onClick={onToggle}>
          Schließen
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export { ImportLogsModal };
