import classNames from "classnames";
import React, { memo, useCallback } from "react";
import { IconHelpText } from "../../IconHelpText/IconHelpText";
import { OrangeCircle } from "../../OrangeCircle/OrangeCircle";
import type { FormFieldValue } from "../FormItems/FormField/FormField";
import { FormField } from "../FormItems/FormField/FormField";
import type { FormFieldData } from "../FormItems/FormItems";
import "./FormItem.scss";
import { FormItemInfoText } from "./FormItemInfoText";

const DEFAULT_ERRORS = [];

interface FormItemProps extends FormFieldData {
  formName?: string;
  value?: FormFieldValue;
  allowInput?: boolean;
  softRequired?: boolean;
  className?: string;
  inputGroupText?: string;
  errors?: Array<string>;
  shouldUsePortal?: boolean;
  onInput: (name: string, value: FormFieldValue) => void;
  onFormItemNodeChange?: (name: string, node: HTMLElement | null) => void;
  highlight?: boolean;
}

/** @deprecated Please use BuildingBlocks/Forms/FormField from now on. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function FormItem(
  {
    formName,
    value,
    allowInput = true,
    softRequired,
    className,
    inputGroupText,
    errors = DEFAULT_ERRORS,
    shouldUsePortal,
    onInput,
    onFormItemNodeChange,
    highlight,
    ...formFieldData
  }: FormItemProps,
  ref: React.Ref<HTMLDivElement>
) {
  const formItemId = `id_${
    formName ? formName : "form"
  }_${formFieldData.name.replace(/\./g, "__")}`;

  const showLabel = !formFieldData.noLabel;

  return (
    <div
      className={classNames("FormItem", className)}
      key={formFieldData.name}
      ref={ref}
    >
      {showLabel && (
        <FormItemLabelMemoized
          helpText={formFieldData.helpText}
          highlight={!value && highlight}
          id={formItemId}
          instance={formFieldData.instance}
          label={formFieldData.label}
          required={formFieldData.required}
        />
      )}
      <FormItemComponent
        {...formFieldData}
        allowInput={allowInput}
        errors={errors}
        id={formItemId}
        inputGroupText={inputGroupText}
        required={softRequired || formFieldData.required}
        shouldUsePortal={shouldUsePortal}
        value={value}
        onFormItemNodeChange={onFormItemNodeChange}
        onInput={onInput}
      />
      {formFieldData.infoText && (
        <FormItemInfoText infoText={formFieldData.infoText} />
      )}
    </div>
  );
}

interface FormItemLabelProps {
  id: string;
  label: string;
  required?: boolean;
  instance?: {
    label: string;
  };
  helpText?: string;
  highlight?: boolean;
}

function FormItemLabel({
  id,
  label,
  required,
  instance,
  helpText,
  highlight
}: FormItemLabelProps) {
  return (
    <div className="label-container">
      <div className="label-title-container">
        {highlight && (
          <div className="wizard-required-container">
            <OrangeCircle />
          </div>
        )}
        <label className="col-form-label" htmlFor={id}>
          {instance && <>{instance.label}: </>}
          {label}
          {required && "*"}
        </label>
      </div>
      {helpText && <IconHelpText helpText={helpText} />}
    </div>
  );
}
const FormItemLabelMemoized = memo(FormItemLabel);

interface FormItemComponentProps extends FormFieldData {
  id: string;
  value?: FormFieldValue;
  allowInput: boolean;
  inputGroupText?: string;
  errors: Array<string>;
  shouldUsePortal?: boolean;
  onFormItemNodeChange?: (name: string, node: HTMLElement | null) => void;
  onInput: (name: string, value: FormFieldValue) => void;
}

function FormItemComponent({
  id,
  value,
  allowInput,
  inputGroupText,
  errors,
  shouldUsePortal,
  onFormItemNodeChange,
  onInput,
  ...formFieldData
}: FormItemComponentProps) {
  const formItemNodeRef = useCallback(
    (node) => {
      if (node !== null && onFormItemNodeChange) {
        onFormItemNodeChange(formFieldData.name, node);
      }
    },
    [formFieldData, onFormItemNodeChange]
  );

  if (shouldUsePortal) {
    return <div className="form-item-node" ref={formItemNodeRef} />;
  } else {
    return (
      <FormField
        allowInput={!formFieldData.readOnly && allowInput}
        allowNull={formFieldData.allowNull}
        choices={formFieldData.choices}
        dataUrl={formFieldData.dataUrl}
        errorTexts={errors}
        firstPossibleDate={formFieldData.firstPossibleDate}
        id={id}
        inputGroupText={inputGroupText}
        invalid={formFieldData.invalid}
        max={formFieldData.maxValue}
        min={formFieldData.minValue}
        multiselect={formFieldData.multiselect}
        name={formFieldData.name}
        placeholder={formFieldData.placeholder}
        required={formFieldData.required}
        type={formFieldData.type}
        value={value}
        warnings={formFieldData.warnings}
        onInput={onInput}
      />
    );
  }
}

/** @deprecated Please use BuildingBlocks/Forms/FormField from now on. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
const ForwardedRefFormItem = React.forwardRef(FormItem);

export { ForwardedRefFormItem as FormItem, FormItemLabel, FormItemProps };
