import { DateTime } from "luxon";
import React, { useId } from "react";
import { DateInput } from "../../../BuildingBlocks/Dates/DateInput/DateInput";

interface DatePickerCellProps {
  value: DateTime | undefined;
  firstPossibleDate?: DateTime;
  lastPossibleDate?: DateTime;
  placeholder?: string;
  onChange: (newDate: DateTime) => void;
}

function DatePickerCell({
  value,
  firstPossibleDate,
  lastPossibleDate,
  placeholder,
  onChange
}: DatePickerCellProps) {
  const formId = useId();

  const disabled = Boolean(
    firstPossibleDate &&
      DateTime.now().minus({ days: 1 }).hasSame(firstPossibleDate, "day")
  );

  function handleChangeDate(date: DateTime | null) {
    if (!date) {
      return;
    }

    onChange(date);
  }

  return (
    <DateInput
      className={!value ? "center" : ""}
      date={value}
      disabled={disabled}
      firstPossibleDate={firstPossibleDate}
      id={formId}
      lastPossibleDate={lastPossibleDate}
      placeholder={placeholder}
      showClearDate={false}
      onChange={handleChangeDate}
    />
  );
}

export { DatePickerCell };
