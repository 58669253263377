import React from "react";
import urls from "../../../../urls";
import FormItems from "../../../DynamicForm/FormItems/FormItems";
import { withCreatableDropdown } from "../../../DynamicForm/FormItems/withCreatableDropdown";
import { OptionsForm } from "../../../OptionsForm/OptionsForm";
import { CREATE_FORM_FIELD_NAMES_REDUCED as PERSON_FORM_FIELD_NAMES } from "../../../PersonWizard/PersonWizard";

interface MaStRSubStepGeneratorProps {
  buttonContainer?: HTMLElement;
  siteId: number;
  variantId: number;
  onSubmit: (taskId: string) => void;
  onBack: () => void;
}

const FILTERED_FIELDS = ["mastrNummer", "person"];

function MaStRSubStepGenerator({
  buttonContainer,
  siteId,
  variantId,
  onSubmit,
  onBack
}: MaStRSubStepGeneratorProps) {
  const optionsUrl = urls.api.importGeneratorsFromMastr(siteId);
  const postUrl = urls.api.importGeneratorsFromMastr();
  const CustomFormItemsComponent = getCustomFormItemsComponent(variantId);
  const nonFieldData = {
    site: siteId,
    variant: variantId
  };

  function getCustomFormItemsComponent(variantId: number) {
    const personPostUrl = urls.api.personsByVariantId(variantId);
    const personPutUrlFunc = urls.api.person;
    return withCreatableDropdown(
      FormItems,
      "person",
      personPostUrl,
      personPutUrlFunc,
      PERSON_FORM_FIELD_NAMES,
      {
        variant: variantId
      }
    );
  }

  function handlePollSuccess(taskIds: Array<string>) {
    onSubmit(taskIds[0]);
  }

  return (
    <div className="mastr-substep">
      <GeneratorExplanation />
      <OptionsForm
        buttonContainer={buttonContainer}
        cancelButtonText="Zurück"
        CustomFormItemsComponent={CustomFormItemsComponent}
        filteredFields={FILTERED_FIELDS}
        nonFieldData={nonFieldData}
        optionsUrl={optionsUrl}
        postUrl={postUrl}
        submitButtonText="Weiter"
        onCancel={onBack}
        onPollSuccess={handlePollSuccess}
        onSubmit={() => {
          /*do nothing - wait for handlePollSuccess*/
        }}
      />
    </div>
  );
}

function GeneratorExplanation() {
  return (
    <p className="explanation">
      Bitte geben Sie die Marktstammdatenregisternummer einer Einheit oder
      Anlage ein und wählen Sie den Betreiber. Es werden automatisch alle
      zusammengehörigen Einheiten und Anlagen angelegt. Eine{" "}
      <a
        href="https://www.marktstammdatenregister.de/MaStR/Einheit/Einheiten/OeffentlicheEinheitenuebersicht"
        rel="noreferrer"
        target="_blank"
      >
        Liste öffentlicher Einheiten
      </a>{" "}
      ist online verfügbar.
    </p>
  );
}

export { MaStRSubStepGenerator };
