import React from "react";

import { EMPTY_FIELD } from "../../../../../utils/constants";

interface EdaPropertyProps {
  header: string;
  text: string | number | null;
}

function EdaProperty({ header, text }: EdaPropertyProps) {
  return (
    <div className="eda-property">
      <h6>{header}</h6>
      <span className="eda-property-value">{text || EMPTY_FIELD}</span>
    </div>
  );
}

export { EdaProperty };
