import type {
  PaginationState,
  ColumnDef as ReactTableColumnDef,
  TableOptions,
  RowData,
  SortDirection,
  SortingState,
  Row
} from "@tanstack/react-table";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import classNames from "classnames";
import React, { useState } from "react";
import { Icon } from "../BuildingBlocks/Icon/Icon";
import { IconName } from "../BuildingBlocks/Icon/types";
import "./Table.scss";
import { calculateBestPageSize } from "../CustomReactTable/utils/calculateBestPageSize";
import { TablePagination } from "./TablePagination";

export type ColumnDef<TData, TValue = unknown> = ReactTableColumnDef<
  TData,
  TValue
>;

interface TableProps<TData> {
  className?: string;
  NoDataComponent?: React.FunctionComponent;
  options: Omit<TableOptions<TData>, "getCoreRowModel"> & {
    showPagination?: boolean;
    showPageJump?: boolean;
  };
  renderSubComponent?: (props: { row: Row<TData> }) => React.ReactElement;
}

/** @deprecated Use mantine-react-table instead. */
function Table<TData extends RowData>({
  className,
  NoDataComponent,
  options,
  renderSubComponent
}: TableProps<TData>) {
  const { showPagination = false, showPageJump = false } = options;
  const [sorting, setSorting] = useState<SortingState>(
    options.initialState?.sorting ?? []
  );
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: calculateBestPageSize(options.data.length)
  });
  const table = useReactTable<TData>({
    ...options,
    columnResizeMode: "onChange",
    state: {
      ...options.initialState,
      sorting,
      pagination
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination
  });

  function getAmountOfCols(cols: Array<ReactTableColumnDef<TData>>) {
    let count = 0;
    cols.forEach((col) => {
      if (col["columns"] !== undefined) {
        count += getAmountOfCols(col["columns"]);
      } else count++;
    });
    return count;
  }

  const amountOfCols = getAmountOfCols(options.columns);

  return (
    <div className="Table">
      <table
        className={classNames(
          className ? className : "",
          "table-main-component"
        )}
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr className="header-group" key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  colSpan={header.colSpan}
                  key={header.id}
                  style={{
                    width: header.getSize()
                  }}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      className={classNames("sortable-header", {
                        "can-sort": header.column.getCanSort()
                      })}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {header.column.columnDef.enableSorting &&
                        header.column.getCanSort() && (
                          <SortingIcon
                            sortDirection={header.column.getIsSorted()}
                          />
                        )}
                    </div>
                  )}
                  <div
                    className={classNames("resizer", {
                      isResizing: header.column.getIsResizing()
                    })}
                    onMouseDown={header.getResizeHandler()}
                    onTouchStart={header.getResizeHandler()}
                  />
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.length === 0 && NoDataComponent ? (
            <tr>
              <td colSpan={amountOfCols}>
                <NoDataComponent />
              </td>
            </tr>
          ) : (
            table.getRowModel().rows.map((row) => (
              <React.Fragment key={row.id}>
                <tr className="basic-row" key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      className="basic-cell"
                      key={cell.id}
                      style={{
                        width: cell.column.getSize()
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
                {row.getIsExpanded() && renderSubComponent && (
                  <tr className="sub-component">
                    <td colSpan={row.getVisibleCells().length}>
                      {renderSubComponent({ row })}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))
          )}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
      {showPagination && (
        <TablePagination showPageJump={showPageJump} table={table} />
      )}
    </div>
  );
}

function SortingIcon({
  sortDirection
}: {
  sortDirection: SortDirection | false;
}) {
  const iconName = !sortDirection
    ? IconName.Unsorted
    : sortDirection === "asc"
      ? IconName.SortAsc
      : IconName.SortDesc;

  return <Icon className="sorting-icon" name={iconName} />;
}

export { Table, TableOptions, TableProps, createColumnHelper };
