import { useQuery } from "@tanstack/react-query";
import api from "../../api";
import type { StartPageCO2Savings } from "../../components/StartPage/StartPage.types";
import urls from "../../urls";

function useStartPageCO2Savings(year: number, projectId?: string) {
  const { data, error, isLoading } = useQuery({
    queryKey: urls.startPage.co2Savings.key(year, projectId),
    queryFn: () => fetchStartPageCO2Savings(year, projectId)
  });

  async function fetchStartPageCO2Savings(year: number, projectId?: string) {
    const response = await api.get<StartPageCO2Savings>(
      urls.startPage.co2Savings.url(year, projectId)
    );
    return response.data;
  }

  return { data, error, isLoading };
}

export { useStartPageCO2Savings };
