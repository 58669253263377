import type { Method } from "axios";

import api, { apiWithoutCamelization } from "../../api";

type FormDataObject = Record<
  string,
  File | Array<File> | string | number | boolean | null
>;

/** Post one or more files to a given endpoint with optional additional data. */
export function uploadFileOrFiles<
  T,
  D = Record<string, string | number | boolean | null>
>(
  fileOrFiles: File | Array<File>,
  endpointUrl: string,
  formDataFileKey: string,
  additionalFormData?: D,
  requestMethod: Method = "POST",
  withoutCamelization = false
) {
  const formData = new FormData();
  // workaround for edge < 18 because formData has no way to traverse the actual data.
  const formDataObject: FormDataObject = {
    ...additionalFormData,
    [formDataFileKey]: fileOrFiles
  };
  const config = {
    headers: {
      "content-type": "multipart/form-data"
    }
  };

  formData["formDataObject"] = formDataObject;

  if (withoutCamelization) {
    return apiWithoutCamelization.request<T>(
      requestMethod,
      endpointUrl,
      formData,
      config
    );
  }

  return api.request<T>(requestMethod, endpointUrl, formData, config);
}
