import type { IconName } from "../types";
import { IconComponentType } from "../types";
import { FLAT_ICON_ICONS } from "./getFlatIconClassNameByIconName";
import { ICON_IMG_SRC_ICONS } from "./getIconImgSrcByIconName";
import { LA_ICONS } from "./getLaIconClassNameByIconName";
import { OPTI_FONT_ICONS } from "./getOptiFontClassNameByIconName";

export function getIconComponentTypeByIconName(iconType: IconName) {
  if (LA_ICONS.includes(iconType)) {
    return IconComponentType.LaIcon;
  }

  if (FLAT_ICON_ICONS.includes(iconType)) {
    return IconComponentType.FlatIcon;
  }

  if (ICON_IMG_SRC_ICONS.includes(iconType)) {
    return IconComponentType.ImgSrc;
  }

  if (OPTI_FONT_ICONS.includes(iconType)) {
    return IconComponentType.OptiIcon;
  }

  return null;
}
