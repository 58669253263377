import React, { useState } from "react";

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../../Buttons/Button/Button";

type NotAccessableModalProps = {
  onClose: () => void;
  text?: string;
};

function NotAccessableModal({ onClose, text }: NotAccessableModalProps) {
  const [isOpen, setIsOpen] = useState(true);

  function handleClose() {
    setIsOpen(false);
  }

  return (
    <Modal isOpen={isOpen} toggle={handleClose} onClosed={() => onClose()}>
      <ModalHeader>Kein Zugriff</ModalHeader>
      <ModalBody>
        <h5>
          {!text
            ? "Diese Option steht derzeit nicht zur Verfügung, da die Konfiguration " +
              "als vollständig bestätigt wurde. Wenden Sie sich für Änderungen an das " +
              "Support Team: "
            : text}
          <a href="mailto:support@node.energy">support@node.energy</a>
        </h5>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => handleClose()}>Zurück</Button>
      </ModalFooter>
    </Modal>
  );
}

export { NotAccessableModal };
