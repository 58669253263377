import type { ApiResponse } from "../../api";
import api from "../../api";
import urls from "../../urls";
import type { ExtendedUser } from "../../utils/backend-types";

function shareProjectToEmails(
  projectId: string,
  emailTextInput: string,
  onError: (error: unknown) => void
) {
  const emails = parseCommaSeparatedEmails(emailTextInput);
  const promises = emails.map((email) =>
    shareToEmail(projectId, email, onError)
  );

  return Promise.all(promises);
}

function parseCommaSeparatedEmails(emails: string) {
  const emailArray = emails.split(";");
  const emailsWithoutWhitespace = emailArray.map((email) => email.trim());

  return emailsWithoutWhitespace;
}

async function shareToEmail(
  projectId: string,
  email: string,
  onError: (error: unknown) => void
) {
  let response: ApiResponse<ExtendedUser> | undefined = undefined;

  try {
    response = await api.post(urls.api.projectsShare(projectId), {
      email
    });
  } catch (error) {
    onError(error);
  }

  return response?.data;
}

export { parseCommaSeparatedEmails, shareProjectToEmails, shareToEmail };
