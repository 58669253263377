import type { BatchImportError, ComponentType } from "../types";

export function groupByComponent(
  array: Array<BatchImportError>
): Record<ComponentType | "ungrouped", Array<BatchImportError>> {
  return array.reduce((result, currentItem) => {
    const groupKey = currentItem["componentType"] ?? "ungrouped";

    if (!result[groupKey]) {
      result[groupKey] = [];
    }
    result[groupKey].push(currentItem);

    return result;
  }, {}) as Record<ComponentType | "ungrouped", Array<BatchImportError>>;
}
