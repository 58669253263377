import classnames from "classnames";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import type { Column } from "react-table";
import { Card, CardBody, Collapse } from "reactstrap";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import { Icon } from "../../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../../BuildingBlocks/Icon/types";
import { Button } from "../../../../Buttons/Button/Button";
import type {
  ChangeableFieldData,
  ChangeableFieldWidgetOptions
} from "../../../../ChangeableFieldWidget/ChangeableFieldWidget";
import { ChangeableFieldWidget } from "../../../../ChangeableFieldWidget/ChangeableFieldWidget";
import { CustomReactTable } from "../../../../CustomReactTable/CustomReactTable";
import type { FormItemProps } from "../../../FormItem/FormItem";
import { FormItemLabel } from "../../../FormItem/FormItem";
import { FuturePriceWarning } from "../ChangeablePriceField/FuturePriceWarning/FuturePriceWarning";
import "./ChangeableSpotMarketFormulaFieldStatic.scss";

const CHANGEABLE_FIELD_WIDGET_OPTIONS: ChangeableFieldWidgetOptions = {
  addButtonText: "Weitere Formel hinzufügen",
  addNullStartDateWhenNoValues: true,
  endDatePlaceholder: "-",
  startDatePlaceholder: "-",
  deleteTooltipText: "Formel entfernen",
  numberValueHeader: "Formel",
  inputGroupText: "ct/kWh",
  saveButtonText: "Übernehmen",
  canAddFutureDates: true
};
export interface ChangeableSpotMarketFormula {
  id?: number;
  firstDate: string | null;
  lastDate: string | null;
  formula: string;
}

export interface ChangeableSpotMarketFormulaFieldStaticProps
  extends Omit<FormItemProps, "type" | "value" | "errors"> {
  fieldName: string;
  unit: string;
  value: Array<ChangeableSpotMarketFormula>;
  collapsible?: boolean;
  errors?: Record<string, Array<string>>;
}

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function ChangeableSpotMarketFormulaFieldStatic(
  {
    allowInput,
    fieldName,
    formName,
    helpText,
    instance,
    label,
    name,
    value: formulas = [],
    required,
    unit,
    onInput,
    collapsible = true,
    errors
  }: ChangeableSpotMarketFormulaFieldStaticProps,
  ref: React.Ref<HTMLDivElement>
) {
  const [isHistoryCollapseOpen, setIsHistoryCollapseOpen] = useState(false);
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [formulaDataForModal, setFormulaDataForModal] =
    useState<ChangeableSpotMarketFormula[]>();

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      setIsCollapseOpen(true);
    }
  }, [errors]);

  const optionsWithUnit = useMemo(() => {
    return {
      ...CHANGEABLE_FIELD_WIDGET_OPTIONS,
      inputGroupText: unit,
      collapsible,
      isTextField: true
    };
  }, [collapsible, unit]);

  const formItemId = `id_${formName ? formName : "form"}_${name.replace(
    /\./g,
    "__"
  )}`;

  function handleClickHistoryButton() {
    setIsCollapseOpen(false);
    setIsHistoryCollapseOpen((open) => !open);
  }

  function handleClickEditButton() {
    setIsHistoryCollapseOpen(false);
    setIsCollapseOpen((open) => !open);
  }

  function getEarliestDate(noIdObjects: Array<ChangeableSpotMarketFormula>) {
    return noIdObjects.reduce((prev, curr) => {
      if (prev.firstDate === null) {
        return prev;
      } else if (curr.firstDate === null) {
        return curr;
      }

      const prevDate = backendDateOrDateTimeToLuxonDateTime(prev.firstDate);
      const currDate = backendDateOrDateTimeToLuxonDateTime(curr.firstDate);

      return prevDate < currDate ? prev : curr;
    });
  }
  const handleSaveFormulas = useCallback(
    (formulas) => {
      setShowWarningModal(false);
      onInput(fieldName, formulas);
      setIsCollapseOpen(false);
      return Promise.resolve();
    },
    [onInput, fieldName]
  );

  const submitFormulas = useCallback(
    (changeableFieldData: Array<ChangeableFieldData<string>>) => {
      const newFormulas =
        convertChangeableFieldDataToFormulas(changeableFieldData);
      const noIdObjects = newFormulas.filter((obj) => obj.id === undefined);
      const oneMonthFromNow = DateTime.now().plus({ months: 1 });

      if (noIdObjects && noIdObjects.length > 0) {
        const firstNewDate = getEarliestDate(noIdObjects);
        const dateToCheck = firstNewDate.firstDate
          ? backendDateOrDateTimeToLuxonDateTime(firstNewDate.firstDate)
          : firstNewDate.lastDate
            ? backendDateOrDateTimeToLuxonDateTime(firstNewDate.lastDate)
            : null;

        if (dateToCheck && dateToCheck < oneMonthFromNow) {
          setShowWarningModal(true);
          setFormulaDataForModal(newFormulas);
          return Promise.resolve();
        }
      }

      return handleSaveFormulas(newFormulas);
    },
    [handleSaveFormulas]
  );

  function convertFormulasToChangeableFieldData(
    formulas?: Array<ChangeableSpotMarketFormula>
  ) {
    if (!formulas) {
      return undefined;
    }

    return formulas
      .map<ChangeableFieldData<string>>((formula) => ({
        id: formula.id,
        startDate: formula.firstDate
          ? backendDateOrDateTimeToLuxonDateTime(formula.firstDate)
          : null,
        endDate: formula.lastDate
          ? backendDateOrDateTimeToLuxonDateTime(formula.lastDate)
          : null,
        value: formula.formula
      }))
      .sort((a, b) => {
        if (a.startDate === null && b.startDate === null) {
          return 0;
        } else if (a.startDate === null) {
          return -1;
        } else if (b.startDate === null) {
          return 1;
        } else if (a.startDate < b.startDate) {
          return -1;
        } else if (b.startDate < a.startDate) {
          return 1;
        }

        return 0;
      });
  }

  function convertChangeableFieldDataToFormulas(
    values: Array<ChangeableFieldData<string>>
  ) {
    return values.map<ChangeableSpotMarketFormula>((value) => ({
      id: value.id,
      formula: value.value as unknown as string,
      firstDate: value.startDate
        ? luxonDateTimeToBackendDateOrDateTime(value.startDate)
        : null,
      lastDate: value.endDate
        ? luxonDateTimeToBackendDateOrDateTime(value.endDate)
        : null
    }));
  }

  function getCurrentFormula(formulas: Array<ChangeableSpotMarketFormula>) {
    const now = DateTime.now();

    return formulas.find((formula) => {
      const firstDate = formula.firstDate
        ? backendDateOrDateTimeToLuxonDateTime(formula.firstDate)
        : null;
      const lastDate = formula.lastDate
        ? backendDateOrDateTimeToLuxonDateTime(formula.lastDate)
        : null;

      const firstDateInRange = !firstDate || firstDate <= now;
      const lastDateInRange = !lastDate || now <= lastDate;

      return firstDateInRange && lastDateInRange;
    });
  }

  const currentFormula = useMemo(() => {
    return getCurrentFormula(formulas);
  }, [formulas]);

  const suggestedChangeableFormulas = useMemo(() => {
    return convertFormulasToChangeableFieldData(formulas);
  }, [formulas]);

  const memoizedChangeableFieldWidget = useMemo(() => {
    if (!suggestedChangeableFormulas) {
      return null;
    }
    return (
      <ChangeableFieldWidget
        dataName="Formeländerungen"
        errors={errors}
        initialValues={suggestedChangeableFormulas}
        options={optionsWithUnit}
        onCancel={() => {
          setIsCollapseOpen(false);
        }}
        onDone={submitFormulas}
      />
    );
  }, [errors, optionsWithUnit, submitFormulas, suggestedChangeableFormulas]);

  return (
    <div className="ChangeableSpotMarketFormulaFieldStatic FormField" ref={ref}>
      {showWarningModal && formulaDataForModal && (
        <FuturePriceWarning
          prices={formulaDataForModal}
          onCancel={() => setShowWarningModal(false)}
          onSuccess={handleSaveFormulas}
        />
      )}
      <div>
        <div className="toggler-label">
          <FormItemLabel
            helpText={helpText}
            id={formItemId}
            instance={instance}
            label={label}
            required={required}
          />
          <div className="label-and-button">
            <span
              className={classnames("label-container", {
                disabled: !optionsWithUnit.collapsible
              })}
              onClick={() => {
                if (optionsWithUnit.collapsible) {
                  handleClickHistoryButton();
                }
              }}
            >
              <div className="label-container-icon-and-value">
                <Icon
                  className="toggle-icon"
                  name={
                    isHistoryCollapseOpen
                      ? IconName.AngleDown
                      : IconName.AngleRight
                  }
                />{" "}
                <span>{currentFormula?.formula}</span>
              </div>
              <div className="input-group-append">
                <span className="input-group-text">ct/kWh</span>
              </div>
            </span>
            <Button
              className="label-button"
              color="brand"
              disabled={!allowInput || isCollapseOpen}
              onClick={handleClickEditButton}
            >
              Formeländerung hinzufügen
            </Button>
          </div>
        </div>
      </div>
      <Collapse isOpen={isHistoryCollapseOpen}>
        {formulas ? (
          <FormulaHistory formulas={formulas} />
        ) : (
          <Card className="loading-card">
            <CardBody></CardBody>
          </Card>
        )}
      </Collapse>
      <Collapse isOpen={isCollapseOpen}>
        <Card className="prices-edit">
          <CardBody>{memoizedChangeableFieldWidget}</CardBody>
        </Card>
      </Collapse>
    </div>
  );
}

interface FormulaHistoryProps {
  formulas: Array<ChangeableSpotMarketFormula>;
}

function FormulaHistory({ formulas }: FormulaHistoryProps) {
  const tableColumns: Array<Column<ChangeableSpotMarketFormula>> = [
    {
      Header: "Von",
      accessor: "firstDate",
      width: 85,
      Cell: (cellData) => <span>{cellData.value ?? "-"}</span>,
      sortable: false
    },
    {
      Header: "Bis",
      accessor: "lastDate",
      width: 85,
      Cell: (cellData) => <span>{cellData.value ?? "-"}</span>,
      sortable: false
    },
    {
      Header: "Formel",
      accessor: "formula",
      Cell: (cellData) => <span>{cellData.value}</span>,
      sortable: false
    }
  ];

  return (
    <div className="formula-history" style={{ marginTop: "0.5rem" }}>
      <CustomReactTable
        columns={tableColumns}
        data={formulas}
        minRows={0}
        pageSize={formulas.length}
      />
    </div>
  );
}

const forwardedRefField = React.forwardRef(
  ChangeableSpotMarketFormulaFieldStatic
);

export { forwardedRefField as ChangeableSpotMarketFormulaFieldStatic };
