import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../../../../api";
import urls from "../../../../urls";
import { ObjectName } from "../../../../utils/enums";
import { getPluralVariableNameFromObjectName } from "../../../../utils/getPluralVariableNameFromObjectName";

export function useAnlagenStromstgMutation(
  anlageStromstgId: number,
  siteId: number
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (generatorIds: Array<number>) =>
      mutateAnlagenStromstg(anlageStromstgId, generatorIds),
    onSuccess() {
      const objectCacheName = getPluralVariableNameFromObjectName(
        ObjectName.Generator
      );

      return queryClient.invalidateQueries({
        queryKey: [objectCacheName, { siteOrVariantId: siteId }]
      });
    }
  });
}

function mutateAnlagenStromstg(
  anlageStromstg: number,
  generatorIds: Array<number>
) {
  return api.post(urls.api.anlageStromstgSetGenerators(anlageStromstg), {
    generators: generatorIds
  });
}
