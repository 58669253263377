import { MeasurementType } from "../../../utils/enums";
import { CounterDataViewMode } from "../counter-data-view-types";

const measurementTypeToCounterDataViewMode: Record<
  MeasurementType,
  CounterDataViewMode
> = {
  [MeasurementType.Single]: CounterDataViewMode.DoubleIndex,
  [MeasurementType.Cumulative]: CounterDataViewMode.SingleIndex,
  [MeasurementType.Arbitrary]: CounterDataViewMode.SparseIndex
};

/** Get the `CounterDataViewMode` according to a given `MeasurementType`. */
export function getCounterDataViewModeByMeasurementType(
  type: MeasurementType
): CounterDataViewMode {
  return measurementTypeToCounterDataViewMode[type];
}
