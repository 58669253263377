import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import urls from "../../../urls";
import type {
  Paragraph6EEGCreditNumberRangeCreate,
  Paragraph6EEGCreditNumberRangeResponse,
  Paragraph6EEGCreditNumberRangeUpdate
} from "../Paragraph6.types";
import type { Paragraph6CreditNumberRangeFormValues } from "../Paragraph6CreditNumberRangesPage/Paragraph6CreditNumberRangesPage.types";

interface CreateMutationData {
  formValues: Paragraph6CreditNumberRangeFormValues;
}

interface MutationData extends CreateMutationData {
  id: Paragraph6EEGCreditNumberRangeResponse["id"];
}

export function useParagraph6CreditNumberRangeMutations(variantId: number) {
  const queryClient = useQueryClient();

  const createMutation = useMutation({
    mutationFn: (data: CreateMutationData) =>
      createParagraph6CreditNumberRange(data.formValues, variantId),
    onMutate: handleCreateOrEditMutate,
    onSettled: handleSettled
  });

  const editMutation = useMutation({
    mutationFn: (data: MutationData) =>
      editParagraph6CreditNumberRange(data.id, data.formValues),
    onMutate: handleCreateOrEditMutate,
    onSettled: handleSettled
  });

  const deleteMutation = useMutation({
    mutationFn: (rangeId: string) => deleteParagraph6CreditNumberRange(rangeId),
    onSettled: handleSettled
  });

  async function handleCreateOrEditMutate() {
    await queryClient.cancelQueries({
      queryKey: ["paragraph6CreditNumberRanges", { variantId }]
    });
  }

  function handleSettled() {
    return queryClient.invalidateQueries({
      queryKey: ["paragraph6CreditNumberRanges", { variantId }]
    });
  }

  return {
    createMutation,
    editMutation,
    deleteMutation
  };
}

function createParagraph6CreditNumberRange(
  formValues: Paragraph6CreditNumberRangeFormValues,
  variantId: number
) {
  const url =
    urls.apiWithoutCamelization.paragraph6CreditNumberRanges(variantId);
  return apiWithoutCamelization.post<Paragraph6EEGCreditNumberRangeResponse>(
    url,
    {
      counter_start_value: formValues.counter_start_value,
      format: formValues.format,
      supplier: formValues.supplier,
      valid_from: formValues.valid_from
    } satisfies Paragraph6EEGCreditNumberRangeCreate
  );
}

function editParagraph6CreditNumberRange(
  rangeId: string,
  formValues: Paragraph6CreditNumberRangeFormValues
) {
  const url = urls.apiWithoutCamelization.paragraph6CreditNumberRange(rangeId);
  return apiWithoutCamelization.patch<Paragraph6EEGCreditNumberRangeResponse>(
    url,
    {
      counter_start_value: formValues.counter_start_value,
      format: formValues.format,
      supplier: formValues.supplier,
      valid_from: formValues.valid_from
    } satisfies Paragraph6EEGCreditNumberRangeUpdate
  );
}

function deleteParagraph6CreditNumberRange(rangeId: string) {
  const url = urls.apiWithoutCamelization.paragraph6CreditNumberRange(rangeId);
  return apiWithoutCamelization.delete(url);
}
