import { MspImportFrequency } from "../../../utils/enums";

export function getMspImportFrequencyLabel(
  importFrequency: MspImportFrequency
) {
  switch (importFrequency) {
    case MspImportFrequency.Yearly:
      return "Jährlich";
    case MspImportFrequency.Monthly:
      return "Monatlich";
    case MspImportFrequency.Daily:
      return "Täglich";
    case MspImportFrequency.Unknown:
      return "Unbekannt";
    default:
      return importFrequency;
  }
}
