import urls from "../../../urls";
import type { MeteringOrMarketLocation } from "../../../utils/backend-types";
import type { FormInputData } from "../../BuildingBlocks/Forms/Controllers/FormFieldController";

export const METERING_LOCATION_FORM_FIELD_DATA = {
  number: {
    name: "number",
    label: "Messlokationsnummer",
    type: "text",
    required: true
  },
  customLabel: {
    name: "customLabel",
    label: "Bezeichnung",
    type: "text"
  },
  meteringServiceProvider: {
    name: "meteringServiceProvider",
    label: "Messstellenbetreiber",
    type: "async-select",
    placeholder: "Name oder Marktpartneridentifikationsnummer",
    dataUrl: urls.api.meteringServiceProviders()
  },
  status: {
    name: "status",
    label: "Status",
    type: "dropdown",
    helpText:
      "Beschreibt den Status der Lokation innerhalb dieser Liegenschaft. Inaktive Lokationen werden weder bei der Erstellung der Vollmachts-Vorlage noch bei der Anfrage nach Energiedaten beim Messstellenbetreiber berücksichtigt.",
    choices: [
      { displayName: "aktiv", value: "active" },
      { displayName: "inaktiv", value: "inactive" }
    ],
    required: true
  },
  comment: {
    name: "comment",
    label: "Kommentar",
    type: "text",
    helpText:
      "Frei wählbar, kann insbesondere genutzt werden um weitere Informationen bei einen Statuswechsel bereitzustellen."
  }
} as const satisfies {
  [key in keyof MeteringOrMarketLocation]?: FormInputData<
    MeteringOrMarketLocation,
    key
  >;
};
