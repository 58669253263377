const enum FieldName {
  Contract = "contract",
  Site = "site",
  DeliveryKind = "deliveryKind",
  DeliveringGenerators = "generators",
  PriceModel = "priceModel",
  WorkingPrices = "workingPrices",
  WorkingPricesPrognosis = "workingPricesPrognosis",
  ConcessionFees = "concessionFees",
  EnergyPriceWithAbgaben = "energyPriceWithAbgaben",
  ElectricityTax = "electricityTax",
  BillingMethod = "billingMethod",
  LossesTransfer = "lossesTransfer",
  IdentificationGraphic = "identificationGraphic",
  MixKind = "mixKind",
  GreenCountries = "greenCountries",
  NuclearPercentage = "nuclearPercentage",
  CoalPercentage = "coalPercentage",
  GasPercentage = "gasPercentage",
  OtherFossilPercentage = "otherFossilPercentage",
  TenantPercentage = "tenantPercentage",
  RenewableNotPromotedPercentage = "renewableNotPromotedPercentage",
  RenewablePromotedPercentage = "renewablePromotedPercentage",
  YearMix = "yearMix",
  EmissionCo2 = "emissionCo2",
  RadioactiveWaste = "radioactiveWaste",
  GridUsageBillingModel = "gridUsageBillingModel",
  GridUsagePowerPrices = "gridUsagePowerPrices",
  AutomaticallyDetermineGridFees = "automaticallyDetermineGridFees",
  UseSpotMarketPrices = "useSpotMarketPrices",
  SpotMarketPrices = "spotMarketPrices"
}

export { FieldName };
