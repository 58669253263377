import type { Choice } from "../TsDropdown";
import { getOptionFromChoice } from "./getOptionFromChoice";
import { getOptionsWithOptionalOption } from "./getOptionsWithOptionalOption";

/** Converts Dropdown Choices to Options used by `react-select`. */
export function getSimpleOptionsFromChoices(
  choices: Array<Choice>,
  includeOptional: boolean
) {
  const options = choices.map(getOptionFromChoice);
  return getOptionsWithOptionalOption(options, includeOptional);
}
