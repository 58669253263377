import { useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { TabContent, TabPane } from "reactstrap";
import type { Product } from "../../utils/enums";
import { ObjectName } from "../../utils/enums";
import { getPluralVariableNameFromObjectName } from "../../utils/getPluralVariableNameFromObjectName";
import { AlertColor } from "../Alert/Alert";
import { IconAlert } from "../BuildingBlocks/IconAlert/IconAlert";
import type { TabData } from "../BuildingBlocks/Layout/TabBar/TabBar";
import {
  ImportOrCreateStep,
  PersonMaStRImportSuccessWidget
} from "../VariantObjectWizard/ImportOrCreateStep/ImportOrCreateStep";
import { WizardSuccessWidget } from "../VariantObjectWizard/WizardSuccessWidget/WizardSuccessWidget";
import { usePersonMutation } from "./hooks/usePersonMutation";
import { PersonForm } from "./PersonForm/PersonForm";
import "./PersonWizard.scss";

// todo: remove in next PR, when edit wizard is replaced
export const CREATE_FORM_FIELD_NAMES_REDUCED = [
  "name",
  "address",
  "zip",
  "city"
];

const TABS: Array<TabData> = [
  {
    id: "general",
    label: "Allgemeine Daten"
  }
];

interface PersonWizardProps {
  buttonContainer?: HTMLElement;
  graphCoordinates: { x: number; y: number };
  product: Product;
  variantId: number;
  onClose: () => void;
}

function PersonWizard({
  buttonContainer,
  graphCoordinates,
  product,
  variantId,
  onClose
}: PersonWizardProps) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const activeTab = TABS[0].id;
  const { isSuccess: wasFormSubmittedSuccessfully, mutateAsync } =
    usePersonMutation("create", graphCoordinates, variantId);
  const [importOrCreateMode, setImportOrCreateMode] = useState<
    "import" | "create"
  >();
  const [maStRImportTaskId, setMaStRImportTaskId] = useState<string | null>(
    null
  );
  const [numErrors, setNumErrors] = useState(0);
  const [highlightedTabs, setHighlightedTabs] = useState<Array<string>>([]);

  // todo: use these variables in next PR (this is just nonsense code to satisfy eslint for now)
  if (numErrors > 0) {
    console.log(
      "todo: numErrors and highlightedTabs: ",
      numErrors,
      highlightedTabs
    );
  }

  const handleChangeNumErrors = useCallback((numErrors: number) => {
    setNumErrors(numErrors);
  }, []);
  const handleChangeNumHighlightedFields = useCallback(
    (numHighlightedFields: number) => {
      setHighlightedTabs(numHighlightedFields > 0 ? [TABS[0].id] : []);
    },
    []
  );

  function handleChooseManualCreate() {
    setImportOrCreateMode("create");
  }

  function handleImportOrCreateSuccess(taskId: string) {
    setImportOrCreateMode("import");
    setMaStRImportTaskId(taskId);
  }

  function refreshObjectData() {
    const objectCacheName = getPluralVariableNameFromObjectName(
      ObjectName.Person
    );

    queryClient.invalidateQueries({
      queryKey: [objectCacheName, { siteOrVariantId: variantId }]
    });
  }

  return (
    <div className="PersonWizard">
      {!importOrCreateMode ? (
        <ImportOrCreateStep
          buttonContainer={buttonContainer}
          objectName={ObjectName.Person}
          variantId={variantId}
          onCancel={onClose}
          onChooseManualCreate={handleChooseManualCreate}
          onImportSuccess={handleImportOrCreateSuccess}
        />
      ) : importOrCreateMode === "create" && !wasFormSubmittedSuccessfully ? (
        <TabContent activeTab={activeTab}>
          <TabPane tabId={TABS[0].id}>
            <PersonForm
              buttonContainer={buttonContainer}
              mode="create"
              product={product}
              onCancel={onClose}
              onChangeNumErrors={handleChangeNumErrors}
              onChangeNumHighlightedFields={handleChangeNumHighlightedFields}
              onSubmit={mutateAsync}
            />
          </TabPane>
        </TabContent>
      ) : importOrCreateMode === "create" ? (
        <WizardSuccessWidget
          buttonContainer={buttonContainer}
          message="Das Unternehmen wurde erstellt."
          onClose={onClose}
        />
      ) : maStRImportTaskId !== null ? (
        <PersonMaStRImportSuccessWidget
          buttonContainer={buttonContainer}
          lastStep
          taskId={maStRImportTaskId}
          onDoneStep={onClose}
          onObjectCreated={refreshObjectData}
        />
      ) : (
        <IconAlert color={AlertColor.Danger} icon="error">
          {t("errors.UnknownError")}
        </IconAlert>
      )}
    </div>
  );
}

export { PersonWizard, PersonWizardProps };
