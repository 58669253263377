import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Col, Popover, PopoverBody, Row } from "reactstrap";
import { IconName } from "../BuildingBlocks/Icon/types";
import { Button } from "../Buttons/Button/Button";
import { IconButton } from "../Buttons/IconButton/IconButton";

class SelectionPopover extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isPopoverOpen: false
    };
  }

  togglePopover = () => {
    if (this.props.onTogglePopover) {
      this.props.onTogglePopover();
    }

    this.setState({
      isPopoverOpen: !this.state.isPopoverOpen
    });
  };

  handleClickPrimaryButton = () => {
    this.togglePopover();
    this.props.onClickPrimaryButton();
  };

  render() {
    const { name, children, openButtonText, isPrimaryButtonDisabled } =
      this.props;

    const { isPopoverOpen } = this.state;

    return (
      <div className={name + "-selection"}>
        <IconButton
          color="brand"
          iconName={IconName.PlusCircle}
          id={name + "-selection-popover"}
          role="button"
          size="lg"
          onClick={this.togglePopover}
        >
          {openButtonText}
        </IconButton>
        <Popover
          id="selection-popover-content"
          isOpen={isPopoverOpen}
          placement="bottom-end"
          target={name + "-selection-popover"}
          toggle={this.togglePopover}
        >
          <PopoverBody className="p-3">
            {children}
            <Row>
              <Col>
                <Button
                  block
                  className="mt-3"
                  color="primary"
                  disabled={isPrimaryButtonDisabled}
                  role="button"
                  onClick={this.handleClickPrimaryButton}
                >
                  Auswählen
                </Button>
              </Col>
            </Row>
          </PopoverBody>
        </Popover>
      </div>
    );
  }
}

SelectionPopover.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  isPrimaryButtonDisabled: PropTypes.bool.isRequired,
  onClickPrimaryButton: PropTypes.func.isRequired,
  onTogglePopover: PropTypes.func
};

export { SelectionPopover };
