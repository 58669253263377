import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { Input } from "../Input/Input";

const DUMMY_VALUE = "<obfuscated>";
const EMPTY_VALUE = { target: { value: "" } };

/** @deprecated Use `FormInput` with type "password". See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function Secret({ value, onChange, ...otherProps }) {
  const [firstValue, setFirstValue] = useState("");
  const [firstFocus, setFirstFocus] = useState(false);

  const firstValueHasChanged = firstValue !== value;
  const showDummyValue = !firstValueHasChanged && firstValue !== "";
  const displayValue = showDummyValue ? DUMMY_VALUE : value;

  useEffect(() => {
    setFirstValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleFocus() {
    if (!firstFocus) {
      setFirstFocus(true);
      setFirstValue(DUMMY_VALUE);
      onChange(EMPTY_VALUE);
    }
  }

  return (
    <Input
      value={displayValue}
      onChange={onChange}
      {...otherProps}
      type="password"
      onFocus={handleFocus}
    />
  );
}

Secret.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired
};

export { Secret };
