import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../../../api";
import urls from "../../../urls";
import type { TodoNote } from "../../../utils/backend-types";
import type { NoteAdd, NoteUpdate } from "../TodoModal/Notes/Notes.types";

function fetchNotes(todoId: number) {
  return api
    .get<Array<TodoNote>>(urls.api.todoNotes(todoId))
    .then((response) => response.data);
}

function addNote({ note, internal, asSystemUser }: NoteAdd, todoId) {
  return api.post<TodoNote>(urls.api.todoNoteCreate(), {
    note,
    internal,
    asSystemUser,
    todo: todoId
  });
}

function updateNote({ noteId, note, internal }: NoteUpdate, todoId: number) {
  return api.post<TodoNote>(urls.api.todoNoteUpdate(noteId), {
    note,
    internal,
    todo: todoId
  });
}

function deleteNote(noteToDelete: number) {
  return api.delete(urls.api.todoNotesDetail(noteToDelete));
}

function useNotes({ todoId }: { todoId: number }) {
  const queryClient = useQueryClient();
  function invalidateNotes() {
    queryClient.invalidateQueries({
      queryKey: ["notes", todoId]
    });
  }

  const {
    data: notes,
    isLoading,
    error
  } = useQuery({
    queryKey: ["notes", todoId],
    queryFn: () => fetchNotes(todoId)
  });

  const addMutation = useMutation({
    mutationFn: (noteToAdd: NoteAdd) => addNote(noteToAdd, todoId),
    onSettled: invalidateNotes
  });

  const updateMutation = useMutation({
    mutationFn: (noteToUpdate: NoteUpdate) => updateNote(noteToUpdate, todoId),
    onSettled: invalidateNotes
  });

  const deleteMutation = useMutation({
    mutationFn: deleteNote,
    onSettled: invalidateNotes
  });

  return {
    addNote: addMutation.mutateAsync,
    updateNote: updateMutation.mutateAsync,
    deleteNote: deleteMutation.mutateAsync,
    notes,
    isLoading,
    error
  };
}

export { useNotes };
