import { useEffect, useState } from "react";

import type { ApiResponse } from "../../api";
import api from "../../api";
import { pollTaskStatus } from "../../utils/api-utils";
import type { FormFieldValue } from "../DynamicForm/FormItems/FormField/FormField";
import type { FormFieldData } from "../DynamicForm/FormItems/FormItems";
import { openErrorAlertPopup } from "../ErrorAlertPopup/openErrorAlertPopup";

/** Ensures value or values are not `undefined`. */
const getSafeValue = (value?: FormFieldValue | FormValues | null) => {
  if (value === undefined || value === null) {
    return null;
  }

  return Object.keys(value).reduce<FormValues>((values, key) => {
    values[key] = typeof value?.[key] === "undefined" ? null : value[key];

    return values;
  }, {});
};

export type FormValues = Record<string, FormFieldValue>;
export type FormErrors = Record<string, Array<string>>;
export type CustomFormOnSubmit = (data, resetForm?: () => void) => void;
export type CustomFormOnInput = (
  name: string | null,
  value: FormFieldValue | Record<string, FormFieldValue>
) => void;
export type CustomFormOnPollSuccess = (a: string[]) => void;

export interface CustomFormOptions {
  nonFieldData?: FormValues;
  postUrl?: string;
  putUrl?: string;
  patchUrl?: string;
  onInput?: CustomFormOnInput;
  onSubmit?: CustomFormOnSubmit;
  onFirstSubmit?: () => void;
  onPollSuccess?: (taskIds: string[]) => void;
  onError?: (error: unknown) => void;
}

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
export function useCustomForm(
  formFields: Array<FormFieldData> | undefined,
  customFormOptions: CustomFormOptions
) {
  const [formValues, setFormValues] = useState<FormValues | undefined>(
    getInitialValues(formFields)
  );
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [waitingForMoreInfo, setWaitingForMoreInfo] = useState<boolean>(false);
  const [moreInfoNeededEvaluationId, setMoreInfoNeededEvaluationId] = useState<
    string | undefined
  >(undefined);
  const {
    nonFieldData,
    postUrl,
    putUrl,
    patchUrl,
    onInput,
    onSubmit,
    onFirstSubmit,
    onPollSuccess,
    onError
  } = customFormOptions;

  useEffect(() => {
    const initialFormValues = getInitialValues(formFields);

    if (initialFormValues) {
      setFormValues((prevFormValues) => {
        return {
          ...getInitialValues(formFields),
          ...prevFormValues
        };
      });
    }
  }, [formFields]);

  function getInitialValues(
    formFields: Array<FormFieldData> | undefined
  ): FormValues | undefined {
    if (!formFields) {
      return undefined;
    }

    return formFields.reduce((obj, f) => {
      if (typeof f.initialValue !== "undefined") {
        obj[f.name] = f.initialValue;
      }

      return obj;
    }, {});
  }

  function changeValue(
    name: string | null,
    value?: FormFieldValue | FormValues | null
  ) {
    // either name must be the name of the form field and value the value it's set to
    // or name can be `null` and value an object name, value pairs. This is to allow updates of multiple fields
    // in one `onManualInput` call
    const newValues =
      name === null
        ? getSafeValue(value)
        : ({ [name]: value ?? null } as FormValues);

    setFormValues({
      ...formValues,
      ...newValues
    });

    if (onInput) {
      onInput(name, value as FormValues);
    }
  }

  function submit(): Promise<boolean> {
    setFormErrors({});

    return sendFormValues().then((success) => {
      if (onFirstSubmit) {
        onFirstSubmit();
      }

      return Promise.resolve(success);
    });
  }

  async function sendFormValues() {
    const formValuesWithDefaults: FormValues = eliminateEmptyStrings(
      formValues || {},
      formFields
    );

    const filteredFormValues = filterOutIrrelevantValues(
      formValuesWithDefaults,
      formFields
    );

    // add any non-field data
    const data = {
      ...filteredFormValues,
      ...(nonFieldData ? nonFieldData : {})
    };

    setSubmitting(true);
    setWaitingForMoreInfo(false);

    let response: ApiResponse<FormValues> | undefined;

    try {
      if (postUrl) {
        response = await api.post(postUrl, data);
      } else if (patchUrl) {
        response = await api.patch(patchUrl, data);
      } else if (putUrl) {
        response = await api.put(putUrl, data);
      }
    } catch (error) {
      setSubmitting(false);

      if (error.response && error.response.status === 400) {
        setFormErrors(error.response.data);

        if (onError) {
          onError(error.response.data);
        }
      } else {
        openErrorAlertPopup(error);
      }
    }

    if (response) {
      if (response.data.evaluationId) {
        // if an evaluation id was returned, we need to get more info
        setMoreInfoNeededEvaluationId(response.data.evaluationId as string);
        setSubmitting(false);
        setWaitingForMoreInfo(true);
      } else if (response.data.taskStatusUrl) {
        // if there is a task status url, we need to poll for the result
        const taskIds = response.data.taskIds as string[];

        pollTaskStatus(
          response.data.taskStatusUrl as string,
          () => {
            if (onPollSuccess) {
              onPollSuccess(taskIds);
            } else {
              sendFormValues();
            }
          },
          (error) => {
            openErrorAlertPopup(error);
            setSubmitting(false);
          },
          (_, evaluationId: string) => {
            setMoreInfoNeededEvaluationId(evaluationId);
            setSubmitting(false);
            setWaitingForMoreInfo(true);
          }
        );
      } else {
        // otherwise, call onSubmit with the response data - we're done!
        if (onSubmit) {
          onSubmit(response.data, () => setSubmitting(false));
        }

        return Promise.resolve(true);
      }
    }

    return Promise.resolve(false);
  }

  return {
    formValues,
    formErrors,
    submitting,
    waitingForMoreInfo,
    moreInfoNeededEvaluationId,
    changeValue,
    submit,
    setWaitingForMoreInfo
  };
}

// adjust for some field types, so that backend does not get empty strings
function eliminateEmptyStrings(
  formValues: FormValues,
  formFields?: Array<FormFieldData>
): FormValues {
  if (!formFields) {
    return formValues;
  }

  const newFormValues: FormValues = { ...formValues };

  formFields.forEach((formField) => {
    const formValue = newFormValues[formField.name];
    const formTypeIsDropdown =
      formField.type === "choice" || formField.type === "field";

    if (formTypeIsDropdown && formValue === "") {
      // blank dropdowns should be sent as null to the backend, not as empty string
      newFormValues[formField.name] = null;
    } else if (formField.type === "date" && formValue === "") {
      // blank date input boxes should be sent as null to the backend, not as empty string
      newFormValues[formField.name] = null;
    }
  });

  return newFormValues;
}

// eliminate values which don't correspond to form fields (e.g. if the form fields changed)
function filterOutIrrelevantValues(
  formValues: FormValues,
  formFields?: Array<FormFieldData>
): FormValues {
  if (!formFields) {
    return formValues;
  }

  let valueKeys = Object.keys(formValues);
  valueKeys = valueKeys.filter((valueKey) =>
    formFields.find((field) => field.name === valueKey)
  );

  return valueKeys.reduce((values, key) => {
    values[key] = formValues[key];
    return values;
  }, {});
}
