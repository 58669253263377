import { useQuery } from "@tanstack/react-query";

import { apiWithoutCamelization } from "../api";
import urls from "../urls";
import type { Site } from "../utils/backend-types";

/** Returns the `variantId` and the `Site` for a given `siteId`. */
export function useSiteVariantId(siteId: number | undefined, disabled = false) {
  const {
    isLoading,
    error,
    data: site
  } = useQuery({
    queryKey: ["site", { siteId }],
    queryFn: () => fetchSite(siteId),
    enabled: !disabled && !!siteId,
    refetchInterval: false,
    refetchOnWindowFocus: false
  });

  async function fetchSite(siteId: number | undefined) {
    if (!siteId) {
      return undefined;
    }

    const response = await apiWithoutCamelization.get<Site>(
      urls.apiWithoutCamelization.sitesDetail(siteId)
    );

    return response.data;
  }

  const variantId = site ? site.variant : undefined;

  return { isLoading, error, site, variantId };
}
