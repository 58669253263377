import type { TFunction } from "i18next";
import jsep from "jsep";
import type { ExpressionEditorProps } from "../FormFields/ExpressionEditor/ExpressionEditor";

function translateErrorMessage(msg: string, t: TFunction) {
  let matches: RegExpExecArray | null;

  if (
    (matches = /Expected expression after (.*) at character (\d+)/.exec(msg))
  ) {
    return t("errors.ExpressionEditor.MissingExpressionAfterCharacter", {
      character: matches[1],
      position: matches[2]
    });
  }

  if ((matches = /Expected (.*) at character (\d+)/.exec(msg))) {
    let expectationString = "";
    switch (matches[1]) {
      case "comma":
        expectationString = "Komma";
        break;

      case "expression":
        expectationString = "Ausdruck";
        break;

      default:
        expectationString = matches[1];
    }

    return t("errors.ExpressionEditor.MissingElement", {
      expectedElement: expectationString,
      position: matches[2]
    });
  }

  if ((matches = /Unexpected (?:token )?(.*) at character (\d+)/.exec(msg))) {
    return t("errors.ExpressionEditor.UnexpectedElement", {
      unexpectedElement: matches[1],
      position: matches[2]
    });
  }

  if ((matches = /Unclosed \( at character (\d+)/.exec(msg))) {
    return t("errors.ExpressionEditor.ParenthesisNotClosed", {
      position: matches[1]
    });
  }

  if ((matches = /missing unaryOp argument at character (\d+)/.exec(msg))) {
    return t("errors.ExpressionEditor.MissingNumberOrVariable", {
      position: matches[1]
    });
  }

  return t("errors.ExpressionEditor.NonSpecificError");
}

export function validateExpression(
  value: ExpressionEditorProps["value"],
  required: boolean,
  t: TFunction
) {
  if (!value) {
    return required ? t("errors.Required") : true;
  }

  try {
    jsep(value);
  } catch (exception) {
    return translateErrorMessage(exception.message, t);
  }

  return true;
}
