import { useQuery } from "@tanstack/react-query";
import api from "../api";
import urls from "../urls";
import type { AnlageStromStg } from "../utils/backend-types";

export function useAnlageStromstg(anlageStromstgId?: number) {
  const { data, error, isLoading } = useQuery({
    queryKey: ["anlageStromStg-" + anlageStromstgId],
    queryFn: () => fetchAnlageStromStg(anlageStromstgId),
    enabled: !!anlageStromstgId
  });

  return { data, error, isLoading };
}

export async function fetchAnlageStromStg(anlageStromstgId?: number) {
  if (!anlageStromstgId) {
    return undefined;
  }

  const anlageUrl = urls.api.anlagenStromstgDetailUrl(anlageStromstgId);
  const response = await api.get<AnlageStromStg>(anlageUrl);

  return response.data;
}
