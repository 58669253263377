import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { useParams } from "react-router-dom";

import urls from "../../urls";
import loader from "../Loader";
import SimulationComparison from "./SimulationComparison";

class SimulationPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      baseVariant: this.props.data.simulation.variant,
      scenarios: this.props.data.simulation.scenarios
    };
  }

  getConceptUrlsForScenario = (scenarios) => {
    return scenarios.reduce((obj, scenario) => {
      const key = scenario.variant.id;
      obj[key] = urls.api.meteringConcept(key);
      return obj;
    }, {});
  };

  keyScenarioValuesByVariantId = (scenarios, value) => {
    return scenarios.reduce((obj, scenario) => {
      obj[scenario.variant.id] = scenario[value];
      return obj;
    }, {});
  };

  addMeteringConceptsForBaseVariant = (otherConceptUrls, baseVariant) => {
    const baseVariantId = baseVariant.id;
    return {
      ...otherConceptUrls,
      [baseVariantId]: urls.api.meteringConcept(baseVariant.id)
    };
  };

  render() {
    const {
      data: {
        simulation: { variant: baseVariant, scenarios }
      }
    } = this.props;

    const otherConceptUrls = this.getConceptUrlsForScenario(scenarios);
    const allConceptUrls = this.addMeteringConceptsForBaseVariant(
      otherConceptUrls,
      baseVariant
    );
    const variants = [baseVariant].concat(
      scenarios.map((scenario) => scenario.variant)
    );
    const additionalInstalledPower = this.keyScenarioValuesByVariantId(
      scenarios,
      "installedCapacity"
    );
    additionalInstalledPower[baseVariant.id] = 0;

    return (
      <SimulationComparison
        additionalInstalledPower={additionalInstalledPower}
        dataUrls={allConceptUrls}
        scenarios={scenarios}
        variants={variants}
      />
    );
  }
}

SimulationPage.propTypes = {
  data: PropTypes.shape({
    simulation: PropTypes.shape({
      scenarios: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          variant: PropTypes.object,
          installedPower: PropTypes.number
        })
      )
    })
  }).isRequired
};

const SimulationPageWithLoader = loader(SimulationPage);

function SimulationPageWithLoaderWrapper(props) {
  const { simulationId } = useParams();
  const dataUrls = {
    simulation: urls.api.simulationDetail(simulationId)
  };

  return (
    <SimulationPageWithLoader
      dataUrls={dataUrls}
      loadingText="Einen Moment bitte, aktuell werden fünf Varianten bewertet. Dies kann einige Minuten dauern."
      redirectHeaderText="Weitere Daten erforderlich"
      redirectText="Es werden weitere Informationen benötigt damit die Berechnung möglichst genau ausgeführt werden kann."
      {...props}
    />
  );
}

export default SimulationPageWithLoaderWrapper;
