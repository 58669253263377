import type { DropdownOption } from "../Dropdown.types";
import type { Choice } from "../TsDropdown";
import { getLabelFromChoice } from "./getLabelFromChoice";

/** Converts Dropdown Choice to Option used by `react-select`. */
export function getOptionFromChoice(choice: Choice): DropdownOption {
  const label = getLabelFromChoice(choice);

  if (typeof choice.isFixed !== "undefined") {
    return {
      value: choice.value,
      label: label,
      isFixed: choice.isFixed
    };
  }

  return {
    value: choice.value,
    label: label
  };
}
