import React from "react";
import { IconHelpText } from "../../../../IconHelpText/IconHelpText";
import { OrangeCircle } from "../../../../OrangeCircle/OrangeCircle";
import "./FormFieldLabel.scss";

interface FormFieldLabelProps {
  formFieldId: string;
  label?: string;
  required?: boolean;
  helpText?: string;
  highlight?: boolean;
}

function FormFieldLabel({
  formFieldId,
  label,
  required,
  helpText,
  highlight
}: FormFieldLabelProps) {
  if (!label) {
    return null;
  }

  return (
    <div className="FormFieldLabel">
      <div className="label-title-container">
        {highlight && <OrangeCircle />}
        <label htmlFor={formFieldId}>
          {label}
          {required && "*"}
        </label>
      </div>
      {helpText && <IconHelpText helpText={helpText} />}
    </div>
  );
}

export { FormFieldLabel, FormFieldLabelProps };
