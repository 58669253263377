import React, { useCallback, useState } from "react";
import ReactDOM from "react-dom";
import { Card, CardBody, Collapse } from "reactstrap";
import type { ApiResponse } from "../../../api";
import urls, { OptiSupportEndpoints } from "../../../urls";
import type { Meter, Site } from "../../../utils/backend-types";
import { DataImportRequestType } from "../../../utils/enums";
import { AnchorLink } from "../../AnchorLink/AnchorLink";
import { Icon } from "../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../BuildingBlocks/Icon/types";
import { Button } from "../../Buttons/Button/Button";
import { SpinButton } from "../../Buttons/SpinButton/SpinButton";
import { DummyLink } from "../../DummyLink/DummyLink";
import type {
  EnergyDataFile,
  EnergyDataUploadResponse
} from "./EnergyDataUpload";
import { uploadEnergyDataFiles } from "./EnergyDataUpload";
import { FileList } from "./FileList/FileList";
import { EnergyDataUploadExplanationWithTemplateDownload } from "./Template/EnergyDataUploadExplanationWithTemplateDownload";
import "./EnergyDataUploadFlow.scss";
import { UploadDropzone } from "./UploadDropzone/UploadDropzone";

export interface EnergyDataUploadPartialResult {
  energyDataFile: EnergyDataFile;
  uploadPromise: Promise<ApiResponse<EnergyDataUploadResponse>>;
}

export interface EnergyDataUploadFlowProps {
  variantId: number;
  meters: Array<Meter>;
  sites: Array<Site>;
  defaultMeterId?: number;
  buttonContainer?: HTMLElement;
  onDone: (
    energyDataUploadPartialResults?: Array<EnergyDataUploadPartialResult>
  ) => void;
}

function EnergyDataUploadFlow({
  variantId,
  meters,
  sites,
  defaultMeterId,
  buttonContainer,
  onDone
}: EnergyDataUploadFlowProps) {
  const [energyDataFiles, setEnergyDataFiles] = useState<EnergyDataFile[]>([]);
  const [uploading, setUploading] = useState(false);

  function handleChooseFiles(files: Array<File>) {
    const newEnergyDataFiles = files.map<EnergyDataFile>((file) => ({
      id: `xxx${Math.floor(Math.random() * 1000000)}`,
      name: file.name,
      data: file,
      selectedMeter: null,
      manySelected: true,
      typeOfData: DataImportRequestType.METERING
    }));

    setEnergyDataFiles((oldFilesToUpload) => [
      ...oldFilesToUpload,
      ...newEnergyDataFiles
    ]);
  }

  function handleClickCancel() {
    onDone();
  }

  function handleChangeFileMeter(
    index: number,
    selectedMeter: number | null,
    manySelected: boolean
  ): void {
    const newFiles = [...energyDataFiles];

    newFiles[index] = {
      ...newFiles[index],
      selectedMeter,
      manySelected
    };

    setEnergyDataFiles(newFiles);
  }

  function handleRemoveFile(index: number): void {
    const newFiles = [...energyDataFiles];
    newFiles.splice(index, 1);

    setEnergyDataFiles(newFiles);
  }

  function handleClickImportFiles(): void {
    setUploading(true);

    const energyDataFilePromises = uploadEnergyDataFiles(
      energyDataFiles,
      variantId
    );

    const partialResults: Array<EnergyDataUploadPartialResult> =
      energyDataFilePromises.map((promise, index) => ({
        energyDataFile: energyDataFiles[index],
        uploadPromise: promise
      }));

    onDone(partialResults);
  }

  const shouldDisableImportButton = useCallback(() => {
    if (uploading || energyDataFiles.length < 1) {
      return true;
    }

    return energyDataFiles.some(({ typeOfData }) => !typeOfData);
  }, [energyDataFiles, uploading]);

  return (
    <div className="EnergyDataUploadFlow UploadStep">
      <ExplanationText defaultMeterId={defaultMeterId} variantId={variantId} />
      <h5>Energiedaten hochladen</h5>
      <FileList
        energyDataFiles={energyDataFiles}
        meters={meters}
        sites={sites}
        onChangeFileMeter={handleChangeFileMeter}
        onRemoveFile={handleRemoveFile}
      />
      <UploadDropzone onChooseFiles={handleChooseFiles} />
      {buttonContainer &&
        ReactDOM.createPortal(
          <div>
            <Button color="secondary" onClick={handleClickCancel}>
              Abbrechen
            </Button>
            <SpinButton
              color="primary"
              disabled={shouldDisableImportButton()}
              spin={uploading}
              style={{ marginLeft: "3px" }}
              onClick={handleClickImportFiles}
            >
              Daten importieren
            </SpinButton>
          </div>,
          buttonContainer
        )}
    </div>
  );
}

interface ExplanationTextProps {
  defaultMeterId?: number;
  variantId: number;
}

function ExplanationText({ defaultMeterId, variantId }: ExplanationTextProps) {
  return (
    <div className="ExplanationText">
      <p>
        Hier können Sie Excel-Dateien mit Messwerten hochladen. Die Dateien
        werden unmittelbar verarbeitet und die Daten gespeichert. Eine
        ausführliche Anleitung zum Upload finden Sie
        <AnchorLink
          href={urls.optiSupport(
            OptiSupportEndpoints.EnergiedatenBereitstellen
          )}
        >
          {" "}
          hier
        </AnchorLink>
        .
      </p>
      <section>
        <h5>Datenformate</h5>
        <h6>Das opti.node-Datenformat</h6>
        <p>
          Am zuverlässigsten können Daten importiert werden, wenn sie dem
          opti.node-Datenformat entsprechen. Dazu können Sie sich eine
          individuelle Vorlage für Ihr Projekt erstellen lassen.
        </p>
        <Collapsible title="Vorlage erstellen">
          <Card>
            <CardBody>
              <EnergyDataUploadExplanationWithTemplateDownload
                defaultMeterId={defaultMeterId}
                variantId={variantId}
              />
              <p>
                Wenn Sie das opti.node-Datenformat{" "}
                <strong>für alle Zähler</strong> verwenden, müssen Sie beim
                Upload keinen Zähler festlegen, sondern können die Option{" "}
                <em>Mehrere Zähler</em> auswählen.
              </p>
            </CardBody>
          </Card>
        </Collapsible>
      </section>
      <section>
        <h6>Weitere Datenformate</h6>
        <p>
          opti.node kann auch exportierte Dateien aus einer Vielzahl an anderen
          Systemen direkt einlesen. Wenn Sie Dateien aus Untermesssystemen
          hochladen möchten, beachten Sie bitte die Hinweise. Beispiele zu
          Dateien, die eingelesen werden können haben wir
          <AnchorLink
            href={urls.optiSupport(
              OptiSupportEndpoints.EnergiedatenBereitstellen
            )}
          >
            {" "}
            hier
          </AnchorLink>
          gesammelt.
        </p>
        <Collapsible title="Hinweise für weitere Datenformate">
          <Card>
            <CardBody>
              <p>
                Häufig gestaltet sich die Zuordnung von Daten zu einzelnen
                Zählern als schwierig. Sofern eine Datei nur Daten eines Zählers
                enthält, können Sie beim Upload den Zähler auswählen. Wenn Daten
                mehrerer Zähler enthalten sind, achten Sie bitte darauf, dass
                die Zählernummern eindeutig aus den Spaltenüberschriften
                hervorgehen oder die jeweilige Nummer in jeder Reihe steht. Sie
                können dann die Option <em>Mehrere Zähler</em>
                auswählen.
              </p>
              <p>
                Um Komplikationen beim Import zu vermeiden, achten Sie bitte
                darauf, dass folgende Informationen in den Dateien enthalten
                sind:
              </p>
              <ul>
                <li>
                  Möglichst lange, zusammenhängende Messzeiträume in einer Datei
                  (z.B. ein Jahr)
                </li>
                <li>
                  Das Messintervall der Zeitreihe muss dem Messintervall
                  entsprechen, das auf dem zugehörigen Zähler in opti.node
                  hinterlegt ist. Zulässige Intervalle sind: 10 Minuten,
                  Viertelstunde, Stunde, Tag, Monat, Jahr
                </li>
                <li>
                  Eine Angabe zur genutzten Zeitzone (z.B. UTC, CET, o.ä.)
                </li>
                <li>Die Einheit der Werte (meist kWh oder kW)</li>
                <li>
                  Eindeutige Benennung der Spalten für die Erzeugung und
                  Verbrauch
                </li>
              </ul>
            </CardBody>
          </Card>
        </Collapsible>
      </section>
    </div>
  );
}

function Collapsible({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const toggleIcon = isOpen ? IconName.CaretDown : IconName.CaretRight;

  return (
    <div className="Collapsible">
      <DummyLink onClick={toggle}>
        <Icon name={toggleIcon} />
        {title}
      </DummyLink>
      <Collapse isOpen={isOpen}>{children}</Collapse>
    </div>
  );
}

export { EnergyDataUploadFlow };
