import React from "react";

import { formatMeterAssignmentErrorDetails } from "../../utils/formatMeterAssignmentErrorDetails";
import type { DifferentParamsInfo } from "../../utils/showSaveMeterErrorToast";

interface SaveMeterErrorProps {
  errorExtraInfo: boolean;
  errorMessage?: string;
  meterName?: string;
  meterDifferingParameters?: DifferentParamsInfo;
}

function SaveMeterError({
  errorMessage,
  meterName,
  errorExtraInfo,
  meterDifferingParameters
}: SaveMeterErrorProps) {
  return (
    <span>
      {errorMessage}
      {meterName ? `: ${meterName}` : ""}
      <br />
      {errorExtraInfo &&
        formatMeterAssignmentErrorDetails(meterDifferingParameters || [])?.map(
          (errorData) => (
            <>
              <span>- {errorData}</span>
              <br />
            </>
          )
        )}
    </span>
  );
}

export { SaveMeterError };
