import type { Person } from "../../../utils/backend-types";
import type { FormInputData } from "../../BuildingBlocks/Forms/Controllers/FormFieldController";

export const PERSON_FORM_FIELD_DATA = {
  name: {
    name: "name",
    label: "Bezeichnung",
    type: "text",
    required: true
  },
  contact: {
    name: "contact",
    label: "Ansprechpartner",
    type: "text"
  },
  address: {
    name: "address",
    label: "Anschrift",
    type: "text"
  },
  zip: {
    name: "zip",
    label: "Postleitzahl",
    type: "text",
    maxLength: 5 // todo??
  },
  city: {
    name: "city",
    label: "Ort",
    type: "text"
  }
} as const satisfies {
  [key in keyof Person]?: FormInputData<Person, key>;
};
