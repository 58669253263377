import { useQuery } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../api";
import urls from "../urls";
import type { ExtendedUser } from "../utils/backend-types";

interface UserProfileReturnValue {
  profile: ExtendedUser | undefined;
  isLoading: boolean;
  error: unknown;
}

function useUserProfile(): UserProfileReturnValue {
  const {
    isLoading: isSitesListLoading,
    error: sitesListError,
    data: profile
  } = useQuery({
    queryKey: ["profile"],
    queryFn: () => fetchProfile()
  });

  async function fetchProfile() {
    const response = await apiWithoutCamelization.get<ExtendedUser>(
      urls.apiWithoutCamelization.userProfile()
    );
    return response.data;
  }

  return {
    isLoading: isSitesListLoading,
    error: sitesListError,
    profile: profile
  };
}

export { useUserProfile };
