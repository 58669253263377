import React, { useState } from "react";
import { showToast } from "../../../utils/toast";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../BuildingBlocks/Layout/Modal/Modal";
import { DeleteConfirmationModal } from "../../BuildingBlocks/Layout/Modals/DeleteConfirmationModal/DeleteConfirmationModal";
import { Button } from "../../Buttons/Button/Button";
import { SpinButton } from "../../Buttons/SpinButton/SpinButton";
import { ORIGIN } from "../CreateEdaVersionModal/CreateEdaVersionModal.types";
import type { Eda } from "../EdaTable/Mbk";
import { useMbksMutation } from "../EdaVisualization/hooks/useMbks";
import "./ViewEdaModal.scss";

type ViewEdaModal = {
  isOpen: boolean;
  siteId: number;
  onToggle: () => void;
  eda: Eda;
  onEditEda: () => void;
};

export function ViewEdaModal({
  isOpen,
  onEditEda,
  siteId,
  onToggle,
  eda
}: ViewEdaModal) {
  const { mutateAsync: deleteEDA, isPending } = useMbksMutation(
    siteId,
    "DELETE"
  );
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] =
    useState(false);

  async function handleDeleteEda() {
    setDeleteConfirmationIsOpen(false);

    try {
      await deleteEDA([eda]);
      onToggle();
    } catch (error) {
      showToast("error", error);
    }
  }

  const medium = eda.versions[0]?.computation?.medium;

  return (
    <Modal fade={false} isOpen={isOpen} toggle={onToggle}>
      <ModalHeader toggle={onToggle}>Energiedatenerfassung</ModalHeader>
      <ModalBody scrollable>
        <div>
          <div className="viewEdaModal-property">
            <h6>ID</h6>
            <span className="viewEdaModal-value">
              {eda.versions[0].publicId}
            </span>
          </div>
          <div className="viewEdaModal-property">
            <h6>Art der Energiemenge</h6>
            <span className="viewEdaModal-value">{eda.identity.type}</span>
          </div>
          <div className="viewEdaModal-property">
            <h6>Referenzen</h6>
            <span className="viewEdaModal-value">
              {Object.keys(eda.identity)
                .filter((i) => i !== "type")
                .join(", ")}
            </span>
          </div>

          <div className="viewEdaModal-property">
            <h6>Herkunft</h6>
            <span className="viewEdaModal-value">
              {ORIGIN[eda.versions[0].origin]}
            </span>
          </div>
          <div className="viewEdaModal-property">
            <h6>Ergänzung</h6>
            <span className="viewEdaModal-value">{eda.versions[0].label}</span>
          </div>
          <div className="viewEdaModal-property">
            <h6>Gültigkeitzeitraum</h6>
            <span className="viewEdaModal-value">
              {eda.versions[0].firstDate} - {eda.versions[0].lastDate}
            </span>
          </div>
          {medium && (
            <div className="viewEdaModal-property">
              <h6>Medium</h6>
              <span className="viewEdaModal-value">Strom</span>
            </div>
          )}
          {eda?.versions[0]?.computation?.formulaStr && (
            <div className="viewEdaModal-property">
              <h6>Details</h6>
              <span className="viewEdaModal-value">
                {eda.versions[0].computation.formulaStr}
              </span>
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <SpinButton
          color="danger"
          spin={isPending}
          onClick={() => setDeleteConfirmationIsOpen(true)}
        >
          Löschen
        </SpinButton>
        <Button color="brand" onClick={onEditEda}>
          Bearbeiten
        </Button>
        <Button color="secondary" onClick={onToggle}>
          Schließen
        </Button>
        <DeleteConfirmationModal
          isModalOpen={deleteConfirmationIsOpen}
          name={`Energiedatenerfassung ${eda.versions[0].publicId}`}
          toggleModal={() => setDeleteConfirmationIsOpen(false)}
          onClickConfirm={() => handleDeleteEda()}
        />
      </ModalFooter>
    </Modal>
  );
}
