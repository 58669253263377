import React from "react";
import { NumericFormat } from "react-number-format";
import type { NumericFormatProps } from "react-number-format";

export type RoundedNumberFormatProps = NumericFormatProps;

function RoundedNumberFormat({ value, ...other }: RoundedNumberFormatProps) {
  let roundedValue = value;

  if (value && value !== "" && Math.abs(Number(value)) < 1e-5) {
    roundedValue = 0;
  }

  return (
    <NumericFormat
      decimalSeparator=","
      thousandSeparator="."
      value={roundedValue}
      {...other}
    />
  );
}

export { RoundedNumberFormat };
