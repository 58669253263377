import React from "react";
import { FormItem } from "../../../FormItem/FormItem";
import type { FormFieldData, FormItemsProps } from "../../FormItems";
import "./RightAlignedField.scss";

interface RightAlignedFieldProps extends Omit<FormItemsProps, "formItems"> {
  fieldNames: [string];
  formItems: Record<string, FormFieldData>;
}

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide
 *
 * Designed to be used in conjunction with withCombinedFields, this components aligns a FormField to the right.
 */
function RightAlignedField({
  fieldNames,
  formName,
  formItems,
  formValues,
  formErrors,
  allowInput,
  onInput
}: RightAlignedFieldProps) {
  const fieldName = fieldNames[0];
  const { [fieldName]: formItem } = formItems;
  const { [fieldName]: value } = formValues;
  const { [fieldName]: errorTexts } = formErrors;

  return (
    <div className="RightAlignedField">
      <FormItem
        {...formItem}
        allowInput={allowInput}
        errors={errorTexts}
        formName={formName}
        value={value}
        onInput={onInput}
      />
    </div>
  );
}

export { RightAlignedField, RightAlignedFieldProps };
