import React, { useEffect } from "react";
import { useAnlagenStromstgGenerators } from "../../../hooks/useAnlagenStromstgGenerators";
import { usePersonGenerators } from "../../../hooks/usePersonGenerators";
import { useVariantSites } from "../../../hooks/useVariantSites";
import type { Generator } from "../../../utils/backend-types";
import { Alert, AlertColor } from "../../Alert/Alert";
import { Icon } from "../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../BuildingBlocks/Icon/types";
import { LoadOrError } from "../../LoadOrError/LoadOrError";
import "./AnlageStromstgFlow.scss";
import { AnlageStromstgForm } from "./AnlageStromstgForm/AnlageStromstgForm";
import { useAnlagenStromstgMutation } from "./hooks/useAnlagenStromstgMutation";

interface AnlageStromstgFlowProps {
  buttonContainer?: HTMLElement;
  currentGenerator: Generator;
  currentGeneratorIsNew?: boolean;
  multiSite?: boolean;
  shouldSkip?: boolean;
  siteId: number;
  variantId: number;
  onSubmit: () => void;
}

function AnlageStromstgFlow({
  buttonContainer,
  currentGenerator,
  currentGeneratorIsNew = false,
  multiSite,
  shouldSkip,
  siteId,
  variantId,
  onSubmit
}: AnlageStromstgFlowProps) {
  /*
    shouldSkip instantly calls onSubmit, which means this step is omitted.
    Since this step should currently only be shown for generators of type wind,
    its skipped for every other generator type.
  */

  const {
    sites,
    isLoading: isSitesLoading,
    error: sitesError
  } = useVariantSites(variantId);
  const {
    data: generators,
    isLoading: isGeneratorsLoading,
    error: generatorsError
  } = usePersonGenerators(currentGenerator.person);
  const {
    anlagenStromstgGenerators,
    isLoading: isAnlagenStromstgGeneratorsLoading,
    error: anlagenStromstgGeneratorsError
  } = useAnlagenStromstgGenerators(currentGenerator.anlageStromstg);
  const anlagenStromstgMutation = useAnlagenStromstgMutation(
    currentGenerator.anlageStromstg,
    siteId
  );

  useEffect(() => {
    if (shouldSkip) {
      onSubmit();
    }
  }, [onSubmit, shouldSkip]);

  async function handleSubmitAnlageStromstgForm(generatorIds: Array<number>) {
    try {
      await anlagenStromstgMutation.mutateAsync(generatorIds);
    } catch (error) {
      return Promise.reject(error);
    }

    onSubmit();

    return Promise.resolve();
  }

  function handleSkipStep() {
    onSubmit();
  }

  const filteredGenerators = multiSite
    ? generators
    : generators?.filter((generator) => generator.site === siteId);

  return (
    <div className="AnlageStromstgFlow">
      <LoadOrError
        error={sitesError || generatorsError || anlagenStromstgGeneratorsError}
        loading={
          isSitesLoading ||
          isGeneratorsLoading ||
          isAnlagenStromstgGeneratorsLoading
        }
      >
        {sites && filteredGenerators && anlagenStromstgGenerators && (
          <>
            <div className="header">
              <h6>Stromsteuerinformationen</h6>
              <a
                href="https://support.node.energy/knowledge/verklammerung-von-anlagen"
                rel="noreferrer"
                target="_blank"
              >
                <Icon name={IconName.QuestionCircle2} />
                &nbsp;&nbsp;
                <span>opti.node-Hilfe</span>
              </a>
            </div>
            {!currentGeneratorIsNew && (
              <Alert className="edit-mode-alert" color={AlertColor.Info}>
                Beim Hinzufügen eines Erzeugers zu einer bestehenden
                stromsteuerlichen Anlage werden die Stromsteuer betreffende
                regulatorische Informationen (z. B. zu bestehenden
                Stromsteuer-Befreiungen) der stromsteuerlichen Anlage
                beibehalten bzw. übernommen. Sofern vorhanden, werden die
                entsprechenden Informationen auf dem Erzeuger nicht übernommen
                bzw. verworfen.
              </Alert>
            )}
            <AnlageStromstgForm
              buttonContainer={buttonContainer}
              cancelButtonText={
                currentGeneratorIsNew ? "Überspringen" : "Abbrechen"
              }
              currentGeneratorId={currentGenerator.id}
              generators={filteredGenerators}
              initiallySelectedGenerators={anlagenStromstgGenerators}
              multiSite={multiSite}
              sites={sites}
              onSkip={handleSkipStep}
              onSubmit={handleSubmitAnlageStromstgForm}
            />
          </>
        )}
      </LoadOrError>
    </div>
  );
}

export { AnlageStromstgFlow, AnlageStromstgFlowProps };
