import React, { useState } from "react";
import { DownIcon } from "../../../Breadcrumbs/icons/DownIcon";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "../../../BuildingBlocks/Dropdown/Dropdown";
import { Icon } from "../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import { DummyLink } from "../../../DummyLink/DummyLink";
import "./CustomTextElementPopup.scss";
import {
  FontWeight,
  getDescriptionForFontWeight
} from "../../utils/getDescriptionForFontWeight";

const DEFAULT_FONT_SIZE = 18;
const DEFAULT_FONT_WEIGHT = FontWeight.Bold;

interface CustomTextElementPopupProps {
  x: number;
  y: number;
  nodeId: string;
  currentFontWeight?: number;
  currentFontSize?: number;
  onClickDelete: (nodeId: string) => void;
  onEditTextConfig: (fontWeight: number, fontSize: number) => void;
}

function CustomTextElementPopup({
  x,
  y,
  nodeId,
  currentFontSize,
  currentFontWeight,
  onClickDelete,
  onEditTextConfig
}: CustomTextElementPopupProps) {
  return (
    <div className="CustomTextElementPopup" style={{ top: y, left: x }}>
      <LinksAndDetails
        currentFontSize={currentFontSize}
        currentFontWeight={currentFontWeight}
        onClickDelete={() => onClickDelete(nodeId)}
        onEditTextConfig={onEditTextConfig}
      />
    </div>
  );
}

interface LinksAndDetailsProps {
  onClickDelete: () => void;
  onEditTextConfig: (fontWeight: number, fontSize: number) => void;
  currentFontSize?: number;
  currentFontWeight?: number;
}

function LinksAndDetails({
  onClickDelete,
  onEditTextConfig,
  currentFontSize,
  currentFontWeight
}: LinksAndDetailsProps) {
  const [fontWeightDropdownOpen, setFontWeightDropdownOpen] = useState(false);
  const toggleFontWeightDropdown = () =>
    setFontWeightDropdownOpen((prevState) => !prevState);
  const [fontSizeDropdownOpen, setFontSizeDropdownOpen] = useState(false);
  const toggleFontSizeDropdown = () =>
    setFontSizeDropdownOpen((prevState) => !prevState);
  const [selectedFontSize, setSelectedFontSize] = useState(currentFontSize);
  const [selectedFontWeight, setSelectedFontWeight] =
    useState(currentFontWeight);

  function handleChangeFontWeight(fontWeight: number) {
    setSelectedFontWeight(fontWeight);
    onEditTextConfig(fontWeight, selectedFontSize ?? DEFAULT_FONT_SIZE);
  }

  function handleChangeFontSize(fontSize: number) {
    setSelectedFontSize(fontSize);
    onEditTextConfig(selectedFontWeight ?? DEFAULT_FONT_WEIGHT, fontSize);
  }
  return (
    <div className="LinksAndDetails">
      <div className="text-config-controls">
        <span className="small">
          <div className="text-config-modal-font-size-dropdown-container dropdown-container">
            <span className="text-config-modal-font-size-description">
              Größe:
            </span>
            <Dropdown
              isOpen={fontSizeDropdownOpen}
              toggle={toggleFontSizeDropdown}
            >
              <DropdownToggle>
                {selectedFontSize ?? DEFAULT_FONT_SIZE} <DownIcon />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => handleChangeFontSize(12)}>
                  12
                </DropdownItem>
                <DropdownItem onClick={() => handleChangeFontSize(14)}>
                  14
                </DropdownItem>
                <DropdownItem onClick={() => handleChangeFontSize(16)}>
                  16
                </DropdownItem>
                <DropdownItem onClick={() => handleChangeFontSize(18)}>
                  18
                </DropdownItem>
                <DropdownItem onClick={() => handleChangeFontSize(20)}>
                  20
                </DropdownItem>
                <DropdownItem onClick={() => handleChangeFontSize(24)}>
                  24
                </DropdownItem>
                <DropdownItem onClick={() => handleChangeFontSize(32)}>
                  32
                </DropdownItem>
                <DropdownItem onClick={() => handleChangeFontSize(46)}>
                  46
                </DropdownItem>
                <DropdownItem onClick={() => handleChangeFontSize(60)}>
                  60
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          <div className="text-config-modal-font-weight-dropdown-container dropdown-container">
            <span className="text-config-modal-font-weight-description">
              Dicke:
            </span>
            <Dropdown
              isOpen={fontWeightDropdownOpen}
              toggle={toggleFontWeightDropdown}
            >
              <DropdownToggle>
                {getDescriptionForFontWeight(
                  selectedFontWeight ?? DEFAULT_FONT_WEIGHT
                )}
                <DownIcon />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  style={{ fontWeight: 300 }}
                  onClick={() => handleChangeFontWeight(300)}
                >
                  Sehr dünn
                </DropdownItem>
                <DropdownItem
                  style={{ fontWeight: 400 }}
                  onClick={() => handleChangeFontWeight(400)}
                >
                  Dünn
                </DropdownItem>
                <DropdownItem
                  style={{ fontWeight: 500 }}
                  onClick={() => handleChangeFontWeight(500)}
                >
                  Normal
                </DropdownItem>
                <DropdownItem
                  style={{ fontWeight: 600 }}
                  onClick={() => handleChangeFontWeight(600)}
                >
                  Fett
                </DropdownItem>
                <DropdownItem
                  style={{ fontWeight: 700 }}
                  onClick={() => handleChangeFontWeight(700)}
                >
                  Sehr fett
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </span>
      </div>
      <div className="delete-link">
        <span className="small">
          <DummyLink onClick={onClickDelete}>
            <Icon className="m--font-danger" name={IconName.Trash} /> Text
            löschen
          </DummyLink>
        </span>
      </div>
    </div>
  );
}

export { CustomTextElementPopup };
