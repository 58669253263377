import { extract, stringify, type StringifyOptions } from "query-string";

export function appendQueryString(
  url: string,
  params,
  stringifyOptions?: StringifyOptions
) {
  if (Object.keys(params).length === 0) {
    return url;
  }

  if (extract(url)) {
    return `${url}&${stringify(params, stringifyOptions)}`;
  } else {
    return `${url}?${stringify(params, stringifyOptions)}`;
  }
}
