import { PAGE_SIZE_OPTIONS } from "../constants";

/** Determine what the page size should be, based on the current page size, the current length of the data, and the `maxPageSize`.
 *
 * If `maxPageSize` is set, the page size will be set to that value if it is less than the current length of the data.
 *
 * If `maxPageSize` is not set, the page size will be set to the largest page size that is less than the current length of the data (or the largest page size).
 */
export function calculateBestPageSize(
  dataLength: number,
  maxPageSize?: number
) {
  if (typeof maxPageSize !== "undefined" && maxPageSize <= dataLength) {
    return maxPageSize;
  }

  const largerPageSizeOptions = PAGE_SIZE_OPTIONS.filter(
    (pageSizeOption) => dataLength <= pageSizeOption
  );

  if (largerPageSizeOptions.length === 0) {
    return PAGE_SIZE_OPTIONS[PAGE_SIZE_OPTIONS.length - 1];
  }

  return largerPageSizeOptions[0];
}
