import {
  CHARACTER_LIMIT,
  LINE_BREAK_LIMIT,
  isTooLongText
} from "./isTooLongText";
import { nthIndex } from "./nthIndex";

/**
 * truncates the text to a certain length and adds an elipsis if the text is too long
 */
export function truncateToStringElipsis(
  text: string,
  charLimit = CHARACTER_LIMIT,
  lineBreakLimit = LINE_BREAK_LIMIT
) {
  let shortString = text.slice(0, charLimit);

  if (isTooLongText(text)) {
    const nthNewLine = nthIndex(text, "\n\n", lineBreakLimit);

    if (nthNewLine > -1) {
      shortString = text.slice(0, nthNewLine);
    }

    shortString += "...";
  }

  return shortString;
}
