import { useQuery } from "@tanstack/react-query";
import api from "../../../api";
import urls from "../../../urls";
import type { SiteBasic } from "../../../utils/backend-types";

type UseSitesReturnValue = {
  sites: Array<SiteBasic>;
  loading: boolean;
  error: unknown;
};

export function useGOSites(variantId: number): UseSitesReturnValue {
  const {
    isLoading: loading,
    error,
    data: sites
  } = useQuery({
    queryKey: ["sites-for-go-list", { variantId }],
    queryFn: () => fetchSitesList(variantId),
    enabled: true,
    refetchInterval: false,
    refetchOnWindowFocus: false
  });

  async function fetchSitesList(variantId: number) {
    const response = await api.get<Array<SiteBasic>>(
      urls.api.guaranteeOfOriginSites(variantId)
    );
    return response.data;
  }
  return {
    loading,
    error,
    sites: sites || []
  };
}
