import { type ComboboxData } from "@mantine/core";
import type { MRT_ColumnDef, MRT_RowData } from "mantine-react-table";
import React from "react";
import type { Mutable } from "utility-types";
import { ControlledMantineEditSelect } from "../../../../../mantine/mrt/components/edit/ControlledMantineEditSelect";
import { isMrtCreating } from "../../../../../mantine/mrt/utils/isMrtCreating";
import type { InlineEditTableColumnData2 } from "../../../../../types/inlineEditTable.types";
import type { MeteringOrMarketLocation } from "../../../../../utils/backend-types";

export function getZaehlpunktColumn<T extends MRT_RowData>(
  { validationErrors }: InlineEditTableColumnData2,
  meloMalos: Array<MeteringOrMarketLocation> | undefined,
  suggestedMeloMaloId: number | undefined,
  overrideMeteringOrMarketLocationId: number | undefined,
  isLoading: boolean
): MRT_ColumnDef<T> {
  const meloMaloChoices =
    meloMalos?.map((meloMalo) => meloMalo.name).sort() ?? [];

  const groupedMeloMaloChoices: Mutable<ComboboxData> = [];
  if (suggestedMeloMaloId) {
    const suggestedMeloMalo = meloMalos?.find(
      (meloMalo) => meloMalo.id === suggestedMeloMaloId
    );

    if (suggestedMeloMalo) {
      groupedMeloMaloChoices.push({
        group: "Automatischer Vorschlag",
        items: [suggestedMeloMalo.name]
      });

      const otherMeloMalos = meloMalos?.filter(
        (meloMalo) => meloMalo.id !== suggestedMeloMaloId
      );

      if (otherMeloMalos) {
        groupedMeloMaloChoices.push({
          group: "Zählpunkte zur Auswahl",
          items: otherMeloMalos.map((meloMalo) => meloMalo.name).sort()
        });
      }
    }
  }

  const overrideMeloMaloName = meloMalos?.find(
    (meloMalo) => meloMalo.id === overrideMeteringOrMarketLocationId
  )?.name;

  return {
    accessorKey: "metering_or_market_location_display_name",
    header: "Zählpunkt",
    editVariant: "select",
    enableSorting: true,
    Edit: ({ cell, column, row, table }) => {
      const shouldOverrideValue = isMrtCreating(table);

      return (
        <ControlledMantineEditSelect
          cell={cell}
          columnId={column.id}
          data={suggestedMeloMaloId ? groupedMeloMaloChoices : meloMaloChoices}
          disabled={!meloMalos || meloMalos.length === 0}
          errorText={validationErrors.meteringOrMarketLocationId}
          isLoading={isLoading}
          label="Zählpunkt"
          overrideValue={shouldOverrideValue ? overrideMeloMaloName : undefined}
          required
          row={row}
        />
      );
    }
  };
}
