import type { Choice } from "../TsDropdown";

/** Gets a label from a Choice, even if there is no `displayName`. */
export function getLabelFromChoice(choice: Choice) {
  return (
    choice.displayName?.toString() ||
    choice.value?.toString() ||
    "nicht verfügbar"
  );
}
