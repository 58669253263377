import React from "react";
import urls from "../../../../urls";
import { OptionsForm } from "../../../OptionsForm/OptionsForm";

interface MaStRSubStepPersonProps {
  variantId: number;
  buttonContainer?: HTMLElement;
  onSubmit: (taskId: string) => void;
  onBack: () => void;
}

const OPTIONS_URL = urls.api.importPersonsFromMastr();
const POST_URL = urls.api.importPersonsFromMastr();
const FILTERED_FIELDS = ["mastrNummer", "person"];

function MaStRSubStepPerson({
  variantId,
  buttonContainer,
  onSubmit,
  onBack
}: MaStRSubStepPersonProps) {
  const nonFieldData = {
    variant: variantId
  };

  function handlePollSuccess(taskIds: Array<string>) {
    onSubmit(taskIds[0]);
  }

  return (
    <div className="mastr-substep">
      <PersonExplanation />
      <OptionsForm
        buttonContainer={buttonContainer}
        cancelButtonText="Zurück"
        filteredFields={FILTERED_FIELDS}
        nonFieldData={nonFieldData}
        optionsUrl={OPTIONS_URL}
        postUrl={POST_URL}
        submitButtonText="Weiter"
        onCancel={onBack}
        onPollSuccess={handlePollSuccess}
        onSubmit={() => {
          /*do nothing - wait for handlePollSuccess*/
        }}
      />
    </div>
  );
}

function PersonExplanation() {
  return (
    <p className="explanation">
      Bitte geben Sie die Marktstammdatenregisters einer juristischen Person mit
      der Marktrolle Anlagenbetreiber ein. Wir haben keinen Zugriff, um
      natürliche Personen über das Marktstammdatenregister abzufragen.
    </p>
  );
}

export { MaStRSubStepPerson };
