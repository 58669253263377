import { useQuery } from "@tanstack/react-query";
import api from "../api";
import urls from "../urls";

export type ComponentBase = {
  id: number;
  name: string;
  site: number;
};

export function useComponentsBase(siteId: number) {
  return useQuery({
    queryKey: ["componentsBase", siteId],
    queryFn: () => fetchComponentsBase(siteId)
  });
}

async function fetchComponentsBase(siteId: number) {
  const response = await api.get<Array<ComponentBase>>(
    urls.api.componentsBase(siteId)
  );
  return response.data;
}
