import type { Todo } from "../../../../utils/backend-types";
import { TodoStatus } from "../../../../utils/backend-types";
import { LAST_YEAR } from "../../../../utils/dates";
import { StatusSymbolColour } from "../../../Icons/StatusSymbol";
import {
  OnboardingTodo,
  getOnboardingTodoLabel
} from "../../../Todos/onboarding-todos";

export const NO_TODOS_TEXT = "Keine Aufgaben";
export const ALL_DONE_TEXT = "Onboarding abgeschlossen";

interface OnboardingTodosSimpleStatus {
  displayText: string;
  statusSymbolColour: StatusSymbolColour;
}

/** Gets the display text and status symbol colour for a given set of onboarding todos. */
export function getOnboardingTodosSimpleStatus(
  todos: Array<Todo>
): OnboardingTodosSimpleStatus {
  const configurationAnlegenTodo = todos.find(
    (todo) =>
      todo.label ===
      getOnboardingTodoLabel(OnboardingTodo.KONFIGURATION_PRUEFEN, LAST_YEAR)
  );
  const configurationAnlegenTodoIsDone =
    !!configurationAnlegenTodo &&
    configurationAnlegenTodo.status === TodoStatus.Done;
  const regulatorischeDatenVervollstaendigenTodo = todos.find(
    (todo) =>
      todo.label ===
      getOnboardingTodoLabel(
        OnboardingTodo.REGULATORISCHE_DATEN_VERVOLLSTAENDIGEN,
        LAST_YEAR
      )
  );
  const regulatorischeDatenVervollstaendigenTodoIsDone =
    !!regulatorischeDatenVervollstaendigenTodo &&
    regulatorischeDatenVervollstaendigenTodo.status === TodoStatus.Done;
  const vollmachtFuerMsbBereitstellenTodo = todos.find(
    (todo) =>
      todo.label ===
      getOnboardingTodoLabel(
        OnboardingTodo.VOLLMACHT_FUER_MSB_BEREITSTELLEN,
        LAST_YEAR
      )
  );
  const vollmachtFuerMsbBereitstellenTodoIsDone =
    !!vollmachtFuerMsbBereitstellenTodo &&
    (vollmachtFuerMsbBereitstellenTodo.status === TodoStatus.Done ||
      vollmachtFuerMsbBereitstellenTodo.status === TodoStatus.NotRelevant);

  let statusSymbolColour = StatusSymbolColour.Green;
  let displayText = ALL_DONE_TEXT;

  if (!configurationAnlegenTodoIsDone) {
    statusSymbolColour = StatusSymbolColour.Red;
    displayText = getOnboardingTodoLabel(
      OnboardingTodo.KONFIGURATION_PRUEFEN,
      LAST_YEAR
    );
  } else if (!regulatorischeDatenVervollstaendigenTodoIsDone) {
    statusSymbolColour = StatusSymbolColour.Red;
    displayText = getOnboardingTodoLabel(
      OnboardingTodo.REGULATORISCHE_DATEN_VERVOLLSTAENDIGEN,
      LAST_YEAR
    );
  } else if (!vollmachtFuerMsbBereitstellenTodoIsDone) {
    statusSymbolColour = StatusSymbolColour.Red;
    displayText = getOnboardingTodoLabel(
      OnboardingTodo.VOLLMACHT_FUER_MSB_BEREITSTELLEN,
      LAST_YEAR
    );
  }

  return { statusSymbolColour, displayText };
}
