import urls from "../urls";
import { ObjectName } from "./enums";

const LIST_API_BY_OBJECTS = {
  [ObjectName.Generator]: urls.api.generators,
  [ObjectName.Consumer]: urls.api.consumers,
  [ObjectName.Storage]: urls.api.storages,
  [ObjectName.Person]: urls.api.personsByVariantId,
  [ObjectName.Connection]: urls.api.connections,
  [ObjectName.GasConnection]: urls.api.gasConnections,
  [ObjectName.Meter]: urls.api.meters,
  [ObjectName.GhostNode]: urls.api.ghostNodes,
  [ObjectName.MarketLocation]: urls.api.marketLocations,
  [ObjectName.MeteringLocation]: urls.api.meteringLocations
} as const;

/** Returns list URL for a given ObjectName. Takes optional `componentId` and `isPremium` for Meter and OPTIONS endpoint in that case. */
export function getObjectListUrl(
  objectName: ObjectName,
  siteOrVariantId: number,
  componentId?: number,
  isPremium = false
) {
  if (objectName === ObjectName.Meter && typeof componentId !== "undefined") {
    if (isPremium) {
      return urls.api.meteringConceptMeterOptions(siteOrVariantId, componentId);
    } else {
      return urls.api.meterOptions(siteOrVariantId, componentId);
    }
  }

  return LIST_API_BY_OBJECTS[objectName](siteOrVariantId);
}
