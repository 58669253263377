import type { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import type { DateRangeConsumptionShare } from "../../../utils/backend-types";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import { Button } from "../../Buttons/Button/Button";
import { NumberValueCell } from "../../ChangeableFieldWidget/ChangeableFieldTable/Cells/NumberValueCell";
import { DatePicker } from "../../DatePicker/DatePicker";
import { FormItemInfoText } from "../../DynamicForm/FormItem/FormItemInfoText";
import { TsDropdown } from "../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import "./ConsumptionShareMask.scss";

interface ConsumptionShareMaskProps {
  onAddNewVersion: (data: DateRangeConsumptionShare) => void;
  consumers: Array<{ consumer: number; consumerName: string }>;
  earliestDatePossible?: DateTime;
}

function ConsumptionShareMask({
  onAddNewVersion,
  earliestDatePossible,
  consumers
}: ConsumptionShareMaskProps) {
  const [consumerKey, setConsumerKey] = useState(0);
  const choices = consumers.map((consumer) => {
    return {
      value: consumer.consumer,
      displayName: consumer.consumerName
    };
  });
  const [consumptionShareMaskData, setConsumptionShareMaskData] =
    useState<DateRangeConsumptionShare>({
      firstDate: earliestDatePossible
        ? luxonDateTimeToBackendDateOrDateTime(earliestDatePossible)
        : null,
      lastDate: null,
      consumerConsumptionShares: []
    });

  useEffect(() => {
    setConsumptionShareMaskData({
      firstDate: earliestDatePossible
        ? luxonDateTimeToBackendDateOrDateTime(earliestDatePossible)
        : null,
      lastDate: null,
      consumerConsumptionShares: []
    });
    setConsumerKey((prevKey) => prevKey + 1);
  }, [earliestDatePossible]);

  function handleChangeConsumerPercentile(
    consumerId: number,
    value: number | undefined
  ) {
    const consumerName =
      choices.find((choice) => choice.value === consumerId)?.displayName ?? "";

    let consumerConsumptionPercentile = value?.toString();
    if (
      consumerConsumptionPercentile &&
      !consumerConsumptionPercentile.includes(".")
    ) {
      consumerConsumptionPercentile += ".00";
    }
    const consumerConsumptionShare = {
      consumer: consumerId,
      consumerName,
      consumptionPercentile: consumerConsumptionPercentile
    };

    const consumerConsumptionShares =
      consumptionShareMaskData.consumerConsumptionShares.filter(
        (consumer) => consumer.consumer !== consumerConsumptionShare.consumer
      );

    consumerConsumptionShares.push(consumerConsumptionShare);

    setConsumptionShareMaskData({
      ...consumptionShareMaskData,
      consumerConsumptionShares
    });
  }

  function handleChangeDateRange(firstDate: string | null) {
    setConsumptionShareMaskData({
      ...consumptionShareMaskData,
      firstDate,
      lastDate: null
    });
  }

  return (
    <div className="ConsumptionShareMaskContainer">
      <h6 className="consumption-share-mask-header">Neue Version hinzufügen</h6>
      <div>
        <ConsumptionShareDateInput
          earliestDatePossible={earliestDatePossible}
          initialDates={{
            startDate: consumptionShareMaskData.firstDate ?? null,
            endDate: consumptionShareMaskData.lastDate ?? null
          }}
          onChange={handleChangeDateRange}
        />
        {consumers.map((consumer) => {
          return (
            <ConsumptionShareRow
              choices={choices}
              consumerId={consumer.consumer}
              key={consumer.consumerName + consumerKey + consumer.consumer}
              onChange={handleChangeConsumerPercentile}
            />
          );
        })}

        <FormItemInfoText
          infoText={
            "Die Summe der Verbrauchsanteile sollte in jedem Zeitraum immer 100% ergeben."
          }
        />
        <div className="consumption-share-mask-footer">
          <Button
            color="brand"
            onClick={() => onAddNewVersion(consumptionShareMaskData)}
          >
            Version hinzufügen
          </Button>
        </div>
      </div>
    </div>
  );
}

interface ConsumptionShareDateInputProps {
  onChange: (startDate?: string | null) => void;
  initialDates: { startDate: string | null; endDate: string | null };
  earliestDatePossible?: DateTime;
}
function ConsumptionShareDateInput({
  onChange,
  initialDates,
  earliestDatePossible
}: ConsumptionShareDateInputProps) {
  function handleChangeDateInput(value?) {
    if (value) {
      onChange(value ? luxonDateTimeToBackendDateOrDateTime(value) : null);
    }
  }
  return (
    <div className="consumption-share-date-input-container">
      <p>Gültigkeitsbeginn *</p>
      <DatePicker
        date={
          initialDates.startDate
            ? backendDateOrDateTimeToLuxonDateTime(initialDates.startDate)
            : undefined
        }
        firstPossibleDate={earliestDatePossible}
        id="consumption-share-date-range-picker"
        onChange={(value) => handleChangeDateInput(value)}
      />
    </div>
  );
}
interface ConsumptionShareRowProps {
  consumerId: number;
  choices?: Array<{ value: number; displayName: string }>;
  percentage?: number;
  onChange: (consumerId: number, value: number) => void;
}

function ConsumptionShareRow({
  choices,
  consumerId,
  onChange
}: ConsumptionShareRowProps) {
  return (
    <div className="consumption-share-row-container">
      <div className="consumption-share-consumer">
        <label>Verbraucher</label>
        <TsDropdown
          choices={choices ?? []}
          defaultValue={consumerId}
          disabled
          id={""}
          name={""}
          onChange={() => {
            //
          }}
        />
      </div>
      <div className="consumption-share-percentage">
        <label>Verbrauchsanteil %</label>
        <NumberValueCell
          decimalScale={2}
          inputGroupText="%"
          value={null}
          onChange={(value) => {
            onChange(consumerId, value);
          }}
        />
      </div>
    </div>
  );
}

export { ConsumptionShareMask };
