import React, { useState } from "react";
import ReactDOM from "react-dom";
import { type FieldError, type SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type {
  AnlageStromstgListData,
  Generator,
  Site
} from "../../../../utils/backend-types";
import { trimString } from "../../../../utils/trimString";
import { FormFieldController } from "../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { setErrorsFromResponseData } from "../../../BuildingBlocks/Forms/utils/setErrorsFromResponseData";
import { Button } from "../../../Buttons/Button/Button";
import type { Choice } from "../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import type { AnlageStromstgSelectionData } from "./AnlageStromstgForm.constants";
import { ANLAGE_STROMSTG_FORM_FIELDS } from "./AnlageStromstgForm.constants";

function getInitiallySelectedSiteIds(
  initiallySelectedGenerators: Array<AnlageStromstgListData>,
  sites: Array<Site>
) {
  const allSiteIds = initiallySelectedGenerators
    .map((generator) => sites.find((site) => site.name === generator.site)?.id)
    .filter((siteId) => typeof siteId !== "undefined");

  return Array.from(new Set(allSiteIds));
}

interface AnlageStromstgFormProps {
  buttonContainer?: HTMLElement;
  cancelButtonText: "Abbrechen" | "Überspringen";
  currentGeneratorId: number;
  generators: Array<Generator>;
  initiallySelectedGenerators: Array<AnlageStromstgListData>;
  multiSite?: boolean;
  sites: Array<Site>;
  onSkip: () => void;
  onSubmit: (selectedValues: Array<number>) => Promise<void>;
}

function AnlageStromstgForm({
  buttonContainer,
  cancelButtonText,
  generators,
  currentGeneratorId,
  initiallySelectedGenerators,
  multiSite,
  sites,
  onSkip,
  onSubmit
}: AnlageStromstgFormProps) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    watch
  } = useForm<AnlageStromstgSelectionData>({
    defaultValues: {
      generatorIds: initiallySelectedGenerators.map(
        (generator) => generator.id
      ),
      site: getInitiallySelectedSiteIds(initiallySelectedGenerators, sites)
    }
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onValidSubmit: SubmitHandler<AnlageStromstgSelectionData> = async (
    data
  ) => {
    setIsSubmitting(true);

    try {
      const response = await onSubmit(data.generatorIds);
      return response;
    } catch (error) {
      setErrorsFromResponseData<AnlageStromstgSelectionData>(
        error,
        watch(),
        setError,
        t("errors.UnknownError")
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const submitCallback = handleSubmit(onValidSubmit);

  function getGeneratorNameWithSite(
    generatorName: string,
    siteId: number,
    sites: Array<Site>
  ) {
    const site = sites.find((site) => site.id === siteId);

    if (site) {
      return `${generatorName} (${site.name})`;
    }

    return generatorName;
  }

  const generatorsFilteredBySite = generators.filter((generator) => {
    const sites = watch("site");

    if (!sites || sites.length === 0) {
      return true;
    }

    return sites.includes(generator.site);
  });

  const generatorNamesWithIds = generatorsFilteredBySite.map((generator) => {
    const trimmedGeneratorName = trimString(generator.name);
    const generatorNameWithSite = getGeneratorNameWithSite(
      trimmedGeneratorName,
      generator.site,
      sites
    );

    return {
      id: generator.id,
      name: generatorNameWithSite
    };
  });

  const generatorIdChoices = generatorNamesWithIds?.map<Choice<number>>(
    (generator) => {
      return {
        value: generator.id,
        displayName: generator.name,
        isFixed: generator.id === currentGeneratorId
      };
    }
  );

  const siteChoices = sites.map<Choice<number>>((site) => {
    return {
      value: site.id,
      displayName: site.name,
      isFixed:
        site.id ===
        generators.find((generator) => generator.id === currentGeneratorId)
          ?.site
    };
  });

  return (
    <form className="AnlageStromstgForm" onSubmit={submitCallback}>
      {multiSite && (
        <FormFieldController
          control={control}
          data={{
            ...ANLAGE_STROMSTG_FORM_FIELDS.site,
            choices: siteChoices
          }}
          error={errors.site as FieldError | undefined}
        />
      )}
      <FormFieldController
        control={control}
        data={{
          ...ANLAGE_STROMSTG_FORM_FIELDS.generatorIds,
          choices: generatorIdChoices ?? []
        }}
        error={errors.generatorIds as FieldError | undefined}
      />
      {buttonContainer &&
        ReactDOM.createPortal(
          <>
            <Button
              color="secondary"
              disabled={isSubmitting}
              type="button"
              onClick={onSkip}
            >
              {cancelButtonText}
            </Button>
            <Button
              className="submit-button"
              color="primary"
              disabled={isSubmitting}
              type="submit"
              onClick={submitCallback}
            >
              Speichern
            </Button>
          </>,
          buttonContainer
        )}
    </form>
  );
}

export { AnlageStromstgForm, AnlageStromstgFormProps };
