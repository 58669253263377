import React from "react";
import type { ReactNode } from "react";

import type { CurrentUser } from "../../utils/backend-types";
import type { SiteName } from "../../utils/enums";
import { Menu } from "../Menu/Menu";
import { useShouldShowStaffView } from "../StaffViewToggle/useShouldShowStaffView";
import { Footer } from "./Footer";
import { Header } from "./Header";

import "./Layout.scss";

interface Props {
  currentUser: CurrentUser | undefined;
  title?: string;
  siteName: SiteName;
  children: ReactNode;

  toggleShowStaffView: () => void;
}

function Layout({
  title,
  children,
  siteName,
  currentUser,
  toggleShowStaffView
}: Props) {
  const shouldShowStaffView = useShouldShowStaffView();

  const {
    admin_url = "",
    has_analyzer_license = false,
    has_manager_license = false
  } = currentUser || {};

  return (
    <>
      <Header
        currentUser={currentUser}
        siteName={siteName}
        toggleShowStaffView={toggleShowStaffView}
      />
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
        <div>
          <div
            className="m-grid__item	m-aside-left m-aside-left--skin-dark"
            id="m_aside_left"
            style={{
              overflow: "auto",
              scrollbarColor: "#777 transparent",
              scrollbarWidth: "thin"
            }}
          >
            <div
              className="m-aside-menu  m-aside-menu--skin-dark m-aside-menu--submenu-skin-dark m-aside-menu--dropdown"
              data-menu-dropdown="true"
              data-menu-dropdown-timeout="500"
              data-menu-scrollable="true"
              data-menu-vertical="true"
              id="m_ver_menu"
              style={{ overflowX: "hidden" }}
            >
              <ul
                className="m-menu__nav m-menu__nav--dropdown-submenu-arrow "
                id="MenuContainer"
              >
                <Menu
                  adminUrl={admin_url}
                  isStaff={shouldShowStaffView}
                  userHasAnalyzerLicense={has_analyzer_license}
                  userHasManagerLicense={has_manager_license}
                />
              </ul>
            </div>
          </div>
          <div
            className="m-grid__item m-grid__item--fluid m-wrapper"
            id="content-and-subheader"
          >
            {title && (
              <div className="m-subheader ">
                <div className="d-flex align-items-center">
                  <div className="mr-auto">
                    <h3 className="m-subheader__title">{title}</h3>
                  </div>
                  <div className="pull-right"></div>
                </div>
              </div>
            )}
            <div className="m-content">{children}</div>
          </div>
        </div>
      </div>
      <Footer siteName={siteName} />
    </>
  );
}

export { Layout };
