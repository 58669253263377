import { DateTime } from "luxon";
import type { Choice } from "../components/DynamicForm/FormItems/FormField/Dropdown/TsDropdown";

export const THIS_YEAR = new Date().getUTCFullYear();
export const LAST_YEAR = THIS_YEAR - 1;

export const LUXON_START_OF_LAST_YEAR = DateTime.now()
  .minus({ years: 1 })
  .startOf("year");
export const LUXON_END_OF_LAST_YEAR = DateTime.now()
  .minus({ years: 1 })
  .endOf("year");

export const LUXON_END_OF_2022 = DateTime.fromISO("2022-12-31T10:00:00");

export const LUXON_START_OF_LAST_MONTH = DateTime.now()
  .minus({ months: 1 })
  .startOf("month");
export const LUXON_END_OF_LAST_MONTH = DateTime.now()
  .minus({ months: 1 })
  .endOf("month");

const TODO_YEAR_CHANGE_DATE = new Date(THIS_YEAR, 9, 1);
const shouldShowThisYearForTodos = TODO_YEAR_CHANGE_DATE < new Date();
export const INITIAL_TODO_YEAR = shouldShowThisYearForTodos
  ? THIS_YEAR
  : LAST_YEAR;
export const FINAL_TODO_YEAR = INITIAL_TODO_YEAR;

const MONTHS = [
  "Januar",
  "Februar",
  "März",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Dezember"
];

export const MONTH_CHOICES: Array<Choice> = MONTHS.map((month, index) => ({
  value: index + 1,
  displayName: month
}));
