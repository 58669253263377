import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import type { CustomFormProps } from "../CustomForm/CustomForm";
import { AdvancedCustomForm } from "../CustomForm/CustomForm";
import type {
  CustomFormOnSubmit,
  CustomFormOptions,
  FormValues
} from "../CustomForm/useCustomForm";
import type { FormFieldValue } from "../DynamicForm/FormItems/FormField/FormField";
import type { FormFieldData } from "../DynamicForm/FormItems/FormItems";
import { AnimatedLoadingIcon } from "../Icons/AnimatedLoadingIcon/AnimatedLoadingIcon";
import { useOptionsForm } from "./useOptionsForm";

import "./OptionsForm.scss";

export const ACTION_TYPES = {
  POST: "pOST",
  PUT: "pUT"
};

interface OptionsFormProps extends CustomFormProps {
  formFields: Array<FormFieldData> | undefined;
  missingFields?: Array<string>;
  [x: string]: unknown;
}

export type FieldsDict = Record<string, FormFieldData>;
export type FieldsArray = Array<FormFieldData>;

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function OptionsForm({
  formFields,
  formValues,
  ...otherProps
}: OptionsFormProps): JSX.Element {
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey((currentKey) => currentKey + 1);
  }, [formFields]);

  if (!formFields || !formValues) {
    return <AnimatedLoadingIcon />;
  }

  return (
    <div className="OptionsForm">
      <AdvancedCustomForm
        formFields={formFields}
        formValues={formValues}
        key={key}
        {...otherProps}
      />
    </div>
  );
}

export interface EasyOptionsFormProps extends CustomFormOptions {
  optionsUrl: string;
  filteredFields?: Array<string>;
  nonFieldData?: FormValues;
  hiddenFields?: Array<string>;
  initialValues?: Record<string, FormFieldValue>;
  readOnlyFields?: Array<string>;
  onSubmit: CustomFormOnSubmit;
  submitting?: boolean;
  missingFields?: Array<string>;
  [x: string]: unknown;
}

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function EasyOptionsForm(props: EasyOptionsFormProps) {
  const {
    optionsUrl,
    filteredFields,
    hiddenFields,
    initialValues,
    postUrl,
    putUrl,
    patchUrl,
    readOnlyFields,
    nonFieldData,
    onInput,
    onSubmit,
    onFirstSubmit,
    onPollSuccess,
    onError,
    missingFields,
    ...otherProps
  } = props;
  const { formFields, ...optionsFormProps } = useOptionsForm(optionsUrl, {
    filteredFields,
    hiddenFields,
    initialValues,
    readOnlyFields,
    nonFieldData,
    postUrl,
    putUrl,
    patchUrl,
    onSubmit,
    onInput,
    onFirstSubmit,
    onPollSuccess,
    onError
  });

  return (
    <OptionsForm
      formFields={formFields}
      missingFields={missingFields}
      patchUrl={patchUrl}
      postUrl={postUrl}
      putUrl={putUrl}
      {...otherProps}
      {...optionsFormProps}
    />
  );
}

EasyOptionsForm.propTypes = {
  optionsUrl: PropTypes.string.isRequired,
  filteredFields: PropTypes.arrayOf(PropTypes.string),
  hiddenFields: PropTypes.arrayOf(PropTypes.string),
  readOnlyFields: PropTypes.arrayOf(PropTypes.string),
  initialValues: PropTypes.object
};

export { EasyOptionsForm as OptionsForm, OptionsForm as AdvancedOptionsForm };
