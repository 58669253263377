import classNames from "classnames";
import React, { useEffect, useId, useState } from "react";
import { Icon } from "../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../BuildingBlocks/Icon/types";
import { EditIcon } from "../../Buttons/EditIcon";
import { SaveIcon } from "../../Buttons/SaveIcon";
import type {
  Choice,
  ChoiceValue
} from "../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { TsDropdown } from "../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { Input } from "../../DynamicForm/FormItems/FormField/Input/Input";

import "./EditSaveCell.scss";

enum ButtonType {
  Edit = "edit",
  Save = "save"
}

enum CellType {
  Input = "input",
  Dropdown = "dropdown"
}

type EditSaveCellProps = Pick<
  ControlsProps,
  "editTooltipText" | "saveTooltipText"
> & {
  placeholder?: string;
} & (
    | {
        cellType: CellType.Input;
        value: string | number | null;
        onChange: (newValue: string | number) => Promise<void>;
      }
    | {
        cellType: CellType.Dropdown;
        value: ChoiceValue;
        getLabel: (value: ChoiceValue) => string;
        choices: Array<Choice>;
        onChange: (newValue: ChoiceValue) => Promise<void>;
      }
  );

function EditSaveCell({
  editTooltipText,
  saveTooltipText,
  placeholder,
  ...typeProps
}: EditSaveCellProps) {
  const [localValue, setLocalValue] = useState(typeProps.value);
  const [buttonType, setButtonType] = useState<ButtonType>(ButtonType.Edit);
  const [loading, setLoading] = useState(false);
  const showLabel = buttonType === ButtonType.Edit;
  const showComponent = buttonType === ButtonType.Save;
  const dropdownId = useId();

  useEffect(() => {
    setLocalValue(typeProps.value);
  }, [typeProps.value]);

  function handleInput(value: string | number | undefined) {
    if (typeof value === "undefined") {
      setLocalValue("");
    } else {
      setLocalValue(value);
    }
  }

  const handleDropdownChange = (_: string, value: typeof typeProps.value) => {
    setLocalValue(value);
  };

  async function handleClickButton(newButtonType: ButtonType) {
    if (newButtonType === ButtonType.Edit) {
      setLoading(true);

      try {
        if (typeProps.cellType === CellType.Input) {
          const nonNullValue = localValue === null ? "" : localValue;
          await typeProps.onChange(
            nonNullValue as NonNullable<typeof typeProps.value>
          );
        } else {
          await typeProps.onChange(localValue as typeof typeProps.value);
        }
      } catch {
        setLocalValue(typeProps.value);
      } finally {
        setLoading(false);
      }
    }

    setButtonType(newButtonType);
  }

  function handleKeyPressEnter() {
    handleClickButton(ButtonType.Edit);
  }

  const showPlaceholder = !localValue || localValue === "";
  const displayText = showPlaceholder ? placeholder : localValue;

  if (typeProps.cellType === CellType.Input) {
    return (
      <div className="EditSaveCell">
        {showLabel && (
          <span
            className={classNames("Text", { placeholder: showPlaceholder })}
          >
            {displayText}
          </span>
        )}
        {showComponent && (
          <Input
            disabled={loading}
            placeholder={placeholder}
            type="text"
            value={localValue as typeof typeProps.value}
            onChange={handleInput}
            onKeyPressEnter={handleKeyPressEnter}
          />
        )}
        <Controls
          buttonType={buttonType}
          editTooltipText={editTooltipText}
          loading={loading}
          saveTooltipText={saveTooltipText}
          onClick={handleClickButton}
        />
      </div>
    );
  } else if (typeProps.cellType === CellType.Dropdown) {
    return (
      <div className="EditSaveCell">
        {showLabel && (
          <span className="DropdownLabel">
            {typeProps.getLabel(localValue as typeof typeProps.value)}
          </span>
        )}
        {showComponent && (
          <TsDropdown
            choices={typeProps.choices}
            id={dropdownId}
            name={`${dropdownId}-dropdown`}
            required
            value={localValue as typeof typeProps.value}
            onChange={handleDropdownChange}
          />
        )}
        <Controls
          buttonType={buttonType}
          editTooltipText={editTooltipText}
          loading={loading}
          saveTooltipText={saveTooltipText}
          onClick={handleClickButton}
        />
      </div>
    );
  }
}

interface ControlsProps {
  buttonType: ButtonType;
  loading: boolean;
  editTooltipText?: string;
  saveTooltipText?: string;
  onClick: (buttonType: ButtonType) => void;
}

function Controls({
  buttonType,
  loading,
  editTooltipText,
  saveTooltipText,
  onClick
}: ControlsProps) {
  return (
    <div className="Controls">
      {loading ? (
        <Icon name={IconName.SpinnerSpinning} />
      ) : buttonType === ButtonType.Edit ? (
        <EditIcon
          tooltipText={editTooltipText || "Bearbeiten"}
          onClick={() => onClick(ButtonType.Save)}
        />
      ) : (
        <SaveIcon
          tooltipText={saveTooltipText || "Speichern"}
          onClick={() => onClick(ButtonType.Edit)}
        />
      )}
    </div>
  );
}

export { EditSaveCell, CellType };
