import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import api from "../../../../../../api";
import urls from "../../../../../../urls";
import type { Todo } from "../../../../../../utils/backend-types";
import { showToast } from "../../../../../../utils/toast";
import { Button } from "../../../../../Buttons/Button/Button";
import { CustomForm } from "../../../../../CustomForm/CustomForm";
import { openErrorAlertPopup } from "../../../../../ErrorAlertPopup/openErrorAlertPopup";
import { AnimatedLoadingIcon } from "../../../../../Icons/AnimatedLoadingIcon/AnimatedLoadingIcon";
import { getGenerateDocumentsButtonText } from "./utils/getGenerateDocumentsButtonText";

export interface GenerateDocumentsButtonProps {
  todoId: number;
  todoLabel: string;
  confirmationQuestion?: string;
  onUpdateTodo: (todo: Todo) => void;
  disabled?: boolean;
}

function GenerateDocumentsButton({
  todoId,
  todoLabel,
  confirmationQuestion,
  onUpdateTodo,
  disabled
}: GenerateDocumentsButtonProps) {
  const [loadingUpdatedTodo, setLoadingUpdatedTodo] = useState(false);
  const [submitButtonText, setSubmitButtonText] = useState(
    getGenerateDocumentsButtonText(todoLabel)
  );
  const [confirmSubmitText, setConfirmSubmitText] = useState<string | null>(
    confirmationQuestion ?? null
  );
  const [formIteration, setFormIteration] = useState(0);

  useEffect(() => {
    setConfirmSubmitText(confirmationQuestion ?? null);
  }, [confirmationQuestion]);

  function handleFirstSubmit() {
    setConfirmSubmitText(null);
  }

  function handleDone() {
    setLoadingUpdatedTodo(true);

    api
      .get<Todo>(urls.api.todosDetail(todoId))
      .then((response) => {
        onUpdateTodo(response.data);
        showToast(
          "success",
          "Automatische Erstellung war erfolgreich. Neue Dokumente wurden an die Aufgabe angehängt."
        );
      })
      .catch((error) => {
        openErrorAlertPopup(error);
      })
      .finally(() => {
        setSubmitButtonText(getGenerateDocumentsButtonText(todoLabel));
        setFormIteration(formIteration + 1);
        setLoadingUpdatedTodo(false);
      });
  }

  if (loadingUpdatedTodo) {
    return <AnimatedLoadingIcon />;
  }

  const url = urls.api.todoGenerateDocuments(todoId);

  /**
   * This is a workaround to show the user a disabled button. Due to the complexity of the custom form it is not possible to disable the button without potential regressions or refactoring the whole component.
   * TODO: Refactor this code to not use the `CustomForm`. https://node-energy.atlassian.net/browse/UIUX-80
   **/

  return disabled ? (
    <Button color="brand" disabled={disabled}>
      {submitButtonText}
    </Button>
  ) : (
    <CustomForm
      confirmSubmitText={confirmSubmitText || undefined}
      formFields={[]}
      key={formIteration}
      postUrl={url}
      submitButtonColor="brand"
      submitButtonText={submitButtonText}
      onFirstSubmit={handleFirstSubmit}
      onPollSuccess={handleDone}
      onSubmit={handleDone}
    />
  );
}

GenerateDocumentsButton.propTypes = {
  todoId: PropTypes.number.isRequired,
  onUpdateTodo: PropTypes.func.isRequired
};

export { GenerateDocumentsButton };
