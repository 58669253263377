import type { GhostNode } from "../../utils/backend-types";
import type { FormInputData } from "../BuildingBlocks/Forms/Controllers/FormFieldController";

export const GHOST_NODE_FORM_FIELD_DATA = {
  name: {
    type: "text",
    required: false,
    label: "Bezeichnung",
    helpText: "Frei wählbar, wird nur zur Identifikation verwendet",
    name: "name"
  }
} as const satisfies {
  [key in keyof GhostNode]?: FormInputData<GhostNode, key>;
};
