import {
  MantineReactTable,
  type MRT_ColumnDef,
  type MRT_SortingState,
  useMantineReactTable
} from "mantine-react-table";
import React, { useMemo } from "react";
import { getDefaultMRTOptions } from "../../../../mantine/getDefaultMRTOptions";
import { MantineRoundedNumberCell } from "../../../../mantine/mrt/components/cell/MantineRoundedNumberCell";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { sortBackendDates } from "../../../../utils/dates/sortBackendDates";
import type {
  EnergyRevenuesListItem,
  EnergyRevenuesListResponse
} from "../../RevenueMonitoring.types";

const DEFAULT_SORTED: MRT_SortingState = [
  {
    id: "period",
    desc: true
  }
];

interface EnergyRevenuesTableProps {
  energyRevenuesData: EnergyRevenuesListResponse;
}

function EnergyRevenuesTable({ energyRevenuesData }: EnergyRevenuesTableProps) {
  const totals = useMemo(() => {
    return energyRevenuesData.reduce(
      (acc, curr) => {
        acc.direktvermarktung_volume += curr.direktvermarktung_volume;
        acc.direktvermarktung_net_revenue += curr.direktvermarktung_net_revenue;
        acc.direktvermarktung_fee += curr.direktvermarktung_fee || 0;
        acc.net_revenue_minus_fee += curr.net_revenue_minus_fee || 0;
        acc.market_premium_volume += curr.market_premium_volume;
        acc.market_premium_revenue += curr.market_premium_revenue;
        return acc;
      },
      {
        direktvermarktung_volume: 0,
        direktvermarktung_net_revenue: 0,
        direktvermarktung_fee: 0,
        net_revenue_minus_fee: 0,
        market_premium_volume: 0,
        market_premium_revenue: 0
      }
    );
  }, [energyRevenuesData]);

  const columns = useMemo<Array<MRT_ColumnDef<EnergyRevenuesListItem>>>(
    () => [
      {
        accessorKey: "period",
        header: "Zeitraum",
        Cell: ({ row }) =>
          backendDateOrDateTimeToLuxonDateTime(
            row.original.period_start
          ).toFormat("MMM yy"),
        footer: "Summe",
        sortingFn: (rowA, rowB) =>
          sortBackendDates(
            rowA.original.period_start,
            rowB.original.period_start
          )
      },
      {
        accessorKey: "direktvermarktung_volume",
        header: "Menge Direktvermarktung in kWh",
        Cell: ({ row }) => (
          <MantineRoundedNumberCell
            value={row.original.direktvermarktung_volume}
          />
        ),
        Footer: () => (
          <MantineRoundedNumberCell value={totals.direktvermarktung_volume} />
        )
      },
      {
        accessorKey: "direktvermarktung_net_revenue",
        header: "Erlös Direktvermarktung netto in EUR",
        Cell: ({ row }) => (
          <MantineRoundedNumberCell
            unit=" €"
            value={row.original.direktvermarktung_net_revenue}
          />
        ),
        Footer: () => (
          <MantineRoundedNumberCell
            unit=" €"
            value={totals.direktvermarktung_net_revenue}
          />
        )
      },
      {
        accessorKey: "direktvermarktung_fee",
        header: "Direktvermarktungsentgelt",
        Cell: ({ row }) => (
          <MantineRoundedNumberCell
            value={row.original.direktvermarktung_fee || 0}
          />
        ),
        Footer: () => (
          <MantineRoundedNumberCell value={totals.direktvermarktung_fee} />
        )
      },
      {
        accessorKey: "net_revenue_minus_fee",
        header: "Direktvermarktung abzgl. Entgelt in EUR",
        Cell: ({ row }) => (
          <MantineRoundedNumberCell
            unit=" €"
            value={row.original.net_revenue_minus_fee || 0}
          />
        ),
        Footer: () => (
          <MantineRoundedNumberCell
            unit=" €"
            value={totals.net_revenue_minus_fee}
          />
        )
      },
      {
        accessorKey: "market_premium_volume",
        header: "Menge Marktprämie in kWh",
        Cell: ({ row }) => (
          <MantineRoundedNumberCell
            value={row.original.market_premium_volume}
          />
        ),
        Footer: () => (
          <MantineRoundedNumberCell value={totals.market_premium_volume} />
        )
      },
      {
        accessorKey: "market_premium_revenue",
        header: "Erlös Marktprämie in EUR",
        Cell: ({ row }) => (
          <MantineRoundedNumberCell
            unit=" €"
            value={row.original.market_premium_revenue}
          />
        ),
        Footer: () => (
          <MantineRoundedNumberCell
            unit=" €"
            value={totals.market_premium_revenue}
          />
        )
      },
      {
        accessorKey: "revenue_per_kwh",
        header: "∅ Erlös in EUR je kWh",
        Cell: ({ row }) =>
          !row.original.net_revenue_minus_fee ||
          !row.original.direktvermarktung_volume ? (
            ""
          ) : (
            <MantineRoundedNumberCell
              decimalScale={3}
              unit=" €"
              value={
                row.original.net_revenue_minus_fee /
                row.original.direktvermarktung_volume
              }
            />
          )
      }
    ],
    [totals]
  );

  const table = useMantineReactTable({
    ...getDefaultMRTOptions<EnergyRevenuesListItem>({
      emptyRowsFallbackText: "Keine Daten vorhanden."
    }),
    columns,
    data: energyRevenuesData,
    enableColumnActions: true,
    enableFilters: false,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 15
      },
      sorting: DEFAULT_SORTED
    },
    mantineTopToolbarProps: {
      hidden: true
    }
  });

  return (
    <div className="EnergyRevenuesTable">
      <MantineReactTable table={table} />
    </div>
  );
}

export { EnergyRevenuesTable, EnergyRevenuesTableProps };
