import api from "../../../../api";
import urls from "../../../../urls";

interface Confirm2FaMethodRes {
  backupCodes: Array<string>;
}

export async function confirm2FaMethod(savedMethod: string, otp: string) {
  const { data } = await api.post<Confirm2FaMethodRes>(
    urls.auth.confirm2FaMethod(savedMethod),
    {
      code: otp
    }
  );

  return data;
}
