import React from "react";
import { useTranslation } from "react-i18next";
import { Navigate, generatePath, useParams } from "react-router-dom";
import { useRouteSiteId } from "../../hooks/useRouteSiteId";
import { useSiteVariantId } from "../../hooks/useSiteVariantId";
import { ROUTES } from "../../routes";
import { Product } from "../../utils/enums";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { StructureView } from "../StructureView/StructureView";

function ManagerStructureViewRoute() {
  const { projectId } = useParams();
  const siteId = useRouteSiteId();
  const { isLoading, error, variantId } = useSiteVariantId(siteId);
  const { t } = useTranslation();

  if (!projectId || !siteId) {
    console.error("projectId or siteId is missing from StructureView route.");
    return <Navigate to={ROUTES.managerProjectList} />;
  }

  const redirectPath = generatePath(ROUTES.managerProjectView, {
    projectId
  });

  const customErrors = {
    404: t("errors.SiteNotFound")
  };

  return (
    <LoadOrError
      customErrors={customErrors}
      error={error}
      errorRedirectTo={redirectPath}
      loading={isLoading}
    >
      {variantId && (
        <StructureView
          context={Product.Manager}
          projectId={projectId}
          siteId={siteId}
          variantId={variantId}
        />
      )}
    </LoadOrError>
  );
}

export { ManagerStructureViewRoute };
