import React from "react";
import { DropdownItem } from "../../BuildingBlocks/Dropdown/Dropdown";
import { NonSelectableDropdownItem } from "../../BuildingBlocks/Dropdown/NonSelectableDropdownItem/NonSelectableDropdownItem";

interface FilterGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  header: string | React.ReactNode;
}

function FilterGroup({ header, children, ...otherProps }: FilterGroupProps) {
  return (
    <div className="FilterGroup" {...otherProps}>
      <DropdownItem header>{header}</DropdownItem>
      <NonSelectableDropdownItem>{children}</NonSelectableDropdownItem>
    </div>
  );
}

export { FilterGroup, FilterGroupProps };
