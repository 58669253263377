import React, { useState } from "react";
import api from "../../../../../../api";
import { useSiteCategories } from "../../../../../../hooks/useSiteCategories";
import { useSiteNotifications } from "../../../../../../hooks/useSiteNotifications";
import { BasicConfirmationModal } from "../../../../../BuildingBlocks/Layout/Modals/BasicConfirmationModal/BasicConfirmationModal";
import { TsDropdown } from "../../../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { AnimatedLoadingIcon } from "../../../../../Icons/AnimatedLoadingIcon/AnimatedLoadingIcon";
import "./ConfigurationConfirmationModal.scss";

type ConfigurationConfirmationModalProps = {
  onConfirm: () => void;
  onCancel: () => void;
  siteId: number;
  projectId?: string;
};

function ConfigurationConfirmationModal({
  onConfirm,
  onCancel,
  siteId,
  projectId
}: ConfigurationConfirmationModalProps) {
  const [isOpen, setIsOpen] = useState(true);
  const { data: siteNotifications, isLoading: siteNotificationsLoading } =
    useSiteNotifications(siteId);
  const { siteCategories, isLoading: siteCategoriesLoading } =
    useSiteCategories(projectId ?? null, {
      enabled: projectId !== undefined
    });
  const categoriesForSite = siteCategories?.find((site) => site.id === siteId);
  const isFullFeedin = categoriesForSite?.is_full_feedin;
  function handleClose() {
    setIsOpen(false);
  }
  function handleChangeGenerator(url: string, choice: string) {
    if (!url) return;
    return api.patch(url, { einspeisungsart: choice });
  }

  return (
    <BasicConfirmationModal
      className="ConfigurationConfirmationModal"
      confirmationButtonText="Konfiguration bestätigen"
      headerText="Konfiguration bestätigen"
      isModalOpen={isOpen}
      size="lg"
      toggleModal={handleClose}
      onCancel={onCancel}
      onConfirm={onConfirm}
    >
      {siteNotificationsLoading || siteCategoriesLoading ? (
        <AnimatedLoadingIcon />
      ) : !isFullFeedin &&
        !(siteNotifications?.generatorsFullFeedin?.length === 0) ? (
        <>
          <p>
            Bevor Sie die Konfiguration bestätigen, überprüfen Sie bitte in der
            folgenden Liste, ob jedem Erzeuger die richtige Einspeiseart
            zugeordnet ist. Die Einspeiseart können Sie je Erzeuger über das
            Dropdown Menü ändern.
          </p>
          <p>
            Bestätigen Sie abschließend Ihre Konfiguration. Ihr erster Schritt
            im Onboarding-Prozess ist damit bereits erledigt!
          </p>
          <div className="configuration-modal-generator-list-container">
            <h5 className="configuration-modal-generator-list-header">
              Erzeuger
            </h5>
            {siteNotifications?.generatorsFullFeedin?.map(
              (notification, index) => (
                <GeneratorEditElement
                  id={notification.id}
                  key={index}
                  name={notification.name}
                  url={notification.url}
                  onEdit={handleChangeGenerator}
                />
              )
            )}
          </div>
        </>
      ) : (
        <p>
          Wenn Sie den Status auf erledigt stellen, sind keine weiteren
          Änderungen ohne die Unterstützung vom Support-Team mehr möglich.
        </p>
      )}
    </BasicConfirmationModal>
  );
}

interface GeneratorEditElementProps {
  id: number;
  name: string;
  url: string;
  onEdit: (url: string, choice: string) => void;
}
function GeneratorEditElement({
  id,
  name,
  url,
  onEdit
}: GeneratorEditElementProps) {
  const generatorChoices = [
    { value: "Volleinspeisung", displayName: "Volleinspeisung" },
    {
      value: "TeileinspeisungEigenverbrauch",
      displayName: "Teileinspeisung (Eigenverbrauch)"
    }
  ];
  return (
    <div className="generator-edit-modal-container">
      <p>{name}</p>
      <TsDropdown
        choices={generatorChoices}
        defaultValue={generatorChoices[0].value}
        id={"generator" + id}
        name={"generator" + id}
        onChange={(_, value) => {
          onEdit(url, value.toString());
        }}
      />
    </div>
  );
}

export { ConfigurationConfirmationModal, ConfigurationConfirmationModalProps };
