import React, { useState, useCallback } from "react";

import type { ApiError } from "../../../../../api";
import { getFieldSpecificError } from "../../../../../utils/api-utils";
import type { Site, Meter } from "../../../../../utils/backend-types";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../../../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../../../../Buttons/Button/Button";
import { openErrorAlertPopup } from "../../../../ErrorAlertPopup/openErrorAlertPopup";
import type { EnergyDataImportRequest } from "../../../EnergyDataUploadFlow/EnergyDataUpload";
import { retryUploadEnergyDataFile } from "../../../EnergyDataUploadFlow/EnergyDataUpload";
import { GeneralImportDetailsList } from "../../EnergyDataImportDetailsModal/GeneralImportDetailsList";
import { useDataImportRequestDetails } from "../../hooks/useDataImportRequestDetails";
import { EnergyMeterSelect } from "./EnergyMeterSelect";

import "./MeterIDModal.scss";

interface MeterIDModalProps {
  onClose: () => void;
  dataImportRequest: EnergyDataImportRequest;
  onRetryUploadDone: (dataImportRequest: EnergyDataImportRequest) => void;
  sites: Array<Site>;
  meters: Array<Meter>;
}

function MeterIDModal({
  onClose,
  dataImportRequest,
  onRetryUploadDone,
  sites,
  meters
}: MeterIDModalProps) {
  const { dataImportRequestWithDetails, isLoading } =
    useDataImportRequestDetails(dataImportRequest.id);

  const [meterID, setMeterID] = useState(0);
  const [submitIsDisabled, setSubmitIsDisabled] = useState(true);

  const handleOnChangeMeter = useCallback((index: number) => {
    setMeterID(index);
    setSubmitIsDisabled(!index);
  }, []);

  const handleRetryUploadRequest = useCallback(() => {
    retryUploadEnergyDataFile(dataImportRequest.id, meterID)
      .then((response) => {
        onRetryUploadDone(response.data);
      })
      .catch((e: ApiError) => {
        const errorMessage = getFieldSpecificError(e, ["selectedMeter"]);

        openErrorAlertPopup(
          e,
          errorMessage ? { [e.response?.status as number]: errorMessage } : {}
        );
      })
      .finally(() => {
        onClose();
      });
  }, [dataImportRequest.id, meterID, onClose, onRetryUploadDone]);

  return (
    <Modal className="MeterIDModal" isOpen toggle={onClose}>
      <ModalHeader toggle={onClose}>
        Importdetails: {dataImportRequest.energyDataFile.name}
      </ModalHeader>
      <ModalBody className="details">
        <GeneralImportDetailsList
          generalDetails={
            isLoading ? dataImportRequest : dataImportRequestWithDetails
          }
        />
      </ModalBody>
      <ModalBody>
        <p>
          Leider konnte in der Datei kein Hinweis auf die Zählernummer (oft die
          Seriennummer) gefunden werden. Bitte wählen Sie den Zähler aus, auf
          den die Daten geladen werden sollen.
        </p>
        <EnergyMeterSelect
          meters={meters}
          sites={sites}
          onChangeMeter={handleOnChangeMeter}
        />
      </ModalBody>
      <ModalFooter>
        <div className="footer-buttons">
          <Button color="secondary" onClick={onClose}>
            Abbrechen
          </Button>
          <Button
            color="primary"
            disabled={submitIsDisabled}
            onClick={() => handleRetryUploadRequest()}
          >
            Wiederholen
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export { MeterIDModal };
