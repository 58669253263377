import { type MRT_ColumnDef, type MRT_RowData } from "mantine-react-table";
import { isMrtEditing } from "../../../../../mantine/mrt/utils/isMrtEditing";
import type { InlineEditTableColumnData2 } from "../../../../../types/inlineEditTable.types";

export function getGeneratorColumn<
  T extends MRT_RowData,
  TUnassigned extends MRT_RowData = T
>(
  { validationErrors }: InlineEditTableColumnData2,
  unassignedGenerators: Array<TUnassigned> | undefined,
  onChange: (generator: TUnassigned) => void
): MRT_ColumnDef<T> {
  const unassignedGeneratorsChoices =
    unassignedGenerators
      ?.map((generator) => generator.generator_display_name)
      .sort() ?? [];

  return {
    accessorKey: "generator_display_name",
    header: "Erzeuger",
    editVariant: "select",
    enableSorting: true,
    mantineEditSelectProps: ({ row, table }) => {
      const isEditingExistingEntry = isMrtEditing(table);

      return {
        "aria-label": "Erzeuger",
        data: isEditingExistingEntry
          ? [row.original.generator_display_name]
          : unassignedGeneratorsChoices,
        error: validationErrors.generatorId,
        required: true,
        disabled: isEditingExistingEntry,
        onChange: (generatorName) => {
          const selectedGenerator = unassignedGenerators?.find(
            (generator) => generator.generator_display_name === generatorName
          );

          if (selectedGenerator) {
            onChange(selectedGenerator);
          }
        }
      };
    }
  };
}
