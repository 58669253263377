import PropTypes from "prop-types";
import React from "react";

interface CustomersCellProps {
  value: Array<string>;
}

function CustomersCell({ value }: CustomersCellProps) {
  if (value.length === 1) {
    return value[0];
  }

  return (
    <ul className="CustomersCell">
      {value.map((customer) => (
        <li key={customer}>{customer}</li>
      ))}
    </ul>
  );
}

CustomersCell.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired
};

export { CustomersCell };
