import type { QueryClient } from "@tanstack/react-query";
import type { ApiError } from "../../../../../api";
import urls from "../../../../../urls";
import type { Consumer } from "../../../../../utils/backend-types";
import {
  LoadprofileType,
  ObjectName,
  Product
} from "../../../../../utils/enums";
import { setLoadprofileType } from "../common";
import {
  buildHistoryData,
  loadData,
  LOADPROFILE_TYPES,
  saveGeneralDatafields
} from "./common";
import type { Form } from "./data-types";
import { buildFieldNameToLabelMapOldForms } from "./utils/buildFieldNameToLabelMapOldForms";

const HIDDEN_FIELDS_IF_NULL: Array<string> = [];

const VISIBLE_FIELDS = [
  "name",
  "type",
  "connection",
  "person",
  "electricityPrice"
];

const VISIBLE_FIELDS_ANALYZER = [
  ...VISIBLE_FIELDS,
  "loadprofile",
  "yearlyEnergy"
];

const REGULATORY_DATA_FIELDS = [
  "operationalPurpose",
  "eligibleForTaxReliefForParticularProcesses"
];

const PRICE_DATA_FIELDS = ["electricityPrice"];

const CONSUMER_EDIT_TABS = {
  TAB_GENERAL: "general",
  TAB_REGULATORY_DATA: "regulatorische-daten",
  TAB_PRICE_DATA: "preisdaten",
  TAB_HISTORY: "historie"
};

const FORM_INDEX = {
  GENERAL: 0,
  REGULATORY_DATA: 1,
  PRICE_DATA: 2
};

export function loadConsumerData(
  queryClient: QueryClient,
  consumerId: number,
  siteId: number,
  variantId: number,
  product: Product,
  showPriceDataTab: boolean,
  isEEPlus: boolean
) {
  const visibleFields =
    product === Product.Manager
      ? isEEPlus
        ? VISIBLE_FIELDS.concat(REGULATORY_DATA_FIELDS)
        : VISIBLE_FIELDS
      : VISIBLE_FIELDS_ANALYZER;
  const hiddenFieldsIfNull = isEEPlus
    ? HIDDEN_FIELDS_IF_NULL
    : HIDDEN_FIELDS_IF_NULL.concat(REGULATORY_DATA_FIELDS);
  return loadData({
    queryClient,
    objectName: ObjectName.Consumer,
    objectId: consumerId,
    siteId,
    variantId,
    visibleFields,
    hiddenFieldsIfNull
  }).then((data: Array<Form>) => {
    let forms = data;
    const regulatoryDataFields = forms[
      FORM_INDEX.GENERAL
    ].sections[0].fields.filter((field) =>
      REGULATORY_DATA_FIELDS.includes(field.name)
    );

    if (regulatoryDataFields && regulatoryDataFields.length > 0) {
      const generalFields = forms[FORM_INDEX.GENERAL].sections[0].fields.filter(
        (field) => !REGULATORY_DATA_FIELDS.includes(field.name)
      );
      const allValues = forms[FORM_INDEX.GENERAL].sections[0].values;
      const values = Object.keys(allValues).reduce(
        (groupedValues, key) => {
          if (REGULATORY_DATA_FIELDS.includes(key)) {
            groupedValues.regulatoryData[key] = allValues[key];
          } else {
            groupedValues.general[key] = allValues[key];
          }

          return groupedValues;
        },
        {
          general: {},
          regulatoryData: {}
        }
      );

      forms = [
        {
          ...forms[FORM_INDEX.GENERAL],
          sections: [
            {
              ...forms[FORM_INDEX.GENERAL].sections[0],
              fields: generalFields,
              values: values.general
            }
          ]
        },
        {
          name: CONSUMER_EDIT_TABS.TAB_REGULATORY_DATA,
          formTitle: "Regulatorische Daten",
          sections: [
            {
              fields: regulatoryDataFields,
              values: values.regulatoryData,
              errors: {}
            }
          ]
        }
      ];
    }

    if (product === Product.Manager) {
      const priceDataFields = forms[
        FORM_INDEX.GENERAL
      ].sections[0].fields.filter((field) =>
        PRICE_DATA_FIELDS.includes(field.name)
      );
      const generalFields = forms[FORM_INDEX.GENERAL].sections[0].fields.filter(
        (field) => !PRICE_DATA_FIELDS.includes(field.name)
      );
      const allValues = forms[FORM_INDEX.GENERAL].sections[0].values;
      const values = Object.keys(allValues).reduce(
        (groupedValues, key) => {
          if (PRICE_DATA_FIELDS.includes(key)) {
            groupedValues.priceData[key] = allValues[key];
          } else {
            groupedValues.general[key] = allValues[key];
          }

          return groupedValues;
        },
        {
          general: {},
          priceData: {}
        }
      );

      forms[0] = {
        ...forms[FORM_INDEX.GENERAL],
        sections: [
          {
            ...forms[FORM_INDEX.GENERAL].sections[0],
            fields: generalFields,
            values: values.general
          }
        ]
      };

      if (showPriceDataTab) {
        forms.push({
          name: CONSUMER_EDIT_TABS.TAB_PRICE_DATA,
          formTitle: "Preisdaten",
          sections: [
            {
              fields: priceDataFields,
              values: values.priceData,
              errors: {}
            }
          ]
        });
      }
    }

    forms[FORM_INDEX.GENERAL].sections[0].fields = setLoadprofileType(
      forms[FORM_INDEX.GENERAL].sections[0].fields,
      LoadprofileType.Consumption
    );
    forms[FORM_INDEX.GENERAL].extraProps = {
      ...forms[FORM_INDEX.GENERAL].extraProps,
      componentId: consumerId,
      confirmationCheckUrl:
        urls.api.operationPeriodsMeteringReadingsRequired(consumerId),
      loadprofileType: LOADPROFILE_TYPES[ObjectName.Consumer],
      initialLoadprofileListOpen: false
    };

    const consumerHistory = buildHistoryData(
      CONSUMER_EDIT_TABS.TAB_HISTORY,
      ObjectName.Consumer,
      consumerId,
      {
        ...buildFieldNameToLabelMapOldForms(forms),
        operation_periods: "Unternehmen"
      }
    );
    forms.push(consumerHistory);

    return Promise.resolve(forms);
  });
}

interface SaveConsumerDataResult {
  responseData: Consumer | null;
  forms: Array<Form>;
  didError: boolean;
  serverError: ApiError<Record<string, string>> | null;
}

export function saveConsumerData(
  consumerId: number,
  forms: Array<Form>
): Promise<SaveConsumerDataResult> {
  const newForms = [...forms];
  let didError = false;
  let serverError: ApiError<Record<string, string>> | null = null;

  newForms.forEach((form, index) => {
    newForms[index] = {
      ...form,
      sections: form.sections.map((section) => {
        return {
          ...section,
          errors: {}
        };
      })
    };
  });

  const regulatoryDataFormIndex = forms.findIndex(
    (form) => form.name === CONSUMER_EDIT_TABS.TAB_REGULATORY_DATA
  );
  const priceDataFormIndex = forms.findIndex(
    (form) => form.name === CONSUMER_EDIT_TABS.TAB_PRICE_DATA
  );
  let generalDataFields = forms[FORM_INDEX.GENERAL].sections[0].values;

  if (forms[regulatoryDataFormIndex]) {
    generalDataFields = {
      ...generalDataFields,
      ...forms[regulatoryDataFormIndex].sections[0].values
    };
  }

  if (forms[priceDataFormIndex]) {
    generalDataFields = {
      ...generalDataFields,
      ...forms[priceDataFormIndex].sections[0].values
    };
  }

  return saveGeneralDatafields(
    ObjectName.Consumer,
    consumerId,
    generalDataFields
  )
    .catch((error: ApiError<Record<string, string>>) => {
      if (error.response && error.response.status === 400) {
        const errors = Object.keys(error.response.data).reduce(
          (errors, key) => {
            if (REGULATORY_DATA_FIELDS.includes(key)) {
              errors.regulatoryData[key] = error.response?.data[key];
            } else if (priceDataFormIndex && PRICE_DATA_FIELDS.includes(key)) {
              errors.priceData[key] = error.response?.data[key];
            } else {
              errors.general[key] = error.response?.data[key];
            }

            return errors;
          },
          {
            general: {},
            regulatoryData: {},
            priceData: {}
          }
        );

        newForms[FORM_INDEX.GENERAL] = {
          ...newForms[FORM_INDEX.GENERAL],
          sections: [
            {
              ...newForms[FORM_INDEX.GENERAL]?.sections[0],
              errors: errors.general
            }
          ]
        };

        newForms[regulatoryDataFormIndex] = {
          ...newForms[regulatoryDataFormIndex],
          sections: [
            {
              ...newForms[regulatoryDataFormIndex]?.sections[0],
              errors: errors.regulatoryData
            }
          ]
        };

        newForms[priceDataFormIndex] = {
          ...newForms[priceDataFormIndex],
          sections: [
            {
              ...newForms[priceDataFormIndex]?.sections[0],
              errors: errors.priceData
            }
          ]
        };

        if (
          Object.prototype.hasOwnProperty.call(
            error.response.data,
            "nonFieldErrors"
          )
        ) {
          serverError = error;
        }
      } else {
        serverError = error;
      }

      didError = true;
    })
    .then((response) => {
      return Promise.resolve({
        responseData: response ? response.data : null,
        forms: newForms,
        didError: didError,
        serverError: serverError
      });
    });
}
