import { useQuery } from "@tanstack/react-query";
import type { UseQueryOptions } from "@tanstack/react-query";

import api from "../api";
import urls from "../urls";
import type { Project } from "../utils/backend-types";

export function useProject(
  projectId?: string | null,
  options?: Partial<UseQueryOptions<Project | undefined>>
) {
  const {
    data: project,
    isLoading,
    error
  } = useQuery({
    queryKey: ["project", { projectId }],
    queryFn: () => fetchProject(projectId),
    ...options
  });

  async function fetchProject(projectId?: string | null) {
    if (!projectId) {
      return undefined;
    }

    const projectsDetailUrl = urls.api.projectsDetail(projectId);
    const response = await api.get<Project>(projectsDetailUrl);
    return response.data;
  }

  return { project, isLoading, error };
}
