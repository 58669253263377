import React from "react";
import { Unit } from "../../../../../../../utils/enums";
import {
  TsDropdown,
  type Choice
} from "../../../../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
interface UnitDropdownProps {
  meterId: number;
  selectedUnit: Unit;
  onUnitSelected: (value: Unit) => void;
}

function UnitDropdown({
  meterId,
  selectedUnit,
  onUnitSelected
}: UnitDropdownProps) {
  const choices: Array<Choice> = [
    {
      value: Unit.KilowattHour,
      displayName: Unit.KilowattHour
    },
    {
      value: Unit.CubicMeter,
      displayName: Unit.CubicMeter
    }
  ];
  return (
    <div className="unit-dropdown">
      <span className="label">Einheit</span>
      <TsDropdown
        choices={choices}
        defaultValue={selectedUnit}
        id={`meter-units-${meterId}`}
        name={`meter-units-${meterId}`}
        required={true}
        onChange={(_, value) => onUnitSelected(value as Unit)}
      />
    </div>
  );
}
export { UnitDropdown };
