import { useQuery, useQueryClient } from "@tanstack/react-query";

import api from "../../api";
import urls from "../../urls";

export interface DeliveryConstraint {
  id: number;
  possible: boolean;
  priceCtPerKwh: number | null;
  source: SourceOrTarget;
  target: SourceOrTarget;
  url: string;
}

export interface SourceOrTarget {
  id: number;
  name: string;
}

function useDeliveryConstraints(siteId: number) {
  const queryClient = useQueryClient();
  const {
    isLoading,
    error,
    data: deliveryConstraints
  } = useQuery({
    queryKey: ["delivery-constraints-list", { siteId }],
    queryFn: () => fetchDeliveryConstraintsList(siteId),
    refetchInterval: false,
    refetchOnWindowFocus: false
  });

  async function fetchDeliveryConstraintsList(siteId: number) {
    const response = await api.get<Array<DeliveryConstraint>>(
      urls.api.deliveryConstraintsList(siteId)
    );
    return response.data;
  }

  function updateLocalDeliveryConstraint(
    id: number,
    parameter: string,
    value: boolean | number | null
  ) {
    queryClient.setQueryData(
      ["delivery-constraints-list", { siteId }],
      (oldDeliveryConstraints: Array<DeliveryConstraint>) => {
        return oldDeliveryConstraints.map((deliveryConstraint) => {
          if (deliveryConstraint.id === id) {
            return {
              ...deliveryConstraint,
              [parameter]: value
            };
          }

          return deliveryConstraint;
        });
      }
    );
  }

  function saveDeliveryConstraints() {
    if (!deliveryConstraints) {
      return Promise.resolve();
    }

    return Promise.all(
      deliveryConstraints.map((deliveryConstraint) =>
        api.patch(urls.api.deliveryConstraintsDetail(deliveryConstraint.id), {
          possible: deliveryConstraint.possible,
          priceCtPerKwh: deliveryConstraint.priceCtPerKwh
        })
      )
    );
  }

  return {
    isLoading,
    error,
    deliveryConstraints,
    saveDeliveryConstraints,
    updateLocalDeliveryConstraint
  };
}

export { useDeliveryConstraints };
