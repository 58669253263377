import React, { useState } from "react";

import {
  Modal,
  ModalBody,
  ModalHeader
} from "../../../BuildingBlocks/Layout/Modal/Modal";
import { HistoryTab } from "../../../HistoryTab/HistoryTab";
import { METERING_SERVICE_PROVIDER_FIELD_LABELS } from "../MeteringServiceProviderTable";

interface HistoryCellProps {
  id: number;
}

interface MeteringServiceProviderHistoryModalProps {
  onClose: () => void;
  id: number;
}

function HistoryCell({ id }: HistoryCellProps) {
  const [selectedProviderId, setSelectedProviderId] = useState<number | null>(
    null
  );

  return (
    <>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setSelectedProviderId(id);
        }}
      >
        Anzeigen
      </a>

      {selectedProviderId && (
        <MeteringServiceProviderHistoryModal
          id={id}
          onClose={() => setSelectedProviderId(null)}
        />
      )}
    </>
  );
}

function MeteringServiceProviderHistoryModal({
  id,
  onClose
}: MeteringServiceProviderHistoryModalProps) {
  return (
    <Modal isOpen toggle={onClose}>
      <ModalHeader toggle={onClose}>Historie</ModalHeader>
      <ModalBody scrollable>
        <HistoryTab
          entityId={id}
          entityType="meteringServiceProvider"
          fieldNameToLabelMap={METERING_SERVICE_PROVIDER_FIELD_LABELS}
        />
      </ModalBody>
    </Modal>
  );
}

export { HistoryCell };
