import { useQuery } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import urls from "../../../urls";
import type { Paragraph6CreditError } from "../Paragraph6.types";

export function useParagraph6CreditErrors(variantId: number) {
  const {
    data: creditErrors,
    isLoading,
    error
  } = useQuery({
    queryKey: ["paragraph6CreditErrors", { variantId }],
    queryFn: () => fetchParagraph6CreditErrors(variantId)
  });

  async function fetchParagraph6CreditErrors(variantId: number) {
    const url = urls.apiWithoutCamelization.paragraph6CreditErrors(variantId);
    const response =
      await apiWithoutCamelization.get<Array<Paragraph6CreditError>>(url);

    return response.data;
  }

  return {
    creditErrors,
    isLoading,
    error
  };
}
