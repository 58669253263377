import { DateTime } from "luxon";

/**
 * Converts either backend Date(Time) formats ("German" and ISO 8601) to Luxon DateTime.
 * Detects and accepts Date and Time or just Date.
 */
export function backendDateOrDateTimeToLuxonDateTime(
  backendDateOrDateTime: string
): DateTime {
  const maybeIso8601 = DateTime.fromISO(backendDateOrDateTime);

  if (maybeIso8601.isValid) {
    // Luxon is very lenient - we only want to accept complete dates
    if (
      isCompleteIsoDate(backendDateOrDateTime) ||
      isCompleteIsoDateTime(backendDateOrDateTime)
    ) {
      return maybeIso8601;
    }

    return DateTime.invalid("Invalid date format: " + backendDateOrDateTime);
  }

  if (isGermanDateFormat(backendDateOrDateTime)) {
    console.warn("German date format is deprecated. Please use ISO 8601.");
    return DateTime.fromFormat(backendDateOrDateTime, "dd.MM.yyyy");
  }

  if (isGermanDateTimeFormat(backendDateOrDateTime)) {
    console.warn("German date format is deprecated. Please use ISO 8601.");
    return DateTime.fromFormat(backendDateOrDateTime, "dd.MM.yyyy HH:mm:ss");
  }

  // return invalid DateTime (can be checked with property `isValid`)
  return maybeIso8601;
}

function isCompleteIsoDate(dateString: string) {
  return /^\d{4}-\d{2}-\d{2}$/.test(dateString);
}

function isCompleteIsoDateTime(dateString: string) {
  return /\b\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\b/.test(dateString);
}

function isGermanDateFormat(dateString: string) {
  return /^\d{2}\.\d{2}\.\d{4}$/.test(dateString);
}

function isGermanDateTimeFormat(dateTimeString: string) {
  return /^\d{2}\.\d{2}\.\d{4} \d{2}:\d{2}:\d{2}$/.test(dateTimeString);
}
