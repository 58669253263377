import { useQuery } from "@tanstack/react-query";
import api from "../../../../../api";
import urls from "../../../../../urls";
import type { Delivery } from "../../../../../utils/backend-types";

export function useDelivery(deliveryId: string | undefined) {
  const {
    isLoading: isDeliveryDataLoading,
    error: deliveryLoadingError,
    data: delivery
  } = useQuery({
    queryKey: ["delivery", { deliveryId }],
    queryFn: () => fetchDelivery(deliveryId),
    enabled: typeof deliveryId !== "undefined",
    gcTime: 0,
    refetchInterval: false,
    refetchOnWindowFocus: false
  });

  return {
    isDeliveryDataLoading,
    deliveryLoadingError,
    delivery
  };
}

async function fetchDelivery(deliveryId: string | undefined) {
  if (!deliveryId) {
    return undefined;
  }

  const endpointUrl = urls.api.delivery(deliveryId);
  const response = await api.get<Delivery>(endpointUrl);

  return response.data;
}
