import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import urls from "../../../urls";
import type {
  Paragraph6ContractResponse,
  paragraph6ErzeugerAnteilResponse
} from "../Paragraph6.types";

export function useParagraph6ErzeugerAnteil(
  contractId: Paragraph6ContractResponse["id"],
  generatorShareId?: number,
  options?: Partial<
    UseQueryOptions<paragraph6ErzeugerAnteilResponse | undefined>
  >
) {
  const {
    data: paragraph6ErzeugerAnteil,
    isLoading,
    isFetching,
    error
  } = useQuery({
    queryKey: ["paragraph6ErzeugerAnteil", { generatorShareId }],
    queryFn: () => fetchparagraph6ErzeugerAnteil(generatorShareId),
    enabled: !!generatorShareId,
    ...options
  });

  async function fetchparagraph6ErzeugerAnteil(generatorShareId?: number) {
    if (!generatorShareId) return undefined;

    const paragraph6ErzeugerAnteilUrl =
      urls.apiWithoutCamelization.paragraph6ErzeugerAnteil(
        contractId,
        generatorShareId
      );
    const response =
      await apiWithoutCamelization.get<paragraph6ErzeugerAnteilResponse>(
        paragraph6ErzeugerAnteilUrl
      );
    return response.data;
  }

  return {
    paragraph6ErzeugerAnteil,
    isLoading,
    isFetching,
    error
  };
}
