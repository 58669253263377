import React, { useState } from "react";
import { OptiSupportEndpoints } from "../../urls";
import type { Site } from "../../utils/backend-types";
import { IconName } from "../BuildingBlocks/Icon/types";
import { Section } from "../BuildingBlocks/Layout/Section";
import { OptiSupportHelpLink } from "../OptiSupportHelpLink/OptiSupportHelpLink";
import type { ConfigurationData } from "./ThirdPartySystems.types";
import { ThirdPartySystemsList } from "./ThirdPartySystemsList/ThirdPartySystemsList";
import "./ThirdPartySystems.scss";

interface ThirdPartySystemsProps {
  initialConfigurations: Array<ConfigurationData>;
  sites: Array<Site>;
  variantId: number;
}

function ThirdPartySystems({
  initialConfigurations,
  sites,
  variantId
}: ThirdPartySystemsProps) {
  const [subMeteringConfigurations, setSubMeteringConfigurations] = useState(
    initialConfigurations
  );

  function handleConfigurationCreated(configuration: ConfigurationData) {
    const newConfigurationList = [...subMeteringConfigurations, configuration];

    setSubMeteringConfigurations(newConfigurationList);
  }

  function handleConfigurationUpdated(updatedConfiguration: ConfigurationData) {
    const configurationIndex = subMeteringConfigurations.findIndex(
      (configuration) => configuration.id === updatedConfiguration.id
    );
    const newConfigurationList = [...subMeteringConfigurations];

    newConfigurationList[configurationIndex] = updatedConfiguration;
    setSubMeteringConfigurations(newConfigurationList);
  }

  function handleConfigurationsDeleted(ids: Array<number>) {
    const newConfigurationList = subMeteringConfigurations.filter(
      (configuration) => !ids.includes(configuration.id)
    );
    setSubMeteringConfigurations(newConfigurationList);
  }

  return (
    <div className="ThirdPartySystemsPage ItemsPage">
      <Section>
        <OptiSupportHelpLink
          iconName={IconName.QuestionCircle2}
          optiSupportEndpoint={
            OptiSupportEndpoints.ScadaDatenUntermessschnittstelle
          }
          text="So stellen Sie Scada-Daten bereit"
        />
        <ThirdPartySystemsList
          sites={sites}
          subMeteringConfigurations={subMeteringConfigurations}
          variantId={variantId}
          onConfigurationCreated={handleConfigurationCreated}
          onConfigurationsDeleted={handleConfigurationsDeleted}
          onConfigurationUpdated={handleConfigurationUpdated}
        />
      </Section>
    </div>
  );
}

export { ThirdPartySystems };
