import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import api from "../../../api";
import urls from "../../../urls";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";

type Period = { startDate?: string; endDate?: string };

export function useParkabrechnungBelieferten(
  projectId: string,
  params: FetchBeliefertenParams,
  enabled: boolean = true
) {
  const { isLoading, error, data } = useQuery({
    queryKey: ["belieferten", projectId, params],
    queryFn: () => fetchBelieferten(projectId, params),
    enabled
  });

  return { isLoading, error, data };
}

type FetchBeliefertenParams = {
  period?: Period;
  siteId?: string;
};

async function fetchBelieferten(
  projectId: string,
  params: FetchBeliefertenParams
) {
  const queryParams = {};

  if (params.period?.startDate) {
    queryParams["start_date"] = params.period.startDate;
  } else {
    queryParams["start_date"] = luxonDateTimeToBackendDateOrDateTime(
      DateTime.now().startOf("year"),
      "ISO 8601"
    );
  }

  if (params.period?.endDate) {
    queryParams["end_date"] = params.period.endDate;
  } else {
    queryParams["end_date"] = luxonDateTimeToBackendDateOrDateTime(
      DateTime.now().endOf("year"),
      "ISO 8601"
    );
  }

  if (params.siteId) {
    queryParams["site_id"] = params.siteId;
  }

  const response = await api.get<Array<{ id: string; name: string }>>(
    urls.api.parkabrechnungBelieferten({
      projectId,
      queryParams
    })
  );

  return response.data;
}
