import React, { PureComponent } from "react";
import { Col, Row } from "reactstrap";

import { showToast } from "../../../utils/toast";
import { Section } from "../../BuildingBlocks/Layout/Section";
import { ProjectShareWidget } from "../../ProjectShareWidget/ProjectShareWidget";

class RightsPage extends PureComponent {
  handleError = (error) => {
    showToast("error", error);
  };

  render() {
    const { projectId, projectName, projectManagers, onShare } = this.props;

    return (
      <div className="RightsPage">
        <Section>
          <Row>
            <Col md={4}>
              <h5>{projectName} teilen</h5>
              <ProjectShareWidget
                projectId={projectId}
                projectManagers={projectManagers}
                onError={this.handleError}
                onShare={onShare}
              />
            </Col>
          </Row>
        </Section>
      </div>
    );
  }
}

export { RightsPage };
