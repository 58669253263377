import { useQuery } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import urls from "../../../urls";
import type { User } from "../../../utils/backend-types";

export function useSystemUsers() {
  return useQuery({
    queryKey: ["system-users"],
    queryFn: getSystemUsers,
    staleTime: 10 * 60 * 1000 // 10 min
  });
}

async function getSystemUsers() {
  const response = await apiWithoutCamelization.get<Array<User>>(
    urls.apiWithoutCamelization.systemUsers()
  );
  return response.data;
}
