import { useQuery } from "@tanstack/react-query";
import api from "../../../api";
import urls from "../../../urls";
import type { VariantId } from "../../../utils/backend-types";
import type { ParkabrechnungContractFormOptionsResponse } from "../Parkabrechnung.types";

type UseParkabrechnungContractFormOptionsArgs = { variantId: VariantId };

export function useParkabrechnungContractFormOptions({
  variantId
}: UseParkabrechnungContractFormOptionsArgs) {
  return useQuery<ParkabrechnungContractFormOptionsResponse>({
    queryKey: ["parkAbrechnungContractFormOptions", { variantId }],
    queryFn: () => fetchParkabrechnungContractFormOptions({ variantId })
  });
}

async function fetchParkabrechnungContractFormOptions({
  variantId
}: UseParkabrechnungContractFormOptionsArgs) {
  return (
    await api.get(urls.api.parkabrechnungContractFormOptions({ variantId }))
  ).data;
}
