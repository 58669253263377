import classnames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export const ErrorText = (text) => (
  <span className={"invalid-feedback "} key={text}>
    <strong>{text}</strong>
  </span>
);

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
export default class Multiline extends PureComponent {
  render() {
    const {
      id,
      name,
      value,
      inputGroupText = null,
      errorTexts = [],
      invalid,
      disabled,
      onChange,
      extraInputClasses = "",
      placeholder
    } = this.props;

    const inputValue = !value && value !== 0 ? "" : value;
    const className = classnames("form-control", "m-input", extraInputClasses, {
      "is-invalid": invalid || errorTexts.length > 0
    });
    const textarea = (
      <textarea
        className={className}
        disabled={disabled}
        id={id}
        name={name}
        placeholder={placeholder}
        rows="10"
        value={inputValue}
        onChange={onChange}
      />
    );

    return (
      <div className={"FormField Multiline"}>
        {inputGroupText ? (
          <div className="input-group">
            {textarea}
            <div className="input-group-append">
              <span className="input-group-text">{inputGroupText}</span>
            </div>
            {errorTexts.map(ErrorText)}
          </div>
        ) : (
          <div>
            {textarea}
            {errorTexts.map(ErrorText)}
          </div>
        )}
      </div>
    );
  }
}

Multiline.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errorTexts: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  extraInputClasses: PropTypes.string
};
