import { useQuery } from "@tanstack/react-query";
import api from "../../../api";
import urls from "../../../urls";
import type { LoadProfile } from "../LoadProfileManager.types";

export function useLoadProfile(loadProfileId: number) {
  const { data, isLoading, error } = useQuery({
    queryKey: ["loadProfile", { loadProfileId }],
    queryFn: () => fetchLoadProfile(loadProfileId)
  });

  return { loadProfile: data, isLoading, error };
}

async function fetchLoadProfile(loadProfileId: number) {
  const url = urls.api.loadprofilesDetail(loadProfileId);
  const response = await api.get<LoadProfile>(url);

  return response.data;
}
