import type { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useCallback, useEffect } from "react";

import { LUXON_END_OF_LAST_YEAR } from "../../../../../utils/dates";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "../../../../BuildingBlocks/Dropdown/Dropdown";
import { Icon } from "../../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../../BuildingBlocks/Icon/types";
import { Button } from "../../../../Buttons/Button/Button";
import { DatePicker } from "../../../../DatePicker/DatePicker";
import { Input } from "../../../../DynamicForm/FormItems/FormField/Input/Input";
import type {
  EdaFilterOption,
  EdaFilterOptions
} from "../EnergyDataAcquisitionSelectionModal";
import {
  FILTER_TYPES,
  FILTER_TYPE_KEYS,
  INSTALLED_CAPACITY_DEFAULT_MIN_MAX
} from "./filter-utils";

interface FilterProps {
  type: string;
  availableTypes: Array<string>;
  options: Array<
    | string
    | {
        min: number;
        max: number;
      }
  >;
  possibleOptions: EdaFilterOptions;
  canRemove: boolean;
  onChangeType: (type: string) => void;
  onChangeOptions: (
    options: Array<
      | string
      | {
          min: number;
          max: number;
        }
    >
  ) => void;
  onRemoveFilter: () => void;
}

function Filter({
  type,
  availableTypes,
  options,
  possibleOptions,
  canRemove,
  onChangeType,
  onChangeOptions,
  onRemoveFilter
}: FilterProps) {
  function handleOptionChange(
    index: number,
    value: string | { min: number; max: number }
  ) {
    const newOptions = [...options];
    newOptions[index] = value;
    onChangeOptions(newOptions);
  }

  function handleAddOption() {
    onChangeOptions([...options, ""]);
  }

  function handleRemoveOption(index: number) {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    onChangeOptions(newOptions);
  }

  return (
    <div className="filter">
      <FilterTypeDropdown
        availableTypes={availableTypes}
        type={type}
        onChange={onChangeType}
      />
      {type !== "" && (
        <React.Fragment>
          <span className="connector-text">
            {type === "installedCapacity" ? "zwischen" : "ist"}
          </span>
          <FilterOptions
            filterType={type}
            options={options}
            possibleOptions={possibleOptions}
            onAddOption={handleAddOption}
            onOptionChange={handleOptionChange}
            onRemoveOption={handleRemoveOption}
          />
        </React.Fragment>
      )}
      {canRemove && <RemoveFilterButton onClick={onRemoveFilter} />}
    </div>
  );
}

interface FilterTypeDropdownProps {
  type: string;
  availableTypes: Array<string>;
  onChange: (value: string | { min: number; max: number }) => void;
}

function FilterTypeDropdown({
  type,
  availableTypes,
  onChange
}: FilterTypeDropdownProps) {
  const availableOrCurrentFilters = FILTER_TYPE_KEYS.filter((filterType) => {
    return availableTypes.includes(filterType) || filterType === type;
  });

  return (
    <UncontrolledDropdown className="filter-type">
      <DropdownToggle caret>
        {type && type !== "" ? FILTER_TYPES[type] : "Filtertyp wählen"}
      </DropdownToggle>
      <DropdownMenu className="scrollable-dropdown">
        {availableOrCurrentFilters.map((filterType) => (
          <DropdownItem key={filterType} onClick={() => onChange(filterType)}>
            {FILTER_TYPES[filterType]}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

interface FilterOptionsProps {
  options: Array<
    | string
    | {
        min: number;
        max: number;
      }
  >;
  filterType: string;
  possibleOptions: EdaFilterOptions;
  onOptionChange: (
    index: number,
    value:
      | string
      | {
          min: number;
          max: number;
        }
  ) => void;
  onAddOption: () => void;
  onRemoveOption: (index: number) => void;
}

function FilterOptions({
  options,
  filterType,
  possibleOptions,
  onOptionChange,
  onAddOption,
  onRemoveOption
}: FilterOptionsProps) {
  const availableOptions = possibleOptions.filter(
    (possibleOption) => !options.includes(possibleOption.value.toString())
  );

  return (
    <div className="filter-options">
      {options.map((option, index) => {
        if (filterType === "installedCapacity") {
          return (
            <FilterInstalledCapacityOption
              key={index}
              option={
                option === ""
                  ? INSTALLED_CAPACITY_DEFAULT_MIN_MAX
                  : (option as { min: number; max: number })
              }
              onChange={(value) => onOptionChange(index, value)}
            />
          );
        } else if (filterType === "date") {
          return (
            <FilterDateOption
              key={index}
              option={option === "" ? null : (option as string)}
              onChange={(value) => onOptionChange(index, value)}
            />
          );
        } else {
          return (
            <React.Fragment key={(option as string) || index}>
              <FilterDropdownOption
                availableOptions={availableOptions}
                option={option as string}
                possibleOptions={possibleOptions}
                onChange={(value) => onOptionChange(index, value.toString())}
              />
              {index < options.length - 1 && (
                <span className="connector-text">oder</span>
              )}
            </React.Fragment>
          );
        }
      })}
      <div className="option-buttons">
        {options.length < possibleOptions.length && (
          <Button
            className="add-option-button"
            color="secondary"
            size="sm"
            onClick={onAddOption}
          >
            <Icon name={IconName.Plus} tooltipText="Option hinzufügen" />
          </Button>
        )}
        {options.length > 1 && (
          <Button
            className="remove-option-button"
            color="secondary"
            size="sm"
            onClick={() => onRemoveOption(options.length - 1)}
          >
            <Icon name={IconName.Minus} tooltipText="Option löschen" />
          </Button>
        )}
      </div>
    </div>
  );
}

function RemoveFilterButton({ onClick }) {
  return (
    <span className="remove-filter-button m--font-danger">
      <Icon
        name={IconName.Trash}
        tooltipText="Kriterium löschen"
        onClick={onClick}
      />
    </span>
  );
}

interface FilterDropdownOptionProps {
  option: string;
  possibleOptions: EdaFilterOptions;
  availableOptions: EdaFilterOptions;
  onChange: (value: string | number) => void;
}

function FilterDropdownOption({
  option,
  possibleOptions,
  availableOptions,
  onChange
}: FilterDropdownOptionProps) {
  function sanitizeChange(value: string) {
    if (value === "") {
      return "";
    } else if (isNaN(value as unknown as number)) {
      // this is typescript headache - isNaN wants a number to be able to check if the value we don't know is a number is a number...
      return value;
    } else {
      return parseInt(value, 10);
    }
  }

  function getSelectedOptionLabel(selectedOption?: EdaFilterOption) {
    if (!selectedOption) {
      return "Bitte wählen";
    }

    let label = selectedOption.label;

    if (selectedOption.site) {
      const optionsWithSameLabels = possibleOptions.filter(
        (possibleOption) => possibleOption.label === selectedOption.label
      );
      if (optionsWithSameLabels.length > 1) {
        // if there are at least 2 options with the same label, show the site name in brackets
        // to help the user distinguish one from the other
        label = `${label} (${selectedOption.site})`;
      }
    }

    return label;
  }

  const selectedOption = possibleOptions.find(
    (possibleOption) => possibleOption.value.toString() === option
  );
  const availableOptionValues = availableOptions.map((ao) => ao.value);
  const availablePossibleOptions = possibleOptions.filter(
    (po) =>
      availableOptionValues.includes(po.value) || po.value.toString() === option
  );

  const optionsGroupedBySite = availablePossibleOptions.reduce(
    (accumulator, availablePossibleOption) => {
      const site = availablePossibleOption.site;
      const existingOptionsForSite =
        site in accumulator ? accumulator[site] : [];
      return {
        ...accumulator,
        [site]: [...existingOptionsForSite, availablePossibleOption]
      };
    },
    {}
  );
  const siteNames = Object.keys(optionsGroupedBySite);
  const siteNamesSorted = siteNames.sort();
  const optionsGroupedBySiteAndSorted = siteNames.reduce(
    (accumulator, site) => {
      return {
        ...accumulator,
        [site]: optionsGroupedBySite[site].sort()
      };
    },
    {}
  );

  const selectedLabel = getSelectedOptionLabel(selectedOption);

  return (
    <UncontrolledDropdown className="filter-option">
      <DropdownToggle caret>{selectedLabel}</DropdownToggle>
      <DropdownMenu className="scrollable-dropdown">
        {siteNamesSorted.length === 0 ? (
          <DropdownItem>Keine Option verfügbar</DropdownItem>
        ) : siteNamesSorted.length > 1 ? (
          <GroupedDropdownItems
            groupNames={siteNames}
            options={optionsGroupedBySiteAndSorted}
            onClick={(value) => onChange(sanitizeChange(value))}
          />
        ) : (
          <DropdownItems
            options={optionsGroupedBySiteAndSorted[siteNames[0]]}
            onClick={(value) => onChange(sanitizeChange(value))}
          />
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

interface GroupedDropdownItemsProps {
  groupNames: Array<string>;
  options: Record<string, EdaFilterOptions>;
  onClick: (value: string) => void;
}

function GroupedDropdownItems({
  groupNames,
  options,
  onClick
}: GroupedDropdownItemsProps) {
  return (
    <>
      {groupNames.map((groupName) => (
        <React.Fragment key={groupName}>
          <DropdownItem header>{groupName}</DropdownItem>
          {options[groupName].map((option) => (
            <DropdownItem
              key={option.value as string}
              onClick={() => onClick(option.value as string)}
            >
              {option.label}
            </DropdownItem>
          ))}
        </React.Fragment>
      ))}
    </>
  );
}

interface DropdownItemsProps {
  options: EdaFilterOptions;
  onClick: (value: string) => void;
}

function DropdownItems({ options, onClick }: DropdownItemsProps) {
  return (
    <>
      {options.map((option) => (
        <DropdownItem
          key={option.value as string}
          onClick={() => onClick(option.value as string)}
        >
          {option.label}
        </DropdownItem>
      ))}
    </>
  );
}

interface FilterInstalledCapacityOptionProps {
  option: { min: number; max: number };
  onChange: (value: { min: number; max: number }) => void;
}

function FilterInstalledCapacityOption({
  option,
  onChange
}: FilterInstalledCapacityOptionProps) {
  function handleChangeMin(value: string) {
    onChange({
      min: parseInt(value, 10),
      max: option.max
    });
  }

  function handleChangeMax(value: string) {
    onChange({
      min: option.min,
      max: parseInt(value, 10)
    });
  }

  return (
    <div className="filter-option">
      <Input
        min={0}
        step={1}
        type="number"
        value={option.min}
        onChange={handleChangeMin}
      />
      <span className="connector-text">und</span>
      <Input
        extraInputClasses="form-control m-input"
        step={1}
        type="number"
        value={option.max}
        onChange={handleChangeMax}
      />
    </div>
  );
}

interface FilterDataOptionProps {
  option: string | null;
  onChange: (date: string) => void;
}

function FilterDateOption({ option, onChange }: FilterDataOptionProps) {
  const handleChangeFilterDate = useCallback(
    (date: DateTime | null) => {
      if (date === null) {
        return;
      }

      const backendDate = luxonDateTimeToBackendDateOrDateTime(date);
      onChange(backendDate);
    },
    [onChange]
  );

  useEffect(() => {
    if (!option) {
      handleChangeFilterDate(LUXON_END_OF_LAST_YEAR);
    }
  }, [option, handleChangeFilterDate]);

  const date = option
    ? backendDateOrDateTimeToLuxonDateTime(option)
    : LUXON_END_OF_LAST_YEAR;

  return (
    <div className="filter-option">
      <DatePicker
        date={date}
        id="eda-filters-date-filter"
        onChange={handleChangeFilterDate}
      />
    </div>
  );
}

Filter.propTypes = {
  type: PropTypes.string.isRequired,
  availableTypes: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  possibleOptions: PropTypes.array.isRequired,
  canRemove: PropTypes.bool.isRequired,
  onChangeType: PropTypes.func.isRequired,
  onChangeOptions: PropTypes.func.isRequired,
  onRemoveFilter: PropTypes.func.isRequired
};

export { Filter };
