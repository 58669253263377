import React from "react";

import { FormField } from "../../BuildingBlocks/Forms/FormField/FormField";
import {
  Modal,
  ModalFooter,
  ModalHeader
} from "../../BuildingBlocks/Layout/Modal/Modal";
import { ModalBody } from "../../BuildingBlocks/Layout/Modal/ModalBody/ModalBody";
import { Button } from "../../Buttons/Button/Button";
import { DummyLink } from "../../DummyLink/DummyLink";

interface AuthDeactivationModalProps {
  isOpen: boolean;
  otp: string;
  isEmail2FaActive: boolean;
  onToggle: () => void;
  onAuthDeactivation: () => void;
  onOtpChange: (value: string) => void;
  onOtpResend: () => void;
}

function AuthDeactivationModal({
  isOpen,
  otp,
  isEmail2FaActive,
  onToggle,
  onAuthDeactivation,
  onOtpChange,
  onOtpResend
}: AuthDeactivationModalProps) {
  const deactivationCodeLabel = isEmail2FaActive
    ? "Um die Zwei-Faktor-Authentifizierung zu deaktivieren, geben Sie bitte den Code ein, den wir Ihnen per E-Mail zugesandt haben."
    : "Um die Zwei-Faktor-Authentifizierung zu deaktivieren, geben Sie bitte den Code aus Ihrer Authentifizierungs-App ein.";

  return (
    <Modal fade={false} isOpen={isOpen} toggle={onToggle}>
      <ModalHeader toggle={onToggle}>
        Bestätigen Sie die Deaktivierung
      </ModalHeader>
      <ModalBody scrollable>
        <FormField
          label={deactivationCodeLabel}
          placeholder="XXXXXX"
          required
          type="text"
          value={otp}
          onChange={(e) => onOtpChange(e.target.value)}
        />

        {isEmail2FaActive && (
          <DummyLink onClick={onOtpResend}>
            Senden Sie den Code erneut
          </DummyLink>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onToggle}>
          Schließen
        </Button>
        <Button color="danger" onClick={onAuthDeactivation}>
          Speichern
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export { AuthDeactivationModal, AuthDeactivationModalProps };
