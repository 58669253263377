import { DateTime } from "luxon";
import type { MeasurandDataGaps } from "../../../../../../../../utils/backend-types";
import type { DataGapsTableObject } from "../DataGaps.types";

export function getTableObjectsFromDataGapEntry(
  dataGap: MeasurandDataGaps
): DataGapsTableObject[] | null {
  const gaps = dataGap.gaps;
  if (gaps.length === 0 || gaps[0].length === 0) {
    return null;
  }

  const groupedGaps: { [key: string]: Array<Array<string>> } = {};

  // Split gaps that span multiple days and group gaps by day
  for (const gap of gaps) {
    let startDateTime = DateTime.fromISO(gap[0], { zone: "Europe/Berlin" });
    const endDateTime = DateTime.fromISO(gap[1], { zone: "Europe/Berlin" });

    if (!startDateTime.equals(endDateTime)) {
      while (startDateTime < endDateTime) {
        const dayStart = startDateTime.startOf("day");
        const dayEnd = startDateTime.endOf("day");

        const gapStart = startDateTime.toISO();
        const gapEnd =
          endDateTime < dayEnd
            ? endDateTime.toISO()
            : dayEnd.minus({ minutes: 14, seconds: 59 }).toISO();

        const day = dayStart.toISODate();
        if (day && gapStart && gapEnd) {
          if (!groupedGaps[day]) {
            groupedGaps[day] = [];
          }
          groupedGaps[day].push([gapStart, gapEnd]);
        }

        startDateTime = dayEnd.plus({ seconds: 1 });
      }
    } else {
      const day = startDateTime.toISODate();
      if (day) {
        if (!groupedGaps[day]) {
          groupedGaps[day] = [];
        }
        const startDateIso = startDateTime.toISO();
        const endDateIso = endDateTime.toISO();
        if (startDateIso && endDateIso) {
          groupedGaps[day].push([startDateIso, endDateIso]);
        }
      }
    }
  }

  const result: DataGapsTableObject[] = [];

  // Create DataGapsTableObject for each day
  for (const day in groupedGaps) {
    const dayGaps = groupedGaps[day];
    let earliestDate = dayGaps[0][0];
    let latestDate = dayGaps[0][1];

    for (const gap of dayGaps) {
      if (gap[0] < earliestDate) {
        earliestDate = gap[0];
      }
      if (gap[1] > latestDate) {
        latestDate = gap[1];
      }
    }

    const startDateTime = DateTime.fromISO(earliestDate, {
      zone: "Europe/Berlin"
    });
    const lastDateTime = DateTime.fromISO(latestDate, {
      zone: "Europe/Berlin"
    }).plus({ minutes: 15 });

    result.push({
      date: {
        startDate: startDateTime,
        lastDate:
          lastDateTime.day === startDateTime.day ? startDateTime : lastDateTime
      },
      measurand: dataGap.measurand,
      dataGaps: dayGaps
    });
  }

  return result;
}
