import * as Sentry from "@sentry/browser";

import { SYSTEMS } from "../ThirdPartySystems.constants";

export function getSystemDisplayNameFromValue(systemValue: string): string {
  const systemData = SYSTEMS.find((choice) => choice.value === systemValue);

  if (!systemData) {
    Sentry.captureMessage(
      `Invalid subMeteringSystem detected in list: ${systemValue}`
    );

    return "Sonstiges";
  }

  return systemData.displayName;
}
