import {
  MantineReactTable,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_SortingState,
  type MRT_TableOptions,
  useMantineReactTable
} from "mantine-react-table";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGenerationShareMutations } from "../../../../hooks/useGenerationShareMutations";
import type { GenerationShareResponse } from "../../../../hooks/useGenerationShares";
import { useGenerationShares } from "../../../../hooks/useGenerationShares";
import { useMeteringOrMarketLocations } from "../../../../hooks/useMeteringOrMarketLocations";
import { useSuggestedMeteringOrMarketLocation } from "../../../../hooks/useSuggestedMeteringOrMarketLocation";
import { getDefaultMRTEditableRowOptions } from "../../../../mantine/getDefaultMRTEditableRowOptions";
import { getDefaultMRTOptions } from "../../../../mantine/getDefaultMRTOptions";
import { getDefaultMRTTopToolbarCustomActionsOptions } from "../../../../mantine/getDefaultMRTTopToolbarCustomActionsOptions";
import { convertApiErrorsToMrtErrors } from "../../../../mantine/mrt/utils/convertApiErrorsToMrtErrors";
import { isMrtCreating } from "../../../../mantine/mrt/utils/isMrtCreating";
import { THEME_VARS } from "../../../../utils/constants";
import { getErrorText } from "../../../../utils/get-error-text";
import { showToast } from "../../../../utils/toast";
import { MultiConfirmationModal } from "../../../BuildingBlocks/Layout/Modals/MultiConfirmationModal/MultiConfirmationModal";
import { getGeneratorColumn } from "../../../Direktvermarktung/DirektvermarktungContractInformation/DirektvermarktungGeneratorShareTable/Columns/getGeneratorColumn";
import { getWeightingColumn } from "../../../Direktvermarktung/DirektvermarktungContractInformation/DirektvermarktungGeneratorShareTable/Columns/getWeightingColumn";
import { getZaehlpunktColumn } from "../../../Direktvermarktung/DirektvermarktungContractInformation/DirektvermarktungGeneratorShareTable/Columns/getZaehlpunktColumn";
import { validateDirektvermarktungGeneratorShareFormValues } from "../../../Direktvermarktung/DirektvermarktungContractInformation/DirektvermarktungGeneratorShareTable/utils/validateDirektvermarktungGeneratorShareFormValues";
import { LoadOrError } from "../../../LoadOrError/LoadOrError";

const DEFAULT_SORTED: MRT_SortingState = [
  {
    id: "generator_display_name",
    desc: false
  }
];

interface WeightingTabProps {
  siteId: number;
}

function WeightingTab({ siteId }: WeightingTabProps) {
  const { t } = useTranslation();
  const {
    generationShares: generationSharesWithNulls = [],
    isLoading,
    error
  } = useGenerationShares(siteId);

  const { editGenerationShare, deleteGenerationShare } =
    useGenerationShareMutations();

  // replace all values in generationSharesWithNulls that are null with empty strings
  const generationShares = generationSharesWithNulls.map((generationShare) => ({
    ...generationShare,
    generator_id: generationShare.generator_id ?? "",
    generator_display_name: generationShare.generator_display_name ?? "",
    metering_or_market_location_display_name:
      generationShare.metering_or_market_location_display_name ?? "",
    weighting: generationShare.weighting ?? ""
  }));

  async function onDelete(generatorShareId) {
    await deleteGenerationShare.mutateAsync(generatorShareId);
  }
  async function onSubmit(generatorShare) {
    await editGenerationShare.mutateAsync(generatorShare);
  }

  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});

  const [weightingToBeDeleted, setWeightingToBeDeleted] = useState<
    Array<GenerationShareResponse>
  >([]);
  const [currentlySelectedGeneratorId, setCurrentlySelectedGeneratorId] =
    useState<number | undefined>(undefined);
  const [
    currentlySelectedGeneratorSiteId,
    setCurrentlySelectedGeneratorSiteId
  ] = useState<number | undefined>(undefined);

  const {
    meteringOrMarketLocations: meloMalosForSelectedGenerator,
    isLoading: meloMalosForSelectedGeneratorLoading
  } = useMeteringOrMarketLocations(currentlySelectedGeneratorSiteId);

  const {
    suggestedMeteringOrMarketLocation,
    isLoading: suggestedMeteringOrMarketLocationLoading
  } = useSuggestedMeteringOrMarketLocation(currentlySelectedGeneratorId);

  const columns = useMemo<Array<MRT_ColumnDef<GenerationShareResponse>>>(
    () => [
      getGeneratorColumn<GenerationShareResponse>(
        { validationErrors, setValidationErrors, t },
        undefined,
        (generator: GenerationShareResponse) => {
          setCurrentlySelectedGeneratorId(generator.generator_id);
          setCurrentlySelectedGeneratorSiteId(siteId);
        }
      ),
      getZaehlpunktColumn<GenerationShareResponse>(
        {
          validationErrors,
          setValidationErrors,
          t
        },
        meloMalosForSelectedGenerator,
        suggestedMeteringOrMarketLocation?.id,
        undefined,
        meloMalosForSelectedGeneratorLoading ||
          suggestedMeteringOrMarketLocationLoading
      ),
      getWeightingColumn<GenerationShareResponse>(
        {
          validationErrors,
          setValidationErrors,
          t
        },
        "1"
      )
    ],
    [
      validationErrors,
      setValidationErrors,
      t,
      meloMalosForSelectedGenerator,
      suggestedMeteringOrMarketLocation,
      meloMalosForSelectedGeneratorLoading,
      suggestedMeteringOrMarketLocationLoading,
      siteId
    ]
  );

  const handleSaveGenerator: MRT_TableOptions<GenerationShareResponse>["onEditingRowSave"] =
    async function ({ values, table }) {
      await createOrEditGenerator(
        values,
        () => table.setEditingRow(null) /* exit editing mode */
      );
    };

  async function createOrEditGenerator(
    values: MRT_Row<GenerationShareResponse>["_valuesCache"],
    exitCreatingOrEditingMode: () => void
  ) {
    const newValidationErrors =
      validateDirektvermarktungGeneratorShareFormValues(values, t);

    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }

    setIsSaving(true);
    setValidationErrors({});

    const currentlyEditingGeneratorId =
      table.getState().editingRow?.original.generator_id;

    try {
      await onSubmit({
        ...values,
        generator_id: currentlyEditingGeneratorId || values.generator_id,
        metering_or_market_location_id:
          meloMalosForSelectedGenerator?.find(
            (meloMalo) =>
              meloMalo.name === values.metering_or_market_location_display_name
          )?.id ?? values.metering_or_market_location_id
      });
    } catch (error) {
      if (error.response.status === 400) {
        const validationErrors = convertApiErrorsToMrtErrors(
          error.response.data
        );

        if (Object.keys(validationErrors).length > 0) {
          setValidationErrors(validationErrors);
        } else {
          showToast("error", error);
        }

        setIsSaving(false);
        return;
      } else {
        showToast("error", error);
      }
    }

    exitCreatingOrEditingMode();
    setIsSaving(false);
    setCurrentlySelectedGeneratorId(undefined);
    setCurrentlySelectedGeneratorSiteId(undefined);
  }

  const table = useMantineReactTable<GenerationShareResponse>({
    ...getDefaultMRTOptions<GenerationShareResponse>({
      emptyRowsFallbackText: "Fügen Sie den ersten Erzeuger hinzu.",
      searchTextInput: {
        placeholder: "Suche nach Erzeugern"
      },
      hideInternalToolbar: true
    }),
    ...getDefaultMRTEditableRowOptions<GenerationShareResponse>({
      rowActions: {
        edit: {
          text: "Erzeuger bearbeiten"
        },
        delete: {
          text: "Erzeuger löschen",
          onClick: (generators) => setWeightingToBeDeleted(generators)
        }
      }
    }),
    ...getDefaultMRTTopToolbarCustomActionsOptions<GenerationShareResponse>({
      create: {
        hide: true
      },
      delete: {
        onClick: (rowSelection) => {
          console.log(rowSelection);
          setWeightingToBeDeleted(
            generationShares.filter(
              (generationShare) => rowSelection[generationShare.generator_id]
            )
          );
        }
      }
    }),
    columns,
    defaultColumn: {
      size: 300,
      minSize: 180
    },
    data: generationShares,
    enableGlobalFilter: false,
    enablePagination: false,
    enableBottomToolbar: false,
    displayColumnDefOptions: {
      "mrt-row-select": {
        visibleInShowHideMenu: false,
        size: 40,
        minSize: 40
      },
      "mrt-row-actions": {
        visibleInShowHideMenu: false,
        size: 40,
        minSize: 40
      }
    },
    initialState: {
      showGlobalFilter: true,
      sorting: DEFAULT_SORTED
    },
    mantineToolbarAlertBannerProps: error
      ? {
          color: THEME_VARS.dangerColor,
          children: getErrorText(error)
        }
      : undefined,
    state: {
      isLoading:
        isLoading ||
        meloMalosForSelectedGeneratorLoading ||
        suggestedMeteringOrMarketLocationLoading,
      isSaving: isSaving || isDeleting,
      showAlertBanner: !!error
    },
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveGenerator
  });

  const tableIsCreating = isMrtCreating(table);
  const tableEditingRow = table.getState().editingRow;

  useEffect(() => {
    if (tableIsCreating) {
      setCurrentlySelectedGeneratorSiteId(undefined);
    }
  }, [tableIsCreating]);

  useEffect(() => {
    if (tableEditingRow?.original.generator_id !== undefined) {
      setCurrentlySelectedGeneratorId(tableEditingRow?.original.generator_id);
    }
    if (tableEditingRow?.original.site_id !== undefined) {
      setCurrentlySelectedGeneratorSiteId(tableEditingRow?.original.site_id);
    }
  }, [tableEditingRow]);

  const namesOfWeightingToBeDeleted = weightingToBeDeleted.map(
    (generatorShare) => String(generatorShare.generator_display_name)
  );
  const denOrDie = namesOfWeightingToBeDeleted.length === 1 ? "den" : "die";

  return (
    <LoadOrError
      error={error}
      loading={isLoading}
      loadingMessage="Erzeugerdaten werden geladen ..."
    >
      <div className="WeightingTable">
        <MantineReactTable table={table} />
        <MultiConfirmationModal
          actionName="löschen"
          actionObjects={namesOfWeightingToBeDeleted}
          confirmationText={`Möchten Sie ${denOrDie} folgenden Erzeuger löschen? Dieser Schritt kann nicht rückgängig gemacht werden.`}
          isModalOpen={weightingToBeDeleted.length > 0}
          objectName="Erzeuger"
          toggleModal={() => setWeightingToBeDeleted([])}
          onAction={async () => {
            setIsDeleting(true);

            await Promise.all(
              weightingToBeDeleted.map((generatorShare) =>
                onDelete(generatorShare.generator_id)
              )
            );

            setWeightingToBeDeleted([]);
            table.setRowSelection({}); // MRT doesn't auto-clear the selected rows after deletion
            setIsDeleting(false);
          }}
        />
      </div>
    </LoadOrError>
  );
}

export { WeightingTab };
