import { capitalizeWord } from "../../../utils/text-utils";

export function formatMeterAssignmentErrorDetails(
  errArr: Array<Record<string, Array<string>>>
) {
  const errors = errArr.map((error) => {
    const [errorKey, errorValues] = Object.entries(error).flat(); // possible errorKey = medium, messrichtung, erfassungDerEnergiemengen and intervallDerMesswerte
    const words = (errorKey as string).split(/(?=[A-Z])/);
    const capitalizedWords = words
      .map((word, idx) =>
        idx === 1 ? word.toLowerCase() : capitalizeWord(word)
      )
      .join(" ");

    return `${capitalizedWords}: ${(errorValues as Array<string>).join(", ")}`;
  });

  return errors;
}
