import type { UseMutationResult } from "@tanstack/react-query";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import type { To } from "react-router-dom";
import { Link } from "react-router-dom";
import type { Column, SortFunction, SortingRule } from "react-table";
import api from "../../api";
import { Icon } from "../BuildingBlocks/Icon/Icon";
import { IconWithRouterLink } from "../BuildingBlocks/Icon/IconWithRouterLink/IconWithRouterLink";
import { IconName } from "../BuildingBlocks/Icon/types";
import { MultiConfirmationModal } from "../BuildingBlocks/Layout/Modals/MultiConfirmationModal/MultiConfirmationModal";
import { ActionIcon } from "../Buttons/ActionIcon";
import { Button } from "../Buttons/Button/Button";
import { DeleteIcon } from "../Buttons/DeleteIcon";
import { EditIcon } from "../Buttons/EditIcon";
import { IconButton } from "../Buttons/IconButton/IconButton";
import { SaveIcon } from "../Buttons/SaveIcon";
import {
  CustomReactSelectTable,
  CustomReactTable
} from "../CustomReactTable/CustomReactTable";
import type { TypedCellInfo } from "../CustomReactTable/CustomReactTable.types";
import { useCustomReactTableCheckboxes } from "../CustomReactTable/CustomReactTableHooks";
import { openErrorAlertPopup } from "../ErrorAlertPopup/openErrorAlertPopup";
import { SearchBar } from "../SearchBar/SearchBar";
import "./SwissArmyTable.scss";

type TableId = number | string;
type TableData = {
  id: TableId;
  name?: string;
};

export type RowState = "view" | "edit" | "loading";

interface SwissArmyTableOptions<T extends TableData> {
  buttons?: {
    create?: {
      iconButton?: boolean;
      isPrimary?: boolean;
      isTertiary?: boolean;
      path?: string | ((id: TableId) => string);
      show?: boolean;
      disabled?: boolean;
      text?: string;
      onClick?: () => void;
    };
    delete?: {
      iconButton?: boolean;
    };
    edit?: {
      path?: string | ((id: TableId) => string);
      rowStates?: Record<TableId, RowState>;
      onClick?: (rowData: T) => void;
      onClickInline?: (rowData: T) => void;
      onClickCancel?: (rowData: T) => void;
      onClickSave?: (rowData: T) => void;
    };
    view?: {
      path?: string | ((id: TableId) => string);
    };
  };
  CustomNoDataComponent?: React.ComponentType;
  deletion: {
    confirmationHeader?: string;
    confirmationText?: string;
    confirmWithText?: boolean;
    multiDelete?: boolean;
    deleteEndpoint?: (id: TableId) => string;
    deleteMutation?: UseMutationResult;
    namesToDeleteCollectionFunction?: (data: Array<T>) => Array<string>;
    onClick?: (id: TableId) => Promise<void>;
    onDataDeleted?: (ids: Array<TableId>) => void;
  };
  pluralWord: string;
  search?: {
    placeholder: string;
    show: boolean;
    filterFunction: (searchString: string, tableData: Array<T>) => void;
  };
  showPageJump?: boolean;
  showPagination?: boolean;
  singleWord: string;
}

function combineOptionsWithDefaults<T extends TableData>(
  customOptions: SwissArmyTableOptions<T>
) {
  return {
    ...customOptions,
    buttons: {
      create: {
        isPrimary: true,
        show: true,
        ...customOptions.buttons?.create
      },
      delete: {
        ...customOptions.buttons?.delete
      },
      edit: {
        ...customOptions.buttons?.edit
      },
      view: {
        ...customOptions.buttons?.view
      }
    },
    deletion: {
      confirmWithText: false,
      multiDelete: true,
      ...customOptions.deletion
    }
  } satisfies SwissArmyTableOptions<T>;
}

interface SwissArmyTableProps<T extends TableData> {
  className?: string;
  data: Array<T>;
  tableColumns: Array<Column<T>>;
  defaultSorted: Array<SortingRule>;
  options: SwissArmyTableOptions<T>;
  defaultSortMethod?: SortFunction;
}

function SwissArmyTable<T extends TableData>({
  className,
  data,
  tableColumns,
  defaultSorted,
  options: customOptions,
  defaultSortMethod
}: SwissArmyTableProps<T>) {
  const [searchedValue, setSearchedValue] = useState("");
  const [tableData, setTableData] = useState(data);
  const [dataToDelete, setDataToDelete] = useState<Array<T>>([]);
  const {
    selection,
    setSelection,
    setSelectAll,
    getSelectedData,
    customReactTableProps
  } = useCustomReactTableCheckboxes<T>();
  const options = combineOptionsWithDefaults(customOptions);

  useEffect(() => {
    const filteredData = options.search?.filterFunction(searchedValue, data);

    setTableData(filteredData ?? data);
  }, [data, searchedValue, options.search]);

  function handleSearch(searchValue: string) {
    setSearchedValue(searchValue);
  }

  function handleClear() {
    setSearchedValue("");
  }

  const toggleDeleteDataModal = () => setDataToDelete([]);

  function handleClickDeleteSelection() {
    const selectedData = getSelectedData();
    setDataToDelete(selectedData);
  }

  function handleClickDelete(idToDelete: TableId) {
    const objToDelete = tableData.find((obj) => obj.id === idToDelete);

    if (objToDelete) {
      setDataToDelete([objToDelete]);
    }
  }

  function handleDeleteSelectedData(ids: Array<TableId>): Promise<void> {
    const deletePromises = ids.map((id) => {
      if (options.deletion.deleteEndpoint)
        return api.delete<void>(options.deletion.deleteEndpoint(id));
      if (options.deletion.deleteMutation)
        return options.deletion.deleteMutation.mutateAsync(id);
      if (options.deletion.onClick) return options.deletion.onClick(id);
    });

    return Promise.all(deletePromises)
      .then(() => {
        if (options.deletion.onDataDeleted) {
          options.deletion.onDataDeleted(ids);
        }
        setSelection([]);
        setSelectAll(false);
      })
      .catch((error) => openErrorAlertPopup(error))
      .finally(() => {
        setDataToDelete([]);
      });
  }

  function getPath(
    path: string | ((id: TableId) => string) | undefined,
    id: TableId
  ) {
    return typeof path === "function" ? path(id) : path;
  }

  const tableColumnsWithControls: Array<Column> = [
    ...tableColumns,
    {
      className: "icons-cell",
      Cell: (cellProps: TypedCellInfo<never, T>) => {
        const viewPath = getPath(
          options.buttons.view.path,
          cellProps.original.id
        );
        const editPath = getPath(
          options.buttons.edit.path,
          cellProps.original.id
        );

        return (
          <div className="icons">
            {viewPath && (
              <SwissViewIcon
                to={viewPath}
                tooltipText={`${options.singleWord} sehen`}
              />
            )}
            {editPath ? (
              <EditIcon
                to={editPath}
                tooltipText={`${options.singleWord} bearbeiten`}
              />
            ) : options.buttons.edit.onClick ||
              (options.buttons.edit.onClickInline &&
                options.buttons.edit.onClickCancel &&
                options.buttons.edit.onClickSave) ? (
              <EditIcons options={options} tableData={cellProps.original} />
            ) : null}
            {(options.deletion.deleteEndpoint ||
              options.deletion.deleteMutation ||
              options.deletion.onClick) &&
              options.buttons.edit.rowStates?.[cellProps.original.id] !==
                "edit" && (
                <DeleteIcon
                  tooltipText={`${options.singleWord} löschen`}
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                    handleClickDelete(cellProps.original.id);
                  }}
                />
              )}
          </div>
        );
      },
      width:
        options.buttons.view.path && options.buttons.edit.path
          ? 90
          : options.buttons.view.path ||
              options.buttons.edit.path ||
              options.buttons.edit.onClick ||
              (options.buttons.edit.onClickInline &&
                options.buttons.edit.onClickCancel &&
                options.buttons.edit.onClickSave)
            ? 60
            : 30,
      sortable: false,
      resizable: false
    }
  ];

  const selectionButtonsDisabled = selection.length === 0;
  const dataToDeleteModalIsOpen = dataToDelete.length > 0;
  const dataToDeleteNames = options.deletion.namesToDeleteCollectionFunction
    ? options.deletion.namesToDeleteCollectionFunction(dataToDelete)
    : dataToDelete.map((obj) => obj.name || obj.id.toString());
  const dataToDeleteIds = dataToDelete.map((obj) => obj.id);
  const singleOrPluralText =
    selection.length <= 1 ? options.singleWord : options.pluralWord;

  return (
    <div className={classNames("SwissArmyTable", className)}>
      <div className="controls">
        {options.search?.show && (
          <SearchBar
            btnHidden
            className="table-search-bar"
            inputClassName="table-search-bar-input"
            placeholder={options.search.placeholder}
            onChange={(event) => handleSearch(event.currentTarget.value)}
            onClear={handleClear}
          />
        )}
        {options.buttons.create.show &&
          (typeof options.buttons.create.path === "string" ||
            options.buttons.create.onClick) &&
          (options.buttons.create.iconButton ? (
            <IconButton
              className={classNames({
                "icon-add-tertiary-button": options.buttons.create.isTertiary
              })}
              color={options.buttons.create.isTertiary ? "secondary" : "brand"}
              disabled={options.buttons.create.disabled}
              iconName={IconName.Plus}
              tag={options.buttons.create.path ? Link : undefined}
              to={options.buttons.create.path}
              onClick={options.buttons.create.onClick}
            >
              {options.buttons.create.text ??
                `${options.singleWord} hinzufügen`}
            </IconButton>
          ) : (
            <Button
              color={options.buttons.create.isPrimary ? "primary" : "brand"}
              disabled={options.buttons.create.disabled}
              tag={options.buttons.create.path ? Link : undefined}
              to={options.buttons.create.path}
              onClick={options.buttons.create.onClick}
            >
              {options.buttons.create.text ??
                `${options.singleWord} hinzufügen`}
            </Button>
          ))}
        {options.deletion.multiDelete &&
          (options.deletion.deleteEndpoint ||
            options.deletion.deleteMutation ||
            options.deletion.onClick) &&
          (options.buttons.delete.iconButton ? (
            <IconButton
              className={classNames("icon-delete-button", {
                active: !selectionButtonsDisabled
              })}
              disabled={selectionButtonsDisabled}
              iconName={IconName.Trash}
              onClick={handleClickDeleteSelection}
            >
              Löschen
            </IconButton>
          ) : (
            <Button
              color="danger"
              disabled={selectionButtonsDisabled}
              onClick={handleClickDeleteSelection}
            >
              {singleOrPluralText} löschen
            </Button>
          ))}
      </div>
      {options.deletion.multiDelete ? (
        <CustomReactSelectTable
          keyField="id"
          {...customReactTableProps}
          columns={tableColumnsWithControls}
          data={tableData}
          defaultSorted={defaultSorted}
          defaultSortMethod={defaultSortMethod}
          minRows={0}
          NoDataComponent={() =>
            options.CustomNoDataComponent ? (
              <options.CustomNoDataComponent />
            ) : (
              <NoDataComponent name={options.pluralWord} />
            )
          }
          pageSize={tableData.length}
          showPageJump={options.showPageJump}
          showPagination={options.showPagination}
        />
      ) : (
        <CustomReactTable
          columns={tableColumnsWithControls}
          data={tableData}
          defaultSorted={defaultSorted}
          defaultSortMethod={defaultSortMethod}
          minRows={0}
          NoDataComponent={() =>
            options.CustomNoDataComponent ? (
              <options.CustomNoDataComponent />
            ) : (
              <NoDataComponent name={options.pluralWord} />
            )
          }
          pageSize={tableData.length}
          showPageJump={options.showPageJump}
          showPagination={options.showPagination}
        />
      )}

      <MultiConfirmationModal
        actionName="löschen"
        actionObjects={dataToDeleteNames}
        confirmAction={options.deletion.confirmWithText}
        confirmationText={
          options.deletion.confirmationText ??
          `Möchten Sie die folgenden ${singleOrPluralText} löschen? Dieser Schritt kann nicht rückgängig gemacht werden.`
        }
        headerText={options.deletion.confirmationHeader}
        isModalOpen={dataToDeleteModalIsOpen}
        matchTextToConfirmActionText={
          <span>
            Geben Sie zur Bestätigung den Namen des {options.singleWord}s{" "}
            <mark>{dataToDeleteNames[0]}</mark> in das Textfeld ein.
          </span>
        }
        objectName={singleOrPluralText}
        toggleModal={toggleDeleteDataModal}
        onAction={() => handleDeleteSelectedData(dataToDeleteIds)}
      />
    </div>
  );
}

function SwissViewIcon({ to, tooltipText }: { to: To; tooltipText: string }) {
  return (
    <IconWithRouterLink
      name={IconName.Search}
      style={{ cursor: "pointer", marginRight: "3px" }}
      to={to}
      tooltipText={tooltipText}
    />
  );
}

function EditIcons<T extends TableData>({
  options,
  tableData
}: {
  options: SwissArmyTableOptions<T>;
  tableData: T;
}) {
  if (options.buttons?.edit?.rowStates?.[tableData.id] === "edit") {
    return (
      <>
        <SaveIcon
          tooltipText="Speichern"
          onClick={() => {
            if (options.buttons?.edit?.onClickSave) {
              options.buttons.edit.onClickSave(tableData);
            }
          }}
        />
        <ActionIcon
          iconName={IconName.Close}
          tooltipText="Abbrechen"
          onClick={() => {
            if (options.buttons?.edit?.onClickCancel) {
              options.buttons.edit.onClickCancel(tableData);
            }
          }}
        />
      </>
    );
  }

  if (options.buttons?.edit?.rowStates?.[tableData.id] === "loading") {
    return <Icon name={IconName.SpinnerSpinning} />;
  }

  return (
    <EditIcon
      tooltipText="Bearbeiten"
      onClick={() => {
        if (options.buttons?.edit?.onClick) {
          options.buttons.edit.onClick(tableData);
        }
        if (options.buttons?.edit?.onClickInline) {
          options.buttons.edit.onClickInline(tableData);
        }
      }}
    />
  );
}

function NoDataComponent({ name }: { name: string }) {
  return (
    <div className="no-data-component">
      <p>Es gibt noch keine {name}.</p>
    </div>
  );
}

export { SwissArmyTable, SwissArmyTableOptions };
