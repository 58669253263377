import { useQuery } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import urls from "../../../urls";
import type { Paragraph6FinancialDocument } from "../Paragraph6.types";
import { FinancialDocumentType } from "../Paragraph6.types";

export function useParagraph6Cancellations(variantId: number) {
  const {
    data: cancellations,
    isLoading,
    error
  } = useQuery({
    queryKey: ["paragraph6Credits", { variantId }, "paragraph6Cancellations"],
    queryFn: () => fetchParagraph6Cancellations(variantId)
  });

  async function fetchParagraph6Cancellations(variantId: number) {
    const url = urls.apiWithoutCamelization.paragraph6Cancellations(variantId);
    const response =
      await apiWithoutCamelization.get<Array<Paragraph6FinancialDocument>>(url);

    return response.data.map((doc) => {
      // The backend does not supply this, so add it here.
      doc.type = FinancialDocumentType.Cancellation;
      return doc;
    });
  }

  return {
    cancellations,
    isLoading,
    error
  };
}
