import type { DataValue } from "../../../DataSheet/DataSheet";
import type { FormFieldValue } from "../../../DynamicForm/FormItems/FormField/FormField";

export enum FormAction {
  SetForms = "set_forms",
  UpdateCustomComponentData = "update_values",
  UpdateValue = "update_value"
}

export type ComponentEditWizardDispatchType =
  | {
      type: FormAction.UpdateValue;
      payload: {
        formIndex: number;
        sectionIndex: number;
        valueKeyValuePair: Record<string, FormFieldValue>;
      };
    }
  | {
      type: FormAction.UpdateCustomComponentData;
      payload: {
        formIndex: number;
        data: Record<number, Record<string, DataValue>>;
      };
    };
