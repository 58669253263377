import { YearPickerInput } from "@mantine/dates";
import React, { useEffect, useState } from "react";
import { useStartPageTiles } from "../../hooks/startPage/useStartPageTiles";
import { useCurrentUserQuery } from "../../hooks/useCurrentUserQuery";
import { useProjects } from "../../hooks/useProjects";
import { useSessionStorage } from "../../hooks/useSessionStorage";
import { Icon } from "../BuildingBlocks/Icon/Icon";
import { IconName } from "../BuildingBlocks/Icon/types";
import { Row } from "../BuildingBlocks/Layout/Row";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { MySitesInNumbers } from "./MySitesInNumbers/MySitesInNumbers";
import { MySitesManagement } from "./MySitesManagement/MySitesManagement";
import { ProjectSelection } from "./ProjectSelection";
import "./StartPage.scss";
import { StartPageSection } from "./StartPageSection";

declare const SITE_NAME: string;

function StartPage() {
  const urlParams = new URLSearchParams(window.location.search);
  const urlProjectId = urlParams.get("projectId");

  const [storedProjectId, setStoredProjectId] = useSessionStorage<
    string | undefined
  >("startPageProjectId", undefined);
  const [storedYear, setStoredYear] = useSessionStorage<number | undefined>(
    "startPageYear",
    undefined
  );

  const {
    data: projects,
    error: projectsError,
    isLoading: projectsLoading
  } = useProjects();

  const currentYear = new Date().getFullYear();

  const [projectId, setProjectId] = useState<string | undefined>(
    urlProjectId || storedProjectId || undefined
  );
  const [year, setYear] = useState<number>(storedYear || currentYear);

  const {
    tiles,
    error: tilesError,
    isLoading: tilesLoading
  } = useStartPageTiles(projectId);

  const {
    currentUser,
    isLoading: userLoading,
    error: userError
  } = useCurrentUserQuery();

  useEffect(() => {
    // need to set this here because there should be no breadcrumb (which usually sets the title)
    document.title = `Startseite - ${SITE_NAME}`;
  }, []);

  useEffect(() => {
    if (urlProjectId) {
      setProjectId(urlProjectId);
      setStoredProjectId(urlProjectId);
    } else if (storedProjectId) {
      setProjectId(storedProjectId);
      const newUrlParams = new URLSearchParams(window.location.search);
      newUrlParams.set("projectId", storedProjectId);
      window.history.replaceState(null, "", "?" + newUrlParams.toString());
    }
  }, [urlProjectId, storedProjectId, setStoredProjectId]);

  function handleChangeProject(newProjectId?: string) {
    setProjectId(newProjectId);
    setStoredProjectId(newProjectId);

    const newUrlParams = new URLSearchParams(window.location.search);
    if (newProjectId) {
      newUrlParams.set("projectId", newProjectId);
    } else {
      newUrlParams.delete("projectId");
    }
    window.history.replaceState(null, "", "?" + newUrlParams.toString());
  }

  function handleChangeYear(newYearDate: Date) {
    setYear(newYearDate.getFullYear());
    setStoredYear(newYearDate.getFullYear());
  }

  const allProjectsChoice = { id: undefined, name: "Alle Projekte" };

  const yearDate = new Date(year, 0, 1);
  const maxDate = new Date(currentYear, 11, 31);

  return (
    <div className="StartPage">
      <LoadOrError
        error={userError || projectsError}
        loading={userLoading || projectsLoading}
      >
        <Row className="heading-row">
          <h3>
            {currentUser
              ? "Willkommen, " + currentUser.name + "!"
              : "Willkommen!"}
          </h3>
          <ProjectSelection
            projectId={projectId}
            projects={
              projects ? [allProjectsChoice, ...projects] : [allProjectsChoice]
            }
            onChange={handleChangeProject}
          />
        </Row>
        <LoadOrError error={tilesError} loading={tilesLoading}>
          {tiles && (
            <>
              <StartPageSection
                className="MySitesInNumbersSection"
                filter={
                  <YearPickerInput
                    leftSection={<Icon name={IconName.Calendar} />}
                    maxDate={maxDate}
                    size="md"
                    value={yearDate}
                    onChange={handleChangeYear}
                  />
                }
                title="Meine Liegenschaften in Zahlen"
              >
                <MySitesInNumbers
                  projectId={projectId}
                  tiles={tiles.filter((tile) => tile.size === "small")}
                  year={year}
                />
              </StartPageSection>
              <StartPageSection
                className="MySitesManagementSection"
                title="Verwaltung meiner Liegenschaften"
              >
                <MySitesManagement
                  projectId={projectId}
                  tiles={tiles.filter((tile) => tile.size === "regular")}
                />
              </StartPageSection>
            </>
          )}
        </LoadOrError>
      </LoadOrError>
    </div>
  );
}

export { StartPage };
