import { useQuery } from "@tanstack/react-query";
import api from "../../../api";
import urls from "../../../urls";
import { queryWithPollResponse } from "../../../utils/api-utils";
import type {
  GuaranteeOfOriginCheckResponse,
  GuaranteeOfOriginPeriod
} from "../GuaranteeOfOrigin.types";

type CheckReturnValue = {
  result: Array<GuaranteeOfOriginCheckResponse>;
  loading: boolean;
  error: unknown;
};

export function useGOCheck(
  periods: Array<GuaranteeOfOriginPeriod>
): CheckReturnValue {
  const {
    isLoading: loading,
    error,
    data: result
  } = useQuery({
    queryKey: ["go-check", { periods }],
    queryFn: () => fetchGuaranteeOfOriginCheck(periods),
    enabled: periods.length > 0,
    refetchInterval: false,
    refetchOnWindowFocus: true
  });

  async function fetchGuaranteeOfOriginCheck(
    periods: Array<GuaranteeOfOriginPeriod>
  ) {
    const taskResponse = await queryWithPollResponse(() =>
      api.post(urls.api.dispatchGuaranteeOfOriginCheck(), periods)
    );
    const taskIds = taskResponse.taskIds ?? [];
    const promises = taskIds.map((taskId) =>
      api.get<GuaranteeOfOriginCheckResponse>(
        urls.api.fetchGuaranteeOfOriginCheck(taskId)
      )
    );
    return Promise.all(promises)
      .then((results) => results.map((r) => r.data))
      .catch((error) => Promise.reject(error));
  }

  return {
    result: result || [],
    loading: loading,
    error: error
  };
}
