import { Box, Flex, Group, Highlight, Stack } from "@mantine/core";
import React from "react";
import "./SiteWithSetupProcess.scss";
import { Link } from "react-router-dom";
import { SiteWithSetupInformationStatus } from "../../../../../types/api.types";
import { DeleteIcon } from "../../../../Buttons/DeleteIcon";
import { EditIcon } from "../../../../Buttons/EditIcon";
import { PremiumBadge } from "../../../../PremiumBadge/PremiumBadge";
import { useShouldShowStaffView } from "../../../../StaffViewToggle/useShouldShowStaffView";
import type { ProjectOverviewSite } from "../../../MsmProjectView.types";
import { ContinueEditingButton } from "./ContinueEditingButton";
import { DisplayStatus } from "./DisplayStatus";

interface SiteWithSetupProcessProps {
  site: ProjectOverviewSite;
  isPremium: boolean;
  onDelete: (site: ProjectOverviewSite) => void;
  onClickEdit: (site: ProjectOverviewSite) => void;
  highlight: string;
}

function SiteWithSetupProcess({
  site,
  isPremium,
  onDelete,
  onClickEdit,
  highlight
}: SiteWithSetupProcessProps) {
  const shouldShowStaffView = useShouldShowStaffView();
  const siteHasSetupProcess =
    typeof site.status !== "undefined" && site.status !== null;
  const siteWithCompletedSetupProcess =
    site.status === SiteWithSetupInformationStatus.Completed;
  const path =
    siteWithCompletedSetupProcess || !siteHasSetupProcess
      ? `./liegenschaften/${site.id}`
      : `./${site.id}/liegenschaftskonfigurator/${site.site_setup_process_id}`;

  return (
    <Box className="SiteWithSetupProcess">
      <Stack gap="lg">
        <Group gap="lg" justify="space-between">
          <Group>
            <a href={path}>
              <Highlight fw={500} highlight={highlight}>
                {site.name}
              </Highlight>
            </a>
            {isPremium && <PremiumBadge className="premium" />}
            <DisplayStatus status={site.status} />
          </Group>
          {siteHasSetupProcess && shouldShowStaffView && (
            <DeleteIcon
              tooltipText="Liegenschaft löschen"
              onClick={() => onDelete(site)}
            />
          )}
          {(site.status === SiteWithSetupInformationStatus.Completed ||
            !siteHasSetupProcess) && (
            <Group gap="xs">
              <EditIcon
                tooltipText="Liegenschaft bearbeiten"
                onClick={() => onClickEdit(site)}
              />
              {shouldShowStaffView && (
                <DeleteIcon
                  tooltipText="Liegenschaft löschen"
                  onClick={() => onDelete(site)}
                />
              )}
            </Group>
          )}
        </Group>
        {site.status !== SiteWithSetupInformationStatus.Completed &&
          siteHasSetupProcess && (
            <Flex justify="flex-end">
              <Link to={path}>
                <ContinueEditingButton status={site.status} />
              </Link>
            </Flex>
          )}
      </Stack>
    </Box>
  );
}

export { SiteWithSetupProcess, SiteWithSetupProcessProps };
