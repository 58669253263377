import type { Person } from "../../../../utils/backend-types";
import type { DrittlieferungPrice } from "../../ParkabrechnungContext";
import type { ParkabrechnungTag } from "../ParkabrechnungData/ParkabrechnungData";
import { PARKABRECHNUNG_TAGS } from "../ParkabrechnungData/ParkabrechnungData";
import type { ParkabrechnungDataResponse } from "../ParkabrechnungTable/ParkabrechnungTable";

export function mapEdaResponseToTableData({
  energyDataDisplayDataResponse,
  abrechnungType,

  companies,
  drittlieferungPrices,
  selectedCompanyId
}: {
  energyDataDisplayDataResponse?: ParkabrechnungDataResponse;
  abrechnungType: ParkabrechnungTag;
  selectedCompanyId?: number;
  companies?: Person[];
  drittlieferungPrices?: DrittlieferungPrice[];
}) {
  return energyDataDisplayDataResponse?.serieses.ids.map((id) => {
    switch (abrechnungType) {
      case PARKABRECHNUNG_TAGS.netzbezug:
        return {
          bezeichnung: energyDataDisplayDataResponse.serieses.header[id][0],
          energiemenge: energyDataDisplayDataResponse?.summary.find(
            (s) => s.energyDataAcquisition.id === id
          )?.energy
        };

      default: {
        const eda = energyDataDisplayDataResponse?.summary.find(
          (s) => s.energyDataAcquisition.id === id
        );
        const energiemenge = eda?.energy;
        let lieferant = "";
        let belieferte: {
          id: number;
          name: string;
          priceCtPerKwh?: number;
        } | null = null;

        if (
          eda?.energyDataAcquisition.identity.type ===
          "ThirdPartyDeliveryFromCompanyToCompany"
        ) {
          const { toCompany } = eda.energyDataAcquisition.identity;

          lieferant =
            companies?.find((company) => company.id === selectedCompanyId)
              ?.name ?? "";

          const companyId = companies?.find(
            (company) => company.id === toCompany
          )?.id;
          const companyName = companies?.find(
            (company) => company.id === toCompany
          )?.name;
          const priceCtPerKwh = companyId
            ? drittlieferungPrices?.find((price) =>
                price.recipientIds?.includes(companyId)
              )?.priceCtPerKwh
            : undefined;
          belieferte =
            companyId && companyName
              ? {
                  id: companyId,
                  priceCtPerKwh,
                  name: companyName
                }
              : null;
        }

        return {
          energiemenge,
          lieferant,
          belieferte
        };
      }
    }
  });
}
