import saveAs from "file-saver";
import { type ApiResponse } from "../../api";
import { b64toBlob } from "./b64toBlob";
import { extractFilenameFromContentDispositionHeader } from "./extractFilenameFromContentDispositionHeader";

/** Downloads a CSV or Excel file according to the "content-disposition" header from the response. */
export function downloadCsvOrExcelFileForResponseWithContentDisposition(
  response: ApiResponse,
  convertFromBase64 = true
) {
  const filename = extractFilenameFromContentDispositionHeader(response);
  const contentType = response.headers["content-type"];
  const blob = convertFromBase64
    ? b64toBlob(response.data, contentType)
    : new Blob([response.data], { type: contentType });

  saveAs(blob, filename);
}
