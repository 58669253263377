import { Overlay, Stack, Text, Title } from "@mantine/core";
import React from "react";
import "./NoChartDataWrapper.scss";

interface NoChartDataWrapperProps {
  children: React.ReactNode;
  noData: boolean;
}

function NoChartDataWrapper({ children, noData }: NoChartDataWrapperProps) {
  return (
    <div className="NoChartDataWrapper">
      {children}
      {noData && (
        <Overlay backgroundOpacity={0}>
          <Stack align="center" h="100%" justify="center">
            <Title c="dimmed" order={5}>
              Leider konnten wir für Ihre aktuellen Auswahl keine Daten finden.
            </Title>
            <Text c="dimmed">Bitte treffen Sie eine andere Filterauswahl.</Text>
          </Stack>
        </Overlay>
      )}
    </div>
  );
}

export { NoChartDataWrapper, NoChartDataWrapperProps };
