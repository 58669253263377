import { DateTime } from "luxon";
import React from "react";
import { Link, generatePath } from "react-router-dom";
import type { Column } from "react-table";

import { ROUTES } from "../../../../../routes";
import urls, { OptiSupportEndpoints } from "../../../../../urls";
import type { Meter } from "../../../../../utils/backend-types";
import { AnchorLink } from "../../../../AnchorLink/AnchorLink";
import { Icon } from "../../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../../BuildingBlocks/Icon/types";
import { Portlet } from "../../../../BuildingBlocks/Layout/Portlet";
import { PortletTitleWithStatusSymbol } from "../../../../BuildingBlocks/Layout/PortletTitleWithStatusSymbol/PortletTitleWithStatusSymbol";
import { CustomReactTable } from "../../../../CustomReactTable/CustomReactTable";
import { IconHelpText } from "../../../../IconHelpText/IconHelpText";
import {
  StatusSymbol,
  StatusSymbolColour
} from "../../../../Icons/StatusSymbol";
import { LoadOrError } from "../../../../LoadOrError/LoadOrError";
import { ObservationPeriodCell } from "../../../../Todos/TodoTable/Cells/ObservationPeriodCell";
import { TABS } from "../../EnergyDataView";
import { useFetchDataSufficiencyAssessment } from "../../hooks/useFetchDataSufficiencyAssessment";

import "./AvailabilityWidget.scss";
import { NoScada } from "../NoScada/NoScada";

const SUFFICIENT_TEXT = "ausreichend";
const NOT_SUFFICIENT_TEXT = "nicht ausreichend";

export enum DataSufficencyResponseType {
  WindDataSufficiencyResponse = "WindDataSufficiencyResponse",
  MeterBasedDataSufficiencyResponse = "MeterBasedDataSufficiencyResponse",
  NoDataSufficiencyResponse = "NoDataSufficiencyResponse"
}

export interface WindDataSufficiencyResponse {
  dataIsSufficient: boolean;
  generators: Array<GeneratorDataSufficiency>;
  gridMeter: GridMeterData;
  siteName: string;
  siteResult: SiteResultData;
  thresholds: ThresholdData;
  year: number;
  kind: DataSufficencyResponseType.WindDataSufficiencyResponse;
}

export interface MeterBasedDataSufficiencyResponse {
  dataIsSufficient: boolean;
  siteName: string;
  siteId: number;
  configurationComplete: boolean;
  meters: Array<MeterData>;
  year: number;
  kind: DataSufficencyResponseType.MeterBasedDataSufficiencyResponse;
}

export interface NoDataSufficiencyResponse {
  reason: string;
  message: string;
  kind: DataSufficencyResponseType.NoDataSufficiencyResponse;
}

interface GeneratorDataSufficiency {
  percentageOfExistingGenerationFeedinData: number;
  percentageOfExistingConsumptionFeedoutData: number;
  dataIsSufficient: boolean;
  isInRelevantGroup: boolean;
  metaData: {
    name: string;
    person: string;
  };
}

interface MeterData {
  percentageOfExistingGenerationFeedinData: number;
  percentageOfExistingConsumptionFeedoutData: number;
  dataIsSufficient: boolean;
  metaData: {
    id: number;
    name: string;
    number: string | null;
    meteringLocation: string | null;
    marketLocationFeedin: string | null;
    marketLocationFeedout: string | null;
  };
  observationPeriod: [string, string];
}

type GridMeterData = Omit<MeterData, "observationPeriod">;

interface SiteResultData {
  allRootMetersHaveCompleteData: boolean;
  relevantGroupsHaveCompleteConsumptionData: boolean;
  relevantGroupsHaveCompleteGenerationData: boolean;
  allWeaHaveCompleteGenerationData: boolean;
}

interface ThresholdData {
  necessaryShareOfExistingGridMeterData: number;
  necessaryShareOfExistingGeneratorData: number;
}

interface AssessmentTableDataNVP {
  name: "NVP";
  meter: Pick<Meter, "id" | "name">;
  meteringOrMarketLocation: string;
  availabilityFeedin: number;
  availabilityFeedout: number;
  sufficient: boolean;
}

interface AssessmentTableDataGeneratorDataSufficiency {
  generator: string;
  company: string;
  isInRelevantGroup: boolean;
  availabilityFeedin: number;
  availabilityFeedout: number;
  sufficient: boolean;
}

interface AvailabilityWidgetProps {
  siteName: string;
  siteId: number;
  projectId?: string;
  year: number;
  toggleInitial: boolean;
}

function AvailabilityWidget({
  siteId,
  siteName,
  projectId,
  year,
  toggleInitial
}: AvailabilityWidgetProps) {
  const { isLoading, error, data } = useFetchDataSufficiencyAssessment(
    siteId,
    year
  );

  function renderResponse() {
    if (data) {
      if (
        data.kind === DataSufficencyResponseType.WindDataSufficiencyResponse
      ) {
        return (
          <WindAvailabilityWidget
            data={data}
            projectId={projectId}
            siteId={siteId}
            year={year}
          />
        );
      } else if (
        data.kind ===
        DataSufficencyResponseType.MeterBasedDataSufficiencyResponse
      ) {
        return <MeterBasedAvailabilityWidget data={data} year={year} />;
      } else {
        return <p>{data.message}</p>;
      }
    } else {
      return <p>Es konnte keine Daten Geladen werden.</p>;
    }
  }

  const showStatusSymbolInTitle =
    data?.kind === DataSufficencyResponseType.WindDataSufficiencyResponse ||
    data?.kind === DataSufficencyResponseType.MeterBasedDataSufficiencyResponse;

  return (
    <Portlet
      className="AvailabilityWidget"
      title={
        <PortletTitleWithStatusSymbol
          isGreen={showStatusSymbolInTitle && data?.dataIsSufficient}
          isLoading={isLoading}
          isRed={showStatusSymbolInTitle && !data?.dataIsSufficient}
          title={siteName}
        />
      }
      toggle
      toggleIconClosed={IconName.AngleRight}
      toggleIconOpen={IconName.AngleDown}
      toggleInitial={toggleInitial}
    >
      <LoadOrError error={error} loading={isLoading}>
        {renderResponse()}
      </LoadOrError>
    </Portlet>
  );
}

interface WindAvailabilityWidgetProps {
  data: WindDataSufficiencyResponse;
  year: number;
  siteId: number;
  projectId?: string;
}

function WindAvailabilityWidget({
  data,
  year,
  siteId,
  projectId
}: WindAvailabilityWidgetProps) {
  return (
    <>
      <h6>Verfügbarkeit der Daten am Netzverknüpfungspunkt</h6>
      <p>
        Für die Abrechnung werden die Daten des Messstellenbetreibers am
        Netzverknüpfungspunkt mit einer Verfügbarkeit ab{" "}
        {data.thresholds.necessaryShareOfExistingGridMeterData}% für Einspeisung
        UND Entnahme benötigt.
      </p>
      <NvpTable
        gridMeterData={data.gridMeter}
        projectId={projectId}
        siteId={siteId}
        year={year}
      />
      <h6>Verfügbarkeit der Anlagendaten</h6>
      <p>
        Bitte stellen Sie für folgende Anlagen SCADA-Daten mit einer
        Verfügbarkeit von mindestens{" "}
        {data.thresholds.necessaryShareOfExistingGeneratorData}% bereit.
        Alternativ kann bei Drittanlagen im Park über Gruppenzähler eine
        ausreichende Datenverfügbarkeit erreicht werden. Weiterführende
        Informationen zu den Anforderungen an verfügbare Messdaten finden Sie in
        unserem{" "}
        <AnchorLink
          href={urls.optiSupport(OptiSupportEndpoints.EnergiedatenAusreichend)}
        >
          Hilfeportal
        </AnchorLink>
        .
      </p>
      <NoScada siteId={siteId} />
      <GeneratorDataTable
        generatorDataSufficiencies={data.generators}
        necessaryShareOfExistingGeneratorData={
          data.thresholds.necessaryShareOfExistingGeneratorData
        }
        year={year}
      />
    </>
  );
}

interface NonWindAvailabilityWidgetProps {
  data: MeterBasedDataSufficiencyResponse;
  year: number;
}

function MeterBasedAvailabilityWidget({
  data,
  year
}: NonWindAvailabilityWidgetProps) {
  function renderConfigurationDisclaimer() {
    if (!data.configurationComplete) {
      return (
        <p>
          Eine abschließende Bewertung der Daten ist erst möglich, wenn die
          Aufgabe &quot;Konfiguration prüfen&quot; abgeschlossen wurde
        </p>
      );
    }
  }

  return (
    <>
      {renderConfigurationDisclaimer()}
      <h6>Verfügbarkeit Marktkommunikationszähler</h6>
      <MeterTable
        meters={data.meters}
        showMarketColumn={true}
        siteId={data.siteId}
        year={year}
      ></MeterTable>
      <h6>Verfügbarkeit Unterzähler</h6>
      <MeterTable
        meters={data.meters}
        showMarketColumn={false}
        siteId={data.siteId}
        year={year}
      ></MeterTable>
    </>
  );
}

interface PercentageCellProps {
  percentage: number;
}

function PercentageCell({ percentage }: PercentageCellProps) {
  return <span>{percentage !== null ? percentage + "%" : "-"}</span>;
}

interface MeterTableProps {
  siteId: number;
  meters: Array<MeterData>;
  showMarketColumn: boolean;
  year: number;
}

function MeterTable({
  siteId,
  meters,
  showMarketColumn,
  year
}: MeterTableProps) {
  const tableData = meters
    .map((meter) => {
      return {
        id: meter.metaData.id,
        name: meter.metaData.name,
        number: meter.metaData.number,
        meLoOrMaLo: getMeteringOrMarketLocation(
          meter.metaData.meteringLocation,
          meter.metaData.marketLocationFeedin,
          meter.metaData.marketLocationFeedout
        ),
        availabilityFeedin: meter.percentageOfExistingGenerationFeedinData,
        availabilityFeedout: meter.percentageOfExistingConsumptionFeedoutData,
        sufficient: meter.dataIsSufficient,
        observationPeriod: [
          DateTime.fromISO(meter.observationPeriod[0]),
          DateTime.fromISO(meter.observationPeriod[1])
        ]
      };
    })
    .filter((row) => !!row.meLoOrMaLo === showMarketColumn);

  const tableColumns: Array<Column> = [
    {
      Header: "Name",
      accessor: "name",
      Cell: (cellData) => (
        <Link
          to={`../liegenschaften/${siteId}/zaehler/${cellData.original.id}`}
        >
          {cellData.value}
        </Link>
      )
    },
    showMarketColumn
      ? {
          Header: "MeLo - MaLo",
          accessor: "meLoOrMaLo"
        }
      : {
          Header: "Zählernummer",
          accessor: "number"
        },
    {
      Header: "Einspeisung",
      accessor: "availabilityFeedin",
      Cell: (cellData) => <PercentageCell percentage={cellData.value} />,
      width: 150,
      style: { textAlign: "center" }
    },
    {
      Header: "Entnahme",
      accessor: "availabilityFeedout",
      Cell: (cellData) => <PercentageCell percentage={cellData.value} />,
      width: 150,
      style: { textAlign: "center" }
    },
    {
      Header: `Ausreichend für Meldungen ${year}?`,
      accessor: "sufficient",
      Cell: (cellData) => <SufficiencyCell sufficient={cellData.value} />,
      minWidth: 270
    },
    {
      Header: `Betrachtungszeitraum`,
      accessor: "observationPeriod",
      Cell: (cellData) => <ObservationPeriodCell period={cellData.value} />,
      minWidth: 150
    }
  ];

  return (
    <div className="MeterTable">
      <CustomReactTable
        columns={tableColumns}
        data={tableData}
        minRows={0}
        NoDataComponent={() => (
          <p className="text-center mt-3">Keine Zähler vorhanden.</p>
        )}
        pageSize={tableData.length}
      />
    </div>
  );
}

function getMeteringOrMarketLocation(
  meteringLocation: string | null,
  marketLocationFeedin: string | null,
  marketLocationFeedout: string | null
) {
  const locations: Array<string> = [];

  if (marketLocationFeedin) {
    locations.push(marketLocationFeedin);
  }

  if (marketLocationFeedout) {
    locations.push(marketLocationFeedout);
  }

  if (meteringLocation) {
    locations.push(meteringLocation);
  }

  return locations.join(", ");
}

interface NvpTableProps {
  gridMeterData: GridMeterData;
  year: number;
  siteId: number;
  projectId?: string;
}

function NvpTable({ gridMeterData, year, siteId, projectId }: NvpTableProps) {
  const tableData: [AssessmentTableDataNVP] = [
    {
      availabilityFeedin:
        gridMeterData.percentageOfExistingGenerationFeedinData,
      availabilityFeedout:
        gridMeterData.percentageOfExistingConsumptionFeedoutData,
      name: "NVP",
      meter: {
        id: gridMeterData.metaData.id,
        name: gridMeterData.metaData.name
      },
      meteringOrMarketLocation: getMeteringOrMarketLocation(
        gridMeterData.metaData.meteringLocation,
        gridMeterData.metaData.marketLocationFeedin,
        gridMeterData.metaData.marketLocationFeedout
      ),
      sufficient: gridMeterData.dataIsSufficient
    }
  ];

  const tableColumns: Array<Column<AssessmentTableDataNVP>> = [
    {
      Header: "Netzverknüpfungspunkt",
      accessor: "name"
    },
    {
      Header: "Zähler",
      accessor: "meter",
      Cell: (cellData) => {
        const { id, name } = cellData.value;
        return (
          <MeterCell
            id={id}
            name={name}
            projectId={projectId}
            siteId={siteId}
          />
        );
      }
    },
    {
      Header: "MeLo - MaLo",
      accessor: "meteringOrMarketLocation"
    },
    {
      Header: "Einspeisung",
      accessor: "availabilityFeedin",
      Cell: (cellData) => <PercentageCell percentage={cellData.value} />,
      width: 150,
      style: { textAlign: "center" }
    },
    {
      Header: "Entnahme",
      accessor: "availabilityFeedout",
      Cell: (cellData) => <PercentageCell percentage={cellData.value} />,
      width: 150,
      style: { textAlign: "center" }
    },
    {
      Header: `Ausreichend für Meldungen ${year}?`,
      accessor: "sufficient",
      Cell: (cellData) => <SufficiencyCell sufficient={cellData.value} />,
      width: 270
    }
  ];

  return (
    <div className="NvpTable">
      <CustomReactTable
        columns={tableColumns}
        data={tableData}
        minRows={0}
        pageSize={tableData.length}
      />
    </div>
  );
}

interface GeneratorDataTableProps {
  generatorDataSufficiencies: Array<GeneratorDataSufficiency>;
  year: number;
  necessaryShareOfExistingGeneratorData: number;
}

function GeneratorDataTable({
  generatorDataSufficiencies,
  year,
  necessaryShareOfExistingGeneratorData
}: GeneratorDataTableProps) {
  const tableData =
    generatorDataSufficiencies.map<AssessmentTableDataGeneratorDataSufficiency>(
      (generatorData) => {
        return {
          availabilityFeedin:
            generatorData.percentageOfExistingGenerationFeedinData,
          availabilityFeedout:
            generatorData.percentageOfExistingConsumptionFeedoutData,
          isInRelevantGroup: generatorData.isInRelevantGroup,
          company: generatorData.metaData.person,
          generator: generatorData.metaData.name,
          sufficient: generatorData.dataIsSufficient
        };
      }
    );

  const tableColumns: Array<
    Column<AssessmentTableDataGeneratorDataSufficiency>
  > = [
    {
      Header: "Erzeugungsanlage",
      accessor: "generator"
    },
    {
      Header: "Unternehmen",
      accessor: "company"
    },
    {
      Header: "Erzeugung",
      accessor: "availabilityFeedin",
      Cell: (cellData) => <PercentageCell percentage={cellData.value} />,
      width: 150,
      style: { textAlign: "center" }
    },
    {
      Header: "Verbrauch",
      accessor: "availabilityFeedout",
      Cell: (cellData) => <PercentageCell percentage={cellData.value} />,
      width: 150,
      style: { textAlign: "center" }
    },
    {
      Header: `Ausreichend für Meldungen ${year}?`,
      accessor: "sufficient",
      Cell: (cellData) => (
        <SufficiencyCell
          dataMeetsThreshold={
            cellData.original.availabilityFeedin >=
              necessaryShareOfExistingGeneratorData &&
            cellData.original.availabilityFeedout >=
              necessaryShareOfExistingGeneratorData
          }
          showThresholdInfo
          sufficient={cellData.value}
        />
      ),
      width: 270
    }
  ];

  return (
    <div className="GeneratorDataTable">
      <CustomReactTable
        columns={tableColumns}
        data={tableData}
        minRows={0}
        NoDataComponent={() => (
          <p className="text-center mt-3">Kein Anlagenzähler vorhanden.</p>
        )}
        pageSize={tableData.length}
      />
    </div>
  );
}

interface SufficiencyCellProps {
  sufficient: boolean;
  dataMeetsThreshold?: boolean;
  showThresholdInfo?: boolean;
}

function SufficiencyCell({
  sufficient,
  dataMeetsThreshold,
  showThresholdInfo
}: SufficiencyCellProps) {
  const sufficiencyText = sufficient ? SUFFICIENT_TEXT : NOT_SUFFICIENT_TEXT;

  return (
    <span>
      <StatusSymbol
        colour={sufficient ? StatusSymbolColour.Green : StatusSymbolColour.Red}
      />{" "}
      {sufficiencyText}
      {showThresholdInfo && sufficient && !dataMeetsThreshold && (
        <SufficientButBelowThresholdSymbol />
      )}
    </span>
  );
}

interface MeterCellProps {
  id: number;
  name: string;
  siteId: number;
  projectId?: string;
}

function MeterCell({ id, name, siteId, projectId }: MeterCellProps) {
  return (
    <div className="MeterCellContainer">
      <span className="MeterName">{name}</span>

      <Link
        to={{
          pathname: generatePath(
            `${ROUTES.energyData}/${TABS.TAB_RAW_ENERGY_DATA}`,
            { projectId: projectId ?? null }
          ),
          search: `?meterId=${id}&siteId=${siteId}`
        }}
      >
        <Icon name={IconName.AreaChart} />
      </Link>
    </div>
  );
}

function SufficientButBelowThresholdSymbol() {
  return (
    <span>
      {" "}
      <IconHelpText
        helpText="Die Datenverfügbarkeit ist für diese Anlage ausreichend, obwohl die Anlage selbst nicht über ausreichende Energiedaten verfügt. Die fehlenden Werte werden über die Energiedaten des Netzverknüpfungspunkts oder über geeichte Unterzähler abgeschätzt."
        icon={IconName.InfoCircle}
      />
    </span>
  );
}

export { AvailabilityWidget, useFetchDataSufficiencyAssessment };
