import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";

import { MetronicBreadcrumbWrapper } from "./MetronicBreadcrumbsWrapper/MetronicBreadcrumbWrapper";
import { PageTitle } from "./PageTitle/PageTitle";
import { ProjectListBreadcrumb } from "./ProjectListBreadcrumb/ProjectListBreadcrumb";
import { SiteBreadcrumbs } from "./SiteBreadcrumbs/SiteBreadcrumbs";
import { VariantBreadcrumbs } from "./VariantBreadcrumbs/VariantBreadcrumbs";

import "./Breadcrumbs.scss";

function Breadcrumbs() {
  return (
    <MetronicBreadcrumbWrapper className="Breadcrumbs">
      <Routes>
        <Route path="/">
          <Route element={<PageTitle title="Modulauswahl" />} index />
          <Route path="analyzer">
            <Route path="projekte">
              <Route element={<PageTitle title="Projekte" />} index />
            </Route>
          </Route>
          <Route path="projekte">
            <Route
              element={
                <ProjectListBreadcrumb
                  listPath="/analyzer/projekte"
                  projectPath="/analyzer/projekte/?erweiterteVarianten=:projectId"
                />
              }
              path=":projectId"
            >
              <Route
                element={<VariantBreadcrumbs />}
                path="varianten/:variantId"
              >
                <Route
                  element={<PageTitle title="Konfiguration" />}
                  path="analyzer/*"
                />
                <Route
                  element={<PageTitle title="Weitere Daten" />}
                  path="bewertung/:evaluationId"
                />
                <Route
                  element={<PageTitle title="Analyse" />}
                  path="analyse/*"
                />
                <Route
                  element={<PageTitle title="Variantenvergleich" />}
                  path="variantenvergleich/*"
                />
                <Route
                  element={<PageTitle title="Ergebnisreport" />}
                  path="ergebnisreport/*"
                />
                <Route path="*" />
              </Route>
              <Route
                element={<PageTitle title="Lastprofil-Manager" />}
                path="lastprofil-manager/*"
              />
              <Route
                element={<PageTitle title="Simulation" />}
                path="simulation/:simulationId"
              />
              <Route path="*" />
            </Route>
          </Route>
          <Route path="manager">
            <Route path="projekte">
              <Route element={<PageTitle title="Dashboard" />} index />
              <Route
                element={
                  <ProjectListBreadcrumb
                    isManagerPage
                    listPath="/manager/projekte"
                    projectPath="/manager/projekte/:projectId"
                  />
                }
                path=":projectId"
              >
                <Route
                  element={<PageTitle title="Projektübersicht" />}
                  path=":projectPage/*"
                />
                <Route element={<Outlet />} path="liegenschaften">
                  <Route
                    element={<PageTitle title="Projektübersicht" />}
                    index
                  />
                  <Route element={<SiteBreadcrumbs />} path=":siteId/*" />
                </Route>
                <Route
                  element={<PageTitle title="Aufgaben" />}
                  path="aufgaben/*"
                />
                <Route
                  element={<PageTitle title="Onboarding" />}
                  path="onboarding/*"
                />
                <Route
                  element={<PageTitle title="Rechnungen und Verträge" />}
                  path="lieferungen-uebersicht/*"
                />
                <Route
                  element={<PageTitle title="Energiedaten" />}
                  path="energiedaten/*"
                />
                <Route
                  element={<PageTitle title="Parkabrechnung" />}
                  path="parkabrechnung/*"
                />
                <Route
                  element={<PageTitle title="Direktvermarktung" />}
                  path="direktvermarktung/*"
                />
                <Route
                  element={<PageTitle title="§ 6 EEG" />}
                  path="paragraph6/*"
                />
                <Route
                  element={<PageTitle title="Erlösmonitoring" />}
                  path="erloesmonitoring/*"
                />
                <Route
                  element={<PageTitle title="Herkunftsnachweis" />}
                  path="herkunftsnachweis/*"
                />
                <Route path="*" />
              </Route>
            </Route>
            <Route element={<Outlet />} path="liegenschaften/*" />
            <Route
              element={<PageTitle title="Direktvermarktung" />}
              path="direktvermarktung/*"
            />
            <Route
              element={<PageTitle title="Erlösmonitoring" />}
              path="erloesmonitoring/*"
            />
          </Route>
          <Route
            element={<PageTitle title="API-Einstellungen" />}
            path="api-einstellungen"
          />
          <Route
            element={<PageTitle title="Einstellungen" />}
            path="einstellungen"
          />
        </Route>
      </Routes>
    </MetronicBreadcrumbWrapper>
  );
}

export { Breadcrumbs };
