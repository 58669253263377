import React, { useEffect, useState } from "react";
import { useComponentsBase } from "../../../hooks/useComponentsBase";
import { useConfiguratorComponents } from "../../../hooks/useConfiguratorComponents";
import { useEnergyDataAcquisitionTags } from "../../../hooks/useEnergyDataAcquisitionTags";
import { useIsMieterstrom } from "../../../hooks/useIsMieterstrom";
import { useSitesDetail } from "../../../hooks/useSitesDetail";
import { useSiteVariantId } from "../../../hooks/useSiteVariantId";
import { ObjectName } from "../../../utils/enums";
import { IconName } from "../../BuildingBlocks/Icon/types";
import { Button } from "../../Buttons/Button/Button";
import { IconButton } from "../../Buttons/IconButton/IconButton";
import { LoadOrError } from "../../LoadOrError/LoadOrError";
import { CreateEdaVersionModal } from "../CreateEdaVersionModal/CreateEdaVersionModal";
import type { PrefillData } from "../CreateEdaVersionModal/CreateEdaVersionModal.constants";
import { useIdentityList } from "../CreateEdaVersionModal/hooks/useIdentityList";
import { CreateMieterstromMBKModal } from "../CreateMieterstromMBKModal/CreateMieterstromMBKModal";
import { EdaTable } from "../EdaTable/EdaTable-v2";
import type { Eda } from "../EdaTable/Mbk";
import { MBKAsResponseArray } from "../EdaTable/Mbk";
import { ViewEdaModal } from "../ViewEdaModal/ViewEdaModal";
import "./EdaVisualization.scss";
import type {
  EdaWithDisplayableReferences,
  EdaWithReferences
} from "./hooks/useMbks";
import { mapEdasWithReferenceValues } from "./hooks/useMbks";

interface EdaVisualizationProps {
  mbksData: Array<EdaWithReferences> | undefined;
  mbksIsLoading: boolean;
  mbksError: Error | null;
  refetchMbks: () => void;
  siteId: number;
}

export function EdaVisualization({
  mbksData,
  mbksError,
  mbksIsLoading,
  refetchMbks,
  siteId
}: EdaVisualizationProps) {
  const { variantId } = useSiteVariantId(siteId);

  const { data: company } = useConfiguratorComponents(
    ObjectName.Person,
    variantId
  );
  const { data: connection } = useConfiguratorComponents(
    ObjectName.Connection,
    siteId
  );
  const { data: consumer } = useConfiguratorComponents(
    ObjectName.Consumer,
    siteId
  );
  const { data: generator } = useConfiguratorComponents(
    ObjectName.Generator,
    siteId
  );
  const { data: meter } = useConfiguratorComponents(ObjectName.Meter, siteId);
  const { data: site } = useSitesDetail(siteId);

  const { data: identityList } = useIdentityList({
    shouldReturnDictionary: true
  });

  const { data: tags } = useEnergyDataAcquisitionTags();

  const [edasWithReferences, setEdasWithReferences] = useState<
    EdaWithDisplayableReferences[]
  >([]);

  const [prefilledData, setPrefilledData] = useState<PrefillData>();
  const [createNewVersionModalIsOpen, setCreateNewVersionModalIsOpen] =
    useState<"editVersion" | "newVersion" | null>(null);
  const [viewMieterstromMBKIsOpen, setViewMieterstromMBKModalIsOpen] =
    useState(false);

  const [viewEdaModalIsOpen, setViewEdaModalIsOpen] = useState(false);
  const [selectedEda, setSelectedEda] = useState<Eda | undefined>(undefined);
  const { data: componentsBase } = useComponentsBase(siteId);

  useEffect(() => {
    if (!mbksData) return;
    setEdasWithReferences(
      mapEdasWithReferenceValues(mbksData, {
        company,
        connection,
        consumer,
        generator,
        meter,
        component: componentsBase,
        site: [site]
      })
    );
  }, [
    mbksData,
    company,
    connection,
    consumer,
    generator,
    meter,
    site,
    componentsBase
  ]);

  function onCreateNewVersionHandler(
    arg?: PrefillData,
    isNewVersion?: boolean
  ) {
    setPrefilledData(arg);
    setCreateNewVersionModalIsOpen(isNewVersion ? "newVersion" : "editVersion");
  }

  const { data: isMieterstromSite } = useIsMieterstrom(siteId);

  function closeCreateNewVersionModal() {
    setPrefilledData(undefined);
    setCreateNewVersionModalIsOpen(null);
  }

  const toggleViewEdaModal = () =>
    setViewEdaModalIsOpen((prevState) => !prevState);

  const onOpenViewEdaModal = (eda: Eda) => {
    setViewEdaModalIsOpen(true);
    setSelectedEda(eda);
  };

  const onVersionCreated = () => {
    refetchMbks();
    setSelectedEda(undefined);
  };

  return (
    <div className="EdaVisualization">
      <div className="control-buttons">
        {isMieterstromSite && (
          <Button
            className="control-button-new"
            color="brand"
            role="button"
            onClick={() => setViewMieterstromMBKModalIsOpen(true)}
          >
            Mieterstrom-MBK erzeugen (Basis)
          </Button>
        )}
        <IconButton
          className="control-button-new"
          color="brand"
          iconName={IconName.Plus}
          role="button"
          onClick={() => onCreateNewVersionHandler()}
        >
          Neue Energiedatenerfassung
        </IconButton>
      </div>
      <LoadOrError error={mbksError} loading={mbksIsLoading}>
        <EdaTable
          siteId={siteId}
          tableData={mbksData ? MBKAsResponseArray(edasWithReferences) : []}
          onClickNewEdaVersion={onCreateNewVersionHandler}
          onOpenViewEdaModal={onOpenViewEdaModal}
        />
      </LoadOrError>

      {typeof selectedEda !== "undefined" && (
        <ViewEdaModal
          eda={selectedEda}
          isOpen={viewEdaModalIsOpen}
          siteId={siteId}
          onEditEda={() => {
            onCreateNewVersionHandler({
              artDerEnergieMenge: selectedEda.identity.type,
              origin: selectedEda.versions[0].origin,
              frequency: selectedEda.versions[0].computation?.frequency,
              medium:
                selectedEda.versions[0].meter?.medium ??
                selectedEda.versions[0].computation?.medium ??
                selectedEda.versions[0].estimation?.medium,
              estimation: selectedEda.versions[0].estimation ?? undefined,
              originalVersion: selectedEda,
              components: [],
              connections: []
            });
          }}
          onToggle={toggleViewEdaModal}
        />
      )}

      {Boolean(createNewVersionModalIsOpen) && (
        <CreateEdaVersionModal
          isNewVersion={createNewVersionModalIsOpen === "newVersion"}
          isOpen
          prefillData={prefilledData}
          referencedIdentityOptions={referencedEdaIdentityDictionary(
            edasWithReferences,
            identityList
          )}
          siteId={siteId}
          tagOptions={tags ?? []}
          onToggle={closeCreateNewVersionModal}
          onVersionCreated={onVersionCreated}
        />
      )}
      <CreateMieterstromMBKModal
        isOpen={viewMieterstromMBKIsOpen}
        siteId={siteId}
        onClose={() => setViewMieterstromMBKModalIsOpen(false)}
        onVersionCreated={refetchMbks}
      />
    </div>
  );
}

const referencedEdaIdentityDictionary = (
  arg: EdaWithDisplayableReferences[],
  identityList: Record<string, Record<string, string>>
) => {
  const response = {};
  for (const eda of arg) {
    const referencesInBrackets = eda.referencesDisplayname
      ? ` (${eda.referencesDisplayname})`
      : "";
    const identityDisplayName =
      identityList[eda.identity.type]?.displayName || eda.identity.type;
    response[
      `eda_${eda.versions[0].publicId} - ${identityDisplayName}${referencesInBrackets}`
    ] = eda.identity;
  }
  return response;
};
