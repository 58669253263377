import api from "../../../api";
import urls from "../../../urls";

interface FindMetersSuggestionsRes {
  taskStatusUrl: string;
  taskIds: Array<string>;
}

export async function findMetersSuggestions(configurationId: number) {
  const { data } = await api.post<FindMetersSuggestionsRes>(
    urls.api.findMeterSuggestions(configurationId)
  );

  return data;
}
