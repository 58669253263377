import * as Sentry from "@sentry/browser";

import {
  LUXON_END_OF_2022,
  LUXON_END_OF_LAST_YEAR
} from "../../../../../utils/dates";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import { decamelize } from "../../../../../utils/SnakeCamelConversion";
import type { AcquisitionFilter } from "../../EnergyDataView";

export const EMPTY_FILTERS = [
  {
    type: "",
    options: [""]
  }
];
export const DEFAULT_FILTERS: Array<AcquisitionFilter> = [
  {
    options: [luxonDateTimeToBackendDateOrDateTime(LUXON_END_OF_LAST_YEAR)],
    type: "date"
  }
];

export const DATE_2022_FILTER: Array<AcquisitionFilter> = [
  {
    options: [luxonDateTimeToBackendDateOrDateTime(LUXON_END_OF_2022)],
    type: "date"
  }
];

export const FILTER_TYPES = {
  sites: "Liegenschaft",
  medium: "Medium",
  energyDataAcquisitionTags: "Klassifizierung",
  generators: "Erzeuger",
  consumers: "Verbraucher",
  persons: "Unternehmen",
  energyDataAcquisitionTypes: "Typ",
  origins: "Herkunft",
  generatorTypes: "Art der Erzeugungsanlagen",
  consumerTypes: "Art der Verbraucher",
  installedCapacity: "Nettonennleistung",
  date: "Gültig am"
};
export const FILTER_TYPE_KEYS = Object.keys(FILTER_TYPES);
export const INSTALLED_CAPACITY_DEFAULT_MIN_MAX = {
  min: 0,
  max: 2000
};

export function buildFilterString(filters: Array<AcquisitionFilter>) {
  let string = "";

  filters.forEach((filter) => {
    if (filter.type === "installedCapacity") {
      const option = filter.options[0] as { min: number; max: number };
      const min = option.min || INSTALLED_CAPACITY_DEFAULT_MIN_MAX.min;
      const max =
        !!option.max || option.max === 0
          ? option.max
          : INSTALLED_CAPACITY_DEFAULT_MIN_MAX.max;
      string += `&generator_capacity_min=${min}&generator_capacity_max=${max}`;
    } else if (FILTER_TYPE_KEYS.includes(filter.type)) {
      string += buildDropdownFilterString(
        decamelize(filter.type),
        filter.options as Array<string>
      );
    } else {
      Sentry.captureMessage(
        `Attempt to send unsupported filter to the backend: ${filter.type}`
      );
    }
  });

  return string;
}

export function buildDropdownFilterString(
  typeString: string,
  options: Array<string>
) {
  const nonEmptyOptions = options.filter((option) => option !== "");
  const optionsWithTypeString = nonEmptyOptions.map(
    (option) => `&${typeString}=${encodeURIComponent(option)}`
  );
  return optionsWithTypeString.join("");
}
