import { type Priority, type Todo } from "../../../utils/backend-types";

export function handleChangePriority(
  todos: Array<Todo>,
  updateTodoPriority: (editedTodo: Todo) => void,
  priorityValue: Priority,
  todoId: number
) {
  const todo = todos.find((todo) => todo.id === todoId);

  if (todo) {
    updateTodoPriority({
      ...todo,
      priority: priorityValue
    });
  }
}
