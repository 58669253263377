import { useQuery } from "@tanstack/react-query";
import React from "react";
import api from "../../../../api";
import { useVariantSites } from "../../../../hooks/useVariantSites";
import urls from "../../../../urls";
import { LoadOrError } from "../../../LoadOrError/LoadOrError";
import { ThirdPartySystems } from "../../../ThirdPartySystems/ThirdPartySystems";
import type { ConfigurationData } from "../../../ThirdPartySystems/ThirdPartySystems.types";

interface ThirdPartySystemsTabContentProps {
  variantId: number;
}

function ThirdPartySystemsTabContent({
  variantId
}: ThirdPartySystemsTabContentProps) {
  const {
    isLoading: isSitesListloading,
    error: sitesListError,
    sites
  } = useVariantSites(variantId);
  const {
    isLoading: isDataLoading,
    error: dataError,
    data
  } = useQuery({
    queryKey: ["sub-metering-configurations", variantId],
    queryFn: () => fetchSubMeteringConfigurations(variantId)
  });

  async function fetchSubMeteringConfigurations(variantId: number) {
    const url = urls.api.subMeteringConfigurations(variantId);
    const response = await api.get<Array<ConfigurationData>>(url);

    return response.data;
  }

  return (
    <LoadOrError
      error={dataError || sitesListError}
      loading={isDataLoading || isSitesListloading}
    >
      {data && sites && (
        <ThirdPartySystems
          initialConfigurations={data}
          sites={sites}
          variantId={variantId}
        />
      )}
    </LoadOrError>
  );
}

export { ThirdPartySystemsTabContent };
