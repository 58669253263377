import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useDownload } from "../../../../hooks/useDownload";
import urls from "../../../../urls";
import { FINANCIAL_DOCUMENT_TYPES } from "../../../../utils/constants";
import { showToast } from "../../../../utils/toast";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../../BuildingBlocks/Layout/Modal/Modal";
import { Button, buttonColors } from "../../../Buttons/Button/Button";
import { IconButton } from "../../../Buttons/IconButton/IconButton";
import { SpinButton } from "../../../Buttons/SpinButton/SpinButton";
import {
  FinancialDocumentType,
  type Paragraph6CreatableCredit
} from "../../Paragraph6.types";
import "./Paragraph6AvailableCreditsModal.scss";

export interface Paragraph6AvailableCreditsModalProps {
  variantId: number;
  creatableCredits?: Array<Paragraph6CreatableCredit>;
  contractNames?: Array<{ id: string; name: string }>;
  createParagraph6Credits: (
    credits: Array<Paragraph6CreatableCredit>,
    preview?: boolean
  ) => Promise<void>;
  fetchDraftCreditStatus: (
    credit: Paragraph6CreatableCredit
  ) => Promise<string>;
  previewMode?: boolean;
  isOpen?: boolean;
  onClose: () => void;
  contentType?: FinancialDocumentType;
}

function Paragraph6AvailableCreditsModal({
  variantId,
  contractNames,
  creatableCredits,
  previewMode,
  isOpen,
  createParagraph6Credits,
  fetchDraftCreditStatus,
  onClose,
  contentType = FinancialDocumentType.Credit
}: Paragraph6AvailableCreditsModalProps) {
  const queryClient = useQueryClient();

  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const [isAllLoading, setIsAllLoading] = useState(false);

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  function getContractName(contractId: string) {
    return (
      contractNames?.find((contract) => contract.id === contractId)?.name ??
      "Unbekannt"
    );
  }

  function handleClickCreateAll() {
    if (!creatableCredits) {
      return;
    }
    setIsAllLoading(true);
    createParagraph6Credits(creatableCredits, previewMode)
      .then(() => {
        onClose();
      })
      .catch((error) => {
        showToast("error", error);
      })
      .finally(() => {
        queryClient.invalidateQueries({
          queryKey: ["paragraph6Credits", { variantId }]
        });
        setIsAllLoading(false);
      });
  }

  return (
    <Modal
      className="Paragraph6AvailableCreditsModal"
      isOpen={isModalOpen}
      size="lg"
    >
      <ModalHeader toggle={onClose}>
        {contentType === FinancialDocumentType.Credit
          ? "Verfügbare Gutschriften"
          : "Verfügbare Zahlungsinformationen"}
      </ModalHeader>
      <ModalBody>
        {creatableCredits && (
          <table>
            <tbody>
              {creatableCredits.map((credit) => {
                return (
                  <CreatableCreditRow
                    contentType={contentType}
                    creatableCredit={credit}
                    createParagraph6Credits={createParagraph6Credits}
                    fetchDraftCreditStatus={fetchDraftCreditStatus}
                    isLoading={isAllLoading}
                    key={credit.contract + credit.start_date + credit.end_date}
                    name={getContractName(credit.contract)}
                    previewMode={previewMode}
                    variantId={variantId}
                    onClose={onClose}
                  />
                );
              })}
            </tbody>
          </table>
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Schließen</Button>
        {!previewMode && (
          <SpinButton
            color="brand"
            spin={isAllLoading}
            onClick={handleClickCreateAll}
          >
            {`Alle ${
              contentType === FinancialDocumentType.Credit
                ? "Gutschriften"
                : "Zahlungsinformationen"
            } erstellen`}
          </SpinButton>
        )}
      </ModalFooter>
    </Modal>
  );
}

interface CreatableCreditRowProps {
  variantId: number;
  creatableCredit: Paragraph6CreatableCredit;
  name: string;
  createParagraph6Credits: (
    credits: Array<Paragraph6CreatableCredit>,
    preview?: boolean
  ) => Promise<unknown>;
  fetchDraftCreditStatus: (
    credit: Paragraph6CreatableCredit
  ) => Promise<string>;
  onClose: () => void;
  isLoading?: boolean;
  previewMode?: boolean;
  contentType?: FinancialDocumentType;
}
function CreatableCreditRow({
  variantId,
  creatableCredit,
  name,
  createParagraph6Credits,
  fetchDraftCreditStatus,
  onClose,
  isLoading,
  previewMode,
  contentType = FinancialDocumentType.Credit
}: CreatableCreditRowProps) {
  const queryClient = useQueryClient();
  const { download } = useDownload();

  const [creditIsLoading, setCreditIsLoading] = useState(isLoading);

  function handleClickCreate() {
    setCreditIsLoading(true);
    createParagraph6Credits([creatableCredit], previewMode)
      .then(async () => {
        if (previewMode) {
          const creditId = await fetchDraftCreditStatus(creatableCredit);
          download({
            downloadUrl:
              urls.apiWithoutCamelization.paragraph6CreditDocument(creditId)
          });
        } else {
          onClose();
        }
      })
      .catch((error) => {
        showToast("error", error);
      })
      .finally(() => {
        if (!previewMode) {
          queryClient.invalidateQueries({
            queryKey: ["paragraph6Credits", { variantId }]
          });
        }
        setCreditIsLoading(false);
      });
  }

  useEffect(() => {
    setCreditIsLoading(isLoading);
  }, [isLoading]);

  return (
    <tr className="CreatableCreditRow">
      <td>{name}</td>
      <td>
        {creatableCredit.start_date} – {creatableCredit.end_date}
      </td>
      <td>
        {previewMode ? (
          <IconButton
            color={buttonColors.link}
            disabled={creditIsLoading}
            iconColor={buttonColors.primary}
            iconName={creditIsLoading ? IconName.SpinnerSpinning : IconName.Eye}
            noBackground
            noBorder
            type="button"
            onClick={handleClickCreate}
          >
            Anschauen
          </IconButton>
        ) : (
          <IconButton
            color={buttonColors.brand}
            disabled={creditIsLoading}
            iconName={
              creditIsLoading ? IconName.SpinnerSpinning : IconName.Plus
            }
            noBackground
            noBorder
            type="button"
            onClick={handleClickCreate}
          >
            {`${FINANCIAL_DOCUMENT_TYPES[contentType]} erstellen`}
          </IconButton>
        )}
      </td>
    </tr>
  );
}

export { Paragraph6AvailableCreditsModal };
