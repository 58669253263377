import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../../../../../api";
import urls from "../../../../../urls";
import type { Delivery } from "../../../../../utils/backend-types";

export function useDeliveryMutations(variantId: number, deliveryId?: string) {
  const queryClient = useQueryClient();

  const deliveryCreateMutation = useMutation({
    mutationFn: (delivery: Delivery) => createDelivery(delivery, variantId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["deliveries", { variantId }]
      });
    }
  });

  const deliveryUpdateMutation = useMutation({
    mutationFn: (delivery: Delivery) => updateDelivery(delivery),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["deliveries", { variantId }]
      });
      queryClient.invalidateQueries({
        queryKey: ["delivery", { deliveryId }]
      });
    }
  });

  return {
    deliveryCreateMutation,
    deliveryUpdateMutation
  };
}

async function createDelivery(delivery: Delivery, variantId: number) {
  const endpointUrl = urls.api.deliveries(variantId);

  try {
    const response = await api.post<Delivery>(endpointUrl, delivery);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

async function updateDelivery(delivery: Delivery) {
  const endpointUrl = urls.api.delivery(delivery.id);

  try {
    const response = await api.put<Delivery>(endpointUrl, delivery);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
