import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import api from "../../../../../../api";
import { FoerderRegime } from "../../../../../../types/api.types";
import urls from "../../../../../../urls";
import type {
  VBHCalculated,
  VBHCalculatedResponseData
} from "../../../../../../utils/backend-types";
import { THIS_YEAR } from "../../../../../../utils/dates";
import { showToast } from "../../../../../../utils/toast";
import { buttonColors } from "../../../../../Buttons/Button/Button";
import { SpinButton } from "../../../../../Buttons/SpinButton/SpinButton";
import type { FormItemsProps } from "../../../../../DynamicForm/FormItems/FormItems";
import { openErrorAlertPopup } from "../../../../../ErrorAlertPopup/openErrorAlertPopup";
import { IconHelpText } from "../../../../../IconHelpText/IconHelpText";
import { OrangeCircle } from "../../../../../OrangeCircle/OrangeCircle";
import { VollbenutzungsstundenInfo } from "./VollbenutzungsstundenInfo/VollbenutzungsstundenInfo";
import "./VollbenutzungsstundenList.scss";
import { VollbenutzungsstundenTable } from "./VollbenutzungsstundenTable/VollbenutzungsstundenTable";

const MAX_HOURS = 30000;

export type Vollbenutzungsstunden = {
  hours: number | null;
  year: number;
};

const VBH_START_YEAR = 2020;
const VBH_HELP_TEXT =
  "Der Zuschlag nach KWKG wird für KWK-Anlagen bis zu 30.000 Vollbenutzungsstunden (Vbh) gewährt. " +
  "Für entsprechende Meldungen können hier die Vbh eingetragen werden.";

type VollbenutzungsstundenListProps = Pick<
  FormItemsProps,
  "formValues" | "missingFields" | "onInput"
>;

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function VollbenutzungsstundenList({
  formValues,
  onInput,
  missingFields
}: VollbenutzungsstundenListProps) {
  const [calculationLoading, setCalculationLoading] = useState(false);
  const [showInfo, setShowInfo] = useState(true);
  //This is hack as we do not have the KWK id in the software.
  const kwkAnlageId = formValues.id as number;
  const { t } = useTranslation();

  const formValuesVbh =
    formValues.vollbenutzungsstunden as unknown as Vollbenutzungsstunden[];
  const vbhListInitial =
    formValuesVbh.length === 0
      ? [{ year: VBH_START_YEAR, hours: null }]
      : formValuesVbh;
  const vbhList = addMissingYearsToVbhList(vbhListInitial);

  function getUpdatedVbhList(
    currentVBH: Vollbenutzungsstunden,
    newHours: number | null
  ): Array<Vollbenutzungsstunden> {
    const newVBHList = [...vbhList];
    const vbhIndex = newVBHList.findIndex((a) => a.year === currentVBH.year);

    if (vbhIndex >= 0) {
      newVBHList[vbhIndex].hours = newHours;
    }

    return newVBHList;
  }

  function removeNotNeededYears(
    vbhArr: Array<Vollbenutzungsstunden>
  ): Array<Vollbenutzungsstunden> {
    const newVBHList = [...vbhArr];
    const exceededRow = computeCumulativeVBH(newVBHList).find(maxHoursExceeded);

    if (exceededRow) {
      const lastCorrectYear = exceededRow.year;
      for (let i = newVBHList.length - 1; i >= 0; i--) {
        if (newVBHList[i].year > lastCorrectYear) {
          newVBHList.splice(i, 1);
        }
      }
    }

    return newVBHList;
  }

  function updateValues(
    currentVBH: Vollbenutzungsstunden,
    newHours: number | null
  ) {
    const newVBHList = removeNotNeededYears(
      getUpdatedVbhList(currentVBH, newHours)
    );

    onInput("vollbenutzungsstunden", newVBHList);
  }

  function getYearsInPastWithNoValues() {
    return vbhList
      .filter((vbh) => !vbh.hours && vbh.year < THIS_YEAR)
      .map((vbh) => vbh.year);
  }

  function handleClickCalculate() {
    const yearsWithNoValues = getYearsInPastWithNoValues();
    setCalculationLoading(true);
    api
      .get<VBHCalculatedResponseData>(
        urls.api.calculateVBH(kwkAnlageId, yearsWithNoValues)
      )
      .then((response) => {
        handleVBHCalculatedData(response.data);
      })
      .catch((error) => {
        openErrorAlertPopup(error);
      })
      .finally(() => {
        setCalculationLoading(false);
      });
  }

  function handleVBHCalculatedData(data: VBHCalculatedResponseData) {
    Object.keys(data).forEach((currentYear) => {
      const newData: VBHCalculated = data[currentYear];
      if (newData.status === "SUCCESS") {
        const vbhForYear = vbhList.find(
          (vbh) => vbh.year === parseInt(currentYear)
        );

        if (vbhForYear) {
          updateValues(vbhForYear, data[currentYear].value);

          showToast(
            "success",
            t("messages.Generator.VBHCalculated", { year: currentYear })
          );
        }
      } else {
        showToast("error", newData.message);
      }
    });
  }
  const isCorrectFoerderregime =
    formValues.foerderregime === FoerderRegime.kwkg_zuschlag_2012 ||
    formValues.foerderregime === FoerderRegime.kwkg_ausschreibung ||
    formValues.foerderregime === FoerderRegime.kwkg_zuschlag_2016_2020 ||
    formValues.foerderregime === FoerderRegime.kwkg_zuschlag_2000_2002_2009;
  return (
    isCorrectFoerderregime && (
      <div className="vbl-container">
        <div className="vbl-control-and-help-container">
          {showInfo && (
            <div>
              <VollbenutzungsstundenInfo onToggle={() => setShowInfo(false)} />
            </div>
          )}

          <div className="vbl-help-text-container">
            <div>
              {missingFields?.includes("vollbenutzungsstunden") && (
                <OrangeCircle />
              )}
            </div>
            <IconHelpText helpText={VBH_HELP_TEXT} />
          </div>
          <div className="vbl-controls-container">
            <SpinButton
              color={buttonColors.brand}
              disabled={getYearsInPastWithNoValues().length === 0}
              spin={calculationLoading}
              onClick={handleClickCalculate}
            >
              Berechnung anstoßen
            </SpinButton>
          </div>
        </div>
        <VollbenutzungsstundenTable
          vollbenutzungsstunden={vbhList}
          onUpdateValues={updateValues}
        />
      </div>
    )
  );
}

function addMissingYearsToVbhList(
  currentVbhList: Array<Vollbenutzungsstunden>
) {
  if (computeCumulativeVBH(currentVbhList).some(maxHoursExceeded)) {
    return currentVbhList;
  }

  const latestYear = currentVbhList.reduce((a, b) =>
    a.year > b.year ? a : b
  ).year;

  if (latestYear === THIS_YEAR) {
    return currentVbhList;
  }

  const newVBHList = [...currentVbhList];
  const yearDifference = THIS_YEAR - latestYear;

  for (let i = 1; i <= yearDifference; i++) {
    newVBHList.push({ year: latestYear + i, hours: null });
  }

  return newVBHList;
}

function maxHoursExceeded(vbh: Vollbenutzungsstunden) {
  return vbh.hours && vbh.hours >= MAX_HOURS;
}

export function computeCumulativeVBH(
  vbh: Array<Vollbenutzungsstunden>
): Array<Vollbenutzungsstunden> {
  return vbh
    .sort((a, b) => a.year - b.year)
    .map((value, index, array) => {
      return {
        year: value.year,
        hours: sumUpToIndex(array, index)
      } as Vollbenutzungsstunden;
    });
}
function sumUpToIndex(
  arr: Array<Vollbenutzungsstunden>,
  index: number
): number {
  // Use the reduce method to sum up the values.
  return arr.reduce((acc, current, currentIndex) => {
    if (currentIndex <= index) {
      return acc + (current.hours ?? 0);
    } else {
      return acc;
    }
  }, 0);
}

export { VollbenutzungsstundenList, VollbenutzungsstundenListProps };
