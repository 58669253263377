import { useQueryClient } from "@tanstack/react-query";
import type { BaseSyntheticEvent } from "react";
import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardImg,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from "reactstrap";
import { QUERY_KEY } from "../../hooks/useCurrentUserQuery";
import { useUserProfile } from "../../hooks/useUserProfile";
import urls from "../../urls";
import { getFieldSpecificError } from "../../utils/api-utils";
import type { ExtendedUser } from "../../utils/backend-types";
import { uploadFileOrFiles } from "../../utils/files/uploadFileOrFiles";
import { showToast } from "../../utils/toast";
import { Portlet } from "../BuildingBlocks/Layout/Portlet";
import { Button } from "../Buttons/Button/Button";
import { openErrorAlertPopup } from "../ErrorAlertPopup/openErrorAlertPopup";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { useShouldShowStaffView } from "../StaffViewToggle/useShouldShowStaffView";
import { ChangePasswordForm } from "./ChangePasswordForm";
import { DevSettings } from "./DevSettings/DevSettings";
import { NotificationSettings } from "./NotificationSettings";
import "./Settings.scss";
import { TwoFactorAuth } from "./TwoFactorAuth/TwoFactorAuth";

function Settings() {
  const { profile, isLoading, error } = useUserProfile();
  const queryClient = useQueryClient();
  const isStaff = useShouldShowStaffView();

  const [profileImage, setProfileImage] = useState<string | undefined>("");
  const [profileUploadFilename, setProfileUploadFilename] =
    useState("Datei wählen");
  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    setProfileImage(profile?.image);
  }, [profile?.image]);

  const onSelectFile = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];
    setSelectedFile(file);
    setProfileUploadFilename(file?.name || "");
  }, []);

  const handleSubmitProfile = useCallback(
    async (event: BaseSyntheticEvent) => {
      event.preventDefault();

      if (!selectedFile) {
        return;
      }

      setIsSubmitting(true);

      try {
        const response = await uploadFileOrFiles<ExtendedUser>(
          selectedFile,
          urls.apiWithoutCamelization.userProfile(),
          "image",
          undefined,
          "PATCH",
          true
        );

        showToast("success", "Profil wurde erfolgreich geändert");

        if (response) {
          setProfileImage(response.data.image);
          queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
        }
      } catch (e) {
        const errorMessage = getFieldSpecificError(e, ["image"]);

        openErrorAlertPopup(
          e,
          errorMessage ? { [e.response?.status as number]: errorMessage } : {}
        );
      }

      setIsSubmitting(false);
    },
    [queryClient, selectedFile]
  );

  return (
    <LoadOrError error={error} loading={isLoading}>
      <Row>
        <Col lg="4" xl="3">
          <Portlet className="profile-picture">
            <Card title="Ihr Profil">
              <CardBody>
                <div className="pic">
                  <div className="pic-wrapper" hidden={!profileImage}>
                    <CardImg key={profileImage} src={profileImage} />
                  </div>
                </div>
                <div className="details">
                  <span className="name">{profile?.name}</span>
                  <a className="email m-link" href="">
                    {profile?.email}
                  </a>
                </div>
              </CardBody>
            </Card>
          </Portlet>
        </Col>
        <Col lg="8" xl="9">
          <Portlet title="Profil">
            <Form
              className="m-form ays-enable"
              encType="multipart/form-data"
              onSubmit={handleSubmitProfile}
            >
              <FormGroup id="div_id_image">
                <div className="label">
                  <Label
                    aria-required={true}
                    className="col-form-label"
                    htmlFor="id_image"
                  >
                    Profilbild
                    <span>*</span>
                  </Label>
                </div>

                <div className="custom-file">
                  <Input
                    accept="image/png, image/jpg, image/jpeg"
                    className="clearablefileinput custom-file-input form-control"
                    id="id_image"
                    name="image"
                    required
                    type="file"
                    onInput={onSelectFile}
                  />

                  <Label className="custom-file-label" htmlFor="id_image">
                    {profileUploadFilename}
                  </Label>
                </div>
              </FormGroup>

              <Button color="primary" disabled={isSubmitting} type="submit">
                Speichern
              </Button>
            </Form>
          </Portlet>
          <NotificationSettings />
          <ChangePasswordForm />
          <TwoFactorAuth />
          {isStaff && <DevSettings />}
        </Col>
      </Row>
    </LoadOrError>
  );
}

export { Settings };
