import type { DateTime } from "luxon";
import React from "react";
import type { Frequency } from "../../../../utils/enums";
import { MonthYearRangePicker } from "../../../MonthYearRangePicker/MonthYearRangePicker";

type DisplayedDaysSelectorProps = {
  onChange: (dates: { startDate: DateTime; endDate: DateTime | null }) => void;
  startDate: DateTime;
  endDate: DateTime;
  monthYearPickerMode: Frequency.Month | Frequency.Year;
};

export function DisplayedDaysSelector({
  onChange,
  startDate,
  endDate,
  monthYearPickerMode
}: DisplayedDaysSelectorProps) {
  return (
    <div>
      <h6>Dargestellter Zeitraum</h6>

      <MonthYearRangePicker
        end={endDate}
        mode={monthYearPickerMode}
        start={startDate}
        onChange={onChange}
      />
    </div>
  );
}
