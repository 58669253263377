import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";

import type { FormFieldData, FormItemsProps } from "./FormItems";

interface CombinedFieldsOptions<T> {
  combinedFieldLabel?: string;
  extraProps?: T;
}

type CombinedFieldsItems = Record<string, FormFieldData | undefined>;

/** Usage pattern:

- CustomForm uses FormItems to display and manage all fields
- To change how fields are displayed, FormItems needs to be overriden
- Therefore, CustomForm can take a CustomFormItemsComponent
- This can be chained infinitely to override as many field types as you wish
- The HoC overrides FormItems but has to place everything back into the right format, as if it didn't exist
- This is just one example but other HoCs (e.g. withCreatableDropdown exist)
- CombinedFieldsComponent (below) handles the display/functionality of the given combined fields
*/
/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function withCombinedFields<T = unknown>(
  FormItemsComponent,
  CombinedFieldsComponent,
  fieldNames: Array<string>,
  options?: CombinedFieldsOptions<T>
) {
  const WrappedFormItemsWithCombinedFields = ({
    formItems,
    formName,
    formValues,
    formErrors,
    formItemsWithPortals,
    allowInput,
    onFormItemNodeChange,
    onInput,
    ...otherProps
  }: FormItemsProps) => {
    const firstFieldName = fieldNames[0];

    const getInitialFormItemsWithPortals = useCallback(
      (skipCombinedFields?: boolean) => {
        if (skipCombinedFields) {
          return formItemsWithPortals ?? [];
        } else {
          return [...(formItemsWithPortals || []), firstFieldName];
        }
      },
      [formItemsWithPortals, firstFieldName]
    );

    const [localFormItemsWithPortals, setLocalFormItemsWithPortals] = useState(
      getInitialFormItemsWithPortals()
    );

    const [formItemNode, setFormItemNode] = useState<HTMLElement | null>(null);

    const getCombinedFormItems = useCallback(() => {
      const combinedFormItems: CombinedFieldsItems = {};

      fieldNames.forEach((name) => {
        combinedFormItems[name] = formItems.find(
          (formItem) => formItem.name === name
        );
      });

      return combinedFormItems;
    }, [formItems]);

    function doCombinedFormItemsExist(
      combinedFormItemsDict: CombinedFieldsItems
    ) {
      const combinedFormItems = Object.values(combinedFormItemsDict);

      const doTheyExist: boolean = combinedFormItems.reduce(
        (allFound, formItem) => {
          return allFound && !!formItem;
        },
        true
      );

      return doTheyExist;
    }

    useEffect(() => {
      const combinedFormItemsDict = getCombinedFormItems();

      const skipCombinedFields = !doCombinedFormItemsExist(
        combinedFormItemsDict
      );

      if (!localFormItemsWithPortals.includes(firstFieldName)) {
        setLocalFormItemsWithPortals(
          getInitialFormItemsWithPortals(skipCombinedFields)
        );
      }
    }, [
      getCombinedFormItems,
      firstFieldName,
      getInitialFormItemsWithPortals,
      localFormItemsWithPortals
    ]);

    function handleFormItemNodeChange(formItemName: string, node: HTMLElement) {
      if (formItemName === firstFieldName) {
        setFormItemNode(node);
      } else if (onFormItemNodeChange) {
        onFormItemNodeChange(formItemName, node);
      }
    }

    const combinedFormItemsDict = getCombinedFormItems();

    const showCombinedFields =
      doCombinedFormItemsExist(combinedFormItemsDict) && !!formItemNode;

    const otherFieldNames = fieldNames.slice(1);

    const formItemsWithoutOtherCombinedFields = useMemo(() => {
      const filteredFormItems = formItems.filter(
        (formItem) => !otherFieldNames.includes(formItem.name)
      );
      const firstFieldIndex = filteredFormItems.findIndex(
        (item) => item.name === firstFieldName
      );

      if (firstFieldIndex >= 0) {
        if (options?.combinedFieldLabel) {
          filteredFormItems[firstFieldIndex] = {
            ...filteredFormItems[firstFieldIndex],
            label: options?.combinedFieldLabel
          };
        } else {
          filteredFormItems[firstFieldIndex] = {
            ...filteredFormItems[firstFieldIndex],
            noLabel: true
          };
        }
      }

      return filteredFormItems;
    }, [formItems, otherFieldNames, firstFieldName]);

    return (
      <>
        <FormItemsComponent
          {...otherProps}
          allowInput={allowInput}
          formErrors={formErrors}
          formItems={formItemsWithoutOtherCombinedFields}
          formItemsWithPortals={localFormItemsWithPortals}
          formValues={formValues}
          onFormItemNodeChange={handleFormItemNodeChange}
          onInput={onInput}
        />
        {showCombinedFields &&
          ReactDOM.createPortal(
            <CombinedFieldsComponent
              {...otherProps}
              {...options?.extraProps}
              allowInput={allowInput}
              fieldNames={fieldNames}
              formErrors={formErrors}
              formItems={combinedFormItemsDict}
              formName={formName}
              formValues={formValues}
              onInput={onInput}
            />,
            formItemNode
          )}
      </>
    );
  };

  WrappedFormItemsWithCombinedFields.propTypes = {
    formItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    formName: PropTypes.string,
    formValues: PropTypes.object.isRequired,
    formErrors: PropTypes.object,
    formItemsWithPortals: PropTypes.array,
    allowInput: PropTypes.bool,
    onInput: PropTypes.func.isRequired,
    onFormItemNodeChange: PropTypes.func
  };

  return WrappedFormItemsWithCombinedFields;
}

export { withCombinedFields };
