import { useQuery } from "@tanstack/react-query";
import api from "../api";
import urls from "../urls";

export function useEnergyDataAcquisitionFilters(projectId: string) {
  return useQuery({
    queryKey: [`energyDataAcquisitionFilterOptions-${projectId}`],
    queryFn: async () =>
      await api.get(urls.api.energyDataAcquisitionFilterOptions(projectId))
  });
}
