import React from "react";

import { MenuItem } from "../MenuItem/MenuItem";
import { RightIcon } from "./RightIcon";

function SeparatorIcon() {
  return (
    <MenuItem>
      <span className="m-menu__link-text">
        <RightIcon />
      </span>
    </MenuItem>
  );
}

export { SeparatorIcon };
