import React from "react";
import { Icon } from "../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../../BuildingBlocks/Layout/Modal/Modal";
import { Button, buttonColors } from "../../../Buttons/Button/Button";
import "./ParticipationWarningModal.scss";

interface ParticipationWarningModalProps {
  onSubmit: () => Promise<void>;
  onCancel: () => void;
}

function ParticipationWarningModal({
  onSubmit,
  onCancel
}: ParticipationWarningModalProps) {
  return (
    <div className="ParticipationWarningModal">
      <Modal isOpen>
        <ModalHeader className="participation-warning-modal-header">
          <div>
            <Icon name={IconName.ExclamationOrange} style={{ width: "16px" }} />
          </div>
          <span className="participation-warning-moda-header-text">
            Änderung übernehmen?
          </span>
        </ModalHeader>
        <ModalBody>
          <p>
            Sie haben eine Änderung im Feld „Teilnahme am Mieterstrom“
            vorgenommen. Wenn Sie diese Änderung speichern, erstellt opti.node
            ein neues Mess-und Bilanzierungskonzept für Sie. Dies kann bis zu
            zwei Tage in Anspruch nehmen. In dieser Zeit kann opti.node keine
            Rechnungen für Sie erstellen.
          </p>
          <p>
            Bitte stellen Sie außerdem sicher, dass Ihr Messstellenbetreiber den
            angepassten Lastgang an opti.node schickt.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color={buttonColors.secondary} onClick={onCancel}>
            Abbrechen
          </Button>
          <Button color={buttonColors.warning} onClick={onSubmit}>
            Ja, übernehmen
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export { ParticipationWarningModal };
