import type { CellInfo } from "react-table";

/** Returns whether a column is sortable. (default === true) */
export function isColumnSortable(cellInfo: CellInfo) {
  if (cellInfo.column.columns) {
    return false;
  }

  if (typeof cellInfo.column.sortable === "undefined") {
    return true;
  }

  return cellInfo.column.sortable;
}
