import classNames from "classnames";
import _ from "lodash";
import React from "react";
import { Col, Row } from "reactstrap";
import { LocationType } from "../../../utils/backend-types";
import { getAccountedFeedOutLabelFromMarketLocation } from "../../../utils/getAccountedFeedOutLabelFromMarketLocation";
import { DecimalLabel } from "../../BuildingBlocks/DecimalLabel/DecimalLabel";
import { mergeByAccessors } from "../merging";
import PriceComponentComparison from "../PriceComponentComparison";
import "./MarketLocationComparison.scss";

export default function MarketLocationComparison(props) {
  const {
    marketLocationKey,
    mergedMarketLocations,
    meteringConceptKeys,
    meteringConceptConfiguration,
    onToggleDetails,
    displayDetails,
    displayFormulas
  } = props;

  const mergedMarketLocation = mergedMarketLocations.merged[marketLocationKey];

  const comparisonValues = meteringConceptKeys.map((meteringConceptKey) => {
    const marketLocation = mergedMarketLocation[meteringConceptKey];
    const comparisonValues = marketLocation ? (
      <MarketLocationComparisonValues
        displayFormulas={displayFormulas}
        marketLocation={marketLocation}
      />
    ) : null;
    return (
      <Col
        className={`background-color--${meteringConceptConfiguration[meteringConceptKey].cellBackgroundColor}`}
        key={meteringConceptKey}
      >
        {comparisonValues}
      </Col>
    );
  });

  const common = mergedMarketLocations.common[marketLocationKey];
  const [firstColLabel, secondColLabel] = marketLocationLabels(common);
  const rawPriceComponents = meteringConceptKeys.reduce(
    (obj, meteringConceptKey) => {
      obj[meteringConceptKey] = _.get(
        mergedMarketLocation,
        `${meteringConceptKey}.priceComponents`,
        []
      );
      return obj;
    },
    {}
  );
  const mergedPriceComponents = mergeByAccessors(rawPriceComponents, ["label"]);
  const renderedPriceComponentsComparison = displayDetails
    ? mergedPriceComponents.keys.map((priceComponentKey) =>
        PriceComponentComparison({
          priceComponentKey,
          mergedPriceComponents,
          meteringConceptKeys,
          meteringConceptConfiguration
        })
      )
    : null;

  return (
    <div>
      <Row
        className={classNames(
          "metering-concept-row",
          mergedPriceComponents.keys.length === 0 || displayFormulas
            ? false
            : "clickable"
        )}
        onClick={displayFormulas ? null : onToggleDetails}
      >
        <Col>
          <Row>
            <Col>{firstColLabel}</Col>
            <Col>{secondColLabel}</Col>
          </Row>
        </Col>
        {comparisonValues}
      </Row>
      {renderedPriceComponentsComparison}
    </div>
  );
}

const marketLocationLabels = (commonMarketLocationData) => {
  switch (commonMarketLocationData.locationType) {
    case LocationType.InternalDelivery:
      return [
        commonMarketLocationData.generator.name,
        commonMarketLocationData.consumer.name
      ];
    case LocationType.AccountedFeedOut:
      return [
        getAccountedFeedOutLabelFromMarketLocation(commonMarketLocationData),
        null
      ];
    case LocationType.AccountedFeedIn:
      return [commonMarketLocationData.generator.name, null];
    case LocationType.ResidualDelivery:
      return [commonMarketLocationData.consumer.name, null];
    default:
      return [null, null];
  }
};

function MarketLocationComparisonValues({ marketLocation, displayFormulas }) {
  if (displayFormulas) {
    return FormulaRow({ formula: marketLocation.formulaStr });
  } else {
    const costs = marketLocation.priceComponents.reduce(
      (sum, priceComponent) => sum + priceComponent.yearlyCost,
      0
    );
    return ValueCostsRow({
      quantity: marketLocation.yearlyEnergy,
      costs: costs
    });
  }
}

export function ValueCostsRow({ quantity, costs }) {
  return (
    <Row>
      <Col>
        <DecimalLabel alignRight suffix=" kWh" value={quantity} />
      </Col>
      <Col>
        <DecimalLabel
          alignRight
          fontDangerIfNegative
          suffix=" €"
          value={-costs}
        />
      </Col>
    </Row>
  );
}

function FormulaRow({ formula }) {
  return (
    <Row>
      <Col className="formula">{formula}</Col>
    </Row>
  );
}
