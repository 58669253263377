import classNames from "classnames";
import React from "react";
import { InputWrapper } from "../../utils/InputWrapper";
import "../styles/form-control.scss";

interface TextInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "type"> {
  defaultValue?: string;
  errorText?: string;
  extraInputClasses?: string;
  inputGroupText?: string;
  invalid?: boolean;
  type: "text" | "email";
  warned?: boolean;
}

function TextInput(
  {
    className,
    errorText,
    extraInputClasses,
    inputGroupText,
    invalid,
    type,
    value,
    defaultValue,
    warned,
    ...otherProps
  }: TextInputProps,
  ref?: React.Ref<HTMLInputElement>
) {
  const inputClassName = classNames(
    "form-control",
    "m-input",
    extraInputClasses,
    {
      "is-invalid": invalid,
      warning: !invalid && warned
    }
  );

  return (
    <InputWrapper
      className={className}
      errorText={errorText}
      inputGroupText={inputGroupText}
    >
      <input
        {...otherProps}
        className={inputClassName}
        ref={ref}
        type={type}
        value={value || defaultValue || ""}
      />
    </InputWrapper>
  );
}

const ForwardedRefTextInput = React.forwardRef(TextInput);

export { ForwardedRefTextInput as TextInput, TextInputProps };
