import React from "react";
import { useParams } from "react-router-dom";
import { Modal, ModalBody } from "../BuildingBlocks/Layout/Modal/Modal";
import { ModalHeader } from "../BuildingBlocks/Layout/Modal/ModalHeader/ModalHeader";
import { Button } from "../Buttons/Button/Button";
import {
  groupMissingData,
  renderGroupedMissingData
} from "./OnboardingWizard/OnboardingMailPage/OnboardingMailPage";
import type {
  OperatorChange,
  OperatorChangeSteps
} from "./OperatorChanges/OperatorChanges.types";
import type {
  TenantChange,
  TenantChangeSteps
} from "./TenantChanges/TenantChanges.types";

type ChangeProcess = OperatorChange | TenantChange;

type ChangeProcessModalProps = {
  changeProcesses: Array<ChangeProcess>;
  step: keyof OperatorChangeSteps | keyof TenantChangeSteps;
  onClose: () => void;
  isOpen: boolean;
};

function ChangeProcessModal({
  changeProcesses,
  step,
  onClose,
  isOpen
}: ChangeProcessModalProps) {
  const { processId } = useParams();
  const process = changeProcesses.find(
    (process) => process.id === parseInt(processId ?? "-1", 10)
  );
  const processStep = process?.steps[step];
  const missingData = processStep?.missingData || [];
  const groupedMissingData = groupMissingData(missingData);

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader>Fehlende Daten ergänzen</ModalHeader>
      <ModalBody scrollable>
        {renderGroupedMissingData(groupedMissingData)}
        <div className="control-container">
          <Button color="brand" onClick={onClose}>
            Fertig
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}

export { ChangeProcessModal };
