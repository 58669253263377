import { DateTime } from "luxon";
import React from "react";

import { LAST_YEAR } from "../../../utils/dates";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import type { SelectedMeter } from "../EditConfigurationModal/EditConfigurationModal";

function AutoImportNotificationMsg({
  selectedMeters
}: {
  selectedMeters: SelectedMeter[];
}) {
  const currentDate = DateTime.now();

  const meterNames = selectedMeters.map((selectedMeter) =>
    sanitizeMeterDisplayName(selectedMeter.meterName)
  );

  return (
    <span>
      {selectedMeters.length === 1 ? (
        <div>
          {`Für den neu zugewiesenen Zähler ‘${sanitizeMeterDisplayName(
            selectedMeters[0].meterName
          )}’ werden die Energiedaten zwischen 01.01.${LAST_YEAR} und ${luxonDateTimeToBackendDateOrDateTime(
            currentDate
          )} automatisch importiert. Dieser Prozess kann einige Minuten dauern.`}
        </div>
      ) : (
        <div>
          {`Für alle neu zugewiesenen Zähler werden die Energiedaten zwischen 01.01.${LAST_YEAR} und ${luxonDateTimeToBackendDateOrDateTime(
            currentDate
          )} automatisch importiert. Dieser Prozess kann einige Minuten dauern. Neu zugewiesene Zähler:`}
          {meterNames.map((name, index) => (
            <div key={index}>{name}</div>
          ))}
        </div>
      )}
    </span>
  );
}

// removes (autom. Vorschlag) at the end of a meter display name
function sanitizeMeterDisplayName(meterDisplayName: string) {
  return meterDisplayName.replace(/\(autom\. Vorschlag\)/, "").trim();
}

export { AutoImportNotificationMsg };
