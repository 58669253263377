import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import urls from "../../../urls";
import type { Paragraph6ContractResponse } from "../Paragraph6.types";

export function useParagraph6Contract(
  contractId?: string,
  options?: Partial<UseQueryOptions<Paragraph6ContractResponse | undefined>>
) {
  const {
    data: paragraph6Contract,
    isLoading,
    error
  } = useQuery({
    queryKey: ["paragraph6Contract", { contractId }],
    queryFn: () => fetchParagraph6Contract(contractId),
    enabled: !!contractId,
    ...options
  });

  async function fetchParagraph6Contract(contractId?: string) {
    if (!contractId) return undefined;

    const paragraph6ContractsUrl =
      urls.apiWithoutCamelization.paragraph6Contract(contractId);
    const response =
      await apiWithoutCamelization.get<Paragraph6ContractResponse>(
        paragraph6ContractsUrl
      );
    return response.data;
  }

  return {
    paragraph6Contract,
    isLoading,
    error
  };
}
