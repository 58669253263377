import React from "react";
import { useLocation, NavLink as RRNavLink } from "react-router-dom";
import { Nav, NavItem, NavLink } from "reactstrap";

import { ROUTES } from "../../../routes";
import type { SiteName } from "../../../utils/enums";
import { IconName } from "../../BuildingBlocks/Icon/types";
import { Brand } from "../../Layout/Brand";
import type { MenuItemData } from "../../Menu/Menu.types";
import { Group } from "../../Menu/Menu.types";
import { MenuItem, ModuleSwitchItem } from "../../Menu/MenuItem/MenuItem";

import "./AdminDashboardLayout.scss";

declare const SITE_NAME: SiteName;

const ADMIN_DASHBOARD_URLS = {
  CENTRAL_TASK_LIST: "/admin-dashboard/aufgaben",
  PROPERTY: "/admin-dashboard/liegenschaftsuebersicht",
  MSB_PROCESS: "/admin-dashboard/msb-prozess/",
  LOCATION_OVERVIEW: "/admin-dashboard/msb-prozess/lokationsuebersicht",
  METERING_SERVICE_PROVIDER: "/admin-dashboard/msb-prozess/messstellenbetreiber"
} as const;

const MENU_ITEMS: Array<MenuItemData> = [
  {
    url: ADMIN_DASHBOARD_URLS.CENTRAL_TASK_LIST,
    group: Group.ModuleChoice,
    icon: IconName.List,
    label: "Zentrale Aufgabenliste",
    showGroups: [Group.TopLevel, Group.ModuleChoice]
  },
  {
    url: ADMIN_DASHBOARD_URLS.PROPERTY,
    group: Group.ModuleChoice,
    icon: IconName.Projects,
    label: "Liegenschaften",
    showGroups: [Group.TopLevel, Group.ModuleChoice]
  },
  {
    url: ADMIN_DASHBOARD_URLS.MSB_PROCESS,
    group: Group.ModuleChoice,
    icon: IconName.Map,
    label: "MSB Prozess",
    showGroups: [Group.TopLevel, Group.ModuleChoice]
  }
];

export function AdminDashboardLayout({ children }) {
  const location = useLocation();

  function renderTabs() {
    switch (removeTrailingSlash(location.pathname)) {
      case ADMIN_DASHBOARD_URLS.CENTRAL_TASK_LIST:
        return (
          <NavItem>
            <NavLink
              tag={RRNavLink}
              to={ADMIN_DASHBOARD_URLS.CENTRAL_TASK_LIST}
            >
              Aufgaben
            </NavLink>
          </NavItem>
        );
      case ADMIN_DASHBOARD_URLS.PROPERTY:
        return (
          <NavItem>
            <NavLink tag={RRNavLink} to={ADMIN_DASHBOARD_URLS.PROPERTY}>
              Liegenschaftsübersicht
            </NavLink>
          </NavItem>
        );
      case ADMIN_DASHBOARD_URLS.MSB_PROCESS:
      case ADMIN_DASHBOARD_URLS.METERING_SERVICE_PROVIDER:
      case ADMIN_DASHBOARD_URLS.LOCATION_OVERVIEW:
        return (
          <>
            <NavItem>
              <NavLink
                tag={RRNavLink}
                to={ADMIN_DASHBOARD_URLS.LOCATION_OVERVIEW}
              >
                Lokationsübersicht
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={RRNavLink}
                to={ADMIN_DASHBOARD_URLS.METERING_SERVICE_PROVIDER}
              >
                Messstellenbetreiber
              </NavLink>
            </NavItem>
          </>
        );
      default:
      // no default
    }
  }

  return (
    <>
      <div className="AdminDashboardHeader">
        <Nav className="tabs" pills>
          {renderTabs()}
        </Nav>
      </div>

      <div className="AdminDashboardContent">
        <div>
          <div
            className="m-grid__item	m-aside-left m-aside-left--skin-dark"
            style={{
              overflow: "auto",
              top: "35px",
              scrollbarColor: "#777 transparent",
              scrollbarWidth: "thin"
            }}
          >
            <div
              className="m-aside-menu m-aside-menu--skin-dark m-aside-menu--submenu-skin-dark m-aside-menu--dropdown"
              data-menu-dropdown="true"
              data-menu-dropdown-timeout="500"
              data-menu-scrollable="true"
              data-menu-vertical="true"
              style={{ overflowX: "hidden" }}
            >
              <ul
                className="m-menu__nav m-menu__nav--dropdown-submenu-arrow"
                style={{ paddingTop: 0 }}
              >
                <Brand
                  isAdminDashboardPage
                  siteName={SITE_NAME}
                  url={ROUTES.productSelectPage}
                />

                {MENU_ITEMS.map((menuItem) => (
                  <MenuItem
                    active={
                      menuItem.url
                        ? location.pathname.includes(
                            removeTrailingSlash(menuItem.url)
                          )
                        : false
                    }
                    key={menuItem.url}
                    {...menuItem}
                  />
                ))}

                <ModuleSwitchItem isAdminDashboardPage />
              </ul>
            </div>
          </div>
          <div>{children}</div>
        </div>
      </div>
    </>
  );
}

function removeTrailingSlash(url: string) {
  return url.replace(/\/$/, "");
}
