import { createRef, useLayoutEffect, useState } from "react";

export function useIsOverflow() {
  const overflowRef = createRef<HTMLDivElement>();
  const [isOverflow, setIsOverflow] = useState(false);

  useLayoutEffect(() => {
    if (
      overflowRef.current &&
      overflowRef.current.clientWidth < overflowRef.current.scrollWidth
    ) {
      setIsOverflow(true);
    }
  }, [overflowRef]);

  return { overflowRef, isOverflow };
}
