import React, { memo } from "react";
import type { FormValues } from "../CustomForm/useCustomForm";
import type {
  FormFieldData,
  FormItemsProps
} from "../DynamicForm/FormItems/FormItems";
import type { ChangeableParticipation } from "./ChangeableParticipationFieldStatic/ChangeableParticipationFieldStatic";
import { ChangeableParticipationFieldStatic } from "./ChangeableParticipationFieldStatic/ChangeableParticipationFieldStatic";

const PARTICIPATION_FIELD_NAME = "participationsInMieterstromModel";

export interface ParticipationFieldsProps
  extends Pick<FormItemsProps, "onInput" | "allowInput" | "formErrors"> {
  formName: string;
  formItems: Record<string, FormFieldData>;
  formValues: FormValues;
  contractId: string;
  missingFields?: Array<string>;
}

/** @deprecated This component is based on the hacky method of manipulating fields within the constraints of the old forms system. It should be replaced when the parent form is reworked to use the new system.*/
function ParticipationFields({
  formItems,
  formValues,
  formErrors,
  onInput,
  allowInput,
  missingFields = []
}: ParticipationFieldsProps) {
  const isMissing =
    missingFields &&
    missingFields.length > 0 &&
    missingFields.includes(PARTICIPATION_FIELD_NAME);
  return (
    <>
      <ChangeableParticipationFieldStatic
        allowInput={allowInput}
        fieldName={PARTICIPATION_FIELD_NAME}
        formErrors={formErrors}
        formItems={formItems}
        formValues={formValues}
        isMissing={isMissing}
        participations={
          formValues[
            PARTICIPATION_FIELD_NAME
          ] as unknown as ChangeableParticipation[]
        }
        onInput={onInput}
      />
    </>
  );
}

const memoizedParticipationFields = memo(ParticipationFields);

export { memoizedParticipationFields as ParticipationFields };
