import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { useCurrentUserQuery } from "../../../hooks/useCurrentUserQuery";
import { useIds } from "../../../hooks/useIds";
import { useOperators } from "../../../hooks/useOperators";
import { usePersons } from "../../../hooks/usePersons";
import { useProject } from "../../../hooks/useProject";
import { useSiteCategories } from "../../../hooks/useSiteCategories";
import { useVariantSites } from "../../../hooks/useVariantSites";
import { MANAGER_URL } from "../../../routes";
import urls, { OperatorFeatures } from "../../../urls";
import { MENU_ITEMS } from "../Menu.constants";
import { type MenuItemData } from "../Menu.types";
import { AdminItem, MenuItem, OptiChargeItem } from "../MenuItem/MenuItem";

export interface MenuRouteProps {
  menuItemForRoute: MenuItemData;
  isStaff?: boolean;
  adminUrl?: string;
}

export function MenuRoute({
  menuItemForRoute,
  isStaff,
  adminUrl
}: MenuRouteProps) {
  const location = useLocation();
  const isManagerRoute = location.pathname.includes(MANAGER_URL);

  const { projectId, siteId, variantId } = useIds();
  const { simulationId } = useParams();
  const { currentUser } = useCurrentUserQuery();
  const { sites } = useVariantSites(variantId ?? undefined);

  const { siteCategories } = useSiteCategories(projectId ?? null, {
    enabled: !!projectId && isManagerRoute
  });

  const { project } = useProject(projectId, {
    enabled: !!projectId,
    refetchInterval: false,
    refetchOnWindowFocus: false
  });
  const { paragraph6Persons } = usePersons(variantId ?? undefined);

  const { data: direktvermarktungOperators } = useOperators({
    activeFeatures: [
      OperatorFeatures.Abschoepfung,
      OperatorFeatures.Paragraph6EEG,
      OperatorFeatures.Parkabrechnung,
      OperatorFeatures.RevenueMonitoring
    ]
  });

  const { data: revenueMonitoringOperators } = useOperators({
    activeFeatures: [OperatorFeatures.RevenueMonitoring]
  });

  const showParagraph6 = paragraph6Persons.length > 0;
  const showDirektvermarktung =
    !projectId &&
    direktvermarktungOperators &&
    direktvermarktungOperators.length > 0;
  const showDirektvermarktungProjectLevel =
    !!projectId &&
    direktvermarktungOperators &&
    direktvermarktungOperators.length > 0;
  const showRevenueMonitoring =
    !projectId &&
    revenueMonitoringOperators &&
    revenueMonitoringOperators.length > 0;
  const showRevenueMonitoringProjectLevel =
    !!projectId &&
    revenueMonitoringOperators &&
    revenueMonitoringOperators.length > 0;

  const groupFilteredItems = Object.values(MENU_ITEMS).filter(
    (item) =>
      item.url === menuItemForRoute.url ||
      menuItemForRoute.showGroups.includes(item.group)
  );

  const experimentalFilteredItems = groupFilteredItems;

  const projectHasSitesWithInvoicing = sites.some(
    (site) => site.invoicing_active
  );

  const projectHasSitesWithGuaranteeOfOrigin = sites.some(
    (site) => site.go_active
  );

  if (siteCategories) {
    const onlyFullFeedin = siteCategories.every(
      (siteCategory) => siteCategory.is_full_feedin
    );

    const onlyEEPlusAndOrPartialFeedin = siteCategories.every(
      (siteCategory) =>
        siteCategory.is_eigenerzeugung_plus ||
        siteCategory.is_partial_feedin_site
    );

    if (onlyEEPlusAndOrPartialFeedin || onlyFullFeedin) {
      const updatedOnboardingItem = {
        ...MENU_ITEMS.ONBOARDING,
        staff: true
      };

      const onboardingIndex = groupFilteredItems.findIndex(
        (item) => item === MENU_ITEMS.ONBOARDING
      );

      experimentalFilteredItems[onboardingIndex] = updatedOnboardingItem;
    }
  }

  const currentUserFilteredItems = experimentalFilteredItems
    .filter((item) => !item.staff || item.staff === isStaff)
    .filter(
      (item) =>
        item !== MENU_ITEMS.API_SETTINGS || currentUser?.has_api_settings_access
    );

  //  inject previous menu into apisettings menu
  currentUserFilteredItems.map((item) => {
    if (item.persistGroups) {
      item.showGroups = menuItemForRoute.showGroups;
    }
    return item;
  });

  let projectFilteredItems = currentUserFilteredItems.filter(
    (item) =>
      item !== MENU_ITEMS.DELIVERY_OVERVIEW || projectHasSitesWithInvoicing
  );
  projectFilteredItems = projectFilteredItems.filter(
    (item) =>
      item !== MENU_ITEMS.PERFORMANCE_MONITORING_OVERVIEW ||
      (project && project.performanceMonitoringActive)
  );

  projectFilteredItems = projectFilteredItems.filter(
    (item) =>
      item !== MENU_ITEMS.GUARANTEE_OF_ORIGIN ||
      projectHasSitesWithGuaranteeOfOrigin
  );

  projectFilteredItems = projectFilteredItems.filter(
    (item) =>
      item !== MENU_ITEMS.PARKABRECHNUNG ||
      (project && project.parkabrechnungActive)
  );

  projectFilteredItems = projectFilteredItems.filter(
    (item) =>
      item !== MENU_ITEMS.OPTI_CHARGE || (project && project.optichargeActive)
  );

  const personFilteredItems = projectFilteredItems
    .filter((item) => item !== MENU_ITEMS.PARAGRAPH6 || showParagraph6)
    .filter(
      (item) => item !== MENU_ITEMS.DIREKTVERMARKTUNG || showDirektvermarktung
    )
    .filter(
      (item) =>
        item !== MENU_ITEMS.DIREKTVERMARKTUNG_PROJECT_LEVEL ||
        showDirektvermarktungProjectLevel
    )
    .filter(
      (item) => item !== MENU_ITEMS.REVENUE_MONITORING || showRevenueMonitoring
    )
    .filter(
      (item) =>
        item !== MENU_ITEMS.REVENUE_MONITORING_PROJECT_LEVEL ||
        showRevenueMonitoringProjectLevel
    );

  return (
    <React.Fragment>
      {personFilteredItems.map((item) => {
        if (item.label === "Admin") {
          return (
            <AdminItem
              adminUrl={adminUrl}
              key={item.label}
              {...item}
              active={adminUrl === menuItemForRoute.url}
            />
          );
        } else if (item.label === "opti.charge") {
          return (
            <OptiChargeItem
              active={false}
              key={item.label}
              optiChargeUrl={urls.optiCharge()}
              {...item}
            />
          );
        } else {
          return (
            <MenuItem
              key={item.label}
              {...item}
              active={item.url === menuItemForRoute.url}
              projectId={projectId}
              simulationId={simulationId}
              siteId={siteId}
              variantId={variantId}
            />
          );
        }
      })}
    </React.Fragment>
  );
}
