import React from "react";
import { Select } from "../../BuildingBlocks/Forms/Select/Select";
import { Icon } from "../../BuildingBlocks/Icon/Icon";
import type { IconName } from "../../BuildingBlocks/Icon/types";
import { Portlet } from "../../BuildingBlocks/Layout/Portlet";
import "./Tile.scss";
import { LoadOrError } from "../../LoadOrError/LoadOrError";

interface TileFilterOption<T> {
  label: string;
  value: T;
}

interface TileProps<T> {
  title: string;
  iconName: IconName;
  children: React.ReactNode;
  error?: Error | null;
  isLoading: boolean;
  filterOptions?: Array<TileFilterOption<T>>;
  filterValue?: TileFilterOption<T>;
  onFilterChange?: (newFilter: TileFilterOption<T>) => void;
}

function Tile<T>({
  title,
  iconName,
  children,
  error,
  isLoading,
  filterOptions,
  filterValue,
  onFilterChange
}: TileProps<T>) {
  return (
    <Portlet className="Tile">
      <div className="tile-header">
        <span>
          <Icon name={iconName} />
          {title}
        </span>
        {filterOptions && filterOptions.length > 0 && (
          <Select<{ label: string; value: T }>
            className="tile-filter"
            options={filterOptions}
            value={filterValue}
            onChange={onFilterChange}
          />
        )}
      </div>
      <div className="tile-body">
        <LoadOrError error={error} loading={isLoading}>
          {children}
        </LoadOrError>
      </div>
    </Portlet>
  );
}

export { Tile, TileProps };
