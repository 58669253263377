import PropTypes from "prop-types";
import React from "react";
import { Col } from "reactstrap";

import { Row } from "../../../../BuildingBlocks/Layout/Row";

interface RegulatoryDutyDetailRowProps {
  title?: string;
  children?: React.ReactNode;
}

function RegulatoryDutyDetailRow({
  title,
  children
}: RegulatoryDutyDetailRowProps) {
  if (!children) {
    return null;
  }

  return (
    <Row mb2>
      <Col>
        <Row>
          <Col>
            {title && (
              <Row>
                <Col className="details-head">{title}</Col>
              </Row>
            )}
            <Row>
              <Col className="details-body break-word">{children}</Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

RegulatoryDutyDetailRow.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
};

export { RegulatoryDutyDetailRow };
