import { Group, Space, Stack } from "@mantine/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { ApiResponse } from "../../../../api";
import { usePersons } from "../../../../hooks/usePersons";
import { useVariantMeters } from "../../../../hooks/useVariantMeters";
import { OptiSupportEndpoints } from "../../../../urls";
import { SiteName } from "../../../../utils/enums";
import { showToast } from "../../../../utils/toast";
import { Portlet } from "../../../BuildingBlocks/Layout/Portlet";
import { Section } from "../../../BuildingBlocks/Layout/Section";
import { Button } from "../../../Buttons/Button/Button";
import { DEFAULT_PAGE_SIZE } from "../../../CustomReactTable/constants";
import { useCustomReactTableCheckboxes } from "../../../CustomReactTable/CustomReactTableHooks";
import { openErrorAlertPopup } from "../../../ErrorAlertPopup/openErrorAlertPopup";
import { LoadOrError } from "../../../LoadOrError/LoadOrError";
import { OptiSupportHelpLink } from "../../../OptiSupportHelpLink/OptiSupportHelpLink";
import { useShouldShowStaffView } from "../../../StaffViewToggle/useShouldShowStaffView";
import { SupportRequestModal } from "../../../Support/SupportModal";
import type {
  EnergyDataImportRequest,
  EnergyDataUploadResponse
} from "../../EnergyDataUploadFlow/EnergyDataUpload";
import {
  EnergyDataFileImportRequestModalType,
  EnergyDataFileImportRequestStatus,
  createEnergyDataImportRequestFromEnergyDataFile,
  getEnergyDataImportRequests,
  pollDataImportRequests
} from "../../EnergyDataUploadFlow/EnergyDataUpload";
import type { EnergyDataUploadPartialResult } from "../../EnergyDataUploadFlow/EnergyDataUploadFlow";
import { EnergyDataUploadExplanationWithTemplateDownload } from "../../EnergyDataUploadFlow/Template/EnergyDataUploadExplanationWithTemplateDownload";
import { EnergyDataImportDetailsModal } from "../EnergyDataImportDetailsModal/EnergyDataImportDetailsModal";
import { MeterIDModal } from "../EnergyDataImportRetryModals/MeterIDModal/MeterIDModal";
import { OriginModal } from "../EnergyDataImportRetryModals/OriginModal/OriginModal";
import { ParameterSetModal } from "../EnergyDataImportRetryModals/ParameterSetModal/ParameterSetModal";
import { EnergyDataUploadModal } from "../EnergyDataUploadModal/EnergyDataUploadModal";
import { useDataImportRequests } from "../hooks/useDataImportRequests";
import { AnImportIsStoppedWarning } from "./AnImportIsStoppedWarning";
import { DeleteDataImportRequestsButton } from "./DeleteDataImportRequestsButton/DeleteDataImportRequestsButton";
import { DownloadButton } from "./DownloadButton/DownloadButton";
import { EnergyDataImportRequestsTable } from "./EnergyDataImportRequestsTable/EnergyDataImportRequestsTable";
import "./EnergyDataUploadPage.scss";
import { FictionalEnergyValueManager } from "./FictionalEnergyValueManager/FictionalEnergyValueManager";
import { ReimportDataImportRequestsButton } from "./ReimportDataImportRequestsButton/ReimportDataImportRequestsButton";

declare const SITE_NAME: SiteName;

export interface EnergyDataUploadPageProps {
  variantId: number;
}

const markenergyDataImportRequestInProgress = (
  item: EnergyDataImportRequest
) =>
  item.status === EnergyDataFileImportRequestStatus.InProgress
    ? { ...item, disabledCheckbox: true }
    : item;

function EnergyDataUploadPage({ variantId }: EnergyDataUploadPageProps) {
  const { t } = useTranslation();
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [energyDataImportRequests, setEnergyDataImportRequests] = useState<
    EnergyDataImportRequest[]
  >([]);
  const [activeRetryModal, setActiveRetryModal] = useState<{
    type: EnergyDataFileImportRequestModalType;
    data: EnergyDataImportRequest | undefined;
  } | null>(null);

  const [loading, setLoading] = useState(false);
  const [pristine, setPristine] = useState(true);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [pageIndex, setPageIndex] = useState(0);
  const [ordering, setOrdering] = useState<string>();
  const [count, setCount] = useState(0);

  const {
    meters,
    sites,
    isLoading: isMetersLoading,
    error: metersError
  } = useVariantMeters(variantId);
  const { removeEnergyDataImportRequests } = useDataImportRequests();

  const { setSelection, setSelectAll, getSelectedData, customReactTableProps } =
    useCustomReactTableCheckboxes<EnergyDataImportRequest>();

  const shouldShowStaffView = useShouldShowStaffView();

  const { paragraph6Persons } = usePersons(variantId);
  const showParagraph6 = paragraph6Persons.length > 0;

  const showAnImportIsStoppedAlert = useMemo(
    () =>
      energyDataImportRequests.filter(
        (file) => file.status === EnergyDataFileImportRequestStatus.Stopped
      ).length > 0,
    [energyDataImportRequests]
  );

  const setModal = useCallback(
    (
      type: EnergyDataFileImportRequestModalType,
      isOpen: boolean,
      selectedImportRequest?: EnergyDataImportRequest
    ) => {
      setActiveRetryModal(
        isOpen ? { type, data: selectedImportRequest } : null
      );
    },
    []
  );

  const handleImportRequestResult = useCallback(
    (
      originalRequest: EnergyDataImportRequest,
      response: EnergyDataImportRequest
    ) => {
      if (
        originalRequest.status !== EnergyDataFileImportRequestStatus.Success &&
        response.status === EnergyDataFileImportRequestStatus.Success
      ) {
        showToast(
          "success",
          `Die Energiedaten der Datei ${response.energyDataFile.name} wurden erfolgreich importiert.`
        );
      } else if (
        originalRequest.status !== EnergyDataFileImportRequestStatus.Error &&
        response.status === EnergyDataFileImportRequestStatus.Error
      ) {
        showToast(
          "error",
          `Bei der Verarbeitung der Datei ${response.energyDataFile.name} ist ein Fehler aufgetreten. Die Energiedaten konnten nicht importiert werden.`
        );
      }

      setEnergyDataImportRequests((oldFiles) => {
        const updatedFiles = [...oldFiles];

        const currentFileIndex = oldFiles.findIndex(
          (file) => file.id === originalRequest.id
        );

        if (response) {
          if (currentFileIndex > -1) {
            updatedFiles[currentFileIndex] = response;
          } else {
            updatedFiles.push(response);
          }

          return updatedFiles;
        }

        return oldFiles;
      });
    },
    []
  );

  const updateEnergyDataImportRequests = useCallback(
    (pageIndex, pageSize, ordering?: string) => {
      setPageIndex(pageIndex);
      setOrdering(ordering);
      setLoading(true);

      return getEnergyDataImportRequests(
        variantId,
        pageIndex + 1,
        pageSize,
        ordering
      )
        .then((data) => {
          const { results: dataImportRequests = [], count } = data;

          setCount(count);

          setEnergyDataImportRequests(
            dataImportRequests.map(markenergyDataImportRequestInProgress)
          );

          pollDataImportRequests(dataImportRequests).forEach((promise, index) =>
            promise.then((responseResult) =>
              handleImportRequestResult(
                dataImportRequests[index],
                responseResult
              )
            )
          );
        })
        .finally(() => setLoading(false));
    },
    [handleImportRequestResult, variantId]
  );

  const getNewPage = useCallback(
    (pageIndex: number, pageSize: number, ordering?: string) => {
      setSelection([]);
      updateEnergyDataImportRequests(pageIndex, pageSize, ordering);
    },
    [setSelection, updateEnergyDataImportRequests]
  );

  const toggleUploadModal = useCallback((active?: boolean) => {
    setIsUploadModalOpen(!!active);
  }, []);

  const handleUploadResults = useCallback(
    (
      uploadPromises: Promise<ApiResponse<EnergyDataUploadResponse>>[],
      uploadedFiles: EnergyDataImportRequest[]
    ): Promise<void> =>
      Promise.allSettled(uploadPromises).then((results) => {
        const failedPromiseIds: string[] = [];

        results.forEach((result, index) => {
          if (result.status === "rejected") {
            failedPromiseIds.push(uploadedFiles[index].id);
            showToast("error", t("errors.FileUploadError"));
          }
        });

        setEnergyDataImportRequests((oldFiles) => {
          const newFiles = [...oldFiles];

          results.forEach((result, resultIndex) => {
            if (result.status === "fulfilled") {
              const oldId = uploadedFiles[resultIndex].id;
              const fileIndex = newFiles.findIndex((file) => file.id === oldId);

              if (fileIndex > -1) {
                newFiles[fileIndex] = {
                  ...newFiles[fileIndex],
                  id: result.value.data.id
                };
              }
            }
          });

          const newFilesWithoutFailedUploads = newFiles.filter(
            (file) => !failedPromiseIds.includes(file.id)
          );

          return newFilesWithoutFailedUploads;
        });
      }),
    [t]
  );

  const handleEnergyUploadStarted = useCallback(
    (energyDataUploadPartialResults: EnergyDataUploadPartialResult[]) => {
      const newFiles = energyDataUploadPartialResults.map((partialResult) =>
        createEnergyDataImportRequestFromEnergyDataFile(
          partialResult.energyDataFile,
          variantId
        )
      );

      const uploadPromises = energyDataUploadPartialResults.map(
        (result) => result.uploadPromise
      );

      setEnergyDataImportRequests((oldFiles) => [...oldFiles, ...newFiles]);

      handleUploadResults(uploadPromises, newFiles).then(() =>
        updateEnergyDataImportRequests(pageIndex, pageSize, ordering)
      );
    },
    [
      handleUploadResults,
      ordering,
      pageIndex,
      pageSize,
      updateEnergyDataImportRequests,
      variantId
    ]
  );

  const updateDataImportRequest = useCallback(
    (dataImportRequest: EnergyDataImportRequest) => {
      setEnergyDataImportRequests((oldDataImportRequests) => {
        const currentIndex = oldDataImportRequests.findIndex(
          (r) => r.id === dataImportRequest.id
        );

        if (currentIndex > -1) {
          const updatedDataImportRequests = [...oldDataImportRequests];
          updatedDataImportRequests[currentIndex] = dataImportRequest;

          return updatedDataImportRequests;
        } else {
          return [dataImportRequest, ...oldDataImportRequests];
        }
      });
    },
    []
  );

  const handleDeleteMultipleDataImportRequests = useCallback(
    (dataImportRequests: Array<EnergyDataImportRequest>) => {
      setLoading(true);

      return removeEnergyDataImportRequests(dataImportRequests)
        .then(() => {
          setSelection([]);
          setSelectAll(false);

          getEnergyDataImportRequests(variantId, pageIndex + 1, pageSize).then(
            (data) => {
              const { results = [], count } = data;

              setCount(count);

              setEnergyDataImportRequests(
                results.map(markenergyDataImportRequestInProgress)
              );
            }
          );
        })
        .catch(openErrorAlertPopup)
        .finally(() => setLoading(false));
    },
    [
      pageIndex,
      pageSize,
      removeEnergyDataImportRequests,
      setSelectAll,
      setSelection,
      variantId
    ]
  );

  const getSelectedRequests = useCallback(
    (filter?: { exceptOfStatus?: EnergyDataFileImportRequestStatus }) =>
      filter?.exceptOfStatus
        ? getSelectedData().filter(
            (item) => item.status !== filter.exceptOfStatus
          )
        : getSelectedData(),
    [getSelectedData]
  );

  const handleSupportRequestSent = useCallback(
    (updatedDataImportRequest: EnergyDataImportRequest) => {
      setModal(EnergyDataFileImportRequestModalType.Support, false);

      showToast(
        "info",
        "Ihre Anfrage an den Support wurde erfolgreich gesendet."
      );

      updateDataImportRequest(updatedDataImportRequest);
    },
    [setModal, updateDataImportRequest]
  );

  const handleRetryUploadDone = useCallback(
    (dataImportRequest: EnergyDataImportRequest) => {
      updateDataImportRequest(dataImportRequest);
      updateEnergyDataImportRequests(pageIndex, pageSize, ordering);
    },
    [
      ordering,
      pageIndex,
      pageSize,
      updateDataImportRequest,
      updateEnergyDataImportRequests
    ]
  );

  const renderModal = useCallback(() => {
    if (activeRetryModal === null || !activeRetryModal.data) {
      return null;
    }

    switch (activeRetryModal.type) {
      case EnergyDataFileImportRequestModalType.Support:
        return (
          <SupportRequestModal
            dataImportRequest={activeRetryModal.data}
            onClose={() =>
              setModal(EnergyDataFileImportRequestModalType.Support, false)
            }
            onSupportRequestSent={handleSupportRequestSent}
          />
        );
      case EnergyDataFileImportRequestModalType.Details:
        return (
          <EnergyDataImportDetailsModal
            dataImportRequest={activeRetryModal.data}
            onClose={() =>
              setModal(EnergyDataFileImportRequestModalType.Details, false)
            }
          />
        );
      case EnergyDataFileImportRequestModalType.Origin:
        return (
          <OriginModal
            dataImportRequest={activeRetryModal.data}
            onClose={() =>
              setModal(EnergyDataFileImportRequestModalType.Origin, false)
            }
            onRetryUploadDone={handleRetryUploadDone}
          />
        );
      case EnergyDataFileImportRequestModalType.Meter:
        return (
          <MeterIDModal
            dataImportRequest={activeRetryModal.data}
            meters={meters}
            sites={sites}
            onClose={() =>
              setModal(EnergyDataFileImportRequestModalType.Meter, false)
            }
            onRetryUploadDone={handleRetryUploadDone}
          />
        );
      case EnergyDataFileImportRequestModalType.ParameterSet:
        return (
          <ParameterSetModal
            dataImportRequest={activeRetryModal.data}
            onClose={() =>
              setModal(EnergyDataFileImportRequestModalType.ParameterSet, false)
            }
            onRetryUploadDone={handleRetryUploadDone}
          />
        );
      default:
        console.error(`Modal type ${activeRetryModal.type} not implemented`);
        return null;
    }
  }, [
    activeRetryModal,
    handleRetryUploadDone,
    handleSupportRequestSent,
    meters,
    setModal,
    sites
  ]);

  useEffect(() => {
    if (pristine) {
      updateEnergyDataImportRequests(pageIndex, pageSize, ordering).then(() =>
        setPristine(false)
      );
    }
  }, [
    updateEnergyDataImportRequests,
    loading,
    pageIndex,
    pageSize,
    pristine,
    ordering
  ]);

  return (
    <div className="EnergyDataUploadPage">
      <Portlet>
        {renderModal()}
        <Section>
          <Stack gap="xs">
            {SITE_NAME === SiteName.Optinode && (
              <OptiSupportHelpLink
                optiSupportEndpoint={
                  OptiSupportEndpoints.EnergiedatenBereitstellen
                }
                text="Wie lade ich manuell Energiedaten hoch?"
              />
            )}
            <Group gap="3px" justify="flex-end">
              {shouldShowStaffView && (
                <ReimportDataImportRequestsButton
                  dataImportRequests={getSelectedRequests({
                    exceptOfStatus: EnergyDataFileImportRequestStatus.InProgress
                  })}
                  setSelection={setSelection}
                  onReimport={() =>
                    updateEnergyDataImportRequests(
                      pageIndex,
                      pageSize,
                      ordering
                    )
                  }
                />
              )}
              <DownloadButton
                dataImportRequests={getSelectedRequests()}
                setLoading={setLoading}
                setSelection={setSelection}
              />
              <DeleteDataImportRequestsButton
                dataImportRequests={getSelectedRequests({
                  exceptOfStatus: EnergyDataFileImportRequestStatus.InProgress
                })}
                onDeleteMultipleImportRequests={
                  handleDeleteMultipleDataImportRequests
                }
              />
              <Button
                color="brand"
                disabled={isMetersLoading}
                onClick={() => toggleUploadModal(true)}
              >
                Energiedaten hochladen
              </Button>
            </Group>
          </Stack>

          <Space h="md" />

          {showAnImportIsStoppedAlert && <AnImportIsStoppedWarning />}
          <LoadOrError error={metersError} loading={isMetersLoading}>
            {meters && (
              <EnergyDataImportRequestsTable
                count={count}
                data={energyDataImportRequests}
                defaultPageSize={DEFAULT_PAGE_SIZE}
                fetchData={getNewPage}
                loading={loading}
                meters={meters}
                pageSize={pageSize}
                setModal={setModal}
                onPageSizeChange={setPageSize}
                {...customReactTableProps}
              />
            )}
          </LoadOrError>
        </Section>
      </Portlet>
      <Portlet>
        <div className="template-download-bottom">
          <EnergyDataUploadExplanationWithTemplateDownload
            variantId={variantId}
          />
        </div>
      </Portlet>
      {showParagraph6 && (
        <Portlet>
          <FictionalEnergyValueManager variantId={variantId} />
        </Portlet>
      )}
      {meters && (
        <EnergyDataUploadModal
          isOpen={isUploadModalOpen}
          meters={meters}
          sites={sites}
          variantId={variantId}
          onFileUploadStarted={handleEnergyUploadStarted}
          onToggle={() => toggleUploadModal(false)}
        />
      )}
    </div>
  );
}

export { EnergyDataUploadPage };
