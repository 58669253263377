import classnames from "classnames";
import React from "react";
import type { RoundedNumberFormatProps } from "../../../../../utils/RoundedNumberFormat";
import { RoundedNumberFormat } from "../../../../../utils/RoundedNumberFormat";
import { ErrorText } from "../utils/ErrorText";
import { WarningText } from "../utils/WarningText";
import "./Input.scss";

interface InputProps
  extends Pick<
    RoundedNumberFormatProps,
    "decimalScale" | "isAllowed" | "allowNegative" | "thousandSeparator"
  > {
  id?: string;
  name?: string;
  type: React.HTMLInputTypeAttribute;
  value: number | string | null;
  inputGroupText?: string;
  errorTexts?: Array<string>;
  warningTexts?: Array<string>;
  step?: number;
  min?: number;
  max?: number;
  placeholder?: string;
  invalid?: boolean;
  warned?: boolean;
  disabled?: boolean;
  className?: string;
  extraInputClasses?: string;
  onChange: (value: number | string | undefined) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onKeyPressEnter?: () => void;
}

/** @deprecated Please use `TextInput` or `NumberInput`. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function Input(
  {
    type,
    value,
    inputGroupText,
    errorTexts = [],
    warningTexts = [],
    step,
    min,
    max,
    className,
    extraInputClasses = "",
    invalid,
    warned,
    onChange,
    onKeyPressEnter,
    ...otherProps
  }: InputProps,
  ref: React.Ref<HTMLInputElement>
) {
  function handleKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter" && onKeyPressEnter) {
      onKeyPressEnter();
    }
  }

  const isNumberType = type === "number";
  const inputValue = !value && value !== 0 ? "" : value;
  const inputClassName = classnames(
    "form-control",
    "m-input",
    extraInputClasses,
    {
      "is-invalid": invalid || errorTexts.length > 0,
      warning:
        (!invalid && warned) ||
        (errorTexts.length === 0 && warningTexts.length > 0)
    }
  );
  const input = isNumberType ? (
    <RoundedNumberFormat
      {...otherProps}
      className={inputClassName}
      displayType="input"
      getInputRef={ref}
      max={max}
      min={min}
      step={step}
      value={inputValue}
      onKeyUp={handleKeyUp}
      onValueChange={({ floatValue }) => onChange(floatValue)}
    />
  ) : (
    <input
      {...otherProps}
      className={inputClassName}
      max={max}
      min={min}
      ref={ref}
      step={step}
      type={type}
      value={inputValue}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        onChange(e.target.value)
      }
      onKeyUp={handleKeyUp}
    />
  );

  return (
    <div className={classnames("FormField Input", className)}>
      {inputGroupText ? (
        <div className="input-group">
          {input}
          <div className="input-group-append">
            <span className="input-group-text">{inputGroupText}</span>
          </div>
          {errorTexts.map(ErrorText)}
          {warningTexts.map(WarningText)}
        </div>
      ) : (
        <div>
          {input}
          {errorTexts.map(ErrorText)}
          {warningTexts.map(WarningText)}
        </div>
      )}
    </div>
  );
}

/** @deprecated Please use `TextInput` or `NumberInput`. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
const ForwardedRefInput = React.forwardRef(Input);

export { ForwardedRefInput as Input, InputProps };
