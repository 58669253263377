import PropTypes from "prop-types";
import React from "react";
import ReactMarkdown from "react-markdown";

import type { RegulatoryDuty } from "../../../utils/backend-types";
import { EMPTY_FIELD } from "../../../utils/constants";
import { RegulatoryDutyDetailRow } from "../../RegulatoryDuties/RegulatoryDutyList/RegulatoryDutyItem/RegulatoryDutyDetailRow/RegulatoryDutyDetailRow";

interface DescriptionProps {
  regulatoryDuty: RegulatoryDuty | null;
  userDefinedDescription: string | null;
}

function Description({
  regulatoryDuty,
  userDefinedDescription
}: DescriptionProps) {
  if (userDefinedDescription) {
    return (
      <div className="user-defined-description">
        <ReactMarkdown linkTarget="_blank">
          {userDefinedDescription}
        </ReactMarkdown>
      </div>
    );
  } else if (regulatoryDuty) {
    return (
      <div className="regulatory-duty-rows">
        <RegulatoryDutyDetailRow>
          {regulatoryDuty.description && (
            <ReactMarkdown linkTarget="_blank">
              {regulatoryDuty.description}
            </ReactMarkdown>
          )}
        </RegulatoryDutyDetailRow>
        <RegulatoryDutyDetailRow title="Empfänger">
          {regulatoryDuty.obligee}
        </RegulatoryDutyDetailRow>
        <RegulatoryDutyDetailRow title="Erfüllungsprozess">
          {regulatoryDuty.fulfillmentProcess && (
            <ReactMarkdown linkTarget="_blank">
              {regulatoryDuty.fulfillmentProcess}
            </ReactMarkdown>
          )}
        </RegulatoryDutyDetailRow>
        <RegulatoryDutyDetailRow title="Gesetzlich vorgegebene Frist">
          {regulatoryDuty.deadline}
        </RegulatoryDutyDetailRow>
        <RegulatoryDutyDetailRow title="Gesetzliche Grundlage">
          {regulatoryDuty.legalBasis}
        </RegulatoryDutyDetailRow>
        <RegulatoryDutyDetailRow title="Weitere Informationen">
          {regulatoryDuty.additionalInformation && (
            <ReactMarkdown linkTarget="_blank">
              {regulatoryDuty.additionalInformation}
            </ReactMarkdown>
          )}
        </RegulatoryDutyDetailRow>
      </div>
    );
  } else {
    return <span>{EMPTY_FIELD}</span>;
  }
}

Description.propTypes = {
  regulatoryDuty: PropTypes.object,
  userDefinedDescription: PropTypes.string
};

export { Description };
