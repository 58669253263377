import { useQuery } from "@tanstack/react-query";
import api from "../../api";
import type { StartPageElectricityTax } from "../../components/StartPage/StartPage.types";
import urls from "../../urls";

function useStartPageElectricityTax(year: number, projectId?: string) {
  const { data, error, isLoading } = useQuery({
    queryKey: urls.startPage.electricityTax.key(year, projectId),
    queryFn: () => fetchStartPageElectricityTax(year, projectId)
  });

  async function fetchStartPageElectricityTax(
    year: number,
    projectId?: string
  ) {
    const response = await api.get<StartPageElectricityTax>(
      urls.startPage.electricityTax.url(year, projectId)
    );
    return response.data;
  }

  return { data, error, isLoading };
}

export { useStartPageElectricityTax };
