const numberFormatter = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 3
});

export function toStr(value: number | string | null): string {
  if (value === null || value === "") {
    return "";
  }

  if (typeof value === "string") {
    return value;
  }

  return Number.isFinite(value)
    ? numberFormatter.format(value)
    : value.toString();
}
