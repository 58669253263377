import { useQueryClient } from "@tanstack/react-query";
import classnames from "classnames";
import { toPng } from "html-to-image";
import { DateTime } from "luxon";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useMatch, useNavigate } from "react-router-dom";
import type {
  DefaultEdgeOptions,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  NodeProps
} from "reactflow";
import {
  Controls,
  ReactFlow as FlowGraph,
  MiniMap,
  getConnectedEdges,
  getRectOfNodes,
  getTransformForBounds,
  useEdgesState,
  useNodesState,
  useReactFlow,
  useStoreApi
} from "reactflow";
import "reactflow/dist/style.css";
import api from "../../api";
import { useSitesDetail } from "../../hooks/useSitesDetail";
import { useStructureViewFlowDiagram } from "../../hooks/useStructureViewFlowDiagram";
import type { UpdateMeteringEdgePayload } from "../../hooks/useStructureViewFlowDiagramMutations";
import {
  DIAGRAM_QUERY_KEY,
  useStructureViewFlowDiagramMutations
} from "../../hooks/useStructureViewFlowDiagramMutations";
import { ROUTES } from "../../routes";
import urls from "../../urls";
import type { Meter, MeteringDirection } from "../../utils/backend-types";
import { ObjectName, StructureDiagramObjectName } from "../../utils/enums";
import { downloadFileAsName } from "../../utils/files/downloadFileAsName";
import { getLinkToComponentEditModal } from "../../utils/getLinkToComponentEditModal";
import { showToast } from "../../utils/toast";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { DataWarningAlert } from "../StructureView/DataWarningAlert/DataWarningAlert";
import { StructureViewMode } from "../StructureView/StructureView.constants";
import { UserAccessContext } from "../StructureView/UserAccessContext";
import {
  generateBlobImage,
  getFileName
} from "../StructureView/utils/createMeteringConceptPdf";
import { EdgePopup } from "../StructureViewDiagram/EdgePopup/EdgePopup";
import type { NodePopupData } from "../StructureViewDiagram/NodePopup/NodePopup";
import { NodePopup } from "../StructureViewDiagram/NodePopup/NodePopup";
import type {
  EdgeData,
  SketchData
} from "../StructureViewDiagram/StructureViewDiagram";
import { ConnectionPointDirection } from "../StructureViewDiagram/StructureViewDiagram";
import { CustomConnectionLine } from "./CustomGraphComponents/CustomConnectionLine/CustomConnectionLine";
import { CustomContainerElementNode } from "./CustomGraphComponents/CustomContainerElementNode/CustomContainerElementNode";
import { CustomContainerElementPopup } from "./CustomGraphComponents/CustomContainerElementPopup/CustomContainerElementPopup";
import { CustomElementMenu } from "./CustomGraphComponents/CustomElementMenu/CustomElementMenu";
import type { CustomFlowNodeData } from "./CustomGraphComponents/CustomNode/CustomFlowNode";
import { CustomFlowNode } from "./CustomGraphComponents/CustomNode/CustomFlowNode";
import { CustomTextElementNode } from "./CustomGraphComponents/CustomTextElementNode/CustomTextElementNode";
import { CustomTextElementPopup } from "./CustomGraphComponents/CustomTextElementPopup/CustomTextElementPopup";
import { FloatingEdge } from "./CustomGraphComponents/FloatingEdge/FloatingEdge";
import { DownloadConfirmationModal } from "./DownloadConfirmationModal/DownloadConfirmationModal";
import "./StructureViewFlowDiagram.scss";
import { getConnectedSourceEdgesOfNode } from "./utils/getConnectedSourceEdgesOfMeter";
import { getFlowEdgesFromEdges } from "./utils/getFlowEdgesFromEdges";
import { getFlowNodesFromContainers } from "./utils/getFlowNodesFromContainers";
import { getFlowNodesFromNodes } from "./utils/getFlowNodesFromNodes";
import { getFlowNodesFromTextBlocks } from "./utils/getFlowNodesFromTextBlocks";
import { getSnapPoints } from "./utils/getSnapPoints";

const connectionLineStyle = {
  strokeWidth: 3,
  stroke: "black"
};

const STRUCTURE_VIEW_IMAGE_WIDTH = 2560;
const STRUCTURE_VIEW_IMAGE_HEIGHT = 1440;

export enum DownloadLoading {
  None,
  Download,
  DownloadWithUpload
}

const defaultEdgeOptions: DefaultEdgeOptions = {
  style: { strokeWidth: 2, stroke: "black" },
  type: "floating",
  interactionWidth: 100
};

const meteringConceptEdgeOptions: DefaultEdgeOptions = {
  style: { strokeWidth: 2, stroke: "black" },
  type: "step"
};

const edgeTypes = {
  floating: FloatingEdge
};

const nodeTypes = (
  mode: Exclude<StructureViewMode, StructureViewMode.Old>,
  edges: Array<EdgeData> = [],
  meters?: Array<Meter>
) => {
  return {
    custom: (props: NodeProps<CustomFlowNodeData>) => {
      let meteringDirection: MeteringDirection | null = null;
      if (props.data.type === ObjectName.Meter) {
        const meter = meters?.find(
          (meter) => meter.id === props.data.componentId
        );
        if (meter) {
          meteringDirection = meter.meteringDirection;
        }
      }
      const connectedSourceEdges = getConnectedSourceEdgesOfNode(
        props.id,
        edges
      );
      return (
        <CustomFlowNode
          {...props}
          connectedSourceEdges={connectedSourceEdges}
          meteringDirection={meteringDirection}
          mode={mode}
        />
      );
    },
    text: (props) => {
      return <CustomTextElementNode {...props} mode={mode} />;
    },
    container: (props) => {
      return <CustomContainerElementNode {...props} mode={mode} />;
    }
  };
};

interface StructureViewFlowDiagramProps extends Omit<SketchData, "nodes"> {
  setShowNotAccessibleModal?: (show: boolean) => void;
  report: boolean;
  projectId: string;
  siteId: number;
  nodes: Array<Node>;
  setCoordinates: (coordinates: { x: number; y: number }) => void;
  centerCoordinates: { x: number; y: number };
  invalidateMeterData: () => void;
  mode: Exclude<StructureViewMode, StructureViewMode.Old>;
  meters?: Array<Meter>;
}

function StructureViewFlowDiagram({
  siteId,
  nodes: initialNodes,
  edges: initialEdges,
  icons,
  projectId,
  centerCoordinates,
  mode,
  meters,
  invalidateMeterData,
  setCoordinates,
  setShowNotAccessibleModal
}: StructureViewFlowDiagramProps) {
  const { getNodes, getIntersectingNodes } = useReactFlow();
  const {
    downloadMeteringConceptPDFMutation,
    deleteContainerElementNodeMutation,
    deleteTextElementNodeMutation,
    updateNodeIconMutation,
    updateNodePositionMutation,
    updateContainerColorMutation,
    updateTextConfigMutation,
    updateMeteringConceptEdgeMutation,
    createContainerElementNodeMutation,
    createTextElementNodeMutation
  } = useStructureViewFlowDiagramMutations(siteId, mode);

  const queryClient = useQueryClient();
  const queryKey = DIAGRAM_QUERY_KEY(mode, siteId);
  const store = useStoreApi();
  const {
    height,
    width,
    transform: [transformX, transformY, zoomLevel]
  } = store.getState();
  const setCenterOfViewport = useCallback(() => {
    const zoomMultiplier = 1 / zoomLevel;

    const centerX = -transformX * zoomMultiplier + (width * zoomMultiplier) / 2;
    const centerY =
      -transformY * zoomMultiplier + (height * zoomMultiplier) / 2;
    setCoordinates({ x: Math.round(centerX), y: Math.round(centerY) });
  }, [height, setCoordinates, transformX, transformY, width, zoomLevel]);

  useEffect(() => {
    setCenterOfViewport();
  }, [setCenterOfViewport]);

  const userCanCreateAndDelete = useContext(UserAccessContext);
  const [downloadLoading, setDownloadLoading] = useState(DownloadLoading.None);
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [nodePopupId, setNodePopupId] = useState<string | null>(null);
  const [nodePopupData, setNodePopupData] = useState<NodePopupData | null>(
    null
  );
  const [edgePopupId, setEdgePopupId] = useState<string | null>(null);
  const [popupXY, setPopupXY] = useState<[number, number] | null>(null);
  const [addEdgeFromNode, setAddEdgeFromNode] = useState<string | null>(null);
  const [addEdgeToNode, setAddEdgeToNode] = useState<string | null>(null);

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(
    getFlowEdgesFromEdges(initialEdges)
  );
  const [shiftIsPressed, setShiftIsPressed] = useState(false);
  const { data: siteDetails, isLoading: siteDetailsLoading } =
    useSitesDetail(siteId);

  const navigate = useNavigate();
  const managerMatch = useMatch(ROUTES.managerVariantStructure + "/*");
  const currentDate = DateTime.now().toFormat("yyyyLLdd_HHmmss");
  const flowGraphRef = useRef<HTMLDivElement>(null);
  const nodeTypesWithMode = useMemo(
    () => nodeTypes(mode, initialEdges, meters),
    [initialEdges, meters, mode]
  );
  const unConfirmedMeters = nodes.filter(
    (node) =>
      node.data.tooltipData?.isConsumptionShareEditable &&
      !node.data.tooltipData?.isConsumptionShareConfirmed
  );
  const edgeOptions =
    mode === StructureViewMode.New
      ? defaultEdgeOptions
      : meteringConceptEdgeOptions;

  const classes = classnames(
    "StructureViewFlowDiagram",
    { "connecting-nodes": !!addEdgeFromNode && !addEdgeToNode },
    { "edge-to-save": !!addEdgeFromNode && !!addEdgeToNode }
  );
  const removeEdge = useCallback(
    (source: string, target: string) => {
      const newEdges = edges.filter(
        (edge) => edge.source !== source || edge.target !== target
      );
      setEdges(newEdges);
      setAddEdgeToNode(null);
    },
    [edges, setEdges]
  );

  const handleKeydown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape" && addEdgeFromNode) {
        if (addEdgeFromNode && addEdgeToNode) {
          removeEdge(addEdgeFromNode, addEdgeToNode);
        }

        setAddEdgeFromNode(null);
        setAddEdgeToNode(null);
      } else if (e.key === "Shift") {
        setShiftIsPressed(true);
      }
    },
    [addEdgeFromNode, addEdgeToNode, removeEdge]
  );

  const handleKeyup = useCallback((e: KeyboardEvent) => {
    if (e.key === "Shift") {
      setShiftIsPressed(false);
    }
  }, []);

  useEffect(() => {
    setEdges(getFlowEdgesFromEdges(initialEdges));
  }, [initialEdges, setEdges]);

  useEffect(() => {
    setNodes(initialNodes);
  }, [initialNodes, setNodes]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown);
    document.addEventListener("keyup", handleKeyup);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
      document.removeEventListener("keyup", handleKeyup);
    };
  }, [handleKeydown, handleKeyup]);

  function handleNodesChange(newNodes: Array<NodeChange>) {
    onNodesChange(newNodes);
  }
  function handleEdgesChange(newEdges: Array<EdgeChange>) {
    onEdgesChange(newEdges);
  }

  function generatePNGImage(): Promise<void | string> {
    const nodesBounds = getRectOfNodes(getNodes());
    //Sometimes the bounds are too small and names are cut off, so we increase the width by 15%
    nodesBounds.width += nodesBounds.width * 0.15;
    const transform = getTransformForBounds(
      nodesBounds,
      STRUCTURE_VIEW_IMAGE_WIDTH,
      STRUCTURE_VIEW_IMAGE_HEIGHT,
      0.5,
      2
    );
    const reactFlowViewport = document.querySelector(".react-flow__viewport");
    if (reactFlowViewport) {
      return toPng(reactFlowViewport as HTMLElement, {
        backgroundColor: "white",
        width: STRUCTURE_VIEW_IMAGE_WIDTH,
        height: STRUCTURE_VIEW_IMAGE_HEIGHT,
        quality: 1,
        style: {
          width: STRUCTURE_VIEW_IMAGE_WIDTH.toString(),
          height: STRUCTURE_VIEW_IMAGE_HEIGHT.toString(),
          transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`
        }
      });
    }
    return Promise.resolve();
  }

  async function handleClickExportMeteringConceptPDF(uploadToTodo = false) {
    if (uploadToTodo) {
      setDownloadLoading(DownloadLoading.DownloadWithUpload);
    } else {
      setDownloadLoading(DownloadLoading.Download);
    }
    const blob = await generateBlobImage(getNodes);
    if (!blob) {
      setDownloadLoading(DownloadLoading.None);
      showToast("error", "Fehler beim Erstellen des Bildes");
    } else {
      const image = new File(
        [blob],
        getFileName(siteDetails, currentDate, "pdf"),
        { type: blob.type }
      );
      await downloadMeteringConceptPDFMutation.mutateAsync(
        {
          uploadToTodo,
          image,
          siteDetails,
          siteId
        },
        {
          onSettled: () => {
            setDownloadLoading(DownloadLoading.None);
          },
          onSuccess: async (response) => {
            let taskIds;
            if (response) {
              taskIds = response.taskIds;
            }
            if (taskIds && taskIds[0]) {
              try {
                await downloadFileAsName(
                  urls.api.mkExportFinished(taskIds[0]),
                  getFileName(siteDetails, currentDate, "pdf")
                );
              } catch (error) {
                showToast("error", error);
                return;
              }
              setDownloadModalOpen(false);
              showToast(
                "success",
                "Messkonzeptdokument erfolgreich exportiert!",
                { position: "top-center" }
              );
            }
          },
          onError: (error) => {
            showToast("error", error);
          }
        }
      );
    }
  }

  function handleClickDownloadGraph() {
    if (mode === StructureViewMode.MeteringConcept) {
      setDownloadModalOpen(true);
    } else {
      generatePNGImage().then((dataUrl) => {
        if (!dataUrl) {
          return;
        }
        const a = document.createElement("a");

        a.setAttribute(
          "download",
          getFileName(siteDetails, currentDate, "png")
        );
        a.setAttribute("href", dataUrl);
        a.click();
      });
    }
  }

  function addEdge(source: string, target: string) {
    const newEdge: Edge = {
      source: source,
      target: target,
      id: "000"
    };

    setEdges([...edges, newEdge]);
    setAddEdgeToNode(target);
  }

  function handleNodeMouseEnter(_, node: Node) {
    if (
      addEdgeFromNode &&
      canAddEdge(addEdgeFromNode, node.id) &&
      node.type !== "text" &&
      node.type !== "container"
    ) {
      addEdge(addEdgeFromNode, node.id);
    }
  }
  function handleNodeMouseLeave(_, node: Node) {
    if (addEdgeFromNode && addEdgeToNode === node.id) {
      removeEdge(addEdgeFromNode, node.id);
    }
  }
  function isEdgeBetweenNodes(edge: Edge, source: string, target: string) {
    return (
      (edge.source === source && edge.target === target) ||
      (edge.target === source && edge.source === target)
    );
  }

  function canAddEdge(from: string, to: string) {
    if (!addEdgeFromNode || from === to) {
      return false;
    }

    const edgeExistsAlready = !!edges.find((edge) =>
      isEdgeBetweenNodes(edge, from, to)
    );

    return !edgeExistsAlready;
  }

  function handleNodeDeleted() {
    hideNodePopup();
  }
  function hideNodePopup() {
    setNodePopupId(null);
  }
  function showEdgePopup(id: string, x: number, y: number) {
    setPopupXY([x, y]);
    setEdgePopupId(id);
  }

  function hideEdgePopup() {
    setEdgePopupId(null);
  }
  function deleteEdge(id: string) {
    const edge = edges.find((edge) => edge.id === id);

    if (edge && edge.source && edge.target) {
      removeEdge(edge.source, edge.target);
      api
        .delete(urls.api.sketchEdgesDetail(parseInt(id)))
        .catch((error) => {
          if (edge.source && edge.target) {
            addEdge(edge.source, edge.target);
          }

          showToast("error", error);
        })
        .finally(() => {
          queryClient.invalidateQueries({
            queryKey
          });
          invalidateMeterData();
        });
    }
  }

  function handleClickDeleteEdge(id: string) {
    deleteEdge(id);
    hideEdgePopup();
  }

  async function handleClickDeleteTextBox(id: string) {
    await deleteTextElementNodeMutation.mutateAsync(id, {
      onError: (error) => {
        showToast("error", error);
      },
      onSettled: () => {
        hideNodePopup();
      }
    });
  }

  async function handleClickDeleteContainerElement(id: string) {
    await deleteContainerElementNodeMutation.mutateAsync(id, {
      onError: (error) => {
        showToast("error", error);
      },
      onSettled: () => {
        hideNodePopup();
      }
    });
  }

  function handleNodeDragEnd(
    event: React.MouseEvent<Element, MouseEvent>,
    _,
    nodes: Array<Node>
  ) {
    nodes.forEach((node) => {
      if (node.type === "container") {
        const containerChildren = getIntersectingNodes(node);
        containerChildren.forEach((child) => {
          updateNodePosition(
            child.id,
            child.position.x,
            child.position.y,
            child.type,
            child.parentId,
            child.positionAbsolute?.x,
            child.positionAbsolute?.y
          );
        });
      }
      updateNodePosition(
        node.id,
        node.position.x,
        node.position.y,
        node.type,
        node.parentId,
        node.positionAbsolute?.x,
        node.positionAbsolute?.y
      );
    });
  }

  function updateMeteringEdge(id: string, isSource?: boolean) {
    const foundEdge = edges.find((edge) => edge.id === id);
    if (foundEdge) {
      let newPosition: ConnectionPointDirection;
      if (isSource) {
        newPosition =
          foundEdge.sourceHandle === ConnectionPointDirection.Right
            ? ConnectionPointDirection.Bottom
            : ConnectionPointDirection.Right;
      } else {
        newPosition =
          foundEdge.targetHandle === ConnectionPointDirection.Left
            ? ConnectionPointDirection.Top
            : ConnectionPointDirection.Left;
      }
      const payload: UpdateMeteringEdgePayload = {
        fromConnectionPoint: isSource
          ? newPosition
          : (foundEdge.sourceHandle as ConnectionPointDirection),
        toConnectionPoint: isSource
          ? (foundEdge.targetHandle as ConnectionPointDirection)
          : newPosition
      };
      updateMeteringConceptEdgeMutation.mutateAsync({ id, payload });
    }
    hideEdgePopup();
  }

  function updateNodePosition(
    nodeId: string,
    x: number,
    y: number,
    type?: string,
    parentId?: string,
    xAbsolute?: number,
    yAbsolute?: number
  ) {
    const newNodes = [...nodes];
    const node = newNodes.find((node) => node.id === nodeId);
    if (!node) {
      return;
    }
    const connectedEdges = getConnectedEdges([node], edges);
    const { snapRelativeX, snapRelativeY, snapAbsoluteX, snapAbsoluteY } =
      getSnapPoints(nodeId, newNodes, connectedEdges, x, y, mode);

    const newX = snapAbsoluteX ?? xAbsolute ?? x;
    const newY = snapAbsoluteY ?? yAbsolute ?? y;
    let newRelativeX = snapRelativeX ?? x;
    let newRelativeY = snapRelativeY ?? y;
    let newParentId: string | null | undefined = parentId;

    if (type === "custom") {
      const intersectingNodes = getIntersectingNodes(node);

      const containerNode = intersectingNodes.find(
        (node) => node.type === "container"
      );
      if (containerNode) {
        if (!parentId) {
          newRelativeX = x - containerNode.position.x;
          newRelativeY = y - containerNode.position.y;
          newParentId = containerNode.id;
        }
      } else {
        if (parentId) {
          newRelativeX = xAbsolute ?? x;
          newRelativeY = yAbsolute ?? y;
          newParentId = null;
        }
      }
    }

    // save the old values in case of failure
    const oldX = node.position.x;
    const oldY = node.position.y;

    // update the values
    node.position.x = newRelativeX;
    node.position.y = newRelativeY;
    node.positionAbsolute = {
      x: newX,
      y: newY
    };
    node.parentId = newParentId ?? undefined;

    setNodes(newNodes);

    //make an interface for the type below
    const payload = {
      xPosition: Math.round(node.positionAbsolute.x),
      yPosition: Math.round(node.positionAbsolute.y),
      xRelative: Math.round(newRelativeX),
      yRelative: Math.round(newRelativeY),
      parent: newParentId
    };
    const nodeUpdateUrl =
      type === "text"
        ? urls.api.standaloneSketchElementsTextBlockOptions(nodeId)
        : type === "container"
          ? urls.api.standaloneSketchElementsContainerOptions(nodeId)
          : urls.api.standaloneSketchUpdate(nodeId);

    updateNodePositionMutation.mutateAsync(
      { updateUrl: nodeUpdateUrl, payload },
      {
        onError: (error) => {
          // reset position on error
          node.position.x = oldX;
          node.position.y = oldY;
          setNodes(newNodes);
          showToast("error", error);
        }
      }
    );
  }

  const handleEnableAddEdgeMode = useCallback((id: string) => {
    hideNodePopup();
    setAddEdgeFromNode(id);
  }, []);

  async function handleChangeIcon(imageName: string) {
    const nodeIndex = nodes.findIndex((node) => node.id === nodePopupId);
    const imageData = icons.find((icon) => icon.imageName === imageName);
    const imageUrl = imageData ? imageData.imageUrl : null;
    if (nodeIndex > -1 && imageUrl) {
      const newNodes = [...nodes];
      const oldNode = nodes[nodeIndex];
      const newNode = { ...oldNode };
      newNode.data.image = imageUrl;
      newNodes[nodeIndex] = newNode;
      setNodes(newNodes);
      hideNodePopup();

      await updateNodeIconMutation.mutateAsync(
        { nodeId: newNode.id, icon: imageName },
        {
          onError: (error) => {
            const resetNodes = [...newNodes];
            resetNodes[nodeIndex] = oldNode;
            setNodes(resetNodes);
            showToast("error", error);
          }
        }
      );
    }
  }

  function showNodePopup(node: Node, x: number, y: number) {
    setPopupXY([x, y]);
    setNodePopupData({
      name: node.data.tooltipData
        ? node.data.tooltipData.name
        : node.data.label,
      icon: node.data.image as string | undefined,
      iconColor: node.data.color,
      active: node.data.active,
      componentId: node.data.componentId,
      componentType: node.data.type,
      type: node.type,
      fontWeight: node.data.fontWeight,
      fontSize: node.data.fontSize,
      detail:
        node.data.tooltipData &&
        Object.prototype.hasOwnProperty.call(node.data.tooltipData, "type") &&
        Object.prototype.hasOwnProperty.call(node.data.tooltipData, "text")
          ? {
              type: node.data.tooltipData.type,
              text: node.data.tooltipData.text,
              isConsumptionShareEditable:
                node.data.tooltipData.isConsumptionShareEditable
            }
          : null
    });
    setNodePopupId(node.id ?? null);
    setEdgePopupId(null);
  }

  function saveEdge(from: string, to: string) {
    api
      .post(urls.api.sketchEdges(), {
        fromNode: from,
        toNode: to
      })
      .then((response) => {
        const edgeIndex = edges.findIndex((edge) =>
          isEdgeBetweenNodes(edge, from, to)
        );

        if (edgeIndex > -1) {
          const newEdges = [...edges];
          newEdges[edgeIndex].id = response.data.id.toString();
          setEdges(newEdges);
        }
      })
      .catch((error) => {
        removeEdge(from, to);
        showToast("error", error);
      })
      .finally(() => {
        invalidateMeterData();
      });
  }

  function handleNodeDragStart() {
    setPopupXY(null);
    setNodePopupData(null);
    setNodePopupId(null);
  }

  function handleNodeClick(
    event: React.MouseEvent<Element, MouseEvent>,
    node: Node
  ) {
    const bounds = flowGraphRef?.current?.getBoundingClientRect();
    const left = bounds?.left ?? 0;
    const top = bounds?.top ?? 0;
    let clearAddEdgeFromNode = true;
    if (addEdgeFromNode && addEdgeToNode === node.id) {
      saveEdge(addEdgeFromNode, node.id);
    } else if (shiftIsPressed) {
      setAddEdgeFromNode(node.id ?? null);
      clearAddEdgeFromNode = false;
    } else {
      showNodePopup(node, event.clientX - left, event.clientY - top);
    }
    if (clearAddEdgeFromNode) {
      setAddEdgeFromNode(null);
    }
  }

  function handleNodeDoubleClick(
    event: React.MouseEvent<Element, MouseEvent>,
    node: Node
  ) {
    const componentId = node.data.componentId;
    const objectName = node.data.type;
    if (managerMatch && componentId && objectName) {
      const path = getLinkToComponentEditModal(
        objectName,
        componentId,
        managerMatch
      );
      if (path) {
        navigate(path);
      }
    }
    hideNodePopup();
  }

  function handleEdgeClick(
    event: React.MouseEvent<Element, MouseEvent>,
    edge: Edge
  ) {
    const bounds = flowGraphRef?.current?.getBoundingClientRect();
    const left = bounds?.left ?? 0;
    const top = bounds?.top ?? 0;
    if (edge && edge.id) {
      showEdgePopup(edge.id, event.clientX - left, event.clientY - top);
    }
  }
  async function handleCreateTextElement() {
    await createTextElementNodeMutation.mutateAsync(centerCoordinates);
  }
  async function handleCreateContainerElement() {
    await createContainerElementNodeMutation.mutateAsync(centerCoordinates);
  }

  async function handleContainerColorChange(color: string, nodeId: string) {
    await updateContainerColorMutation.mutateAsync({ id: nodeId, color });
  }

  async function handleTextConfigChange(
    fontWeight: number,
    fontSize: number,
    nodeId: string
  ) {
    await updateTextConfigMutation.mutateAsync({
      id: nodeId,
      fontWeight,
      fontSize
    });
  }

  return (
    <>
      {unConfirmedMeters.length > 0 && (
        <DataWarningAlert
          objectNames={unConfirmedMeters.map(
            (node) => node.data.tooltipData.name
          )}
        />
      )}

      <div
        className={classes}
        data-testid={
          mode === StructureViewMode.New
            ? "structure-view-flow-diagram"
            : "structure-view-metering-concept"
        }
        style={{ width: "100%", height: "100%" }}
      >
        <FlowGraph
          connectionLineComponent={CustomConnectionLine}
          connectionLineStyle={connectionLineStyle}
          defaultEdgeOptions={edgeOptions}
          edges={edges}
          edgeTypes={edgeTypes}
          fitView
          fitViewOptions={{ padding: 0.1 }}
          minZoom={0.1}
          nodeDragThreshold={1}
          nodes={nodes}
          nodeTypes={nodeTypesWithMode}
          ref={flowGraphRef}
          selectNodesOnDrag={false}
          onEdgeClick={handleEdgeClick}
          onEdgesChange={handleEdgesChange}
          onMoveEnd={() => setCenterOfViewport()}
          onNodeClick={handleNodeClick}
          onNodeDoubleClick={handleNodeDoubleClick}
          onNodeDragStart={handleNodeDragStart}
          onNodeDragStop={handleNodeDragEnd}
          onNodeMouseEnter={handleNodeMouseEnter}
          onNodeMouseLeave={handleNodeMouseLeave}
          onNodesChange={handleNodesChange}
          onPaneClick={() => {
            setPopupXY(null);
            setAddEdgeFromNode(null);
            setNodePopupData(null);
            setNodePopupId(null);
          }}
          onSelectionDragStop={(event, nodes) =>
            handleNodeDragEnd(event, null, nodes)
          }
        >
          <MiniMap pannable zoomable />
          <CustomElementMenu
            siteDetailsLoading={siteDetailsLoading}
            onAddBoxElement={handleCreateContainerElement}
            onAddTextElement={handleCreateTextElement}
            onDownloadGraph={handleClickDownloadGraph}
          />
          <Controls />
        </FlowGraph>
        {downloadModalOpen && (
          <DownloadConfirmationModal
            downloadLoading={downloadLoading === DownloadLoading.Download}
            downloadWithUploadLoading={
              downloadLoading === DownloadLoading.DownloadWithUpload
            }
            onCancel={() => setDownloadModalOpen(false)}
            onDownload={() => handleClickExportMeteringConceptPDF()}
            onDownloadWithUpload={() =>
              handleClickExportMeteringConceptPDF(true)
            }
          />
        )}
        {!!nodePopupId &&
          popupXY !== null &&
          nodePopupData &&
          !(
            nodePopupData.type === "text" || nodePopupData.type === "container"
          ) && (
            <NodePopup
              className={classnames({ "allow-shrink": !!nodePopupData })}
              data={nodePopupData}
              icons={icons}
              key={nodePopupId}
              nodeId={parseInt(nodePopupId, 10)}
              projectId={projectId}
              setShowNotAccessibleModal={setShowNotAccessibleModal}
              siteId={siteId}
              x={popupXY[0]}
              y={popupXY[1]}
              onChangeIcon={(e) => handleChangeIcon(e)}
              onClickAddEdge={(id) => handleEnableAddEdgeMode(id.toString())}
              onNodeDeleted={() => handleNodeDeleted()}
            />
          )}
        {!!nodePopupId &&
          popupXY !== null &&
          nodePopupData &&
          nodePopupData.type === "text" && (
            <CustomTextElementPopup
              currentFontSize={nodePopupData.fontSize}
              currentFontWeight={nodePopupData.fontWeight}
              nodeId={nodePopupId}
              x={popupXY[0]}
              y={popupXY[1]}
              onClickDelete={(nodeId) => handleClickDeleteTextBox(nodeId)}
              onEditTextConfig={(fontWeight, fontSize) =>
                handleTextConfigChange(fontWeight, fontSize, nodePopupId)
              }
            />
          )}
        {!!nodePopupId &&
          popupXY !== null &&
          nodePopupData &&
          nodePopupData.type === "container" && (
            <CustomContainerElementPopup
              currentlySelectedColor={nodePopupData.iconColor}
              nodeId={nodePopupId}
              x={popupXY[0]}
              y={popupXY[1]}
              onClickDelete={(nodeId) =>
                handleClickDeleteContainerElement(nodeId)
              }
              onColorChange={(color) =>
                handleContainerColorChange(color, nodePopupId)
              }
            />
          )}
        {!!edgePopupId && popupXY !== null && (
          <EdgePopup
            edgeId={parseInt(edgePopupId as string, 10)}
            key={edgePopupId}
            setShowNotAccessibleModal={setShowNotAccessibleModal}
            showSourceTargetButtons={mode === StructureViewMode.MeteringConcept}
            userHasNoAccess={
              userCanCreateAndDelete &&
              !userCanCreateAndDelete[StructureDiagramObjectName.Edge]
            }
            x={popupXY[0]}
            y={popupXY[1]}
            onClickDelete={(id) => handleClickDeleteEdge(id.toString())}
            onUpdateMeteringEdge={updateMeteringEdge}
          />
        )}
      </div>
    </>
  );
}

export interface StructureViewFlowDiagramWrapperProps
  extends Omit<StructureViewFlowDiagramProps, "nodes" | "edges" | "icons"> {
  enabled: boolean;
  setCoordinates: (coordinates: { x: number; y: number }) => void;
}

function StructureViewFlowDiagramWrapper({
  enabled,
  siteId,
  setCoordinates,
  mode,
  meters,
  ...otherProps
}: StructureViewFlowDiagramWrapperProps) {
  const { data, isLoading, error } = useStructureViewFlowDiagram(siteId, mode);

  function convertAllNodesToFlowNodes(nodes) {
    const containerNodes = nodes.filter(
      (node) => node.data.type === "Container"
    );
    const textNodes = nodes.filter((node) => node.data.type === "Freitext");
    const customNodes = nodes.filter(
      (node) => node.data.type !== "Container" && node.data.type !== "Freitext"
    );
    const newContainerNodes = getFlowNodesFromContainers(
      containerNodes,
      siteId
    );
    const newCustomNodes = getFlowNodesFromNodes(customNodes);
    const newTextNodes = getFlowNodesFromTextBlocks(textNodes, siteId);
    return [...newContainerNodes, ...newCustomNodes, ...newTextNodes];
  }
  return (
    <LoadOrError error={error} loading={!enabled || isLoading}>
      {data && (
        <StructureViewFlowDiagram
          edges={data.edges}
          icons={data.icons}
          meters={meters}
          mode={mode}
          nodes={convertAllNodesToFlowNodes(data.nodes)}
          setCoordinates={setCoordinates}
          siteId={siteId}
          {...otherProps}
        />
      )}
    </LoadOrError>
  );
}

export {
  STRUCTURE_VIEW_IMAGE_HEIGHT,
  STRUCTURE_VIEW_IMAGE_WIDTH,
  StructureViewFlowDiagramWrapper as StructureViewFlowDiagram,
  StructureViewFlowDiagram as StructureViewFlowDiagramComponent,
  StructureViewFlowDiagramProps as StructureViewFlowDiagramComponentProps
};
