import React from "react";
import type { ButtonColor } from "../../../../../Buttons/Button/Button";
import { Button } from "../../../../../Buttons/Button/Button";
import { SpinButton } from "../../../../../Buttons/SpinButton/SpinButton";

interface BasicConfirmationFooterProps {
  confirmationButtonColor?: ButtonColor;
  confirmationButtonText?: string;
  cancelButtonText?: string;
  cancelButtonColor?: ButtonColor;
  error?: unknown;
  isConfirmationBtnDisabled?: boolean;
  isLoading?: boolean;
  toggleModal: () => void;
  onConfirm: () => void;
  onCancel?: () => void;
}

function BasicConfirmationFooter({
  confirmationButtonColor = "primary",
  confirmationButtonText = "OK",
  cancelButtonText = "Abbrechen",
  cancelButtonColor = "secondary",
  error,
  isConfirmationBtnDisabled,
  isLoading = false,
  toggleModal,
  onConfirm,
  onCancel
}: BasicConfirmationFooterProps) {
  function handleClickCancel() {
    if (onCancel) {
      onCancel();
    }

    toggleModal();
  }

  const showCancelButton = !!onCancel && !error;
  const showCloseButton = !!error;
  const showConfirmButton = !error;

  return (
    <>
      {showCancelButton && (
        <Button
          color={cancelButtonColor}
          disabled={isLoading}
          onClick={handleClickCancel}
        >
          {cancelButtonText}
        </Button>
      )}
      {showCloseButton && (
        <Button color="secondary" onClick={toggleModal}>
          Schließen
        </Button>
      )}
      {showConfirmButton && (
        <SpinButton
          color={confirmationButtonColor}
          disabled={isConfirmationBtnDisabled}
          spin={isLoading}
          onClick={onConfirm}
        >
          {confirmationButtonText}
        </SpinButton>
      )}
    </>
  );
}

export { BasicConfirmationFooter, BasicConfirmationFooterProps };
