import React from "react";
import type { Control, FieldErrors, UseFormWatch } from "react-hook-form";
import { FormFieldController } from "../../../../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { DueDateFields } from "../../../../../../BuildingBlocks/Forms/FormFields/GroupedFields/DueDateFields/DueDateFields";
import type { Paragraph6ContractFormFields } from "../../../../../Paragraph6.types";
import { PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA } from "../Paragraph6ContractForm.constants";

interface BeteiligungenProps {
  control: Control<Paragraph6ContractFormFields>;
  errors: FieldErrors<Paragraph6ContractFormFields>;
  isStaff?: boolean;
  watch: UseFormWatch<Paragraph6ContractFormFields>;
}

function Beteiligungen({
  control,
  errors,
  isStaff,
  watch
}: BeteiligungenProps) {
  const hasSplitBeteiligung = watch("has_split_beteiligung");
  const beteiligungFieldInfo = {
    ...PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA.beteiligung,
    label: hasSplitBeteiligung
      ? "Beteiligung für rückerstattungsfähige Mengen in ct/kWh"
      : PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA.beteiligung.label,
    infoText: hasSplitBeteiligung
      ? undefined
      : PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA.beteiligung.infoText
  };
  const formulaBeteiligungInfo = {
    ...PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA.formula_beteiligung,
    label: hasSplitBeteiligung
      ? "Formel für rückerstattungsfähige Mengen"
      : PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA.formula_beteiligung.label
  };

  const has_split_beteiligung_field_info = {
    name: "has_split_beteiligung",
    required: true,
    label:
      "Für diesen Vertrag gelten gesonderte Regelungen zur Berücksichtigung der rückerstattungsfähigen Mengen",
    type: "boolean",
    helpText:
      "Ausschließlich für die tatsächlich eingespeisten Strommengen wird, je nach definiertem Abrechnungszeitraum, eine ordnungsgemäße Gutschrift für die Gemeinde erstellt."
  } as const;
  const has_fiktive_strommenge_field_info = {
    name: "has_fiktive_strommenge",
    required: true,
    label:
      "Für diesen Vertrag sollen fiktive Strommengen berücksichtigt werden.",
    type: "boolean",
    helpText: `Es gibt drei Arten von fiktiven Strommengen:

- Strommengen, die im Rahmen des Redispatch 2.0-Regimes abgeregelt wurden.

- Strommengen, die auf eine technische Nichtverfügbarkeit von mehr als 2 Prozent des Bruttostromertrags zurückgehen.

- Strommengen, die wegen sonstigen Abschaltungen oder Drosselungen, zum Beispiel der optimierten Vermarktung des Stroms, der Eigenversorgung oder der Stromlieferungen unmittelbar an Dritte, nicht eingespeist wurden.

Haben Sie ausgewählt, dass diese Mengen berücksichtigt werden, dann gehen Sie bitte anschließend wie folgt vor: Wechseln Sie in den Reiter Energiedaten und laden Sie dort die fiktiven Strommengen je Erzeuger hoch.`
  } as const;

  const has_fiktive_strommenge = watch("has_fiktive_strommenge");
  const formulaBeteiligung = watch("formula_beteiligung");
  const formulaBeteiligungNichtErstattungsfaehig = watch(
    "formula_beteiligung_nicht_erstattungsfaehig"
  );

  return (
    <section>
      <FormFieldController
        control={control}
        data={has_split_beteiligung_field_info}
        error={errors.has_split_beteiligung}
      />
      <FormFieldController
        control={control}
        data={beteiligungFieldInfo}
        error={errors.beteiligung}
      />
      {isStaff && (
        <FormFieldController
          control={control}
          data={formulaBeteiligungInfo}
          error={errors.formula_beteiligung}
        />
      )}
      {isStaff && formulaBeteiligung && (
        <FormFieldController
          control={control}
          data={PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA.formula_beteiligung_text}
          error={errors.formula_beteiligung_text}
        />
      )}
      {hasSplitBeteiligung && (
        <>
          <FormFieldController
            control={control}
            data={
              PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA.beteiligung_nicht_erstattungsfaehig
            }
            error={errors.beteiligung_nicht_erstattungsfaehig}
          />
          {isStaff && formulaBeteiligungNichtErstattungsfaehig && (
            <FormFieldController
              control={control}
              data={
                PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA.formula_beteiligung_nicht_erstattungsfaehig_text
              }
              error={errors.formula_beteiligung_nicht_erstattungsfaehig_text}
            />
          )}
          {isStaff && (
            <FormFieldController
              control={control}
              data={
                PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA.formula_beteiligung_nicht_erstattungsfaehig
              }
              error={errors.formula_beteiligung_nicht_erstattungsfaehig}
            />
          )}
        </>
      )}
      <FormFieldController
        control={control}
        data={has_fiktive_strommenge_field_info}
        error={errors.has_fiktive_strommenge}
      />
      {has_fiktive_strommenge && (
        <FormFieldController
          control={control}
          data={
            PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA.beteiligung_fiktive_strommengen
          }
          error={errors.beteiligung_fiktive_strommengen}
        />
      )}
      <FormFieldController
        control={control}
        data={PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA.payment_method}
        error={errors.payment_method}
      />
      <DueDateFields
        control={control}
        errors={errors}
        formFieldData={PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA}
        label="Fälligkeit der Zahlung"
        prefix="in"
        unitFieldName="due_date_unit"
        valueFieldName="due_date_value"
      />
    </section>
  );
}

export { Beteiligungen };
