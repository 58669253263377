import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import urls from "../../../urls";
import type { Paragraph6GeneratorListElement } from "../Paragraph6.types";

export function useParagraph6UnassignedGenerators(
  contractId?: string,
  options?: Partial<UseQueryOptions<Array<Paragraph6GeneratorListElement>>>
) {
  const {
    data: paragraph6UnassignedGenerators,
    isLoading,
    error
  } = useQuery({
    queryKey: ["paragraph6UnassignedGenerators", { contractId }],
    queryFn: () => fetchParagraph6UnassignedGenerators(contractId),
    ...options
  });

  async function fetchParagraph6UnassignedGenerators(contractId?: string) {
    if (!contractId) {
      return [];
    }
    const paragraph6UnassignedGeneratorsUrl =
      urls.apiWithoutCamelization.paragraph6UnassignedGenerators(contractId);
    const response = await apiWithoutCamelization.get<
      Array<Paragraph6GeneratorListElement>
    >(paragraph6UnassignedGeneratorsUrl);
    return response.data;
  }

  return { paragraph6UnassignedGenerators, isLoading, error };
}
