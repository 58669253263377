import type { FormInputData } from "../../../BuildingBlocks/Forms/Controllers/FormFieldController";

export interface AnlageStromstgSelectionData {
  generatorIds: Array<number>;
  site: Array<number>;
}

export const ANLAGE_STROMSTG_FORM_FIELDS = {
  site: {
    name: "site",
    type: "dropdown",
    label: "Liegenschaft",
    helpText:
      "Hier bestimmen Sie die Liegenschaft der Erzeuger, die sich in der stromsteuerlichen Einheit ergänzend zu der Bisherigen befinden. Diese Auswahl wird Ihnen angezeigt, weil Sie auf der Seite zuvor angegeben haben, dass der Erzeuger Teil einer standortübergreifend Verklammerung ist.",
    choices: [],
    multiselect: true
  },
  generatorIds: {
    name: "generatorIds",
    type: "dropdown",
    label:
      "Wählen Sie die Einheit(en) aus, mit der/denen diese Einheit zu einer stromsteuerlichen Anlage verklammert ist.",
    helpText:
      "Informationen zur stromsteuerlichen „Verklammerung“ von mehreren Stromerzeugern: Nach " +
      "[§ 9 EnergieStV](https://www.buzer.de/gesetz/7310/a143993.htm) und " +
      "[§ 12b Absatz 1 StromStV](https://www.buzer.de/gesetz/225/a144106.htm) ist bei " +
      "__mehreren Stromerzeugungseinheiten__ an einem Standort dann von __einer Anlage__ im " +
      "stromsteuerlichen Sinne auszugehen, wenn diese Einheiten zusammengeschaltet sind bzw. " +
      "unmittelbar miteinander verbunden sind. In ihrem [Informationsschreiben](https://www.zoll.de/SharedDocs/Downloads/DE/Links-fuer-Inhaltseiten/Fachthemen/Verbrauchsteuern/informationsschreiben_anlagenbegriff_stromsteuerrecht.pdf?__blob=publicationFile&v=2) " +
      "zum stromsteuerlichen Anlagenbegriff kommt die Generalzolldirektion in Ihrem Fazit (siehe Seite 12) zu dem " +
      "Schluss, dass mehrere an einem Standort befindliche WEA als eine Anlage im stromsteuerlichen Sinne zu werten " +
      "sind, sofern sie vom __selben Betreiber__ betrieben __und__ über einen betreiberspezifischen __Windparkregler__ " +
      "miteinander verbunden sind.\n\n" +
      "Für WEA/ PV bis 2 MW kann eine Verklammerung dazu führen, dass die Leistungsgrenze von 2 MW stromsteuerrechtlich " +
      "überschritten wird, sodass eine steuerfreie Lieferung an Letztverbraucher im räumlichen Zusammenhang nicht " +
      "zulässig ist. Für alle WEA/ PV gilt: Einige Stromsteuerformulare (Betriebserklärungen) müssen nur einmal je " +
      "stromsteuerlicher Anlage befüllt werden. Für verklammerte WEA/ PV muss in diesem Fall nur eine Betriebserklärung " +
      "eingereicht werden, welche mehrere WEA/ PV umfasst.\n\n" +
      "Sofern Ihnen bereits stromsteuerliche Erlaubnisse bzw. Bewilligungen vorliegen: Da die Verklammerung in der " +
      "Verwaltungspraxis nicht immer einheitlich angewendet wurde, empfiehlt es sich zu prüfen, ob Ihr Hauptzollamt in " +
      "der Vergangenheit von einer Verklammerung ausgegangen ist oder ob jede einzelne WEA bzw. PV-Anlage als eine eigenständige " +
      "stromsteuerliche Anlage behandelt wurde.",
    choices: [],
    multiselect: true,
    required: true
  }
} as const satisfies {
  [key in keyof AnlageStromstgSelectionData]?: FormInputData<
    AnlageStromstgSelectionData,
    key
  >;
};
