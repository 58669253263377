import * as Sentry from "@sentry/browser";
import { ObjectName } from "./enums";

const OBJECT_NAME_TO_VARIABLE_NAME: Readonly<Record<ObjectName, string>> = {
  [ObjectName.Generator]: "generator",
  [ObjectName.Consumer]: "consumer",
  [ObjectName.Storage]: "storage",
  [ObjectName.Person]: "person",
  [ObjectName.Connection]: "connection",
  [ObjectName.GasConnection]: "gasConnection",
  [ObjectName.Meter]: "meter",
  [ObjectName.GhostNode]: "ghostNode",
  [ObjectName.MarketLocation]: "marketLocation",
  [ObjectName.MeteringLocation]: "meteringLocation",
  [ObjectName.Weighting]: "weighting"
};

export function getPluralVariableNameFromObjectName(objectName: ObjectName) {
  const variableName =
    OBJECT_NAME_TO_VARIABLE_NAME[objectName] ?? "not_implemented";

  if (variableName === "not_implemented") {
    Sentry.captureMessage(
      `ObjectName ${objectName} not implemented. Please contact the frontend team immediately.`
    );
    return variableName;
  }

  const plural = `${variableName}s`;

  return plural;
}
