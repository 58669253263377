import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import urls from "../../../urls";
import { showToast } from "../../../utils/toast";
import type { Paragraph6ContractPayload } from "../Paragraph6.types";

export function useParagraph6ContractMutations(variantId: number) {
  const queryClient = useQueryClient();

  function invalidateCommonQueries() {
    queryClient.invalidateQueries({
      queryKey: ["paragraph6Contracts", { variantId }]
    });
    queryClient.invalidateQueries({
      queryKey: ["paragraph6CreditErrors", { variantId }]
    });
    queryClient.invalidateQueries({
      queryKey: ["paragraph6CreatableCredits", { variantId }]
    });
  }

  const paragraph6ContractCreateMutation = useMutation({
    mutationFn: (contract: Paragraph6ContractPayload) =>
      createParagraph6Contract(contract),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["paragraph6Contracts", { variantId }]
      });
      queryClient.invalidateQueries({
        queryKey: ["paragraph6CreditErrors", { variantId }]
      });
    }
  });

  const paragraph6ContractEditMutation = useMutation({
    mutationFn: (contract: Paragraph6ContractPayload) =>
      editParagraph6Contract(contract),
    onSuccess: (_, contract) => {
      queryClient.invalidateQueries({
        queryKey: ["paragraph6Contract", { contractId: contract.id }]
      });
      queryClient.invalidateQueries({
        queryKey: [
          "paragraph6UnassignedGenerators",
          { contractId: contract.id }
        ]
      });
      invalidateCommonQueries();
    },
    onError: (error) => showToast("error", error),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["paragraph6Contracts", { variantId }]
      });
    }
  });

  const paragraph6ContractDeleteMutation = useMutation({
    mutationFn: (contractId: string) => deleteParagraph6Contract(contractId),
    onSuccess: (_, contractId) => {
      queryClient.invalidateQueries({
        queryKey: ["paragraph6Contract", { contractId }]
      });
      invalidateCommonQueries();
    }
  });

  return {
    paragraph6ContractCreateMutation,
    paragraph6ContractEditMutation,
    paragraph6ContractDeleteMutation
  };
}

function createParagraph6Contract(contract: Paragraph6ContractPayload) {
  const paragraph6ContractsUrl =
    urls.apiWithoutCamelization.paragraph6Contracts();
  return apiWithoutCamelization.post<Paragraph6ContractPayload>(
    paragraph6ContractsUrl,
    contract
  );
}

function editParagraph6Contract(contract: Paragraph6ContractPayload) {
  const paragraph6ContractsUrl = urls.apiWithoutCamelization.paragraph6Contract(
    contract.id
  );
  return apiWithoutCamelization.put<Paragraph6ContractPayload>(
    paragraph6ContractsUrl,
    contract
  );
}

function deleteParagraph6Contract(contractId: string) {
  const paragraph6ContractsUrl =
    urls.apiWithoutCamelization.paragraph6Contract(contractId);
  return apiWithoutCamelization.delete(paragraph6ContractsUrl);
}
