import api from "../../../api";
import urls from "../../../urls";
import type { RefreshSuccess } from "./login";

export const checkAuth = async (redirectUrl: string) => {
  if (!new RegExp("/(login|password-reset(/.+)?)").test(location.pathname)) {
    return true;
  }

  try {
    await api.publicRequest<RefreshSuccess>("POST", urls.auth.refresh());
    location.replace(redirectUrl);
    return false;
  } catch (_) {
    return true;
  }
};
