import classNames from "classnames";
import React, { useState } from "react";
import { generatePath } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { useProject } from "../../../hooks/useProject";
import { ROUTES } from "../../../routes";
import urls from "../../../urls";
import type { Variant } from "../../../utils/backend-types";
import { Product } from "../../../utils/enums";
import {
  Action,
  ActionConfirmationModal,
  ActionObject
} from "../../BuildingBlocks/Layout/Modals/ActionConfirmationModal/ActionConfirmationModal";
import type { ButtonProps } from "../../Buttons/Button/Button";
import { SquareButton } from "../../Buttons/SquareButton/SquareButton";
import { openErrorAlertPopup } from "../../ErrorAlertPopup/openErrorAlertPopup";
import { MoreVariantOptions } from "../../MoreVariantOptions/MoreVariantOptions";
import "./StructureViewButtons.scss";

export interface StructureViewButtonsProps {
  projectId: string;
  variantId: number;
  openModal: (arg?: { isV2: boolean }) => void;
  product: Product;
  isPartialFeedin: boolean;
  shouldShowStaffView: boolean;
}

function StructureViewButtons({
  projectId,
  variantId,
  openModal,
  product,
  isPartialFeedin,
  shouldShowStaffView
}: StructureViewButtonsProps) {
  const { project } = useProject(projectId);
  const [isMoreVariantOptionsModalOpen, setIsMoreVariantOptionsModalOpen] =
    useState(false);
  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);
  const variant = project?.variants.find((v) => v.id === variantId);
  const showMBKWithVersioningButton =
    (project?.customerMbkWriteAccessActive && isPartialFeedin) ||
    shouldShowStaffView;

  function handleToggleMoreVariantOptionsModal() {
    setIsMoreVariantOptionsModalOpen(
      (isMoreVariantOptionsModalOpen) => !isMoreVariantOptionsModalOpen
    );
  }

  function handleClickCopyVariantButton() {
    setIsCopyModalOpen(true);
  }

  function handleCloseModal() {
    setIsCopyModalOpen(false);
  }

  function handleCopySuccess(newVariant: Variant) {
    window.location.href = generatePath(ROUTES.analyzerVariantStructure, {
      variantId: newVariant.id.toString(),
      projectId: projectId
    });
  }

  function onError(error: unknown) {
    openErrorAlertPopup(error);
  }

  return (
    <div className="StructureViewButtons">
      <Row
        className={classNames({
          "buttons-row-two-buttons": product === Product.Manager
        })}
      >
        {product === Product.Analyzer && (
          <ColWithButton
            color="brand"
            linkTo={urls.loadprofileManagerList(projectId)}
          >
            Lastprofil-Manager
          </ColWithButton>
        )}
        <ColWithButton color="brand" onClick={() => openModal()}>
          {product === Product.Analyzer
            ? "Lieferbeziehungen"
            : "Mess- und Bilanzierungskonzept"}
        </ColWithButton>
        {product !== Product.Analyzer && showMBKWithVersioningButton && (
          <ColWithButton
            color="warning"
            onClick={() => openModal({ isV2: true })}
          >
            <>
              Mess- und Bilanzierungskonzept
              <br />
              mit zeitlicher Veränderbarkeit
            </>
          </ColWithButton>
        )}
        {product === Product.Analyzer && (
          <ColWithButton
            color="brand"
            onClick={handleToggleMoreVariantOptionsModal}
          >
            Weitere Einstellungen
          </ColWithButton>
        )}
        {isMoreVariantOptionsModalOpen && (
          <MoreVariantOptions
            isOpen={isMoreVariantOptionsModalOpen}
            toggleModal={handleToggleMoreVariantOptionsModal}
            variantId={variantId}
          />
        )}

        {product === Product.Analyzer && (
          <>
            <ColWithButton color="brand" onClick={handleClickCopyVariantButton}>
              Variante duplizieren
            </ColWithButton>
            {isCopyModalOpen && variant && (
              <ActionConfirmationModal
                action={Action.Copy}
                object={ActionObject.Variant}
                variant={variant}
                onActionSuccess={handleCopySuccess}
                onClose={handleCloseModal}
                onError={onError}
              />
            )}
            <ColWithButton
              color="primary"
              linkTo={urls.analyze(variantId, projectId)}
            >
              Bewerten
            </ColWithButton>
          </>
        )}
      </Row>
    </div>
  );
}

interface ColWithButtonProps extends ButtonProps {
  linkTo?: string;
}

function ColWithButton({ linkTo, ...other }: ColWithButtonProps) {
  const actualButton = (
    <SquareButton className="btn-block m-btn--custom" {...other} />
  );
  const buttonMaybeWrappedInLink = linkTo ? (
    <a className="no-hover" href={linkTo}>
      {actualButton}
    </a>
  ) : (
    actualButton
  );

  return (
    <Col className="button-col" md="6" xl="4" xs="12">
      {buttonMaybeWrappedInLink}
    </Col>
  );
}

export { StructureViewButtons };
