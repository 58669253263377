import React from "react";
import { FilterDropdown } from "../../../FilterDropdown/FilterDropdown";
import type { FilterDropdownValues } from "../../../FilterDropdown/FilterDropdown.types";
import { LOCATION_OVERVIEW_FILTERS } from "../LocationOverview.types";
import { DEFAULT_FILTER_VALUES } from "../LocationOverviewTable/LocationOverview.constants";

interface LocationOverviewFilterDropdownProps {
  selectedFilters: FilterDropdownValues;
  onChangeFilters: (newFilters: FilterDropdownValues) => void;
}

function LocationOverviewFilterDropdown({
  selectedFilters,
  onChangeFilters
}: LocationOverviewFilterDropdownProps) {
  return (
    <FilterDropdown
      defaultValues={DEFAULT_FILTER_VALUES}
      filters={LOCATION_OVERVIEW_FILTERS}
      values={selectedFilters}
      onSave={onChangeFilters}
    />
  );
}

export { LocationOverviewFilterDropdown, LocationOverviewFilterDropdownProps };
