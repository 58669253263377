import { MspCategory } from "../../../utils/enums";

export function getMspCategoryLabel(category: MspCategory) {
  switch (category) {
    case MspCategory.LiefEdi:
      return "LIEF-EDI (MSCONS, die vom MSB an die Lieferanten-Rolle versandt werden)";
    case MspCategory.EsaEdi:
      return "ESA-EDI (Versand von MSCONS an die ESA-Rolle außerhalb des vorgeschriebenen REQOTE-Prozesses)";
    case MspCategory.EsaReq:
      return "ESA-REQ (Versand von MSCONS aus einem REQOTE-Prozess)";
    case MspCategory.CSV:
      return "CSV (MSBs die CSV Dateien senden)";
    case MspCategory.Unknown:
      return "Unbekannt (noch kein Austausch)";
    case MspCategory.Reluctant:
      return "Unwillige MSBs";
    default:
      return category;
  }
}
