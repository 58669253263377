import { Group } from "@mantine/core";
import { type MRT_ColumnDef } from "mantine-react-table";
import React from "react";
import { type Todo } from "../../../utils/backend-types";
import { Icon } from "../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../BuildingBlocks/Icon/types";
import { TodosListColumnKeys } from "../TodoTable";

const PERSON_EMPTY = "empty";

export function getPersonColumn(todos: Array<Todo>): MRT_ColumnDef<Todo> {
  const allPersons = todos.map((todo) => todo.person);
  const sortedNonNullPersons = allPersons
    .filter((dueDate) => dueDate !== null)
    .sort((personA, personB) => (personA ?? "").localeCompare(personB ?? ""));
  const uniqueUsedPersons = Array.from<string>(new Set(sortedNonNullPersons));
  const usedPersons = Array.from(uniqueUsedPersons);

  return {
    accessorKey: TodosListColumnKeys.Person,
    header: "Verpflichtetes Unternehmen",
    enableGrouping: false,
    Cell: ({ row }) =>
      row.original.person ? (
        <Group className="cell-person" gap="sm" wrap="nowrap">
          <Icon className="company-icon" name={IconName.Building} />
          {row.original.person}
        </Group>
      ) : null,
    filterVariant: "select",
    filterFn: (row, _, filterValue) =>
      filterValue === PERSON_EMPTY
        ? row.original.person === null
        : row.original.person?.includes(filterValue) || false,
    mantineFilterSelectProps: {
      data: [
        { value: PERSON_EMPTY, label: "(kein verpflichtetes Unternehmen)" }
      ].concat(
        usedPersons.map((company) => ({
          value: company,
          label: company
        }))
      )
    }
  };
}
