import type { Contract } from "../../../../../utils/backend-types";
import {
  MeasuringPointPriceOption,
  PaymentIntervalAndBillingMethod,
  VATExemptionReason
} from "../../../../../utils/backend-types";
import { PAYMENT_METHOD_CHOICES } from "../../../../../utils/constants";
import type { FormFieldData } from "../../../../BuildingBlocks/Forms/Controllers/FormFieldController";

export const CONTRACT_FORM_HELP_TEXTS: { [key in keyof Contract]?: string } = {
  addBasePrice: `Stromanbieter erheben häufig einen verbrauchsunabhängigen Grundpreis. Dieser unterscheidet \nsich je nach Anbieter, im Regelfall finden Sie die Grundgebühr auf Ihrer Stromrechnung.`,
  addPriceMeasuringPoint: `Der Messstellenbetrieb erfasst alle Dienstleistungen und Investitionen rund um den Betrieb \nder Stromzähler. Dies beinhaltet sowohl die vom Messstellenbetreiber für die \nunterschiedlichen Zähler der Liegenschaft ausgewiesenen Kosten (im Regelfall werden diese \nim Rahmen der Stromrechnung für den Netzbezug abgerechnet) sowie die Tätigkeit des \nMieterstrom-Lieferanten hinsichtlich Ablesung und Betrieb von Zählern. Definieren Sie, ob \nSie die Messkosten pauschal pro Kunde in Rechnung stellen möchten (Messkosten in Summe), \njeder Belieferte bezahlt dann gleich viel. Alternativ können Sie die Messkosten nach Aufwand \nausweisen (Messkosten je Zähler), die Messkosten werden dann anhand der Anzahl an \nausgelesenen Zählern berechnet und zusammen mit der Zähleranzahl auf der Rechnung ausgewiesen.`,
  allowanceSendInvoiceViaMail: `Rechnungen können gemäß § 14 Abs. 1 UStG ausdrücklich in elektronischer Form versandt werden. \nHierfür ist die Zustimmung des Empfängers erforderlich. Eine schriftliche Zustimmung ist \njedoch nicht zwingend, eine mündliche Vereinbarung oder ggf. sogar eine stillschweigende \nZustimmung zum Rechnungsversand per Email ist ausreichend.`,
  automaticExtension:
    "Definieren Sie, ob der Stromliefervertrag automatisch und stillschweigend verlängert wird, insofern keine explizite Kündigung vorliegt. Dies gilt auch für alle Folgejahre, der Vertrag über die Stromlieferung wird immer weiter verlängert. Denn gemäß § 309 Nr. 9 BGB sind stillschweigende Vertragsverlängerungen für Verträge, welche seit dem 1. März 2022 abgeschlossen wurden, nur dann zulässig, wenn diese auf unbestimmte Zeit verlängert werden.",
  automaticSendInvoiceActive: `Insofern der Rechnungsempfänger mit einem Emailversand der Rechnung einverstanden ist (stillschweigende Zustimmung ist\nim Regelfall ausreichend), kann opti.node die Stromrechnungen automatisch versenden.\nDer Versand erfolgt sobald alle notwendigen Daten vorhanden sind. Dies betrifft in den meisten Fällen die\nErzeugungs- und Verbrauchslastgänge der Stromzähler. Darüber hinaus ist auch eine Empfänger-Emailadresse notwendig.\nDiese hinterlegen Sie auf dem entsprechenden (belieferten) Unternehmen → Reiter Stromlieferung und Abrechnung →\nE-Mail Adresse für Rechnungsempfang.\nSie erhalten ebenfalls bei jedem Rechnungsversand eine Kopie an Ihre Emailadresse (cc).`,
  billingMethodAndPeriod: `Definieren Sie das Intervall, in welchem Sie Rechnungen erstellen möchten. \n- _Jahresendabrechnung (ohne Abschläge)_ bedeutet, dass Ihnen für jedes Kalenderjahr eine Abrechnung zur Verfügung gestellt wird. Diese Option kann verwendet werden, wenn Zähler nur einmal im Jahr ausgelesen werden und es sich um relativ geringe Stromverbräuche handelt. Denn die gelieferte Strommenge wird immer erst nach Ablauf des Kalenderjahres bezahlt. \n\n- _Monatliche Abschläge + Jahresendabrechnung_ bezieht sich ebenfalls auf den Abrechnungszeitraum Kalenderjahr. Zusätzlich zur Abrechnung des Vorjahrs enthalten die Stromrechnungen einen Abschlagsplan für das Folgejahr. Der Abschlagsplan weist die voraussichtlichen monatlichen Kosten für die Stromlieferung aus. Diese geleisteten Abschlagszahlungen werden als Guthaben für die nächste Jahresendabrechnung berücksichtigt. Vor der ersten Stromlieferung erhalten Sie einen Abschlagsplan für Ihre Kunden. \n\n- _Monatliche Abrechnung auf Basis von Messwerten (ohne Abschläge)_ ist hauptsächlich dann möglich, wenn der Stromverbrauch als Lastgang gemessen und automatisiert (bspw. durch den Messstellenbetreiber) übertragen wird.`,
  cancelationReference:
    "Hinterlegen Sie die Kündigungsfrist für den Belieferten, diese muss auf der Stromrechnung ausgewiesen werden. Gemäß § 309 Nr. 9 BGB sind Verträge über eine regelmäßige Lieferung von Waren, welche ab dem 1. März 2022 abgeschlossen werden, nur dann zulässig, wenn die Kündigungsfrist höchstens 1 Monat zum Vertragsende beträgt sowie bei stillschweigender Verlängerung jederzeit mit einer Frist von 1 Monat zum Monatsende vom Belieferten gekündigt werden kann.",
  deposit: `Insofern Sie von Ihren Stromkunden eine Kaution erheben, können Sie diese hier hinterlegen. \nDie Kaution wird dem Belieferten auf dem ersten Abschlagsplan ausgewiesen.`,
  end: "Sofern der Vertrag fristgerecht oder außerordentlich, z.B. aufgrund von Auszug, gekündigt wurde, tragen Sie hier bitte das Datum ein, zu welchem der Vertrag endet. Daraufhin wird für dieses Datum eine Endabrechnung erstellt. Insofern noch kein Ende der Lieferbeziehung bekannt ist, tragen Sie in dieses Feld nichts ein.",
  mandateReference: `Die Mandatsreferenz wird von Ihnen selbst generiert und kann bis zu 35 Stellen lang sein, \nfällt aber zumeist sehr viel kürzer aus. Die Mandatsreferenz dient dazu, eine Zahlung \neindeutig einem Belieferten zuzuordnen und kann deshalb auch eine Kundennummer oder \nähnliches enthalten. Die Kombination aus Mandatsreferenz und Gläubiger ID muss eindeutig \nsein, Sie können also nicht die selbe Mandatsreferenz für zwei Kunden verwenden.`,
  minimumTerm:
    "Bestimmen Sie, wie lange der Vertrag gültig ist. Sie können die Mindestvertragslaufzeit durch eine automatische Vertragsverlängerung ergänzen. Wählen Sie beispielsweise ein Datum, welches ein oder zwei Jahre nach dem Vertragsbeginn liegt und ergänzen Sie diese um eine automatische Verlängerung um ein Jahr. Gemäß  §309 Nr. 9 BGB sind Verträge mit Konsumenten über eine wiederkehrende Lieferung nur über einen Zeitraum von bis zu zwei Jahren möglich.",
  monthlyInvoiceDocumentsForAdvancePayment: `Wählen Sie _ja_, wenn Sie jeden Monat die im Abschlagsplan ausgewiesenen Beträge (Abschläge) in einem \nseparaten Rechnungsdokument für die Belieferten erhalten möchten. Wählen Sie diese Option \nvorzugsweise, wenn Sie jeden Monat vom Belieferten eine Überweisung erwarten. Bezahlt die Belieferte \nper Lastschriftverfahren, so ist häufig kein monatliches Rechnungsdokument erforderlich.`,
  name: "Sie können die Bezeichnung des Vertrags frei wählen. Es empfiehlt sich jedoch, einen Namen zu wählen, welcher eine sofortige und eindeutige Zuordnung ermöglicht. Beispiel _Stromliefervertag NameKunde_.",
  paymentMethod: `Im Verfahren Überweisung erstellen wir eine Rechnung, mit der Aufforderung, den fälligen \nBetrag auf das von Ihnen hinterlegte Bankkonto (Reiter Stromlieferung und Abrechnung auf \nden Stammdaten der Betreibergesellschaft) unter Einhaltung der nachfolgend definierten \nFrist zu überweisen. Wählen Sie Lastschrift, wenn Sie eine Ermächtigung haben, den \nRechnungsbetrag vom Konto des Belieferten einzuziehen. Ergänzen Sie die dazugehörige \nMandatsreferenz (nachfolgendes Feld), die IBAN des Belieferten (auf dem Unternehmen des \nBelieferten → Reiter Stromlieferung und Abrechnung unter der Überschrift Für den \nBelieferten) sowie die Gläubiger ID des Lieferanten (auf Ihrem Unternehmen → Reiter \nStromlieferung und Abrechnung unter der Überschrift Für den Lieferanten).`,
  productName:
    "Gemäß § 40 Abs. 2 Nr. 13 EnWG müssen Stromrechnungen die einschlägige Tarif- oder Produktbezeichnung enthalten. Bitte tragen Sie hier die entsprechende Bezeichnung ein, die auf den Stromrechnungen ausgewiesen werden soll. Im Prinzip sind Sie frei in der Gestaltung der Produktbezeichnung, es empfiehlt sich, bspw. die Stromquellen als Produktbezeichnung zu wählen. Als Einschränkung gilt, dass Strom aus der eigenen PV-Anlage nicht als Grün- oder Ökostrom bezeichnet werden darf, insofern dafür keine Herkunftsnachweise (HKN) im Herkunftsnachweisregister des Umweltbundesamt gemäß § 79 Abs. 2 EEG entwertet wurden. Damit einher geht, dass **Stromlieferungen ohne HKN mit den spezifischen Emissionen des Strommix Deutschland bewertet werden müssen**, auch wenn diese ganz offensichtlich aus einer PV-Anlage stammen.",
  isVatIncluded:
    "Unter Umständen ist der Lieferant nicht verpflichtet bzw. berechtigt die Mehrwertsteuer auf den gelieferten Strom \n" +
    "auszuweisen. Gründe hierfür kann u.a. die Kleinunternehmerregelung oder das Weiterleiten des Stroms durch den Belieferten sein. Insofern keine MwSt. ausgewiesen werden soll, ist es verpflichtend, hierfür einen Grund auf der Rechnung zu benennen.",
  customVatExemptionReason:
    "Eine Steuerbefreiung muss auf der Rechnung erläutert werden (§ 14 Abs. 4 Nr. 7 UStG). Grund hierfür kann bspw. die Kleinunternehmerregelung (§ 19 Abs. 1 UStG) sein."
};

export const CONTRACT_FORM_FIELD_DATA = {
  name: {
    name: "name",
    type: "text",
    label: "Bezeichnung",
    helpText: CONTRACT_FORM_HELP_TEXTS.name,
    required: true
  },
  productName: {
    name: "productName",
    type: "text",
    label: "Produktbezeichnung",
    helpText: CONTRACT_FORM_HELP_TEXTS.productName,
    required: true
  },
  supplier: {
    name: "supplier",
    type: "dropdown",
    label: "Lieferant",
    choices: [],
    required: true,
    id: "id_form_supplier"
  },
  supplied: {
    name: "supplied",
    type: "dropdown",
    label: "Belieferter",
    choices: [],
    required: true,
    id: "id_form_supplied"
  },
  begin: {
    name: "begin",
    type: "date",
    label: "Vertragsbeginn",
    required: true
  },
  minimumTerm: {
    name: "minimumTerm",
    type: "date",
    label: "Ende der Mindestvertragslaufzeit",
    helpText: CONTRACT_FORM_HELP_TEXTS.minimumTerm,
    required: true
  },
  automaticExtension: {
    name: "automaticExtension",
    type: "boolean",
    label: "Automatische Verlängerung",
    helpText: CONTRACT_FORM_HELP_TEXTS.automaticExtension,
    required: true
  },
  extensionDurationValue: {
    name: "extensionDurationValue",
    type: "number",
    label: "Automatische Verlängerung (Dauer)",
    min: -2147483648,
    max: 2147483647,
    id: "id_form_extensionDurationValue"
  },
  extensionDurationUnit: {
    name: "extensionDurationUnit",
    type: "dropdown",
    label: "Automatische Verlängerung (Einheit)",
    choices: [
      { value: "year", displayName: "Jahr(e)" },
      { value: "month", displayName: "Monat(e)" }
    ],
    id: "id_form_extensionDurationUnit"
  },
  cancelationDeadlineValue: {
    name: "cancelationDeadlineValue",
    type: "number",
    label: "Kündigungsfrist (Dauer)",
    min: -2147483648,
    max: 2147483647,
    id: "id_form_cancelationDeadlineValue"
  },
  cancelationDeadlineUnit: {
    name: "cancelationDeadlineUnit",
    type: "dropdown",
    label: "Kündigungsfrist (Einheit)",
    choices: [
      { value: "year", displayName: "Jahr(e)" },
      { value: "month", displayName: "Monat(e)" },
      { value: "day", displayName: "Tag(e)" }
    ],
    id: "id_form_cancelationDeadlineUnit"
  },
  cancelationReference: {
    name: "cancelationReference",
    type: "dropdown",
    label: "Kündigungsfrist (Referenz)",
    choices: [
      { value: "end_of_contract", displayName: "Vertragsende" },
      { value: "end_of_month", displayName: "Monatsende" },
      { value: "end_of_year", displayName: "Jahresende" }
    ],
    helpText: CONTRACT_FORM_HELP_TEXTS.cancelationReference,
    required: true,
    id: "id_form_cancelationReference"
  },
  end: {
    name: "end",
    type: "date",
    label: "Vertragsende (ggf. wegen außerordentlicher Kündigung)",
    helpText: CONTRACT_FORM_HELP_TEXTS.end,
    required: false
  },
  billingMethodAndPeriod: {
    name: "billingMethodAndPeriod",
    type: "dropdown",
    label: "Abrechnungsart und -periode",
    choices: [
      {
        value: PaymentIntervalAndBillingMethod.AnnualBasedOnMeasurements,
        displayName: "Jahresendabrechnung (ohne Abschläge)"
      },
      {
        value:
          PaymentIntervalAndBillingMethod.MonthlyAdvancePaymentsAndAnnualFinalInvoice,
        displayName: "Monatliche Abschläge + Jahresendabrechnung"
      },
      {
        value: PaymentIntervalAndBillingMethod.MonthlyBasedOnMeasurements,
        displayName:
          "Monatliche Abrechnung auf Basis von Messwerten (ohne Abschläge)"
      }
    ],
    helpText: CONTRACT_FORM_HELP_TEXTS.billingMethodAndPeriod,
    required: true,
    id: "id_form_billingMethodAndPeriod"
  },
  monthlyInvoiceDocumentsForAdvancePayment: {
    name: "monthlyInvoiceDocumentsForAdvancePayment",
    type: "boolean",
    label:
      "Sollen monatlich Rechnungsdokumente für Abschläge zur Verfügung gestellt werden",
    helpText: CONTRACT_FORM_HELP_TEXTS.monthlyInvoiceDocumentsForAdvancePayment
  },
  previousElectricityConsumption: {
    name: "previousElectricityConsumption",
    type: "boolean",
    label:
      "Soll der bisherige Stromverbrauch auf den Abschlagsrechnungen ausgewiesen werden?",
    helpText:
      "Wählen Sie _ja_, wenn Sie Informationen über den bisherigen Stromverbrauch im aktuellen Jahr und im Vormonat" +
      "auf den monatlichen Rechnungsdokumenten für die Abschläge ausweisen wollen."
  },
  paymentMethod: {
    name: "paymentMethod",
    type: "dropdown",
    label: "Zahlungsverfahren",
    choices: PAYMENT_METHOD_CHOICES,
    helpText: CONTRACT_FORM_HELP_TEXTS.paymentMethod,
    required: true,
    id: "id_form_paymentMethod"
  },
  dueDateValue: {
    name: "dueDateValue",
    type: "number",
    label: "Fälligkeit (Dauer)",
    min: -2147483648,
    max: 2147483647,
    required: true
  },
  dueDateUnit: {
    name: "dueDateUnit",
    type: "dropdown",
    label: "Fälligkeit (Einheit)",
    choices: [
      { value: "year", displayName: "Jahr(e)" },
      { value: "month", displayName: "Monat(e)" },
      { value: "day", displayName: "Tag(e)" }
    ],
    required: true
  },
  mandateReference: {
    name: "mandateReference",
    type: "text",
    label: "Mandatsreferenz",
    helpText: CONTRACT_FORM_HELP_TEXTS.mandateReference
  },
  allowanceSendInvoiceViaMail: {
    name: "allowanceSendInvoiceViaMail",
    type: "boolean",
    label:
      "Zustimmung des Belieferten zum Rechnungsversand per E-Mail vorhanden",
    helpText: CONTRACT_FORM_HELP_TEXTS.allowanceSendInvoiceViaMail
  },
  automaticSendInvoiceActive: {
    name: "automaticSendInvoiceActive",
    type: "boolean",
    label: "Automatischer Rechnungsversand aktivieren",
    helpText: CONTRACT_FORM_HELP_TEXTS.automaticSendInvoiceActive
  },
  addPriceMeasuringPoint: {
    name: "addPriceMeasuringPoint",
    type: "dropdown",
    label: "Messstellenbetrieb in Rechnung stellen",
    helpText: CONTRACT_FORM_HELP_TEXTS.addPriceMeasuringPoint,
    choices: [
      {
        value: MeasuringPointPriceOption.None,
        displayName: "Keine Messkosten"
      },
      {
        value: MeasuringPointPriceOption.Total,
        displayName: "Messkosten in Summe"
      },
      {
        value: MeasuringPointPriceOption.PerMeter,
        displayName: "Messkosten je Zähler"
      }
    ],
    required: true,
    id: "id_form_addPriceMeasuringPoint"
  },
  measuringPointPrices: {
    name: "measuringPointPrices",
    type: "number",
    label: "Messkosten [€/Monat]"
  },
  addBasePrice: {
    name: "addBasePrice",
    type: "boolean",
    label: "Grundpreis in Rechnung stellen",
    helpText: CONTRACT_FORM_HELP_TEXTS.addBasePrice,
    required: true
  },
  basePrices: {
    name: "basePrices",
    type: "number",
    label: "Grundpreis [€]"
  },
  deposit: {
    name: "deposit",
    type: "number",
    label: "Kaution (netto)",
    helpText: CONTRACT_FORM_HELP_TEXTS.deposit,
    inputGroupText: "EUR",
    min: 0
  },
  creationTemplate: {
    name: "creationTemplate",
    type: "dropdown",
    label: "Referenzvertrag auswählen",
    choices: [],
    id: "id_form_creationTemplate"
  },
  creationTemplateSite: {
    name: "creationTemplateSite",
    type: "dropdown",
    label: "Liegenschaft auswählen",
    choices: [],
    id: "id_form_creationTemplateSite"
  },
  isVatIncluded: {
    name: "isVatIncluded",
    type: "boolean",
    label: "Die Mehrwertsteuer wird auf der Rechnung ausgewiesen",
    required: true,
    id: "id_form_isVatIncluded",
    helpText: CONTRACT_FORM_HELP_TEXTS.isVatIncluded
  },
  vatExemptionReason: {
    name: "vatExemptionReason",
    type: "dropdown",
    label: "Begründung für die Befreiung von der Mehrwertsteuer",
    required: true,
    choices: [
      {
        value: VATExemptionReason.smallBusinessRegulation,
        displayName:
          "Die Rechnung enthält nach § 19 Abs. 1 UStG (Kleinunternehmerregelung) keine Umsatzsteuer"
      },
      {
        value: VATExemptionReason.reverseCharge,
        displayName:
          "Die Steuerschuldnerschaft liegt gemäß § 13b Abs. 2 Nr. 5 UStG beim Leistungsempfänger (Reverse Charge Verfahren)"
      },
      {
        value: VATExemptionReason.custom,
        displayName: "Weiterer Grund"
      }
    ],
    id: "id_form_vatExemptionReason"
  },
  customVatExemptionReason: {
    name: "customVatExemptionReason",
    type: "text",
    label: "Begründung",
    required: true,
    id: "id_form_customVatExemptionReason",
    helpText: CONTRACT_FORM_HELP_TEXTS.customVatExemptionReason,
    placeholder: "Bitte geben Sie einen weiteren Grund an"
  }
} as const satisfies FormFieldData<Contract>;

export const NEW_CONTRACT_DEFAULT_VALUES = {
  isVatIncluded: true
} as const satisfies Partial<Contract>;
