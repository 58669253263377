import { Group, Space } from "@mantine/core";
import {
  QueryClient,
  QueryClientProvider,
  useQuery
} from "@tanstack/react-query";
import classnames from "classnames";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import {
  Link,
  useMatch,
  useNavigate,
  useResolvedPath,
  useSearchParams
} from "react-router-dom";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import api from "../../api";
import urls, { OptiSupportEndpoints } from "../../urls";
import { queryWithPollResponse } from "../../utils/api-utils";
import type { Todo } from "../../utils/backend-types";
import { TodoStatus } from "../../utils/backend-types";
import { LAST_YEAR } from "../../utils/dates";
import { downloadAsExcel } from "../../utils/files/downloadAsExcel";
import { Icon } from "../BuildingBlocks/Icon/Icon";
import { IconName } from "../BuildingBlocks/Icon/types";
import { Portlet } from "../BuildingBlocks/Layout/Portlet";
import { IconButton } from "../Buttons/IconButton/IconButton";
import type {
  CellToStringConverters,
  ColumnToExport
} from "../CustomReactTable/CustomReactTableHooks";
import { useCustomReactTableDataExport } from "../CustomReactTable/CustomReactTableHooks";
import { AnimatedLoadingIcon } from "../Icons/AnimatedLoadingIcon/AnimatedLoadingIcon";
import { StatusSymbolColour } from "../Icons/StatusSymbol";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { OptiSupportHelpLink } from "../OptiSupportHelpLink/OptiSupportHelpLink";
import { useShouldShowStaffView } from "../StaffViewToggle/useShouldShowStaffView";
import "./SiteStatus.scss";
import type {
  MeteringConceptInfo,
  OnboardingTodo,
  SiteOverviewSite,
  SiteStatusLogs,
  SiteStatusSite
} from "./site-status-types";
import { OnboardingTodosGroup } from "./site-status-types";
import { SiteStatusControls } from "./SiteStatusControls";
import { SiteStatusModal } from "./SiteStatusModal";
import { SiteStatusTable } from "./SiteStatusTable/SiteStatusTable";
import {
  DataAssessmentAccessor,
  DataAssessmentState
} from "./SiteStatusTable/TableCells/DataAssessment";
import { calculateNumDoneOnboardingTodos } from "./SiteStatusTable/utils/calculateNumDoneOnboardingTodos";
import { getOnboardingTodosSimpleStatus } from "./SiteStatusTable/utils/getOnboardingTodosSimpleStatus";
import { convertSiteLabelsToString } from "./utils/convertSiteLabelsToString";

enum SiteStatusTabs {
  Active = "aktiv",
  Inactive = "inaktiv"
}

const SIMPLE_COLUMNS_TO_EXPORT: Array<ColumnToExport> = [
  { accessor: "id", header: "ID" },
  { accessor: "classification", header: "Klassifizierung" },
  { accessor: "name", header: "Liegenschaft" },
  { accessor: "project", header: "Projekt" },
  { accessor: "onboardingTodos", header: "Nächste Onboarding-Aufgabe" },
  {
    accessor: DataAssessmentAccessor.SiteDataAssessmentGridMakoMeter,
    header: `Netzverknüpfungspunkt-Daten ${LAST_YEAR}`
  },
  {
    accessor: DataAssessmentAccessor.SiteDataAssessmentGeneratorSubMeter,
    header: `Daten der Erzeugungsanlagen ${LAST_YEAR}`
  },
  { accessor: "meteringConceptType", header: `Meldetyp ${LAST_YEAR}` }
];

const ADMIN_COLUMNS_TO_EXPORT: Array<ColumnToExport> = [
  { accessor: "id", header: "ID" },
  { accessor: "classification", header: "Klassifizierung" },
  { accessor: "name", header: "Liegenschaft" },
  { accessor: "project", header: "Projekt" },
  { accessor: "customer", header: "Kunde" },
  { accessor: "customers", header: "Kunden (alt)" },
  { accessor: "onboardingTodos", header: "Status Onboarding-Aufgaben" },
  { accessor: "meteringConceptRequirementsMet", header: "Voraussetzung" },
  { accessor: "meteringConceptType", header: `Meldetyp ${LAST_YEAR}` },
  { accessor: "siteLabels", header: "Label" }
];

const SIMPLE_CELL_TO_STRING_CONVERTERS: CellToStringConverters = {
  onboardingTodos: (todos: Array<Todo>) => {
    return getOnboardingTodosSimpleStatus(todos).displayText;
  },
  siteDataAssessmentGridMakoMeter: (assessment: boolean | null) => {
    if (assessment === null) {
      return "-";
    }

    return assessment === true
      ? DataAssessmentState.Sufficient
      : DataAssessmentState.NotSufficient;
  },
  siteDataAssessmentGeneratorSubMeter: (assessment: boolean | null) => {
    if (assessment === null) {
      return "-";
    }

    return assessment === true
      ? DataAssessmentState.Sufficient
      : DataAssessmentState.NotSufficient;
  }
};

const ADMIN_CELL_TO_STRING_CONVERTERS: CellToStringConverters = {
  onboardingTodos: (todos: Array<OnboardingTodo>) => {
    if (todos.length === 0) {
      return "";
    }

    const numDone = calculateNumDoneOnboardingTodos(todos);

    if (numDone === todos.length) {
      return "Erledigt";
    } else if (numDone > 0) {
      return "Teilweise erledigt";
    } else {
      return "Nicht erledigt";
    }
  },
  meteringConceptRequirementsMet: (requirementsMet: boolean) => {
    return requirementsMet ? "Erledigt" : "Nicht erledigt";
  },
  siteLabels: convertSiteLabelsToString
};

const queryClient = new QueryClient();

interface SiteStatusProps {
  sites: Array<SiteStatusSite> | Array<SiteOverviewSite>;
  title?: string;
  simple?: boolean;
  showProjectLinks?: boolean;
  showCreateProject?: boolean;
}

function SiteStatus({
  sites,
  title,
  simple,
  showProjectLinks,
  showCreateProject
}: SiteStatusProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const shouldShowStaffView = useShouldShowStaffView();
  const exportOptions = useMemo(
    () => ({
      columnsToExport: simple
        ? SIMPLE_COLUMNS_TO_EXPORT
        : ADMIN_COLUMNS_TO_EXPORT,
      cellToStringConverters: simple
        ? SIMPLE_CELL_TO_STRING_CONVERTERS
        : ADMIN_CELL_TO_STRING_CONVERTERS
    }),
    [simple]
  );
  const { setTableRef: setActiveTableRef, exportData: exportActiveData } =
    useCustomReactTableDataExport(exportOptions);
  const { setTableRef: setInactiveTableRef, exportData: exportInactiveData } =
    useCustomReactTableDataExport(exportOptions);
  const [exportIsLoading, setExportIsLoading] = useState(false);
  const [showDemoProjects, setShowDemoProjects] = useState(false);
  const [displayedActiveSitesCount, setDisplayedActiveSitesCount] = useState<
    number | null
  >(null);
  const [displayedInactiveSitesCount, setDisplayedInactiveSitesCount] =
    useState<number | null>(null);
  const [onboardingTodosModalTodos, setOnboardingTodosModalTodos] =
    useState<Array<OnboardingTodo> | null>(null);
  const [logsModalLogs, setLogsModalLogs] = useState<SiteStatusLogs | null>(
    null
  );
  const isOnboardingTodosModalOpen =
    !!onboardingTodosModalTodos && onboardingTodosModalTodos.length > 0;
  const [meteringConceptInfo, setMeteringConceptInfo] =
    useState<MeteringConceptInfo | null>(null);
  const isLogsModalOpen = !!logsModalLogs;
  const isMeteringConceptInfoModalOpen = !!meteringConceptInfo;

  const filteredSites = useMemo(
    () =>
      sites.filter(
        (site) =>
          site.projectIsDemo === showDemoProjects ||
          (!showDemoProjects && !site.projectIsDemo)
      ),
    [sites, showDemoProjects]
  );
  const activeSites = useMemo(
    () => filteredSites.filter((site) => site.projectIsActive),
    [filteredSites]
  );
  const inactiveSites = useMemo(
    () => filteredSites.filter((site) => !site.projectIsActive),
    [filteredSites]
  );

  const handleOpenOnboardingTodosModal = useCallback(
    (todos: Array<OnboardingTodo>) => {
      setOnboardingTodosModalTodos(todos);
    },
    []
  );

  function handleCloseOnboardingTodosModal() {
    setOnboardingTodosModalTodos(null);
  }

  const handleOpenLogsModal = useCallback((logs: SiteStatusLogs) => {
    setLogsModalLogs(logs);
  }, []);

  function handleCloseLogsModal() {
    setLogsModalLogs(null);
  }

  const handleOpenMeteringConceptInfoCellModal = useCallback(
    (mbkInfo: MeteringConceptInfo) => {
      setMeteringConceptInfo(mbkInfo);
    },
    []
  );

  function handleCloseMeteringConceptInfoModal() {
    setMeteringConceptInfo(null);
  }

  function handleProjectCreated(projectId: string) {
    navigate(urls.managerProjectView(projectId));
  }

  function getDisplayedSitesCountText(
    displayText: boolean,
    sitesCount: number | null,
    totalSites: number
  ) {
    if (!displayText || sitesCount === null) {
      return null;
    }

    return (
      <span>
        {sitesCount} von {totalSites} Liegenschaften
      </span>
    );
  }

  async function handleClickExport() {
    setExportIsLoading(true);

    const exportPromise =
      activeTab === SiteStatusTabs.Active
        ? exportActiveData()
        : exportInactiveData();
    const rows = await exportPromise;

    downloadAsExcel(rows, "site-status");
    setExportIsLoading(false);
  }

  if (!filteredSites) {
    return (
      <div className="site-status-loader">
        <AnimatedLoadingIcon />
      </div>
    );
  }

  const siteStatusControls = (
    <SiteStatusControls
      showCreateProjectButton={!!showCreateProject && filteredSites.length > 0}
      showDemoProjects={showDemoProjects}
      showDemoProjectsToggle={shouldShowStaffView}
      onCreateProject={handleProjectCreated}
      onToggleDemoProjects={setShowDemoProjects}
    />
  );
  const portletNavItems = [siteStatusControls];
  const showTabs = inactiveSites.length > 0;
  const activeTab =
    showTabs && searchParams.get(SiteStatusTabs.Inactive) !== null
      ? SiteStatusTabs.Inactive
      : SiteStatusTabs.Active;

  return (
    <div className="SiteStatus">
      <Row>
        <Col>
          <Portlet navItems={portletNavItems} title={title}>
            {showTabs && (
              <div className="tabs-and-more">
                <Nav className="tabs" pills>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === SiteStatusTabs.Active
                      })}
                      tag={Link}
                      to={`./?aktiv`}
                    >
                      Aktive Projekte
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === SiteStatusTabs.Inactive
                      })}
                      tag={Link}
                      to={`./?inaktiv`}
                    >
                      Inaktive Projekte
                    </NavLink>
                  </NavItem>
                </Nav>
                <Group
                  className="export-and-site-count-or-help"
                  gap="md"
                  mb="md"
                >
                  {shouldShowStaffView ? (
                    <>
                      <IconButton
                        className="export-button"
                        color="brand"
                        disabled={exportIsLoading}
                        iconName={
                          exportIsLoading
                            ? IconName.SpinnerSpinning
                            : IconName.Download
                        }
                        size="sm"
                        onClick={handleClickExport}
                      >
                        Als Excel-Datei herunterladen
                      </IconButton>
                      <Space h="md" />
                    </>
                  ) : (
                    <OptiSupportHelpLink
                      iconName={IconName.QuestionCircle2}
                      optiSupportEndpoint={OptiSupportEndpoints.Meldedaten}
                      text="Meldetypen in opti.node"
                    />
                  )}
                  {getDisplayedSitesCountText(
                    shouldShowStaffView,
                    activeTab === SiteStatusTabs.Active
                      ? displayedActiveSitesCount
                      : displayedInactiveSitesCount,
                    activeTab === SiteStatusTabs.Active
                      ? activeSites.length
                      : inactiveSites.length
                  )}
                </Group>
              </div>
            )}
            <TabContent activeTab={activeTab}>
              {activeTab === SiteStatusTabs.Active && (
                <TabPane tabId={SiteStatusTabs.Active}>
                  <SiteStatusTable
                    setTableRef={setActiveTableRef}
                    showCategoriesColumn={shouldShowStaffView}
                    showProjectLinks={showProjectLinks}
                    simple={simple}
                    sites={activeSites}
                    onClickLogsCell={handleOpenLogsModal}
                    onClickMeteringConceptInfoCell={
                      handleOpenMeteringConceptInfoCellModal
                    }
                    onClickOnboardingTodosCell={handleOpenOnboardingTodosModal}
                    onNumDisplayedRowsUpdated={setDisplayedActiveSitesCount}
                  />
                </TabPane>
              )}
              <TabPane tabId={SiteStatusTabs.Inactive}>
                {inactiveSites.length > 0 &&
                  activeTab === SiteStatusTabs.Inactive && (
                    <SiteStatusTable
                      setTableRef={setInactiveTableRef}
                      showCategoriesColumn={shouldShowStaffView}
                      showProjectLinks={showProjectLinks}
                      simple={simple}
                      sites={inactiveSites}
                      onClickLogsCell={handleOpenLogsModal}
                      onClickMeteringConceptInfoCell={
                        handleOpenMeteringConceptInfoCellModal
                      }
                      onClickOnboardingTodosCell={
                        handleOpenOnboardingTodosModal
                      }
                      onNumDisplayedRowsUpdated={setDisplayedInactiveSitesCount}
                    />
                  )}
              </TabPane>
            </TabContent>
          </Portlet>
        </Col>
      </Row>
      <OnboardingTodosModal
        isOpen={isOnboardingTodosModalOpen}
        todos={onboardingTodosModalTodos}
        onClose={handleCloseOnboardingTodosModal}
      />
      <MeteringConceptInfoModal
        isOpen={isMeteringConceptInfoModalOpen}
        mbkInfo={meteringConceptInfo}
        onClose={handleCloseMeteringConceptInfoModal}
      />
      <LogsModal
        isOpen={isLogsModalOpen}
        logs={logsModalLogs}
        onClose={handleCloseLogsModal}
      />
    </div>
  );
}

interface OnboardingTodoModalProps {
  todos: Array<OnboardingTodo> | null;
  isOpen: boolean;
  onClose: () => void;
}

function OnboardingTodosModal({
  todos,
  isOpen,
  onClose
}: OnboardingTodoModalProps) {
  const safeTodos = todos || [];
  const noGroupTodos = safeTodos.filter((todo) => !todo.group);
  const meteringConceptCreationRequirementTodos = safeTodos.filter(
    (todo) =>
      todo.group === OnboardingTodosGroup.MeteringConceptCreationRequirement
  );
  const meteringConceptCreationStatusTodos = safeTodos.filter(
    (todo) => todo.group === OnboardingTodosGroup.MeteringConceptCreationStatus
  );

  return (
    <SiteStatusModal
      className="onboarding-todos-modal"
      header="Status Onboarding-Aufgaben"
      isOpen={isOpen}
      onClose={onClose}
    >
      <TodosGroup
        title="Voraussetzung für Messkonzepterstellung"
        todos={meteringConceptCreationRequirementTodos}
      />
      <TodosGroup
        title="Status der Messkonzepterstellung"
        todos={meteringConceptCreationStatusTodos}
      />
      <TodosGroup title="Weitere Aufgaben" todos={noGroupTodos} />
    </SiteStatusModal>
  );
}

interface MeteringConceptInfoModalProps {
  mbkInfo: MeteringConceptInfo | null;
  isOpen: boolean;
  onClose: () => void;
}

function MeteringConceptInfoModal({
  mbkInfo,
  isOpen,
  onClose
}: MeteringConceptInfoModalProps) {
  return (
    <SiteStatusModal
      header="Letzte MBK Fehlermeldung"
      isOpen={isOpen}
      onClose={onClose}
    >
      <p style={{ whiteSpace: "pre-wrap" }}>
        {mbkInfo?.errorMessages.join("\n")}
      </p>
    </SiteStatusModal>
  );
}

interface LogsModalProps {
  logs: SiteStatusLogs | null;
  isOpen: boolean;
  onClose: () => void;
}

function LogsModal({ logs, isOpen, onClose }: LogsModalProps) {
  return (
    <SiteStatusModal
      header="Hinweise aus der Verbrauchsanalyse"
      isOpen={isOpen}
      onClose={onClose}
    >
      <h6>Infos</h6>
      <ul>
        {logs && logs.infos && logs.infos.length > 0 ? (
          logs.infos.map((info, index) => <li key={index}>{info}</li>)
        ) : (
          <li>Keine Infos</li>
        )}
      </ul>
      <h6>Warnungen</h6>
      <ul>
        {logs && logs.warnings && logs.warnings.length > 0 ? (
          logs.warnings.map((warning, index) => <li key={index}>{warning}</li>)
        ) : (
          <li>Keine Warnungen</li>
        )}
      </ul>
      <h6>Blocker</h6>
      <ul>
        {logs && logs.errors && logs.errors.length > 0 ? (
          logs.errors.map((error, index) => <li key={index}>{error}</li>)
        ) : (
          <li>Keine Blocker</li>
        )}
      </ul>
    </SiteStatusModal>
  );
}

interface TodosGroupProps {
  todos: Array<OnboardingTodo>;
  title: string;
}

function TodosGroup({ todos, title }: TodosGroupProps) {
  if (!todos || todos.length === 0) {
    return (
      <TodosList title={title}>
        <li className="onboarding-todo-list-item">
          <Icon
            name={IconName.Question}
            style={{
              marginRight: "5px"
            }}
          />{" "}
          Keine Aufgaben.
        </li>
      </TodosList>
    );
  }

  return <TodosList title={title}>{todos.map(TodoListItem)}</TodosList>;
}

interface TodoListProps {
  title: string;
  children: React.ReactNode;
}

function TodosList({ title, children }: TodoListProps) {
  return (
    <React.Fragment>
      {title && <h6>{title}</h6>}
      <ul className="onboarding-todo-list">{children}</ul>
    </React.Fragment>
  );
}

function TodoListItem(todo: OnboardingTodo, index: number) {
  return (
    <li className="onboarding-todo-list-item" key={index}>
      <Icon
        name={todo.status === TodoStatus.Done ? IconName.Check : IconName.Times}
        style={{
          marginRight: "5px",
          color:
            todo.status === TodoStatus.Done
              ? StatusSymbolColour.Green
              : StatusSymbolColour.Red
        }}
      />
      {todo.label}
    </li>
  );
}

SiteStatus.propTypes = {
  sites: PropTypes.array.isRequired
};

function SiteStatusLoader({ title = "Ihre Projekte" }) {
  const resolved = useResolvedPath(":year");
  const match = useMatch({ path: resolved.pathname, end: true });
  const year = match && match.params.year ? match.params.year : undefined;
  const url = year
    ? urls.api.siteStatusOverviewByYear(parseInt(year, 10))
    : urls.api.siteStatusOverview();

  const {
    data: sites,
    isLoading,
    error
  } = useQuery({
    queryKey: ["site-status-overview"],
    queryFn: async () => {
      const response = await queryWithPollResponse(() => api.get(url), {
        queryAgainOnPollSuccess: true
      });
      return response.data;
    },
    refetchOnWindowFocus: false
  });

  return (
    <LoadOrError error={error} loading={isLoading}>
      {sites && <SiteStatus sites={sites} title={title} />}
    </LoadOrError>
  );
}

function SiteStatusLoaderWithQueryClientProvider(props) {
  return (
    <QueryClientProvider client={queryClient}>
      <SiteStatusLoader {...props} />
    </QueryClientProvider>
  );
}

function SiteOverviewLoader({ title = "Ihre Projekte" }) {
  const {
    data: sites,
    isLoading,
    error
  } = useQuery({
    queryKey: ["site-overview"],
    queryFn: fetchSiteOverview,
    refetchInterval: false,
    refetchOnWindowFocus: false
  });

  async function fetchSiteOverview() {
    const response = await api.get<Array<SiteOverviewSite>>(
      urls.api.siteOverview()
    );
    return response.data;
  }

  return (
    <LoadOrError error={error} loading={isLoading}>
      {sites && (
        <SiteStatus
          showCreateProject
          showProjectLinks
          simple
          sites={sites}
          title={title}
        />
      )}
    </LoadOrError>
  );
}

export {
  SiteOverviewLoader as SiteOverview,
  SiteStatusLoaderWithQueryClientProvider as SiteStatus,
  SiteStatus as SiteStatusNoLoaderNoQueryClientProvider
};
