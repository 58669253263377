import React from "react";
import { DeleteIcon } from "../../../Buttons/DeleteIcon";
import { EditIcon } from "../../../Buttons/EditIcon";
import { Spacer } from "./Note";

interface NoteControlsProps {
  canEdit: boolean;
  canDelete: boolean;
  onEdit: () => void;
  onDelete: () => void;
}

function NoteControls({
  canEdit,
  canDelete,
  onEdit,
  onDelete
}: NoteControlsProps) {
  return (
    <span className="controls">
      <Spacer />
      {canEdit && (
        <span
          aria-label="Notiz bearbeiten"
          role="button"
          tabIndex={0}
          onClick={onEdit}
        >
          <EditIcon tooltipText="Notiz bearbeiten" />
        </span>
      )}
      {canDelete && (
        <span
          aria-label="Notiz löschen"
          role="button"
          tabIndex={0}
          onClick={onDelete}
        >
          <DeleteIcon tooltipText="Notiz löschen" />
        </span>
      )}
    </span>
  );
}

export { NoteControls };
