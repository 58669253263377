import { buttonColors } from "../../Buttons/Button/Button";
import type { ParkabrechnungTag } from "../ParkabrechnungOverview/ParkabrechnungData/ParkabrechnungData";

export const DOWNLOAD_BUTTON_LABEL = "Herunterladen";
export const DOWNLOAD_BUTTON_LABEL_PROJECT_OVERVIEW =
  "Projektübersicht herunterladen";
export const BUTTON_COLOR_BRAND = buttonColors.brand;

export const PARK_TYPE = {
  gridFeedouts: "grid_feedouts",
  thirdPartyDeliveries: "third_party_deliveries"
} as const;

export type NetzbezugDownloadButtonProps =
  ParkabrechnungDownloadButtonCommonProps;

export type ActivatedDrittlieferungButtonProps =
  ParkabrechnungDownloadButtonCommonProps & {
    payload: DrittlieferungExportPayload;
  };

export type ParkabrechnungDownloadButtonCommonProps = {
  startDate: string;
  endDate: string;
  projectId: string;
  parkType: ParkabrechnungTag;
};

export type DrittlieferungExportPayload = {
  siteId: number;
  supplierId: number;
  recipientId: number;
  priceCtPerKwh: number;
}[];
