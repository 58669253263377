import PropTypes from "prop-types";
import React from "react";

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function SectionHeader({ children }) {
  return <h6 style={{ marginTop: "30px" }}>{children}</h6>;
}

SectionHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object)
  ])
};

export { SectionHeader };
