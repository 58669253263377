export type MeteringMarketFormField = { name: string; staffOnly?: boolean };

export const METERING_LOCATION_FORM_FIELD_NAMES: MeteringMarketFormField[] = [
  { name: "number" },
  { name: "customLabel" },
  { name: "meteringServiceProvider" },
  { name: "status", staffOnly: true },
  { name: "comment", staffOnly: true }
];

export const MARKET_LOCATION_FORM_FIELD_NAMES: MeteringMarketFormField[] = [
  { name: "number" },
  { name: "customLabel" },
  { name: "meteringServiceProvider" },
  { name: "status", staffOnly: true },
  { name: "comment", staffOnly: true }
];

export const getCustomerFields = (fields: MeteringMarketFormField[]) =>
  fields.filter((field) => !field.staffOnly);

export const formFieldNames = (fields: MeteringMarketFormField[]) =>
  fields.map((field) => field.name);
