import React, { useState } from "react";
import { Col, Collapse } from "reactstrap";

import { Icon } from "../../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../../BuildingBlocks/Icon/types";
import { Row } from "../../../../BuildingBlocks/Layout/Row";
import { Button } from "../../../../Buttons/Button/Button";

export function MarktakteurContactInformation({ marktakteur }) {
  return (
    <div>
      <Row mb2>
        <Col>
          <b>Adresse</b>
          <div>
            <div>
              <span>{marktakteur.firmenname}</span>{" "}
            </div>
            <div>
              <span>{marktakteur.strasse}</span>{" "}
              <span>{marktakteur.hausnummer}</span>{" "}
              <span>{marktakteur.adresszusatz}</span>
            </div>
            <div>
              <span>{marktakteur.postleitzahl}</span>{" "}
              <span>{marktakteur.ort}</span>
            </div>
          </div>
        </Col>
        <Col>
          <b>Zustelladresse</b>
          <div>
            <div>
              <span>{marktakteur.firmenname}</span>{" "}
            </div>
            <div>
              <span>
                {marktakteur.strasseAnZustelladresse
                  ? marktakteur.strasseAnZustelladresse
                  : marktakteur.strasse}
              </span>{" "}
              <span>
                {marktakteur.hausnummerAnZustelladresse
                  ? marktakteur.hausnummerAnZustelladresse
                  : marktakteur.hausnummer}
              </span>{" "}
              <span>
                {marktakteur.adresszusatzAnZustelladresse
                  ? marktakteur.adresszusatzAnZustelladresse
                  : marktakteur.adresszusatz}
              </span>
            </div>
            <div>
              <span>
                {marktakteur.postleitzahlAnZustelladresse
                  ? marktakteur.postleitzahlAnZustelladresse
                  : marktakteur.postleitzahl}
              </span>{" "}
              <span>
                {marktakteur.ortAnZustelladresse
                  ? marktakteur.ortAnZustelladresse
                  : marktakteur.ort}
              </span>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <b>Kontakt (Marktakteur)</b>
          <div>
            <ContactEntry
              iconName={IconName.Envelope}
              value={marktakteur.email}
            />
            <ContactEntry
              iconName={IconName.Home}
              value={marktakteur.webseite}
            />
            <ContactEntry
              iconName={IconName.Phone}
              value={marktakteur.telefon}
            />
            <ContactEntry iconName={IconName.Fax} value={marktakteur.fax} />
          </div>
        </Col>
      </Row>
      <MarktrollenContactInformation marktrollen={marktakteur.marktrollen} />
    </div>
  );
}

function ContactEntry({ value, iconName }) {
  if (!value) {
    return null;
  }
  return (
    <div>
      <Icon name={iconName} /> {value}
    </div>
  );
}

function MarktrollenContactInformation({ marktrollen }) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const marktrollenWithContactInformation = marktrollen.filter(
    (data) => !!data.kontaktdatenMarktrolle
  );

  if (marktrollenWithContactInformation.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <Button className="my-2" color="secondary" size="sm" onClick={toggle}>
        Kontakt Marktrollen {isOpen ? "ausblenden" : "anzeigen"}
      </Button>
      <Collapse isOpen={isOpen}>
        {marktrollenWithContactInformation.map((data) => {
          return (
            <Row key={data.marktrolle}>
              <Col>
                <b>Marktrolle - {data.marktrolle}</b>
                <div>
                  {data.kontaktdatenMarktrolle
                    .split("\r\n")
                    .map((line, index) => (
                      <div key={index}>{line}</div>
                    ))}
                </div>
              </Col>
            </Row>
          );
        })}
      </Collapse>
    </React.Fragment>
  );
}
