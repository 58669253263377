import React, { useState } from "react";
import { Icon } from "../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import { DummyLink } from "../../../DummyLink/DummyLink";
import { ColorSelectionModal } from "../ColorSelectionModal/ColorSelectionModal";
import "./CustomContainerElementPopup.scss";

interface CustomContainerElementPopupProps {
  x: number;
  y: number;
  nodeId: string;
  onClickDelete: (nodeId: string) => void;
  onColorChange: (color: string) => void;
  currentlySelectedColor?: string;
}

function CustomContainerElementPopup({
  x,
  y,
  nodeId,
  currentlySelectedColor,
  onClickDelete,
  onColorChange
}: CustomContainerElementPopupProps) {
  const [showColorChange, setShowColorChange] = useState(false);
  return (
    <div className="CustomContainerElementPopup" style={{ top: y, left: x }}>
      <LinksAndDetails
        onClickDelete={() => onClickDelete(nodeId)}
        onShowColorChange={() => setShowColorChange(true)}
      />
      {showColorChange && (
        <ColorSelectionModal
          currentlySelectedColor={currentlySelectedColor}
          onColorChange={onColorChange}
          onExit={() => setShowColorChange(false)}
        />
      )}
    </div>
  );
}

interface LinksAndDetailsProps {
  onClickDelete: () => void;
  onShowColorChange: () => void;
}

function LinksAndDetails({
  onClickDelete,
  onShowColorChange
}: LinksAndDetailsProps) {
  return (
    <div className="LinksAndDetails">
      <div className="delete-link">
        <span className="small">
          <DummyLink onClick={onClickDelete}>
            <Icon className="m--font-danger" name={IconName.Trash} /> Rahmen
            löschen
          </DummyLink>
        </span>
      </div>
      <div className="color-edit-link">
        <span className="small">
          <DummyLink onClick={onShowColorChange}>
            <Icon name={IconName.PieChart} /> Farbe ändern
          </DummyLink>
        </span>
      </div>
    </div>
  );
}
export { CustomContainerElementPopup };
