import { Group, Button as MantineButton, Menu } from "@mantine/core";
import {
  type MRT_ColumnDef,
  type MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable
} from "mantine-react-table";
import React, { useCallback, useMemo, useState } from "react";
import api from "../../api";
import { useNav } from "../../hooks/useNav";
import { usePersons } from "../../hooks/usePersons";
import { useProject } from "../../hooks/useProject";
import { getDefaultMRTOptions } from "../../mantine/getDefaultMRTOptions";
import urls from "../../urls";
import {
  type Priority,
  type Todo,
  TodoGroup,
  TodoStatus
} from "../../utils/backend-types";
import { downloadCsvOrExcelFileForResponseWithContentDisposition } from "../../utils/files/downloadCsvOrExcelFileForResponseWithContentDisposition";
import { Icon } from "../BuildingBlocks/Icon/Icon";
import { IconName } from "../BuildingBlocks/Icon/types";
import { Button } from "../Buttons/Button/Button";
import { openErrorAlertPopup } from "../ErrorAlertPopup/openErrorAlertPopup";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { useShouldShowStaffView } from "../StaffViewToggle/useShouldShowStaffView";
import { CLUSTERS, Mode } from "../Todos/common";
import { useTodos } from "../Todos/hooks/useTodos";
import { getDocumentProvidedColumn } from "./Columns/getDocumentProvidedColumn";
import { getDueDateColumn } from "./Columns/getDueDateColumn";
import { getFulfillForColumn } from "./Columns/getFulfillForColumn";
import { getLabelColumn } from "./Columns/getLabelColumn";
import { getPersonColumn } from "./Columns/getPersonColumn";
import { getPriorityColumn } from "./Columns/getPriorityColumn";
import { getResponsibleColumn } from "./Columns/getResponsibleColumn";
import { getStatusColumn } from "./Columns/getStatusColumn";
import { TabValues } from "./TodosV2";
import "./TodoTable.scss";
import { handleChangePriority } from "./utils/handleChangePriority";
import { handleChangeResponsible } from "./utils/handleChangeResponsible";
import { handleChangeStatus } from "./utils/handleChangeStatus";

const enum TodosListColumnKeys {
  Group = "group",
  Cluster = "cluster",
  Label = "label",
  DueDate = "dueDate",
  Priority = "priority",
  Responsible = "responsible",
  FulfillFor = "fulfillFor",
  Person = "person",
  Status = "status",
  DocumentProvided = "documentProvided"
}

const groupLabelMapping = {
  [TodoGroup.ProjectPreparation]: "Projektvorbereitung",
  [TodoGroup.RegulatoryDuties]: "Pflichten"
};

interface TodosListProps {
  isPremium: boolean;
  mode: TabValues;
  variantId: number;
  projectId: string;
  onRowSelectionChange: (rowSelection: Array<number>) => void;
}

function TodoTable({
  isPremium,
  mode,
  variantId,
  projectId,
  onRowSelectionChange
}: TodosListProps) {
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const isStaff = useShouldShowStaffView();

  const {
    project,
    isLoading: projectLoading,
    error: projectError
  } = useProject(projectId);
  const users = project?.managers;

  const {
    todos,
    sortedTodos,
    isLoading: todosLoading,
    error: todosError,
    updateTodoResponsible,
    updateTodoPriority,
    updateTodoStatus
  } = useTodos({
    mode: Mode.Normal,
    variantId,
    isRecurring: mode === TabValues.Recurring,
    onUpdateError: openErrorAlertPopup
  });

  const {
    persons,
    isLoading: personsLoading,
    error: personsError
  } = usePersons(variantId);

  const navigate = useNav();

  const toggleTodoModal = (todoId: number | false) => {
    if (todoId) {
      navigate(`./${todoId}`);
    } else {
      navigate(".");
    }
  };

  const callHandleChangePriority = useCallback(
    (priorityValue: Priority, todoId: number) =>
      handleChangePriority(
        todos || [],
        updateTodoPriority,
        priorityValue,
        todoId
      ),
    [todos, updateTodoPriority]
  );

  const callHandleChangeResponsible = useCallback(
    (responsibleId: number | null, todoId: number) =>
      handleChangeResponsible(
        todos || [],
        updateTodoResponsible,
        responsibleId,
        todoId
      ),
    [todos, updateTodoResponsible]
  );

  const callHandleChangeStatus = useCallback(
    (status: TodoStatus, todoId: number) =>
      handleChangeStatus(todos || [], updateTodoStatus, status, todoId),
    [todos, updateTodoStatus]
  );

  function getSelectedAndVisibleTodos() {
    return todos
      ? todos.filter((todo) =>
          Object.keys(rowSelection).includes(todo.id.toString())
        )
      : [];
  }

  function getDownloadButtonLabel() {
    const selectedTodos = getSelectedAndVisibleTodos();
    const totalSelectedTodos = selectedTodos.length;
    if (totalSelectedTodos === 0) {
      return "";
    }

    let totalDocuments = 0;
    let numberOfTodosWithDocuments = 0;
    selectedTodos.forEach((todo) => {
      totalDocuments += todo.todoFiles.length;
      if (todo.todoFiles.length > 0) {
        numberOfTodosWithDocuments += 1;
      }
    });
    if (totalDocuments === 0) {
      return "";
    }
    const documentWording = totalDocuments === 1 ? "Dokument" : "Dokumente";
    return `${totalDocuments} ${documentWording} (in ${numberOfTodosWithDocuments} von ${totalSelectedTodos} Aufgaben)`;
  }
  const downloadButtonLabel = getDownloadButtonLabel();

  function handleDownloadSelectedTodoDocuments() {
    const selectedTodos = getSelectedAndVisibleTodos();
    const selectedTodoIds = selectedTodos.map((todo) => todo.id);
    if (selectedTodoIds && selectedTodoIds.length > 0) {
      const url = urls.api.downloadSelectedTodoDocuments(variantId);
      const body = {
        todoIds: selectedTodoIds
      };
      api
        .post(url, body)
        .then((response) =>
          downloadCsvOrExcelFileForResponseWithContentDisposition(
            response,
            true
          )
        )
        .catch((error) => openErrorAlertPopup(error));
    }
  }

  const columns: Array<MRT_ColumnDef<Todo>> = useMemo(() => {
    const cols = [
      {
        accessorKey: TodosListColumnKeys.Group,
        header: "Gruppe",
        getGroupingValue: (row) => groupLabelMapping[row.group]
      },
      {
        accessorKey: TodosListColumnKeys.Cluster,
        header: "Kategorie",
        getGroupingValue: (row) => {
          const key = Object.keys(CLUSTERS).find(
            (clusterKey) => CLUSTERS[clusterKey].value === row.cluster
          );
          return key ? CLUSTERS[key].displayName : "";
        }
      },
      getLabelColumn(),
      getDueDateColumn(todos || [], isPremium),
      getPriorityColumn(callHandleChangePriority),
      getResponsibleColumn(callHandleChangeResponsible, todos || [], users),
      getFulfillForColumn(todos || []),
      getPersonColumn(todos || []),
      getStatusColumn(callHandleChangeStatus)
    ];
    if (isPremium) {
      cols.push(getDocumentProvidedColumn());
    }
    return cols;
  }, [
    todos,
    isPremium,
    users,
    callHandleChangePriority,
    callHandleChangeResponsible,
    callHandleChangeStatus
  ]);

  const table = useMantineReactTable({
    ...getDefaultMRTOptions<Todo>({
      emptyRowsFallbackText: "Keine Daten vorhanden."
    }),
    columns,
    data: sortedTodos || [],
    enableToolbarInternalActions: false,
    enablePagination: false,
    enableRowVirtualization: true,
    positionToolbarAlertBanner: "none",
    enableSorting: false,
    enableGrouping: true,
    groupedColumnMode: "remove",
    columnFilterDisplayMode: "popover",
    positionGlobalFilter: "left",
    enableRowSelection: isStaff,
    initialState: {
      columnFilters: [
        { id: TodosListColumnKeys.Status, value: TodoStatus.Open }
      ],
      expanded: true,
      grouping: [TodosListColumnKeys.Group, TodosListColumnKeys.Cluster],
      showGlobalFilter: true
    },
    state: {
      rowSelection
    },
    onRowSelectionChange: (newSelection) => {
      setRowSelection(newSelection);
      if (typeof newSelection === "function") {
        onRowSelectionChange(
          Object.keys(newSelection(rowSelection)).map((idAsString) =>
            parseInt(idAsString)
          )
        );
      }
    },
    mantineExpandButtonProps: ({ row }) => ({
      size: row.groupingColumnId === TodosListColumnKeys.Group ? 0 : "md",
      children: (
        <Icon
          name={row.getIsExpanded() ? IconName.AngleDown : IconName.AngleRight}
        />
      )
    }),
    /* hide grouping cells and useless chevrons */
    mantineTableHeadCellProps: ({ column }) => ({
      display: column.getIndex() === 0 ? "none" : "block"
    }),
    /* hide grouping cells and useless chevrons */
    mantineTableBodyCellProps: ({ row, column }) => ({
      onClick: () => {
        if (row.groupingColumnId === TodosListColumnKeys.Cluster) {
          row.toggleExpanded();
        } else if (column.id === TodosListColumnKeys.Label) {
          toggleTodoModal(row.original.id);
        }
      },
      display:
        (row.getCanExpand() && column.getIndex() !== 0) ||
        (!row.getCanExpand() && column.getIndex() === 0)
          ? "none"
          : "block",
      style: {
        fontWeight: row.getCanExpand() ? "bold" : "normal",
        cursor:
          row.groupingColumnId === TodosListColumnKeys.Cluster ||
          column.id === TodosListColumnKeys.Label
            ? "pointer"
            : "default"
      }
    }),
    mantineTopToolbarProps: {
      pb: "md"
    },
    renderTopToolbarCustomActions: () => (
      <Group>
        <Button
          className="download-selected-documents-button"
          color="brand"
          disabled={!downloadButtonLabel}
          onClick={handleDownloadSelectedTodoDocuments}
        >
          Dokumente herunterladen
        </Button>
        <Menu>
          <Menu.Target>
            <MantineButton className="toggle-expand">
              <Icon name={IconName.More} />
            </MantineButton>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              onClick={() =>
                table.setExpanded(
                  Object.assign(
                    {},
                    ...table
                      .getCenterRows()
                      .filter((row) => row.getCanExpand())
                      .map((row) => ({ [row.id]: true }))
                  )
                )
              }
            >
              Alle ausklappen
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                table.setExpanded(
                  Object.assign(
                    {},
                    ...table
                      .getCenterRows()
                      .filter(
                        (row) =>
                          row.groupingColumnId === TodosListColumnKeys.Group
                      )
                      .map((row) => ({ [row.id]: true }))
                  )
                )
              }
            >
              Alle reduzieren
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
    )
  });

  return (
    <div className="TodoTable">
      <LoadOrError
        error={projectError || personsError || todosError}
        loading={projectLoading || personsLoading || todosLoading}
      >
        {project && persons && todos && (
          <>
            <MantineReactTable table={table} />
          </>
        )}
      </LoadOrError>
    </div>
  );
}

export { TodoTable, TodosListColumnKeys, TodosListProps };
