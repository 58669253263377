import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import type { HistoryLogs } from "../../../utils/backend-types";
import type { EntityId, EntityType } from "../HistoryTab.types";
import { HISTORY_API_URLS } from "../HistoryTab.types";

export function useHistory(
  entityId: EntityId,
  entityType: EntityType,
  options?: Partial<UseQueryOptions<HistoryLogs>>
) {
  return useQuery({
    queryKey: ["historyData", { entityId }],
    queryFn: () => fetchHistoryData(entityType, entityId),
    ...options
  });
}

async function fetchHistoryData(entityType: EntityType, entityId: EntityId) {
  const historyUrl = getHistoryUrl(entityType, entityId);
  const response = await apiWithoutCamelization.get<HistoryLogs>(historyUrl);
  return response.data;
}

function getHistoryUrl(entityType: EntityType, entityId: EntityId) {
  return HISTORY_API_URLS[entityType](entityId);
}
