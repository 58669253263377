import React from "react";
import type { Control, FieldErrors } from "react-hook-form";
import type { Delivery } from "../../../../../../../utils/backend-types";
import type {
  FormFieldData,
  FormInputData
} from "../../../../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { FormFieldController } from "../../../../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { isFieldError } from "../../../../../../BuildingBlocks/Forms/FormField/HelperText/utils/isFieldError";
import { SectionHeader } from "../../../../../../DynamicForm/FormItems/SectionHeader/SectionHeader";
import { ChangeablePricesField } from "../../../../SharedFields/ChangeablePricesField";
import { DELIVERY_FORM_HELP_TEXTS } from "../../DeliveryForm.constants";
import { ChangeableSpotMarketField } from "../ChangeableSpotMarketField/ChangeableSpotMarketField";

interface DirectDeliveryPassingOnFieldsProps {
  control: Control<Delivery>;
  errors: FieldErrors<Delivery>;
  formFieldData: FormFieldData<Delivery>;
  useSpotMarketPrices?: boolean;
  areSpotMarketPricesAvailable?: boolean;
  spotMarketPricesError?: Record<string, Array<string>>;
}

function DirectDeliveryPassingOnFields({
  control,
  errors,
  formFieldData,
  useSpotMarketPrices,
  areSpotMarketPricesAvailable,
  spotMarketPricesError
}: DirectDeliveryPassingOnFieldsProps) {
  return (
    <>
      <SectionHeader>Konditionen Direktlieferung</SectionHeader>
      {areSpotMarketPricesAvailable && (
        <FormFieldController
          control={control}
          data={
            formFieldData.useSpotMarketPrices as FormInputData<
              Delivery,
              "useSpotMarketPrices"
            >
          }
          error={errors.useSpotMarketPrices}
        />
      )}
      {areSpotMarketPricesAvailable && useSpotMarketPrices ? (
        <ChangeableSpotMarketField
          className="SpotMarketPrices"
          control={control}
          error={spotMarketPricesError}
          label="Börsenpreis Formel (netto)*"
          name="spotMarketPrices"
          unit="ct/kWh"
        />
      ) : (
        <ChangeablePricesField
          className="WorkingPrices"
          control={control}
          error={
            isFieldError(errors.workingPrices)
              ? errors.workingPrices
              : undefined
          }
          helpText={DELIVERY_FORM_HELP_TEXTS.workingPrices}
          label="Arbeitspreis exkl. Abgaben/Umlagen, exkl. MWSt.*"
          name="workingPrices"
          unit="ct/kWh"
        />
      )}
      <FormFieldController
        control={control}
        data={
          formFieldData.electricityTax as FormInputData<
            Delivery,
            "electricityTax"
          >
        }
        error={errors.electricityTax}
      />
    </>
  );
}

export { DirectDeliveryPassingOnFields };
