import api from "../../../api";
import urls from "../../../urls";

export interface RefreshSuccess {
  access?: string;
  ephemeralToken?: string;
  method?: string;
}

export const login = async (username: string, password: string) => {
  const { data } = await api.publicRequest<RefreshSuccess>(
    "POST",
    urls.auth.login(),
    {
      username,
      password
    }
  );

  if (data.ephemeralToken) {
    sessionStorage.setItem("ephemeral_token", data.ephemeralToken);
    return data;
  }

  api.setAccessToken(data.access);
};
