import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import urls from "../../../urls";
import type { EnergyRevenuesListResponse } from "../RevenueMonitoring.types";

export function usePersonEnergyRevenuesData(
  personId?: number,
  options?: Partial<UseQueryOptions<EnergyRevenuesListResponse>>
) {
  const {
    data: energyRevenuesData,
    isLoading,
    isFetching,
    error
  } = useQuery({
    queryKey: ["energyRevenuesData", { personId }],
    queryFn: () => fetchEnergyRevenuesData(personId),
    enabled: !!personId,
    retry: false,
    ...options
  });

  async function fetchEnergyRevenuesData(personId?: number) {
    if (!personId) return [];

    const revenueMonitoringPerPersonUrl =
      urls.apiWithoutCamelization.revenueMonitoringPerPerson({ personId });
    const response =
      await apiWithoutCamelization.get<EnergyRevenuesListResponse>(
        revenueMonitoringPerPersonUrl
      );
    return response.data;
  }

  return {
    energyRevenuesData,
    isLoading,
    isFetching,
    error
  };
}
