import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import api from "../../../../api";
import urls from "../../../../urls";
import { queryWithPollResponse } from "../../../../utils/api-utils";
import type { Person } from "../../../../utils/backend-types";

export function useImportPersonFromMastr(
  taskId: string,
  options?: Partial<UseQueryOptions<Person | undefined>>
) {
  async function fetchPersonFromMastr(taskId: string) {
    const url = urls.api.getCreatedPersonFromMastr(taskId);

    const response = await queryWithPollResponse<Person>(() => api.get(url));

    return response.data;
  }

  return useQuery({
    queryKey: ["person-from-mastr", { taskId }],
    queryFn: () => fetchPersonFromMastr(taskId),
    ...options
  });
}
