import React, { useEffect, useState } from "react";

import api from "../../../api";
import { GENERATOR_TYPES } from "../../ComponentListContainer/ComponentList/ComponentEditWizard/Data/Generator";
import {
  CustomForm,
  getFormFieldsFromResponse
} from "../../CustomForm/CustomForm";
import type { FormFieldData } from "../../DynamicForm/FormItems/FormItems";
import { openErrorAlertPopup } from "../../ErrorAlertPopup/openErrorAlertPopup";

function FinancialDataStep({
  allFormFields,
  onStepDone,
  detailUrl,
  buttonContainer
}) {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState(null);
  const [selectedFormFields, setSelectedFormFields] = useState<
    Array<FormFieldData>
  >([]);

  useEffect(() => {
    api
      .get(detailUrl)
      .then((response) => {
        let formFieldNames;
        switch (response.data.type) {
          case GENERATOR_TYPES.EEG_ANLAGE_SOLAR:
            formFieldNames = [
              "applicableValuePv",
              "marketValueEeg",
              "directMarketingFee",
              "ownConsumptionSubsidy"
            ];
            break;
          case GENERATOR_TYPES.EEG_ANLAGE_BIOMASSE:
          case GENERATOR_TYPES.EEG_ANLAGE_GSGK:
          case GENERATOR_TYPES.EEG_ANLAGE_WASSER:
          case GENERATOR_TYPES.EEG_ANLAGE_WIND:
            formFieldNames = ["feedinTariffNonPv", "ownConsumptionSubsidy"];
            break;
          case GENERATOR_TYPES.KWK_ANLAGE_VERBRENNUNG:
          case GENERATOR_TYPES.SONSTIGE:
            formFieldNames = [
              "feedinTariffNonPv",
              "ownConsumptionSubsidy",
              "kwkgDirectDeliverySubsidy"
            ];
            break;
          default:
            formFieldNames = [];
            console.log("Unexpected type error in generator data.");
        }

        const formFieldArray = getFormFieldsFromResponse(
          formFieldNames,
          allFormFields,
          response.data
        );
        setSelectedFormFields(formFieldArray);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => setLoading(false));
  }, [allFormFields, detailUrl]);

  if (error !== null) {
    openErrorAlertPopup(error);
    return null;
  } else if (loading) {
    return null;
  }

  return (
    <CustomForm
      buttonContainer={buttonContainer}
      formFields={selectedFormFields}
      patchUrl={detailUrl}
      submitButtonText="Weiter"
      onSubmit={onStepDone}
    />
  );
}

export { FinancialDataStep };
