import type { MRT_ColumnDef } from "mantine-react-table";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import React, { useMemo } from "react";
import { getDefaultMRTOptions } from "../../../../mantine/getDefaultMRTOptions";
import type { HistoryDateBoundedValue } from "../../../../utils/backend-types";
import { sortBackendDates } from "../../../../utils/dates/sortBackendDates";

const DISPLAY_NO_DATE_LIMIT = "-";
const MIN_DATE_VALUE = "01.01.0001";
const MAX_DATE_VALUE = "31.12.9999";

export function DateBoundedValuesTable({
  values
}: {
  values: Array<HistoryDateBoundedValue>;
}) {
  const columns = useMemo<Array<MRT_ColumnDef<HistoryDateBoundedValue>>>(
    () => [
      {
        accessorFn: (row) => dateColumnAccessor(row.first_date),
        id: "first_date",
        header: "Von",
        enableColumnFilter: false,
        sortingFn: (rowA, rowB) =>
          sortBackendDates(
            rowA.original.first_date ?? undefined,
            rowB.original.first_date ?? undefined
          )
      },
      {
        accessorFn: (row) => dateColumnAccessor(row.last_date),
        id: "last_date",
        header: "Bis",
        enableColumnFilter: false,
        sortingFn: (rowA, rowB) =>
          sortBackendDates(
            rowA.original.last_date ?? undefined,
            rowB.original.last_date ?? undefined
          )
      },
      {
        accessorFn: (row) => {
          if (row.value === true) {
            return "Ja";
          } else if (row.value === false) {
            return "Nein";
          } else {
            return row.value;
          }
        },
        id: "value",
        header: "",
        enableColumnFilter: false
      }
    ],
    []
  );

  const enablePagination = values.length > 5;

  const table = useMantineReactTable({
    ...getDefaultMRTOptions<HistoryDateBoundedValue>({
      emptyRowsFallbackText: "Leer"
    }),
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: enablePagination,
    enableSorting: false,
    enableTableFooter: false,
    enableTableHead: true,
    enableTopToolbar: false,
    enableBottomToolbar: enablePagination,
    initialState: { density: "xs", pagination: { pageIndex: 0, pageSize: 5 } },
    mantineTableProps: {
      withColumnBorders: true,
      withRowBorders: true
    },
    columns,
    data: values
  });

  return <MantineReactTable table={table} />;
}

function dateColumnAccessor(backendDate: string | null) {
  if (
    backendDate === null ||
    backendDate === MIN_DATE_VALUE ||
    backendDate === MAX_DATE_VALUE
  ) {
    return DISPLAY_NO_DATE_LIMIT;
  }

  return backendDate;
}
