import type { QueryClient } from "@tanstack/react-query";

import api from "../../../../../../api";
import type {
  MastrData,
  PutOptions
} from "../../../../../../utils/backend-types";

export async function loadMastrData(
  queryClient: QueryClient,
  mastrEinheitUrl?: string
) {
  if (!mastrEinheitUrl) {
    return [{}, {}];
  }

  const data = await queryClient.fetchQuery({
    queryKey: ["mastrData-" + mastrEinheitUrl],
    queryFn: () => getMastrData(mastrEinheitUrl)
  });

  return data;
}

export async function getMastrData(mastrEinheitUrl: string) {
  const mastrEinheitOptionsResponse =
    await api.options<PutOptions>(mastrEinheitUrl);
  const mastrEinheitGetResponse = await api.get<MastrData>(mastrEinheitUrl);

  return [
    mastrEinheitOptionsResponse.data.actions.pUT,
    mastrEinheitGetResponse.data
  ];
}
