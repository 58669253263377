import { useQuery } from "@tanstack/react-query";
import api from "../../api";
import type { StartPageCompany } from "../../components/StartPage/StartPage.types";
import urls from "../../urls";

function useStartPageCompanies(projectId?: string) {
  const { data, error, isLoading } = useQuery({
    queryKey: urls.startPage.companies.key(projectId),
    queryFn: () => fetchStartPageCompanies(projectId)
  });

  async function fetchStartPageCompanies(projectId?: string) {
    const response = await api.get<Array<StartPageCompany>>(
      urls.startPage.companies.url(projectId)
    );
    return response.data;
  }

  return {
    companies: data,
    isLoading,
    error
  };
}

export { useStartPageCompanies };
