import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../api";
import urls from "../urls";
import type { Site } from "../utils/backend-types";

export function useSiteMutations(siteId: number | undefined) {
  const queryClient = useQueryClient();

  const updateMutation = useMutation({
    mutationFn: (newData: Site) => updateSite(newData, siteId),
    onSettled: handleSettled
  });

  const deleteMutation = useMutation({
    mutationFn: (siteId: Site["id"]) => deleteSite(siteId),
    onSettled: handleSettled
  });

  function handleSettled() {
    return queryClient.invalidateQueries({
      queryKey: ["site", { siteId }]
    });
  }

  return { updateMutation, deleteMutation };
}

function updateSite(newSiteData: Site, siteId: number | undefined) {
  if (!siteId) {
    return Promise.reject();
  }
  return apiWithoutCamelization.put(
    urls.apiWithoutCamelization.sitesDetail(siteId),
    newSiteData
  );
}

function deleteSite(siteId: number) {
  return apiWithoutCamelization.delete(
    urls.apiWithoutCamelization.sitesDetail(siteId)
  );
}
