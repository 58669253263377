import { DateTime } from "luxon";
import type {
  ChangeableFieldData,
  ChangeableFieldWidgetOptions
} from "../ChangeableFieldWidget";

export function addNewChangeableFieldValue<T>(
  currentValues: Array<ChangeableFieldData<T | null>>,
  options: ChangeableFieldWidgetOptions
) {
  if (currentValues.length === 0) {
    return [
      {
        value: null,
        startDate: options.addNullStartDateWhenNoValues
          ? null
          : calculateNewValueStartDate(undefined),
        endDate: null
      }
    ];
  }

  const lastValueIndex = currentValues.length - 1;
  const lastValueEndDate = calculateLastValueEndDate(
    currentValues[lastValueIndex]
  );
  const newValueStartDate = calculateNewValueStartDate(lastValueEndDate);
  const updatedLastValue: ChangeableFieldData<T> = {
    ...currentValues[lastValueIndex],
    endDate: lastValueEndDate
  };
  const newValue: ChangeableFieldData<T> = {
    value: null,
    startDate: newValueStartDate,
    endDate: null
  };
  const updatedValues = [...currentValues];

  updatedValues[lastValueIndex] = updatedLastValue;
  updatedValues.push(newValue);

  return updatedValues;
}

function calculateLastValueEndDate<T>(
  lastValue: ChangeableFieldData<T | null> | undefined
) {
  if (lastValue && lastValue.endDate) {
    return lastValue.endDate;
  }

  return DateTime.now().minus({ days: 2 }).startOf("day");
}

function calculateNewValueStartDate(lastValueEndDate: DateTime | undefined) {
  if (!lastValueEndDate) {
    return DateTime.now().startOf("day");
  }

  return lastValueEndDate.set({
    day: lastValueEndDate.day + 1
  });
}
