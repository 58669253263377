import urls from "../../../../../urls";
import { queryWithPollResponse } from "../../../../../utils/api-utils";
import { uploadFileOrFiles } from "../../../../../utils/files/uploadFileOrFiles";

export async function uploadGeneratorBatchImportFile(
  file: File,
  siteId: number,
  cleanChecked: boolean,
  ignoreSameOtherSitesChecked: boolean
) {
  const url = urls.api.uploadGeneratorBatchImport(
    siteId,
    cleanChecked,
    ignoreSameOtherSitesChecked
  );

  const { taskIds } = await queryWithPollResponse(() =>
    uploadFileOrFiles<{ taskId: string }>(file, url, "data_file")
  );

  return taskIds?.[0];
}
