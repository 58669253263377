import classNames from "classnames";
import { DateTime } from "luxon";
import React from "react";
import "./MonthDayDateInput.scss";
import { MONTH_CHOICES } from "../../../../../utils/dates";
import { backendDateOrDateTimeToLuxonDateTimeOrNull } from "../../../../../utils/dates/backendDateOrDateTimeToLuxonDateTimeOrNull";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import type { ChoiceValue } from "../../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { TsDropdown } from "../../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { getDayChoicesFromDays } from "./utils/getDayChoicesFromDays";
import { getDaysOfMonth } from "./utils/getDaysOfMonth";

interface MonthDayDateInputProps {
  name?: string;
  id?: string;
  invalid?: boolean;
  value?: string | null;
  warned?: boolean;
  onChange: (date: string | null) => void;
}

function MonthDayDateInput({
  name,
  id,
  invalid,
  value,
  warned,
  onChange
}: MonthDayDateInputProps) {
  const dateFromValue = backendDateOrDateTimeToLuxonDateTimeOrNull(
    value ?? null
  );
  const dayValue = dateFromValue ? dateFromValue.day : null;
  const monthValue = dateFromValue ? dateFromValue.month : null;

  const dayChoices = getDayChoicesFromDays(getDaysOfMonth(monthValue ?? 1));
  const dayChoice = dayChoices.find(
    (choice) => choice.value === dayValue
  )?.value;
  const monthChoice = MONTH_CHOICES.find(
    (choice) => choice.value === monthValue
  )?.value;

  function handleChangeDay(value: number) {
    if (value <= 31 && value >= 1) {
      const newDate = DateTime.local(2023, monthValue ?? 1, value);
      onChange(luxonDateTimeToBackendDateOrDateTime(newDate));
    }
  }

  function handleChangeMonth(value: number) {
    if (value <= 12 && value >= 1) {
      const daysOfMonth = getDaysOfMonth(value);
      const safeDayValue = Math.min(dayValue ?? 1, daysOfMonth);
      const newDate = DateTime.local(2023, value, safeDayValue);
      onChange(luxonDateTimeToBackendDateOrDateTime(newDate));
    }
  }

  return (
    <div
      className={classNames("MonthDayDateInput", {
        "is-invalid": invalid,
        warning: !invalid && warned
      })}
      data-fieldname={name}
    >
      <div>
        <TsDropdown
          choices={dayChoices}
          id={id}
          invalid={invalid}
          name="month-day-date-day-field"
          placeholder="Tag"
          value={dayChoice}
          onChange={(_, newValue: ChoiceValue) => {
            handleChangeDay(newValue as number);
          }}
        />
      </div>
      <div>
        <TsDropdown
          choices={MONTH_CHOICES}
          invalid={invalid}
          name="month-day-date-month-field"
          placeholder="Monat"
          value={monthChoice}
          onChange={(_, newValue: ChoiceValue) => {
            handleChangeMonth(newValue as number);
          }}
        />
      </div>
    </div>
  );
}

export { MonthDayDateInput, MonthDayDateInputProps };
