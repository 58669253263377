import type { ProjectMinimal } from "../../../utils/backend-types";

export function filterAnalyzerProjects(projects: Array<ProjectMinimal>) {
  const projectsWithoutManagerVariants = projects.map((project) => {
    project.variants = project.variants.filter(
      (variant) => variant.isManagerVariant === false
    );
    return project;
  });

  return projectsWithoutManagerVariants.filter(
    (project) => project.variants.length > 0
  );
}
