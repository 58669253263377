import { useQuery } from "@tanstack/react-query";

import api from "../../../../../api";
import urls from "../../../../../urls";
import type { Generator } from "../../../../../utils/backend-types";

interface CreatedGeneratorsFromBatchImport {
  generators: Array<Generator>;
  warnings: Array<string>;
}

export function useGetCreatedGeneratorsFromBatchImport(
  batchImportTaskId: string
) {
  async function fetchCreatedGeneratorsFromBatchImport() {
    const response = await api.get<CreatedGeneratorsFromBatchImport>(
      urls.api.getCreatedGeneratorsFromBatchImport(batchImportTaskId)
    );
    return response.data;
  }

  return useQuery({
    queryKey: ["batch-generators"],
    queryFn: () => fetchCreatedGeneratorsFromBatchImport()
  });
}
