import { useQuery } from "@tanstack/react-query";
import api from "../api";
import urls from "../urls";
import { type Meter } from "../utils/backend-types";

export function useMeters(siteId: number) {
  const { data, isLoading, error } = useQuery({
    queryKey: ["meters", { siteId }],
    queryFn: () => fetchMeters(siteId)
  });

  async function fetchMeters(siteId: number) {
    const response = await api.get<Array<Meter>>(urls.api.meters(siteId));
    return response.data;
  }

  return { data, isLoading, error };
}
