import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import urls from "../../../urls";
import type { EnergyRevenuesListResponse } from "../RevenueMonitoring.types";

export function useGeneratorEnergyRevenuesData(
  generatorIds?: Array<number>,
  options?: Partial<UseQueryOptions<EnergyRevenuesListResponse>>
) {
  const sortedGeneratorIds = generatorIds ? [...generatorIds.sort()] : [];

  const {
    data: energyRevenuesGeneratorData,
    isLoading,
    isFetching,
    error
  } = useQuery({
    queryKey: ["energyRevenuesData", { generatorIds: sortedGeneratorIds }],
    queryFn: () => fetchEnergyRevenuesData(sortedGeneratorIds),
    enabled: !!generatorIds && generatorIds.length > 0,
    retry: false,
    ...options
  });

  async function fetchEnergyRevenuesData(generatorIds?: Array<number>) {
    if (!generatorIds || generatorIds.length === 0) return [];

    const revenueMonitoringPerPersonUrl =
      urls.apiWithoutCamelization.revenueMonitoringPerGenerator({
        generatorIds
      });
    const response =
      await apiWithoutCamelization.get<EnergyRevenuesListResponse>(
        revenueMonitoringPerPersonUrl
      );
    return response.data;
  }

  return {
    energyRevenuesGeneratorData,
    isLoading,
    isFetching,
    error
  };
}
