import { LAST_YEAR, THIS_YEAR } from "../../utils/dates";
import type { Acquisition } from "./EnergyDataView/EnergyDataView";

export const FIELDS = {
  TYPE: "type",
  COMPONENT: "component",
  CONNECTION: "connection",
  ORIGIN: "origin",
  METER: "meter",
  MEDIUM: "medium",
  MARKET_LOCATION: "meteringOrMarketLocation",
  FORMULA: "formulaStr",
  ESTIMATION_PROCEDURE: "estimationProcedure",
  REFERENCES: "references",
  CONSTANT_LOAD_KW: "constantLoadKw",
  CONSUMER_TYPE: "consumerType",
  NO_OF_CONSUMERS: "numberOfConsumers",
  SECURITY_BUFFER: "securityBuffer",
  RATIO_OF_ENERGY_DATA: "ratioOfEnergyData",
  ESTIMATION_ENERGY_DATA: "estimationEnergyData",
  ESTIMATION_ENERGY_DATA_EXAMPLE_MEASURING:
    "estimationEnergyDataExampleMeasuring",
  STATEMENT: "statement",
  ANNUAL_CONSUMPTION: "annualConsumption",
  CUSTOM_LABEL: "customLabel",
  FREQUENCY: "frequency",
  TAGS: "tags"
};

export const TYPES = {
  GENERATION: "generation",
  CONSUMPTION: "consumption",
  FEEDIN: "feedin",
  FEEDOUT: "feedout",
  MISC: "misc"
};

export const ORIGINS = {
  METER: "meter",
  COMPUTATION: "computation",
  ESTIMATION: "estimation",
  MARKET_LOCATION: "market_location"
};

export const ESTIMATION_PROCEDURES = {
  CONSTANT_LOAD: "constant_load",
  TYPICAL_STANDARD_VALUES: "typical_standard_values",
  TYPICAL_STANDARD_VALUES_15_MIN: "typical_standard_values_15_min",
  EXAMPLE_MEASURING: "example_measuring",
  NON_CALIBRATED_MEASURING: "non_calibrated_measuring",
  PARTIAL_RATIO: "parital_ratio",
  MISC: "misc"
};

export const TYPE_LABEL_MAP = {
  [TYPES.GENERATION]: "Erzeugung",
  [TYPES.CONSUMPTION]: "Verbrauch",
  [TYPES.FEEDIN]: "Einspeisung",
  [TYPES.FEEDOUT]: "Entnahme",
  [TYPES.MISC]: "Sonstiges"
};

export const ORIGIN_LABEL_MAP = {
  [ORIGINS.METER]: "Zähler",
  [ORIGINS.COMPUTATION]: "Berechnung",
  [ORIGINS.ESTIMATION]: "Schätzung",
  [ORIGINS.MARKET_LOCATION]: "Marktlokation"
};

export const MEDIUM_LABEL_MAP = {
  electricity: "Strom",
  gas: "Gas",
  heat: "Wärme"
};

export const EARLIEST_YEAR = 2020;
export const INITIAL_YEAR = THIS_YEAR;
export const YEAR_CHOICES_UNTIL_LAST_YEAR = Array(LAST_YEAR - EARLIEST_YEAR + 1)
  .fill("")
  .map((_, index) => ({
    value: LAST_YEAR - index,
    displayName: (LAST_YEAR - index).toString()
  }));
export const YEAR_CHOICES_UNTIL_THIS_YEAR = [
  {
    value: THIS_YEAR,
    displayName: THIS_YEAR.toString()
  },
  ...YEAR_CHOICES_UNTIL_LAST_YEAR
];

export interface FormattedEda extends Acquisition {
  medium: string;
  origin: string;
  type: string;
  references: Array<string>;
}

export function formatEda(eda: Acquisition) {
  const references =
    eda.connections.length > 0 ? eda.connections : eda.components;
  const formattedEda: FormattedEda = {
    ...eda,
    medium: MEDIUM_LABEL_MAP[eda.medium],
    origin: ORIGIN_LABEL_MAP[eda.origin],
    type: TYPE_LABEL_MAP[eda.type],
    references: references
  };

  return formattedEda;
}

export function getActiveAcquisitionTab(pathname: string) {
  return pathname.split("/").slice(-3).join("/");
}
