import React from "react";
import "./FormContainer.scss";

interface FormContainerProps {
  children?: React.ReactNode;
}

export function FormContainer({ children }: FormContainerProps) {
  return <div className="FormContainer">{children}</div>;
}
