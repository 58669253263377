import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../api";
import { openErrorAlertPopup } from "../components/ErrorAlertPopup/openErrorAlertPopup";
import urls from "../urls";
import type {
  SiteCategoriesResponse,
  SiteCategoriesUpdate
} from "../utils/backend-types";
import { showToast } from "../utils/toast";

export async function patchSiteCategories(
  projectId: string,
  siteCategories: Array<SiteCategoriesUpdate> | undefined
) {
  await apiWithoutCamelization
    .patch(
      urls.apiWithoutCamelization.siteCategories(projectId),
      siteCategories
    )
    .then(() => {
      showToast(
        "success",
        "Die Liegenschafts-Kategorien wurden erfolgreich aktualisiert."
      );
    })
    .catch(openErrorAlertPopup);
}

export function useSiteCategories(
  projectId: string | null,
  options?: Partial<UseQueryOptions<Array<SiteCategoriesResponse> | undefined>>
) {
  const {
    data: siteCategories,
    isLoading,
    error
  } = useQuery({
    queryKey: ["siteCategories", { projectId }],
    queryFn: () => fetchSiteCategories(projectId),
    ...options
  });

  async function fetchSiteCategories(projectId: string | null) {
    if (!projectId) {
      return undefined;
    }

    const siteCategoriesUrl =
      urls.apiWithoutCamelization.siteCategories(projectId);
    const response =
      await apiWithoutCamelization.get<Array<SiteCategoriesResponse>>(
        siteCategoriesUrl
      );
    return response.data;
  }

  return { siteCategories, isLoading, error };
}
