import { useQuery } from "@tanstack/react-query";
import api from "../../../api";
import urls from "../../../urls";
import type { ParkabrechnungPoolContract } from "../Parkabrechnung.types";

export function useParkabrechnungPoolContracts(variantId: number) {
  const { data, isLoading, error } = useQuery({
    queryKey: ["parkabrechnung-pool-contracts", { variantId }],
    queryFn: () => fetchParkabrechnungPoolContracts(variantId)
  });

  async function fetchParkabrechnungPoolContracts(variantId: number) {
    const response = await api.get<Array<ParkabrechnungPoolContract>>(
      urls.api.parkabrechnungPoolContracts(variantId)
    );

    return response.data;
  }

  return { contracts: data, isLoading, error };
}
