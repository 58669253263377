import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Col, Row } from "reactstrap";

import urls from "../../urls";
import { DescriptionHeader } from "../Layout/DescriptionHeader";
import MeteringConcept from "../MeteringConcept";

import "./MeteringConceptPage.scss";

class MeteringConceptPage extends PureComponent {
  render() {
    const { variantId } = this.props;

    const dataUrls = { meteringConcept: urls.api.meteringConcept(variantId) };

    return (
      <div className="MeteringConceptPage">
        <Row className="align-items-end">
          <Col className="mb-4">
            <DescriptionHeader
              description={`Über die sogenannte gewillkürte Vorrangregelung ermittelt  ${SITE_NAME} automatisch das jeweils wirtschaftlich optimale Bilanzierungskonzept. Diese Übersicht zeigt die hierbei ermittelten und angewandten Berechnungsformeln.`}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <MeteringConcept dataUrls={dataUrls} variantId={variantId} />
          </Col>
        </Row>
      </div>
    );
  }
}

MeteringConceptPage.propTypes = {
  variantId: PropTypes.number
};

export { MeteringConceptPage };
