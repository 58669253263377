import classNames from "classnames";
import saveAs from "file-saver";
import PropTypes from "prop-types";
import type { MouseEvent } from "react";
import React, { useCallback } from "react";

import api from "../../api";
import { openErrorAlertPopup } from "../ErrorAlertPopup/openErrorAlertPopup";

import "./DownloadLink.scss";

export interface DownloadLinkProps {
  url: string;
  filename: string;
  children?: React.ReactNode;
  className?: string;
}

function DownloadLink({
  url,
  filename,
  children,
  className
}: DownloadLinkProps) {
  const onDownloadClick = useCallback(
    async (e: MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();

      try {
        const response = await api.get(url, {
          responseType: "blob",
          transformResponse: []
        });

        const blob = new Blob([response.data]);

        saveAs(blob, filename);
      } catch (e) {
        openErrorAlertPopup(e);
      }
    },
    [filename, url]
  );

  return (
    <span
      className={classNames("download-link", className)}
      onClick={onDownloadClick}
    >
      {children}
    </span>
  );
}

DownloadLink.propTypes = {
  url: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string
};

export { DownloadLink };
