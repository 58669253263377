import React from "react";
import { Icon } from "../../BuildingBlocks/Icon/Icon";
import type { IconName } from "../../BuildingBlocks/Icon/types";
import { Portlet } from "../../BuildingBlocks/Layout/Portlet";
import "./MiniTile.scss";
import { LoadOrError } from "../../LoadOrError/LoadOrError";

interface MiniTileItem {
  label: string;
  value: React.ReactNode;
}

interface MiniTileProps {
  title: string | React.ReactNode;
  subTitle?: string;
  iconName: IconName;
  items: Array<MiniTileItem>;
  isLoading?: boolean;
}

function MiniTile({ title, iconName, items, isLoading }: MiniTileProps) {
  return (
    <Portlet className="MiniTile">
      <div className="mini-tile-header">
        {title}
        <Icon className="Icon" name={iconName} />
      </div>
      <LoadOrError loading={isLoading}>
        <div className="mini-tile-body">
          {items.map((item) => (
            <div className="item" key={item.label}>
              <div className="label">{item.label}</div>
              <div className="value">{item.value}</div>
            </div>
          ))}
        </div>
      </LoadOrError>
    </Portlet>
  );
}

export { MiniTile, MiniTileProps, MiniTileItem };
