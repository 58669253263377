import React from "react";
import type { DropdownMenuProps as ReactstrapDropdownMenuProps } from "reactstrap";
// eslint-disable-next-line no-restricted-imports
import { DropdownMenu as ReactstrapDropdownMenu } from "reactstrap";

type DropdownMenuProps = ReactstrapDropdownMenuProps;

function DropdownMenu(props: DropdownMenuProps) {
  return <ReactstrapDropdownMenu {...props} />;
}

export { DropdownMenu, DropdownMenuProps };
