import React, { useCallback, useEffect, useState } from "react";
import type {
  ActionMeta,
  GroupBase,
  OnChangeValue,
  Options,
  PropsValue,
  Props as ReactSelectProps
} from "react-select";
import ReactAsyncSelect from "react-select/async";
import api from "../../../../../api";

export interface AsyncSelectChoice {
  id: string | number;
  name: string;
}

interface AsyncSelectProps<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> extends ReactSelectProps<Option, IsMulti, Group> {
  dataUrl: string;
  onChangeIsValidInput: (isValid: boolean) => void;
}

function AsyncSelect<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  dataUrl,
  defaultValue,
  onChange,
  onChangeIsValidInput,
  isDisabled,
  ...otherProps
}: AsyncSelectProps<Option, IsMulti, Group>) {
  const [value, setValue] = useState<PropsValue<Option> | undefined>(null);
  const [loadingInitialValue, setLoadingInitialValue] = useState(false);

  useEffect(() => {
    if (dataUrl && defaultValue) {
      setLoadingInitialValue(true);
      api
        .get(`${dataUrl}${defaultValue}/`)
        .then((response: { data: AsyncSelectChoice }) => {
          // typescript hack to force Option type
          const newValue: Option = {
            value: response.data.id,
            label: response.data.name
          } as unknown as Option;
          setValue(newValue);
          setLoadingInitialValue(false);
        });
    }
  }, [dataUrl, defaultValue]);

  function handleInputChange(inputValue: string): void {
    onChangeIsValidInput(inputValue.length > 2);
  }

  function handleOnChange(
    selected: OnChangeValue<Option, IsMulti>,
    actionMeta: ActionMeta<Option>
  ): void {
    setValue(selected);

    if (onChange) {
      onChange(selected, actionMeta);
    }
  }

  const promiseOptions = useCallback(
    (inputValue: string) => {
      if (inputValue.length <= 2) {
        return Promise.reject();
      }

      return api
        .get<Array<AsyncSelectChoice>>(`${dataUrl}?search=${inputValue}`)
        .then((response) => {
          return Promise.resolve(
            response.data.map((responseOption) => {
              return {
                value: responseOption.id,
                label: responseOption.name
              };
            }) as unknown as Options<Option> // ts: satisfy loadOptions on AsyncSelect
          );
        });
    },
    [dataUrl]
  );

  return (
    <ReactAsyncSelect
      cacheOptions
      defaultOptions
      loadOptions={promiseOptions}
      {...otherProps}
      isDisabled={loadingInitialValue || isDisabled}
      value={value}
      onChange={handleOnChange}
      onInputChange={handleInputChange}
    />
  );
}

export { AsyncSelect, AsyncSelectProps };
