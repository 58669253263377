import { useMutation } from "@tanstack/react-query";

import type { GeneratorChangesFromMastr } from "../../../utils/backend-types";
import { updateGeneratorWithChangesFromMastr } from "../utils/updateGeneratorWithChangesFromMastr";

export function useOverwriteGeneratorMutation({
  generatorId,
  onSuccess,
  onError
}) {
  return useMutation({
    mutationFn: async (
      generatorChangesFromMastr: GeneratorChangesFromMastr[]
    ) =>
      updateGeneratorWithChangesFromMastr(
        generatorId,
        generatorChangesFromMastr
      ),
    onSuccess,
    onError
  });
}
