import type {
  CancellationState,
  components,
  Paragraph6EEGCreditResponseState
} from "../../types/api.types";

export type Paragraph6ContractResponse =
  components["schemas"]["Paragraph6EEGContract"];

export type Paragraph6ContractPayload = Paragraph6ContractResponse;

export type Paragraph6ContractFormFields = Paragraph6ContractPayload & {
  is_template: boolean;
  has_split_beteiligung?: boolean;
  has_fiktive_strommenge?: boolean;
  project_description?: string; // currently a fixed value in FE, therefore optional for API call
};

export type Paragraph6FinancialDocument = Omit<
  components["schemas"]["Paragraph6EEGCreditResponse"],
  "state"
> & {
  state: Paragraph6EEGCreditResponseState | CancellationState;
  credit_number?: string;
  type?: FinancialDocumentType; // only handled in FE, therefore optional
};

export type Paragraph6CreatableCredit =
  components["schemas"]["CreditRequest"] & {
    fiktive_strommengen_are_imprecise: boolean;
  };

export enum FinancialDocumentType {
  Credit = "credit",
  Cancellation = "cancellation",
  PaymentInformation = "payment_information"
}

export interface Paragraph6CreditError {
  billing_period: Paragraph6CreatableCredit;
  energy_data_check_failed: boolean;
  master_data_check_failed: boolean;
  exactly_one_grid_operator_check_failed: boolean;
}

export type Paragraph6CreditUpdatePayload = Partial<
  components["schemas"]["Paragraph6EEGCreditResponse"]
>;

export type Paragraph6Company = components["schemas"]["Company"];

export type Paragraph6EEGCreditNumberRangeResponse =
  components["schemas"]["Paragraph6EEGCreditNumberRangeResponse"];

export type Paragraph6EEGCreditNumberRangeCreate =
  components["schemas"]["Paragraph6EEGCreditNumberRangeCreate"];

export type Paragraph6EEGCreditNumberRangeUpdate =
  components["schemas"]["Paragraph6EEGCreditNumberRangeUpdate"];

export type paragraph6ErzeugerAnteilResponse =
  components["schemas"]["ErzeugerAnteil"];

export type paragraph6ErzeugerAnteilPayload =
  Partial<paragraph6ErzeugerAnteilResponse>;

export type Paragraph6GeneratorListElement =
  components["schemas"]["Paragraph6UnassignedGeneratorsResponse"];

export type Paragraph6GenerationFeedinShare = Pick<
  Paragraph6GeneratorListElement,
  "metering_or_market_location_id" | "weighting" | "anzulegender_wert"
>;
