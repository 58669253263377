/* eslint-disable react/jsx-sort-props */
import {
  QueryClient,
  QueryClientProvider,
  useQuery
} from "@tanstack/react-query";
import classnames from "classnames";
import _ from "lodash";
import type { LegacyRef } from "react";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import type {
  Edge,
  GraphData,
  GraphEvents,
  Network,
  Node,
  Options
} from "react-graph-vis";
import Graph from "react-graph-vis";
import { BrowserRouter as Router } from "react-router-dom";
import type { IdType } from "vis";
import api from "../../api";
import { usePrevious } from "../../hooks/usePrevious";
import CenterScreenIcon from "../../icons/center-screen.svg";
import urls from "../../urls";
import { THEME_VARS } from "../../utils/constants";
import type { ObjectName } from "../../utils/enums";
import { StructureDiagramObjectName } from "../../utils/enums";
import { openErrorAlertPopup } from "../ErrorAlertPopup/openErrorAlertPopup";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { DataWarningAlert } from "../StructureView/DataWarningAlert/DataWarningAlert";
import { UserAccessContext } from "../StructureView/UserAccessContext";
import { EdgePopup } from "./EdgePopup/EdgePopup";
import type { NodePopupData } from "./NodePopup/NodePopup";
import { NodePopup } from "./NodePopup/NodePopup";
import "./StructureViewDiagram.scss";
import { getStructureViewImageUrl } from "./utils/getStructureViewImageUrl";

// some config options for the graph
const NODE_SIZE = 30;
const EDGE_WIDTH = 2;

const DISABLED_OPACITY = 0.7;
const DISABLED_COLOUR = `RGBA(87, 89, 98, ${DISABLED_OPACITY})`;
export interface NodeData {
  color?: string;
  data: {
    active?: boolean;
    color: string;
    componentId: number;
    type: ObjectName;
    meterBillingRelevantOrSubmeter?: string;
    meterPriorityDescription?: string;
  };
  id: number;
  imageUrl: string;
  label: string;
  shape?: string;
  size?: number;
  tooltipData: TooltipData;
  x: number;
  y: number;
  yRelative?: number;
  xRelative?: number;
  parent?: string;
}

export interface GraphNodePlus extends Node {
  active: boolean;
  componentId: number;
  componentType: ObjectName;
  person: { color?: string };
  tooltipData: TooltipData;
}

export enum ConnectionPointDirection {
  Top = "top",
  Bottom = "bottom",
  Left = "left",
  Right = "right"
}
export interface EdgeData {
  from: number;
  id: number;
  to: number;
  fromConnectionPoint?: ConnectionPointDirection;
  toConnectionPoint?: ConnectionPointDirection;
  active: boolean;
}

interface NewEdge {
  from: IdType;
  id?: number;
  to: IdType;
  active: boolean;
}

interface GraphEdgePlus extends Edge {
  dbId?: number;
}

export interface TooltipData {
  name: string;
  text: string;
  type: string;
  isConsumptionShareEditable?: boolean;
  isConsumptionShareConfirmed?: boolean;
}

export interface IconData {
  imageName: string;
  imageUrl: string;
}

interface Font {
  size: number;
  background?: string;
  color?: string;
}

interface StructureViewDiagramProps extends SketchData {
  setShowNotAccessibleModal?: (show: boolean) => void;
  report: boolean;
  projectId: string;
  siteId: number;
  setCoordinates: ({ x, y }) => void;
  invalidateMeterData: () => void;
}

const MAX_ZOOM = 1.5;
const MIN_ZOOM = 0.1;

function StructureViewDiagram({
  nodes,
  edges,
  icons,
  projectId,
  siteId,
  report,
  setCoordinates,
  setShowNotAccessibleModal,
  invalidateMeterData
}: StructureViewDiagramProps) {
  const graphRef = useRef<{ Network: Network }>(null);
  const structureRef = useRef<HTMLDivElement>(null);
  const userCanCreateAndDelete = useContext(UserAccessContext);
  const [newNodeId, setNewNodeId] = useState<IdType | null | undefined>(null);
  const convertNodePropsToGraphNodes = useCallback(
    (nodes: Array<NodeData>, report: boolean) => {
      const font: Font = {
        size: 18,
        background: report ? undefined : "white",
        color: report ? undefined : THEME_VARS.nodeEnergyBlack
      };

      return nodes.map((node) => {
        return convertNodeToGraphNode(node, font);
      });
    },
    []
  );

  const convertEdgePropsToGraphEdges = useCallback(
    (edges: Array<EdgeData>, report: boolean) => {
      return edges.map((edge) => {
        return convertEdgeToGraphEdge(edge, report);
      });
    },
    []
  );

  const [graphNodes, setGraphNodes] = useState(
    convertNodePropsToGraphNodes(nodes, report)
  );
  const [graphEdges, setGraphEdges] = useState(
    convertEdgePropsToGraphEdges(edges, report)
  );
  const [popupXY, setPopupXY] = useState<[number, number] | null>(null);
  const [nodePopupId, setNodePopupId] = useState<IdType | null>(null);
  const [nodePopupData, setNodePopupData] = useState<NodePopupData | null>(
    null
  );
  const [edgePopupId, setEdgePopupId] = useState<IdType | null>(null);
  const [addEdgeFromNode, setAddEdgeFromNode] = useState<IdType | null>(null);
  const [addEdgeToNode, setAddEdgeToNode] = useState<IdType | null>(null);
  const [shiftIsPressed, setShiftIsPressed] = useState(false);
  const [clientHeight, setClientHeight] = useState<number | null>(null);
  const unConfirmedMeters = nodes.filter(
    (node) =>
      node.tooltipData.isConsumptionShareEditable &&
      !node.tooltipData.isConsumptionShareConfirmed
  );
  function resizeDiagram() {
    if (!structureRef.current) {
      return;
    }

    const observer = new MutationObserver(() => {
      if (structureRef && structureRef.current) {
        setClientHeight(structureRef.current.clientHeight);
      }
    });

    observer.observe(structureRef.current, {
      attributes: true,
      childList: true,
      subtree: true
    });

    return () => {
      observer.disconnect();
    };
  }

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (structureRef && structureRef.current) {
        setClientHeight(structureRef.current.clientHeight);
      }
    });

    if (structureRef && structureRef.current) {
      setClientHeight(structureRef.current?.clientHeight);
      resizeObserver.observe(structureRef.current);
    }

    resizeDiagram();

    return () => resizeObserver.disconnect();
  }, []);

  const removeEdge = useCallback(
    (from: IdType, to: IdType) => {
      const newEdges = graphEdges.filter(
        (edge) => edge.from !== from || edge.to !== to
      );
      setGraphEdges(newEdges);
      setAddEdgeToNode(null);
    },
    [graphEdges]
  );

  const handleKeydown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape" && addEdgeFromNode) {
        if (addEdgeFromNode && addEdgeToNode) {
          removeEdge(addEdgeFromNode, addEdgeToNode);
        }

        setAddEdgeFromNode(null);
        setAddEdgeToNode(null);
      } else if (e.key === "Shift") {
        setShiftIsPressed(true);
      }
    },
    [addEdgeFromNode, addEdgeToNode, removeEdge]
  );

  const handleKeyup = useCallback((e: KeyboardEvent) => {
    if (e.key === "Shift") {
      setShiftIsPressed(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown);
    document.addEventListener("keyup", handleKeyup);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
      document.removeEventListener("keyup", handleKeyup);
    };
  }, [handleKeydown, handleKeyup]);

  const prevNodes = usePrevious(graphNodes);

  useEffect(() => {
    if (prevNodes && prevNodes.length !== graphNodes.length) {
      const difference = graphNodes.filter((object1) => {
        return !prevNodes.some((object2) => {
          return object1.id === object2.id;
        });
      });
      if (difference.length === 1) {
        setNewNodeId(difference[0].id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphNodes]);

  useEffect(() => {
    setCoordinates(calcGraphCoordinates());
  }, [setCoordinates, graphRef]);

  useEffect(() => {
    centerGraph();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphRef.current?.Network]);

  //write a fucntion that simplifies the setting of the graphCoordinates
  function calcGraphCoordinates() {
    let graphCoords = { x: 0, y: 0 };

    if (graphRef && graphRef?.current) {
      const viewCenter = graphRef.current.Network.getViewPosition();

      graphCoords = {
        x: Math.round(viewCenter.x),
        y: Math.round(viewCenter.y)
      };
    }
    return graphCoords;
  }

  useEffect(() => {
    if (newNodeId && graphRef && graphRef?.current) {
      graphRef.current.Network.setSelection({
        nodes: [newNodeId],
        edges: []
      });
    }
  }, [newNodeId]);

  useEffect(() => {
    setGraphNodes(convertNodePropsToGraphNodes(nodes, report));
    setGraphEdges(convertEdgePropsToGraphEdges(edges, report));
  }, [
    nodes,
    edges,
    report,
    convertNodePropsToGraphNodes,
    convertEdgePropsToGraphEdges
  ]);

  function convertNodeToGraphNode(node: NodeData, font: Font) {
    const graphNode: GraphNodePlus = {
      id: node.id,
      label: node.label ? node.label : "",
      x: node.x,
      y: node.y,
      size: node.size ? node.size : NODE_SIZE,
      font: font,
      color: node.color,
      shape: node.shape,
      active: node.data.active !== false,
      componentId: node.data.componentId, // not used by visjs
      componentType: node.data.type, // not used by visjs
      person: {}, // not used by visjs
      tooltipData: node.tooltipData // not used by visjs
    };

    if (node.imageUrl) {
      graphNode.shape = "image";
      graphNode.person.color = node.data.color;
      graphNode.image = getStructureViewImageUrl(
        node.imageUrl,
        graphNode.person.color,
        node.data.active === false ? DISABLED_OPACITY : undefined
      );
    }

    return graphNode;
  }

  function convertEdgeToGraphEdge(edge: EdgeData | NewEdge, report: boolean) {
    const graphEdge: GraphEdgePlus = {
      from: edge.from,
      to: edge.to,
      dbId: edge.id,
      width: EDGE_WIDTH,
      color: {
        color: report ? "c3c3c3" : edge.active ? "575962" : DISABLED_COLOUR,
        hover: edge.active ? "575962" : DISABLED_COLOUR,
        highlight: edge.active ? "575962" : DISABLED_COLOUR
      },
      arrows: {
        to: false,
        middle: false,
        from: false
      }
    };

    return graphEdge;
  }

  function showNodePopup(node: GraphNodePlus, x: number, y: number) {
    setPopupXY([x, y]);
    setNodePopupData({
      name: node.tooltipData ? node.tooltipData.name : node.label,
      icon: node.image as string | undefined, // ts note: we never use vis internal type Image
      iconColor: node.person.color,
      active: node.active,
      componentId: node.componentId,
      componentType: node.componentType,
      detail:
        node.tooltipData &&
        Object.prototype.hasOwnProperty.call(node.tooltipData, "type") &&
        Object.prototype.hasOwnProperty.call(node.tooltipData, "text")
          ? {
              type: node.tooltipData.type,
              text: node.tooltipData.text,
              isConsumptionShareEditable:
                node.tooltipData.isConsumptionShareEditable
            }
          : null
    });
    setNodePopupId(node.id ?? null);
    setEdgePopupId(null);
  }

  function hideNodePopup() {
    setNodePopupId(null);
  }

  function showEdgePopup(id: number, x: number, y: number) {
    setPopupXY([x, y]);
    setEdgePopupId(id);
    setNodePopupId(null);
  }

  function hideEdgePopup() {
    setEdgePopupId(null);
  }

  function updateNodePosition(nodeId: number, x: number, y: number) {
    const newGraphNodes = [...graphNodes];
    const node = newGraphNodes.find((node) => node.id === nodeId);

    if (!node) {
      return;
    }

    // save the old values in case of failure
    const oldX = node.x;
    const oldY = node.y;

    // update the values
    node.x = x;
    node.y = y;

    setGraphNodes(newGraphNodes);

    const payload = {
      xPosition: Math.round(node.x),
      yPosition: Math.round(node.y)
    };

    api.patch(urls.api.sketchElementsUpdate(nodeId), payload).catch((error) => {
      // reset position on error
      node.x = oldX;
      node.y = oldY;
      setGraphNodes(newGraphNodes);
      openErrorAlertPopup(error);
    });
  }

  function handleNodeDeleted() {
    hideNodePopup();
  }

  function handleChangeIcon(imageName: string) {
    const nodeIndex = graphNodes.findIndex((node) => node.id === nodePopupId);
    const imageData = icons.find((icon) => icon.imageName === imageName);
    const imageUrl = imageData ? imageData.imageUrl : null;

    if (nodeIndex > -1 && imageUrl) {
      const newGraphNodes = [...graphNodes];
      const oldNode = graphNodes[nodeIndex];
      const newNode = { ...oldNode };
      const imageUrlWithColor = getStructureViewImageUrl(
        imageUrl,
        newNode.person.color,
        newNode.active === false ? DISABLED_OPACITY : undefined
      );

      newNode.image = imageUrlWithColor;
      newGraphNodes[nodeIndex] = newNode;

      setGraphNodes(newGraphNodes);
      hideNodePopup();

      api
        .patch(
          urls.api.sketchElementsUpdate(parseInt(newNode.id as string, 10)),
          {
            icon: imageName
          }
        )
        .catch((error) => {
          const resetNodes = [...newGraphNodes];
          resetNodes[nodeIndex] = oldNode;
          setGraphNodes(resetNodes);
          openErrorAlertPopup(error);
        });
    }
  }

  function handleEnableAddEdgeMode(id: number) {
    hideNodePopup();
    setAddEdgeFromNode(id);
  }

  function handleClickDeleteEdge(id: number) {
    deleteEdge(id);
    hideEdgePopup();
  }

  function canAddEdge(from: IdType, to: IdType) {
    if (!addEdgeFromNode || from === to) {
      return false;
    }

    const edgeExistsAlready = !!graphEdges.find((edge) =>
      isEdgeBetweenNodes(edge, from, to)
    );

    return !edgeExistsAlready;
  }

  function addEdge(from: IdType, to: IdType) {
    const newEdge: NewEdge = {
      from: from,
      to: to,
      active: true
    };
    const newGraphEdge = convertEdgeToGraphEdge(newEdge, report);

    setGraphEdges([...graphEdges, newGraphEdge]);
    setAddEdgeToNode(to);
  }

  function saveEdge(from: IdType, to: IdType) {
    api
      .post(urls.api.sketchEdges(), {
        fromNode: from,
        toNode: to
      })
      .then((response) => {
        const edgeIndex = graphEdges.findIndex((edge) =>
          isEdgeBetweenNodes(edge, from, to)
        );

        if (edgeIndex > -1) {
          const newEdges = [...graphEdges];
          newEdges[edgeIndex].dbId = response.data.id;
          setGraphEdges(newEdges);
        }
      })
      .catch((error) => {
        removeEdge(from, to);
        openErrorAlertPopup(error);
      })
      .finally(() => {
        invalidateMeterData();
      });
  }

  function deleteEdge(id: number) {
    const edge = graphEdges.find((edge) => edge.dbId === id);

    if (edge && edge.from && edge.to) {
      removeEdge(edge.from, edge.to);
      api
        .delete(urls.api.sketchEdgesDetail(id))
        .catch((error) => {
          if (edge.from && edge.to) {
            addEdge(edge.from, edge.to);
          }

          openErrorAlertPopup(error);
        })
        .finally(() => {
          invalidateMeterData();
        });
    }
  }

  function isEdgeBetweenNodes(edge: GraphEdgePlus, from: IdType, to: IdType) {
    return (
      (edge.from === from && edge.to === to) ||
      (edge.to === from && edge.from === to)
    );
  }

  // this will zoom to the level, on which graph is fully visible
  function centerGraph() {
    if (graphRef.current) {
      graphRef.current?.Network.fit();
      const currentPosition = calcGraphCoordinates();
      setCoordinates(currentPosition);
    }
  }

  const debouncedSetCoordinates = _.debounce(() => {
    setCoordinates(calcGraphCoordinates());
  }, 500);

  const graph: GraphData = {
    nodes: graphNodes,
    edges: graphEdges
  };

  let height: string;

  if (report || !clientHeight) {
    height = "400px";
  } else {
    height = `${clientHeight - 29}px`;
  }

  const options: Options = {
    interaction: {
      hover: true
    },
    physics: false, // physics makes everything jump around on load or whenever you move a node
    autoResize: true,
    height
  };

  const events: Partial<GraphEvents> = {
    zoom: () => {
      if (graphRef && graphRef.current) {
        const currentZoom = graphRef.current.Network.getScale();

        if (currentZoom >= MAX_ZOOM) {
          graphRef.current.Network.moveTo({ scale: MAX_ZOOM });
        } else if (currentZoom <= MIN_ZOOM) {
          graphRef.current.Network.moveTo({ scale: MIN_ZOOM });
        }

        debouncedSetCoordinates();
      }
    },
    select: (event) => {
      const { nodes, edges } = event;
      let clearAddEdgeFromNode = true;

      if (nodes.length > 0) {
        const node = graph.nodes.find((node) => {
          return node.id === nodes[0];
        });

        if (node) {
          if (addEdgeFromNode && addEdgeToNode === node.id) {
            saveEdge(addEdgeFromNode, node.id);
          } else if (shiftIsPressed) {
            setAddEdgeFromNode(node.id ?? null);
            clearAddEdgeFromNode = false;
          } else {
            if (newNodeId === node.id) {
              setNewNodeId(null);
            }

            showNodePopup(
              node as GraphNodePlus,
              event.pointer.DOM.x,
              event.pointer.DOM.y
            );
          }
        }
      } else if (edges.length > 0) {
        const edge = graph.edges.find(
          (edge) => edge.id === edges[0]
        ) as GraphEdgePlus;

        if (edge && edge.dbId) {
          showEdgePopup(edge.dbId, event.pointer.DOM.x, event.pointer.DOM.y);
        }
      }

      if (clearAddEdgeFromNode) {
        setAddEdgeFromNode(null);
      }

      setAddEdgeToNode(null);
    },

    click: (event) => {
      if (event.nodes.length === 0) {
        hideNodePopup();
      }

      if (event.edges.length === 0) {
        hideEdgePopup();
      }

      if (addEdgeFromNode && !shiftIsPressed) {
        setAddEdgeFromNode(null);
      }
    },

    dragStart: (event) => {
      if (event.nodes.includes(newNodeId)) {
        setNewNodeId(null);
      }
      hideNodePopup();
    },

    dragEnd: (event) => {
      setCoordinates(calcGraphCoordinates());
      event.nodes.forEach((nodeId) => {
        updateNodePosition(
          nodeId,
          event.pointer.canvas.x,
          event.pointer.canvas.y
        );
      });
    },

    hoverNode: (event) => {
      if (addEdgeFromNode && canAddEdge(addEdgeFromNode, event.node)) {
        addEdge(addEdgeFromNode, event.node);
      }
    },

    blurNode: (event) => {
      if (addEdgeFromNode && addEdgeToNode === event.node) {
        removeEdge(addEdgeFromNode, event.node);
      }
    }
  };

  const classes = classnames(
    "StructureViewDiagram",
    { "connecting-nodes": !!addEdgeFromNode && !addEdgeToNode },
    { "edge-to-save": !!addEdgeFromNode && !!addEdgeToNode }
  );

  function handleZoom(direction: "in" | "out") {
    if (graphRef?.current) {
      const currentPosition = graphRef.current?.Network.getViewPosition();
      const currentScale = +graphRef.current?.Network.getScale().toFixed(1);

      const zoomIn =
        currentScale + (currentScale > 1.3 ? MAX_ZOOM - currentScale : 0.2); // maximum 1.5
      const zoomOut =
        currentScale -
        (currentScale > MIN_ZOOM && currentScale < 0.3
          ? Math.abs(MIN_ZOOM - currentScale)
          : 0.2); // minimum 0.1

      graphRef.current?.Network.moveTo({
        position: currentPosition,
        scale: direction === "in" ? zoomIn : zoomOut,
        animation: {
          duration: 300,
          easingFunction: "easeInOutCubic"
        }
      });

      setCoordinates(calcGraphCoordinates());
    }
  }

  function getCurrentZoomPercentage() {
    // Normalize the value
    if (graphRef?.current) {
      const currentScale = graphRef.current.Network.getScale();
      const normalizedValue = currentScale / 1.0;

      // Convert to percentage
      const percentage = normalizedValue * 100;

      return percentage.toFixed(0);
    }
  }
  return (
    <>
      {unConfirmedMeters.length > 0 && (
        <DataWarningAlert
          objectNames={unConfirmedMeters.map((node) => node.tooltipData.name)}
        />
      )}
      <div
        ref={structureRef}
        className={classes}
        data-testid="structure-view-diagram"
      >
        <Graph
          events={events as GraphEvents}
          graph={graph}
          options={options}
          ref={graphRef as unknown as LegacyRef<Graph>}
        />
        {!!nodePopupId && popupXY !== null && nodePopupData && (
          <NodePopup
            className={classnames({ "allow-shrink": !!nodePopupData })}
            data={nodePopupData}
            icons={icons}
            key={nodePopupId}
            nodeId={parseInt(nodePopupId as string, 10)}
            projectId={projectId}
            setShowNotAccessibleModal={setShowNotAccessibleModal}
            siteId={siteId}
            userHasNoAccess={
              userCanCreateAndDelete &&
              !userCanCreateAndDelete[nodePopupData.componentType]
            }
            x={popupXY[0]}
            y={popupXY[1]}
            onChangeIcon={(e) => handleChangeIcon(e)}
            onClickAddEdge={(id) => handleEnableAddEdgeMode(id)}
            onNodeDeleted={handleNodeDeleted}
          />
        )}
        {!!edgePopupId && popupXY !== null && (
          <EdgePopup
            edgeId={parseInt(edgePopupId as string, 10)}
            key={edgePopupId}
            setShowNotAccessibleModal={setShowNotAccessibleModal}
            userHasNoAccess={
              userCanCreateAndDelete &&
              !userCanCreateAndDelete[StructureDiagramObjectName.Edge]
            }
            x={popupXY[0]}
            y={popupXY[1]}
            onClickDelete={(id) => handleClickDeleteEdge(id)}
          />
        )}

        <div className="StructureViewDiagramControls">
          <button
            className="StructureViewDiagramControlsBtn"
            disabled={
              !!graphRef?.current &&
              graphRef.current?.Network.getScale() >= MAX_ZOOM
            }
            onClick={() => handleZoom("in")}
          >
            &#43;
          </button>
          <span className="StructureViewDiagramControlsPercentage">
            {getCurrentZoomPercentage()}%
          </span>
          <button
            className="StructureViewDiagramControlsBtn"
            disabled={
              !!graphRef?.current && graphRef.current?.Network.getScale() <= 0.2
            }
            onClick={() => handleZoom("out")}
          >
            &#8722;
          </button>

          <button
            className="StructureViewDiagramControlsBtn"
            onClick={centerGraph}
          >
            <img
              alt="Center Graph"
              height={16}
              src={CenterScreenIcon}
              width={16}
            />
          </button>
        </div>
      </div>
    </>
  );
}

export interface SketchData {
  nodes: Array<NodeData>;
  edges: Array<EdgeData>;
  icons: Array<IconData>;
}

export interface StructureViewDiagramWrapperProps
  extends Omit<StructureViewDiagramProps, "nodes" | "edges" | "icons"> {
  enabled: boolean;
  siteId: number;
}

function StructureViewDiagramWrapper({
  enabled,
  siteId,
  ...otherProps
}: StructureViewDiagramWrapperProps) {
  const { data, isLoading, error } = useQuery({
    queryKey: ["sketch-data", { siteId }],
    queryFn: () => fetchSketchData(siteId),
    enabled: enabled
  });

  async function fetchSketchData(siteId: number) {
    const url = urls.api.sketchData(siteId);
    const response = await api.get<SketchData>(url);

    return response.data;
  }

  return (
    <LoadOrError error={error} loading={!enabled || isLoading}>
      {data && (
        <StructureViewDiagram
          edges={data.edges}
          icons={data.icons}
          nodes={data.nodes}
          siteId={siteId}
          {...otherProps}
        />
      )}
    </LoadOrError>
  );
}
interface StructureViewDiagramAnalyzerReportWrapperProps
  extends Omit<
    StructureViewDiagramWrapperProps,
    "report" | "enabled" | "setCoordinates" | "projectId"
  > {
  siteId: number;
}

function StructureViewDiagramAnalyzerReportWrapper({
  siteId,
  invalidateMeterData
}: StructureViewDiagramAnalyzerReportWrapperProps) {
  const queryClient = new QueryClient();
  function doNothing() {
    // no need to set coordinates for the report
  }

  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <StructureViewDiagramWrapper
          enabled
          invalidateMeterData={invalidateMeterData}
          projectId={""} // project id is not needed for the report
          report
          setCoordinates={doNothing}
          siteId={siteId}
        />
      </QueryClientProvider>
    </Router>
  );
}
export {
  StructureViewDiagramWrapper as StructureViewDiagram,
  StructureViewDiagramAnalyzerReportWrapper as StructureViewDiagramAnalyzerReport,
  StructureViewDiagram as StructureViewDiagramComponent,
  StructureViewDiagramProps as StructureViewDiagramComponentProps
};
