import React from "react";

import { FormField } from "../../BuildingBlocks/Forms/FormField/FormField";
import {
  Modal,
  ModalFooter,
  ModalHeader
} from "../../BuildingBlocks/Layout/Modal/Modal";
import { ModalBody } from "../../BuildingBlocks/Layout/Modal/ModalBody/ModalBody";
import { Button } from "../../Buttons/Button/Button";
import type { Choice } from "../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { AUTH_METHODS } from "./hooks/enum";

const TWO_FACTOR_AUTH_CHOICES: Array<Choice<string>> = [
  {
    value: AUTH_METHODS.EMAIL,
    displayName: "E-Mail"
  },
  {
    value: AUTH_METHODS.APP,
    displayName: "Authentifizierungs-App"
  }
];

interface AuthSelectModalProps {
  isOpen: boolean;
  onToggle: () => void;
  onDropdownChange: (value: string) => void;
  onAuthMethodSave: () => void;
}

function AuthSelectModal({
  isOpen,
  onToggle,
  onDropdownChange,
  onAuthMethodSave
}: AuthSelectModalProps) {
  return (
    <Modal fade={false} isOpen={isOpen} toggle={onToggle}>
      <ModalHeader toggle={onToggle}>
        Aktivieren Sie die Zwei-Faktor-Authentifizierung
      </ModalHeader>
      <ModalBody scrollable>
        <div className="intro">
          <p>
            Die Zwei-Faktor-Authentifizierung fügt Ihrem Konto eine zusätzliche
            Sicherheitsebene hinzu, indem sie neben einem Passwort einen
            weiteren Authentifizierungsschritt zur Anmeldung erfordert.
          </p>
          <p>
            Sie können die 2-Faktor-Authentifizierung entweder über eine
            spezielle Authentifizierungs-App oder per E-Mail einrichten.
          </p>
          <p>
            Für die Option Authentifizierungs-App benötigen Sie eine App wie
            beispielsweise Google Authenticator, Microsoft Authenticator oder
            Authy. Die App generiert generiert zeitbasierte Sicherheitscodes,
            die Sie bei jeder Anmeldung eingeben müssen.
          </p>
        </div>

        <div className="description">
          <FormField
            choices={TWO_FACTOR_AUTH_CHOICES}
            label="Methode"
            name="two-factor-auth-select"
            required
            type="dropdown"
            value={AUTH_METHODS.EMAIL}
            onChange={onDropdownChange}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onToggle}>
          Schließen
        </Button>
        <Button color="primary" onClick={onAuthMethodSave}>
          Weiter
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export { AuthSelectModal, AuthSelectModalProps };
