import { useState } from "react";
import api from "../../../api";
import urls from "../../../urls";
import { queryWithPollResponse } from "../../../utils/api-utils";
import { downloadPDF } from "../../../utils/downloadPdf";
import type { GuaranteeOfOriginPeriod } from "../GuaranteeOfOrigin.types";

function useGOGenerate() {
  const [loading, setLoading] = useState<boolean>(false);

  async function generateAndDownloadDocument(
    periods: Array<GuaranteeOfOriginPeriod>
  ) {
    setLoading(true);
    return queryWithPollResponse(
      () => api.post(urls.api.dispatchGuaranteeOfOriginGeneration(), periods),
      {
        queryAgainOnPollSuccess: true,
        queryAgainOnPollSuccessWithTaskId: true,
        queryAgainFn: (taskId: string) =>
          api.get(urls.api.fetchGuaranteeOfOriginDocument(taskId))
      }
    )
      .then((result) => {
        downloadPDF(result.response);
        return Promise.resolve();
      })
      .catch((error) => Promise.reject(error))
      .finally(() => setLoading(false));
  }

  return { generateAndDownloadDocument, loading };
}

export { useGOGenerate };
