import { useQuery } from "@tanstack/react-query";
import api from "../../api";
import type { StartPageRegulatoryTodo } from "../../components/StartPage/StartPage.types";
import urls from "../../urls";
import type { Paged } from "../../utils/backend-types";

function useStartPageRegulatoryTodos(
  page: number,
  pageSize: number,
  projectId?: string,
  companyId?: number
) {
  const { data, error, isLoading } = useQuery({
    queryKey: urls.startPage.regulatoryTodos.key(
      page,
      pageSize,
      projectId,
      companyId
    ),
    queryFn: () =>
      fetchStartPageRegulatoryTodos(page, pageSize, projectId, companyId)
  });

  async function fetchStartPageRegulatoryTodos(
    page: number,
    pageSize: number,
    projectId?: string,
    companyId?: number
  ) {
    const response = await api.get<Paged<StartPageRegulatoryTodo>>(
      urls.startPage.regulatoryTodos.url(page, pageSize, projectId, companyId)
    );
    return response.data;
  }

  return {
    regulatoryTodos: data,
    isLoading,
    error
  };
}

export { useStartPageRegulatoryTodos };
