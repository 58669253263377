import React, { useState } from "react";
import type { NodeProps } from "reactflow";
import { useStructureViewFlowDiagramMutations } from "../../../../hooks/useStructureViewFlowDiagramMutations";
import type { ObjectName } from "../../../../utils/enums";
import { showToast } from "../../../../utils/toast";
import { type StructureViewMode } from "../../../StructureView/StructureView.constants";
import { CustomInputComponent } from "./CustomInputComponent/CustomInputComponent";
import "./CustomTextElementNode.scss";

interface CustomTextElementNodeData {
  text: string;
  type: ObjectName;
  site: number;
  fontSize: number;
  fontWeight: number;
}
interface CustomTextElementNodeProps
  extends NodeProps<CustomTextElementNodeData> {
  id: string;
  mode: Exclude<StructureViewMode, StructureViewMode.Old>;
}
function CustomTextElementNode({ data, id, mode }: CustomTextElementNodeProps) {
  const [isEditMode, setIsEditMode] = useState(false);

  const { updateTextElementNodeMutation } =
    useStructureViewFlowDiagramMutations(data.site, mode);

  async function submitNewTextValue(textValue: string) {
    setIsEditMode(false);
    await updateTextElementNodeMutation.mutateAsync(
      { nodeId: id, text: textValue },
      {
        onError: (error) => {
          showToast("error", error);
        }
      }
    );
  }
  return (
    <div
      className="CustomTextElementNode"
      data-testid="custom-flow-node"
      onDoubleClick={() => setIsEditMode(true)}
    >
      {isEditMode ? (
        <CustomInputComponent
          value={data.text}
          onCancel={() => setIsEditMode(false)}
          onSubmitNewTextValue={submitNewTextValue}
        />
      ) : (
        <div
          className="custom-text-element-body"
          style={{ fontSize: data.fontSize, fontWeight: data.fontWeight }}
        >
          {data.text.split("\n").map((line, index) => (
            <p
              className="custom-text"
              key={index}
              style={{ margin: !line ? "10px" : "0px" }}
            >
              {line}
            </p>
          ))}
        </div>
      )}
    </div>
  );
}

export { CustomTextElementNode };
