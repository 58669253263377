import { useQuery } from "@tanstack/react-query";
import type { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import api from "../../../../api";
import urls from "../../../../urls";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import type { Acquisition } from "../../../EnergyData/EnergyDataView/EnergyDataView";
import type { EdaWithReferences } from "./useMbks";
import { useMbksQuery } from "./useMbks";

type EdaData = {
  isLoading: boolean;
  error: Error | null;
} & (
  | {
      data: Array<Acquisition> | undefined;
      isV2: false;
    }
  | {
      data: Array<EdaWithReferences> | undefined;
      isV2: true;
      refetchMbks: () => void;
    }
);

type EdaDataOptions =
  | {
      isV2: false;
      filterDate: DateTime;
    }
  | {
      isV2: true;
    };

function useEdaData(siteId: number, options: EdaDataOptions): EdaData {
  const {
    data: v1Data,
    isLoading: v1IsLoading,
    error: v1Error
  } = useQuery({
    queryKey: ["data-acquisitions", { siteId, options }],
    queryFn: () => fetchDataAcquisitions(siteId, options),
    enabled: !options || !options.isV2,
    refetchInterval: false,
    refetchOnWindowFocus: false
  });
  const {
    data: v2Data,
    isLoading: v2IsLoading,
    error: v2Error,
    refetch: v2Refetch
  } = useMbksQuery(siteId);
  const { t } = useTranslation();

  async function fetchDataAcquisitions(
    siteId: number,
    options: EdaDataOptions
  ) {
    if (options.isV2) {
      console.error("fetchDataAcquisitions must use v1");
      throw new Error(t("errors.UnknownError"));
    }

    const filterDateString = luxonDateTimeToBackendDateOrDateTime(
      options.filterDate
    );
    const url = urls.api.energyDataAcquisitionsBySite(siteId, filterDateString);
    const response = await api.get<Array<Acquisition>>(url);

    return response.data;
  }

  if (options.isV2) {
    return {
      data: v2Data,
      isV2: true,
      isLoading: v2IsLoading,
      error: v2Error,
      refetchMbks: v2Refetch
    };
  }

  return {
    data: v1Data,
    isV2: false,
    isLoading: v1IsLoading,
    error: v1Error
  };
}

export { useEdaData };
