import type { Edge } from "reactflow";
import type { EdgeData } from "../../StructureViewDiagram/StructureViewDiagram";

export function getFlowEdgesFromEdges(edges: Array<EdgeData>): Array<Edge> {
  return edges.map((edge) => {
    return {
      id: edge.id.toString(),
      source: edge.from.toString(),
      target: edge.to.toString(),
      targetHandle: edge.toConnectionPoint,
      sourceHandle: edge.fromConnectionPoint
    };
  });
}
