import { Title } from "@mantine/core";
import React from "react";
import type { Control, FieldErrors } from "react-hook-form";
import { FormFieldController } from "../../../../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import type { Choice } from "../../../../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import type {
  Paragraph6Company,
  Paragraph6ContractFormFields,
  Paragraph6ContractResponse
} from "../../../../../Paragraph6.types";
import { PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA } from "../Paragraph6ContractForm.constants";

interface GeneralInformationProps {
  control: Control<Paragraph6ContractFormFields>;
  errors: FieldErrors<Paragraph6ContractFormFields>;
  paragraph6Contract?: Partial<Paragraph6ContractResponse>;
  paragraph6Companies: Array<Paragraph6Company>;
}

function GeneralInformation({
  control,
  errors,
  paragraph6Companies,
  paragraph6Contract
}: GeneralInformationProps) {
  const supplierChoices: Array<Choice> =
    paragraph6Companies?.map((company) => ({
      value: company.id,
      displayName: company.name
    })) || [];
  const supplierFieldInfo = {
    ...PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA.supplier,
    choices: supplierChoices,
    disabled:
      paragraph6Contract?.erzeuger_anteile &&
      paragraph6Contract?.erzeuger_anteile.length > 0
  };

  return (
    <>
      <section>
        <FormFieldController
          control={control}
          data={PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA.name}
          error={errors.name}
        />
        <FormFieldController
          control={control}
          data={PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA.project_description}
          error={errors.project_description}
        />
        <FormFieldController
          control={control}
          data={supplierFieldInfo}
          error={errors.supplier}
        />
      </section>

      <section>
        <Title mt="xl" order={5}>
          Gemeinde/Kommune
        </Title>
        <FormFieldController
          control={control}
          data={PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA.community_name}
          error={errors.community_name}
        />
        <FormFieldController
          control={control}
          data={PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA.community_address}
          error={errors.community_address}
        />
        <FormFieldController
          control={control}
          data={PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA.community_address_extra}
          error={errors.community_address_extra}
        />
        <FormFieldController
          control={control}
          data={PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA.community_city}
          error={errors.community_city}
        />
        <FormFieldController
          control={control}
          data={PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA.community_zip}
          error={errors.community_zip}
        />
        <FormFieldController
          control={control}
          data={PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA.community_email}
          error={errors.community_email}
        />
        <FormFieldController
          control={control}
          data={PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA.community_iban}
          error={errors.community_iban}
        />
      </section>
    </>
  );
}

export { GeneralInformation };
