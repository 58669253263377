import type { FictionalEnergyValueResponse } from "../EnergyDataUploadPage/FictionalEnergyValueManager/FictionalEnergyValueManager";

/** Converts FictionalEnergyValueResponses to a format expected by `react-hook-form`. **WARNING**: No type-safety for fields in object. */
export function convertFictionalEnergyValuesToReactHookFormValues(
  fictionalEnergyValues: Array<FictionalEnergyValueResponse>
) {
  // ts: I have no idea what type react-hook-form wants here (AsyncDefaultValues is not exported)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return fictionalEnergyValues.reduce<any>((flatValues, item, index) => {
    return {
      ...flatValues,
      ...convertFictionalEnergyValueToReactHookFormValues(
        item,
        index.toString()
      )
    };
  }, {});
}

/** Converts FictionalEnergyValueResponse to a format expected by `react-hook-form` for a given row. **WARNING**: No type-safety for fields in object. */
export function convertFictionalEnergyValueToReactHookFormValues(
  fictionalEnergyValue: FictionalEnergyValueResponse,
  rowIndex: string
) {
  return {
    [`${rowIndex}.id`]: fictionalEnergyValue.id,
    [`${rowIndex}.generator`]: fictionalEnergyValue.generator,
    [`${rowIndex}.firstDate`]: fictionalEnergyValue.firstDate,
    [`${rowIndex}.lastDate`]: fictionalEnergyValue.lastDate,
    [`${rowIndex}.technischeNichtverfuegbarkeitKwh`]:
      fictionalEnergyValue.technischeNichtverfuegbarkeitKwh,
    [`${rowIndex}.redispatchAbregelungKwh`]:
      fictionalEnergyValue.redispatchAbregelungKwh,
    [`${rowIndex}.sonstigeAbschaltungOderDrosselungKwh`]:
      fictionalEnergyValue.sonstigeAbschaltungOderDrosselungKwh
  };
}
