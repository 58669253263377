import type { DropdownOptionWithGroup } from "../Dropdown.types";
import type { Choice } from "../TsDropdown";
import { getOptionFromChoice } from "./getOptionFromChoice";
import { getOptionsWithOptionalOption } from "./getOptionsWithOptionalOption";

/** An inbetween util function for grouping Dropdown Choices for later conversion to GroupBase format for `react-select`. */
export function getGroupedOptionsFromChoices(
  choices: Array<Choice>,
  includeOptional: boolean
): Array<DropdownOptionWithGroup> {
  const options = choices.map<DropdownOptionWithGroup>((choice) => {
    return {
      ...getOptionFromChoice(choice),
      group: choice.group
    };
  });

  return getOptionsWithOptionalOption(options, includeOptional);
}
