import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import { aggregateMeteringConceptComparisonData } from "../../../utils/aggregate-metering-concept-comparison-data";
import type {
  MarketLocation,
  MeteringConceptByPersons,
  PriceComponent,
  ResultSummary,
  Variant
} from "../../../utils/backend-types";
import { LOCATION_SECTIONS, THEME_VARS } from "../../../utils/constants";
import loader from "../../Loader/Loader";
import { MeteringConceptComparison } from "../../MeteringConceptComparison/MeteringConceptComparison";
import VariantComparisonHeader from "./VariantComparisonHeader";

import "./VariantComparison.scss";

type VariantComparisonData = Record<number, [MeteringConceptByPersons]>;

interface ComparisonData {
  marketLocations: Record<number, Array<MarketLocation>>;
  priceComponents: Record<number, Array<PriceComponent>>;
  summary: Record<number, ResultSummary>;
}

interface VariantMeteringConceptConfiguration {
  header: string;
  headerBackgroundColor: string;
  cellBackgroundColor: string | null;
  bundleConsumers: boolean;
  showAlert: boolean;
}

interface VariantComparisonProps {
  selectedVariantIds: Array<number>;
  columnHeaders: Record<number, string>;
  variants: Record<number, Variant>;
  data: VariantComparisonData;
  onMoveLeft: () => void;
  onMoveRight: () => void;
  formatForReport: boolean;
  isAnalyzeView: boolean;
  selectedTab: string | null;
}

function VariantComparison({
  selectedVariantIds,
  columnHeaders,
  variants,
  data,
  onMoveLeft,
  onMoveRight,
  formatForReport,
  isAnalyzeView,
  selectedTab = null
}: VariantComparisonProps) {
  const [activeTab, setActiveTab] = useState(selectedTab || getFirstPerson());

  function getFirstPerson() {
    const firstVariant = Object.keys(data)[0];
    const [meteringConcepts] = data[firstVariant];
    const meteringConceptsByPerson = meteringConcepts.persons;

    return meteringConceptsByPerson[0].name;
  }

  function sortDataForComparison(data: VariantComparisonData) {
    return Object.keys(data).reduce<Record<string, ComparisonData>>(
      (comparisonFormat, variantId) => {
        const [meteringConcepts] = data[variantId];
        const meteringConceptsByPerson = meteringConcepts.persons;

        meteringConceptsByPerson.forEach((meteringConcept) => {
          comparisonFormat[meteringConcept.name] = {
            marketLocations: {
              ...comparisonFormat[meteringConcept.name]?.marketLocations,
              [variantId]: meteringConcept.marketLocations
            },
            priceComponents: {
              ...comparisonFormat[meteringConcept.name]?.priceComponents,
              [variantId]: meteringConcept.priceComponents
            },
            summary: {
              ...comparisonFormat[meteringConcept.name]?.summary,
              [variantId]: meteringConcept.summary
            }
          };
        });

        return comparisonFormat;
      },
      {}
    );
  }

  const comparisonDataByPerson = sortDataForComparison(data);
  const meteringConceptConfiguration = selectedVariantIds.reduce<
    Record<number, VariantMeteringConceptConfiguration>
  >((obj, variantId, index) => {
    obj[variantId] = {
      header: columnHeaders[variantId],
      headerBackgroundColor:
        index % 2 === 0 ? THEME_VARS.customGrey : THEME_VARS.brandColor,
      cellBackgroundColor: index % 2 === 0 ? "light-grey" : null,
      bundleConsumers: variants[variantId].bundleConsumers,
      showAlert:
        data[variantId] && data[variantId][0]
          ? data[variantId][0].hasWarnings
          : false
    };
    return obj;
  }, {});

  return (
    <div className="VariantComparison">
      <Nav className="variant-comparison-tabs" pills>
        {Object.keys(comparisonDataByPerson).map((personName) => (
          <NavItem key={personName}>
            <NavLink
              className={classNames({
                active: activeTab === personName
              })}
              onClick={() => setActiveTab(personName)}
            >
              {personName}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {Object.keys(comparisonDataByPerson).map((personName) => (
          <TabPane key={personName} tabId={personName}>
            <MeteringConceptComparisonWithVariantHeader
              formatForReport={formatForReport}
              isAnalyzeView={isAnalyzeView}
              marketLocations={
                comparisonDataByPerson[personName].marketLocations
              }
              meteringConceptConfiguration={meteringConceptConfiguration}
              priceComponents={
                comparisonDataByPerson[personName].priceComponents
              }
              resultSummary={comparisonDataByPerson[personName].summary}
              selectedVariantIds={selectedVariantIds}
              onMoveLeft={onMoveLeft}
              onMoveRight={onMoveRight}
            />
          </TabPane>
        ))}
      </TabContent>
    </div>
  );
}

interface MeteringConceptComparisonWithVariantHeaderProps {
  selectedVariantIds: Array<number>;
  marketLocations: Record<number, Array<MarketLocation>>;
  priceComponents: Record<number, Array<PriceComponent>>;
  resultSummary: Record<number, ResultSummary>;
  meteringConceptConfiguration: Record<
    number,
    VariantMeteringConceptConfiguration
  >;
  onMoveLeft: () => void;
  onMoveRight: () => void;
  formatForReport: boolean;
  isAnalyzeView: boolean;
}

function MeteringConceptComparisonWithVariantHeader({
  selectedVariantIds,
  marketLocations,
  priceComponents,
  resultSummary,
  meteringConceptConfiguration,
  onMoveLeft,
  onMoveRight,
  formatForReport,
  isAnalyzeView
}: MeteringConceptComparisonWithVariantHeaderProps) {
  const { mergedMarketLocations, mergedPriceComponents, yearlyAggregates } =
    aggregateMeteringConceptComparisonData(
      marketLocations,
      priceComponents,
      resultSummary
    );

  const header = (
    <VariantComparisonHeader
      formatForReport={formatForReport}
      isAnalyzeView={isAnalyzeView}
      meteringConceptConfiguration={meteringConceptConfiguration}
      meteringConceptKeys={selectedVariantIds}
      yearlyAggregates={yearlyAggregates}
      onMoveLeft={onMoveLeft}
      onMoveRight={onMoveRight}
    />
  );

  return (
    <MeteringConceptComparison
      formatForReport={formatForReport}
      header={header}
      locationSections={LOCATION_SECTIONS}
      mergedMarketLocations={mergedMarketLocations}
      mergedPriceComponents={mergedPriceComponents}
      meteringConceptConfiguration={meteringConceptConfiguration}
      meteringConceptKeys={selectedVariantIds}
      title="Vergleich Marktlokationen"
      yearlyAggregates={yearlyAggregates}
      onMoveLeft={onMoveLeft}
      onMoveRight={onMoveRight}
    />
  );
}

VariantComparison.propTypes = {
  selectedVariantIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  data: PropTypes.object.isRequired,
  columnHeaders: PropTypes.object.isRequired,
  variants: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      bundleConsumers: PropTypes.bool
    })
  ).isRequired,
  onMoveLeft: PropTypes.func,
  onMoveRight: PropTypes.func,
  formatForReport: PropTypes.bool,
  isAnalyzeView: PropTypes.bool
};

export default loader(VariantComparison);
