import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import urls from "../../../urls";
import type { Paragraph6CreditUpdatePayload } from "../Paragraph6.types";

interface UpdateParagraph6CreditStatusProps {
  creditId: string;
  payload: Paragraph6CreditUpdatePayload;
}

export function useParagraph6CreditMutations(variantId: number) {
  const queryClient = useQueryClient();

  const paragraph6CreditUpdateStatusMutation = useMutation({
    mutationFn: (updateProps: UpdateParagraph6CreditStatusProps) =>
      updateParagraph6CreditStatus(updateProps),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["paragraph6Credits", { variantId }]
      });
    }
  });

  const paragraph6CreditDeleteMutation = useMutation({
    mutationFn: (creditId: string) => deleteParagraph6Credit(creditId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["paragraph6Credits", { variantId }]
      });
      queryClient.invalidateQueries({
        queryKey: ["paragraph6CreatableCredits", { variantId }]
      });
    }
  });

  return {
    paragraph6CreditUpdateStatusMutation,
    paragraph6CreditDeleteMutation
  };
}

function updateParagraph6CreditStatus({
  creditId,
  payload
}: UpdateParagraph6CreditStatusProps) {
  const paragraph6CreditUpdateUrl =
    urls.apiWithoutCamelization.paragraph6CreditUpdateState(creditId);
  return apiWithoutCamelization.patch<Paragraph6CreditUpdatePayload>(
    paragraph6CreditUpdateUrl,
    payload
  );
}

function deleteParagraph6Credit(creditId: string) {
  const paragraph6CreditsUrl =
    urls.apiWithoutCamelization.paragraph6Credit(creditId);
  return apiWithoutCamelization.delete(paragraph6CreditsUrl);
}
