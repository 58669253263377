import type { DateTime } from "luxon";
import React from "react";
import { Input, Label } from "reactstrap";

import { luxonDateTimeToBackendDateOrDateTime } from "../../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import { DatePicker } from "../../../DatePicker/DatePicker";

import "./TodoDueDateCell.scss";

type TodoDueDateCellProps = {
  dueDate?: DateTime | undefined;
  overdue: boolean;
  onChangeDueDate: (todoId: number, dueDate: string | null) => void;
  onChangeOverdue: (todoId: number, overdue: boolean) => void;
  todoId: number;
  notChangeable?: boolean;
};

function TodoDueDateCell({
  onChangeDueDate,
  onChangeOverdue,
  dueDate,
  overdue,
  todoId,
  notChangeable
}: TodoDueDateCellProps) {
  return (
    <div className="todo-due-date-cell-container">
      {notChangeable ? (
        <div>
          {overdue
            ? "Überfällig"
            : dueDate
              ? luxonDateTimeToBackendDateOrDateTime(dueDate)
              : ""}
        </div>
      ) : (
        <>
          <div>
            <DatePicker
              className="todo-due-date-cell-datepicker"
              date={dueDate}
              disabled={overdue}
              id={"todo-due-date-cell-datepicker" + todoId}
              onChange={(date) => {
                if (date) {
                  onChangeDueDate(
                    todoId,
                    luxonDateTimeToBackendDateOrDateTime(date)
                  );
                }
              }}
            />
          </div>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <Label
                  className="m-checkbox m-checkbox--brand form-check-label form-check-label m-checkbox--single mr-1"
                  style={{ height: "18px" }}
                >
                  <Input
                    checked={overdue}
                    className="form-check-input"
                    id={"overdue" + todoId}
                    type="checkbox"
                    onChange={() => onChangeOverdue(todoId, !overdue)}
                  />
                  <span />
                </Label>
                Überfällig
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export { TodoDueDateCell };
