import type { CSSProperties } from "react";
import React, { useCallback } from "react";
import { getStraightPath, useStore } from "reactflow";
import { getEdgeParams } from "../../utils/getEdgeParams";

interface FloatingEdgeProps {
  id: string;
  source: string;
  target: string;
  markerStart?: string;
  markerEnd?: string;
  style?: CSSProperties;
}
function FloatingEdge({
  id,
  source,
  target,
  markerStart,
  markerEnd,
  style
}: FloatingEdgeProps) {
  const sourceNode = useStore(
    useCallback((store) => store.nodeInternals.get(source), [source])
  );
  const targetNode = useStore(
    useCallback((store) => store.nodeInternals.get(target), [target])
  );

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty } = getEdgeParams(sourceNode, targetNode);

  const [edgePath] = getStraightPath({
    sourceX: sx,
    sourceY: sy,
    targetX: tx,
    targetY: ty
  });

  return (
    <path
      className="react-flow__edge-path"
      d={edgePath}
      id={id}
      markerEnd={markerEnd}
      markerStart={markerStart}
      style={style}
    />
  );
}

export { FloatingEdge };
