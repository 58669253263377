import { useQuery } from "@tanstack/react-query";

import api from "../api";
import urls from "../urls";
import type { NotificationSettings } from "../utils/backend-types";

interface NotificationSettingsReturnValue {
  settings: NotificationSettings | undefined;
  isLoading: boolean;
  error: unknown;
}

function useNotificationSettings(): NotificationSettingsReturnValue {
  const {
    isLoading,
    error,
    data: settings
  } = useQuery({
    queryKey: ["settings"],
    queryFn: () => fetchSettings(),
    staleTime: 10 * 60 * 1000 // 10 min
  });

  async function fetchSettings() {
    const response = await api.get<NotificationSettings>(urls.api.settings());
    return response.data;
  }

  return {
    isLoading,
    error,
    settings
  };
}

export { useNotificationSettings };
