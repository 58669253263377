import React, { useContext } from "react";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import { Button } from "../../Buttons/Button/Button";
import { ParkabrechnungContext } from "../ParkabrechnungContext";
import { PARKABRECHNUNG_TAGS } from "../ParkabrechnungOverview/ParkabrechnungData/ParkabrechnungData";
import { ActivatedDrittlieferungButton } from "./ActivatedDrittlieferungButton";
import { mapDrittlieferungPriceToExportPayload } from "./mapDrittlieferungPriceToExportPayload";
import { NetzbezugDownloadButton } from "./NetzbezugDownloadButton";
import type { ParkabrechnungDownloadButtonCommonProps } from "./ParkabrechnungDownloadButton.types";
import {
  BUTTON_COLOR_BRAND,
  DOWNLOAD_BUTTON_LABEL
} from "./ParkabrechnungDownloadButton.types";

type ParkabrechnungDownloadButtonProps = Pick<
  Partial<ParkabrechnungDownloadButtonCommonProps>,
  "parkType" | "projectId"
>;

function ParkabrechnungDownloadButton({
  parkType,
  projectId
}: ParkabrechnungDownloadButtonProps) {
  const { selectedEndDate, selectedStartDate, drittlieferungPrices } =
    useContext(ParkabrechnungContext);

  if (parkType === PARKABRECHNUNG_TAGS.vertrag || !parkType) {
    return null;
  }

  const shouldShowNetzbezugButton =
    !!selectedEndDate &&
    parkType === PARKABRECHNUNG_TAGS.netzbezug &&
    !!projectId &&
    !!selectedStartDate;

  const shouldShowActiveDrittlieferungButton =
    !!selectedStartDate &&
    !!selectedEndDate &&
    parkType === PARKABRECHNUNG_TAGS.drittlieferung &&
    !!projectId;

  return shouldShowNetzbezugButton ? (
    <NetzbezugDownloadButton
      endDate={luxonDateTimeToBackendDateOrDateTime(
        selectedEndDate,
        "ISO 8601"
      )}
      parkType={parkType}
      projectId={projectId}
      startDate={luxonDateTimeToBackendDateOrDateTime(
        selectedStartDate,
        "ISO 8601"
      )}
    />
  ) : shouldShowActiveDrittlieferungButton ? (
    <ActivatedDrittlieferungButton
      endDate={luxonDateTimeToBackendDateOrDateTime(
        selectedEndDate,
        "ISO 8601"
      )}
      parkType={parkType}
      payload={mapDrittlieferungPriceToExportPayload(
        drittlieferungPrices || []
      )}
      projectId={projectId}
      startDate={luxonDateTimeToBackendDateOrDateTime(
        selectedStartDate,
        "ISO 8601"
      )}
    />
  ) : (
    <Button color={BUTTON_COLOR_BRAND} disabled>
      {DOWNLOAD_BUTTON_LABEL}
    </Button>
  );
}

export { ParkabrechnungDownloadButton, ParkabrechnungDownloadButtonProps };
