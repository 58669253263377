import React from "react";
import { FormItem } from "../../../FormItem/FormItem";
import type { FormItemsProps } from "../../FormItems";

interface DynamicCustomChoiceFieldsProps extends FormItemsProps {
  choiceFieldKey: string;
  keysToShow: Array<string>;
  fieldsKeyArray: Array<string>;
}

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function DynamicCustomChoiceFields({
  choiceFieldKey,
  keysToShow,
  fieldsKeyArray,
  formItems,
  formValues,
  formErrors,
  formName,
  onInput,
  allowInput,
  missingFields,
  exclusiveOrFields,
  isHighlighted
}: DynamicCustomChoiceFieldsProps) {
  const choiceFieldValue = formValues[choiceFieldKey];
  const choiceFieldError = formErrors[choiceFieldKey];
  const choiceFieldItem = formItems[choiceFieldKey];

  const showFields =
    choiceFieldValue && keysToShow.includes(choiceFieldValue?.toString());

  function renderFormItems() {
    return fieldsKeyArray.map((field, index) => {
      const value = formValues[field];
      const error = formErrors[field];
      const item = formItems[field];
      return (
        <FormItem
          key={index}
          {...item}
          allowInput={allowInput}
          errors={error}
          formName={formName}
          highlight={
            isHighlighted
              ? isHighlighted(missingFields, exclusiveOrFields, item)
              : !!missingFields && missingFields.includes(item.name)
          }
          value={value}
          onInput={onInput}
        />
      );
    });
  }

  return (
    <div>
      <FormItem
        {...choiceFieldItem}
        allowInput={allowInput}
        errors={choiceFieldError}
        formName={formName}
        highlight={
          isHighlighted
            ? isHighlighted(missingFields, exclusiveOrFields, choiceFieldItem)
            : !!missingFields && missingFields.includes(choiceFieldItem.name)
        }
        value={choiceFieldValue}
        onInput={onInput}
      />
      {showFields && renderFormItems()}
    </div>
  );
}

export { DynamicCustomChoiceFields };
