import { useQuery } from "@tanstack/react-query";
import api from "../api";
import urls from "../urls";

export function useIsMieterstrom(siteId?: number) {
  return useQuery({
    queryKey: ["isMieterstrom", { siteId }],
    queryFn: () => fetchIsMieterstrom(siteId),
    staleTime: Infinity,
    enabled: siteId !== undefined
  });
}

async function fetchIsMieterstrom(siteId?: number) {
  if (siteId === undefined) {
    return false;
  }

  const res = await api.get(urls.apiWithoutCamelization.sitesDetail(siteId));
  return res.data?.isMieterstromSite ?? false;
}
