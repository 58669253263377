import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import axios from "../../../../api";
import urls from "../../../../urls";
import { downloadCsvOrExcelFileForResponseWithContentDisposition } from "../../../../utils/files/downloadCsvOrExcelFileForResponseWithContentDisposition";
import { showToast } from "../../../../utils/toast";
import { DecimalLabel } from "../../../BuildingBlocks/DecimalLabel/DecimalLabel";
import { Icon } from "../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import { DummyLink } from "../../../DummyLink/DummyLink";

import "./ListItem.scss";

function LoadProfileOptions(props) {
  const { isGeneric, isPinned, downloading } = props;
  const downloadIcon = downloading
    ? IconName.SpinnerSpinning
    : IconName.Download;
  const edit = isGeneric ? (
    <Icon
      name={IconName.Edit}
      tooltipText="Sie können dieses Lastprofil nicht bearbeiten."
    />
  ) : (
    <Link to={props.editUrl}>
      <Icon name={IconName.Edit} tooltipText="Bearbeiten" />
    </Link>
  );
  const download = (
    <DummyLink onClick={props.onDownload}>
      <Icon mame={downloadIcon} tooltipText="Herunterladen" />
    </DummyLink>
  );
  const del = isGeneric ? (
    <Icon
      name={IconName.Trash}
      tooltipText="Sie können dieses Lastprofil nicht löschen."
    />
  ) : (
    <DummyLink onClick={props.onDelete}>
      <Icon name={IconName.Trash} tooltipText="Löschen" />
    </DummyLink>
  );
  const pin = isGeneric ? (
    <Icon
      className="pinned"
      name={IconName.ThumbTack}
      tooltipText="Dieses Lastprofil ist in allen Projekten verfügbar."
    />
  ) : (
    <DummyLink onClick={props.onPin}>
      <Icon
        className={classNames(isPinned ? "pinned pinnable" : "unpinned")}
        name={IconName.ThumbTack}
        tooltipText={isPinned ? "Von Projekt entfernen" : "Projekt zuordnen"}
      />
    </DummyLink>
  );
  return (
    <div>
      {edit}&nbsp;
      {download}&nbsp;
      {del}&nbsp;
      {pin}
    </div>
  );
}

export default class ListItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      downloading: false
    };
  }

  handleDownload = async () => {
    if (this.state.downloading) {
      return;
    }
    this.setState({ downloading: true });

    try {
      const response = await axios.get(
        urls.api.loadprofilesDownload(this.props.id)
      );
      downloadCsvOrExcelFileForResponseWithContentDisposition(response);
    } catch (error) {
      showToast("error", error);
    }
    this.setState({ downloading: false });
  };

  render() {
    const {
      id,
      name,
      totalKwh,
      maxPower,
      description,
      category,
      isGeneric,
      isPinned,
      onDelete,
      onPin
    } = this.props;

    return (
      <tr className="ListItem">
        <td className="name">{<Link to={id + "/"}>{name}</Link>}</td>
        <td align="right" className="totalKwh">
          <DecimalLabel value={totalKwh} />
        </td>
        <td align="right" className="maxPower">
          <DecimalLabel value={maxPower} />
        </td>
        <td align="right" className="fullLoadHours">
          <DecimalLabel value={totalKwh / maxPower} />
        </td>
        <td className="category">{category}</td>
        <td className="description">{description}</td>
        <td className="icons">
          <LoadProfileOptions
            downloading={this.state.downloading}
            downloadUrl={urls.api.loadprofilesDownload(id)}
            editUrl={id + "/"}
            isGeneric={isGeneric}
            isPinned={isPinned}
            onDelete={() => onDelete(id)}
            onDownload={this.handleDownload}
            onPin={() => onPin(id, name, !isPinned)}
          />
        </td>
      </tr>
    );
  }
}

ListItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  totalKwh: PropTypes.number.isRequired,
  maxPower: PropTypes.number,
  category: PropTypes.string.isRequired,
  description: PropTypes.string,
  isGeneric: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onPin: PropTypes.func.isRequired
};

ListItem.defaultProps = {
  description: "",
  isGeneric: true,
  isPinned: false
};
