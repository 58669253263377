import type {
  Component,
  MeteringOrMarketLocation,
  SiteBasic
} from "../../utils/backend-types";

export interface ParkabrechnungPoolContract {
  begin: string; // Warning: ISO 8601
  end: string; // Warning: ISO 8601
  gridFeedoutContract?: GridFeedoutContract;
  gridFeedinContract?: GridFeedinContract;
  id: string;
  name: string;
  powerFeedoutContracts: Array<PowerFeedoutContract>;
  site: SiteBasic;
}

export interface GridFeedoutContract {
  billingPeriod: ParkabrechnungBillingPeriod;
  componentFeedoutShares: Array<ComponentFeedoutShare>;
  connectingParty: Component;
  id: string;
}

export enum ParkabrechnungBillingPeriod {
  Monthly = "monthly",
  Yearly = "yearly"
}

export enum ShareType {
  InstalledCapacity = "installed_capacity",
  ScadaData = "scada_data",
  FixedShareFactor = "fixed_share_factor"
}

export interface ComponentFeedoutShare {
  component: {
    id: number;
    name: string;
    person: Component;
  };
  id: number;
  meteringOrMarketLocation: Pick<MeteringOrMarketLocation, "id" | "number">;
  share: number;
}

export interface GridFeedinContract {
  billingPeriod: ParkabrechnungBillingPeriod;
  generatorFeedinShares: Array<GeneratorFeedinShare>;
  shareType: ShareType;
}

interface GeneratorFeedinShare {
  generator: { id: number };
  meteringOrMarketLocation: { id: number };
  share: number;
}

export interface PowerFeedoutContract {
  price: number;
  begin: string;
  end: string;
  site: {
    id: number;
  };
}

export interface SimpleOrExpandedCompany {
  id: string;
  name: string;
}

export type ParkabrechnungContractFormOptionsResponse = {
  sites: Array<ParkabrechnungContractFormOptionsSite>;
};

export type ParkabrechnungContractFormOptionsSite = {
  id: number;
  name: string;
  connectingParty: string;
  components: Array<ParkabrechnungContractFormOptionsComponent>;
  meteringLocations: Array<ParkabrechnungContractFormOptionsMeteringLocation>;
};

export type ParkabrechnungContractFormOptionsComponent = {
  id: number;
  name: string;
  person: string;
};

export type ParkabrechnungContractFormOptionsMeteringLocation = {
  id: number;
  name: string;
  number: string;
  default: boolean;
};
