import _ from "lodash";
import React, { useMemo } from "react";
import { useFileOrigins } from "../../../../hooks/useFileOrigins";
import { useVariantMeters } from "../../../../hooks/useVariantMeters";
import type { Meter } from "../../../../utils/backend-types";
import { EMPTY_FIELD, THEME_VARS } from "../../../../utils/constants";
import { DataImportRequestType } from "../../../../utils/enums";
import { MANY_METERS_TEXT } from "../../../../utils/meter";
import { capitalizeWord } from "../../../../utils/text-utils";
import { Icon } from "../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import { useShouldShowStaffView } from "../../../StaffViewToggle/useShouldShowStaffView";
import type { EnergyDataImportRequest } from "../../EnergyDataUploadFlow/EnergyDataUpload";
import {
  EnergyDataFileImportRequestStatus,
  EnergyDataFileImportRequestStatusToRepresentation,
  getTypeOfDataLabel
} from "../../EnergyDataUploadFlow/EnergyDataUpload";
import "./GeneralImportDetailsList.scss";

interface GeneralImportDetailsListProps {
  generalDetails: EnergyDataImportRequest;
}

const generalDetailsTitles = [
  "Hochgeladen durch",
  "Hochgeladen am",
  "Typ",
  "Status",
  "Fehlermeldung",
  "Zähler",
  "Ausgewählte Herkunft"
];

const getStatusToRepresentation = (status: string) => {
  switch (status) {
    case EnergyDataFileImportRequestStatus.Stopped:
      return EnergyDataFileImportRequestStatusToRepresentation.Stopped;
    case EnergyDataFileImportRequestStatus.Error:
      return EnergyDataFileImportRequestStatusToRepresentation.Error;
    case EnergyDataFileImportRequestStatus.Success:
      return EnergyDataFileImportRequestStatusToRepresentation.Success;
    default:
      return "Unbekannt";
  }
};

function selectedMeterToRepresentation(
  generalDetails: EnergyDataImportRequest,
  meters: Meter[]
) {
  const selectedMeter = meters.find(
    (meter) => meter.id === generalDetails?.selectedMeter
  );

  if (!generalDetails?.selectedMeter) {
    return MANY_METERS_TEXT;
  } else if (typeof generalDetails?.selectedMeter === "number") {
    return selectedMeter?.name || "";
  }

  return "";
}

function GeneralImportDetailsList({
  generalDetails
}: GeneralImportDetailsListProps) {
  const { origins } = useFileOrigins();
  const { meters } = useVariantMeters(generalDetails?.variant);
  const showStaffView = useShouldShowStaffView();

  const details = useMemo(() => {
    const selectedOrigin = origins.find(
      (origin) => origin.id === generalDetails?.selectedOrigin
    );

    const generalDetailsToRepresentation = [
      generalDetails?.user || "",
      generalDetails?.created || "",
      getTypeOfDataLabel(generalDetails.typeOfData) as string,
      capitalizeWord(getStatusToRepresentation(generalDetails?.status)),
      generalDetails?.errorMessage ||
        `${EMPTY_FIELD} kein Fehler ${EMPTY_FIELD}`,
      ...(generalDetails.typeOfData === DataImportRequestType.REDISPATCH
        ? []
        : [selectedMeterToRepresentation(generalDetails, meters)]),
      generalDetails?.newOriginName || selectedOrigin?.name || EMPTY_FIELD
    ];

    return [
      generalDetails.typeOfData === DataImportRequestType.REDISPATCH
        ? generalDetailsTitles.filter((title) => title !== "Zähler")
        : generalDetailsTitles,
      generalDetailsToRepresentation
    ];
  }, [generalDetails, meters, origins]);

  return (
    <ul className="general-import-details-list">
      {_.unzipWith(details, (title: string, value: string) => (
        <li key={title}>
          <strong>{title}</strong>: {value}
        </li>
      ))}
      <li>
        <strong>Download: </strong>
        <Icon
          name={IconName.Download}
          style={{ color: THEME_VARS.brandColor, fontSize: 13 }}
        />
        <a download href={generalDetails?.energyDataFile.dataFile}>
          {generalDetails?.energyDataFile.name}
        </a>
      </li>
      {showStaffView && generalDetails?.adminUrl && (
        <li>
          <strong>Data Import Request: </strong>
          <a href={generalDetails?.adminUrl} rel="noreferrer" target="_blank">
            Im Admin-Bereich ansehen <Icon name={IconName.ExternalLink} />
          </a>
        </li>
      )}
    </ul>
  );
}

export { GeneralImportDetailsList, GeneralImportDetailsListProps };
