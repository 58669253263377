import React, { useContext } from "react";
import { useConfiguratorComponents } from "../../../../hooks/useConfiguratorComponents";
import type { Person } from "../../../../utils/backend-types";
import { ObjectName } from "../../../../utils/enums";
import {
  CustomReactTable,
  NoDataComponent
} from "../../../CustomReactTable/CustomReactTable";
import type { Index } from "../../../DataSheet/DataSheet";
import { ParkabrechnungContext } from "../../ParkabrechnungContext";
import type { ParkabrechnungTag } from "../ParkabrechnungData/ParkabrechnungData";
import { getParkabrechnungTableColumns as getParkabrechnungTableColumns } from "../utils/getParkabrechnungTableColumns";
import { mapEdaResponseToTableData } from "../utils/mapEdaResponseToTableData";

type NetzbezugEdaSummary = {
  id: number;
  identity: {
    type: "ShareOfGridFeedoutForCompany";
    company: number;
  };
};

type DrittlieferungEdaSummary = {
  id: number;
  identity: {
    type: "ThirdPartyDeliveryFromCompanyToCompany";
    toCompany: number;
    fromCompany: number;
  };
};

export type ParkabrechnungDataResponseSerieses = {
  ids: number[];
  header: Record<string, string[]>;
  labels: Record<string, string>;
  indexHeader: Index;
  index: Index;
  values: Record<string, number[]>;
};

export type ParkabrechnungDataResponse = {
  serieses: ParkabrechnungDataResponseSerieses;
  summary: {
    energyDataAcquisition: NetzbezugEdaSummary | DrittlieferungEdaSummary;

    energy: number;
    maxPower: number;
  }[];
  acquisitionsWithLowerFrequency: never[];
  missingData: null;
};

type ParkabrechnungTableProps = {
  abrechnungType: ParkabrechnungTag;
  ctPerKwh?: number;
  energyDataDisplayDataResponse?: ParkabrechnungDataResponse;
  variantId: number;
};

export function ParkabrechnungTable({
  abrechnungType,
  energyDataDisplayDataResponse,
  ctPerKwh,
  variantId
}: ParkabrechnungTableProps) {
  const { data: companies } = useConfiguratorComponents<Person>(
    ObjectName.Person,
    variantId
  );
  const {
    selectedCompany,
    isStromlieferung,
    isStromsteuer,
    drittlieferungPrices
  } = useContext(ParkabrechnungContext);

  const tableColumns = getParkabrechnungTableColumns(
    abrechnungType,
    { isStromlieferung, isStromsteuer },
    ctPerKwh
  );

  const tableData = mapEdaResponseToTableData({
    energyDataDisplayDataResponse,
    abrechnungType,
    companies,
    selectedCompanyId: selectedCompany,
    drittlieferungPrices
  });

  return (
    <CustomReactTable
      columns={tableColumns}
      data={tableData}
      NoDataComponent={() => (
        <NoDataComponent
          hasCustomText
          text="Für diese Liegenschaft konnten bisher keine Berechnungen durchgeführt werden. Bitte prüfen Sie noch ausstehende Onboarding-Aufgaben"
        />
      )}
    />
  );
}
