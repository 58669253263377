import { useQuery } from "@tanstack/react-query";
import api from "../../api";
import type { StartPageOnboardingProgress } from "../../components/StartPage/StartPage.types";
import urls from "../../urls";
import type { Paged } from "../../utils/backend-types";

function useStartPageOnboardingProgress(
  page: number,
  pageSize: number,
  projectId?: string,
  siteId?: number
) {
  const { data, error, isLoading } = useQuery({
    queryKey: urls.startPage.onboardingProgress.key(
      page,
      pageSize,
      projectId,
      siteId
    ),
    queryFn: () =>
      fetchStartPageOnboardingProcess(page, pageSize, projectId, siteId)
  });

  async function fetchStartPageOnboardingProcess(
    page: number,
    pageSize: number,
    projectId?: string,
    siteId?: number
  ) {
    const response = await api.get<Paged<StartPageOnboardingProgress>>(
      urls.startPage.onboardingProgress.url(page, pageSize, projectId, siteId)
    );
    return response.data;
  }

  return {
    onboardingProgress: data,
    isLoading,
    error
  };
}

export { useStartPageOnboardingProgress };
