import React, { useEffect, useState } from "react";

import api from "../../../../../api";
import type { FormValues } from "../../../../CustomForm/useCustomForm";
import { LoadOrError } from "../../../../LoadOrError/LoadOrError";
import { OptionsForm } from "../../../../OptionsForm/OptionsForm";
import type { CreatableObject } from "./types";

interface EditFormProps {
  putUrl: string;
  includedFields: Array<string>;
  nonFieldData: FormValues;
  formName: string;
  onSubmit: (updatedObject: CreatableObject) => void;
  onCancel: () => void;
}

function EditForm({
  putUrl,
  includedFields,
  nonFieldData,
  formName,
  onSubmit,
  onCancel
}: EditFormProps) {
  const [initialValues, setInitialValues] = useState<FormValues | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(true);
  const [timesRendered, setTimesRendered] = useState(1);

  useEffect(() => {
    setLoading(true);

    api.get<CreatableObject>(putUrl).then((response) => {
      if (response.data) {
        setInitialValues(response.data as unknown as FormValues);
        setTimesRendered((currentTimesRendered) => currentTimesRendered + 1);
        setLoading(false);
      }
    });
  }, [putUrl]);

  return (
    <LoadOrError loading={loading}>
      {!loading && (
        <OptionsForm
          filteredFields={includedFields}
          formName={formName}
          initialValues={initialValues}
          key={timesRendered}
          nonFieldData={nonFieldData}
          optionsUrl={putUrl}
          putUrl={putUrl}
          submitButtonText="Speichern"
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      )}
    </LoadOrError>
  );
}

export { EditForm };
