import classNames from "classnames";
import React from "react";
import type { BasicConfirmationModalProps } from "../BasicConfirmationModal/BasicConfirmationModal";
import { BasicConfirmationModal } from "../BasicConfirmationModal/BasicConfirmationModal";
import "./DeleteConfirmationModal.scss";

interface DeleteConfirmationModalProps
  extends Pick<
    BasicConfirmationModalProps,
    | "className"
    | "error"
    | "isLoading"
    | "isConfirmationBtnDisabled"
    | "isModalOpen"
    | "toggleModal"
  > {
  name: string;
  headerName?: string;
  modalBodyElement?: BasicConfirmationModalProps["children"];
  onClickConfirm: BasicConfirmationModalProps["onConfirm"];
}

function DeleteConfirmationModal({
  className,
  name,
  error,
  headerName,
  modalBodyElement,
  onClickConfirm,
  ...confirmationModalProps
}: DeleteConfirmationModalProps) {
  function getHeaderText() {
    const nameInHeaderText = headerName ? headerName : name;

    if (error) {
      return `${nameInHeaderText} kann nicht gelöscht werden`;
    }

    return `${nameInHeaderText} löschen`;
  }

  return (
    <BasicConfirmationModal
      className={classNames("DeleteConfirmationModal", className)}
      confirmationButtonColor="danger"
      confirmationButtonText="Ja, löschen"
      error={error}
      headerText={getHeaderText()}
      onCancel={confirmationModalProps.toggleModal}
      onConfirm={onClickConfirm}
      {...confirmationModalProps}
    >
      {modalBodyElement ? (
        modalBodyElement
      ) : (
        <div>
          <p>
            Sind Sie sicher, dass Sie <b>{name}</b> löschen möchten?
          </p>
          <p>Dieser Schritt kann nicht rückgängig gemacht werden.</p>
        </div>
      )}
    </BasicConfirmationModal>
  );
}

export { DeleteConfirmationModal, DeleteConfirmationModalProps };
