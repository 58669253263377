import React from "react";
import type { CellInfo, Column, SortingRule } from "react-table";

import { isCallback } from "../../../utils/isCallback";
import { HeaderWithCustomSorting } from "../Headers/HeaderWithCustomSorting";
import { getCurrentSortedDesc } from "./getCurrentSortedDesc";
import { isColumnSortable } from "./isColumnSortable";

type TableCellRendererOnlyCallback = (
  cellInfo: CellInfo,
  column: unknown
) => React.ReactNode;

/** Replaces the Header in all columns and sub-columns to be wrapped with a special sorting icon, while hiding the default sorting style. */
export function replaceColumnsWithCustomHeaders<T>(
  columns: Array<Column<T>> | undefined,
  sortedColumns: Array<SortingRule> | undefined
): Array<Column<T>> | undefined {
  return columns?.map<Column<T>>((alteredColumn, index) => {
    const Header = alteredColumn.Header;

    return {
      ...alteredColumn,
      Header: isCallback<TableCellRendererOnlyCallback>(Header)
        ? (cellInfo, column) => (
            <HeaderWithCustomSorting
              className={alteredColumn.className}
              desc={getCurrentSortedDesc(sortedColumns, cellInfo)}
              key={index}
              sortable={isColumnSortable(cellInfo)}
            >
              {Header(cellInfo, column)}
            </HeaderWithCustomSorting>
          )
        : (cellInfo) => (
            <HeaderWithCustomSorting
              className={alteredColumn.className}
              desc={getCurrentSortedDesc(sortedColumns, cellInfo)}
              key={index}
              sortable={isColumnSortable(cellInfo)}
            >
              {Header}
            </HeaderWithCustomSorting>
          ),
      columns: alteredColumn.columns
        ? replaceColumnsWithCustomHeaders(alteredColumn.columns, sortedColumns)
        : undefined
    } as Column<T>;
  });
}
