import { useQuery } from "@tanstack/react-query";
import api from "../../../../../api";
import urls from "../../../../../urls";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import type { DataAvailabilityForFeedInProceeds } from "../FeedinPage.types";

export function useCanExportParkabrechnungFeedinProceeds(
  contractId: string | null,
  startDate: string | null,
  endDate: string | null
) {
  const { data: canExport } = useQuery({
    queryKey: [
      "data-availability-for-feedin-proceeds",
      { contractId, startDate, endDate }
    ],
    queryFn: () =>
      fetchDataAvailabilityForFeedInProceeds(contractId, startDate, endDate),
    enabled: !!contractId && !!startDate && !!endDate
  });

  async function fetchDataAvailabilityForFeedInProceeds(
    contractId: string | null,
    startDate: string | null,
    endDate: string | null
  ) {
    if (
      !contractId ||
      !startDate ||
      !backendDateOrDateTimeToLuxonDateTime(startDate).isValid ||
      !endDate ||
      !backendDateOrDateTimeToLuxonDateTime(endDate).isValid
    ) {
      return false;
    }

    const response = await api.get<DataAvailabilityForFeedInProceeds>(
      urls.api.dataAvailabilityForFeedInProceeds(contractId, startDate, endDate)
    );

    return response.data.sufficientData;
  }

  return canExport;
}
