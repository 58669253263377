import { isEqual } from "lodash";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import type { Identity } from "../../EdaTable/Mbk";
import type { PrefillData } from "../CreateEdaVersionModal.constants";
import { EDA_FORM_FIELDS } from "../CreateEdaVersionModal.constants";
import type { EdaVersionFormValues } from "../CreateEdaVersionModal.types";

export function computeInitialFormStateFromPrefillData(
  prefillData: PrefillData | undefined,
  referencedIdentityOptions: Record<string, Identity>
): EdaVersionFormValues {
  return {
    [EDA_FORM_FIELDS.annualConsumption]:
      prefillData?.estimation?.annualConsumption ?? undefined,
    [EDA_FORM_FIELDS.artDerEnergieMenge]: prefillData?.artDerEnergieMenge,
    [EDA_FORM_FIELDS.company]: prefillData?.originalVersion.identity.company,
    [EDA_FORM_FIELDS.connection]:
      prefillData?.originalVersion.identity.connection,
    [EDA_FORM_FIELDS.constantLoadKw]:
      prefillData?.estimation?.constantLoadKw ?? undefined,
    [EDA_FORM_FIELDS.consumer]: prefillData?.originalVersion.identity.consumer,
    [EDA_FORM_FIELDS.description]:
      prefillData?.originalVersion.identity.description,
    [EDA_FORM_FIELDS.estimationProcedure]: prefillData?.estimation?.procedure,
    [EDA_FORM_FIELDS.formula]:
      prefillData?.originalVersion.versions[0].computation?.formulaStr ??
      prefillData?.originalVersion.versions[0].estimation?.computation
        ?.formulaStr,
    [EDA_FORM_FIELDS.frequency]:
      prefillData?.originalVersion.versions[0].computation?.frequency ??
      prefillData?.originalVersion.versions[0].estimation?.frequency,
    [EDA_FORM_FIELDS.fromCompany]:
      prefillData?.originalVersion.identity.fromCompany,
    [EDA_FORM_FIELDS.generator]:
      prefillData?.originalVersion.identity.generator,
    [EDA_FORM_FIELDS.label]: prefillData?.originalVersion.versions[0].label,
    [EDA_FORM_FIELDS.medium]:
      prefillData?.originalVersion.versions[0].meter?.medium ??
      prefillData?.originalVersion.versions[0].computation?.medium ??
      prefillData?.originalVersion.versions[0].estimation?.medium,
    [EDA_FORM_FIELDS.meter]: prefillData?.originalVersion.versions[0].meter?.id,
    [EDA_FORM_FIELDS.numberOfConsumers]:
      prefillData?.estimation?.numberOfConsumers === null
        ? undefined
        : prefillData?.estimation?.numberOfConsumers,
    [EDA_FORM_FIELDS.origin]: prefillData?.origin,
    [EDA_FORM_FIELDS.ratioOfEnergyData]:
      prefillData?.estimation?.ratioOfEnergyData === null
        ? undefined
        : prefillData?.estimation?.ratioOfEnergyData,
    [EDA_FORM_FIELDS.component]:
      prefillData?.originalVersion.identity.component,
    [EDA_FORM_FIELDS.referencedEdaIdentity]: Object.entries(
      referencedIdentityOptions
    ).find((keyValueTuple) => {
      if (
        isEqual(
          keyValueTuple[1],
          prefillData?.estimation?.referencedEdaIdentity
        )
      )
        return true;
    })?.[0],
    [EDA_FORM_FIELDS.rootMeter]:
      prefillData?.originalVersion.identity.rootMeter,
    [EDA_FORM_FIELDS.securityBuffer]:
      typeof prefillData?.estimation?.securityBuffer !== "number"
        ? undefined
        : prefillData?.estimation?.securityBuffer,
    stromsteuerpflichtig: Boolean(
      prefillData?.originalVersion.versions[0].tags.length &&
        prefillData.originalVersion.versions[0].tags.length > 0
    ),
    [EDA_FORM_FIELDS.supplied]: prefillData?.originalVersion.identity.supplied,
    [EDA_FORM_FIELDS.supplier]: prefillData?.originalVersion.identity.supplier,
    [EDA_FORM_FIELDS.tags]: prefillData?.originalVersion.versions[0].tags,
    [EDA_FORM_FIELDS.toCompany]:
      prefillData?.originalVersion.identity.toCompany,
    [EDA_FORM_FIELDS.validity]: {
      endDate: prefillData?.originalVersion.versions[0].lastDate
        ? backendDateOrDateTimeToLuxonDateTime(
            prefillData?.originalVersion.versions[0].lastDate
          )
        : null,
      startDate: prefillData?.originalVersion.versions[0].firstDate
        ? backendDateOrDateTimeToLuxonDateTime(
            prefillData?.originalVersion.versions[0].firstDate
          )
        : null
    }
  };
}
