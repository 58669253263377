import classNames from "classnames";
import React from "react";
import { isTooLongText } from "../../utils/isTooLongText";
import { IconName } from "../BuildingBlocks/Icon/types";
import "./IconHelpText.scss";
import { IconHelpTextPopover } from "./IconHelpTextPopover";
import { IconHelpTextToolTip } from "./IconHelpTextTooltip";
import { type PopoverPosition } from "./Popover/Popover";

export interface IconHelpTextProps {
  className?: string;
  helpText: string;
  icon?: IconName;
  disableMarkdown?: boolean;
  popoverPosition?: PopoverPosition;
}

function IconHelpText({
  className,
  helpText,
  icon = IconName.InfoCircle,
  disableMarkdown,
  popoverPosition = "left"
}: IconHelpTextProps) {
  const combinedClassNames = classNames(
    { "help-icon": icon === IconName.InfoCircle },
    className
  );

  return (
    <>
      {isTooLongText(helpText) ? (
        <IconHelpTextPopover
          className={combinedClassNames}
          helpText={helpText}
          icon={icon}
          popoverPosition={popoverPosition}
        />
      ) : (
        <IconHelpTextToolTip
          className={combinedClassNames}
          disableMarkdown={disableMarkdown}
          helpText={helpText}
          icon={icon}
          place={popoverPosition}
        />
      )}
    </>
  );
}

export { IconHelpText };
