import type { Choice } from "../TsDropdown";

export const sortChoices = (
  a: Choice<number | string>,
  b: Choice<number | string>
) => {
  const displayNameA = a.displayName.toString().toUpperCase();
  const displayNameB = b.displayName.toString().toUpperCase();

  if (displayNameA < displayNameB) {
    return -1;
  }
  if (displayNameA > displayNameB) {
    return 1;
  }

  return 0;
};
