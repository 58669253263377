import type { DateTime } from "luxon";

/** Sorting function for Luxon DateTime objects. */
export function sortDateTime(aDateTime: DateTime, bDateTime: DateTime) {
  if (!aDateTime.isValid) {
    return 1;
  } else if (!bDateTime.isValid) {
    return -1;
  }

  if (aDateTime < bDateTime) {
    return -1;
  } else if (bDateTime < aDateTime) {
    return 1;
  }

  return 0;
}
