import { useQuery } from "@tanstack/react-query";
import api from "../api";
import urls from "../urls";

export function useAreSpotMarketPricesAvailable(siteId?: number | null) {
  const {
    data: areSpotMarketPricesAvailable,
    isLoading,
    isError
  } = useQuery({
    queryKey: ["areSpotMarketPricesAvailable", { siteId }],
    queryFn: () => fetchAreSpotMarketPricesAvailable(siteId)
  });

  async function fetchAreSpotMarketPricesAvailable(siteId?: number | null) {
    if (typeof siteId === "undefined" || siteId === null) return {};

    const spotMarketPricesAvailableUrl =
      urls.api.deliverySpotMarketPricesAvailable(siteId);
    const response = await api.get(spotMarketPricesAvailableUrl);
    return response.data;
  }

  if (siteId === null)
    return {
      areSpotMarketPricesAvailable: false,
      isLoading: false,
      isError: false
    };

  return {
    areSpotMarketPricesAvailable,
    isLoading,
    isError
  };
}
