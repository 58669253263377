import React from "react";
import { Checkbox } from "../../BuildingBlocks/Forms/Checkbox/Checkbox";

interface BooleanFilterItemProps {
  filterValue: boolean;
  label: string;
  onChange: () => void;
}

function BooleanFilterItem({
  filterValue,
  label,
  onChange
}: BooleanFilterItemProps) {
  return (
    <Checkbox checked={filterValue} onChange={onChange}>
      {label}
    </Checkbox>
  );
}

export { BooleanFilterItem, BooleanFilterItemProps };
