import classnames from "classnames";
import React, { useState } from "react";
import ReactDOM from "react-dom";

import type { Generator, Person } from "../../../utils/backend-types";
import { ObjectName } from "../../../utils/enums";
import { Button } from "../../Buttons/Button/Button";
import { LoadOrError } from "../../LoadOrError/LoadOrError";
import { useShouldShowStaffView } from "../../StaffViewToggle/useShouldShowStaffView";
import { GeneratorBatchImport } from "./GeneratorBatchImport/GeneratorBatchImport";
import "./ImportOrCreateStep.scss";
import { useImportGeneratorFromMastr } from "./hooks/useImportGeneratorFromMastr";
import { useImportPersonFromMastr } from "./hooks/useImportPersonFromMastr";
import type { MaStRImportSuccessWidgetProps } from "./MaStRImportSuccessWidget/MaStRImportSuccessWidget";
import { MaStRImportSuccessWidget } from "./MaStRImportSuccessWidget/MaStRImportSuccessWidget";
import { MaStRSubStepGenerator } from "./MaStRSubStep/MaStRSubStepGenerator";
import { MaStRSubStepPerson } from "./MaStRSubStep/MaStRSubStepPerson";

type ImportOrCreateStepProps = {
  variantId: number;
  buttonContainer?: HTMLElement;
  onChooseManualCreate: () => void;
  onImportSuccess: (taskId: string) => void;
  onCancel: () => void;
} & (
  | {
      objectName: ObjectName.Generator;
      siteId: number;
      onBatchImportSuccess: (taskId: string) => void;
    }
  | {
      objectName: ObjectName.Person;
    }
);

function ImportOrCreateStep({
  variantId,
  buttonContainer,
  onChooseManualCreate,
  onImportSuccess,
  onCancel,
  ...objectProps
}: ImportOrCreateStepProps) {
  const [showMaStRSubStep, setShowMaStRSubStep] = useState(false);
  const [showBatchImportSubStep, setShowBatchImportSubStep] = useState(false);

  function handleChooseMaStRImport() {
    setShowMaStRSubStep(true);
  }

  function handleChooseBatchImport() {
    setShowBatchImportSubStep(true);
  }

  return (
    <div
      className={classnames("ImportOrCreateStep", {
        "mastr-sub-step": showMaStRSubStep
      })}
    >
      {showMaStRSubStep && objectProps.objectName === ObjectName.Generator ? (
        <MaStRSubStepGenerator
          buttonContainer={buttonContainer}
          siteId={objectProps.siteId}
          variantId={variantId}
          onBack={() => setShowMaStRSubStep(false)}
          onSubmit={(taskId) => onImportSuccess(taskId)}
        />
      ) : showMaStRSubStep && objectProps.objectName === ObjectName.Person ? (
        <MaStRSubStepPerson
          buttonContainer={buttonContainer}
          variantId={variantId}
          onBack={() => setShowMaStRSubStep(false)}
          onSubmit={(taskId) => onImportSuccess(taskId)}
        />
      ) : showBatchImportSubStep &&
        objectProps.objectName === ObjectName.Generator ? (
        <GeneratorBatchImport
          buttonContainer={buttonContainer}
          siteId={objectProps.siteId}
          onSuccess={objectProps.onBatchImportSuccess}
        />
      ) : (
        <div>
          <CreationChoices
            objectName={objectProps.objectName}
            onChooseBatchImport={handleChooseBatchImport}
            onChooseManualCreate={onChooseManualCreate}
            onChooseMaStRImport={handleChooseMaStRImport}
          />
          {buttonContainer &&
            ReactDOM.createPortal(
              <div className="import-or-create-step-controls">
                <Button onClick={onCancel}>Abbrechen</Button>
              </div>,
              buttonContainer
            )}
        </div>
      )}
    </div>
  );
}

interface CreationChoicesProps {
  objectName: ObjectName;
  onChooseManualCreate: () => void;
  onChooseMaStRImport: () => void;
  onChooseBatchImport: () => void;
}

function CreationChoices({
  objectName,
  onChooseManualCreate,
  onChooseMaStRImport,
  onChooseBatchImport
}: CreationChoicesProps) {
  const shouldShowStaffView = useShouldShowStaffView();
  const manualLabel = `${objectName} manuell erstellen`;
  const mastrLabel = `${objectName} aus dem Marktstammdatenregister importieren`;
  const batchImportLabel = `${objectName} aus einer Datei importieren`;
  const showBatchImportButton =
    shouldShowStaffView && objectName === ObjectName.Generator;

  return (
    <div className="choices">
      <Button
        className="m-btn--custom"
        color="brand"
        onClick={onChooseManualCreate}
      >
        {manualLabel}
      </Button>
      <Button
        className="m-btn--custom"
        color="brand"
        onClick={onChooseMaStRImport}
      >
        {mastrLabel}
      </Button>
      {showBatchImportButton && (
        <Button
          className="m-btn--custom"
          color="brand"
          onClick={onChooseBatchImport}
        >
          {batchImportLabel}
        </Button>
      )}
    </div>
  );
}

interface GeneratorMaStRImportSuccessWidgetProps
  extends Omit<
    MaStRImportSuccessWidgetProps<Generator>,
    "createdObjects" | "warnings" | "objectName"
  > {
  taskId: string;
}

function GeneratorMaStRImportSuccessWidget({
  taskId,
  ...other
}: GeneratorMaStRImportSuccessWidgetProps) {
  const { data, isLoading, error } = useImportGeneratorFromMastr(taskId);
  return (
    <LoadOrError error={error} loading={isLoading}>
      {data && (
        <MaStRImportSuccessWidget
          createdObjects={data.generators}
          objectName={ObjectName.Generator}
          warnings={data.warnings}
          {...other}
        />
      )}
    </LoadOrError>
  );
}

interface PersonMaStRImportSuccessWidgetProps
  extends Omit<
    MaStRImportSuccessWidgetProps<Person>,
    "createdObjects" | "warnings" | "objectName"
  > {
  taskId: string;
}

function PersonMaStRImportSuccessWidget({
  taskId,
  ...other
}: PersonMaStRImportSuccessWidgetProps) {
  const { data, isLoading, error } = useImportPersonFromMastr(taskId);

  return (
    <LoadOrError error={error} loading={isLoading}>
      {data && (
        <MaStRImportSuccessWidget
          createdObjects={[data]}
          objectName={ObjectName.Person}
          warnings={[]}
          {...other}
        />
      )}
    </LoadOrError>
  );
}

export {
  GeneratorMaStRImportSuccessWidget,
  ImportOrCreateStep,
  PersonMaStRImportSuccessWidget
};
