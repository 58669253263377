import classnames from "classnames";
import React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { usePersons } from "../../../hooks/usePersons";
import type {
  ExtendedUser,
  Priority,
  Todo
} from "../../../utils/backend-types";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../../Buttons/Button/Button";
import { LoadOrError } from "../../LoadOrError/LoadOrError";
import { useShouldShowStaffView } from "../../StaffViewToggle/useShouldShowStaffView";
import { Mode } from "../common";
import type { TodoStatusCellProps } from "../TodoTable/TableColumns";
import { Notes } from "./Notes/Notes";
import { DescriptionTab } from "./Tabs/DescriptionTab";
import { LogsTab } from "./Tabs/LogsTab";
import { OverviewTab } from "./Tabs/OverviewTab";

import "./TodoModal.scss";

interface TodoModalProps extends Pick<TodoStatusCellProps, "onChangeStatus"> {
  isOpen: boolean;
  mode: Mode;
  todo: Todo;
  users: Array<ExtendedUser>;
  variantId: number;
  showTodoStatus?: boolean;
  onToggle: () => void;
  onOpenEditTodoModal: () => void;
  onChangeResponsible: (responsibleId: number, todoId: number) => void;
  onChangePriority: (priorityValue: Priority, todoId: number) => void;
  onChangeTodoStatus: (todoId: number) => void;
  onChangeDueDate: (todoId: number, dueDate: string | null) => void;
  onChangeOverdue: (todoId: number, overdue: boolean) => void;
  onChangePerson: (todoId: number, person: number) => void;
}

function TodoModal({
  isOpen,
  mode,
  todo: {
    id,
    label,
    description,
    regulatoryDuty,
    category,
    priority: priorityValue,
    fulfillFor,
    person: personName,
    dueDate,
    overdue,
    responsible: responsibleId,
    status,
    todoLogs: logs,
    todoFiles: documents,
    documentProvided
  },
  users,
  variantId,
  showTodoStatus,
  onToggle,
  onOpenEditTodoModal,
  onChangeStatus,
  onChangeResponsible,
  onChangePriority,
  onChangeTodoStatus,
  onChangeDueDate,
  onChangeOverdue,
  onChangePerson
}: TodoModalProps) {
  const resolved = useResolvedPath(":activeTab");
  const match = useMatch({ path: resolved.pathname, end: true });
  const activeTab = match && match.params ? match.params.activeTab : undefined;
  const shouldShowStaffView = useShouldShowStaffView();

  const {
    persons,
    isLoading: personsLoading,
    error: personsError
  } = usePersons(variantId);

  const descriptionPath = "beschreibung";
  const notesPath = "notizen";
  const logsPath = "historie";

  const universal = mode === Mode.Universal;
  const canEdit = mode === Mode.Normal && shouldShowStaffView;

  return (
    <Modal className="TodoModal" isOpen={isOpen} size="lg" toggle={onToggle}>
      <ModalHeader toggle={onToggle}>{label}</ModalHeader>
      <ModalBody scrollable>
        <Nav className="tabs" pills>
          <NavItem>
            <NavLink
              className={classnames({
                active: !activeTab
              })}
              tag={Link}
              to="."
            >
              Dokumente
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === descriptionPath
              })}
              tag={Link}
              to={descriptionPath}
            >
              Beschreibung
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === notesPath
              })}
              tag={Link}
              to={notesPath}
            >
              Notizen
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === logsPath })}
              tag={Link}
              to={logsPath}
            >
              Historie
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={undefined}>
            <OverviewTab
              documents={documents}
              mode={mode}
              regulatoryDuty={regulatoryDuty}
              showTodoStatus={showTodoStatus}
              todoCategory={category}
              todoId={id}
              todoLabel={label}
              variantId={variantId}
            />
          </TabPane>
          <TabPane tabId={descriptionPath}>
            <LoadOrError error={personsError} loading={personsLoading}>
              <DescriptionTab
                category={category}
                description={description}
                dueDate={dueDate}
                fulfillFor={fulfillFor}
                label={label}
                overdue={overdue}
                personName={personName}
                persons={persons}
                priorityValue={priorityValue}
                regulatoryDuty={regulatoryDuty}
                responsibleId={responsibleId}
                status={status}
                todoId={id}
                todoStatus={documentProvided}
                universal={universal}
                users={users}
                onChangeDueDate={onChangeDueDate}
                onChangeOverdue={onChangeOverdue}
                onChangePerson={onChangePerson}
                onChangePriority={onChangePriority}
                onChangeResponsible={onChangeResponsible}
                onChangeStatus={onChangeStatus}
                onChangeTodoStatus={onChangeTodoStatus}
              />
            </LoadOrError>
          </TabPane>
          <TabPane tabId={notesPath}>
            <Notes todoId={id} />
          </TabPane>
          <TabPane tabId={logsPath}>
            <LogsTab logs={logs} />
          </TabPane>
        </TabContent>
      </ModalBody>
      <ModalFooter>
        {canEdit && (
          <Button color="brand" onClick={onOpenEditTodoModal}>
            Bearbeiten
          </Button>
        )}
        <Button color="brand" onClick={onToggle}>
          Schließen
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export { TodoModal };
