import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import urls from "../../../urls";
import { queryWithPollResponse } from "../../../utils/api-utils";
import { openErrorAlertPopup } from "../../ErrorAlertPopup/openErrorAlertPopup";
import type { Paragraph6FinancialDocument } from "../Paragraph6.types";

interface CancellationUpdateProps {
  cancellation: Paragraph6FinancialDocument;
  payload: Partial<Paragraph6FinancialDocument>;
}

export function useParagraph6CancellationMutations(variantId: number) {
  const queryClient = useQueryClient();

  function onError(error) {
    openErrorAlertPopup(error);
  }

  function onSuccess() {
    return queryClient.invalidateQueries({
      queryKey: ["paragraph6Credits", { variantId }, "paragraph6Cancellations"]
    });
  }

  const paragraph6CancellationCreateMutation = useMutation({
    mutationFn: (creditId: string) =>
      queryWithPollResponse(() =>
        apiWithoutCamelization.post(
          urls.apiWithoutCamelization.paragraph6CreateCancellation(),
          [{ credit: creditId }]
        )
      ),
    onError,
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: ["paragraph6CreatableCredits", { variantId }]
      });
    }
  });

  const paragraph6CancellationUpdateMutation = useMutation({
    mutationFn: (o: CancellationUpdateProps) => {
      return apiWithoutCamelization.patch(
        urls.apiWithoutCamelization.paragraph6Cancellation(o.cancellation.id),
        o.payload
      );
    },
    onError,
    onSuccess
  });

  return {
    paragraph6CancellationCreateMutation,
    paragraph6CancellationUpdateMutation
  };
}
