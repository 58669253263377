import React from "react";
import type { InputProps } from "../../DynamicForm/FormItems/FormField/Input/Input";
import { Input } from "../../DynamicForm/FormItems/FormField/Input/Input";

interface NumberFilterItemProps
  extends Pick<InputProps, "inputGroupText" | "min" | "max"> {
  filterValue: number | undefined;
  label: string;
  onChange: (value?: number) => void;
}

function NumberFilterItem({
  filterValue,
  label,
  inputGroupText,
  max,
  min,
  onChange
}: NumberFilterItemProps) {
  return (
    <div>
      {label}:{" "}
      <Input
        inputGroupText={inputGroupText}
        max={max}
        min={min}
        type="number"
        value={filterValue ?? null}
        onChange={onChange}
      />
    </div>
  );
}

export { NumberFilterItem, NumberFilterItemProps };
