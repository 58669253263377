import React from "react";
import type { Column } from "react-table";
import { Paragraph6EEGCreditResponseState } from "../../../../../types/api.types";
import { IconName } from "../../../../BuildingBlocks/Icon/types";
import { buttonColors } from "../../../../Buttons/Button/Button";
import { IconButton } from "../../../../Buttons/IconButton/IconButton";
import type { TypedCellInfo } from "../../../../CustomReactTable/CustomReactTable.types";
import type { Paragraph6FinancialDocument } from "../../../Paragraph6.types";
import { FinancialDocumentType } from "../../../Paragraph6.types";

function getDeleteColumn(
  onClickDeleteCredit: (row: Paragraph6FinancialDocument) => void
) {
  return {
    Cell: (cellInfo: TypedCellInfo<never, Paragraph6FinancialDocument>) => {
      if (cellInfo.original.type == FinancialDocumentType.Cancellation)
        return null;

      const disabled =
        cellInfo.original.state == Paragraph6EEGCreditResponseState.cancelled;
      const title =
        cellInfo.original.state == Paragraph6EEGCreditResponseState.cancelled
          ? "Stornierte Gutschriften können nicht gelöscht werden"
          : "";

      return (
        <IconButton
          className="credit-delete-button"
          color={buttonColors.link}
          disabled={disabled}
          iconColor={buttonColors.danger}
          iconName={IconName.Trash}
          noBackground
          title={title}
          type="button"
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            onClickDeleteCredit(cellInfo.original);
          }}
        >
          Löschen
        </IconButton>
      );
    },
    width: 90,
    sortable: false,
    resizable: false
  } satisfies Column<Paragraph6FinancialDocument>;
}

export { getDeleteColumn };
