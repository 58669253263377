import React from "react";
import "./PageHeader.scss";

interface PageHeaderProps {
  title: string;
}

function PageHeader({ title }: PageHeaderProps) {
  return (
    <div className="PageHeader m-subheader" style={{ padding: 0 }}>
      <h3 className="m-subheader__title ">{title}</h3>
    </div>
  );
}

export { PageHeader, PageHeaderProps };
