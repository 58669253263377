import {
  MRT_ShowHideColumnsButton,
  type MRT_Icons,
  type MRT_RowData,
  type MRT_TableOptions
} from "mantine-react-table";
import { MRT_Localization_DE } from "mantine-react-table/locales/de";
import React from "react";
import { Icon } from "../components/BuildingBlocks/Icon/Icon";
import { IconName } from "../components/BuildingBlocks/Icon/types";

const TABLE_ICONS: Partial<MRT_Icons> = {
  IconCircleX: () => <Icon name={IconName.Close} />,
  IconDeviceFloppy: () => <Icon name={IconName.Save} />,
  IconDotsVertical: () => (
    <Icon className="icon-dots-vertical" name={IconName.More} />
  ),
  IconSearch: () => <Icon name={IconName.Search} />
};

interface DefaultMRTOptionsOptions {
  emptyRowsFallbackText?: string;
  searchTextInput?: {
    placeholder?: string;
  };
  hideInternalToolbar?: boolean;
}

/** Define re-useable default Mantine-React-Table options */
export function getDefaultMRTOptions<TData extends MRT_RowData>(
  options: DefaultMRTOptionsOptions = {}
): Partial<MRT_TableOptions<TData>> {
  return {
    enableColumnActions: false,
    icons: TABLE_ICONS,
    localization: MRT_Localization_DE,
    mantineSearchTextInputProps: {
      placeholder: options.searchTextInput?.placeholder ?? "Suche...",
      variant: "default"
    },
    mantineTopToolbarProps: {
      className: "mrt-top-toolbar"
    },
    paginationDisplayMode: "pages",
    getRowId: (row) => row.id,
    renderEmptyRowsFallback: () =>
      options.emptyRowsFallbackText ? (
        <NoDataComponent text={options.emptyRowsFallbackText} />
      ) : undefined,
    renderToolbarInternalActions: ({ table }) =>
      !options.hideInternalToolbar ? (
        <MRT_ShowHideColumnsButton table={table} />
      ) : (
        <></>
      )
  };
}

function NoDataComponent({ text }: { text: string }) {
  return (
    <div className="mrt-no-data-component">
      <p>{text}</p>
    </div>
  );
}
