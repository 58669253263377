import React from "react";

type DummyLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;

function DummyLink({ children, onClick, ...otherProps }: DummyLinkProps) {
  function handleClick(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();

    if (onClick) {
      onClick(e);
    }
  }

  return (
    <a href="#" onClick={handleClick} {...otherProps}>
      {children}
    </a>
  );
}

export { DummyLink, DummyLinkProps };
