import { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

/** Hack version of navigate, to prevent unnecessary re-renders
 * See https://github.com/remix-run/react-router/issues/7634
 */
function useNav() {
  const navigate = useNavigate();
  const navigateRef = useRef({ navigate });

  useEffect(() => {
    navigateRef.current.navigate = navigate;
  }, [navigate]);

  return useCallback((location: string) => {
    navigateRef.current.navigate(location);
  }, []);
}

export { useNav };
