import React from "react";
import "./TableLight.scss";
import { TableLightPagination } from "./TableLightPagination";

type DataType = string | number;

interface TableLightColumn<TData> {
  accessor: string;
  hidden?: boolean;
  render?: (value: DataType, dataEntry: TData) => React.ReactNode;
}

interface TableLightProps<TData> {
  columns: Array<TableLightColumn<TData>>;
  count: number;
  data: Array<TData>;
  currentPage: number;
  onCurrentPageChange: (value: number) => void;
  pageSize: number;
  onPageSizeChange: (value: number) => void;
  NoDataComponent?: () => React.ReactNode;
}

function TableLight<TData>({
  columns,
  count,
  data,
  currentPage,
  onCurrentPageChange,
  pageSize,
  onPageSizeChange,
  NoDataComponent
}: TableLightProps<TData>) {
  const pageCount = Math.ceil(count / pageSize);

  return (
    <div className="TableLight">
      <table>
        <tbody>
          {data.length === 0 && NoDataComponent && <NoDataComponent />}
          {data.map((dataEntry, dataIndex) => (
            <tr key={dataIndex}>
              {columns
                .filter((column) => !column.hidden)
                .map((column) => (
                  <td
                    className={`cell-${column.accessor}`}
                    key={dataIndex + "-" + column.accessor}
                  >
                    {column.render
                      ? column.render(dataEntry[column.accessor], dataEntry)
                      : dataEntry[column.accessor]}
                  </td>
                ))}
            </tr>
          ))}
        </tbody>
      </table>
      {data.length > 0 && (
        <TableLightPagination
          count={count}
          currentPage={currentPage}
          pageCount={pageCount}
          pageSize={pageSize}
          onCurrentPageChange={onCurrentPageChange}
          onPageSizeChange={onPageSizeChange}
        />
      )}
    </div>
  );
}

export { TableLight, TableLightProps, TableLightColumn };
