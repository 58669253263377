import React, { useState } from "react";
import { FormItemLabel } from "../FormItem/FormItem";
import { ErrorText } from "./FormField/utils/ErrorText";
import type { FormItemsProps } from "./FormItems";

import "./UploadableImageField.scss";
import { UploadWidget } from "./UploadWidget";

interface ExtraProps {
  resourceId: number;
  resourceImageFieldName?: string;
  highlighted?: boolean;
  uploadUrl: (resourceId: number, fieldName?: string) => string;
  deleteUrl: (resourceId: number, fieldName?: string) => string;
  imageType: string;
}

interface UploadableImageFieldProps extends FormItemsProps, ExtraProps {
  fieldNames: Array<string>;
  onInput: (name: string, value: string | null) => void;
}

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function UploadableImageField({
  fieldNames,
  formItems,
  formValues,
  formName,
  resourceId,
  resourceImageFieldName,
  onInput,
  highlighted = false,
  uploadUrl,
  deleteUrl,
  imageType
}: UploadableImageFieldProps) {
  const [errorTexts, setErrorTexts] = useState<Array<string>>([]);

  const FIELD_NAME = fieldNames[0];
  const formItem = formItems[FIELD_NAME];
  const formValue = formValues[FIELD_NAME];
  const formItemId = `id_${
    formName ? formName : "form"
  }_${formItem.name.replace(/\./g, "__")}`;

  function handleUploaded(url: string) {
    onInput(FIELD_NAME, url);
  }

  function handleDeleted() {
    onInput(FIELD_NAME, null);
  }

  return (
    <div className="UploadableImageField FormField">
      <div className="uploadable-image-label">
        <FormItemLabel
          helpText={formItem.helpText}
          highlight={highlighted}
          id={formItemId}
          instance={formItem.instance}
          label={formItem.label}
          required={formItem.required}
        />
        <div className="upload-with-button">
          {formValue && (
            <img className="uploaded-image" src={formValue as string} />
          )}
          <UploadWidget
            deleteUrl={deleteUrl}
            hasImage={formValue !== null}
            imageType={imageType}
            resourceId={resourceId}
            resourceImageFieldName={resourceImageFieldName}
            setErrorTexts={setErrorTexts}
            uploadUrl={uploadUrl}
            onDeleted={handleDeleted}
            onUploaded={handleUploaded}
          />
        </div>
      </div>
      {errorTexts.map(ErrorText)}
    </div>
  );
}

export { UploadableImageField };
