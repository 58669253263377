import { IconName } from "../types";

const ICONS_MAP = {
  [IconName.Admin]: "flaticon-admin",
  [IconName.Analytics]: "flaticon-analytics",
  [IconName.ArrowUp]: "flaticon-up-arrow",
  [IconName.Billing]: "flaticon-billing",
  [IconName.Cogwheel]: "flaticon-cogwheel",
  [IconName.Cogwheel1]: "flaticon-cogwheel-1",
  [IconName.Cogwheel2]: "flaticon-cogwheel-2",
  [IconName.Download2]: "flaticon-download",
  [IconName.Graph]: "flaticon-graph",
  [IconName.List]: "flaticon-list",
  [IconName.Map]: "flaticon-map",
  [IconName.More]: "flaticon-more",
  [IconName.PieChart]: "flaticon-pie-chart",
  [IconName.Pins]: "flaticon-pins",
  [IconName.Projects]: "flaticon-projects",
  [IconName.QuestionCircle2]: "flaticon-questions-circular-button",
  [IconName.Refresh2]: "flaticon-refresh",
  [IconName.Report]: "flaticon-report",
  [IconName.Rocket]: "flaticon-rocket",
  [IconName.Todos]: "flaticon-todos"
} as const;

const FLAT_ICON_ICONS = Object.keys(ICONS_MAP) as Array<IconName>;

function getFlatIconClassNameByIconName(iconName: IconName) {
  return ICONS_MAP[iconName] || "";
}

export { FLAT_ICON_ICONS, getFlatIconClassNameByIconName };
