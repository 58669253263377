import React from "react";
import { numericFormatter } from "react-number-format";
import { useStartPageProducedElectricity } from "../../../hooks/startPage/useStartPageProducedElectricity";
import { IconName } from "../../BuildingBlocks/Icon/types";
import type { MiniTileItem } from "./MiniTile";
import { MiniTile } from "./MiniTile";

type ProducedElectricityMiniTileProps = {
  projectId?: string;
  year: number;
};

function ProducedElectricityMiniTile({
  projectId,
  year
}: ProducedElectricityMiniTileProps) {
  const { data, isLoading } = useStartPageProducedElectricity(year, projectId);

  const items: Array<MiniTileItem> = [
    {
      label: "Summe seit Jahresbeginn",
      value: data
        ? numericFormatter(data.producedElectricityKwh.toString(), {
            decimalSeparator: ",",
            thousandSeparator: ".",
            suffix: " kWh"
          })
        : ""
    }
  ];

  return (
    <MiniTile
      iconName={IconName.WindGenerator}
      isLoading={isLoading}
      items={items}
      title="Erzeugte Strommengen"
    />
  );
}

export { ProducedElectricityMiniTile };
