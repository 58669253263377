export function generateFakeId() {
  return Math.floor(Math.random() * 1000000);
}

type Primitives =
  | string
  | number
  | bigint
  | boolean
  | undefined
  | symbol
  | null;

export function makeArrayUnique<T extends Primitives>(
  array: Array<T>
): Array<T> {
  return Array.from(new Set(array));
}

// https://stackoverflow.com/questions/51977209/can-a-typed-zip-function-be-implemented-now-with-typescript-3-0s-generic-rest-p?rq=1
type Zip<T extends unknown[][]> = {
  [I in keyof T]: T[I] extends (infer U)[] ? U : never;
}[];

// WARNING: just like lodash, this implementation will fill in undefined for mismatched values - but it doesn't make problems for TypeScript
export function zip<T extends unknown[][]>(...args: T): Zip<T> {
  return <Zip<T>>(
    (<unknown>args[0].map((_, idx) => args.map((row) => row[idx])))
  );
}
