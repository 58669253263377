import { MspCategory } from "../../../../utils/enums";
import type { FilterDropdownValues } from "../../../FilterDropdown/FilterDropdown.types";
import { LocationOverviewFilterType } from "../LocationOverview.types";

export const DEFAULT_FILTER_VALUES: FilterDropdownValues = {
  [LocationOverviewFilterType.Status]: undefined,
  [LocationOverviewFilterType.MinMissingConsumptionMonthPercentage]: undefined,
  [LocationOverviewFilterType.MinMissingConsumptionYearPercentage]: undefined,
  [LocationOverviewFilterType.MinMissingGenerationMonthPercentage]: undefined,
  [LocationOverviewFilterType.MinMissingGenerationYearPercentage]: undefined,
  [MspCategory.CSV]: false,
  [MspCategory.LiefEdi]: false,
  [MspCategory.EsaEdi]: false,
  [MspCategory.EsaReq]: false,
  [MspCategory.Reluctant]: false,
  [MspCategory.Unknown]: false
};
