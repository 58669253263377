const BASE_URL = "/";
const PROJECTS_WITH_ID_URL = "projekte/:projectId/";
const OPTIONAL_PROJECT_ID_URL = ":projectId?";
export const ANALYZER_URL = `${BASE_URL}analyzer/`;
export const VARIANTS_URL_WITH_PROJECT_ID = `${BASE_URL}${PROJECTS_WITH_ID_URL}varianten/`;
export const VARIANTS_URL_WITH_ID = `${VARIANTS_URL_WITH_PROJECT_ID}:variantId/`;
export const MANAGER_URL = `${BASE_URL}manager/`;
export const MANAGER_URL_WITH_PROJECT_ID = `${MANAGER_URL}${PROJECTS_WITH_ID_URL}`;
export const THIRD_PARTY_SYSTEMS_URL = "untermesssysteme";
const PROJECT_URL_WITH_ID = `${BASE_URL}${PROJECTS_WITH_ID_URL}`;

export const ROUTES = {
  productSelectPage: BASE_URL,
  userProfilePage: `${BASE_URL}profile/`,
  analyzerProjectList: `${ANALYZER_URL}projekte/`,
  managerStartPage: `${MANAGER_URL}startseite`,
  managerProjectList: `${MANAGER_URL}projekte/`,
  direktvermarktung: `${MANAGER_URL}direktvermarktung/`,
  revenueMonitoring: `${MANAGER_URL}erloesmonitoring/`,
  managerProjectView: MANAGER_URL_WITH_PROJECT_ID,
  simulation: `${PROJECT_URL_WITH_ID}simulation/:simulationId/`,
  analyse: `${VARIANTS_URL_WITH_ID}analyse/`,
  analyseSubPage: `${VARIANTS_URL_WITH_ID}analyse/:analysisPage`,
  deliveryOverview: `${MANAGER_URL_WITH_PROJECT_ID}lieferungen-uebersicht/`,
  guaranteeOfOrigin: `${MANAGER_URL_WITH_PROJECT_ID}herkunftsnachweis/`,
  parkAbrechnung: `${MANAGER_URL_WITH_PROJECT_ID}parkabrechnung/`,
  performanceMonitoringOverview: `${MANAGER_URL_WITH_PROJECT_ID}performance-monitoring/`,
  loadprofileManagerList: `${PROJECT_URL_WITH_ID}lastprofil-manager/`,
  energyData: `${MANAGER_URL_WITH_PROJECT_ID}energiedaten/`,
  direktvermarktungProjectLevel: `${MANAGER_URL_WITH_PROJECT_ID}direktvermarktung/`,
  revenueMonitoringProjectLevel: `${MANAGER_URL_WITH_PROJECT_ID}erloesmonitoring/`,
  report: `${VARIANTS_URL_WITH_ID}ergebnisreport/`,
  todos: `${MANAGER_URL_WITH_PROJECT_ID}aufgaben/`,
  onboarding: `${MANAGER_URL_WITH_PROJECT_ID}onboarding/`,
  onboardingTodo: `${MANAGER_URL_WITH_PROJECT_ID}onboarding/:todoId/`,
  universalTodos: `${BASE_URL}zentrale-aufgabenliste/`,
  variantComparison: `${VARIANTS_URL_WITH_ID}variantenvergleich/`,
  analyzerVariantStructure: `${VARIANTS_URL_WITH_ID}analyzer/`,
  analyzerVariantStructureComponent: `${VARIANTS_URL_WITH_ID}analyzer/:objectUrlName/:componentId/`,
  managerVariantStructure: `${MANAGER_URL_WITH_PROJECT_ID}liegenschaften/:siteId/`,
  managerVariantStructureComponent: `${MANAGER_URL_WITH_PROJECT_ID}liegenschaften/:siteId/:objectUrlName/:componentId/`,
  paragraph6: `${MANAGER_URL_WITH_PROJECT_ID}paragraph6/`,
  evaluation: `${VARIANTS_URL_WITH_ID}bewertung/:evaluationId/`,
  apiSettings: `${BASE_URL}api-einstellungen/${OPTIONAL_PROJECT_ID_URL}`,
  settings: `${BASE_URL}einstellungen`,
  admin: `${BASE_URL}admin`
} as const;

export const PUBLIC_ROUTES = {
  login: "/login",
  otpConfirm: "/login/code",
  passwordReset: "/password-reset",
  passwordResetConfirm: "/password-reset/confirm",
  setPasswordConfirm: "/set-password/confirm",
  support: "/seiten/support"
} as const;
