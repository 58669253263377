import { useQuery, useQueryClient } from "@tanstack/react-query";
import PropTypes from "prop-types";
import React from "react";
import type { Column } from "react-table";

import api from "../../../api";
import urls from "../../../urls";
import { sortBackendDates } from "../../../utils/dates/sortBackendDates";
import { Icon } from "../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../BuildingBlocks/Icon/types";
import { DownloadLink } from "../../DownloadLink/DownloadLink";
import { LoadOrError } from "../../LoadOrError/LoadOrError";
import { SwissArmyTable } from "../../SwissArmyTable/SwissArmyTable";

import "./ReportList.scss";

const DEFAULT_SORTED = [
  {
    id: "created",
    desc: true
  }
];

interface ReportListProps {
  variantId: number;
}

type Report = {
  id: number;
  name: string;
  created: string;
};

function ReportList(props: ReportListProps) {
  const { variantId } = props;
  const {
    data: reports = [],
    isLoading,
    error
  } = useQuery({
    queryKey: ["reports", { variantId }],
    queryFn: () => fetchReports(variantId)
  });
  const queryClient = useQueryClient();

  async function fetchReports(variantId: number) {
    const response = await api.get<Array<Report>>(urls.api.reports(variantId));
    return response.data;
  }

  function handleReportDeleted() {
    queryClient.invalidateQueries({
      queryKey: ["reports", { variantId }]
    });
  }

  const tableColumns: Array<Column<Report>> = [
    {
      Header: "Erstellungsdatum",
      accessor: "created",
      width: 145,
      sortMethod: sortBackendDates
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: (cellData) => (
        <NameCell name={cellData.value} reportId={cellData.original.id} />
      )
    }
  ];

  return (
    <div className="ReportList">
      <LoadOrError error={error} loading={isLoading}>
        {reports && (
          <SwissArmyTable
            data={reports}
            defaultSorted={DEFAULT_SORTED}
            options={{
              buttons: {
                create: {
                  show: false
                }
              },
              deletion: {
                deleteEndpoint: urls.api.report,
                onDataDeleted: handleReportDeleted
              },
              pluralWord: "Reports",
              singleWord: "Report"
            }}
            tableColumns={tableColumns}
          />
        )}
      </LoadOrError>
    </div>
  );
}

interface ReportCellProps {
  name: string;
  reportId: number;
}

function NameCell(props: ReportCellProps) {
  const { name, reportId } = props;
  const filename = `${name}.pdf`;
  const downloadUrl = urls.api.reportDownload(reportId);

  return (
    <DownloadLink filename={filename} url={downloadUrl}>
      <Icon name={IconName.CloudDownload} /> {name}
    </DownloadLink>
  );
}

ReportList.propTypes = {
  variantId: PropTypes.number.isRequired
};

export { ReportList };
