import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../../../../api";
import urls from "../../../../urls";
import type { FictionalEnergyValueResponse } from "../EnergyDataUploadPage/FictionalEnergyValueManager/FictionalEnergyValueManager";

export function useFictionalEnergyValueMutations(variantId: number) {
  const queryClient = useQueryClient();

  const createMutation = useMutation({
    mutationFn: (payload: FictionalEnergyValueResponse) =>
      createFictionalEnergyValue(payload, variantId),
    onSuccess: handleSuccess
  });

  const editMutation = useMutation({
    mutationFn: (payload: FictionalEnergyValueResponse) =>
      editFictionalEnergyValue(payload),
    onSuccess: handleSuccess
  });

  const deleteMutation = useMutation({
    mutationFn: (id: string) => deleteFictionalEnergyValue(id),
    onSuccess: handleSuccess
  });

  function handleSuccess() {
    queryClient.invalidateQueries({
      queryKey: ["fictionalEnergyValues", { variantId }]
    });
  }

  return {
    createMutation,
    editMutation,
    deleteMutation
  };
}

function createFictionalEnergyValue(
  payload: FictionalEnergyValueResponse,
  variantId: number
) {
  const url = urls.api.fictionalEnergyValues(variantId);
  return api.post<FictionalEnergyValueResponse>(url, payload);
}

function editFictionalEnergyValue(payload: FictionalEnergyValueResponse) {
  const url = urls.api.fictionalEnergyValue(payload.id);
  return api.patch(url, payload);
}

function deleteFictionalEnergyValue(id: string) {
  const url = urls.api.fictionalEnergyValue(id);
  return api.delete(url);
}
