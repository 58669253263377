import { type FieldError } from "react-hook-form";

/** Type Guard for `FieldError` to be used only when checking vs. an error with nested FieldErrors */
export function isFieldError(error: unknown): error is FieldError {
  if (typeof error !== "object") {
    return false;
  }

  return "type" in (error as FieldError);
}
