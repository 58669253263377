import classNames from "classnames";
import type { FormEvent } from "react";
import React, { useCallback, useState, useMemo, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Form, FormGroup } from "reactstrap";

import type { ApiError } from "../../api";
import { ROUTES } from "../../routes";
import urls from "../../urls";
import { SiteName } from "../../utils/enums";
import { Button } from "../Buttons/Button/Button";
import { SpinButton } from "../Buttons/SpinButton/SpinButton";
import { FormItem } from "../DynamicForm/FormItem/FormItem";
import { FormFieldType } from "../DynamicForm/FormItems/FormField/FormField";
import { PublicLayout } from "../Layout/PublicLayout";
import { useSsoConfiguration } from "./useSsoConfiguration";
import { checkAuth } from "./utils/checkAuth";
import { login } from "./utils/login";

import "./Login.scss";

declare const SITE_NAME: SiteName;

const handleRoedlLogin = (redirectUrl: string) => {
  location.replace(`${urls.auth.roedlLogin()}?next=${redirectUrl}`);
};

const handleMicrosoftLogin = (redirectUrl: string) => {
  location.replace(`${urls.auth.microsoftLogin()}?next=${redirectUrl}`);
};

function Login() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { ssoConfig } = useSsoConfiguration();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState<string>();
  const [loginInProgress, setLoginInProgress] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const redirectUrl = useMemo(
    () => searchParams.get("next") ?? ROUTES.productSelectPage,
    [searchParams]
  );

  const handleLogin = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      setLoginInProgress(true);

      try {
        const loginInfo = await login(username, password);

        if (loginInfo?.ephemeralToken) {
          navigate(`/login/code?method=${loginInfo.method}&email=${username}`);
          return;
        }

        location.replace(redirectUrl);
      } catch (e) {
        setLoginError((e as ApiError<{ error: string }>).response?.data.error);
      } finally {
        setLoginInProgress(false);
      }
    },
    [password, redirectUrl, username, navigate]
  );

  useEffect(() => {
    const prepareRender = async () => {
      const hasAccess = await checkAuth(redirectUrl);

      setIsReady(hasAccess);
    };

    prepareRender();
  }, [redirectUrl]);

  return isReady ? (
    <PublicLayout>
      <Form className="LoginForm" id="login-form" onSubmit={handleLogin}>
        <div className="LoginIconContainer">
          <div className="LoginIcon"></div>
        </div>

        <h3 className="LoginTitle">Anmelden</h3>

        <FormGroup>
          <FormItem
            initialValue=""
            label=""
            name="auth-username"
            noLabel
            placeholder="Email"
            required
            type={FormFieldType.String}
            value={username}
            onInput={(_, value: string) => setUsername(value)}
          />
          <FormItem
            initialValue=""
            label=""
            name="auth-password"
            noLabel
            placeholder="Passwort"
            required
            type={FormFieldType.Obfuscated}
            value={password}
            onInput={(_, value: string) => setPassword(value)}
          />
        </FormGroup>

        {loginError && (
          <div
            className={classNames(
              "alert alert-danger",
              "alert-dismissable",
              "login-error"
            )}
          >
            <p className="LoginErrorText">
              Ungültige Zugangsdaten. Bitte erneut versuchen
            </p>
          </div>
        )}

        <div>
          <SpinButton
            className="LoginFilledBtn"
            color="brand"
            disabled={!username || !password || loginInProgress}
            id="m_login_signin_submit"
            spin={loginInProgress}
          >
            Login
          </SpinButton>
        </div>

        {SITE_NAME === SiteName.Smarendo && ssoConfig?.roedl && (
          <div>
            <Button
              className="LoginOutlinedBtn"
              id="m_login_sso"
              onClick={() => handleRoedlLogin(redirectUrl)}
            >
              Login für Rödl&Partner-Mitarbeiter
            </Button>
          </div>
        )}

        {ssoConfig?.microsoft && (
          <div>
            <Button
              className="LoginOutlinedBtn"
              color="secondary"
              onClick={() => handleMicrosoftLogin(redirectUrl)}
            >
              Login mit einem Microsoft-Konto
            </Button>
          </div>
        )}

        <div className="ForgotPassword">
          <Link
            className="m-link m-link--brand"
            id="m_login_forget_password"
            to={`/password-reset`}
          >
            Passwort vergessen?
          </Link>
        </div>
      </Form>
    </PublicLayout>
  ) : null;
}

export { Login };
