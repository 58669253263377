import { type ApiResponse } from "../../api";

/** Gets the filename from the "content-disposition" property of API response headers.
 * If the filename is not found, it defaults to "file.xlsx".
 */
export function extractFilenameFromContentDispositionHeader(
  response: ApiResponse
) {
  const disposition = response.headers["content-disposition"];
  let filename = "file.xlsx";

  if (disposition && disposition.indexOf("attachment") !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);

    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, "");
    }
  }

  return filename;
}
