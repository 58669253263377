import { MonthPickerInput } from "@mantine/dates";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { useVariantSites } from "../../../../hooks/useVariantSites";
import type { Site } from "../../../../utils/backend-types";
import { THIS_YEAR } from "../../../../utils/dates";
import { Portlet } from "../../../BuildingBlocks/Layout/Portlet";
import { LoadOrError } from "../../../LoadOrError/LoadOrError";
import { EARLIEST_YEAR } from "../../common";
import "./RawEnergyDataPage.scss";
import { RawEnergyDataSite } from "./RawEnergyDataSite/RawEnergyDataSite";

interface RawEnergyDataPageProps {
  showPlausibility?: boolean;
  sites: Array<Site>;
}

function RawEnergyDataPage({
  showPlausibility,
  sites
}: RawEnergyDataPageProps) {
  const now = DateTime.now();
  const [selectedTimeRange, setSelectedTimeRange] = useState<
    [DateTime, DateTime]
  >([now.startOf("year"), now.endOf("year")]);

  return (
    <div className="RawEnergyDataPage">
      <Controls
        selectedTimeRange={selectedTimeRange}
        onChangeSelectedTimeRange={setSelectedTimeRange}
      />
      {sites.map((site) => (
        <RawEnergyDataSite
          key={site.id}
          selectedTimeRange={selectedTimeRange}
          showPlausibility={!!showPlausibility}
          siteId={site.id}
          siteName={site.name}
        />
      ))}
    </div>
  );
}

interface ControlsProps {
  selectedTimeRange: [DateTime, DateTime];
  onChangeSelectedTimeRange: (newTimeRange: [DateTime, DateTime]) => void;
}

function Controls({
  selectedTimeRange,
  onChangeSelectedTimeRange
}: ControlsProps) {
  const max = new Date(THIS_YEAR, 11, 31);
  const min = new Date(EARLIEST_YEAR, 0, 1);

  return (
    <Portlet className="raw-energy-data-controls">
      <div className="raw-energy-data-years-dropdown raw-energy-data-dropdown">
        <span className="label">Zeitraum</span>
        <MonthPickerInput
          allowSingleDateInRange
          defaultValue={[
            selectedTimeRange[0].toJSDate(),
            selectedTimeRange[1].toJSDate()
          ]}
          maxDate={max}
          minDate={min}
          type="range"
          onChange={(newValue) =>
            newValue[0] && newValue[1]
              ? onChangeSelectedTimeRange([
                  DateTime.fromJSDate(newValue[0]).startOf("month"),
                  DateTime.fromJSDate(newValue[1]).endOf("month")
                ])
              : {}
          }
        />
      </div>
    </Portlet>
  );
}

interface RawEnergyDataPageLoaderProps {
  showPlausibility?: boolean;
  variantId: number;
}

function RawEnergyDataPageLoader({
  showPlausibility,
  variantId
}: RawEnergyDataPageLoaderProps) {
  const {
    sites,
    isLoading: isSitesLoading,
    error: sitesError
  } = useVariantSites(variantId);
  const renderComponent = sites.length > 0;

  return (
    <LoadOrError error={sitesError} loading={isSitesLoading}>
      {renderComponent && (
        <RawEnergyDataPage showPlausibility={showPlausibility} sites={sites} />
      )}
    </LoadOrError>
  );
}

export {
  RawEnergyDataPageLoader as RawEnergyDataPage,
  RawEnergyDataPageLoaderProps as RawEnergyDataPageProps
};
