import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import urls from "../../../urls";
import type { Paragraph6ContractResponse } from "../Paragraph6.types";

export function useParagraph6Contracts(
  variantId: number,
  options?: Partial<UseQueryOptions<Array<Paragraph6ContractResponse>>>
) {
  const {
    data: paragraph6Contracts,
    isLoading,
    error
  } = useQuery({
    queryKey: ["paragraph6Contracts", { variantId }],
    queryFn: () => fetchParagraph6Contracts(variantId),
    enabled: !!variantId,
    ...options
  });

  async function fetchParagraph6Contracts(variantId: number) {
    const paragraph6ContractsUrl =
      urls.apiWithoutCamelization.paragraph6VariantContracts(variantId);
    const response = await apiWithoutCamelization.get<
      Array<Paragraph6ContractResponse>
    >(paragraph6ContractsUrl);
    return response.data;
  }

  return {
    paragraph6Contracts,
    isLoading,
    error
  };
}
