import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { ApiResponse } from "../../api";
import api from "../../api";
import type { CustomFormOptions } from "../CustomForm/useCustomForm";
import { useCustomForm } from "../CustomForm/useCustomForm";
import type { FormFieldValue } from "../DynamicForm/FormItems/FormField/FormField";
import { openErrorAlertPopup } from "../ErrorAlertPopup/openErrorAlertPopup";
import type { FieldsArray, FieldsDict } from "./OptionsForm";
import { ACTION_TYPES } from "./OptionsForm";

type ApiOptionsResponse = {
  actions:
    | {
        ["pOST"]: FieldsDict;
      }
    | {
        ["pUT"]: FieldsDict;
      };
};

interface OptionsFormOptions extends CustomFormOptions {
  filteredFields?: Array<string>;
  hiddenFields?: Array<string>;
  initialValues?: Record<string, FormFieldValue>;
  readOnlyFields?: Array<string>;
}

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
export function useOptionsForm(
  optionsUrl: string | undefined,
  optionsFormOptions: OptionsFormOptions
) {
  const { data: unfilteredFormFields } = useQuery({
    queryKey: ["options", optionsUrl],
    queryFn: fetchOptionsData,
    gcTime: 0,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: !!optionsUrl
  });
  const { t } = useTranslation();
  const {
    filteredFields,
    hiddenFields,
    initialValues,
    readOnlyFields,
    ...customFormOptions
  } = optionsFormOptions;

  const formFields: FieldsArray | undefined = useMemo(() => {
    if (unfilteredFormFields) {
      const filteredFormFields = getFilteredFormFields(
        filteredFields,
        unfilteredFormFields,
        hiddenFields,
        readOnlyFields,
        initialValues
      );

      return Object.entries(filteredFormFields).map(([name, formField]) => {
        return {
          ...formField,
          name
        };
      });
    }
  }, [
    filteredFields,
    unfilteredFormFields,
    hiddenFields,
    readOnlyFields,
    initialValues
  ]);

  const useCustomFormProps = useCustomForm(formFields, customFormOptions);

  async function fetchOptionsData() {
    if (!optionsUrl) {
      // this code is unreachable, due to `enabled` parameter
      throw new Error(t("errors.UnknownError"));
    }

    let response: ApiResponse;
    try {
      response = await api.options<ApiOptionsResponse>(optionsUrl);
    } catch (error) {
      openErrorAlertPopup(error);
      return {};
    }

    const action = customFormOptions.postUrl
      ? ACTION_TYPES.POST
      : ACTION_TYPES.PUT;

    if (
      typeof response.data.actions === "undefined" ||
      typeof response.data.actions[action] === "undefined"
    ) {
      throw new Error(t("errors.UnknownError"));
    }

    return response.data.actions[action];
  }

  function getFilteredFormFields(
    filteredFields: string[] | undefined,
    unfilteredFormFields: FieldsDict,
    hiddenFields: string[] = [],
    readOnlyFields: string[] | undefined,
    initialValues: Record<string, FormFieldValue> | undefined
  ): FieldsDict {
    const fieldNames: Array<string> =
      filteredFields || Object.keys(unfilteredFormFields);

    return fieldNames.reduce((obj, key) => {
      if (!unfilteredFormFields[key] || hiddenFields.includes(key)) {
        return obj;
      }

      obj[key] = unfilteredFormFields[key];

      // add initial value, if it exists
      if (
        initialValues &&
        Object.prototype.hasOwnProperty.call(initialValues, key)
      ) {
        obj[key].initialValue = initialValues[key];
      }
      if (readOnlyFields && readOnlyFields.includes(key)) {
        obj[key].readOnly = true;
      }

      return obj;
    }, {});
  }

  return { formFields, ...useCustomFormProps };
}
