import type { QueryClient } from "@tanstack/react-query";

import { ObjectName } from "../../../../../utils/enums";
import { MarktakteurContactInformation } from "../Components/MarktakteurContactInformation";
import { loadData, saveDataBasic } from "./common";
import type { Form } from "./data-types";

const HIDDEN_FIELDS_IF_NULL = [];

export const COMMON_FIELDS = [
  "name",
  "voltage",
  "gridOperator",
  "transmissionSystemOperator"
];
export const METERING_CONCEPT_RELATED_DATA_FIELDS = [
  "supplyType",
  "meteringConcept"
];
export const GRID_CONNECTION_RESPONSIBILITY_RELATED_DATA_FIELDS = [
  "connectingParty",
  "supplyContractHeldBy"
];

export const DEFAULT_FIELDS = [
  ...COMMON_FIELDS,
  ...GRID_CONNECTION_RESPONSIBILITY_RELATED_DATA_FIELDS
];

export const FIELDS_FOR_MIETERSTROM_SITES = [
  ...COMMON_FIELDS,
  ...METERING_CONCEPT_RELATED_DATA_FIELDS
];

export function loadConnectionData(
  queryClient: QueryClient,
  connectionId: number,
  siteId: number,
  variantId: number,
  isMieterstromSite: boolean
): Promise<Array<Form>> {
  let visibleFields = DEFAULT_FIELDS;
  if (isMieterstromSite) {
    visibleFields = FIELDS_FOR_MIETERSTROM_SITES;
  }

  return loadData({
    queryClient,
    objectName: ObjectName.Connection,
    objectId: connectionId,
    siteId,
    variantId,
    visibleFields: visibleFields,
    hiddenFieldsIfNull: HIDDEN_FIELDS_IF_NULL
  }).then((forms: Array<Form>) => {
    const gridOperatorMarktakteur =
      forms[0].sections[0].values["gridOperatorMarktakteur"];
    if (gridOperatorMarktakteur) {
      forms.push({
        name: "kontaktdaten-anb",
        formTitle: "Kontaktdaten ANB",
        Component: MarktakteurContactInformation,
        extraProps: {
          marktakteur: gridOperatorMarktakteur
        },
        sections: []
      });
    }
    const transmissionSystemOperatorMarktakteur =
      forms[0].sections[0].values["transmissionSystemOperatorMarktakteur"];
    if (transmissionSystemOperatorMarktakteur) {
      forms.push({
        name: "kontaktdaten-ünb",
        formTitle: "Kontaktdaten ÜNB",
        Component: MarktakteurContactInformation,
        extraProps: {
          marktakteur: transmissionSystemOperatorMarktakteur
        },
        sections: []
      });
    }
    return Promise.resolve(forms);
  });
}

export function saveConnectionData(connectionId: number, forms: Array<Form>) {
  return saveDataBasic(ObjectName.Connection, connectionId, [forms[0]]).then(
    (result) => {
      const resultWithFixedForms = {
        ...result,
        forms: [...result.forms, ...forms.slice(1)]
      };

      return Promise.resolve(resultWithFixedForms);
    }
  );
}
