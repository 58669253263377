import React from "react";
import { useParams } from "react-router-dom";

import urls from "../../urls";
import { Product } from "../../utils/enums";
import { ViewMode } from "../StructureView/StructureView";
import { AnalyzerStructureView } from "./AnalyzerStructureView";

interface Props {
  projectId?: string;
  siteId: number;
  variantId: number;
}

function MissingDataStructureView({ projectId, siteId, variantId }: Props) {
  const { evaluationId = "" } = useParams();

  const requestUrl = evaluationId
    ? urls.api.evaluationDetail(evaluationId)
    : undefined;

  const componentsPortletChildProps = {
    requestUrl
  };

  return (
    <AnalyzerStructureView
      componentsPortletChildProps={componentsPortletChildProps}
      componentsPortletTitle="Weitere Daten erforderlich"
      context={Product.Manager}
      projectId={projectId ?? ""}
      siteId={siteId}
      variantId={variantId}
      viewMode={ViewMode.MissingDataForm}
    />
  );
}

export { MissingDataStructureView };
