import { Stack } from "@mantine/core";
import React, { useState } from "react";
import { OptiSupportEndpoints } from "../../../../../urls";
import { isIdLocal } from "../../../../../utils/isIdLocal";
import { LoadOrError } from "../../../../LoadOrError/LoadOrError";
import { OptiSupportHelpLink } from "../../../../OptiSupportHelpLink/OptiSupportHelpLink";
import { useFictionalEnergyValueMutations } from "../../hooks/useFictionalEnergyValueMutations";
import { useFictionalEnergyValues } from "../../hooks/useFictionalEnergyValues";
import { createNewLocalFictionalEnergyValue } from "../../utils/createNewLocalFictionalEnergyValue";
import { FictionalEnergyValuesTable } from "../FictionalEnergyValuesTable/FictionalEnergyValuesTable";

export interface FictionalEnergyValueResponse {
  id: string;
  created: string;
  generator: number | null;
  generatorDisplayName: string | null;
  firstDate: string | null;
  lastDate: string | null;
  technischeNichtverfuegbarkeitKwh: number | null;
  redispatchAbregelungKwh: number | null;
  sonstigeAbschaltungOderDrosselungKwh: number | null;
}

interface FictionalEnergyValueManagerProps {
  variantId: number;
}

function FictionalEnergyValueManager({
  variantId
}: FictionalEnergyValueManagerProps) {
  const { fictionalEnergyValues, error, isLoading } =
    useFictionalEnergyValues(variantId);
  const fictionalEnergyValueMutations =
    useFictionalEnergyValueMutations(variantId);
  const [localEntries, setLocalEntries] = useState<
    Array<FictionalEnergyValueResponse>
  >([]);

  function handleCreateNewLocalEntry() {
    const newEntry = createNewLocalFictionalEnergyValue();
    setLocalEntries((oldEntries) => [newEntry, ...oldEntries]);

    return newEntry;
  }

  async function handleSaveCreditRange(
    id: string,
    payload: FictionalEnergyValueResponse
  ) {
    if (isIdLocal(id)) {
      // TODO: maybe just handle this directly in the table component...?
      const response =
        await fictionalEnergyValueMutations.createMutation.mutateAsync(payload);

      if (response.status === 201) {
        return Promise.resolve(response);
      }

      return Promise.reject(response);
    }

    payload.id = id;
    return fictionalEnergyValueMutations.editMutation.mutateAsync(payload);
  }

  async function handleDeleteCreditRange(id: string) {
    if (isIdLocal(id)) {
      setLocalEntries((oldEntries) =>
        oldEntries.filter((oldEntry) => oldEntry.id !== id)
      );

      return Promise.resolve();
    }

    const response =
      await fictionalEnergyValueMutations.deleteMutation.mutateAsync(id);

    if (response.status === 204) {
      return Promise.resolve();
    }

    return Promise.reject();
  }

  return (
    <div className="FictionalEnergyValueManager">
      <LoadOrError
        error={error}
        loading={isLoading}
        loadingMessage="Fiktive Strommengen werden geladen ..."
      >
        <Stack gap="xs">
          <OptiSupportHelpLink
            optiSupportEndpoint={
              OptiSupportEndpoints.BeruecksichtigungFiktiveMengen
            }
            text="Wie berücksichtigt opti.node die fiktiven Mengen?"
          />
          <FictionalEnergyValuesTable
            createNewFictionalEnergyValue={handleCreateNewLocalEntry}
            fictionalEnergyValues={[
              ...localEntries,
              ...(fictionalEnergyValues || [])
            ]}
            variantId={variantId}
            onDelete={handleDeleteCreditRange}
            onSubmit={handleSaveCreditRange}
          />
        </Stack>
      </LoadOrError>
    </div>
  );
}

export { FictionalEnergyValueManager, FictionalEnergyValueManagerProps };
