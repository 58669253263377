import classNames from "classnames";
import React, { useCallback, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "../BuildingBlocks/Dropdown/Dropdown";
import { NonSelectableDropdownItem } from "../BuildingBlocks/Dropdown/NonSelectableDropdownItem/NonSelectableDropdownItem";
import { Icon } from "../BuildingBlocks/Icon/Icon";
import { IconName } from "../BuildingBlocks/Icon/types";
import { Button } from "../Buttons/Button/Button";
import "./FilterDropdown.scss";
import type {
  FilterDropdownFilter,
  FilterDropdownFilterGroup,
  FilterDropdownFilters,
  FilterDropdownValues
} from "./FilterDropdown.types";
import { FilterGroup } from "./FilterGroup/FilterGroup";
import { BooleanFilterItem } from "./FilterItems/BooleanFilterItem";
import { NumberFilterItem } from "./FilterItems/NumberFilterItem";
import { RadioFilterItem } from "./FilterItems/RadioFilterItem";

interface FilterDropdownProps {
  className?: string;
  defaultValues: FilterDropdownValues;
  filters: FilterDropdownFilters;
  values: FilterDropdownValues;
  onSave: (newValues: FilterDropdownValues) => void;
}

function FilterDropdown({
  className,
  defaultValues,
  filters,
  values,
  onSave,
  ...otherProps
}: FilterDropdownProps) {
  const [localSelectedFilters, setLocalSelectedFilters] =
    useState<FilterDropdownValues>(values);
  const [isOpen, setIsOpen] = useState(false);

  const handleFilterChange = useCallback(
    (key: string, value: FilterDropdownValues[string]) => {
      setLocalSelectedFilters((oldFilters) => ({
        ...oldFilters,
        [key]: value
      }));
    },
    []
  );

  const handleClickReset = useCallback(() => {
    setLocalSelectedFilters(defaultValues);
    setIsOpen(false);
    onSave(defaultValues);
  }, [defaultValues, onSave]);

  const handleClickSave = useCallback(() => {
    onSave(localSelectedFilters);
    setIsOpen(false);
  }, [localSelectedFilters, onSave]);

  const filteredValuesCount = Object.values(values).filter(Boolean).length;

  return (
    <Dropdown
      className={classNames("FilterDropdown", className)}
      isOpen={isOpen}
      toggle={() => setIsOpen((isOpen) => !isOpen)}
      {...otherProps}
    >
      <DropdownToggle>
        <Icon name={IconName.Filter} /> Filter{" "}
        {filteredValuesCount > 0 && (
          <span className="filters-count">{filteredValuesCount}</span>
        )}
      </DropdownToggle>
      <DropdownMenu>
        {filters.map((filter) => (
          <FilterRenderer
            filter={filter}
            key={filter.key}
            values={localSelectedFilters}
            onChange={handleFilterChange}
          />
        ))}
        <DropdownItem divider />
        <NonSelectableDropdownItem className="footer">
          <Button color="secondary" onClick={handleClickReset}>
            Löschen
          </Button>
          <Button color="brand" onClick={handleClickSave}>
            Anwenden
          </Button>
        </NonSelectableDropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

interface FilterRendererProps {
  filter: FilterDropdownFilterGroup | FilterDropdownFilter;
  values: FilterDropdownValues;
  onChange: (key: string, value: FilterDropdownValues[string]) => void;
}

function FilterRenderer({ filter, values, onChange }: FilterRendererProps) {
  if ("header" in filter) {
    return (
      <FilterGroup header={filter.header}>
        {filter.filters.map((filter) => (
          <FilterRenderer
            filter={filter}
            key={filter.key}
            values={values}
            onChange={onChange}
          />
        ))}
      </FilterGroup>
    );
  }

  switch (filter.type) {
    case "boolean":
      return (
        <BooleanFilterItem
          filterValue={Boolean(values[filter.key])}
          key={filter.key}
          label={filter.label}
          onChange={() => onChange(filter.key, !values[filter.key])}
        />
      );
    case "number":
      return (
        <NumberFilterItem
          filterValue={Number(values[filter.key])}
          inputGroupText={filter.inputGroupText}
          key={filter.key}
          label={filter.label}
          max={filter.max}
          min={filter.min}
          onChange={(value) => onChange(filter.key, value)}
        />
      );
    case "radio":
      return (
        <RadioFilterItem
          choices={filter.choices}
          filterValue={String(values[filter.key])}
          key={filter.key}
          radioName={filter.radioName}
          onChange={(value) => onChange(filter.key, value)}
        />
      );
    default:
      console.error("Filter type not implemented for filter: ", filter);
      return null;
  }
}

export { FilterDropdown, FilterDropdownProps };
