import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Col, Row } from "reactstrap";
import { SelectionPopover } from "../SelectionPopover/SelectionPopover";
import VariantSelectionPopoverBodyContent from "./VariantSelectionPopoverBodyContent";

import "./VariantSelection.scss";

export default class VariantSelection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedVariantIds: [...this.props.selectedVariantIds]
    };
  }

  selectVariant = (variantId, multiSelect = true) => {
    const selectedVariantIds = multiSelect
      ? [...this.state.selectedVariantIds]
      : [];
    selectedVariantIds.push(variantId);
    this.setState({ selectedVariantIds });
  };

  deselectVariant = (variantId) => {
    const selectedVariantIds = [...this.state.selectedVariantIds];
    const variantIdIndex = selectedVariantIds.indexOf(variantId);
    if (variantIdIndex >= 0) {
      selectedVariantIds.splice(variantIdIndex, 1);
      this.setState({ selectedVariantIds });
    }
  };

  saveChanges = () => {
    this.props.onUpdateSelectedVariantIds(this.state.selectedVariantIds);
  };

  render() {
    const { variants, maxSelectable } = this.props;
    const { selectedVariantIds } = this.state;
    const nothingSelected = selectedVariantIds.length < 1;
    return (
      <SelectionPopover
        isPrimaryButtonDisabled={nothingSelected}
        name="variant"
        openButtonText="Varianten auswählen"
        onClickPrimaryButton={this.saveChanges}
      >
        <VariantSelectionPopoverBodyContent
          maxSelectable={maxSelectable}
          selectedVariantIds={selectedVariantIds}
          variants={variants}
          onVariantDeselect={this.deselectVariant}
          onVariantSelect={this.selectVariant}
        />
        <Row>
          <Col>
            {nothingSelected && (
              <span className="m--font-danger">
                Bitte mindestens 1 Element auswählen.
              </span>
            )}
          </Col>
        </Row>
      </SelectionPopover>
    );
  }
}

VariantSelection.propTypes = {
  variants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string
    })
  ).isRequired,
  onUpdateSelectedVariantIds: PropTypes.func.isRequired,
  selectedVariantIds: PropTypes.arrayOf(PropTypes.number),
  maxSelectable: PropTypes.number
};

VariantSelection.defaultProps = {
  selectedVariantIds: [],
  maxSelectable: Number.MAX_SAFE_INTEGER
};
