import urls from "../urls";
import { ObjectName } from "./enums";

const DETAIL_API_BY_OBJECTS = {
  [ObjectName.Generator]: urls.api.generator,
  [ObjectName.Consumer]: urls.api.consumer,
  [ObjectName.Storage]: urls.api.storage,
  [ObjectName.Person]: urls.api.person,
  [ObjectName.Connection]: urls.api.connection,
  [ObjectName.GasConnection]: urls.api.gasConnection,
  [ObjectName.Meter]: urls.api.meter,
  [ObjectName.GhostNode]: urls.api.ghostNode,
  [ObjectName.MarketLocation]: urls.api.marketLocation,
  [ObjectName.MeteringLocation]: urls.api.meteringLocation
} as const;

/** Gets the "detail" (GET) URL for a given ObjectName. */
export function getObjectDetailUrl(
  objectName: ObjectName,
  componentId: number,
  isPremium = false
) {
  if (isPremium && objectName === ObjectName.Meter) {
    return urls.api.meteringConceptMeter(componentId);
  }
  return DETAIL_API_BY_OBJECTS[objectName](componentId);
}
