import { IconName } from "../types";

const ICONS_MAP = {
  [IconName.GuaranteeOfOrigin]: "op-guarantee-of-origin",
  [IconName.OptiCharge]: "op-opti-charge",
  [IconName.Paragraph6]: "op-paragraph-6",
  [IconName.RevenueMonitoring]: "op-erloesmonitoring"
} as const;

const OPTI_FONT_ICONS = Object.keys(ICONS_MAP) as Array<IconName>;

function getOptiFontClassNameByIconName(iconName: IconName) {
  return ICONS_MAP[iconName] || "";
}

export { OPTI_FONT_ICONS, getOptiFontClassNameByIconName };
