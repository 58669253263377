import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../../../api";
import urls from "../../../urls";
import type { VariantId } from "../../../utils/backend-types";
import type {
  ParkabrechnungContractFormState,
  ParkabrechnungMeteringLocation
} from "../ParkabrechnungOverview/ParkabrechnungContracts/ParkabrechnungContractModal/ParkabrechnungContractModal.types";
import { parseFormStateToPayload } from "../ParkabrechnungOverview/ParkabrechnungContracts/ParkabrechnungContractModal/utils/parseFormStateToPayload";

export function useParkabrechnungPoolContractMutations(variantId: number) {
  const queryClient = useQueryClient();

  const parkabrechnungPoolContractCreateMutation = useMutation({
    mutationFn: async ({
      formState,
      meteringLocations,
      variantId
    }: {
      formState: ParkabrechnungContractFormState;
      meteringLocations: Array<ParkabrechnungMeteringLocation>;
      variantId: VariantId;
    }) => {
      const payload = parseFormStateToPayload({
        formState,
        meteringLocations
      });

      return await api.post(
        urls.api.parkabrechnungPoolContracts(variantId),
        payload
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["parkabrechnung-pool-contracts", { variantId }]
      });
    }
  });

  const parkabrechnungPoolContractEditMutation = useMutation({
    mutationFn: async ({
      contractId,
      formState,
      meteringLocations
    }: {
      contractId: string;
      formState: ParkabrechnungContractFormState;
      meteringLocations: Array<ParkabrechnungMeteringLocation>;
    }) => {
      const payload = parseFormStateToPayload({
        formState,
        meteringLocations
      });

      return await api.patch(
        urls.api.parkabrechnungPoolContract(contractId),
        payload
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["parkabrechnung-pool-contracts", { variantId }]
      });
    }
  });

  const parkabrechnungPoolContractDeleteMutation = useMutation({
    mutationFn: (contractId: string) => deletePoolContract(contractId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["parkabrechnung-pool-contracts", { variantId }]
      });
    }
  });

  return {
    parkabrechnungPoolContractCreateMutation,
    parkabrechnungPoolContractDeleteMutation,
    parkabrechnungPoolContractEditMutation
  };
}

function deletePoolContract(contractId: string) {
  const url = urls.api.parkabrechnungPoolContract(contractId);
  return api.delete(url);
}
