import type { QueryClient } from "@tanstack/react-query";

import api from "../../../../../../api";
import type {
  AnlageData,
  PutOptions
} from "../../../../../../utils/backend-types";
import { getFormFieldsAndValuesFromResponse } from "./getFormFieldsAndValuesFromResponse";

export async function loadEegAnlageData(
  queryClient: QueryClient,
  eegAnlageUrl: string,
  visibleEegAnlageFieldNames: Array<string>
) {
  const eegAnlageData = await queryClient.fetchQuery({
    queryKey: ["eegAnlage-" + eegAnlageUrl],
    queryFn: () => getEegAnlageData(eegAnlageUrl, visibleEegAnlageFieldNames)
  });

  return eegAnlageData;
}

export async function getEegAnlageData(
  eegAnlageUrl: string,
  visibleEegAnlageFieldNames: Array<string>
) {
  const optionsResponse = await api.options<PutOptions>(eegAnlageUrl);
  const getResponse = await api.get<AnlageData>(eegAnlageUrl);

  return getFormFieldsAndValuesFromResponse(
    [optionsResponse, getResponse],
    visibleEegAnlageFieldNames
  );
}
