import React from "react";
import type { Column } from "react-table";
import { numberToGermanLocalizedString } from "../../../../utils/numberToGermanLocalizedString";
import type { ParkabrechnungTag } from "../ParkabrechnungData/ParkabrechnungData";
import { PARKABRECHNUNG_TAGS } from "../ParkabrechnungData/ParkabrechnungData";

/* TODO: replace this hardcoded value endpoint is ready */
const CT_STROMSTEUER = 0.0205;

type NetzbezugColumn = {
  bezeichnung: string;
  energiemenge: number | undefined;
  lieferant?: undefined;
  belieferte?: undefined;
};

type DrittlieferungColumn = {
  energiemenge: number | undefined;
  lieferant: string;
  belieferte: {
    id: number;
    name: string;
    priceCtPerKwh?: number | undefined;
  } | null;
  bezeichnung?: undefined;
};
export function getParkabrechnungTableColumns(
  abrechnungType: ParkabrechnungTag,
  {
    isStromlieferung,
    isStromsteuer
  }: { isStromlieferung?: boolean; isStromsteuer?: boolean },
  ctPerKwh?: number
): Column<NetzbezugColumn | DrittlieferungColumn>[] {
  switch (abrechnungType) {
    case PARKABRECHNUNG_TAGS.drittlieferung:
      return [
        {
          Header: "Bezeichnung",
          columns: [
            {
              Header: "Lieferant",
              style: { textAlign: "right" },
              Cell: (values) => {
                return values.original.lieferant;
              }
            },
            {
              Header: "Belieferter",
              style: { textAlign: "right" },
              Cell: (values) => {
                return values.original.belieferte?.name;
              }
            }
          ]
        },
        {
          Header: "Energiemenge [kWh]",
          accessor: "energiemenge",
          style: { textAlign: "right" },
          Cell: (values) => {
            return numberToGermanLocalizedString(values.original.energiemenge);
          },
          /* eslint-disable @typescript-eslint/no-explicit-any */
          Footer: (values: any) => {
            const totalInCt = values.data.reduce((acc, item) => {
              return acc + item.energiemenge;
            }, 0);

            return (
              <Sum value={numberToGermanLocalizedString(totalInCt / 100)} />
            );
          }
        },
        {
          Header: "Stromsteuer [€]",
          accessor: "energiemenge",
          style: { textAlign: "right" },

          Cell: (values) =>
            isStromsteuer
              ? numberToGermanLocalizedString(
                  (values.original.energiemenge * CT_STROMSTEUER) / 100
                )
              : "-",
          /* eslint-disable @typescript-eslint/no-explicit-any */
          Footer: (values: any) => {
            const totalInCt = values.data.reduce((acc, item) => {
              return acc + item.energiemenge;
            }, 0);
            return (
              <Sum
                value={
                  isStromsteuer
                    ? numberToGermanLocalizedString(
                        (totalInCt * CT_STROMSTEUER) / 100
                      )
                    : "-"
                }
              />
            );
          }
        },
        {
          Header: "Stromlieferung [€]",
          accessor: "energiemenge",
          style: { textAlign: "right" },
          Cell: (values) => {
            if (!isStromlieferung) {
              return "-";
            } else if (
              typeof values.original.belieferte?.priceCtPerKwh === "number"
            ) {
              return numberToGermanLocalizedString(
                (values.original.belieferte.priceCtPerKwh *
                  values.original.energiemenge) /
                  100
              );
            } else {
              return "-";
            }
          },
          /* eslint-disable @typescript-eslint/no-explicit-any */
          Footer: (values: any) => {
            const totalInCt = values.data.reduce((acc, item) => {
              const energiemenge = item.energiemenge;
              const priceCtPerKwh =
                item._original.belieferte?.priceCtPerKwh || 0;
              const energiekosten = energiemenge * priceCtPerKwh;

              return acc + energiekosten;
            }, 0);
            return (
              <Sum
                value={
                  isStromlieferung
                    ? numberToGermanLocalizedString(totalInCt / 100)
                    : "-"
                }
              />
            );
          }
        },

        {
          Header: "Abrechnungsbetrag [€]",
          style: { textAlign: "right" },
          Cell: (values) => {
            let totalInCt = 0;
            if (isStromlieferung) {
              totalInCt =
                values.original.belieferte.priceCtPerKwh *
                values.original.energiemenge;
              if (isNaN(totalInCt)) {
                return "-";
              }
            }
            if (isStromsteuer) {
              totalInCt =
                totalInCt + CT_STROMSTEUER * values.original.energiemenge;
            }
            return numberToGermanLocalizedString(totalInCt / 100);
          }
        }
      ];

    case PARKABRECHNUNG_TAGS.netzbezug:
      return [
        {
          Header: "Bezeichnung",
          accessor: "bezeichnung"
        },
        {
          Header: "Energiemenge [kWh]",
          accessor: "energiemenge",
          style: { textAlign: "right" },
          Cell: (values) => {
            return numberToGermanLocalizedString(values.original.energiemenge);
          },
          /* eslint-disable @typescript-eslint/no-explicit-any */
          Footer: (values: any) => {
            const total = values.data.reduce((acc, item) => {
              return acc + item.energiemenge;
            }, 0);

            return <Sum value={numberToGermanLocalizedString(total)} />;
          }
        },
        {
          Header: "Abrechnungsbetrag [€]",
          style: { textAlign: "right" },
          Cell: (values) => {
            return ctPerKwh
              ? numberToGermanLocalizedString(
                  (values.original.energiemenge / 100) * ctPerKwh
                )
              : "-";
          },
          /* eslint-disable @typescript-eslint/no-explicit-any */
          Footer: (values: any) => {
            const totalInCt = values.data.reduce((acc, item) => {
              return acc + item.energiemenge;
            }, 0);

            return (
              <Sum
                value={
                  ctPerKwh
                    ? numberToGermanLocalizedString(
                        (totalInCt * ctPerKwh) / 100
                      )
                    : "-"
                }
              />
            );
          }
        }
      ];
    default:
      console.error(`abrechnungType ${abrechnungType} not implemented`);
      return [];
  }
}

function Sum({ value }: { value: string }) {
  return (
    <>
      <span style={{ fontWeight: 800 }}> Summe: </span> {value}
    </>
  );
}
