import api from "../api";
import { openErrorAlertPopup } from "../components/ErrorAlertPopup/openErrorAlertPopup";
import urls from "../urls";
import { pollTaskStatus } from "../utils/api-utils";
import { downloadPDF } from "../utils/downloadPdf";
import { getErrorText } from "../utils/get-error-text";
import { showToast } from "../utils/toast";

function useDownloadGuaranteeOfAttorney(siteId: number) {
  async function generateAndDownloadGuaranteeOfAttorney() {
    if (!siteId) return;
    const response = await api.get<{
      canGenerateDocumentsAutomatically: boolean;
      documentGenerationBlocker: {
        message: string;
      };
    }>(urls.api.siteCanGenerateDocuments(siteId));
    if (response.data.canGenerateDocumentsAutomatically) {
      api.get(urls.api.generateGuaranteeOfAttorney(siteId)).then((response) => {
        if (response.data) {
          pollTaskStatus(
            urls.api.jobStatus(response.data.taskIds[0]),
            () => getFilefromBackend(siteId, response.data.taskIds[0]),
            (error) => {
              showToast("error", getErrorText(error));
            },
            () => {
              openErrorAlertPopup(
                "Ein Fehler ist aufgetreten. Wir sind bereits darüber informiert."
              );
            }
          );
        }
      });
    } else {
      showToast("error", response.data.documentGenerationBlocker.message);
    }
  }

  function getFilefromBackend(siteId: number, taskId: string) {
    api.get(urls.api.getGuaranteeOfAttorney(siteId, taskId)).then((r) => {
      downloadPDF(r);
    });
  }

  return { generateAndDownloadGuaranteeOfAttorney };
}

export { useDownloadGuaranteeOfAttorney };
