import { useQuery } from "@tanstack/react-query";
import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "reactstrap";

import api from "../../api";
import urls from "../../urls";
import { Portlet } from "../BuildingBlocks/Layout/Portlet";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { ReportForm } from "./ReportForm/ReportForm";
import { ReportList } from "./ReportList/ReportList";

interface ReportProps {
  projectId: string;
  variantId: number;
}

type Variant = {
  id: number;
  name: string;
  bundleConsumers: boolean;
  isManagerVariant: boolean;
};

function Report(props: ReportProps) {
  const { projectId, variantId } = props;
  const {
    data: variant,
    isLoading: isVariantLoading,
    error: variantError
  } = useQuery({
    queryKey: ["variant", { variantId }],
    queryFn: () => fetchVariant(variantId)
  });

  async function fetchVariant(variantId: number) {
    const response = await api.get<Variant>(urls.api.variantDetail(variantId));
    return response.data;
  }

  return (
    <div className="Report">
      <Row>
        <Col md="6">
          <Portlet title="Neuen Report erstellen">
            <ReportForm projectId={projectId} variantId={variantId} />
          </Portlet>
        </Col>
        <Col md="6">
          <Portlet
            title={
              variant && (
                <span>
                  Reports für <em>{variant.name}</em>
                </span>
              )
            }
          >
            <LoadOrError error={variantError} loading={isVariantLoading}>
              <ReportList variantId={variantId} />
            </LoadOrError>
          </Portlet>
        </Col>
      </Row>
    </div>
  );
}

Report.propTypes = {
  projectId: PropTypes.string.isRequired,
  variantId: PropTypes.number.isRequired
};

export { Report };
