import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../api";
import urls from "../urls";
import type { GenerationShareResponse } from "./useGenerationShares";

export function useGenerationShareMutations() {
  const queryClient = useQueryClient();

  const editGenerationShare = useMutation({
    mutationFn: (generationShare: GenerationShareResponse) =>
      editGenerationShareRequest(generationShare),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["generationShares"]
      });
    }
  });

  const deleteGenerationShare = useMutation({
    mutationFn: (generationShareId: GenerationShareResponse["generator_id"]) =>
      deleteGenerationShareRequest(generationShareId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["generationShares"]
      });
    }
  });

  return {
    editGenerationShare,
    deleteGenerationShare
  };
}

function editGenerationShareRequest(generationShare: GenerationShareResponse) {
  if (!generationShare.generator_id) {
    throw new Error("Cannot edit generator share without an id");
  }
  const url = urls.apiWithoutCamelization.generationSharesDetail(
    generationShare.generator_id
  );
  return apiWithoutCamelization.put<GenerationShareResponse>(
    url,
    generationShare
  );
}

function deleteGenerationShareRequest(
  generationShareId: GenerationShareResponse["generator_id"]
) {
  if (!generationShareId) {
    throw new Error("Cannot delete generator share without an id");
  }
  const url =
    urls.apiWithoutCamelization.generationSharesDetail(generationShareId);
  return apiWithoutCamelization.delete<GenerationShareResponse>(url);
}
