import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import api from "../../api";
import type { CurrentUser } from "../../utils/backend-types";
import type { SiteName } from "../../utils/enums";
import { Layout } from "../Layout/Layout";

interface Props {
  currentUser: CurrentUser | undefined;
  toggleShowStaffView: () => void;
}

declare const SITE_NAME: SiteName;

function InternalPage({ currentUser, toggleShowStaffView }: Props) {
  const [isAccessTokenValid, setIsAccessTokenValid] = useState(false);

  useEffect(() => {
    api.checkAuthMiddleware().then((result) => {
      setIsAccessTokenValid(result);
    });
  }, []);

  if (isAccessTokenValid) {
    return (
      <Layout
        currentUser={currentUser}
        siteName={SITE_NAME}
        toggleShowStaffView={toggleShowStaffView}
      >
        <Outlet />
      </Layout>
    );
  }
}

export { InternalPage };
