import * as Sentry from "@sentry/browser";

const FIRST_SUPPORTED_ONBOARDING_TODO_YEAR = 2020;
export const LAST_SUPPORTED_ONBOARDING_TODO_YEAR = 2023;

export enum OnboardingTodo {
  KONFIGURATION_PRUEFEN,
  AUFGABENLISTE_MIT_REGULATORISCHEN_PFLICHTEN_ANLEGEN,
  REGULATORISCHE_DATEN_VERVOLLSTAENDIGEN,
  HISTORISCHE_MESSDATEN_AUS_UNTERMESSSYSTEMEN_BEREITSTELLEN,
  HISTORISCHE_MESSDATEN_AUS_UNTERMESSSYSTEMEN_INTEGRIEREN,
  VOLLMACHT_FUER_MSB_BEREITSTELLEN,
  HISTORISCHE_MSB_DATEN_INTEGRIEREN,
  KONTINUIERLICHER_MSB_DATENEMPFANG_EINRICHTEN,
  KONTINUIERLICHER_MESSDATENEMPFANG_AUS_UNTERMESSSYSTEMEN_EINRICHTEN,
  MESS_UND_BILANZIERUNGSKONZEPT_ERSTELLEN,
  LIEFERKONDITIONEN_HINTERLEGEN_STROMLIEFERUNG_UND_ABRECHNUNG,
  MESSKONZEPT_UND_ZAEHLERKONFIGURATION_DATEN_VERVOLLSTAENDIGEN
}

// adapted from optinode/webserver/manager/enums.py
export function getOnboardingTodoLabel(
  onboardingTodo: OnboardingTodo,
  year: number
) {
  switch (onboardingTodo) {
    case OnboardingTodo.KONFIGURATION_PRUEFEN:
      return "Konfiguration prüfen";
    case OnboardingTodo.MESSKONZEPT_UND_ZAEHLERKONFIGURATION_DATEN_VERVOLLSTAENDIGEN:
      return "Messkonzept und Zählerkonfiguration Daten vervollständigen";
    case OnboardingTodo.AUFGABENLISTE_MIT_REGULATORISCHEN_PFLICHTEN_ANLEGEN:
      return "Aufgabenliste mit regulatorischen Pflichten anlegen";
    case OnboardingTodo.REGULATORISCHE_DATEN_VERVOLLSTAENDIGEN:
      return "Regulatorische Daten vervollständigen";
    case OnboardingTodo.HISTORISCHE_MESSDATEN_AUS_UNTERMESSSYSTEMEN_BEREITSTELLEN:
      return `Historische Messdaten für ${year} aus Untermesssystemen bereitstellen`;
    case OnboardingTodo.HISTORISCHE_MESSDATEN_AUS_UNTERMESSSYSTEMEN_INTEGRIEREN:
      return `Historische Messdaten für ${year} aus Untermesssystemen integrieren`;
    case OnboardingTodo.VOLLMACHT_FUER_MSB_BEREITSTELLEN:
      return "Vollmacht für MSB bereitstellen";
    case OnboardingTodo.HISTORISCHE_MSB_DATEN_INTEGRIEREN:
      return `Historische MSB-Daten integrieren für ${year}`;
    case OnboardingTodo.KONTINUIERLICHER_MSB_DATENEMPFANG_EINRICHTEN:
      return "Kontinuierlicher MSB-Datenempfang einrichten";
    case OnboardingTodo.KONTINUIERLICHER_MESSDATENEMPFANG_AUS_UNTERMESSSYSTEMEN_EINRICHTEN:
      return "Kontinuierlicher Messdatenempfang aus Untermesssystemen einrichten";
    case OnboardingTodo.MESS_UND_BILANZIERUNGSKONZEPT_ERSTELLEN:
      return "Mess- und Bilanzierungskonzept erstellen";
    case OnboardingTodo.LIEFERKONDITIONEN_HINTERLEGEN_STROMLIEFERUNG_UND_ABRECHNUNG:
      return "Lieferkonditionen hinterlegen / Stromlieferung und Abrechnung";
    default:
      Sentry.captureMessage(
        `Unknown onboarding todo: ${onboardingTodo} for year ${year}`
      );
      return "Unbekannte Aufgabe";
  }
}

export function getAllOnboardingTodoLabelsForYear(year: number) {
  const keys = Object.keys(OnboardingTodo).filter((key) =>
    isNaN(Number(key))
  ) as Array<keyof typeof OnboardingTodo>;

  return keys.map((key) => getOnboardingTodoLabel(OnboardingTodo[key], year));
}

export function getAllPossibleOnboardingTodoLabels() {
  const labels: Array<string> = [];

  for (
    let year = FIRST_SUPPORTED_ONBOARDING_TODO_YEAR;
    year < LAST_SUPPORTED_ONBOARDING_TODO_YEAR + 1;
    year++
  ) {
    labels.push(...getAllOnboardingTodoLabelsForYear(year));
  }

  const uniqueLabels = Array.from(new Set(labels));

  return uniqueLabels;
}

export function isTodoSpecificOnboardingTodo(
  todoLabel: string,
  onboardingTodo: OnboardingTodo
) {
  for (
    let year = FIRST_SUPPORTED_ONBOARDING_TODO_YEAR;
    year < LAST_SUPPORTED_ONBOARDING_TODO_YEAR + 1;
    year++
  ) {
    if (todoLabel === getOnboardingTodoLabel(onboardingTodo, year)) {
      return true;
    }
  }

  return false;
}
