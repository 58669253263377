import { useQuery } from "@tanstack/react-query";
import saveAs from "file-saver";
import type { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";

import type { ApiResponse } from "../../../../api";
import api from "../../../../api";
import urls from "../../../../urls";
import type { TemplateMeter } from "../../../../utils/backend-types";
import {
  LUXON_END_OF_LAST_YEAR,
  LUXON_START_OF_LAST_YEAR
} from "../../../../utils/dates";
import type { Unit } from "../../../../utils/enums";
import { b64toBlob } from "../../../../utils/files/b64toBlob";
import { BasicConfirmationModal } from "../../../BuildingBlocks/Layout/Modals/BasicConfirmationModal/BasicConfirmationModal";
import { SpinButton } from "../../../Buttons/SpinButton/SpinButton";
import {
  DatePresetType,
  DateRangePicker,
  OpenDirection
} from "../../../DatePicker/DatePicker";
import type { Choice } from "../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { TsDropdown } from "../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { openErrorAlertPopup } from "../../../ErrorAlertPopup/openErrorAlertPopup";
import { LoadOrError } from "../../../LoadOrError/LoadOrError";

import "./EnergyDataUploadExplanationWithTemplateDownload.scss";

const START_DATE = LUXON_START_OF_LAST_YEAR;
const END_DATE = LUXON_END_OF_LAST_YEAR;

interface EnergyDataTemplateDownloadResponse {
  encodedFileData: string;
  fileName: string;
  warnings: Array<string>;
}

interface DateRange {
  startDate: DateTime | null;
  endDate: DateTime | null;
}

interface EnergyDataUploadExplanationWithTemplateDownloadProps {
  variantId: number;
  defaultMeterId?: number;
}

function EnergyDataUploadExplanationWithTemplateDownload({
  variantId,
  defaultMeterId
}: EnergyDataUploadExplanationWithTemplateDownloadProps) {
  const [downloadResponse, setDownloadResponse] = useState<
    ApiResponse<EnergyDataTemplateDownloadResponse> | undefined
  >();
  const [downloadWarnings, setDownloadWarnings] = useState<Array<string>>([]);
  const [isDownloading, setIsDownLoading] = useState(false);

  function handleExcelFile(
    response: ApiResponse<EnergyDataTemplateDownloadResponse>
  ) {
    setDownloadResponse(response);

    if (response.data.warnings && response.data.warnings.length > 0) {
      setDownloadWarnings(response.data.warnings);
    } else {
      saveDownload(response);
    }
  }

  function handleClickDownloadTemplate(
    dates: DateRange,
    selectedMeter: number | null,
    unit?: Unit
  ) {
    if (dates.startDate === null || dates.endDate === null) {
      return;
    }

    if (selectedMeter && !unit) {
      return;
    }

    setIsDownLoading(true);
    api
      .get<EnergyDataTemplateDownloadResponse>(
        urls.api.energyDataTemplateDownload(
          variantId,
          dates.startDate.toISO() as string, // todo auto convert sent dates in axios hooks
          dates.endDate.toISO() as string, // todo auto convert sent dates in axios hooks
          selectedMeter,
          unit
        )
      )
      .then((response) => {
        handleExcelFile(response);
      })
      .catch((error) => {
        openErrorAlertPopup(error);
      })
      .finally(() => setIsDownLoading(false));
  }

  function handleDownloadConfirmation() {
    if (downloadResponse) {
      saveDownload(downloadResponse);
    }

    setDownloadWarnings([]);
  }

  function saveDownload(
    response: ApiResponse<EnergyDataTemplateDownloadResponse>
  ) {
    const contentType = response.headers["content-type"];
    const blob = b64toBlob(response.data.encodedFileData, contentType);

    saveAs(blob, response.data.fileName);
    setDownloadResponse(undefined);
  }

  return (
    <div className="EnergyDataTemplateDownload">
      <div className="dropzone-overlay-section explanation">
        Sie können sich hier eine Dateivorlage für den Upload erstellen lassen.
        Bitte wählen Sie den Zeitraum, der in der Vorlage enthalten sein soll.
        Wenn Sie das opti.node-Datenformat <strong>für alle Zähler</strong>{" "}
        verwenden, muss das Format der hochgeladenen Datei der Vorlage
        entsprechen. Bei Bedarf können Sie aber Zeilen, Spalten oder
        Tabellenblätter löschen.
      </div>
      <div className="dropzone-overlay-section template-download">
        <Controls
          defaultSelectedMeterId={defaultMeterId}
          isDownloading={isDownloading}
          variantId={variantId}
          onClickDownload={handleClickDownloadTemplate}
        />
      </div>
      <BasicConfirmationModal
        cancelButtonText="Vorlage trotzdem herunterladen"
        confirmationButtonText="OK"
        headerText="Hinweis"
        isModalOpen={downloadWarnings.length > 0}
        toggleModal={() => setDownloadWarnings([])}
        onCancel={handleDownloadConfirmation}
        onConfirm={() => setDownloadWarnings([])}
      >
        <div className="energy-data-template-download-confirmation-body">
          {downloadWarnings.map((warning) => (
            <p key={warning}>{warning}</p>
          ))}
        </div>
      </BasicConfirmationModal>
    </div>
  );
}

interface ControlsProps {
  defaultSelectedMeterId?: number;
  isDownloading: boolean;
  variantId: number;
  onClickDownload: (
    dates: DateRange,
    selectedMeter: number | null,
    unit?: Unit
  ) => void;
}

function Controls({
  defaultSelectedMeterId,
  isDownloading,
  variantId,
  onClickDownload
}: ControlsProps) {
  const [dates, setDates] = useState<DateRange>({
    startDate: START_DATE,
    endDate: END_DATE
  });
  const {
    data: templateMeters,
    isLoading,
    error
  } = useQuery({
    queryKey: ["template-meters", { variantId }],
    queryFn: () => fetchTemplateMeters(variantId)
  });
  const [selectedMeter, setSelectedMeter] = useState<number | null>(
    defaultSelectedMeterId ?? null
  );
  const [selectedUnit, setSelectedUnit] = useState<Unit | undefined>();
  const meterChoices = useMemo<Array<Choice>>(() => {
    const allMetersChoice = {
      displayName: "Alle Zähler",
      value: "",
      group: templateMeters && templateMeters.length > 0 ? "" : undefined
    };

    if (!templateMeters) {
      return [allMetersChoice];
    }

    const templateMeterChoices = templateMeters.map<Choice>((meter) => ({
      displayName: meter.name,
      value: meter.id,
      group: meter.siteName
    }));

    templateMeterChoices.sort((choiceA, choiceB) => {
      if (choiceA.displayName < choiceB.displayName) {
        return -1;
      } else if (choiceA.displayName > choiceB.displayName) {
        return 1;
      }

      return 0;
    });

    return [allMetersChoice, ...templateMeterChoices];
  }, [templateMeters]);

  useEffect(() => {
    const selectedTemplateMeter = templateMeters?.find(
      (meter) => meter.id === selectedMeter
    );

    if (selectedTemplateMeter) {
      setSelectedUnit(selectedTemplateMeter.defaultUnit);
    } else {
      setSelectedUnit(undefined);
    }
  }, [selectedMeter, templateMeters]);

  async function fetchTemplateMeters(variantId: number) {
    const response = await api.get<TemplateMeter[]>(
      urls.api.energyDataTemplateMeters(variantId)
    );

    return response.data;
  }

  function handleMeterChange(newValue: string) {
    if (newValue === "") {
      setSelectedMeter(null);
    } else {
      setSelectedMeter(parseInt(newValue, 10));
    }
  }

  function handleUnitChange(newValue: string) {
    setSelectedUnit(newValue as Unit);
  }

  const canDownload = dates.startDate !== null && dates.endDate !== null;
  const selectedTemplateMeter = templateMeters?.find(
    (meter) => meter.id === selectedMeter
  );
  const unitChoices =
    selectedTemplateMeter?.units.map((unit) => ({
      displayName: unit[1],
      value: unit[0]
    })) ?? [];

  return (
    <LoadOrError error={error} loading={isLoading}>
      {templateMeters && (
        <>
          <DateRangePicker
            id="template-date-range-picker"
            initialEndDate={END_DATE}
            initialStartDate={START_DATE}
            openDirection={OpenDirection.OpenUp}
            presets={DatePresetType.MonthYear}
            onChange={setDates}
          />
          <TsDropdown
            choices={meterChoices}
            defaultValue={selectedMeter ?? ""}
            id="template-meter-selection"
            key={selectedMeter ?? "all"}
            name="template-meter-selection"
            placeholder="Zähler auswählen"
            required
            onChange={(_, newValue: string) => handleMeterChange(newValue)}
          />
          {selectedMeter && (
            <TsDropdown
              choices={unitChoices}
              defaultValue={selectedUnit}
              id="template-unit-selection"
              key={selectedUnit}
              name="template-unit-selection"
              placeholder="Einheit auswählen"
              required
              onChange={(_, newValue: string) => handleUnitChange(newValue)}
            />
          )}
          <SpinButton
            color="secondary"
            disabled={!canDownload}
            spin={isDownloading}
            onClick={() => onClickDownload(dates, selectedMeter, selectedUnit)}
          >
            Vorlage herunterladen
          </SpinButton>
        </>
      )}
    </LoadOrError>
  );
}

EnergyDataUploadExplanationWithTemplateDownload.propTypes = {
  variantId: PropTypes.number,
  defaultMeterId: PropTypes.number,
  appendDatePickerToBody: PropTypes.bool
};

export { EnergyDataUploadExplanationWithTemplateDownload };
