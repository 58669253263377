import { useQuery } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../api";
import urls from "../urls";
import type { SiteCategories } from "../utils/backend-types";

function useSiteCategoriesSummary() {
  const {
    isLoading,
    error,
    data: siteCategoriesSummary
  } = useQuery({
    queryKey: ["siteCategoriesSummary"],
    queryFn: () => fetchSiteCategoriesSummary(),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity
  });

  async function fetchSiteCategoriesSummary() {
    const response = await apiWithoutCamelization.get<SiteCategories>(
      urls.apiWithoutCamelization.siteCategoriesSummary()
    );
    return response.data;
  }

  return { isLoading, error, siteCategoriesSummary };
}

export { useSiteCategoriesSummary };
