import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Navigate } from "react-router-dom";
import urls from "../../../urls";
import { UNITS } from "../../../utils/constants";
import { Portlet } from "../../BuildingBlocks/Layout/Portlet";
import { Section } from "../../BuildingBlocks/Layout/Section";
import CustomForm from "../../CustomForm";

import "./SettingsView.scss";

export default class SettingsView extends PureComponent {
  constructor(props) {
    super(props);

    const {
      name,
      description,
      type,
      unit,
      energyFormatted,
      fullLoadHours,
      maxPower,
      category,
      projects,
      isGeneric,
      categories,
      allProjects
    } = props;

    const formattedCategories = categories.map((category) => {
      return {
        value: category.name,
        displayName: category.name
      };
    });

    const formattedProjects = allProjects.map((project) => {
      return {
        value: project.id,
        displayName: project.name
      };
    });

    this.units = Object.values(UNITS);

    this.types = [
      { value: "generation", displayName: "Erzeugung" },
      { value: "consumption", displayName: "Verbrauch" }
    ];

    let projectValue;
    if (isGeneric) {
      projectValue = allProjects.map((project) => {
        return project.id;
      });
    } else {
      const matchingProjects = allProjects.filter((project) =>
        projects.includes(project.id)
      );
      projectValue = matchingProjects.map((project) => {
        return project.id;
      });
    }

    this.state = {
      formItems: [
        {
          name: "name",
          type: "string",
          label: "Bezeichnung",
          helpText: `Frei wählbar - wird nur zur Identifikation auf ${SITE_NAME} benötigt`,
          initialValue: name
        },
        {
          name: "description",
          type: "string",
          label: "Beschreibung",
          initialValue: description
        },
        {
          name: "unit",
          type: "dropdown",
          label: "Einheit",
          choices: this.units,
          initialValue: unit
        },
        {
          name: "energyFormatted",
          type: "string",
          label: "Energie [kWh]",
          initialValue: energyFormatted,
          readOnly: true
        },
        {
          name: "maxPower",
          type: "integer",
          label: "Höchstleistung [kW]",
          initialValue: maxPower,
          readOnly: true
        },
        {
          name: "fullLoadHours",
          type: "integer",
          label: "Vollbenutzungsstunden [h]",
          initialValue: fullLoadHours,
          readOnly: true
        },
        {
          name: "type",
          type: "dropdown",
          label: "Typ",
          choices: this.types,
          initialValue: type
        },
        {
          name: "category",
          type: "select2-creatable",
          label: "Kategorie",
          choices: formattedCategories,
          initialValue: category
        },
        {
          name: "projectsToAssign",
          type: "dropdown",
          label: "Zugeordnete Projekte",
          choices: formattedProjects,
          initialValue: projectValue,
          multiselect: true
        }
      ],
      error: undefined,
      returnToList: false
    };
  }

  returnToList = () => {
    this.setState({
      returnToList: true
    });
  };

  componentWillUnmount() {
    const categoryDropdown = this.state.formItems.find(
      (formItem) => formItem.name === "category"
    );
    if (categoryDropdown) {
      if (
        !this.props.categories.find(
          (category) => category.name === categoryDropdown.value
        )
      ) {
        this.props.onAddCategory(categoryDropdown.value);
      }
    }
  }

  render() {
    const { isGeneric } = this.props;

    const { formItems, returnToList } = this.state;

    if (returnToList) {
      return <Navigate replace to="../" />;
    }

    const url = urls.api.loadprofilesDetail(this.props.loadProfileId);
    return (
      <Portlet className="SettingsView" title="Einstellungen">
        <Section>
          <CustomForm
            allowInput={!isGeneric}
            cancelButtonText={isGeneric ? "Zurück" : "Abbrechen"}
            formFields={formItems}
            patchUrl={url}
            onCancel={this.returnToList}
            onSubmit={!isGeneric ? this.returnToList : undefined}
          />
        </Section>
      </Portlet>
    );
  }
}

SettingsView.propTypes = {
  loadProfileId: PropTypes.number.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  unit: PropTypes.string,
  category: PropTypes.string,
  energyFormatted: PropTypes.string,
  fullLoadHours: PropTypes.number,
  maxPower: PropTypes.number,
  projects: PropTypes.arrayOf(PropTypes.string),
  apiUrl: PropTypes.string,
  isGeneric: PropTypes.bool,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ).isRequired,
  allProjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired
};

SettingsView.defaultProps = {};
