import { useQuery } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import urls from "../../../urls";
import type { OAuthApplicationResponse } from "../ApiSettings.types";

export function useOAuthApplications() {
  const { data, isLoading, error } = useQuery({
    queryKey: ["oauthApplications"],
    queryFn: () => fetchApplications()
  });

  async function fetchApplications() {
    const url = urls.apiWithoutCamelization.oauthApplications();
    const response =
      await apiWithoutCamelization.get<Array<OAuthApplicationResponse>>(url);

    return response.data;
  }

  return {
    applications: data,
    isLoading,
    error
  };
}
