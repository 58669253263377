import classNames from "classnames";
import React from "react";
import { type FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import "./HelperText.scss";

interface HelperTextProps {
  error?: FieldError | string;
  helperText?: string;
  warning?: string;
}

/** Used for showing helper text, an error, or a warning below a form field. */
function HelperText({ error, helperText, warning }: HelperTextProps) {
  const { t } = useTranslation();

  if (typeof error !== "undefined" && error !== "") {
    if (typeof error === "string") {
      return <ErrorText text={error} />;
    } else if (error.message) {
      return <ErrorText text={error.message} />;
    }

    const errorText = t(
      `errors.${error.type.charAt(0).toUpperCase() + error.type.slice(1)}`
    );

    return <ErrorText text={errorText} />;
  } else if (typeof warning !== "undefined" && warning !== "") {
    return <WarningText text={warning} />;
  } else if (typeof helperText !== "undefined" && helperText !== "") {
    return <HelperTextComponent text={helperText} />;
  }

  return null;
}

function HelperTextWrapper(props: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={classNames("helper-text-wrapper", props.className)}
      {...props}
    />
  );
}

function HelperTextComponent({ text }: { text: string }) {
  return (
    <HelperTextWrapper>
      <span className="helper-text">{text}</span>
    </HelperTextWrapper>
  );
}

function ErrorText({ text }: { text: string }) {
  return (
    <HelperTextWrapper>
      <span className="invalid-feedback">{text}</span>
    </HelperTextWrapper>
  );
}

function WarningText({ text }: { text: string }) {
  return (
    <HelperTextWrapper>
      <span className="warning-feedback">{text}</span>
    </HelperTextWrapper>
  );
}

export { HelperText, HelperTextProps };
