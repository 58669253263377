import { useQuery } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../api";
import { startPageApiWithoutCamelization } from "../../api/subApis/startPageApi";
import type { StartPageTile } from "../../components/StartPage/StartPage.types";

function useStartPageTiles(projectId?: string) {
  const { data, error, isLoading } = useQuery({
    queryKey: startPageApiWithoutCamelization.tiles.key(projectId),
    queryFn: () => fetchStartPageTiles(projectId)
  });

  async function fetchStartPageTiles(projectId?: string) {
    const response = await apiWithoutCamelization.get<Array<StartPageTile>>(
      startPageApiWithoutCamelization.tiles.url(projectId)
    );

    return response.data;
  }

  return {
    tiles: data,
    isLoading,
    error
  };
}

export { useStartPageTiles };
