import { Group } from "@mantine/core";
import classNames from "classnames";
import React from "react";
import type { AlertProps } from "../../Alert/Alert";
import { Alert, AlertColor } from "../../Alert/Alert";
import { Icon } from "../Icon/Icon";
import { IconName } from "../Icon/types";
import "./IconAlert.scss";

type IconAlertColor = AlertColor.Danger | AlertColor.Warning | AlertColor.Info;

const ICON_NAME_BY_COLOR: Record<IconAlertColor, IconName> = {
  [AlertColor.Danger]: IconName.ExclamationRed,
  [AlertColor.Warning]: IconName.ExclamationOrange,
  [AlertColor.Info]: IconName.Info
};

export type IconAlertProps = Omit<AlertProps, "color"> & {
  color: IconAlertColor;
};

function IconAlert({ color, className, children, ...other }: IconAlertProps) {
  const iconName = ICON_NAME_BY_COLOR[color];
  const isInfoAlert = color === AlertColor.Info;

  return (
    <Alert
      className={classNames("IconAlert", className, {
        CustomInfoAlert: isInfoAlert
      })}
      color={color}
      {...other}
    >
      <Group align="center">
        <Icon className="icon-alert-icon" name={iconName} />
        <div
          className={classNames("icon-alert-content", {
            "info-alert-content": isInfoAlert
          })}
        >
          {children}
        </div>
      </Group>
    </Alert>
  );
}

export { IconAlert };
