import { useQuery } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../api";
import type { components } from "../types/api.types";
import urls from "../urls";

type GenerationShareResponse = components["schemas"]["GenerationShareResponse"];

function useGenerationShares(siteId: number) {
  const {
    data: generationShares,
    isLoading,
    error
  } = useQuery({
    queryKey: ["generationShares", { siteId }],
    queryFn: () => fetchGenerationShares(siteId)
  });

  async function fetchGenerationShares(siteId: number) {
    const paragraph6ContractsUrl =
      urls.apiWithoutCamelization.generationShares(siteId);
    const response = await apiWithoutCamelization.get<
      Array<GenerationShareResponse>
    >(paragraph6ContractsUrl);
    return response.data;
  }

  return {
    generationShares,
    isLoading,
    error
  };
}

export { GenerationShareResponse, useGenerationShares };
