import React from "react";
import { Icon } from "../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import "./CustomElementMenu.scss";

interface CustomElementMenuProps {
  onAddTextElement: () => void;
  onAddBoxElement: () => void;
  onDownloadGraph: () => void;
  siteDetailsLoading?: boolean;
}

function CustomElementMenu({
  onAddBoxElement,
  onAddTextElement,
  onDownloadGraph,
  siteDetailsLoading
}: CustomElementMenuProps) {
  return (
    <div
      className="CustomElementMenu react-flow__panel"
      data-testid="structure-view-flow-diagram-custom-element-menu"
    >
      <button
        className="text-element-button button-with-only-icon"
        type="button"
        onClick={onAddTextElement}
      >
        <Icon name={IconName.TextElement} style={{ width: "20px" }} />
      </button>
      <button
        className="box-element-button button-with-only-icon"
        type="button"
        onClick={onAddBoxElement}
      >
        <div className="box-element" />
      </button>
      <button
        className="download-graph-button button-with-only-icon"
        data-testid="download-graph-button"
        disabled={siteDetailsLoading}
        type="button"
        onClick={onDownloadGraph}
      >
        <Icon
          name={
            siteDetailsLoading ? IconName.SpinnerSpinning : IconName.Download2
          }
          style={{ fontSize: "1.5rem" }}
        />
      </button>
    </div>
  );
}

export { CustomElementMenu };
