import PropTypes from "prop-types";
import React from "react";
import { Icon } from "../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../BuildingBlocks/Icon/types";
import { DummyLink } from "../../DummyLink/DummyLink";
import "./EdgePopup.scss";
import { SourceTargetButton } from "./SourceTargetButton/SourceTargetButton";

interface EdgePopupProps {
  x: number;
  y: number;
  edgeId: number;
  userHasNoAccess?: boolean;
  showSourceTargetButtons?: boolean;
  onUpdateMeteringEdge?: (edgeId: string, isSource: boolean) => void;
  setShowNotAccessibleModal?: (show: boolean) => void;
  onClickDelete: (edgeId: number) => void;
}

function EdgePopup({
  x,
  y,
  edgeId,
  showSourceTargetButtons,
  onClickDelete,
  setShowNotAccessibleModal,
  onUpdateMeteringEdge,
  userHasNoAccess
}: EdgePopupProps) {
  return (
    <div className="EdgePopup" style={{ top: y, left: x }}>
      <DeleteLink
        setShowNotAccessibleModal={setShowNotAccessibleModal}
        userHasNoAccess={userHasNoAccess}
        onClickDelete={() => onClickDelete(edgeId)}
      />
      {showSourceTargetButtons && onUpdateMeteringEdge && (
        <div className="source-target-buttons">
          <SourceTargetButton
            isSource
            onSave={() => onUpdateMeteringEdge(edgeId.toString(), true)}
          />
          <SourceTargetButton
            onSave={() => onUpdateMeteringEdge(edgeId.toString(), false)}
          />
        </div>
      )}
    </div>
  );
}

interface DeleteLinkProps {
  onClickDelete: () => void;
  userHasNoAccess?: boolean;
  setShowNotAccessibleModal?: (show: boolean) => void;
}

function DeleteLink({
  onClickDelete,
  userHasNoAccess,
  setShowNotAccessibleModal
}: DeleteLinkProps) {
  return (
    <div className="delete-link">
      <span className="small">
        {!userHasNoAccess ? (
          <DummyLink onClick={onClickDelete}>
            <Icon className="m--font-danger" name={IconName.Trash} /> Verbindung
            löschen
          </DummyLink>
        ) : (
          <DummyLink
            onClick={() => {
              if (setShowNotAccessibleModal) {
                setShowNotAccessibleModal(true);
              }
            }}
          >
            <Icon className="m--font-danger" name={IconName.Trash} />{" "}
            <span className="delete-disabled">Verbindung löschen</span>
          </DummyLink>
        )}
      </span>
    </div>
  );
}

EdgePopup.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  edgeId: PropTypes.number,
  onClickDelete: PropTypes.func
};

export { EdgePopup };
