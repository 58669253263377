import React from "react";

import { Alert, AlertColor } from "../../Alert/Alert";
import { FormField } from "../../BuildingBlocks/Forms/FormField/FormField";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../../Buttons/Button/Button";
import { DummyLink } from "../../DummyLink/DummyLink";

interface EmailConfirmationModalProps {
  isOpen: boolean;
  otp: string;
  error: string | Array<string> | null;
  onToggle: () => void;
  onOtpChange: (value: string) => void;
  onOtpResend: () => void;
  onAuthMethodConfirm: () => void;
}

function EmailConfirmationModal({
  isOpen,
  otp,
  error,
  onToggle,
  onOtpChange,
  onOtpResend,
  onAuthMethodConfirm
}: EmailConfirmationModalProps) {
  return (
    <Modal fade={false} isOpen={isOpen} toggle={onToggle}>
      <ModalHeader toggle={onToggle}>Aktivierung bestätigen</ModalHeader>
      <ModalBody scrollable>
        <div>
          <p>
            Sie haben die E-Mail-Option für die 2-Faktor-Authentifizierung
            gewählt. Wir haben Ihnen einen Bestätigungscode an Ihre registrierte
            E-Mail-Adresse gesendet. Bitte geben Sie diesen Code im folgenden
            Feld ein, um den Prozess abzuschließen und Ihre
            2-Faktor-Authentifizierung zu aktivieren.
          </p>
        </div>
        <FormField
          label="Code"
          placeholder="XXXXXX"
          required
          type="text"
          value={otp}
          onChange={(e) => onOtpChange(e.target.value)}
        />

        {error && (
          <Alert color={AlertColor.Danger}>
            <p>{error}</p>
          </Alert>
        )}

        <DummyLink onClick={onOtpResend}>Senden Sie den Code erneut</DummyLink>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onToggle}>
          Schließen
        </Button>
        <Button color="primary" onClick={onAuthMethodConfirm}>
          Speichern
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export { EmailConfirmationModal, EmailConfirmationModalProps };
