import React, { useState } from "react";
import { useShouldShowStaffView } from "../../../StaffViewToggle/useShouldShowStaffView";
import type { StepStatus } from "../../ChangeProcess.types";
import { OperatorChangeProcess } from "../OperatorChangeProcess/OperatorChangeProcess";
import type {
  OperatorChange,
  OperatorChangeSteps
} from "../OperatorChanges.types";
import "./OperatorChangeTaskList.scss";

interface OperatorChangeTaskListProps {
  operatorChanges: Array<OperatorChange>;
  projectId: string | undefined;
  onUpdateProcess: (operatorChange: OperatorChange) => Promise<void>;
}

function OperatorChangeTaskList({
  operatorChanges,
  projectId,
  onUpdateProcess
}: OperatorChangeTaskListProps) {
  const [isUpdating, setIsUpdating] = useState(false);
  const shouldShowStaffView = useShouldShowStaffView();

  function handleUpdateStepStatus(
    operatorChange: OperatorChange,
    step: keyof OperatorChangeSteps,
    status: StepStatus
  ) {
    setIsUpdating(true);
    onUpdateProcess({
      ...operatorChange,
      steps: {
        ...operatorChange.steps,
        [step]: { ...operatorChange.steps[step], done: status }
      }
    }).finally(() => setIsUpdating(false));
  }

  return operatorChanges.map((operatorChange, index) => (
    <OperatorChangeProcess
      isUpdating={isUpdating}
      key={operatorChange.id}
      operatorChange={operatorChange}
      projectId={projectId}
      shouldShowStaffView={shouldShowStaffView}
      toggleInitial={index === 0}
      onUpdateStepStatus={handleUpdateStepStatus}
    />
  ));
}

export { OperatorChangeTaskList, OperatorChangeTaskListProps };
