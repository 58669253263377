import { chunk } from "lodash";
import { useEffect } from "react";

export function usePagedContent<T>({
  items,
  pageSize,
  page,
  onChangePage
}: {
  items: Array<T>;
  pageSize: number;
  page: number;
  onChangePage: (page: number) => void;
}) {
  const pagedItems = chunk(items ?? [], pageSize);

  useEffect(() => {
    if (pagedItems.length > 0 && page > pagedItems.length) {
      onChangePage(pagedItems.length);
    }
  }, [onChangePage, page, pageSize, pagedItems.length]);

  return {
    pagedItems
  };
}
