import React, { useEffect, useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import {
  Modal,
  ModalBody,
  ModalHeader
} from "../../../../BuildingBlocks/Layout/Modal/Modal";
import { LoadOrError } from "../../../../LoadOrError/LoadOrError";
import { useParagraph6ErzeugerAnteil } from "../../../hooks/useParagraph6ErzeugerAnteil";
import { useParagraph6ErzeugerAnteilMutations } from "../../../hooks/useParagraph6ErzeugerAnteilMutations";
import { useParagraph6UnassignedGenerators } from "../../../hooks/useParagraph6UnassignedGenerators";
import type { paragraph6ErzeugerAnteilPayload } from "../../../Paragraph6.types";
import { Paragraph6GeneratorForm } from "./Paragraph6GeneratorForm/Paragraph6GeneratorForm";

interface Paragraph6GeneratorModalProps {
  variantId: number;
  contractId: string;
  generatorShareId?: number;
  isOpen?: boolean;
  onClose: () => void;
}

function Paragraph6GeneratorModal({
  variantId,
  contractId,
  generatorShareId,
  isOpen,
  onClose
}: Paragraph6GeneratorModalProps) {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const [responseError, setResponseError] = useState<Error>();

  const editMode = !!generatorShareId;

  const defaultparagraph6ErzeugerAnteil = {
    contract: contractId
  } as const satisfies Pick<paragraph6ErzeugerAnteilPayload, "contract">;

  const {
    paragraph6ErzeugerAnteil,
    isFetching: paragraph6ErzeugerAnteilFetching
  } = useParagraph6ErzeugerAnteil(contractId, generatorShareId, {
    refetchOnWindowFocus: false
  });
  const {
    paragraph6ErzeugerAnteilCreateMutation,
    paragraph6ErzeugerAnteilEditMutation
  } = useParagraph6ErzeugerAnteilMutations(variantId, contractId);
  const isMutating =
    paragraph6ErzeugerAnteilCreateMutation.isPending ||
    paragraph6ErzeugerAnteilEditMutation.isPending;

  const {
    paragraph6UnassignedGenerators,
    isLoading: paragraph6UnassignedGeneratorsLoading
  } = useParagraph6UnassignedGenerators(contractId);

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  const onSubmit: SubmitHandler<paragraph6ErzeugerAnteilPayload> = (data) => {
    const mutation = editMode
      ? paragraph6ErzeugerAnteilEditMutation
      : paragraph6ErzeugerAnteilCreateMutation;

    mutation.mutate(data, {
      onSuccess: () => {
        onClose();
      },
      onError: (error) => {
        setResponseError(error);
      }
    });
  };

  return (
    <Modal isOpen={isModalOpen} size="xl">
      <ModalHeader toggle={onClose}>
        Erzeuger {editMode ? "bearbeiten" : "hinzufügen"}
      </ModalHeader>
      <ModalBody>
        <LoadOrError
          loading={
            (editMode &&
              (paragraph6ErzeugerAnteilFetching ||
                paragraph6UnassignedGeneratorsLoading)) ||
            paragraph6UnassignedGeneratorsLoading
          }
          loadingMessage="Erzeuger wird geladen ..."
        >
          <Paragraph6GeneratorForm
            editMode={editMode}
            isSubmitting={isMutating}
            paragraph6ErzeugerAnteil={
              paragraph6ErzeugerAnteil || defaultparagraph6ErzeugerAnteil
            }
            paragraph6UnassignedGenerators={paragraph6UnassignedGenerators}
            submissionError={responseError}
            onCancel={onClose}
            onSubmit={onSubmit}
          />
        </LoadOrError>
      </ModalBody>
    </Modal>
  );
}

export { Paragraph6GeneratorModal, Paragraph6GeneratorModalProps };
