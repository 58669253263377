import { useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect, useState } from "react";
import {
  Outlet,
  Route,
  Routes,
  generatePath,
  useMatch,
  useNavigate,
  useParams,
  useResolvedPath
} from "react-router-dom";
import { ReactFlowProvider } from "reactflow";
import { Col, Row } from "reactstrap";
import { useCanAssessManagerSiteFinancially } from "../../hooks/useCanAssessManagerSiteFinanciallyQuery";
import { useConfiguratorComponents } from "../../hooks/useConfiguratorComponents";
import { useEnergyWizard } from "../../hooks/useEnergyWizard";
import { useSiteCategories } from "../../hooks/useSiteCategories";
import { useSiteWizard } from "../../hooks/useSiteWizard";
import { ROUTES } from "../../routes";
import urls from "../../urls";
import type { Generator, Meter } from "../../utils/backend-types";
import {
  OBJECT_URL_NAMES_TO_OBJECT_NAMES,
  ObjectName,
  ObjectUrlName,
  Product,
  StructureDiagramObjectName
} from "../../utils/enums";
import { showToast } from "../../utils/toast";
import { ComponentEditWizard } from "../ComponentListContainer/ComponentList/ComponentEditWizard/ComponentEditWizard";
import { ConsumptionShareModal } from "../ConsumptionShareModal/ConsumptionShareModal";
import DeliveryConstraintsModal from "../DeliveryConstraintsModal";
import { ChoicesDictContextProvider as CreatableDropdownContextProvider } from "../DynamicForm/FormItems/FormField/CreatableDropdownField/CreatableDropdownField";
import { EdaVisualizationModal } from "../EdaVisualizationModal/EdaVisualizationModal";
import { UpdateAnlageStromstgModal } from "../GeneratorWizard/AnlageStromstgFlow/UpdateAnlageStromstgModal";
import { ChangeProcessModal } from "../SiteTasks/ChangeProcessModal";
import { OnboardingMailPage } from "../SiteTasks/OnboardingWizard/OnboardingMailPage/OnboardingMailPage";
import type { OperatorChange } from "../SiteTasks/OperatorChanges/OperatorChanges.types";
import { SiteTasks } from "../SiteTasks/SiteTasks";
import type { TenantChange } from "../SiteTasks/TenantChanges/TenantChanges.types";
import {
  useChangeMutation,
  useChangeProcesses
} from "../SiteTasks/useChangeProcesses";
import { useShouldShowStaffView } from "../StaffViewToggle/useShouldShowStaffView";
import { VariantObjectWizard } from "../VariantObjectWizard/VariantObjectWizard";
import { ComponentsPortlet } from "./ComponentsPortlet/ComponentsPortlet";
import { NotAccessableModal } from "./NotAccessableModal/NotAccessableModal";
import { SiteAddress } from "./SiteAddress";
import "./StructureView.scss";
import { StructureViewButtons } from "./StructureViewButtons/StructureViewButtons";
import { StructureViewDiagramContainer } from "./StructureViewDiagramContainer/StructureViewDiagramContainer";
import { StructureViewModeContextProvider } from "./StructureViewModeContext";
import { UserAccessContext } from "./UserAccessContext";

export enum ViewMode {
  PvSimulationForm = "pv_simulation_form",
  MissingDataForm = "missing_data_form"
}

export interface StructureViewProps {
  variantId: number;
  projectId: string;
  siteId: number;
  viewMode?: ViewMode;
  componentsPortletTitle?: string;
  componentsPortletChildProps?; // todo ts: set when DynamicForm is converted to typescript
  context: Product;
}

function StructureView({
  variantId,
  projectId,
  siteId,
  viewMode,
  componentsPortletTitle,
  componentsPortletChildProps,
  context: product
}: StructureViewProps) {
  const [isDeliveryConstraintsModalOpen, setIsDeliveryConstraintsModalOpen] =
    useState(false);
  const [graphCoordinates, setGraphCoordinates] = useState({
    x: 0,
    y: 0
  });

  const [showNotAccessibleModal, setShowNotAccessibleModal] = useState(false);

  const { siteCategories } = useSiteCategories(projectId, {
    enabled: product === Product.Manager
  });
  const categoriesForSite = siteCategories?.find((site) => site.id === siteId);
  const siteIsFullFeedin = !!categoriesForSite?.is_full_feedin;
  const isPartialFeedin = categoriesForSite?.is_partial_feedin_site ?? false;

  const { data: generators } = useConfiguratorComponents<Generator>(
    ObjectName.Generator,
    siteId
  );
  const { data: consumers } = useConfiguratorComponents(
    ObjectName.Consumer,
    siteId
  );
  const { data: storages } = useConfiguratorComponents(
    ObjectName.Storage,
    siteId
  );
  const { data: gasConnections } = useConfiguratorComponents(
    ObjectName.GasConnection,
    siteId
  );
  const { data: persons } = useConfiguratorComponents(
    ObjectName.Person,
    variantId
  );
  const { data: connections } = useConfiguratorComponents(
    ObjectName.Connection,
    siteId
  );
  const {
    data: meters,
    invalidateConfiguratorComponents: invalidateMeterData
  } = useConfiguratorComponents<Meter>(ObjectName.Meter, siteId);
  const { data: ghostNodes } = useConfiguratorComponents(
    ObjectName.GhostNode,
    siteId
  );
  const {
    isLoading: currentUserCanAssessManagerSiteFinanciallyIsLoading,
    error: currentUserCanAssessManagerSiteFinanciallyError,
    currentUserCanAssessManagerSiteFinancially
  } = useCanAssessManagerSiteFinancially();
  const { siteWizard } = useSiteWizard(siteId, {
    enabled: product === Product.Manager,
    refetchOnWindowFocus: true
  });

  const { energyWizard } = useEnergyWizard(siteId, {
    enabled: product === Product.Manager,
    refetchOnWindowFocus: true
  });

  const { data: operatorChanges } = useChangeProcesses<OperatorChange>(
    "operatorChanges",
    urls.api.mieterstromProcesses.listOperatorChanges(siteId),
    siteId,
    {
      enabled:
        product === Product.Manager &&
        categoriesForSite?.is_partial_feedin_site,
      refetchOnWindowFocus: true
    }
  );
  const operatorChangesMutation = useChangeMutation<OperatorChange>(
    siteId,
    urls.api.mieterstromProcesses.updateOperatorChanges(siteId),
    "operatorChanges"
  );

  const { data: tenantChanges } = useChangeProcesses<TenantChange>(
    "tenantChanges",
    urls.api.mieterstromProcesses.listTenantChanges(siteId),
    siteId,
    {
      enabled:
        product === Product.Manager &&
        categoriesForSite?.is_partial_feedin_site,
      refetchOnWindowFocus: true
    }
  );
  const tenantChangesMutation = useChangeMutation<TenantChange>(
    siteId,
    urls.api.mieterstromProcesses.updateTenantChanges(siteId),
    "tenantChanges"
  );

  const edaVisualizationModalResolvedPath = useResolvedPath("./messkonzept");
  const edaVisualizationModalResolvedPathV2 =
    useResolvedPath("./messkonzept-v2");
  const isEdaVisualizationModalOpen = !!useMatch({
    path: edaVisualizationModalResolvedPath.pathname,
    end: false
  });
  const isEdaVisualizationV2ModalOpen = !!useMatch({
    path: edaVisualizationModalResolvedPathV2.pathname,
    end: false
  });

  const shouldShowStaffView = useShouldShowStaffView();
  const configurationFinished =
    siteWizard && siteWizard.enabled && siteWizard?.phases.configuration.done;
  const msbAuthorityFinished =
    siteWizard && siteWizard.enabled && siteWizard?.phases.msbauthority.done;

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (
      generators &&
      consumers &&
      storages &&
      persons &&
      connections &&
      meters &&
      ghostNodes &&
      gasConnections
    ) {
      queryClient.invalidateQueries({
        queryKey: ["sketch-data", { siteId }]
      });
      queryClient.invalidateQueries({
        queryKey: ["premium-sketch-data", { siteId }]
      });
      queryClient.invalidateQueries({
        queryKey: ["premium-metering-concept-sketch-data", { siteId }]
      });
    }
  }, [
    generators,
    consumers,
    storages,
    persons,
    connections,
    meters,
    ghostNodes,
    gasConnections,
    queryClient,
    siteId
  ]);

  const handleShowNotAccessibleModal = useCallback((show: boolean) => {
    setShowNotAccessibleModal(show);
  }, []);

  function toggleDeliveryConstraintsModal() {
    setIsDeliveryConstraintsModalOpen(!isDeliveryConstraintsModalOpen);
  }

  function toggleEdaVisualizationModal(arg?: { isV2: boolean }) {
    if (isEdaVisualizationModalOpen || isEdaVisualizationV2ModalOpen) {
      navigate("./");
    } else if (arg?.isV2) {
      navigate("./messkonzept-v2");
    } else {
      navigate("./messkonzept");
    }
  }

  function toggleMissingDataModal() {
    navigate("./");
  }

  function toggleConsumptionShareModal() {
    navigate("./");
  }

  async function onSiteWizardUpdated() {
    await queryClient.invalidateQueries({
      queryKey: ["siteWizard", { siteId }]
    });
  }

  function onEnergyWizardUpdated() {
    queryClient.invalidateQueries({
      queryKey: ["energyWizard", { siteId }]
    });
  }

  function invalidateChangeProcesses() {
    queryClient.invalidateQueries({
      queryKey: ["operatorChanges", { siteId }]
    });
    queryClient.invalidateQueries({
      queryKey: ["tenantChanges", { siteId }]
    });
  }

  function invalidatePremiumMeteringConceptSketchData() {
    queryClient.invalidateQueries({
      queryKey: ["premium-metering-concept-sketch-data", { siteId }]
    });
  }

  function onComponentUpdated() {
    onEnergyWizardUpdated();
    onSiteWizardUpdated();
    invalidateChangeProcesses();
    invalidatePremiumMeteringConceptSketchData();
  }

  function onCloseNotAccessable() {
    handleShowNotAccessibleModal(false);
  }

  function handleUpdateOperatorChangeProcess(operatorChange: OperatorChange) {
    return operatorChangesMutation
      .mutateAsync(operatorChange)
      .then(() => Promise.resolve())
      .catch((e) => {
        showToast(
          "error",
          "Die Aktualisierung dieses Prozesses konnte nicht abgeschlossen werden. Bitte versuchen Sie es später erneut."
        );
        return Promise.reject(e);
      });
  }

  function handleUpdateTenantChangeProcess(tenantChange: TenantChange) {
    return tenantChangesMutation
      .mutateAsync(tenantChange)
      .then(() => Promise.resolve())
      .catch((e) => {
        showToast(
          "error",
          "Die Aktualisierung dieses Prozesses konnte nicht abgeschlossen werden. Bitte versuchen Sie es später erneut."
        );
        return Promise.reject(e);
      });
  }

  const openModalFunction = (arg?: { isV2: boolean }) =>
    product === Product.Analyzer
      ? toggleDeliveryConstraintsModal()
      : toggleEdaVisualizationModal(arg);

  const userCanCreateAndDelete: Record<string, boolean> = Object.values(
    StructureDiagramObjectName
  ).reduce<Record<string, boolean>>((dict, name) => {
    let canCreateAndDelete = shouldShowStaffView || !configurationFinished;
    if (
      name === ObjectName.MarketLocation ||
      name === ObjectName.MeteringLocation
    ) {
      canCreateAndDelete = shouldShowStaffView || !msbAuthorityFinished;
    }
    return { ...dict, [name]: canCreateAndDelete };
  }, {});

  const safeCurrentUserCanAssessManagerSiteFinancially =
    currentUserCanAssessManagerSiteFinanciallyIsLoading ||
    currentUserCanAssessManagerSiteFinanciallyError
      ? false
      : currentUserCanAssessManagerSiteFinancially;
  const enableStructureViewDiagramLoad =
    !!generators &&
    !!consumers &&
    !!storages &&
    !!persons &&
    !!connections &&
    !!meters &&
    !!ghostNodes &&
    !!gasConnections;

  const configurationPageDetails = siteWizard?.phases.configuration;
  const regulatoryPageDetails = siteWizard?.phases.regulatory;
  const vollmachtPageDetails = siteWizard?.phases.msbauthority;
  const invoicesPageDetails = siteWizard?.phases.invoices;
  const meteringConceptPageDetails = siteWizard?.phases.meteringConcept;

  const meterPageDetails = energyWizard?.phases.meters;
  return (
    <UserAccessContext.Provider value={userCanCreateAndDelete}>
      <StructureViewModeContextProvider>
        <Row className="StructureView">
          <ReactFlowProvider>
            <Col>
              {isPartialFeedin && <SiteAddress siteId={siteId} />}
              <Row>
                <Col className="structure-view-diagram-column">
                  <StructureViewDiagramContainer
                    enableStructureViewDiagramLoad={
                      enableStructureViewDiagramLoad
                    }
                    graphCoordinates={graphCoordinates}
                    handleShowNotAccessibleModal={handleShowNotAccessibleModal}
                    hasGasConnection={
                      gasConnections && gasConnections.length > 0 ? true : false
                    }
                    invalidateMeterData={invalidateMeterData}
                    isPartialFeedin={isPartialFeedin}
                    isPPA={categoriesForSite?.is_ppaaas ?? false}
                    isPremium={categoriesForSite?.is_premium ?? false}
                    meters={meters}
                    product={product}
                    projectId={projectId}
                    setGraphCoordinates={setGraphCoordinates}
                    siteId={siteId}
                    variantId={variantId}
                  />
                </Col>

                {siteWizard &&
                  energyWizard &&
                  (siteWizard.enabled || energyWizard.enabled) && (
                    <SiteTasks
                      energyWizard={energyWizard}
                      operatorChanges={operatorChanges ?? []}
                      projectId={projectId}
                      siteIsFullFeedin={siteIsFullFeedin}
                      siteWizard={siteWizard}
                      tenantChanges={tenantChanges ?? []}
                      onEnergyWizardUpdated={onEnergyWizardUpdated}
                      onSiteWizardUpdated={onSiteWizardUpdated}
                      onUpdateOperatorChangeProcess={
                        handleUpdateOperatorChangeProcess
                      }
                      onUpdateTenantChangeProcess={
                        handleUpdateTenantChangeProcess
                      }
                    />
                  )}
              </Row>
              <Row style={{ marginTop: "1rem" }}>
                <Col>
                  <StructureViewButtons
                    isPartialFeedin={isPartialFeedin}
                    openModal={openModalFunction}
                    product={product}
                    projectId={projectId}
                    shouldShowStaffView={shouldShowStaffView}
                    variantId={variantId}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <ComponentsPortlet
                    childProps={componentsPortletChildProps}
                    graphCoordinates={graphCoordinates}
                    initialViewMode={viewMode}
                    product={product}
                    projectId={projectId}
                    setShowNotAccessibleModal={handleShowNotAccessibleModal}
                    siteId={siteId}
                    title={componentsPortletTitle}
                    variantId={variantId}
                  />
                </Col>
              </Row>
            </Col>
            {isDeliveryConstraintsModalOpen && (
              <DeliveryConstraintsModal
                siteId={siteId}
                onCloseDeliveryConstraintsModal={toggleDeliveryConstraintsModal}
              />
            )}
            {showNotAccessibleModal && (
              <NotAccessableModal onClose={onCloseNotAccessable} />
            )}
            <Routes>
              <Route
                element={
                  <EdaVisualizationModal
                    projectId={projectId}
                    siteId={siteId}
                    onCloseModal={() =>
                      toggleEdaVisualizationModal({ isV2: false })
                    }
                  />
                }
                path="messkonzept/"
              />
              <Route
                element={
                  <EdaVisualizationModal
                    isV2
                    projectId={projectId}
                    siteId={siteId}
                    onCloseModal={() =>
                      toggleEdaVisualizationModal({ isV2: true })
                    }
                  />
                }
                path="messkonzept-v2/"
              />
              <Route
                element={
                  <ConsumptionShareModal
                    invalidateMeterData={invalidateMeterData}
                    meters={meters}
                    onCloseModal={toggleConsumptionShareModal}
                  />
                }
                path="verbrauchsanteile/:componentId"
              />
              <Route path=":objectUrlName">
                <Route element={<Outlet />} index />
                <Route path=":componentId">
                  <Route
                    element={
                      <UpdateAnlageStromstgModal
                        generators={generators}
                        isOpen
                        siteId={siteId}
                        variantId={variantId}
                      />
                    }
                    path="anlage-stromstg-aendern"
                  />
                  <Route
                    element={
                      <ComponentEditRoute
                        currentUserCanAssessManagerSiteFinancially={
                          safeCurrentUserCanAssessManagerSiteFinancially
                        }
                        doAfterSave={onComponentUpdated}
                        product={product}
                        siteId={siteId}
                        variantId={variantId}
                      />
                    }
                    index
                  />
                  <Route
                    element={
                      <ComponentEditRoute
                        currentUserCanAssessManagerSiteFinancially={
                          safeCurrentUserCanAssessManagerSiteFinancially
                        }
                        doAfterSave={onComponentUpdated}
                        product={product}
                        siteId={siteId}
                        variantId={variantId}
                      />
                    }
                    path="*"
                  />
                </Route>
              </Route>
              {siteWizard && (
                <>
                  <Route
                    element={
                      <OnboardingMailPage
                        details={configurationPageDetails}
                        phaseKey="configuration"
                        onClose={toggleMissingDataModal}
                      />
                    }
                    path="konfiguration"
                  />
                  <Route
                    element={
                      <OnboardingMailPage
                        details={regulatoryPageDetails}
                        phaseKey="regulatory"
                        onClose={toggleMissingDataModal}
                      />
                    }
                    path="regulatorischeDaten"
                  />
                  <Route
                    element={
                      <OnboardingMailPage
                        details={meteringConceptPageDetails}
                        phaseKey="meteringConcept"
                        onClose={toggleMissingDataModal}
                      />
                    }
                    path="messkonzeptDaten"
                  />
                  <Route
                    element={
                      <OnboardingMailPage
                        details={vollmachtPageDetails}
                        phaseKey="msbauthority"
                        onClose={toggleMissingDataModal}
                      />
                    }
                    path="vollmacht"
                  />
                  <Route
                    element={
                      <OnboardingMailPage
                        details={invoicesPageDetails}
                        phaseKey="invoices"
                        onClose={toggleMissingDataModal}
                      />
                    }
                    path="vertraege"
                  />
                  <Route
                    element={
                      <OnboardingMailPage
                        details={meterPageDetails}
                        phaseKey="meters"
                        onClose={toggleMissingDataModal}
                      />
                    }
                    path="historischeMessdaten"
                  />
                  <Route
                    element={
                      <ChangeProcessModal
                        changeProcesses={operatorChanges || []}
                        isOpen
                        step="stepNewData"
                        onClose={toggleMissingDataModal}
                      />
                    }
                    path="betreiberwechsel/unternehmensdaten/:processId"
                  />
                  <Route
                    element={
                      <ChangeProcessModal
                        changeProcesses={operatorChanges || []}
                        isOpen
                        step="stepOldContract"
                        onClose={toggleMissingDataModal}
                      />
                    }
                    path="betreiberwechsel/vertragsdaten/:processId"
                  />
                  <Route
                    element={
                      <ChangeProcessModal
                        changeProcesses={tenantChanges || []}
                        isOpen
                        step="stepParticipation"
                        onClose={toggleMissingDataModal}
                      />
                    }
                    path="betreiberwechsel/teilnahme/:processId"
                  />
                  <Route
                    element={
                      <ChangeProcessModal
                        changeProcesses={tenantChanges || []}
                        isOpen
                        step="stepParticipation"
                        onClose={toggleMissingDataModal}
                      />
                    }
                    path="mieterwechsel/teilnahme/:processId"
                  />
                  <Route
                    element={
                      <ChangeProcessModal
                        changeProcesses={tenantChanges || []}
                        isOpen
                        step="stepNewData"
                        onClose={toggleMissingDataModal}
                      />
                    }
                    path="mieterwechsel/unternehmensdaten/:processId"
                  />
                  <Route
                    element={
                      <ChangeProcessModal
                        changeProcesses={tenantChanges || []}
                        isOpen
                        step="stepOldContract"
                        onClose={toggleMissingDataModal}
                      />
                    }
                    path="mieterwechsel/vertragsdaten/:processId"
                  />
                </>
              )}
            </Routes>
          </ReactFlowProvider>
        </Row>
      </StructureViewModeContextProvider>
    </UserAccessContext.Provider>
  );
}

const StructureViewAndCreatableDropdownContextProvider = (
  props: StructureViewProps
) => (
  <CreatableDropdownContextProvider>
    <StructureView {...props} />
  </CreatableDropdownContextProvider>
);

interface ComponentEditWizardProps {
  product: Product;
  siteId: number;
  variantId: number;
  currentUserCanAssessManagerSiteFinancially?: boolean;
  doAfterSave?: () => void;
}

function ComponentEditRoute({
  product,
  siteId,
  variantId,
  currentUserCanAssessManagerSiteFinancially,
  doAfterSave
}: ComponentEditWizardProps) {
  const navigate = useNavigate();
  const { componentId, objectUrlName } = useParams();
  const parentObjectPath = useParentObjectPath();

  const objectName =
    objectUrlName &&
    Object.values(ObjectUrlName).includes(objectUrlName as ObjectUrlName)
      ? OBJECT_URL_NAMES_TO_OBJECT_NAMES[objectUrlName as ObjectUrlName]
      : undefined;

  function handleCloseComponentEditWizard() {
    navigate(parentObjectPath ?? "../../");
  }

  if (
    (objectName === ObjectName.GhostNode ||
      objectName === ObjectName.MeteringLocation ||
      objectName === ObjectName.MarketLocation) &&
    typeof componentId !== "undefined"
  ) {
    return (
      <VariantObjectWizard
        isOpen
        mode="edit"
        objectDisplayName={objectName}
        objectId={Number(componentId)}
        objectName={objectName}
        product={product}
        siteId={siteId}
        variantId={variantId}
        onToggle={handleCloseComponentEditWizard}
      />
    );
  }

  return (
    <ComponentEditWizard
      currentUserCanAssessManagerSiteFinancially={
        currentUserCanAssessManagerSiteFinancially
      }
      doAfterSave={doAfterSave}
      isOpen
      product={product}
      siteId={siteId}
      variantId={variantId}
      onClose={handleCloseComponentEditWizard}
    />
  );
}

function useParentObjectPath() {
  const { objectUrlName } = useParams();
  const managerMatch = useMatch<"projectId" | "siteId", string>(
    `${ROUTES.managerVariantStructure}/*`
  );
  const analyzerMatch = useMatch<"projectId" | "variantId", string>(
    `${ROUTES.analyzerVariantStructure}/*`
  );
  const evaluationMatch = useMatch<"projectId" | "variantId", string>(
    `${ROUTES.evaluation}/*`
  );
  let parentObjectPath: string | null = null;

  if (managerMatch && !!objectUrlName) {
    parentObjectPath = generatePath(
      `${ROUTES.managerVariantStructure}:objectUrlName`,
      {
        projectId: managerMatch.params.projectId ?? null,
        siteId: managerMatch.params.siteId ?? null,
        objectUrlName: objectUrlName
      }
    );
  } else if (analyzerMatch && !!objectUrlName) {
    parentObjectPath = generatePath(
      `${ROUTES.analyzerVariantStructure}:objectUrlName`,
      {
        projectId: analyzerMatch.params.projectId ?? null,
        variantId: analyzerMatch.params.variantId ?? null,
        objectUrlName: objectUrlName
      }
    );
  } else if (evaluationMatch && !!objectUrlName) {
    parentObjectPath = generatePath(
      `${ROUTES.analyzerVariantStructure}:objectUrlName`,
      {
        projectId: evaluationMatch.params.projectId ?? null,
        variantId: evaluationMatch.params.variantId ?? null,
        objectUrlName: objectUrlName
      }
    );
  }

  return parentObjectPath;
}

export { StructureViewAndCreatableDropdownContextProvider as StructureView };
