import { type Todo } from "../../../utils/backend-types";

export function handleChangeDueDate(
  todos: Array<Todo>,
  updateTodoDueDate: (editedTodo: Todo) => void,
  todoId: number,
  dueDate: string | null
) {
  const todo = todos.find((todo) => todo.id === todoId);

  if (todo && dueDate) {
    updateTodoDueDate({
      ...todo,
      dueDate: dueDate
    });
  }
}
