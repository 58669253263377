import classNames from "classnames";
import React, { useState } from "react";
import { Icon } from "../Icon/Icon";
import type { IconName } from "../Icon/types";
import "./Portlet.scss";

interface PortletProps {
  title?: string | React.ReactNode;
  className?: string;
  bodyClassName?: string;
  style?: React.CSSProperties;
  noPadding?: boolean;
  icon?: IconName;
  helpText?: string;
  navItems?: Array<React.ReactNode>;
  id?: string;
  footer?: React.ReactNode;
  toggle?: boolean;
  toggleIconClosed?: IconName;
  toggleIconOpen?: IconName;
  toggleInitial?: boolean;
  children: React.ReactNode | Array<React.ReactNode>;
}

function Portlet(
  {
    title,
    className,
    style,
    bodyClassName,
    noPadding,
    icon,
    helpText,
    navItems = [],
    id,
    footer,
    toggle = false,
    toggleIconOpen,
    toggleIconClosed,
    toggleInitial,
    children
  }: PortletProps,
  ref: React.RefObject<HTMLDivElement>
) {
  const initialOpen = toggle ? (toggleInitial ?? false) : true;
  const [open, setOpen] = useState(initialOpen);
  const togglePortlet = () => setOpen((prevOpen) => !prevOpen);
  const headerIcon = toggle ? (open ? toggleIconOpen : toggleIconClosed) : icon;
  const displayPortlet = toggle ? (open ? undefined : "none") : undefined;

  return (
    <div
      className={classNames("m-portlet", className)}
      data-portlet={toggle}
      id={id}
      ref={ref}
      style={style}
    >
      <PortletHeader
        headerIcon={headerIcon}
        helpText={helpText}
        navItems={navItems}
        title={title}
        toggle={toggle}
        togglePortlet={togglePortlet}
      />
      <div
        className={classNames(
          bodyClassName,
          "m-portlet__body",
          noPadding ? "m-portlet__body--no-padding" : false
        )}
        style={{ display: displayPortlet }}
      >
        {children}
      </div>
      {footer && (
        <div
          className={classNames(
            "m-portlet__foot",
            noPadding ? "m-portlet__foot--no-padding" : false
          )}
        >
          {footer}
        </div>
      )}
    </div>
  );
}

interface PortletHeaderProps
  extends Pick<PortletProps, "title" | "toggle" | "helpText"> {
  togglePortlet: React.MouseEventHandler;
  headerIcon?: IconName;
  navItems?: Array<React.ReactNode>;
}

function PortletHeader({
  title,
  toggle,
  togglePortlet,
  headerIcon,
  helpText,
  navItems
}: PortletHeaderProps) {
  if (!title) {
    return null;
  }

  return (
    <header
      className={classNames(
        "m-portlet__head",
        toggle ? "portlet-toggle" : null
      )}
      onClick={toggle ? togglePortlet : undefined}
    >
      <div className="m-portlet__head-caption">
        <div className="m-portlet__head-title">
          {headerIcon && (
            <span className="m-portlet__head-icon">
              <Icon
                name={headerIcon}
                tooltipPlace="top"
                tooltipText={helpText}
              />
            </span>
          )}
          <h3 className="m-portlet__head-text">{title}</h3>
        </div>
      </div>
      {navItems && navItems.length > 0 && (
        <div className="m-portlet__head-tools">
          <ul className="m-portlet__nav">
            {navItems.map((item, ix) => (
              <li className="m-portlet__nav-item" key={ix}>
                {item}
              </li>
            ))}
          </ul>
        </div>
      )}
    </header>
  );
}

const PortletForwardRef = React.forwardRef(Portlet);

export { PortletForwardRef as Portlet, PortletProps };
