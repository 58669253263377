import type { FictionalEnergyValueResponse } from "../EnergyDataUploadPage/FictionalEnergyValueManager/FictionalEnergyValueManager";

/** TypeGuard for FictionalEnergyValueResponse */
export function isFictionalEnergyValueResponseUpdatedFormValues(
  potentialFictionalEnergyValue: unknown
): potentialFictionalEnergyValue is FictionalEnergyValueResponse {
  return (
    !!potentialFictionalEnergyValue &&
    Object.hasOwn(potentialFictionalEnergyValue, "generator") &&
    Object.hasOwn(potentialFictionalEnergyValue, "firstDate") &&
    Object.hasOwn(potentialFictionalEnergyValue, "lastDate") &&
    Object.hasOwn(
      potentialFictionalEnergyValue,
      "technischeNichtverfuegbarkeitKwh"
    ) &&
    Object.hasOwn(potentialFictionalEnergyValue, "redispatchAbregelungKwh") &&
    Object.hasOwn(
      potentialFictionalEnergyValue,
      "sonstigeAbschaltungOderDrosselungKwh"
    )
  );
}
