import React from "react";
import type { Column } from "react-table";
import { Badge } from "../../../../BuildingBlocks/Badge/Badge";
import type { TypedCellInfo } from "../../../../CustomReactTable/CustomReactTable.types";
import { getMspCategoryLabel } from "../../../utils/getMspCategoryLabel";
import type {
  MarketOrMeteringLocationOverview,
  LocationOverviewData
} from "../../LocationOverview.types";

function getMsbColumn() {
  return {
    Header: "MSB",
    id: "msb",
    accessor: (row) => row.singleOverviews,
    Cell: (
      cellInfo: TypedCellInfo<
        Array<MarketOrMeteringLocationOverview>,
        LocationOverviewData
      >
    ) => (
      <div className="msbs">
        {cellInfo.value.map((overview) => (
          <div className="sub-row" key={overview.location.id}>
            {overview.location.meteringServiceProviderName}
            <Badge color="primary">
              {getMspCategoryLabel(
                overview.location.meteringServiceProviderCategory
              )}
            </Badge>
          </div>
        ))}
      </div>
    ),
    sortable: false,
    minWidth: 350
  } satisfies Column<LocationOverviewData>;
}

export { getMsbColumn };
