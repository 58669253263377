import type { DateTime } from "luxon";
import React, { useState } from "react";
import { Row } from "reactstrap";
import urls from "../../urls";
import { backendDateOrDateTimeToLuxonDateTime } from "../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { luxonDateTimeToBackendDateOrDateTime } from "../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import EnergyFlowDatepicker from "./EnergyFlowDatepicker/EnergyFlowDatepicker";
import EnergyFlowDownload from "./EnergyFlowDownload/EnergyFlowDownload";
import EnergyFlowPlots from "./EnergyFlowPlots/EnergyFlowPlots";
import "./EnergyFlows.scss";

const DEFAULT_DATE = backendDateOrDateTimeToLuxonDateTime("2019-01-01");

interface EnergyFlowsProps {
  selectedVariantId: number;
  selectedDataUrls: Record<number, string>;
  formatForReport: boolean;
}

function EnergyFlows({
  selectedVariantId,
  selectedDataUrls,
  formatForReport
}: EnergyFlowsProps) {
  const [startDate, setStartDate] = useState(DEFAULT_DATE);
  const [endDate, setEndDate] = useState(DEFAULT_DATE);
  const [yearlyAverage, setYearlyAverage] = useState(true);

  function setSelectedDateRange(startDate: DateTime, endDate: DateTime) {
    setStartDate(startDate);
    setEndDate(endDate);
    setYearlyAverage(false);
  }

  function selectYearlyAverage() {
    setYearlyAverage(true);
  }

  const energyFlowUrlsWithTimespan = Object.keys(selectedDataUrls).reduce<
    Record<string, string>
  >((obj, variantId) => {
    if (yearlyAverage) {
      obj[variantId] = selectedDataUrls[variantId];
    } else {
      obj[variantId] = urls.api.meteringConceptEnergyFlowsTimespan(
        selectedDataUrls[variantId],
        luxonDateTimeToBackendDateOrDateTime(startDate, "ISO 8601"),
        luxonDateTimeToBackendDateOrDateTime(endDate, "ISO 8601")
      );
    }
    return obj;
  }, {});

  return (
    <div>
      <Row>
        <EnergyFlowDatepicker
          endDate={endDate}
          startDate={startDate}
          onSelectDateRange={setSelectedDateRange}
          onSelectYearlyAverage={selectYearlyAverage}
        />
        <EnergyFlowDownload selectedVariantId={selectedVariantId} />
      </Row>
      <EnergyFlowPlots
        formatForReport={formatForReport}
        queryUrl={energyFlowUrlsWithTimespan}
      />
    </div>
  );
}

export { EnergyFlows };
