import { Flex, Group, Text } from "@mantine/core";
import React, { useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { usePagedContent } from "../../hooks/usePagedContent";
import { MantinePagination } from "../../mantine/components/MantinePagination/MantinePagination";
import { Icon } from "../BuildingBlocks/Icon/Icon";
import { IconName } from "../BuildingBlocks/Icon/types";
import { PageContent } from "../BuildingBlocks/Layout/PageContent/PageContent";
import { PageHeader } from "../BuildingBlocks/Layout/PageHeader/PageHeader";
import { Portlet } from "../BuildingBlocks/Layout/Portlet";
import { Button, buttonColors } from "../Buttons/Button/Button";
import { IconButton } from "../Buttons/IconButton/IconButton";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { NoContracts } from "../NoContracts/NoContracts";
import { SearchBar } from "../SearchBar/SearchBar";
import { DirektvermarktungContractInformation } from "./DirektvermarktungContractInformation/DirektvermarktungContractInformation";
import { DirektvermarktungContractModal } from "./DirektvermarktungContractModal/DirektvermarktungContractModal";
import "./DirektvermarktungView.scss";
import { useDirektvermarktungContracts } from "./hooks/useDirektvermarktungContracts";

const INITIAL_PAGE_SIZE = 5;

function DirektvermarktungView() {
  const [newContractModalOpen, setNewContractModalOpen] = useState(false);
  const [pageSize, setPageSize] = useState(INITIAL_PAGE_SIZE);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const {
    data: direktvermarktungContracts,
    isLoading: direktvermarktungContractsLoading,
    error: direktvermarktungContractsError
  } = useDirektvermarktungContracts();

  const direktvermarktungContractsFiltered =
    direktvermarktungContracts &&
    direktvermarktungContracts.length > 0 &&
    direktvermarktungContracts?.filter(
      (contract) =>
        contract?.name &&
        contract.name.toLowerCase().includes(search.toLowerCase())
    );

  const { pagedItems: pagedContracts } = usePagedContent({
    items: direktvermarktungContractsFiltered || [],
    pageSize,
    page,
    onChangePage: setPage
  });
  const showPagination = direktvermarktungContracts
    ? direktvermarktungContracts.length > INITIAL_PAGE_SIZE
    : false;

  const navigate = useNavigate();

  function handleClickEditContract(contractId: string) {
    navigate(contractId);
  }

  return (
    <PageContent className="DirektvermarktungView">
      <PageHeader title="Direktvermarktung" />
      <DirektvermarktungContractModal
        isOpen={newContractModalOpen}
        onClose={() => setNewContractModalOpen(false)}
      />
      <Routes>
        <Route element={<EditContractHelper />} path=":contractId" />
      </Routes>
      <Portlet>
        {direktvermarktungContracts && direktvermarktungContracts.length > 0 ? (
          <>
            <div className="contracts-header">
              <SearchBar
                btnHidden
                controlledValue={search}
                placeholder="Suche nach Verträgen"
                onChange={(event) => setSearch(event.currentTarget.value)}
                onClear={() => setSearch("")}
              />
              <IconButton
                color={buttonColors.brand}
                iconName={IconName.Plus}
                onClick={() => setNewContractModalOpen(true)}
              >
                Direktvermarktungsvertrag hinzufügen
              </IconButton>
            </div>
            <div className="contracts-body">
              {pagedContracts.length > 0 ? (
                pagedContracts[page - 1]?.map((contract) => (
                  <DirektvermarktungContractInformation
                    contract={contract}
                    highlight={search}
                    initiallyOpen={direktvermarktungContracts.length === 1}
                    key={contract.id}
                    onClickEditContract={handleClickEditContract}
                  />
                ))
              ) : (
                <Flex
                  align="center"
                  className="contracts-no-result"
                  direction="column"
                  justify="flex-start"
                  mt="xl"
                >
                  <Icon
                    className="contracts-no-result-icon"
                    name={IconName.NoSearchResults}
                    style={{}}
                  />
                  <Text mt="xl">
                    für &quot;{search}&quot; konnten wir leider keine
                    Übereinstimmungen finden. Versuchen Sie es erneut
                  </Text>
                  <Button
                    color="brand"
                    noBackground
                    noBorder
                    outline
                    onClick={() => setSearch("")}
                  >
                    Suche zurücksetzen
                  </Button>
                </Flex>
              )}
            </div>
            {showPagination && (
              <Group justify="flex-end" mb="xl" mt="xl">
                <MantinePagination
                  page={page}
                  pageSize={pageSize}
                  totalPages={pagedContracts.length}
                  onChangePage={setPage}
                  onChangePageSize={(size) =>
                    setPageSize(size ?? INITIAL_PAGE_SIZE)
                  }
                />
              </Group>
            )}
          </>
        ) : (
          <LoadOrError
            error={direktvermarktungContractsError}
            loading={direktvermarktungContractsLoading}
            loadingMessage="Verträge werden geladen ..."
          >
            <NoContracts
              buttonText="Direktvermarktungsvertrag hinzufügen"
              onClickAddContract={() => setNewContractModalOpen(true)}
            />
          </LoadOrError>
        )}
      </Portlet>
    </PageContent>
  );
}

function EditContractHelper() {
  const navigate = useNavigate();
  const { contractId } = useParams();

  return (
    <DirektvermarktungContractModal
      contractId={contractId}
      isOpen
      onClose={() => navigate("../")}
    />
  );
}

export { DirektvermarktungView };
