import React from "react";

import { SiteOverview } from "../SiteStatus/SiteStatus";

function ManagerDashboard() {
  return (
    <div className="ManagerDashboard">
      <SiteOverview title="Ihre Projekte" />
    </div>
  );
}

export { ManagerDashboard };
