import type { ScatterChartSeries } from "@mantine/charts";
import {
  Measurand,
  type MeterDataGaps
} from "../../../../../../../../utils/backend-types";
import { getDataGapsForMeterByMeasurand } from "./getDataGapsForMeterByMeasurand";
import { mapMeasurandToLabel } from "./mapMeasurandToLabel";

export function getChartDataFromDataGaps(
  dataGaps: MeterDataGaps
): Array<ScatterChartSeries> {
  const finalData: Array<ScatterChartSeries> = [];
  Object.keys(dataGaps).forEach((accessorKey) => {
    if (accessorKey !== "expectedOrigin") {
      const dataGapsForMeasurand = getDataGapsForMeterByMeasurand(
        dataGaps[accessorKey]
      );
      if (dataGapsForMeasurand) {
        if (dataGaps[accessorKey].measurand.includes(Measurand.lossOfWork)) {
          finalData.push({
            color: "green.5",
            name: mapMeasurandToLabel(Measurand.lossOfWork),
            data: dataGapsForMeasurand
          });
        } else if (accessorKey.includes(Measurand.positive)) {
          finalData.push({
            color: "yellow.5",
            name: mapMeasurandToLabel(Measurand.positive),
            data: dataGapsForMeasurand
          });
        } else if (
          dataGaps[accessorKey].measurand.includes(Measurand.negative)
        ) {
          finalData.push({
            color: "red.5",
            name: mapMeasurandToLabel(Measurand.negative),
            data: dataGapsForMeasurand
          });
        } else if (
          dataGaps[accessorKey].measurand.includes(Measurand.reactiveCapacitive)
        ) {
          finalData.push({
            color: "blue.5",
            name: mapMeasurandToLabel(Measurand.reactiveCapacitive),
            data: dataGapsForMeasurand
          });
        } else if (
          dataGaps[accessorKey].measurand.includes(Measurand.reactiveInductive)
        ) {
          finalData.push({
            color: "orange.5",
            name: mapMeasurandToLabel(Measurand.reactiveInductive),
            data: dataGapsForMeasurand
          });
        }
      }
    }
  });

  return finalData;
}
