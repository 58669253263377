import React from "react";
import type { Control, FieldError, FieldErrors } from "react-hook-form";
import type { Delivery } from "../../../../../../../utils/backend-types";
import type { FormFieldData } from "../../../../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { FormFieldController } from "../../../../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { INDIVIDUAL_MIX_FIELDS } from "../../../DeliveryModal.types";
import type { FieldName } from "../FieldNames";
import "./IndividualMixFields.scss";

interface IndividualMixFieldsProps {
  control: Control<Delivery>;
  errors: FieldErrors<Delivery>;
  formFieldData: FormFieldData<Delivery>;
}

function IndividualMixFields({
  control,
  errors,
  formFieldData
}: IndividualMixFieldsProps) {
  const individualMixItems = Object.values(formFieldData).filter((field) =>
    INDIVIDUAL_MIX_FIELDS.includes(field.name as FieldName)
  );
  return (
    <div className="IndividualMixFields">
      {individualMixItems.map((field) => {
        return (
          <div className="FormItemInline" key={field.name}>
            <FormFieldController
              control={control}
              data={field}
              error={errors[field.name] as FieldError}
            />
          </div>
        );
      })}
    </div>
  );
}

export default IndividualMixFields;
