import React from "react";
import type { DropdownItemProps as ReactstrapDropdownItemProps } from "reactstrap";
// eslint-disable-next-line no-restricted-imports
import { DropdownItem as ReactstrapDropdownItem } from "reactstrap";

type DropdownItemProps = ReactstrapDropdownItemProps;

function DropdownItem(props: DropdownItemProps) {
  return <ReactstrapDropdownItem {...props} />;
}

export { DropdownItem, DropdownItemProps };
