import { useQuery } from "@tanstack/react-query";
import api from "../../../../../api";
import urls from "../../../../../urls";
import type {
  ContractFormOptions,
  ContractFormOptionsResponse
} from "../ContractModal.types";

export function useContractFormOptions(variantId: number) {
  const { isLoading, error, data } = useQuery({
    queryKey: ["contract-form-options", { variantId }],
    queryFn: () => fetchContractFormOptions(variantId)
  });

  return {
    isLoading,
    error,
    data
  };
}

async function fetchContractFormOptions(variantId: number) {
  if (!variantId) {
    return undefined;
  }

  const endpointUrl = urls.api.contractFormOptions(variantId);
  const response = await api.get<ContractFormOptionsResponse>(endpointUrl);

  const options: ContractFormOptions = {
    supplier: response.data.suppliers.map((supplier) => ({
      value: supplier.id,
      displayName: supplier.name
    })),
    supplied: response.data.supplied.map((supplied) => ({
      value: supplied.id,
      displayName: supplied.name
    }))
  };

  return options;
}
