export function copyToClipboard(str: string) {
  /**
   * This methods copies a string to the clipboard.
   * It is a bit hacky because the Clipboard API is not widely spread.
   * https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API
   * If this changes, Clipboard can be used to replace this method.
   */
  const el = document.createElement("textarea");
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
}
