import type { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { memo } from "react";
import type { Loadprofile } from "../../../../utils/backend-types";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import { luxonDateTimeToBackendDateOrDateTimeOrNull } from "../../../../utils/dates/luxonDateTimeToBackendDateOrDateTimeOrNull";
import { HelperText } from "../../../BuildingBlocks/Forms/FormField/HelperText/HelperText";
import { DateInput } from "../../../BuildingBlocks/Forms/FormFields/DateInput/DateInput";
import { Select } from "../../../BuildingBlocks/Forms/Select/Select";
import {
  DatePresetType,
  DateRangePicker,
  OpenDirection
} from "../../../DatePicker/DatePicker";
import { BooleanField } from "./BooleanField/BooleanField";
import type { Choice } from "./Dropdown/TsDropdown";
import { TsDropdown } from "./Dropdown/TsDropdown";
import { Email } from "./Email/Email";
import { Input } from "./Input/Input";
import Multiline from "./Multiline/Multiline.js";
import RegulatoryDuty from "./RegulatoryDuty/RegulatoryDuty.js";
import { Secret } from "./Secret/Secret";
import { ErrorText } from "./utils/ErrorText";

import "./FormField.scss";

export enum FormFieldType {
  Boolean = "boolean",
  Integer = "integer",
  Float = "float",
  String = "string",
  Obfuscated = "obfuscated",
  Email = "email",
  Multiline = "multiline",
  Date = "date",
  DateRange = "daterange",
  Choice = "choice",
  Dropdown = "dropdown",
  Field = "field",
  Select2 = "select2",
  Select2Creatable = "select2-creatable",
  RegulatoryDuty = "regulatory-duty",
  Loadprofile = "loadprofile"
}

interface ChoiceValue {
  value: string | number;
  label: string;
}
interface DateRange {
  lower: string | null;
  upper: string | null;
}
type SketchElement = {
  xPosition: number;
  yPosition: number;
};
export type FormFieldValue =
  | string
  | number
  | boolean
  | Array<string>
  | Array<number>
  | ChoiceValue
  | DateRange
  | null
  | SketchElement
  | Loadprofile
  | { x: number; y: number };

export interface Warning {
  operator: WarningOperator;
  maxValue: number;
  message: string;
}

enum WarningOperator {
  GreaterThan = "gt"
}

let inputTimeout: NodeJS.Timeout | null = null;

interface DateRangeErrors {
  lower: Array<string>;
  upper: Array<string>;
}

interface FormFieldProps {
  id: string;
  type: FormFieldType;
  name: string;
  value?: FormFieldValue;
  inputGroupText?: string;
  min?: number;
  max?: number;
  allowInput?: boolean;
  invalid?: boolean;
  errorTexts?: Array<string> | DateRangeErrors;
  warnings?: Array<Warning>;
  choices?: Array<Choice>;
  dataUrl?: string;
  placeholder?: string;
  multiselect?: boolean;
  allowNull?: boolean;
  required?: boolean;
  firstPossibleDate?: DateTime | string;
  onInput: (
    name: string,
    value: FormFieldValue,
    updateEvaluation?: boolean
  ) => void;
}

/** @deprecated Please use BuildingBlocks/Forms/FormField from now on. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
function FormField({
  id,
  type,
  name,
  value,
  inputGroupText,
  min,
  max,
  allowInput = true,
  invalid,
  errorTexts,
  warnings = [],
  choices,
  dataUrl,
  placeholder,
  multiselect,
  allowNull,
  required,
  onInput,
  ...otherProps
}: FormFieldProps) {
  function handleDateRangeInput(change: {
    startDate: DateTime | null;
    endDate: DateTime | null;
  }) {
    const newValue = {
      lower: luxonDateTimeToBackendDateOrDateTimeOrNull(change.startDate),
      upper: luxonDateTimeToBackendDateOrDateTimeOrNull(change.endDate)
    };

    onInput(name, newValue, false);
  }

  function handleTextOrIntegerInput(value: string | number | null) {
    onInput(name, value, false); // update value only

    if (inputTimeout) {
      clearTimeout(inputTimeout);
    }

    inputTimeout = setTimeout(() => {
      onInput(name, value);
    }, 500);
  }

  function getWarningTexts(value: number) {
    return warnings.reduce<Array<string>>((arr, warning) => {
      if (
        warning.operator === WarningOperator.GreaterThan &&
        value > warning.maxValue
      ) {
        arr.push(warning.message);
      }
      return arr;
    }, []);
  }

  // don't pass an undefined or null value into the input
  const safeValue = typeof value === "undefined" || value === null ? "" : value;
  const warningTexts =
    typeof safeValue === "number" ? getWarningTexts(safeValue) : [];
  // safety to ensure type for all fields other than DateRange
  const safeErrorTexts =
    errorTexts && Array.isArray(errorTexts) ? errorTexts : undefined;

  switch (type) {
    case FormFieldType.Boolean: {
      let safeBooleanValue = safeValue;

      if (typeof safeBooleanValue !== "boolean" && safeBooleanValue !== "") {
        console.error(`Invalid boolean field value: ${safeBooleanValue}`);
        safeBooleanValue = false;
      }

      return (
        <BooleanField
          allowInput={allowInput}
          allowNull={allowNull}
          checked={safeBooleanValue}
          errorTexts={safeErrorTexts}
          id={id}
          name={name}
          onInput={onInput}
        />
      );
    }
    case FormFieldType.Integer:
    case FormFieldType.Float: {
      let safeNumberValue = safeValue;

      if (typeof safeNumberValue === "string" && safeNumberValue !== "") {
        console.error(
          `Warning: string value given to Integer or Float field: ${safeNumberValue}`
        );
        safeNumberValue = parseInt(safeNumberValue, 10);
      } else if (
        typeof safeNumberValue !== "number" &&
        safeNumberValue !== ""
      ) {
        console.error(
          `Invalid value given to Integer or Float field: ${safeNumberValue}`
        );
        safeNumberValue = "";
      }

      return (
        <Input
          disabled={!allowInput}
          errorTexts={safeErrorTexts}
          id={id}
          inputGroupText={inputGroupText}
          invalid={invalid}
          max={max}
          min={min}
          name={name}
          placeholder={placeholder}
          type="number"
          value={safeNumberValue}
          warningTexts={warningTexts}
          onChange={(value) =>
            typeof value !== "undefined"
              ? handleTextOrIntegerInput(value)
              : handleTextOrIntegerInput(null)
          }
        />
      );
    }
    case FormFieldType.String: {
      let safeStringValue = safeValue;

      if (typeof safeStringValue !== "string") {
        console.error(
          `Invalid value given to String field: ${safeStringValue}`
        );
        safeStringValue = safeStringValue.toString();
      }

      return (
        <Input
          disabled={!allowInput}
          errorTexts={safeErrorTexts}
          id={id}
          inputGroupText={inputGroupText}
          invalid={invalid}
          name={name}
          placeholder={placeholder}
          type="text"
          value={safeStringValue}
          warningTexts={warningTexts}
          onChange={(value) =>
            typeof value !== "undefined"
              ? handleTextOrIntegerInput(value)
              : handleTextOrIntegerInput(null)
          }
        />
      );
    }
    case FormFieldType.Obfuscated:
      return (
        <Secret
          disabled={!allowInput}
          errorTexts={safeErrorTexts}
          id={id}
          invalid={invalid}
          name={name}
          placeholder={placeholder}
          value={safeValue}
          warningTexts={warningTexts}
          onChange={(value) => {
            if (typeof value === "string" || typeof value === "number") {
              handleTextOrIntegerInput(value);
            } else {
              handleTextOrIntegerInput(null);
            }
          }}
        />
      );
    case FormFieldType.Email: {
      let safeStringValue = safeValue;

      if (typeof safeStringValue !== "string") {
        console.error(
          `Invalid value given to String field: ${safeStringValue}`
        );
        safeStringValue = safeStringValue.toString();
      }

      return (
        <Email
          disabled={!allowInput}
          errorTexts={safeErrorTexts}
          id={id}
          invalid={invalid}
          name={name}
          value={safeStringValue}
          warningTexts={warningTexts}
          onChange={(value) =>
            typeof value !== "undefined"
              ? handleTextOrIntegerInput(value)
              : handleTextOrIntegerInput(null)
          }
        />
      );
    }
    case FormFieldType.Multiline:
      return (
        <Multiline
          disabled={!allowInput}
          errorTexts={safeErrorTexts}
          id={id}
          invalid={invalid}
          name={name}
          value={safeValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleTextOrIntegerInput(e.target.value)
          }
        />
      );
    case FormFieldType.Date: {
      const firstPossibleDate =
        typeof otherProps.firstPossibleDate === "string"
          ? otherProps.firstPossibleDate
          : typeof otherProps.firstPossibleDate === "undefined"
            ? undefined
            : luxonDateTimeToBackendDateOrDateTime(
                otherProps.firstPossibleDate
              );

      return (
        <>
          <DateInput
            disabled={!allowInput}
            firstPossibleDate={firstPossibleDate}
            id={id}
            invalid={invalid || (safeErrorTexts && safeErrorTexts.length > 0)}
            name={name}
            placeholder={placeholder}
            value={typeof safeValue === "string" ? safeValue : null}
            onChange={handleTextOrIntegerInput}
          />
          <HelperText error={safeErrorTexts?.length ? safeErrorTexts[0] : ""} />
        </>
      );
    }
    case FormFieldType.DateRange: {
      // contrary to other fields, here `null` is allowed since it represents a not-yet-defined daterange
      const dateValue = value as DateRange | null | undefined;
      const initialDate = dateValue
        ? {
            startDate: dateValue.lower
              ? backendDateOrDateTimeToLuxonDateTime(dateValue.lower)
              : undefined,
            endDate: dateValue.upper
              ? backendDateOrDateTimeToLuxonDateTime(dateValue.upper)
              : undefined
          }
        : undefined;

      const errors: Array<string> = [];
      if (errorTexts) {
        // safeguard to prevent wrong error format messing things up
        if (Array.isArray(errorTexts)) {
          // without errors, errorTexts still get passed as empty array, even though the type allows `undefined` as well
          if (errorTexts.length)
            console.error(
              `Invalid error format on DateRange field ${id} (object expected, but got array)`
            );

          errors.push(...errorTexts);
        } else {
          Object.keys(errorTexts).forEach((key) => {
            errors.push(...errorTexts[key]);
          });
        }
      }

      return (
        <>
          <DateRangePicker
            disabled={!allowInput}
            id={id}
            initialEndDate={initialDate?.endDate}
            initialStartDate={initialDate?.startDate}
            invalid={{
              startDate:
                (errorTexts && Object.hasOwn(errorTexts, "lower")) || false,
              endDate:
                (errorTexts && Object.hasOwn(errorTexts, "upper")) || false
            }}
            openDirection={OpenDirection.OpenUp}
            presets={DatePresetType.MonthYear}
            onChange={handleDateRangeInput}
          />
          <HelperText error={errors.length ? errors[0] : ""} />
        </>
      );
    }
    case FormFieldType.Dropdown:
    case FormFieldType.Choice:
    case FormFieldType.Field: {
      const safeValueHasRightTypes =
        typeof safeValue === "string" ||
        typeof safeValue === "number" ||
        typeof safeValue === "boolean" ||
        Array.isArray(safeValue);

      return (
        <TsDropdown
          choices={choices || []}
          defaultValue={safeValueHasRightTypes ? safeValue : undefined}
          disabled={!allowInput}
          errorTexts={safeErrorTexts}
          id={id}
          invalid={invalid}
          multiselect={multiselect}
          name={name}
          placeholder={placeholder}
          required={required}
          onChange={onInput}
        />
      );
    }
    case FormFieldType.Select2:
    case FormFieldType.Select2Creatable:
      return (
        <Select2Field
          allowInput={allowInput}
          choices={choices}
          dataUrl={dataUrl}
          errorTexts={safeErrorTexts}
          id={id}
          name={name}
          placeholder={placeholder}
          required={required}
          type={type}
          value={safeValue}
          onChange={onInput}
        />
      );
    case FormFieldType.RegulatoryDuty:
      return (
        <RegulatoryDuty
          disabled={!allowInput}
          errorTexts={safeErrorTexts}
          id={id}
          name={name}
          value={safeValue}
          onChange={onInput}
        />
      );
    default:
      // there is an error in the code if this case ever happens
      console.error(`Form field ${type} not implemented`);
      return null;
  }
}

/** @deprecated Please use BuildingBlocks/Forms/FormField from now on. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
const FormFieldMemoized = memo(FormField);

/** @deprecated Please use BuildingBlocks/Forms/FormFields/AsyncSelect or implement a similar component for the creatable dropdown variant. */
function Select2Field({
  value,
  choices,
  id,
  name,
  dataUrl,
  type,
  required,
  placeholder,
  allowInput,
  errorTexts,
  onChange
}) {
  function handleChange(selected) {
    onChange(name, selected ? selected.value : null);
  }

  const options = choices
    ? choices.map((choice) => {
        return {
          value: choice.value,
          label: choice.displayName
        };
      })
    : null;

  return (
    <div className="FormField select2">
      <Select
        creatable={type === "select2-creatable"}
        dataUrl={dataUrl}
        defaultValue={value}
        id={id}
        invalid={errorTexts && errorTexts.length > 0}
        isClearable={!required}
        isDisabled={!allowInput}
        name={name}
        options={options}
        placeholder={placeholder}
        onChange={handleChange}
      />
      {errorTexts && errorTexts.map(ErrorText)}
    </div>
  );
}

FormField.propTypes = {
  id: PropTypes.string,
  type: PropTypes.oneOf([
    "boolean",
    "integer",
    "float",
    "string",
    "obfuscated",
    "email",
    "multiline",
    "date",
    "daterange",
    "choice",
    "dropdown",
    "field",
    "select2",
    "select2-creatable",
    "regulatory-duty"
  ]).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
    PropTypes.number,
    PropTypes.bool,
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string
    })
  ]),
  inputGroupText: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  allowInput: PropTypes.bool,
  required: PropTypes.bool,
  errorTexts: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.object
  ]),
  warnings: PropTypes.arrayOf(PropTypes.object),
  choices: PropTypes.arrayOf(PropTypes.object),
  dataUrl: PropTypes.string,
  placeholder: PropTypes.string,
  multiselect: PropTypes.bool,
  allowNull: PropTypes.bool,
  onInput: PropTypes.func
};

export { FormFieldMemoized as FormField };
