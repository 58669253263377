import { useQuery } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../api";
import urls from "../urls";
import type { CurrentUser } from "../utils/backend-types";

const QUERY_KEY = "currentUser";

function useCurrentUserQuery() {
  const {
    isLoading,
    error,
    data: currentUser
  } = useQuery({
    queryKey: [QUERY_KEY],
    queryFn: () => fetchCurrentUser(),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity
  });

  async function fetchCurrentUser() {
    const response = await apiWithoutCamelization.get<CurrentUser>(
      urls.apiWithoutCamelization.currentUser()
    );
    return response.data;
  }

  return { isLoading, error, currentUser };
}

export { QUERY_KEY, useCurrentUserQuery };
