import { DateTime } from "luxon";
import type { MeasurandDataGaps } from "../../../../../../../../utils/backend-types";
import type { DataGapsTimeSeriesObject } from "../DataGaps.types";

export function getDataGapsForMeterByMeasurand(
  dataGapsData: MeasurandDataGaps
): Array<DataGapsTimeSeriesObject> | null {
  const dataGaps = dataGapsData.gaps;
  const result: Array<DataGapsTimeSeriesObject> = [];

  dataGaps.forEach((dataGapTuple) => {
    const startDate = dataGapTuple[0];
    const endDate = dataGapTuple[1];

    if (startDate && endDate) {
      const startDateTime = DateTime.fromISO(startDate, {
        zone: "Europe/Berlin"
      });

      const endDateTime = DateTime.fromISO(endDate, {
        zone: "Europe/Berlin"
      });

      // Ensure valid DateTime objects
      if (startDateTime.isValid && endDateTime.isValid) {
        // Step 1: Iterate through each day between start and end date
        let currentDateTime = startDateTime.startOf("day");
        const endDateOnly = endDateTime.endOf("day");

        while (currentDateTime <= endDateOnly) {
          // Step 2: Calculate 15-minute intervals for the current day
          const dayStart =
            currentDateTime > startDateTime ? currentDateTime : startDateTime;
          const dayEnd =
            currentDateTime.endOf("day") < endDateTime
              ? currentDateTime.endOf("day")
              : endDateTime;

          const diffInMinutes = dayEnd.diff(dayStart, "minutes").minutes;
          const intervals = Math.floor(diffInMinutes / 15) + 1;

          // Step 3: Add the result to the array if there's at least 1 interval
          if (intervals > 0) {
            result.push({
              dataGaps: intervals,
              day: currentDateTime.day
            });
          }

          // Move to the next day
          currentDateTime = currentDateTime.plus({ days: 1 });
        }
      }
    }
  });
  if (result.length > 0) {
    // Add all same days together
    const finalResult: Array<DataGapsTimeSeriesObject> = [];
    const dayMap = new Map<number, number>();
    result.forEach((dataGap) => {
      if (dayMap.has(dataGap.day)) {
        const index = dayMap.get(dataGap.day) as number;
        finalResult[index].dataGaps += dataGap.dataGaps;
      } else {
        dayMap.set(dataGap.day, finalResult.length);
        finalResult.push(dataGap);
      }
    });
    return finalResult;
  } else {
    return null;
  }
}
