import { useQuery } from "@tanstack/react-query";

import api from "../../../../api";
import urls from "../../../../urls";
import type {
  LocationOverviewDataQueryParams,
  LocationOverviewDataResponse
} from "../LocationOverview.types";

export function useGetLocationOverviewTableData(
  queryParams: LocationOverviewDataQueryParams | null
) {
  const { data, isLoading } = useQuery({
    queryKey: ["locationOverviewData", queryParams],
    queryFn: () => fetchLocationOverviewData(queryParams),
    enabled: Boolean(queryParams)
  });

  return {
    locationOverviewData: data,
    isLocationOverviewDataLoading: isLoading
  };
}

async function fetchLocationOverviewData(
  queryParams: LocationOverviewDataQueryParams | null
) {
  const response = await api.get<LocationOverviewDataResponse>(
    urls.api.meteringOrMarketLocationsOverview(queryParams)
  );

  return response.data;
}
