import { useQuery } from "@tanstack/react-query";
import type { UseQueryOptions } from "@tanstack/react-query";

import api from "../api";
import urls from "../urls";
import type { OnboardingAssistantDocuments } from "../utils/backend-types";

export function useOnboardingAssistantDocuments(
  siteId: number,
  options?: Partial<UseQueryOptions<OnboardingAssistantDocuments>>
) {
  return useQuery({
    queryKey: ["onboardingAssistantDocuments", { siteId }],
    queryFn: () => fetchOnboardingAssistantDocuments(siteId),
    ...options
  });
}

async function fetchOnboardingAssistantDocuments(siteId: number) {
  const OnboardingAssistantDocumentsUrl =
    urls.api.onboardingAssistantDocuments(siteId);
  const response = await api.get(OnboardingAssistantDocumentsUrl);
  return response.data;
}
