import React from "react";

import { useIsMieterstrom } from "../../../hooks/useIsMieterstrom";
import urls from "../../../urls";
import type { Generator } from "../../../utils/backend-types";
import {
  DEFAULT_FIELDS as DEFAULT_CONNECTION_FIELDS,
  FIELDS_FOR_MIETERSTROM_SITES as CONNECTION_FIELDS_FOR_MIETERSTROM_SITES
} from "../../ComponentListContainer/ComponentList/ComponentEditWizard/Data/Connection";
import { CustomForm } from "../../CustomForm/CustomForm";
import FormItems from "../../DynamicForm/FormItems/FormItems";
import { withCreatableDropdown } from "../../DynamicForm/FormItems/withCreatableDropdown";
import type { FieldsArray } from "../../OptionsForm/OptionsForm";
import { CREATE_FORM_FIELD_NAMES_REDUCED as PERSON_FORM_FIELD_NAMES } from "../../PersonWizard/PersonWizard";

interface CreateFormStepProps {
  siteId: number;
  graphCoordinates: { x: number; y: number };
  variantId: number;
  selectedFormFields: FieldsArray | undefined;
  buttonContainer?: HTMLElement;
  onClickCreate: (responseData: Generator) => void;
  onClose: () => void;
}

function CreateFormStep({
  siteId,
  graphCoordinates,
  variantId,
  selectedFormFields,
  buttonContainer,
  onClickCreate,
  onClose
}: CreateFormStepProps) {
  const { data: siteIsMieterstrom } = useIsMieterstrom(siteId);
  const postUrl = urls.api.generators(siteId);
  const nonFieldData = {
    site: siteId,
    sketchElement: {
      xPosition: graphCoordinates.x,
      yPosition: graphCoordinates.y
    }
  };
  const personPostUrl = urls.api.personsByVariantId(variantId);
  const personPutUrlFunc = urls.api.person;
  const personNonFieldData = {
    variant: variantId
  };
  const connectionPostUrl = urls.api.connections(siteId);
  const connectionPutUrlFunc = urls.api.connection;
  const FormItemsWithCreatablePerson = withCreatableDropdown(
    FormItems,
    "person",
    personPostUrl,
    personPutUrlFunc,
    PERSON_FORM_FIELD_NAMES,
    personNonFieldData
  );
  const FormItemsWithCreatablePersonAndConnection = withCreatableDropdown(
    FormItemsWithCreatablePerson as unknown as typeof FormItems,
    "connection",
    connectionPostUrl,
    connectionPutUrlFunc,
    siteIsMieterstrom
      ? CONNECTION_FIELDS_FOR_MIETERSTROM_SITES
      : DEFAULT_CONNECTION_FIELDS,
    nonFieldData
  );

  const CustomFormItemsComponent = (props) => (
    <FormItemsWithCreatablePersonAndConnection {...props} />
  );

  return (
    <CustomForm
      buttonContainer={buttonContainer}
      CustomFormItemsComponent={CustomFormItemsComponent}
      formFields={selectedFormFields}
      key="base-creation"
      nonFieldData={nonFieldData}
      postUrl={postUrl}
      submitButtonText="Erstellen"
      onCancel={onClose}
      onSubmit={onClickCreate}
    />
  );
}

export { CreateFormStep };
