import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import type { Column, Filter, SortingRule } from "react-table";
import ReactTable from "react-table";
import selectTableHOC from "react-table/lib/hoc/selectTable";
import { Input, Label } from "reactstrap";
import type {
  Person,
  Priority,
  SiteCategoriesResponse,
  Todo
} from "../../../utils/backend-types";
import { TodoStatus } from "../../../utils/backend-types";
import { Button, buttonColors } from "../../Buttons/Button/Button";
import "../../CustomReactTable/CustomReactTable.scss";
import type { TypedRowInfo } from "../../CustomReactTable/CustomReactTable.types";
import { usePageSize } from "../../CustomReactTable/usePageSize";
import { CustomTablePagination } from "../../CustomTablePagination/CustomTablePagination";
import { useShouldShowStaffView } from "../../StaffViewToggle/useShouldShowStaffView";
import type { TodoUser } from "../common";
import { CATEGORIES, Mode } from "../common";
import { isTodoClickable } from "../utils/isTodoClickable";
import type { TodoStatusCellProps } from "./TableColumns";
import { buildTableColumns } from "./TableColumns";
import { buildTableFilters } from "./TableFilters";
import "./TodoTable.scss";
import { parseTodos } from "./todo-table-utils";

const SelectTable = selectTableHOC(ReactTable);
export const OVERDUE_DATE = "-1";

const DEFAULT_SORTED: Array<SortingRule> = [
  {
    id: "dueDate",
    desc: false
  }
];

const PREMIUM_SORTED: Array<SortingRule> = [
  {
    id: "label",
    desc: false
  }
];

const DEFAULT_FILTERED: Array<Filter> = [
  {
    id: "status",
    value: {
      [TodoStatus.Open]: true
    }
  }
];

const INITIAL_FILTER_FOR_CATEGORY_UNIVERSAL: Filter = {
  id: "category",
  value: {
    [CATEGORIES.ONBOARDING.value]: true
  }
};

interface ExtraTodoTableFields {
  dueDate: string | null;
}

export type TodoTableTodo = Todo & ExtraTodoTableFields;

interface TodoTableProps extends Pick<TodoStatusCellProps, "onChangeStatus"> {
  todos: Array<Todo>;
  users: Array<TodoUser>;
  disableFilters: boolean;
  selectAll: boolean;
  mode: Mode;
  showPersonColumn: boolean;
  showPriorityColumn?: boolean;
  showTodoStatusColumn?: boolean;
  siteCategories?: Array<SiteCategoriesResponse>;
  personsForVariant: Array<Person>;
  downloadButtonLabel: string;
  onClickRow: (todoId: number) => void;
  onChangePriority: (priorityValue: Priority, todoId: number) => void;
  onChangeResponsible: (responsibleId: number, todoId: number) => void;
  onChangeTodoStatus: (todoId: number) => void;
  onChangeTodoPerson: (todoId: number, personId: number) => void;
  onChangeDueDate: (todoId: number, dueDate: string) => void;
  onChangeOverdue: (todoId: number, overdue: boolean) => void;
  toggleSelection: (id: string) => void;
  toggleAll: () => void;
  isSelected: (key: string) => boolean;
  setTableRef: (ref) => void;
  onClickDownloadSelectedTodoDocuments: () => void;
}

function TodoTable({
  todos,
  users,
  personsForVariant,
  disableFilters,
  selectAll,
  mode,
  showPersonColumn,
  showPriorityColumn,
  showTodoStatusColumn,
  siteCategories,
  downloadButtonLabel,
  onClickRow,
  onChangeStatus,
  onChangePriority,
  onChangeResponsible,
  onChangeTodoStatus,
  onChangeTodoPerson,
  onChangeDueDate,
  onChangeOverdue,
  toggleSelection,
  toggleAll,
  isSelected,
  setTableRef,
  onClickDownloadSelectedTodoDocuments
}: TodoTableProps) {
  const [sortedColumns, setSortedColumns] = useState(DEFAULT_SORTED);
  const { pageSize, setPageSize } = usePageSize<Todo>(todos);
  const shouldShowStaffView = useShouldShowStaffView();

  const isPremiumFiltered =
    siteCategories?.some((category) => category.is_premium) &&
    mode === Mode.Normal;

  const isPartialFeedinProject =
    siteCategories?.every((category) => category.is_partial_feedin_site) &&
    mode === Mode.Normal;

  const memoisedSelectInputComponent = useCallback(
    (props) => (
      <SelectInputComponent toggleSelection={toggleSelection} {...props} />
    ),
    [toggleSelection]
  );

  const memoisedSelectAllInputComponent = useCallback(
    (props) => <SelectAllInputComponent toggleAll={toggleAll} {...props} />,
    [toggleAll]
  );

  function getTdProps(
    state,
    rowInfo: TypedRowInfo<TodoTableTodo>,
    columnInfo: Column<TodoTableTodo>
  ) {
    const clickable =
      columnInfo.id !== "responsible" &&
      columnInfo.id !== "status" &&
      columnInfo.id !== "_selector" &&
      columnInfo.id !== "priority" &&
      columnInfo.id !== "documentProvided" &&
      columnInfo.id !== "person" &&
      columnInfo.id !== "dueDate" &&
      isTodoClickable(rowInfo.original.status);

    return {
      onClick: clickable
        ? function () {
            onClickRow(rowInfo.original.id);
          }
        : null,
      className: clickable ? "clickable-cell" : ""
    };
  }

  function onSortedChange(newSorted: Array<SortingRule>) {
    return setSortedColumns(newSorted);
  }

  function onPageSizeChange(newPageSize: number) {
    setPageSize(newPageSize);
  }

  const { persons, responsiblePersons, firstDueDate, lastDueDate } = useMemo(
    () => parseTodos(todos),
    [todos]
  );

  const todosWithAdjustedOverdueDates = useMemo(
    () =>
      todos.map<TodoTableTodo>((todo) => {
        return {
          ...todo,
          dueDate: todo.overdue ? OVERDUE_DATE : todo.dueDate
        };
      }),
    [todos]
  );

  const tableColumns = useMemo(
    () =>
      buildTableColumns(
        users,
        personsForVariant,
        firstDueDate,
        lastDueDate,
        sortedColumns,
        mode,
        showPersonColumn,
        showPriorityColumn,
        showTodoStatusColumn,
        onChangeStatus,
        onChangePriority,
        onChangeResponsible,
        onChangeTodoStatus,
        onChangeTodoPerson,
        onChangeDueDate,
        onChangeOverdue,
        shouldShowStaffView
      ),
    [
      users,
      personsForVariant,
      firstDueDate,
      lastDueDate,
      sortedColumns,
      mode,
      showPersonColumn,
      showPriorityColumn,
      showTodoStatusColumn,
      onChangeStatus,
      onChangePriority,
      onChangeResponsible,
      onChangeTodoStatus,
      onChangeTodoPerson,
      onChangeDueDate,
      onChangeOverdue,
      shouldShowStaffView
    ]
  );
  const tableFilters = useMemo(
    () =>
      buildTableFilters(
        todos,
        users,
        persons,
        responsiblePersons,
        firstDueDate,
        lastDueDate,
        disableFilters,
        mode
      ),
    [
      todos,
      users,
      persons,
      responsiblePersons,
      firstDueDate,
      lastDueDate,
      disableFilters,
      mode
    ]
  );

  const tableColumnsWithFilters = useMemo(
    () =>
      tableColumns.map<Column<TodoTableTodo>>((column) => {
        return {
          ...column,
          Filter: tableFilters[column.accessor]
        };
      }),
    [tableColumns, tableFilters]
  );

  const defaultFiltered = useMemo(() => {
    let defaultFiltered = [...DEFAULT_FILTERED];

    if (mode === Mode.Universal) {
      defaultFiltered = [
        ...defaultFiltered,
        INITIAL_FILTER_FOR_CATEGORY_UNIVERSAL
      ];
    } else if (mode === Mode.Onboarding) {
      const defaultResponsibleFilter = responsiblePersons.reduce(
        (filter, person) => {
          const user = users.find((user) => user.id === person);

          filter[person] = !user || (!user.is_staff && !user.isSystemUser);

          return filter;
        },
        { ohne: true }
      );

      defaultFiltered = [
        ...defaultFiltered,
        {
          id: "responsible",
          value: defaultResponsibleFilter
        }
      ];
    }

    return defaultFiltered;
  }, [mode, responsiblePersons, users]);

  if (
    (mode === Mode.Onboarding || !isPartialFeedinProject) &&
    !shouldShowStaffView
  ) {
    return (
      <ReactTable
        columns={tableColumnsWithFilters}
        data={todosWithAdjustedOverdueDates}
        defaultFiltered={defaultFiltered}
        defaultSorted={isPremiumFiltered ? PREMIUM_SORTED : DEFAULT_SORTED}
        filterable
        getTdProps={getTdProps}
        minRows={0}
        NoDataComponent={NoDataComponent}
        pageSize={pageSize}
        PaginationComponent={CustomTablePagination}
        showPageJump={true}
        showPagination={true}
        onPageSizeChange={onPageSizeChange}
        onSortedChange={onSortedChange}
      />
    );
  }
  return (
    <>
      <div className="download-selected-documents-button-container">
        <p className="download-selected-documents-button-label">
          {downloadButtonLabel}
        </p>
        <Button
          className="download-selected-documents-button"
          color={buttonColors.brand}
          disabled={!downloadButtonLabel}
          onClick={onClickDownloadSelectedTodoDocuments}
        >
          Dokumente herunterladen
        </Button>
      </div>

      <SelectTable
        columns={tableColumnsWithFilters}
        data={todosWithAdjustedOverdueDates}
        defaultFiltered={defaultFiltered}
        defaultSorted={isPremiumFiltered ? PREMIUM_SORTED : DEFAULT_SORTED}
        filterable
        getTdProps={getTdProps}
        isSelected={isSelected}
        keyField={"id"}
        minRows={0}
        NoDataComponent={NoDataComponent}
        pageSize={pageSize}
        PaginationComponent={CustomTablePagination}
        ref={(r) => setTableRef(r)}
        selectAll={selectAll}
        SelectAllInputComponent={memoisedSelectAllInputComponent}
        SelectInputComponent={memoisedSelectInputComponent}
        selectType="checkbox"
        showPageJump={true}
        showPagination={true}
        toggleAll={toggleAll}
        toggleSelection={toggleSelection}
        onPageSizeChange={onPageSizeChange}
        onSortedChange={onSortedChange}
      />
    </>
  );
}

const MemoisedTodoTable = React.memo(TodoTable);

interface SelectInputComponentProps {
  checked: boolean;
  id: number;
  toggleSelection: (id: number) => void;
}

function SelectInputComponent({
  checked,
  id,
  toggleSelection
}: SelectInputComponentProps) {
  return (
    <TodoCheckbox
      checked={checked}
      inputClassName="todo-checkbox"
      onChange={() => toggleSelection(id)}
    />
  );
}

interface SelectAllInputComponentProps {
  checked: boolean;
  toggleAll: () => void;
}

function SelectAllInputComponent({
  checked,
  toggleAll
}: SelectAllInputComponentProps) {
  return (
    <TodoCheckbox
      checked={checked}
      inputClassName="select-all-checkbox"
      onChange={toggleAll}
    />
  );
}

function NoDataComponent() {
  return (
    <div className="no-data-component">
      <p>Keine Aufgaben entsprechen dem gewählten Filter.</p>
    </div>
  );
}

interface TodoCheckboxProps {
  checked: boolean;
  inputClassName: string;
  onChange: () => void;
}

function TodoCheckbox({
  checked,
  inputClassName,
  onChange
}: TodoCheckboxProps) {
  return (
    <Label className="m-checkbox">
      <Input
        checked={checked}
        className={`m-checkbox ${inputClassName}`}
        type="checkbox"
        onChange={onChange}
      />
      <span />
    </Label>
  );
}

TodoTable.propTypes = {
  todos: PropTypes.arrayOf(PropTypes.object).isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  disableFilters: PropTypes.bool.isRequired,
  selectAll: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  showPersonColumn: PropTypes.bool.isRequired,
  showPriorityColumn: PropTypes.bool.isRequired,
  onClickRow: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  onChangeTodoStatus: PropTypes.func.isRequired,
  onChangeResponsible: PropTypes.func.isRequired,
  toggleSelection: PropTypes.func.isRequired,
  toggleAll: PropTypes.func.isRequired,
  isSelected: PropTypes.func.isRequired,
  setTableRef: PropTypes.func.isRequired
};

export { MemoisedTodoTable as TodoTable };
