import { groupBy } from "lodash";
import type { GroupBase } from "react-select";
import type {
  DropdownOption,
  DropdownOptionWithGroup
} from "../Dropdown.types";

/** Formats Dropdown options in the GroupBase format expected by `react-select`. */
export function groupDropdownOptions(
  options: Array<DropdownOptionWithGroup>
): Array<GroupBase<DropdownOption>> {
  const groupedOptionsByGroupLabel = groupBy(options, "group");
  const groupedOptions = Object.keys(groupedOptionsByGroupLabel).map(
    (groupLabel) => {
      return {
        label: groupLabel,
        options: groupedOptionsByGroupLabel[groupLabel].map<DropdownOption>(
          (option) => ({
            value: option.value,
            label: option.label,
            isFixed: option.isFixed
          })
        )
      };
    }
  );

  if (
    groupedOptions.length > 1 &&
    groupedOptions[groupedOptions.length - 1].label === ""
  ) {
    const [lastOption] = groupedOptions.splice(groupedOptions.length - 1, 1);
    groupedOptions.unshift(lastOption);
  }

  return groupedOptions;
}
