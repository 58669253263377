import React from "react";
import type { Control, FieldErrors } from "react-hook-form";
import type { Delivery } from "../../../../../../../utils/backend-types";
import { isFieldError } from "../../../../../../BuildingBlocks/Forms/FormField/HelperText/utils/isFieldError";
import { ChangeablePricesField } from "../../../../SharedFields/ChangeablePricesField";
import { DELIVERY_FORM_HELP_TEXTS } from "../../DeliveryForm.constants";

interface ForwardFixedPriceFieldsProps {
  control: Control<Delivery>;
  errors: FieldErrors<Delivery>;
}

function ForwardFixedPriceFields({
  control,
  errors
}: ForwardFixedPriceFieldsProps) {
  return (
    <>
      <ChangeablePricesField
        className="WorkingPrices"
        control={control}
        error={
          isFieldError(errors.workingPrices) ? errors.workingPrices : undefined
        }
        helpText={DELIVERY_FORM_HELP_TEXTS.workingPrices}
        label={"Arbeitspreis inkl. Abgaben/Umlagen, exkl. MWSt.*"}
        name="workingPrices"
        unit="ct/kWh"
      />
      <ChangeablePricesField
        className="ConcessionFees"
        control={control}
        error={
          isFieldError(errors.concessionFees)
            ? errors.concessionFees
            : undefined
        }
        helpText={DELIVERY_FORM_HELP_TEXTS.concessionFees}
        label={"Konzessionsabgabe"}
        name="concessionFees"
        unit="ct/kWh"
      />
    </>
  );
}

export { ForwardFixedPriceFields };
