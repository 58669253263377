import PropTypes from "prop-types";
import React from "react";
import { generatePath, Link } from "react-router-dom";
import type { Filter } from "react-table";

import { ROUTES } from "../../../../routes";
import type { Todo } from "../../../../utils/backend-types";
import { TodoStatus } from "../../../../utils/backend-types";
import { LAST_YEAR } from "../../../../utils/dates";
import { DropdownFilter } from "../../../CustomReactTable/Filters/DropdownFilter/DropdownFilter";
import { StatusSymbol, StatusSymbolColour } from "../../../Icons/StatusSymbol";
import {
  getOnboardingTodoLabel,
  OnboardingTodo
} from "../../../Todos/onboarding-todos";
import {
  ALL_DONE_TEXT,
  getOnboardingTodosSimpleStatus,
  NO_TODOS_TEXT
} from "../utils/getOnboardingTodosSimpleStatus";

import "./OnboardingTodos.scss";

interface OnboardingTodosCellSimpleProps {
  todos: Array<Todo>;
  projectId: string;
  siteId: number;
}

function OnboardingTodosCellSimple({
  todos,
  projectId,
  siteId
}: OnboardingTodosCellSimpleProps) {
  const numTodos = todos.length;
  const textAndSymbolStyle = { display: "flex", alignItems: "center" };

  if (numTodos === 0) {
    return (
      <div style={textAndSymbolStyle}>
        <StatusSymbol noStatus />
        <span>{NO_TODOS_TEXT}</span>
      </div>
    );
  }

  const { statusSymbolColour, displayText } =
    getOnboardingTodosSimpleStatus(todos);
  const managerVariantStructurePath = generatePath(
    ROUTES.managerVariantStructure,
    { projectId: projectId, siteId: siteId.toString() }
  );

  return (
    <div style={textAndSymbolStyle}>
      <StatusSymbol colour={statusSymbolColour} />
      <span>
        {statusSymbolColour == StatusSymbolColour.Green ? (
          displayText
        ) : (
          <Link to={managerVariantStructurePath}>{displayText}</Link>
        )}
      </span>
    </div>
  );
}

function sortOnboardingTodosSimple(a: Array<Todo>, b: Array<Todo>) {
  const aScore = calculateTodosScore(a);
  const bScore = calculateTodosScore(b);

  if (aScore < bScore) {
    return -1;
  } else if (bScore > aScore) {
    return 1;
  }

  return 0;
}

function OnboardingTodosSimpleFilter({ filter, onChange }) {
  const choices = {
    [getOnboardingTodoLabel(OnboardingTodo.KONFIGURATION_PRUEFEN, LAST_YEAR)]:
      getOnboardingTodoLabel(OnboardingTodo.KONFIGURATION_PRUEFEN, LAST_YEAR),
    [getOnboardingTodoLabel(
      OnboardingTodo.REGULATORISCHE_DATEN_VERVOLLSTAENDIGEN,
      LAST_YEAR
    )]: getOnboardingTodoLabel(
      OnboardingTodo.REGULATORISCHE_DATEN_VERVOLLSTAENDIGEN,
      LAST_YEAR
    ),
    [getOnboardingTodoLabel(
      OnboardingTodo.VOLLMACHT_FUER_MSB_BEREITSTELLEN,
      LAST_YEAR
    )]: getOnboardingTodoLabel(
      OnboardingTodo.VOLLMACHT_FUER_MSB_BEREITSTELLEN,
      LAST_YEAR
    ),
    [ALL_DONE_TEXT]: ALL_DONE_TEXT
  };

  return (
    <DropdownFilter
      choices={choices}
      filter={filter}
      showNoneOption
      onChange={onChange}
    />
  );
}

function onFilterOnboardingTodosSimple(filter: Filter, row) {
  if (!filter.value) {
    return true;
  }

  const todos: Array<Todo> = row.onboardingTodos;
  const configurationAnlegenTodo = todos.find(
    (todo) =>
      todo.label ===
      getOnboardingTodoLabel(OnboardingTodo.KONFIGURATION_PRUEFEN, LAST_YEAR)
  );
  const configurationAnlegenTodoIsDone =
    !!configurationAnlegenTodo &&
    configurationAnlegenTodo.status === TodoStatus.Done;
  const regulatorischeDatenVervollstaendigenTodo = todos.find(
    (todo) =>
      todo.label ===
      getOnboardingTodoLabel(
        OnboardingTodo.REGULATORISCHE_DATEN_VERVOLLSTAENDIGEN,
        LAST_YEAR
      )
  );
  const regulatorischeDatenVervollstaendigenTodoIsDone =
    !!regulatorischeDatenVervollstaendigenTodo &&
    regulatorischeDatenVervollstaendigenTodo.status === TodoStatus.Done;
  const vollmachtFuerMsbBereitstellenTodo = todos.find(
    (todo) =>
      todo.label ===
      getOnboardingTodoLabel(
        OnboardingTodo.VOLLMACHT_FUER_MSB_BEREITSTELLEN,
        LAST_YEAR
      )
  );
  const vollmachtFuerMsbBereitstellenTodoIsDone =
    !!vollmachtFuerMsbBereitstellenTodo &&
    vollmachtFuerMsbBereitstellenTodo.status === TodoStatus.Done;
  const configurationAnlegenTodoLabel = getOnboardingTodoLabel(
    OnboardingTodo.KONFIGURATION_PRUEFEN,
    LAST_YEAR
  );
  const regulatorischeDatenVervollstaendigenTodoLabel = getOnboardingTodoLabel(
    OnboardingTodo.REGULATORISCHE_DATEN_VERVOLLSTAENDIGEN,
    LAST_YEAR
  );
  const vollmachtFuerMsbBereitstellenTodoLabel = getOnboardingTodoLabel(
    OnboardingTodo.VOLLMACHT_FUER_MSB_BEREITSTELLEN,
    LAST_YEAR
  );

  if (
    filter.value[configurationAnlegenTodoLabel] &&
    !configurationAnlegenTodoIsDone
  ) {
    return true;
  }

  if (
    filter.value[regulatorischeDatenVervollstaendigenTodoLabel] &&
    !regulatorischeDatenVervollstaendigenTodoIsDone &&
    configurationAnlegenTodoIsDone
  ) {
    return true;
  }

  if (
    filter.value[vollmachtFuerMsbBereitstellenTodoLabel] &&
    !vollmachtFuerMsbBereitstellenTodoIsDone &&
    configurationAnlegenTodoIsDone &&
    regulatorischeDatenVervollstaendigenTodoIsDone
  ) {
    return true;
  }

  if (
    filter.value[ALL_DONE_TEXT] &&
    configurationAnlegenTodoIsDone &&
    regulatorischeDatenVervollstaendigenTodoIsDone &&
    vollmachtFuerMsbBereitstellenTodoIsDone
  ) {
    return true;
  }

  return false;
}

function calculateTodosScore(todos: Array<Todo>) {
  const configurationAnlegenTodo = todos.find(
    (todo) =>
      todo.label ===
      getOnboardingTodoLabel(OnboardingTodo.KONFIGURATION_PRUEFEN, LAST_YEAR)
  );
  const configurationAnlegenTodoIsDone =
    !!configurationAnlegenTodo &&
    configurationAnlegenTodo.status === TodoStatus.Done;
  const regulatorischeDatenVervollstaendigenTodo = todos.find(
    (todo) =>
      todo.label ===
      getOnboardingTodoLabel(
        OnboardingTodo.REGULATORISCHE_DATEN_VERVOLLSTAENDIGEN,
        LAST_YEAR
      )
  );
  const regulatorischeDatenVervollstaendigenTodoIsDone =
    !!regulatorischeDatenVervollstaendigenTodo &&
    regulatorischeDatenVervollstaendigenTodo.status === TodoStatus.Done;
  const vollmachtFuerMsbBereitstellenTodo = todos.find(
    (todo) =>
      todo.label ===
      getOnboardingTodoLabel(
        OnboardingTodo.VOLLMACHT_FUER_MSB_BEREITSTELLEN,
        LAST_YEAR
      )
  );
  const vollmachtFuerMsbBereitstellenTodoIsDone =
    !!vollmachtFuerMsbBereitstellenTodo &&
    vollmachtFuerMsbBereitstellenTodo.status === TodoStatus.Done;

  if (
    configurationAnlegenTodoIsDone &&
    regulatorischeDatenVervollstaendigenTodoIsDone &&
    vollmachtFuerMsbBereitstellenTodoIsDone
  ) {
    return 3;
  } else if (regulatorischeDatenVervollstaendigenTodoIsDone) {
    return 2;
  } else if (configurationAnlegenTodoIsDone) {
    return 1;
  }

  return 0;
}

OnboardingTodosCellSimple.propTypes = {
  todos: PropTypes.arrayOf(PropTypes.object).isRequired,
  projectId: PropTypes.string.isRequired,
  siteId: PropTypes.number.isRequired
};

export {
  OnboardingTodosCellSimple,
  sortOnboardingTodosSimple,
  OnboardingTodosSimpleFilter,
  onFilterOnboardingTodosSimple
};
