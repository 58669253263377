import type { FieldValues, Path, UseFormSetError } from "react-hook-form";
import { isApiError } from "../../../../../../utils/isApiError";
import { showToast } from "../../../../../../utils/toast";

/** Using `setError` from `useForm`, assigns incoming errors from an API response to their respective fields.
 * Note: this function has been partially copied from the BuildingBlocks/Forms/utils/setErrorsFromResponseData.ts file. Future refactoring after MVP might be warranted.
 */
export function setErrorsFromResponseData<T extends FieldValues>(
  error: unknown,
  fieldList: FieldValues,
  setError: UseFormSetError<T>,
  unknownErrorText: string,
  unknownTableErrorText: string
) {
  if (
    isApiError<Record<keyof T, Array<string>>>(error) &&
    error.response?.data &&
    error.response?.status === 400
  ) {
    const formKeys: ReadonlyArray<string> = Object.keys(fieldList);

    Object.keys(error.response.data).forEach((key) => {
      if (formKeys.includes(key)) {
        setKeyError(
          key,
          error.response?.data[key][0],
          setError,
          unknownErrorText
        );
      } else {
        switch (key) {
          case "begin":
            setKeyError(
              "startDate",
              error.response?.data[key][0],
              setError,
              unknownErrorText
            );
            break;
          case "end":
            setKeyError(
              "endDate",
              error.response?.data[key][0],
              setError,
              unknownErrorText
            );
            break;
          case "gridFeedoutContract":
            if (error.response?.data[key]["billingPeriod"]) {
              setKeyError(
                "period",
                error.response?.data[key]["billingPeriod"][0],
                setError,
                unknownErrorText
              );
            }

            if (error.response?.data[key]["componentFeedoutShares"]) {
              showToast("error", unknownTableErrorText);
            }
            break;
          case "gridFeedinContract":
            if (error.response?.data[key]["billingPeriod"]) {
              setKeyError(
                "feedinPeriod",
                error.response?.data[key]["billingPeriod"][0],
                setError,
                unknownErrorText
              );
            }

            if (error.response?.data[key]["generatorFeedinShares"]) {
              showToast("error", unknownTableErrorText);
            }

            if (error.response?.data[key]["meter"]) {
              setKeyError(
                "feedinMeloMaloId",
                error.response?.data[key]["meter"][0],
                setError,
                unknownErrorText
              );
            }

            if (error.response?.data[key]["shareType"]) {
              setKeyError(
                "feedinDistributionType",
                error.response?.data[key]["shareType"][0],
                setError,
                unknownErrorText
              );
            }

            break;
          default:
            if (key === "nonFieldErrors") {
              showToast("error", error.response?.data[key] ?? unknownErrorText);
            } else {
              showToast("error", unknownErrorText);
            }
        }
      }
    });
  } else {
    showToast("error", unknownErrorText);
  }
}

function setKeyError<T extends FieldValues>(
  key: string,
  error: unknown,
  setError: UseFormSetError<T>,
  unknownErrorText: string
) {
  setError(
    key as Path<T>,
    {
      type: "custom",
      message: typeof error === "string" ? error : unknownErrorText
    },
    { shouldFocus: true }
  );
}
