import { Group, Text } from "@mantine/core";
import React from "react";
import { Controller, type Control, type FieldErrors } from "react-hook-form";
import { SupplyType } from "../../../../types/api.types";
import { FormFieldController } from "../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { Icon } from "../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import { Button } from "../../../Buttons/Button/Button";
import { type SiteSetupProcessForForm } from "../../SiteSetupAssistant.types";
import { SITE_SETUP_ASSISTANT_FORM } from "../SiteSetupAssistantForms.constants";

const zipRegex = /^\d{5}$/;

interface AddressFormItemsProps {
  formControl: Control<SiteSetupProcessForForm>;
  formErrors: FieldErrors<SiteSetupProcessForForm>;
}

function AddressFormItems({ formControl, formErrors }: AddressFormItemsProps) {
  return (
    <div className="AddressFormItems">
      <Text fw={500}>Adresse der Liegenschaft</Text>
      <FormFieldController
        control={formControl}
        data={SITE_SETUP_ASSISTANT_FORM.siteAddress}
        error={formErrors.siteAddress}
      />
      <Group>
        <FormFieldController
          control={formControl}
          data={SITE_SETUP_ASSISTANT_FORM.siteZip}
          error={formErrors.siteZip}
          rules={{
            validate: (value) =>
              zipRegex.test((value || "").toString()) ||
              "Bitte geben Sie eine Postleitzahl bestehend aus fünf Ziffern an."
          }}
        />
        <FormFieldController
          control={formControl}
          data={SITE_SETUP_ASSISTANT_FORM.siteCity}
          error={formErrors.siteCity}
        />
      </Group>

      <Text fw={500} mb="lg" mt="xl">
        Versorgungskonzept
      </Text>
      <Controller
        control={formControl}
        name="siteSupplyType"
        render={({ field }) => {
          return (
            <>
              <Text mb="lg">
                Welches Versorgungskonzept streben Sie in Ihrer Liegenschaft
                an?*
              </Text>
              <Group gap="xl" justify="center">
                <Button
                  active={field.value === SupplyType.FullSupply}
                  className="supply-type-button"
                  onClick={() => field.onChange(SupplyType.FullSupply)}
                >
                  <Icon name={IconName.Home} />
                  <br />
                  Vollversorgung (Netz- und PV-Strom)
                </Button>
                <Button
                  active={field.value === SupplyType.PartialSupply}
                  className="supply-type-button"
                  onClick={() => field.onChange(SupplyType.PartialSupply)}
                >
                  <Icon name={IconName.SolarPowerGenerator} />
                  <br />
                  Teilversorgung (nur PV-Strom)
                </Button>
              </Group>
            </>
          );
        }}
      />
    </div>
  );
}

export { AddressFormItems };
