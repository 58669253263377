import React from "react";

import type { CurrentUser } from "../../utils/backend-types";
import { SiteName } from "../../utils/enums";
import { Breadcrumbs } from "../Breadcrumbs/Breadcrumbs";
import { Icon } from "../BuildingBlocks/Icon/Icon";
import { IconName } from "../BuildingBlocks/Icon/types";
import { OptiNodeHelpIcon } from "../OptiNodeHelpIcon/OptiNodeHelpIcon";
import { SiteQuickSwitch } from "../SiteQuickSwitch/SiteQuickSwitch";
import { StaffViewToggle } from "../StaffViewToggle/StaffViewToggle";
import { Brand } from "./Brand";
import { UserMenu } from "./UserMenu";

import "./Header.scss";

interface Props {
  siteName: SiteName;
  currentUser: CurrentUser | undefined;

  toggleShowStaffView: () => void;
}

function Header({ siteName, currentUser, toggleShowStaffView }: Props) {
  return (
    <header
      className="m-grid__item m-header "
      data-minimize-mobile-offset="200"
      data-minimize-offset="200"
    >
      <div className="m-container m-container--fluid m-container--full-height">
        <div className="m-stack m-stack--ver m-stack--desktop">
          <Brand siteName={siteName} />
          <div
            className="m-stack__item m-stack__item--fluid m-header-head"
            id="m_header_nav"
          >
            <button
              className="m-aside-header-menu-mobile-close  m-aside-header-menu-mobile-close--skin-dark "
              id="m_aside_header_menu_mobile_close_btn"
            >
              <Icon name={IconName.Close} />
            </button>
            <div className="m-header-menu m-aside-header-menu-mobile m-aside-header-menu-mobile--offcanvas m-header-menu--skin-light m-header-menu--submenu-skin-light m-aside-header-menu-mobile--skin-dark m-aside-header-menu-mobile--submenu-skin-dark">
              <Breadcrumbs />
            </div>
            <div
              className="m-topbar  m-stack m-stack--ver m-stack--general"
              id="m_header_topbar"
            >
              <UserMenu currentUser={currentUser} siteName={siteName} />
            </div>
            <div id="optinode-help-icon">
              {siteName === SiteName.Optinode && <OptiNodeHelpIcon />}
            </div>
            <div id="staff-view-toggle">
              <StaffViewToggle toggleShowStaffView={toggleShowStaffView} />
            </div>
            <div id="search">
              <SiteQuickSwitch />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export { Header };
