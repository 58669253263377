import Building from "../../../../icons/building.svg";
import Contract from "../../../../icons/contract.svg";
import FileTypeJSON from "../../../../icons/filetype-json.svg";
import FlowArrow from "../../../../icons/flow-arrow.svg";
import GraphMeter from "../../../../icons/graph-icons/electric-meter.svg";
import Leaf from "../../../../icons/leaf.svg";
import MapPin from "../../../../icons/map-pin.svg";
import NoSearchResults from "../../../../icons/no-search-results.svg";
import CheckIconGreen from "../../../../icons/rounded-check-green.svg";
import InfoIcon from "../../../../icons/rounded-info-solid.svg";
import SolarPowerGenerator from "../../../../icons/solar-power-generator.svg";
import TextElementIcon from "../../../../icons/text-element.svg";
import ExclamationIconOrange from "../../../../icons/triangle-exclamation-solid-orange.svg";
import ExclamationIconRed from "../../../../icons/triangle-exclamation-solid-red.svg";
import WindGenerator from "../../../../icons/wind-generator.svg";
import { FileMinus } from "../../../../images";
import { IconName } from "../types";

const ICONS_MAP = {
  [IconName.FlowArrow]: FlowArrow,
  [IconName.TextElement]: TextElementIcon,
  [IconName.Info]: InfoIcon,
  [IconName.ExclamationOrange]: ExclamationIconOrange,
  [IconName.ExclamationRed]: ExclamationIconRed,
  [IconName.FileMinus]: FileMinus,
  [IconName.RoundedCheckGreen]: CheckIconGreen,
  [IconName.GraphMeter]: GraphMeter,
  [IconName.Leaf]: Leaf,
  [IconName.Contract]: Contract,
  [IconName.FileJSON]: FileTypeJSON,
  [IconName.SolarPowerGenerator]: SolarPowerGenerator,
  [IconName.WindGenerator]: WindGenerator,
  [IconName.MapPin]: MapPin,
  [IconName.NoSearchResults]: NoSearchResults,
  [IconName.Building]: Building
} as const;

const ICON_IMG_SRC_ICONS = Object.keys(ICONS_MAP) as Array<IconName>;

function getIconImgSrcByIconName(iconName: IconName) {
  return ICONS_MAP[iconName] || null;
}

export { ICON_IMG_SRC_ICONS, getIconImgSrcByIconName };
