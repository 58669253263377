import type { QueryClient } from "@tanstack/react-query";
import type { ApiError } from "../../../../../api";
import api from "../../../../../api";
import { ObjectName } from "../../../../../utils/enums";
import { getObjectDetailUrl } from "../../../../../utils/getObjectDetailUrl";
import type { FormValues } from "../../../../CustomForm/useCustomForm";
import type { FormFieldData } from "../../../../DynamicForm/FormItems/FormItems";
import type { FieldNameToLabelMap } from "../../../../HistoryTab/HistoryTab";
import { HistoryTab } from "../../../../HistoryTab/HistoryTab";
import {
  getOrderedFormFieldsFromResponseAndValues,
  getSelectedFormFields
} from "../common";
import type { FieldDependency, Form, FormSection } from "./data-types";
import { loadGeneralDataFields } from "./utils/loadGeneralDataFields";

interface LoadDataArgs {
  queryClient: QueryClient;
  objectName: ObjectName;
  objectId: number;
  siteId: number;
  variantId: number;
  visibleFields: Array<string>;
  hiddenFieldsIfNull: Array<string>;
  isPremium?: boolean;
}

interface BasicDataField {
  name: string;
  form?: number;
  apiCall?: string;
  product?: number;
  hiddenIfNull?: boolean;
}

interface LoadDataBasicArgs {
  queryClient: QueryClient;
  objectName: ObjectName;
  componentId: number;
  siteId: number;
  product: number;
  fields: Array<BasicDataField>;
  generalFormIndex: number;
  generalApiCall: string;
  generalTabName: string;
}

export interface GeneralDataField {
  name: string;
  dependencies?: Array<FieldDependency>;
}

export const LOADPROFILE_TYPES = {
  [ObjectName.Generator]: "generation",
  [ObjectName.Consumer]: "consumption"
};

export function buildHistoryData(
  name: string,
  objectName: ObjectName,
  componentId: number,
  fieldNameToLabelMap: FieldNameToLabelMap
) {
  return {
    name,
    formTitle: "Historie",
    Component: HistoryTab,
    extraProps: {
      entityId: componentId,
      entityType: objectName,
      fieldNameToLabelMap
    },
    sections: []
  };
}

/** @deprecated - see loadDataBasic below */
export function loadData({
  queryClient,
  objectName,
  objectId,
  siteId,
  variantId,
  visibleFields,
  hiddenFieldsIfNull,
  isPremium = false
}: LoadDataArgs) {
  const siteOrVariantId = objectName === ObjectName.Person ? variantId : siteId;

  return loadGeneralDataFields(
    queryClient,
    objectName,
    objectId,
    siteOrVariantId,
    isPremium
  ).then((response) => {
    const selectedFormFields = getSelectedFormFields(
      response.option.data.actions.pOST,
      response.detail.data,
      visibleFields,
      hiddenFieldsIfNull
    );

    const generalData = {
      name: "general",
      formTitle: "Allgemeine Daten",
      extraProps: {
        objectName: objectName,
        siteId: siteId,
        variantId: variantId
      },
      sections: [
        {
          fields: selectedFormFields,
          values: response.detail.data,
          errors: {}
        }
      ]
    };

    return Promise.resolve([generalData]);
  });
}

// very similar to loadData
// use this function when transitioning to new data format (used in Generator, MarketLocation, and MeteringLocation)
export function loadDataBasic({
  queryClient,
  objectName,
  componentId,
  siteId,
  product,
  fields,
  generalFormIndex,
  generalApiCall,
  generalTabName
}: LoadDataBasicArgs) {
  return loadGeneralDataFields(queryClient, objectName, componentId, siteId)
    .catch((error) => Promise.reject(error))
    .then((response) => {
      const formFields = fields.filter(
        (field) =>
          (!field.apiCall || field.apiCall === generalApiCall) &&
          field.form === generalFormIndex &&
          (!field.product || field.product === product)
      );
      const formFieldNames = formFields.map((field) => field.name);
      const hiddenIfNullFormFields = formFields.filter(
        (field) => field.hiddenIfNull === true
      );
      const hiddenIfNullFormFieldNames = hiddenIfNullFormFields.map(
        (field) => field.name
      );

      const selectedFormFields = getOrderedFormFieldsFromResponseAndValues(
        formFieldNames,
        hiddenIfNullFormFieldNames,
        response.option.data.actions.pOST,
        response.detail.data
      );

      const generalData = {
        name: generalTabName,
        formTitle: "Allgemeine Daten",
        sections: [
          {
            fields: selectedFormFields,
            values: response.detail.data,
            errors: {}
          }
        ]
      };

      return Promise.resolve([generalData]);
    });
}

export function saveDataBasic(
  objectName: ObjectName,
  objectId: number,
  forms: Array<Form>,
  isPremium?: boolean
) {
  let newForms = [...forms];
  let didError = false;
  let serverError = null;

  newForms.forEach((form, index) => {
    newForms[index] = {
      ...form,
      sections: form.sections.map((section) => {
        return {
          ...section,
          errors: {}
        };
      })
    };
  });

  const allValues = forms.reduce((values, form) => {
    form.sections.forEach((section) => {
      values = {
        ...values,
        ...section.values
      };
    });

    return values;
  }, {});
  return saveGeneralDatafields(objectName, objectId, allValues, isPremium)
    .catch((error) => {
      if (error.response && error.response.status === 400) {
        const errors = assignErrors(error, forms);
        const clearedForms = newForms;

        newForms = clearedForms.map((clearedForm, formIndex) => {
          return {
            ...clearedForm,
            sections: clearedForm.sections.map((section, sectionIndex) => {
              const sectionErrors =
                clearedForm.sections.length === 1
                  ? errors[formIndex]
                  : errors[formIndex][sectionIndex];

              return {
                ...section,
                errors: { ...sectionErrors }
              };
            })
          };
        });

        if (
          Object.prototype.hasOwnProperty.call(
            error.response.data,
            "nonFieldErrors"
          )
        ) {
          serverError = error;
        }
      } else {
        serverError = error;
      }

      didError = true;
    })
    .then((response) => {
      return Promise.resolve({
        responseData: response ? response.data : null,
        forms: newForms,
        didError: didError,
        serverError: serverError
      });
    });
}

export function saveGeneralDatafields(
  objectName: ObjectName,
  componentId: number,
  data: FormValues,
  isPremium?: boolean
) {
  const detailUrl = getObjectDetailUrl(objectName, componentId, isPremium);
  return api.patch(detailUrl, data);
}

function assignErrors(error: ApiError, forms: Array<Form>) {
  const initialErrorsList = forms.map((form) => {
    if (form.sections.length === 1) {
      return {};
    }

    const sectionErrors: Array<Record<string, never>> = [];

    let sectionIndex = form.sections.length;
    while (sectionIndex--) {
      sectionErrors.push({});
    }

    return sectionErrors;
  });

  const errorsData = error.response?.data || {};

  const errors =
    Object.keys(errorsData).length > 0
      ? Object.keys(errorsData).reduce((errors, key) => {
          let sectionIncludesKey = false;

          for (let formIndex = 0; formIndex < forms.length; formIndex++) {
            const sections = forms[formIndex].sections;

            for (
              let sectionIndex = 0;
              sectionIndex < sections.length;
              sectionIndex++
            ) {
              sectionIncludesKey = checkSectionIncludesKey(
                sections[sectionIndex],
                key
              );

              if (sectionIncludesKey) {
                if (sections.length === 1) {
                  errors[formIndex][key] = errorsData[key];
                } else {
                  errors[formIndex][sectionIndex][key] = errorsData[key];
                }

                break;
              }
            }

            if (sectionIncludesKey) {
              break;
            }
          }

          return errors;
        }, initialErrorsList)
      : [];

  return errors;
}

function checkSectionIncludesKey(section: FormSection, key: string) {
  return section.fields.map((field) => field.name).includes(key);
}

export function filterValues(
  allValues: FormValues,
  fields: Array<FormFieldData>
) {
  const allowedKeys = fields.map((field) => field.name);
  const allowedValues = Object.keys(allValues).reduce((values, key) => {
    if (allowedKeys.includes(key)) {
      values[key] = allValues[key];
    }

    return values;
  }, {});

  return allowedValues;
}

export function mapDependenciesToSelectedFields(
  selectedFields: Array<FormFieldData>,
  fieldDefinitions: Array<GeneralDataField>
) {
  return selectedFields.map((field) => {
    const retField = {
      ...field
    };

    const dependencies = fieldDefinitions.find(
      (rdField) => rdField.name === field.name
    )?.dependencies;

    if (dependencies) {
      retField.dependencies = dependencies;
    }

    return retField;
  });
}
