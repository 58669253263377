import api from "../../../api";
import urls from "../../../urls";

interface EntityAndMeterId {
  entityId: number;
  meterId: number | null;
}

export async function updateEntitiesMeterData(
  configurationId: number,
  entityAndMeterIds: EntityAndMeterId[]
) {
  await api.post(
    urls.api.subMeteringConfigurationMeterAssignments(configurationId),
    entityAndMeterIds.map((data) => ({
      subMeteringSystemEntityId: data.entityId,
      meterId: data.meterId
    }))
  );
}
