import type { DateTime } from "luxon";
import {
  type BackendDateFormat,
  luxonDateTimeToBackendDateOrDateTime
} from "./luxonDateTimeToBackendDateOrDateTime";

/** Calls `luxonDateTimeToBackendDateOrDateTime` but returns `null` if `luxonDateTime` is null. */
export function luxonDateTimeToBackendDateOrDateTimeOrNull(
  luxonDateTime: DateTime | null,
  format: BackendDateFormat = "German"
) {
  if (!luxonDateTime) {
    return null;
  }

  return luxonDateTimeToBackendDateOrDateTime(luxonDateTime, format);
}
