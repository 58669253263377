export const loadScript = (url: string, id?: string) =>
  new Promise((resolve, reject) => {
    const s = document.createElement("script");
    s.src = url;
    s.onload = resolve;
    s.onerror = reject;
    s.async = true;

    if (id) {
      s.id = id;
    }

    document.body.appendChild(s);
  });
