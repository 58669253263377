import React from "react";
import ReactMarkdown from "react-markdown";
import { InfoIcon, InfoType } from "../../../../Icons/InfoIcon/InfoIcon";
import "./FormFieldInfoText.scss";

interface FormFieldInfoTextProps {
  infoText: string;
}

function FormFieldInfoText({ infoText }: FormFieldInfoTextProps) {
  return (
    <div className="FormFieldInfoText">
      <InfoIcon infoType={InfoType.Instruction}>
        <span className="info-text">
          <ReactMarkdown>{infoText}</ReactMarkdown>
        </span>
      </InfoIcon>
    </div>
  );
}

export { FormFieldInfoText, FormFieldInfoTextProps };
