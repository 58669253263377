import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import api from "../api";
import urls from "../urls";
import type { SiteNotification } from "../utils/backend-types";

export function useSiteNotifications(
  siteId: number,
  options?: Partial<UseQueryOptions<SiteNotification>>
) {
  return useQuery({
    queryKey: ["siteNotifications", { siteId }],
    queryFn: () => fetchSiteNotifications(siteId),
    ...options
  });
}

async function fetchSiteNotifications(siteId: number) {
  const siteNotificationsUrl = urls.api.siteNotifications(siteId);
  const response = await api.get(siteNotificationsUrl);
  return response.data;
}
