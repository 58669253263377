import React from "react";
import { NETZBEZUG_FREQUENCY_CHOICES } from "../../../../utils/constants";
import { Frequency } from "../../../../utils/enums";
import { TsDropdown } from "../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import "./IntervalSelector.scss";

type IntervalSelectorProps = {
  onIntervalChange: (_, interval: Frequency) => void;
  selectedFrequency: Frequency | undefined;
};

function IntervalSelector({
  onIntervalChange,
  selectedFrequency
}: IntervalSelectorProps) {
  return (
    <div className="interval-selector-container">
      <h6>Zeitintervall</h6>
      <TsDropdown
        choices={NETZBEZUG_FREQUENCY_CHOICES}
        defaultValue={selectedFrequency ?? Frequency.Month}
        id="energy-data-time-intervals"
        name="time-intervals"
        required={true}
        onChange={onIntervalChange}
      />
    </div>
  );
}

export { IntervalSelector };
