import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import urls from "../../../../../urls";
import { Select } from "../../../../BuildingBlocks/Forms/Select/Select";
import loader from "../../../../Loader";
import { Description as RegulatoryDutyDescription } from "../../../../Todos/TodoDescription/TodoDescription";
import { ErrorText } from "../utils/ErrorText";

import "./RegulatoryDuty.scss";

// Displays a dropdown with the list of regulatory duties in alphabetical order
// When a duty is selected, its description is shown as a preview below the dropdown
/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
class RegulatoryDuty extends PureComponent {
  handleChange = (selected) => {
    const value = selected.value;
    this.props.onChange(this.props.name, value === null ? null : value);
  };

  render() {
    const {
      id,
      value,
      data: { regulatoryDuties },
      disabled,
      errorTexts
    } = this.props;

    const sortedRegulatoryDuties = [...regulatoryDuties].sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      } else {
        return 1;
      }
    });

    const options = [
      {
        value: null,
        label: "Keine regulatorische Pflicht"
      },
      ...sortedRegulatoryDuties.map((duty) => {
        return {
          value: duty.id,
          label: duty.name
        };
      })
    ];

    return (
      <div className={"RegulatoryDuty FormField"}>
        <Select
          defaultValue={
            options.find((option) => option.value === value) || options[0]
          }
          id={id}
          invalid={errorTexts && errorTexts.length > 0}
          isDisabled={disabled}
          name={name}
          options={options}
          onChange={this.handleChange}
        />
        {errorTexts && errorTexts.map(ErrorText)}
        {!!value && (
          <div className="regulatory-duty-description">
            <h6>Beschreibung</h6>
            <RegulatoryDutyDescription
              regulatoryDuty={sortedRegulatoryDuties.find(
                (duty) => duty.id === value
              )}
            />
          </div>
        )}
      </div>
    );
  }
}

const RegulatoryDutyWithLoader = loader(RegulatoryDuty);

/** @deprecated All components related to DynamicForm should no longer be used. See https://node-energy.atlassian.net/wiki/spaces/DEV/pages/955973652/Forms+of+the+Future+Migration+Guide */
export default class RegulatoryDutyWithLoaderWrapper extends PureComponent {
  render() {
    const dataUrls = {
      regulatoryDuties: urls.api.regulatoryDutiesList()
    };

    return <RegulatoryDutyWithLoader dataUrls={dataUrls} {...this.props} />;
  }
}

RegulatoryDutyWithLoaderWrapper.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string // empty string is passed instead of null, so we need to support string
  ]),
  disabled: PropTypes.bool
};
