import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../api";
import urls from "../urls";
import type { MeteringOrMarketLocation } from "../utils/backend-types";
import { ObjectName } from "../utils/enums";
import { getPluralVariableNameFromObjectName } from "../utils/getPluralVariableNameFromObjectName";

export function useMeteringOrMarketLocationMutation(
  objectName: ObjectName.MeteringLocation | ObjectName.MarketLocation,
  mode: "create" | "edit",
  siteId: number
) {
  const queryClient = useQueryClient();
  const cacheName = getPluralVariableNameFromObjectName(objectName);

  return useMutation({
    mutationFn: (newData: MeteringOrMarketLocation) =>
      mutateMeteringOrMarketLocation(newData, objectName, mode, siteId),
    mutationKey: [cacheName, { siteOrVariantId: siteId }],
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [cacheName, { siteOrVariantId: siteId }]
      });
    }
  });
}

function mutateMeteringOrMarketLocation(
  newData: MeteringOrMarketLocation,
  objectName: ObjectName.MeteringLocation | ObjectName.MarketLocation,
  mode: "create" | "edit",
  siteId: number
) {
  if (mode === "create") {
    return createMeteringOrMarketLocation(newData, objectName, siteId);
  }

  return editMeteringOrMarketLocation(newData, objectName);
}

function createMeteringOrMarketLocation(
  newLocation: MeteringOrMarketLocation,
  objectName: ObjectName.MeteringLocation | ObjectName.MarketLocation,
  siteId: number
) {
  const url =
    objectName === ObjectName.MeteringLocation
      ? urls.api.meteringLocations(siteId)
      : urls.api.marketLocations(siteId);

  return api.post(url, {
    ...newLocation,
    site: siteId
  });
}

function editMeteringOrMarketLocation(
  editedLocation: MeteringOrMarketLocation,
  objectName: ObjectName.MeteringLocation | ObjectName.MarketLocation
) {
  const url =
    objectName === ObjectName.MeteringLocation
      ? urls.api.meteringLocation(editedLocation.id)
      : urls.api.marketLocation(editedLocation.id);

  return api.patch(url, editedLocation);
}
