import { Flex, Paper, Text } from "@mantine/core";
import React from "react";

interface DataGapsChartTooltipProps {
  dateString: string;
  dataGaps: number;
  label: string;
}

function DataGapsChartTooltip({
  dateString,
  dataGaps,
  label
}: DataGapsChartTooltipProps) {
  if (!dateString || !dataGaps) return null;
  return (
    <Paper px="md" py="sm" radius="md" shadow="md" withBorder>
      <Text fw={700} fz="h5" mb={"sm"}>
        {label}
      </Text>

      <Flex justify={"space-between"}>
        <Text fw={500} fz="sm">
          Datum:
        </Text>{" "}
        <Text fw={500} fz="sm">
          {dateString}
        </Text>
      </Flex>

      <Flex justify={"space-between"}>
        <Text fw={500} fz="sm" mr="md">
          Anzahl Datenlücken:
        </Text>{" "}
        <Text fw={500} fz="sm">
          {dataGaps}/96
        </Text>
      </Flex>
    </Paper>
  );
}

export { DataGapsChartTooltip };
