import type { Node } from "reactflow";
import type { ContainerElement } from "../../../utils/backend-types";

export function getFlowNodesFromContainers(
  containerElements: Array<ContainerElement>,
  siteId: number
): Array<Node> {
  return containerElements.map((containerElement) => {
    return {
      id: containerElement.id.toString(),
      position: {
        y: containerElement.y,
        x: containerElement.x
      },
      data: {
        site: siteId,
        width: containerElement.width,
        height: containerElement.height,
        color: containerElement.data.color
      },
      type: "container"
    };
  });
}
