import { useQuery } from "@tanstack/react-query";
import api from "../api";
import urls from "../urls";
import type { Generator } from "../utils/backend-types";

export function useVariantGenerators(variantId: number) {
  const { data, isLoading, error } = useQuery({
    queryKey: ["generatorsByVariant", { variantId }],
    queryFn: () => fetchVariantGenerators(variantId)
  });

  async function fetchVariantGenerators(variantId: number) {
    const response = await api.get<Array<Generator>>(
      urls.api.generatorsByVariant(variantId)
    );

    return response.data;
  }

  return { data, isLoading, error };
}
