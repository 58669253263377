import React, { useCallback, useState, useMemo } from "react";

import type {
  Meter,
  MeterConfigurationData,
  Site
} from "../../../../utils/backend-types";
import { getMeterGroupName } from "../../../../utils/meter";
import { Icon } from "../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import { DeleteIcon } from "../../../Buttons/DeleteIcon";
import type { Choice } from "../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { TsDropdown } from "../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { Tooltip } from "../../../Tooltip/Tooltip";
import type { SelectedMeter } from "../../EditConfigurationModal/EditConfigurationModal";
import { getSuggestedMeterDisplayName } from "../../utils/getSuggestedMeterDisplayName";

import "./MeterSelectionCell.scss";

interface MeterSelectionProps {
  meterParameter: MeterConfigurationData;
  selectedMeters: Array<SelectedMeter>;
  metersBySiteId: Array<Meter> | undefined;
  sites: Site[];
  onChange: (value: string) => void;
  onMeterSave: (singleEntityId?: number) => Promise<{ success: boolean }>;
  onMeterRemove: (meterId: number) => Promise<void>;
}

function MeterSelectionCell({
  meterParameter,
  selectedMeters,
  metersBySiteId,
  sites,
  onChange,
  onMeterSave,
  onMeterRemove
}: MeterSelectionProps) {
  const [isSavingMeter, setIsSavingMeter] = useState<boolean>(false);
  const [isDeletingMeter, setIsDeletingMeter] = useState<boolean>(false);

  const meterChoices: Array<Choice> = useMemo(() => {
    return metersBySiteId && metersBySiteId?.length > 0
      ? [
          { displayName: "", value: "", group: "Zähler abwählen" },
          ...metersBySiteId
            .filter((meter) => meter.number)
            .map((meter) => ({
              displayName:
                meterParameter.suggestedMeter === meter.id
                  ? getSuggestedMeterDisplayName(meter)
                  : `${meter.number} | ${meter.name}`,
              value: meter.id,
              group: getMeterGroupName(meter.site, sites)
            }))
        ]
      : [{ displayName: "", value: "" }];
  }, [meterParameter, metersBySiteId, sites]);

  const handleChangeValue = useCallback(
    (_, value: string) => {
      onChange(value);
    },
    [onChange]
  );

  const savedMeter = metersBySiteId?.find(
    (meter) => meter.id === meterParameter.meter
  );

  const savedMeterDisplayName = savedMeter?.number
    ? `${savedMeter.number} | ` + savedMeter?.name
    : savedMeter?.name;

  return !meterParameter.meter ? (
    <div className="MeterSelectionWrapper">
      <TsDropdown
        choices={meterChoices}
        defaultValue={
          selectedMeters.find(
            (meter) => meter.subMeteringSystemEntityId === meterParameter.id
          )?.meterId ?? undefined
        }
        id={meterParameter.id.toString()}
        menuWidth={300}
        name={meterParameter.id.toString()}
        required
        onChange={handleChangeValue}
      />

      <div className="MeterSaveIconWrapper">
        {selectedMeters.find(
          (meter) =>
            meter.subMeteringSystemEntityId === meterParameter.id &&
            meter.meterName
        ) && (
          <>
            {isSavingMeter ? (
              <Icon name={IconName.SpinnerSpinning} />
            ) : (
              <Icon
                className="MeterSaveIcon"
                name={IconName.Save}
                tooltipText="Zähler speichern"
                onClick={async () => {
                  setIsSavingMeter(true);
                  await onMeterSave(meterParameter.id);
                  setIsSavingMeter(false);
                }}
              />
            )}
          </>
        )}
      </div>
    </div>
  ) : (
    <div className="MeterSwitchWrapper">
      <p className="SavedMeterText" data-for={`${savedMeter?.id}`} data-tip>
        {savedMeterDisplayName}
      </p>
      <Tooltip id={`${savedMeter?.id}`}>{savedMeterDisplayName}</Tooltip>

      {isDeletingMeter ? (
        <Icon name={IconName.SpinnerSpinning} />
      ) : (
        <DeleteIcon
          className="MeterRemoveIcon"
          onClick={async () => {
            setIsDeletingMeter(true);
            await onMeterRemove(meterParameter.id);
            setIsDeletingMeter(false);
          }}
        />
      )}
    </div>
  );
}

export { MeterSelectionCell };
