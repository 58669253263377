import { SiteSetupProcessDataStep } from "../../../types/api.types";

export function getTitleFromStep(step: SiteSetupProcessDataStep) {
  switch (step) {
    case SiteSetupProcessDataStep.name_and_pv_plants:
      return "Beginnen wir mit den Erzeugungsanlagen Ihrer Liegenschaft";
    case SiteSetupProcessDataStep.address_and_supply_type:
      return "Lassen Sie uns Ihre Liegenschaft konfigurieren";
    case SiteSetupProcessDataStep.connection:
      return "Diesen Netzverknüpfungspunkt gibt es in Ihrer Liegenschaft";
    case SiteSetupProcessDataStep.consumers:
      return "Diese Verbraucher gibt es in Ihrer Liegenschaft";
    case SiteSetupProcessDataStep.tenants:
      return "Welche Mieter beziehen Strom von Ihnen?";
    case SiteSetupProcessDataStep.meters:
      return "Welche Zähler befinden sich in Ihrer Liegenschaft?";
    default:
      return "Unbekannter Schritt";
  }
}
