import { useParams } from "react-router-dom";

/** Returns the siteId from the route - e.g. `/some-path/:siteId` */
export function useRouteSiteId() {
  const { siteId } = useParams();

  if (!siteId) {
    return undefined;
  }

  const formattedSiteId = parseInt(siteId, 10);

  return formattedSiteId;
}
