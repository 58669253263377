import { useQuery } from "@tanstack/react-query";
import api from "../api";
import urls from "../urls";
import type { AnlageStromstgListData } from "../utils/backend-types";

export function useAnlagenStromstgGenerators(anlagestromstgId: number) {
  const { data, error, isLoading } = useQuery({
    queryKey: ["anlagenStromstgListGenerators", anlagestromstgId],
    queryFn: () => fetchAnlagenStromstgGenerators(anlagestromstgId)
  });

  async function fetchAnlagenStromstgGenerators(anlagestromstgId: number) {
    const response = await api.get<Array<AnlageStromstgListData>>(
      urls.api.anlagenStromstgListGenerators(anlagestromstgId)
    );
    return response.data;
  }

  return {
    anlagenStromstgGenerators: data,
    error,
    isLoading
  };
}
